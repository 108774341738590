html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

button {
  background-color: #0000;
  background-image: none;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

html {
  font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #d2d6dc;
}

hr {
  border-top-width: 1px;
}

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  color: #a0aec0;
}

button, [role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

button, input, optgroup, select, textarea {
  line-height: inherit;
  color: inherit;
  padding: 0;
}

pre, code, kbd, samp {
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

.form-input {
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: .375rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.form-input::placeholder {
  color: #9fa6b2;
  opacity: 1;
}

.form-input:focus {
  border-color: #a4cafe;
  outline: none;
  box-shadow: 0 0 0 3px #a4cafe73;
}

.form-textarea {
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: .375rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.form-textarea::placeholder {
  color: #9fa6b2;
  opacity: 1;
}

.form-textarea:focus {
  border-color: #a4cafe;
  outline: none;
  box-shadow: 0 0 0 3px #a4cafe73;
}

.form-multiselect {
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: .375rem;
  padding: .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.form-multiselect:focus {
  border-color: #a4cafe;
  outline: none;
  box-shadow: 0 0 0 3px #a4cafe73;
}

.form-select {
  appearance: none;
  color-adjust: exact;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' fill='none'%3e%3cpath d='M7 7l3-3 3 3m0 6l-3 3-3-3' stroke='%239fa6b2' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
  background-position: right .5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: .375rem;
  padding: .5rem 2.5rem .5rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.form-select::-ms-expand {
  color: #9fa6b2;
  border: none;
}

@media not print {
  .form-select::-ms-expand {
    display: none;
  }
}

@media print and (-ms-high-contrast: active), print and (-ms-high-contrast: none) {
  .form-select {
    padding-right: .75rem;
  }
}

.form-select:focus {
  border-color: #a4cafe;
  outline: none;
  box-shadow: 0 0 0 3px #a4cafe73;
}

.form-checkbox:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media not print {
  .form-checkbox::-ms-check {
    color: #0000;
    background: inherit;
    border-width: 1px;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-checkbox {
  appearance: none;
  color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #3f83f8;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: .25rem;
  flex-shrink: 0;
  display: inline-block;
}

.form-checkbox:focus {
  border-color: #a4cafe;
  outline: none;
  box-shadow: 0 0 0 3px #a4cafe73;
}

.form-checkbox:checked:focus {
  border-color: #0000;
}

.form-radio:checked {
  background-color: currentColor;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: #0000;
}

@media not print {
  .form-radio::-ms-check {
    color: #0000;
    background: inherit;
    border-width: 1px;
    border-color: inherit;
    border-radius: inherit;
  }
}

.form-radio {
  appearance: none;
  color-adjust: exact;
  vertical-align: middle;
  user-select: none;
  height: 1rem;
  width: 1rem;
  color: #3f83f8;
  background-color: #fff;
  background-origin: border-box;
  border-width: 1px;
  border-color: #d2d6dc;
  border-radius: 100%;
  flex-shrink: 0;
  display: inline-block;
}

.form-radio:focus {
  border-color: #a4cafe;
  outline: none;
  box-shadow: 0 0 0 3px #a4cafe73;
}

.form-radio:checked:focus {
  border-color: #0000;
}

.space-y-0 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(0px * var(--space-y-reverse) );
}

.space-x-0 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(0px * var(--space-x-reverse) );
  margin-left: calc(0px * calc(1 - var(--space-x-reverse) ) );
}

.space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(.25rem * var(--space-y-reverse) );
}

.space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(.25rem * var(--space-x-reverse) );
  margin-left: calc(.25rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(.5rem * var(--space-y-reverse) );
}

.space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(.5rem * var(--space-x-reverse) );
  margin-left: calc(.5rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(.75rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(.75rem * var(--space-y-reverse) );
}

.space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(.75rem * var(--space-x-reverse) );
  margin-left: calc(.75rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(1rem * var(--space-y-reverse) );
}

.space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1rem * var(--space-x-reverse) );
  margin-left: calc(1rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(1.25rem * var(--space-y-reverse) );
}

.space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--space-x-reverse) );
  margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(1.5rem * var(--space-y-reverse) );
}

.space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--space-x-reverse) );
  margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-7 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(1.75rem * var(--space-y-reverse) );
}

.space-x-7 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--space-x-reverse) );
  margin-left: calc(1.75rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(2rem * var(--space-y-reverse) );
}

.space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2rem * var(--space-x-reverse) );
  margin-left: calc(2rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-9 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(2.25rem * var(--space-y-reverse) );
}

.space-x-9 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--space-x-reverse) );
  margin-left: calc(2.25rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(2.5rem * var(--space-y-reverse) );
}

.space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--space-x-reverse) );
  margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-11 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(2.75rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(2.75rem * var(--space-y-reverse) );
}

.space-x-11 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--space-x-reverse) );
  margin-left: calc(2.75rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(3rem * var(--space-y-reverse) );
}

.space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(3rem * var(--space-x-reverse) );
  margin-left: calc(3rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-13 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(3.25rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(3.25rem * var(--space-y-reverse) );
}

.space-x-13 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(3.25rem * var(--space-x-reverse) );
  margin-left: calc(3.25rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-14 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(3.5rem * var(--space-y-reverse) );
}

.space-x-14 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--space-x-reverse) );
  margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-15 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(3.75rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(3.75rem * var(--space-y-reverse) );
}

.space-x-15 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(3.75rem * var(--space-x-reverse) );
  margin-left: calc(3.75rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(4rem * var(--space-y-reverse) );
}

.space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(4rem * var(--space-x-reverse) );
  margin-left: calc(4rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(5rem * var(--space-y-reverse) );
}

.space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(5rem * var(--space-x-reverse) );
  margin-left: calc(5rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(6rem * var(--space-y-reverse) );
}

.space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(6rem * var(--space-x-reverse) );
  margin-left: calc(6rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-28 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(7rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(7rem * var(--space-y-reverse) );
}

.space-x-28 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(7rem * var(--space-x-reverse) );
  margin-left: calc(7rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(8rem * var(--space-y-reverse) );
}

.space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(8rem * var(--space-x-reverse) );
  margin-left: calc(8rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-36 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(9rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(9rem * var(--space-y-reverse) );
}

.space-x-36 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(9rem * var(--space-x-reverse) );
  margin-left: calc(9rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(10rem * var(--space-y-reverse) );
}

.space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(10rem * var(--space-x-reverse) );
  margin-left: calc(10rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-44 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(11rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(11rem * var(--space-y-reverse) );
}

.space-x-44 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(11rem * var(--space-x-reverse) );
  margin-left: calc(11rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(12rem * var(--space-y-reverse) );
}

.space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(12rem * var(--space-x-reverse) );
  margin-left: calc(12rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-52 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(13rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(13rem * var(--space-y-reverse) );
}

.space-x-52 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(13rem * var(--space-x-reverse) );
  margin-left: calc(13rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(14rem * var(--space-y-reverse) );
}

.space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(14rem * var(--space-x-reverse) );
  margin-left: calc(14rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-60 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(15rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(15rem * var(--space-y-reverse) );
}

.space-x-60 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(15rem * var(--space-x-reverse) );
  margin-left: calc(15rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(16rem * var(--space-y-reverse) );
}

.space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(16rem * var(--space-x-reverse) );
  margin-left: calc(16rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-72 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(18rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(18rem * var(--space-y-reverse) );
}

.space-x-72 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(18rem * var(--space-x-reverse) );
  margin-left: calc(18rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-80 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(20rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(20rem * var(--space-y-reverse) );
}

.space-x-80 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(20rem * var(--space-x-reverse) );
  margin-left: calc(20rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-96 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(24rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(24rem * var(--space-y-reverse) );
}

.space-x-96 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(24rem * var(--space-x-reverse) );
  margin-left: calc(24rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(1px * var(--space-y-reverse) );
}

.space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(1px * var(--space-x-reverse) );
  margin-left: calc(1px * calc(1 - var(--space-x-reverse) ) );
}

.space-y-0\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(.125rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(.125rem * var(--space-y-reverse) );
}

.space-x-0\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(.125rem * var(--space-x-reverse) );
  margin-left: calc(.125rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-1\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(.375rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(.375rem * var(--space-y-reverse) );
}

.space-x-1\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(.375rem * var(--space-x-reverse) );
  margin-left: calc(.375rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-2\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(.625rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(.625rem * var(--space-y-reverse) );
}

.space-x-2\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(.625rem * var(--space-x-reverse) );
  margin-left: calc(.625rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-3\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(.875rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(.875rem * var(--space-y-reverse) );
}

.space-x-3\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(.875rem * var(--space-x-reverse) );
  margin-left: calc(.875rem * calc(1 - var(--space-x-reverse) ) );
}

.space-y-1\/2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(50% * var(--space-y-reverse) );
}

.space-x-1\/2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(50% * var(--space-x-reverse) );
  margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-1\/3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(33.3333% * var(--space-y-reverse) );
}

.space-x-1\/3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(33.3333% * var(--space-x-reverse) );
  margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-2\/3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(66.6667% * var(--space-y-reverse) );
}

.space-x-2\/3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(66.6667% * var(--space-x-reverse) );
  margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-1\/4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(25% * var(--space-y-reverse) );
}

.space-x-1\/4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(25% * var(--space-x-reverse) );
  margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-2\/4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(50% * var(--space-y-reverse) );
}

.space-x-2\/4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(50% * var(--space-x-reverse) );
  margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-3\/4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(75% * var(--space-y-reverse) );
}

.space-x-3\/4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(75% * var(--space-x-reverse) );
  margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-1\/5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(20% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(20% * var(--space-y-reverse) );
}

.space-x-1\/5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(20% * var(--space-x-reverse) );
  margin-left: calc(20% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-2\/5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(40% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(40% * var(--space-y-reverse) );
}

.space-x-2\/5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(40% * var(--space-x-reverse) );
  margin-left: calc(40% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-3\/5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(60% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(60% * var(--space-y-reverse) );
}

.space-x-3\/5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(60% * var(--space-x-reverse) );
  margin-left: calc(60% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-4\/5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(80% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(80% * var(--space-y-reverse) );
}

.space-x-4\/5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(80% * var(--space-x-reverse) );
  margin-left: calc(80% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-1\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(16.6667% * var(--space-y-reverse) );
}

.space-x-1\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(16.6667% * var(--space-x-reverse) );
  margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-2\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(33.3333% * var(--space-y-reverse) );
}

.space-x-2\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(33.3333% * var(--space-x-reverse) );
  margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-3\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(50% * var(--space-y-reverse) );
}

.space-x-3\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(50% * var(--space-x-reverse) );
  margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-4\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(66.6667% * var(--space-y-reverse) );
}

.space-x-4\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(66.6667% * var(--space-x-reverse) );
  margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-5\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(83.3333% * var(--space-y-reverse) );
}

.space-x-5\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(83.3333% * var(--space-x-reverse) );
  margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-1\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(8.33333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(8.33333% * var(--space-y-reverse) );
}

.space-x-1\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(8.33333% * var(--space-x-reverse) );
  margin-left: calc(8.33333% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-2\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(16.6667% * var(--space-y-reverse) );
}

.space-x-2\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(16.6667% * var(--space-x-reverse) );
  margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-3\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(25% * var(--space-y-reverse) );
}

.space-x-3\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(25% * var(--space-x-reverse) );
  margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-4\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(33.3333% * var(--space-y-reverse) );
}

.space-x-4\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(33.3333% * var(--space-x-reverse) );
  margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-5\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(41.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(41.6667% * var(--space-y-reverse) );
}

.space-x-5\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(41.6667% * var(--space-x-reverse) );
  margin-left: calc(41.6667% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-6\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(50% * var(--space-y-reverse) );
}

.space-x-6\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(50% * var(--space-x-reverse) );
  margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-7\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(58.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(58.3333% * var(--space-y-reverse) );
}

.space-x-7\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(58.3333% * var(--space-x-reverse) );
  margin-left: calc(58.3333% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-8\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(66.6667% * var(--space-y-reverse) );
}

.space-x-8\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(66.6667% * var(--space-x-reverse) );
  margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-9\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(75% * var(--space-y-reverse) );
}

.space-x-9\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(75% * var(--space-x-reverse) );
  margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-10\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(83.3333% * var(--space-y-reverse) );
}

.space-x-10\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(83.3333% * var(--space-x-reverse) );
  margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-11\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(91.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(91.6667% * var(--space-y-reverse) );
}

.space-x-11\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(91.6667% * var(--space-x-reverse) );
  margin-left: calc(91.6667% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-full > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(100% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(100% * var(--space-y-reverse) );
}

.space-x-full > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(100% * var(--space-x-reverse) );
  margin-left: calc(100% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-1 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-.25rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-.25rem * var(--space-y-reverse) );
}

.-space-x-1 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-.25rem * var(--space-x-reverse) );
  margin-left: calc(-.25rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-.5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-.5rem * var(--space-y-reverse) );
}

.-space-x-2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-.5rem * var(--space-x-reverse) );
  margin-left: calc(-.5rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-.75rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-.75rem * var(--space-y-reverse) );
}

.-space-x-3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-.75rem * var(--space-x-reverse) );
  margin-left: calc(-.75rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-1rem * var(--space-y-reverse) );
}

.-space-x-4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1rem * var(--space-x-reverse) );
  margin-left: calc(-1rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-1.25rem * var(--space-y-reverse) );
}

.-space-x-5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--space-x-reverse) );
  margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-1.5rem * var(--space-y-reverse) );
}

.-space-x-6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--space-x-reverse) );
  margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-7 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1.75rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-1.75rem * var(--space-y-reverse) );
}

.-space-x-7 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--space-x-reverse) );
  margin-left: calc(-1.75rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-8 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-2rem * var(--space-y-reverse) );
}

.-space-x-8 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2rem * var(--space-x-reverse) );
  margin-left: calc(-2rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-9 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2.25rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-2.25rem * var(--space-y-reverse) );
}

.-space-x-9 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--space-x-reverse) );
  margin-left: calc(-2.25rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-10 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-2.5rem * var(--space-y-reverse) );
}

.-space-x-10 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--space-x-reverse) );
  margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-11 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-2.75rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-2.75rem * var(--space-y-reverse) );
}

.-space-x-11 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--space-x-reverse) );
  margin-left: calc(-2.75rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-3rem * var(--space-y-reverse) );
}

.-space-x-12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-3rem * var(--space-x-reverse) );
  margin-left: calc(-3rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-13 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-3.25rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-3.25rem * var(--space-y-reverse) );
}

.-space-x-13 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-3.25rem * var(--space-x-reverse) );
  margin-left: calc(-3.25rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-14 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-3.5rem * var(--space-y-reverse) );
}

.-space-x-14 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--space-x-reverse) );
  margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-15 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-3.75rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-3.75rem * var(--space-y-reverse) );
}

.-space-x-15 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-3.75rem * var(--space-x-reverse) );
  margin-left: calc(-3.75rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-16 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-4rem * var(--space-y-reverse) );
}

.-space-x-16 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-4rem * var(--space-x-reverse) );
  margin-left: calc(-4rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-20 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-5rem * var(--space-y-reverse) );
}

.-space-x-20 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-5rem * var(--space-x-reverse) );
  margin-left: calc(-5rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-24 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-6rem * var(--space-y-reverse) );
}

.-space-x-24 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-6rem * var(--space-x-reverse) );
  margin-left: calc(-6rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-28 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-7rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-7rem * var(--space-y-reverse) );
}

.-space-x-28 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-7rem * var(--space-x-reverse) );
  margin-left: calc(-7rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-32 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-8rem * var(--space-y-reverse) );
}

.-space-x-32 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-8rem * var(--space-x-reverse) );
  margin-left: calc(-8rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-36 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-9rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-9rem * var(--space-y-reverse) );
}

.-space-x-36 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-9rem * var(--space-x-reverse) );
  margin-left: calc(-9rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-40 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-10rem * var(--space-y-reverse) );
}

.-space-x-40 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-10rem * var(--space-x-reverse) );
  margin-left: calc(-10rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-44 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-11rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-11rem * var(--space-y-reverse) );
}

.-space-x-44 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-11rem * var(--space-x-reverse) );
  margin-left: calc(-11rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-48 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-12rem * var(--space-y-reverse) );
}

.-space-x-48 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-12rem * var(--space-x-reverse) );
  margin-left: calc(-12rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-52 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-13rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-13rem * var(--space-y-reverse) );
}

.-space-x-52 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-13rem * var(--space-x-reverse) );
  margin-left: calc(-13rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-56 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-14rem * var(--space-y-reverse) );
}

.-space-x-56 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-14rem * var(--space-x-reverse) );
  margin-left: calc(-14rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-60 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-15rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-15rem * var(--space-y-reverse) );
}

.-space-x-60 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-15rem * var(--space-x-reverse) );
  margin-left: calc(-15rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-64 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-16rem * var(--space-y-reverse) );
}

.-space-x-64 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-16rem * var(--space-x-reverse) );
  margin-left: calc(-16rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-72 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-18rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-18rem * var(--space-y-reverse) );
}

.-space-x-72 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-18rem * var(--space-x-reverse) );
  margin-left: calc(-18rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-80 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-20rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-20rem * var(--space-y-reverse) );
}

.-space-x-80 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-20rem * var(--space-x-reverse) );
  margin-left: calc(-20rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-96 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-24rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-24rem * var(--space-y-reverse) );
}

.-space-x-96 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-24rem * var(--space-x-reverse) );
  margin-left: calc(-24rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-px > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-1px * var(--space-y-reverse) );
}

.-space-x-px > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-1px * var(--space-x-reverse) );
  margin-left: calc(-1px * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-0\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-.125rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-.125rem * var(--space-y-reverse) );
}

.-space-x-0\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-.125rem * var(--space-x-reverse) );
  margin-left: calc(-.125rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-1\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-.375rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-.375rem * var(--space-y-reverse) );
}

.-space-x-1\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-.375rem * var(--space-x-reverse) );
  margin-left: calc(-.375rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-2\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-.625rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-.625rem * var(--space-y-reverse) );
}

.-space-x-2\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-.625rem * var(--space-x-reverse) );
  margin-left: calc(-.625rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-3\.5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-.875rem * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-.875rem * var(--space-y-reverse) );
}

.-space-x-3\.5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-.875rem * var(--space-x-reverse) );
  margin-left: calc(-.875rem * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-1\/2 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-50% * var(--space-y-reverse) );
}

.-space-x-1\/2 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-50% * var(--space-x-reverse) );
  margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-1\/3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
}

.-space-x-1\/3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-33.3333% * var(--space-x-reverse) );
  margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-2\/3 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
}

.-space-x-2\/3 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-66.6667% * var(--space-x-reverse) );
  margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-1\/4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-25% * var(--space-y-reverse) );
}

.-space-x-1\/4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-25% * var(--space-x-reverse) );
  margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-2\/4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-50% * var(--space-y-reverse) );
}

.-space-x-2\/4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-50% * var(--space-x-reverse) );
  margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-3\/4 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-75% * var(--space-y-reverse) );
}

.-space-x-3\/4 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-75% * var(--space-x-reverse) );
  margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-1\/5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-20% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-20% * var(--space-y-reverse) );
}

.-space-x-1\/5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-20% * var(--space-x-reverse) );
  margin-left: calc(-20% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-2\/5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-40% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-40% * var(--space-y-reverse) );
}

.-space-x-2\/5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-40% * var(--space-x-reverse) );
  margin-left: calc(-40% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-3\/5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-60% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-60% * var(--space-y-reverse) );
}

.-space-x-3\/5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-60% * var(--space-x-reverse) );
  margin-left: calc(-60% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-4\/5 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-80% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-80% * var(--space-y-reverse) );
}

.-space-x-4\/5 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-80% * var(--space-x-reverse) );
  margin-left: calc(-80% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-1\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
}

.-space-x-1\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-16.6667% * var(--space-x-reverse) );
  margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-2\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
}

.-space-x-2\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-33.3333% * var(--space-x-reverse) );
  margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-3\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-50% * var(--space-y-reverse) );
}

.-space-x-3\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-50% * var(--space-x-reverse) );
  margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-4\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
}

.-space-x-4\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-66.6667% * var(--space-x-reverse) );
  margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-5\/6 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
}

.-space-x-5\/6 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-83.3333% * var(--space-x-reverse) );
  margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-1\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-8.33333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-8.33333% * var(--space-y-reverse) );
}

.-space-x-1\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-8.33333% * var(--space-x-reverse) );
  margin-left: calc(-8.33333% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-2\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
}

.-space-x-2\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-16.6667% * var(--space-x-reverse) );
  margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-3\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-25% * var(--space-y-reverse) );
}

.-space-x-3\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-25% * var(--space-x-reverse) );
  margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-4\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
}

.-space-x-4\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-33.3333% * var(--space-x-reverse) );
  margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-5\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-41.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-41.6667% * var(--space-y-reverse) );
}

.-space-x-5\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-41.6667% * var(--space-x-reverse) );
  margin-left: calc(-41.6667% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-6\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-50% * var(--space-y-reverse) );
}

.-space-x-6\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-50% * var(--space-x-reverse) );
  margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-7\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-58.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-58.3333% * var(--space-y-reverse) );
}

.-space-x-7\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-58.3333% * var(--space-x-reverse) );
  margin-left: calc(-58.3333% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-8\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
}

.-space-x-8\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-66.6667% * var(--space-x-reverse) );
  margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-9\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-75% * var(--space-y-reverse) );
}

.-space-x-9\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-75% * var(--space-x-reverse) );
  margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-10\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
}

.-space-x-10\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-83.3333% * var(--space-x-reverse) );
  margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-11\/12 > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-91.6667% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-91.6667% * var(--space-y-reverse) );
}

.-space-x-11\/12 > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-91.6667% * var(--space-x-reverse) );
  margin-left: calc(-91.6667% * calc(1 - var(--space-x-reverse) ) );
}

.-space-y-full > :not(template) ~ :not(template) {
  --space-y-reverse: 0;
  margin-top: calc(-100% * calc(1 - var(--space-y-reverse) ) );
  margin-bottom: calc(-100% * var(--space-y-reverse) );
}

.-space-x-full > :not(template) ~ :not(template) {
  --space-x-reverse: 0;
  margin-right: calc(-100% * var(--space-x-reverse) );
  margin-left: calc(-100% * calc(1 - var(--space-x-reverse) ) );
}

.space-y-reverse > :not(template) ~ :not(template) {
  --space-y-reverse: 1;
}

.space-x-reverse > :not(template) ~ :not(template) {
  --space-x-reverse: 1;
}

.divide-y-0 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--divide-y-reverse) ) );
  border-bottom-width: calc(0px * var(--divide-y-reverse) );
}

.divide-x-0 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(0px * var(--divide-x-reverse) );
  border-left-width: calc(0px * calc(1 - var(--divide-x-reverse) ) );
}

.divide-y-2 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--divide-y-reverse) ) );
  border-bottom-width: calc(2px * var(--divide-y-reverse) );
}

.divide-x-2 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(2px * var(--divide-x-reverse) );
  border-left-width: calc(2px * calc(1 - var(--divide-x-reverse) ) );
}

.divide-y-4 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--divide-y-reverse) ) );
  border-bottom-width: calc(4px * var(--divide-y-reverse) );
}

.divide-x-4 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(4px * var(--divide-x-reverse) );
  border-left-width: calc(4px * calc(1 - var(--divide-x-reverse) ) );
}

.divide-y-8 > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--divide-y-reverse) ) );
  border-bottom-width: calc(8px * var(--divide-y-reverse) );
}

.divide-x-8 > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(8px * var(--divide-x-reverse) );
  border-left-width: calc(8px * calc(1 - var(--divide-x-reverse) ) );
}

.divide-y > :not(template) ~ :not(template) {
  --divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--divide-y-reverse) ) );
  border-bottom-width: calc(1px * var(--divide-y-reverse) );
}

.divide-x > :not(template) ~ :not(template) {
  --divide-x-reverse: 0;
  border-right-width: calc(1px * var(--divide-x-reverse) );
  border-left-width: calc(1px * calc(1 - var(--divide-x-reverse) ) );
}

.divide-y-reverse > :not(template) ~ :not(template) {
  --divide-y-reverse: 1;
}

.divide-x-reverse > :not(template) ~ :not(template) {
  --divide-x-reverse: 1;
}

.divide-transparent > :not(template) ~ :not(template) {
  border-color: #0000;
}

.divide-white > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--divide-opacity) );
}

.divide-black > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--divide-opacity) );
}

.divide-gray-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--divide-opacity) );
}

.divide-gray-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--divide-opacity) );
}

.divide-gray-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--divide-opacity) );
}

.divide-gray-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d2d6dc;
  border-color: rgba(210, 214, 220, var(--divide-opacity) );
}

.divide-gray-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9fa6b2;
  border-color: rgba(159, 166, 178, var(--divide-opacity) );
}

.divide-gray-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #6b7280;
  border-color: rgba(107, 114, 128, var(--divide-opacity) );
}

.divide-gray-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4b5563;
  border-color: rgba(75, 85, 99, var(--divide-opacity) );
}

.divide-gray-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #374151;
  border-color: rgba(55, 65, 81, var(--divide-opacity) );
}

.divide-gray-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #252f3f;
  border-color: rgba(37, 47, 63, var(--divide-opacity) );
}

.divide-gray-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #161e2e;
  border-color: rgba(22, 30, 46, var(--divide-opacity) );
}

.divide-cool-gray-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--divide-opacity) );
}

.divide-cool-gray-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--divide-opacity) );
}

.divide-cool-gray-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--divide-opacity) );
}

.divide-cool-gray-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--divide-opacity) );
}

.divide-cool-gray-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--divide-opacity) );
}

.divide-cool-gray-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--divide-opacity) );
}

.divide-cool-gray-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--divide-opacity) );
}

.divide-cool-gray-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--divide-opacity) );
}

.divide-cool-gray-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--divide-opacity) );
}

.divide-cool-gray-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--divide-opacity) );
}

.divide-red-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--divide-opacity) );
}

.divide-red-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--divide-opacity) );
}

.divide-red-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--divide-opacity) );
}

.divide-red-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--divide-opacity) );
}

.divide-red-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--divide-opacity) );
}

.divide-red-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--divide-opacity) );
}

.divide-red-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--divide-opacity) );
}

.divide-red-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--divide-opacity) );
}

.divide-red-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--divide-opacity) );
}

.divide-red-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--divide-opacity) );
}

.divide-orange-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--divide-opacity) );
}

.divide-orange-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--divide-opacity) );
}

.divide-orange-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--divide-opacity) );
}

.divide-orange-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--divide-opacity) );
}

.divide-orange-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--divide-opacity) );
}

.divide-orange-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--divide-opacity) );
}

.divide-orange-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--divide-opacity) );
}

.divide-orange-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--divide-opacity) );
}

.divide-orange-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--divide-opacity) );
}

.divide-orange-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #73230d;
  border-color: rgba(115, 35, 13, var(--divide-opacity) );
}

.divide-yellow-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--divide-opacity) );
}

.divide-yellow-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--divide-opacity) );
}

.divide-yellow-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--divide-opacity) );
}

.divide-yellow-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--divide-opacity) );
}

.divide-yellow-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--divide-opacity) );
}

.divide-yellow-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--divide-opacity) );
}

.divide-yellow-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--divide-opacity) );
}

.divide-yellow-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--divide-opacity) );
}

.divide-yellow-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--divide-opacity) );
}

.divide-yellow-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--divide-opacity) );
}

.divide-green-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--divide-opacity) );
}

.divide-green-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--divide-opacity) );
}

.divide-green-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--divide-opacity) );
}

.divide-green-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--divide-opacity) );
}

.divide-green-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--divide-opacity) );
}

.divide-green-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--divide-opacity) );
}

.divide-green-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--divide-opacity) );
}

.divide-green-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--divide-opacity) );
}

.divide-green-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--divide-opacity) );
}

.divide-green-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--divide-opacity) );
}

.divide-teal-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--divide-opacity) );
}

.divide-teal-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--divide-opacity) );
}

.divide-teal-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--divide-opacity) );
}

.divide-teal-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--divide-opacity) );
}

.divide-teal-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--divide-opacity) );
}

.divide-teal-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--divide-opacity) );
}

.divide-teal-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--divide-opacity) );
}

.divide-teal-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--divide-opacity) );
}

.divide-teal-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--divide-opacity) );
}

.divide-teal-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--divide-opacity) );
}

.divide-blue-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--divide-opacity) );
}

.divide-blue-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--divide-opacity) );
}

.divide-blue-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--divide-opacity) );
}

.divide-blue-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--divide-opacity) );
}

.divide-blue-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--divide-opacity) );
}

.divide-blue-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--divide-opacity) );
}

.divide-blue-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--divide-opacity) );
}

.divide-blue-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--divide-opacity) );
}

.divide-blue-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--divide-opacity) );
}

.divide-blue-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--divide-opacity) );
}

.divide-indigo-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--divide-opacity) );
}

.divide-indigo-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--divide-opacity) );
}

.divide-indigo-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--divide-opacity) );
}

.divide-indigo-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--divide-opacity) );
}

.divide-indigo-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--divide-opacity) );
}

.divide-indigo-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--divide-opacity) );
}

.divide-indigo-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--divide-opacity) );
}

.divide-indigo-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--divide-opacity) );
}

.divide-indigo-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--divide-opacity) );
}

.divide-indigo-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--divide-opacity) );
}

.divide-purple-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--divide-opacity) );
}

.divide-purple-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--divide-opacity) );
}

.divide-purple-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--divide-opacity) );
}

.divide-purple-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--divide-opacity) );
}

.divide-purple-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--divide-opacity) );
}

.divide-purple-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--divide-opacity) );
}

.divide-purple-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--divide-opacity) );
}

.divide-purple-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--divide-opacity) );
}

.divide-purple-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--divide-opacity) );
}

.divide-purple-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--divide-opacity) );
}

.divide-pink-50 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--divide-opacity) );
}

.divide-pink-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--divide-opacity) );
}

.divide-pink-200 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--divide-opacity) );
}

.divide-pink-300 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--divide-opacity) );
}

.divide-pink-400 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--divide-opacity) );
}

.divide-pink-500 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--divide-opacity) );
}

.divide-pink-600 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--divide-opacity) );
}

.divide-pink-700 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--divide-opacity) );
}

.divide-pink-800 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--divide-opacity) );
}

.divide-pink-900 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--divide-opacity) );
}

.divide-solid > :not(template) ~ :not(template) {
  border-style: solid;
}

.divide-dashed > :not(template) ~ :not(template) {
  border-style: dashed;
}

.divide-dotted > :not(template) ~ :not(template) {
  border-style: dotted;
}

.divide-double > :not(template) ~ :not(template) {
  border-style: double;
}

.divide-none > :not(template) ~ :not(template) {
  border-style: none;
}

.divide-opacity-0 > :not(template) ~ :not(template) {
  --divide-opacity: 0;
}

.divide-opacity-25 > :not(template) ~ :not(template) {
  --divide-opacity: .25;
}

.divide-opacity-50 > :not(template) ~ :not(template) {
  --divide-opacity: .5;
}

.divide-opacity-75 > :not(template) ~ :not(template) {
  --divide-opacity: .75;
}

.divide-opacity-100 > :not(template) ~ :not(template) {
  --divide-opacity: 1;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.not-sr-only {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

.focus\:sr-only:focus {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.focus\:not-sr-only:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  margin: 0;
  padding: 0;
  position: static;
  overflow: visible;
}

.appearance-none {
  appearance: none;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-local {
  background-attachment: local;
}

.bg-scroll {
  background-attachment: scroll;
}

.bg-clip-border {
  background-clip: border-box;
}

.bg-clip-padding {
  background-clip: padding-box;
}

.bg-clip-content {
  background-clip: content-box;
}

.bg-clip-text {
  background-clip: text;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity) );
}

.bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity) );
}

.bg-gray-50 {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity) );
}

.bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity) );
}

.bg-gray-200 {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity) );
}

.bg-gray-300 {
  --bg-opacity: 1;
  background-color: #d2d6dc;
  background-color: rgba(210, 214, 220, var(--bg-opacity) );
}

.bg-gray-400 {
  --bg-opacity: 1;
  background-color: #9fa6b2;
  background-color: rgba(159, 166, 178, var(--bg-opacity) );
}

.bg-gray-500 {
  --bg-opacity: 1;
  background-color: #6b7280;
  background-color: rgba(107, 114, 128, var(--bg-opacity) );
}

.bg-gray-600 {
  --bg-opacity: 1;
  background-color: #4b5563;
  background-color: rgba(75, 85, 99, var(--bg-opacity) );
}

.bg-gray-700 {
  --bg-opacity: 1;
  background-color: #374151;
  background-color: rgba(55, 65, 81, var(--bg-opacity) );
}

.bg-gray-800 {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity) );
}

.bg-gray-900 {
  --bg-opacity: 1;
  background-color: #161e2e;
  background-color: rgba(22, 30, 46, var(--bg-opacity) );
}

.bg-cool-gray-50 {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity) );
}

.bg-cool-gray-100 {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity) );
}

.bg-cool-gray-200 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity) );
}

.bg-cool-gray-300 {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity) );
}

.bg-cool-gray-400 {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity) );
}

.bg-cool-gray-500 {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity) );
}

.bg-cool-gray-600 {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity) );
}

.bg-cool-gray-700 {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity) );
}

.bg-cool-gray-800 {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity) );
}

.bg-cool-gray-900 {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity) );
}

.bg-red-50 {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity) );
}

.bg-red-100 {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity) );
}

.bg-red-200 {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity) );
}

.bg-red-300 {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity) );
}

.bg-red-400 {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity) );
}

.bg-red-500 {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity) );
}

.bg-red-600 {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity) );
}

.bg-red-700 {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity) );
}

.bg-red-800 {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity) );
}

.bg-red-900 {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity) );
}

.bg-orange-50 {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity) );
}

.bg-orange-100 {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity) );
}

.bg-orange-200 {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity) );
}

.bg-orange-300 {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity) );
}

.bg-orange-400 {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity) );
}

.bg-orange-500 {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity) );
}

.bg-orange-600 {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity) );
}

.bg-orange-700 {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity) );
}

.bg-orange-800 {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity) );
}

.bg-orange-900 {
  --bg-opacity: 1;
  background-color: #73230d;
  background-color: rgba(115, 35, 13, var(--bg-opacity) );
}

.bg-yellow-50 {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity) );
}

.bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity) );
}

.bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity) );
}

.bg-yellow-300 {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity) );
}

.bg-yellow-400 {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity) );
}

.bg-yellow-500 {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity) );
}

.bg-yellow-600 {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity) );
}

.bg-yellow-700 {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity) );
}

.bg-yellow-800 {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity) );
}

.bg-yellow-900 {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity) );
}

.bg-green-50 {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity) );
}

.bg-green-100 {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity) );
}

.bg-green-200 {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity) );
}

.bg-green-300 {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity) );
}

.bg-green-400 {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity) );
}

.bg-green-500 {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity) );
}

.bg-green-600 {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity) );
}

.bg-green-700 {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity) );
}

.bg-green-800 {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity) );
}

.bg-green-900 {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity) );
}

.bg-teal-50 {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity) );
}

.bg-teal-100 {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity) );
}

.bg-teal-200 {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity) );
}

.bg-teal-300 {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity) );
}

.bg-teal-400 {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity) );
}

.bg-teal-500 {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity) );
}

.bg-teal-600 {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity) );
}

.bg-teal-700 {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity) );
}

.bg-teal-800 {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity) );
}

.bg-teal-900 {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity) );
}

.bg-blue-50 {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity) );
}

.bg-blue-100 {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity) );
}

.bg-blue-200 {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity) );
}

.bg-blue-300 {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity) );
}

.bg-blue-400 {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity) );
}

.bg-blue-500 {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity) );
}

.bg-blue-600 {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity) );
}

.bg-blue-700 {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity) );
}

.bg-blue-800 {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity) );
}

.bg-blue-900 {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity) );
}

.bg-indigo-50 {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity) );
}

.bg-indigo-100 {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity) );
}

.bg-indigo-200 {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity) );
}

.bg-indigo-300 {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity) );
}

.bg-indigo-400 {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity) );
}

.bg-indigo-500 {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity) );
}

.bg-indigo-600 {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity) );
}

.bg-indigo-700 {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity) );
}

.bg-indigo-800 {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity) );
}

.bg-indigo-900 {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity) );
}

.bg-purple-50 {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity) );
}

.bg-purple-100 {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity) );
}

.bg-purple-200 {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity) );
}

.bg-purple-300 {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity) );
}

.bg-purple-400 {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity) );
}

.bg-purple-500 {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity) );
}

.bg-purple-600 {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity) );
}

.bg-purple-700 {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity) );
}

.bg-purple-800 {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity) );
}

.bg-purple-900 {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity) );
}

.bg-pink-50 {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity) );
}

.bg-pink-100 {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity) );
}

.bg-pink-200 {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity) );
}

.bg-pink-300 {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity) );
}

.bg-pink-400 {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity) );
}

.bg-pink-500 {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity) );
}

.bg-pink-600 {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity) );
}

.bg-pink-700 {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity) );
}

.bg-pink-800 {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity) );
}

.bg-pink-900 {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-transparent {
  background-color: #0000;
}

.group:hover .group-hover\:bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-50 {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-200 {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-300 {
  --bg-opacity: 1;
  background-color: #d2d6dc;
  background-color: rgba(210, 214, 220, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-400 {
  --bg-opacity: 1;
  background-color: #9fa6b2;
  background-color: rgba(159, 166, 178, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-500 {
  --bg-opacity: 1;
  background-color: #6b7280;
  background-color: rgba(107, 114, 128, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-600 {
  --bg-opacity: 1;
  background-color: #4b5563;
  background-color: rgba(75, 85, 99, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-700 {
  --bg-opacity: 1;
  background-color: #374151;
  background-color: rgba(55, 65, 81, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-800 {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-gray-900 {
  --bg-opacity: 1;
  background-color: #161e2e;
  background-color: rgba(22, 30, 46, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-50 {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-100 {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-200 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-300 {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-400 {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-500 {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-600 {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-700 {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-800 {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-cool-gray-900 {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-50 {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-100 {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-200 {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-300 {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-400 {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-500 {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-600 {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-700 {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-800 {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-red-900 {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-50 {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-100 {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-200 {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-300 {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-400 {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-500 {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-600 {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-700 {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-800 {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-orange-900 {
  --bg-opacity: 1;
  background-color: #73230d;
  background-color: rgba(115, 35, 13, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-50 {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-300 {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-400 {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-500 {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-600 {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-700 {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-800 {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-yellow-900 {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-50 {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-100 {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-200 {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-300 {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-400 {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-500 {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-600 {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-700 {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-800 {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-green-900 {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-50 {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-100 {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-200 {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-300 {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-400 {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-500 {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-600 {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-700 {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-800 {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-teal-900 {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-50 {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-100 {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-200 {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-300 {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-400 {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-500 {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-600 {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-700 {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-800 {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-blue-900 {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-50 {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-100 {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-200 {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-300 {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-400 {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-500 {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-600 {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-700 {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-800 {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-indigo-900 {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-50 {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-100 {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-200 {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-300 {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-400 {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-500 {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-600 {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-700 {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-800 {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-purple-900 {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-50 {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-100 {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-200 {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-300 {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-400 {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-500 {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-600 {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-700 {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-800 {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity) );
}

.group:hover .group-hover\:bg-pink-900 {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-transparent {
  background-color: #0000;
}

.group:focus .group-focus\:bg-white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-50 {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-100 {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-200 {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-300 {
  --bg-opacity: 1;
  background-color: #d2d6dc;
  background-color: rgba(210, 214, 220, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-400 {
  --bg-opacity: 1;
  background-color: #9fa6b2;
  background-color: rgba(159, 166, 178, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-500 {
  --bg-opacity: 1;
  background-color: #6b7280;
  background-color: rgba(107, 114, 128, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-600 {
  --bg-opacity: 1;
  background-color: #4b5563;
  background-color: rgba(75, 85, 99, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-700 {
  --bg-opacity: 1;
  background-color: #374151;
  background-color: rgba(55, 65, 81, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-800 {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-gray-900 {
  --bg-opacity: 1;
  background-color: #161e2e;
  background-color: rgba(22, 30, 46, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-50 {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-100 {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-200 {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-300 {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-400 {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-500 {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-600 {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-700 {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-800 {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-cool-gray-900 {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-50 {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-100 {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-200 {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-300 {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-400 {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-500 {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-600 {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-700 {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-800 {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-red-900 {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-50 {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-100 {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-200 {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-300 {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-400 {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-500 {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-600 {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-700 {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-800 {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-orange-900 {
  --bg-opacity: 1;
  background-color: #73230d;
  background-color: rgba(115, 35, 13, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-50 {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-200 {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-300 {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-400 {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-500 {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-600 {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-700 {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-800 {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-yellow-900 {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-50 {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-100 {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-200 {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-300 {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-400 {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-500 {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-600 {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-700 {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-800 {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-green-900 {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-50 {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-100 {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-200 {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-300 {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-400 {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-500 {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-600 {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-700 {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-800 {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-teal-900 {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-50 {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-100 {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-200 {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-300 {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-400 {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-500 {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-600 {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-700 {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-800 {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-blue-900 {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-50 {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-100 {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-200 {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-300 {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-400 {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-500 {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-600 {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-700 {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-800 {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-indigo-900 {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-50 {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-100 {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-200 {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-300 {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-400 {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-500 {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-600 {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-700 {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-800 {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-purple-900 {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-50 {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-100 {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-200 {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-300 {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-400 {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-500 {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-600 {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-700 {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-800 {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity) );
}

.group:focus .group-focus\:bg-pink-900 {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity) );
}

.hover\:bg-transparent:hover {
  background-color: #0000;
}

.hover\:bg-white:hover {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity) );
}

.hover\:bg-black:hover {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity) );
}

.hover\:bg-gray-50:hover {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity) );
}

.hover\:bg-gray-100:hover {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity) );
}

.hover\:bg-gray-200:hover {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity) );
}

.hover\:bg-gray-300:hover {
  --bg-opacity: 1;
  background-color: #d2d6dc;
  background-color: rgba(210, 214, 220, var(--bg-opacity) );
}

.hover\:bg-gray-400:hover {
  --bg-opacity: 1;
  background-color: #9fa6b2;
  background-color: rgba(159, 166, 178, var(--bg-opacity) );
}

.hover\:bg-gray-500:hover {
  --bg-opacity: 1;
  background-color: #6b7280;
  background-color: rgba(107, 114, 128, var(--bg-opacity) );
}

.hover\:bg-gray-600:hover {
  --bg-opacity: 1;
  background-color: #4b5563;
  background-color: rgba(75, 85, 99, var(--bg-opacity) );
}

.hover\:bg-gray-700:hover {
  --bg-opacity: 1;
  background-color: #374151;
  background-color: rgba(55, 65, 81, var(--bg-opacity) );
}

.hover\:bg-gray-800:hover {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity) );
}

.hover\:bg-gray-900:hover {
  --bg-opacity: 1;
  background-color: #161e2e;
  background-color: rgba(22, 30, 46, var(--bg-opacity) );
}

.hover\:bg-cool-gray-50:hover {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity) );
}

.hover\:bg-cool-gray-100:hover {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity) );
}

.hover\:bg-cool-gray-200:hover {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity) );
}

.hover\:bg-cool-gray-300:hover {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity) );
}

.hover\:bg-cool-gray-400:hover {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity) );
}

.hover\:bg-cool-gray-500:hover {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity) );
}

.hover\:bg-cool-gray-600:hover {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity) );
}

.hover\:bg-cool-gray-700:hover {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity) );
}

.hover\:bg-cool-gray-800:hover {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity) );
}

.hover\:bg-cool-gray-900:hover {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity) );
}

.hover\:bg-red-50:hover {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity) );
}

.hover\:bg-red-100:hover {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity) );
}

.hover\:bg-red-200:hover {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity) );
}

.hover\:bg-red-300:hover {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity) );
}

.hover\:bg-red-400:hover {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity) );
}

.hover\:bg-red-500:hover {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity) );
}

.hover\:bg-red-600:hover {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity) );
}

.hover\:bg-red-700:hover {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity) );
}

.hover\:bg-red-800:hover {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity) );
}

.hover\:bg-red-900:hover {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity) );
}

.hover\:bg-orange-50:hover {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity) );
}

.hover\:bg-orange-100:hover {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity) );
}

.hover\:bg-orange-200:hover {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity) );
}

.hover\:bg-orange-300:hover {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity) );
}

.hover\:bg-orange-400:hover {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity) );
}

.hover\:bg-orange-500:hover {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity) );
}

.hover\:bg-orange-600:hover {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity) );
}

.hover\:bg-orange-700:hover {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity) );
}

.hover\:bg-orange-800:hover {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity) );
}

.hover\:bg-orange-900:hover {
  --bg-opacity: 1;
  background-color: #73230d;
  background-color: rgba(115, 35, 13, var(--bg-opacity) );
}

.hover\:bg-yellow-50:hover {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity) );
}

.hover\:bg-yellow-100:hover {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity) );
}

.hover\:bg-yellow-200:hover {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity) );
}

.hover\:bg-yellow-300:hover {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity) );
}

.hover\:bg-yellow-400:hover {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity) );
}

.hover\:bg-yellow-500:hover {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity) );
}

.hover\:bg-yellow-600:hover {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity) );
}

.hover\:bg-yellow-700:hover {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity) );
}

.hover\:bg-yellow-800:hover {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity) );
}

.hover\:bg-yellow-900:hover {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity) );
}

.hover\:bg-green-50:hover {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity) );
}

.hover\:bg-green-100:hover {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity) );
}

.hover\:bg-green-200:hover {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity) );
}

.hover\:bg-green-300:hover {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity) );
}

.hover\:bg-green-400:hover {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity) );
}

.hover\:bg-green-500:hover {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity) );
}

.hover\:bg-green-600:hover {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity) );
}

.hover\:bg-green-700:hover {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity) );
}

.hover\:bg-green-800:hover {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity) );
}

.hover\:bg-green-900:hover {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity) );
}

.hover\:bg-teal-50:hover {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity) );
}

.hover\:bg-teal-100:hover {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity) );
}

.hover\:bg-teal-200:hover {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity) );
}

.hover\:bg-teal-300:hover {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity) );
}

.hover\:bg-teal-400:hover {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity) );
}

.hover\:bg-teal-500:hover {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity) );
}

.hover\:bg-teal-600:hover {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity) );
}

.hover\:bg-teal-700:hover {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity) );
}

.hover\:bg-teal-800:hover {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity) );
}

.hover\:bg-teal-900:hover {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity) );
}

.hover\:bg-blue-50:hover {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity) );
}

.hover\:bg-blue-100:hover {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity) );
}

.hover\:bg-blue-200:hover {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity) );
}

.hover\:bg-blue-300:hover {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity) );
}

.hover\:bg-blue-400:hover {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity) );
}

.hover\:bg-blue-500:hover {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity) );
}

.hover\:bg-blue-600:hover {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity) );
}

.hover\:bg-blue-700:hover {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity) );
}

.hover\:bg-blue-800:hover {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity) );
}

.hover\:bg-blue-900:hover {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity) );
}

.hover\:bg-indigo-50:hover {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity) );
}

.hover\:bg-indigo-100:hover {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity) );
}

.hover\:bg-indigo-200:hover {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity) );
}

.hover\:bg-indigo-300:hover {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity) );
}

.hover\:bg-indigo-400:hover {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity) );
}

.hover\:bg-indigo-500:hover {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity) );
}

.hover\:bg-indigo-600:hover {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity) );
}

.hover\:bg-indigo-700:hover {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity) );
}

.hover\:bg-indigo-800:hover {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity) );
}

.hover\:bg-indigo-900:hover {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity) );
}

.hover\:bg-purple-50:hover {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity) );
}

.hover\:bg-purple-100:hover {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity) );
}

.hover\:bg-purple-200:hover {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity) );
}

.hover\:bg-purple-300:hover {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity) );
}

.hover\:bg-purple-400:hover {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity) );
}

.hover\:bg-purple-500:hover {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity) );
}

.hover\:bg-purple-600:hover {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity) );
}

.hover\:bg-purple-700:hover {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity) );
}

.hover\:bg-purple-800:hover {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity) );
}

.hover\:bg-purple-900:hover {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity) );
}

.hover\:bg-pink-50:hover {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity) );
}

.hover\:bg-pink-100:hover {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity) );
}

.hover\:bg-pink-200:hover {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity) );
}

.hover\:bg-pink-300:hover {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity) );
}

.hover\:bg-pink-400:hover {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity) );
}

.hover\:bg-pink-500:hover {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity) );
}

.hover\:bg-pink-600:hover {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity) );
}

.hover\:bg-pink-700:hover {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity) );
}

.hover\:bg-pink-800:hover {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity) );
}

.hover\:bg-pink-900:hover {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity) );
}

.focus\:bg-transparent:focus {
  background-color: #0000;
}

.focus\:bg-white:focus {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity) );
}

.focus\:bg-black:focus {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity) );
}

.focus\:bg-gray-50:focus {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity) );
}

.focus\:bg-gray-100:focus {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity) );
}

.focus\:bg-gray-200:focus {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity) );
}

.focus\:bg-gray-300:focus {
  --bg-opacity: 1;
  background-color: #d2d6dc;
  background-color: rgba(210, 214, 220, var(--bg-opacity) );
}

.focus\:bg-gray-400:focus {
  --bg-opacity: 1;
  background-color: #9fa6b2;
  background-color: rgba(159, 166, 178, var(--bg-opacity) );
}

.focus\:bg-gray-500:focus {
  --bg-opacity: 1;
  background-color: #6b7280;
  background-color: rgba(107, 114, 128, var(--bg-opacity) );
}

.focus\:bg-gray-600:focus {
  --bg-opacity: 1;
  background-color: #4b5563;
  background-color: rgba(75, 85, 99, var(--bg-opacity) );
}

.focus\:bg-gray-700:focus {
  --bg-opacity: 1;
  background-color: #374151;
  background-color: rgba(55, 65, 81, var(--bg-opacity) );
}

.focus\:bg-gray-800:focus {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity) );
}

.focus\:bg-gray-900:focus {
  --bg-opacity: 1;
  background-color: #161e2e;
  background-color: rgba(22, 30, 46, var(--bg-opacity) );
}

.focus\:bg-cool-gray-50:focus {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity) );
}

.focus\:bg-cool-gray-100:focus {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity) );
}

.focus\:bg-cool-gray-200:focus {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity) );
}

.focus\:bg-cool-gray-300:focus {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity) );
}

.focus\:bg-cool-gray-400:focus {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity) );
}

.focus\:bg-cool-gray-500:focus {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity) );
}

.focus\:bg-cool-gray-600:focus {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity) );
}

.focus\:bg-cool-gray-700:focus {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity) );
}

.focus\:bg-cool-gray-800:focus {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity) );
}

.focus\:bg-cool-gray-900:focus {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity) );
}

.focus\:bg-red-50:focus {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity) );
}

.focus\:bg-red-100:focus {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity) );
}

.focus\:bg-red-200:focus {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity) );
}

.focus\:bg-red-300:focus {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity) );
}

.focus\:bg-red-400:focus {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity) );
}

.focus\:bg-red-500:focus {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity) );
}

.focus\:bg-red-600:focus {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity) );
}

.focus\:bg-red-700:focus {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity) );
}

.focus\:bg-red-800:focus {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity) );
}

.focus\:bg-red-900:focus {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity) );
}

.focus\:bg-orange-50:focus {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity) );
}

.focus\:bg-orange-100:focus {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity) );
}

.focus\:bg-orange-200:focus {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity) );
}

.focus\:bg-orange-300:focus {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity) );
}

.focus\:bg-orange-400:focus {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity) );
}

.focus\:bg-orange-500:focus {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity) );
}

.focus\:bg-orange-600:focus {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity) );
}

.focus\:bg-orange-700:focus {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity) );
}

.focus\:bg-orange-800:focus {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity) );
}

.focus\:bg-orange-900:focus {
  --bg-opacity: 1;
  background-color: #73230d;
  background-color: rgba(115, 35, 13, var(--bg-opacity) );
}

.focus\:bg-yellow-50:focus {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity) );
}

.focus\:bg-yellow-100:focus {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity) );
}

.focus\:bg-yellow-200:focus {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity) );
}

.focus\:bg-yellow-300:focus {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity) );
}

.focus\:bg-yellow-400:focus {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity) );
}

.focus\:bg-yellow-500:focus {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity) );
}

.focus\:bg-yellow-600:focus {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity) );
}

.focus\:bg-yellow-700:focus {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity) );
}

.focus\:bg-yellow-800:focus {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity) );
}

.focus\:bg-yellow-900:focus {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity) );
}

.focus\:bg-green-50:focus {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity) );
}

.focus\:bg-green-100:focus {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity) );
}

.focus\:bg-green-200:focus {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity) );
}

.focus\:bg-green-300:focus {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity) );
}

.focus\:bg-green-400:focus {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity) );
}

.focus\:bg-green-500:focus {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity) );
}

.focus\:bg-green-600:focus {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity) );
}

.focus\:bg-green-700:focus {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity) );
}

.focus\:bg-green-800:focus {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity) );
}

.focus\:bg-green-900:focus {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity) );
}

.focus\:bg-teal-50:focus {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity) );
}

.focus\:bg-teal-100:focus {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity) );
}

.focus\:bg-teal-200:focus {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity) );
}

.focus\:bg-teal-300:focus {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity) );
}

.focus\:bg-teal-400:focus {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity) );
}

.focus\:bg-teal-500:focus {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity) );
}

.focus\:bg-teal-600:focus {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity) );
}

.focus\:bg-teal-700:focus {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity) );
}

.focus\:bg-teal-800:focus {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity) );
}

.focus\:bg-teal-900:focus {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity) );
}

.focus\:bg-blue-50:focus {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity) );
}

.focus\:bg-blue-100:focus {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity) );
}

.focus\:bg-blue-200:focus {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity) );
}

.focus\:bg-blue-300:focus {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity) );
}

.focus\:bg-blue-400:focus {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity) );
}

.focus\:bg-blue-500:focus {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity) );
}

.focus\:bg-blue-600:focus {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity) );
}

.focus\:bg-blue-700:focus {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity) );
}

.focus\:bg-blue-800:focus {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity) );
}

.focus\:bg-blue-900:focus {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity) );
}

.focus\:bg-indigo-50:focus {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity) );
}

.focus\:bg-indigo-100:focus {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity) );
}

.focus\:bg-indigo-200:focus {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity) );
}

.focus\:bg-indigo-300:focus {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity) );
}

.focus\:bg-indigo-400:focus {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity) );
}

.focus\:bg-indigo-500:focus {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity) );
}

.focus\:bg-indigo-600:focus {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity) );
}

.focus\:bg-indigo-700:focus {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity) );
}

.focus\:bg-indigo-800:focus {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity) );
}

.focus\:bg-indigo-900:focus {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity) );
}

.focus\:bg-purple-50:focus {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity) );
}

.focus\:bg-purple-100:focus {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity) );
}

.focus\:bg-purple-200:focus {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity) );
}

.focus\:bg-purple-300:focus {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity) );
}

.focus\:bg-purple-400:focus {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity) );
}

.focus\:bg-purple-500:focus {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity) );
}

.focus\:bg-purple-600:focus {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity) );
}

.focus\:bg-purple-700:focus {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity) );
}

.focus\:bg-purple-800:focus {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity) );
}

.focus\:bg-purple-900:focus {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity) );
}

.focus\:bg-pink-50:focus {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity) );
}

.focus\:bg-pink-100:focus {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity) );
}

.focus\:bg-pink-200:focus {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity) );
}

.focus\:bg-pink-300:focus {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity) );
}

.focus\:bg-pink-400:focus {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity) );
}

.focus\:bg-pink-500:focus {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity) );
}

.focus\:bg-pink-600:focus {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity) );
}

.focus\:bg-pink-700:focus {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity) );
}

.focus\:bg-pink-800:focus {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity) );
}

.focus\:bg-pink-900:focus {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity) );
}

.active\:bg-transparent:active {
  background-color: #0000;
}

.active\:bg-white:active {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity) );
}

.active\:bg-black:active {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity) );
}

.active\:bg-gray-50:active {
  --bg-opacity: 1;
  background-color: #f9fafb;
  background-color: rgba(249, 250, 251, var(--bg-opacity) );
}

.active\:bg-gray-100:active {
  --bg-opacity: 1;
  background-color: #f4f5f7;
  background-color: rgba(244, 245, 247, var(--bg-opacity) );
}

.active\:bg-gray-200:active {
  --bg-opacity: 1;
  background-color: #e5e7eb;
  background-color: rgba(229, 231, 235, var(--bg-opacity) );
}

.active\:bg-gray-300:active {
  --bg-opacity: 1;
  background-color: #d2d6dc;
  background-color: rgba(210, 214, 220, var(--bg-opacity) );
}

.active\:bg-gray-400:active {
  --bg-opacity: 1;
  background-color: #9fa6b2;
  background-color: rgba(159, 166, 178, var(--bg-opacity) );
}

.active\:bg-gray-500:active {
  --bg-opacity: 1;
  background-color: #6b7280;
  background-color: rgba(107, 114, 128, var(--bg-opacity) );
}

.active\:bg-gray-600:active {
  --bg-opacity: 1;
  background-color: #4b5563;
  background-color: rgba(75, 85, 99, var(--bg-opacity) );
}

.active\:bg-gray-700:active {
  --bg-opacity: 1;
  background-color: #374151;
  background-color: rgba(55, 65, 81, var(--bg-opacity) );
}

.active\:bg-gray-800:active {
  --bg-opacity: 1;
  background-color: #252f3f;
  background-color: rgba(37, 47, 63, var(--bg-opacity) );
}

.active\:bg-gray-900:active {
  --bg-opacity: 1;
  background-color: #161e2e;
  background-color: rgba(22, 30, 46, var(--bg-opacity) );
}

.active\:bg-cool-gray-50:active {
  --bg-opacity: 1;
  background-color: #fbfdfe;
  background-color: rgba(251, 253, 254, var(--bg-opacity) );
}

.active\:bg-cool-gray-100:active {
  --bg-opacity: 1;
  background-color: #f1f5f9;
  background-color: rgba(241, 245, 249, var(--bg-opacity) );
}

.active\:bg-cool-gray-200:active {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity) );
}

.active\:bg-cool-gray-300:active {
  --bg-opacity: 1;
  background-color: #cfd8e3;
  background-color: rgba(207, 216, 227, var(--bg-opacity) );
}

.active\:bg-cool-gray-400:active {
  --bg-opacity: 1;
  background-color: #97a6ba;
  background-color: rgba(151, 166, 186, var(--bg-opacity) );
}

.active\:bg-cool-gray-500:active {
  --bg-opacity: 1;
  background-color: #64748b;
  background-color: rgba(100, 116, 139, var(--bg-opacity) );
}

.active\:bg-cool-gray-600:active {
  --bg-opacity: 1;
  background-color: #475569;
  background-color: rgba(71, 85, 105, var(--bg-opacity) );
}

.active\:bg-cool-gray-700:active {
  --bg-opacity: 1;
  background-color: #364152;
  background-color: rgba(54, 65, 82, var(--bg-opacity) );
}

.active\:bg-cool-gray-800:active {
  --bg-opacity: 1;
  background-color: #27303f;
  background-color: rgba(39, 48, 63, var(--bg-opacity) );
}

.active\:bg-cool-gray-900:active {
  --bg-opacity: 1;
  background-color: #1a202e;
  background-color: rgba(26, 32, 46, var(--bg-opacity) );
}

.active\:bg-red-50:active {
  --bg-opacity: 1;
  background-color: #fdf2f2;
  background-color: rgba(253, 242, 242, var(--bg-opacity) );
}

.active\:bg-red-100:active {
  --bg-opacity: 1;
  background-color: #fde8e8;
  background-color: rgba(253, 232, 232, var(--bg-opacity) );
}

.active\:bg-red-200:active {
  --bg-opacity: 1;
  background-color: #fbd5d5;
  background-color: rgba(251, 213, 213, var(--bg-opacity) );
}

.active\:bg-red-300:active {
  --bg-opacity: 1;
  background-color: #f8b4b4;
  background-color: rgba(248, 180, 180, var(--bg-opacity) );
}

.active\:bg-red-400:active {
  --bg-opacity: 1;
  background-color: #f98080;
  background-color: rgba(249, 128, 128, var(--bg-opacity) );
}

.active\:bg-red-500:active {
  --bg-opacity: 1;
  background-color: #f05252;
  background-color: rgba(240, 82, 82, var(--bg-opacity) );
}

.active\:bg-red-600:active {
  --bg-opacity: 1;
  background-color: #e02424;
  background-color: rgba(224, 36, 36, var(--bg-opacity) );
}

.active\:bg-red-700:active {
  --bg-opacity: 1;
  background-color: #c81e1e;
  background-color: rgba(200, 30, 30, var(--bg-opacity) );
}

.active\:bg-red-800:active {
  --bg-opacity: 1;
  background-color: #9b1c1c;
  background-color: rgba(155, 28, 28, var(--bg-opacity) );
}

.active\:bg-red-900:active {
  --bg-opacity: 1;
  background-color: #771d1d;
  background-color: rgba(119, 29, 29, var(--bg-opacity) );
}

.active\:bg-orange-50:active {
  --bg-opacity: 1;
  background-color: #fff8f1;
  background-color: rgba(255, 248, 241, var(--bg-opacity) );
}

.active\:bg-orange-100:active {
  --bg-opacity: 1;
  background-color: #feecdc;
  background-color: rgba(254, 236, 220, var(--bg-opacity) );
}

.active\:bg-orange-200:active {
  --bg-opacity: 1;
  background-color: #fcd9bd;
  background-color: rgba(252, 217, 189, var(--bg-opacity) );
}

.active\:bg-orange-300:active {
  --bg-opacity: 1;
  background-color: #fdba8c;
  background-color: rgba(253, 186, 140, var(--bg-opacity) );
}

.active\:bg-orange-400:active {
  --bg-opacity: 1;
  background-color: #ff8a4c;
  background-color: rgba(255, 138, 76, var(--bg-opacity) );
}

.active\:bg-orange-500:active {
  --bg-opacity: 1;
  background-color: #ff5a1f;
  background-color: rgba(255, 90, 31, var(--bg-opacity) );
}

.active\:bg-orange-600:active {
  --bg-opacity: 1;
  background-color: #d03801;
  background-color: rgba(208, 56, 1, var(--bg-opacity) );
}

.active\:bg-orange-700:active {
  --bg-opacity: 1;
  background-color: #b43403;
  background-color: rgba(180, 52, 3, var(--bg-opacity) );
}

.active\:bg-orange-800:active {
  --bg-opacity: 1;
  background-color: #8a2c0d;
  background-color: rgba(138, 44, 13, var(--bg-opacity) );
}

.active\:bg-orange-900:active {
  --bg-opacity: 1;
  background-color: #73230d;
  background-color: rgba(115, 35, 13, var(--bg-opacity) );
}

.active\:bg-yellow-50:active {
  --bg-opacity: 1;
  background-color: #fdfdea;
  background-color: rgba(253, 253, 234, var(--bg-opacity) );
}

.active\:bg-yellow-100:active {
  --bg-opacity: 1;
  background-color: #fdf6b2;
  background-color: rgba(253, 246, 178, var(--bg-opacity) );
}

.active\:bg-yellow-200:active {
  --bg-opacity: 1;
  background-color: #fce96a;
  background-color: rgba(252, 233, 106, var(--bg-opacity) );
}

.active\:bg-yellow-300:active {
  --bg-opacity: 1;
  background-color: #faca15;
  background-color: rgba(250, 202, 21, var(--bg-opacity) );
}

.active\:bg-yellow-400:active {
  --bg-opacity: 1;
  background-color: #e3a008;
  background-color: rgba(227, 160, 8, var(--bg-opacity) );
}

.active\:bg-yellow-500:active {
  --bg-opacity: 1;
  background-color: #c27803;
  background-color: rgba(194, 120, 3, var(--bg-opacity) );
}

.active\:bg-yellow-600:active {
  --bg-opacity: 1;
  background-color: #9f580a;
  background-color: rgba(159, 88, 10, var(--bg-opacity) );
}

.active\:bg-yellow-700:active {
  --bg-opacity: 1;
  background-color: #8e4b10;
  background-color: rgba(142, 75, 16, var(--bg-opacity) );
}

.active\:bg-yellow-800:active {
  --bg-opacity: 1;
  background-color: #723b13;
  background-color: rgba(114, 59, 19, var(--bg-opacity) );
}

.active\:bg-yellow-900:active {
  --bg-opacity: 1;
  background-color: #633112;
  background-color: rgba(99, 49, 18, var(--bg-opacity) );
}

.active\:bg-green-50:active {
  --bg-opacity: 1;
  background-color: #f3faf7;
  background-color: rgba(243, 250, 247, var(--bg-opacity) );
}

.active\:bg-green-100:active {
  --bg-opacity: 1;
  background-color: #def7ec;
  background-color: rgba(222, 247, 236, var(--bg-opacity) );
}

.active\:bg-green-200:active {
  --bg-opacity: 1;
  background-color: #bcf0da;
  background-color: rgba(188, 240, 218, var(--bg-opacity) );
}

.active\:bg-green-300:active {
  --bg-opacity: 1;
  background-color: #84e1bc;
  background-color: rgba(132, 225, 188, var(--bg-opacity) );
}

.active\:bg-green-400:active {
  --bg-opacity: 1;
  background-color: #31c48d;
  background-color: rgba(49, 196, 141, var(--bg-opacity) );
}

.active\:bg-green-500:active {
  --bg-opacity: 1;
  background-color: #0e9f6e;
  background-color: rgba(14, 159, 110, var(--bg-opacity) );
}

.active\:bg-green-600:active {
  --bg-opacity: 1;
  background-color: #057a55;
  background-color: rgba(5, 122, 85, var(--bg-opacity) );
}

.active\:bg-green-700:active {
  --bg-opacity: 1;
  background-color: #046c4e;
  background-color: rgba(4, 108, 78, var(--bg-opacity) );
}

.active\:bg-green-800:active {
  --bg-opacity: 1;
  background-color: #03543f;
  background-color: rgba(3, 84, 63, var(--bg-opacity) );
}

.active\:bg-green-900:active {
  --bg-opacity: 1;
  background-color: #014737;
  background-color: rgba(1, 71, 55, var(--bg-opacity) );
}

.active\:bg-teal-50:active {
  --bg-opacity: 1;
  background-color: #edfafa;
  background-color: rgba(237, 250, 250, var(--bg-opacity) );
}

.active\:bg-teal-100:active {
  --bg-opacity: 1;
  background-color: #d5f5f6;
  background-color: rgba(213, 245, 246, var(--bg-opacity) );
}

.active\:bg-teal-200:active {
  --bg-opacity: 1;
  background-color: #afecef;
  background-color: rgba(175, 236, 239, var(--bg-opacity) );
}

.active\:bg-teal-300:active {
  --bg-opacity: 1;
  background-color: #7edce2;
  background-color: rgba(126, 220, 226, var(--bg-opacity) );
}

.active\:bg-teal-400:active {
  --bg-opacity: 1;
  background-color: #16bdca;
  background-color: rgba(22, 189, 202, var(--bg-opacity) );
}

.active\:bg-teal-500:active {
  --bg-opacity: 1;
  background-color: #0694a2;
  background-color: rgba(6, 148, 162, var(--bg-opacity) );
}

.active\:bg-teal-600:active {
  --bg-opacity: 1;
  background-color: #047481;
  background-color: rgba(4, 116, 129, var(--bg-opacity) );
}

.active\:bg-teal-700:active {
  --bg-opacity: 1;
  background-color: #036672;
  background-color: rgba(3, 102, 114, var(--bg-opacity) );
}

.active\:bg-teal-800:active {
  --bg-opacity: 1;
  background-color: #05505c;
  background-color: rgba(5, 80, 92, var(--bg-opacity) );
}

.active\:bg-teal-900:active {
  --bg-opacity: 1;
  background-color: #014451;
  background-color: rgba(1, 68, 81, var(--bg-opacity) );
}

.active\:bg-blue-50:active {
  --bg-opacity: 1;
  background-color: #ebf5ff;
  background-color: rgba(235, 245, 255, var(--bg-opacity) );
}

.active\:bg-blue-100:active {
  --bg-opacity: 1;
  background-color: #e1effe;
  background-color: rgba(225, 239, 254, var(--bg-opacity) );
}

.active\:bg-blue-200:active {
  --bg-opacity: 1;
  background-color: #c3ddfd;
  background-color: rgba(195, 221, 253, var(--bg-opacity) );
}

.active\:bg-blue-300:active {
  --bg-opacity: 1;
  background-color: #a4cafe;
  background-color: rgba(164, 202, 254, var(--bg-opacity) );
}

.active\:bg-blue-400:active {
  --bg-opacity: 1;
  background-color: #76a9fa;
  background-color: rgba(118, 169, 250, var(--bg-opacity) );
}

.active\:bg-blue-500:active {
  --bg-opacity: 1;
  background-color: #3f83f8;
  background-color: rgba(63, 131, 248, var(--bg-opacity) );
}

.active\:bg-blue-600:active {
  --bg-opacity: 1;
  background-color: #1c64f2;
  background-color: rgba(28, 100, 242, var(--bg-opacity) );
}

.active\:bg-blue-700:active {
  --bg-opacity: 1;
  background-color: #1a56db;
  background-color: rgba(26, 86, 219, var(--bg-opacity) );
}

.active\:bg-blue-800:active {
  --bg-opacity: 1;
  background-color: #1e429f;
  background-color: rgba(30, 66, 159, var(--bg-opacity) );
}

.active\:bg-blue-900:active {
  --bg-opacity: 1;
  background-color: #233876;
  background-color: rgba(35, 56, 118, var(--bg-opacity) );
}

.active\:bg-indigo-50:active {
  --bg-opacity: 1;
  background-color: #f0f5ff;
  background-color: rgba(240, 245, 255, var(--bg-opacity) );
}

.active\:bg-indigo-100:active {
  --bg-opacity: 1;
  background-color: #e5edff;
  background-color: rgba(229, 237, 255, var(--bg-opacity) );
}

.active\:bg-indigo-200:active {
  --bg-opacity: 1;
  background-color: #cddbfe;
  background-color: rgba(205, 219, 254, var(--bg-opacity) );
}

.active\:bg-indigo-300:active {
  --bg-opacity: 1;
  background-color: #b4c6fc;
  background-color: rgba(180, 198, 252, var(--bg-opacity) );
}

.active\:bg-indigo-400:active {
  --bg-opacity: 1;
  background-color: #8da2fb;
  background-color: rgba(141, 162, 251, var(--bg-opacity) );
}

.active\:bg-indigo-500:active {
  --bg-opacity: 1;
  background-color: #6875f5;
  background-color: rgba(104, 117, 245, var(--bg-opacity) );
}

.active\:bg-indigo-600:active {
  --bg-opacity: 1;
  background-color: #5850ec;
  background-color: rgba(88, 80, 236, var(--bg-opacity) );
}

.active\:bg-indigo-700:active {
  --bg-opacity: 1;
  background-color: #5145cd;
  background-color: rgba(81, 69, 205, var(--bg-opacity) );
}

.active\:bg-indigo-800:active {
  --bg-opacity: 1;
  background-color: #42389d;
  background-color: rgba(66, 56, 157, var(--bg-opacity) );
}

.active\:bg-indigo-900:active {
  --bg-opacity: 1;
  background-color: #362f78;
  background-color: rgba(54, 47, 120, var(--bg-opacity) );
}

.active\:bg-purple-50:active {
  --bg-opacity: 1;
  background-color: #f6f5ff;
  background-color: rgba(246, 245, 255, var(--bg-opacity) );
}

.active\:bg-purple-100:active {
  --bg-opacity: 1;
  background-color: #edebfe;
  background-color: rgba(237, 235, 254, var(--bg-opacity) );
}

.active\:bg-purple-200:active {
  --bg-opacity: 1;
  background-color: #dcd7fe;
  background-color: rgba(220, 215, 254, var(--bg-opacity) );
}

.active\:bg-purple-300:active {
  --bg-opacity: 1;
  background-color: #cabffd;
  background-color: rgba(202, 191, 253, var(--bg-opacity) );
}

.active\:bg-purple-400:active {
  --bg-opacity: 1;
  background-color: #ac94fa;
  background-color: rgba(172, 148, 250, var(--bg-opacity) );
}

.active\:bg-purple-500:active {
  --bg-opacity: 1;
  background-color: #9061f9;
  background-color: rgba(144, 97, 249, var(--bg-opacity) );
}

.active\:bg-purple-600:active {
  --bg-opacity: 1;
  background-color: #7e3af2;
  background-color: rgba(126, 58, 242, var(--bg-opacity) );
}

.active\:bg-purple-700:active {
  --bg-opacity: 1;
  background-color: #6c2bd9;
  background-color: rgba(108, 43, 217, var(--bg-opacity) );
}

.active\:bg-purple-800:active {
  --bg-opacity: 1;
  background-color: #5521b5;
  background-color: rgba(85, 33, 181, var(--bg-opacity) );
}

.active\:bg-purple-900:active {
  --bg-opacity: 1;
  background-color: #4a1d96;
  background-color: rgba(74, 29, 150, var(--bg-opacity) );
}

.active\:bg-pink-50:active {
  --bg-opacity: 1;
  background-color: #fdf2f8;
  background-color: rgba(253, 242, 248, var(--bg-opacity) );
}

.active\:bg-pink-100:active {
  --bg-opacity: 1;
  background-color: #fce8f3;
  background-color: rgba(252, 232, 243, var(--bg-opacity) );
}

.active\:bg-pink-200:active {
  --bg-opacity: 1;
  background-color: #fad1e8;
  background-color: rgba(250, 209, 232, var(--bg-opacity) );
}

.active\:bg-pink-300:active {
  --bg-opacity: 1;
  background-color: #f8b4d9;
  background-color: rgba(248, 180, 217, var(--bg-opacity) );
}

.active\:bg-pink-400:active {
  --bg-opacity: 1;
  background-color: #f17eb8;
  background-color: rgba(241, 126, 184, var(--bg-opacity) );
}

.active\:bg-pink-500:active {
  --bg-opacity: 1;
  background-color: #e74694;
  background-color: rgba(231, 70, 148, var(--bg-opacity) );
}

.active\:bg-pink-600:active {
  --bg-opacity: 1;
  background-color: #d61f69;
  background-color: rgba(214, 31, 105, var(--bg-opacity) );
}

.active\:bg-pink-700:active {
  --bg-opacity: 1;
  background-color: #bf125d;
  background-color: rgba(191, 18, 93, var(--bg-opacity) );
}

.active\:bg-pink-800:active {
  --bg-opacity: 1;
  background-color: #99154b;
  background-color: rgba(153, 21, 75, var(--bg-opacity) );
}

.active\:bg-pink-900:active {
  --bg-opacity: 1;
  background-color: #751a3d;
  background-color: rgba(117, 26, 61, var(--bg-opacity) );
}

.bg-none {
  background-image: none;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--gradient-color-stops) );
}

.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--gradient-color-stops) );
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--gradient-color-stops) );
}

.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--gradient-color-stops) );
}

.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--gradient-color-stops) );
}

.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--gradient-color-stops) );
}

.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--gradient-color-stops) );
}

.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--gradient-color-stops) );
}

.from-transparent {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
}

.from-white {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
}

.from-black {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
}

.from-gray-50 {
  --gradient-from-color: #f9fafb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
}

.from-gray-100 {
  --gradient-from-color: #f4f5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
}

.from-gray-200 {
  --gradient-from-color: #e5e7eb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
}

.from-gray-300 {
  --gradient-from-color: #d2d6dc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
}

.from-gray-400 {
  --gradient-from-color: #9fa6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
}

.from-gray-500 {
  --gradient-from-color: #6b7280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
}

.from-gray-600 {
  --gradient-from-color: #4b5563;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
}

.from-gray-700 {
  --gradient-from-color: #374151;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
}

.from-gray-800 {
  --gradient-from-color: #252f3f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
}

.from-gray-900 {
  --gradient-from-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
}

.from-cool-gray-50 {
  --gradient-from-color: #fbfdfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
}

.from-cool-gray-100 {
  --gradient-from-color: #f1f5f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
}

.from-cool-gray-200 {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
}

.from-cool-gray-300 {
  --gradient-from-color: #cfd8e3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
}

.from-cool-gray-400 {
  --gradient-from-color: #97a6ba;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
}

.from-cool-gray-500 {
  --gradient-from-color: #64748b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
}

.from-cool-gray-600 {
  --gradient-from-color: #475569;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
}

.from-cool-gray-700 {
  --gradient-from-color: #364152;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
}

.from-cool-gray-800 {
  --gradient-from-color: #27303f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
}

.from-cool-gray-900 {
  --gradient-from-color: #1a202e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
}

.from-red-50 {
  --gradient-from-color: #fdf2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
}

.from-red-100 {
  --gradient-from-color: #fde8e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
}

.from-red-200 {
  --gradient-from-color: #fbd5d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
}

.from-red-300 {
  --gradient-from-color: #f8b4b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
}

.from-red-400 {
  --gradient-from-color: #f98080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
}

.from-red-500 {
  --gradient-from-color: #f05252;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
}

.from-red-600 {
  --gradient-from-color: #e02424;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
}

.from-red-700 {
  --gradient-from-color: #c81e1e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
}

.from-red-800 {
  --gradient-from-color: #9b1c1c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
}

.from-red-900 {
  --gradient-from-color: #771d1d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
}

.from-orange-50 {
  --gradient-from-color: #fff8f1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
}

.from-orange-100 {
  --gradient-from-color: #feecdc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
}

.from-orange-200 {
  --gradient-from-color: #fcd9bd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
}

.from-orange-300 {
  --gradient-from-color: #fdba8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
}

.from-orange-400 {
  --gradient-from-color: #ff8a4c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
}

.from-orange-500 {
  --gradient-from-color: #ff5a1f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
}

.from-orange-600 {
  --gradient-from-color: #d03801;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
}

.from-orange-700 {
  --gradient-from-color: #b43403;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
}

.from-orange-800 {
  --gradient-from-color: #8a2c0d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
}

.from-orange-900 {
  --gradient-from-color: #73230d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
}

.from-yellow-50 {
  --gradient-from-color: #fdfdea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
}

.from-yellow-100 {
  --gradient-from-color: #fdf6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
}

.from-yellow-200 {
  --gradient-from-color: #fce96a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
}

.from-yellow-300 {
  --gradient-from-color: #faca15;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
}

.from-yellow-400 {
  --gradient-from-color: #e3a008;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
}

.from-yellow-500 {
  --gradient-from-color: #c27803;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
}

.from-yellow-600 {
  --gradient-from-color: #9f580a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
}

.from-yellow-700 {
  --gradient-from-color: #8e4b10;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
}

.from-yellow-800 {
  --gradient-from-color: #723b13;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
}

.from-yellow-900 {
  --gradient-from-color: #633112;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
}

.from-green-50 {
  --gradient-from-color: #f3faf7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
}

.from-green-100 {
  --gradient-from-color: #def7ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
}

.from-green-200 {
  --gradient-from-color: #bcf0da;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
}

.from-green-300 {
  --gradient-from-color: #84e1bc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
}

.from-green-400 {
  --gradient-from-color: #31c48d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
}

.from-green-500 {
  --gradient-from-color: #0e9f6e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
}

.from-green-600 {
  --gradient-from-color: #057a55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
}

.from-green-700 {
  --gradient-from-color: #046c4e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
}

.from-green-800 {
  --gradient-from-color: #03543f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
}

.from-green-900 {
  --gradient-from-color: #014737;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
}

.from-teal-50 {
  --gradient-from-color: #edfafa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
}

.from-teal-100 {
  --gradient-from-color: #d5f5f6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
}

.from-teal-200 {
  --gradient-from-color: #afecef;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
}

.from-teal-300 {
  --gradient-from-color: #7edce2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
}

.from-teal-400 {
  --gradient-from-color: #16bdca;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
}

.from-teal-500 {
  --gradient-from-color: #0694a2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
}

.from-teal-600 {
  --gradient-from-color: #047481;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
}

.from-teal-700 {
  --gradient-from-color: #036672;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
}

.from-teal-800 {
  --gradient-from-color: #05505c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
}

.from-teal-900 {
  --gradient-from-color: #014451;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
}

.from-blue-50 {
  --gradient-from-color: #ebf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
}

.from-blue-100 {
  --gradient-from-color: #e1effe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
}

.from-blue-200 {
  --gradient-from-color: #c3ddfd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
}

.from-blue-300 {
  --gradient-from-color: #a4cafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
}

.from-blue-400 {
  --gradient-from-color: #76a9fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
}

.from-blue-500 {
  --gradient-from-color: #3f83f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
}

.from-blue-600 {
  --gradient-from-color: #1c64f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
}

.from-blue-700 {
  --gradient-from-color: #1a56db;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
}

.from-blue-800 {
  --gradient-from-color: #1e429f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
}

.from-blue-900 {
  --gradient-from-color: #233876;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
}

.from-indigo-50 {
  --gradient-from-color: #f0f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
}

.from-indigo-100 {
  --gradient-from-color: #e5edff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
}

.from-indigo-200 {
  --gradient-from-color: #cddbfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
}

.from-indigo-300 {
  --gradient-from-color: #b4c6fc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
}

.from-indigo-400 {
  --gradient-from-color: #8da2fb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
}

.from-indigo-500 {
  --gradient-from-color: #6875f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
}

.from-indigo-600 {
  --gradient-from-color: #5850ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
}

.from-indigo-700 {
  --gradient-from-color: #5145cd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
}

.from-indigo-800 {
  --gradient-from-color: #42389d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
}

.from-indigo-900 {
  --gradient-from-color: #362f78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
}

.from-purple-50 {
  --gradient-from-color: #f6f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
}

.from-purple-100 {
  --gradient-from-color: #edebfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
}

.from-purple-200 {
  --gradient-from-color: #dcd7fe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
}

.from-purple-300 {
  --gradient-from-color: #cabffd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
}

.from-purple-400 {
  --gradient-from-color: #ac94fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
}

.from-purple-500 {
  --gradient-from-color: #9061f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
}

.from-purple-600 {
  --gradient-from-color: #7e3af2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
}

.from-purple-700 {
  --gradient-from-color: #6c2bd9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
}

.from-purple-800 {
  --gradient-from-color: #5521b5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
}

.from-purple-900 {
  --gradient-from-color: #4a1d96;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
}

.from-pink-50 {
  --gradient-from-color: #fdf2f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
}

.from-pink-100 {
  --gradient-from-color: #fce8f3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
}

.from-pink-200 {
  --gradient-from-color: #fad1e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
}

.from-pink-300 {
  --gradient-from-color: #f8b4d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
}

.from-pink-400 {
  --gradient-from-color: #f17eb8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
}

.from-pink-500 {
  --gradient-from-color: #e74694;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
}

.from-pink-600 {
  --gradient-from-color: #d61f69;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
}

.from-pink-700 {
  --gradient-from-color: #bf125d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
}

.from-pink-800 {
  --gradient-from-color: #99154b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
}

.from-pink-900 {
  --gradient-from-color: #751a3d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
}

.via-transparent {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
}

.via-white {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
}

.via-black {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
}

.via-gray-50 {
  --gradient-via-color: #f9fafb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
}

.via-gray-100 {
  --gradient-via-color: #f4f5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
}

.via-gray-200 {
  --gradient-via-color: #e5e7eb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
}

.via-gray-300 {
  --gradient-via-color: #d2d6dc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
}

.via-gray-400 {
  --gradient-via-color: #9fa6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
}

.via-gray-500 {
  --gradient-via-color: #6b7280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
}

.via-gray-600 {
  --gradient-via-color: #4b5563;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
}

.via-gray-700 {
  --gradient-via-color: #374151;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
}

.via-gray-800 {
  --gradient-via-color: #252f3f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
}

.via-gray-900 {
  --gradient-via-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
}

.via-cool-gray-50 {
  --gradient-via-color: #fbfdfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
}

.via-cool-gray-100 {
  --gradient-via-color: #f1f5f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
}

.via-cool-gray-200 {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
}

.via-cool-gray-300 {
  --gradient-via-color: #cfd8e3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
}

.via-cool-gray-400 {
  --gradient-via-color: #97a6ba;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
}

.via-cool-gray-500 {
  --gradient-via-color: #64748b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
}

.via-cool-gray-600 {
  --gradient-via-color: #475569;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
}

.via-cool-gray-700 {
  --gradient-via-color: #364152;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
}

.via-cool-gray-800 {
  --gradient-via-color: #27303f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
}

.via-cool-gray-900 {
  --gradient-via-color: #1a202e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
}

.via-red-50 {
  --gradient-via-color: #fdf2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
}

.via-red-100 {
  --gradient-via-color: #fde8e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
}

.via-red-200 {
  --gradient-via-color: #fbd5d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
}

.via-red-300 {
  --gradient-via-color: #f8b4b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
}

.via-red-400 {
  --gradient-via-color: #f98080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
}

.via-red-500 {
  --gradient-via-color: #f05252;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
}

.via-red-600 {
  --gradient-via-color: #e02424;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
}

.via-red-700 {
  --gradient-via-color: #c81e1e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
}

.via-red-800 {
  --gradient-via-color: #9b1c1c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
}

.via-red-900 {
  --gradient-via-color: #771d1d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
}

.via-orange-50 {
  --gradient-via-color: #fff8f1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
}

.via-orange-100 {
  --gradient-via-color: #feecdc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
}

.via-orange-200 {
  --gradient-via-color: #fcd9bd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
}

.via-orange-300 {
  --gradient-via-color: #fdba8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
}

.via-orange-400 {
  --gradient-via-color: #ff8a4c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
}

.via-orange-500 {
  --gradient-via-color: #ff5a1f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
}

.via-orange-600 {
  --gradient-via-color: #d03801;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
}

.via-orange-700 {
  --gradient-via-color: #b43403;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
}

.via-orange-800 {
  --gradient-via-color: #8a2c0d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
}

.via-orange-900 {
  --gradient-via-color: #73230d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
}

.via-yellow-50 {
  --gradient-via-color: #fdfdea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
}

.via-yellow-100 {
  --gradient-via-color: #fdf6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
}

.via-yellow-200 {
  --gradient-via-color: #fce96a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
}

.via-yellow-300 {
  --gradient-via-color: #faca15;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
}

.via-yellow-400 {
  --gradient-via-color: #e3a008;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
}

.via-yellow-500 {
  --gradient-via-color: #c27803;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
}

.via-yellow-600 {
  --gradient-via-color: #9f580a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
}

.via-yellow-700 {
  --gradient-via-color: #8e4b10;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
}

.via-yellow-800 {
  --gradient-via-color: #723b13;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
}

.via-yellow-900 {
  --gradient-via-color: #633112;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
}

.via-green-50 {
  --gradient-via-color: #f3faf7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
}

.via-green-100 {
  --gradient-via-color: #def7ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
}

.via-green-200 {
  --gradient-via-color: #bcf0da;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
}

.via-green-300 {
  --gradient-via-color: #84e1bc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
}

.via-green-400 {
  --gradient-via-color: #31c48d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
}

.via-green-500 {
  --gradient-via-color: #0e9f6e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
}

.via-green-600 {
  --gradient-via-color: #057a55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
}

.via-green-700 {
  --gradient-via-color: #046c4e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
}

.via-green-800 {
  --gradient-via-color: #03543f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
}

.via-green-900 {
  --gradient-via-color: #014737;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
}

.via-teal-50 {
  --gradient-via-color: #edfafa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
}

.via-teal-100 {
  --gradient-via-color: #d5f5f6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
}

.via-teal-200 {
  --gradient-via-color: #afecef;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
}

.via-teal-300 {
  --gradient-via-color: #7edce2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
}

.via-teal-400 {
  --gradient-via-color: #16bdca;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
}

.via-teal-500 {
  --gradient-via-color: #0694a2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
}

.via-teal-600 {
  --gradient-via-color: #047481;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
}

.via-teal-700 {
  --gradient-via-color: #036672;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
}

.via-teal-800 {
  --gradient-via-color: #05505c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
}

.via-teal-900 {
  --gradient-via-color: #014451;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
}

.via-blue-50 {
  --gradient-via-color: #ebf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
}

.via-blue-100 {
  --gradient-via-color: #e1effe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
}

.via-blue-200 {
  --gradient-via-color: #c3ddfd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
}

.via-blue-300 {
  --gradient-via-color: #a4cafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
}

.via-blue-400 {
  --gradient-via-color: #76a9fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
}

.via-blue-500 {
  --gradient-via-color: #3f83f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
}

.via-blue-600 {
  --gradient-via-color: #1c64f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
}

.via-blue-700 {
  --gradient-via-color: #1a56db;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
}

.via-blue-800 {
  --gradient-via-color: #1e429f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
}

.via-blue-900 {
  --gradient-via-color: #233876;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
}

.via-indigo-50 {
  --gradient-via-color: #f0f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
}

.via-indigo-100 {
  --gradient-via-color: #e5edff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
}

.via-indigo-200 {
  --gradient-via-color: #cddbfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
}

.via-indigo-300 {
  --gradient-via-color: #b4c6fc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
}

.via-indigo-400 {
  --gradient-via-color: #8da2fb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
}

.via-indigo-500 {
  --gradient-via-color: #6875f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
}

.via-indigo-600 {
  --gradient-via-color: #5850ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
}

.via-indigo-700 {
  --gradient-via-color: #5145cd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
}

.via-indigo-800 {
  --gradient-via-color: #42389d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
}

.via-indigo-900 {
  --gradient-via-color: #362f78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
}

.via-purple-50 {
  --gradient-via-color: #f6f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
}

.via-purple-100 {
  --gradient-via-color: #edebfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
}

.via-purple-200 {
  --gradient-via-color: #dcd7fe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
}

.via-purple-300 {
  --gradient-via-color: #cabffd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
}

.via-purple-400 {
  --gradient-via-color: #ac94fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
}

.via-purple-500 {
  --gradient-via-color: #9061f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
}

.via-purple-600 {
  --gradient-via-color: #7e3af2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
}

.via-purple-700 {
  --gradient-via-color: #6c2bd9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
}

.via-purple-800 {
  --gradient-via-color: #5521b5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
}

.via-purple-900 {
  --gradient-via-color: #4a1d96;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
}

.via-pink-50 {
  --gradient-via-color: #fdf2f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
}

.via-pink-100 {
  --gradient-via-color: #fce8f3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
}

.via-pink-200 {
  --gradient-via-color: #fad1e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
}

.via-pink-300 {
  --gradient-via-color: #f8b4d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
}

.via-pink-400 {
  --gradient-via-color: #f17eb8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
}

.via-pink-500 {
  --gradient-via-color: #e74694;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
}

.via-pink-600 {
  --gradient-via-color: #d61f69;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
}

.via-pink-700 {
  --gradient-via-color: #bf125d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
}

.via-pink-800 {
  --gradient-via-color: #99154b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
}

.via-pink-900 {
  --gradient-via-color: #751a3d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
}

.to-transparent {
  --gradient-to-color: transparent;
}

.to-white {
  --gradient-to-color: #fff;
}

.to-black {
  --gradient-to-color: #000;
}

.to-gray-50 {
  --gradient-to-color: #f9fafb;
}

.to-gray-100 {
  --gradient-to-color: #f4f5f7;
}

.to-gray-200 {
  --gradient-to-color: #e5e7eb;
}

.to-gray-300 {
  --gradient-to-color: #d2d6dc;
}

.to-gray-400 {
  --gradient-to-color: #9fa6b2;
}

.to-gray-500 {
  --gradient-to-color: #6b7280;
}

.to-gray-600 {
  --gradient-to-color: #4b5563;
}

.to-gray-700 {
  --gradient-to-color: #374151;
}

.to-gray-800 {
  --gradient-to-color: #252f3f;
}

.to-gray-900 {
  --gradient-to-color: #161e2e;
}

.to-cool-gray-50 {
  --gradient-to-color: #fbfdfe;
}

.to-cool-gray-100 {
  --gradient-to-color: #f1f5f9;
}

.to-cool-gray-200 {
  --gradient-to-color: #e2e8f0;
}

.to-cool-gray-300 {
  --gradient-to-color: #cfd8e3;
}

.to-cool-gray-400 {
  --gradient-to-color: #97a6ba;
}

.to-cool-gray-500 {
  --gradient-to-color: #64748b;
}

.to-cool-gray-600 {
  --gradient-to-color: #475569;
}

.to-cool-gray-700 {
  --gradient-to-color: #364152;
}

.to-cool-gray-800 {
  --gradient-to-color: #27303f;
}

.to-cool-gray-900 {
  --gradient-to-color: #1a202e;
}

.to-red-50 {
  --gradient-to-color: #fdf2f2;
}

.to-red-100 {
  --gradient-to-color: #fde8e8;
}

.to-red-200 {
  --gradient-to-color: #fbd5d5;
}

.to-red-300 {
  --gradient-to-color: #f8b4b4;
}

.to-red-400 {
  --gradient-to-color: #f98080;
}

.to-red-500 {
  --gradient-to-color: #f05252;
}

.to-red-600 {
  --gradient-to-color: #e02424;
}

.to-red-700 {
  --gradient-to-color: #c81e1e;
}

.to-red-800 {
  --gradient-to-color: #9b1c1c;
}

.to-red-900 {
  --gradient-to-color: #771d1d;
}

.to-orange-50 {
  --gradient-to-color: #fff8f1;
}

.to-orange-100 {
  --gradient-to-color: #feecdc;
}

.to-orange-200 {
  --gradient-to-color: #fcd9bd;
}

.to-orange-300 {
  --gradient-to-color: #fdba8c;
}

.to-orange-400 {
  --gradient-to-color: #ff8a4c;
}

.to-orange-500 {
  --gradient-to-color: #ff5a1f;
}

.to-orange-600 {
  --gradient-to-color: #d03801;
}

.to-orange-700 {
  --gradient-to-color: #b43403;
}

.to-orange-800 {
  --gradient-to-color: #8a2c0d;
}

.to-orange-900 {
  --gradient-to-color: #73230d;
}

.to-yellow-50 {
  --gradient-to-color: #fdfdea;
}

.to-yellow-100 {
  --gradient-to-color: #fdf6b2;
}

.to-yellow-200 {
  --gradient-to-color: #fce96a;
}

.to-yellow-300 {
  --gradient-to-color: #faca15;
}

.to-yellow-400 {
  --gradient-to-color: #e3a008;
}

.to-yellow-500 {
  --gradient-to-color: #c27803;
}

.to-yellow-600 {
  --gradient-to-color: #9f580a;
}

.to-yellow-700 {
  --gradient-to-color: #8e4b10;
}

.to-yellow-800 {
  --gradient-to-color: #723b13;
}

.to-yellow-900 {
  --gradient-to-color: #633112;
}

.to-green-50 {
  --gradient-to-color: #f3faf7;
}

.to-green-100 {
  --gradient-to-color: #def7ec;
}

.to-green-200 {
  --gradient-to-color: #bcf0da;
}

.to-green-300 {
  --gradient-to-color: #84e1bc;
}

.to-green-400 {
  --gradient-to-color: #31c48d;
}

.to-green-500 {
  --gradient-to-color: #0e9f6e;
}

.to-green-600 {
  --gradient-to-color: #057a55;
}

.to-green-700 {
  --gradient-to-color: #046c4e;
}

.to-green-800 {
  --gradient-to-color: #03543f;
}

.to-green-900 {
  --gradient-to-color: #014737;
}

.to-teal-50 {
  --gradient-to-color: #edfafa;
}

.to-teal-100 {
  --gradient-to-color: #d5f5f6;
}

.to-teal-200 {
  --gradient-to-color: #afecef;
}

.to-teal-300 {
  --gradient-to-color: #7edce2;
}

.to-teal-400 {
  --gradient-to-color: #16bdca;
}

.to-teal-500 {
  --gradient-to-color: #0694a2;
}

.to-teal-600 {
  --gradient-to-color: #047481;
}

.to-teal-700 {
  --gradient-to-color: #036672;
}

.to-teal-800 {
  --gradient-to-color: #05505c;
}

.to-teal-900 {
  --gradient-to-color: #014451;
}

.to-blue-50 {
  --gradient-to-color: #ebf5ff;
}

.to-blue-100 {
  --gradient-to-color: #e1effe;
}

.to-blue-200 {
  --gradient-to-color: #c3ddfd;
}

.to-blue-300 {
  --gradient-to-color: #a4cafe;
}

.to-blue-400 {
  --gradient-to-color: #76a9fa;
}

.to-blue-500 {
  --gradient-to-color: #3f83f8;
}

.to-blue-600 {
  --gradient-to-color: #1c64f2;
}

.to-blue-700 {
  --gradient-to-color: #1a56db;
}

.to-blue-800 {
  --gradient-to-color: #1e429f;
}

.to-blue-900 {
  --gradient-to-color: #233876;
}

.to-indigo-50 {
  --gradient-to-color: #f0f5ff;
}

.to-indigo-100 {
  --gradient-to-color: #e5edff;
}

.to-indigo-200 {
  --gradient-to-color: #cddbfe;
}

.to-indigo-300 {
  --gradient-to-color: #b4c6fc;
}

.to-indigo-400 {
  --gradient-to-color: #8da2fb;
}

.to-indigo-500 {
  --gradient-to-color: #6875f5;
}

.to-indigo-600 {
  --gradient-to-color: #5850ec;
}

.to-indigo-700 {
  --gradient-to-color: #5145cd;
}

.to-indigo-800 {
  --gradient-to-color: #42389d;
}

.to-indigo-900 {
  --gradient-to-color: #362f78;
}

.to-purple-50 {
  --gradient-to-color: #f6f5ff;
}

.to-purple-100 {
  --gradient-to-color: #edebfe;
}

.to-purple-200 {
  --gradient-to-color: #dcd7fe;
}

.to-purple-300 {
  --gradient-to-color: #cabffd;
}

.to-purple-400 {
  --gradient-to-color: #ac94fa;
}

.to-purple-500 {
  --gradient-to-color: #9061f9;
}

.to-purple-600 {
  --gradient-to-color: #7e3af2;
}

.to-purple-700 {
  --gradient-to-color: #6c2bd9;
}

.to-purple-800 {
  --gradient-to-color: #5521b5;
}

.to-purple-900 {
  --gradient-to-color: #4a1d96;
}

.to-pink-50 {
  --gradient-to-color: #fdf2f8;
}

.to-pink-100 {
  --gradient-to-color: #fce8f3;
}

.to-pink-200 {
  --gradient-to-color: #fad1e8;
}

.to-pink-300 {
  --gradient-to-color: #f8b4d9;
}

.to-pink-400 {
  --gradient-to-color: #f17eb8;
}

.to-pink-500 {
  --gradient-to-color: #e74694;
}

.to-pink-600 {
  --gradient-to-color: #d61f69;
}

.to-pink-700 {
  --gradient-to-color: #bf125d;
}

.to-pink-800 {
  --gradient-to-color: #99154b;
}

.to-pink-900 {
  --gradient-to-color: #751a3d;
}

.hover\:from-transparent:hover {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
}

.hover\:from-white:hover {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
}

.hover\:from-black:hover {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
}

.hover\:from-gray-50:hover {
  --gradient-from-color: #f9fafb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
}

.hover\:from-gray-100:hover {
  --gradient-from-color: #f4f5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
}

.hover\:from-gray-200:hover {
  --gradient-from-color: #e5e7eb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
}

.hover\:from-gray-300:hover {
  --gradient-from-color: #d2d6dc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
}

.hover\:from-gray-400:hover {
  --gradient-from-color: #9fa6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
}

.hover\:from-gray-500:hover {
  --gradient-from-color: #6b7280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
}

.hover\:from-gray-600:hover {
  --gradient-from-color: #4b5563;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
}

.hover\:from-gray-700:hover {
  --gradient-from-color: #374151;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
}

.hover\:from-gray-800:hover {
  --gradient-from-color: #252f3f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
}

.hover\:from-gray-900:hover {
  --gradient-from-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
}

.hover\:from-cool-gray-50:hover {
  --gradient-from-color: #fbfdfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
}

.hover\:from-cool-gray-100:hover {
  --gradient-from-color: #f1f5f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
}

.hover\:from-cool-gray-200:hover {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
}

.hover\:from-cool-gray-300:hover {
  --gradient-from-color: #cfd8e3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
}

.hover\:from-cool-gray-400:hover {
  --gradient-from-color: #97a6ba;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
}

.hover\:from-cool-gray-500:hover {
  --gradient-from-color: #64748b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
}

.hover\:from-cool-gray-600:hover {
  --gradient-from-color: #475569;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
}

.hover\:from-cool-gray-700:hover {
  --gradient-from-color: #364152;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
}

.hover\:from-cool-gray-800:hover {
  --gradient-from-color: #27303f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
}

.hover\:from-cool-gray-900:hover {
  --gradient-from-color: #1a202e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
}

.hover\:from-red-50:hover {
  --gradient-from-color: #fdf2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
}

.hover\:from-red-100:hover {
  --gradient-from-color: #fde8e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
}

.hover\:from-red-200:hover {
  --gradient-from-color: #fbd5d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
}

.hover\:from-red-300:hover {
  --gradient-from-color: #f8b4b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
}

.hover\:from-red-400:hover {
  --gradient-from-color: #f98080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
}

.hover\:from-red-500:hover {
  --gradient-from-color: #f05252;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
}

.hover\:from-red-600:hover {
  --gradient-from-color: #e02424;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
}

.hover\:from-red-700:hover {
  --gradient-from-color: #c81e1e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
}

.hover\:from-red-800:hover {
  --gradient-from-color: #9b1c1c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
}

.hover\:from-red-900:hover {
  --gradient-from-color: #771d1d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
}

.hover\:from-orange-50:hover {
  --gradient-from-color: #fff8f1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
}

.hover\:from-orange-100:hover {
  --gradient-from-color: #feecdc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
}

.hover\:from-orange-200:hover {
  --gradient-from-color: #fcd9bd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
}

.hover\:from-orange-300:hover {
  --gradient-from-color: #fdba8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
}

.hover\:from-orange-400:hover {
  --gradient-from-color: #ff8a4c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
}

.hover\:from-orange-500:hover {
  --gradient-from-color: #ff5a1f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
}

.hover\:from-orange-600:hover {
  --gradient-from-color: #d03801;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
}

.hover\:from-orange-700:hover {
  --gradient-from-color: #b43403;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
}

.hover\:from-orange-800:hover {
  --gradient-from-color: #8a2c0d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
}

.hover\:from-orange-900:hover {
  --gradient-from-color: #73230d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
}

.hover\:from-yellow-50:hover {
  --gradient-from-color: #fdfdea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
}

.hover\:from-yellow-100:hover {
  --gradient-from-color: #fdf6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
}

.hover\:from-yellow-200:hover {
  --gradient-from-color: #fce96a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
}

.hover\:from-yellow-300:hover {
  --gradient-from-color: #faca15;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
}

.hover\:from-yellow-400:hover {
  --gradient-from-color: #e3a008;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
}

.hover\:from-yellow-500:hover {
  --gradient-from-color: #c27803;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
}

.hover\:from-yellow-600:hover {
  --gradient-from-color: #9f580a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
}

.hover\:from-yellow-700:hover {
  --gradient-from-color: #8e4b10;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
}

.hover\:from-yellow-800:hover {
  --gradient-from-color: #723b13;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
}

.hover\:from-yellow-900:hover {
  --gradient-from-color: #633112;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
}

.hover\:from-green-50:hover {
  --gradient-from-color: #f3faf7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
}

.hover\:from-green-100:hover {
  --gradient-from-color: #def7ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
}

.hover\:from-green-200:hover {
  --gradient-from-color: #bcf0da;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
}

.hover\:from-green-300:hover {
  --gradient-from-color: #84e1bc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
}

.hover\:from-green-400:hover {
  --gradient-from-color: #31c48d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
}

.hover\:from-green-500:hover {
  --gradient-from-color: #0e9f6e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
}

.hover\:from-green-600:hover {
  --gradient-from-color: #057a55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
}

.hover\:from-green-700:hover {
  --gradient-from-color: #046c4e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
}

.hover\:from-green-800:hover {
  --gradient-from-color: #03543f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
}

.hover\:from-green-900:hover {
  --gradient-from-color: #014737;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
}

.hover\:from-teal-50:hover {
  --gradient-from-color: #edfafa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
}

.hover\:from-teal-100:hover {
  --gradient-from-color: #d5f5f6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
}

.hover\:from-teal-200:hover {
  --gradient-from-color: #afecef;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
}

.hover\:from-teal-300:hover {
  --gradient-from-color: #7edce2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
}

.hover\:from-teal-400:hover {
  --gradient-from-color: #16bdca;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
}

.hover\:from-teal-500:hover {
  --gradient-from-color: #0694a2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
}

.hover\:from-teal-600:hover {
  --gradient-from-color: #047481;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
}

.hover\:from-teal-700:hover {
  --gradient-from-color: #036672;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
}

.hover\:from-teal-800:hover {
  --gradient-from-color: #05505c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
}

.hover\:from-teal-900:hover {
  --gradient-from-color: #014451;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
}

.hover\:from-blue-50:hover {
  --gradient-from-color: #ebf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
}

.hover\:from-blue-100:hover {
  --gradient-from-color: #e1effe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
}

.hover\:from-blue-200:hover {
  --gradient-from-color: #c3ddfd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
}

.hover\:from-blue-300:hover {
  --gradient-from-color: #a4cafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
}

.hover\:from-blue-400:hover {
  --gradient-from-color: #76a9fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
}

.hover\:from-blue-500:hover {
  --gradient-from-color: #3f83f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
}

.hover\:from-blue-600:hover {
  --gradient-from-color: #1c64f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
}

.hover\:from-blue-700:hover {
  --gradient-from-color: #1a56db;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
}

.hover\:from-blue-800:hover {
  --gradient-from-color: #1e429f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
}

.hover\:from-blue-900:hover {
  --gradient-from-color: #233876;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
}

.hover\:from-indigo-50:hover {
  --gradient-from-color: #f0f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
}

.hover\:from-indigo-100:hover {
  --gradient-from-color: #e5edff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
}

.hover\:from-indigo-200:hover {
  --gradient-from-color: #cddbfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
}

.hover\:from-indigo-300:hover {
  --gradient-from-color: #b4c6fc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
}

.hover\:from-indigo-400:hover {
  --gradient-from-color: #8da2fb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
}

.hover\:from-indigo-500:hover {
  --gradient-from-color: #6875f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
}

.hover\:from-indigo-600:hover {
  --gradient-from-color: #5850ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
}

.hover\:from-indigo-700:hover {
  --gradient-from-color: #5145cd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
}

.hover\:from-indigo-800:hover {
  --gradient-from-color: #42389d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
}

.hover\:from-indigo-900:hover {
  --gradient-from-color: #362f78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
}

.hover\:from-purple-50:hover {
  --gradient-from-color: #f6f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
}

.hover\:from-purple-100:hover {
  --gradient-from-color: #edebfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
}

.hover\:from-purple-200:hover {
  --gradient-from-color: #dcd7fe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
}

.hover\:from-purple-300:hover {
  --gradient-from-color: #cabffd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
}

.hover\:from-purple-400:hover {
  --gradient-from-color: #ac94fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
}

.hover\:from-purple-500:hover {
  --gradient-from-color: #9061f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
}

.hover\:from-purple-600:hover {
  --gradient-from-color: #7e3af2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
}

.hover\:from-purple-700:hover {
  --gradient-from-color: #6c2bd9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
}

.hover\:from-purple-800:hover {
  --gradient-from-color: #5521b5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
}

.hover\:from-purple-900:hover {
  --gradient-from-color: #4a1d96;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
}

.hover\:from-pink-50:hover {
  --gradient-from-color: #fdf2f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
}

.hover\:from-pink-100:hover {
  --gradient-from-color: #fce8f3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
}

.hover\:from-pink-200:hover {
  --gradient-from-color: #fad1e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
}

.hover\:from-pink-300:hover {
  --gradient-from-color: #f8b4d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
}

.hover\:from-pink-400:hover {
  --gradient-from-color: #f17eb8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
}

.hover\:from-pink-500:hover {
  --gradient-from-color: #e74694;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
}

.hover\:from-pink-600:hover {
  --gradient-from-color: #d61f69;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
}

.hover\:from-pink-700:hover {
  --gradient-from-color: #bf125d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
}

.hover\:from-pink-800:hover {
  --gradient-from-color: #99154b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
}

.hover\:from-pink-900:hover {
  --gradient-from-color: #751a3d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
}

.hover\:via-transparent:hover {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
}

.hover\:via-white:hover {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
}

.hover\:via-black:hover {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
}

.hover\:via-gray-50:hover {
  --gradient-via-color: #f9fafb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
}

.hover\:via-gray-100:hover {
  --gradient-via-color: #f4f5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
}

.hover\:via-gray-200:hover {
  --gradient-via-color: #e5e7eb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
}

.hover\:via-gray-300:hover {
  --gradient-via-color: #d2d6dc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
}

.hover\:via-gray-400:hover {
  --gradient-via-color: #9fa6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
}

.hover\:via-gray-500:hover {
  --gradient-via-color: #6b7280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
}

.hover\:via-gray-600:hover {
  --gradient-via-color: #4b5563;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
}

.hover\:via-gray-700:hover {
  --gradient-via-color: #374151;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
}

.hover\:via-gray-800:hover {
  --gradient-via-color: #252f3f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
}

.hover\:via-gray-900:hover {
  --gradient-via-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
}

.hover\:via-cool-gray-50:hover {
  --gradient-via-color: #fbfdfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
}

.hover\:via-cool-gray-100:hover {
  --gradient-via-color: #f1f5f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
}

.hover\:via-cool-gray-200:hover {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
}

.hover\:via-cool-gray-300:hover {
  --gradient-via-color: #cfd8e3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
}

.hover\:via-cool-gray-400:hover {
  --gradient-via-color: #97a6ba;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
}

.hover\:via-cool-gray-500:hover {
  --gradient-via-color: #64748b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
}

.hover\:via-cool-gray-600:hover {
  --gradient-via-color: #475569;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
}

.hover\:via-cool-gray-700:hover {
  --gradient-via-color: #364152;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
}

.hover\:via-cool-gray-800:hover {
  --gradient-via-color: #27303f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
}

.hover\:via-cool-gray-900:hover {
  --gradient-via-color: #1a202e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
}

.hover\:via-red-50:hover {
  --gradient-via-color: #fdf2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
}

.hover\:via-red-100:hover {
  --gradient-via-color: #fde8e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
}

.hover\:via-red-200:hover {
  --gradient-via-color: #fbd5d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
}

.hover\:via-red-300:hover {
  --gradient-via-color: #f8b4b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
}

.hover\:via-red-400:hover {
  --gradient-via-color: #f98080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
}

.hover\:via-red-500:hover {
  --gradient-via-color: #f05252;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
}

.hover\:via-red-600:hover {
  --gradient-via-color: #e02424;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
}

.hover\:via-red-700:hover {
  --gradient-via-color: #c81e1e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
}

.hover\:via-red-800:hover {
  --gradient-via-color: #9b1c1c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
}

.hover\:via-red-900:hover {
  --gradient-via-color: #771d1d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
}

.hover\:via-orange-50:hover {
  --gradient-via-color: #fff8f1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
}

.hover\:via-orange-100:hover {
  --gradient-via-color: #feecdc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
}

.hover\:via-orange-200:hover {
  --gradient-via-color: #fcd9bd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
}

.hover\:via-orange-300:hover {
  --gradient-via-color: #fdba8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
}

.hover\:via-orange-400:hover {
  --gradient-via-color: #ff8a4c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
}

.hover\:via-orange-500:hover {
  --gradient-via-color: #ff5a1f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
}

.hover\:via-orange-600:hover {
  --gradient-via-color: #d03801;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
}

.hover\:via-orange-700:hover {
  --gradient-via-color: #b43403;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
}

.hover\:via-orange-800:hover {
  --gradient-via-color: #8a2c0d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
}

.hover\:via-orange-900:hover {
  --gradient-via-color: #73230d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
}

.hover\:via-yellow-50:hover {
  --gradient-via-color: #fdfdea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
}

.hover\:via-yellow-100:hover {
  --gradient-via-color: #fdf6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
}

.hover\:via-yellow-200:hover {
  --gradient-via-color: #fce96a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
}

.hover\:via-yellow-300:hover {
  --gradient-via-color: #faca15;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
}

.hover\:via-yellow-400:hover {
  --gradient-via-color: #e3a008;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
}

.hover\:via-yellow-500:hover {
  --gradient-via-color: #c27803;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
}

.hover\:via-yellow-600:hover {
  --gradient-via-color: #9f580a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
}

.hover\:via-yellow-700:hover {
  --gradient-via-color: #8e4b10;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
}

.hover\:via-yellow-800:hover {
  --gradient-via-color: #723b13;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
}

.hover\:via-yellow-900:hover {
  --gradient-via-color: #633112;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
}

.hover\:via-green-50:hover {
  --gradient-via-color: #f3faf7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
}

.hover\:via-green-100:hover {
  --gradient-via-color: #def7ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
}

.hover\:via-green-200:hover {
  --gradient-via-color: #bcf0da;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
}

.hover\:via-green-300:hover {
  --gradient-via-color: #84e1bc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
}

.hover\:via-green-400:hover {
  --gradient-via-color: #31c48d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
}

.hover\:via-green-500:hover {
  --gradient-via-color: #0e9f6e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
}

.hover\:via-green-600:hover {
  --gradient-via-color: #057a55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
}

.hover\:via-green-700:hover {
  --gradient-via-color: #046c4e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
}

.hover\:via-green-800:hover {
  --gradient-via-color: #03543f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
}

.hover\:via-green-900:hover {
  --gradient-via-color: #014737;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
}

.hover\:via-teal-50:hover {
  --gradient-via-color: #edfafa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
}

.hover\:via-teal-100:hover {
  --gradient-via-color: #d5f5f6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
}

.hover\:via-teal-200:hover {
  --gradient-via-color: #afecef;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
}

.hover\:via-teal-300:hover {
  --gradient-via-color: #7edce2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
}

.hover\:via-teal-400:hover {
  --gradient-via-color: #16bdca;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
}

.hover\:via-teal-500:hover {
  --gradient-via-color: #0694a2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
}

.hover\:via-teal-600:hover {
  --gradient-via-color: #047481;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
}

.hover\:via-teal-700:hover {
  --gradient-via-color: #036672;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
}

.hover\:via-teal-800:hover {
  --gradient-via-color: #05505c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
}

.hover\:via-teal-900:hover {
  --gradient-via-color: #014451;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
}

.hover\:via-blue-50:hover {
  --gradient-via-color: #ebf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
}

.hover\:via-blue-100:hover {
  --gradient-via-color: #e1effe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
}

.hover\:via-blue-200:hover {
  --gradient-via-color: #c3ddfd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
}

.hover\:via-blue-300:hover {
  --gradient-via-color: #a4cafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
}

.hover\:via-blue-400:hover {
  --gradient-via-color: #76a9fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
}

.hover\:via-blue-500:hover {
  --gradient-via-color: #3f83f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
}

.hover\:via-blue-600:hover {
  --gradient-via-color: #1c64f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
}

.hover\:via-blue-700:hover {
  --gradient-via-color: #1a56db;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
}

.hover\:via-blue-800:hover {
  --gradient-via-color: #1e429f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
}

.hover\:via-blue-900:hover {
  --gradient-via-color: #233876;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
}

.hover\:via-indigo-50:hover {
  --gradient-via-color: #f0f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
}

.hover\:via-indigo-100:hover {
  --gradient-via-color: #e5edff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
}

.hover\:via-indigo-200:hover {
  --gradient-via-color: #cddbfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
}

.hover\:via-indigo-300:hover {
  --gradient-via-color: #b4c6fc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
}

.hover\:via-indigo-400:hover {
  --gradient-via-color: #8da2fb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
}

.hover\:via-indigo-500:hover {
  --gradient-via-color: #6875f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
}

.hover\:via-indigo-600:hover {
  --gradient-via-color: #5850ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
}

.hover\:via-indigo-700:hover {
  --gradient-via-color: #5145cd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
}

.hover\:via-indigo-800:hover {
  --gradient-via-color: #42389d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
}

.hover\:via-indigo-900:hover {
  --gradient-via-color: #362f78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
}

.hover\:via-purple-50:hover {
  --gradient-via-color: #f6f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
}

.hover\:via-purple-100:hover {
  --gradient-via-color: #edebfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
}

.hover\:via-purple-200:hover {
  --gradient-via-color: #dcd7fe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
}

.hover\:via-purple-300:hover {
  --gradient-via-color: #cabffd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
}

.hover\:via-purple-400:hover {
  --gradient-via-color: #ac94fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
}

.hover\:via-purple-500:hover {
  --gradient-via-color: #9061f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
}

.hover\:via-purple-600:hover {
  --gradient-via-color: #7e3af2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
}

.hover\:via-purple-700:hover {
  --gradient-via-color: #6c2bd9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
}

.hover\:via-purple-800:hover {
  --gradient-via-color: #5521b5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
}

.hover\:via-purple-900:hover {
  --gradient-via-color: #4a1d96;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
}

.hover\:via-pink-50:hover {
  --gradient-via-color: #fdf2f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
}

.hover\:via-pink-100:hover {
  --gradient-via-color: #fce8f3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
}

.hover\:via-pink-200:hover {
  --gradient-via-color: #fad1e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
}

.hover\:via-pink-300:hover {
  --gradient-via-color: #f8b4d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
}

.hover\:via-pink-400:hover {
  --gradient-via-color: #f17eb8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
}

.hover\:via-pink-500:hover {
  --gradient-via-color: #e74694;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
}

.hover\:via-pink-600:hover {
  --gradient-via-color: #d61f69;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
}

.hover\:via-pink-700:hover {
  --gradient-via-color: #bf125d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
}

.hover\:via-pink-800:hover {
  --gradient-via-color: #99154b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
}

.hover\:via-pink-900:hover {
  --gradient-via-color: #751a3d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
}

.hover\:to-transparent:hover {
  --gradient-to-color: transparent;
}

.hover\:to-white:hover {
  --gradient-to-color: #fff;
}

.hover\:to-black:hover {
  --gradient-to-color: #000;
}

.hover\:to-gray-50:hover {
  --gradient-to-color: #f9fafb;
}

.hover\:to-gray-100:hover {
  --gradient-to-color: #f4f5f7;
}

.hover\:to-gray-200:hover {
  --gradient-to-color: #e5e7eb;
}

.hover\:to-gray-300:hover {
  --gradient-to-color: #d2d6dc;
}

.hover\:to-gray-400:hover {
  --gradient-to-color: #9fa6b2;
}

.hover\:to-gray-500:hover {
  --gradient-to-color: #6b7280;
}

.hover\:to-gray-600:hover {
  --gradient-to-color: #4b5563;
}

.hover\:to-gray-700:hover {
  --gradient-to-color: #374151;
}

.hover\:to-gray-800:hover {
  --gradient-to-color: #252f3f;
}

.hover\:to-gray-900:hover {
  --gradient-to-color: #161e2e;
}

.hover\:to-cool-gray-50:hover {
  --gradient-to-color: #fbfdfe;
}

.hover\:to-cool-gray-100:hover {
  --gradient-to-color: #f1f5f9;
}

.hover\:to-cool-gray-200:hover {
  --gradient-to-color: #e2e8f0;
}

.hover\:to-cool-gray-300:hover {
  --gradient-to-color: #cfd8e3;
}

.hover\:to-cool-gray-400:hover {
  --gradient-to-color: #97a6ba;
}

.hover\:to-cool-gray-500:hover {
  --gradient-to-color: #64748b;
}

.hover\:to-cool-gray-600:hover {
  --gradient-to-color: #475569;
}

.hover\:to-cool-gray-700:hover {
  --gradient-to-color: #364152;
}

.hover\:to-cool-gray-800:hover {
  --gradient-to-color: #27303f;
}

.hover\:to-cool-gray-900:hover {
  --gradient-to-color: #1a202e;
}

.hover\:to-red-50:hover {
  --gradient-to-color: #fdf2f2;
}

.hover\:to-red-100:hover {
  --gradient-to-color: #fde8e8;
}

.hover\:to-red-200:hover {
  --gradient-to-color: #fbd5d5;
}

.hover\:to-red-300:hover {
  --gradient-to-color: #f8b4b4;
}

.hover\:to-red-400:hover {
  --gradient-to-color: #f98080;
}

.hover\:to-red-500:hover {
  --gradient-to-color: #f05252;
}

.hover\:to-red-600:hover {
  --gradient-to-color: #e02424;
}

.hover\:to-red-700:hover {
  --gradient-to-color: #c81e1e;
}

.hover\:to-red-800:hover {
  --gradient-to-color: #9b1c1c;
}

.hover\:to-red-900:hover {
  --gradient-to-color: #771d1d;
}

.hover\:to-orange-50:hover {
  --gradient-to-color: #fff8f1;
}

.hover\:to-orange-100:hover {
  --gradient-to-color: #feecdc;
}

.hover\:to-orange-200:hover {
  --gradient-to-color: #fcd9bd;
}

.hover\:to-orange-300:hover {
  --gradient-to-color: #fdba8c;
}

.hover\:to-orange-400:hover {
  --gradient-to-color: #ff8a4c;
}

.hover\:to-orange-500:hover {
  --gradient-to-color: #ff5a1f;
}

.hover\:to-orange-600:hover {
  --gradient-to-color: #d03801;
}

.hover\:to-orange-700:hover {
  --gradient-to-color: #b43403;
}

.hover\:to-orange-800:hover {
  --gradient-to-color: #8a2c0d;
}

.hover\:to-orange-900:hover {
  --gradient-to-color: #73230d;
}

.hover\:to-yellow-50:hover {
  --gradient-to-color: #fdfdea;
}

.hover\:to-yellow-100:hover {
  --gradient-to-color: #fdf6b2;
}

.hover\:to-yellow-200:hover {
  --gradient-to-color: #fce96a;
}

.hover\:to-yellow-300:hover {
  --gradient-to-color: #faca15;
}

.hover\:to-yellow-400:hover {
  --gradient-to-color: #e3a008;
}

.hover\:to-yellow-500:hover {
  --gradient-to-color: #c27803;
}

.hover\:to-yellow-600:hover {
  --gradient-to-color: #9f580a;
}

.hover\:to-yellow-700:hover {
  --gradient-to-color: #8e4b10;
}

.hover\:to-yellow-800:hover {
  --gradient-to-color: #723b13;
}

.hover\:to-yellow-900:hover {
  --gradient-to-color: #633112;
}

.hover\:to-green-50:hover {
  --gradient-to-color: #f3faf7;
}

.hover\:to-green-100:hover {
  --gradient-to-color: #def7ec;
}

.hover\:to-green-200:hover {
  --gradient-to-color: #bcf0da;
}

.hover\:to-green-300:hover {
  --gradient-to-color: #84e1bc;
}

.hover\:to-green-400:hover {
  --gradient-to-color: #31c48d;
}

.hover\:to-green-500:hover {
  --gradient-to-color: #0e9f6e;
}

.hover\:to-green-600:hover {
  --gradient-to-color: #057a55;
}

.hover\:to-green-700:hover {
  --gradient-to-color: #046c4e;
}

.hover\:to-green-800:hover {
  --gradient-to-color: #03543f;
}

.hover\:to-green-900:hover {
  --gradient-to-color: #014737;
}

.hover\:to-teal-50:hover {
  --gradient-to-color: #edfafa;
}

.hover\:to-teal-100:hover {
  --gradient-to-color: #d5f5f6;
}

.hover\:to-teal-200:hover {
  --gradient-to-color: #afecef;
}

.hover\:to-teal-300:hover {
  --gradient-to-color: #7edce2;
}

.hover\:to-teal-400:hover {
  --gradient-to-color: #16bdca;
}

.hover\:to-teal-500:hover {
  --gradient-to-color: #0694a2;
}

.hover\:to-teal-600:hover {
  --gradient-to-color: #047481;
}

.hover\:to-teal-700:hover {
  --gradient-to-color: #036672;
}

.hover\:to-teal-800:hover {
  --gradient-to-color: #05505c;
}

.hover\:to-teal-900:hover {
  --gradient-to-color: #014451;
}

.hover\:to-blue-50:hover {
  --gradient-to-color: #ebf5ff;
}

.hover\:to-blue-100:hover {
  --gradient-to-color: #e1effe;
}

.hover\:to-blue-200:hover {
  --gradient-to-color: #c3ddfd;
}

.hover\:to-blue-300:hover {
  --gradient-to-color: #a4cafe;
}

.hover\:to-blue-400:hover {
  --gradient-to-color: #76a9fa;
}

.hover\:to-blue-500:hover {
  --gradient-to-color: #3f83f8;
}

.hover\:to-blue-600:hover {
  --gradient-to-color: #1c64f2;
}

.hover\:to-blue-700:hover {
  --gradient-to-color: #1a56db;
}

.hover\:to-blue-800:hover {
  --gradient-to-color: #1e429f;
}

.hover\:to-blue-900:hover {
  --gradient-to-color: #233876;
}

.hover\:to-indigo-50:hover {
  --gradient-to-color: #f0f5ff;
}

.hover\:to-indigo-100:hover {
  --gradient-to-color: #e5edff;
}

.hover\:to-indigo-200:hover {
  --gradient-to-color: #cddbfe;
}

.hover\:to-indigo-300:hover {
  --gradient-to-color: #b4c6fc;
}

.hover\:to-indigo-400:hover {
  --gradient-to-color: #8da2fb;
}

.hover\:to-indigo-500:hover {
  --gradient-to-color: #6875f5;
}

.hover\:to-indigo-600:hover {
  --gradient-to-color: #5850ec;
}

.hover\:to-indigo-700:hover {
  --gradient-to-color: #5145cd;
}

.hover\:to-indigo-800:hover {
  --gradient-to-color: #42389d;
}

.hover\:to-indigo-900:hover {
  --gradient-to-color: #362f78;
}

.hover\:to-purple-50:hover {
  --gradient-to-color: #f6f5ff;
}

.hover\:to-purple-100:hover {
  --gradient-to-color: #edebfe;
}

.hover\:to-purple-200:hover {
  --gradient-to-color: #dcd7fe;
}

.hover\:to-purple-300:hover {
  --gradient-to-color: #cabffd;
}

.hover\:to-purple-400:hover {
  --gradient-to-color: #ac94fa;
}

.hover\:to-purple-500:hover {
  --gradient-to-color: #9061f9;
}

.hover\:to-purple-600:hover {
  --gradient-to-color: #7e3af2;
}

.hover\:to-purple-700:hover {
  --gradient-to-color: #6c2bd9;
}

.hover\:to-purple-800:hover {
  --gradient-to-color: #5521b5;
}

.hover\:to-purple-900:hover {
  --gradient-to-color: #4a1d96;
}

.hover\:to-pink-50:hover {
  --gradient-to-color: #fdf2f8;
}

.hover\:to-pink-100:hover {
  --gradient-to-color: #fce8f3;
}

.hover\:to-pink-200:hover {
  --gradient-to-color: #fad1e8;
}

.hover\:to-pink-300:hover {
  --gradient-to-color: #f8b4d9;
}

.hover\:to-pink-400:hover {
  --gradient-to-color: #f17eb8;
}

.hover\:to-pink-500:hover {
  --gradient-to-color: #e74694;
}

.hover\:to-pink-600:hover {
  --gradient-to-color: #d61f69;
}

.hover\:to-pink-700:hover {
  --gradient-to-color: #bf125d;
}

.hover\:to-pink-800:hover {
  --gradient-to-color: #99154b;
}

.hover\:to-pink-900:hover {
  --gradient-to-color: #751a3d;
}

.focus\:from-transparent:focus {
  --gradient-from-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
}

.focus\:from-white:focus {
  --gradient-from-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
}

.focus\:from-black:focus {
  --gradient-from-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
}

.focus\:from-gray-50:focus {
  --gradient-from-color: #f9fafb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
}

.focus\:from-gray-100:focus {
  --gradient-from-color: #f4f5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
}

.focus\:from-gray-200:focus {
  --gradient-from-color: #e5e7eb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
}

.focus\:from-gray-300:focus {
  --gradient-from-color: #d2d6dc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
}

.focus\:from-gray-400:focus {
  --gradient-from-color: #9fa6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
}

.focus\:from-gray-500:focus {
  --gradient-from-color: #6b7280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
}

.focus\:from-gray-600:focus {
  --gradient-from-color: #4b5563;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
}

.focus\:from-gray-700:focus {
  --gradient-from-color: #374151;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
}

.focus\:from-gray-800:focus {
  --gradient-from-color: #252f3f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
}

.focus\:from-gray-900:focus {
  --gradient-from-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
}

.focus\:from-cool-gray-50:focus {
  --gradient-from-color: #fbfdfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
}

.focus\:from-cool-gray-100:focus {
  --gradient-from-color: #f1f5f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
}

.focus\:from-cool-gray-200:focus {
  --gradient-from-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
}

.focus\:from-cool-gray-300:focus {
  --gradient-from-color: #cfd8e3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
}

.focus\:from-cool-gray-400:focus {
  --gradient-from-color: #97a6ba;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
}

.focus\:from-cool-gray-500:focus {
  --gradient-from-color: #64748b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
}

.focus\:from-cool-gray-600:focus {
  --gradient-from-color: #475569;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
}

.focus\:from-cool-gray-700:focus {
  --gradient-from-color: #364152;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
}

.focus\:from-cool-gray-800:focus {
  --gradient-from-color: #27303f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
}

.focus\:from-cool-gray-900:focus {
  --gradient-from-color: #1a202e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
}

.focus\:from-red-50:focus {
  --gradient-from-color: #fdf2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
}

.focus\:from-red-100:focus {
  --gradient-from-color: #fde8e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
}

.focus\:from-red-200:focus {
  --gradient-from-color: #fbd5d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
}

.focus\:from-red-300:focus {
  --gradient-from-color: #f8b4b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
}

.focus\:from-red-400:focus {
  --gradient-from-color: #f98080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
}

.focus\:from-red-500:focus {
  --gradient-from-color: #f05252;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
}

.focus\:from-red-600:focus {
  --gradient-from-color: #e02424;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
}

.focus\:from-red-700:focus {
  --gradient-from-color: #c81e1e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
}

.focus\:from-red-800:focus {
  --gradient-from-color: #9b1c1c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
}

.focus\:from-red-900:focus {
  --gradient-from-color: #771d1d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
}

.focus\:from-orange-50:focus {
  --gradient-from-color: #fff8f1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
}

.focus\:from-orange-100:focus {
  --gradient-from-color: #feecdc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
}

.focus\:from-orange-200:focus {
  --gradient-from-color: #fcd9bd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
}

.focus\:from-orange-300:focus {
  --gradient-from-color: #fdba8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
}

.focus\:from-orange-400:focus {
  --gradient-from-color: #ff8a4c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
}

.focus\:from-orange-500:focus {
  --gradient-from-color: #ff5a1f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
}

.focus\:from-orange-600:focus {
  --gradient-from-color: #d03801;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
}

.focus\:from-orange-700:focus {
  --gradient-from-color: #b43403;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
}

.focus\:from-orange-800:focus {
  --gradient-from-color: #8a2c0d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
}

.focus\:from-orange-900:focus {
  --gradient-from-color: #73230d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
}

.focus\:from-yellow-50:focus {
  --gradient-from-color: #fdfdea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
}

.focus\:from-yellow-100:focus {
  --gradient-from-color: #fdf6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
}

.focus\:from-yellow-200:focus {
  --gradient-from-color: #fce96a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
}

.focus\:from-yellow-300:focus {
  --gradient-from-color: #faca15;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
}

.focus\:from-yellow-400:focus {
  --gradient-from-color: #e3a008;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
}

.focus\:from-yellow-500:focus {
  --gradient-from-color: #c27803;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
}

.focus\:from-yellow-600:focus {
  --gradient-from-color: #9f580a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
}

.focus\:from-yellow-700:focus {
  --gradient-from-color: #8e4b10;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
}

.focus\:from-yellow-800:focus {
  --gradient-from-color: #723b13;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
}

.focus\:from-yellow-900:focus {
  --gradient-from-color: #633112;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
}

.focus\:from-green-50:focus {
  --gradient-from-color: #f3faf7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
}

.focus\:from-green-100:focus {
  --gradient-from-color: #def7ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
}

.focus\:from-green-200:focus {
  --gradient-from-color: #bcf0da;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
}

.focus\:from-green-300:focus {
  --gradient-from-color: #84e1bc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
}

.focus\:from-green-400:focus {
  --gradient-from-color: #31c48d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
}

.focus\:from-green-500:focus {
  --gradient-from-color: #0e9f6e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
}

.focus\:from-green-600:focus {
  --gradient-from-color: #057a55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
}

.focus\:from-green-700:focus {
  --gradient-from-color: #046c4e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
}

.focus\:from-green-800:focus {
  --gradient-from-color: #03543f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
}

.focus\:from-green-900:focus {
  --gradient-from-color: #014737;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
}

.focus\:from-teal-50:focus {
  --gradient-from-color: #edfafa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
}

.focus\:from-teal-100:focus {
  --gradient-from-color: #d5f5f6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
}

.focus\:from-teal-200:focus {
  --gradient-from-color: #afecef;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
}

.focus\:from-teal-300:focus {
  --gradient-from-color: #7edce2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
}

.focus\:from-teal-400:focus {
  --gradient-from-color: #16bdca;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
}

.focus\:from-teal-500:focus {
  --gradient-from-color: #0694a2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
}

.focus\:from-teal-600:focus {
  --gradient-from-color: #047481;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
}

.focus\:from-teal-700:focus {
  --gradient-from-color: #036672;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
}

.focus\:from-teal-800:focus {
  --gradient-from-color: #05505c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
}

.focus\:from-teal-900:focus {
  --gradient-from-color: #014451;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
}

.focus\:from-blue-50:focus {
  --gradient-from-color: #ebf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
}

.focus\:from-blue-100:focus {
  --gradient-from-color: #e1effe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
}

.focus\:from-blue-200:focus {
  --gradient-from-color: #c3ddfd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
}

.focus\:from-blue-300:focus {
  --gradient-from-color: #a4cafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
}

.focus\:from-blue-400:focus {
  --gradient-from-color: #76a9fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
}

.focus\:from-blue-500:focus {
  --gradient-from-color: #3f83f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
}

.focus\:from-blue-600:focus {
  --gradient-from-color: #1c64f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
}

.focus\:from-blue-700:focus {
  --gradient-from-color: #1a56db;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
}

.focus\:from-blue-800:focus {
  --gradient-from-color: #1e429f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
}

.focus\:from-blue-900:focus {
  --gradient-from-color: #233876;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
}

.focus\:from-indigo-50:focus {
  --gradient-from-color: #f0f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
}

.focus\:from-indigo-100:focus {
  --gradient-from-color: #e5edff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
}

.focus\:from-indigo-200:focus {
  --gradient-from-color: #cddbfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
}

.focus\:from-indigo-300:focus {
  --gradient-from-color: #b4c6fc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
}

.focus\:from-indigo-400:focus {
  --gradient-from-color: #8da2fb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
}

.focus\:from-indigo-500:focus {
  --gradient-from-color: #6875f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
}

.focus\:from-indigo-600:focus {
  --gradient-from-color: #5850ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
}

.focus\:from-indigo-700:focus {
  --gradient-from-color: #5145cd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
}

.focus\:from-indigo-800:focus {
  --gradient-from-color: #42389d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
}

.focus\:from-indigo-900:focus {
  --gradient-from-color: #362f78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
}

.focus\:from-purple-50:focus {
  --gradient-from-color: #f6f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
}

.focus\:from-purple-100:focus {
  --gradient-from-color: #edebfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
}

.focus\:from-purple-200:focus {
  --gradient-from-color: #dcd7fe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
}

.focus\:from-purple-300:focus {
  --gradient-from-color: #cabffd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
}

.focus\:from-purple-400:focus {
  --gradient-from-color: #ac94fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
}

.focus\:from-purple-500:focus {
  --gradient-from-color: #9061f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
}

.focus\:from-purple-600:focus {
  --gradient-from-color: #7e3af2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
}

.focus\:from-purple-700:focus {
  --gradient-from-color: #6c2bd9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
}

.focus\:from-purple-800:focus {
  --gradient-from-color: #5521b5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
}

.focus\:from-purple-900:focus {
  --gradient-from-color: #4a1d96;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
}

.focus\:from-pink-50:focus {
  --gradient-from-color: #fdf2f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
}

.focus\:from-pink-100:focus {
  --gradient-from-color: #fce8f3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
}

.focus\:from-pink-200:focus {
  --gradient-from-color: #fad1e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
}

.focus\:from-pink-300:focus {
  --gradient-from-color: #f8b4d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
}

.focus\:from-pink-400:focus {
  --gradient-from-color: #f17eb8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
}

.focus\:from-pink-500:focus {
  --gradient-from-color: #e74694;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
}

.focus\:from-pink-600:focus {
  --gradient-from-color: #d61f69;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
}

.focus\:from-pink-700:focus {
  --gradient-from-color: #bf125d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
}

.focus\:from-pink-800:focus {
  --gradient-from-color: #99154b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
}

.focus\:from-pink-900:focus {
  --gradient-from-color: #751a3d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
}

.focus\:via-transparent:focus {
  --gradient-via-color: transparent;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
}

.focus\:via-white:focus {
  --gradient-via-color: #fff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
}

.focus\:via-black:focus {
  --gradient-via-color: #000;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
}

.focus\:via-gray-50:focus {
  --gradient-via-color: #f9fafb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
}

.focus\:via-gray-100:focus {
  --gradient-via-color: #f4f5f7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
}

.focus\:via-gray-200:focus {
  --gradient-via-color: #e5e7eb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
}

.focus\:via-gray-300:focus {
  --gradient-via-color: #d2d6dc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
}

.focus\:via-gray-400:focus {
  --gradient-via-color: #9fa6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
}

.focus\:via-gray-500:focus {
  --gradient-via-color: #6b7280;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
}

.focus\:via-gray-600:focus {
  --gradient-via-color: #4b5563;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
}

.focus\:via-gray-700:focus {
  --gradient-via-color: #374151;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
}

.focus\:via-gray-800:focus {
  --gradient-via-color: #252f3f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
}

.focus\:via-gray-900:focus {
  --gradient-via-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
}

.focus\:via-cool-gray-50:focus {
  --gradient-via-color: #fbfdfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
}

.focus\:via-cool-gray-100:focus {
  --gradient-via-color: #f1f5f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
}

.focus\:via-cool-gray-200:focus {
  --gradient-via-color: #e2e8f0;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
}

.focus\:via-cool-gray-300:focus {
  --gradient-via-color: #cfd8e3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
}

.focus\:via-cool-gray-400:focus {
  --gradient-via-color: #97a6ba;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
}

.focus\:via-cool-gray-500:focus {
  --gradient-via-color: #64748b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
}

.focus\:via-cool-gray-600:focus {
  --gradient-via-color: #475569;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
}

.focus\:via-cool-gray-700:focus {
  --gradient-via-color: #364152;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
}

.focus\:via-cool-gray-800:focus {
  --gradient-via-color: #27303f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
}

.focus\:via-cool-gray-900:focus {
  --gradient-via-color: #1a202e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
}

.focus\:via-red-50:focus {
  --gradient-via-color: #fdf2f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
}

.focus\:via-red-100:focus {
  --gradient-via-color: #fde8e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
}

.focus\:via-red-200:focus {
  --gradient-via-color: #fbd5d5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
}

.focus\:via-red-300:focus {
  --gradient-via-color: #f8b4b4;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
}

.focus\:via-red-400:focus {
  --gradient-via-color: #f98080;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
}

.focus\:via-red-500:focus {
  --gradient-via-color: #f05252;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
}

.focus\:via-red-600:focus {
  --gradient-via-color: #e02424;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
}

.focus\:via-red-700:focus {
  --gradient-via-color: #c81e1e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
}

.focus\:via-red-800:focus {
  --gradient-via-color: #9b1c1c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
}

.focus\:via-red-900:focus {
  --gradient-via-color: #771d1d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
}

.focus\:via-orange-50:focus {
  --gradient-via-color: #fff8f1;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
}

.focus\:via-orange-100:focus {
  --gradient-via-color: #feecdc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
}

.focus\:via-orange-200:focus {
  --gradient-via-color: #fcd9bd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
}

.focus\:via-orange-300:focus {
  --gradient-via-color: #fdba8c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
}

.focus\:via-orange-400:focus {
  --gradient-via-color: #ff8a4c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
}

.focus\:via-orange-500:focus {
  --gradient-via-color: #ff5a1f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
}

.focus\:via-orange-600:focus {
  --gradient-via-color: #d03801;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
}

.focus\:via-orange-700:focus {
  --gradient-via-color: #b43403;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
}

.focus\:via-orange-800:focus {
  --gradient-via-color: #8a2c0d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
}

.focus\:via-orange-900:focus {
  --gradient-via-color: #73230d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
}

.focus\:via-yellow-50:focus {
  --gradient-via-color: #fdfdea;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
}

.focus\:via-yellow-100:focus {
  --gradient-via-color: #fdf6b2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
}

.focus\:via-yellow-200:focus {
  --gradient-via-color: #fce96a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
}

.focus\:via-yellow-300:focus {
  --gradient-via-color: #faca15;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
}

.focus\:via-yellow-400:focus {
  --gradient-via-color: #e3a008;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
}

.focus\:via-yellow-500:focus {
  --gradient-via-color: #c27803;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
}

.focus\:via-yellow-600:focus {
  --gradient-via-color: #9f580a;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
}

.focus\:via-yellow-700:focus {
  --gradient-via-color: #8e4b10;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
}

.focus\:via-yellow-800:focus {
  --gradient-via-color: #723b13;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
}

.focus\:via-yellow-900:focus {
  --gradient-via-color: #633112;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
}

.focus\:via-green-50:focus {
  --gradient-via-color: #f3faf7;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
}

.focus\:via-green-100:focus {
  --gradient-via-color: #def7ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
}

.focus\:via-green-200:focus {
  --gradient-via-color: #bcf0da;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
}

.focus\:via-green-300:focus {
  --gradient-via-color: #84e1bc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
}

.focus\:via-green-400:focus {
  --gradient-via-color: #31c48d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
}

.focus\:via-green-500:focus {
  --gradient-via-color: #0e9f6e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
}

.focus\:via-green-600:focus {
  --gradient-via-color: #057a55;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
}

.focus\:via-green-700:focus {
  --gradient-via-color: #046c4e;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
}

.focus\:via-green-800:focus {
  --gradient-via-color: #03543f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
}

.focus\:via-green-900:focus {
  --gradient-via-color: #014737;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
}

.focus\:via-teal-50:focus {
  --gradient-via-color: #edfafa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
}

.focus\:via-teal-100:focus {
  --gradient-via-color: #d5f5f6;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
}

.focus\:via-teal-200:focus {
  --gradient-via-color: #afecef;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
}

.focus\:via-teal-300:focus {
  --gradient-via-color: #7edce2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
}

.focus\:via-teal-400:focus {
  --gradient-via-color: #16bdca;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
}

.focus\:via-teal-500:focus {
  --gradient-via-color: #0694a2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
}

.focus\:via-teal-600:focus {
  --gradient-via-color: #047481;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
}

.focus\:via-teal-700:focus {
  --gradient-via-color: #036672;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
}

.focus\:via-teal-800:focus {
  --gradient-via-color: #05505c;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
}

.focus\:via-teal-900:focus {
  --gradient-via-color: #014451;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
}

.focus\:via-blue-50:focus {
  --gradient-via-color: #ebf5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
}

.focus\:via-blue-100:focus {
  --gradient-via-color: #e1effe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
}

.focus\:via-blue-200:focus {
  --gradient-via-color: #c3ddfd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
}

.focus\:via-blue-300:focus {
  --gradient-via-color: #a4cafe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
}

.focus\:via-blue-400:focus {
  --gradient-via-color: #76a9fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
}

.focus\:via-blue-500:focus {
  --gradient-via-color: #3f83f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
}

.focus\:via-blue-600:focus {
  --gradient-via-color: #1c64f2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
}

.focus\:via-blue-700:focus {
  --gradient-via-color: #1a56db;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
}

.focus\:via-blue-800:focus {
  --gradient-via-color: #1e429f;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
}

.focus\:via-blue-900:focus {
  --gradient-via-color: #233876;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
}

.focus\:via-indigo-50:focus {
  --gradient-via-color: #f0f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
}

.focus\:via-indigo-100:focus {
  --gradient-via-color: #e5edff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
}

.focus\:via-indigo-200:focus {
  --gradient-via-color: #cddbfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
}

.focus\:via-indigo-300:focus {
  --gradient-via-color: #b4c6fc;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
}

.focus\:via-indigo-400:focus {
  --gradient-via-color: #8da2fb;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
}

.focus\:via-indigo-500:focus {
  --gradient-via-color: #6875f5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
}

.focus\:via-indigo-600:focus {
  --gradient-via-color: #5850ec;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
}

.focus\:via-indigo-700:focus {
  --gradient-via-color: #5145cd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
}

.focus\:via-indigo-800:focus {
  --gradient-via-color: #42389d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
}

.focus\:via-indigo-900:focus {
  --gradient-via-color: #362f78;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
}

.focus\:via-purple-50:focus {
  --gradient-via-color: #f6f5ff;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
}

.focus\:via-purple-100:focus {
  --gradient-via-color: #edebfe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
}

.focus\:via-purple-200:focus {
  --gradient-via-color: #dcd7fe;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
}

.focus\:via-purple-300:focus {
  --gradient-via-color: #cabffd;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
}

.focus\:via-purple-400:focus {
  --gradient-via-color: #ac94fa;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
}

.focus\:via-purple-500:focus {
  --gradient-via-color: #9061f9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
}

.focus\:via-purple-600:focus {
  --gradient-via-color: #7e3af2;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
}

.focus\:via-purple-700:focus {
  --gradient-via-color: #6c2bd9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
}

.focus\:via-purple-800:focus {
  --gradient-via-color: #5521b5;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
}

.focus\:via-purple-900:focus {
  --gradient-via-color: #4a1d96;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
}

.focus\:via-pink-50:focus {
  --gradient-via-color: #fdf2f8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
}

.focus\:via-pink-100:focus {
  --gradient-via-color: #fce8f3;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
}

.focus\:via-pink-200:focus {
  --gradient-via-color: #fad1e8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
}

.focus\:via-pink-300:focus {
  --gradient-via-color: #f8b4d9;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
}

.focus\:via-pink-400:focus {
  --gradient-via-color: #f17eb8;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
}

.focus\:via-pink-500:focus {
  --gradient-via-color: #e74694;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
}

.focus\:via-pink-600:focus {
  --gradient-via-color: #d61f69;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
}

.focus\:via-pink-700:focus {
  --gradient-via-color: #bf125d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
}

.focus\:via-pink-800:focus {
  --gradient-via-color: #99154b;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
}

.focus\:via-pink-900:focus {
  --gradient-via-color: #751a3d;
  --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
}

.focus\:to-transparent:focus {
  --gradient-to-color: transparent;
}

.focus\:to-white:focus {
  --gradient-to-color: #fff;
}

.focus\:to-black:focus {
  --gradient-to-color: #000;
}

.focus\:to-gray-50:focus {
  --gradient-to-color: #f9fafb;
}

.focus\:to-gray-100:focus {
  --gradient-to-color: #f4f5f7;
}

.focus\:to-gray-200:focus {
  --gradient-to-color: #e5e7eb;
}

.focus\:to-gray-300:focus {
  --gradient-to-color: #d2d6dc;
}

.focus\:to-gray-400:focus {
  --gradient-to-color: #9fa6b2;
}

.focus\:to-gray-500:focus {
  --gradient-to-color: #6b7280;
}

.focus\:to-gray-600:focus {
  --gradient-to-color: #4b5563;
}

.focus\:to-gray-700:focus {
  --gradient-to-color: #374151;
}

.focus\:to-gray-800:focus {
  --gradient-to-color: #252f3f;
}

.focus\:to-gray-900:focus {
  --gradient-to-color: #161e2e;
}

.focus\:to-cool-gray-50:focus {
  --gradient-to-color: #fbfdfe;
}

.focus\:to-cool-gray-100:focus {
  --gradient-to-color: #f1f5f9;
}

.focus\:to-cool-gray-200:focus {
  --gradient-to-color: #e2e8f0;
}

.focus\:to-cool-gray-300:focus {
  --gradient-to-color: #cfd8e3;
}

.focus\:to-cool-gray-400:focus {
  --gradient-to-color: #97a6ba;
}

.focus\:to-cool-gray-500:focus {
  --gradient-to-color: #64748b;
}

.focus\:to-cool-gray-600:focus {
  --gradient-to-color: #475569;
}

.focus\:to-cool-gray-700:focus {
  --gradient-to-color: #364152;
}

.focus\:to-cool-gray-800:focus {
  --gradient-to-color: #27303f;
}

.focus\:to-cool-gray-900:focus {
  --gradient-to-color: #1a202e;
}

.focus\:to-red-50:focus {
  --gradient-to-color: #fdf2f2;
}

.focus\:to-red-100:focus {
  --gradient-to-color: #fde8e8;
}

.focus\:to-red-200:focus {
  --gradient-to-color: #fbd5d5;
}

.focus\:to-red-300:focus {
  --gradient-to-color: #f8b4b4;
}

.focus\:to-red-400:focus {
  --gradient-to-color: #f98080;
}

.focus\:to-red-500:focus {
  --gradient-to-color: #f05252;
}

.focus\:to-red-600:focus {
  --gradient-to-color: #e02424;
}

.focus\:to-red-700:focus {
  --gradient-to-color: #c81e1e;
}

.focus\:to-red-800:focus {
  --gradient-to-color: #9b1c1c;
}

.focus\:to-red-900:focus {
  --gradient-to-color: #771d1d;
}

.focus\:to-orange-50:focus {
  --gradient-to-color: #fff8f1;
}

.focus\:to-orange-100:focus {
  --gradient-to-color: #feecdc;
}

.focus\:to-orange-200:focus {
  --gradient-to-color: #fcd9bd;
}

.focus\:to-orange-300:focus {
  --gradient-to-color: #fdba8c;
}

.focus\:to-orange-400:focus {
  --gradient-to-color: #ff8a4c;
}

.focus\:to-orange-500:focus {
  --gradient-to-color: #ff5a1f;
}

.focus\:to-orange-600:focus {
  --gradient-to-color: #d03801;
}

.focus\:to-orange-700:focus {
  --gradient-to-color: #b43403;
}

.focus\:to-orange-800:focus {
  --gradient-to-color: #8a2c0d;
}

.focus\:to-orange-900:focus {
  --gradient-to-color: #73230d;
}

.focus\:to-yellow-50:focus {
  --gradient-to-color: #fdfdea;
}

.focus\:to-yellow-100:focus {
  --gradient-to-color: #fdf6b2;
}

.focus\:to-yellow-200:focus {
  --gradient-to-color: #fce96a;
}

.focus\:to-yellow-300:focus {
  --gradient-to-color: #faca15;
}

.focus\:to-yellow-400:focus {
  --gradient-to-color: #e3a008;
}

.focus\:to-yellow-500:focus {
  --gradient-to-color: #c27803;
}

.focus\:to-yellow-600:focus {
  --gradient-to-color: #9f580a;
}

.focus\:to-yellow-700:focus {
  --gradient-to-color: #8e4b10;
}

.focus\:to-yellow-800:focus {
  --gradient-to-color: #723b13;
}

.focus\:to-yellow-900:focus {
  --gradient-to-color: #633112;
}

.focus\:to-green-50:focus {
  --gradient-to-color: #f3faf7;
}

.focus\:to-green-100:focus {
  --gradient-to-color: #def7ec;
}

.focus\:to-green-200:focus {
  --gradient-to-color: #bcf0da;
}

.focus\:to-green-300:focus {
  --gradient-to-color: #84e1bc;
}

.focus\:to-green-400:focus {
  --gradient-to-color: #31c48d;
}

.focus\:to-green-500:focus {
  --gradient-to-color: #0e9f6e;
}

.focus\:to-green-600:focus {
  --gradient-to-color: #057a55;
}

.focus\:to-green-700:focus {
  --gradient-to-color: #046c4e;
}

.focus\:to-green-800:focus {
  --gradient-to-color: #03543f;
}

.focus\:to-green-900:focus {
  --gradient-to-color: #014737;
}

.focus\:to-teal-50:focus {
  --gradient-to-color: #edfafa;
}

.focus\:to-teal-100:focus {
  --gradient-to-color: #d5f5f6;
}

.focus\:to-teal-200:focus {
  --gradient-to-color: #afecef;
}

.focus\:to-teal-300:focus {
  --gradient-to-color: #7edce2;
}

.focus\:to-teal-400:focus {
  --gradient-to-color: #16bdca;
}

.focus\:to-teal-500:focus {
  --gradient-to-color: #0694a2;
}

.focus\:to-teal-600:focus {
  --gradient-to-color: #047481;
}

.focus\:to-teal-700:focus {
  --gradient-to-color: #036672;
}

.focus\:to-teal-800:focus {
  --gradient-to-color: #05505c;
}

.focus\:to-teal-900:focus {
  --gradient-to-color: #014451;
}

.focus\:to-blue-50:focus {
  --gradient-to-color: #ebf5ff;
}

.focus\:to-blue-100:focus {
  --gradient-to-color: #e1effe;
}

.focus\:to-blue-200:focus {
  --gradient-to-color: #c3ddfd;
}

.focus\:to-blue-300:focus {
  --gradient-to-color: #a4cafe;
}

.focus\:to-blue-400:focus {
  --gradient-to-color: #76a9fa;
}

.focus\:to-blue-500:focus {
  --gradient-to-color: #3f83f8;
}

.focus\:to-blue-600:focus {
  --gradient-to-color: #1c64f2;
}

.focus\:to-blue-700:focus {
  --gradient-to-color: #1a56db;
}

.focus\:to-blue-800:focus {
  --gradient-to-color: #1e429f;
}

.focus\:to-blue-900:focus {
  --gradient-to-color: #233876;
}

.focus\:to-indigo-50:focus {
  --gradient-to-color: #f0f5ff;
}

.focus\:to-indigo-100:focus {
  --gradient-to-color: #e5edff;
}

.focus\:to-indigo-200:focus {
  --gradient-to-color: #cddbfe;
}

.focus\:to-indigo-300:focus {
  --gradient-to-color: #b4c6fc;
}

.focus\:to-indigo-400:focus {
  --gradient-to-color: #8da2fb;
}

.focus\:to-indigo-500:focus {
  --gradient-to-color: #6875f5;
}

.focus\:to-indigo-600:focus {
  --gradient-to-color: #5850ec;
}

.focus\:to-indigo-700:focus {
  --gradient-to-color: #5145cd;
}

.focus\:to-indigo-800:focus {
  --gradient-to-color: #42389d;
}

.focus\:to-indigo-900:focus {
  --gradient-to-color: #362f78;
}

.focus\:to-purple-50:focus {
  --gradient-to-color: #f6f5ff;
}

.focus\:to-purple-100:focus {
  --gradient-to-color: #edebfe;
}

.focus\:to-purple-200:focus {
  --gradient-to-color: #dcd7fe;
}

.focus\:to-purple-300:focus {
  --gradient-to-color: #cabffd;
}

.focus\:to-purple-400:focus {
  --gradient-to-color: #ac94fa;
}

.focus\:to-purple-500:focus {
  --gradient-to-color: #9061f9;
}

.focus\:to-purple-600:focus {
  --gradient-to-color: #7e3af2;
}

.focus\:to-purple-700:focus {
  --gradient-to-color: #6c2bd9;
}

.focus\:to-purple-800:focus {
  --gradient-to-color: #5521b5;
}

.focus\:to-purple-900:focus {
  --gradient-to-color: #4a1d96;
}

.focus\:to-pink-50:focus {
  --gradient-to-color: #fdf2f8;
}

.focus\:to-pink-100:focus {
  --gradient-to-color: #fce8f3;
}

.focus\:to-pink-200:focus {
  --gradient-to-color: #fad1e8;
}

.focus\:to-pink-300:focus {
  --gradient-to-color: #f8b4d9;
}

.focus\:to-pink-400:focus {
  --gradient-to-color: #f17eb8;
}

.focus\:to-pink-500:focus {
  --gradient-to-color: #e74694;
}

.focus\:to-pink-600:focus {
  --gradient-to-color: #d61f69;
}

.focus\:to-pink-700:focus {
  --gradient-to-color: #bf125d;
}

.focus\:to-pink-800:focus {
  --gradient-to-color: #99154b;
}

.focus\:to-pink-900:focus {
  --gradient-to-color: #751a3d;
}

.bg-opacity-0 {
  --bg-opacity: 0;
}

.bg-opacity-25 {
  --bg-opacity: .25;
}

.bg-opacity-50 {
  --bg-opacity: .5;
}

.bg-opacity-75 {
  --bg-opacity: .75;
}

.bg-opacity-100 {
  --bg-opacity: 1;
}

.hover\:bg-opacity-0:hover {
  --bg-opacity: 0;
}

.hover\:bg-opacity-25:hover {
  --bg-opacity: .25;
}

.hover\:bg-opacity-50:hover {
  --bg-opacity: .5;
}

.hover\:bg-opacity-75:hover {
  --bg-opacity: .75;
}

.hover\:bg-opacity-100:hover {
  --bg-opacity: 1;
}

.focus\:bg-opacity-0:focus {
  --bg-opacity: 0;
}

.focus\:bg-opacity-25:focus {
  --bg-opacity: .25;
}

.focus\:bg-opacity-50:focus {
  --bg-opacity: .5;
}

.focus\:bg-opacity-75:focus {
  --bg-opacity: .75;
}

.focus\:bg-opacity-100:focus {
  --bg-opacity: 1;
}

.bg-bottom {
  background-position: bottom;
}

.bg-center {
  background-position: center;
}

.bg-left {
  background-position: 0;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-left-top {
  background-position: 0 0;
}

.bg-right {
  background-position: 100%;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-right-top {
  background-position: 100% 0;
}

.bg-top {
  background-position: top;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-auto {
  background-size: auto;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.border-collapse {
  border-collapse: collapse;
}

.border-separate {
  border-collapse: separate;
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity) );
}

.border-black {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity) );
}

.border-gray-50 {
  --border-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--border-opacity) );
}

.border-gray-100 {
  --border-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--border-opacity) );
}

.border-gray-200 {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity) );
}

.border-gray-300 {
  --border-opacity: 1;
  border-color: #d2d6dc;
  border-color: rgba(210, 214, 220, var(--border-opacity) );
}

.border-gray-400 {
  --border-opacity: 1;
  border-color: #9fa6b2;
  border-color: rgba(159, 166, 178, var(--border-opacity) );
}

.border-gray-500 {
  --border-opacity: 1;
  border-color: #6b7280;
  border-color: rgba(107, 114, 128, var(--border-opacity) );
}

.border-gray-600 {
  --border-opacity: 1;
  border-color: #4b5563;
  border-color: rgba(75, 85, 99, var(--border-opacity) );
}

.border-gray-700 {
  --border-opacity: 1;
  border-color: #374151;
  border-color: rgba(55, 65, 81, var(--border-opacity) );
}

.border-gray-800 {
  --border-opacity: 1;
  border-color: #252f3f;
  border-color: rgba(37, 47, 63, var(--border-opacity) );
}

.border-gray-900 {
  --border-opacity: 1;
  border-color: #161e2e;
  border-color: rgba(22, 30, 46, var(--border-opacity) );
}

.border-cool-gray-50 {
  --border-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--border-opacity) );
}

.border-cool-gray-100 {
  --border-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--border-opacity) );
}

.border-cool-gray-200 {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity) );
}

.border-cool-gray-300 {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity) );
}

.border-cool-gray-400 {
  --border-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--border-opacity) );
}

.border-cool-gray-500 {
  --border-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--border-opacity) );
}

.border-cool-gray-600 {
  --border-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--border-opacity) );
}

.border-cool-gray-700 {
  --border-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--border-opacity) );
}

.border-cool-gray-800 {
  --border-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--border-opacity) );
}

.border-cool-gray-900 {
  --border-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--border-opacity) );
}

.border-red-50 {
  --border-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--border-opacity) );
}

.border-red-100 {
  --border-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--border-opacity) );
}

.border-red-200 {
  --border-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--border-opacity) );
}

.border-red-300 {
  --border-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--border-opacity) );
}

.border-red-400 {
  --border-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--border-opacity) );
}

.border-red-500 {
  --border-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--border-opacity) );
}

.border-red-600 {
  --border-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--border-opacity) );
}

.border-red-700 {
  --border-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--border-opacity) );
}

.border-red-800 {
  --border-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--border-opacity) );
}

.border-red-900 {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity) );
}

.border-orange-50 {
  --border-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--border-opacity) );
}

.border-orange-100 {
  --border-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--border-opacity) );
}

.border-orange-200 {
  --border-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--border-opacity) );
}

.border-orange-300 {
  --border-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--border-opacity) );
}

.border-orange-400 {
  --border-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--border-opacity) );
}

.border-orange-500 {
  --border-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--border-opacity) );
}

.border-orange-600 {
  --border-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--border-opacity) );
}

.border-orange-700 {
  --border-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--border-opacity) );
}

.border-orange-800 {
  --border-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--border-opacity) );
}

.border-orange-900 {
  --border-opacity: 1;
  border-color: #73230d;
  border-color: rgba(115, 35, 13, var(--border-opacity) );
}

.border-yellow-50 {
  --border-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--border-opacity) );
}

.border-yellow-100 {
  --border-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--border-opacity) );
}

.border-yellow-200 {
  --border-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--border-opacity) );
}

.border-yellow-300 {
  --border-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--border-opacity) );
}

.border-yellow-400 {
  --border-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--border-opacity) );
}

.border-yellow-500 {
  --border-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--border-opacity) );
}

.border-yellow-600 {
  --border-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--border-opacity) );
}

.border-yellow-700 {
  --border-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--border-opacity) );
}

.border-yellow-800 {
  --border-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--border-opacity) );
}

.border-yellow-900 {
  --border-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--border-opacity) );
}

.border-green-50 {
  --border-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--border-opacity) );
}

.border-green-100 {
  --border-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--border-opacity) );
}

.border-green-200 {
  --border-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--border-opacity) );
}

.border-green-300 {
  --border-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--border-opacity) );
}

.border-green-400 {
  --border-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--border-opacity) );
}

.border-green-500 {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--border-opacity) );
}

.border-green-600 {
  --border-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--border-opacity) );
}

.border-green-700 {
  --border-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--border-opacity) );
}

.border-green-800 {
  --border-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--border-opacity) );
}

.border-green-900 {
  --border-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--border-opacity) );
}

.border-teal-50 {
  --border-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--border-opacity) );
}

.border-teal-100 {
  --border-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--border-opacity) );
}

.border-teal-200 {
  --border-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--border-opacity) );
}

.border-teal-300 {
  --border-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--border-opacity) );
}

.border-teal-400 {
  --border-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--border-opacity) );
}

.border-teal-500 {
  --border-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--border-opacity) );
}

.border-teal-600 {
  --border-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--border-opacity) );
}

.border-teal-700 {
  --border-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--border-opacity) );
}

.border-teal-800 {
  --border-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--border-opacity) );
}

.border-teal-900 {
  --border-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--border-opacity) );
}

.border-blue-50 {
  --border-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--border-opacity) );
}

.border-blue-100 {
  --border-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--border-opacity) );
}

.border-blue-200 {
  --border-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--border-opacity) );
}

.border-blue-300 {
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity) );
}

.border-blue-400 {
  --border-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--border-opacity) );
}

.border-blue-500 {
  --border-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--border-opacity) );
}

.border-blue-600 {
  --border-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--border-opacity) );
}

.border-blue-700 {
  --border-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--border-opacity) );
}

.border-blue-800 {
  --border-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--border-opacity) );
}

.border-blue-900 {
  --border-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--border-opacity) );
}

.border-indigo-50 {
  --border-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--border-opacity) );
}

.border-indigo-100 {
  --border-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--border-opacity) );
}

.border-indigo-200 {
  --border-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--border-opacity) );
}

.border-indigo-300 {
  --border-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--border-opacity) );
}

.border-indigo-400 {
  --border-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--border-opacity) );
}

.border-indigo-500 {
  --border-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--border-opacity) );
}

.border-indigo-600 {
  --border-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--border-opacity) );
}

.border-indigo-700 {
  --border-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--border-opacity) );
}

.border-indigo-800 {
  --border-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--border-opacity) );
}

.border-indigo-900 {
  --border-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--border-opacity) );
}

.border-purple-50 {
  --border-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--border-opacity) );
}

.border-purple-100 {
  --border-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--border-opacity) );
}

.border-purple-200 {
  --border-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--border-opacity) );
}

.border-purple-300 {
  --border-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--border-opacity) );
}

.border-purple-400 {
  --border-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--border-opacity) );
}

.border-purple-500 {
  --border-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--border-opacity) );
}

.border-purple-600 {
  --border-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--border-opacity) );
}

.border-purple-700 {
  --border-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--border-opacity) );
}

.border-purple-800 {
  --border-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--border-opacity) );
}

.border-purple-900 {
  --border-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--border-opacity) );
}

.border-pink-50 {
  --border-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--border-opacity) );
}

.border-pink-100 {
  --border-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--border-opacity) );
}

.border-pink-200 {
  --border-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--border-opacity) );
}

.border-pink-300 {
  --border-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--border-opacity) );
}

.border-pink-400 {
  --border-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--border-opacity) );
}

.border-pink-500 {
  --border-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--border-opacity) );
}

.border-pink-600 {
  --border-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--border-opacity) );
}

.border-pink-700 {
  --border-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--border-opacity) );
}

.border-pink-800 {
  --border-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--border-opacity) );
}

.border-pink-900 {
  --border-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--border-opacity) );
}

.group:hover .group-hover\:border-transparent {
  border-color: #0000;
}

.group:hover .group-hover\:border-white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity) );
}

.group:hover .group-hover\:border-black {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-50 {
  --border-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-100 {
  --border-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-200 {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-300 {
  --border-opacity: 1;
  border-color: #d2d6dc;
  border-color: rgba(210, 214, 220, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-400 {
  --border-opacity: 1;
  border-color: #9fa6b2;
  border-color: rgba(159, 166, 178, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-500 {
  --border-opacity: 1;
  border-color: #6b7280;
  border-color: rgba(107, 114, 128, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-600 {
  --border-opacity: 1;
  border-color: #4b5563;
  border-color: rgba(75, 85, 99, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-700 {
  --border-opacity: 1;
  border-color: #374151;
  border-color: rgba(55, 65, 81, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-800 {
  --border-opacity: 1;
  border-color: #252f3f;
  border-color: rgba(37, 47, 63, var(--border-opacity) );
}

.group:hover .group-hover\:border-gray-900 {
  --border-opacity: 1;
  border-color: #161e2e;
  border-color: rgba(22, 30, 46, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-50 {
  --border-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-100 {
  --border-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-200 {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-300 {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-400 {
  --border-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-500 {
  --border-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-600 {
  --border-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-700 {
  --border-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-800 {
  --border-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--border-opacity) );
}

.group:hover .group-hover\:border-cool-gray-900 {
  --border-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-50 {
  --border-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-100 {
  --border-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-200 {
  --border-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-300 {
  --border-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-400 {
  --border-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-500 {
  --border-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-600 {
  --border-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-700 {
  --border-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-800 {
  --border-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--border-opacity) );
}

.group:hover .group-hover\:border-red-900 {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-50 {
  --border-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-100 {
  --border-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-200 {
  --border-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-300 {
  --border-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-400 {
  --border-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-500 {
  --border-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-600 {
  --border-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-700 {
  --border-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-800 {
  --border-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--border-opacity) );
}

.group:hover .group-hover\:border-orange-900 {
  --border-opacity: 1;
  border-color: #73230d;
  border-color: rgba(115, 35, 13, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-50 {
  --border-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-100 {
  --border-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-200 {
  --border-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-300 {
  --border-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-400 {
  --border-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-500 {
  --border-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-600 {
  --border-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-700 {
  --border-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-800 {
  --border-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--border-opacity) );
}

.group:hover .group-hover\:border-yellow-900 {
  --border-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-50 {
  --border-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-100 {
  --border-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-200 {
  --border-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-300 {
  --border-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-400 {
  --border-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-500 {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-600 {
  --border-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-700 {
  --border-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-800 {
  --border-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--border-opacity) );
}

.group:hover .group-hover\:border-green-900 {
  --border-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-50 {
  --border-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-100 {
  --border-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-200 {
  --border-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-300 {
  --border-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-400 {
  --border-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-500 {
  --border-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-600 {
  --border-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-700 {
  --border-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-800 {
  --border-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--border-opacity) );
}

.group:hover .group-hover\:border-teal-900 {
  --border-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-50 {
  --border-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-100 {
  --border-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-200 {
  --border-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-300 {
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-400 {
  --border-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-500 {
  --border-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-600 {
  --border-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-700 {
  --border-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-800 {
  --border-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--border-opacity) );
}

.group:hover .group-hover\:border-blue-900 {
  --border-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-50 {
  --border-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-100 {
  --border-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-200 {
  --border-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-300 {
  --border-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-400 {
  --border-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-500 {
  --border-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-600 {
  --border-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-700 {
  --border-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-800 {
  --border-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--border-opacity) );
}

.group:hover .group-hover\:border-indigo-900 {
  --border-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-50 {
  --border-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-100 {
  --border-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-200 {
  --border-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-300 {
  --border-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-400 {
  --border-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-500 {
  --border-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-600 {
  --border-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-700 {
  --border-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-800 {
  --border-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--border-opacity) );
}

.group:hover .group-hover\:border-purple-900 {
  --border-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-50 {
  --border-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-100 {
  --border-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-200 {
  --border-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-300 {
  --border-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-400 {
  --border-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-500 {
  --border-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-600 {
  --border-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-700 {
  --border-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-800 {
  --border-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--border-opacity) );
}

.group:hover .group-hover\:border-pink-900 {
  --border-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--border-opacity) );
}

.group:focus .group-focus\:border-transparent {
  border-color: #0000;
}

.group:focus .group-focus\:border-white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity) );
}

.group:focus .group-focus\:border-black {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-50 {
  --border-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-100 {
  --border-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-200 {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-300 {
  --border-opacity: 1;
  border-color: #d2d6dc;
  border-color: rgba(210, 214, 220, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-400 {
  --border-opacity: 1;
  border-color: #9fa6b2;
  border-color: rgba(159, 166, 178, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-500 {
  --border-opacity: 1;
  border-color: #6b7280;
  border-color: rgba(107, 114, 128, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-600 {
  --border-opacity: 1;
  border-color: #4b5563;
  border-color: rgba(75, 85, 99, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-700 {
  --border-opacity: 1;
  border-color: #374151;
  border-color: rgba(55, 65, 81, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-800 {
  --border-opacity: 1;
  border-color: #252f3f;
  border-color: rgba(37, 47, 63, var(--border-opacity) );
}

.group:focus .group-focus\:border-gray-900 {
  --border-opacity: 1;
  border-color: #161e2e;
  border-color: rgba(22, 30, 46, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-50 {
  --border-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-100 {
  --border-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-200 {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-300 {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-400 {
  --border-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-500 {
  --border-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-600 {
  --border-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-700 {
  --border-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-800 {
  --border-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--border-opacity) );
}

.group:focus .group-focus\:border-cool-gray-900 {
  --border-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-50 {
  --border-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-100 {
  --border-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-200 {
  --border-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-300 {
  --border-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-400 {
  --border-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-500 {
  --border-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-600 {
  --border-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-700 {
  --border-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-800 {
  --border-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--border-opacity) );
}

.group:focus .group-focus\:border-red-900 {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-50 {
  --border-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-100 {
  --border-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-200 {
  --border-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-300 {
  --border-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-400 {
  --border-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-500 {
  --border-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-600 {
  --border-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-700 {
  --border-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-800 {
  --border-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--border-opacity) );
}

.group:focus .group-focus\:border-orange-900 {
  --border-opacity: 1;
  border-color: #73230d;
  border-color: rgba(115, 35, 13, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-50 {
  --border-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-100 {
  --border-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-200 {
  --border-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-300 {
  --border-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-400 {
  --border-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-500 {
  --border-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-600 {
  --border-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-700 {
  --border-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-800 {
  --border-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--border-opacity) );
}

.group:focus .group-focus\:border-yellow-900 {
  --border-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-50 {
  --border-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-100 {
  --border-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-200 {
  --border-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-300 {
  --border-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-400 {
  --border-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-500 {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-600 {
  --border-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-700 {
  --border-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-800 {
  --border-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--border-opacity) );
}

.group:focus .group-focus\:border-green-900 {
  --border-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-50 {
  --border-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-100 {
  --border-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-200 {
  --border-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-300 {
  --border-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-400 {
  --border-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-500 {
  --border-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-600 {
  --border-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-700 {
  --border-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-800 {
  --border-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--border-opacity) );
}

.group:focus .group-focus\:border-teal-900 {
  --border-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-50 {
  --border-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-100 {
  --border-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-200 {
  --border-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-300 {
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-400 {
  --border-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-500 {
  --border-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-600 {
  --border-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-700 {
  --border-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-800 {
  --border-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--border-opacity) );
}

.group:focus .group-focus\:border-blue-900 {
  --border-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-50 {
  --border-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-100 {
  --border-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-200 {
  --border-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-300 {
  --border-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-400 {
  --border-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-500 {
  --border-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-600 {
  --border-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-700 {
  --border-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-800 {
  --border-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--border-opacity) );
}

.group:focus .group-focus\:border-indigo-900 {
  --border-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-50 {
  --border-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-100 {
  --border-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-200 {
  --border-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-300 {
  --border-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-400 {
  --border-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-500 {
  --border-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-600 {
  --border-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-700 {
  --border-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-800 {
  --border-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--border-opacity) );
}

.group:focus .group-focus\:border-purple-900 {
  --border-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-50 {
  --border-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-100 {
  --border-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-200 {
  --border-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-300 {
  --border-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-400 {
  --border-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-500 {
  --border-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-600 {
  --border-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-700 {
  --border-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-800 {
  --border-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--border-opacity) );
}

.group:focus .group-focus\:border-pink-900 {
  --border-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--border-opacity) );
}

.hover\:border-transparent:hover {
  border-color: #0000;
}

.hover\:border-white:hover {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity) );
}

.hover\:border-black:hover {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity) );
}

.hover\:border-gray-50:hover {
  --border-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--border-opacity) );
}

.hover\:border-gray-100:hover {
  --border-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--border-opacity) );
}

.hover\:border-gray-200:hover {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity) );
}

.hover\:border-gray-300:hover {
  --border-opacity: 1;
  border-color: #d2d6dc;
  border-color: rgba(210, 214, 220, var(--border-opacity) );
}

.hover\:border-gray-400:hover {
  --border-opacity: 1;
  border-color: #9fa6b2;
  border-color: rgba(159, 166, 178, var(--border-opacity) );
}

.hover\:border-gray-500:hover {
  --border-opacity: 1;
  border-color: #6b7280;
  border-color: rgba(107, 114, 128, var(--border-opacity) );
}

.hover\:border-gray-600:hover {
  --border-opacity: 1;
  border-color: #4b5563;
  border-color: rgba(75, 85, 99, var(--border-opacity) );
}

.hover\:border-gray-700:hover {
  --border-opacity: 1;
  border-color: #374151;
  border-color: rgba(55, 65, 81, var(--border-opacity) );
}

.hover\:border-gray-800:hover {
  --border-opacity: 1;
  border-color: #252f3f;
  border-color: rgba(37, 47, 63, var(--border-opacity) );
}

.hover\:border-gray-900:hover {
  --border-opacity: 1;
  border-color: #161e2e;
  border-color: rgba(22, 30, 46, var(--border-opacity) );
}

.hover\:border-cool-gray-50:hover {
  --border-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--border-opacity) );
}

.hover\:border-cool-gray-100:hover {
  --border-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--border-opacity) );
}

.hover\:border-cool-gray-200:hover {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity) );
}

.hover\:border-cool-gray-300:hover {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity) );
}

.hover\:border-cool-gray-400:hover {
  --border-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--border-opacity) );
}

.hover\:border-cool-gray-500:hover {
  --border-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--border-opacity) );
}

.hover\:border-cool-gray-600:hover {
  --border-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--border-opacity) );
}

.hover\:border-cool-gray-700:hover {
  --border-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--border-opacity) );
}

.hover\:border-cool-gray-800:hover {
  --border-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--border-opacity) );
}

.hover\:border-cool-gray-900:hover {
  --border-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--border-opacity) );
}

.hover\:border-red-50:hover {
  --border-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--border-opacity) );
}

.hover\:border-red-100:hover {
  --border-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--border-opacity) );
}

.hover\:border-red-200:hover {
  --border-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--border-opacity) );
}

.hover\:border-red-300:hover {
  --border-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--border-opacity) );
}

.hover\:border-red-400:hover {
  --border-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--border-opacity) );
}

.hover\:border-red-500:hover {
  --border-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--border-opacity) );
}

.hover\:border-red-600:hover {
  --border-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--border-opacity) );
}

.hover\:border-red-700:hover {
  --border-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--border-opacity) );
}

.hover\:border-red-800:hover {
  --border-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--border-opacity) );
}

.hover\:border-red-900:hover {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity) );
}

.hover\:border-orange-50:hover {
  --border-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--border-opacity) );
}

.hover\:border-orange-100:hover {
  --border-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--border-opacity) );
}

.hover\:border-orange-200:hover {
  --border-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--border-opacity) );
}

.hover\:border-orange-300:hover {
  --border-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--border-opacity) );
}

.hover\:border-orange-400:hover {
  --border-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--border-opacity) );
}

.hover\:border-orange-500:hover {
  --border-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--border-opacity) );
}

.hover\:border-orange-600:hover {
  --border-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--border-opacity) );
}

.hover\:border-orange-700:hover {
  --border-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--border-opacity) );
}

.hover\:border-orange-800:hover {
  --border-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--border-opacity) );
}

.hover\:border-orange-900:hover {
  --border-opacity: 1;
  border-color: #73230d;
  border-color: rgba(115, 35, 13, var(--border-opacity) );
}

.hover\:border-yellow-50:hover {
  --border-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--border-opacity) );
}

.hover\:border-yellow-100:hover {
  --border-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--border-opacity) );
}

.hover\:border-yellow-200:hover {
  --border-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--border-opacity) );
}

.hover\:border-yellow-300:hover {
  --border-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--border-opacity) );
}

.hover\:border-yellow-400:hover {
  --border-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--border-opacity) );
}

.hover\:border-yellow-500:hover {
  --border-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--border-opacity) );
}

.hover\:border-yellow-600:hover {
  --border-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--border-opacity) );
}

.hover\:border-yellow-700:hover {
  --border-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--border-opacity) );
}

.hover\:border-yellow-800:hover {
  --border-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--border-opacity) );
}

.hover\:border-yellow-900:hover {
  --border-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--border-opacity) );
}

.hover\:border-green-50:hover {
  --border-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--border-opacity) );
}

.hover\:border-green-100:hover {
  --border-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--border-opacity) );
}

.hover\:border-green-200:hover {
  --border-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--border-opacity) );
}

.hover\:border-green-300:hover {
  --border-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--border-opacity) );
}

.hover\:border-green-400:hover {
  --border-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--border-opacity) );
}

.hover\:border-green-500:hover {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--border-opacity) );
}

.hover\:border-green-600:hover {
  --border-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--border-opacity) );
}

.hover\:border-green-700:hover {
  --border-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--border-opacity) );
}

.hover\:border-green-800:hover {
  --border-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--border-opacity) );
}

.hover\:border-green-900:hover {
  --border-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--border-opacity) );
}

.hover\:border-teal-50:hover {
  --border-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--border-opacity) );
}

.hover\:border-teal-100:hover {
  --border-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--border-opacity) );
}

.hover\:border-teal-200:hover {
  --border-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--border-opacity) );
}

.hover\:border-teal-300:hover {
  --border-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--border-opacity) );
}

.hover\:border-teal-400:hover {
  --border-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--border-opacity) );
}

.hover\:border-teal-500:hover {
  --border-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--border-opacity) );
}

.hover\:border-teal-600:hover {
  --border-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--border-opacity) );
}

.hover\:border-teal-700:hover {
  --border-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--border-opacity) );
}

.hover\:border-teal-800:hover {
  --border-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--border-opacity) );
}

.hover\:border-teal-900:hover {
  --border-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--border-opacity) );
}

.hover\:border-blue-50:hover {
  --border-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--border-opacity) );
}

.hover\:border-blue-100:hover {
  --border-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--border-opacity) );
}

.hover\:border-blue-200:hover {
  --border-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--border-opacity) );
}

.hover\:border-blue-300:hover {
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity) );
}

.hover\:border-blue-400:hover {
  --border-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--border-opacity) );
}

.hover\:border-blue-500:hover {
  --border-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--border-opacity) );
}

.hover\:border-blue-600:hover {
  --border-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--border-opacity) );
}

.hover\:border-blue-700:hover {
  --border-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--border-opacity) );
}

.hover\:border-blue-800:hover {
  --border-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--border-opacity) );
}

.hover\:border-blue-900:hover {
  --border-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--border-opacity) );
}

.hover\:border-indigo-50:hover {
  --border-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--border-opacity) );
}

.hover\:border-indigo-100:hover {
  --border-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--border-opacity) );
}

.hover\:border-indigo-200:hover {
  --border-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--border-opacity) );
}

.hover\:border-indigo-300:hover {
  --border-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--border-opacity) );
}

.hover\:border-indigo-400:hover {
  --border-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--border-opacity) );
}

.hover\:border-indigo-500:hover {
  --border-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--border-opacity) );
}

.hover\:border-indigo-600:hover {
  --border-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--border-opacity) );
}

.hover\:border-indigo-700:hover {
  --border-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--border-opacity) );
}

.hover\:border-indigo-800:hover {
  --border-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--border-opacity) );
}

.hover\:border-indigo-900:hover {
  --border-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--border-opacity) );
}

.hover\:border-purple-50:hover {
  --border-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--border-opacity) );
}

.hover\:border-purple-100:hover {
  --border-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--border-opacity) );
}

.hover\:border-purple-200:hover {
  --border-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--border-opacity) );
}

.hover\:border-purple-300:hover {
  --border-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--border-opacity) );
}

.hover\:border-purple-400:hover {
  --border-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--border-opacity) );
}

.hover\:border-purple-500:hover {
  --border-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--border-opacity) );
}

.hover\:border-purple-600:hover {
  --border-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--border-opacity) );
}

.hover\:border-purple-700:hover {
  --border-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--border-opacity) );
}

.hover\:border-purple-800:hover {
  --border-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--border-opacity) );
}

.hover\:border-purple-900:hover {
  --border-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--border-opacity) );
}

.hover\:border-pink-50:hover {
  --border-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--border-opacity) );
}

.hover\:border-pink-100:hover {
  --border-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--border-opacity) );
}

.hover\:border-pink-200:hover {
  --border-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--border-opacity) );
}

.hover\:border-pink-300:hover {
  --border-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--border-opacity) );
}

.hover\:border-pink-400:hover {
  --border-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--border-opacity) );
}

.hover\:border-pink-500:hover {
  --border-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--border-opacity) );
}

.hover\:border-pink-600:hover {
  --border-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--border-opacity) );
}

.hover\:border-pink-700:hover {
  --border-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--border-opacity) );
}

.hover\:border-pink-800:hover {
  --border-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--border-opacity) );
}

.hover\:border-pink-900:hover {
  --border-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--border-opacity) );
}

.focus\:border-transparent:focus {
  border-color: #0000;
}

.focus\:border-white:focus {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity) );
}

.focus\:border-black:focus {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity) );
}

.focus\:border-gray-50:focus {
  --border-opacity: 1;
  border-color: #f9fafb;
  border-color: rgba(249, 250, 251, var(--border-opacity) );
}

.focus\:border-gray-100:focus {
  --border-opacity: 1;
  border-color: #f4f5f7;
  border-color: rgba(244, 245, 247, var(--border-opacity) );
}

.focus\:border-gray-200:focus {
  --border-opacity: 1;
  border-color: #e5e7eb;
  border-color: rgba(229, 231, 235, var(--border-opacity) );
}

.focus\:border-gray-300:focus {
  --border-opacity: 1;
  border-color: #d2d6dc;
  border-color: rgba(210, 214, 220, var(--border-opacity) );
}

.focus\:border-gray-400:focus {
  --border-opacity: 1;
  border-color: #9fa6b2;
  border-color: rgba(159, 166, 178, var(--border-opacity) );
}

.focus\:border-gray-500:focus {
  --border-opacity: 1;
  border-color: #6b7280;
  border-color: rgba(107, 114, 128, var(--border-opacity) );
}

.focus\:border-gray-600:focus {
  --border-opacity: 1;
  border-color: #4b5563;
  border-color: rgba(75, 85, 99, var(--border-opacity) );
}

.focus\:border-gray-700:focus {
  --border-opacity: 1;
  border-color: #374151;
  border-color: rgba(55, 65, 81, var(--border-opacity) );
}

.focus\:border-gray-800:focus {
  --border-opacity: 1;
  border-color: #252f3f;
  border-color: rgba(37, 47, 63, var(--border-opacity) );
}

.focus\:border-gray-900:focus {
  --border-opacity: 1;
  border-color: #161e2e;
  border-color: rgba(22, 30, 46, var(--border-opacity) );
}

.focus\:border-cool-gray-50:focus {
  --border-opacity: 1;
  border-color: #fbfdfe;
  border-color: rgba(251, 253, 254, var(--border-opacity) );
}

.focus\:border-cool-gray-100:focus {
  --border-opacity: 1;
  border-color: #f1f5f9;
  border-color: rgba(241, 245, 249, var(--border-opacity) );
}

.focus\:border-cool-gray-200:focus {
  --border-opacity: 1;
  border-color: #e2e8f0;
  border-color: rgba(226, 232, 240, var(--border-opacity) );
}

.focus\:border-cool-gray-300:focus {
  --border-opacity: 1;
  border-color: #cfd8e3;
  border-color: rgba(207, 216, 227, var(--border-opacity) );
}

.focus\:border-cool-gray-400:focus {
  --border-opacity: 1;
  border-color: #97a6ba;
  border-color: rgba(151, 166, 186, var(--border-opacity) );
}

.focus\:border-cool-gray-500:focus {
  --border-opacity: 1;
  border-color: #64748b;
  border-color: rgba(100, 116, 139, var(--border-opacity) );
}

.focus\:border-cool-gray-600:focus {
  --border-opacity: 1;
  border-color: #475569;
  border-color: rgba(71, 85, 105, var(--border-opacity) );
}

.focus\:border-cool-gray-700:focus {
  --border-opacity: 1;
  border-color: #364152;
  border-color: rgba(54, 65, 82, var(--border-opacity) );
}

.focus\:border-cool-gray-800:focus {
  --border-opacity: 1;
  border-color: #27303f;
  border-color: rgba(39, 48, 63, var(--border-opacity) );
}

.focus\:border-cool-gray-900:focus {
  --border-opacity: 1;
  border-color: #1a202e;
  border-color: rgba(26, 32, 46, var(--border-opacity) );
}

.focus\:border-red-50:focus {
  --border-opacity: 1;
  border-color: #fdf2f2;
  border-color: rgba(253, 242, 242, var(--border-opacity) );
}

.focus\:border-red-100:focus {
  --border-opacity: 1;
  border-color: #fde8e8;
  border-color: rgba(253, 232, 232, var(--border-opacity) );
}

.focus\:border-red-200:focus {
  --border-opacity: 1;
  border-color: #fbd5d5;
  border-color: rgba(251, 213, 213, var(--border-opacity) );
}

.focus\:border-red-300:focus {
  --border-opacity: 1;
  border-color: #f8b4b4;
  border-color: rgba(248, 180, 180, var(--border-opacity) );
}

.focus\:border-red-400:focus {
  --border-opacity: 1;
  border-color: #f98080;
  border-color: rgba(249, 128, 128, var(--border-opacity) );
}

.focus\:border-red-500:focus {
  --border-opacity: 1;
  border-color: #f05252;
  border-color: rgba(240, 82, 82, var(--border-opacity) );
}

.focus\:border-red-600:focus {
  --border-opacity: 1;
  border-color: #e02424;
  border-color: rgba(224, 36, 36, var(--border-opacity) );
}

.focus\:border-red-700:focus {
  --border-opacity: 1;
  border-color: #c81e1e;
  border-color: rgba(200, 30, 30, var(--border-opacity) );
}

.focus\:border-red-800:focus {
  --border-opacity: 1;
  border-color: #9b1c1c;
  border-color: rgba(155, 28, 28, var(--border-opacity) );
}

.focus\:border-red-900:focus {
  --border-opacity: 1;
  border-color: #771d1d;
  border-color: rgba(119, 29, 29, var(--border-opacity) );
}

.focus\:border-orange-50:focus {
  --border-opacity: 1;
  border-color: #fff8f1;
  border-color: rgba(255, 248, 241, var(--border-opacity) );
}

.focus\:border-orange-100:focus {
  --border-opacity: 1;
  border-color: #feecdc;
  border-color: rgba(254, 236, 220, var(--border-opacity) );
}

.focus\:border-orange-200:focus {
  --border-opacity: 1;
  border-color: #fcd9bd;
  border-color: rgba(252, 217, 189, var(--border-opacity) );
}

.focus\:border-orange-300:focus {
  --border-opacity: 1;
  border-color: #fdba8c;
  border-color: rgba(253, 186, 140, var(--border-opacity) );
}

.focus\:border-orange-400:focus {
  --border-opacity: 1;
  border-color: #ff8a4c;
  border-color: rgba(255, 138, 76, var(--border-opacity) );
}

.focus\:border-orange-500:focus {
  --border-opacity: 1;
  border-color: #ff5a1f;
  border-color: rgba(255, 90, 31, var(--border-opacity) );
}

.focus\:border-orange-600:focus {
  --border-opacity: 1;
  border-color: #d03801;
  border-color: rgba(208, 56, 1, var(--border-opacity) );
}

.focus\:border-orange-700:focus {
  --border-opacity: 1;
  border-color: #b43403;
  border-color: rgba(180, 52, 3, var(--border-opacity) );
}

.focus\:border-orange-800:focus {
  --border-opacity: 1;
  border-color: #8a2c0d;
  border-color: rgba(138, 44, 13, var(--border-opacity) );
}

.focus\:border-orange-900:focus {
  --border-opacity: 1;
  border-color: #73230d;
  border-color: rgba(115, 35, 13, var(--border-opacity) );
}

.focus\:border-yellow-50:focus {
  --border-opacity: 1;
  border-color: #fdfdea;
  border-color: rgba(253, 253, 234, var(--border-opacity) );
}

.focus\:border-yellow-100:focus {
  --border-opacity: 1;
  border-color: #fdf6b2;
  border-color: rgba(253, 246, 178, var(--border-opacity) );
}

.focus\:border-yellow-200:focus {
  --border-opacity: 1;
  border-color: #fce96a;
  border-color: rgba(252, 233, 106, var(--border-opacity) );
}

.focus\:border-yellow-300:focus {
  --border-opacity: 1;
  border-color: #faca15;
  border-color: rgba(250, 202, 21, var(--border-opacity) );
}

.focus\:border-yellow-400:focus {
  --border-opacity: 1;
  border-color: #e3a008;
  border-color: rgba(227, 160, 8, var(--border-opacity) );
}

.focus\:border-yellow-500:focus {
  --border-opacity: 1;
  border-color: #c27803;
  border-color: rgba(194, 120, 3, var(--border-opacity) );
}

.focus\:border-yellow-600:focus {
  --border-opacity: 1;
  border-color: #9f580a;
  border-color: rgba(159, 88, 10, var(--border-opacity) );
}

.focus\:border-yellow-700:focus {
  --border-opacity: 1;
  border-color: #8e4b10;
  border-color: rgba(142, 75, 16, var(--border-opacity) );
}

.focus\:border-yellow-800:focus {
  --border-opacity: 1;
  border-color: #723b13;
  border-color: rgba(114, 59, 19, var(--border-opacity) );
}

.focus\:border-yellow-900:focus {
  --border-opacity: 1;
  border-color: #633112;
  border-color: rgba(99, 49, 18, var(--border-opacity) );
}

.focus\:border-green-50:focus {
  --border-opacity: 1;
  border-color: #f3faf7;
  border-color: rgba(243, 250, 247, var(--border-opacity) );
}

.focus\:border-green-100:focus {
  --border-opacity: 1;
  border-color: #def7ec;
  border-color: rgba(222, 247, 236, var(--border-opacity) );
}

.focus\:border-green-200:focus {
  --border-opacity: 1;
  border-color: #bcf0da;
  border-color: rgba(188, 240, 218, var(--border-opacity) );
}

.focus\:border-green-300:focus {
  --border-opacity: 1;
  border-color: #84e1bc;
  border-color: rgba(132, 225, 188, var(--border-opacity) );
}

.focus\:border-green-400:focus {
  --border-opacity: 1;
  border-color: #31c48d;
  border-color: rgba(49, 196, 141, var(--border-opacity) );
}

.focus\:border-green-500:focus {
  --border-opacity: 1;
  border-color: #0e9f6e;
  border-color: rgba(14, 159, 110, var(--border-opacity) );
}

.focus\:border-green-600:focus {
  --border-opacity: 1;
  border-color: #057a55;
  border-color: rgba(5, 122, 85, var(--border-opacity) );
}

.focus\:border-green-700:focus {
  --border-opacity: 1;
  border-color: #046c4e;
  border-color: rgba(4, 108, 78, var(--border-opacity) );
}

.focus\:border-green-800:focus {
  --border-opacity: 1;
  border-color: #03543f;
  border-color: rgba(3, 84, 63, var(--border-opacity) );
}

.focus\:border-green-900:focus {
  --border-opacity: 1;
  border-color: #014737;
  border-color: rgba(1, 71, 55, var(--border-opacity) );
}

.focus\:border-teal-50:focus {
  --border-opacity: 1;
  border-color: #edfafa;
  border-color: rgba(237, 250, 250, var(--border-opacity) );
}

.focus\:border-teal-100:focus {
  --border-opacity: 1;
  border-color: #d5f5f6;
  border-color: rgba(213, 245, 246, var(--border-opacity) );
}

.focus\:border-teal-200:focus {
  --border-opacity: 1;
  border-color: #afecef;
  border-color: rgba(175, 236, 239, var(--border-opacity) );
}

.focus\:border-teal-300:focus {
  --border-opacity: 1;
  border-color: #7edce2;
  border-color: rgba(126, 220, 226, var(--border-opacity) );
}

.focus\:border-teal-400:focus {
  --border-opacity: 1;
  border-color: #16bdca;
  border-color: rgba(22, 189, 202, var(--border-opacity) );
}

.focus\:border-teal-500:focus {
  --border-opacity: 1;
  border-color: #0694a2;
  border-color: rgba(6, 148, 162, var(--border-opacity) );
}

.focus\:border-teal-600:focus {
  --border-opacity: 1;
  border-color: #047481;
  border-color: rgba(4, 116, 129, var(--border-opacity) );
}

.focus\:border-teal-700:focus {
  --border-opacity: 1;
  border-color: #036672;
  border-color: rgba(3, 102, 114, var(--border-opacity) );
}

.focus\:border-teal-800:focus {
  --border-opacity: 1;
  border-color: #05505c;
  border-color: rgba(5, 80, 92, var(--border-opacity) );
}

.focus\:border-teal-900:focus {
  --border-opacity: 1;
  border-color: #014451;
  border-color: rgba(1, 68, 81, var(--border-opacity) );
}

.focus\:border-blue-50:focus {
  --border-opacity: 1;
  border-color: #ebf5ff;
  border-color: rgba(235, 245, 255, var(--border-opacity) );
}

.focus\:border-blue-100:focus {
  --border-opacity: 1;
  border-color: #e1effe;
  border-color: rgba(225, 239, 254, var(--border-opacity) );
}

.focus\:border-blue-200:focus {
  --border-opacity: 1;
  border-color: #c3ddfd;
  border-color: rgba(195, 221, 253, var(--border-opacity) );
}

.focus\:border-blue-300:focus {
  --border-opacity: 1;
  border-color: #a4cafe;
  border-color: rgba(164, 202, 254, var(--border-opacity) );
}

.focus\:border-blue-400:focus {
  --border-opacity: 1;
  border-color: #76a9fa;
  border-color: rgba(118, 169, 250, var(--border-opacity) );
}

.focus\:border-blue-500:focus {
  --border-opacity: 1;
  border-color: #3f83f8;
  border-color: rgba(63, 131, 248, var(--border-opacity) );
}

.focus\:border-blue-600:focus {
  --border-opacity: 1;
  border-color: #1c64f2;
  border-color: rgba(28, 100, 242, var(--border-opacity) );
}

.focus\:border-blue-700:focus {
  --border-opacity: 1;
  border-color: #1a56db;
  border-color: rgba(26, 86, 219, var(--border-opacity) );
}

.focus\:border-blue-800:focus {
  --border-opacity: 1;
  border-color: #1e429f;
  border-color: rgba(30, 66, 159, var(--border-opacity) );
}

.focus\:border-blue-900:focus {
  --border-opacity: 1;
  border-color: #233876;
  border-color: rgba(35, 56, 118, var(--border-opacity) );
}

.focus\:border-indigo-50:focus {
  --border-opacity: 1;
  border-color: #f0f5ff;
  border-color: rgba(240, 245, 255, var(--border-opacity) );
}

.focus\:border-indigo-100:focus {
  --border-opacity: 1;
  border-color: #e5edff;
  border-color: rgba(229, 237, 255, var(--border-opacity) );
}

.focus\:border-indigo-200:focus {
  --border-opacity: 1;
  border-color: #cddbfe;
  border-color: rgba(205, 219, 254, var(--border-opacity) );
}

.focus\:border-indigo-300:focus {
  --border-opacity: 1;
  border-color: #b4c6fc;
  border-color: rgba(180, 198, 252, var(--border-opacity) );
}

.focus\:border-indigo-400:focus {
  --border-opacity: 1;
  border-color: #8da2fb;
  border-color: rgba(141, 162, 251, var(--border-opacity) );
}

.focus\:border-indigo-500:focus {
  --border-opacity: 1;
  border-color: #6875f5;
  border-color: rgba(104, 117, 245, var(--border-opacity) );
}

.focus\:border-indigo-600:focus {
  --border-opacity: 1;
  border-color: #5850ec;
  border-color: rgba(88, 80, 236, var(--border-opacity) );
}

.focus\:border-indigo-700:focus {
  --border-opacity: 1;
  border-color: #5145cd;
  border-color: rgba(81, 69, 205, var(--border-opacity) );
}

.focus\:border-indigo-800:focus {
  --border-opacity: 1;
  border-color: #42389d;
  border-color: rgba(66, 56, 157, var(--border-opacity) );
}

.focus\:border-indigo-900:focus {
  --border-opacity: 1;
  border-color: #362f78;
  border-color: rgba(54, 47, 120, var(--border-opacity) );
}

.focus\:border-purple-50:focus {
  --border-opacity: 1;
  border-color: #f6f5ff;
  border-color: rgba(246, 245, 255, var(--border-opacity) );
}

.focus\:border-purple-100:focus {
  --border-opacity: 1;
  border-color: #edebfe;
  border-color: rgba(237, 235, 254, var(--border-opacity) );
}

.focus\:border-purple-200:focus {
  --border-opacity: 1;
  border-color: #dcd7fe;
  border-color: rgba(220, 215, 254, var(--border-opacity) );
}

.focus\:border-purple-300:focus {
  --border-opacity: 1;
  border-color: #cabffd;
  border-color: rgba(202, 191, 253, var(--border-opacity) );
}

.focus\:border-purple-400:focus {
  --border-opacity: 1;
  border-color: #ac94fa;
  border-color: rgba(172, 148, 250, var(--border-opacity) );
}

.focus\:border-purple-500:focus {
  --border-opacity: 1;
  border-color: #9061f9;
  border-color: rgba(144, 97, 249, var(--border-opacity) );
}

.focus\:border-purple-600:focus {
  --border-opacity: 1;
  border-color: #7e3af2;
  border-color: rgba(126, 58, 242, var(--border-opacity) );
}

.focus\:border-purple-700:focus {
  --border-opacity: 1;
  border-color: #6c2bd9;
  border-color: rgba(108, 43, 217, var(--border-opacity) );
}

.focus\:border-purple-800:focus {
  --border-opacity: 1;
  border-color: #5521b5;
  border-color: rgba(85, 33, 181, var(--border-opacity) );
}

.focus\:border-purple-900:focus {
  --border-opacity: 1;
  border-color: #4a1d96;
  border-color: rgba(74, 29, 150, var(--border-opacity) );
}

.focus\:border-pink-50:focus {
  --border-opacity: 1;
  border-color: #fdf2f8;
  border-color: rgba(253, 242, 248, var(--border-opacity) );
}

.focus\:border-pink-100:focus {
  --border-opacity: 1;
  border-color: #fce8f3;
  border-color: rgba(252, 232, 243, var(--border-opacity) );
}

.focus\:border-pink-200:focus {
  --border-opacity: 1;
  border-color: #fad1e8;
  border-color: rgba(250, 209, 232, var(--border-opacity) );
}

.focus\:border-pink-300:focus {
  --border-opacity: 1;
  border-color: #f8b4d9;
  border-color: rgba(248, 180, 217, var(--border-opacity) );
}

.focus\:border-pink-400:focus {
  --border-opacity: 1;
  border-color: #f17eb8;
  border-color: rgba(241, 126, 184, var(--border-opacity) );
}

.focus\:border-pink-500:focus {
  --border-opacity: 1;
  border-color: #e74694;
  border-color: rgba(231, 70, 148, var(--border-opacity) );
}

.focus\:border-pink-600:focus {
  --border-opacity: 1;
  border-color: #d61f69;
  border-color: rgba(214, 31, 105, var(--border-opacity) );
}

.focus\:border-pink-700:focus {
  --border-opacity: 1;
  border-color: #bf125d;
  border-color: rgba(191, 18, 93, var(--border-opacity) );
}

.focus\:border-pink-800:focus {
  --border-opacity: 1;
  border-color: #99154b;
  border-color: rgba(153, 21, 75, var(--border-opacity) );
}

.focus\:border-pink-900:focus {
  --border-opacity: 1;
  border-color: #751a3d;
  border-color: rgba(117, 26, 61, var(--border-opacity) );
}

.border-opacity-0 {
  --border-opacity: 0;
}

.border-opacity-25 {
  --border-opacity: .25;
}

.border-opacity-50 {
  --border-opacity: .5;
}

.border-opacity-75 {
  --border-opacity: .75;
}

.border-opacity-100 {
  --border-opacity: 1;
}

.hover\:border-opacity-0:hover {
  --border-opacity: 0;
}

.hover\:border-opacity-25:hover {
  --border-opacity: .25;
}

.hover\:border-opacity-50:hover {
  --border-opacity: .5;
}

.hover\:border-opacity-75:hover {
  --border-opacity: .75;
}

.hover\:border-opacity-100:hover {
  --border-opacity: 1;
}

.focus\:border-opacity-0:focus {
  --border-opacity: 0;
}

.focus\:border-opacity-25:focus {
  --border-opacity: .25;
}

.focus\:border-opacity-50:focus {
  --border-opacity: .5;
}

.focus\:border-opacity-75:focus {
  --border-opacity: .75;
}

.focus\:border-opacity-100:focus {
  --border-opacity: 1;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded {
  border-radius: .25rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-b-none {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-t-sm {
  border-top-left-radius: .125rem;
  border-top-right-radius: .125rem;
}

.rounded-r-sm {
  border-top-right-radius: .125rem;
  border-bottom-right-radius: .125rem;
}

.rounded-b-sm {
  border-bottom-left-radius: .125rem;
  border-bottom-right-radius: .125rem;
}

.rounded-l-sm {
  border-top-left-radius: .125rem;
  border-bottom-left-radius: .125rem;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-b {
  border-bottom-left-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-r-md {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-b-md {
  border-bottom-left-radius: .375rem;
  border-bottom-right-radius: .375rem;
}

.rounded-l-md {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-b-lg {
  border-bottom-left-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.rounded-r-xl {
  border-top-right-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.rounded-b-xl {
  border-bottom-left-radius: .75rem;
  border-bottom-right-radius: .75rem;
}

.rounded-l-xl {
  border-top-left-radius: .75rem;
  border-bottom-left-radius: .75rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-b-2xl {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}

.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-b-3xl {
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}

.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-b-full {
  border-bottom-left-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.rounded-tl-none {
  border-top-left-radius: 0;
}

.rounded-tr-none {
  border-top-right-radius: 0;
}

.rounded-br-none {
  border-bottom-right-radius: 0;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded-tl-sm {
  border-top-left-radius: .125rem;
}

.rounded-tr-sm {
  border-top-right-radius: .125rem;
}

.rounded-br-sm {
  border-bottom-right-radius: .125rem;
}

.rounded-bl-sm {
  border-bottom-left-radius: .125rem;
}

.rounded-tl {
  border-top-left-radius: .25rem;
}

.rounded-tr {
  border-top-right-radius: .25rem;
}

.rounded-br {
  border-bottom-right-radius: .25rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-tl-xl {
  border-top-left-radius: .75rem;
}

.rounded-tr-xl {
  border-top-right-radius: .75rem;
}

.rounded-br-xl {
  border-bottom-right-radius: .75rem;
}

.rounded-bl-xl {
  border-bottom-left-radius: .75rem;
}

.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}

.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}

.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}

.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}

.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}

.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}

.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}

.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}

.rounded-tl-full {
  border-top-left-radius: 9999px;
}

.rounded-tr-full {
  border-top-right-radius: 9999px;
}

.rounded-br-full {
  border-bottom-right-radius: 9999px;
}

.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-double {
  border-style: double;
}

.border-none {
  border-style: none;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-8 {
  border-width: 8px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.border-r-0 {
  border-right-width: 0;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-l-0 {
  border-left-width: 0;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-r-2 {
  border-right-width: 2px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-t-4 {
  border-top-width: 4px;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-t-8 {
  border-top-width: 8px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-b-8 {
  border-bottom-width: 8px;
}

.border-l-8 {
  border-left-width: 8px;
}

.border-t {
  border-top-width: 1px;
}

.border-r {
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.cursor-auto {
  cursor: auto;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}

.cursor-text {
  cursor: text;
}

.cursor-move {
  cursor: move;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.table-caption {
  display: table-caption;
}

.table-cell {
  display: table-cell;
}

.table-column {
  display: table-column;
}

.table-column-group {
  display: table-column-group;
}

.table-footer-group {
  display: table-footer-group;
}

.table-header-group {
  display: table-header-group;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.flow-root {
  display: flow-root;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.contents {
  display: contents;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-no-wrap {
  flex-wrap: nowrap;
}

.place-items-auto {
  place-items: auto;
}

.place-items-start {
  place-items: start;
}

.place-items-end {
  place-items: end;
}

.place-items-center {
  place-items: center;
}

.place-items-stretch {
  place-items: stretch stretch;
}

.place-content-center {
  place-content: center;
}

.place-content-start {
  place-content: start;
}

.place-content-end {
  place-content: end;
}

.place-content-between {
  place-content: space-between;
}

.place-content-around {
  place-content: space-around;
}

.place-content-evenly {
  place-content: space-evenly;
}

.place-content-stretch {
  place-content: stretch;
}

.place-self-auto {
  place-self: auto;
}

.place-self-start {
  place-self: start;
}

.place-self-end {
  place-self: end;
}

.place-self-center {
  place-self: center;
}

.place-self-stretch {
  place-self: stretch stretch;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-items-auto {
  justify-items: auto;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.flex-1 {
  flex: 1;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-none {
  flex: none;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink {
  flex-shrink: 1;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.order-none {
  order: 0;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.float-none {
  float: none;
}

.clearfix:after {
  content: "";
  clear: both;
  display: table;
}

.clear-left {
  clear: left;
}

.clear-right {
  clear: right;
}

.clear-both {
  clear: both;
}

.clear-none {
  clear: none;
}

.font-sans {
  font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-serif {
  font-family: Georgia, Cambria, Times New Roman, Times, serif;
}

.font-mono {
  font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-ibmmono {
  font-family: IBM Plex Mono;
}

.font-hairline {
  font-weight: 100;
}

.font-thin {
  font-weight: 200;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-black {
  font-weight: 900;
}

.hover\:font-hairline:hover {
  font-weight: 100;
}

.hover\:font-thin:hover {
  font-weight: 200;
}

.hover\:font-light:hover {
  font-weight: 300;
}

.hover\:font-normal:hover {
  font-weight: 400;
}

.hover\:font-medium:hover {
  font-weight: 500;
}

.hover\:font-semibold:hover {
  font-weight: 600;
}

.hover\:font-bold:hover {
  font-weight: 700;
}

.hover\:font-extrabold:hover {
  font-weight: 800;
}

.hover\:font-black:hover {
  font-weight: 900;
}

.focus\:font-hairline:focus {
  font-weight: 100;
}

.focus\:font-thin:focus {
  font-weight: 200;
}

.focus\:font-light:focus {
  font-weight: 300;
}

.focus\:font-normal:focus {
  font-weight: 400;
}

.focus\:font-medium:focus {
  font-weight: 500;
}

.focus\:font-semibold:focus {
  font-weight: 600;
}

.focus\:font-bold:focus {
  font-weight: 700;
}

.focus\:font-extrabold:focus {
  font-weight: 800;
}

.focus\:font-black:focus {
  font-weight: 900;
}

.h-0 {
  height: 0;
}

.h-1 {
  height: .25rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-13 {
  height: 3.25rem;
}

.h-14 {
  height: 3.5rem;
}

.h-15 {
  height: 3.75rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.h-px {
  height: 1px;
}

.h-0\.5 {
  height: .125rem;
}

.h-1\.5 {
  height: .375rem;
}

.h-2\.5 {
  height: .625rem;
}

.h-3\.5 {
  height: .875rem;
}

.h-1\/2 {
  height: 50%;
}

.h-1\/3 {
  height: 33.3333%;
}

.h-2\/3 {
  height: 66.6667%;
}

.h-1\/4 {
  height: 25%;
}

.h-2\/4 {
  height: 50%;
}

.h-3\/4 {
  height: 75%;
}

.h-1\/5 {
  height: 20%;
}

.h-2\/5 {
  height: 40%;
}

.h-3\/5 {
  height: 60%;
}

.h-4\/5 {
  height: 80%;
}

.h-1\/6 {
  height: 16.6667%;
}

.h-2\/6 {
  height: 33.3333%;
}

.h-3\/6 {
  height: 50%;
}

.h-4\/6 {
  height: 66.6667%;
}

.h-5\/6 {
  height: 83.3333%;
}

.h-1\/12 {
  height: 8.33333%;
}

.h-2\/12 {
  height: 16.6667%;
}

.h-3\/12 {
  height: 25%;
}

.h-4\/12 {
  height: 33.3333%;
}

.h-5\/12 {
  height: 41.6667%;
}

.h-6\/12 {
  height: 50%;
}

.h-7\/12 {
  height: 58.3333%;
}

.h-8\/12 {
  height: 66.6667%;
}

.h-9\/12 {
  height: 75%;
}

.h-10\/12 {
  height: 83.3333%;
}

.h-11\/12 {
  height: 91.6667%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.text-xs {
  font-size: .75rem;
}

.text-sm {
  font-size: .875rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-xl {
  font-size: 1.25rem;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-4xl {
  font-size: 2.25rem;
}

.text-5xl {
  font-size: 3rem;
}

.text-6xl {
  font-size: 4rem;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-5 {
  line-height: 1.25rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-10 {
  line-height: 2.5rem;
}

.leading-none {
  line-height: 1;
}

.leading-tight {
  line-height: 1.25;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.leading-loose {
  line-height: 2;
}

.list-inside {
  list-style-position: inside;
}

.list-outside {
  list-style-position: outside;
}

.list-none {
  list-style-type: none;
}

.list-disc {
  list-style-type: disc;
}

.list-decimal {
  list-style-type: decimal;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.m-7 {
  margin: 1.75rem;
}

.m-8 {
  margin: 2rem;
}

.m-9 {
  margin: 2.25rem;
}

.m-10 {
  margin: 2.5rem;
}

.m-11 {
  margin: 2.75rem;
}

.m-12 {
  margin: 3rem;
}

.m-13 {
  margin: 3.25rem;
}

.m-14 {
  margin: 3.5rem;
}

.m-15 {
  margin: 3.75rem;
}

.m-16 {
  margin: 4rem;
}

.m-20 {
  margin: 5rem;
}

.m-24 {
  margin: 6rem;
}

.m-28 {
  margin: 7rem;
}

.m-32 {
  margin: 8rem;
}

.m-36 {
  margin: 9rem;
}

.m-40 {
  margin: 10rem;
}

.m-44 {
  margin: 11rem;
}

.m-48 {
  margin: 12rem;
}

.m-52 {
  margin: 13rem;
}

.m-56 {
  margin: 14rem;
}

.m-60 {
  margin: 15rem;
}

.m-64 {
  margin: 16rem;
}

.m-72 {
  margin: 18rem;
}

.m-80 {
  margin: 20rem;
}

.m-96 {
  margin: 24rem;
}

.m-auto {
  margin: auto;
}

.m-px {
  margin: 1px;
}

.m-0\.5 {
  margin: .125rem;
}

.m-1\.5 {
  margin: .375rem;
}

.m-2\.5 {
  margin: .625rem;
}

.m-3\.5 {
  margin: .875rem;
}

.m-1\/2 {
  margin: 50%;
}

.m-1\/3 {
  margin: 33.3333%;
}

.m-2\/3 {
  margin: 66.6667%;
}

.m-1\/4 {
  margin: 25%;
}

.m-2\/4 {
  margin: 50%;
}

.m-3\/4 {
  margin: 75%;
}

.m-1\/5 {
  margin: 20%;
}

.m-2\/5 {
  margin: 40%;
}

.m-3\/5 {
  margin: 60%;
}

.m-4\/5 {
  margin: 80%;
}

.m-1\/6 {
  margin: 16.6667%;
}

.m-2\/6 {
  margin: 33.3333%;
}

.m-3\/6 {
  margin: 50%;
}

.m-4\/6 {
  margin: 66.6667%;
}

.m-5\/6 {
  margin: 83.3333%;
}

.m-1\/12 {
  margin: 8.33333%;
}

.m-2\/12 {
  margin: 16.6667%;
}

.m-3\/12 {
  margin: 25%;
}

.m-4\/12 {
  margin: 33.3333%;
}

.m-5\/12 {
  margin: 41.6667%;
}

.m-6\/12 {
  margin: 50%;
}

.m-7\/12 {
  margin: 58.3333%;
}

.m-8\/12 {
  margin: 66.6667%;
}

.m-9\/12 {
  margin: 75%;
}

.m-10\/12 {
  margin: 83.3333%;
}

.m-11\/12 {
  margin: 91.6667%;
}

.m-full {
  margin: 100%;
}

.-m-1 {
  margin: -.25rem;
}

.-m-2 {
  margin: -.5rem;
}

.-m-3 {
  margin: -.75rem;
}

.-m-4 {
  margin: -1rem;
}

.-m-5 {
  margin: -1.25rem;
}

.-m-6 {
  margin: -1.5rem;
}

.-m-7 {
  margin: -1.75rem;
}

.-m-8 {
  margin: -2rem;
}

.-m-9 {
  margin: -2.25rem;
}

.-m-10 {
  margin: -2.5rem;
}

.-m-11 {
  margin: -2.75rem;
}

.-m-12 {
  margin: -3rem;
}

.-m-13 {
  margin: -3.25rem;
}

.-m-14 {
  margin: -3.5rem;
}

.-m-15 {
  margin: -3.75rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-20 {
  margin: -5rem;
}

.-m-24 {
  margin: -6rem;
}

.-m-28 {
  margin: -7rem;
}

.-m-32 {
  margin: -8rem;
}

.-m-36 {
  margin: -9rem;
}

.-m-40 {
  margin: -10rem;
}

.-m-44 {
  margin: -11rem;
}

.-m-48 {
  margin: -12rem;
}

.-m-52 {
  margin: -13rem;
}

.-m-56 {
  margin: -14rem;
}

.-m-60 {
  margin: -15rem;
}

.-m-64 {
  margin: -16rem;
}

.-m-72 {
  margin: -18rem;
}

.-m-80 {
  margin: -20rem;
}

.-m-96 {
  margin: -24rem;
}

.-m-px {
  margin: -1px;
}

.-m-0\.5 {
  margin: -.125rem;
}

.-m-1\.5 {
  margin: -.375rem;
}

.-m-2\.5 {
  margin: -.625rem;
}

.-m-3\.5 {
  margin: -.875rem;
}

.-m-1\/2 {
  margin: -50%;
}

.-m-1\/3 {
  margin: -33.3333%;
}

.-m-2\/3 {
  margin: -66.6667%;
}

.-m-1\/4 {
  margin: -25%;
}

.-m-2\/4 {
  margin: -50%;
}

.-m-3\/4 {
  margin: -75%;
}

.-m-1\/5 {
  margin: -20%;
}

.-m-2\/5 {
  margin: -40%;
}

.-m-3\/5 {
  margin: -60%;
}

.-m-4\/5 {
  margin: -80%;
}

.-m-1\/6 {
  margin: -16.6667%;
}

.-m-2\/6 {
  margin: -33.3333%;
}

.-m-3\/6 {
  margin: -50%;
}

.-m-4\/6 {
  margin: -66.6667%;
}

.-m-5\/6 {
  margin: -83.3333%;
}

.-m-1\/12 {
  margin: -8.33333%;
}

.-m-2\/12 {
  margin: -16.6667%;
}

.-m-3\/12 {
  margin: -25%;
}

.-m-4\/12 {
  margin: -33.3333%;
}

.-m-5\/12 {
  margin: -41.6667%;
}

.-m-6\/12 {
  margin: -50%;
}

.-m-7\/12 {
  margin: -58.3333%;
}

.-m-8\/12 {
  margin: -66.6667%;
}

.-m-9\/12 {
  margin: -75%;
}

.-m-10\/12 {
  margin: -83.3333%;
}

.-m-11\/12 {
  margin: -91.6667%;
}

.-m-full {
  margin: -100%;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.my-3 {
  margin-top: .75rem;
  margin-bottom: .75rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.my-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem;
}

.mx-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem;
}

.mx-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}

.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}

.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}

.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}

.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}

.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}

.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}

.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}

.my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}

.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}

.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}

.my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}

.mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}

.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}

.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}

.my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}

.mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}

.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}

.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}

.my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}

.mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}

.my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}

.mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}

.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}

.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}

.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.my-0\.5 {
  margin-top: .125rem;
  margin-bottom: .125rem;
}

.mx-0\.5 {
  margin-left: .125rem;
  margin-right: .125rem;
}

.my-1\.5 {
  margin-top: .375rem;
  margin-bottom: .375rem;
}

.mx-1\.5 {
  margin-left: .375rem;
  margin-right: .375rem;
}

.my-2\.5 {
  margin-top: .625rem;
  margin-bottom: .625rem;
}

.mx-2\.5 {
  margin-left: .625rem;
  margin-right: .625rem;
}

.my-3\.5 {
  margin-top: .875rem;
  margin-bottom: .875rem;
}

.mx-3\.5 {
  margin-left: .875rem;
  margin-right: .875rem;
}

.my-1\/2 {
  margin-top: 50%;
  margin-bottom: 50%;
}

.mx-1\/2 {
  margin-left: 50%;
  margin-right: 50%;
}

.my-1\/3 {
  margin-top: 33.3333%;
  margin-bottom: 33.3333%;
}

.mx-1\/3 {
  margin-left: 33.3333%;
  margin-right: 33.3333%;
}

.my-2\/3 {
  margin-top: 66.6667%;
  margin-bottom: 66.6667%;
}

.mx-2\/3 {
  margin-left: 66.6667%;
  margin-right: 66.6667%;
}

.my-1\/4 {
  margin-top: 25%;
  margin-bottom: 25%;
}

.mx-1\/4 {
  margin-left: 25%;
  margin-right: 25%;
}

.my-2\/4 {
  margin-top: 50%;
  margin-bottom: 50%;
}

.mx-2\/4 {
  margin-left: 50%;
  margin-right: 50%;
}

.my-3\/4 {
  margin-top: 75%;
  margin-bottom: 75%;
}

.mx-3\/4 {
  margin-left: 75%;
  margin-right: 75%;
}

.my-1\/5 {
  margin-top: 20%;
  margin-bottom: 20%;
}

.mx-1\/5 {
  margin-left: 20%;
  margin-right: 20%;
}

.my-2\/5 {
  margin-top: 40%;
  margin-bottom: 40%;
}

.mx-2\/5 {
  margin-left: 40%;
  margin-right: 40%;
}

.my-3\/5 {
  margin-top: 60%;
  margin-bottom: 60%;
}

.mx-3\/5 {
  margin-left: 60%;
  margin-right: 60%;
}

.my-4\/5 {
  margin-top: 80%;
  margin-bottom: 80%;
}

.mx-4\/5 {
  margin-left: 80%;
  margin-right: 80%;
}

.my-1\/6 {
  margin-top: 16.6667%;
  margin-bottom: 16.6667%;
}

.mx-1\/6 {
  margin-left: 16.6667%;
  margin-right: 16.6667%;
}

.my-2\/6 {
  margin-top: 33.3333%;
  margin-bottom: 33.3333%;
}

.mx-2\/6 {
  margin-left: 33.3333%;
  margin-right: 33.3333%;
}

.my-3\/6 {
  margin-top: 50%;
  margin-bottom: 50%;
}

.mx-3\/6 {
  margin-left: 50%;
  margin-right: 50%;
}

.my-4\/6 {
  margin-top: 66.6667%;
  margin-bottom: 66.6667%;
}

.mx-4\/6 {
  margin-left: 66.6667%;
  margin-right: 66.6667%;
}

.my-5\/6 {
  margin-top: 83.3333%;
  margin-bottom: 83.3333%;
}

.mx-5\/6 {
  margin-left: 83.3333%;
  margin-right: 83.3333%;
}

.my-1\/12 {
  margin-top: 8.33333%;
  margin-bottom: 8.33333%;
}

.mx-1\/12 {
  margin-left: 8.33333%;
  margin-right: 8.33333%;
}

.my-2\/12 {
  margin-top: 16.6667%;
  margin-bottom: 16.6667%;
}

.mx-2\/12 {
  margin-left: 16.6667%;
  margin-right: 16.6667%;
}

.my-3\/12 {
  margin-top: 25%;
  margin-bottom: 25%;
}

.mx-3\/12 {
  margin-left: 25%;
  margin-right: 25%;
}

.my-4\/12 {
  margin-top: 33.3333%;
  margin-bottom: 33.3333%;
}

.mx-4\/12 {
  margin-left: 33.3333%;
  margin-right: 33.3333%;
}

.my-5\/12 {
  margin-top: 41.6667%;
  margin-bottom: 41.6667%;
}

.mx-5\/12 {
  margin-left: 41.6667%;
  margin-right: 41.6667%;
}

.my-6\/12 {
  margin-top: 50%;
  margin-bottom: 50%;
}

.mx-6\/12 {
  margin-left: 50%;
  margin-right: 50%;
}

.my-7\/12 {
  margin-top: 58.3333%;
  margin-bottom: 58.3333%;
}

.mx-7\/12 {
  margin-left: 58.3333%;
  margin-right: 58.3333%;
}

.my-8\/12 {
  margin-top: 66.6667%;
  margin-bottom: 66.6667%;
}

.mx-8\/12 {
  margin-left: 66.6667%;
  margin-right: 66.6667%;
}

.my-9\/12 {
  margin-top: 75%;
  margin-bottom: 75%;
}

.mx-9\/12 {
  margin-left: 75%;
  margin-right: 75%;
}

.my-10\/12 {
  margin-top: 83.3333%;
  margin-bottom: 83.3333%;
}

.mx-10\/12 {
  margin-left: 83.3333%;
  margin-right: 83.3333%;
}

.my-11\/12 {
  margin-top: 91.6667%;
  margin-bottom: 91.6667%;
}

.mx-11\/12 {
  margin-left: 91.6667%;
  margin-right: 91.6667%;
}

.my-full {
  margin-top: 100%;
  margin-bottom: 100%;
}

.mx-full {
  margin-left: 100%;
  margin-right: 100%;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.-mx-1 {
  margin-left: -.25rem;
  margin-right: -.25rem;
}

.-my-2 {
  margin-top: -.5rem;
  margin-bottom: -.5rem;
}

.-mx-2 {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.-my-3 {
  margin-top: -.75rem;
  margin-bottom: -.75rem;
}

.-mx-3 {
  margin-left: -.75rem;
  margin-right: -.75rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}

.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}

.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}

.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}

.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}

.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}

.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}

.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}

.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}

.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}

.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}

.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}

.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}

.-my-13 {
  margin-top: -3.25rem;
  margin-bottom: -3.25rem;
}

.-mx-13 {
  margin-left: -3.25rem;
  margin-right: -3.25rem;
}

.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}

.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}

.-my-15 {
  margin-top: -3.75rem;
  margin-bottom: -3.75rem;
}

.-mx-15 {
  margin-left: -3.75rem;
  margin-right: -3.75rem;
}

.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}

.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}

.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}

.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}

.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}

.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}

.-my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}

.-mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}

.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}

.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}

.-my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}

.-mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}

.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}

.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}

.-my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}

.-mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}

.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}

.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}

.-my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}

.-mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}

.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}

.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}

.-my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}

.-mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}

.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}

.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}

.-my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}

.-mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}

.-my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}

.-mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}

.-my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}

.-mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}

.-my-0\.5 {
  margin-top: -.125rem;
  margin-bottom: -.125rem;
}

.-mx-0\.5 {
  margin-left: -.125rem;
  margin-right: -.125rem;
}

.-my-1\.5 {
  margin-top: -.375rem;
  margin-bottom: -.375rem;
}

.-mx-1\.5 {
  margin-left: -.375rem;
  margin-right: -.375rem;
}

.-my-2\.5 {
  margin-top: -.625rem;
  margin-bottom: -.625rem;
}

.-mx-2\.5 {
  margin-left: -.625rem;
  margin-right: -.625rem;
}

.-my-3\.5 {
  margin-top: -.875rem;
  margin-bottom: -.875rem;
}

.-mx-3\.5 {
  margin-left: -.875rem;
  margin-right: -.875rem;
}

.-my-1\/2 {
  margin-top: -50%;
  margin-bottom: -50%;
}

.-mx-1\/2 {
  margin-left: -50%;
  margin-right: -50%;
}

.-my-1\/3 {
  margin-top: -33.3333%;
  margin-bottom: -33.3333%;
}

.-mx-1\/3 {
  margin-left: -33.3333%;
  margin-right: -33.3333%;
}

.-my-2\/3 {
  margin-top: -66.6667%;
  margin-bottom: -66.6667%;
}

.-mx-2\/3 {
  margin-left: -66.6667%;
  margin-right: -66.6667%;
}

.-my-1\/4 {
  margin-top: -25%;
  margin-bottom: -25%;
}

.-mx-1\/4 {
  margin-left: -25%;
  margin-right: -25%;
}

.-my-2\/4 {
  margin-top: -50%;
  margin-bottom: -50%;
}

.-mx-2\/4 {
  margin-left: -50%;
  margin-right: -50%;
}

.-my-3\/4 {
  margin-top: -75%;
  margin-bottom: -75%;
}

.-mx-3\/4 {
  margin-left: -75%;
  margin-right: -75%;
}

.-my-1\/5 {
  margin-top: -20%;
  margin-bottom: -20%;
}

.-mx-1\/5 {
  margin-left: -20%;
  margin-right: -20%;
}

.-my-2\/5 {
  margin-top: -40%;
  margin-bottom: -40%;
}

.-mx-2\/5 {
  margin-left: -40%;
  margin-right: -40%;
}

.-my-3\/5 {
  margin-top: -60%;
  margin-bottom: -60%;
}

.-mx-3\/5 {
  margin-left: -60%;
  margin-right: -60%;
}

.-my-4\/5 {
  margin-top: -80%;
  margin-bottom: -80%;
}

.-mx-4\/5 {
  margin-left: -80%;
  margin-right: -80%;
}

.-my-1\/6 {
  margin-top: -16.6667%;
  margin-bottom: -16.6667%;
}

.-mx-1\/6 {
  margin-left: -16.6667%;
  margin-right: -16.6667%;
}

.-my-2\/6 {
  margin-top: -33.3333%;
  margin-bottom: -33.3333%;
}

.-mx-2\/6 {
  margin-left: -33.3333%;
  margin-right: -33.3333%;
}

.-my-3\/6 {
  margin-top: -50%;
  margin-bottom: -50%;
}

.-mx-3\/6 {
  margin-left: -50%;
  margin-right: -50%;
}

.-my-4\/6 {
  margin-top: -66.6667%;
  margin-bottom: -66.6667%;
}

.-mx-4\/6 {
  margin-left: -66.6667%;
  margin-right: -66.6667%;
}

.-my-5\/6 {
  margin-top: -83.3333%;
  margin-bottom: -83.3333%;
}

.-mx-5\/6 {
  margin-left: -83.3333%;
  margin-right: -83.3333%;
}

.-my-1\/12 {
  margin-top: -8.33333%;
  margin-bottom: -8.33333%;
}

.-mx-1\/12 {
  margin-left: -8.33333%;
  margin-right: -8.33333%;
}

.-my-2\/12 {
  margin-top: -16.6667%;
  margin-bottom: -16.6667%;
}

.-mx-2\/12 {
  margin-left: -16.6667%;
  margin-right: -16.6667%;
}

.-my-3\/12 {
  margin-top: -25%;
  margin-bottom: -25%;
}

.-mx-3\/12 {
  margin-left: -25%;
  margin-right: -25%;
}

.-my-4\/12 {
  margin-top: -33.3333%;
  margin-bottom: -33.3333%;
}

.-mx-4\/12 {
  margin-left: -33.3333%;
  margin-right: -33.3333%;
}

.-my-5\/12 {
  margin-top: -41.6667%;
  margin-bottom: -41.6667%;
}

.-mx-5\/12 {
  margin-left: -41.6667%;
  margin-right: -41.6667%;
}

.-my-6\/12 {
  margin-top: -50%;
  margin-bottom: -50%;
}

.-mx-6\/12 {
  margin-left: -50%;
  margin-right: -50%;
}

.-my-7\/12 {
  margin-top: -58.3333%;
  margin-bottom: -58.3333%;
}

.-mx-7\/12 {
  margin-left: -58.3333%;
  margin-right: -58.3333%;
}

.-my-8\/12 {
  margin-top: -66.6667%;
  margin-bottom: -66.6667%;
}

.-mx-8\/12 {
  margin-left: -66.6667%;
  margin-right: -66.6667%;
}

.-my-9\/12 {
  margin-top: -75%;
  margin-bottom: -75%;
}

.-mx-9\/12 {
  margin-left: -75%;
  margin-right: -75%;
}

.-my-10\/12 {
  margin-top: -83.3333%;
  margin-bottom: -83.3333%;
}

.-mx-10\/12 {
  margin-left: -83.3333%;
  margin-right: -83.3333%;
}

.-my-11\/12 {
  margin-top: -91.6667%;
  margin-bottom: -91.6667%;
}

.-mx-11\/12 {
  margin-left: -91.6667%;
  margin-right: -91.6667%;
}

.-my-full {
  margin-top: -100%;
  margin-bottom: -100%;
}

.-mx-full {
  margin-left: -100%;
  margin-right: -100%;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mr-1 {
  margin-right: .25rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.ml-1 {
  margin-left: .25rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.ml-3 {
  margin-left: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mr-9 {
  margin-right: 2.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-9 {
  margin-left: 2.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mr-10 {
  margin-right: 2.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.mt-11 {
  margin-top: 2.75rem;
}

.mr-11 {
  margin-right: 2.75rem;
}

.mb-11 {
  margin-bottom: 2.75rem;
}

.ml-11 {
  margin-left: 2.75rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mt-13 {
  margin-top: 3.25rem;
}

.mr-13 {
  margin-right: 3.25rem;
}

.mb-13 {
  margin-bottom: 3.25rem;
}

.ml-13 {
  margin-left: 3.25rem;
}

.mt-14 {
  margin-top: 3.5rem;
}

.mr-14 {
  margin-right: 3.5rem;
}

.mb-14 {
  margin-bottom: 3.5rem;
}

.ml-14 {
  margin-left: 3.5rem;
}

.mt-15 {
  margin-top: 3.75rem;
}

.mr-15 {
  margin-right: 3.75rem;
}

.mb-15 {
  margin-bottom: 3.75rem;
}

.ml-15 {
  margin-left: 3.75rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mr-16 {
  margin-right: 4rem;
}

.mb-16 {
  margin-bottom: 4rem;
}

.ml-16 {
  margin-left: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mr-20 {
  margin-right: 5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.ml-20 {
  margin-left: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mr-24 {
  margin-right: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.ml-24 {
  margin-left: 6rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mr-28 {
  margin-right: 7rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.ml-28 {
  margin-left: 7rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mr-32 {
  margin-right: 8rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.ml-32 {
  margin-left: 8rem;
}

.mt-36 {
  margin-top: 9rem;
}

.mr-36 {
  margin-right: 9rem;
}

.mb-36 {
  margin-bottom: 9rem;
}

.ml-36 {
  margin-left: 9rem;
}

.mt-40 {
  margin-top: 10rem;
}

.mr-40 {
  margin-right: 10rem;
}

.mb-40 {
  margin-bottom: 10rem;
}

.ml-40 {
  margin-left: 10rem;
}

.mt-44 {
  margin-top: 11rem;
}

.mr-44 {
  margin-right: 11rem;
}

.mb-44 {
  margin-bottom: 11rem;
}

.ml-44 {
  margin-left: 11rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-48 {
  margin-right: 12rem;
}

.mb-48 {
  margin-bottom: 12rem;
}

.ml-48 {
  margin-left: 12rem;
}

.mt-52 {
  margin-top: 13rem;
}

.mr-52 {
  margin-right: 13rem;
}

.mb-52 {
  margin-bottom: 13rem;
}

.ml-52 {
  margin-left: 13rem;
}

.mt-56 {
  margin-top: 14rem;
}

.mr-56 {
  margin-right: 14rem;
}

.mb-56 {
  margin-bottom: 14rem;
}

.ml-56 {
  margin-left: 14rem;
}

.mt-60 {
  margin-top: 15rem;
}

.mr-60 {
  margin-right: 15rem;
}

.mb-60 {
  margin-bottom: 15rem;
}

.ml-60 {
  margin-left: 15rem;
}

.mt-64 {
  margin-top: 16rem;
}

.mr-64 {
  margin-right: 16rem;
}

.mb-64 {
  margin-bottom: 16rem;
}

.ml-64 {
  margin-left: 16rem;
}

.mt-72 {
  margin-top: 18rem;
}

.mr-72 {
  margin-right: 18rem;
}

.mb-72 {
  margin-bottom: 18rem;
}

.ml-72 {
  margin-left: 18rem;
}

.mt-80 {
  margin-top: 20rem;
}

.mr-80 {
  margin-right: 20rem;
}

.mb-80 {
  margin-bottom: 20rem;
}

.ml-80 {
  margin-left: 20rem;
}

.mt-96 {
  margin-top: 24rem;
}

.mr-96 {
  margin-right: 24rem;
}

.mb-96 {
  margin-bottom: 24rem;
}

.ml-96 {
  margin-left: 24rem;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-px {
  margin-top: 1px;
}

.mr-px {
  margin-right: 1px;
}

.mb-px {
  margin-bottom: 1px;
}

.ml-px {
  margin-left: 1px;
}

.mt-0\.5 {
  margin-top: .125rem;
}

.mr-0\.5 {
  margin-right: .125rem;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.mt-1\.5 {
  margin-top: .375rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mb-1\.5 {
  margin-bottom: .375rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.mt-2\.5 {
  margin-top: .625rem;
}

.mr-2\.5 {
  margin-right: .625rem;
}

.mb-2\.5 {
  margin-bottom: .625rem;
}

.ml-2\.5 {
  margin-left: .625rem;
}

.mt-3\.5 {
  margin-top: .875rem;
}

.mr-3\.5 {
  margin-right: .875rem;
}

.mb-3\.5 {
  margin-bottom: .875rem;
}

.ml-3\.5 {
  margin-left: .875rem;
}

.mt-1\/2 {
  margin-top: 50%;
}

.mr-1\/2 {
  margin-right: 50%;
}

.mb-1\/2 {
  margin-bottom: 50%;
}

.ml-1\/2 {
  margin-left: 50%;
}

.mt-1\/3 {
  margin-top: 33.3333%;
}

.mr-1\/3 {
  margin-right: 33.3333%;
}

.mb-1\/3 {
  margin-bottom: 33.3333%;
}

.ml-1\/3 {
  margin-left: 33.3333%;
}

.mt-2\/3 {
  margin-top: 66.6667%;
}

.mr-2\/3 {
  margin-right: 66.6667%;
}

.mb-2\/3 {
  margin-bottom: 66.6667%;
}

.ml-2\/3 {
  margin-left: 66.6667%;
}

.mt-1\/4 {
  margin-top: 25%;
}

.mr-1\/4 {
  margin-right: 25%;
}

.mb-1\/4 {
  margin-bottom: 25%;
}

.ml-1\/4 {
  margin-left: 25%;
}

.mt-2\/4 {
  margin-top: 50%;
}

.mr-2\/4 {
  margin-right: 50%;
}

.mb-2\/4 {
  margin-bottom: 50%;
}

.ml-2\/4 {
  margin-left: 50%;
}

.mt-3\/4 {
  margin-top: 75%;
}

.mr-3\/4 {
  margin-right: 75%;
}

.mb-3\/4 {
  margin-bottom: 75%;
}

.ml-3\/4 {
  margin-left: 75%;
}

.mt-1\/5 {
  margin-top: 20%;
}

.mr-1\/5 {
  margin-right: 20%;
}

.mb-1\/5 {
  margin-bottom: 20%;
}

.ml-1\/5 {
  margin-left: 20%;
}

.mt-2\/5 {
  margin-top: 40%;
}

.mr-2\/5 {
  margin-right: 40%;
}

.mb-2\/5 {
  margin-bottom: 40%;
}

.ml-2\/5 {
  margin-left: 40%;
}

.mt-3\/5 {
  margin-top: 60%;
}

.mr-3\/5 {
  margin-right: 60%;
}

.mb-3\/5 {
  margin-bottom: 60%;
}

.ml-3\/5 {
  margin-left: 60%;
}

.mt-4\/5 {
  margin-top: 80%;
}

.mr-4\/5 {
  margin-right: 80%;
}

.mb-4\/5 {
  margin-bottom: 80%;
}

.ml-4\/5 {
  margin-left: 80%;
}

.mt-1\/6 {
  margin-top: 16.6667%;
}

.mr-1\/6 {
  margin-right: 16.6667%;
}

.mb-1\/6 {
  margin-bottom: 16.6667%;
}

.ml-1\/6 {
  margin-left: 16.6667%;
}

.mt-2\/6 {
  margin-top: 33.3333%;
}

.mr-2\/6 {
  margin-right: 33.3333%;
}

.mb-2\/6 {
  margin-bottom: 33.3333%;
}

.ml-2\/6 {
  margin-left: 33.3333%;
}

.mt-3\/6 {
  margin-top: 50%;
}

.mr-3\/6 {
  margin-right: 50%;
}

.mb-3\/6 {
  margin-bottom: 50%;
}

.ml-3\/6 {
  margin-left: 50%;
}

.mt-4\/6 {
  margin-top: 66.6667%;
}

.mr-4\/6 {
  margin-right: 66.6667%;
}

.mb-4\/6 {
  margin-bottom: 66.6667%;
}

.ml-4\/6 {
  margin-left: 66.6667%;
}

.mt-5\/6 {
  margin-top: 83.3333%;
}

.mr-5\/6 {
  margin-right: 83.3333%;
}

.mb-5\/6 {
  margin-bottom: 83.3333%;
}

.ml-5\/6 {
  margin-left: 83.3333%;
}

.mt-1\/12 {
  margin-top: 8.33333%;
}

.mr-1\/12 {
  margin-right: 8.33333%;
}

.mb-1\/12 {
  margin-bottom: 8.33333%;
}

.ml-1\/12 {
  margin-left: 8.33333%;
}

.mt-2\/12 {
  margin-top: 16.6667%;
}

.mr-2\/12 {
  margin-right: 16.6667%;
}

.mb-2\/12 {
  margin-bottom: 16.6667%;
}

.ml-2\/12 {
  margin-left: 16.6667%;
}

.mt-3\/12 {
  margin-top: 25%;
}

.mr-3\/12 {
  margin-right: 25%;
}

.mb-3\/12 {
  margin-bottom: 25%;
}

.ml-3\/12 {
  margin-left: 25%;
}

.mt-4\/12 {
  margin-top: 33.3333%;
}

.mr-4\/12 {
  margin-right: 33.3333%;
}

.mb-4\/12 {
  margin-bottom: 33.3333%;
}

.ml-4\/12 {
  margin-left: 33.3333%;
}

.mt-5\/12 {
  margin-top: 41.6667%;
}

.mr-5\/12 {
  margin-right: 41.6667%;
}

.mb-5\/12 {
  margin-bottom: 41.6667%;
}

.ml-5\/12 {
  margin-left: 41.6667%;
}

.mt-6\/12 {
  margin-top: 50%;
}

.mr-6\/12 {
  margin-right: 50%;
}

.mb-6\/12 {
  margin-bottom: 50%;
}

.ml-6\/12 {
  margin-left: 50%;
}

.mt-7\/12 {
  margin-top: 58.3333%;
}

.mr-7\/12 {
  margin-right: 58.3333%;
}

.mb-7\/12 {
  margin-bottom: 58.3333%;
}

.ml-7\/12 {
  margin-left: 58.3333%;
}

.mt-8\/12 {
  margin-top: 66.6667%;
}

.mr-8\/12 {
  margin-right: 66.6667%;
}

.mb-8\/12 {
  margin-bottom: 66.6667%;
}

.ml-8\/12 {
  margin-left: 66.6667%;
}

.mt-9\/12 {
  margin-top: 75%;
}

.mr-9\/12 {
  margin-right: 75%;
}

.mb-9\/12 {
  margin-bottom: 75%;
}

.ml-9\/12 {
  margin-left: 75%;
}

.mt-10\/12 {
  margin-top: 83.3333%;
}

.mr-10\/12 {
  margin-right: 83.3333%;
}

.mb-10\/12 {
  margin-bottom: 83.3333%;
}

.ml-10\/12 {
  margin-left: 83.3333%;
}

.mt-11\/12 {
  margin-top: 91.6667%;
}

.mr-11\/12 {
  margin-right: 91.6667%;
}

.mb-11\/12 {
  margin-bottom: 91.6667%;
}

.ml-11\/12 {
  margin-left: 91.6667%;
}

.mt-full {
  margin-top: 100%;
}

.mr-full {
  margin-right: 100%;
}

.mb-full {
  margin-bottom: 100%;
}

.ml-full {
  margin-left: 100%;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mb-2 {
  margin-bottom: -.5rem;
}

.-ml-2 {
  margin-left: -.5rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mr-3 {
  margin-right: -.75rem;
}

.-mb-3 {
  margin-bottom: -.75rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mr-4 {
  margin-right: -1rem;
}

.-mb-4 {
  margin-bottom: -1rem;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-5 {
  margin-top: -1.25rem;
}

.-mr-5 {
  margin-right: -1.25rem;
}

.-mb-5 {
  margin-bottom: -1.25rem;
}

.-ml-5 {
  margin-left: -1.25rem;
}

.-mt-6 {
  margin-top: -1.5rem;
}

.-mr-6 {
  margin-right: -1.5rem;
}

.-mb-6 {
  margin-bottom: -1.5rem;
}

.-ml-6 {
  margin-left: -1.5rem;
}

.-mt-7 {
  margin-top: -1.75rem;
}

.-mr-7 {
  margin-right: -1.75rem;
}

.-mb-7 {
  margin-bottom: -1.75rem;
}

.-ml-7 {
  margin-left: -1.75rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mr-8 {
  margin-right: -2rem;
}

.-mb-8 {
  margin-bottom: -2rem;
}

.-ml-8 {
  margin-left: -2rem;
}

.-mt-9 {
  margin-top: -2.25rem;
}

.-mr-9 {
  margin-right: -2.25rem;
}

.-mb-9 {
  margin-bottom: -2.25rem;
}

.-ml-9 {
  margin-left: -2.25rem;
}

.-mt-10 {
  margin-top: -2.5rem;
}

.-mr-10 {
  margin-right: -2.5rem;
}

.-mb-10 {
  margin-bottom: -2.5rem;
}

.-ml-10 {
  margin-left: -2.5rem;
}

.-mt-11 {
  margin-top: -2.75rem;
}

.-mr-11 {
  margin-right: -2.75rem;
}

.-mb-11 {
  margin-bottom: -2.75rem;
}

.-ml-11 {
  margin-left: -2.75rem;
}

.-mt-12 {
  margin-top: -3rem;
}

.-mr-12 {
  margin-right: -3rem;
}

.-mb-12 {
  margin-bottom: -3rem;
}

.-ml-12 {
  margin-left: -3rem;
}

.-mt-13 {
  margin-top: -3.25rem;
}

.-mr-13 {
  margin-right: -3.25rem;
}

.-mb-13 {
  margin-bottom: -3.25rem;
}

.-ml-13 {
  margin-left: -3.25rem;
}

.-mt-14 {
  margin-top: -3.5rem;
}

.-mr-14 {
  margin-right: -3.5rem;
}

.-mb-14 {
  margin-bottom: -3.5rem;
}

.-ml-14 {
  margin-left: -3.5rem;
}

.-mt-15 {
  margin-top: -3.75rem;
}

.-mr-15 {
  margin-right: -3.75rem;
}

.-mb-15 {
  margin-bottom: -3.75rem;
}

.-ml-15 {
  margin-left: -3.75rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.-mr-16 {
  margin-right: -4rem;
}

.-mb-16 {
  margin-bottom: -4rem;
}

.-ml-16 {
  margin-left: -4rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-mr-20 {
  margin-right: -5rem;
}

.-mb-20 {
  margin-bottom: -5rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mr-24 {
  margin-right: -6rem;
}

.-mb-24 {
  margin-bottom: -6rem;
}

.-ml-24 {
  margin-left: -6rem;
}

.-mt-28 {
  margin-top: -7rem;
}

.-mr-28 {
  margin-right: -7rem;
}

.-mb-28 {
  margin-bottom: -7rem;
}

.-ml-28 {
  margin-left: -7rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mr-32 {
  margin-right: -8rem;
}

.-mb-32 {
  margin-bottom: -8rem;
}

.-ml-32 {
  margin-left: -8rem;
}

.-mt-36 {
  margin-top: -9rem;
}

.-mr-36 {
  margin-right: -9rem;
}

.-mb-36 {
  margin-bottom: -9rem;
}

.-ml-36 {
  margin-left: -9rem;
}

.-mt-40 {
  margin-top: -10rem;
}

.-mr-40 {
  margin-right: -10rem;
}

.-mb-40 {
  margin-bottom: -10rem;
}

.-ml-40 {
  margin-left: -10rem;
}

.-mt-44 {
  margin-top: -11rem;
}

.-mr-44 {
  margin-right: -11rem;
}

.-mb-44 {
  margin-bottom: -11rem;
}

.-ml-44 {
  margin-left: -11rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mr-48 {
  margin-right: -12rem;
}

.-mb-48 {
  margin-bottom: -12rem;
}

.-ml-48 {
  margin-left: -12rem;
}

.-mt-52 {
  margin-top: -13rem;
}

.-mr-52 {
  margin-right: -13rem;
}

.-mb-52 {
  margin-bottom: -13rem;
}

.-ml-52 {
  margin-left: -13rem;
}

.-mt-56 {
  margin-top: -14rem;
}

.-mr-56 {
  margin-right: -14rem;
}

.-mb-56 {
  margin-bottom: -14rem;
}

.-ml-56 {
  margin-left: -14rem;
}

.-mt-60 {
  margin-top: -15rem;
}

.-mr-60 {
  margin-right: -15rem;
}

.-mb-60 {
  margin-bottom: -15rem;
}

.-ml-60 {
  margin-left: -15rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.-mr-64 {
  margin-right: -16rem;
}

.-mb-64 {
  margin-bottom: -16rem;
}

.-ml-64 {
  margin-left: -16rem;
}

.-mt-72 {
  margin-top: -18rem;
}

.-mr-72 {
  margin-right: -18rem;
}

.-mb-72 {
  margin-bottom: -18rem;
}

.-ml-72 {
  margin-left: -18rem;
}

.-mt-80 {
  margin-top: -20rem;
}

.-mr-80 {
  margin-right: -20rem;
}

.-mb-80 {
  margin-bottom: -20rem;
}

.-ml-80 {
  margin-left: -20rem;
}

.-mt-96 {
  margin-top: -24rem;
}

.-mr-96 {
  margin-right: -24rem;
}

.-mb-96 {
  margin-bottom: -24rem;
}

.-ml-96 {
  margin-left: -24rem;
}

.-mt-px {
  margin-top: -1px;
}

.-mr-px {
  margin-right: -1px;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-px {
  margin-left: -1px;
}

.-mt-0\.5 {
  margin-top: -.125rem;
}

.-mr-0\.5 {
  margin-right: -.125rem;
}

.-mb-0\.5 {
  margin-bottom: -.125rem;
}

.-ml-0\.5 {
  margin-left: -.125rem;
}

.-mt-1\.5 {
  margin-top: -.375rem;
}

.-mr-1\.5 {
  margin-right: -.375rem;
}

.-mb-1\.5 {
  margin-bottom: -.375rem;
}

.-ml-1\.5 {
  margin-left: -.375rem;
}

.-mt-2\.5 {
  margin-top: -.625rem;
}

.-mr-2\.5 {
  margin-right: -.625rem;
}

.-mb-2\.5 {
  margin-bottom: -.625rem;
}

.-ml-2\.5 {
  margin-left: -.625rem;
}

.-mt-3\.5 {
  margin-top: -.875rem;
}

.-mr-3\.5 {
  margin-right: -.875rem;
}

.-mb-3\.5 {
  margin-bottom: -.875rem;
}

.-ml-3\.5 {
  margin-left: -.875rem;
}

.-mt-1\/2 {
  margin-top: -50%;
}

.-mr-1\/2 {
  margin-right: -50%;
}

.-mb-1\/2 {
  margin-bottom: -50%;
}

.-ml-1\/2 {
  margin-left: -50%;
}

.-mt-1\/3 {
  margin-top: -33.3333%;
}

.-mr-1\/3 {
  margin-right: -33.3333%;
}

.-mb-1\/3 {
  margin-bottom: -33.3333%;
}

.-ml-1\/3 {
  margin-left: -33.3333%;
}

.-mt-2\/3 {
  margin-top: -66.6667%;
}

.-mr-2\/3 {
  margin-right: -66.6667%;
}

.-mb-2\/3 {
  margin-bottom: -66.6667%;
}

.-ml-2\/3 {
  margin-left: -66.6667%;
}

.-mt-1\/4 {
  margin-top: -25%;
}

.-mr-1\/4 {
  margin-right: -25%;
}

.-mb-1\/4 {
  margin-bottom: -25%;
}

.-ml-1\/4 {
  margin-left: -25%;
}

.-mt-2\/4 {
  margin-top: -50%;
}

.-mr-2\/4 {
  margin-right: -50%;
}

.-mb-2\/4 {
  margin-bottom: -50%;
}

.-ml-2\/4 {
  margin-left: -50%;
}

.-mt-3\/4 {
  margin-top: -75%;
}

.-mr-3\/4 {
  margin-right: -75%;
}

.-mb-3\/4 {
  margin-bottom: -75%;
}

.-ml-3\/4 {
  margin-left: -75%;
}

.-mt-1\/5 {
  margin-top: -20%;
}

.-mr-1\/5 {
  margin-right: -20%;
}

.-mb-1\/5 {
  margin-bottom: -20%;
}

.-ml-1\/5 {
  margin-left: -20%;
}

.-mt-2\/5 {
  margin-top: -40%;
}

.-mr-2\/5 {
  margin-right: -40%;
}

.-mb-2\/5 {
  margin-bottom: -40%;
}

.-ml-2\/5 {
  margin-left: -40%;
}

.-mt-3\/5 {
  margin-top: -60%;
}

.-mr-3\/5 {
  margin-right: -60%;
}

.-mb-3\/5 {
  margin-bottom: -60%;
}

.-ml-3\/5 {
  margin-left: -60%;
}

.-mt-4\/5 {
  margin-top: -80%;
}

.-mr-4\/5 {
  margin-right: -80%;
}

.-mb-4\/5 {
  margin-bottom: -80%;
}

.-ml-4\/5 {
  margin-left: -80%;
}

.-mt-1\/6 {
  margin-top: -16.6667%;
}

.-mr-1\/6 {
  margin-right: -16.6667%;
}

.-mb-1\/6 {
  margin-bottom: -16.6667%;
}

.-ml-1\/6 {
  margin-left: -16.6667%;
}

.-mt-2\/6 {
  margin-top: -33.3333%;
}

.-mr-2\/6 {
  margin-right: -33.3333%;
}

.-mb-2\/6 {
  margin-bottom: -33.3333%;
}

.-ml-2\/6 {
  margin-left: -33.3333%;
}

.-mt-3\/6 {
  margin-top: -50%;
}

.-mr-3\/6 {
  margin-right: -50%;
}

.-mb-3\/6 {
  margin-bottom: -50%;
}

.-ml-3\/6 {
  margin-left: -50%;
}

.-mt-4\/6 {
  margin-top: -66.6667%;
}

.-mr-4\/6 {
  margin-right: -66.6667%;
}

.-mb-4\/6 {
  margin-bottom: -66.6667%;
}

.-ml-4\/6 {
  margin-left: -66.6667%;
}

.-mt-5\/6 {
  margin-top: -83.3333%;
}

.-mr-5\/6 {
  margin-right: -83.3333%;
}

.-mb-5\/6 {
  margin-bottom: -83.3333%;
}

.-ml-5\/6 {
  margin-left: -83.3333%;
}

.-mt-1\/12 {
  margin-top: -8.33333%;
}

.-mr-1\/12 {
  margin-right: -8.33333%;
}

.-mb-1\/12 {
  margin-bottom: -8.33333%;
}

.-ml-1\/12 {
  margin-left: -8.33333%;
}

.-mt-2\/12 {
  margin-top: -16.6667%;
}

.-mr-2\/12 {
  margin-right: -16.6667%;
}

.-mb-2\/12 {
  margin-bottom: -16.6667%;
}

.-ml-2\/12 {
  margin-left: -16.6667%;
}

.-mt-3\/12 {
  margin-top: -25%;
}

.-mr-3\/12 {
  margin-right: -25%;
}

.-mb-3\/12 {
  margin-bottom: -25%;
}

.-ml-3\/12 {
  margin-left: -25%;
}

.-mt-4\/12 {
  margin-top: -33.3333%;
}

.-mr-4\/12 {
  margin-right: -33.3333%;
}

.-mb-4\/12 {
  margin-bottom: -33.3333%;
}

.-ml-4\/12 {
  margin-left: -33.3333%;
}

.-mt-5\/12 {
  margin-top: -41.6667%;
}

.-mr-5\/12 {
  margin-right: -41.6667%;
}

.-mb-5\/12 {
  margin-bottom: -41.6667%;
}

.-ml-5\/12 {
  margin-left: -41.6667%;
}

.-mt-6\/12 {
  margin-top: -50%;
}

.-mr-6\/12 {
  margin-right: -50%;
}

.-mb-6\/12 {
  margin-bottom: -50%;
}

.-ml-6\/12 {
  margin-left: -50%;
}

.-mt-7\/12 {
  margin-top: -58.3333%;
}

.-mr-7\/12 {
  margin-right: -58.3333%;
}

.-mb-7\/12 {
  margin-bottom: -58.3333%;
}

.-ml-7\/12 {
  margin-left: -58.3333%;
}

.-mt-8\/12 {
  margin-top: -66.6667%;
}

.-mr-8\/12 {
  margin-right: -66.6667%;
}

.-mb-8\/12 {
  margin-bottom: -66.6667%;
}

.-ml-8\/12 {
  margin-left: -66.6667%;
}

.-mt-9\/12 {
  margin-top: -75%;
}

.-mr-9\/12 {
  margin-right: -75%;
}

.-mb-9\/12 {
  margin-bottom: -75%;
}

.-ml-9\/12 {
  margin-left: -75%;
}

.-mt-10\/12 {
  margin-top: -83.3333%;
}

.-mr-10\/12 {
  margin-right: -83.3333%;
}

.-mb-10\/12 {
  margin-bottom: -83.3333%;
}

.-ml-10\/12 {
  margin-left: -83.3333%;
}

.-mt-11\/12 {
  margin-top: -91.6667%;
}

.-mr-11\/12 {
  margin-right: -91.6667%;
}

.-mb-11\/12 {
  margin-bottom: -91.6667%;
}

.-ml-11\/12 {
  margin-left: -91.6667%;
}

.-mt-full {
  margin-top: -100%;
}

.-mr-full {
  margin-right: -100%;
}

.-mb-full {
  margin-bottom: -100%;
}

.-ml-full {
  margin-left: -100%;
}

.max-h-0 {
  max-height: 0;
}

.max-h-1 {
  max-height: .25rem;
}

.max-h-2 {
  max-height: .5rem;
}

.max-h-3 {
  max-height: .75rem;
}

.max-h-4 {
  max-height: 1rem;
}

.max-h-5 {
  max-height: 1.25rem;
}

.max-h-6 {
  max-height: 1.5rem;
}

.max-h-7 {
  max-height: 1.75rem;
}

.max-h-8 {
  max-height: 2rem;
}

.max-h-9 {
  max-height: 2.25rem;
}

.max-h-10 {
  max-height: 2.5rem;
}

.max-h-11 {
  max-height: 2.75rem;
}

.max-h-12 {
  max-height: 3rem;
}

.max-h-13 {
  max-height: 3.25rem;
}

.max-h-14 {
  max-height: 3.5rem;
}

.max-h-15 {
  max-height: 3.75rem;
}

.max-h-16 {
  max-height: 4rem;
}

.max-h-20 {
  max-height: 5rem;
}

.max-h-24 {
  max-height: 6rem;
}

.max-h-28 {
  max-height: 7rem;
}

.max-h-32 {
  max-height: 8rem;
}

.max-h-36 {
  max-height: 9rem;
}

.max-h-40 {
  max-height: 10rem;
}

.max-h-44 {
  max-height: 11rem;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-52 {
  max-height: 13rem;
}

.max-h-56 {
  max-height: 14rem;
}

.max-h-60 {
  max-height: 15rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-72 {
  max-height: 18rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-screen {
  max-height: 100vh;
}

.max-h-px {
  max-height: 1px;
}

.max-h-0\.5 {
  max-height: .125rem;
}

.max-h-1\.5 {
  max-height: .375rem;
}

.max-h-2\.5 {
  max-height: .625rem;
}

.max-h-3\.5 {
  max-height: .875rem;
}

.max-h-1\/2 {
  max-height: 50%;
}

.max-h-1\/3 {
  max-height: 33.3333%;
}

.max-h-2\/3 {
  max-height: 66.6667%;
}

.max-h-1\/4 {
  max-height: 25%;
}

.max-h-2\/4 {
  max-height: 50%;
}

.max-h-3\/4 {
  max-height: 75%;
}

.max-h-1\/5 {
  max-height: 20%;
}

.max-h-2\/5 {
  max-height: 40%;
}

.max-h-3\/5 {
  max-height: 60%;
}

.max-h-4\/5 {
  max-height: 80%;
}

.max-h-1\/6 {
  max-height: 16.6667%;
}

.max-h-2\/6 {
  max-height: 33.3333%;
}

.max-h-3\/6 {
  max-height: 50%;
}

.max-h-4\/6 {
  max-height: 66.6667%;
}

.max-h-5\/6 {
  max-height: 83.3333%;
}

.max-h-1\/12 {
  max-height: 8.33333%;
}

.max-h-2\/12 {
  max-height: 16.6667%;
}

.max-h-3\/12 {
  max-height: 25%;
}

.max-h-4\/12 {
  max-height: 33.3333%;
}

.max-h-5\/12 {
  max-height: 41.6667%;
}

.max-h-6\/12 {
  max-height: 50%;
}

.max-h-7\/12 {
  max-height: 58.3333%;
}

.max-h-8\/12 {
  max-height: 66.6667%;
}

.max-h-9\/12 {
  max-height: 75%;
}

.max-h-10\/12 {
  max-height: 83.3333%;
}

.max-h-11\/12 {
  max-height: 91.6667%;
}

.max-h-full {
  max-height: 100%;
}

.max-w-none {
  max-width: none;
}

.max-w-xs {
  max-width: 20rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-xl {
  max-width: 36rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-5xl {
  max-width: 64rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-full {
  max-width: 100%;
}

.max-w-min-content {
  max-width: min-content;
}

.max-w-max-content {
  max-width: max-content;
}

.max-w-screen-sm {
  max-width: 640px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.min-h-0 {
  min-height: 0;
}

.min-h-full {
  min-height: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-w-0 {
  min-width: 0;
}

.min-w-full {
  min-width: 100%;
}

.min-w-min-content {
  min-width: min-content;
}

.min-w-max-content {
  min-width: max-content;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-none {
  object-fit: none;
}

.object-scale-down {
  object-fit: scale-down;
}

.object-bottom {
  object-position: bottom;
}

.object-center {
  object-position: center;
}

.object-left {
  object-position: left;
}

.object-left-bottom {
  object-position: left bottom;
}

.object-left-top {
  object-position: left top;
}

.object-right {
  object-position: right;
}

.object-right-bottom {
  object-position: right bottom;
}

.object-right-top {
  object-position: right top;
}

.object-top {
  object-position: top;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: .25;
}

.opacity-50 {
  opacity: .5;
}

.opacity-75 {
  opacity: .75;
}

.opacity-100 {
  opacity: 1;
}

.hover\:opacity-0:hover {
  opacity: 0;
}

.hover\:opacity-25:hover {
  opacity: .25;
}

.hover\:opacity-50:hover {
  opacity: .5;
}

.hover\:opacity-75:hover {
  opacity: .75;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.focus\:opacity-0:focus {
  opacity: 0;
}

.focus\:opacity-25:focus {
  opacity: .25;
}

.focus\:opacity-50:focus {
  opacity: .5;
}

.focus\:opacity-75:focus {
  opacity: .75;
}

.focus\:opacity-100:focus {
  opacity: 1;
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline-white {
  outline-offset: 2px;
  outline: 2px dotted #fff;
}

.outline-black {
  outline-offset: 2px;
  outline: 2px dotted #000;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-white:focus {
  outline-offset: 2px;
  outline: 2px dotted #fff;
}

.focus\:outline-black:focus {
  outline-offset: 2px;
  outline: 2px dotted #000;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scrolling-touch {
  -webkit-overflow-scrolling: touch;
}

.scrolling-auto {
  -webkit-overflow-scrolling: auto;
}

.overscroll-auto {
  overscroll-behavior: auto;
}

.overscroll-contain {
  overscroll-behavior: contain;
}

.overscroll-none {
  overscroll-behavior: none;
}

.overscroll-y-auto {
  overscroll-behavior-y: auto;
}

.overscroll-y-contain {
  overscroll-behavior-y: contain;
}

.overscroll-y-none {
  overscroll-behavior-y: none;
}

.overscroll-x-auto {
  overscroll-behavior-x: auto;
}

.overscroll-x-contain {
  overscroll-behavior-x: contain;
}

.overscroll-x-none {
  overscroll-behavior-x: none;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-9 {
  padding: 2.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.p-13 {
  padding: 3.25rem;
}

.p-14 {
  padding: 3.5rem;
}

.p-15 {
  padding: 3.75rem;
}

.p-16 {
  padding: 4rem;
}

.p-20 {
  padding: 5rem;
}

.p-24 {
  padding: 6rem;
}

.p-28 {
  padding: 7rem;
}

.p-32 {
  padding: 8rem;
}

.p-36 {
  padding: 9rem;
}

.p-40 {
  padding: 10rem;
}

.p-44 {
  padding: 11rem;
}

.p-48 {
  padding: 12rem;
}

.p-52 {
  padding: 13rem;
}

.p-56 {
  padding: 14rem;
}

.p-60 {
  padding: 15rem;
}

.p-64 {
  padding: 16rem;
}

.p-72 {
  padding: 18rem;
}

.p-80 {
  padding: 20rem;
}

.p-96 {
  padding: 24rem;
}

.p-px {
  padding: 1px;
}

.p-0\.5 {
  padding: .125rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2\.5 {
  padding: .625rem;
}

.p-3\.5 {
  padding: .875rem;
}

.p-1\/2 {
  padding: 50%;
}

.p-1\/3 {
  padding: 33.3333%;
}

.p-2\/3 {
  padding: 66.6667%;
}

.p-1\/4 {
  padding: 25%;
}

.p-2\/4 {
  padding: 50%;
}

.p-3\/4 {
  padding: 75%;
}

.p-1\/5 {
  padding: 20%;
}

.p-2\/5 {
  padding: 40%;
}

.p-3\/5 {
  padding: 60%;
}

.p-4\/5 {
  padding: 80%;
}

.p-1\/6 {
  padding: 16.6667%;
}

.p-2\/6 {
  padding: 33.3333%;
}

.p-3\/6 {
  padding: 50%;
}

.p-4\/6 {
  padding: 66.6667%;
}

.p-5\/6 {
  padding: 83.3333%;
}

.p-1\/12 {
  padding: 8.33333%;
}

.p-2\/12 {
  padding: 16.6667%;
}

.p-3\/12 {
  padding: 25%;
}

.p-4\/12 {
  padding: 33.3333%;
}

.p-5\/12 {
  padding: 41.6667%;
}

.p-6\/12 {
  padding: 50%;
}

.p-7\/12 {
  padding: 58.3333%;
}

.p-8\/12 {
  padding: 66.6667%;
}

.p-9\/12 {
  padding: 75%;
}

.p-10\/12 {
  padding: 83.3333%;
}

.p-11\/12 {
  padding: 91.6667%;
}

.p-full {
  padding: 100%;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem;
}

.px-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}

.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}

.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}

.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}

.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}

.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}

.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}

.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}

.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}

.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}

.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}

.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}

.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}

.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}

.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}

.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}

.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}

.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}

.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}

.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}

.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.py-3\.5 {
  padding-top: .875rem;
  padding-bottom: .875rem;
}

.px-3\.5 {
  padding-left: .875rem;
  padding-right: .875rem;
}

.py-1\/2 {
  padding-top: 50%;
  padding-bottom: 50%;
}

.px-1\/2 {
  padding-left: 50%;
  padding-right: 50%;
}

.py-1\/3 {
  padding-top: 33.3333%;
  padding-bottom: 33.3333%;
}

.px-1\/3 {
  padding-left: 33.3333%;
  padding-right: 33.3333%;
}

.py-2\/3 {
  padding-top: 66.6667%;
  padding-bottom: 66.6667%;
}

.px-2\/3 {
  padding-left: 66.6667%;
  padding-right: 66.6667%;
}

.py-1\/4 {
  padding-top: 25%;
  padding-bottom: 25%;
}

.px-1\/4 {
  padding-left: 25%;
  padding-right: 25%;
}

.py-2\/4 {
  padding-top: 50%;
  padding-bottom: 50%;
}

.px-2\/4 {
  padding-left: 50%;
  padding-right: 50%;
}

.py-3\/4 {
  padding-top: 75%;
  padding-bottom: 75%;
}

.px-3\/4 {
  padding-left: 75%;
  padding-right: 75%;
}

.py-1\/5 {
  padding-top: 20%;
  padding-bottom: 20%;
}

.px-1\/5 {
  padding-left: 20%;
  padding-right: 20%;
}

.py-2\/5 {
  padding-top: 40%;
  padding-bottom: 40%;
}

.px-2\/5 {
  padding-left: 40%;
  padding-right: 40%;
}

.py-3\/5 {
  padding-top: 60%;
  padding-bottom: 60%;
}

.px-3\/5 {
  padding-left: 60%;
  padding-right: 60%;
}

.py-4\/5 {
  padding-top: 80%;
  padding-bottom: 80%;
}

.px-4\/5 {
  padding-left: 80%;
  padding-right: 80%;
}

.py-1\/6 {
  padding-top: 16.6667%;
  padding-bottom: 16.6667%;
}

.px-1\/6 {
  padding-left: 16.6667%;
  padding-right: 16.6667%;
}

.py-2\/6 {
  padding-top: 33.3333%;
  padding-bottom: 33.3333%;
}

.px-2\/6 {
  padding-left: 33.3333%;
  padding-right: 33.3333%;
}

.py-3\/6 {
  padding-top: 50%;
  padding-bottom: 50%;
}

.px-3\/6 {
  padding-left: 50%;
  padding-right: 50%;
}

.py-4\/6 {
  padding-top: 66.6667%;
  padding-bottom: 66.6667%;
}

.px-4\/6 {
  padding-left: 66.6667%;
  padding-right: 66.6667%;
}

.py-5\/6 {
  padding-top: 83.3333%;
  padding-bottom: 83.3333%;
}

.px-5\/6 {
  padding-left: 83.3333%;
  padding-right: 83.3333%;
}

.py-1\/12 {
  padding-top: 8.33333%;
  padding-bottom: 8.33333%;
}

.px-1\/12 {
  padding-left: 8.33333%;
  padding-right: 8.33333%;
}

.py-2\/12 {
  padding-top: 16.6667%;
  padding-bottom: 16.6667%;
}

.px-2\/12 {
  padding-left: 16.6667%;
  padding-right: 16.6667%;
}

.py-3\/12 {
  padding-top: 25%;
  padding-bottom: 25%;
}

.px-3\/12 {
  padding-left: 25%;
  padding-right: 25%;
}

.py-4\/12 {
  padding-top: 33.3333%;
  padding-bottom: 33.3333%;
}

.px-4\/12 {
  padding-left: 33.3333%;
  padding-right: 33.3333%;
}

.py-5\/12 {
  padding-top: 41.6667%;
  padding-bottom: 41.6667%;
}

.px-5\/12 {
  padding-left: 41.6667%;
  padding-right: 41.6667%;
}

.py-6\/12 {
  padding-top: 50%;
  padding-bottom: 50%;
}

.px-6\/12 {
  padding-left: 50%;
  padding-right: 50%;
}

.py-7\/12 {
  padding-top: 58.3333%;
  padding-bottom: 58.3333%;
}

.px-7\/12 {
  padding-left: 58.3333%;
  padding-right: 58.3333%;
}

.py-8\/12 {
  padding-top: 66.6667%;
  padding-bottom: 66.6667%;
}

.px-8\/12 {
  padding-left: 66.6667%;
  padding-right: 66.6667%;
}

.py-9\/12 {
  padding-top: 75%;
  padding-bottom: 75%;
}

.px-9\/12 {
  padding-left: 75%;
  padding-right: 75%;
}

.py-10\/12 {
  padding-top: 83.3333%;
  padding-bottom: 83.3333%;
}

.px-10\/12 {
  padding-left: 83.3333%;
  padding-right: 83.3333%;
}

.py-11\/12 {
  padding-top: 91.6667%;
  padding-bottom: 91.6667%;
}

.px-11\/12 {
  padding-left: 91.6667%;
  padding-right: 91.6667%;
}

.py-full {
  padding-top: 100%;
  padding-bottom: 100%;
}

.px-full {
  padding-left: 100%;
  padding-right: 100%;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.pt-13 {
  padding-top: 3.25rem;
}

.pr-13 {
  padding-right: 3.25rem;
}

.pb-13 {
  padding-bottom: 3.25rem;
}

.pl-13 {
  padding-left: 3.25rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.pt-15 {
  padding-top: 3.75rem;
}

.pr-15 {
  padding-right: 3.75rem;
}

.pb-15 {
  padding-bottom: 3.75rem;
}

.pl-15 {
  padding-left: 3.75rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pl-20 {
  padding-left: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pr-24 {
  padding-right: 6rem;
}

.pb-24 {
  padding-bottom: 6rem;
}

.pl-24 {
  padding-left: 6rem;
}

.pt-28 {
  padding-top: 7rem;
}

.pr-28 {
  padding-right: 7rem;
}

.pb-28 {
  padding-bottom: 7rem;
}

.pl-28 {
  padding-left: 7rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pr-32 {
  padding-right: 8rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pl-32 {
  padding-left: 8rem;
}

.pt-36 {
  padding-top: 9rem;
}

.pr-36 {
  padding-right: 9rem;
}

.pb-36 {
  padding-bottom: 9rem;
}

.pl-36 {
  padding-left: 9rem;
}

.pt-40 {
  padding-top: 10rem;
}

.pr-40 {
  padding-right: 10rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pl-40 {
  padding-left: 10rem;
}

.pt-44 {
  padding-top: 11rem;
}

.pr-44 {
  padding-right: 11rem;
}

.pb-44 {
  padding-bottom: 11rem;
}

.pl-44 {
  padding-left: 11rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pr-48 {
  padding-right: 12rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pl-48 {
  padding-left: 12rem;
}

.pt-52 {
  padding-top: 13rem;
}

.pr-52 {
  padding-right: 13rem;
}

.pb-52 {
  padding-bottom: 13rem;
}

.pl-52 {
  padding-left: 13rem;
}

.pt-56 {
  padding-top: 14rem;
}

.pr-56 {
  padding-right: 14rem;
}

.pb-56 {
  padding-bottom: 14rem;
}

.pl-56 {
  padding-left: 14rem;
}

.pt-60 {
  padding-top: 15rem;
}

.pr-60 {
  padding-right: 15rem;
}

.pb-60 {
  padding-bottom: 15rem;
}

.pl-60 {
  padding-left: 15rem;
}

.pt-64 {
  padding-top: 16rem;
}

.pr-64 {
  padding-right: 16rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.pl-64 {
  padding-left: 16rem;
}

.pt-72 {
  padding-top: 18rem;
}

.pr-72 {
  padding-right: 18rem;
}

.pb-72 {
  padding-bottom: 18rem;
}

.pl-72 {
  padding-left: 18rem;
}

.pt-80 {
  padding-top: 20rem;
}

.pr-80 {
  padding-right: 20rem;
}

.pb-80 {
  padding-bottom: 20rem;
}

.pl-80 {
  padding-left: 20rem;
}

.pt-96 {
  padding-top: 24rem;
}

.pr-96 {
  padding-right: 24rem;
}

.pb-96 {
  padding-bottom: 24rem;
}

.pl-96 {
  padding-left: 24rem;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pr-0\.5 {
  padding-right: .125rem;
}

.pb-0\.5 {
  padding-bottom: .125rem;
}

.pl-0\.5 {
  padding-left: .125rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pr-1\.5 {
  padding-right: .375rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pt-2\.5 {
  padding-top: .625rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pb-2\.5 {
  padding-bottom: .625rem;
}

.pl-2\.5 {
  padding-left: .625rem;
}

.pt-3\.5 {
  padding-top: .875rem;
}

.pr-3\.5 {
  padding-right: .875rem;
}

.pb-3\.5 {
  padding-bottom: .875rem;
}

.pl-3\.5 {
  padding-left: .875rem;
}

.pt-1\/2 {
  padding-top: 50%;
}

.pr-1\/2 {
  padding-right: 50%;
}

.pb-1\/2 {
  padding-bottom: 50%;
}

.pl-1\/2 {
  padding-left: 50%;
}

.pt-1\/3 {
  padding-top: 33.3333%;
}

.pr-1\/3 {
  padding-right: 33.3333%;
}

.pb-1\/3 {
  padding-bottom: 33.3333%;
}

.pl-1\/3 {
  padding-left: 33.3333%;
}

.pt-2\/3 {
  padding-top: 66.6667%;
}

.pr-2\/3 {
  padding-right: 66.6667%;
}

.pb-2\/3 {
  padding-bottom: 66.6667%;
}

.pl-2\/3 {
  padding-left: 66.6667%;
}

.pt-1\/4 {
  padding-top: 25%;
}

.pr-1\/4 {
  padding-right: 25%;
}

.pb-1\/4 {
  padding-bottom: 25%;
}

.pl-1\/4 {
  padding-left: 25%;
}

.pt-2\/4 {
  padding-top: 50%;
}

.pr-2\/4 {
  padding-right: 50%;
}

.pb-2\/4 {
  padding-bottom: 50%;
}

.pl-2\/4 {
  padding-left: 50%;
}

.pt-3\/4 {
  padding-top: 75%;
}

.pr-3\/4 {
  padding-right: 75%;
}

.pb-3\/4 {
  padding-bottom: 75%;
}

.pl-3\/4 {
  padding-left: 75%;
}

.pt-1\/5 {
  padding-top: 20%;
}

.pr-1\/5 {
  padding-right: 20%;
}

.pb-1\/5 {
  padding-bottom: 20%;
}

.pl-1\/5 {
  padding-left: 20%;
}

.pt-2\/5 {
  padding-top: 40%;
}

.pr-2\/5 {
  padding-right: 40%;
}

.pb-2\/5 {
  padding-bottom: 40%;
}

.pl-2\/5 {
  padding-left: 40%;
}

.pt-3\/5 {
  padding-top: 60%;
}

.pr-3\/5 {
  padding-right: 60%;
}

.pb-3\/5 {
  padding-bottom: 60%;
}

.pl-3\/5 {
  padding-left: 60%;
}

.pt-4\/5 {
  padding-top: 80%;
}

.pr-4\/5 {
  padding-right: 80%;
}

.pb-4\/5 {
  padding-bottom: 80%;
}

.pl-4\/5 {
  padding-left: 80%;
}

.pt-1\/6 {
  padding-top: 16.6667%;
}

.pr-1\/6 {
  padding-right: 16.6667%;
}

.pb-1\/6 {
  padding-bottom: 16.6667%;
}

.pl-1\/6 {
  padding-left: 16.6667%;
}

.pt-2\/6 {
  padding-top: 33.3333%;
}

.pr-2\/6 {
  padding-right: 33.3333%;
}

.pb-2\/6 {
  padding-bottom: 33.3333%;
}

.pl-2\/6 {
  padding-left: 33.3333%;
}

.pt-3\/6 {
  padding-top: 50%;
}

.pr-3\/6 {
  padding-right: 50%;
}

.pb-3\/6 {
  padding-bottom: 50%;
}

.pl-3\/6 {
  padding-left: 50%;
}

.pt-4\/6 {
  padding-top: 66.6667%;
}

.pr-4\/6 {
  padding-right: 66.6667%;
}

.pb-4\/6 {
  padding-bottom: 66.6667%;
}

.pl-4\/6 {
  padding-left: 66.6667%;
}

.pt-5\/6 {
  padding-top: 83.3333%;
}

.pr-5\/6 {
  padding-right: 83.3333%;
}

.pb-5\/6 {
  padding-bottom: 83.3333%;
}

.pl-5\/6 {
  padding-left: 83.3333%;
}

.pt-1\/12 {
  padding-top: 8.33333%;
}

.pr-1\/12 {
  padding-right: 8.33333%;
}

.pb-1\/12 {
  padding-bottom: 8.33333%;
}

.pl-1\/12 {
  padding-left: 8.33333%;
}

.pt-2\/12 {
  padding-top: 16.6667%;
}

.pr-2\/12 {
  padding-right: 16.6667%;
}

.pb-2\/12 {
  padding-bottom: 16.6667%;
}

.pl-2\/12 {
  padding-left: 16.6667%;
}

.pt-3\/12 {
  padding-top: 25%;
}

.pr-3\/12 {
  padding-right: 25%;
}

.pb-3\/12 {
  padding-bottom: 25%;
}

.pl-3\/12 {
  padding-left: 25%;
}

.pt-4\/12 {
  padding-top: 33.3333%;
}

.pr-4\/12 {
  padding-right: 33.3333%;
}

.pb-4\/12 {
  padding-bottom: 33.3333%;
}

.pl-4\/12 {
  padding-left: 33.3333%;
}

.pt-5\/12 {
  padding-top: 41.6667%;
}

.pr-5\/12 {
  padding-right: 41.6667%;
}

.pb-5\/12 {
  padding-bottom: 41.6667%;
}

.pl-5\/12 {
  padding-left: 41.6667%;
}

.pt-6\/12 {
  padding-top: 50%;
}

.pr-6\/12 {
  padding-right: 50%;
}

.pb-6\/12 {
  padding-bottom: 50%;
}

.pl-6\/12 {
  padding-left: 50%;
}

.pt-7\/12 {
  padding-top: 58.3333%;
}

.pr-7\/12 {
  padding-right: 58.3333%;
}

.pb-7\/12 {
  padding-bottom: 58.3333%;
}

.pl-7\/12 {
  padding-left: 58.3333%;
}

.pt-8\/12 {
  padding-top: 66.6667%;
}

.pr-8\/12 {
  padding-right: 66.6667%;
}

.pb-8\/12 {
  padding-bottom: 66.6667%;
}

.pl-8\/12 {
  padding-left: 66.6667%;
}

.pt-9\/12 {
  padding-top: 75%;
}

.pr-9\/12 {
  padding-right: 75%;
}

.pb-9\/12 {
  padding-bottom: 75%;
}

.pl-9\/12 {
  padding-left: 75%;
}

.pt-10\/12 {
  padding-top: 83.3333%;
}

.pr-10\/12 {
  padding-right: 83.3333%;
}

.pb-10\/12 {
  padding-bottom: 83.3333%;
}

.pl-10\/12 {
  padding-left: 83.3333%;
}

.pt-11\/12 {
  padding-top: 91.6667%;
}

.pr-11\/12 {
  padding-right: 91.6667%;
}

.pb-11\/12 {
  padding-bottom: 91.6667%;
}

.pl-11\/12 {
  padding-left: 91.6667%;
}

.pt-full {
  padding-top: 100%;
}

.pr-full {
  padding-right: 100%;
}

.pb-full {
  padding-bottom: 100%;
}

.pl-full {
  padding-left: 100%;
}

.placeholder-transparent::placeholder {
  color: #0000;
}

.placeholder-white::placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity) );
}

.placeholder-black::placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity) );
}

.placeholder-gray-50::placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity) );
}

.placeholder-gray-100::placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity) );
}

.placeholder-gray-200::placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity) );
}

.placeholder-gray-300::placeholder {
  --placeholder-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--placeholder-opacity) );
}

.placeholder-gray-400::placeholder {
  --placeholder-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--placeholder-opacity) );
}

.placeholder-gray-500::placeholder {
  --placeholder-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--placeholder-opacity) );
}

.placeholder-gray-600::placeholder {
  --placeholder-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--placeholder-opacity) );
}

.placeholder-gray-700::placeholder {
  --placeholder-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--placeholder-opacity) );
}

.placeholder-gray-800::placeholder {
  --placeholder-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--placeholder-opacity) );
}

.placeholder-gray-900::placeholder {
  --placeholder-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--placeholder-opacity) );
}

.placeholder-cool-gray-50::placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity) );
}

.placeholder-cool-gray-100::placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity) );
}

.placeholder-cool-gray-200::placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity) );
}

.placeholder-cool-gray-300::placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity) );
}

.placeholder-cool-gray-400::placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity) );
}

.placeholder-cool-gray-500::placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity) );
}

.placeholder-cool-gray-600::placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity) );
}

.placeholder-cool-gray-700::placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity) );
}

.placeholder-cool-gray-800::placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity) );
}

.placeholder-cool-gray-900::placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity) );
}

.placeholder-red-50::placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity) );
}

.placeholder-red-100::placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity) );
}

.placeholder-red-200::placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity) );
}

.placeholder-red-300::placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity) );
}

.placeholder-red-400::placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity) );
}

.placeholder-red-500::placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity) );
}

.placeholder-red-600::placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity) );
}

.placeholder-red-700::placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity) );
}

.placeholder-red-800::placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity) );
}

.placeholder-red-900::placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity) );
}

.placeholder-orange-50::placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity) );
}

.placeholder-orange-100::placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity) );
}

.placeholder-orange-200::placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity) );
}

.placeholder-orange-300::placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity) );
}

.placeholder-orange-400::placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity) );
}

.placeholder-orange-500::placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity) );
}

.placeholder-orange-600::placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity) );
}

.placeholder-orange-700::placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity) );
}

.placeholder-orange-800::placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity) );
}

.placeholder-orange-900::placeholder {
  --placeholder-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--placeholder-opacity) );
}

.placeholder-yellow-50::placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity) );
}

.placeholder-yellow-100::placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity) );
}

.placeholder-yellow-200::placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity) );
}

.placeholder-yellow-300::placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity) );
}

.placeholder-yellow-400::placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity) );
}

.placeholder-yellow-500::placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity) );
}

.placeholder-yellow-600::placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity) );
}

.placeholder-yellow-700::placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity) );
}

.placeholder-yellow-800::placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity) );
}

.placeholder-yellow-900::placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity) );
}

.placeholder-green-50::placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity) );
}

.placeholder-green-100::placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity) );
}

.placeholder-green-200::placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity) );
}

.placeholder-green-300::placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity) );
}

.placeholder-green-400::placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity) );
}

.placeholder-green-500::placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity) );
}

.placeholder-green-600::placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity) );
}

.placeholder-green-700::placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity) );
}

.placeholder-green-800::placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity) );
}

.placeholder-green-900::placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity) );
}

.placeholder-teal-50::placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity) );
}

.placeholder-teal-100::placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity) );
}

.placeholder-teal-200::placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity) );
}

.placeholder-teal-300::placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity) );
}

.placeholder-teal-400::placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity) );
}

.placeholder-teal-500::placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity) );
}

.placeholder-teal-600::placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity) );
}

.placeholder-teal-700::placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity) );
}

.placeholder-teal-800::placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity) );
}

.placeholder-teal-900::placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity) );
}

.placeholder-blue-50::placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity) );
}

.placeholder-blue-100::placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity) );
}

.placeholder-blue-200::placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity) );
}

.placeholder-blue-300::placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity) );
}

.placeholder-blue-400::placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity) );
}

.placeholder-blue-500::placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity) );
}

.placeholder-blue-600::placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity) );
}

.placeholder-blue-700::placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity) );
}

.placeholder-blue-800::placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity) );
}

.placeholder-blue-900::placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity) );
}

.placeholder-indigo-50::placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity) );
}

.placeholder-indigo-100::placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity) );
}

.placeholder-indigo-200::placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity) );
}

.placeholder-indigo-300::placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity) );
}

.placeholder-indigo-400::placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity) );
}

.placeholder-indigo-500::placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity) );
}

.placeholder-indigo-600::placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity) );
}

.placeholder-indigo-700::placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity) );
}

.placeholder-indigo-800::placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity) );
}

.placeholder-indigo-900::placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity) );
}

.placeholder-purple-50::placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity) );
}

.placeholder-purple-100::placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity) );
}

.placeholder-purple-200::placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity) );
}

.placeholder-purple-300::placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity) );
}

.placeholder-purple-400::placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity) );
}

.placeholder-purple-500::placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity) );
}

.placeholder-purple-600::placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity) );
}

.placeholder-purple-700::placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity) );
}

.placeholder-purple-800::placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity) );
}

.placeholder-purple-900::placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity) );
}

.placeholder-pink-50::placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity) );
}

.placeholder-pink-100::placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity) );
}

.placeholder-pink-200::placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity) );
}

.placeholder-pink-300::placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity) );
}

.placeholder-pink-400::placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity) );
}

.placeholder-pink-500::placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity) );
}

.placeholder-pink-600::placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity) );
}

.placeholder-pink-700::placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity) );
}

.placeholder-pink-800::placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity) );
}

.placeholder-pink-900::placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity) );
}

.focus\:placeholder-transparent:focus::placeholder {
  color: #0000;
}

.focus\:placeholder-white:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--placeholder-opacity) );
}

.focus\:placeholder-black:focus::placeholder {
  --placeholder-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--placeholder-opacity) );
}

.focus\:placeholder-gray-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--placeholder-opacity) );
}

.focus\:placeholder-cool-gray-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--placeholder-opacity) );
}

.focus\:placeholder-red-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--placeholder-opacity) );
}

.focus\:placeholder-red-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--placeholder-opacity) );
}

.focus\:placeholder-red-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--placeholder-opacity) );
}

.focus\:placeholder-red-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--placeholder-opacity) );
}

.focus\:placeholder-red-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--placeholder-opacity) );
}

.focus\:placeholder-red-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--placeholder-opacity) );
}

.focus\:placeholder-red-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--placeholder-opacity) );
}

.focus\:placeholder-red-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--placeholder-opacity) );
}

.focus\:placeholder-red-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--placeholder-opacity) );
}

.focus\:placeholder-red-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--placeholder-opacity) );
}

.focus\:placeholder-orange-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--placeholder-opacity) );
}

.focus\:placeholder-yellow-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--placeholder-opacity) );
}

.focus\:placeholder-green-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--placeholder-opacity) );
}

.focus\:placeholder-green-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--placeholder-opacity) );
}

.focus\:placeholder-green-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--placeholder-opacity) );
}

.focus\:placeholder-green-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--placeholder-opacity) );
}

.focus\:placeholder-green-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--placeholder-opacity) );
}

.focus\:placeholder-green-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--placeholder-opacity) );
}

.focus\:placeholder-green-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--placeholder-opacity) );
}

.focus\:placeholder-green-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--placeholder-opacity) );
}

.focus\:placeholder-green-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--placeholder-opacity) );
}

.focus\:placeholder-green-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--placeholder-opacity) );
}

.focus\:placeholder-teal-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--placeholder-opacity) );
}

.focus\:placeholder-blue-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--placeholder-opacity) );
}

.focus\:placeholder-indigo-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--placeholder-opacity) );
}

.focus\:placeholder-purple-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-50:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-100:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-200:focus::placeholder {
  --placeholder-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-300:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-400:focus::placeholder {
  --placeholder-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-500:focus::placeholder {
  --placeholder-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-600:focus::placeholder {
  --placeholder-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-700:focus::placeholder {
  --placeholder-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-800:focus::placeholder {
  --placeholder-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--placeholder-opacity) );
}

.focus\:placeholder-pink-900:focus::placeholder {
  --placeholder-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--placeholder-opacity) );
}

.placeholder-opacity-0::placeholder {
  --placeholder-opacity: 0;
}

.placeholder-opacity-25::placeholder {
  --placeholder-opacity: .25;
}

.placeholder-opacity-50::placeholder {
  --placeholder-opacity: .5;
}

.placeholder-opacity-75::placeholder {
  --placeholder-opacity: .75;
}

.placeholder-opacity-100::placeholder {
  --placeholder-opacity: 1;
}

.focus\:placeholder-opacity-0:focus::placeholder {
  --placeholder-opacity: 0;
}

.focus\:placeholder-opacity-25:focus::placeholder {
  --placeholder-opacity: .25;
}

.focus\:placeholder-opacity-50:focus::placeholder {
  --placeholder-opacity: .5;
}

.focus\:placeholder-opacity-75:focus::placeholder {
  --placeholder-opacity: .75;
}

.focus\:placeholder-opacity-100:focus::placeholder {
  --placeholder-opacity: 1;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.inset-1 {
  inset: .25rem;
}

.inset-2 {
  inset: .5rem;
}

.inset-3 {
  inset: .75rem;
}

.inset-4 {
  inset: 1rem;
}

.inset-5 {
  inset: 1.25rem;
}

.inset-6 {
  inset: 1.5rem;
}

.inset-7 {
  inset: 1.75rem;
}

.inset-8 {
  inset: 2rem;
}

.inset-9 {
  inset: 2.25rem;
}

.inset-10 {
  inset: 2.5rem;
}

.inset-11 {
  inset: 2.75rem;
}

.inset-12 {
  inset: 3rem;
}

.inset-13 {
  inset: 3.25rem;
}

.inset-14 {
  inset: 3.5rem;
}

.inset-15 {
  inset: 3.75rem;
}

.inset-16 {
  inset: 4rem;
}

.inset-20 {
  inset: 5rem;
}

.inset-24 {
  inset: 6rem;
}

.inset-28 {
  inset: 7rem;
}

.inset-32 {
  inset: 8rem;
}

.inset-36 {
  inset: 9rem;
}

.inset-40 {
  inset: 10rem;
}

.inset-44 {
  inset: 11rem;
}

.inset-48 {
  inset: 12rem;
}

.inset-52 {
  inset: 13rem;
}

.inset-56 {
  inset: 14rem;
}

.inset-60 {
  inset: 15rem;
}

.inset-64 {
  inset: 16rem;
}

.inset-72 {
  inset: 18rem;
}

.inset-80 {
  inset: 20rem;
}

.inset-96 {
  inset: 24rem;
}

.inset-auto {
  inset: auto;
}

.inset-px {
  inset: 1px;
}

.inset-0\.5 {
  inset: .125rem;
}

.inset-1\.5 {
  inset: .375rem;
}

.inset-2\.5 {
  inset: .625rem;
}

.inset-3\.5 {
  inset: .875rem;
}

.inset-1\/2 {
  inset: 50%;
}

.inset-1\/3 {
  inset: 33.3333%;
}

.inset-2\/3 {
  inset: 66.6667%;
}

.inset-1\/4 {
  inset: 25%;
}

.inset-2\/4 {
  inset: 50%;
}

.inset-3\/4 {
  inset: 75%;
}

.inset-1\/5 {
  inset: 20%;
}

.inset-2\/5 {
  inset: 40%;
}

.inset-3\/5 {
  inset: 60%;
}

.inset-4\/5 {
  inset: 80%;
}

.inset-1\/6 {
  inset: 16.6667%;
}

.inset-2\/6 {
  inset: 33.3333%;
}

.inset-3\/6 {
  inset: 50%;
}

.inset-4\/6 {
  inset: 66.6667%;
}

.inset-5\/6 {
  inset: 83.3333%;
}

.inset-1\/12 {
  inset: 8.33333%;
}

.inset-2\/12 {
  inset: 16.6667%;
}

.inset-3\/12 {
  inset: 25%;
}

.inset-4\/12 {
  inset: 33.3333%;
}

.inset-5\/12 {
  inset: 41.6667%;
}

.inset-6\/12 {
  inset: 50%;
}

.inset-7\/12 {
  inset: 58.3333%;
}

.inset-8\/12 {
  inset: 66.6667%;
}

.inset-9\/12 {
  inset: 75%;
}

.inset-10\/12 {
  inset: 83.3333%;
}

.inset-11\/12 {
  inset: 91.6667%;
}

.inset-full {
  inset: 100%;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-1 {
  top: .25rem;
  bottom: .25rem;
}

.inset-x-1 {
  left: .25rem;
  right: .25rem;
}

.inset-y-2 {
  top: .5rem;
  bottom: .5rem;
}

.inset-x-2 {
  left: .5rem;
  right: .5rem;
}

.inset-y-3 {
  top: .75rem;
  bottom: .75rem;
}

.inset-x-3 {
  left: .75rem;
  right: .75rem;
}

.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}

.inset-x-4 {
  left: 1rem;
  right: 1rem;
}

.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}

.inset-x-5 {
  left: 1.25rem;
  right: 1.25rem;
}

.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}

.inset-x-6 {
  left: 1.5rem;
  right: 1.5rem;
}

.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}

.inset-x-7 {
  left: 1.75rem;
  right: 1.75rem;
}

.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}

.inset-x-8 {
  left: 2rem;
  right: 2rem;
}

.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}

.inset-x-9 {
  left: 2.25rem;
  right: 2.25rem;
}

.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}

.inset-x-10 {
  left: 2.5rem;
  right: 2.5rem;
}

.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}

.inset-x-11 {
  left: 2.75rem;
  right: 2.75rem;
}

.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}

.inset-x-12 {
  left: 3rem;
  right: 3rem;
}

.inset-y-13 {
  top: 3.25rem;
  bottom: 3.25rem;
}

.inset-x-13 {
  left: 3.25rem;
  right: 3.25rem;
}

.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}

.inset-x-14 {
  left: 3.5rem;
  right: 3.5rem;
}

.inset-y-15 {
  top: 3.75rem;
  bottom: 3.75rem;
}

.inset-x-15 {
  left: 3.75rem;
  right: 3.75rem;
}

.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}

.inset-x-16 {
  left: 4rem;
  right: 4rem;
}

.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}

.inset-x-20 {
  left: 5rem;
  right: 5rem;
}

.inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}

.inset-x-24 {
  left: 6rem;
  right: 6rem;
}

.inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}

.inset-x-28 {
  left: 7rem;
  right: 7rem;
}

.inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}

.inset-x-32 {
  left: 8rem;
  right: 8rem;
}

.inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}

.inset-x-36 {
  left: 9rem;
  right: 9rem;
}

.inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}

.inset-x-40 {
  left: 10rem;
  right: 10rem;
}

.inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}

.inset-x-44 {
  left: 11rem;
  right: 11rem;
}

.inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}

.inset-x-48 {
  left: 12rem;
  right: 12rem;
}

.inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}

.inset-x-52 {
  left: 13rem;
  right: 13rem;
}

.inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}

.inset-x-56 {
  left: 14rem;
  right: 14rem;
}

.inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}

.inset-x-60 {
  left: 15rem;
  right: 15rem;
}

.inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}

.inset-x-64 {
  left: 16rem;
  right: 16rem;
}

.inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}

.inset-x-72 {
  left: 18rem;
  right: 18rem;
}

.inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}

.inset-x-80 {
  left: 20rem;
  right: 20rem;
}

.inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}

.inset-x-96 {
  left: 24rem;
  right: 24rem;
}

.inset-y-auto {
  top: auto;
  bottom: auto;
}

.inset-x-auto {
  left: auto;
  right: auto;
}

.inset-y-px {
  top: 1px;
  bottom: 1px;
}

.inset-x-px {
  left: 1px;
  right: 1px;
}

.inset-y-0\.5 {
  top: .125rem;
  bottom: .125rem;
}

.inset-x-0\.5 {
  left: .125rem;
  right: .125rem;
}

.inset-y-1\.5 {
  top: .375rem;
  bottom: .375rem;
}

.inset-x-1\.5 {
  left: .375rem;
  right: .375rem;
}

.inset-y-2\.5 {
  top: .625rem;
  bottom: .625rem;
}

.inset-x-2\.5 {
  left: .625rem;
  right: .625rem;
}

.inset-y-3\.5 {
  top: .875rem;
  bottom: .875rem;
}

.inset-x-3\.5 {
  left: .875rem;
  right: .875rem;
}

.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}

.inset-x-1\/2 {
  left: 50%;
  right: 50%;
}

.inset-y-1\/3 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.inset-x-1\/3 {
  left: 33.3333%;
  right: 33.3333%;
}

.inset-y-2\/3 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.inset-x-2\/3 {
  left: 66.6667%;
  right: 66.6667%;
}

.inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}

.inset-x-1\/4 {
  left: 25%;
  right: 25%;
}

.inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}

.inset-x-2\/4 {
  left: 50%;
  right: 50%;
}

.inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}

.inset-x-3\/4 {
  left: 75%;
  right: 75%;
}

.inset-y-1\/5 {
  top: 20%;
  bottom: 20%;
}

.inset-x-1\/5 {
  left: 20%;
  right: 20%;
}

.inset-y-2\/5 {
  top: 40%;
  bottom: 40%;
}

.inset-x-2\/5 {
  left: 40%;
  right: 40%;
}

.inset-y-3\/5 {
  top: 60%;
  bottom: 60%;
}

.inset-x-3\/5 {
  left: 60%;
  right: 60%;
}

.inset-y-4\/5 {
  top: 80%;
  bottom: 80%;
}

.inset-x-4\/5 {
  left: 80%;
  right: 80%;
}

.inset-y-1\/6 {
  top: 16.6667%;
  bottom: 16.6667%;
}

.inset-x-1\/6 {
  left: 16.6667%;
  right: 16.6667%;
}

.inset-y-2\/6 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.inset-x-2\/6 {
  left: 33.3333%;
  right: 33.3333%;
}

.inset-y-3\/6 {
  top: 50%;
  bottom: 50%;
}

.inset-x-3\/6 {
  left: 50%;
  right: 50%;
}

.inset-y-4\/6 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.inset-x-4\/6 {
  left: 66.6667%;
  right: 66.6667%;
}

.inset-y-5\/6 {
  top: 83.3333%;
  bottom: 83.3333%;
}

.inset-x-5\/6 {
  left: 83.3333%;
  right: 83.3333%;
}

.inset-y-1\/12 {
  top: 8.33333%;
  bottom: 8.33333%;
}

.inset-x-1\/12 {
  left: 8.33333%;
  right: 8.33333%;
}

.inset-y-2\/12 {
  top: 16.6667%;
  bottom: 16.6667%;
}

.inset-x-2\/12 {
  left: 16.6667%;
  right: 16.6667%;
}

.inset-y-3\/12 {
  top: 25%;
  bottom: 25%;
}

.inset-x-3\/12 {
  left: 25%;
  right: 25%;
}

.inset-y-4\/12 {
  top: 33.3333%;
  bottom: 33.3333%;
}

.inset-x-4\/12 {
  left: 33.3333%;
  right: 33.3333%;
}

.inset-y-5\/12 {
  top: 41.6667%;
  bottom: 41.6667%;
}

.inset-x-5\/12 {
  left: 41.6667%;
  right: 41.6667%;
}

.inset-y-6\/12 {
  top: 50%;
  bottom: 50%;
}

.inset-x-6\/12 {
  left: 50%;
  right: 50%;
}

.inset-y-7\/12 {
  top: 58.3333%;
  bottom: 58.3333%;
}

.inset-x-7\/12 {
  left: 58.3333%;
  right: 58.3333%;
}

.inset-y-8\/12 {
  top: 66.6667%;
  bottom: 66.6667%;
}

.inset-x-8\/12 {
  left: 66.6667%;
  right: 66.6667%;
}

.inset-y-9\/12 {
  top: 75%;
  bottom: 75%;
}

.inset-x-9\/12 {
  left: 75%;
  right: 75%;
}

.inset-y-10\/12 {
  top: 83.3333%;
  bottom: 83.3333%;
}

.inset-x-10\/12 {
  left: 83.3333%;
  right: 83.3333%;
}

.inset-y-11\/12 {
  top: 91.6667%;
  bottom: 91.6667%;
}

.inset-x-11\/12 {
  left: 91.6667%;
  right: 91.6667%;
}

.inset-y-full {
  top: 100%;
  bottom: 100%;
}

.inset-x-full {
  left: 100%;
  right: 100%;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.top-1 {
  top: .25rem;
}

.right-1 {
  right: .25rem;
}

.bottom-1 {
  bottom: .25rem;
}

.left-1 {
  left: .25rem;
}

.top-2 {
  top: .5rem;
}

.right-2 {
  right: .5rem;
}

.bottom-2 {
  bottom: .5rem;
}

.left-2 {
  left: .5rem;
}

.top-3 {
  top: .75rem;
}

.right-3 {
  right: .75rem;
}

.bottom-3 {
  bottom: .75rem;
}

.left-3 {
  left: .75rem;
}

.top-4 {
  top: 1rem;
}

.right-4 {
  right: 1rem;
}

.bottom-4 {
  bottom: 1rem;
}

.left-4 {
  left: 1rem;
}

.top-5 {
  top: 1.25rem;
}

.right-5 {
  right: 1.25rem;
}

.bottom-5 {
  bottom: 1.25rem;
}

.left-5 {
  left: 1.25rem;
}

.top-6 {
  top: 1.5rem;
}

.right-6 {
  right: 1.5rem;
}

.bottom-6 {
  bottom: 1.5rem;
}

.left-6 {
  left: 1.5rem;
}

.top-7 {
  top: 1.75rem;
}

.right-7 {
  right: 1.75rem;
}

.bottom-7 {
  bottom: 1.75rem;
}

.left-7 {
  left: 1.75rem;
}

.top-8 {
  top: 2rem;
}

.right-8 {
  right: 2rem;
}

.bottom-8 {
  bottom: 2rem;
}

.left-8 {
  left: 2rem;
}

.top-9 {
  top: 2.25rem;
}

.right-9 {
  right: 2.25rem;
}

.bottom-9 {
  bottom: 2.25rem;
}

.left-9 {
  left: 2.25rem;
}

.top-10 {
  top: 2.5rem;
}

.right-10 {
  right: 2.5rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.left-10 {
  left: 2.5rem;
}

.top-11 {
  top: 2.75rem;
}

.right-11 {
  right: 2.75rem;
}

.bottom-11 {
  bottom: 2.75rem;
}

.left-11 {
  left: 2.75rem;
}

.top-12 {
  top: 3rem;
}

.right-12 {
  right: 3rem;
}

.bottom-12 {
  bottom: 3rem;
}

.left-12 {
  left: 3rem;
}

.top-13 {
  top: 3.25rem;
}

.right-13 {
  right: 3.25rem;
}

.bottom-13 {
  bottom: 3.25rem;
}

.left-13 {
  left: 3.25rem;
}

.top-14 {
  top: 3.5rem;
}

.right-14 {
  right: 3.5rem;
}

.bottom-14 {
  bottom: 3.5rem;
}

.left-14 {
  left: 3.5rem;
}

.top-15 {
  top: 3.75rem;
}

.right-15 {
  right: 3.75rem;
}

.bottom-15 {
  bottom: 3.75rem;
}

.left-15 {
  left: 3.75rem;
}

.top-16 {
  top: 4rem;
}

.right-16 {
  right: 4rem;
}

.bottom-16 {
  bottom: 4rem;
}

.left-16 {
  left: 4rem;
}

.top-20 {
  top: 5rem;
}

.right-20 {
  right: 5rem;
}

.bottom-20 {
  bottom: 5rem;
}

.left-20 {
  left: 5rem;
}

.top-24 {
  top: 6rem;
}

.right-24 {
  right: 6rem;
}

.bottom-24 {
  bottom: 6rem;
}

.left-24 {
  left: 6rem;
}

.top-28 {
  top: 7rem;
}

.right-28 {
  right: 7rem;
}

.bottom-28 {
  bottom: 7rem;
}

.left-28 {
  left: 7rem;
}

.top-32 {
  top: 8rem;
}

.right-32 {
  right: 8rem;
}

.bottom-32 {
  bottom: 8rem;
}

.left-32 {
  left: 8rem;
}

.top-36 {
  top: 9rem;
}

.right-36 {
  right: 9rem;
}

.bottom-36 {
  bottom: 9rem;
}

.left-36 {
  left: 9rem;
}

.top-40 {
  top: 10rem;
}

.right-40 {
  right: 10rem;
}

.bottom-40 {
  bottom: 10rem;
}

.left-40 {
  left: 10rem;
}

.top-44 {
  top: 11rem;
}

.right-44 {
  right: 11rem;
}

.bottom-44 {
  bottom: 11rem;
}

.left-44 {
  left: 11rem;
}

.top-48 {
  top: 12rem;
}

.right-48 {
  right: 12rem;
}

.bottom-48 {
  bottom: 12rem;
}

.left-48 {
  left: 12rem;
}

.top-52 {
  top: 13rem;
}

.right-52 {
  right: 13rem;
}

.bottom-52 {
  bottom: 13rem;
}

.left-52 {
  left: 13rem;
}

.top-56 {
  top: 14rem;
}

.right-56 {
  right: 14rem;
}

.bottom-56 {
  bottom: 14rem;
}

.left-56 {
  left: 14rem;
}

.top-60 {
  top: 15rem;
}

.right-60 {
  right: 15rem;
}

.bottom-60 {
  bottom: 15rem;
}

.left-60 {
  left: 15rem;
}

.top-64 {
  top: 16rem;
}

.right-64 {
  right: 16rem;
}

.bottom-64 {
  bottom: 16rem;
}

.left-64 {
  left: 16rem;
}

.top-72 {
  top: 18rem;
}

.right-72 {
  right: 18rem;
}

.bottom-72 {
  bottom: 18rem;
}

.left-72 {
  left: 18rem;
}

.top-80 {
  top: 20rem;
}

.right-80 {
  right: 20rem;
}

.bottom-80 {
  bottom: 20rem;
}

.left-80 {
  left: 20rem;
}

.top-96 {
  top: 24rem;
}

.right-96 {
  right: 24rem;
}

.bottom-96 {
  bottom: 24rem;
}

.left-96 {
  left: 24rem;
}

.top-auto {
  top: auto;
}

.right-auto {
  right: auto;
}

.bottom-auto {
  bottom: auto;
}

.left-auto {
  left: auto;
}

.top-px {
  top: 1px;
}

.right-px {
  right: 1px;
}

.bottom-px {
  bottom: 1px;
}

.left-px {
  left: 1px;
}

.top-0\.5 {
  top: .125rem;
}

.right-0\.5 {
  right: .125rem;
}

.bottom-0\.5 {
  bottom: .125rem;
}

.left-0\.5 {
  left: .125rem;
}

.top-1\.5 {
  top: .375rem;
}

.right-1\.5 {
  right: .375rem;
}

.bottom-1\.5 {
  bottom: .375rem;
}

.left-1\.5 {
  left: .375rem;
}

.top-2\.5 {
  top: .625rem;
}

.right-2\.5 {
  right: .625rem;
}

.bottom-2\.5 {
  bottom: .625rem;
}

.left-2\.5 {
  left: .625rem;
}

.top-3\.5 {
  top: .875rem;
}

.right-3\.5 {
  right: .875rem;
}

.bottom-3\.5 {
  bottom: .875rem;
}

.left-3\.5 {
  left: .875rem;
}

.top-1\/2 {
  top: 50%;
}

.right-1\/2 {
  right: 50%;
}

.bottom-1\/2 {
  bottom: 50%;
}

.left-1\/2 {
  left: 50%;
}

.top-1\/3 {
  top: 33.3333%;
}

.right-1\/3 {
  right: 33.3333%;
}

.bottom-1\/3 {
  bottom: 33.3333%;
}

.left-1\/3 {
  left: 33.3333%;
}

.top-2\/3 {
  top: 66.6667%;
}

.right-2\/3 {
  right: 66.6667%;
}

.bottom-2\/3 {
  bottom: 66.6667%;
}

.left-2\/3 {
  left: 66.6667%;
}

.top-1\/4 {
  top: 25%;
}

.right-1\/4 {
  right: 25%;
}

.bottom-1\/4 {
  bottom: 25%;
}

.left-1\/4 {
  left: 25%;
}

.top-2\/4 {
  top: 50%;
}

.right-2\/4 {
  right: 50%;
}

.bottom-2\/4 {
  bottom: 50%;
}

.left-2\/4 {
  left: 50%;
}

.top-3\/4 {
  top: 75%;
}

.right-3\/4 {
  right: 75%;
}

.bottom-3\/4 {
  bottom: 75%;
}

.left-3\/4 {
  left: 75%;
}

.top-1\/5 {
  top: 20%;
}

.right-1\/5 {
  right: 20%;
}

.bottom-1\/5 {
  bottom: 20%;
}

.left-1\/5 {
  left: 20%;
}

.top-2\/5 {
  top: 40%;
}

.right-2\/5 {
  right: 40%;
}

.bottom-2\/5 {
  bottom: 40%;
}

.left-2\/5 {
  left: 40%;
}

.top-3\/5 {
  top: 60%;
}

.right-3\/5 {
  right: 60%;
}

.bottom-3\/5 {
  bottom: 60%;
}

.left-3\/5 {
  left: 60%;
}

.top-4\/5 {
  top: 80%;
}

.right-4\/5 {
  right: 80%;
}

.bottom-4\/5 {
  bottom: 80%;
}

.left-4\/5 {
  left: 80%;
}

.top-1\/6 {
  top: 16.6667%;
}

.right-1\/6 {
  right: 16.6667%;
}

.bottom-1\/6 {
  bottom: 16.6667%;
}

.left-1\/6 {
  left: 16.6667%;
}

.top-2\/6 {
  top: 33.3333%;
}

.right-2\/6 {
  right: 33.3333%;
}

.bottom-2\/6 {
  bottom: 33.3333%;
}

.left-2\/6 {
  left: 33.3333%;
}

.top-3\/6 {
  top: 50%;
}

.right-3\/6 {
  right: 50%;
}

.bottom-3\/6 {
  bottom: 50%;
}

.left-3\/6 {
  left: 50%;
}

.top-4\/6 {
  top: 66.6667%;
}

.right-4\/6 {
  right: 66.6667%;
}

.bottom-4\/6 {
  bottom: 66.6667%;
}

.left-4\/6 {
  left: 66.6667%;
}

.top-5\/6 {
  top: 83.3333%;
}

.right-5\/6 {
  right: 83.3333%;
}

.bottom-5\/6 {
  bottom: 83.3333%;
}

.left-5\/6 {
  left: 83.3333%;
}

.top-1\/12 {
  top: 8.33333%;
}

.right-1\/12 {
  right: 8.33333%;
}

.bottom-1\/12 {
  bottom: 8.33333%;
}

.left-1\/12 {
  left: 8.33333%;
}

.top-2\/12 {
  top: 16.6667%;
}

.right-2\/12 {
  right: 16.6667%;
}

.bottom-2\/12 {
  bottom: 16.6667%;
}

.left-2\/12 {
  left: 16.6667%;
}

.top-3\/12 {
  top: 25%;
}

.right-3\/12 {
  right: 25%;
}

.bottom-3\/12 {
  bottom: 25%;
}

.left-3\/12 {
  left: 25%;
}

.top-4\/12 {
  top: 33.3333%;
}

.right-4\/12 {
  right: 33.3333%;
}

.bottom-4\/12 {
  bottom: 33.3333%;
}

.left-4\/12 {
  left: 33.3333%;
}

.top-5\/12 {
  top: 41.6667%;
}

.right-5\/12 {
  right: 41.6667%;
}

.bottom-5\/12 {
  bottom: 41.6667%;
}

.left-5\/12 {
  left: 41.6667%;
}

.top-6\/12 {
  top: 50%;
}

.right-6\/12 {
  right: 50%;
}

.bottom-6\/12 {
  bottom: 50%;
}

.left-6\/12 {
  left: 50%;
}

.top-7\/12 {
  top: 58.3333%;
}

.right-7\/12 {
  right: 58.3333%;
}

.bottom-7\/12 {
  bottom: 58.3333%;
}

.left-7\/12 {
  left: 58.3333%;
}

.top-8\/12 {
  top: 66.6667%;
}

.right-8\/12 {
  right: 66.6667%;
}

.bottom-8\/12 {
  bottom: 66.6667%;
}

.left-8\/12 {
  left: 66.6667%;
}

.top-9\/12 {
  top: 75%;
}

.right-9\/12 {
  right: 75%;
}

.bottom-9\/12 {
  bottom: 75%;
}

.left-9\/12 {
  left: 75%;
}

.top-10\/12 {
  top: 83.3333%;
}

.right-10\/12 {
  right: 83.3333%;
}

.bottom-10\/12 {
  bottom: 83.3333%;
}

.left-10\/12 {
  left: 83.3333%;
}

.top-11\/12 {
  top: 91.6667%;
}

.right-11\/12 {
  right: 91.6667%;
}

.bottom-11\/12 {
  bottom: 91.6667%;
}

.left-11\/12 {
  left: 91.6667%;
}

.top-full {
  top: 100%;
}

.right-full {
  right: 100%;
}

.bottom-full {
  bottom: 100%;
}

.left-full {
  left: 100%;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

.shadow-xs {
  box-shadow: 0 0 0 1px #0000000d;
}

.shadow-sm {
  box-shadow: 0 1px 2px #0000000d;
}

.shadow {
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.shadow-md {
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px #00000040;
}

.shadow-inner {
  box-shadow: inset 0 2px 4px #0000000f;
}

.shadow-outline {
  box-shadow: 0 0 0 3px #76a9fa73;
}

.shadow-none {
  box-shadow: none;
}

.shadow-solid {
  box-shadow: 0 0 0 2px;
}

.shadow-outline-gray {
  box-shadow: 0 0 0 3px #9fa6b273;
}

.shadow-outline-blue {
  box-shadow: 0 0 0 3px #a4cafe73;
}

.shadow-outline-teal {
  box-shadow: 0 0 0 3px #7edce273;
}

.shadow-outline-green {
  box-shadow: 0 0 0 3px #84e1bc73;
}

.shadow-outline-yellow {
  box-shadow: 0 0 0 3px #faca1573;
}

.shadow-outline-orange {
  box-shadow: 0 0 0 3px #fdba8c73;
}

.shadow-outline-red {
  box-shadow: 0 0 0 3px #f8b4b473;
}

.shadow-outline-pink {
  box-shadow: 0 0 0 3px #f8b4d973;
}

.shadow-outline-purple {
  box-shadow: 0 0 0 3px #cabffd73;
}

.shadow-outline-indigo {
  box-shadow: 0 0 0 3px #b4c6fc73;
}

.group:focus .group-focus\:shadow-xs {
  box-shadow: 0 0 0 1px #0000000d;
}

.group:focus .group-focus\:shadow-sm {
  box-shadow: 0 1px 2px #0000000d;
}

.group:focus .group-focus\:shadow {
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.group:focus .group-focus\:shadow-md {
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
}

.group:focus .group-focus\:shadow-lg {
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

.group:focus .group-focus\:shadow-xl {
  box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
}

.group:focus .group-focus\:shadow-2xl {
  box-shadow: 0 25px 50px -12px #00000040;
}

.group:focus .group-focus\:shadow-inner {
  box-shadow: inset 0 2px 4px #0000000f;
}

.group:focus .group-focus\:shadow-outline {
  box-shadow: 0 0 0 3px #76a9fa73;
}

.group:focus .group-focus\:shadow-none {
  box-shadow: none;
}

.group:focus .group-focus\:shadow-solid {
  box-shadow: 0 0 0 2px;
}

.group:focus .group-focus\:shadow-outline-gray {
  box-shadow: 0 0 0 3px #9fa6b273;
}

.group:focus .group-focus\:shadow-outline-blue {
  box-shadow: 0 0 0 3px #a4cafe73;
}

.group:focus .group-focus\:shadow-outline-teal {
  box-shadow: 0 0 0 3px #7edce273;
}

.group:focus .group-focus\:shadow-outline-green {
  box-shadow: 0 0 0 3px #84e1bc73;
}

.group:focus .group-focus\:shadow-outline-yellow {
  box-shadow: 0 0 0 3px #faca1573;
}

.group:focus .group-focus\:shadow-outline-orange {
  box-shadow: 0 0 0 3px #fdba8c73;
}

.group:focus .group-focus\:shadow-outline-red {
  box-shadow: 0 0 0 3px #f8b4b473;
}

.group:focus .group-focus\:shadow-outline-pink {
  box-shadow: 0 0 0 3px #f8b4d973;
}

.group:focus .group-focus\:shadow-outline-purple {
  box-shadow: 0 0 0 3px #cabffd73;
}

.group:focus .group-focus\:shadow-outline-indigo {
  box-shadow: 0 0 0 3px #b4c6fc73;
}

.hover\:shadow-xs:hover {
  box-shadow: 0 0 0 1px #0000000d;
}

.hover\:shadow-sm:hover {
  box-shadow: 0 1px 2px #0000000d;
}

.hover\:shadow:hover {
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.hover\:shadow-md:hover {
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
}

.hover\:shadow-lg:hover {
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

.hover\:shadow-xl:hover {
  box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
}

.hover\:shadow-2xl:hover {
  box-shadow: 0 25px 50px -12px #00000040;
}

.hover\:shadow-inner:hover {
  box-shadow: inset 0 2px 4px #0000000f;
}

.hover\:shadow-outline:hover {
  box-shadow: 0 0 0 3px #76a9fa73;
}

.hover\:shadow-none:hover {
  box-shadow: none;
}

.hover\:shadow-solid:hover {
  box-shadow: 0 0 0 2px;
}

.hover\:shadow-outline-gray:hover {
  box-shadow: 0 0 0 3px #9fa6b273;
}

.hover\:shadow-outline-blue:hover {
  box-shadow: 0 0 0 3px #a4cafe73;
}

.hover\:shadow-outline-teal:hover {
  box-shadow: 0 0 0 3px #7edce273;
}

.hover\:shadow-outline-green:hover {
  box-shadow: 0 0 0 3px #84e1bc73;
}

.hover\:shadow-outline-yellow:hover {
  box-shadow: 0 0 0 3px #faca1573;
}

.hover\:shadow-outline-orange:hover {
  box-shadow: 0 0 0 3px #fdba8c73;
}

.hover\:shadow-outline-red:hover {
  box-shadow: 0 0 0 3px #f8b4b473;
}

.hover\:shadow-outline-pink:hover {
  box-shadow: 0 0 0 3px #f8b4d973;
}

.hover\:shadow-outline-purple:hover {
  box-shadow: 0 0 0 3px #cabffd73;
}

.hover\:shadow-outline-indigo:hover {
  box-shadow: 0 0 0 3px #b4c6fc73;
}

.focus\:shadow-xs:focus {
  box-shadow: 0 0 0 1px #0000000d;
}

.focus\:shadow-sm:focus {
  box-shadow: 0 1px 2px #0000000d;
}

.focus\:shadow:focus {
  box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
}

.focus\:shadow-md:focus {
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
}

.focus\:shadow-lg:focus {
  box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
}

.focus\:shadow-xl:focus {
  box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
}

.focus\:shadow-2xl:focus {
  box-shadow: 0 25px 50px -12px #00000040;
}

.focus\:shadow-inner:focus {
  box-shadow: inset 0 2px 4px #0000000f;
}

.focus\:shadow-outline:focus {
  box-shadow: 0 0 0 3px #76a9fa73;
}

.focus\:shadow-none:focus {
  box-shadow: none;
}

.focus\:shadow-solid:focus {
  box-shadow: 0 0 0 2px;
}

.focus\:shadow-outline-gray:focus {
  box-shadow: 0 0 0 3px #9fa6b273;
}

.focus\:shadow-outline-blue:focus {
  box-shadow: 0 0 0 3px #a4cafe73;
}

.focus\:shadow-outline-teal:focus {
  box-shadow: 0 0 0 3px #7edce273;
}

.focus\:shadow-outline-green:focus {
  box-shadow: 0 0 0 3px #84e1bc73;
}

.focus\:shadow-outline-yellow:focus {
  box-shadow: 0 0 0 3px #faca1573;
}

.focus\:shadow-outline-orange:focus {
  box-shadow: 0 0 0 3px #fdba8c73;
}

.focus\:shadow-outline-red:focus {
  box-shadow: 0 0 0 3px #f8b4b473;
}

.focus\:shadow-outline-pink:focus {
  box-shadow: 0 0 0 3px #f8b4d973;
}

.focus\:shadow-outline-purple:focus {
  box-shadow: 0 0 0 3px #cabffd73;
}

.focus\:shadow-outline-indigo:focus {
  box-shadow: 0 0 0 3px #b4c6fc73;
}

.fill-current {
  fill: currentColor;
}

.stroke-current {
  stroke: currentColor;
}

.stroke-0 {
  stroke-width: 0;
}

.stroke-1 {
  stroke-width: 1px;
}

.stroke-2 {
  stroke-width: 2px;
}

.table-auto {
  table-layout: auto;
}

.table-fixed {
  table-layout: fixed;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.text-transparent {
  color: #0000;
}

.text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity) );
}

.text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity) );
}

.text-gray-50 {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity) );
}

.text-gray-100 {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity) );
}

.text-gray-200 {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity) );
}

.text-gray-300 {
  --text-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--text-opacity) );
}

.text-gray-400 {
  --text-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--text-opacity) );
}

.text-gray-500 {
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity) );
}

.text-gray-600 {
  --text-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--text-opacity) );
}

.text-gray-700 {
  --text-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--text-opacity) );
}

.text-gray-800 {
  --text-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--text-opacity) );
}

.text-gray-900 {
  --text-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--text-opacity) );
}

.text-cool-gray-50 {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity) );
}

.text-cool-gray-100 {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity) );
}

.text-cool-gray-200 {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity) );
}

.text-cool-gray-300 {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity) );
}

.text-cool-gray-400 {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity) );
}

.text-cool-gray-500 {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity) );
}

.text-cool-gray-600 {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity) );
}

.text-cool-gray-700 {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity) );
}

.text-cool-gray-800 {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity) );
}

.text-cool-gray-900 {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity) );
}

.text-red-50 {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity) );
}

.text-red-100 {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity) );
}

.text-red-200 {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity) );
}

.text-red-300 {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity) );
}

.text-red-400 {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity) );
}

.text-red-500 {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity) );
}

.text-red-600 {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity) );
}

.text-red-700 {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity) );
}

.text-red-800 {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity) );
}

.text-red-900 {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity) );
}

.text-orange-50 {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity) );
}

.text-orange-100 {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity) );
}

.text-orange-200 {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity) );
}

.text-orange-300 {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity) );
}

.text-orange-400 {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity) );
}

.text-orange-500 {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity) );
}

.text-orange-600 {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity) );
}

.text-orange-700 {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity) );
}

.text-orange-800 {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity) );
}

.text-orange-900 {
  --text-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--text-opacity) );
}

.text-yellow-50 {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity) );
}

.text-yellow-100 {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity) );
}

.text-yellow-200 {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity) );
}

.text-yellow-300 {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity) );
}

.text-yellow-400 {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity) );
}

.text-yellow-500 {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity) );
}

.text-yellow-600 {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity) );
}

.text-yellow-700 {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity) );
}

.text-yellow-800 {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity) );
}

.text-yellow-900 {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity) );
}

.text-green-50 {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity) );
}

.text-green-100 {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity) );
}

.text-green-200 {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity) );
}

.text-green-300 {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity) );
}

.text-green-400 {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity) );
}

.text-green-500 {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity) );
}

.text-green-600 {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity) );
}

.text-green-700 {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity) );
}

.text-green-800 {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity) );
}

.text-green-900 {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity) );
}

.text-teal-50 {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity) );
}

.text-teal-100 {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity) );
}

.text-teal-200 {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity) );
}

.text-teal-300 {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity) );
}

.text-teal-400 {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity) );
}

.text-teal-500 {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity) );
}

.text-teal-600 {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity) );
}

.text-teal-700 {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity) );
}

.text-teal-800 {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity) );
}

.text-teal-900 {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity) );
}

.text-blue-50 {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity) );
}

.text-blue-100 {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity) );
}

.text-blue-200 {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity) );
}

.text-blue-300 {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity) );
}

.text-blue-400 {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity) );
}

.text-blue-500 {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity) );
}

.text-blue-600 {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity) );
}

.text-blue-700 {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity) );
}

.text-blue-800 {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity) );
}

.text-blue-900 {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity) );
}

.text-indigo-50 {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity) );
}

.text-indigo-100 {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity) );
}

.text-indigo-200 {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity) );
}

.text-indigo-300 {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity) );
}

.text-indigo-400 {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity) );
}

.text-indigo-500 {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity) );
}

.text-indigo-600 {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity) );
}

.text-indigo-700 {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity) );
}

.text-indigo-800 {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity) );
}

.text-indigo-900 {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity) );
}

.text-purple-50 {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity) );
}

.text-purple-100 {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity) );
}

.text-purple-200 {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity) );
}

.text-purple-300 {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity) );
}

.text-purple-400 {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity) );
}

.text-purple-500 {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity) );
}

.text-purple-600 {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity) );
}

.text-purple-700 {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity) );
}

.text-purple-800 {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity) );
}

.text-purple-900 {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity) );
}

.text-pink-50 {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity) );
}

.text-pink-100 {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity) );
}

.text-pink-200 {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity) );
}

.text-pink-300 {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity) );
}

.text-pink-400 {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity) );
}

.text-pink-500 {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity) );
}

.text-pink-600 {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity) );
}

.text-pink-700 {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity) );
}

.text-pink-800 {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity) );
}

.text-pink-900 {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity) );
}

.group:hover .group-hover\:text-transparent {
  color: #0000;
}

.group:hover .group-hover\:text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity) );
}

.group:hover .group-hover\:text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-50 {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-100 {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-200 {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-300 {
  --text-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-400 {
  --text-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-500 {
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-600 {
  --text-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-700 {
  --text-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-800 {
  --text-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--text-opacity) );
}

.group:hover .group-hover\:text-gray-900 {
  --text-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-50 {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-100 {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-200 {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-300 {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-400 {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-500 {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-600 {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-700 {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-800 {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity) );
}

.group:hover .group-hover\:text-cool-gray-900 {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-50 {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-100 {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-200 {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-300 {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-400 {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-500 {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-600 {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-700 {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-800 {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity) );
}

.group:hover .group-hover\:text-red-900 {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-50 {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-100 {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-200 {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-300 {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-400 {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-500 {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-600 {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-700 {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-800 {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity) );
}

.group:hover .group-hover\:text-orange-900 {
  --text-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-50 {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-100 {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-200 {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-300 {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-400 {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-500 {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-600 {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-700 {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-800 {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity) );
}

.group:hover .group-hover\:text-yellow-900 {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-50 {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-100 {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-200 {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-300 {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-400 {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-500 {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-600 {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-700 {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-800 {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity) );
}

.group:hover .group-hover\:text-green-900 {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-50 {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-100 {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-200 {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-300 {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-400 {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-500 {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-600 {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-700 {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-800 {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity) );
}

.group:hover .group-hover\:text-teal-900 {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-50 {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-100 {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-200 {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-300 {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-400 {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-500 {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-600 {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-700 {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-800 {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity) );
}

.group:hover .group-hover\:text-blue-900 {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-50 {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-100 {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-200 {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-300 {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-400 {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-500 {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-600 {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-700 {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-800 {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity) );
}

.group:hover .group-hover\:text-indigo-900 {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-50 {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-100 {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-200 {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-300 {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-400 {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-500 {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-600 {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-700 {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-800 {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity) );
}

.group:hover .group-hover\:text-purple-900 {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-50 {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-100 {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-200 {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-300 {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-400 {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-500 {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-600 {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-700 {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-800 {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity) );
}

.group:hover .group-hover\:text-pink-900 {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity) );
}

.group:focus .group-focus\:text-transparent {
  color: #0000;
}

.group:focus .group-focus\:text-white {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity) );
}

.group:focus .group-focus\:text-black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-50 {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-100 {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-200 {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-300 {
  --text-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-400 {
  --text-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-500 {
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-600 {
  --text-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-700 {
  --text-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-800 {
  --text-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--text-opacity) );
}

.group:focus .group-focus\:text-gray-900 {
  --text-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-50 {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-100 {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-200 {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-300 {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-400 {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-500 {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-600 {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-700 {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-800 {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity) );
}

.group:focus .group-focus\:text-cool-gray-900 {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-50 {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-100 {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-200 {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-300 {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-400 {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-500 {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-600 {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-700 {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-800 {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity) );
}

.group:focus .group-focus\:text-red-900 {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-50 {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-100 {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-200 {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-300 {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-400 {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-500 {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-600 {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-700 {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-800 {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity) );
}

.group:focus .group-focus\:text-orange-900 {
  --text-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-50 {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-100 {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-200 {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-300 {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-400 {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-500 {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-600 {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-700 {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-800 {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity) );
}

.group:focus .group-focus\:text-yellow-900 {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-50 {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-100 {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-200 {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-300 {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-400 {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-500 {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-600 {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-700 {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-800 {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity) );
}

.group:focus .group-focus\:text-green-900 {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-50 {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-100 {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-200 {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-300 {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-400 {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-500 {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-600 {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-700 {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-800 {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity) );
}

.group:focus .group-focus\:text-teal-900 {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-50 {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-100 {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-200 {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-300 {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-400 {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-500 {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-600 {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-700 {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-800 {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity) );
}

.group:focus .group-focus\:text-blue-900 {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-50 {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-100 {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-200 {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-300 {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-400 {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-500 {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-600 {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-700 {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-800 {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity) );
}

.group:focus .group-focus\:text-indigo-900 {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-50 {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-100 {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-200 {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-300 {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-400 {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-500 {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-600 {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-700 {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-800 {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity) );
}

.group:focus .group-focus\:text-purple-900 {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-50 {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-100 {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-200 {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-300 {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-400 {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-500 {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-600 {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-700 {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-800 {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity) );
}

.group:focus .group-focus\:text-pink-900 {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity) );
}

.hover\:text-transparent:hover {
  color: #0000;
}

.hover\:text-white:hover {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity) );
}

.hover\:text-black:hover {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity) );
}

.hover\:text-gray-50:hover {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity) );
}

.hover\:text-gray-100:hover {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity) );
}

.hover\:text-gray-200:hover {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity) );
}

.hover\:text-gray-300:hover {
  --text-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--text-opacity) );
}

.hover\:text-gray-400:hover {
  --text-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--text-opacity) );
}

.hover\:text-gray-500:hover {
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity) );
}

.hover\:text-gray-600:hover {
  --text-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--text-opacity) );
}

.hover\:text-gray-700:hover {
  --text-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--text-opacity) );
}

.hover\:text-gray-800:hover {
  --text-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--text-opacity) );
}

.hover\:text-gray-900:hover {
  --text-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--text-opacity) );
}

.hover\:text-cool-gray-50:hover {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity) );
}

.hover\:text-cool-gray-100:hover {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity) );
}

.hover\:text-cool-gray-200:hover {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity) );
}

.hover\:text-cool-gray-300:hover {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity) );
}

.hover\:text-cool-gray-400:hover {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity) );
}

.hover\:text-cool-gray-500:hover {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity) );
}

.hover\:text-cool-gray-600:hover {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity) );
}

.hover\:text-cool-gray-700:hover {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity) );
}

.hover\:text-cool-gray-800:hover {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity) );
}

.hover\:text-cool-gray-900:hover {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity) );
}

.hover\:text-red-50:hover {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity) );
}

.hover\:text-red-100:hover {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity) );
}

.hover\:text-red-200:hover {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity) );
}

.hover\:text-red-300:hover {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity) );
}

.hover\:text-red-400:hover {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity) );
}

.hover\:text-red-500:hover {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity) );
}

.hover\:text-red-600:hover {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity) );
}

.hover\:text-red-700:hover {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity) );
}

.hover\:text-red-800:hover {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity) );
}

.hover\:text-red-900:hover {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity) );
}

.hover\:text-orange-50:hover {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity) );
}

.hover\:text-orange-100:hover {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity) );
}

.hover\:text-orange-200:hover {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity) );
}

.hover\:text-orange-300:hover {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity) );
}

.hover\:text-orange-400:hover {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity) );
}

.hover\:text-orange-500:hover {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity) );
}

.hover\:text-orange-600:hover {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity) );
}

.hover\:text-orange-700:hover {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity) );
}

.hover\:text-orange-800:hover {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity) );
}

.hover\:text-orange-900:hover {
  --text-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--text-opacity) );
}

.hover\:text-yellow-50:hover {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity) );
}

.hover\:text-yellow-100:hover {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity) );
}

.hover\:text-yellow-200:hover {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity) );
}

.hover\:text-yellow-300:hover {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity) );
}

.hover\:text-yellow-400:hover {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity) );
}

.hover\:text-yellow-500:hover {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity) );
}

.hover\:text-yellow-600:hover {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity) );
}

.hover\:text-yellow-700:hover {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity) );
}

.hover\:text-yellow-800:hover {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity) );
}

.hover\:text-yellow-900:hover {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity) );
}

.hover\:text-green-50:hover {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity) );
}

.hover\:text-green-100:hover {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity) );
}

.hover\:text-green-200:hover {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity) );
}

.hover\:text-green-300:hover {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity) );
}

.hover\:text-green-400:hover {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity) );
}

.hover\:text-green-500:hover {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity) );
}

.hover\:text-green-600:hover {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity) );
}

.hover\:text-green-700:hover {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity) );
}

.hover\:text-green-800:hover {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity) );
}

.hover\:text-green-900:hover {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity) );
}

.hover\:text-teal-50:hover {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity) );
}

.hover\:text-teal-100:hover {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity) );
}

.hover\:text-teal-200:hover {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity) );
}

.hover\:text-teal-300:hover {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity) );
}

.hover\:text-teal-400:hover {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity) );
}

.hover\:text-teal-500:hover {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity) );
}

.hover\:text-teal-600:hover {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity) );
}

.hover\:text-teal-700:hover {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity) );
}

.hover\:text-teal-800:hover {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity) );
}

.hover\:text-teal-900:hover {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity) );
}

.hover\:text-blue-50:hover {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity) );
}

.hover\:text-blue-100:hover {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity) );
}

.hover\:text-blue-200:hover {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity) );
}

.hover\:text-blue-300:hover {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity) );
}

.hover\:text-blue-400:hover {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity) );
}

.hover\:text-blue-500:hover {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity) );
}

.hover\:text-blue-600:hover {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity) );
}

.hover\:text-blue-700:hover {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity) );
}

.hover\:text-blue-800:hover {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity) );
}

.hover\:text-blue-900:hover {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity) );
}

.hover\:text-indigo-50:hover {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity) );
}

.hover\:text-indigo-100:hover {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity) );
}

.hover\:text-indigo-200:hover {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity) );
}

.hover\:text-indigo-300:hover {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity) );
}

.hover\:text-indigo-400:hover {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity) );
}

.hover\:text-indigo-500:hover {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity) );
}

.hover\:text-indigo-600:hover {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity) );
}

.hover\:text-indigo-700:hover {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity) );
}

.hover\:text-indigo-800:hover {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity) );
}

.hover\:text-indigo-900:hover {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity) );
}

.hover\:text-purple-50:hover {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity) );
}

.hover\:text-purple-100:hover {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity) );
}

.hover\:text-purple-200:hover {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity) );
}

.hover\:text-purple-300:hover {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity) );
}

.hover\:text-purple-400:hover {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity) );
}

.hover\:text-purple-500:hover {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity) );
}

.hover\:text-purple-600:hover {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity) );
}

.hover\:text-purple-700:hover {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity) );
}

.hover\:text-purple-800:hover {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity) );
}

.hover\:text-purple-900:hover {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity) );
}

.hover\:text-pink-50:hover {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity) );
}

.hover\:text-pink-100:hover {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity) );
}

.hover\:text-pink-200:hover {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity) );
}

.hover\:text-pink-300:hover {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity) );
}

.hover\:text-pink-400:hover {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity) );
}

.hover\:text-pink-500:hover {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity) );
}

.hover\:text-pink-600:hover {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity) );
}

.hover\:text-pink-700:hover {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity) );
}

.hover\:text-pink-800:hover {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity) );
}

.hover\:text-pink-900:hover {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity) );
}

.focus-within\:text-transparent:focus-within {
  color: #0000;
}

.focus-within\:text-white:focus-within {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity) );
}

.focus-within\:text-black:focus-within {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity) );
}

.focus-within\:text-gray-50:focus-within {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity) );
}

.focus-within\:text-gray-100:focus-within {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity) );
}

.focus-within\:text-gray-200:focus-within {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity) );
}

.focus-within\:text-gray-300:focus-within {
  --text-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--text-opacity) );
}

.focus-within\:text-gray-400:focus-within {
  --text-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--text-opacity) );
}

.focus-within\:text-gray-500:focus-within {
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity) );
}

.focus-within\:text-gray-600:focus-within {
  --text-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--text-opacity) );
}

.focus-within\:text-gray-700:focus-within {
  --text-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--text-opacity) );
}

.focus-within\:text-gray-800:focus-within {
  --text-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--text-opacity) );
}

.focus-within\:text-gray-900:focus-within {
  --text-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--text-opacity) );
}

.focus-within\:text-cool-gray-50:focus-within {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity) );
}

.focus-within\:text-cool-gray-100:focus-within {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity) );
}

.focus-within\:text-cool-gray-200:focus-within {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity) );
}

.focus-within\:text-cool-gray-300:focus-within {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity) );
}

.focus-within\:text-cool-gray-400:focus-within {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity) );
}

.focus-within\:text-cool-gray-500:focus-within {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity) );
}

.focus-within\:text-cool-gray-600:focus-within {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity) );
}

.focus-within\:text-cool-gray-700:focus-within {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity) );
}

.focus-within\:text-cool-gray-800:focus-within {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity) );
}

.focus-within\:text-cool-gray-900:focus-within {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity) );
}

.focus-within\:text-red-50:focus-within {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity) );
}

.focus-within\:text-red-100:focus-within {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity) );
}

.focus-within\:text-red-200:focus-within {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity) );
}

.focus-within\:text-red-300:focus-within {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity) );
}

.focus-within\:text-red-400:focus-within {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity) );
}

.focus-within\:text-red-500:focus-within {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity) );
}

.focus-within\:text-red-600:focus-within {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity) );
}

.focus-within\:text-red-700:focus-within {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity) );
}

.focus-within\:text-red-800:focus-within {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity) );
}

.focus-within\:text-red-900:focus-within {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity) );
}

.focus-within\:text-orange-50:focus-within {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity) );
}

.focus-within\:text-orange-100:focus-within {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity) );
}

.focus-within\:text-orange-200:focus-within {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity) );
}

.focus-within\:text-orange-300:focus-within {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity) );
}

.focus-within\:text-orange-400:focus-within {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity) );
}

.focus-within\:text-orange-500:focus-within {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity) );
}

.focus-within\:text-orange-600:focus-within {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity) );
}

.focus-within\:text-orange-700:focus-within {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity) );
}

.focus-within\:text-orange-800:focus-within {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity) );
}

.focus-within\:text-orange-900:focus-within {
  --text-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--text-opacity) );
}

.focus-within\:text-yellow-50:focus-within {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity) );
}

.focus-within\:text-yellow-100:focus-within {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity) );
}

.focus-within\:text-yellow-200:focus-within {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity) );
}

.focus-within\:text-yellow-300:focus-within {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity) );
}

.focus-within\:text-yellow-400:focus-within {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity) );
}

.focus-within\:text-yellow-500:focus-within {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity) );
}

.focus-within\:text-yellow-600:focus-within {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity) );
}

.focus-within\:text-yellow-700:focus-within {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity) );
}

.focus-within\:text-yellow-800:focus-within {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity) );
}

.focus-within\:text-yellow-900:focus-within {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity) );
}

.focus-within\:text-green-50:focus-within {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity) );
}

.focus-within\:text-green-100:focus-within {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity) );
}

.focus-within\:text-green-200:focus-within {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity) );
}

.focus-within\:text-green-300:focus-within {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity) );
}

.focus-within\:text-green-400:focus-within {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity) );
}

.focus-within\:text-green-500:focus-within {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity) );
}

.focus-within\:text-green-600:focus-within {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity) );
}

.focus-within\:text-green-700:focus-within {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity) );
}

.focus-within\:text-green-800:focus-within {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity) );
}

.focus-within\:text-green-900:focus-within {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity) );
}

.focus-within\:text-teal-50:focus-within {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity) );
}

.focus-within\:text-teal-100:focus-within {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity) );
}

.focus-within\:text-teal-200:focus-within {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity) );
}

.focus-within\:text-teal-300:focus-within {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity) );
}

.focus-within\:text-teal-400:focus-within {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity) );
}

.focus-within\:text-teal-500:focus-within {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity) );
}

.focus-within\:text-teal-600:focus-within {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity) );
}

.focus-within\:text-teal-700:focus-within {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity) );
}

.focus-within\:text-teal-800:focus-within {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity) );
}

.focus-within\:text-teal-900:focus-within {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity) );
}

.focus-within\:text-blue-50:focus-within {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity) );
}

.focus-within\:text-blue-100:focus-within {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity) );
}

.focus-within\:text-blue-200:focus-within {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity) );
}

.focus-within\:text-blue-300:focus-within {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity) );
}

.focus-within\:text-blue-400:focus-within {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity) );
}

.focus-within\:text-blue-500:focus-within {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity) );
}

.focus-within\:text-blue-600:focus-within {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity) );
}

.focus-within\:text-blue-700:focus-within {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity) );
}

.focus-within\:text-blue-800:focus-within {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity) );
}

.focus-within\:text-blue-900:focus-within {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity) );
}

.focus-within\:text-indigo-50:focus-within {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity) );
}

.focus-within\:text-indigo-100:focus-within {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity) );
}

.focus-within\:text-indigo-200:focus-within {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity) );
}

.focus-within\:text-indigo-300:focus-within {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity) );
}

.focus-within\:text-indigo-400:focus-within {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity) );
}

.focus-within\:text-indigo-500:focus-within {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity) );
}

.focus-within\:text-indigo-600:focus-within {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity) );
}

.focus-within\:text-indigo-700:focus-within {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity) );
}

.focus-within\:text-indigo-800:focus-within {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity) );
}

.focus-within\:text-indigo-900:focus-within {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity) );
}

.focus-within\:text-purple-50:focus-within {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity) );
}

.focus-within\:text-purple-100:focus-within {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity) );
}

.focus-within\:text-purple-200:focus-within {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity) );
}

.focus-within\:text-purple-300:focus-within {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity) );
}

.focus-within\:text-purple-400:focus-within {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity) );
}

.focus-within\:text-purple-500:focus-within {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity) );
}

.focus-within\:text-purple-600:focus-within {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity) );
}

.focus-within\:text-purple-700:focus-within {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity) );
}

.focus-within\:text-purple-800:focus-within {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity) );
}

.focus-within\:text-purple-900:focus-within {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity) );
}

.focus-within\:text-pink-50:focus-within {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity) );
}

.focus-within\:text-pink-100:focus-within {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity) );
}

.focus-within\:text-pink-200:focus-within {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity) );
}

.focus-within\:text-pink-300:focus-within {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity) );
}

.focus-within\:text-pink-400:focus-within {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity) );
}

.focus-within\:text-pink-500:focus-within {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity) );
}

.focus-within\:text-pink-600:focus-within {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity) );
}

.focus-within\:text-pink-700:focus-within {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity) );
}

.focus-within\:text-pink-800:focus-within {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity) );
}

.focus-within\:text-pink-900:focus-within {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity) );
}

.focus\:text-transparent:focus {
  color: #0000;
}

.focus\:text-white:focus {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity) );
}

.focus\:text-black:focus {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity) );
}

.focus\:text-gray-50:focus {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity) );
}

.focus\:text-gray-100:focus {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity) );
}

.focus\:text-gray-200:focus {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity) );
}

.focus\:text-gray-300:focus {
  --text-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--text-opacity) );
}

.focus\:text-gray-400:focus {
  --text-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--text-opacity) );
}

.focus\:text-gray-500:focus {
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity) );
}

.focus\:text-gray-600:focus {
  --text-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--text-opacity) );
}

.focus\:text-gray-700:focus {
  --text-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--text-opacity) );
}

.focus\:text-gray-800:focus {
  --text-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--text-opacity) );
}

.focus\:text-gray-900:focus {
  --text-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--text-opacity) );
}

.focus\:text-cool-gray-50:focus {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity) );
}

.focus\:text-cool-gray-100:focus {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity) );
}

.focus\:text-cool-gray-200:focus {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity) );
}

.focus\:text-cool-gray-300:focus {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity) );
}

.focus\:text-cool-gray-400:focus {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity) );
}

.focus\:text-cool-gray-500:focus {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity) );
}

.focus\:text-cool-gray-600:focus {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity) );
}

.focus\:text-cool-gray-700:focus {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity) );
}

.focus\:text-cool-gray-800:focus {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity) );
}

.focus\:text-cool-gray-900:focus {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity) );
}

.focus\:text-red-50:focus {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity) );
}

.focus\:text-red-100:focus {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity) );
}

.focus\:text-red-200:focus {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity) );
}

.focus\:text-red-300:focus {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity) );
}

.focus\:text-red-400:focus {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity) );
}

.focus\:text-red-500:focus {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity) );
}

.focus\:text-red-600:focus {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity) );
}

.focus\:text-red-700:focus {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity) );
}

.focus\:text-red-800:focus {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity) );
}

.focus\:text-red-900:focus {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity) );
}

.focus\:text-orange-50:focus {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity) );
}

.focus\:text-orange-100:focus {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity) );
}

.focus\:text-orange-200:focus {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity) );
}

.focus\:text-orange-300:focus {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity) );
}

.focus\:text-orange-400:focus {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity) );
}

.focus\:text-orange-500:focus {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity) );
}

.focus\:text-orange-600:focus {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity) );
}

.focus\:text-orange-700:focus {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity) );
}

.focus\:text-orange-800:focus {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity) );
}

.focus\:text-orange-900:focus {
  --text-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--text-opacity) );
}

.focus\:text-yellow-50:focus {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity) );
}

.focus\:text-yellow-100:focus {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity) );
}

.focus\:text-yellow-200:focus {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity) );
}

.focus\:text-yellow-300:focus {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity) );
}

.focus\:text-yellow-400:focus {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity) );
}

.focus\:text-yellow-500:focus {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity) );
}

.focus\:text-yellow-600:focus {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity) );
}

.focus\:text-yellow-700:focus {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity) );
}

.focus\:text-yellow-800:focus {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity) );
}

.focus\:text-yellow-900:focus {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity) );
}

.focus\:text-green-50:focus {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity) );
}

.focus\:text-green-100:focus {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity) );
}

.focus\:text-green-200:focus {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity) );
}

.focus\:text-green-300:focus {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity) );
}

.focus\:text-green-400:focus {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity) );
}

.focus\:text-green-500:focus {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity) );
}

.focus\:text-green-600:focus {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity) );
}

.focus\:text-green-700:focus {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity) );
}

.focus\:text-green-800:focus {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity) );
}

.focus\:text-green-900:focus {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity) );
}

.focus\:text-teal-50:focus {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity) );
}

.focus\:text-teal-100:focus {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity) );
}

.focus\:text-teal-200:focus {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity) );
}

.focus\:text-teal-300:focus {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity) );
}

.focus\:text-teal-400:focus {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity) );
}

.focus\:text-teal-500:focus {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity) );
}

.focus\:text-teal-600:focus {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity) );
}

.focus\:text-teal-700:focus {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity) );
}

.focus\:text-teal-800:focus {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity) );
}

.focus\:text-teal-900:focus {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity) );
}

.focus\:text-blue-50:focus {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity) );
}

.focus\:text-blue-100:focus {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity) );
}

.focus\:text-blue-200:focus {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity) );
}

.focus\:text-blue-300:focus {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity) );
}

.focus\:text-blue-400:focus {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity) );
}

.focus\:text-blue-500:focus {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity) );
}

.focus\:text-blue-600:focus {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity) );
}

.focus\:text-blue-700:focus {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity) );
}

.focus\:text-blue-800:focus {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity) );
}

.focus\:text-blue-900:focus {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity) );
}

.focus\:text-indigo-50:focus {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity) );
}

.focus\:text-indigo-100:focus {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity) );
}

.focus\:text-indigo-200:focus {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity) );
}

.focus\:text-indigo-300:focus {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity) );
}

.focus\:text-indigo-400:focus {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity) );
}

.focus\:text-indigo-500:focus {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity) );
}

.focus\:text-indigo-600:focus {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity) );
}

.focus\:text-indigo-700:focus {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity) );
}

.focus\:text-indigo-800:focus {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity) );
}

.focus\:text-indigo-900:focus {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity) );
}

.focus\:text-purple-50:focus {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity) );
}

.focus\:text-purple-100:focus {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity) );
}

.focus\:text-purple-200:focus {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity) );
}

.focus\:text-purple-300:focus {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity) );
}

.focus\:text-purple-400:focus {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity) );
}

.focus\:text-purple-500:focus {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity) );
}

.focus\:text-purple-600:focus {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity) );
}

.focus\:text-purple-700:focus {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity) );
}

.focus\:text-purple-800:focus {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity) );
}

.focus\:text-purple-900:focus {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity) );
}

.focus\:text-pink-50:focus {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity) );
}

.focus\:text-pink-100:focus {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity) );
}

.focus\:text-pink-200:focus {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity) );
}

.focus\:text-pink-300:focus {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity) );
}

.focus\:text-pink-400:focus {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity) );
}

.focus\:text-pink-500:focus {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity) );
}

.focus\:text-pink-600:focus {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity) );
}

.focus\:text-pink-700:focus {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity) );
}

.focus\:text-pink-800:focus {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity) );
}

.focus\:text-pink-900:focus {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity) );
}

.active\:text-transparent:active {
  color: #0000;
}

.active\:text-white:active {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity) );
}

.active\:text-black:active {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity) );
}

.active\:text-gray-50:active {
  --text-opacity: 1;
  color: #f9fafb;
  color: rgba(249, 250, 251, var(--text-opacity) );
}

.active\:text-gray-100:active {
  --text-opacity: 1;
  color: #f4f5f7;
  color: rgba(244, 245, 247, var(--text-opacity) );
}

.active\:text-gray-200:active {
  --text-opacity: 1;
  color: #e5e7eb;
  color: rgba(229, 231, 235, var(--text-opacity) );
}

.active\:text-gray-300:active {
  --text-opacity: 1;
  color: #d2d6dc;
  color: rgba(210, 214, 220, var(--text-opacity) );
}

.active\:text-gray-400:active {
  --text-opacity: 1;
  color: #9fa6b2;
  color: rgba(159, 166, 178, var(--text-opacity) );
}

.active\:text-gray-500:active {
  --text-opacity: 1;
  color: #6b7280;
  color: rgba(107, 114, 128, var(--text-opacity) );
}

.active\:text-gray-600:active {
  --text-opacity: 1;
  color: #4b5563;
  color: rgba(75, 85, 99, var(--text-opacity) );
}

.active\:text-gray-700:active {
  --text-opacity: 1;
  color: #374151;
  color: rgba(55, 65, 81, var(--text-opacity) );
}

.active\:text-gray-800:active {
  --text-opacity: 1;
  color: #252f3f;
  color: rgba(37, 47, 63, var(--text-opacity) );
}

.active\:text-gray-900:active {
  --text-opacity: 1;
  color: #161e2e;
  color: rgba(22, 30, 46, var(--text-opacity) );
}

.active\:text-cool-gray-50:active {
  --text-opacity: 1;
  color: #fbfdfe;
  color: rgba(251, 253, 254, var(--text-opacity) );
}

.active\:text-cool-gray-100:active {
  --text-opacity: 1;
  color: #f1f5f9;
  color: rgba(241, 245, 249, var(--text-opacity) );
}

.active\:text-cool-gray-200:active {
  --text-opacity: 1;
  color: #e2e8f0;
  color: rgba(226, 232, 240, var(--text-opacity) );
}

.active\:text-cool-gray-300:active {
  --text-opacity: 1;
  color: #cfd8e3;
  color: rgba(207, 216, 227, var(--text-opacity) );
}

.active\:text-cool-gray-400:active {
  --text-opacity: 1;
  color: #97a6ba;
  color: rgba(151, 166, 186, var(--text-opacity) );
}

.active\:text-cool-gray-500:active {
  --text-opacity: 1;
  color: #64748b;
  color: rgba(100, 116, 139, var(--text-opacity) );
}

.active\:text-cool-gray-600:active {
  --text-opacity: 1;
  color: #475569;
  color: rgba(71, 85, 105, var(--text-opacity) );
}

.active\:text-cool-gray-700:active {
  --text-opacity: 1;
  color: #364152;
  color: rgba(54, 65, 82, var(--text-opacity) );
}

.active\:text-cool-gray-800:active {
  --text-opacity: 1;
  color: #27303f;
  color: rgba(39, 48, 63, var(--text-opacity) );
}

.active\:text-cool-gray-900:active {
  --text-opacity: 1;
  color: #1a202e;
  color: rgba(26, 32, 46, var(--text-opacity) );
}

.active\:text-red-50:active {
  --text-opacity: 1;
  color: #fdf2f2;
  color: rgba(253, 242, 242, var(--text-opacity) );
}

.active\:text-red-100:active {
  --text-opacity: 1;
  color: #fde8e8;
  color: rgba(253, 232, 232, var(--text-opacity) );
}

.active\:text-red-200:active {
  --text-opacity: 1;
  color: #fbd5d5;
  color: rgba(251, 213, 213, var(--text-opacity) );
}

.active\:text-red-300:active {
  --text-opacity: 1;
  color: #f8b4b4;
  color: rgba(248, 180, 180, var(--text-opacity) );
}

.active\:text-red-400:active {
  --text-opacity: 1;
  color: #f98080;
  color: rgba(249, 128, 128, var(--text-opacity) );
}

.active\:text-red-500:active {
  --text-opacity: 1;
  color: #f05252;
  color: rgba(240, 82, 82, var(--text-opacity) );
}

.active\:text-red-600:active {
  --text-opacity: 1;
  color: #e02424;
  color: rgba(224, 36, 36, var(--text-opacity) );
}

.active\:text-red-700:active {
  --text-opacity: 1;
  color: #c81e1e;
  color: rgba(200, 30, 30, var(--text-opacity) );
}

.active\:text-red-800:active {
  --text-opacity: 1;
  color: #9b1c1c;
  color: rgba(155, 28, 28, var(--text-opacity) );
}

.active\:text-red-900:active {
  --text-opacity: 1;
  color: #771d1d;
  color: rgba(119, 29, 29, var(--text-opacity) );
}

.active\:text-orange-50:active {
  --text-opacity: 1;
  color: #fff8f1;
  color: rgba(255, 248, 241, var(--text-opacity) );
}

.active\:text-orange-100:active {
  --text-opacity: 1;
  color: #feecdc;
  color: rgba(254, 236, 220, var(--text-opacity) );
}

.active\:text-orange-200:active {
  --text-opacity: 1;
  color: #fcd9bd;
  color: rgba(252, 217, 189, var(--text-opacity) );
}

.active\:text-orange-300:active {
  --text-opacity: 1;
  color: #fdba8c;
  color: rgba(253, 186, 140, var(--text-opacity) );
}

.active\:text-orange-400:active {
  --text-opacity: 1;
  color: #ff8a4c;
  color: rgba(255, 138, 76, var(--text-opacity) );
}

.active\:text-orange-500:active {
  --text-opacity: 1;
  color: #ff5a1f;
  color: rgba(255, 90, 31, var(--text-opacity) );
}

.active\:text-orange-600:active {
  --text-opacity: 1;
  color: #d03801;
  color: rgba(208, 56, 1, var(--text-opacity) );
}

.active\:text-orange-700:active {
  --text-opacity: 1;
  color: #b43403;
  color: rgba(180, 52, 3, var(--text-opacity) );
}

.active\:text-orange-800:active {
  --text-opacity: 1;
  color: #8a2c0d;
  color: rgba(138, 44, 13, var(--text-opacity) );
}

.active\:text-orange-900:active {
  --text-opacity: 1;
  color: #73230d;
  color: rgba(115, 35, 13, var(--text-opacity) );
}

.active\:text-yellow-50:active {
  --text-opacity: 1;
  color: #fdfdea;
  color: rgba(253, 253, 234, var(--text-opacity) );
}

.active\:text-yellow-100:active {
  --text-opacity: 1;
  color: #fdf6b2;
  color: rgba(253, 246, 178, var(--text-opacity) );
}

.active\:text-yellow-200:active {
  --text-opacity: 1;
  color: #fce96a;
  color: rgba(252, 233, 106, var(--text-opacity) );
}

.active\:text-yellow-300:active {
  --text-opacity: 1;
  color: #faca15;
  color: rgba(250, 202, 21, var(--text-opacity) );
}

.active\:text-yellow-400:active {
  --text-opacity: 1;
  color: #e3a008;
  color: rgba(227, 160, 8, var(--text-opacity) );
}

.active\:text-yellow-500:active {
  --text-opacity: 1;
  color: #c27803;
  color: rgba(194, 120, 3, var(--text-opacity) );
}

.active\:text-yellow-600:active {
  --text-opacity: 1;
  color: #9f580a;
  color: rgba(159, 88, 10, var(--text-opacity) );
}

.active\:text-yellow-700:active {
  --text-opacity: 1;
  color: #8e4b10;
  color: rgba(142, 75, 16, var(--text-opacity) );
}

.active\:text-yellow-800:active {
  --text-opacity: 1;
  color: #723b13;
  color: rgba(114, 59, 19, var(--text-opacity) );
}

.active\:text-yellow-900:active {
  --text-opacity: 1;
  color: #633112;
  color: rgba(99, 49, 18, var(--text-opacity) );
}

.active\:text-green-50:active {
  --text-opacity: 1;
  color: #f3faf7;
  color: rgba(243, 250, 247, var(--text-opacity) );
}

.active\:text-green-100:active {
  --text-opacity: 1;
  color: #def7ec;
  color: rgba(222, 247, 236, var(--text-opacity) );
}

.active\:text-green-200:active {
  --text-opacity: 1;
  color: #bcf0da;
  color: rgba(188, 240, 218, var(--text-opacity) );
}

.active\:text-green-300:active {
  --text-opacity: 1;
  color: #84e1bc;
  color: rgba(132, 225, 188, var(--text-opacity) );
}

.active\:text-green-400:active {
  --text-opacity: 1;
  color: #31c48d;
  color: rgba(49, 196, 141, var(--text-opacity) );
}

.active\:text-green-500:active {
  --text-opacity: 1;
  color: #0e9f6e;
  color: rgba(14, 159, 110, var(--text-opacity) );
}

.active\:text-green-600:active {
  --text-opacity: 1;
  color: #057a55;
  color: rgba(5, 122, 85, var(--text-opacity) );
}

.active\:text-green-700:active {
  --text-opacity: 1;
  color: #046c4e;
  color: rgba(4, 108, 78, var(--text-opacity) );
}

.active\:text-green-800:active {
  --text-opacity: 1;
  color: #03543f;
  color: rgba(3, 84, 63, var(--text-opacity) );
}

.active\:text-green-900:active {
  --text-opacity: 1;
  color: #014737;
  color: rgba(1, 71, 55, var(--text-opacity) );
}

.active\:text-teal-50:active {
  --text-opacity: 1;
  color: #edfafa;
  color: rgba(237, 250, 250, var(--text-opacity) );
}

.active\:text-teal-100:active {
  --text-opacity: 1;
  color: #d5f5f6;
  color: rgba(213, 245, 246, var(--text-opacity) );
}

.active\:text-teal-200:active {
  --text-opacity: 1;
  color: #afecef;
  color: rgba(175, 236, 239, var(--text-opacity) );
}

.active\:text-teal-300:active {
  --text-opacity: 1;
  color: #7edce2;
  color: rgba(126, 220, 226, var(--text-opacity) );
}

.active\:text-teal-400:active {
  --text-opacity: 1;
  color: #16bdca;
  color: rgba(22, 189, 202, var(--text-opacity) );
}

.active\:text-teal-500:active {
  --text-opacity: 1;
  color: #0694a2;
  color: rgba(6, 148, 162, var(--text-opacity) );
}

.active\:text-teal-600:active {
  --text-opacity: 1;
  color: #047481;
  color: rgba(4, 116, 129, var(--text-opacity) );
}

.active\:text-teal-700:active {
  --text-opacity: 1;
  color: #036672;
  color: rgba(3, 102, 114, var(--text-opacity) );
}

.active\:text-teal-800:active {
  --text-opacity: 1;
  color: #05505c;
  color: rgba(5, 80, 92, var(--text-opacity) );
}

.active\:text-teal-900:active {
  --text-opacity: 1;
  color: #014451;
  color: rgba(1, 68, 81, var(--text-opacity) );
}

.active\:text-blue-50:active {
  --text-opacity: 1;
  color: #ebf5ff;
  color: rgba(235, 245, 255, var(--text-opacity) );
}

.active\:text-blue-100:active {
  --text-opacity: 1;
  color: #e1effe;
  color: rgba(225, 239, 254, var(--text-opacity) );
}

.active\:text-blue-200:active {
  --text-opacity: 1;
  color: #c3ddfd;
  color: rgba(195, 221, 253, var(--text-opacity) );
}

.active\:text-blue-300:active {
  --text-opacity: 1;
  color: #a4cafe;
  color: rgba(164, 202, 254, var(--text-opacity) );
}

.active\:text-blue-400:active {
  --text-opacity: 1;
  color: #76a9fa;
  color: rgba(118, 169, 250, var(--text-opacity) );
}

.active\:text-blue-500:active {
  --text-opacity: 1;
  color: #3f83f8;
  color: rgba(63, 131, 248, var(--text-opacity) );
}

.active\:text-blue-600:active {
  --text-opacity: 1;
  color: #1c64f2;
  color: rgba(28, 100, 242, var(--text-opacity) );
}

.active\:text-blue-700:active {
  --text-opacity: 1;
  color: #1a56db;
  color: rgba(26, 86, 219, var(--text-opacity) );
}

.active\:text-blue-800:active {
  --text-opacity: 1;
  color: #1e429f;
  color: rgba(30, 66, 159, var(--text-opacity) );
}

.active\:text-blue-900:active {
  --text-opacity: 1;
  color: #233876;
  color: rgba(35, 56, 118, var(--text-opacity) );
}

.active\:text-indigo-50:active {
  --text-opacity: 1;
  color: #f0f5ff;
  color: rgba(240, 245, 255, var(--text-opacity) );
}

.active\:text-indigo-100:active {
  --text-opacity: 1;
  color: #e5edff;
  color: rgba(229, 237, 255, var(--text-opacity) );
}

.active\:text-indigo-200:active {
  --text-opacity: 1;
  color: #cddbfe;
  color: rgba(205, 219, 254, var(--text-opacity) );
}

.active\:text-indigo-300:active {
  --text-opacity: 1;
  color: #b4c6fc;
  color: rgba(180, 198, 252, var(--text-opacity) );
}

.active\:text-indigo-400:active {
  --text-opacity: 1;
  color: #8da2fb;
  color: rgba(141, 162, 251, var(--text-opacity) );
}

.active\:text-indigo-500:active {
  --text-opacity: 1;
  color: #6875f5;
  color: rgba(104, 117, 245, var(--text-opacity) );
}

.active\:text-indigo-600:active {
  --text-opacity: 1;
  color: #5850ec;
  color: rgba(88, 80, 236, var(--text-opacity) );
}

.active\:text-indigo-700:active {
  --text-opacity: 1;
  color: #5145cd;
  color: rgba(81, 69, 205, var(--text-opacity) );
}

.active\:text-indigo-800:active {
  --text-opacity: 1;
  color: #42389d;
  color: rgba(66, 56, 157, var(--text-opacity) );
}

.active\:text-indigo-900:active {
  --text-opacity: 1;
  color: #362f78;
  color: rgba(54, 47, 120, var(--text-opacity) );
}

.active\:text-purple-50:active {
  --text-opacity: 1;
  color: #f6f5ff;
  color: rgba(246, 245, 255, var(--text-opacity) );
}

.active\:text-purple-100:active {
  --text-opacity: 1;
  color: #edebfe;
  color: rgba(237, 235, 254, var(--text-opacity) );
}

.active\:text-purple-200:active {
  --text-opacity: 1;
  color: #dcd7fe;
  color: rgba(220, 215, 254, var(--text-opacity) );
}

.active\:text-purple-300:active {
  --text-opacity: 1;
  color: #cabffd;
  color: rgba(202, 191, 253, var(--text-opacity) );
}

.active\:text-purple-400:active {
  --text-opacity: 1;
  color: #ac94fa;
  color: rgba(172, 148, 250, var(--text-opacity) );
}

.active\:text-purple-500:active {
  --text-opacity: 1;
  color: #9061f9;
  color: rgba(144, 97, 249, var(--text-opacity) );
}

.active\:text-purple-600:active {
  --text-opacity: 1;
  color: #7e3af2;
  color: rgba(126, 58, 242, var(--text-opacity) );
}

.active\:text-purple-700:active {
  --text-opacity: 1;
  color: #6c2bd9;
  color: rgba(108, 43, 217, var(--text-opacity) );
}

.active\:text-purple-800:active {
  --text-opacity: 1;
  color: #5521b5;
  color: rgba(85, 33, 181, var(--text-opacity) );
}

.active\:text-purple-900:active {
  --text-opacity: 1;
  color: #4a1d96;
  color: rgba(74, 29, 150, var(--text-opacity) );
}

.active\:text-pink-50:active {
  --text-opacity: 1;
  color: #fdf2f8;
  color: rgba(253, 242, 248, var(--text-opacity) );
}

.active\:text-pink-100:active {
  --text-opacity: 1;
  color: #fce8f3;
  color: rgba(252, 232, 243, var(--text-opacity) );
}

.active\:text-pink-200:active {
  --text-opacity: 1;
  color: #fad1e8;
  color: rgba(250, 209, 232, var(--text-opacity) );
}

.active\:text-pink-300:active {
  --text-opacity: 1;
  color: #f8b4d9;
  color: rgba(248, 180, 217, var(--text-opacity) );
}

.active\:text-pink-400:active {
  --text-opacity: 1;
  color: #f17eb8;
  color: rgba(241, 126, 184, var(--text-opacity) );
}

.active\:text-pink-500:active {
  --text-opacity: 1;
  color: #e74694;
  color: rgba(231, 70, 148, var(--text-opacity) );
}

.active\:text-pink-600:active {
  --text-opacity: 1;
  color: #d61f69;
  color: rgba(214, 31, 105, var(--text-opacity) );
}

.active\:text-pink-700:active {
  --text-opacity: 1;
  color: #bf125d;
  color: rgba(191, 18, 93, var(--text-opacity) );
}

.active\:text-pink-800:active {
  --text-opacity: 1;
  color: #99154b;
  color: rgba(153, 21, 75, var(--text-opacity) );
}

.active\:text-pink-900:active {
  --text-opacity: 1;
  color: #751a3d;
  color: rgba(117, 26, 61, var(--text-opacity) );
}

.text-opacity-0 {
  --text-opacity: 0;
}

.text-opacity-25 {
  --text-opacity: .25;
}

.text-opacity-50 {
  --text-opacity: .5;
}

.text-opacity-75 {
  --text-opacity: .75;
}

.text-opacity-100 {
  --text-opacity: 1;
}

.hover\:text-opacity-0:hover {
  --text-opacity: 0;
}

.hover\:text-opacity-25:hover {
  --text-opacity: .25;
}

.hover\:text-opacity-50:hover {
  --text-opacity: .5;
}

.hover\:text-opacity-75:hover {
  --text-opacity: .75;
}

.hover\:text-opacity-100:hover {
  --text-opacity: 1;
}

.focus\:text-opacity-0:focus {
  --text-opacity: 0;
}

.focus\:text-opacity-25:focus {
  --text-opacity: .25;
}

.focus\:text-opacity-50:focus {
  --text-opacity: .5;
}

.focus\:text-opacity-75:focus {
  --text-opacity: .75;
}

.focus\:text-opacity-100:focus {
  --text-opacity: 1;
}

.italic {
  font-style: italic;
}

.not-italic {
  font-style: normal;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.no-underline {
  text-decoration: none;
}

.group:hover .group-hover\:underline {
  text-decoration: underline;
}

.group:hover .group-hover\:line-through {
  text-decoration: line-through;
}

.group:hover .group-hover\:no-underline {
  text-decoration: none;
}

.group:focus .group-focus\:underline {
  text-decoration: underline;
}

.group:focus .group-focus\:line-through {
  text-decoration: line-through;
}

.group:focus .group-focus\:no-underline {
  text-decoration: none;
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:line-through:hover {
  text-decoration: line-through;
}

.hover\:no-underline:hover {
  text-decoration: none;
}

.focus\:underline:focus {
  text-decoration: underline;
}

.focus\:line-through:focus {
  text-decoration: line-through;
}

.focus\:no-underline:focus {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}

.ordinal, .slashed-zero, .lining-nums, .oldstyle-nums, .proportional-nums, .tabular-nums, .diagonal-fractions, .stacked-fractions {
  --font-variant-numeric-ordinal: var(--tailwind-empty, );
  --font-variant-numeric-slashed-zero: var(--tailwind-empty, );
  --font-variant-numeric-figure: var(--tailwind-empty, );
  --font-variant-numeric-spacing: var(--tailwind-empty, );
  --font-variant-numeric-fraction: var(--tailwind-empty, );
  font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
}

.normal-nums {
  font-variant-numeric: normal;
}

.ordinal {
  --font-variant-numeric-ordinal: ordinal;
}

.slashed-zero {
  --font-variant-numeric-slashed-zero: slashed-zero;
}

.lining-nums {
  --font-variant-numeric-figure: lining-nums;
}

.oldstyle-nums {
  --font-variant-numeric-figure: oldstyle-nums;
}

.proportional-nums {
  --font-variant-numeric-spacing: proportional-nums;
}

.tabular-nums {
  --font-variant-numeric-spacing: tabular-nums;
}

.diagonal-fractions {
  --font-variant-numeric-fraction: diagonal-fractions;
}

.stacked-fractions {
  --font-variant-numeric-fraction: stacked-fractions;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-normal {
  letter-spacing: 0;
}

.tracking-wide {
  letter-spacing: .025em;
}

.tracking-wider {
  letter-spacing: .05em;
}

.tracking-widest {
  letter-spacing: .1em;
}

.select-none {
  user-select: none;
}

.select-text {
  user-select: text;
}

.select-all {
  user-select: all;
}

.select-auto {
  user-select: auto;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-no-wrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.break-normal {
  word-wrap: normal;
  overflow-wrap: normal;
  word-break: normal;
}

.break-words {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.w-0 {
  width: 0;
}

.w-1 {
  width: .25rem;
}

.w-2 {
  width: .5rem;
}

.w-3 {
  width: .75rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-13 {
  width: 3.25rem;
}

.w-14 {
  width: 3.5rem;
}

.w-15 {
  width: 3.75rem;
}

.w-16 {
  width: 4rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: .125rem;
}

.w-1\.5 {
  width: .375rem;
}

.w-2\.5 {
  width: .625rem;
}

.w-3\.5 {
  width: .875rem;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-1\/4 {
  width: 25%;
}

.w-2\/4 {
  width: 50%;
}

.w-3\/4 {
  width: 75%;
}

.w-1\/5 {
  width: 20%;
}

.w-2\/5 {
  width: 40%;
}

.w-3\/5 {
  width: 60%;
}

.w-4\/5 {
  width: 80%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-2\/6 {
  width: 33.3333%;
}

.w-3\/6 {
  width: 50%;
}

.w-4\/6 {
  width: 66.6667%;
}

.w-5\/6 {
  width: 83.3333%;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-2\/12 {
  width: 16.6667%;
}

.w-3\/12 {
  width: 25%;
}

.w-4\/12 {
  width: 33.3333%;
}

.w-5\/12 {
  width: 41.6667%;
}

.w-6\/12 {
  width: 50%;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-8\/12 {
  width: 66.6667%;
}

.w-9\/12 {
  width: 75%;
}

.w-10\/12 {
  width: 83.3333%;
}

.w-11\/12 {
  width: 91.6667%;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.w-min-content {
  width: min-content;
}

.w-max-content {
  width: max-content;
}

.z-0 {
  z-index: 0;
}

.z-10 {
  z-index: 10;
}

.z-20 {
  z-index: 20;
}

.z-30 {
  z-index: 30;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-auto {
  z-index: auto;
}

.focus-within\:z-0:focus-within {
  z-index: 0;
}

.focus-within\:z-10:focus-within {
  z-index: 10;
}

.focus-within\:z-20:focus-within {
  z-index: 20;
}

.focus-within\:z-30:focus-within {
  z-index: 30;
}

.focus-within\:z-40:focus-within {
  z-index: 40;
}

.focus-within\:z-50:focus-within {
  z-index: 50;
}

.focus-within\:z-auto:focus-within {
  z-index: auto;
}

.focus\:z-0:focus {
  z-index: 0;
}

.focus\:z-10:focus {
  z-index: 10;
}

.focus\:z-20:focus {
  z-index: 20;
}

.focus\:z-30:focus {
  z-index: 30;
}

.focus\:z-40:focus {
  z-index: 40;
}

.focus\:z-50:focus {
  z-index: 50;
}

.focus\:z-auto:focus {
  z-index: auto;
}

.gap-0 {
  grid-gap: 0;
  gap: 0;
}

.gap-1 {
  grid-gap: .25rem;
  gap: .25rem;
}

.gap-2 {
  grid-gap: .5rem;
  gap: .5rem;
}

.gap-3 {
  grid-gap: .75rem;
  gap: .75rem;
}

.gap-4 {
  grid-gap: 1rem;
  gap: 1rem;
}

.gap-5 {
  grid-gap: 1.25rem;
  gap: 1.25rem;
}

.gap-6 {
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.gap-7 {
  grid-gap: 1.75rem;
  gap: 1.75rem;
}

.gap-8 {
  grid-gap: 2rem;
  gap: 2rem;
}

.gap-9 {
  grid-gap: 2.25rem;
  gap: 2.25rem;
}

.gap-10 {
  grid-gap: 2.5rem;
  gap: 2.5rem;
}

.gap-11 {
  grid-gap: 2.75rem;
  gap: 2.75rem;
}

.gap-12 {
  grid-gap: 3rem;
  gap: 3rem;
}

.gap-13 {
  grid-gap: 3.25rem;
  gap: 3.25rem;
}

.gap-14 {
  grid-gap: 3.5rem;
  gap: 3.5rem;
}

.gap-15 {
  grid-gap: 3.75rem;
  gap: 3.75rem;
}

.gap-16 {
  grid-gap: 4rem;
  gap: 4rem;
}

.gap-20 {
  grid-gap: 5rem;
  gap: 5rem;
}

.gap-24 {
  grid-gap: 6rem;
  gap: 6rem;
}

.gap-28 {
  grid-gap: 7rem;
  gap: 7rem;
}

.gap-32 {
  grid-gap: 8rem;
  gap: 8rem;
}

.gap-36 {
  grid-gap: 9rem;
  gap: 9rem;
}

.gap-40 {
  grid-gap: 10rem;
  gap: 10rem;
}

.gap-44 {
  grid-gap: 11rem;
  gap: 11rem;
}

.gap-48 {
  grid-gap: 12rem;
  gap: 12rem;
}

.gap-52 {
  grid-gap: 13rem;
  gap: 13rem;
}

.gap-56 {
  grid-gap: 14rem;
  gap: 14rem;
}

.gap-60 {
  grid-gap: 15rem;
  gap: 15rem;
}

.gap-64 {
  grid-gap: 16rem;
  gap: 16rem;
}

.gap-72 {
  grid-gap: 18rem;
  gap: 18rem;
}

.gap-80 {
  grid-gap: 20rem;
  gap: 20rem;
}

.gap-96 {
  grid-gap: 24rem;
  gap: 24rem;
}

.gap-px {
  grid-gap: 1px;
  gap: 1px;
}

.gap-0\.5 {
  grid-gap: .125rem;
  gap: .125rem;
}

.gap-1\.5 {
  grid-gap: .375rem;
  gap: .375rem;
}

.gap-2\.5 {
  grid-gap: .625rem;
  gap: .625rem;
}

.gap-3\.5 {
  grid-gap: .875rem;
  gap: .875rem;
}

.gap-1\/2 {
  grid-gap: 50%;
  gap: 50%;
}

.gap-1\/3 {
  grid-gap: 33.3333%;
  gap: 33.3333%;
}

.gap-2\/3 {
  grid-gap: 66.6667%;
  gap: 66.6667%;
}

.gap-1\/4 {
  grid-gap: 25%;
  gap: 25%;
}

.gap-2\/4 {
  grid-gap: 50%;
  gap: 50%;
}

.gap-3\/4 {
  grid-gap: 75%;
  gap: 75%;
}

.gap-1\/5 {
  grid-gap: 20%;
  gap: 20%;
}

.gap-2\/5 {
  grid-gap: 40%;
  gap: 40%;
}

.gap-3\/5 {
  grid-gap: 60%;
  gap: 60%;
}

.gap-4\/5 {
  grid-gap: 80%;
  gap: 80%;
}

.gap-1\/6 {
  grid-gap: 16.6667%;
  gap: 16.6667%;
}

.gap-2\/6 {
  grid-gap: 33.3333%;
  gap: 33.3333%;
}

.gap-3\/6 {
  grid-gap: 50%;
  gap: 50%;
}

.gap-4\/6 {
  grid-gap: 66.6667%;
  gap: 66.6667%;
}

.gap-5\/6 {
  grid-gap: 83.3333%;
  gap: 83.3333%;
}

.gap-1\/12 {
  grid-gap: 8.33333%;
  gap: 8.33333%;
}

.gap-2\/12 {
  grid-gap: 16.6667%;
  gap: 16.6667%;
}

.gap-3\/12 {
  grid-gap: 25%;
  gap: 25%;
}

.gap-4\/12 {
  grid-gap: 33.3333%;
  gap: 33.3333%;
}

.gap-5\/12 {
  grid-gap: 41.6667%;
  gap: 41.6667%;
}

.gap-6\/12 {
  grid-gap: 50%;
  gap: 50%;
}

.gap-7\/12 {
  grid-gap: 58.3333%;
  gap: 58.3333%;
}

.gap-8\/12 {
  grid-gap: 66.6667%;
  gap: 66.6667%;
}

.gap-9\/12 {
  grid-gap: 75%;
  gap: 75%;
}

.gap-10\/12 {
  grid-gap: 83.3333%;
  gap: 83.3333%;
}

.gap-11\/12 {
  grid-gap: 91.6667%;
  gap: 91.6667%;
}

.gap-full {
  grid-gap: 100%;
  gap: 100%;
}

.col-gap-0 {
  grid-column-gap: 0;
  column-gap: 0;
}

.col-gap-1 {
  grid-column-gap: .25rem;
  column-gap: .25rem;
}

.col-gap-2 {
  grid-column-gap: .5rem;
  column-gap: .5rem;
}

.col-gap-3 {
  grid-column-gap: .75rem;
  column-gap: .75rem;
}

.col-gap-4 {
  grid-column-gap: 1rem;
  column-gap: 1rem;
}

.col-gap-5 {
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.col-gap-6 {
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.col-gap-7 {
  grid-column-gap: 1.75rem;
  column-gap: 1.75rem;
}

.col-gap-8 {
  grid-column-gap: 2rem;
  column-gap: 2rem;
}

.col-gap-9 {
  grid-column-gap: 2.25rem;
  column-gap: 2.25rem;
}

.col-gap-10 {
  grid-column-gap: 2.5rem;
  column-gap: 2.5rem;
}

.col-gap-11 {
  grid-column-gap: 2.75rem;
  column-gap: 2.75rem;
}

.col-gap-12 {
  grid-column-gap: 3rem;
  column-gap: 3rem;
}

.col-gap-13 {
  grid-column-gap: 3.25rem;
  column-gap: 3.25rem;
}

.col-gap-14 {
  grid-column-gap: 3.5rem;
  column-gap: 3.5rem;
}

.col-gap-15 {
  grid-column-gap: 3.75rem;
  column-gap: 3.75rem;
}

.col-gap-16 {
  grid-column-gap: 4rem;
  column-gap: 4rem;
}

.col-gap-20 {
  grid-column-gap: 5rem;
  column-gap: 5rem;
}

.col-gap-24 {
  grid-column-gap: 6rem;
  column-gap: 6rem;
}

.col-gap-28 {
  grid-column-gap: 7rem;
  column-gap: 7rem;
}

.col-gap-32 {
  grid-column-gap: 8rem;
  column-gap: 8rem;
}

.col-gap-36 {
  grid-column-gap: 9rem;
  column-gap: 9rem;
}

.col-gap-40 {
  grid-column-gap: 10rem;
  column-gap: 10rem;
}

.col-gap-44 {
  grid-column-gap: 11rem;
  column-gap: 11rem;
}

.col-gap-48 {
  grid-column-gap: 12rem;
  column-gap: 12rem;
}

.col-gap-52 {
  grid-column-gap: 13rem;
  column-gap: 13rem;
}

.col-gap-56 {
  grid-column-gap: 14rem;
  column-gap: 14rem;
}

.col-gap-60 {
  grid-column-gap: 15rem;
  column-gap: 15rem;
}

.col-gap-64 {
  grid-column-gap: 16rem;
  column-gap: 16rem;
}

.col-gap-72 {
  grid-column-gap: 18rem;
  column-gap: 18rem;
}

.col-gap-80 {
  grid-column-gap: 20rem;
  column-gap: 20rem;
}

.col-gap-96 {
  grid-column-gap: 24rem;
  column-gap: 24rem;
}

.col-gap-px {
  grid-column-gap: 1px;
  column-gap: 1px;
}

.col-gap-0\.5 {
  grid-column-gap: .125rem;
  column-gap: .125rem;
}

.col-gap-1\.5 {
  grid-column-gap: .375rem;
  column-gap: .375rem;
}

.col-gap-2\.5 {
  grid-column-gap: .625rem;
  column-gap: .625rem;
}

.col-gap-3\.5 {
  grid-column-gap: .875rem;
  column-gap: .875rem;
}

.col-gap-1\/2 {
  grid-column-gap: 50%;
  column-gap: 50%;
}

.col-gap-1\/3 {
  grid-column-gap: 33.3333%;
  column-gap: 33.3333%;
}

.col-gap-2\/3 {
  grid-column-gap: 66.6667%;
  column-gap: 66.6667%;
}

.col-gap-1\/4 {
  grid-column-gap: 25%;
  column-gap: 25%;
}

.col-gap-2\/4 {
  grid-column-gap: 50%;
  column-gap: 50%;
}

.col-gap-3\/4 {
  grid-column-gap: 75%;
  column-gap: 75%;
}

.col-gap-1\/5 {
  grid-column-gap: 20%;
  column-gap: 20%;
}

.col-gap-2\/5 {
  grid-column-gap: 40%;
  column-gap: 40%;
}

.col-gap-3\/5 {
  grid-column-gap: 60%;
  column-gap: 60%;
}

.col-gap-4\/5 {
  grid-column-gap: 80%;
  column-gap: 80%;
}

.col-gap-1\/6 {
  grid-column-gap: 16.6667%;
  column-gap: 16.6667%;
}

.col-gap-2\/6 {
  grid-column-gap: 33.3333%;
  column-gap: 33.3333%;
}

.col-gap-3\/6 {
  grid-column-gap: 50%;
  column-gap: 50%;
}

.col-gap-4\/6 {
  grid-column-gap: 66.6667%;
  column-gap: 66.6667%;
}

.col-gap-5\/6 {
  grid-column-gap: 83.3333%;
  column-gap: 83.3333%;
}

.col-gap-1\/12 {
  grid-column-gap: 8.33333%;
  column-gap: 8.33333%;
}

.col-gap-2\/12 {
  grid-column-gap: 16.6667%;
  column-gap: 16.6667%;
}

.col-gap-3\/12 {
  grid-column-gap: 25%;
  column-gap: 25%;
}

.col-gap-4\/12 {
  grid-column-gap: 33.3333%;
  column-gap: 33.3333%;
}

.col-gap-5\/12 {
  grid-column-gap: 41.6667%;
  column-gap: 41.6667%;
}

.col-gap-6\/12 {
  grid-column-gap: 50%;
  column-gap: 50%;
}

.col-gap-7\/12 {
  grid-column-gap: 58.3333%;
  column-gap: 58.3333%;
}

.col-gap-8\/12 {
  grid-column-gap: 66.6667%;
  column-gap: 66.6667%;
}

.col-gap-9\/12 {
  grid-column-gap: 75%;
  column-gap: 75%;
}

.col-gap-10\/12 {
  grid-column-gap: 83.3333%;
  column-gap: 83.3333%;
}

.col-gap-11\/12 {
  grid-column-gap: 91.6667%;
  column-gap: 91.6667%;
}

.col-gap-full {
  grid-column-gap: 100%;
  column-gap: 100%;
}

.gap-x-0 {
  grid-column-gap: 0;
  column-gap: 0;
}

.gap-x-1 {
  grid-column-gap: .25rem;
  column-gap: .25rem;
}

.gap-x-2 {
  grid-column-gap: .5rem;
  column-gap: .5rem;
}

.gap-x-3 {
  grid-column-gap: .75rem;
  column-gap: .75rem;
}

.gap-x-4 {
  grid-column-gap: 1rem;
  column-gap: 1rem;
}

.gap-x-5 {
  grid-column-gap: 1.25rem;
  column-gap: 1.25rem;
}

.gap-x-6 {
  grid-column-gap: 1.5rem;
  column-gap: 1.5rem;
}

.gap-x-7 {
  grid-column-gap: 1.75rem;
  column-gap: 1.75rem;
}

.gap-x-8 {
  grid-column-gap: 2rem;
  column-gap: 2rem;
}

.gap-x-9 {
  grid-column-gap: 2.25rem;
  column-gap: 2.25rem;
}

.gap-x-10 {
  grid-column-gap: 2.5rem;
  column-gap: 2.5rem;
}

.gap-x-11 {
  grid-column-gap: 2.75rem;
  column-gap: 2.75rem;
}

.gap-x-12 {
  grid-column-gap: 3rem;
  column-gap: 3rem;
}

.gap-x-13 {
  grid-column-gap: 3.25rem;
  column-gap: 3.25rem;
}

.gap-x-14 {
  grid-column-gap: 3.5rem;
  column-gap: 3.5rem;
}

.gap-x-15 {
  grid-column-gap: 3.75rem;
  column-gap: 3.75rem;
}

.gap-x-16 {
  grid-column-gap: 4rem;
  column-gap: 4rem;
}

.gap-x-20 {
  grid-column-gap: 5rem;
  column-gap: 5rem;
}

.gap-x-24 {
  grid-column-gap: 6rem;
  column-gap: 6rem;
}

.gap-x-28 {
  grid-column-gap: 7rem;
  column-gap: 7rem;
}

.gap-x-32 {
  grid-column-gap: 8rem;
  column-gap: 8rem;
}

.gap-x-36 {
  grid-column-gap: 9rem;
  column-gap: 9rem;
}

.gap-x-40 {
  grid-column-gap: 10rem;
  column-gap: 10rem;
}

.gap-x-44 {
  grid-column-gap: 11rem;
  column-gap: 11rem;
}

.gap-x-48 {
  grid-column-gap: 12rem;
  column-gap: 12rem;
}

.gap-x-52 {
  grid-column-gap: 13rem;
  column-gap: 13rem;
}

.gap-x-56 {
  grid-column-gap: 14rem;
  column-gap: 14rem;
}

.gap-x-60 {
  grid-column-gap: 15rem;
  column-gap: 15rem;
}

.gap-x-64 {
  grid-column-gap: 16rem;
  column-gap: 16rem;
}

.gap-x-72 {
  grid-column-gap: 18rem;
  column-gap: 18rem;
}

.gap-x-80 {
  grid-column-gap: 20rem;
  column-gap: 20rem;
}

.gap-x-96 {
  grid-column-gap: 24rem;
  column-gap: 24rem;
}

.gap-x-px {
  grid-column-gap: 1px;
  column-gap: 1px;
}

.gap-x-0\.5 {
  grid-column-gap: .125rem;
  column-gap: .125rem;
}

.gap-x-1\.5 {
  grid-column-gap: .375rem;
  column-gap: .375rem;
}

.gap-x-2\.5 {
  grid-column-gap: .625rem;
  column-gap: .625rem;
}

.gap-x-3\.5 {
  grid-column-gap: .875rem;
  column-gap: .875rem;
}

.gap-x-1\/2 {
  grid-column-gap: 50%;
  column-gap: 50%;
}

.gap-x-1\/3 {
  grid-column-gap: 33.3333%;
  column-gap: 33.3333%;
}

.gap-x-2\/3 {
  grid-column-gap: 66.6667%;
  column-gap: 66.6667%;
}

.gap-x-1\/4 {
  grid-column-gap: 25%;
  column-gap: 25%;
}

.gap-x-2\/4 {
  grid-column-gap: 50%;
  column-gap: 50%;
}

.gap-x-3\/4 {
  grid-column-gap: 75%;
  column-gap: 75%;
}

.gap-x-1\/5 {
  grid-column-gap: 20%;
  column-gap: 20%;
}

.gap-x-2\/5 {
  grid-column-gap: 40%;
  column-gap: 40%;
}

.gap-x-3\/5 {
  grid-column-gap: 60%;
  column-gap: 60%;
}

.gap-x-4\/5 {
  grid-column-gap: 80%;
  column-gap: 80%;
}

.gap-x-1\/6 {
  grid-column-gap: 16.6667%;
  column-gap: 16.6667%;
}

.gap-x-2\/6 {
  grid-column-gap: 33.3333%;
  column-gap: 33.3333%;
}

.gap-x-3\/6 {
  grid-column-gap: 50%;
  column-gap: 50%;
}

.gap-x-4\/6 {
  grid-column-gap: 66.6667%;
  column-gap: 66.6667%;
}

.gap-x-5\/6 {
  grid-column-gap: 83.3333%;
  column-gap: 83.3333%;
}

.gap-x-1\/12 {
  grid-column-gap: 8.33333%;
  column-gap: 8.33333%;
}

.gap-x-2\/12 {
  grid-column-gap: 16.6667%;
  column-gap: 16.6667%;
}

.gap-x-3\/12 {
  grid-column-gap: 25%;
  column-gap: 25%;
}

.gap-x-4\/12 {
  grid-column-gap: 33.3333%;
  column-gap: 33.3333%;
}

.gap-x-5\/12 {
  grid-column-gap: 41.6667%;
  column-gap: 41.6667%;
}

.gap-x-6\/12 {
  grid-column-gap: 50%;
  column-gap: 50%;
}

.gap-x-7\/12 {
  grid-column-gap: 58.3333%;
  column-gap: 58.3333%;
}

.gap-x-8\/12 {
  grid-column-gap: 66.6667%;
  column-gap: 66.6667%;
}

.gap-x-9\/12 {
  grid-column-gap: 75%;
  column-gap: 75%;
}

.gap-x-10\/12 {
  grid-column-gap: 83.3333%;
  column-gap: 83.3333%;
}

.gap-x-11\/12 {
  grid-column-gap: 91.6667%;
  column-gap: 91.6667%;
}

.gap-x-full {
  grid-column-gap: 100%;
  column-gap: 100%;
}

.row-gap-0 {
  grid-row-gap: 0;
  row-gap: 0;
}

.row-gap-1 {
  grid-row-gap: .25rem;
  row-gap: .25rem;
}

.row-gap-2 {
  grid-row-gap: .5rem;
  row-gap: .5rem;
}

.row-gap-3 {
  grid-row-gap: .75rem;
  row-gap: .75rem;
}

.row-gap-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.row-gap-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.row-gap-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.row-gap-7 {
  grid-row-gap: 1.75rem;
  row-gap: 1.75rem;
}

.row-gap-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.row-gap-9 {
  grid-row-gap: 2.25rem;
  row-gap: 2.25rem;
}

.row-gap-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}

.row-gap-11 {
  grid-row-gap: 2.75rem;
  row-gap: 2.75rem;
}

.row-gap-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.row-gap-13 {
  grid-row-gap: 3.25rem;
  row-gap: 3.25rem;
}

.row-gap-14 {
  grid-row-gap: 3.5rem;
  row-gap: 3.5rem;
}

.row-gap-15 {
  grid-row-gap: 3.75rem;
  row-gap: 3.75rem;
}

.row-gap-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.row-gap-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem;
}

.row-gap-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem;
}

.row-gap-28 {
  grid-row-gap: 7rem;
  row-gap: 7rem;
}

.row-gap-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem;
}

.row-gap-36 {
  grid-row-gap: 9rem;
  row-gap: 9rem;
}

.row-gap-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem;
}

.row-gap-44 {
  grid-row-gap: 11rem;
  row-gap: 11rem;
}

.row-gap-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem;
}

.row-gap-52 {
  grid-row-gap: 13rem;
  row-gap: 13rem;
}

.row-gap-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem;
}

.row-gap-60 {
  grid-row-gap: 15rem;
  row-gap: 15rem;
}

.row-gap-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem;
}

.row-gap-72 {
  grid-row-gap: 18rem;
  row-gap: 18rem;
}

.row-gap-80 {
  grid-row-gap: 20rem;
  row-gap: 20rem;
}

.row-gap-96 {
  grid-row-gap: 24rem;
  row-gap: 24rem;
}

.row-gap-px {
  grid-row-gap: 1px;
  row-gap: 1px;
}

.row-gap-0\.5 {
  grid-row-gap: .125rem;
  row-gap: .125rem;
}

.row-gap-1\.5 {
  grid-row-gap: .375rem;
  row-gap: .375rem;
}

.row-gap-2\.5 {
  grid-row-gap: .625rem;
  row-gap: .625rem;
}

.row-gap-3\.5 {
  grid-row-gap: .875rem;
  row-gap: .875rem;
}

.row-gap-1\/2 {
  grid-row-gap: 50%;
  row-gap: 50%;
}

.row-gap-1\/3 {
  grid-row-gap: 33.3333%;
  row-gap: 33.3333%;
}

.row-gap-2\/3 {
  grid-row-gap: 66.6667%;
  row-gap: 66.6667%;
}

.row-gap-1\/4 {
  grid-row-gap: 25%;
  row-gap: 25%;
}

.row-gap-2\/4 {
  grid-row-gap: 50%;
  row-gap: 50%;
}

.row-gap-3\/4 {
  grid-row-gap: 75%;
  row-gap: 75%;
}

.row-gap-1\/5 {
  grid-row-gap: 20%;
  row-gap: 20%;
}

.row-gap-2\/5 {
  grid-row-gap: 40%;
  row-gap: 40%;
}

.row-gap-3\/5 {
  grid-row-gap: 60%;
  row-gap: 60%;
}

.row-gap-4\/5 {
  grid-row-gap: 80%;
  row-gap: 80%;
}

.row-gap-1\/6 {
  grid-row-gap: 16.6667%;
  row-gap: 16.6667%;
}

.row-gap-2\/6 {
  grid-row-gap: 33.3333%;
  row-gap: 33.3333%;
}

.row-gap-3\/6 {
  grid-row-gap: 50%;
  row-gap: 50%;
}

.row-gap-4\/6 {
  grid-row-gap: 66.6667%;
  row-gap: 66.6667%;
}

.row-gap-5\/6 {
  grid-row-gap: 83.3333%;
  row-gap: 83.3333%;
}

.row-gap-1\/12 {
  grid-row-gap: 8.33333%;
  row-gap: 8.33333%;
}

.row-gap-2\/12 {
  grid-row-gap: 16.6667%;
  row-gap: 16.6667%;
}

.row-gap-3\/12 {
  grid-row-gap: 25%;
  row-gap: 25%;
}

.row-gap-4\/12 {
  grid-row-gap: 33.3333%;
  row-gap: 33.3333%;
}

.row-gap-5\/12 {
  grid-row-gap: 41.6667%;
  row-gap: 41.6667%;
}

.row-gap-6\/12 {
  grid-row-gap: 50%;
  row-gap: 50%;
}

.row-gap-7\/12 {
  grid-row-gap: 58.3333%;
  row-gap: 58.3333%;
}

.row-gap-8\/12 {
  grid-row-gap: 66.6667%;
  row-gap: 66.6667%;
}

.row-gap-9\/12 {
  grid-row-gap: 75%;
  row-gap: 75%;
}

.row-gap-10\/12 {
  grid-row-gap: 83.3333%;
  row-gap: 83.3333%;
}

.row-gap-11\/12 {
  grid-row-gap: 91.6667%;
  row-gap: 91.6667%;
}

.row-gap-full {
  grid-row-gap: 100%;
  row-gap: 100%;
}

.gap-y-0 {
  grid-row-gap: 0;
  row-gap: 0;
}

.gap-y-1 {
  grid-row-gap: .25rem;
  row-gap: .25rem;
}

.gap-y-2 {
  grid-row-gap: .5rem;
  row-gap: .5rem;
}

.gap-y-3 {
  grid-row-gap: .75rem;
  row-gap: .75rem;
}

.gap-y-4 {
  grid-row-gap: 1rem;
  row-gap: 1rem;
}

.gap-y-5 {
  grid-row-gap: 1.25rem;
  row-gap: 1.25rem;
}

.gap-y-6 {
  grid-row-gap: 1.5rem;
  row-gap: 1.5rem;
}

.gap-y-7 {
  grid-row-gap: 1.75rem;
  row-gap: 1.75rem;
}

.gap-y-8 {
  grid-row-gap: 2rem;
  row-gap: 2rem;
}

.gap-y-9 {
  grid-row-gap: 2.25rem;
  row-gap: 2.25rem;
}

.gap-y-10 {
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}

.gap-y-11 {
  grid-row-gap: 2.75rem;
  row-gap: 2.75rem;
}

.gap-y-12 {
  grid-row-gap: 3rem;
  row-gap: 3rem;
}

.gap-y-13 {
  grid-row-gap: 3.25rem;
  row-gap: 3.25rem;
}

.gap-y-14 {
  grid-row-gap: 3.5rem;
  row-gap: 3.5rem;
}

.gap-y-15 {
  grid-row-gap: 3.75rem;
  row-gap: 3.75rem;
}

.gap-y-16 {
  grid-row-gap: 4rem;
  row-gap: 4rem;
}

.gap-y-20 {
  grid-row-gap: 5rem;
  row-gap: 5rem;
}

.gap-y-24 {
  grid-row-gap: 6rem;
  row-gap: 6rem;
}

.gap-y-28 {
  grid-row-gap: 7rem;
  row-gap: 7rem;
}

.gap-y-32 {
  grid-row-gap: 8rem;
  row-gap: 8rem;
}

.gap-y-36 {
  grid-row-gap: 9rem;
  row-gap: 9rem;
}

.gap-y-40 {
  grid-row-gap: 10rem;
  row-gap: 10rem;
}

.gap-y-44 {
  grid-row-gap: 11rem;
  row-gap: 11rem;
}

.gap-y-48 {
  grid-row-gap: 12rem;
  row-gap: 12rem;
}

.gap-y-52 {
  grid-row-gap: 13rem;
  row-gap: 13rem;
}

.gap-y-56 {
  grid-row-gap: 14rem;
  row-gap: 14rem;
}

.gap-y-60 {
  grid-row-gap: 15rem;
  row-gap: 15rem;
}

.gap-y-64 {
  grid-row-gap: 16rem;
  row-gap: 16rem;
}

.gap-y-72 {
  grid-row-gap: 18rem;
  row-gap: 18rem;
}

.gap-y-80 {
  grid-row-gap: 20rem;
  row-gap: 20rem;
}

.gap-y-96 {
  grid-row-gap: 24rem;
  row-gap: 24rem;
}

.gap-y-px {
  grid-row-gap: 1px;
  row-gap: 1px;
}

.gap-y-0\.5 {
  grid-row-gap: .125rem;
  row-gap: .125rem;
}

.gap-y-1\.5 {
  grid-row-gap: .375rem;
  row-gap: .375rem;
}

.gap-y-2\.5 {
  grid-row-gap: .625rem;
  row-gap: .625rem;
}

.gap-y-3\.5 {
  grid-row-gap: .875rem;
  row-gap: .875rem;
}

.gap-y-1\/2 {
  grid-row-gap: 50%;
  row-gap: 50%;
}

.gap-y-1\/3 {
  grid-row-gap: 33.3333%;
  row-gap: 33.3333%;
}

.gap-y-2\/3 {
  grid-row-gap: 66.6667%;
  row-gap: 66.6667%;
}

.gap-y-1\/4 {
  grid-row-gap: 25%;
  row-gap: 25%;
}

.gap-y-2\/4 {
  grid-row-gap: 50%;
  row-gap: 50%;
}

.gap-y-3\/4 {
  grid-row-gap: 75%;
  row-gap: 75%;
}

.gap-y-1\/5 {
  grid-row-gap: 20%;
  row-gap: 20%;
}

.gap-y-2\/5 {
  grid-row-gap: 40%;
  row-gap: 40%;
}

.gap-y-3\/5 {
  grid-row-gap: 60%;
  row-gap: 60%;
}

.gap-y-4\/5 {
  grid-row-gap: 80%;
  row-gap: 80%;
}

.gap-y-1\/6 {
  grid-row-gap: 16.6667%;
  row-gap: 16.6667%;
}

.gap-y-2\/6 {
  grid-row-gap: 33.3333%;
  row-gap: 33.3333%;
}

.gap-y-3\/6 {
  grid-row-gap: 50%;
  row-gap: 50%;
}

.gap-y-4\/6 {
  grid-row-gap: 66.6667%;
  row-gap: 66.6667%;
}

.gap-y-5\/6 {
  grid-row-gap: 83.3333%;
  row-gap: 83.3333%;
}

.gap-y-1\/12 {
  grid-row-gap: 8.33333%;
  row-gap: 8.33333%;
}

.gap-y-2\/12 {
  grid-row-gap: 16.6667%;
  row-gap: 16.6667%;
}

.gap-y-3\/12 {
  grid-row-gap: 25%;
  row-gap: 25%;
}

.gap-y-4\/12 {
  grid-row-gap: 33.3333%;
  row-gap: 33.3333%;
}

.gap-y-5\/12 {
  grid-row-gap: 41.6667%;
  row-gap: 41.6667%;
}

.gap-y-6\/12 {
  grid-row-gap: 50%;
  row-gap: 50%;
}

.gap-y-7\/12 {
  grid-row-gap: 58.3333%;
  row-gap: 58.3333%;
}

.gap-y-8\/12 {
  grid-row-gap: 66.6667%;
  row-gap: 66.6667%;
}

.gap-y-9\/12 {
  grid-row-gap: 75%;
  row-gap: 75%;
}

.gap-y-10\/12 {
  grid-row-gap: 83.3333%;
  row-gap: 83.3333%;
}

.gap-y-11\/12 {
  grid-row-gap: 91.6667%;
  row-gap: 91.6667%;
}

.gap-y-full {
  grid-row-gap: 100%;
  row-gap: 100%;
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.auto-cols-auto {
  grid-auto-columns: auto;
}

.auto-cols-min {
  grid-auto-columns: min-content;
}

.auto-cols-max {
  grid-auto-columns: max-content;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-full {
  grid-column: 1 / -1;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-auto {
  grid-column-start: auto;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-auto {
  grid-column-end: auto;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-full {
  grid-row: 1 / -1;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-auto {
  grid-row-start: auto;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-auto {
  grid-row-end: auto;
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x) ) translateY(var(--transform-translate-y) ) rotate(var(--transform-rotate) ) skewX(var(--transform-skew-x) ) skewY(var(--transform-skew-y) ) scaleX(var(--transform-scale-x) ) scaleY(var(--transform-scale-y) );
}

.transform-none {
  transform: none;
}

.origin-center {
  transform-origin: center;
}

.origin-top {
  transform-origin: top;
}

.origin-top-right {
  transform-origin: 100% 0;
}

.origin-right {
  transform-origin: 100%;
}

.origin-bottom-right {
  transform-origin: 100% 100%;
}

.origin-bottom {
  transform-origin: bottom;
}

.origin-bottom-left {
  transform-origin: 0 100%;
}

.origin-left {
  transform-origin: 0;
}

.origin-top-left {
  transform-origin: 0 0;
}

.scale-0 {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.scale-50 {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.scale-75 {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.scale-90 {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.scale-95 {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.scale-100 {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.scale-105 {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.scale-110 {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.scale-125 {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.scale-150 {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.scale-x-0 {
  --transform-scale-x: 0;
}

.scale-x-50 {
  --transform-scale-x: .5;
}

.scale-x-75 {
  --transform-scale-x: .75;
}

.scale-x-90 {
  --transform-scale-x: .9;
}

.scale-x-95 {
  --transform-scale-x: .95;
}

.scale-x-100 {
  --transform-scale-x: 1;
}

.scale-x-105 {
  --transform-scale-x: 1.05;
}

.scale-x-110 {
  --transform-scale-x: 1.1;
}

.scale-x-125 {
  --transform-scale-x: 1.25;
}

.scale-x-150 {
  --transform-scale-x: 1.5;
}

.scale-y-0 {
  --transform-scale-y: 0;
}

.scale-y-50 {
  --transform-scale-y: .5;
}

.scale-y-75 {
  --transform-scale-y: .75;
}

.scale-y-90 {
  --transform-scale-y: .9;
}

.scale-y-95 {
  --transform-scale-y: .95;
}

.scale-y-100 {
  --transform-scale-y: 1;
}

.scale-y-105 {
  --transform-scale-y: 1.05;
}

.scale-y-110 {
  --transform-scale-y: 1.1;
}

.scale-y-125 {
  --transform-scale-y: 1.25;
}

.scale-y-150 {
  --transform-scale-y: 1.5;
}

.hover\:scale-0:hover {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.hover\:scale-50:hover {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.hover\:scale-75:hover {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.hover\:scale-90:hover {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.hover\:scale-95:hover {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.hover\:scale-100:hover {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.hover\:scale-105:hover {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.hover\:scale-110:hover {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.hover\:scale-125:hover {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.hover\:scale-150:hover {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.hover\:scale-x-0:hover {
  --transform-scale-x: 0;
}

.hover\:scale-x-50:hover {
  --transform-scale-x: .5;
}

.hover\:scale-x-75:hover {
  --transform-scale-x: .75;
}

.hover\:scale-x-90:hover {
  --transform-scale-x: .9;
}

.hover\:scale-x-95:hover {
  --transform-scale-x: .95;
}

.hover\:scale-x-100:hover {
  --transform-scale-x: 1;
}

.hover\:scale-x-105:hover {
  --transform-scale-x: 1.05;
}

.hover\:scale-x-110:hover {
  --transform-scale-x: 1.1;
}

.hover\:scale-x-125:hover {
  --transform-scale-x: 1.25;
}

.hover\:scale-x-150:hover {
  --transform-scale-x: 1.5;
}

.hover\:scale-y-0:hover {
  --transform-scale-y: 0;
}

.hover\:scale-y-50:hover {
  --transform-scale-y: .5;
}

.hover\:scale-y-75:hover {
  --transform-scale-y: .75;
}

.hover\:scale-y-90:hover {
  --transform-scale-y: .9;
}

.hover\:scale-y-95:hover {
  --transform-scale-y: .95;
}

.hover\:scale-y-100:hover {
  --transform-scale-y: 1;
}

.hover\:scale-y-105:hover {
  --transform-scale-y: 1.05;
}

.hover\:scale-y-110:hover {
  --transform-scale-y: 1.1;
}

.hover\:scale-y-125:hover {
  --transform-scale-y: 1.25;
}

.hover\:scale-y-150:hover {
  --transform-scale-y: 1.5;
}

.focus\:scale-0:focus {
  --transform-scale-x: 0;
  --transform-scale-y: 0;
}

.focus\:scale-50:focus {
  --transform-scale-x: .5;
  --transform-scale-y: .5;
}

.focus\:scale-75:focus {
  --transform-scale-x: .75;
  --transform-scale-y: .75;
}

.focus\:scale-90:focus {
  --transform-scale-x: .9;
  --transform-scale-y: .9;
}

.focus\:scale-95:focus {
  --transform-scale-x: .95;
  --transform-scale-y: .95;
}

.focus\:scale-100:focus {
  --transform-scale-x: 1;
  --transform-scale-y: 1;
}

.focus\:scale-105:focus {
  --transform-scale-x: 1.05;
  --transform-scale-y: 1.05;
}

.focus\:scale-110:focus {
  --transform-scale-x: 1.1;
  --transform-scale-y: 1.1;
}

.focus\:scale-125:focus {
  --transform-scale-x: 1.25;
  --transform-scale-y: 1.25;
}

.focus\:scale-150:focus {
  --transform-scale-x: 1.5;
  --transform-scale-y: 1.5;
}

.focus\:scale-x-0:focus {
  --transform-scale-x: 0;
}

.focus\:scale-x-50:focus {
  --transform-scale-x: .5;
}

.focus\:scale-x-75:focus {
  --transform-scale-x: .75;
}

.focus\:scale-x-90:focus {
  --transform-scale-x: .9;
}

.focus\:scale-x-95:focus {
  --transform-scale-x: .95;
}

.focus\:scale-x-100:focus {
  --transform-scale-x: 1;
}

.focus\:scale-x-105:focus {
  --transform-scale-x: 1.05;
}

.focus\:scale-x-110:focus {
  --transform-scale-x: 1.1;
}

.focus\:scale-x-125:focus {
  --transform-scale-x: 1.25;
}

.focus\:scale-x-150:focus {
  --transform-scale-x: 1.5;
}

.focus\:scale-y-0:focus {
  --transform-scale-y: 0;
}

.focus\:scale-y-50:focus {
  --transform-scale-y: .5;
}

.focus\:scale-y-75:focus {
  --transform-scale-y: .75;
}

.focus\:scale-y-90:focus {
  --transform-scale-y: .9;
}

.focus\:scale-y-95:focus {
  --transform-scale-y: .95;
}

.focus\:scale-y-100:focus {
  --transform-scale-y: 1;
}

.focus\:scale-y-105:focus {
  --transform-scale-y: 1.05;
}

.focus\:scale-y-110:focus {
  --transform-scale-y: 1.1;
}

.focus\:scale-y-125:focus {
  --transform-scale-y: 1.25;
}

.focus\:scale-y-150:focus {
  --transform-scale-y: 1.5;
}

.rotate-0 {
  --transform-rotate: 0;
}

.rotate-1 {
  --transform-rotate: 1deg;
}

.rotate-2 {
  --transform-rotate: 2deg;
}

.rotate-3 {
  --transform-rotate: 3deg;
}

.rotate-6 {
  --transform-rotate: 6deg;
}

.rotate-12 {
  --transform-rotate: 12deg;
}

.rotate-45 {
  --transform-rotate: 45deg;
}

.rotate-90 {
  --transform-rotate: 90deg;
}

.rotate-180 {
  --transform-rotate: 180deg;
}

.-rotate-180 {
  --transform-rotate: -180deg;
}

.-rotate-90 {
  --transform-rotate: -90deg;
}

.-rotate-45 {
  --transform-rotate: -45deg;
}

.-rotate-12 {
  --transform-rotate: -12deg;
}

.-rotate-6 {
  --transform-rotate: -6deg;
}

.-rotate-3 {
  --transform-rotate: -3deg;
}

.-rotate-2 {
  --transform-rotate: -2deg;
}

.-rotate-1 {
  --transform-rotate: -1deg;
}

.hover\:rotate-0:hover {
  --transform-rotate: 0;
}

.hover\:rotate-1:hover {
  --transform-rotate: 1deg;
}

.hover\:rotate-2:hover {
  --transform-rotate: 2deg;
}

.hover\:rotate-3:hover {
  --transform-rotate: 3deg;
}

.hover\:rotate-6:hover {
  --transform-rotate: 6deg;
}

.hover\:rotate-12:hover {
  --transform-rotate: 12deg;
}

.hover\:rotate-45:hover {
  --transform-rotate: 45deg;
}

.hover\:rotate-90:hover {
  --transform-rotate: 90deg;
}

.hover\:rotate-180:hover {
  --transform-rotate: 180deg;
}

.hover\:-rotate-180:hover {
  --transform-rotate: -180deg;
}

.hover\:-rotate-90:hover {
  --transform-rotate: -90deg;
}

.hover\:-rotate-45:hover {
  --transform-rotate: -45deg;
}

.hover\:-rotate-12:hover {
  --transform-rotate: -12deg;
}

.hover\:-rotate-6:hover {
  --transform-rotate: -6deg;
}

.hover\:-rotate-3:hover {
  --transform-rotate: -3deg;
}

.hover\:-rotate-2:hover {
  --transform-rotate: -2deg;
}

.hover\:-rotate-1:hover {
  --transform-rotate: -1deg;
}

.focus\:rotate-0:focus {
  --transform-rotate: 0;
}

.focus\:rotate-1:focus {
  --transform-rotate: 1deg;
}

.focus\:rotate-2:focus {
  --transform-rotate: 2deg;
}

.focus\:rotate-3:focus {
  --transform-rotate: 3deg;
}

.focus\:rotate-6:focus {
  --transform-rotate: 6deg;
}

.focus\:rotate-12:focus {
  --transform-rotate: 12deg;
}

.focus\:rotate-45:focus {
  --transform-rotate: 45deg;
}

.focus\:rotate-90:focus {
  --transform-rotate: 90deg;
}

.focus\:rotate-180:focus {
  --transform-rotate: 180deg;
}

.focus\:-rotate-180:focus {
  --transform-rotate: -180deg;
}

.focus\:-rotate-90:focus {
  --transform-rotate: -90deg;
}

.focus\:-rotate-45:focus {
  --transform-rotate: -45deg;
}

.focus\:-rotate-12:focus {
  --transform-rotate: -12deg;
}

.focus\:-rotate-6:focus {
  --transform-rotate: -6deg;
}

.focus\:-rotate-3:focus {
  --transform-rotate: -3deg;
}

.focus\:-rotate-2:focus {
  --transform-rotate: -2deg;
}

.focus\:-rotate-1:focus {
  --transform-rotate: -1deg;
}

.translate-x-0 {
  --transform-translate-x: 0;
}

.translate-x-1 {
  --transform-translate-x: .25rem;
}

.translate-x-2 {
  --transform-translate-x: .5rem;
}

.translate-x-3 {
  --transform-translate-x: .75rem;
}

.translate-x-4 {
  --transform-translate-x: 1rem;
}

.translate-x-5 {
  --transform-translate-x: 1.25rem;
}

.translate-x-6 {
  --transform-translate-x: 1.5rem;
}

.translate-x-7 {
  --transform-translate-x: 1.75rem;
}

.translate-x-8 {
  --transform-translate-x: 2rem;
}

.translate-x-9 {
  --transform-translate-x: 2.25rem;
}

.translate-x-10 {
  --transform-translate-x: 2.5rem;
}

.translate-x-11 {
  --transform-translate-x: 2.75rem;
}

.translate-x-12 {
  --transform-translate-x: 3rem;
}

.translate-x-13 {
  --transform-translate-x: 3.25rem;
}

.translate-x-14 {
  --transform-translate-x: 3.5rem;
}

.translate-x-15 {
  --transform-translate-x: 3.75rem;
}

.translate-x-16 {
  --transform-translate-x: 4rem;
}

.translate-x-20 {
  --transform-translate-x: 5rem;
}

.translate-x-24 {
  --transform-translate-x: 6rem;
}

.translate-x-28 {
  --transform-translate-x: 7rem;
}

.translate-x-32 {
  --transform-translate-x: 8rem;
}

.translate-x-36 {
  --transform-translate-x: 9rem;
}

.translate-x-40 {
  --transform-translate-x: 10rem;
}

.translate-x-44 {
  --transform-translate-x: 11rem;
}

.translate-x-48 {
  --transform-translate-x: 12rem;
}

.translate-x-52 {
  --transform-translate-x: 13rem;
}

.translate-x-56 {
  --transform-translate-x: 14rem;
}

.translate-x-60 {
  --transform-translate-x: 15rem;
}

.translate-x-64 {
  --transform-translate-x: 16rem;
}

.translate-x-72 {
  --transform-translate-x: 18rem;
}

.translate-x-80 {
  --transform-translate-x: 20rem;
}

.translate-x-96 {
  --transform-translate-x: 24rem;
}

.translate-x-px {
  --transform-translate-x: 1px;
}

.translate-x-0\.5 {
  --transform-translate-x: .125rem;
}

.translate-x-1\.5 {
  --transform-translate-x: .375rem;
}

.translate-x-2\.5 {
  --transform-translate-x: .625rem;
}

.translate-x-3\.5 {
  --transform-translate-x: .875rem;
}

.translate-x-1\/2 {
  --transform-translate-x: 50%;
}

.translate-x-1\/3 {
  --transform-translate-x: 33.3333%;
}

.translate-x-2\/3 {
  --transform-translate-x: 66.6667%;
}

.translate-x-1\/4 {
  --transform-translate-x: 25%;
}

.translate-x-2\/4 {
  --transform-translate-x: 50%;
}

.translate-x-3\/4 {
  --transform-translate-x: 75%;
}

.translate-x-1\/5 {
  --transform-translate-x: 20%;
}

.translate-x-2\/5 {
  --transform-translate-x: 40%;
}

.translate-x-3\/5 {
  --transform-translate-x: 60%;
}

.translate-x-4\/5 {
  --transform-translate-x: 80%;
}

.translate-x-1\/6 {
  --transform-translate-x: 16.6667%;
}

.translate-x-2\/6 {
  --transform-translate-x: 33.3333%;
}

.translate-x-3\/6 {
  --transform-translate-x: 50%;
}

.translate-x-4\/6 {
  --transform-translate-x: 66.6667%;
}

.translate-x-5\/6 {
  --transform-translate-x: 83.3333%;
}

.translate-x-1\/12 {
  --transform-translate-x: 8.33333%;
}

.translate-x-2\/12 {
  --transform-translate-x: 16.6667%;
}

.translate-x-3\/12 {
  --transform-translate-x: 25%;
}

.translate-x-4\/12 {
  --transform-translate-x: 33.3333%;
}

.translate-x-5\/12 {
  --transform-translate-x: 41.6667%;
}

.translate-x-6\/12 {
  --transform-translate-x: 50%;
}

.translate-x-7\/12 {
  --transform-translate-x: 58.3333%;
}

.translate-x-8\/12 {
  --transform-translate-x: 66.6667%;
}

.translate-x-9\/12 {
  --transform-translate-x: 75%;
}

.translate-x-10\/12 {
  --transform-translate-x: 83.3333%;
}

.translate-x-11\/12 {
  --transform-translate-x: 91.6667%;
}

.translate-x-full {
  --transform-translate-x: 100%;
}

.-translate-x-1 {
  --transform-translate-x: -.25rem;
}

.-translate-x-2 {
  --transform-translate-x: -.5rem;
}

.-translate-x-3 {
  --transform-translate-x: -.75rem;
}

.-translate-x-4 {
  --transform-translate-x: -1rem;
}

.-translate-x-5 {
  --transform-translate-x: -1.25rem;
}

.-translate-x-6 {
  --transform-translate-x: -1.5rem;
}

.-translate-x-7 {
  --transform-translate-x: -1.75rem;
}

.-translate-x-8 {
  --transform-translate-x: -2rem;
}

.-translate-x-9 {
  --transform-translate-x: -2.25rem;
}

.-translate-x-10 {
  --transform-translate-x: -2.5rem;
}

.-translate-x-11 {
  --transform-translate-x: -2.75rem;
}

.-translate-x-12 {
  --transform-translate-x: -3rem;
}

.-translate-x-13 {
  --transform-translate-x: -3.25rem;
}

.-translate-x-14 {
  --transform-translate-x: -3.5rem;
}

.-translate-x-15 {
  --transform-translate-x: -3.75rem;
}

.-translate-x-16 {
  --transform-translate-x: -4rem;
}

.-translate-x-20 {
  --transform-translate-x: -5rem;
}

.-translate-x-24 {
  --transform-translate-x: -6rem;
}

.-translate-x-28 {
  --transform-translate-x: -7rem;
}

.-translate-x-32 {
  --transform-translate-x: -8rem;
}

.-translate-x-36 {
  --transform-translate-x: -9rem;
}

.-translate-x-40 {
  --transform-translate-x: -10rem;
}

.-translate-x-44 {
  --transform-translate-x: -11rem;
}

.-translate-x-48 {
  --transform-translate-x: -12rem;
}

.-translate-x-52 {
  --transform-translate-x: -13rem;
}

.-translate-x-56 {
  --transform-translate-x: -14rem;
}

.-translate-x-60 {
  --transform-translate-x: -15rem;
}

.-translate-x-64 {
  --transform-translate-x: -16rem;
}

.-translate-x-72 {
  --transform-translate-x: -18rem;
}

.-translate-x-80 {
  --transform-translate-x: -20rem;
}

.-translate-x-96 {
  --transform-translate-x: -24rem;
}

.-translate-x-px {
  --transform-translate-x: -1px;
}

.-translate-x-0\.5 {
  --transform-translate-x: -.125rem;
}

.-translate-x-1\.5 {
  --transform-translate-x: -.375rem;
}

.-translate-x-2\.5 {
  --transform-translate-x: -.625rem;
}

.-translate-x-3\.5 {
  --transform-translate-x: -.875rem;
}

.-translate-x-1\/2 {
  --transform-translate-x: -50%;
}

.-translate-x-1\/3 {
  --transform-translate-x: -33.3333%;
}

.-translate-x-2\/3 {
  --transform-translate-x: -66.6667%;
}

.-translate-x-1\/4 {
  --transform-translate-x: -25%;
}

.-translate-x-2\/4 {
  --transform-translate-x: -50%;
}

.-translate-x-3\/4 {
  --transform-translate-x: -75%;
}

.-translate-x-1\/5 {
  --transform-translate-x: -20%;
}

.-translate-x-2\/5 {
  --transform-translate-x: -40%;
}

.-translate-x-3\/5 {
  --transform-translate-x: -60%;
}

.-translate-x-4\/5 {
  --transform-translate-x: -80%;
}

.-translate-x-1\/6 {
  --transform-translate-x: -16.6667%;
}

.-translate-x-2\/6 {
  --transform-translate-x: -33.3333%;
}

.-translate-x-3\/6 {
  --transform-translate-x: -50%;
}

.-translate-x-4\/6 {
  --transform-translate-x: -66.6667%;
}

.-translate-x-5\/6 {
  --transform-translate-x: -83.3333%;
}

.-translate-x-1\/12 {
  --transform-translate-x: -8.33333%;
}

.-translate-x-2\/12 {
  --transform-translate-x: -16.6667%;
}

.-translate-x-3\/12 {
  --transform-translate-x: -25%;
}

.-translate-x-4\/12 {
  --transform-translate-x: -33.3333%;
}

.-translate-x-5\/12 {
  --transform-translate-x: -41.6667%;
}

.-translate-x-6\/12 {
  --transform-translate-x: -50%;
}

.-translate-x-7\/12 {
  --transform-translate-x: -58.3333%;
}

.-translate-x-8\/12 {
  --transform-translate-x: -66.6667%;
}

.-translate-x-9\/12 {
  --transform-translate-x: -75%;
}

.-translate-x-10\/12 {
  --transform-translate-x: -83.3333%;
}

.-translate-x-11\/12 {
  --transform-translate-x: -91.6667%;
}

.-translate-x-full {
  --transform-translate-x: -100%;
}

.translate-y-0 {
  --transform-translate-y: 0;
}

.translate-y-1 {
  --transform-translate-y: .25rem;
}

.translate-y-2 {
  --transform-translate-y: .5rem;
}

.translate-y-3 {
  --transform-translate-y: .75rem;
}

.translate-y-4 {
  --transform-translate-y: 1rem;
}

.translate-y-5 {
  --transform-translate-y: 1.25rem;
}

.translate-y-6 {
  --transform-translate-y: 1.5rem;
}

.translate-y-7 {
  --transform-translate-y: 1.75rem;
}

.translate-y-8 {
  --transform-translate-y: 2rem;
}

.translate-y-9 {
  --transform-translate-y: 2.25rem;
}

.translate-y-10 {
  --transform-translate-y: 2.5rem;
}

.translate-y-11 {
  --transform-translate-y: 2.75rem;
}

.translate-y-12 {
  --transform-translate-y: 3rem;
}

.translate-y-13 {
  --transform-translate-y: 3.25rem;
}

.translate-y-14 {
  --transform-translate-y: 3.5rem;
}

.translate-y-15 {
  --transform-translate-y: 3.75rem;
}

.translate-y-16 {
  --transform-translate-y: 4rem;
}

.translate-y-20 {
  --transform-translate-y: 5rem;
}

.translate-y-24 {
  --transform-translate-y: 6rem;
}

.translate-y-28 {
  --transform-translate-y: 7rem;
}

.translate-y-32 {
  --transform-translate-y: 8rem;
}

.translate-y-36 {
  --transform-translate-y: 9rem;
}

.translate-y-40 {
  --transform-translate-y: 10rem;
}

.translate-y-44 {
  --transform-translate-y: 11rem;
}

.translate-y-48 {
  --transform-translate-y: 12rem;
}

.translate-y-52 {
  --transform-translate-y: 13rem;
}

.translate-y-56 {
  --transform-translate-y: 14rem;
}

.translate-y-60 {
  --transform-translate-y: 15rem;
}

.translate-y-64 {
  --transform-translate-y: 16rem;
}

.translate-y-72 {
  --transform-translate-y: 18rem;
}

.translate-y-80 {
  --transform-translate-y: 20rem;
}

.translate-y-96 {
  --transform-translate-y: 24rem;
}

.translate-y-px {
  --transform-translate-y: 1px;
}

.translate-y-0\.5 {
  --transform-translate-y: .125rem;
}

.translate-y-1\.5 {
  --transform-translate-y: .375rem;
}

.translate-y-2\.5 {
  --transform-translate-y: .625rem;
}

.translate-y-3\.5 {
  --transform-translate-y: .875rem;
}

.translate-y-1\/2 {
  --transform-translate-y: 50%;
}

.translate-y-1\/3 {
  --transform-translate-y: 33.3333%;
}

.translate-y-2\/3 {
  --transform-translate-y: 66.6667%;
}

.translate-y-1\/4 {
  --transform-translate-y: 25%;
}

.translate-y-2\/4 {
  --transform-translate-y: 50%;
}

.translate-y-3\/4 {
  --transform-translate-y: 75%;
}

.translate-y-1\/5 {
  --transform-translate-y: 20%;
}

.translate-y-2\/5 {
  --transform-translate-y: 40%;
}

.translate-y-3\/5 {
  --transform-translate-y: 60%;
}

.translate-y-4\/5 {
  --transform-translate-y: 80%;
}

.translate-y-1\/6 {
  --transform-translate-y: 16.6667%;
}

.translate-y-2\/6 {
  --transform-translate-y: 33.3333%;
}

.translate-y-3\/6 {
  --transform-translate-y: 50%;
}

.translate-y-4\/6 {
  --transform-translate-y: 66.6667%;
}

.translate-y-5\/6 {
  --transform-translate-y: 83.3333%;
}

.translate-y-1\/12 {
  --transform-translate-y: 8.33333%;
}

.translate-y-2\/12 {
  --transform-translate-y: 16.6667%;
}

.translate-y-3\/12 {
  --transform-translate-y: 25%;
}

.translate-y-4\/12 {
  --transform-translate-y: 33.3333%;
}

.translate-y-5\/12 {
  --transform-translate-y: 41.6667%;
}

.translate-y-6\/12 {
  --transform-translate-y: 50%;
}

.translate-y-7\/12 {
  --transform-translate-y: 58.3333%;
}

.translate-y-8\/12 {
  --transform-translate-y: 66.6667%;
}

.translate-y-9\/12 {
  --transform-translate-y: 75%;
}

.translate-y-10\/12 {
  --transform-translate-y: 83.3333%;
}

.translate-y-11\/12 {
  --transform-translate-y: 91.6667%;
}

.translate-y-full {
  --transform-translate-y: 100%;
}

.-translate-y-1 {
  --transform-translate-y: -.25rem;
}

.-translate-y-2 {
  --transform-translate-y: -.5rem;
}

.-translate-y-3 {
  --transform-translate-y: -.75rem;
}

.-translate-y-4 {
  --transform-translate-y: -1rem;
}

.-translate-y-5 {
  --transform-translate-y: -1.25rem;
}

.-translate-y-6 {
  --transform-translate-y: -1.5rem;
}

.-translate-y-7 {
  --transform-translate-y: -1.75rem;
}

.-translate-y-8 {
  --transform-translate-y: -2rem;
}

.-translate-y-9 {
  --transform-translate-y: -2.25rem;
}

.-translate-y-10 {
  --transform-translate-y: -2.5rem;
}

.-translate-y-11 {
  --transform-translate-y: -2.75rem;
}

.-translate-y-12 {
  --transform-translate-y: -3rem;
}

.-translate-y-13 {
  --transform-translate-y: -3.25rem;
}

.-translate-y-14 {
  --transform-translate-y: -3.5rem;
}

.-translate-y-15 {
  --transform-translate-y: -3.75rem;
}

.-translate-y-16 {
  --transform-translate-y: -4rem;
}

.-translate-y-20 {
  --transform-translate-y: -5rem;
}

.-translate-y-24 {
  --transform-translate-y: -6rem;
}

.-translate-y-28 {
  --transform-translate-y: -7rem;
}

.-translate-y-32 {
  --transform-translate-y: -8rem;
}

.-translate-y-36 {
  --transform-translate-y: -9rem;
}

.-translate-y-40 {
  --transform-translate-y: -10rem;
}

.-translate-y-44 {
  --transform-translate-y: -11rem;
}

.-translate-y-48 {
  --transform-translate-y: -12rem;
}

.-translate-y-52 {
  --transform-translate-y: -13rem;
}

.-translate-y-56 {
  --transform-translate-y: -14rem;
}

.-translate-y-60 {
  --transform-translate-y: -15rem;
}

.-translate-y-64 {
  --transform-translate-y: -16rem;
}

.-translate-y-72 {
  --transform-translate-y: -18rem;
}

.-translate-y-80 {
  --transform-translate-y: -20rem;
}

.-translate-y-96 {
  --transform-translate-y: -24rem;
}

.-translate-y-px {
  --transform-translate-y: -1px;
}

.-translate-y-0\.5 {
  --transform-translate-y: -.125rem;
}

.-translate-y-1\.5 {
  --transform-translate-y: -.375rem;
}

.-translate-y-2\.5 {
  --transform-translate-y: -.625rem;
}

.-translate-y-3\.5 {
  --transform-translate-y: -.875rem;
}

.-translate-y-1\/2 {
  --transform-translate-y: -50%;
}

.-translate-y-1\/3 {
  --transform-translate-y: -33.3333%;
}

.-translate-y-2\/3 {
  --transform-translate-y: -66.6667%;
}

.-translate-y-1\/4 {
  --transform-translate-y: -25%;
}

.-translate-y-2\/4 {
  --transform-translate-y: -50%;
}

.-translate-y-3\/4 {
  --transform-translate-y: -75%;
}

.-translate-y-1\/5 {
  --transform-translate-y: -20%;
}

.-translate-y-2\/5 {
  --transform-translate-y: -40%;
}

.-translate-y-3\/5 {
  --transform-translate-y: -60%;
}

.-translate-y-4\/5 {
  --transform-translate-y: -80%;
}

.-translate-y-1\/6 {
  --transform-translate-y: -16.6667%;
}

.-translate-y-2\/6 {
  --transform-translate-y: -33.3333%;
}

.-translate-y-3\/6 {
  --transform-translate-y: -50%;
}

.-translate-y-4\/6 {
  --transform-translate-y: -66.6667%;
}

.-translate-y-5\/6 {
  --transform-translate-y: -83.3333%;
}

.-translate-y-1\/12 {
  --transform-translate-y: -8.33333%;
}

.-translate-y-2\/12 {
  --transform-translate-y: -16.6667%;
}

.-translate-y-3\/12 {
  --transform-translate-y: -25%;
}

.-translate-y-4\/12 {
  --transform-translate-y: -33.3333%;
}

.-translate-y-5\/12 {
  --transform-translate-y: -41.6667%;
}

.-translate-y-6\/12 {
  --transform-translate-y: -50%;
}

.-translate-y-7\/12 {
  --transform-translate-y: -58.3333%;
}

.-translate-y-8\/12 {
  --transform-translate-y: -66.6667%;
}

.-translate-y-9\/12 {
  --transform-translate-y: -75%;
}

.-translate-y-10\/12 {
  --transform-translate-y: -83.3333%;
}

.-translate-y-11\/12 {
  --transform-translate-y: -91.6667%;
}

.-translate-y-full {
  --transform-translate-y: -100%;
}

.hover\:translate-x-0:hover {
  --transform-translate-x: 0;
}

.hover\:translate-x-1:hover {
  --transform-translate-x: .25rem;
}

.hover\:translate-x-2:hover {
  --transform-translate-x: .5rem;
}

.hover\:translate-x-3:hover {
  --transform-translate-x: .75rem;
}

.hover\:translate-x-4:hover {
  --transform-translate-x: 1rem;
}

.hover\:translate-x-5:hover {
  --transform-translate-x: 1.25rem;
}

.hover\:translate-x-6:hover {
  --transform-translate-x: 1.5rem;
}

.hover\:translate-x-7:hover {
  --transform-translate-x: 1.75rem;
}

.hover\:translate-x-8:hover {
  --transform-translate-x: 2rem;
}

.hover\:translate-x-9:hover {
  --transform-translate-x: 2.25rem;
}

.hover\:translate-x-10:hover {
  --transform-translate-x: 2.5rem;
}

.hover\:translate-x-11:hover {
  --transform-translate-x: 2.75rem;
}

.hover\:translate-x-12:hover {
  --transform-translate-x: 3rem;
}

.hover\:translate-x-13:hover {
  --transform-translate-x: 3.25rem;
}

.hover\:translate-x-14:hover {
  --transform-translate-x: 3.5rem;
}

.hover\:translate-x-15:hover {
  --transform-translate-x: 3.75rem;
}

.hover\:translate-x-16:hover {
  --transform-translate-x: 4rem;
}

.hover\:translate-x-20:hover {
  --transform-translate-x: 5rem;
}

.hover\:translate-x-24:hover {
  --transform-translate-x: 6rem;
}

.hover\:translate-x-28:hover {
  --transform-translate-x: 7rem;
}

.hover\:translate-x-32:hover {
  --transform-translate-x: 8rem;
}

.hover\:translate-x-36:hover {
  --transform-translate-x: 9rem;
}

.hover\:translate-x-40:hover {
  --transform-translate-x: 10rem;
}

.hover\:translate-x-44:hover {
  --transform-translate-x: 11rem;
}

.hover\:translate-x-48:hover {
  --transform-translate-x: 12rem;
}

.hover\:translate-x-52:hover {
  --transform-translate-x: 13rem;
}

.hover\:translate-x-56:hover {
  --transform-translate-x: 14rem;
}

.hover\:translate-x-60:hover {
  --transform-translate-x: 15rem;
}

.hover\:translate-x-64:hover {
  --transform-translate-x: 16rem;
}

.hover\:translate-x-72:hover {
  --transform-translate-x: 18rem;
}

.hover\:translate-x-80:hover {
  --transform-translate-x: 20rem;
}

.hover\:translate-x-96:hover {
  --transform-translate-x: 24rem;
}

.hover\:translate-x-px:hover {
  --transform-translate-x: 1px;
}

.hover\:translate-x-0\.5:hover {
  --transform-translate-x: .125rem;
}

.hover\:translate-x-1\.5:hover {
  --transform-translate-x: .375rem;
}

.hover\:translate-x-2\.5:hover {
  --transform-translate-x: .625rem;
}

.hover\:translate-x-3\.5:hover {
  --transform-translate-x: .875rem;
}

.hover\:translate-x-1\/2:hover {
  --transform-translate-x: 50%;
}

.hover\:translate-x-1\/3:hover {
  --transform-translate-x: 33.3333%;
}

.hover\:translate-x-2\/3:hover {
  --transform-translate-x: 66.6667%;
}

.hover\:translate-x-1\/4:hover {
  --transform-translate-x: 25%;
}

.hover\:translate-x-2\/4:hover {
  --transform-translate-x: 50%;
}

.hover\:translate-x-3\/4:hover {
  --transform-translate-x: 75%;
}

.hover\:translate-x-1\/5:hover {
  --transform-translate-x: 20%;
}

.hover\:translate-x-2\/5:hover {
  --transform-translate-x: 40%;
}

.hover\:translate-x-3\/5:hover {
  --transform-translate-x: 60%;
}

.hover\:translate-x-4\/5:hover {
  --transform-translate-x: 80%;
}

.hover\:translate-x-1\/6:hover {
  --transform-translate-x: 16.6667%;
}

.hover\:translate-x-2\/6:hover {
  --transform-translate-x: 33.3333%;
}

.hover\:translate-x-3\/6:hover {
  --transform-translate-x: 50%;
}

.hover\:translate-x-4\/6:hover {
  --transform-translate-x: 66.6667%;
}

.hover\:translate-x-5\/6:hover {
  --transform-translate-x: 83.3333%;
}

.hover\:translate-x-1\/12:hover {
  --transform-translate-x: 8.33333%;
}

.hover\:translate-x-2\/12:hover {
  --transform-translate-x: 16.6667%;
}

.hover\:translate-x-3\/12:hover {
  --transform-translate-x: 25%;
}

.hover\:translate-x-4\/12:hover {
  --transform-translate-x: 33.3333%;
}

.hover\:translate-x-5\/12:hover {
  --transform-translate-x: 41.6667%;
}

.hover\:translate-x-6\/12:hover {
  --transform-translate-x: 50%;
}

.hover\:translate-x-7\/12:hover {
  --transform-translate-x: 58.3333%;
}

.hover\:translate-x-8\/12:hover {
  --transform-translate-x: 66.6667%;
}

.hover\:translate-x-9\/12:hover {
  --transform-translate-x: 75%;
}

.hover\:translate-x-10\/12:hover {
  --transform-translate-x: 83.3333%;
}

.hover\:translate-x-11\/12:hover {
  --transform-translate-x: 91.6667%;
}

.hover\:translate-x-full:hover {
  --transform-translate-x: 100%;
}

.hover\:-translate-x-1:hover {
  --transform-translate-x: -.25rem;
}

.hover\:-translate-x-2:hover {
  --transform-translate-x: -.5rem;
}

.hover\:-translate-x-3:hover {
  --transform-translate-x: -.75rem;
}

.hover\:-translate-x-4:hover {
  --transform-translate-x: -1rem;
}

.hover\:-translate-x-5:hover {
  --transform-translate-x: -1.25rem;
}

.hover\:-translate-x-6:hover {
  --transform-translate-x: -1.5rem;
}

.hover\:-translate-x-7:hover {
  --transform-translate-x: -1.75rem;
}

.hover\:-translate-x-8:hover {
  --transform-translate-x: -2rem;
}

.hover\:-translate-x-9:hover {
  --transform-translate-x: -2.25rem;
}

.hover\:-translate-x-10:hover {
  --transform-translate-x: -2.5rem;
}

.hover\:-translate-x-11:hover {
  --transform-translate-x: -2.75rem;
}

.hover\:-translate-x-12:hover {
  --transform-translate-x: -3rem;
}

.hover\:-translate-x-13:hover {
  --transform-translate-x: -3.25rem;
}

.hover\:-translate-x-14:hover {
  --transform-translate-x: -3.5rem;
}

.hover\:-translate-x-15:hover {
  --transform-translate-x: -3.75rem;
}

.hover\:-translate-x-16:hover {
  --transform-translate-x: -4rem;
}

.hover\:-translate-x-20:hover {
  --transform-translate-x: -5rem;
}

.hover\:-translate-x-24:hover {
  --transform-translate-x: -6rem;
}

.hover\:-translate-x-28:hover {
  --transform-translate-x: -7rem;
}

.hover\:-translate-x-32:hover {
  --transform-translate-x: -8rem;
}

.hover\:-translate-x-36:hover {
  --transform-translate-x: -9rem;
}

.hover\:-translate-x-40:hover {
  --transform-translate-x: -10rem;
}

.hover\:-translate-x-44:hover {
  --transform-translate-x: -11rem;
}

.hover\:-translate-x-48:hover {
  --transform-translate-x: -12rem;
}

.hover\:-translate-x-52:hover {
  --transform-translate-x: -13rem;
}

.hover\:-translate-x-56:hover {
  --transform-translate-x: -14rem;
}

.hover\:-translate-x-60:hover {
  --transform-translate-x: -15rem;
}

.hover\:-translate-x-64:hover {
  --transform-translate-x: -16rem;
}

.hover\:-translate-x-72:hover {
  --transform-translate-x: -18rem;
}

.hover\:-translate-x-80:hover {
  --transform-translate-x: -20rem;
}

.hover\:-translate-x-96:hover {
  --transform-translate-x: -24rem;
}

.hover\:-translate-x-px:hover {
  --transform-translate-x: -1px;
}

.hover\:-translate-x-0\.5:hover {
  --transform-translate-x: -.125rem;
}

.hover\:-translate-x-1\.5:hover {
  --transform-translate-x: -.375rem;
}

.hover\:-translate-x-2\.5:hover {
  --transform-translate-x: -.625rem;
}

.hover\:-translate-x-3\.5:hover {
  --transform-translate-x: -.875rem;
}

.hover\:-translate-x-1\/2:hover {
  --transform-translate-x: -50%;
}

.hover\:-translate-x-1\/3:hover {
  --transform-translate-x: -33.3333%;
}

.hover\:-translate-x-2\/3:hover {
  --transform-translate-x: -66.6667%;
}

.hover\:-translate-x-1\/4:hover {
  --transform-translate-x: -25%;
}

.hover\:-translate-x-2\/4:hover {
  --transform-translate-x: -50%;
}

.hover\:-translate-x-3\/4:hover {
  --transform-translate-x: -75%;
}

.hover\:-translate-x-1\/5:hover {
  --transform-translate-x: -20%;
}

.hover\:-translate-x-2\/5:hover {
  --transform-translate-x: -40%;
}

.hover\:-translate-x-3\/5:hover {
  --transform-translate-x: -60%;
}

.hover\:-translate-x-4\/5:hover {
  --transform-translate-x: -80%;
}

.hover\:-translate-x-1\/6:hover {
  --transform-translate-x: -16.6667%;
}

.hover\:-translate-x-2\/6:hover {
  --transform-translate-x: -33.3333%;
}

.hover\:-translate-x-3\/6:hover {
  --transform-translate-x: -50%;
}

.hover\:-translate-x-4\/6:hover {
  --transform-translate-x: -66.6667%;
}

.hover\:-translate-x-5\/6:hover {
  --transform-translate-x: -83.3333%;
}

.hover\:-translate-x-1\/12:hover {
  --transform-translate-x: -8.33333%;
}

.hover\:-translate-x-2\/12:hover {
  --transform-translate-x: -16.6667%;
}

.hover\:-translate-x-3\/12:hover {
  --transform-translate-x: -25%;
}

.hover\:-translate-x-4\/12:hover {
  --transform-translate-x: -33.3333%;
}

.hover\:-translate-x-5\/12:hover {
  --transform-translate-x: -41.6667%;
}

.hover\:-translate-x-6\/12:hover {
  --transform-translate-x: -50%;
}

.hover\:-translate-x-7\/12:hover {
  --transform-translate-x: -58.3333%;
}

.hover\:-translate-x-8\/12:hover {
  --transform-translate-x: -66.6667%;
}

.hover\:-translate-x-9\/12:hover {
  --transform-translate-x: -75%;
}

.hover\:-translate-x-10\/12:hover {
  --transform-translate-x: -83.3333%;
}

.hover\:-translate-x-11\/12:hover {
  --transform-translate-x: -91.6667%;
}

.hover\:-translate-x-full:hover {
  --transform-translate-x: -100%;
}

.hover\:translate-y-0:hover {
  --transform-translate-y: 0;
}

.hover\:translate-y-1:hover {
  --transform-translate-y: .25rem;
}

.hover\:translate-y-2:hover {
  --transform-translate-y: .5rem;
}

.hover\:translate-y-3:hover {
  --transform-translate-y: .75rem;
}

.hover\:translate-y-4:hover {
  --transform-translate-y: 1rem;
}

.hover\:translate-y-5:hover {
  --transform-translate-y: 1.25rem;
}

.hover\:translate-y-6:hover {
  --transform-translate-y: 1.5rem;
}

.hover\:translate-y-7:hover {
  --transform-translate-y: 1.75rem;
}

.hover\:translate-y-8:hover {
  --transform-translate-y: 2rem;
}

.hover\:translate-y-9:hover {
  --transform-translate-y: 2.25rem;
}

.hover\:translate-y-10:hover {
  --transform-translate-y: 2.5rem;
}

.hover\:translate-y-11:hover {
  --transform-translate-y: 2.75rem;
}

.hover\:translate-y-12:hover {
  --transform-translate-y: 3rem;
}

.hover\:translate-y-13:hover {
  --transform-translate-y: 3.25rem;
}

.hover\:translate-y-14:hover {
  --transform-translate-y: 3.5rem;
}

.hover\:translate-y-15:hover {
  --transform-translate-y: 3.75rem;
}

.hover\:translate-y-16:hover {
  --transform-translate-y: 4rem;
}

.hover\:translate-y-20:hover {
  --transform-translate-y: 5rem;
}

.hover\:translate-y-24:hover {
  --transform-translate-y: 6rem;
}

.hover\:translate-y-28:hover {
  --transform-translate-y: 7rem;
}

.hover\:translate-y-32:hover {
  --transform-translate-y: 8rem;
}

.hover\:translate-y-36:hover {
  --transform-translate-y: 9rem;
}

.hover\:translate-y-40:hover {
  --transform-translate-y: 10rem;
}

.hover\:translate-y-44:hover {
  --transform-translate-y: 11rem;
}

.hover\:translate-y-48:hover {
  --transform-translate-y: 12rem;
}

.hover\:translate-y-52:hover {
  --transform-translate-y: 13rem;
}

.hover\:translate-y-56:hover {
  --transform-translate-y: 14rem;
}

.hover\:translate-y-60:hover {
  --transform-translate-y: 15rem;
}

.hover\:translate-y-64:hover {
  --transform-translate-y: 16rem;
}

.hover\:translate-y-72:hover {
  --transform-translate-y: 18rem;
}

.hover\:translate-y-80:hover {
  --transform-translate-y: 20rem;
}

.hover\:translate-y-96:hover {
  --transform-translate-y: 24rem;
}

.hover\:translate-y-px:hover {
  --transform-translate-y: 1px;
}

.hover\:translate-y-0\.5:hover {
  --transform-translate-y: .125rem;
}

.hover\:translate-y-1\.5:hover {
  --transform-translate-y: .375rem;
}

.hover\:translate-y-2\.5:hover {
  --transform-translate-y: .625rem;
}

.hover\:translate-y-3\.5:hover {
  --transform-translate-y: .875rem;
}

.hover\:translate-y-1\/2:hover {
  --transform-translate-y: 50%;
}

.hover\:translate-y-1\/3:hover {
  --transform-translate-y: 33.3333%;
}

.hover\:translate-y-2\/3:hover {
  --transform-translate-y: 66.6667%;
}

.hover\:translate-y-1\/4:hover {
  --transform-translate-y: 25%;
}

.hover\:translate-y-2\/4:hover {
  --transform-translate-y: 50%;
}

.hover\:translate-y-3\/4:hover {
  --transform-translate-y: 75%;
}

.hover\:translate-y-1\/5:hover {
  --transform-translate-y: 20%;
}

.hover\:translate-y-2\/5:hover {
  --transform-translate-y: 40%;
}

.hover\:translate-y-3\/5:hover {
  --transform-translate-y: 60%;
}

.hover\:translate-y-4\/5:hover {
  --transform-translate-y: 80%;
}

.hover\:translate-y-1\/6:hover {
  --transform-translate-y: 16.6667%;
}

.hover\:translate-y-2\/6:hover {
  --transform-translate-y: 33.3333%;
}

.hover\:translate-y-3\/6:hover {
  --transform-translate-y: 50%;
}

.hover\:translate-y-4\/6:hover {
  --transform-translate-y: 66.6667%;
}

.hover\:translate-y-5\/6:hover {
  --transform-translate-y: 83.3333%;
}

.hover\:translate-y-1\/12:hover {
  --transform-translate-y: 8.33333%;
}

.hover\:translate-y-2\/12:hover {
  --transform-translate-y: 16.6667%;
}

.hover\:translate-y-3\/12:hover {
  --transform-translate-y: 25%;
}

.hover\:translate-y-4\/12:hover {
  --transform-translate-y: 33.3333%;
}

.hover\:translate-y-5\/12:hover {
  --transform-translate-y: 41.6667%;
}

.hover\:translate-y-6\/12:hover {
  --transform-translate-y: 50%;
}

.hover\:translate-y-7\/12:hover {
  --transform-translate-y: 58.3333%;
}

.hover\:translate-y-8\/12:hover {
  --transform-translate-y: 66.6667%;
}

.hover\:translate-y-9\/12:hover {
  --transform-translate-y: 75%;
}

.hover\:translate-y-10\/12:hover {
  --transform-translate-y: 83.3333%;
}

.hover\:translate-y-11\/12:hover {
  --transform-translate-y: 91.6667%;
}

.hover\:translate-y-full:hover {
  --transform-translate-y: 100%;
}

.hover\:-translate-y-1:hover {
  --transform-translate-y: -.25rem;
}

.hover\:-translate-y-2:hover {
  --transform-translate-y: -.5rem;
}

.hover\:-translate-y-3:hover {
  --transform-translate-y: -.75rem;
}

.hover\:-translate-y-4:hover {
  --transform-translate-y: -1rem;
}

.hover\:-translate-y-5:hover {
  --transform-translate-y: -1.25rem;
}

.hover\:-translate-y-6:hover {
  --transform-translate-y: -1.5rem;
}

.hover\:-translate-y-7:hover {
  --transform-translate-y: -1.75rem;
}

.hover\:-translate-y-8:hover {
  --transform-translate-y: -2rem;
}

.hover\:-translate-y-9:hover {
  --transform-translate-y: -2.25rem;
}

.hover\:-translate-y-10:hover {
  --transform-translate-y: -2.5rem;
}

.hover\:-translate-y-11:hover {
  --transform-translate-y: -2.75rem;
}

.hover\:-translate-y-12:hover {
  --transform-translate-y: -3rem;
}

.hover\:-translate-y-13:hover {
  --transform-translate-y: -3.25rem;
}

.hover\:-translate-y-14:hover {
  --transform-translate-y: -3.5rem;
}

.hover\:-translate-y-15:hover {
  --transform-translate-y: -3.75rem;
}

.hover\:-translate-y-16:hover {
  --transform-translate-y: -4rem;
}

.hover\:-translate-y-20:hover {
  --transform-translate-y: -5rem;
}

.hover\:-translate-y-24:hover {
  --transform-translate-y: -6rem;
}

.hover\:-translate-y-28:hover {
  --transform-translate-y: -7rem;
}

.hover\:-translate-y-32:hover {
  --transform-translate-y: -8rem;
}

.hover\:-translate-y-36:hover {
  --transform-translate-y: -9rem;
}

.hover\:-translate-y-40:hover {
  --transform-translate-y: -10rem;
}

.hover\:-translate-y-44:hover {
  --transform-translate-y: -11rem;
}

.hover\:-translate-y-48:hover {
  --transform-translate-y: -12rem;
}

.hover\:-translate-y-52:hover {
  --transform-translate-y: -13rem;
}

.hover\:-translate-y-56:hover {
  --transform-translate-y: -14rem;
}

.hover\:-translate-y-60:hover {
  --transform-translate-y: -15rem;
}

.hover\:-translate-y-64:hover {
  --transform-translate-y: -16rem;
}

.hover\:-translate-y-72:hover {
  --transform-translate-y: -18rem;
}

.hover\:-translate-y-80:hover {
  --transform-translate-y: -20rem;
}

.hover\:-translate-y-96:hover {
  --transform-translate-y: -24rem;
}

.hover\:-translate-y-px:hover {
  --transform-translate-y: -1px;
}

.hover\:-translate-y-0\.5:hover {
  --transform-translate-y: -.125rem;
}

.hover\:-translate-y-1\.5:hover {
  --transform-translate-y: -.375rem;
}

.hover\:-translate-y-2\.5:hover {
  --transform-translate-y: -.625rem;
}

.hover\:-translate-y-3\.5:hover {
  --transform-translate-y: -.875rem;
}

.hover\:-translate-y-1\/2:hover {
  --transform-translate-y: -50%;
}

.hover\:-translate-y-1\/3:hover {
  --transform-translate-y: -33.3333%;
}

.hover\:-translate-y-2\/3:hover {
  --transform-translate-y: -66.6667%;
}

.hover\:-translate-y-1\/4:hover {
  --transform-translate-y: -25%;
}

.hover\:-translate-y-2\/4:hover {
  --transform-translate-y: -50%;
}

.hover\:-translate-y-3\/4:hover {
  --transform-translate-y: -75%;
}

.hover\:-translate-y-1\/5:hover {
  --transform-translate-y: -20%;
}

.hover\:-translate-y-2\/5:hover {
  --transform-translate-y: -40%;
}

.hover\:-translate-y-3\/5:hover {
  --transform-translate-y: -60%;
}

.hover\:-translate-y-4\/5:hover {
  --transform-translate-y: -80%;
}

.hover\:-translate-y-1\/6:hover {
  --transform-translate-y: -16.6667%;
}

.hover\:-translate-y-2\/6:hover {
  --transform-translate-y: -33.3333%;
}

.hover\:-translate-y-3\/6:hover {
  --transform-translate-y: -50%;
}

.hover\:-translate-y-4\/6:hover {
  --transform-translate-y: -66.6667%;
}

.hover\:-translate-y-5\/6:hover {
  --transform-translate-y: -83.3333%;
}

.hover\:-translate-y-1\/12:hover {
  --transform-translate-y: -8.33333%;
}

.hover\:-translate-y-2\/12:hover {
  --transform-translate-y: -16.6667%;
}

.hover\:-translate-y-3\/12:hover {
  --transform-translate-y: -25%;
}

.hover\:-translate-y-4\/12:hover {
  --transform-translate-y: -33.3333%;
}

.hover\:-translate-y-5\/12:hover {
  --transform-translate-y: -41.6667%;
}

.hover\:-translate-y-6\/12:hover {
  --transform-translate-y: -50%;
}

.hover\:-translate-y-7\/12:hover {
  --transform-translate-y: -58.3333%;
}

.hover\:-translate-y-8\/12:hover {
  --transform-translate-y: -66.6667%;
}

.hover\:-translate-y-9\/12:hover {
  --transform-translate-y: -75%;
}

.hover\:-translate-y-10\/12:hover {
  --transform-translate-y: -83.3333%;
}

.hover\:-translate-y-11\/12:hover {
  --transform-translate-y: -91.6667%;
}

.hover\:-translate-y-full:hover {
  --transform-translate-y: -100%;
}

.focus\:translate-x-0:focus {
  --transform-translate-x: 0;
}

.focus\:translate-x-1:focus {
  --transform-translate-x: .25rem;
}

.focus\:translate-x-2:focus {
  --transform-translate-x: .5rem;
}

.focus\:translate-x-3:focus {
  --transform-translate-x: .75rem;
}

.focus\:translate-x-4:focus {
  --transform-translate-x: 1rem;
}

.focus\:translate-x-5:focus {
  --transform-translate-x: 1.25rem;
}

.focus\:translate-x-6:focus {
  --transform-translate-x: 1.5rem;
}

.focus\:translate-x-7:focus {
  --transform-translate-x: 1.75rem;
}

.focus\:translate-x-8:focus {
  --transform-translate-x: 2rem;
}

.focus\:translate-x-9:focus {
  --transform-translate-x: 2.25rem;
}

.focus\:translate-x-10:focus {
  --transform-translate-x: 2.5rem;
}

.focus\:translate-x-11:focus {
  --transform-translate-x: 2.75rem;
}

.focus\:translate-x-12:focus {
  --transform-translate-x: 3rem;
}

.focus\:translate-x-13:focus {
  --transform-translate-x: 3.25rem;
}

.focus\:translate-x-14:focus {
  --transform-translate-x: 3.5rem;
}

.focus\:translate-x-15:focus {
  --transform-translate-x: 3.75rem;
}

.focus\:translate-x-16:focus {
  --transform-translate-x: 4rem;
}

.focus\:translate-x-20:focus {
  --transform-translate-x: 5rem;
}

.focus\:translate-x-24:focus {
  --transform-translate-x: 6rem;
}

.focus\:translate-x-28:focus {
  --transform-translate-x: 7rem;
}

.focus\:translate-x-32:focus {
  --transform-translate-x: 8rem;
}

.focus\:translate-x-36:focus {
  --transform-translate-x: 9rem;
}

.focus\:translate-x-40:focus {
  --transform-translate-x: 10rem;
}

.focus\:translate-x-44:focus {
  --transform-translate-x: 11rem;
}

.focus\:translate-x-48:focus {
  --transform-translate-x: 12rem;
}

.focus\:translate-x-52:focus {
  --transform-translate-x: 13rem;
}

.focus\:translate-x-56:focus {
  --transform-translate-x: 14rem;
}

.focus\:translate-x-60:focus {
  --transform-translate-x: 15rem;
}

.focus\:translate-x-64:focus {
  --transform-translate-x: 16rem;
}

.focus\:translate-x-72:focus {
  --transform-translate-x: 18rem;
}

.focus\:translate-x-80:focus {
  --transform-translate-x: 20rem;
}

.focus\:translate-x-96:focus {
  --transform-translate-x: 24rem;
}

.focus\:translate-x-px:focus {
  --transform-translate-x: 1px;
}

.focus\:translate-x-0\.5:focus {
  --transform-translate-x: .125rem;
}

.focus\:translate-x-1\.5:focus {
  --transform-translate-x: .375rem;
}

.focus\:translate-x-2\.5:focus {
  --transform-translate-x: .625rem;
}

.focus\:translate-x-3\.5:focus {
  --transform-translate-x: .875rem;
}

.focus\:translate-x-1\/2:focus {
  --transform-translate-x: 50%;
}

.focus\:translate-x-1\/3:focus {
  --transform-translate-x: 33.3333%;
}

.focus\:translate-x-2\/3:focus {
  --transform-translate-x: 66.6667%;
}

.focus\:translate-x-1\/4:focus {
  --transform-translate-x: 25%;
}

.focus\:translate-x-2\/4:focus {
  --transform-translate-x: 50%;
}

.focus\:translate-x-3\/4:focus {
  --transform-translate-x: 75%;
}

.focus\:translate-x-1\/5:focus {
  --transform-translate-x: 20%;
}

.focus\:translate-x-2\/5:focus {
  --transform-translate-x: 40%;
}

.focus\:translate-x-3\/5:focus {
  --transform-translate-x: 60%;
}

.focus\:translate-x-4\/5:focus {
  --transform-translate-x: 80%;
}

.focus\:translate-x-1\/6:focus {
  --transform-translate-x: 16.6667%;
}

.focus\:translate-x-2\/6:focus {
  --transform-translate-x: 33.3333%;
}

.focus\:translate-x-3\/6:focus {
  --transform-translate-x: 50%;
}

.focus\:translate-x-4\/6:focus {
  --transform-translate-x: 66.6667%;
}

.focus\:translate-x-5\/6:focus {
  --transform-translate-x: 83.3333%;
}

.focus\:translate-x-1\/12:focus {
  --transform-translate-x: 8.33333%;
}

.focus\:translate-x-2\/12:focus {
  --transform-translate-x: 16.6667%;
}

.focus\:translate-x-3\/12:focus {
  --transform-translate-x: 25%;
}

.focus\:translate-x-4\/12:focus {
  --transform-translate-x: 33.3333%;
}

.focus\:translate-x-5\/12:focus {
  --transform-translate-x: 41.6667%;
}

.focus\:translate-x-6\/12:focus {
  --transform-translate-x: 50%;
}

.focus\:translate-x-7\/12:focus {
  --transform-translate-x: 58.3333%;
}

.focus\:translate-x-8\/12:focus {
  --transform-translate-x: 66.6667%;
}

.focus\:translate-x-9\/12:focus {
  --transform-translate-x: 75%;
}

.focus\:translate-x-10\/12:focus {
  --transform-translate-x: 83.3333%;
}

.focus\:translate-x-11\/12:focus {
  --transform-translate-x: 91.6667%;
}

.focus\:translate-x-full:focus {
  --transform-translate-x: 100%;
}

.focus\:-translate-x-1:focus {
  --transform-translate-x: -.25rem;
}

.focus\:-translate-x-2:focus {
  --transform-translate-x: -.5rem;
}

.focus\:-translate-x-3:focus {
  --transform-translate-x: -.75rem;
}

.focus\:-translate-x-4:focus {
  --transform-translate-x: -1rem;
}

.focus\:-translate-x-5:focus {
  --transform-translate-x: -1.25rem;
}

.focus\:-translate-x-6:focus {
  --transform-translate-x: -1.5rem;
}

.focus\:-translate-x-7:focus {
  --transform-translate-x: -1.75rem;
}

.focus\:-translate-x-8:focus {
  --transform-translate-x: -2rem;
}

.focus\:-translate-x-9:focus {
  --transform-translate-x: -2.25rem;
}

.focus\:-translate-x-10:focus {
  --transform-translate-x: -2.5rem;
}

.focus\:-translate-x-11:focus {
  --transform-translate-x: -2.75rem;
}

.focus\:-translate-x-12:focus {
  --transform-translate-x: -3rem;
}

.focus\:-translate-x-13:focus {
  --transform-translate-x: -3.25rem;
}

.focus\:-translate-x-14:focus {
  --transform-translate-x: -3.5rem;
}

.focus\:-translate-x-15:focus {
  --transform-translate-x: -3.75rem;
}

.focus\:-translate-x-16:focus {
  --transform-translate-x: -4rem;
}

.focus\:-translate-x-20:focus {
  --transform-translate-x: -5rem;
}

.focus\:-translate-x-24:focus {
  --transform-translate-x: -6rem;
}

.focus\:-translate-x-28:focus {
  --transform-translate-x: -7rem;
}

.focus\:-translate-x-32:focus {
  --transform-translate-x: -8rem;
}

.focus\:-translate-x-36:focus {
  --transform-translate-x: -9rem;
}

.focus\:-translate-x-40:focus {
  --transform-translate-x: -10rem;
}

.focus\:-translate-x-44:focus {
  --transform-translate-x: -11rem;
}

.focus\:-translate-x-48:focus {
  --transform-translate-x: -12rem;
}

.focus\:-translate-x-52:focus {
  --transform-translate-x: -13rem;
}

.focus\:-translate-x-56:focus {
  --transform-translate-x: -14rem;
}

.focus\:-translate-x-60:focus {
  --transform-translate-x: -15rem;
}

.focus\:-translate-x-64:focus {
  --transform-translate-x: -16rem;
}

.focus\:-translate-x-72:focus {
  --transform-translate-x: -18rem;
}

.focus\:-translate-x-80:focus {
  --transform-translate-x: -20rem;
}

.focus\:-translate-x-96:focus {
  --transform-translate-x: -24rem;
}

.focus\:-translate-x-px:focus {
  --transform-translate-x: -1px;
}

.focus\:-translate-x-0\.5:focus {
  --transform-translate-x: -.125rem;
}

.focus\:-translate-x-1\.5:focus {
  --transform-translate-x: -.375rem;
}

.focus\:-translate-x-2\.5:focus {
  --transform-translate-x: -.625rem;
}

.focus\:-translate-x-3\.5:focus {
  --transform-translate-x: -.875rem;
}

.focus\:-translate-x-1\/2:focus {
  --transform-translate-x: -50%;
}

.focus\:-translate-x-1\/3:focus {
  --transform-translate-x: -33.3333%;
}

.focus\:-translate-x-2\/3:focus {
  --transform-translate-x: -66.6667%;
}

.focus\:-translate-x-1\/4:focus {
  --transform-translate-x: -25%;
}

.focus\:-translate-x-2\/4:focus {
  --transform-translate-x: -50%;
}

.focus\:-translate-x-3\/4:focus {
  --transform-translate-x: -75%;
}

.focus\:-translate-x-1\/5:focus {
  --transform-translate-x: -20%;
}

.focus\:-translate-x-2\/5:focus {
  --transform-translate-x: -40%;
}

.focus\:-translate-x-3\/5:focus {
  --transform-translate-x: -60%;
}

.focus\:-translate-x-4\/5:focus {
  --transform-translate-x: -80%;
}

.focus\:-translate-x-1\/6:focus {
  --transform-translate-x: -16.6667%;
}

.focus\:-translate-x-2\/6:focus {
  --transform-translate-x: -33.3333%;
}

.focus\:-translate-x-3\/6:focus {
  --transform-translate-x: -50%;
}

.focus\:-translate-x-4\/6:focus {
  --transform-translate-x: -66.6667%;
}

.focus\:-translate-x-5\/6:focus {
  --transform-translate-x: -83.3333%;
}

.focus\:-translate-x-1\/12:focus {
  --transform-translate-x: -8.33333%;
}

.focus\:-translate-x-2\/12:focus {
  --transform-translate-x: -16.6667%;
}

.focus\:-translate-x-3\/12:focus {
  --transform-translate-x: -25%;
}

.focus\:-translate-x-4\/12:focus {
  --transform-translate-x: -33.3333%;
}

.focus\:-translate-x-5\/12:focus {
  --transform-translate-x: -41.6667%;
}

.focus\:-translate-x-6\/12:focus {
  --transform-translate-x: -50%;
}

.focus\:-translate-x-7\/12:focus {
  --transform-translate-x: -58.3333%;
}

.focus\:-translate-x-8\/12:focus {
  --transform-translate-x: -66.6667%;
}

.focus\:-translate-x-9\/12:focus {
  --transform-translate-x: -75%;
}

.focus\:-translate-x-10\/12:focus {
  --transform-translate-x: -83.3333%;
}

.focus\:-translate-x-11\/12:focus {
  --transform-translate-x: -91.6667%;
}

.focus\:-translate-x-full:focus {
  --transform-translate-x: -100%;
}

.focus\:translate-y-0:focus {
  --transform-translate-y: 0;
}

.focus\:translate-y-1:focus {
  --transform-translate-y: .25rem;
}

.focus\:translate-y-2:focus {
  --transform-translate-y: .5rem;
}

.focus\:translate-y-3:focus {
  --transform-translate-y: .75rem;
}

.focus\:translate-y-4:focus {
  --transform-translate-y: 1rem;
}

.focus\:translate-y-5:focus {
  --transform-translate-y: 1.25rem;
}

.focus\:translate-y-6:focus {
  --transform-translate-y: 1.5rem;
}

.focus\:translate-y-7:focus {
  --transform-translate-y: 1.75rem;
}

.focus\:translate-y-8:focus {
  --transform-translate-y: 2rem;
}

.focus\:translate-y-9:focus {
  --transform-translate-y: 2.25rem;
}

.focus\:translate-y-10:focus {
  --transform-translate-y: 2.5rem;
}

.focus\:translate-y-11:focus {
  --transform-translate-y: 2.75rem;
}

.focus\:translate-y-12:focus {
  --transform-translate-y: 3rem;
}

.focus\:translate-y-13:focus {
  --transform-translate-y: 3.25rem;
}

.focus\:translate-y-14:focus {
  --transform-translate-y: 3.5rem;
}

.focus\:translate-y-15:focus {
  --transform-translate-y: 3.75rem;
}

.focus\:translate-y-16:focus {
  --transform-translate-y: 4rem;
}

.focus\:translate-y-20:focus {
  --transform-translate-y: 5rem;
}

.focus\:translate-y-24:focus {
  --transform-translate-y: 6rem;
}

.focus\:translate-y-28:focus {
  --transform-translate-y: 7rem;
}

.focus\:translate-y-32:focus {
  --transform-translate-y: 8rem;
}

.focus\:translate-y-36:focus {
  --transform-translate-y: 9rem;
}

.focus\:translate-y-40:focus {
  --transform-translate-y: 10rem;
}

.focus\:translate-y-44:focus {
  --transform-translate-y: 11rem;
}

.focus\:translate-y-48:focus {
  --transform-translate-y: 12rem;
}

.focus\:translate-y-52:focus {
  --transform-translate-y: 13rem;
}

.focus\:translate-y-56:focus {
  --transform-translate-y: 14rem;
}

.focus\:translate-y-60:focus {
  --transform-translate-y: 15rem;
}

.focus\:translate-y-64:focus {
  --transform-translate-y: 16rem;
}

.focus\:translate-y-72:focus {
  --transform-translate-y: 18rem;
}

.focus\:translate-y-80:focus {
  --transform-translate-y: 20rem;
}

.focus\:translate-y-96:focus {
  --transform-translate-y: 24rem;
}

.focus\:translate-y-px:focus {
  --transform-translate-y: 1px;
}

.focus\:translate-y-0\.5:focus {
  --transform-translate-y: .125rem;
}

.focus\:translate-y-1\.5:focus {
  --transform-translate-y: .375rem;
}

.focus\:translate-y-2\.5:focus {
  --transform-translate-y: .625rem;
}

.focus\:translate-y-3\.5:focus {
  --transform-translate-y: .875rem;
}

.focus\:translate-y-1\/2:focus {
  --transform-translate-y: 50%;
}

.focus\:translate-y-1\/3:focus {
  --transform-translate-y: 33.3333%;
}

.focus\:translate-y-2\/3:focus {
  --transform-translate-y: 66.6667%;
}

.focus\:translate-y-1\/4:focus {
  --transform-translate-y: 25%;
}

.focus\:translate-y-2\/4:focus {
  --transform-translate-y: 50%;
}

.focus\:translate-y-3\/4:focus {
  --transform-translate-y: 75%;
}

.focus\:translate-y-1\/5:focus {
  --transform-translate-y: 20%;
}

.focus\:translate-y-2\/5:focus {
  --transform-translate-y: 40%;
}

.focus\:translate-y-3\/5:focus {
  --transform-translate-y: 60%;
}

.focus\:translate-y-4\/5:focus {
  --transform-translate-y: 80%;
}

.focus\:translate-y-1\/6:focus {
  --transform-translate-y: 16.6667%;
}

.focus\:translate-y-2\/6:focus {
  --transform-translate-y: 33.3333%;
}

.focus\:translate-y-3\/6:focus {
  --transform-translate-y: 50%;
}

.focus\:translate-y-4\/6:focus {
  --transform-translate-y: 66.6667%;
}

.focus\:translate-y-5\/6:focus {
  --transform-translate-y: 83.3333%;
}

.focus\:translate-y-1\/12:focus {
  --transform-translate-y: 8.33333%;
}

.focus\:translate-y-2\/12:focus {
  --transform-translate-y: 16.6667%;
}

.focus\:translate-y-3\/12:focus {
  --transform-translate-y: 25%;
}

.focus\:translate-y-4\/12:focus {
  --transform-translate-y: 33.3333%;
}

.focus\:translate-y-5\/12:focus {
  --transform-translate-y: 41.6667%;
}

.focus\:translate-y-6\/12:focus {
  --transform-translate-y: 50%;
}

.focus\:translate-y-7\/12:focus {
  --transform-translate-y: 58.3333%;
}

.focus\:translate-y-8\/12:focus {
  --transform-translate-y: 66.6667%;
}

.focus\:translate-y-9\/12:focus {
  --transform-translate-y: 75%;
}

.focus\:translate-y-10\/12:focus {
  --transform-translate-y: 83.3333%;
}

.focus\:translate-y-11\/12:focus {
  --transform-translate-y: 91.6667%;
}

.focus\:translate-y-full:focus {
  --transform-translate-y: 100%;
}

.focus\:-translate-y-1:focus {
  --transform-translate-y: -.25rem;
}

.focus\:-translate-y-2:focus {
  --transform-translate-y: -.5rem;
}

.focus\:-translate-y-3:focus {
  --transform-translate-y: -.75rem;
}

.focus\:-translate-y-4:focus {
  --transform-translate-y: -1rem;
}

.focus\:-translate-y-5:focus {
  --transform-translate-y: -1.25rem;
}

.focus\:-translate-y-6:focus {
  --transform-translate-y: -1.5rem;
}

.focus\:-translate-y-7:focus {
  --transform-translate-y: -1.75rem;
}

.focus\:-translate-y-8:focus {
  --transform-translate-y: -2rem;
}

.focus\:-translate-y-9:focus {
  --transform-translate-y: -2.25rem;
}

.focus\:-translate-y-10:focus {
  --transform-translate-y: -2.5rem;
}

.focus\:-translate-y-11:focus {
  --transform-translate-y: -2.75rem;
}

.focus\:-translate-y-12:focus {
  --transform-translate-y: -3rem;
}

.focus\:-translate-y-13:focus {
  --transform-translate-y: -3.25rem;
}

.focus\:-translate-y-14:focus {
  --transform-translate-y: -3.5rem;
}

.focus\:-translate-y-15:focus {
  --transform-translate-y: -3.75rem;
}

.focus\:-translate-y-16:focus {
  --transform-translate-y: -4rem;
}

.focus\:-translate-y-20:focus {
  --transform-translate-y: -5rem;
}

.focus\:-translate-y-24:focus {
  --transform-translate-y: -6rem;
}

.focus\:-translate-y-28:focus {
  --transform-translate-y: -7rem;
}

.focus\:-translate-y-32:focus {
  --transform-translate-y: -8rem;
}

.focus\:-translate-y-36:focus {
  --transform-translate-y: -9rem;
}

.focus\:-translate-y-40:focus {
  --transform-translate-y: -10rem;
}

.focus\:-translate-y-44:focus {
  --transform-translate-y: -11rem;
}

.focus\:-translate-y-48:focus {
  --transform-translate-y: -12rem;
}

.focus\:-translate-y-52:focus {
  --transform-translate-y: -13rem;
}

.focus\:-translate-y-56:focus {
  --transform-translate-y: -14rem;
}

.focus\:-translate-y-60:focus {
  --transform-translate-y: -15rem;
}

.focus\:-translate-y-64:focus {
  --transform-translate-y: -16rem;
}

.focus\:-translate-y-72:focus {
  --transform-translate-y: -18rem;
}

.focus\:-translate-y-80:focus {
  --transform-translate-y: -20rem;
}

.focus\:-translate-y-96:focus {
  --transform-translate-y: -24rem;
}

.focus\:-translate-y-px:focus {
  --transform-translate-y: -1px;
}

.focus\:-translate-y-0\.5:focus {
  --transform-translate-y: -.125rem;
}

.focus\:-translate-y-1\.5:focus {
  --transform-translate-y: -.375rem;
}

.focus\:-translate-y-2\.5:focus {
  --transform-translate-y: -.625rem;
}

.focus\:-translate-y-3\.5:focus {
  --transform-translate-y: -.875rem;
}

.focus\:-translate-y-1\/2:focus {
  --transform-translate-y: -50%;
}

.focus\:-translate-y-1\/3:focus {
  --transform-translate-y: -33.3333%;
}

.focus\:-translate-y-2\/3:focus {
  --transform-translate-y: -66.6667%;
}

.focus\:-translate-y-1\/4:focus {
  --transform-translate-y: -25%;
}

.focus\:-translate-y-2\/4:focus {
  --transform-translate-y: -50%;
}

.focus\:-translate-y-3\/4:focus {
  --transform-translate-y: -75%;
}

.focus\:-translate-y-1\/5:focus {
  --transform-translate-y: -20%;
}

.focus\:-translate-y-2\/5:focus {
  --transform-translate-y: -40%;
}

.focus\:-translate-y-3\/5:focus {
  --transform-translate-y: -60%;
}

.focus\:-translate-y-4\/5:focus {
  --transform-translate-y: -80%;
}

.focus\:-translate-y-1\/6:focus {
  --transform-translate-y: -16.6667%;
}

.focus\:-translate-y-2\/6:focus {
  --transform-translate-y: -33.3333%;
}

.focus\:-translate-y-3\/6:focus {
  --transform-translate-y: -50%;
}

.focus\:-translate-y-4\/6:focus {
  --transform-translate-y: -66.6667%;
}

.focus\:-translate-y-5\/6:focus {
  --transform-translate-y: -83.3333%;
}

.focus\:-translate-y-1\/12:focus {
  --transform-translate-y: -8.33333%;
}

.focus\:-translate-y-2\/12:focus {
  --transform-translate-y: -16.6667%;
}

.focus\:-translate-y-3\/12:focus {
  --transform-translate-y: -25%;
}

.focus\:-translate-y-4\/12:focus {
  --transform-translate-y: -33.3333%;
}

.focus\:-translate-y-5\/12:focus {
  --transform-translate-y: -41.6667%;
}

.focus\:-translate-y-6\/12:focus {
  --transform-translate-y: -50%;
}

.focus\:-translate-y-7\/12:focus {
  --transform-translate-y: -58.3333%;
}

.focus\:-translate-y-8\/12:focus {
  --transform-translate-y: -66.6667%;
}

.focus\:-translate-y-9\/12:focus {
  --transform-translate-y: -75%;
}

.focus\:-translate-y-10\/12:focus {
  --transform-translate-y: -83.3333%;
}

.focus\:-translate-y-11\/12:focus {
  --transform-translate-y: -91.6667%;
}

.focus\:-translate-y-full:focus {
  --transform-translate-y: -100%;
}

.skew-x-0 {
  --transform-skew-x: 0;
}

.skew-x-1 {
  --transform-skew-x: 1deg;
}

.skew-x-2 {
  --transform-skew-x: 2deg;
}

.skew-x-3 {
  --transform-skew-x: 3deg;
}

.skew-x-6 {
  --transform-skew-x: 6deg;
}

.skew-x-12 {
  --transform-skew-x: 12deg;
}

.-skew-x-12 {
  --transform-skew-x: -12deg;
}

.-skew-x-6 {
  --transform-skew-x: -6deg;
}

.-skew-x-3 {
  --transform-skew-x: -3deg;
}

.-skew-x-2 {
  --transform-skew-x: -2deg;
}

.-skew-x-1 {
  --transform-skew-x: -1deg;
}

.skew-y-0 {
  --transform-skew-y: 0;
}

.skew-y-1 {
  --transform-skew-y: 1deg;
}

.skew-y-2 {
  --transform-skew-y: 2deg;
}

.skew-y-3 {
  --transform-skew-y: 3deg;
}

.skew-y-6 {
  --transform-skew-y: 6deg;
}

.skew-y-12 {
  --transform-skew-y: 12deg;
}

.-skew-y-12 {
  --transform-skew-y: -12deg;
}

.-skew-y-6 {
  --transform-skew-y: -6deg;
}

.-skew-y-3 {
  --transform-skew-y: -3deg;
}

.-skew-y-2 {
  --transform-skew-y: -2deg;
}

.-skew-y-1 {
  --transform-skew-y: -1deg;
}

.hover\:skew-x-0:hover {
  --transform-skew-x: 0;
}

.hover\:skew-x-1:hover {
  --transform-skew-x: 1deg;
}

.hover\:skew-x-2:hover {
  --transform-skew-x: 2deg;
}

.hover\:skew-x-3:hover {
  --transform-skew-x: 3deg;
}

.hover\:skew-x-6:hover {
  --transform-skew-x: 6deg;
}

.hover\:skew-x-12:hover {
  --transform-skew-x: 12deg;
}

.hover\:-skew-x-12:hover {
  --transform-skew-x: -12deg;
}

.hover\:-skew-x-6:hover {
  --transform-skew-x: -6deg;
}

.hover\:-skew-x-3:hover {
  --transform-skew-x: -3deg;
}

.hover\:-skew-x-2:hover {
  --transform-skew-x: -2deg;
}

.hover\:-skew-x-1:hover {
  --transform-skew-x: -1deg;
}

.hover\:skew-y-0:hover {
  --transform-skew-y: 0;
}

.hover\:skew-y-1:hover {
  --transform-skew-y: 1deg;
}

.hover\:skew-y-2:hover {
  --transform-skew-y: 2deg;
}

.hover\:skew-y-3:hover {
  --transform-skew-y: 3deg;
}

.hover\:skew-y-6:hover {
  --transform-skew-y: 6deg;
}

.hover\:skew-y-12:hover {
  --transform-skew-y: 12deg;
}

.hover\:-skew-y-12:hover {
  --transform-skew-y: -12deg;
}

.hover\:-skew-y-6:hover {
  --transform-skew-y: -6deg;
}

.hover\:-skew-y-3:hover {
  --transform-skew-y: -3deg;
}

.hover\:-skew-y-2:hover {
  --transform-skew-y: -2deg;
}

.hover\:-skew-y-1:hover {
  --transform-skew-y: -1deg;
}

.focus\:skew-x-0:focus {
  --transform-skew-x: 0;
}

.focus\:skew-x-1:focus {
  --transform-skew-x: 1deg;
}

.focus\:skew-x-2:focus {
  --transform-skew-x: 2deg;
}

.focus\:skew-x-3:focus {
  --transform-skew-x: 3deg;
}

.focus\:skew-x-6:focus {
  --transform-skew-x: 6deg;
}

.focus\:skew-x-12:focus {
  --transform-skew-x: 12deg;
}

.focus\:-skew-x-12:focus {
  --transform-skew-x: -12deg;
}

.focus\:-skew-x-6:focus {
  --transform-skew-x: -6deg;
}

.focus\:-skew-x-3:focus {
  --transform-skew-x: -3deg;
}

.focus\:-skew-x-2:focus {
  --transform-skew-x: -2deg;
}

.focus\:-skew-x-1:focus {
  --transform-skew-x: -1deg;
}

.focus\:skew-y-0:focus {
  --transform-skew-y: 0;
}

.focus\:skew-y-1:focus {
  --transform-skew-y: 1deg;
}

.focus\:skew-y-2:focus {
  --transform-skew-y: 2deg;
}

.focus\:skew-y-3:focus {
  --transform-skew-y: 3deg;
}

.focus\:skew-y-6:focus {
  --transform-skew-y: 6deg;
}

.focus\:skew-y-12:focus {
  --transform-skew-y: 12deg;
}

.focus\:-skew-y-12:focus {
  --transform-skew-y: -12deg;
}

.focus\:-skew-y-6:focus {
  --transform-skew-y: -6deg;
}

.focus\:-skew-y-3:focus {
  --transform-skew-y: -3deg;
}

.focus\:-skew-y-2:focus {
  --transform-skew-y: -2deg;
}

.focus\:-skew-y-1:focus {
  --transform-skew-y: -1deg;
}

.transition-none {
  transition-property: none;
}

.transition-all {
  transition-property: all;
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
}

.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
}

.transition-opacity {
  transition-property: opacity;
}

.transition-shadow {
  transition-property: box-shadow;
}

.transition-transform {
  transition-property: transform;
}

.ease-linear {
  transition-timing-function: linear;
}

.ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-75 {
  transition-duration: 75ms;
}

.duration-100 {
  transition-duration: .1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-700 {
  transition-duration: .7s;
}

.duration-1000 {
  transition-duration: 1s;
}

.delay-75 {
  transition-delay: 75ms;
}

.delay-100 {
  transition-delay: .1s;
}

.delay-150 {
  transition-delay: .15s;
}

.delay-200 {
  transition-delay: .2s;
}

.delay-300 {
  transition-delay: .3s;
}

.delay-500 {
  transition-delay: .5s;
}

.delay-700 {
  transition-delay: .7s;
}

.delay-1000 {
  transition-delay: 1s;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.animate-none {
  animation: none;
}

.animate-spin {
  animation: spin 1s linear infinite;
}

.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
}

.animate-bounce {
  animation: bounce 1s infinite;
}

@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }

  .sm\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(0px * var(--space-y-reverse) );
  }

  .sm\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse) );
    margin-left: calc(0px * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.25rem * var(--space-y-reverse) );
  }

  .sm\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.25rem * var(--space-x-reverse) );
    margin-left: calc(.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.5rem * var(--space-y-reverse) );
  }

  .sm\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.5rem * var(--space-x-reverse) );
    margin-left: calc(.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.75rem * var(--space-y-reverse) );
  }

  .sm\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.75rem * var(--space-x-reverse) );
    margin-left: calc(.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1rem * var(--space-y-reverse) );
  }

  .sm\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse) );
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.25rem * var(--space-y-reverse) );
  }

  .sm\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse) );
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.5rem * var(--space-y-reverse) );
  }

  .sm\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse) );
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.75rem * var(--space-y-reverse) );
  }

  .sm\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--space-x-reverse) );
    margin-left: calc(1.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2rem * var(--space-y-reverse) );
  }

  .sm\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse) );
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-9 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.25rem * var(--space-y-reverse) );
  }

  .sm\:space-x-9 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--space-x-reverse) );
    margin-left: calc(2.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.5rem * var(--space-y-reverse) );
  }

  .sm\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse) );
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-11 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.75rem * var(--space-y-reverse) );
  }

  .sm\:space-x-11 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--space-x-reverse) );
    margin-left: calc(2.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3rem * var(--space-y-reverse) );
  }

  .sm\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse) );
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-13 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.25rem * var(--space-y-reverse) );
  }

  .sm\:space-x-13 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.25rem * var(--space-x-reverse) );
    margin-left: calc(3.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.5rem * var(--space-y-reverse) );
  }

  .sm\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--space-x-reverse) );
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-15 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.75rem * var(--space-y-reverse) );
  }

  .sm\:space-x-15 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.75rem * var(--space-x-reverse) );
    margin-left: calc(3.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(4rem * var(--space-y-reverse) );
  }

  .sm\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse) );
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(5rem * var(--space-y-reverse) );
  }

  .sm\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse) );
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(6rem * var(--space-y-reverse) );
  }

  .sm\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse) );
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(7rem * var(--space-y-reverse) );
  }

  .sm\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(7rem * var(--space-x-reverse) );
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(8rem * var(--space-y-reverse) );
  }

  .sm\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse) );
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(9rem * var(--space-y-reverse) );
  }

  .sm\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(9rem * var(--space-x-reverse) );
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(10rem * var(--space-y-reverse) );
  }

  .sm\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse) );
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-44 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(11rem * var(--space-y-reverse) );
  }

  .sm\:space-x-44 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(11rem * var(--space-x-reverse) );
    margin-left: calc(11rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(12rem * var(--space-y-reverse) );
  }

  .sm\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse) );
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-52 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(13rem * var(--space-y-reverse) );
  }

  .sm\:space-x-52 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(13rem * var(--space-x-reverse) );
    margin-left: calc(13rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(14rem * var(--space-y-reverse) );
  }

  .sm\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse) );
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-60 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(15rem * var(--space-y-reverse) );
  }

  .sm\:space-x-60 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15rem * var(--space-x-reverse) );
    margin-left: calc(15rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16rem * var(--space-y-reverse) );
  }

  .sm\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse) );
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-72 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(18rem * var(--space-y-reverse) );
  }

  .sm\:space-x-72 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(18rem * var(--space-x-reverse) );
    margin-left: calc(18rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-80 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(20rem * var(--space-y-reverse) );
  }

  .sm\:space-x-80 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20rem * var(--space-x-reverse) );
    margin-left: calc(20rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-96 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(24rem * var(--space-y-reverse) );
  }

  .sm\:space-x-96 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(24rem * var(--space-x-reverse) );
    margin-left: calc(24rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1px * var(--space-y-reverse) );
  }

  .sm\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse) );
    margin-left: calc(1px * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-0\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.125rem * var(--space-y-reverse) );
  }

  .sm\:space-x-0\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.125rem * var(--space-x-reverse) );
    margin-left: calc(.125rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-1\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.375rem * var(--space-y-reverse) );
  }

  .sm\:space-x-1\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.375rem * var(--space-x-reverse) );
    margin-left: calc(.375rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-2\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.625rem * var(--space-y-reverse) );
  }

  .sm\:space-x-2\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.625rem * var(--space-x-reverse) );
    margin-left: calc(.625rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-3\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.875rem * var(--space-y-reverse) );
  }

  .sm\:space-x-3\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.875rem * var(--space-x-reverse) );
    margin-left: calc(.875rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-1\/2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .sm\:space-x-1\/2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-1\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .sm\:space-x-1\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-2\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .sm\:space-x-2\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-1\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(25% * var(--space-y-reverse) );
  }

  .sm\:space-x-1\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(25% * var(--space-x-reverse) );
    margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-2\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .sm\:space-x-2\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-3\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(75% * var(--space-y-reverse) );
  }

  .sm\:space-x-3\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75% * var(--space-x-reverse) );
    margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-1\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(20% * var(--space-y-reverse) );
  }

  .sm\:space-x-1\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20% * var(--space-x-reverse) );
    margin-left: calc(20% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-2\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(40% * var(--space-y-reverse) );
  }

  .sm\:space-x-2\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40% * var(--space-x-reverse) );
    margin-left: calc(40% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-3\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(60% * var(--space-y-reverse) );
  }

  .sm\:space-x-3\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60% * var(--space-x-reverse) );
    margin-left: calc(60% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-4\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(80% * var(--space-y-reverse) );
  }

  .sm\:space-x-4\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80% * var(--space-x-reverse) );
    margin-left: calc(80% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-1\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16.6667% * var(--space-y-reverse) );
  }

  .sm\:space-x-1\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16.6667% * var(--space-x-reverse) );
    margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-2\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .sm\:space-x-2\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-3\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .sm\:space-x-3\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-4\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .sm\:space-x-4\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-5\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(83.3333% * var(--space-y-reverse) );
  }

  .sm\:space-x-5\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(83.3333% * var(--space-x-reverse) );
    margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-1\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8.33333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(8.33333% * var(--space-y-reverse) );
  }

  .sm\:space-x-1\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8.33333% * var(--space-x-reverse) );
    margin-left: calc(8.33333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-2\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16.6667% * var(--space-y-reverse) );
  }

  .sm\:space-x-2\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16.6667% * var(--space-x-reverse) );
    margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-3\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(25% * var(--space-y-reverse) );
  }

  .sm\:space-x-3\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(25% * var(--space-x-reverse) );
    margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-4\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .sm\:space-x-4\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-5\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(41.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(41.6667% * var(--space-y-reverse) );
  }

  .sm\:space-x-5\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(41.6667% * var(--space-x-reverse) );
    margin-left: calc(41.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-6\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .sm\:space-x-6\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-7\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(58.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(58.3333% * var(--space-y-reverse) );
  }

  .sm\:space-x-7\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(58.3333% * var(--space-x-reverse) );
    margin-left: calc(58.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-8\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .sm\:space-x-8\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-9\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(75% * var(--space-y-reverse) );
  }

  .sm\:space-x-9\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75% * var(--space-x-reverse) );
    margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-10\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(83.3333% * var(--space-y-reverse) );
  }

  .sm\:space-x-10\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(83.3333% * var(--space-x-reverse) );
    margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-11\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(91.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(91.6667% * var(--space-y-reverse) );
  }

  .sm\:space-x-11\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(91.6667% * var(--space-x-reverse) );
    margin-left: calc(91.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-full > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(100% * var(--space-y-reverse) );
  }

  .sm\:space-x-full > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100% * var(--space-x-reverse) );
    margin-left: calc(100% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.25rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--space-x-reverse) );
    margin-left: calc(-.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.5rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--space-x-reverse) );
    margin-left: calc(-.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.75rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--space-x-reverse) );
    margin-left: calc(-.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse) );
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.25rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse) );
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.5rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse) );
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.75rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--space-x-reverse) );
    margin-left: calc(-1.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse) );
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-9 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.25rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-9 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--space-x-reverse) );
    margin-left: calc(-2.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.5rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse) );
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-11 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.75rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-11 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--space-x-reverse) );
    margin-left: calc(-2.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse) );
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-13 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.25rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-13 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.25rem * var(--space-x-reverse) );
    margin-left: calc(-3.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.5rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--space-x-reverse) );
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-15 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.75rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-15 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.75rem * var(--space-x-reverse) );
    margin-left: calc(-3.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-4rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse) );
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-5rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse) );
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-6rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse) );
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-7rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-7rem * var(--space-x-reverse) );
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-8rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse) );
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-9rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-9rem * var(--space-x-reverse) );
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-10rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse) );
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-44 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-11rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-44 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-11rem * var(--space-x-reverse) );
    margin-left: calc(-11rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-12rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse) );
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-52 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-13rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-52 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-13rem * var(--space-x-reverse) );
    margin-left: calc(-13rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-14rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse) );
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-60 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-15rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-60 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15rem * var(--space-x-reverse) );
    margin-left: calc(-15rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse) );
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-72 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-18rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-72 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-18rem * var(--space-x-reverse) );
    margin-left: calc(-18rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-80 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-20rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-80 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20rem * var(--space-x-reverse) );
    margin-left: calc(-20rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-96 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-24rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-96 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-24rem * var(--space-x-reverse) );
    margin-left: calc(-24rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1px * var(--space-y-reverse) );
  }

  .sm\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse) );
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-0\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.125rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-0\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--space-x-reverse) );
    margin-left: calc(-.125rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-1\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.375rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-1\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--space-x-reverse) );
    margin-left: calc(-.375rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-2\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.625rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-2\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--space-x-reverse) );
    margin-left: calc(-.625rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-3\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.875rem * var(--space-y-reverse) );
  }

  .sm\:-space-x-3\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--space-x-reverse) );
    margin-left: calc(-.875rem * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-1\/2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .sm\:-space-x-1\/2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-1\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .sm\:-space-x-1\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-2\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .sm\:-space-x-2\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-1\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-25% * var(--space-y-reverse) );
  }

  .sm\:-space-x-1\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-25% * var(--space-x-reverse) );
    margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-2\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .sm\:-space-x-2\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-3\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-75% * var(--space-y-reverse) );
  }

  .sm\:-space-x-3\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75% * var(--space-x-reverse) );
    margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-1\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-20% * var(--space-y-reverse) );
  }

  .sm\:-space-x-1\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20% * var(--space-x-reverse) );
    margin-left: calc(-20% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-2\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-40% * var(--space-y-reverse) );
  }

  .sm\:-space-x-2\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40% * var(--space-x-reverse) );
    margin-left: calc(-40% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-3\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-60% * var(--space-y-reverse) );
  }

  .sm\:-space-x-3\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60% * var(--space-x-reverse) );
    margin-left: calc(-60% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-4\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-80% * var(--space-y-reverse) );
  }

  .sm\:-space-x-4\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80% * var(--space-x-reverse) );
    margin-left: calc(-80% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-1\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
  }

  .sm\:-space-x-1\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16.6667% * var(--space-x-reverse) );
    margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-2\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .sm\:-space-x-2\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-3\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .sm\:-space-x-3\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-4\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .sm\:-space-x-4\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-5\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
  }

  .sm\:-space-x-5\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-83.3333% * var(--space-x-reverse) );
    margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-1\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8.33333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-8.33333% * var(--space-y-reverse) );
  }

  .sm\:-space-x-1\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8.33333% * var(--space-x-reverse) );
    margin-left: calc(-8.33333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-2\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
  }

  .sm\:-space-x-2\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16.6667% * var(--space-x-reverse) );
    margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-3\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-25% * var(--space-y-reverse) );
  }

  .sm\:-space-x-3\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-25% * var(--space-x-reverse) );
    margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-4\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .sm\:-space-x-4\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-5\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-41.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-41.6667% * var(--space-y-reverse) );
  }

  .sm\:-space-x-5\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-41.6667% * var(--space-x-reverse) );
    margin-left: calc(-41.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-6\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .sm\:-space-x-6\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-7\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-58.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-58.3333% * var(--space-y-reverse) );
  }

  .sm\:-space-x-7\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-58.3333% * var(--space-x-reverse) );
    margin-left: calc(-58.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-8\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .sm\:-space-x-8\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-9\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-75% * var(--space-y-reverse) );
  }

  .sm\:-space-x-9\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75% * var(--space-x-reverse) );
    margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-10\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
  }

  .sm\:-space-x-10\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-83.3333% * var(--space-x-reverse) );
    margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-11\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-91.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-91.6667% * var(--space-y-reverse) );
  }

  .sm\:-space-x-11\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-91.6667% * var(--space-x-reverse) );
    margin-left: calc(-91.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:-space-y-full > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-100% * var(--space-y-reverse) );
  }

  .sm\:-space-x-full > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100% * var(--space-x-reverse) );
    margin-left: calc(-100% * calc(1 - var(--space-x-reverse) ) );
  }

  .sm\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .sm\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .sm\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(0px * var(--divide-y-reverse) );
  }

  .sm\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse) );
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse) ) );
  }

  .sm\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(2px * var(--divide-y-reverse) );
  }

  .sm\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse) );
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse) ) );
  }

  .sm\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(4px * var(--divide-y-reverse) );
  }

  .sm\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse) );
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse) ) );
  }

  .sm\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(8px * var(--divide-y-reverse) );
  }

  .sm\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse) );
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse) ) );
  }

  .sm\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(1px * var(--divide-y-reverse) );
  }

  .sm\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse) );
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse) ) );
  }

  .sm\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .sm\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .sm\:divide-transparent > :not(template) ~ :not(template) {
    border-color: #0000;
  }

  .sm\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity) );
  }

  .sm\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity) );
  }

  .sm\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--divide-opacity) );
  }

  .sm\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--divide-opacity) );
  }

  .sm\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--divide-opacity) );
  }

  .sm\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--divide-opacity) );
  }

  .sm\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--divide-opacity) );
  }

  .sm\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--divide-opacity) );
  }

  .sm\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--divide-opacity) );
  }

  .sm\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--divide-opacity) );
  }

  .sm\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--divide-opacity) );
  }

  .sm\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--divide-opacity) );
  }

  .sm\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--divide-opacity) );
  }

  .sm\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--divide-opacity) );
  }

  .sm\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--divide-opacity) );
  }

  .sm\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--divide-opacity) );
  }

  .sm\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--divide-opacity) );
  }

  .sm\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--divide-opacity) );
  }

  .sm\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--divide-opacity) );
  }

  .sm\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--divide-opacity) );
  }

  .sm\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--divide-opacity) );
  }

  .sm\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--divide-opacity) );
  }

  .sm\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--divide-opacity) );
  }

  .sm\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--divide-opacity) );
  }

  .sm\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--divide-opacity) );
  }

  .sm\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--divide-opacity) );
  }

  .sm\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--divide-opacity) );
  }

  .sm\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--divide-opacity) );
  }

  .sm\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--divide-opacity) );
  }

  .sm\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--divide-opacity) );
  }

  .sm\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--divide-opacity) );
  }

  .sm\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--divide-opacity) );
  }

  .sm\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--divide-opacity) );
  }

  .sm\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--divide-opacity) );
  }

  .sm\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--divide-opacity) );
  }

  .sm\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--divide-opacity) );
  }

  .sm\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--divide-opacity) );
  }

  .sm\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--divide-opacity) );
  }

  .sm\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--divide-opacity) );
  }

  .sm\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--divide-opacity) );
  }

  .sm\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--divide-opacity) );
  }

  .sm\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--divide-opacity) );
  }

  .sm\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--divide-opacity) );
  }

  .sm\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--divide-opacity) );
  }

  .sm\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--divide-opacity) );
  }

  .sm\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--divide-opacity) );
  }

  .sm\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--divide-opacity) );
  }

  .sm\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--divide-opacity) );
  }

  .sm\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--divide-opacity) );
  }

  .sm\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--divide-opacity) );
  }

  .sm\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--divide-opacity) );
  }

  .sm\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--divide-opacity) );
  }

  .sm\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--divide-opacity) );
  }

  .sm\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--divide-opacity) );
  }

  .sm\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--divide-opacity) );
  }

  .sm\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--divide-opacity) );
  }

  .sm\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--divide-opacity) );
  }

  .sm\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--divide-opacity) );
  }

  .sm\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--divide-opacity) );
  }

  .sm\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--divide-opacity) );
  }

  .sm\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--divide-opacity) );
  }

  .sm\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--divide-opacity) );
  }

  .sm\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--divide-opacity) );
  }

  .sm\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--divide-opacity) );
  }

  .sm\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--divide-opacity) );
  }

  .sm\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--divide-opacity) );
  }

  .sm\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--divide-opacity) );
  }

  .sm\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--divide-opacity) );
  }

  .sm\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--divide-opacity) );
  }

  .sm\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--divide-opacity) );
  }

  .sm\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--divide-opacity) );
  }

  .sm\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--divide-opacity) );
  }

  .sm\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--divide-opacity) );
  }

  .sm\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--divide-opacity) );
  }

  .sm\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--divide-opacity) );
  }

  .sm\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--divide-opacity) );
  }

  .sm\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--divide-opacity) );
  }

  .sm\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--divide-opacity) );
  }

  .sm\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--divide-opacity) );
  }

  .sm\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--divide-opacity) );
  }

  .sm\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--divide-opacity) );
  }

  .sm\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--divide-opacity) );
  }

  .sm\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--divide-opacity) );
  }

  .sm\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--divide-opacity) );
  }

  .sm\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--divide-opacity) );
  }

  .sm\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--divide-opacity) );
  }

  .sm\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--divide-opacity) );
  }

  .sm\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--divide-opacity) );
  }

  .sm\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--divide-opacity) );
  }

  .sm\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--divide-opacity) );
  }

  .sm\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--divide-opacity) );
  }

  .sm\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--divide-opacity) );
  }

  .sm\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--divide-opacity) );
  }

  .sm\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--divide-opacity) );
  }

  .sm\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--divide-opacity) );
  }

  .sm\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--divide-opacity) );
  }

  .sm\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--divide-opacity) );
  }

  .sm\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--divide-opacity) );
  }

  .sm\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--divide-opacity) );
  }

  .sm\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--divide-opacity) );
  }

  .sm\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--divide-opacity) );
  }

  .sm\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--divide-opacity) );
  }

  .sm\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--divide-opacity) );
  }

  .sm\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .sm\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .sm\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .sm\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .sm\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .sm\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .sm\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: .25;
  }

  .sm\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: .5;
  }

  .sm\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: .75;
  }

  .sm\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .sm\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .sm\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .sm\:appearance-none {
    appearance: none;
  }

  .sm\:bg-fixed {
    background-attachment: fixed;
  }

  .sm\:bg-local {
    background-attachment: local;
  }

  .sm\:bg-scroll {
    background-attachment: scroll;
  }

  .sm\:bg-clip-border {
    background-clip: border-box;
  }

  .sm\:bg-clip-padding {
    background-clip: padding-box;
  }

  .sm\:bg-clip-content {
    background-clip: content-box;
  }

  .sm\:bg-clip-text {
    background-clip: text;
  }

  .sm\:bg-transparent {
    background-color: #0000;
  }

  .sm\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .sm\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .sm\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .sm\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .sm\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .sm\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .sm\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .sm\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .sm\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .sm\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .sm\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .sm\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .sm\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .sm\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .sm\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .sm\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .sm\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .sm\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .sm\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .sm\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .sm\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .sm\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .sm\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .sm\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .sm\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .sm\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .sm\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .sm\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .sm\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .sm\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .sm\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .sm\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .sm\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .sm\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .sm\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .sm\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .sm\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .sm\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .sm\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .sm\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .sm\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .sm\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .sm\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .sm\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .sm\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .sm\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .sm\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .sm\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .sm\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .sm\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .sm\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .sm\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .sm\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .sm\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .sm\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .sm\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .sm\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .sm\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .sm\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .sm\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .sm\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .sm\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .sm\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .sm\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .sm\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .sm\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .sm\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .sm\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .sm\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .sm\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .sm\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .sm\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .sm\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .sm\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .sm\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .sm\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .sm\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .sm\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .sm\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .sm\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .sm\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .sm\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .sm\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .sm\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .sm\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .sm\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .sm\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .sm\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .sm\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .sm\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .sm\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .sm\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .sm\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .sm\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .sm\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .sm\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .sm\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .sm\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .sm\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .sm\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .sm\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .sm\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .sm\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .sm\:group-hover\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .group:hover .sm\:group-hover\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-transparent {
    background-color: #0000;
  }

  .group:focus .sm\:group-focus\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .group:focus .sm\:group-focus\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .sm\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .sm\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .sm\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-50:hover {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .sm\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-50:hover {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-300:hover {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-400:hover {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-500:hover {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-600:hover {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-700:hover {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-800:hover {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .sm\:hover\:bg-cool-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .sm\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-50:hover {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .sm\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-50:hover {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .sm\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-50:hover {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .sm\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-50:hover {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .sm\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-50:hover {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .sm\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-50:hover {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .sm\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-50:hover {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .sm\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .sm\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .sm\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .sm\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .sm\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-50:focus {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .sm\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-50:focus {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-300:focus {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-400:focus {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-500:focus {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-600:focus {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-700:focus {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-800:focus {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .sm\:focus\:bg-cool-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .sm\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-50:focus {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .sm\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-50:focus {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .sm\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-50:focus {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .sm\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-50:focus {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .sm\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-50:focus {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .sm\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-50:focus {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .sm\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-50:focus {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .sm\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .sm\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .sm\:active\:bg-transparent:active {
    background-color: #0000;
  }

  .sm\:active\:bg-white:active {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .sm\:active\:bg-black:active {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-50:active {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-100:active {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-200:active {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-300:active {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-400:active {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-500:active {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-600:active {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-700:active {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-800:active {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .sm\:active\:bg-gray-900:active {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-50:active {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-100:active {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-200:active {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-300:active {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-400:active {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-500:active {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-600:active {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-700:active {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-800:active {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .sm\:active\:bg-cool-gray-900:active {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-100:active {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-200:active {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-300:active {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-400:active {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-500:active {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-600:active {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-700:active {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-800:active {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .sm\:active\:bg-red-900:active {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-50:active {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-100:active {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-200:active {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-300:active {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-400:active {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-500:active {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-600:active {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-700:active {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-800:active {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .sm\:active\:bg-orange-900:active {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-50:active {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-100:active {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-200:active {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-300:active {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-400:active {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-500:active {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-600:active {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-700:active {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-800:active {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .sm\:active\:bg-yellow-900:active {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-50:active {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-100:active {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-200:active {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-300:active {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-400:active {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-500:active {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-600:active {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-700:active {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-800:active {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .sm\:active\:bg-green-900:active {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-50:active {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-100:active {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-200:active {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-300:active {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-400:active {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-500:active {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-600:active {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-700:active {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-800:active {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .sm\:active\:bg-teal-900:active {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-50:active {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-100:active {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-200:active {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-300:active {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-400:active {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-500:active {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-600:active {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-700:active {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-800:active {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .sm\:active\:bg-blue-900:active {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-50:active {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-100:active {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-200:active {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-300:active {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-400:active {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-500:active {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-600:active {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-700:active {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-800:active {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .sm\:active\:bg-indigo-900:active {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-50:active {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-100:active {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-200:active {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-300:active {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-400:active {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-500:active {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-600:active {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-700:active {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-800:active {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .sm\:active\:bg-purple-900:active {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-100:active {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-200:active {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-300:active {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-400:active {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-500:active {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-600:active {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-700:active {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-800:active {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .sm\:active\:bg-pink-900:active {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .sm\:bg-none {
    background-image: none;
  }

  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops) );
  }

  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops) );
  }

  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops) );
  }

  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops) );
  }

  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops) );
  }

  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops) );
  }

  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops) );
  }

  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops) );
  }

  .sm\:from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .sm\:from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .sm\:from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .sm\:from-gray-50 {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .sm\:from-gray-100 {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .sm\:from-gray-200 {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .sm\:from-gray-300 {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .sm\:from-gray-400 {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .sm\:from-gray-500 {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .sm\:from-gray-600 {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .sm\:from-gray-700 {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .sm\:from-gray-800 {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .sm\:from-gray-900 {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .sm\:from-cool-gray-50 {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .sm\:from-cool-gray-100 {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .sm\:from-cool-gray-200 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .sm\:from-cool-gray-300 {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .sm\:from-cool-gray-400 {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .sm\:from-cool-gray-500 {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .sm\:from-cool-gray-600 {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .sm\:from-cool-gray-700 {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .sm\:from-cool-gray-800 {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .sm\:from-cool-gray-900 {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .sm\:from-red-50 {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .sm\:from-red-100 {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .sm\:from-red-200 {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .sm\:from-red-300 {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .sm\:from-red-400 {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .sm\:from-red-500 {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .sm\:from-red-600 {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .sm\:from-red-700 {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .sm\:from-red-800 {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .sm\:from-red-900 {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .sm\:from-orange-50 {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .sm\:from-orange-100 {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .sm\:from-orange-200 {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .sm\:from-orange-300 {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .sm\:from-orange-400 {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .sm\:from-orange-500 {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .sm\:from-orange-600 {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .sm\:from-orange-700 {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .sm\:from-orange-800 {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .sm\:from-orange-900 {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .sm\:from-yellow-50 {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .sm\:from-yellow-100 {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .sm\:from-yellow-200 {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .sm\:from-yellow-300 {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .sm\:from-yellow-400 {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .sm\:from-yellow-500 {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .sm\:from-yellow-600 {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .sm\:from-yellow-700 {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .sm\:from-yellow-800 {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .sm\:from-yellow-900 {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .sm\:from-green-50 {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .sm\:from-green-100 {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .sm\:from-green-200 {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .sm\:from-green-300 {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .sm\:from-green-400 {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .sm\:from-green-500 {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .sm\:from-green-600 {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .sm\:from-green-700 {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .sm\:from-green-800 {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .sm\:from-green-900 {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .sm\:from-teal-50 {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .sm\:from-teal-100 {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .sm\:from-teal-200 {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .sm\:from-teal-300 {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .sm\:from-teal-400 {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .sm\:from-teal-500 {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .sm\:from-teal-600 {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .sm\:from-teal-700 {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .sm\:from-teal-800 {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .sm\:from-teal-900 {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .sm\:from-blue-50 {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .sm\:from-blue-100 {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .sm\:from-blue-200 {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .sm\:from-blue-300 {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .sm\:from-blue-400 {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .sm\:from-blue-500 {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .sm\:from-blue-600 {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .sm\:from-blue-700 {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .sm\:from-blue-800 {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .sm\:from-blue-900 {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .sm\:from-indigo-50 {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .sm\:from-indigo-100 {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .sm\:from-indigo-200 {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .sm\:from-indigo-300 {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .sm\:from-indigo-400 {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .sm\:from-indigo-500 {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .sm\:from-indigo-600 {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .sm\:from-indigo-700 {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .sm\:from-indigo-800 {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .sm\:from-indigo-900 {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .sm\:from-purple-50 {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .sm\:from-purple-100 {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .sm\:from-purple-200 {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .sm\:from-purple-300 {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .sm\:from-purple-400 {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .sm\:from-purple-500 {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .sm\:from-purple-600 {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .sm\:from-purple-700 {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .sm\:from-purple-800 {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .sm\:from-purple-900 {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .sm\:from-pink-50 {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .sm\:from-pink-100 {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .sm\:from-pink-200 {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .sm\:from-pink-300 {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .sm\:from-pink-400 {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .sm\:from-pink-500 {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .sm\:from-pink-600 {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .sm\:from-pink-700 {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .sm\:from-pink-800 {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .sm\:from-pink-900 {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .sm\:via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .sm\:via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .sm\:via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .sm\:via-gray-50 {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .sm\:via-gray-100 {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .sm\:via-gray-200 {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .sm\:via-gray-300 {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .sm\:via-gray-400 {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .sm\:via-gray-500 {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .sm\:via-gray-600 {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .sm\:via-gray-700 {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .sm\:via-gray-800 {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .sm\:via-gray-900 {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .sm\:via-cool-gray-50 {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .sm\:via-cool-gray-100 {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .sm\:via-cool-gray-200 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .sm\:via-cool-gray-300 {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .sm\:via-cool-gray-400 {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .sm\:via-cool-gray-500 {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .sm\:via-cool-gray-600 {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .sm\:via-cool-gray-700 {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .sm\:via-cool-gray-800 {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .sm\:via-cool-gray-900 {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .sm\:via-red-50 {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .sm\:via-red-100 {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .sm\:via-red-200 {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .sm\:via-red-300 {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .sm\:via-red-400 {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .sm\:via-red-500 {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .sm\:via-red-600 {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .sm\:via-red-700 {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .sm\:via-red-800 {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .sm\:via-red-900 {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .sm\:via-orange-50 {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .sm\:via-orange-100 {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .sm\:via-orange-200 {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .sm\:via-orange-300 {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .sm\:via-orange-400 {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .sm\:via-orange-500 {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .sm\:via-orange-600 {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .sm\:via-orange-700 {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .sm\:via-orange-800 {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .sm\:via-orange-900 {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .sm\:via-yellow-50 {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .sm\:via-yellow-100 {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .sm\:via-yellow-200 {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .sm\:via-yellow-300 {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .sm\:via-yellow-400 {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .sm\:via-yellow-500 {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .sm\:via-yellow-600 {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .sm\:via-yellow-700 {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .sm\:via-yellow-800 {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .sm\:via-yellow-900 {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .sm\:via-green-50 {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .sm\:via-green-100 {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .sm\:via-green-200 {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .sm\:via-green-300 {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .sm\:via-green-400 {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .sm\:via-green-500 {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .sm\:via-green-600 {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .sm\:via-green-700 {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .sm\:via-green-800 {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .sm\:via-green-900 {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .sm\:via-teal-50 {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .sm\:via-teal-100 {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .sm\:via-teal-200 {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .sm\:via-teal-300 {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .sm\:via-teal-400 {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .sm\:via-teal-500 {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .sm\:via-teal-600 {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .sm\:via-teal-700 {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .sm\:via-teal-800 {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .sm\:via-teal-900 {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .sm\:via-blue-50 {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .sm\:via-blue-100 {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .sm\:via-blue-200 {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .sm\:via-blue-300 {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .sm\:via-blue-400 {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .sm\:via-blue-500 {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .sm\:via-blue-600 {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .sm\:via-blue-700 {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .sm\:via-blue-800 {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .sm\:via-blue-900 {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .sm\:via-indigo-50 {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .sm\:via-indigo-100 {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .sm\:via-indigo-200 {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .sm\:via-indigo-300 {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .sm\:via-indigo-400 {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .sm\:via-indigo-500 {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .sm\:via-indigo-600 {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .sm\:via-indigo-700 {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .sm\:via-indigo-800 {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .sm\:via-indigo-900 {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .sm\:via-purple-50 {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .sm\:via-purple-100 {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .sm\:via-purple-200 {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .sm\:via-purple-300 {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .sm\:via-purple-400 {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .sm\:via-purple-500 {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .sm\:via-purple-600 {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .sm\:via-purple-700 {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .sm\:via-purple-800 {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .sm\:via-purple-900 {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .sm\:via-pink-50 {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .sm\:via-pink-100 {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .sm\:via-pink-200 {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .sm\:via-pink-300 {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .sm\:via-pink-400 {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .sm\:via-pink-500 {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .sm\:via-pink-600 {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .sm\:via-pink-700 {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .sm\:via-pink-800 {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .sm\:via-pink-900 {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .sm\:to-transparent {
    --gradient-to-color: transparent;
  }

  .sm\:to-white {
    --gradient-to-color: #fff;
  }

  .sm\:to-black {
    --gradient-to-color: #000;
  }

  .sm\:to-gray-50 {
    --gradient-to-color: #f9fafb;
  }

  .sm\:to-gray-100 {
    --gradient-to-color: #f4f5f7;
  }

  .sm\:to-gray-200 {
    --gradient-to-color: #e5e7eb;
  }

  .sm\:to-gray-300 {
    --gradient-to-color: #d2d6dc;
  }

  .sm\:to-gray-400 {
    --gradient-to-color: #9fa6b2;
  }

  .sm\:to-gray-500 {
    --gradient-to-color: #6b7280;
  }

  .sm\:to-gray-600 {
    --gradient-to-color: #4b5563;
  }

  .sm\:to-gray-700 {
    --gradient-to-color: #374151;
  }

  .sm\:to-gray-800 {
    --gradient-to-color: #252f3f;
  }

  .sm\:to-gray-900 {
    --gradient-to-color: #161e2e;
  }

  .sm\:to-cool-gray-50 {
    --gradient-to-color: #fbfdfe;
  }

  .sm\:to-cool-gray-100 {
    --gradient-to-color: #f1f5f9;
  }

  .sm\:to-cool-gray-200 {
    --gradient-to-color: #e2e8f0;
  }

  .sm\:to-cool-gray-300 {
    --gradient-to-color: #cfd8e3;
  }

  .sm\:to-cool-gray-400 {
    --gradient-to-color: #97a6ba;
  }

  .sm\:to-cool-gray-500 {
    --gradient-to-color: #64748b;
  }

  .sm\:to-cool-gray-600 {
    --gradient-to-color: #475569;
  }

  .sm\:to-cool-gray-700 {
    --gradient-to-color: #364152;
  }

  .sm\:to-cool-gray-800 {
    --gradient-to-color: #27303f;
  }

  .sm\:to-cool-gray-900 {
    --gradient-to-color: #1a202e;
  }

  .sm\:to-red-50 {
    --gradient-to-color: #fdf2f2;
  }

  .sm\:to-red-100 {
    --gradient-to-color: #fde8e8;
  }

  .sm\:to-red-200 {
    --gradient-to-color: #fbd5d5;
  }

  .sm\:to-red-300 {
    --gradient-to-color: #f8b4b4;
  }

  .sm\:to-red-400 {
    --gradient-to-color: #f98080;
  }

  .sm\:to-red-500 {
    --gradient-to-color: #f05252;
  }

  .sm\:to-red-600 {
    --gradient-to-color: #e02424;
  }

  .sm\:to-red-700 {
    --gradient-to-color: #c81e1e;
  }

  .sm\:to-red-800 {
    --gradient-to-color: #9b1c1c;
  }

  .sm\:to-red-900 {
    --gradient-to-color: #771d1d;
  }

  .sm\:to-orange-50 {
    --gradient-to-color: #fff8f1;
  }

  .sm\:to-orange-100 {
    --gradient-to-color: #feecdc;
  }

  .sm\:to-orange-200 {
    --gradient-to-color: #fcd9bd;
  }

  .sm\:to-orange-300 {
    --gradient-to-color: #fdba8c;
  }

  .sm\:to-orange-400 {
    --gradient-to-color: #ff8a4c;
  }

  .sm\:to-orange-500 {
    --gradient-to-color: #ff5a1f;
  }

  .sm\:to-orange-600 {
    --gradient-to-color: #d03801;
  }

  .sm\:to-orange-700 {
    --gradient-to-color: #b43403;
  }

  .sm\:to-orange-800 {
    --gradient-to-color: #8a2c0d;
  }

  .sm\:to-orange-900 {
    --gradient-to-color: #73230d;
  }

  .sm\:to-yellow-50 {
    --gradient-to-color: #fdfdea;
  }

  .sm\:to-yellow-100 {
    --gradient-to-color: #fdf6b2;
  }

  .sm\:to-yellow-200 {
    --gradient-to-color: #fce96a;
  }

  .sm\:to-yellow-300 {
    --gradient-to-color: #faca15;
  }

  .sm\:to-yellow-400 {
    --gradient-to-color: #e3a008;
  }

  .sm\:to-yellow-500 {
    --gradient-to-color: #c27803;
  }

  .sm\:to-yellow-600 {
    --gradient-to-color: #9f580a;
  }

  .sm\:to-yellow-700 {
    --gradient-to-color: #8e4b10;
  }

  .sm\:to-yellow-800 {
    --gradient-to-color: #723b13;
  }

  .sm\:to-yellow-900 {
    --gradient-to-color: #633112;
  }

  .sm\:to-green-50 {
    --gradient-to-color: #f3faf7;
  }

  .sm\:to-green-100 {
    --gradient-to-color: #def7ec;
  }

  .sm\:to-green-200 {
    --gradient-to-color: #bcf0da;
  }

  .sm\:to-green-300 {
    --gradient-to-color: #84e1bc;
  }

  .sm\:to-green-400 {
    --gradient-to-color: #31c48d;
  }

  .sm\:to-green-500 {
    --gradient-to-color: #0e9f6e;
  }

  .sm\:to-green-600 {
    --gradient-to-color: #057a55;
  }

  .sm\:to-green-700 {
    --gradient-to-color: #046c4e;
  }

  .sm\:to-green-800 {
    --gradient-to-color: #03543f;
  }

  .sm\:to-green-900 {
    --gradient-to-color: #014737;
  }

  .sm\:to-teal-50 {
    --gradient-to-color: #edfafa;
  }

  .sm\:to-teal-100 {
    --gradient-to-color: #d5f5f6;
  }

  .sm\:to-teal-200 {
    --gradient-to-color: #afecef;
  }

  .sm\:to-teal-300 {
    --gradient-to-color: #7edce2;
  }

  .sm\:to-teal-400 {
    --gradient-to-color: #16bdca;
  }

  .sm\:to-teal-500 {
    --gradient-to-color: #0694a2;
  }

  .sm\:to-teal-600 {
    --gradient-to-color: #047481;
  }

  .sm\:to-teal-700 {
    --gradient-to-color: #036672;
  }

  .sm\:to-teal-800 {
    --gradient-to-color: #05505c;
  }

  .sm\:to-teal-900 {
    --gradient-to-color: #014451;
  }

  .sm\:to-blue-50 {
    --gradient-to-color: #ebf5ff;
  }

  .sm\:to-blue-100 {
    --gradient-to-color: #e1effe;
  }

  .sm\:to-blue-200 {
    --gradient-to-color: #c3ddfd;
  }

  .sm\:to-blue-300 {
    --gradient-to-color: #a4cafe;
  }

  .sm\:to-blue-400 {
    --gradient-to-color: #76a9fa;
  }

  .sm\:to-blue-500 {
    --gradient-to-color: #3f83f8;
  }

  .sm\:to-blue-600 {
    --gradient-to-color: #1c64f2;
  }

  .sm\:to-blue-700 {
    --gradient-to-color: #1a56db;
  }

  .sm\:to-blue-800 {
    --gradient-to-color: #1e429f;
  }

  .sm\:to-blue-900 {
    --gradient-to-color: #233876;
  }

  .sm\:to-indigo-50 {
    --gradient-to-color: #f0f5ff;
  }

  .sm\:to-indigo-100 {
    --gradient-to-color: #e5edff;
  }

  .sm\:to-indigo-200 {
    --gradient-to-color: #cddbfe;
  }

  .sm\:to-indigo-300 {
    --gradient-to-color: #b4c6fc;
  }

  .sm\:to-indigo-400 {
    --gradient-to-color: #8da2fb;
  }

  .sm\:to-indigo-500 {
    --gradient-to-color: #6875f5;
  }

  .sm\:to-indigo-600 {
    --gradient-to-color: #5850ec;
  }

  .sm\:to-indigo-700 {
    --gradient-to-color: #5145cd;
  }

  .sm\:to-indigo-800 {
    --gradient-to-color: #42389d;
  }

  .sm\:to-indigo-900 {
    --gradient-to-color: #362f78;
  }

  .sm\:to-purple-50 {
    --gradient-to-color: #f6f5ff;
  }

  .sm\:to-purple-100 {
    --gradient-to-color: #edebfe;
  }

  .sm\:to-purple-200 {
    --gradient-to-color: #dcd7fe;
  }

  .sm\:to-purple-300 {
    --gradient-to-color: #cabffd;
  }

  .sm\:to-purple-400 {
    --gradient-to-color: #ac94fa;
  }

  .sm\:to-purple-500 {
    --gradient-to-color: #9061f9;
  }

  .sm\:to-purple-600 {
    --gradient-to-color: #7e3af2;
  }

  .sm\:to-purple-700 {
    --gradient-to-color: #6c2bd9;
  }

  .sm\:to-purple-800 {
    --gradient-to-color: #5521b5;
  }

  .sm\:to-purple-900 {
    --gradient-to-color: #4a1d96;
  }

  .sm\:to-pink-50 {
    --gradient-to-color: #fdf2f8;
  }

  .sm\:to-pink-100 {
    --gradient-to-color: #fce8f3;
  }

  .sm\:to-pink-200 {
    --gradient-to-color: #fad1e8;
  }

  .sm\:to-pink-300 {
    --gradient-to-color: #f8b4d9;
  }

  .sm\:to-pink-400 {
    --gradient-to-color: #f17eb8;
  }

  .sm\:to-pink-500 {
    --gradient-to-color: #e74694;
  }

  .sm\:to-pink-600 {
    --gradient-to-color: #d61f69;
  }

  .sm\:to-pink-700 {
    --gradient-to-color: #bf125d;
  }

  .sm\:to-pink-800 {
    --gradient-to-color: #99154b;
  }

  .sm\:to-pink-900 {
    --gradient-to-color: #751a3d;
  }

  .sm\:hover\:from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .sm\:hover\:from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .sm\:hover\:from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .sm\:hover\:from-gray-50:hover {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .sm\:hover\:from-gray-100:hover {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .sm\:hover\:from-gray-200:hover {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .sm\:hover\:from-gray-300:hover {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .sm\:hover\:from-gray-400:hover {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .sm\:hover\:from-gray-500:hover {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .sm\:hover\:from-gray-600:hover {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .sm\:hover\:from-gray-700:hover {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .sm\:hover\:from-gray-800:hover {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .sm\:hover\:from-gray-900:hover {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .sm\:hover\:from-cool-gray-50:hover {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .sm\:hover\:from-cool-gray-100:hover {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .sm\:hover\:from-cool-gray-200:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .sm\:hover\:from-cool-gray-300:hover {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .sm\:hover\:from-cool-gray-400:hover {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .sm\:hover\:from-cool-gray-500:hover {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .sm\:hover\:from-cool-gray-600:hover {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .sm\:hover\:from-cool-gray-700:hover {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .sm\:hover\:from-cool-gray-800:hover {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .sm\:hover\:from-cool-gray-900:hover {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .sm\:hover\:from-red-50:hover {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .sm\:hover\:from-red-100:hover {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .sm\:hover\:from-red-200:hover {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .sm\:hover\:from-red-300:hover {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .sm\:hover\:from-red-400:hover {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .sm\:hover\:from-red-500:hover {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .sm\:hover\:from-red-600:hover {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .sm\:hover\:from-red-700:hover {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .sm\:hover\:from-red-800:hover {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .sm\:hover\:from-red-900:hover {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .sm\:hover\:from-orange-50:hover {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .sm\:hover\:from-orange-100:hover {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .sm\:hover\:from-orange-200:hover {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .sm\:hover\:from-orange-300:hover {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .sm\:hover\:from-orange-400:hover {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .sm\:hover\:from-orange-500:hover {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .sm\:hover\:from-orange-600:hover {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .sm\:hover\:from-orange-700:hover {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .sm\:hover\:from-orange-800:hover {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .sm\:hover\:from-orange-900:hover {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .sm\:hover\:from-yellow-50:hover {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .sm\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .sm\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .sm\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .sm\:hover\:from-yellow-400:hover {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .sm\:hover\:from-yellow-500:hover {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .sm\:hover\:from-yellow-600:hover {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .sm\:hover\:from-yellow-700:hover {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .sm\:hover\:from-yellow-800:hover {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .sm\:hover\:from-yellow-900:hover {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .sm\:hover\:from-green-50:hover {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .sm\:hover\:from-green-100:hover {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .sm\:hover\:from-green-200:hover {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .sm\:hover\:from-green-300:hover {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .sm\:hover\:from-green-400:hover {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .sm\:hover\:from-green-500:hover {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .sm\:hover\:from-green-600:hover {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .sm\:hover\:from-green-700:hover {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .sm\:hover\:from-green-800:hover {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .sm\:hover\:from-green-900:hover {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .sm\:hover\:from-teal-50:hover {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .sm\:hover\:from-teal-100:hover {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .sm\:hover\:from-teal-200:hover {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .sm\:hover\:from-teal-300:hover {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .sm\:hover\:from-teal-400:hover {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .sm\:hover\:from-teal-500:hover {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .sm\:hover\:from-teal-600:hover {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .sm\:hover\:from-teal-700:hover {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .sm\:hover\:from-teal-800:hover {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .sm\:hover\:from-teal-900:hover {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .sm\:hover\:from-blue-50:hover {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .sm\:hover\:from-blue-100:hover {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .sm\:hover\:from-blue-200:hover {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .sm\:hover\:from-blue-300:hover {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .sm\:hover\:from-blue-400:hover {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .sm\:hover\:from-blue-500:hover {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .sm\:hover\:from-blue-600:hover {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .sm\:hover\:from-blue-700:hover {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .sm\:hover\:from-blue-800:hover {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .sm\:hover\:from-blue-900:hover {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .sm\:hover\:from-indigo-50:hover {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .sm\:hover\:from-indigo-100:hover {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .sm\:hover\:from-indigo-200:hover {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .sm\:hover\:from-indigo-300:hover {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .sm\:hover\:from-indigo-400:hover {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .sm\:hover\:from-indigo-500:hover {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .sm\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .sm\:hover\:from-indigo-700:hover {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .sm\:hover\:from-indigo-800:hover {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .sm\:hover\:from-indigo-900:hover {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .sm\:hover\:from-purple-50:hover {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .sm\:hover\:from-purple-100:hover {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .sm\:hover\:from-purple-200:hover {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .sm\:hover\:from-purple-300:hover {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .sm\:hover\:from-purple-400:hover {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .sm\:hover\:from-purple-500:hover {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .sm\:hover\:from-purple-600:hover {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .sm\:hover\:from-purple-700:hover {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .sm\:hover\:from-purple-800:hover {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .sm\:hover\:from-purple-900:hover {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .sm\:hover\:from-pink-50:hover {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .sm\:hover\:from-pink-100:hover {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .sm\:hover\:from-pink-200:hover {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .sm\:hover\:from-pink-300:hover {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .sm\:hover\:from-pink-400:hover {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .sm\:hover\:from-pink-500:hover {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .sm\:hover\:from-pink-600:hover {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .sm\:hover\:from-pink-700:hover {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .sm\:hover\:from-pink-800:hover {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .sm\:hover\:from-pink-900:hover {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .sm\:hover\:via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .sm\:hover\:via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .sm\:hover\:via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .sm\:hover\:via-gray-50:hover {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .sm\:hover\:via-gray-100:hover {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .sm\:hover\:via-gray-200:hover {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .sm\:hover\:via-gray-300:hover {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .sm\:hover\:via-gray-400:hover {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .sm\:hover\:via-gray-500:hover {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .sm\:hover\:via-gray-600:hover {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .sm\:hover\:via-gray-700:hover {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .sm\:hover\:via-gray-800:hover {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .sm\:hover\:via-gray-900:hover {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .sm\:hover\:via-cool-gray-50:hover {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .sm\:hover\:via-cool-gray-100:hover {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .sm\:hover\:via-cool-gray-200:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .sm\:hover\:via-cool-gray-300:hover {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .sm\:hover\:via-cool-gray-400:hover {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .sm\:hover\:via-cool-gray-500:hover {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .sm\:hover\:via-cool-gray-600:hover {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .sm\:hover\:via-cool-gray-700:hover {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .sm\:hover\:via-cool-gray-800:hover {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .sm\:hover\:via-cool-gray-900:hover {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .sm\:hover\:via-red-50:hover {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .sm\:hover\:via-red-100:hover {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .sm\:hover\:via-red-200:hover {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .sm\:hover\:via-red-300:hover {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .sm\:hover\:via-red-400:hover {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .sm\:hover\:via-red-500:hover {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .sm\:hover\:via-red-600:hover {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .sm\:hover\:via-red-700:hover {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .sm\:hover\:via-red-800:hover {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .sm\:hover\:via-red-900:hover {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .sm\:hover\:via-orange-50:hover {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .sm\:hover\:via-orange-100:hover {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .sm\:hover\:via-orange-200:hover {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .sm\:hover\:via-orange-300:hover {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .sm\:hover\:via-orange-400:hover {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .sm\:hover\:via-orange-500:hover {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .sm\:hover\:via-orange-600:hover {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .sm\:hover\:via-orange-700:hover {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .sm\:hover\:via-orange-800:hover {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .sm\:hover\:via-orange-900:hover {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .sm\:hover\:via-yellow-50:hover {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .sm\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .sm\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .sm\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .sm\:hover\:via-yellow-400:hover {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .sm\:hover\:via-yellow-500:hover {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .sm\:hover\:via-yellow-600:hover {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .sm\:hover\:via-yellow-700:hover {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .sm\:hover\:via-yellow-800:hover {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .sm\:hover\:via-yellow-900:hover {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .sm\:hover\:via-green-50:hover {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .sm\:hover\:via-green-100:hover {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .sm\:hover\:via-green-200:hover {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .sm\:hover\:via-green-300:hover {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .sm\:hover\:via-green-400:hover {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .sm\:hover\:via-green-500:hover {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .sm\:hover\:via-green-600:hover {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .sm\:hover\:via-green-700:hover {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .sm\:hover\:via-green-800:hover {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .sm\:hover\:via-green-900:hover {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .sm\:hover\:via-teal-50:hover {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .sm\:hover\:via-teal-100:hover {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .sm\:hover\:via-teal-200:hover {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .sm\:hover\:via-teal-300:hover {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .sm\:hover\:via-teal-400:hover {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .sm\:hover\:via-teal-500:hover {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .sm\:hover\:via-teal-600:hover {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .sm\:hover\:via-teal-700:hover {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .sm\:hover\:via-teal-800:hover {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .sm\:hover\:via-teal-900:hover {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .sm\:hover\:via-blue-50:hover {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .sm\:hover\:via-blue-100:hover {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .sm\:hover\:via-blue-200:hover {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .sm\:hover\:via-blue-300:hover {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .sm\:hover\:via-blue-400:hover {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .sm\:hover\:via-blue-500:hover {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .sm\:hover\:via-blue-600:hover {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .sm\:hover\:via-blue-700:hover {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .sm\:hover\:via-blue-800:hover {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .sm\:hover\:via-blue-900:hover {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .sm\:hover\:via-indigo-50:hover {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .sm\:hover\:via-indigo-100:hover {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .sm\:hover\:via-indigo-200:hover {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .sm\:hover\:via-indigo-300:hover {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .sm\:hover\:via-indigo-400:hover {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .sm\:hover\:via-indigo-500:hover {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .sm\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .sm\:hover\:via-indigo-700:hover {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .sm\:hover\:via-indigo-800:hover {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .sm\:hover\:via-indigo-900:hover {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .sm\:hover\:via-purple-50:hover {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .sm\:hover\:via-purple-100:hover {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .sm\:hover\:via-purple-200:hover {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .sm\:hover\:via-purple-300:hover {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .sm\:hover\:via-purple-400:hover {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .sm\:hover\:via-purple-500:hover {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .sm\:hover\:via-purple-600:hover {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .sm\:hover\:via-purple-700:hover {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .sm\:hover\:via-purple-800:hover {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .sm\:hover\:via-purple-900:hover {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .sm\:hover\:via-pink-50:hover {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .sm\:hover\:via-pink-100:hover {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .sm\:hover\:via-pink-200:hover {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .sm\:hover\:via-pink-300:hover {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .sm\:hover\:via-pink-400:hover {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .sm\:hover\:via-pink-500:hover {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .sm\:hover\:via-pink-600:hover {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .sm\:hover\:via-pink-700:hover {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .sm\:hover\:via-pink-800:hover {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .sm\:hover\:via-pink-900:hover {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .sm\:hover\:to-transparent:hover {
    --gradient-to-color: transparent;
  }

  .sm\:hover\:to-white:hover {
    --gradient-to-color: #fff;
  }

  .sm\:hover\:to-black:hover {
    --gradient-to-color: #000;
  }

  .sm\:hover\:to-gray-50:hover {
    --gradient-to-color: #f9fafb;
  }

  .sm\:hover\:to-gray-100:hover {
    --gradient-to-color: #f4f5f7;
  }

  .sm\:hover\:to-gray-200:hover {
    --gradient-to-color: #e5e7eb;
  }

  .sm\:hover\:to-gray-300:hover {
    --gradient-to-color: #d2d6dc;
  }

  .sm\:hover\:to-gray-400:hover {
    --gradient-to-color: #9fa6b2;
  }

  .sm\:hover\:to-gray-500:hover {
    --gradient-to-color: #6b7280;
  }

  .sm\:hover\:to-gray-600:hover {
    --gradient-to-color: #4b5563;
  }

  .sm\:hover\:to-gray-700:hover {
    --gradient-to-color: #374151;
  }

  .sm\:hover\:to-gray-800:hover {
    --gradient-to-color: #252f3f;
  }

  .sm\:hover\:to-gray-900:hover {
    --gradient-to-color: #161e2e;
  }

  .sm\:hover\:to-cool-gray-50:hover {
    --gradient-to-color: #fbfdfe;
  }

  .sm\:hover\:to-cool-gray-100:hover {
    --gradient-to-color: #f1f5f9;
  }

  .sm\:hover\:to-cool-gray-200:hover {
    --gradient-to-color: #e2e8f0;
  }

  .sm\:hover\:to-cool-gray-300:hover {
    --gradient-to-color: #cfd8e3;
  }

  .sm\:hover\:to-cool-gray-400:hover {
    --gradient-to-color: #97a6ba;
  }

  .sm\:hover\:to-cool-gray-500:hover {
    --gradient-to-color: #64748b;
  }

  .sm\:hover\:to-cool-gray-600:hover {
    --gradient-to-color: #475569;
  }

  .sm\:hover\:to-cool-gray-700:hover {
    --gradient-to-color: #364152;
  }

  .sm\:hover\:to-cool-gray-800:hover {
    --gradient-to-color: #27303f;
  }

  .sm\:hover\:to-cool-gray-900:hover {
    --gradient-to-color: #1a202e;
  }

  .sm\:hover\:to-red-50:hover {
    --gradient-to-color: #fdf2f2;
  }

  .sm\:hover\:to-red-100:hover {
    --gradient-to-color: #fde8e8;
  }

  .sm\:hover\:to-red-200:hover {
    --gradient-to-color: #fbd5d5;
  }

  .sm\:hover\:to-red-300:hover {
    --gradient-to-color: #f8b4b4;
  }

  .sm\:hover\:to-red-400:hover {
    --gradient-to-color: #f98080;
  }

  .sm\:hover\:to-red-500:hover {
    --gradient-to-color: #f05252;
  }

  .sm\:hover\:to-red-600:hover {
    --gradient-to-color: #e02424;
  }

  .sm\:hover\:to-red-700:hover {
    --gradient-to-color: #c81e1e;
  }

  .sm\:hover\:to-red-800:hover {
    --gradient-to-color: #9b1c1c;
  }

  .sm\:hover\:to-red-900:hover {
    --gradient-to-color: #771d1d;
  }

  .sm\:hover\:to-orange-50:hover {
    --gradient-to-color: #fff8f1;
  }

  .sm\:hover\:to-orange-100:hover {
    --gradient-to-color: #feecdc;
  }

  .sm\:hover\:to-orange-200:hover {
    --gradient-to-color: #fcd9bd;
  }

  .sm\:hover\:to-orange-300:hover {
    --gradient-to-color: #fdba8c;
  }

  .sm\:hover\:to-orange-400:hover {
    --gradient-to-color: #ff8a4c;
  }

  .sm\:hover\:to-orange-500:hover {
    --gradient-to-color: #ff5a1f;
  }

  .sm\:hover\:to-orange-600:hover {
    --gradient-to-color: #d03801;
  }

  .sm\:hover\:to-orange-700:hover {
    --gradient-to-color: #b43403;
  }

  .sm\:hover\:to-orange-800:hover {
    --gradient-to-color: #8a2c0d;
  }

  .sm\:hover\:to-orange-900:hover {
    --gradient-to-color: #73230d;
  }

  .sm\:hover\:to-yellow-50:hover {
    --gradient-to-color: #fdfdea;
  }

  .sm\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fdf6b2;
  }

  .sm\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fce96a;
  }

  .sm\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faca15;
  }

  .sm\:hover\:to-yellow-400:hover {
    --gradient-to-color: #e3a008;
  }

  .sm\:hover\:to-yellow-500:hover {
    --gradient-to-color: #c27803;
  }

  .sm\:hover\:to-yellow-600:hover {
    --gradient-to-color: #9f580a;
  }

  .sm\:hover\:to-yellow-700:hover {
    --gradient-to-color: #8e4b10;
  }

  .sm\:hover\:to-yellow-800:hover {
    --gradient-to-color: #723b13;
  }

  .sm\:hover\:to-yellow-900:hover {
    --gradient-to-color: #633112;
  }

  .sm\:hover\:to-green-50:hover {
    --gradient-to-color: #f3faf7;
  }

  .sm\:hover\:to-green-100:hover {
    --gradient-to-color: #def7ec;
  }

  .sm\:hover\:to-green-200:hover {
    --gradient-to-color: #bcf0da;
  }

  .sm\:hover\:to-green-300:hover {
    --gradient-to-color: #84e1bc;
  }

  .sm\:hover\:to-green-400:hover {
    --gradient-to-color: #31c48d;
  }

  .sm\:hover\:to-green-500:hover {
    --gradient-to-color: #0e9f6e;
  }

  .sm\:hover\:to-green-600:hover {
    --gradient-to-color: #057a55;
  }

  .sm\:hover\:to-green-700:hover {
    --gradient-to-color: #046c4e;
  }

  .sm\:hover\:to-green-800:hover {
    --gradient-to-color: #03543f;
  }

  .sm\:hover\:to-green-900:hover {
    --gradient-to-color: #014737;
  }

  .sm\:hover\:to-teal-50:hover {
    --gradient-to-color: #edfafa;
  }

  .sm\:hover\:to-teal-100:hover {
    --gradient-to-color: #d5f5f6;
  }

  .sm\:hover\:to-teal-200:hover {
    --gradient-to-color: #afecef;
  }

  .sm\:hover\:to-teal-300:hover {
    --gradient-to-color: #7edce2;
  }

  .sm\:hover\:to-teal-400:hover {
    --gradient-to-color: #16bdca;
  }

  .sm\:hover\:to-teal-500:hover {
    --gradient-to-color: #0694a2;
  }

  .sm\:hover\:to-teal-600:hover {
    --gradient-to-color: #047481;
  }

  .sm\:hover\:to-teal-700:hover {
    --gradient-to-color: #036672;
  }

  .sm\:hover\:to-teal-800:hover {
    --gradient-to-color: #05505c;
  }

  .sm\:hover\:to-teal-900:hover {
    --gradient-to-color: #014451;
  }

  .sm\:hover\:to-blue-50:hover {
    --gradient-to-color: #ebf5ff;
  }

  .sm\:hover\:to-blue-100:hover {
    --gradient-to-color: #e1effe;
  }

  .sm\:hover\:to-blue-200:hover {
    --gradient-to-color: #c3ddfd;
  }

  .sm\:hover\:to-blue-300:hover {
    --gradient-to-color: #a4cafe;
  }

  .sm\:hover\:to-blue-400:hover {
    --gradient-to-color: #76a9fa;
  }

  .sm\:hover\:to-blue-500:hover {
    --gradient-to-color: #3f83f8;
  }

  .sm\:hover\:to-blue-600:hover {
    --gradient-to-color: #1c64f2;
  }

  .sm\:hover\:to-blue-700:hover {
    --gradient-to-color: #1a56db;
  }

  .sm\:hover\:to-blue-800:hover {
    --gradient-to-color: #1e429f;
  }

  .sm\:hover\:to-blue-900:hover {
    --gradient-to-color: #233876;
  }

  .sm\:hover\:to-indigo-50:hover {
    --gradient-to-color: #f0f5ff;
  }

  .sm\:hover\:to-indigo-100:hover {
    --gradient-to-color: #e5edff;
  }

  .sm\:hover\:to-indigo-200:hover {
    --gradient-to-color: #cddbfe;
  }

  .sm\:hover\:to-indigo-300:hover {
    --gradient-to-color: #b4c6fc;
  }

  .sm\:hover\:to-indigo-400:hover {
    --gradient-to-color: #8da2fb;
  }

  .sm\:hover\:to-indigo-500:hover {
    --gradient-to-color: #6875f5;
  }

  .sm\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5850ec;
  }

  .sm\:hover\:to-indigo-700:hover {
    --gradient-to-color: #5145cd;
  }

  .sm\:hover\:to-indigo-800:hover {
    --gradient-to-color: #42389d;
  }

  .sm\:hover\:to-indigo-900:hover {
    --gradient-to-color: #362f78;
  }

  .sm\:hover\:to-purple-50:hover {
    --gradient-to-color: #f6f5ff;
  }

  .sm\:hover\:to-purple-100:hover {
    --gradient-to-color: #edebfe;
  }

  .sm\:hover\:to-purple-200:hover {
    --gradient-to-color: #dcd7fe;
  }

  .sm\:hover\:to-purple-300:hover {
    --gradient-to-color: #cabffd;
  }

  .sm\:hover\:to-purple-400:hover {
    --gradient-to-color: #ac94fa;
  }

  .sm\:hover\:to-purple-500:hover {
    --gradient-to-color: #9061f9;
  }

  .sm\:hover\:to-purple-600:hover {
    --gradient-to-color: #7e3af2;
  }

  .sm\:hover\:to-purple-700:hover {
    --gradient-to-color: #6c2bd9;
  }

  .sm\:hover\:to-purple-800:hover {
    --gradient-to-color: #5521b5;
  }

  .sm\:hover\:to-purple-900:hover {
    --gradient-to-color: #4a1d96;
  }

  .sm\:hover\:to-pink-50:hover {
    --gradient-to-color: #fdf2f8;
  }

  .sm\:hover\:to-pink-100:hover {
    --gradient-to-color: #fce8f3;
  }

  .sm\:hover\:to-pink-200:hover {
    --gradient-to-color: #fad1e8;
  }

  .sm\:hover\:to-pink-300:hover {
    --gradient-to-color: #f8b4d9;
  }

  .sm\:hover\:to-pink-400:hover {
    --gradient-to-color: #f17eb8;
  }

  .sm\:hover\:to-pink-500:hover {
    --gradient-to-color: #e74694;
  }

  .sm\:hover\:to-pink-600:hover {
    --gradient-to-color: #d61f69;
  }

  .sm\:hover\:to-pink-700:hover {
    --gradient-to-color: #bf125d;
  }

  .sm\:hover\:to-pink-800:hover {
    --gradient-to-color: #99154b;
  }

  .sm\:hover\:to-pink-900:hover {
    --gradient-to-color: #751a3d;
  }

  .sm\:focus\:from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .sm\:focus\:from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .sm\:focus\:from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .sm\:focus\:from-gray-50:focus {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .sm\:focus\:from-gray-100:focus {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .sm\:focus\:from-gray-200:focus {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .sm\:focus\:from-gray-300:focus {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .sm\:focus\:from-gray-400:focus {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .sm\:focus\:from-gray-500:focus {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .sm\:focus\:from-gray-600:focus {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .sm\:focus\:from-gray-700:focus {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .sm\:focus\:from-gray-800:focus {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .sm\:focus\:from-gray-900:focus {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .sm\:focus\:from-cool-gray-50:focus {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .sm\:focus\:from-cool-gray-100:focus {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .sm\:focus\:from-cool-gray-200:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .sm\:focus\:from-cool-gray-300:focus {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .sm\:focus\:from-cool-gray-400:focus {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .sm\:focus\:from-cool-gray-500:focus {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .sm\:focus\:from-cool-gray-600:focus {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .sm\:focus\:from-cool-gray-700:focus {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .sm\:focus\:from-cool-gray-800:focus {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .sm\:focus\:from-cool-gray-900:focus {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .sm\:focus\:from-red-50:focus {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .sm\:focus\:from-red-100:focus {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .sm\:focus\:from-red-200:focus {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .sm\:focus\:from-red-300:focus {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .sm\:focus\:from-red-400:focus {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .sm\:focus\:from-red-500:focus {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .sm\:focus\:from-red-600:focus {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .sm\:focus\:from-red-700:focus {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .sm\:focus\:from-red-800:focus {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .sm\:focus\:from-red-900:focus {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .sm\:focus\:from-orange-50:focus {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .sm\:focus\:from-orange-100:focus {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .sm\:focus\:from-orange-200:focus {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .sm\:focus\:from-orange-300:focus {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .sm\:focus\:from-orange-400:focus {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .sm\:focus\:from-orange-500:focus {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .sm\:focus\:from-orange-600:focus {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .sm\:focus\:from-orange-700:focus {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .sm\:focus\:from-orange-800:focus {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .sm\:focus\:from-orange-900:focus {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .sm\:focus\:from-yellow-50:focus {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .sm\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .sm\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .sm\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .sm\:focus\:from-yellow-400:focus {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .sm\:focus\:from-yellow-500:focus {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .sm\:focus\:from-yellow-600:focus {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .sm\:focus\:from-yellow-700:focus {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .sm\:focus\:from-yellow-800:focus {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .sm\:focus\:from-yellow-900:focus {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .sm\:focus\:from-green-50:focus {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .sm\:focus\:from-green-100:focus {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .sm\:focus\:from-green-200:focus {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .sm\:focus\:from-green-300:focus {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .sm\:focus\:from-green-400:focus {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .sm\:focus\:from-green-500:focus {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .sm\:focus\:from-green-600:focus {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .sm\:focus\:from-green-700:focus {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .sm\:focus\:from-green-800:focus {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .sm\:focus\:from-green-900:focus {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .sm\:focus\:from-teal-50:focus {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .sm\:focus\:from-teal-100:focus {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .sm\:focus\:from-teal-200:focus {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .sm\:focus\:from-teal-300:focus {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .sm\:focus\:from-teal-400:focus {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .sm\:focus\:from-teal-500:focus {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .sm\:focus\:from-teal-600:focus {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .sm\:focus\:from-teal-700:focus {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .sm\:focus\:from-teal-800:focus {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .sm\:focus\:from-teal-900:focus {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .sm\:focus\:from-blue-50:focus {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .sm\:focus\:from-blue-100:focus {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .sm\:focus\:from-blue-200:focus {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .sm\:focus\:from-blue-300:focus {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .sm\:focus\:from-blue-400:focus {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .sm\:focus\:from-blue-500:focus {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .sm\:focus\:from-blue-600:focus {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .sm\:focus\:from-blue-700:focus {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .sm\:focus\:from-blue-800:focus {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .sm\:focus\:from-blue-900:focus {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .sm\:focus\:from-indigo-50:focus {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .sm\:focus\:from-indigo-100:focus {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .sm\:focus\:from-indigo-200:focus {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .sm\:focus\:from-indigo-300:focus {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .sm\:focus\:from-indigo-400:focus {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .sm\:focus\:from-indigo-500:focus {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .sm\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .sm\:focus\:from-indigo-700:focus {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .sm\:focus\:from-indigo-800:focus {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .sm\:focus\:from-indigo-900:focus {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .sm\:focus\:from-purple-50:focus {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .sm\:focus\:from-purple-100:focus {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .sm\:focus\:from-purple-200:focus {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .sm\:focus\:from-purple-300:focus {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .sm\:focus\:from-purple-400:focus {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .sm\:focus\:from-purple-500:focus {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .sm\:focus\:from-purple-600:focus {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .sm\:focus\:from-purple-700:focus {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .sm\:focus\:from-purple-800:focus {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .sm\:focus\:from-purple-900:focus {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .sm\:focus\:from-pink-50:focus {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .sm\:focus\:from-pink-100:focus {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .sm\:focus\:from-pink-200:focus {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .sm\:focus\:from-pink-300:focus {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .sm\:focus\:from-pink-400:focus {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .sm\:focus\:from-pink-500:focus {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .sm\:focus\:from-pink-600:focus {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .sm\:focus\:from-pink-700:focus {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .sm\:focus\:from-pink-800:focus {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .sm\:focus\:from-pink-900:focus {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .sm\:focus\:via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .sm\:focus\:via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .sm\:focus\:via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .sm\:focus\:via-gray-50:focus {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .sm\:focus\:via-gray-100:focus {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .sm\:focus\:via-gray-200:focus {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .sm\:focus\:via-gray-300:focus {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .sm\:focus\:via-gray-400:focus {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .sm\:focus\:via-gray-500:focus {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .sm\:focus\:via-gray-600:focus {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .sm\:focus\:via-gray-700:focus {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .sm\:focus\:via-gray-800:focus {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .sm\:focus\:via-gray-900:focus {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .sm\:focus\:via-cool-gray-50:focus {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .sm\:focus\:via-cool-gray-100:focus {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .sm\:focus\:via-cool-gray-200:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .sm\:focus\:via-cool-gray-300:focus {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .sm\:focus\:via-cool-gray-400:focus {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .sm\:focus\:via-cool-gray-500:focus {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .sm\:focus\:via-cool-gray-600:focus {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .sm\:focus\:via-cool-gray-700:focus {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .sm\:focus\:via-cool-gray-800:focus {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .sm\:focus\:via-cool-gray-900:focus {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .sm\:focus\:via-red-50:focus {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .sm\:focus\:via-red-100:focus {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .sm\:focus\:via-red-200:focus {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .sm\:focus\:via-red-300:focus {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .sm\:focus\:via-red-400:focus {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .sm\:focus\:via-red-500:focus {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .sm\:focus\:via-red-600:focus {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .sm\:focus\:via-red-700:focus {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .sm\:focus\:via-red-800:focus {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .sm\:focus\:via-red-900:focus {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .sm\:focus\:via-orange-50:focus {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .sm\:focus\:via-orange-100:focus {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .sm\:focus\:via-orange-200:focus {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .sm\:focus\:via-orange-300:focus {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .sm\:focus\:via-orange-400:focus {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .sm\:focus\:via-orange-500:focus {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .sm\:focus\:via-orange-600:focus {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .sm\:focus\:via-orange-700:focus {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .sm\:focus\:via-orange-800:focus {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .sm\:focus\:via-orange-900:focus {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .sm\:focus\:via-yellow-50:focus {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .sm\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .sm\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .sm\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .sm\:focus\:via-yellow-400:focus {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .sm\:focus\:via-yellow-500:focus {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .sm\:focus\:via-yellow-600:focus {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .sm\:focus\:via-yellow-700:focus {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .sm\:focus\:via-yellow-800:focus {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .sm\:focus\:via-yellow-900:focus {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .sm\:focus\:via-green-50:focus {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .sm\:focus\:via-green-100:focus {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .sm\:focus\:via-green-200:focus {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .sm\:focus\:via-green-300:focus {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .sm\:focus\:via-green-400:focus {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .sm\:focus\:via-green-500:focus {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .sm\:focus\:via-green-600:focus {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .sm\:focus\:via-green-700:focus {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .sm\:focus\:via-green-800:focus {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .sm\:focus\:via-green-900:focus {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .sm\:focus\:via-teal-50:focus {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .sm\:focus\:via-teal-100:focus {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .sm\:focus\:via-teal-200:focus {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .sm\:focus\:via-teal-300:focus {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .sm\:focus\:via-teal-400:focus {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .sm\:focus\:via-teal-500:focus {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .sm\:focus\:via-teal-600:focus {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .sm\:focus\:via-teal-700:focus {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .sm\:focus\:via-teal-800:focus {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .sm\:focus\:via-teal-900:focus {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .sm\:focus\:via-blue-50:focus {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .sm\:focus\:via-blue-100:focus {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .sm\:focus\:via-blue-200:focus {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .sm\:focus\:via-blue-300:focus {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .sm\:focus\:via-blue-400:focus {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .sm\:focus\:via-blue-500:focus {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .sm\:focus\:via-blue-600:focus {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .sm\:focus\:via-blue-700:focus {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .sm\:focus\:via-blue-800:focus {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .sm\:focus\:via-blue-900:focus {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .sm\:focus\:via-indigo-50:focus {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .sm\:focus\:via-indigo-100:focus {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .sm\:focus\:via-indigo-200:focus {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .sm\:focus\:via-indigo-300:focus {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .sm\:focus\:via-indigo-400:focus {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .sm\:focus\:via-indigo-500:focus {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .sm\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .sm\:focus\:via-indigo-700:focus {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .sm\:focus\:via-indigo-800:focus {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .sm\:focus\:via-indigo-900:focus {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .sm\:focus\:via-purple-50:focus {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .sm\:focus\:via-purple-100:focus {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .sm\:focus\:via-purple-200:focus {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .sm\:focus\:via-purple-300:focus {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .sm\:focus\:via-purple-400:focus {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .sm\:focus\:via-purple-500:focus {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .sm\:focus\:via-purple-600:focus {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .sm\:focus\:via-purple-700:focus {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .sm\:focus\:via-purple-800:focus {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .sm\:focus\:via-purple-900:focus {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .sm\:focus\:via-pink-50:focus {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .sm\:focus\:via-pink-100:focus {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .sm\:focus\:via-pink-200:focus {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .sm\:focus\:via-pink-300:focus {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .sm\:focus\:via-pink-400:focus {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .sm\:focus\:via-pink-500:focus {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .sm\:focus\:via-pink-600:focus {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .sm\:focus\:via-pink-700:focus {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .sm\:focus\:via-pink-800:focus {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .sm\:focus\:via-pink-900:focus {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .sm\:focus\:to-transparent:focus {
    --gradient-to-color: transparent;
  }

  .sm\:focus\:to-white:focus {
    --gradient-to-color: #fff;
  }

  .sm\:focus\:to-black:focus {
    --gradient-to-color: #000;
  }

  .sm\:focus\:to-gray-50:focus {
    --gradient-to-color: #f9fafb;
  }

  .sm\:focus\:to-gray-100:focus {
    --gradient-to-color: #f4f5f7;
  }

  .sm\:focus\:to-gray-200:focus {
    --gradient-to-color: #e5e7eb;
  }

  .sm\:focus\:to-gray-300:focus {
    --gradient-to-color: #d2d6dc;
  }

  .sm\:focus\:to-gray-400:focus {
    --gradient-to-color: #9fa6b2;
  }

  .sm\:focus\:to-gray-500:focus {
    --gradient-to-color: #6b7280;
  }

  .sm\:focus\:to-gray-600:focus {
    --gradient-to-color: #4b5563;
  }

  .sm\:focus\:to-gray-700:focus {
    --gradient-to-color: #374151;
  }

  .sm\:focus\:to-gray-800:focus {
    --gradient-to-color: #252f3f;
  }

  .sm\:focus\:to-gray-900:focus {
    --gradient-to-color: #161e2e;
  }

  .sm\:focus\:to-cool-gray-50:focus {
    --gradient-to-color: #fbfdfe;
  }

  .sm\:focus\:to-cool-gray-100:focus {
    --gradient-to-color: #f1f5f9;
  }

  .sm\:focus\:to-cool-gray-200:focus {
    --gradient-to-color: #e2e8f0;
  }

  .sm\:focus\:to-cool-gray-300:focus {
    --gradient-to-color: #cfd8e3;
  }

  .sm\:focus\:to-cool-gray-400:focus {
    --gradient-to-color: #97a6ba;
  }

  .sm\:focus\:to-cool-gray-500:focus {
    --gradient-to-color: #64748b;
  }

  .sm\:focus\:to-cool-gray-600:focus {
    --gradient-to-color: #475569;
  }

  .sm\:focus\:to-cool-gray-700:focus {
    --gradient-to-color: #364152;
  }

  .sm\:focus\:to-cool-gray-800:focus {
    --gradient-to-color: #27303f;
  }

  .sm\:focus\:to-cool-gray-900:focus {
    --gradient-to-color: #1a202e;
  }

  .sm\:focus\:to-red-50:focus {
    --gradient-to-color: #fdf2f2;
  }

  .sm\:focus\:to-red-100:focus {
    --gradient-to-color: #fde8e8;
  }

  .sm\:focus\:to-red-200:focus {
    --gradient-to-color: #fbd5d5;
  }

  .sm\:focus\:to-red-300:focus {
    --gradient-to-color: #f8b4b4;
  }

  .sm\:focus\:to-red-400:focus {
    --gradient-to-color: #f98080;
  }

  .sm\:focus\:to-red-500:focus {
    --gradient-to-color: #f05252;
  }

  .sm\:focus\:to-red-600:focus {
    --gradient-to-color: #e02424;
  }

  .sm\:focus\:to-red-700:focus {
    --gradient-to-color: #c81e1e;
  }

  .sm\:focus\:to-red-800:focus {
    --gradient-to-color: #9b1c1c;
  }

  .sm\:focus\:to-red-900:focus {
    --gradient-to-color: #771d1d;
  }

  .sm\:focus\:to-orange-50:focus {
    --gradient-to-color: #fff8f1;
  }

  .sm\:focus\:to-orange-100:focus {
    --gradient-to-color: #feecdc;
  }

  .sm\:focus\:to-orange-200:focus {
    --gradient-to-color: #fcd9bd;
  }

  .sm\:focus\:to-orange-300:focus {
    --gradient-to-color: #fdba8c;
  }

  .sm\:focus\:to-orange-400:focus {
    --gradient-to-color: #ff8a4c;
  }

  .sm\:focus\:to-orange-500:focus {
    --gradient-to-color: #ff5a1f;
  }

  .sm\:focus\:to-orange-600:focus {
    --gradient-to-color: #d03801;
  }

  .sm\:focus\:to-orange-700:focus {
    --gradient-to-color: #b43403;
  }

  .sm\:focus\:to-orange-800:focus {
    --gradient-to-color: #8a2c0d;
  }

  .sm\:focus\:to-orange-900:focus {
    --gradient-to-color: #73230d;
  }

  .sm\:focus\:to-yellow-50:focus {
    --gradient-to-color: #fdfdea;
  }

  .sm\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fdf6b2;
  }

  .sm\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fce96a;
  }

  .sm\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faca15;
  }

  .sm\:focus\:to-yellow-400:focus {
    --gradient-to-color: #e3a008;
  }

  .sm\:focus\:to-yellow-500:focus {
    --gradient-to-color: #c27803;
  }

  .sm\:focus\:to-yellow-600:focus {
    --gradient-to-color: #9f580a;
  }

  .sm\:focus\:to-yellow-700:focus {
    --gradient-to-color: #8e4b10;
  }

  .sm\:focus\:to-yellow-800:focus {
    --gradient-to-color: #723b13;
  }

  .sm\:focus\:to-yellow-900:focus {
    --gradient-to-color: #633112;
  }

  .sm\:focus\:to-green-50:focus {
    --gradient-to-color: #f3faf7;
  }

  .sm\:focus\:to-green-100:focus {
    --gradient-to-color: #def7ec;
  }

  .sm\:focus\:to-green-200:focus {
    --gradient-to-color: #bcf0da;
  }

  .sm\:focus\:to-green-300:focus {
    --gradient-to-color: #84e1bc;
  }

  .sm\:focus\:to-green-400:focus {
    --gradient-to-color: #31c48d;
  }

  .sm\:focus\:to-green-500:focus {
    --gradient-to-color: #0e9f6e;
  }

  .sm\:focus\:to-green-600:focus {
    --gradient-to-color: #057a55;
  }

  .sm\:focus\:to-green-700:focus {
    --gradient-to-color: #046c4e;
  }

  .sm\:focus\:to-green-800:focus {
    --gradient-to-color: #03543f;
  }

  .sm\:focus\:to-green-900:focus {
    --gradient-to-color: #014737;
  }

  .sm\:focus\:to-teal-50:focus {
    --gradient-to-color: #edfafa;
  }

  .sm\:focus\:to-teal-100:focus {
    --gradient-to-color: #d5f5f6;
  }

  .sm\:focus\:to-teal-200:focus {
    --gradient-to-color: #afecef;
  }

  .sm\:focus\:to-teal-300:focus {
    --gradient-to-color: #7edce2;
  }

  .sm\:focus\:to-teal-400:focus {
    --gradient-to-color: #16bdca;
  }

  .sm\:focus\:to-teal-500:focus {
    --gradient-to-color: #0694a2;
  }

  .sm\:focus\:to-teal-600:focus {
    --gradient-to-color: #047481;
  }

  .sm\:focus\:to-teal-700:focus {
    --gradient-to-color: #036672;
  }

  .sm\:focus\:to-teal-800:focus {
    --gradient-to-color: #05505c;
  }

  .sm\:focus\:to-teal-900:focus {
    --gradient-to-color: #014451;
  }

  .sm\:focus\:to-blue-50:focus {
    --gradient-to-color: #ebf5ff;
  }

  .sm\:focus\:to-blue-100:focus {
    --gradient-to-color: #e1effe;
  }

  .sm\:focus\:to-blue-200:focus {
    --gradient-to-color: #c3ddfd;
  }

  .sm\:focus\:to-blue-300:focus {
    --gradient-to-color: #a4cafe;
  }

  .sm\:focus\:to-blue-400:focus {
    --gradient-to-color: #76a9fa;
  }

  .sm\:focus\:to-blue-500:focus {
    --gradient-to-color: #3f83f8;
  }

  .sm\:focus\:to-blue-600:focus {
    --gradient-to-color: #1c64f2;
  }

  .sm\:focus\:to-blue-700:focus {
    --gradient-to-color: #1a56db;
  }

  .sm\:focus\:to-blue-800:focus {
    --gradient-to-color: #1e429f;
  }

  .sm\:focus\:to-blue-900:focus {
    --gradient-to-color: #233876;
  }

  .sm\:focus\:to-indigo-50:focus {
    --gradient-to-color: #f0f5ff;
  }

  .sm\:focus\:to-indigo-100:focus {
    --gradient-to-color: #e5edff;
  }

  .sm\:focus\:to-indigo-200:focus {
    --gradient-to-color: #cddbfe;
  }

  .sm\:focus\:to-indigo-300:focus {
    --gradient-to-color: #b4c6fc;
  }

  .sm\:focus\:to-indigo-400:focus {
    --gradient-to-color: #8da2fb;
  }

  .sm\:focus\:to-indigo-500:focus {
    --gradient-to-color: #6875f5;
  }

  .sm\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5850ec;
  }

  .sm\:focus\:to-indigo-700:focus {
    --gradient-to-color: #5145cd;
  }

  .sm\:focus\:to-indigo-800:focus {
    --gradient-to-color: #42389d;
  }

  .sm\:focus\:to-indigo-900:focus {
    --gradient-to-color: #362f78;
  }

  .sm\:focus\:to-purple-50:focus {
    --gradient-to-color: #f6f5ff;
  }

  .sm\:focus\:to-purple-100:focus {
    --gradient-to-color: #edebfe;
  }

  .sm\:focus\:to-purple-200:focus {
    --gradient-to-color: #dcd7fe;
  }

  .sm\:focus\:to-purple-300:focus {
    --gradient-to-color: #cabffd;
  }

  .sm\:focus\:to-purple-400:focus {
    --gradient-to-color: #ac94fa;
  }

  .sm\:focus\:to-purple-500:focus {
    --gradient-to-color: #9061f9;
  }

  .sm\:focus\:to-purple-600:focus {
    --gradient-to-color: #7e3af2;
  }

  .sm\:focus\:to-purple-700:focus {
    --gradient-to-color: #6c2bd9;
  }

  .sm\:focus\:to-purple-800:focus {
    --gradient-to-color: #5521b5;
  }

  .sm\:focus\:to-purple-900:focus {
    --gradient-to-color: #4a1d96;
  }

  .sm\:focus\:to-pink-50:focus {
    --gradient-to-color: #fdf2f8;
  }

  .sm\:focus\:to-pink-100:focus {
    --gradient-to-color: #fce8f3;
  }

  .sm\:focus\:to-pink-200:focus {
    --gradient-to-color: #fad1e8;
  }

  .sm\:focus\:to-pink-300:focus {
    --gradient-to-color: #f8b4d9;
  }

  .sm\:focus\:to-pink-400:focus {
    --gradient-to-color: #f17eb8;
  }

  .sm\:focus\:to-pink-500:focus {
    --gradient-to-color: #e74694;
  }

  .sm\:focus\:to-pink-600:focus {
    --gradient-to-color: #d61f69;
  }

  .sm\:focus\:to-pink-700:focus {
    --gradient-to-color: #bf125d;
  }

  .sm\:focus\:to-pink-800:focus {
    --gradient-to-color: #99154b;
  }

  .sm\:focus\:to-pink-900:focus {
    --gradient-to-color: #751a3d;
  }

  .sm\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .sm\:bg-opacity-25 {
    --bg-opacity: .25;
  }

  .sm\:bg-opacity-50 {
    --bg-opacity: .5;
  }

  .sm\:bg-opacity-75 {
    --bg-opacity: .75;
  }

  .sm\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .sm\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .sm\:hover\:bg-opacity-25:hover {
    --bg-opacity: .25;
  }

  .sm\:hover\:bg-opacity-50:hover {
    --bg-opacity: .5;
  }

  .sm\:hover\:bg-opacity-75:hover {
    --bg-opacity: .75;
  }

  .sm\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .sm\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .sm\:focus\:bg-opacity-25:focus {
    --bg-opacity: .25;
  }

  .sm\:focus\:bg-opacity-50:focus {
    --bg-opacity: .5;
  }

  .sm\:focus\:bg-opacity-75:focus {
    --bg-opacity: .75;
  }

  .sm\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .sm\:bg-bottom {
    background-position: bottom;
  }

  .sm\:bg-center {
    background-position: center;
  }

  .sm\:bg-left {
    background-position: 0;
  }

  .sm\:bg-left-bottom {
    background-position: 0 100%;
  }

  .sm\:bg-left-top {
    background-position: 0 0;
  }

  .sm\:bg-right {
    background-position: 100%;
  }

  .sm\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .sm\:bg-right-top {
    background-position: 100% 0;
  }

  .sm\:bg-top {
    background-position: top;
  }

  .sm\:bg-repeat {
    background-repeat: repeat;
  }

  .sm\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .sm\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .sm\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .sm\:bg-repeat-round {
    background-repeat: round;
  }

  .sm\:bg-repeat-space {
    background-repeat: space;
  }

  .sm\:bg-auto {
    background-size: auto;
  }

  .sm\:bg-cover {
    background-size: cover;
  }

  .sm\:bg-contain {
    background-size: contain;
  }

  .sm\:border-collapse {
    border-collapse: collapse;
  }

  .sm\:border-separate {
    border-collapse: separate;
  }

  .sm\:border-transparent {
    border-color: #0000;
  }

  .sm\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .sm\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .sm\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .sm\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .sm\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .sm\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .sm\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .sm\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .sm\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .sm\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .sm\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .sm\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .sm\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .sm\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .sm\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .sm\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .sm\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .sm\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .sm\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .sm\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .sm\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .sm\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .sm\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .sm\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .sm\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .sm\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .sm\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .sm\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .sm\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .sm\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .sm\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .sm\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .sm\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .sm\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .sm\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .sm\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .sm\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .sm\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .sm\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .sm\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .sm\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .sm\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .sm\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .sm\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .sm\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .sm\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .sm\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .sm\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .sm\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .sm\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .sm\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .sm\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .sm\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .sm\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .sm\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .sm\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .sm\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .sm\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .sm\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .sm\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .sm\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .sm\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .sm\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .sm\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .sm\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .sm\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .sm\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .sm\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .sm\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .sm\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .sm\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .sm\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .sm\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .sm\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .sm\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .sm\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .sm\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .sm\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .sm\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .sm\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .sm\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .sm\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .sm\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .sm\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .sm\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .sm\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .sm\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .sm\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .sm\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .sm\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .sm\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .sm\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .sm\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .sm\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .sm\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .sm\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .sm\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .sm\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .sm\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .sm\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .sm\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .sm\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .sm\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .sm\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .sm\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .sm\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .sm\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .sm\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .sm\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .sm\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .sm\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .sm\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .sm\:group-hover\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .group:hover .sm\:group-hover\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-transparent {
    border-color: #0000;
  }

  .group:focus .sm\:group-focus\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .group:focus .sm\:group-focus\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .sm\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .sm\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .sm\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-50:hover {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .sm\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-50:hover {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-100:hover {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-200:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-300:hover {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-400:hover {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-500:hover {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-600:hover {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-700:hover {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-800:hover {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .sm\:hover\:border-cool-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .sm\:hover\:border-red-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .sm\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .sm\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .sm\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .sm\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .sm\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .sm\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .sm\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .sm\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .sm\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-50:hover {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .sm\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-50:hover {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .sm\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .sm\:hover\:border-green-50:hover {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .sm\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .sm\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .sm\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .sm\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .sm\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .sm\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .sm\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .sm\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .sm\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-50:hover {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .sm\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-50:hover {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .sm\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-50:hover {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .sm\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-50:hover {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .sm\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .sm\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .sm\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .sm\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .sm\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-50:focus {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .sm\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-50:focus {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-100:focus {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-200:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-300:focus {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-400:focus {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-500:focus {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-600:focus {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-700:focus {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-800:focus {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .sm\:focus\:border-cool-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .sm\:focus\:border-red-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .sm\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .sm\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .sm\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .sm\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .sm\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .sm\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .sm\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .sm\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .sm\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-50:focus {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .sm\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-50:focus {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .sm\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .sm\:focus\:border-green-50:focus {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .sm\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .sm\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .sm\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .sm\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .sm\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .sm\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .sm\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .sm\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .sm\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-50:focus {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .sm\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-50:focus {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .sm\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-50:focus {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .sm\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-50:focus {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .sm\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .sm\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .sm\:border-opacity-0 {
    --border-opacity: 0;
  }

  .sm\:border-opacity-25 {
    --border-opacity: .25;
  }

  .sm\:border-opacity-50 {
    --border-opacity: .5;
  }

  .sm\:border-opacity-75 {
    --border-opacity: .75;
  }

  .sm\:border-opacity-100 {
    --border-opacity: 1;
  }

  .sm\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .sm\:hover\:border-opacity-25:hover {
    --border-opacity: .25;
  }

  .sm\:hover\:border-opacity-50:hover {
    --border-opacity: .5;
  }

  .sm\:hover\:border-opacity-75:hover {
    --border-opacity: .75;
  }

  .sm\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .sm\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .sm\:focus\:border-opacity-25:focus {
    --border-opacity: .25;
  }

  .sm\:focus\:border-opacity-50:focus {
    --border-opacity: .5;
  }

  .sm\:focus\:border-opacity-75:focus {
    --border-opacity: .75;
  }

  .sm\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .sm\:rounded-none {
    border-radius: 0;
  }

  .sm\:rounded-sm {
    border-radius: .125rem;
  }

  .sm\:rounded {
    border-radius: .25rem;
  }

  .sm\:rounded-md {
    border-radius: .375rem;
  }

  .sm\:rounded-lg {
    border-radius: .5rem;
  }

  .sm\:rounded-xl {
    border-radius: .75rem;
  }

  .sm\:rounded-2xl {
    border-radius: 1rem;
  }

  .sm\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .sm\:rounded-full {
    border-radius: 9999px;
  }

  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .sm\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .sm\:rounded-b-sm {
    border-bottom-left-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .sm\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .sm\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .sm\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .sm\:rounded-b {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .sm\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .sm\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .sm\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .sm\:rounded-b-md {
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .sm\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .sm\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .sm\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .sm\:rounded-b-lg {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .sm\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .sm\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .sm\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .sm\:rounded-b-xl {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .sm\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-b-2xl {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-b-3xl {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-b-full {
    border-bottom-left-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .sm\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .sm\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .sm\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .sm\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .sm\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .sm\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .sm\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .sm\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .sm\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .sm\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .sm\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .sm\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .sm\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .sm\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .sm\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .sm\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .sm\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .sm\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .sm\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .sm\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .sm\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .sm\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .sm\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .sm\:border-solid {
    border-style: solid;
  }

  .sm\:border-dashed {
    border-style: dashed;
  }

  .sm\:border-dotted {
    border-style: dotted;
  }

  .sm\:border-double {
    border-style: double;
  }

  .sm\:border-none {
    border-style: none;
  }

  .sm\:border-0 {
    border-width: 0;
  }

  .sm\:border-2 {
    border-width: 2px;
  }

  .sm\:border-4 {
    border-width: 4px;
  }

  .sm\:border-8 {
    border-width: 8px;
  }

  .sm\:border {
    border-width: 1px;
  }

  .sm\:border-t-0 {
    border-top-width: 0;
  }

  .sm\:border-r-0 {
    border-right-width: 0;
  }

  .sm\:border-b-0 {
    border-bottom-width: 0;
  }

  .sm\:border-l-0 {
    border-left-width: 0;
  }

  .sm\:border-t-2 {
    border-top-width: 2px;
  }

  .sm\:border-r-2 {
    border-right-width: 2px;
  }

  .sm\:border-b-2 {
    border-bottom-width: 2px;
  }

  .sm\:border-l-2 {
    border-left-width: 2px;
  }

  .sm\:border-t-4 {
    border-top-width: 4px;
  }

  .sm\:border-r-4 {
    border-right-width: 4px;
  }

  .sm\:border-b-4 {
    border-bottom-width: 4px;
  }

  .sm\:border-l-4 {
    border-left-width: 4px;
  }

  .sm\:border-t-8 {
    border-top-width: 8px;
  }

  .sm\:border-r-8 {
    border-right-width: 8px;
  }

  .sm\:border-b-8 {
    border-bottom-width: 8px;
  }

  .sm\:border-l-8 {
    border-left-width: 8px;
  }

  .sm\:border-t {
    border-top-width: 1px;
  }

  .sm\:border-r {
    border-right-width: 1px;
  }

  .sm\:border-b {
    border-bottom-width: 1px;
  }

  .sm\:border-l {
    border-left-width: 1px;
  }

  .sm\:box-border {
    box-sizing: border-box;
  }

  .sm\:box-content {
    box-sizing: content-box;
  }

  .sm\:cursor-auto {
    cursor: auto;
  }

  .sm\:cursor-default {
    cursor: default;
  }

  .sm\:cursor-pointer {
    cursor: pointer;
  }

  .sm\:cursor-wait {
    cursor: wait;
  }

  .sm\:cursor-text {
    cursor: text;
  }

  .sm\:cursor-move {
    cursor: move;
  }

  .sm\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .sm\:block {
    display: block;
  }

  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:inline-flex {
    display: inline-flex;
  }

  .sm\:table {
    display: table;
  }

  .sm\:table-caption {
    display: table-caption;
  }

  .sm\:table-cell {
    display: table-cell;
  }

  .sm\:table-column {
    display: table-column;
  }

  .sm\:table-column-group {
    display: table-column-group;
  }

  .sm\:table-footer-group {
    display: table-footer-group;
  }

  .sm\:table-header-group {
    display: table-header-group;
  }

  .sm\:table-row-group {
    display: table-row-group;
  }

  .sm\:table-row {
    display: table-row;
  }

  .sm\:flow-root {
    display: flow-root;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:inline-grid {
    display: inline-grid;
  }

  .sm\:contents {
    display: contents;
  }

  .sm\:hidden {
    display: none;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm\:flex-col {
    flex-direction: column;
  }

  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm\:flex-wrap {
    flex-wrap: wrap;
  }

  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm\:flex-no-wrap {
    flex-wrap: nowrap;
  }

  .sm\:place-items-auto {
    place-items: auto;
  }

  .sm\:place-items-start {
    place-items: start;
  }

  .sm\:place-items-end {
    place-items: end;
  }

  .sm\:place-items-center {
    place-items: center;
  }

  .sm\:place-items-stretch {
    place-items: stretch stretch;
  }

  .sm\:place-content-center {
    place-content: center;
  }

  .sm\:place-content-start {
    place-content: start;
  }

  .sm\:place-content-end {
    place-content: end;
  }

  .sm\:place-content-between {
    place-content: space-between;
  }

  .sm\:place-content-around {
    place-content: space-around;
  }

  .sm\:place-content-evenly {
    place-content: space-evenly;
  }

  .sm\:place-content-stretch {
    place-content: stretch;
  }

  .sm\:place-self-auto {
    place-self: auto;
  }

  .sm\:place-self-start {
    place-self: start;
  }

  .sm\:place-self-end {
    place-self: end;
  }

  .sm\:place-self-center {
    place-self: center;
  }

  .sm\:place-self-stretch {
    place-self: stretch stretch;
  }

  .sm\:items-start {
    align-items: flex-start;
  }

  .sm\:items-end {
    align-items: flex-end;
  }

  .sm\:items-center {
    align-items: center;
  }

  .sm\:items-baseline {
    align-items: baseline;
  }

  .sm\:items-stretch {
    align-items: stretch;
  }

  .sm\:content-center {
    align-content: center;
  }

  .sm\:content-start {
    align-content: flex-start;
  }

  .sm\:content-end {
    align-content: flex-end;
  }

  .sm\:content-between {
    align-content: space-between;
  }

  .sm\:content-around {
    align-content: space-around;
  }

  .sm\:content-evenly {
    align-content: space-evenly;
  }

  .sm\:self-auto {
    align-self: auto;
  }

  .sm\:self-start {
    align-self: flex-start;
  }

  .sm\:self-end {
    align-self: flex-end;
  }

  .sm\:self-center {
    align-self: center;
  }

  .sm\:self-stretch {
    align-self: stretch;
  }

  .sm\:justify-items-auto {
    justify-items: auto;
  }

  .sm\:justify-items-start {
    justify-items: start;
  }

  .sm\:justify-items-end {
    justify-items: end;
  }

  .sm\:justify-items-center {
    justify-items: center;
  }

  .sm\:justify-items-stretch {
    justify-items: stretch;
  }

  .sm\:justify-start {
    justify-content: flex-start;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:justify-center {
    justify-content: center;
  }

  .sm\:justify-between {
    justify-content: space-between;
  }

  .sm\:justify-around {
    justify-content: space-around;
  }

  .sm\:justify-evenly {
    justify-content: space-evenly;
  }

  .sm\:justify-self-auto {
    justify-self: auto;
  }

  .sm\:justify-self-start {
    justify-self: start;
  }

  .sm\:justify-self-end {
    justify-self: end;
  }

  .sm\:justify-self-center {
    justify-self: center;
  }

  .sm\:justify-self-stretch {
    justify-self: stretch;
  }

  .sm\:flex-1 {
    flex: 1;
  }

  .sm\:flex-auto {
    flex: auto;
  }

  .sm\:flex-initial {
    flex: 0 auto;
  }

  .sm\:flex-none {
    flex: none;
  }

  .sm\:flex-grow-0 {
    flex-grow: 0;
  }

  .sm\:flex-grow {
    flex-grow: 1;
  }

  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .sm\:flex-shrink {
    flex-shrink: 1;
  }

  .sm\:order-1 {
    order: 1;
  }

  .sm\:order-2 {
    order: 2;
  }

  .sm\:order-3 {
    order: 3;
  }

  .sm\:order-4 {
    order: 4;
  }

  .sm\:order-5 {
    order: 5;
  }

  .sm\:order-6 {
    order: 6;
  }

  .sm\:order-7 {
    order: 7;
  }

  .sm\:order-8 {
    order: 8;
  }

  .sm\:order-9 {
    order: 9;
  }

  .sm\:order-10 {
    order: 10;
  }

  .sm\:order-11 {
    order: 11;
  }

  .sm\:order-12 {
    order: 12;
  }

  .sm\:order-first {
    order: -9999;
  }

  .sm\:order-last {
    order: 9999;
  }

  .sm\:order-none {
    order: 0;
  }

  .sm\:float-right {
    float: right;
  }

  .sm\:float-left {
    float: left;
  }

  .sm\:float-none {
    float: none;
  }

  .sm\:clearfix:after {
    content: "";
    clear: both;
    display: table;
  }

  .sm\:clear-left {
    clear: left;
  }

  .sm\:clear-right {
    clear: right;
  }

  .sm\:clear-both {
    clear: both;
  }

  .sm\:clear-none {
    clear: none;
  }

  .sm\:font-sans {
    font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .sm\:font-serif {
    font-family: Georgia, Cambria, Times New Roman, Times, serif;
  }

  .sm\:font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .sm\:font-ibmmono {
    font-family: IBM Plex Mono;
  }

  .sm\:font-hairline {
    font-weight: 100;
  }

  .sm\:font-thin {
    font-weight: 200;
  }

  .sm\:font-light {
    font-weight: 300;
  }

  .sm\:font-normal {
    font-weight: 400;
  }

  .sm\:font-medium {
    font-weight: 500;
  }

  .sm\:font-semibold {
    font-weight: 600;
  }

  .sm\:font-bold {
    font-weight: 700;
  }

  .sm\:font-extrabold {
    font-weight: 800;
  }

  .sm\:font-black {
    font-weight: 900;
  }

  .sm\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .sm\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .sm\:hover\:font-light:hover {
    font-weight: 300;
  }

  .sm\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .sm\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .sm\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .sm\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .sm\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .sm\:hover\:font-black:hover {
    font-weight: 900;
  }

  .sm\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .sm\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .sm\:focus\:font-light:focus {
    font-weight: 300;
  }

  .sm\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .sm\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .sm\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .sm\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .sm\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .sm\:focus\:font-black:focus {
    font-weight: 900;
  }

  .sm\:h-0 {
    height: 0;
  }

  .sm\:h-1 {
    height: .25rem;
  }

  .sm\:h-2 {
    height: .5rem;
  }

  .sm\:h-3 {
    height: .75rem;
  }

  .sm\:h-4 {
    height: 1rem;
  }

  .sm\:h-5 {
    height: 1.25rem;
  }

  .sm\:h-6 {
    height: 1.5rem;
  }

  .sm\:h-7 {
    height: 1.75rem;
  }

  .sm\:h-8 {
    height: 2rem;
  }

  .sm\:h-9 {
    height: 2.25rem;
  }

  .sm\:h-10 {
    height: 2.5rem;
  }

  .sm\:h-11 {
    height: 2.75rem;
  }

  .sm\:h-12 {
    height: 3rem;
  }

  .sm\:h-13 {
    height: 3.25rem;
  }

  .sm\:h-14 {
    height: 3.5rem;
  }

  .sm\:h-15 {
    height: 3.75rem;
  }

  .sm\:h-16 {
    height: 4rem;
  }

  .sm\:h-20 {
    height: 5rem;
  }

  .sm\:h-24 {
    height: 6rem;
  }

  .sm\:h-28 {
    height: 7rem;
  }

  .sm\:h-32 {
    height: 8rem;
  }

  .sm\:h-36 {
    height: 9rem;
  }

  .sm\:h-40 {
    height: 10rem;
  }

  .sm\:h-44 {
    height: 11rem;
  }

  .sm\:h-48 {
    height: 12rem;
  }

  .sm\:h-52 {
    height: 13rem;
  }

  .sm\:h-56 {
    height: 14rem;
  }

  .sm\:h-60 {
    height: 15rem;
  }

  .sm\:h-64 {
    height: 16rem;
  }

  .sm\:h-72 {
    height: 18rem;
  }

  .sm\:h-80 {
    height: 20rem;
  }

  .sm\:h-96 {
    height: 24rem;
  }

  .sm\:h-auto {
    height: auto;
  }

  .sm\:h-px {
    height: 1px;
  }

  .sm\:h-0\.5 {
    height: .125rem;
  }

  .sm\:h-1\.5 {
    height: .375rem;
  }

  .sm\:h-2\.5 {
    height: .625rem;
  }

  .sm\:h-3\.5 {
    height: .875rem;
  }

  .sm\:h-1\/2 {
    height: 50%;
  }

  .sm\:h-1\/3 {
    height: 33.3333%;
  }

  .sm\:h-2\/3 {
    height: 66.6667%;
  }

  .sm\:h-1\/4 {
    height: 25%;
  }

  .sm\:h-2\/4 {
    height: 50%;
  }

  .sm\:h-3\/4 {
    height: 75%;
  }

  .sm\:h-1\/5 {
    height: 20%;
  }

  .sm\:h-2\/5 {
    height: 40%;
  }

  .sm\:h-3\/5 {
    height: 60%;
  }

  .sm\:h-4\/5 {
    height: 80%;
  }

  .sm\:h-1\/6 {
    height: 16.6667%;
  }

  .sm\:h-2\/6 {
    height: 33.3333%;
  }

  .sm\:h-3\/6 {
    height: 50%;
  }

  .sm\:h-4\/6 {
    height: 66.6667%;
  }

  .sm\:h-5\/6 {
    height: 83.3333%;
  }

  .sm\:h-1\/12 {
    height: 8.33333%;
  }

  .sm\:h-2\/12 {
    height: 16.6667%;
  }

  .sm\:h-3\/12 {
    height: 25%;
  }

  .sm\:h-4\/12 {
    height: 33.3333%;
  }

  .sm\:h-5\/12 {
    height: 41.6667%;
  }

  .sm\:h-6\/12 {
    height: 50%;
  }

  .sm\:h-7\/12 {
    height: 58.3333%;
  }

  .sm\:h-8\/12 {
    height: 66.6667%;
  }

  .sm\:h-9\/12 {
    height: 75%;
  }

  .sm\:h-10\/12 {
    height: 83.3333%;
  }

  .sm\:h-11\/12 {
    height: 91.6667%;
  }

  .sm\:h-full {
    height: 100%;
  }

  .sm\:h-screen {
    height: 100vh;
  }

  .sm\:text-xs {
    font-size: .75rem;
  }

  .sm\:text-sm {
    font-size: .875rem;
  }

  .sm\:text-base {
    font-size: 1rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
  }

  .sm\:text-xl {
    font-size: 1.25rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
  }

  .sm\:text-6xl {
    font-size: 4rem;
  }

  .sm\:leading-3 {
    line-height: .75rem;
  }

  .sm\:leading-4 {
    line-height: 1rem;
  }

  .sm\:leading-5 {
    line-height: 1.25rem;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }

  .sm\:leading-7 {
    line-height: 1.75rem;
  }

  .sm\:leading-8 {
    line-height: 2rem;
  }

  .sm\:leading-9 {
    line-height: 2.25rem;
  }

  .sm\:leading-10 {
    line-height: 2.5rem;
  }

  .sm\:leading-none {
    line-height: 1;
  }

  .sm\:leading-tight {
    line-height: 1.25;
  }

  .sm\:leading-snug {
    line-height: 1.375;
  }

  .sm\:leading-normal {
    line-height: 1.5;
  }

  .sm\:leading-relaxed {
    line-height: 1.625;
  }

  .sm\:leading-loose {
    line-height: 2;
  }

  .sm\:list-inside {
    list-style-position: inside;
  }

  .sm\:list-outside {
    list-style-position: outside;
  }

  .sm\:list-none {
    list-style-type: none;
  }

  .sm\:list-disc {
    list-style-type: disc;
  }

  .sm\:list-decimal {
    list-style-type: decimal;
  }

  .sm\:m-0 {
    margin: 0;
  }

  .sm\:m-1 {
    margin: .25rem;
  }

  .sm\:m-2 {
    margin: .5rem;
  }

  .sm\:m-3 {
    margin: .75rem;
  }

  .sm\:m-4 {
    margin: 1rem;
  }

  .sm\:m-5 {
    margin: 1.25rem;
  }

  .sm\:m-6 {
    margin: 1.5rem;
  }

  .sm\:m-7 {
    margin: 1.75rem;
  }

  .sm\:m-8 {
    margin: 2rem;
  }

  .sm\:m-9 {
    margin: 2.25rem;
  }

  .sm\:m-10 {
    margin: 2.5rem;
  }

  .sm\:m-11 {
    margin: 2.75rem;
  }

  .sm\:m-12 {
    margin: 3rem;
  }

  .sm\:m-13 {
    margin: 3.25rem;
  }

  .sm\:m-14 {
    margin: 3.5rem;
  }

  .sm\:m-15 {
    margin: 3.75rem;
  }

  .sm\:m-16 {
    margin: 4rem;
  }

  .sm\:m-20 {
    margin: 5rem;
  }

  .sm\:m-24 {
    margin: 6rem;
  }

  .sm\:m-28 {
    margin: 7rem;
  }

  .sm\:m-32 {
    margin: 8rem;
  }

  .sm\:m-36 {
    margin: 9rem;
  }

  .sm\:m-40 {
    margin: 10rem;
  }

  .sm\:m-44 {
    margin: 11rem;
  }

  .sm\:m-48 {
    margin: 12rem;
  }

  .sm\:m-52 {
    margin: 13rem;
  }

  .sm\:m-56 {
    margin: 14rem;
  }

  .sm\:m-60 {
    margin: 15rem;
  }

  .sm\:m-64 {
    margin: 16rem;
  }

  .sm\:m-72 {
    margin: 18rem;
  }

  .sm\:m-80 {
    margin: 20rem;
  }

  .sm\:m-96 {
    margin: 24rem;
  }

  .sm\:m-auto {
    margin: auto;
  }

  .sm\:m-px {
    margin: 1px;
  }

  .sm\:m-0\.5 {
    margin: .125rem;
  }

  .sm\:m-1\.5 {
    margin: .375rem;
  }

  .sm\:m-2\.5 {
    margin: .625rem;
  }

  .sm\:m-3\.5 {
    margin: .875rem;
  }

  .sm\:m-1\/2 {
    margin: 50%;
  }

  .sm\:m-1\/3 {
    margin: 33.3333%;
  }

  .sm\:m-2\/3 {
    margin: 66.6667%;
  }

  .sm\:m-1\/4 {
    margin: 25%;
  }

  .sm\:m-2\/4 {
    margin: 50%;
  }

  .sm\:m-3\/4 {
    margin: 75%;
  }

  .sm\:m-1\/5 {
    margin: 20%;
  }

  .sm\:m-2\/5 {
    margin: 40%;
  }

  .sm\:m-3\/5 {
    margin: 60%;
  }

  .sm\:m-4\/5 {
    margin: 80%;
  }

  .sm\:m-1\/6 {
    margin: 16.6667%;
  }

  .sm\:m-2\/6 {
    margin: 33.3333%;
  }

  .sm\:m-3\/6 {
    margin: 50%;
  }

  .sm\:m-4\/6 {
    margin: 66.6667%;
  }

  .sm\:m-5\/6 {
    margin: 83.3333%;
  }

  .sm\:m-1\/12 {
    margin: 8.33333%;
  }

  .sm\:m-2\/12 {
    margin: 16.6667%;
  }

  .sm\:m-3\/12 {
    margin: 25%;
  }

  .sm\:m-4\/12 {
    margin: 33.3333%;
  }

  .sm\:m-5\/12 {
    margin: 41.6667%;
  }

  .sm\:m-6\/12 {
    margin: 50%;
  }

  .sm\:m-7\/12 {
    margin: 58.3333%;
  }

  .sm\:m-8\/12 {
    margin: 66.6667%;
  }

  .sm\:m-9\/12 {
    margin: 75%;
  }

  .sm\:m-10\/12 {
    margin: 83.3333%;
  }

  .sm\:m-11\/12 {
    margin: 91.6667%;
  }

  .sm\:m-full {
    margin: 100%;
  }

  .sm\:-m-1 {
    margin: -.25rem;
  }

  .sm\:-m-2 {
    margin: -.5rem;
  }

  .sm\:-m-3 {
    margin: -.75rem;
  }

  .sm\:-m-4 {
    margin: -1rem;
  }

  .sm\:-m-5 {
    margin: -1.25rem;
  }

  .sm\:-m-6 {
    margin: -1.5rem;
  }

  .sm\:-m-7 {
    margin: -1.75rem;
  }

  .sm\:-m-8 {
    margin: -2rem;
  }

  .sm\:-m-9 {
    margin: -2.25rem;
  }

  .sm\:-m-10 {
    margin: -2.5rem;
  }

  .sm\:-m-11 {
    margin: -2.75rem;
  }

  .sm\:-m-12 {
    margin: -3rem;
  }

  .sm\:-m-13 {
    margin: -3.25rem;
  }

  .sm\:-m-14 {
    margin: -3.5rem;
  }

  .sm\:-m-15 {
    margin: -3.75rem;
  }

  .sm\:-m-16 {
    margin: -4rem;
  }

  .sm\:-m-20 {
    margin: -5rem;
  }

  .sm\:-m-24 {
    margin: -6rem;
  }

  .sm\:-m-28 {
    margin: -7rem;
  }

  .sm\:-m-32 {
    margin: -8rem;
  }

  .sm\:-m-36 {
    margin: -9rem;
  }

  .sm\:-m-40 {
    margin: -10rem;
  }

  .sm\:-m-44 {
    margin: -11rem;
  }

  .sm\:-m-48 {
    margin: -12rem;
  }

  .sm\:-m-52 {
    margin: -13rem;
  }

  .sm\:-m-56 {
    margin: -14rem;
  }

  .sm\:-m-60 {
    margin: -15rem;
  }

  .sm\:-m-64 {
    margin: -16rem;
  }

  .sm\:-m-72 {
    margin: -18rem;
  }

  .sm\:-m-80 {
    margin: -20rem;
  }

  .sm\:-m-96 {
    margin: -24rem;
  }

  .sm\:-m-px {
    margin: -1px;
  }

  .sm\:-m-0\.5 {
    margin: -.125rem;
  }

  .sm\:-m-1\.5 {
    margin: -.375rem;
  }

  .sm\:-m-2\.5 {
    margin: -.625rem;
  }

  .sm\:-m-3\.5 {
    margin: -.875rem;
  }

  .sm\:-m-1\/2 {
    margin: -50%;
  }

  .sm\:-m-1\/3 {
    margin: -33.3333%;
  }

  .sm\:-m-2\/3 {
    margin: -66.6667%;
  }

  .sm\:-m-1\/4 {
    margin: -25%;
  }

  .sm\:-m-2\/4 {
    margin: -50%;
  }

  .sm\:-m-3\/4 {
    margin: -75%;
  }

  .sm\:-m-1\/5 {
    margin: -20%;
  }

  .sm\:-m-2\/5 {
    margin: -40%;
  }

  .sm\:-m-3\/5 {
    margin: -60%;
  }

  .sm\:-m-4\/5 {
    margin: -80%;
  }

  .sm\:-m-1\/6 {
    margin: -16.6667%;
  }

  .sm\:-m-2\/6 {
    margin: -33.3333%;
  }

  .sm\:-m-3\/6 {
    margin: -50%;
  }

  .sm\:-m-4\/6 {
    margin: -66.6667%;
  }

  .sm\:-m-5\/6 {
    margin: -83.3333%;
  }

  .sm\:-m-1\/12 {
    margin: -8.33333%;
  }

  .sm\:-m-2\/12 {
    margin: -16.6667%;
  }

  .sm\:-m-3\/12 {
    margin: -25%;
  }

  .sm\:-m-4\/12 {
    margin: -33.3333%;
  }

  .sm\:-m-5\/12 {
    margin: -41.6667%;
  }

  .sm\:-m-6\/12 {
    margin: -50%;
  }

  .sm\:-m-7\/12 {
    margin: -58.3333%;
  }

  .sm\:-m-8\/12 {
    margin: -66.6667%;
  }

  .sm\:-m-9\/12 {
    margin: -75%;
  }

  .sm\:-m-10\/12 {
    margin: -83.3333%;
  }

  .sm\:-m-11\/12 {
    margin: -91.6667%;
  }

  .sm\:-m-full {
    margin: -100%;
  }

  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .sm\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .sm\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .sm\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .sm\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .sm\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .sm\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .sm\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .sm\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .sm\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .sm\:my-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem;
  }

  .sm\:mx-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem;
  }

  .sm\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .sm\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .sm\:my-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .sm\:mx-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .sm\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .sm\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .sm\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .sm\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .sm\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .sm\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .sm\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .sm\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .sm\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .sm\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .sm\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .sm\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .sm\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .sm\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .sm\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .sm\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .sm\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .sm\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .sm\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .sm\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .sm\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .sm\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .sm\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .sm\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .sm\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .sm\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .sm\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .sm\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .sm\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .sm\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .sm\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .sm\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .sm\:my-1\/2 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .sm\:mx-1\/2 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .sm\:my-1\/3 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .sm\:mx-1\/3 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .sm\:my-2\/3 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .sm\:mx-2\/3 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .sm\:my-1\/4 {
    margin-top: 25%;
    margin-bottom: 25%;
  }

  .sm\:mx-1\/4 {
    margin-left: 25%;
    margin-right: 25%;
  }

  .sm\:my-2\/4 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .sm\:mx-2\/4 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .sm\:my-3\/4 {
    margin-top: 75%;
    margin-bottom: 75%;
  }

  .sm\:mx-3\/4 {
    margin-left: 75%;
    margin-right: 75%;
  }

  .sm\:my-1\/5 {
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .sm\:mx-1\/5 {
    margin-left: 20%;
    margin-right: 20%;
  }

  .sm\:my-2\/5 {
    margin-top: 40%;
    margin-bottom: 40%;
  }

  .sm\:mx-2\/5 {
    margin-left: 40%;
    margin-right: 40%;
  }

  .sm\:my-3\/5 {
    margin-top: 60%;
    margin-bottom: 60%;
  }

  .sm\:mx-3\/5 {
    margin-left: 60%;
    margin-right: 60%;
  }

  .sm\:my-4\/5 {
    margin-top: 80%;
    margin-bottom: 80%;
  }

  .sm\:mx-4\/5 {
    margin-left: 80%;
    margin-right: 80%;
  }

  .sm\:my-1\/6 {
    margin-top: 16.6667%;
    margin-bottom: 16.6667%;
  }

  .sm\:mx-1\/6 {
    margin-left: 16.6667%;
    margin-right: 16.6667%;
  }

  .sm\:my-2\/6 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .sm\:mx-2\/6 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .sm\:my-3\/6 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .sm\:mx-3\/6 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .sm\:my-4\/6 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .sm\:mx-4\/6 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .sm\:my-5\/6 {
    margin-top: 83.3333%;
    margin-bottom: 83.3333%;
  }

  .sm\:mx-5\/6 {
    margin-left: 83.3333%;
    margin-right: 83.3333%;
  }

  .sm\:my-1\/12 {
    margin-top: 8.33333%;
    margin-bottom: 8.33333%;
  }

  .sm\:mx-1\/12 {
    margin-left: 8.33333%;
    margin-right: 8.33333%;
  }

  .sm\:my-2\/12 {
    margin-top: 16.6667%;
    margin-bottom: 16.6667%;
  }

  .sm\:mx-2\/12 {
    margin-left: 16.6667%;
    margin-right: 16.6667%;
  }

  .sm\:my-3\/12 {
    margin-top: 25%;
    margin-bottom: 25%;
  }

  .sm\:mx-3\/12 {
    margin-left: 25%;
    margin-right: 25%;
  }

  .sm\:my-4\/12 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .sm\:mx-4\/12 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .sm\:my-5\/12 {
    margin-top: 41.6667%;
    margin-bottom: 41.6667%;
  }

  .sm\:mx-5\/12 {
    margin-left: 41.6667%;
    margin-right: 41.6667%;
  }

  .sm\:my-6\/12 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .sm\:mx-6\/12 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .sm\:my-7\/12 {
    margin-top: 58.3333%;
    margin-bottom: 58.3333%;
  }

  .sm\:mx-7\/12 {
    margin-left: 58.3333%;
    margin-right: 58.3333%;
  }

  .sm\:my-8\/12 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .sm\:mx-8\/12 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .sm\:my-9\/12 {
    margin-top: 75%;
    margin-bottom: 75%;
  }

  .sm\:mx-9\/12 {
    margin-left: 75%;
    margin-right: 75%;
  }

  .sm\:my-10\/12 {
    margin-top: 83.3333%;
    margin-bottom: 83.3333%;
  }

  .sm\:mx-10\/12 {
    margin-left: 83.3333%;
    margin-right: 83.3333%;
  }

  .sm\:my-11\/12 {
    margin-top: 91.6667%;
    margin-bottom: 91.6667%;
  }

  .sm\:mx-11\/12 {
    margin-left: 91.6667%;
    margin-right: 91.6667%;
  }

  .sm\:my-full {
    margin-top: 100%;
    margin-bottom: 100%;
  }

  .sm\:mx-full {
    margin-left: 100%;
    margin-right: 100%;
  }

  .sm\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .sm\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .sm\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .sm\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .sm\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .sm\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .sm\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .sm\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .sm\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .sm\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .sm\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .sm\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .sm\:-my-13 {
    margin-top: -3.25rem;
    margin-bottom: -3.25rem;
  }

  .sm\:-mx-13 {
    margin-left: -3.25rem;
    margin-right: -3.25rem;
  }

  .sm\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .sm\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .sm\:-my-15 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }

  .sm\:-mx-15 {
    margin-left: -3.75rem;
    margin-right: -3.75rem;
  }

  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .sm\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .sm\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .sm\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .sm\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .sm\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .sm\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .sm\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .sm\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .sm\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .sm\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .sm\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .sm\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .sm\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .sm\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .sm\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .sm\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .sm\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .sm\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .sm\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .sm\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .sm\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .sm\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .sm\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .sm\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .sm\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .sm\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .sm\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .sm\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .sm\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .sm\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .sm\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .sm\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .sm\:-my-1\/2 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .sm\:-mx-1\/2 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .sm\:-my-1\/3 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .sm\:-mx-1\/3 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .sm\:-my-2\/3 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .sm\:-mx-2\/3 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .sm\:-my-1\/4 {
    margin-top: -25%;
    margin-bottom: -25%;
  }

  .sm\:-mx-1\/4 {
    margin-left: -25%;
    margin-right: -25%;
  }

  .sm\:-my-2\/4 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .sm\:-mx-2\/4 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .sm\:-my-3\/4 {
    margin-top: -75%;
    margin-bottom: -75%;
  }

  .sm\:-mx-3\/4 {
    margin-left: -75%;
    margin-right: -75%;
  }

  .sm\:-my-1\/5 {
    margin-top: -20%;
    margin-bottom: -20%;
  }

  .sm\:-mx-1\/5 {
    margin-left: -20%;
    margin-right: -20%;
  }

  .sm\:-my-2\/5 {
    margin-top: -40%;
    margin-bottom: -40%;
  }

  .sm\:-mx-2\/5 {
    margin-left: -40%;
    margin-right: -40%;
  }

  .sm\:-my-3\/5 {
    margin-top: -60%;
    margin-bottom: -60%;
  }

  .sm\:-mx-3\/5 {
    margin-left: -60%;
    margin-right: -60%;
  }

  .sm\:-my-4\/5 {
    margin-top: -80%;
    margin-bottom: -80%;
  }

  .sm\:-mx-4\/5 {
    margin-left: -80%;
    margin-right: -80%;
  }

  .sm\:-my-1\/6 {
    margin-top: -16.6667%;
    margin-bottom: -16.6667%;
  }

  .sm\:-mx-1\/6 {
    margin-left: -16.6667%;
    margin-right: -16.6667%;
  }

  .sm\:-my-2\/6 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .sm\:-mx-2\/6 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .sm\:-my-3\/6 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .sm\:-mx-3\/6 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .sm\:-my-4\/6 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .sm\:-mx-4\/6 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .sm\:-my-5\/6 {
    margin-top: -83.3333%;
    margin-bottom: -83.3333%;
  }

  .sm\:-mx-5\/6 {
    margin-left: -83.3333%;
    margin-right: -83.3333%;
  }

  .sm\:-my-1\/12 {
    margin-top: -8.33333%;
    margin-bottom: -8.33333%;
  }

  .sm\:-mx-1\/12 {
    margin-left: -8.33333%;
    margin-right: -8.33333%;
  }

  .sm\:-my-2\/12 {
    margin-top: -16.6667%;
    margin-bottom: -16.6667%;
  }

  .sm\:-mx-2\/12 {
    margin-left: -16.6667%;
    margin-right: -16.6667%;
  }

  .sm\:-my-3\/12 {
    margin-top: -25%;
    margin-bottom: -25%;
  }

  .sm\:-mx-3\/12 {
    margin-left: -25%;
    margin-right: -25%;
  }

  .sm\:-my-4\/12 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .sm\:-mx-4\/12 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .sm\:-my-5\/12 {
    margin-top: -41.6667%;
    margin-bottom: -41.6667%;
  }

  .sm\:-mx-5\/12 {
    margin-left: -41.6667%;
    margin-right: -41.6667%;
  }

  .sm\:-my-6\/12 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .sm\:-mx-6\/12 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .sm\:-my-7\/12 {
    margin-top: -58.3333%;
    margin-bottom: -58.3333%;
  }

  .sm\:-mx-7\/12 {
    margin-left: -58.3333%;
    margin-right: -58.3333%;
  }

  .sm\:-my-8\/12 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .sm\:-mx-8\/12 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .sm\:-my-9\/12 {
    margin-top: -75%;
    margin-bottom: -75%;
  }

  .sm\:-mx-9\/12 {
    margin-left: -75%;
    margin-right: -75%;
  }

  .sm\:-my-10\/12 {
    margin-top: -83.3333%;
    margin-bottom: -83.3333%;
  }

  .sm\:-mx-10\/12 {
    margin-left: -83.3333%;
    margin-right: -83.3333%;
  }

  .sm\:-my-11\/12 {
    margin-top: -91.6667%;
    margin-bottom: -91.6667%;
  }

  .sm\:-mx-11\/12 {
    margin-left: -91.6667%;
    margin-right: -91.6667%;
  }

  .sm\:-my-full {
    margin-top: -100%;
    margin-bottom: -100%;
  }

  .sm\:-mx-full {
    margin-left: -100%;
    margin-right: -100%;
  }

  .sm\:mt-0 {
    margin-top: 0;
  }

  .sm\:mr-0 {
    margin-right: 0;
  }

  .sm\:mb-0 {
    margin-bottom: 0;
  }

  .sm\:ml-0 {
    margin-left: 0;
  }

  .sm\:mt-1 {
    margin-top: .25rem;
  }

  .sm\:mr-1 {
    margin-right: .25rem;
  }

  .sm\:mb-1 {
    margin-bottom: .25rem;
  }

  .sm\:ml-1 {
    margin-left: .25rem;
  }

  .sm\:mt-2 {
    margin-top: .5rem;
  }

  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:mb-2 {
    margin-bottom: .5rem;
  }

  .sm\:ml-2 {
    margin-left: .5rem;
  }

  .sm\:mt-3 {
    margin-top: .75rem;
  }

  .sm\:mr-3 {
    margin-right: .75rem;
  }

  .sm\:mb-3 {
    margin-bottom: .75rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:mt-4 {
    margin-top: 1rem;
  }

  .sm\:mr-4 {
    margin-right: 1rem;
  }

  .sm\:mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:mt-7 {
    margin-top: 1.75rem;
  }

  .sm\:mr-7 {
    margin-right: 1.75rem;
  }

  .sm\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .sm\:ml-7 {
    margin-left: 1.75rem;
  }

  .sm\:mt-8 {
    margin-top: 2rem;
  }

  .sm\:mr-8 {
    margin-right: 2rem;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:ml-8 {
    margin-left: 2rem;
  }

  .sm\:mt-9 {
    margin-top: 2.25rem;
  }

  .sm\:mr-9 {
    margin-right: 2.25rem;
  }

  .sm\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .sm\:ml-9 {
    margin-left: 2.25rem;
  }

  .sm\:mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:mt-11 {
    margin-top: 2.75rem;
  }

  .sm\:mr-11 {
    margin-right: 2.75rem;
  }

  .sm\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .sm\:ml-11 {
    margin-left: 2.75rem;
  }

  .sm\:mt-12 {
    margin-top: 3rem;
  }

  .sm\:mr-12 {
    margin-right: 3rem;
  }

  .sm\:mb-12 {
    margin-bottom: 3rem;
  }

  .sm\:ml-12 {
    margin-left: 3rem;
  }

  .sm\:mt-13 {
    margin-top: 3.25rem;
  }

  .sm\:mr-13 {
    margin-right: 3.25rem;
  }

  .sm\:mb-13 {
    margin-bottom: 3.25rem;
  }

  .sm\:ml-13 {
    margin-left: 3.25rem;
  }

  .sm\:mt-14 {
    margin-top: 3.5rem;
  }

  .sm\:mr-14 {
    margin-right: 3.5rem;
  }

  .sm\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .sm\:ml-14 {
    margin-left: 3.5rem;
  }

  .sm\:mt-15 {
    margin-top: 3.75rem;
  }

  .sm\:mr-15 {
    margin-right: 3.75rem;
  }

  .sm\:mb-15 {
    margin-bottom: 3.75rem;
  }

  .sm\:ml-15 {
    margin-left: 3.75rem;
  }

  .sm\:mt-16 {
    margin-top: 4rem;
  }

  .sm\:mr-16 {
    margin-right: 4rem;
  }

  .sm\:mb-16 {
    margin-bottom: 4rem;
  }

  .sm\:ml-16 {
    margin-left: 4rem;
  }

  .sm\:mt-20 {
    margin-top: 5rem;
  }

  .sm\:mr-20 {
    margin-right: 5rem;
  }

  .sm\:mb-20 {
    margin-bottom: 5rem;
  }

  .sm\:ml-20 {
    margin-left: 5rem;
  }

  .sm\:mt-24 {
    margin-top: 6rem;
  }

  .sm\:mr-24 {
    margin-right: 6rem;
  }

  .sm\:mb-24 {
    margin-bottom: 6rem;
  }

  .sm\:ml-24 {
    margin-left: 6rem;
  }

  .sm\:mt-28 {
    margin-top: 7rem;
  }

  .sm\:mr-28 {
    margin-right: 7rem;
  }

  .sm\:mb-28 {
    margin-bottom: 7rem;
  }

  .sm\:ml-28 {
    margin-left: 7rem;
  }

  .sm\:mt-32 {
    margin-top: 8rem;
  }

  .sm\:mr-32 {
    margin-right: 8rem;
  }

  .sm\:mb-32 {
    margin-bottom: 8rem;
  }

  .sm\:ml-32 {
    margin-left: 8rem;
  }

  .sm\:mt-36 {
    margin-top: 9rem;
  }

  .sm\:mr-36 {
    margin-right: 9rem;
  }

  .sm\:mb-36 {
    margin-bottom: 9rem;
  }

  .sm\:ml-36 {
    margin-left: 9rem;
  }

  .sm\:mt-40 {
    margin-top: 10rem;
  }

  .sm\:mr-40 {
    margin-right: 10rem;
  }

  .sm\:mb-40 {
    margin-bottom: 10rem;
  }

  .sm\:ml-40 {
    margin-left: 10rem;
  }

  .sm\:mt-44 {
    margin-top: 11rem;
  }

  .sm\:mr-44 {
    margin-right: 11rem;
  }

  .sm\:mb-44 {
    margin-bottom: 11rem;
  }

  .sm\:ml-44 {
    margin-left: 11rem;
  }

  .sm\:mt-48 {
    margin-top: 12rem;
  }

  .sm\:mr-48 {
    margin-right: 12rem;
  }

  .sm\:mb-48 {
    margin-bottom: 12rem;
  }

  .sm\:ml-48 {
    margin-left: 12rem;
  }

  .sm\:mt-52 {
    margin-top: 13rem;
  }

  .sm\:mr-52 {
    margin-right: 13rem;
  }

  .sm\:mb-52 {
    margin-bottom: 13rem;
  }

  .sm\:ml-52 {
    margin-left: 13rem;
  }

  .sm\:mt-56 {
    margin-top: 14rem;
  }

  .sm\:mr-56 {
    margin-right: 14rem;
  }

  .sm\:mb-56 {
    margin-bottom: 14rem;
  }

  .sm\:ml-56 {
    margin-left: 14rem;
  }

  .sm\:mt-60 {
    margin-top: 15rem;
  }

  .sm\:mr-60 {
    margin-right: 15rem;
  }

  .sm\:mb-60 {
    margin-bottom: 15rem;
  }

  .sm\:ml-60 {
    margin-left: 15rem;
  }

  .sm\:mt-64 {
    margin-top: 16rem;
  }

  .sm\:mr-64 {
    margin-right: 16rem;
  }

  .sm\:mb-64 {
    margin-bottom: 16rem;
  }

  .sm\:ml-64 {
    margin-left: 16rem;
  }

  .sm\:mt-72 {
    margin-top: 18rem;
  }

  .sm\:mr-72 {
    margin-right: 18rem;
  }

  .sm\:mb-72 {
    margin-bottom: 18rem;
  }

  .sm\:ml-72 {
    margin-left: 18rem;
  }

  .sm\:mt-80 {
    margin-top: 20rem;
  }

  .sm\:mr-80 {
    margin-right: 20rem;
  }

  .sm\:mb-80 {
    margin-bottom: 20rem;
  }

  .sm\:ml-80 {
    margin-left: 20rem;
  }

  .sm\:mt-96 {
    margin-top: 24rem;
  }

  .sm\:mr-96 {
    margin-right: 24rem;
  }

  .sm\:mb-96 {
    margin-bottom: 24rem;
  }

  .sm\:ml-96 {
    margin-left: 24rem;
  }

  .sm\:mt-auto {
    margin-top: auto;
  }

  .sm\:mr-auto {
    margin-right: auto;
  }

  .sm\:mb-auto {
    margin-bottom: auto;
  }

  .sm\:ml-auto {
    margin-left: auto;
  }

  .sm\:mt-px {
    margin-top: 1px;
  }

  .sm\:mr-px {
    margin-right: 1px;
  }

  .sm\:mb-px {
    margin-bottom: 1px;
  }

  .sm\:ml-px {
    margin-left: 1px;
  }

  .sm\:mt-0\.5 {
    margin-top: .125rem;
  }

  .sm\:mr-0\.5 {
    margin-right: .125rem;
  }

  .sm\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .sm\:ml-0\.5 {
    margin-left: .125rem;
  }

  .sm\:mt-1\.5 {
    margin-top: .375rem;
  }

  .sm\:mr-1\.5 {
    margin-right: .375rem;
  }

  .sm\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .sm\:ml-1\.5 {
    margin-left: .375rem;
  }

  .sm\:mt-2\.5 {
    margin-top: .625rem;
  }

  .sm\:mr-2\.5 {
    margin-right: .625rem;
  }

  .sm\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .sm\:ml-2\.5 {
    margin-left: .625rem;
  }

  .sm\:mt-3\.5 {
    margin-top: .875rem;
  }

  .sm\:mr-3\.5 {
    margin-right: .875rem;
  }

  .sm\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .sm\:ml-3\.5 {
    margin-left: .875rem;
  }

  .sm\:mt-1\/2 {
    margin-top: 50%;
  }

  .sm\:mr-1\/2 {
    margin-right: 50%;
  }

  .sm\:mb-1\/2 {
    margin-bottom: 50%;
  }

  .sm\:ml-1\/2 {
    margin-left: 50%;
  }

  .sm\:mt-1\/3 {
    margin-top: 33.3333%;
  }

  .sm\:mr-1\/3 {
    margin-right: 33.3333%;
  }

  .sm\:mb-1\/3 {
    margin-bottom: 33.3333%;
  }

  .sm\:ml-1\/3 {
    margin-left: 33.3333%;
  }

  .sm\:mt-2\/3 {
    margin-top: 66.6667%;
  }

  .sm\:mr-2\/3 {
    margin-right: 66.6667%;
  }

  .sm\:mb-2\/3 {
    margin-bottom: 66.6667%;
  }

  .sm\:ml-2\/3 {
    margin-left: 66.6667%;
  }

  .sm\:mt-1\/4 {
    margin-top: 25%;
  }

  .sm\:mr-1\/4 {
    margin-right: 25%;
  }

  .sm\:mb-1\/4 {
    margin-bottom: 25%;
  }

  .sm\:ml-1\/4 {
    margin-left: 25%;
  }

  .sm\:mt-2\/4 {
    margin-top: 50%;
  }

  .sm\:mr-2\/4 {
    margin-right: 50%;
  }

  .sm\:mb-2\/4 {
    margin-bottom: 50%;
  }

  .sm\:ml-2\/4 {
    margin-left: 50%;
  }

  .sm\:mt-3\/4 {
    margin-top: 75%;
  }

  .sm\:mr-3\/4 {
    margin-right: 75%;
  }

  .sm\:mb-3\/4 {
    margin-bottom: 75%;
  }

  .sm\:ml-3\/4 {
    margin-left: 75%;
  }

  .sm\:mt-1\/5 {
    margin-top: 20%;
  }

  .sm\:mr-1\/5 {
    margin-right: 20%;
  }

  .sm\:mb-1\/5 {
    margin-bottom: 20%;
  }

  .sm\:ml-1\/5 {
    margin-left: 20%;
  }

  .sm\:mt-2\/5 {
    margin-top: 40%;
  }

  .sm\:mr-2\/5 {
    margin-right: 40%;
  }

  .sm\:mb-2\/5 {
    margin-bottom: 40%;
  }

  .sm\:ml-2\/5 {
    margin-left: 40%;
  }

  .sm\:mt-3\/5 {
    margin-top: 60%;
  }

  .sm\:mr-3\/5 {
    margin-right: 60%;
  }

  .sm\:mb-3\/5 {
    margin-bottom: 60%;
  }

  .sm\:ml-3\/5 {
    margin-left: 60%;
  }

  .sm\:mt-4\/5 {
    margin-top: 80%;
  }

  .sm\:mr-4\/5 {
    margin-right: 80%;
  }

  .sm\:mb-4\/5 {
    margin-bottom: 80%;
  }

  .sm\:ml-4\/5 {
    margin-left: 80%;
  }

  .sm\:mt-1\/6 {
    margin-top: 16.6667%;
  }

  .sm\:mr-1\/6 {
    margin-right: 16.6667%;
  }

  .sm\:mb-1\/6 {
    margin-bottom: 16.6667%;
  }

  .sm\:ml-1\/6 {
    margin-left: 16.6667%;
  }

  .sm\:mt-2\/6 {
    margin-top: 33.3333%;
  }

  .sm\:mr-2\/6 {
    margin-right: 33.3333%;
  }

  .sm\:mb-2\/6 {
    margin-bottom: 33.3333%;
  }

  .sm\:ml-2\/6 {
    margin-left: 33.3333%;
  }

  .sm\:mt-3\/6 {
    margin-top: 50%;
  }

  .sm\:mr-3\/6 {
    margin-right: 50%;
  }

  .sm\:mb-3\/6 {
    margin-bottom: 50%;
  }

  .sm\:ml-3\/6 {
    margin-left: 50%;
  }

  .sm\:mt-4\/6 {
    margin-top: 66.6667%;
  }

  .sm\:mr-4\/6 {
    margin-right: 66.6667%;
  }

  .sm\:mb-4\/6 {
    margin-bottom: 66.6667%;
  }

  .sm\:ml-4\/6 {
    margin-left: 66.6667%;
  }

  .sm\:mt-5\/6 {
    margin-top: 83.3333%;
  }

  .sm\:mr-5\/6 {
    margin-right: 83.3333%;
  }

  .sm\:mb-5\/6 {
    margin-bottom: 83.3333%;
  }

  .sm\:ml-5\/6 {
    margin-left: 83.3333%;
  }

  .sm\:mt-1\/12 {
    margin-top: 8.33333%;
  }

  .sm\:mr-1\/12 {
    margin-right: 8.33333%;
  }

  .sm\:mb-1\/12 {
    margin-bottom: 8.33333%;
  }

  .sm\:ml-1\/12 {
    margin-left: 8.33333%;
  }

  .sm\:mt-2\/12 {
    margin-top: 16.6667%;
  }

  .sm\:mr-2\/12 {
    margin-right: 16.6667%;
  }

  .sm\:mb-2\/12 {
    margin-bottom: 16.6667%;
  }

  .sm\:ml-2\/12 {
    margin-left: 16.6667%;
  }

  .sm\:mt-3\/12 {
    margin-top: 25%;
  }

  .sm\:mr-3\/12 {
    margin-right: 25%;
  }

  .sm\:mb-3\/12 {
    margin-bottom: 25%;
  }

  .sm\:ml-3\/12 {
    margin-left: 25%;
  }

  .sm\:mt-4\/12 {
    margin-top: 33.3333%;
  }

  .sm\:mr-4\/12 {
    margin-right: 33.3333%;
  }

  .sm\:mb-4\/12 {
    margin-bottom: 33.3333%;
  }

  .sm\:ml-4\/12 {
    margin-left: 33.3333%;
  }

  .sm\:mt-5\/12 {
    margin-top: 41.6667%;
  }

  .sm\:mr-5\/12 {
    margin-right: 41.6667%;
  }

  .sm\:mb-5\/12 {
    margin-bottom: 41.6667%;
  }

  .sm\:ml-5\/12 {
    margin-left: 41.6667%;
  }

  .sm\:mt-6\/12 {
    margin-top: 50%;
  }

  .sm\:mr-6\/12 {
    margin-right: 50%;
  }

  .sm\:mb-6\/12 {
    margin-bottom: 50%;
  }

  .sm\:ml-6\/12 {
    margin-left: 50%;
  }

  .sm\:mt-7\/12 {
    margin-top: 58.3333%;
  }

  .sm\:mr-7\/12 {
    margin-right: 58.3333%;
  }

  .sm\:mb-7\/12 {
    margin-bottom: 58.3333%;
  }

  .sm\:ml-7\/12 {
    margin-left: 58.3333%;
  }

  .sm\:mt-8\/12 {
    margin-top: 66.6667%;
  }

  .sm\:mr-8\/12 {
    margin-right: 66.6667%;
  }

  .sm\:mb-8\/12 {
    margin-bottom: 66.6667%;
  }

  .sm\:ml-8\/12 {
    margin-left: 66.6667%;
  }

  .sm\:mt-9\/12 {
    margin-top: 75%;
  }

  .sm\:mr-9\/12 {
    margin-right: 75%;
  }

  .sm\:mb-9\/12 {
    margin-bottom: 75%;
  }

  .sm\:ml-9\/12 {
    margin-left: 75%;
  }

  .sm\:mt-10\/12 {
    margin-top: 83.3333%;
  }

  .sm\:mr-10\/12 {
    margin-right: 83.3333%;
  }

  .sm\:mb-10\/12 {
    margin-bottom: 83.3333%;
  }

  .sm\:ml-10\/12 {
    margin-left: 83.3333%;
  }

  .sm\:mt-11\/12 {
    margin-top: 91.6667%;
  }

  .sm\:mr-11\/12 {
    margin-right: 91.6667%;
  }

  .sm\:mb-11\/12 {
    margin-bottom: 91.6667%;
  }

  .sm\:ml-11\/12 {
    margin-left: 91.6667%;
  }

  .sm\:mt-full {
    margin-top: 100%;
  }

  .sm\:mr-full {
    margin-right: 100%;
  }

  .sm\:mb-full {
    margin-bottom: 100%;
  }

  .sm\:ml-full {
    margin-left: 100%;
  }

  .sm\:-mt-1 {
    margin-top: -.25rem;
  }

  .sm\:-mr-1 {
    margin-right: -.25rem;
  }

  .sm\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .sm\:-ml-1 {
    margin-left: -.25rem;
  }

  .sm\:-mt-2 {
    margin-top: -.5rem;
  }

  .sm\:-mr-2 {
    margin-right: -.5rem;
  }

  .sm\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .sm\:-ml-2 {
    margin-left: -.5rem;
  }

  .sm\:-mt-3 {
    margin-top: -.75rem;
  }

  .sm\:-mr-3 {
    margin-right: -.75rem;
  }

  .sm\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .sm\:-ml-3 {
    margin-left: -.75rem;
  }

  .sm\:-mt-4 {
    margin-top: -1rem;
  }

  .sm\:-mr-4 {
    margin-right: -1rem;
  }

  .sm\:-mb-4 {
    margin-bottom: -1rem;
  }

  .sm\:-ml-4 {
    margin-left: -1rem;
  }

  .sm\:-mt-5 {
    margin-top: -1.25rem;
  }

  .sm\:-mr-5 {
    margin-right: -1.25rem;
  }

  .sm\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .sm\:-ml-5 {
    margin-left: -1.25rem;
  }

  .sm\:-mt-6 {
    margin-top: -1.5rem;
  }

  .sm\:-mr-6 {
    margin-right: -1.5rem;
  }

  .sm\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .sm\:-ml-6 {
    margin-left: -1.5rem;
  }

  .sm\:-mt-7 {
    margin-top: -1.75rem;
  }

  .sm\:-mr-7 {
    margin-right: -1.75rem;
  }

  .sm\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .sm\:-ml-7 {
    margin-left: -1.75rem;
  }

  .sm\:-mt-8 {
    margin-top: -2rem;
  }

  .sm\:-mr-8 {
    margin-right: -2rem;
  }

  .sm\:-mb-8 {
    margin-bottom: -2rem;
  }

  .sm\:-ml-8 {
    margin-left: -2rem;
  }

  .sm\:-mt-9 {
    margin-top: -2.25rem;
  }

  .sm\:-mr-9 {
    margin-right: -2.25rem;
  }

  .sm\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .sm\:-ml-9 {
    margin-left: -2.25rem;
  }

  .sm\:-mt-10 {
    margin-top: -2.5rem;
  }

  .sm\:-mr-10 {
    margin-right: -2.5rem;
  }

  .sm\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .sm\:-ml-10 {
    margin-left: -2.5rem;
  }

  .sm\:-mt-11 {
    margin-top: -2.75rem;
  }

  .sm\:-mr-11 {
    margin-right: -2.75rem;
  }

  .sm\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .sm\:-ml-11 {
    margin-left: -2.75rem;
  }

  .sm\:-mt-12 {
    margin-top: -3rem;
  }

  .sm\:-mr-12 {
    margin-right: -3rem;
  }

  .sm\:-mb-12 {
    margin-bottom: -3rem;
  }

  .sm\:-ml-12 {
    margin-left: -3rem;
  }

  .sm\:-mt-13 {
    margin-top: -3.25rem;
  }

  .sm\:-mr-13 {
    margin-right: -3.25rem;
  }

  .sm\:-mb-13 {
    margin-bottom: -3.25rem;
  }

  .sm\:-ml-13 {
    margin-left: -3.25rem;
  }

  .sm\:-mt-14 {
    margin-top: -3.5rem;
  }

  .sm\:-mr-14 {
    margin-right: -3.5rem;
  }

  .sm\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .sm\:-ml-14 {
    margin-left: -3.5rem;
  }

  .sm\:-mt-15 {
    margin-top: -3.75rem;
  }

  .sm\:-mr-15 {
    margin-right: -3.75rem;
  }

  .sm\:-mb-15 {
    margin-bottom: -3.75rem;
  }

  .sm\:-ml-15 {
    margin-left: -3.75rem;
  }

  .sm\:-mt-16 {
    margin-top: -4rem;
  }

  .sm\:-mr-16 {
    margin-right: -4rem;
  }

  .sm\:-mb-16 {
    margin-bottom: -4rem;
  }

  .sm\:-ml-16 {
    margin-left: -4rem;
  }

  .sm\:-mt-20 {
    margin-top: -5rem;
  }

  .sm\:-mr-20 {
    margin-right: -5rem;
  }

  .sm\:-mb-20 {
    margin-bottom: -5rem;
  }

  .sm\:-ml-20 {
    margin-left: -5rem;
  }

  .sm\:-mt-24 {
    margin-top: -6rem;
  }

  .sm\:-mr-24 {
    margin-right: -6rem;
  }

  .sm\:-mb-24 {
    margin-bottom: -6rem;
  }

  .sm\:-ml-24 {
    margin-left: -6rem;
  }

  .sm\:-mt-28 {
    margin-top: -7rem;
  }

  .sm\:-mr-28 {
    margin-right: -7rem;
  }

  .sm\:-mb-28 {
    margin-bottom: -7rem;
  }

  .sm\:-ml-28 {
    margin-left: -7rem;
  }

  .sm\:-mt-32 {
    margin-top: -8rem;
  }

  .sm\:-mr-32 {
    margin-right: -8rem;
  }

  .sm\:-mb-32 {
    margin-bottom: -8rem;
  }

  .sm\:-ml-32 {
    margin-left: -8rem;
  }

  .sm\:-mt-36 {
    margin-top: -9rem;
  }

  .sm\:-mr-36 {
    margin-right: -9rem;
  }

  .sm\:-mb-36 {
    margin-bottom: -9rem;
  }

  .sm\:-ml-36 {
    margin-left: -9rem;
  }

  .sm\:-mt-40 {
    margin-top: -10rem;
  }

  .sm\:-mr-40 {
    margin-right: -10rem;
  }

  .sm\:-mb-40 {
    margin-bottom: -10rem;
  }

  .sm\:-ml-40 {
    margin-left: -10rem;
  }

  .sm\:-mt-44 {
    margin-top: -11rem;
  }

  .sm\:-mr-44 {
    margin-right: -11rem;
  }

  .sm\:-mb-44 {
    margin-bottom: -11rem;
  }

  .sm\:-ml-44 {
    margin-left: -11rem;
  }

  .sm\:-mt-48 {
    margin-top: -12rem;
  }

  .sm\:-mr-48 {
    margin-right: -12rem;
  }

  .sm\:-mb-48 {
    margin-bottom: -12rem;
  }

  .sm\:-ml-48 {
    margin-left: -12rem;
  }

  .sm\:-mt-52 {
    margin-top: -13rem;
  }

  .sm\:-mr-52 {
    margin-right: -13rem;
  }

  .sm\:-mb-52 {
    margin-bottom: -13rem;
  }

  .sm\:-ml-52 {
    margin-left: -13rem;
  }

  .sm\:-mt-56 {
    margin-top: -14rem;
  }

  .sm\:-mr-56 {
    margin-right: -14rem;
  }

  .sm\:-mb-56 {
    margin-bottom: -14rem;
  }

  .sm\:-ml-56 {
    margin-left: -14rem;
  }

  .sm\:-mt-60 {
    margin-top: -15rem;
  }

  .sm\:-mr-60 {
    margin-right: -15rem;
  }

  .sm\:-mb-60 {
    margin-bottom: -15rem;
  }

  .sm\:-ml-60 {
    margin-left: -15rem;
  }

  .sm\:-mt-64 {
    margin-top: -16rem;
  }

  .sm\:-mr-64 {
    margin-right: -16rem;
  }

  .sm\:-mb-64 {
    margin-bottom: -16rem;
  }

  .sm\:-ml-64 {
    margin-left: -16rem;
  }

  .sm\:-mt-72 {
    margin-top: -18rem;
  }

  .sm\:-mr-72 {
    margin-right: -18rem;
  }

  .sm\:-mb-72 {
    margin-bottom: -18rem;
  }

  .sm\:-ml-72 {
    margin-left: -18rem;
  }

  .sm\:-mt-80 {
    margin-top: -20rem;
  }

  .sm\:-mr-80 {
    margin-right: -20rem;
  }

  .sm\:-mb-80 {
    margin-bottom: -20rem;
  }

  .sm\:-ml-80 {
    margin-left: -20rem;
  }

  .sm\:-mt-96 {
    margin-top: -24rem;
  }

  .sm\:-mr-96 {
    margin-right: -24rem;
  }

  .sm\:-mb-96 {
    margin-bottom: -24rem;
  }

  .sm\:-ml-96 {
    margin-left: -24rem;
  }

  .sm\:-mt-px {
    margin-top: -1px;
  }

  .sm\:-mr-px {
    margin-right: -1px;
  }

  .sm\:-mb-px {
    margin-bottom: -1px;
  }

  .sm\:-ml-px {
    margin-left: -1px;
  }

  .sm\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .sm\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .sm\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .sm\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .sm\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .sm\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .sm\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .sm\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .sm\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .sm\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .sm\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .sm\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .sm\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .sm\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .sm\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .sm\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .sm\:-mt-1\/2 {
    margin-top: -50%;
  }

  .sm\:-mr-1\/2 {
    margin-right: -50%;
  }

  .sm\:-mb-1\/2 {
    margin-bottom: -50%;
  }

  .sm\:-ml-1\/2 {
    margin-left: -50%;
  }

  .sm\:-mt-1\/3 {
    margin-top: -33.3333%;
  }

  .sm\:-mr-1\/3 {
    margin-right: -33.3333%;
  }

  .sm\:-mb-1\/3 {
    margin-bottom: -33.3333%;
  }

  .sm\:-ml-1\/3 {
    margin-left: -33.3333%;
  }

  .sm\:-mt-2\/3 {
    margin-top: -66.6667%;
  }

  .sm\:-mr-2\/3 {
    margin-right: -66.6667%;
  }

  .sm\:-mb-2\/3 {
    margin-bottom: -66.6667%;
  }

  .sm\:-ml-2\/3 {
    margin-left: -66.6667%;
  }

  .sm\:-mt-1\/4 {
    margin-top: -25%;
  }

  .sm\:-mr-1\/4 {
    margin-right: -25%;
  }

  .sm\:-mb-1\/4 {
    margin-bottom: -25%;
  }

  .sm\:-ml-1\/4 {
    margin-left: -25%;
  }

  .sm\:-mt-2\/4 {
    margin-top: -50%;
  }

  .sm\:-mr-2\/4 {
    margin-right: -50%;
  }

  .sm\:-mb-2\/4 {
    margin-bottom: -50%;
  }

  .sm\:-ml-2\/4 {
    margin-left: -50%;
  }

  .sm\:-mt-3\/4 {
    margin-top: -75%;
  }

  .sm\:-mr-3\/4 {
    margin-right: -75%;
  }

  .sm\:-mb-3\/4 {
    margin-bottom: -75%;
  }

  .sm\:-ml-3\/4 {
    margin-left: -75%;
  }

  .sm\:-mt-1\/5 {
    margin-top: -20%;
  }

  .sm\:-mr-1\/5 {
    margin-right: -20%;
  }

  .sm\:-mb-1\/5 {
    margin-bottom: -20%;
  }

  .sm\:-ml-1\/5 {
    margin-left: -20%;
  }

  .sm\:-mt-2\/5 {
    margin-top: -40%;
  }

  .sm\:-mr-2\/5 {
    margin-right: -40%;
  }

  .sm\:-mb-2\/5 {
    margin-bottom: -40%;
  }

  .sm\:-ml-2\/5 {
    margin-left: -40%;
  }

  .sm\:-mt-3\/5 {
    margin-top: -60%;
  }

  .sm\:-mr-3\/5 {
    margin-right: -60%;
  }

  .sm\:-mb-3\/5 {
    margin-bottom: -60%;
  }

  .sm\:-ml-3\/5 {
    margin-left: -60%;
  }

  .sm\:-mt-4\/5 {
    margin-top: -80%;
  }

  .sm\:-mr-4\/5 {
    margin-right: -80%;
  }

  .sm\:-mb-4\/5 {
    margin-bottom: -80%;
  }

  .sm\:-ml-4\/5 {
    margin-left: -80%;
  }

  .sm\:-mt-1\/6 {
    margin-top: -16.6667%;
  }

  .sm\:-mr-1\/6 {
    margin-right: -16.6667%;
  }

  .sm\:-mb-1\/6 {
    margin-bottom: -16.6667%;
  }

  .sm\:-ml-1\/6 {
    margin-left: -16.6667%;
  }

  .sm\:-mt-2\/6 {
    margin-top: -33.3333%;
  }

  .sm\:-mr-2\/6 {
    margin-right: -33.3333%;
  }

  .sm\:-mb-2\/6 {
    margin-bottom: -33.3333%;
  }

  .sm\:-ml-2\/6 {
    margin-left: -33.3333%;
  }

  .sm\:-mt-3\/6 {
    margin-top: -50%;
  }

  .sm\:-mr-3\/6 {
    margin-right: -50%;
  }

  .sm\:-mb-3\/6 {
    margin-bottom: -50%;
  }

  .sm\:-ml-3\/6 {
    margin-left: -50%;
  }

  .sm\:-mt-4\/6 {
    margin-top: -66.6667%;
  }

  .sm\:-mr-4\/6 {
    margin-right: -66.6667%;
  }

  .sm\:-mb-4\/6 {
    margin-bottom: -66.6667%;
  }

  .sm\:-ml-4\/6 {
    margin-left: -66.6667%;
  }

  .sm\:-mt-5\/6 {
    margin-top: -83.3333%;
  }

  .sm\:-mr-5\/6 {
    margin-right: -83.3333%;
  }

  .sm\:-mb-5\/6 {
    margin-bottom: -83.3333%;
  }

  .sm\:-ml-5\/6 {
    margin-left: -83.3333%;
  }

  .sm\:-mt-1\/12 {
    margin-top: -8.33333%;
  }

  .sm\:-mr-1\/12 {
    margin-right: -8.33333%;
  }

  .sm\:-mb-1\/12 {
    margin-bottom: -8.33333%;
  }

  .sm\:-ml-1\/12 {
    margin-left: -8.33333%;
  }

  .sm\:-mt-2\/12 {
    margin-top: -16.6667%;
  }

  .sm\:-mr-2\/12 {
    margin-right: -16.6667%;
  }

  .sm\:-mb-2\/12 {
    margin-bottom: -16.6667%;
  }

  .sm\:-ml-2\/12 {
    margin-left: -16.6667%;
  }

  .sm\:-mt-3\/12 {
    margin-top: -25%;
  }

  .sm\:-mr-3\/12 {
    margin-right: -25%;
  }

  .sm\:-mb-3\/12 {
    margin-bottom: -25%;
  }

  .sm\:-ml-3\/12 {
    margin-left: -25%;
  }

  .sm\:-mt-4\/12 {
    margin-top: -33.3333%;
  }

  .sm\:-mr-4\/12 {
    margin-right: -33.3333%;
  }

  .sm\:-mb-4\/12 {
    margin-bottom: -33.3333%;
  }

  .sm\:-ml-4\/12 {
    margin-left: -33.3333%;
  }

  .sm\:-mt-5\/12 {
    margin-top: -41.6667%;
  }

  .sm\:-mr-5\/12 {
    margin-right: -41.6667%;
  }

  .sm\:-mb-5\/12 {
    margin-bottom: -41.6667%;
  }

  .sm\:-ml-5\/12 {
    margin-left: -41.6667%;
  }

  .sm\:-mt-6\/12 {
    margin-top: -50%;
  }

  .sm\:-mr-6\/12 {
    margin-right: -50%;
  }

  .sm\:-mb-6\/12 {
    margin-bottom: -50%;
  }

  .sm\:-ml-6\/12 {
    margin-left: -50%;
  }

  .sm\:-mt-7\/12 {
    margin-top: -58.3333%;
  }

  .sm\:-mr-7\/12 {
    margin-right: -58.3333%;
  }

  .sm\:-mb-7\/12 {
    margin-bottom: -58.3333%;
  }

  .sm\:-ml-7\/12 {
    margin-left: -58.3333%;
  }

  .sm\:-mt-8\/12 {
    margin-top: -66.6667%;
  }

  .sm\:-mr-8\/12 {
    margin-right: -66.6667%;
  }

  .sm\:-mb-8\/12 {
    margin-bottom: -66.6667%;
  }

  .sm\:-ml-8\/12 {
    margin-left: -66.6667%;
  }

  .sm\:-mt-9\/12 {
    margin-top: -75%;
  }

  .sm\:-mr-9\/12 {
    margin-right: -75%;
  }

  .sm\:-mb-9\/12 {
    margin-bottom: -75%;
  }

  .sm\:-ml-9\/12 {
    margin-left: -75%;
  }

  .sm\:-mt-10\/12 {
    margin-top: -83.3333%;
  }

  .sm\:-mr-10\/12 {
    margin-right: -83.3333%;
  }

  .sm\:-mb-10\/12 {
    margin-bottom: -83.3333%;
  }

  .sm\:-ml-10\/12 {
    margin-left: -83.3333%;
  }

  .sm\:-mt-11\/12 {
    margin-top: -91.6667%;
  }

  .sm\:-mr-11\/12 {
    margin-right: -91.6667%;
  }

  .sm\:-mb-11\/12 {
    margin-bottom: -91.6667%;
  }

  .sm\:-ml-11\/12 {
    margin-left: -91.6667%;
  }

  .sm\:-mt-full {
    margin-top: -100%;
  }

  .sm\:-mr-full {
    margin-right: -100%;
  }

  .sm\:-mb-full {
    margin-bottom: -100%;
  }

  .sm\:-ml-full {
    margin-left: -100%;
  }

  .sm\:max-h-0 {
    max-height: 0;
  }

  .sm\:max-h-1 {
    max-height: .25rem;
  }

  .sm\:max-h-2 {
    max-height: .5rem;
  }

  .sm\:max-h-3 {
    max-height: .75rem;
  }

  .sm\:max-h-4 {
    max-height: 1rem;
  }

  .sm\:max-h-5 {
    max-height: 1.25rem;
  }

  .sm\:max-h-6 {
    max-height: 1.5rem;
  }

  .sm\:max-h-7 {
    max-height: 1.75rem;
  }

  .sm\:max-h-8 {
    max-height: 2rem;
  }

  .sm\:max-h-9 {
    max-height: 2.25rem;
  }

  .sm\:max-h-10 {
    max-height: 2.5rem;
  }

  .sm\:max-h-11 {
    max-height: 2.75rem;
  }

  .sm\:max-h-12 {
    max-height: 3rem;
  }

  .sm\:max-h-13 {
    max-height: 3.25rem;
  }

  .sm\:max-h-14 {
    max-height: 3.5rem;
  }

  .sm\:max-h-15 {
    max-height: 3.75rem;
  }

  .sm\:max-h-16 {
    max-height: 4rem;
  }

  .sm\:max-h-20 {
    max-height: 5rem;
  }

  .sm\:max-h-24 {
    max-height: 6rem;
  }

  .sm\:max-h-28 {
    max-height: 7rem;
  }

  .sm\:max-h-32 {
    max-height: 8rem;
  }

  .sm\:max-h-36 {
    max-height: 9rem;
  }

  .sm\:max-h-40 {
    max-height: 10rem;
  }

  .sm\:max-h-44 {
    max-height: 11rem;
  }

  .sm\:max-h-48 {
    max-height: 12rem;
  }

  .sm\:max-h-52 {
    max-height: 13rem;
  }

  .sm\:max-h-56 {
    max-height: 14rem;
  }

  .sm\:max-h-60 {
    max-height: 15rem;
  }

  .sm\:max-h-64 {
    max-height: 16rem;
  }

  .sm\:max-h-72 {
    max-height: 18rem;
  }

  .sm\:max-h-80 {
    max-height: 20rem;
  }

  .sm\:max-h-96 {
    max-height: 24rem;
  }

  .sm\:max-h-screen {
    max-height: 100vh;
  }

  .sm\:max-h-px {
    max-height: 1px;
  }

  .sm\:max-h-0\.5 {
    max-height: .125rem;
  }

  .sm\:max-h-1\.5 {
    max-height: .375rem;
  }

  .sm\:max-h-2\.5 {
    max-height: .625rem;
  }

  .sm\:max-h-3\.5 {
    max-height: .875rem;
  }

  .sm\:max-h-1\/2 {
    max-height: 50%;
  }

  .sm\:max-h-1\/3 {
    max-height: 33.3333%;
  }

  .sm\:max-h-2\/3 {
    max-height: 66.6667%;
  }

  .sm\:max-h-1\/4 {
    max-height: 25%;
  }

  .sm\:max-h-2\/4 {
    max-height: 50%;
  }

  .sm\:max-h-3\/4 {
    max-height: 75%;
  }

  .sm\:max-h-1\/5 {
    max-height: 20%;
  }

  .sm\:max-h-2\/5 {
    max-height: 40%;
  }

  .sm\:max-h-3\/5 {
    max-height: 60%;
  }

  .sm\:max-h-4\/5 {
    max-height: 80%;
  }

  .sm\:max-h-1\/6 {
    max-height: 16.6667%;
  }

  .sm\:max-h-2\/6 {
    max-height: 33.3333%;
  }

  .sm\:max-h-3\/6 {
    max-height: 50%;
  }

  .sm\:max-h-4\/6 {
    max-height: 66.6667%;
  }

  .sm\:max-h-5\/6 {
    max-height: 83.3333%;
  }

  .sm\:max-h-1\/12 {
    max-height: 8.33333%;
  }

  .sm\:max-h-2\/12 {
    max-height: 16.6667%;
  }

  .sm\:max-h-3\/12 {
    max-height: 25%;
  }

  .sm\:max-h-4\/12 {
    max-height: 33.3333%;
  }

  .sm\:max-h-5\/12 {
    max-height: 41.6667%;
  }

  .sm\:max-h-6\/12 {
    max-height: 50%;
  }

  .sm\:max-h-7\/12 {
    max-height: 58.3333%;
  }

  .sm\:max-h-8\/12 {
    max-height: 66.6667%;
  }

  .sm\:max-h-9\/12 {
    max-height: 75%;
  }

  .sm\:max-h-10\/12 {
    max-height: 83.3333%;
  }

  .sm\:max-h-11\/12 {
    max-height: 91.6667%;
  }

  .sm\:max-h-full {
    max-height: 100%;
  }

  .sm\:max-w-none {
    max-width: none;
  }

  .sm\:max-w-xs {
    max-width: 20rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:max-w-md {
    max-width: 28rem;
  }

  .sm\:max-w-lg {
    max-width: 32rem;
  }

  .sm\:max-w-xl {
    max-width: 36rem;
  }

  .sm\:max-w-2xl {
    max-width: 42rem;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-4xl {
    max-width: 56rem;
  }

  .sm\:max-w-5xl {
    max-width: 64rem;
  }

  .sm\:max-w-6xl {
    max-width: 72rem;
  }

  .sm\:max-w-7xl {
    max-width: 80rem;
  }

  .sm\:max-w-full {
    max-width: 100%;
  }

  .sm\:max-w-min-content {
    max-width: min-content;
  }

  .sm\:max-w-max-content {
    max-width: max-content;
  }

  .sm\:max-w-screen-sm {
    max-width: 640px;
  }

  .sm\:max-w-screen-md {
    max-width: 768px;
  }

  .sm\:max-w-screen-lg {
    max-width: 1024px;
  }

  .sm\:max-w-screen-xl {
    max-width: 1280px;
  }

  .sm\:min-h-0 {
    min-height: 0;
  }

  .sm\:min-h-full {
    min-height: 100%;
  }

  .sm\:min-h-screen {
    min-height: 100vh;
  }

  .sm\:min-w-0 {
    min-width: 0;
  }

  .sm\:min-w-full {
    min-width: 100%;
  }

  .sm\:min-w-min-content {
    min-width: min-content;
  }

  .sm\:min-w-max-content {
    min-width: max-content;
  }

  .sm\:object-contain {
    object-fit: contain;
  }

  .sm\:object-cover {
    object-fit: cover;
  }

  .sm\:object-fill {
    object-fit: fill;
  }

  .sm\:object-none {
    object-fit: none;
  }

  .sm\:object-scale-down {
    object-fit: scale-down;
  }

  .sm\:object-bottom {
    object-position: bottom;
  }

  .sm\:object-center {
    object-position: center;
  }

  .sm\:object-left {
    object-position: left;
  }

  .sm\:object-left-bottom {
    object-position: left bottom;
  }

  .sm\:object-left-top {
    object-position: left top;
  }

  .sm\:object-right {
    object-position: right;
  }

  .sm\:object-right-bottom {
    object-position: right bottom;
  }

  .sm\:object-right-top {
    object-position: right top;
  }

  .sm\:object-top {
    object-position: top;
  }

  .sm\:opacity-0 {
    opacity: 0;
  }

  .sm\:opacity-25 {
    opacity: .25;
  }

  .sm\:opacity-50 {
    opacity: .5;
  }

  .sm\:opacity-75 {
    opacity: .75;
  }

  .sm\:opacity-100 {
    opacity: 1;
  }

  .sm\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .sm\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .sm\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .sm\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .sm\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .sm\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .sm\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .sm\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .sm\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .sm\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .sm\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .sm\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .sm\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .sm\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .sm\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .sm\:overflow-auto {
    overflow: auto;
  }

  .sm\:overflow-hidden {
    overflow: hidden;
  }

  .sm\:overflow-visible {
    overflow: visible;
  }

  .sm\:overflow-scroll {
    overflow: scroll;
  }

  .sm\:overflow-x-auto {
    overflow-x: auto;
  }

  .sm\:overflow-y-auto {
    overflow-y: auto;
  }

  .sm\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .sm\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .sm\:overflow-x-visible {
    overflow-x: visible;
  }

  .sm\:overflow-y-visible {
    overflow-y: visible;
  }

  .sm\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .sm\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .sm\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .sm\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .sm\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .sm\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .sm\:overscroll-none {
    overscroll-behavior: none;
  }

  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .sm\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .sm\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .sm\:p-0 {
    padding: 0;
  }

  .sm\:p-1 {
    padding: .25rem;
  }

  .sm\:p-2 {
    padding: .5rem;
  }

  .sm\:p-3 {
    padding: .75rem;
  }

  .sm\:p-4 {
    padding: 1rem;
  }

  .sm\:p-5 {
    padding: 1.25rem;
  }

  .sm\:p-6 {
    padding: 1.5rem;
  }

  .sm\:p-7 {
    padding: 1.75rem;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:p-9 {
    padding: 2.25rem;
  }

  .sm\:p-10 {
    padding: 2.5rem;
  }

  .sm\:p-11 {
    padding: 2.75rem;
  }

  .sm\:p-12 {
    padding: 3rem;
  }

  .sm\:p-13 {
    padding: 3.25rem;
  }

  .sm\:p-14 {
    padding: 3.5rem;
  }

  .sm\:p-15 {
    padding: 3.75rem;
  }

  .sm\:p-16 {
    padding: 4rem;
  }

  .sm\:p-20 {
    padding: 5rem;
  }

  .sm\:p-24 {
    padding: 6rem;
  }

  .sm\:p-28 {
    padding: 7rem;
  }

  .sm\:p-32 {
    padding: 8rem;
  }

  .sm\:p-36 {
    padding: 9rem;
  }

  .sm\:p-40 {
    padding: 10rem;
  }

  .sm\:p-44 {
    padding: 11rem;
  }

  .sm\:p-48 {
    padding: 12rem;
  }

  .sm\:p-52 {
    padding: 13rem;
  }

  .sm\:p-56 {
    padding: 14rem;
  }

  .sm\:p-60 {
    padding: 15rem;
  }

  .sm\:p-64 {
    padding: 16rem;
  }

  .sm\:p-72 {
    padding: 18rem;
  }

  .sm\:p-80 {
    padding: 20rem;
  }

  .sm\:p-96 {
    padding: 24rem;
  }

  .sm\:p-px {
    padding: 1px;
  }

  .sm\:p-0\.5 {
    padding: .125rem;
  }

  .sm\:p-1\.5 {
    padding: .375rem;
  }

  .sm\:p-2\.5 {
    padding: .625rem;
  }

  .sm\:p-3\.5 {
    padding: .875rem;
  }

  .sm\:p-1\/2 {
    padding: 50%;
  }

  .sm\:p-1\/3 {
    padding: 33.3333%;
  }

  .sm\:p-2\/3 {
    padding: 66.6667%;
  }

  .sm\:p-1\/4 {
    padding: 25%;
  }

  .sm\:p-2\/4 {
    padding: 50%;
  }

  .sm\:p-3\/4 {
    padding: 75%;
  }

  .sm\:p-1\/5 {
    padding: 20%;
  }

  .sm\:p-2\/5 {
    padding: 40%;
  }

  .sm\:p-3\/5 {
    padding: 60%;
  }

  .sm\:p-4\/5 {
    padding: 80%;
  }

  .sm\:p-1\/6 {
    padding: 16.6667%;
  }

  .sm\:p-2\/6 {
    padding: 33.3333%;
  }

  .sm\:p-3\/6 {
    padding: 50%;
  }

  .sm\:p-4\/6 {
    padding: 66.6667%;
  }

  .sm\:p-5\/6 {
    padding: 83.3333%;
  }

  .sm\:p-1\/12 {
    padding: 8.33333%;
  }

  .sm\:p-2\/12 {
    padding: 16.6667%;
  }

  .sm\:p-3\/12 {
    padding: 25%;
  }

  .sm\:p-4\/12 {
    padding: 33.3333%;
  }

  .sm\:p-5\/12 {
    padding: 41.6667%;
  }

  .sm\:p-6\/12 {
    padding: 50%;
  }

  .sm\:p-7\/12 {
    padding: 58.3333%;
  }

  .sm\:p-8\/12 {
    padding: 66.6667%;
  }

  .sm\:p-9\/12 {
    padding: 75%;
  }

  .sm\:p-10\/12 {
    padding: 83.3333%;
  }

  .sm\:p-11\/12 {
    padding: 91.6667%;
  }

  .sm\:p-full {
    padding: 100%;
  }

  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .sm\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .sm\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .sm\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .sm\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .sm\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .sm\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .sm\:py-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem;
  }

  .sm\:px-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }

  .sm\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .sm\:py-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .sm\:px-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .sm\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .sm\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .sm\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .sm\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .sm\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .sm\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .sm\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .sm\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .sm\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .sm\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .sm\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .sm\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .sm\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .sm\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .sm\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .sm\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .sm\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .sm\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .sm\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .sm\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .sm\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .sm\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .sm\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .sm\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .sm\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .sm\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .sm\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .sm\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .sm\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .sm\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .sm\:py-1\/2 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .sm\:px-1\/2 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .sm\:py-1\/3 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .sm\:px-1\/3 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .sm\:py-2\/3 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .sm\:px-2\/3 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .sm\:py-1\/4 {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .sm\:px-1\/4 {
    padding-left: 25%;
    padding-right: 25%;
  }

  .sm\:py-2\/4 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .sm\:px-2\/4 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .sm\:py-3\/4 {
    padding-top: 75%;
    padding-bottom: 75%;
  }

  .sm\:px-3\/4 {
    padding-left: 75%;
    padding-right: 75%;
  }

  .sm\:py-1\/5 {
    padding-top: 20%;
    padding-bottom: 20%;
  }

  .sm\:px-1\/5 {
    padding-left: 20%;
    padding-right: 20%;
  }

  .sm\:py-2\/5 {
    padding-top: 40%;
    padding-bottom: 40%;
  }

  .sm\:px-2\/5 {
    padding-left: 40%;
    padding-right: 40%;
  }

  .sm\:py-3\/5 {
    padding-top: 60%;
    padding-bottom: 60%;
  }

  .sm\:px-3\/5 {
    padding-left: 60%;
    padding-right: 60%;
  }

  .sm\:py-4\/5 {
    padding-top: 80%;
    padding-bottom: 80%;
  }

  .sm\:px-4\/5 {
    padding-left: 80%;
    padding-right: 80%;
  }

  .sm\:py-1\/6 {
    padding-top: 16.6667%;
    padding-bottom: 16.6667%;
  }

  .sm\:px-1\/6 {
    padding-left: 16.6667%;
    padding-right: 16.6667%;
  }

  .sm\:py-2\/6 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .sm\:px-2\/6 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .sm\:py-3\/6 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .sm\:px-3\/6 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .sm\:py-4\/6 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .sm\:px-4\/6 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .sm\:py-5\/6 {
    padding-top: 83.3333%;
    padding-bottom: 83.3333%;
  }

  .sm\:px-5\/6 {
    padding-left: 83.3333%;
    padding-right: 83.3333%;
  }

  .sm\:py-1\/12 {
    padding-top: 8.33333%;
    padding-bottom: 8.33333%;
  }

  .sm\:px-1\/12 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }

  .sm\:py-2\/12 {
    padding-top: 16.6667%;
    padding-bottom: 16.6667%;
  }

  .sm\:px-2\/12 {
    padding-left: 16.6667%;
    padding-right: 16.6667%;
  }

  .sm\:py-3\/12 {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .sm\:px-3\/12 {
    padding-left: 25%;
    padding-right: 25%;
  }

  .sm\:py-4\/12 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .sm\:px-4\/12 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .sm\:py-5\/12 {
    padding-top: 41.6667%;
    padding-bottom: 41.6667%;
  }

  .sm\:px-5\/12 {
    padding-left: 41.6667%;
    padding-right: 41.6667%;
  }

  .sm\:py-6\/12 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .sm\:px-6\/12 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .sm\:py-7\/12 {
    padding-top: 58.3333%;
    padding-bottom: 58.3333%;
  }

  .sm\:px-7\/12 {
    padding-left: 58.3333%;
    padding-right: 58.3333%;
  }

  .sm\:py-8\/12 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .sm\:px-8\/12 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .sm\:py-9\/12 {
    padding-top: 75%;
    padding-bottom: 75%;
  }

  .sm\:px-9\/12 {
    padding-left: 75%;
    padding-right: 75%;
  }

  .sm\:py-10\/12 {
    padding-top: 83.3333%;
    padding-bottom: 83.3333%;
  }

  .sm\:px-10\/12 {
    padding-left: 83.3333%;
    padding-right: 83.3333%;
  }

  .sm\:py-11\/12 {
    padding-top: 91.6667%;
    padding-bottom: 91.6667%;
  }

  .sm\:px-11\/12 {
    padding-left: 91.6667%;
    padding-right: 91.6667%;
  }

  .sm\:py-full {
    padding-top: 100%;
    padding-bottom: 100%;
  }

  .sm\:px-full {
    padding-left: 100%;
    padding-right: 100%;
  }

  .sm\:pt-0 {
    padding-top: 0;
  }

  .sm\:pr-0 {
    padding-right: 0;
  }

  .sm\:pb-0 {
    padding-bottom: 0;
  }

  .sm\:pl-0 {
    padding-left: 0;
  }

  .sm\:pt-1 {
    padding-top: .25rem;
  }

  .sm\:pr-1 {
    padding-right: .25rem;
  }

  .sm\:pb-1 {
    padding-bottom: .25rem;
  }

  .sm\:pl-1 {
    padding-left: .25rem;
  }

  .sm\:pt-2 {
    padding-top: .5rem;
  }

  .sm\:pr-2 {
    padding-right: .5rem;
  }

  .sm\:pb-2 {
    padding-bottom: .5rem;
  }

  .sm\:pl-2 {
    padding-left: .5rem;
  }

  .sm\:pt-3 {
    padding-top: .75rem;
  }

  .sm\:pr-3 {
    padding-right: .75rem;
  }

  .sm\:pb-3 {
    padding-bottom: .75rem;
  }

  .sm\:pl-3 {
    padding-left: .75rem;
  }

  .sm\:pt-4 {
    padding-top: 1rem;
  }

  .sm\:pr-4 {
    padding-right: 1rem;
  }

  .sm\:pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:pl-4 {
    padding-left: 1rem;
  }

  .sm\:pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm\:pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:pt-7 {
    padding-top: 1.75rem;
  }

  .sm\:pr-7 {
    padding-right: 1.75rem;
  }

  .sm\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .sm\:pl-7 {
    padding-left: 1.75rem;
  }

  .sm\:pt-8 {
    padding-top: 2rem;
  }

  .sm\:pr-8 {
    padding-right: 2rem;
  }

  .sm\:pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:pl-8 {
    padding-left: 2rem;
  }

  .sm\:pt-9 {
    padding-top: 2.25rem;
  }

  .sm\:pr-9 {
    padding-right: 2.25rem;
  }

  .sm\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .sm\:pl-9 {
    padding-left: 2.25rem;
  }

  .sm\:pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:pt-11 {
    padding-top: 2.75rem;
  }

  .sm\:pr-11 {
    padding-right: 2.75rem;
  }

  .sm\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .sm\:pl-11 {
    padding-left: 2.75rem;
  }

  .sm\:pt-12 {
    padding-top: 3rem;
  }

  .sm\:pr-12 {
    padding-right: 3rem;
  }

  .sm\:pb-12 {
    padding-bottom: 3rem;
  }

  .sm\:pl-12 {
    padding-left: 3rem;
  }

  .sm\:pt-13 {
    padding-top: 3.25rem;
  }

  .sm\:pr-13 {
    padding-right: 3.25rem;
  }

  .sm\:pb-13 {
    padding-bottom: 3.25rem;
  }

  .sm\:pl-13 {
    padding-left: 3.25rem;
  }

  .sm\:pt-14 {
    padding-top: 3.5rem;
  }

  .sm\:pr-14 {
    padding-right: 3.5rem;
  }

  .sm\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .sm\:pl-14 {
    padding-left: 3.5rem;
  }

  .sm\:pt-15 {
    padding-top: 3.75rem;
  }

  .sm\:pr-15 {
    padding-right: 3.75rem;
  }

  .sm\:pb-15 {
    padding-bottom: 3.75rem;
  }

  .sm\:pl-15 {
    padding-left: 3.75rem;
  }

  .sm\:pt-16 {
    padding-top: 4rem;
  }

  .sm\:pr-16 {
    padding-right: 4rem;
  }

  .sm\:pb-16 {
    padding-bottom: 4rem;
  }

  .sm\:pl-16 {
    padding-left: 4rem;
  }

  .sm\:pt-20 {
    padding-top: 5rem;
  }

  .sm\:pr-20 {
    padding-right: 5rem;
  }

  .sm\:pb-20 {
    padding-bottom: 5rem;
  }

  .sm\:pl-20 {
    padding-left: 5rem;
  }

  .sm\:pt-24 {
    padding-top: 6rem;
  }

  .sm\:pr-24 {
    padding-right: 6rem;
  }

  .sm\:pb-24 {
    padding-bottom: 6rem;
  }

  .sm\:pl-24 {
    padding-left: 6rem;
  }

  .sm\:pt-28 {
    padding-top: 7rem;
  }

  .sm\:pr-28 {
    padding-right: 7rem;
  }

  .sm\:pb-28 {
    padding-bottom: 7rem;
  }

  .sm\:pl-28 {
    padding-left: 7rem;
  }

  .sm\:pt-32 {
    padding-top: 8rem;
  }

  .sm\:pr-32 {
    padding-right: 8rem;
  }

  .sm\:pb-32 {
    padding-bottom: 8rem;
  }

  .sm\:pl-32 {
    padding-left: 8rem;
  }

  .sm\:pt-36 {
    padding-top: 9rem;
  }

  .sm\:pr-36 {
    padding-right: 9rem;
  }

  .sm\:pb-36 {
    padding-bottom: 9rem;
  }

  .sm\:pl-36 {
    padding-left: 9rem;
  }

  .sm\:pt-40 {
    padding-top: 10rem;
  }

  .sm\:pr-40 {
    padding-right: 10rem;
  }

  .sm\:pb-40 {
    padding-bottom: 10rem;
  }

  .sm\:pl-40 {
    padding-left: 10rem;
  }

  .sm\:pt-44 {
    padding-top: 11rem;
  }

  .sm\:pr-44 {
    padding-right: 11rem;
  }

  .sm\:pb-44 {
    padding-bottom: 11rem;
  }

  .sm\:pl-44 {
    padding-left: 11rem;
  }

  .sm\:pt-48 {
    padding-top: 12rem;
  }

  .sm\:pr-48 {
    padding-right: 12rem;
  }

  .sm\:pb-48 {
    padding-bottom: 12rem;
  }

  .sm\:pl-48 {
    padding-left: 12rem;
  }

  .sm\:pt-52 {
    padding-top: 13rem;
  }

  .sm\:pr-52 {
    padding-right: 13rem;
  }

  .sm\:pb-52 {
    padding-bottom: 13rem;
  }

  .sm\:pl-52 {
    padding-left: 13rem;
  }

  .sm\:pt-56 {
    padding-top: 14rem;
  }

  .sm\:pr-56 {
    padding-right: 14rem;
  }

  .sm\:pb-56 {
    padding-bottom: 14rem;
  }

  .sm\:pl-56 {
    padding-left: 14rem;
  }

  .sm\:pt-60 {
    padding-top: 15rem;
  }

  .sm\:pr-60 {
    padding-right: 15rem;
  }

  .sm\:pb-60 {
    padding-bottom: 15rem;
  }

  .sm\:pl-60 {
    padding-left: 15rem;
  }

  .sm\:pt-64 {
    padding-top: 16rem;
  }

  .sm\:pr-64 {
    padding-right: 16rem;
  }

  .sm\:pb-64 {
    padding-bottom: 16rem;
  }

  .sm\:pl-64 {
    padding-left: 16rem;
  }

  .sm\:pt-72 {
    padding-top: 18rem;
  }

  .sm\:pr-72 {
    padding-right: 18rem;
  }

  .sm\:pb-72 {
    padding-bottom: 18rem;
  }

  .sm\:pl-72 {
    padding-left: 18rem;
  }

  .sm\:pt-80 {
    padding-top: 20rem;
  }

  .sm\:pr-80 {
    padding-right: 20rem;
  }

  .sm\:pb-80 {
    padding-bottom: 20rem;
  }

  .sm\:pl-80 {
    padding-left: 20rem;
  }

  .sm\:pt-96 {
    padding-top: 24rem;
  }

  .sm\:pr-96 {
    padding-right: 24rem;
  }

  .sm\:pb-96 {
    padding-bottom: 24rem;
  }

  .sm\:pl-96 {
    padding-left: 24rem;
  }

  .sm\:pt-px {
    padding-top: 1px;
  }

  .sm\:pr-px {
    padding-right: 1px;
  }

  .sm\:pb-px {
    padding-bottom: 1px;
  }

  .sm\:pl-px {
    padding-left: 1px;
  }

  .sm\:pt-0\.5 {
    padding-top: .125rem;
  }

  .sm\:pr-0\.5 {
    padding-right: .125rem;
  }

  .sm\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .sm\:pl-0\.5 {
    padding-left: .125rem;
  }

  .sm\:pt-1\.5 {
    padding-top: .375rem;
  }

  .sm\:pr-1\.5 {
    padding-right: .375rem;
  }

  .sm\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .sm\:pl-1\.5 {
    padding-left: .375rem;
  }

  .sm\:pt-2\.5 {
    padding-top: .625rem;
  }

  .sm\:pr-2\.5 {
    padding-right: .625rem;
  }

  .sm\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .sm\:pl-2\.5 {
    padding-left: .625rem;
  }

  .sm\:pt-3\.5 {
    padding-top: .875rem;
  }

  .sm\:pr-3\.5 {
    padding-right: .875rem;
  }

  .sm\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .sm\:pl-3\.5 {
    padding-left: .875rem;
  }

  .sm\:pt-1\/2 {
    padding-top: 50%;
  }

  .sm\:pr-1\/2 {
    padding-right: 50%;
  }

  .sm\:pb-1\/2 {
    padding-bottom: 50%;
  }

  .sm\:pl-1\/2 {
    padding-left: 50%;
  }

  .sm\:pt-1\/3 {
    padding-top: 33.3333%;
  }

  .sm\:pr-1\/3 {
    padding-right: 33.3333%;
  }

  .sm\:pb-1\/3 {
    padding-bottom: 33.3333%;
  }

  .sm\:pl-1\/3 {
    padding-left: 33.3333%;
  }

  .sm\:pt-2\/3 {
    padding-top: 66.6667%;
  }

  .sm\:pr-2\/3 {
    padding-right: 66.6667%;
  }

  .sm\:pb-2\/3 {
    padding-bottom: 66.6667%;
  }

  .sm\:pl-2\/3 {
    padding-left: 66.6667%;
  }

  .sm\:pt-1\/4 {
    padding-top: 25%;
  }

  .sm\:pr-1\/4 {
    padding-right: 25%;
  }

  .sm\:pb-1\/4 {
    padding-bottom: 25%;
  }

  .sm\:pl-1\/4 {
    padding-left: 25%;
  }

  .sm\:pt-2\/4 {
    padding-top: 50%;
  }

  .sm\:pr-2\/4 {
    padding-right: 50%;
  }

  .sm\:pb-2\/4 {
    padding-bottom: 50%;
  }

  .sm\:pl-2\/4 {
    padding-left: 50%;
  }

  .sm\:pt-3\/4 {
    padding-top: 75%;
  }

  .sm\:pr-3\/4 {
    padding-right: 75%;
  }

  .sm\:pb-3\/4 {
    padding-bottom: 75%;
  }

  .sm\:pl-3\/4 {
    padding-left: 75%;
  }

  .sm\:pt-1\/5 {
    padding-top: 20%;
  }

  .sm\:pr-1\/5 {
    padding-right: 20%;
  }

  .sm\:pb-1\/5 {
    padding-bottom: 20%;
  }

  .sm\:pl-1\/5 {
    padding-left: 20%;
  }

  .sm\:pt-2\/5 {
    padding-top: 40%;
  }

  .sm\:pr-2\/5 {
    padding-right: 40%;
  }

  .sm\:pb-2\/5 {
    padding-bottom: 40%;
  }

  .sm\:pl-2\/5 {
    padding-left: 40%;
  }

  .sm\:pt-3\/5 {
    padding-top: 60%;
  }

  .sm\:pr-3\/5 {
    padding-right: 60%;
  }

  .sm\:pb-3\/5 {
    padding-bottom: 60%;
  }

  .sm\:pl-3\/5 {
    padding-left: 60%;
  }

  .sm\:pt-4\/5 {
    padding-top: 80%;
  }

  .sm\:pr-4\/5 {
    padding-right: 80%;
  }

  .sm\:pb-4\/5 {
    padding-bottom: 80%;
  }

  .sm\:pl-4\/5 {
    padding-left: 80%;
  }

  .sm\:pt-1\/6 {
    padding-top: 16.6667%;
  }

  .sm\:pr-1\/6 {
    padding-right: 16.6667%;
  }

  .sm\:pb-1\/6 {
    padding-bottom: 16.6667%;
  }

  .sm\:pl-1\/6 {
    padding-left: 16.6667%;
  }

  .sm\:pt-2\/6 {
    padding-top: 33.3333%;
  }

  .sm\:pr-2\/6 {
    padding-right: 33.3333%;
  }

  .sm\:pb-2\/6 {
    padding-bottom: 33.3333%;
  }

  .sm\:pl-2\/6 {
    padding-left: 33.3333%;
  }

  .sm\:pt-3\/6 {
    padding-top: 50%;
  }

  .sm\:pr-3\/6 {
    padding-right: 50%;
  }

  .sm\:pb-3\/6 {
    padding-bottom: 50%;
  }

  .sm\:pl-3\/6 {
    padding-left: 50%;
  }

  .sm\:pt-4\/6 {
    padding-top: 66.6667%;
  }

  .sm\:pr-4\/6 {
    padding-right: 66.6667%;
  }

  .sm\:pb-4\/6 {
    padding-bottom: 66.6667%;
  }

  .sm\:pl-4\/6 {
    padding-left: 66.6667%;
  }

  .sm\:pt-5\/6 {
    padding-top: 83.3333%;
  }

  .sm\:pr-5\/6 {
    padding-right: 83.3333%;
  }

  .sm\:pb-5\/6 {
    padding-bottom: 83.3333%;
  }

  .sm\:pl-5\/6 {
    padding-left: 83.3333%;
  }

  .sm\:pt-1\/12 {
    padding-top: 8.33333%;
  }

  .sm\:pr-1\/12 {
    padding-right: 8.33333%;
  }

  .sm\:pb-1\/12 {
    padding-bottom: 8.33333%;
  }

  .sm\:pl-1\/12 {
    padding-left: 8.33333%;
  }

  .sm\:pt-2\/12 {
    padding-top: 16.6667%;
  }

  .sm\:pr-2\/12 {
    padding-right: 16.6667%;
  }

  .sm\:pb-2\/12 {
    padding-bottom: 16.6667%;
  }

  .sm\:pl-2\/12 {
    padding-left: 16.6667%;
  }

  .sm\:pt-3\/12 {
    padding-top: 25%;
  }

  .sm\:pr-3\/12 {
    padding-right: 25%;
  }

  .sm\:pb-3\/12 {
    padding-bottom: 25%;
  }

  .sm\:pl-3\/12 {
    padding-left: 25%;
  }

  .sm\:pt-4\/12 {
    padding-top: 33.3333%;
  }

  .sm\:pr-4\/12 {
    padding-right: 33.3333%;
  }

  .sm\:pb-4\/12 {
    padding-bottom: 33.3333%;
  }

  .sm\:pl-4\/12 {
    padding-left: 33.3333%;
  }

  .sm\:pt-5\/12 {
    padding-top: 41.6667%;
  }

  .sm\:pr-5\/12 {
    padding-right: 41.6667%;
  }

  .sm\:pb-5\/12 {
    padding-bottom: 41.6667%;
  }

  .sm\:pl-5\/12 {
    padding-left: 41.6667%;
  }

  .sm\:pt-6\/12 {
    padding-top: 50%;
  }

  .sm\:pr-6\/12 {
    padding-right: 50%;
  }

  .sm\:pb-6\/12 {
    padding-bottom: 50%;
  }

  .sm\:pl-6\/12 {
    padding-left: 50%;
  }

  .sm\:pt-7\/12 {
    padding-top: 58.3333%;
  }

  .sm\:pr-7\/12 {
    padding-right: 58.3333%;
  }

  .sm\:pb-7\/12 {
    padding-bottom: 58.3333%;
  }

  .sm\:pl-7\/12 {
    padding-left: 58.3333%;
  }

  .sm\:pt-8\/12 {
    padding-top: 66.6667%;
  }

  .sm\:pr-8\/12 {
    padding-right: 66.6667%;
  }

  .sm\:pb-8\/12 {
    padding-bottom: 66.6667%;
  }

  .sm\:pl-8\/12 {
    padding-left: 66.6667%;
  }

  .sm\:pt-9\/12 {
    padding-top: 75%;
  }

  .sm\:pr-9\/12 {
    padding-right: 75%;
  }

  .sm\:pb-9\/12 {
    padding-bottom: 75%;
  }

  .sm\:pl-9\/12 {
    padding-left: 75%;
  }

  .sm\:pt-10\/12 {
    padding-top: 83.3333%;
  }

  .sm\:pr-10\/12 {
    padding-right: 83.3333%;
  }

  .sm\:pb-10\/12 {
    padding-bottom: 83.3333%;
  }

  .sm\:pl-10\/12 {
    padding-left: 83.3333%;
  }

  .sm\:pt-11\/12 {
    padding-top: 91.6667%;
  }

  .sm\:pr-11\/12 {
    padding-right: 91.6667%;
  }

  .sm\:pb-11\/12 {
    padding-bottom: 91.6667%;
  }

  .sm\:pl-11\/12 {
    padding-left: 91.6667%;
  }

  .sm\:pt-full {
    padding-top: 100%;
  }

  .sm\:pr-full {
    padding-right: 100%;
  }

  .sm\:pb-full {
    padding-bottom: 100%;
  }

  .sm\:pl-full {
    padding-left: 100%;
  }

  .sm\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .sm\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity) );
  }

  .sm\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--placeholder-opacity) );
  }

  .sm\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity) );
  }

  .sm\:placeholder-cool-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity) );
  }

  .sm\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-50::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity) );
  }

  .sm\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity) );
  }

  .sm\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-50::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity) );
  }

  .sm\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-50::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity) );
  }

  .sm\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-50::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity) );
  }

  .sm\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-50::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity) );
  }

  .sm\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-50::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity) );
  }

  .sm\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity) );
  }

  .sm\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .sm\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-cool-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity) );
  }

  .sm\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity) );
  }

  .sm\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .sm\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: .25;
  }

  .sm\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: .5;
  }

  .sm\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: .75;
  }

  .sm\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: .25;
  }

  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: .5;
  }

  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: .75;
  }

  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .sm\:pointer-events-none {
    pointer-events: none;
  }

  .sm\:pointer-events-auto {
    pointer-events: auto;
  }

  .sm\:static {
    position: static;
  }

  .sm\:fixed {
    position: fixed;
  }

  .sm\:absolute {
    position: absolute;
  }

  .sm\:relative {
    position: relative;
  }

  .sm\:sticky {
    position: sticky;
  }

  .sm\:inset-0 {
    inset: 0;
  }

  .sm\:inset-1 {
    inset: .25rem;
  }

  .sm\:inset-2 {
    inset: .5rem;
  }

  .sm\:inset-3 {
    inset: .75rem;
  }

  .sm\:inset-4 {
    inset: 1rem;
  }

  .sm\:inset-5 {
    inset: 1.25rem;
  }

  .sm\:inset-6 {
    inset: 1.5rem;
  }

  .sm\:inset-7 {
    inset: 1.75rem;
  }

  .sm\:inset-8 {
    inset: 2rem;
  }

  .sm\:inset-9 {
    inset: 2.25rem;
  }

  .sm\:inset-10 {
    inset: 2.5rem;
  }

  .sm\:inset-11 {
    inset: 2.75rem;
  }

  .sm\:inset-12 {
    inset: 3rem;
  }

  .sm\:inset-13 {
    inset: 3.25rem;
  }

  .sm\:inset-14 {
    inset: 3.5rem;
  }

  .sm\:inset-15 {
    inset: 3.75rem;
  }

  .sm\:inset-16 {
    inset: 4rem;
  }

  .sm\:inset-20 {
    inset: 5rem;
  }

  .sm\:inset-24 {
    inset: 6rem;
  }

  .sm\:inset-28 {
    inset: 7rem;
  }

  .sm\:inset-32 {
    inset: 8rem;
  }

  .sm\:inset-36 {
    inset: 9rem;
  }

  .sm\:inset-40 {
    inset: 10rem;
  }

  .sm\:inset-44 {
    inset: 11rem;
  }

  .sm\:inset-48 {
    inset: 12rem;
  }

  .sm\:inset-52 {
    inset: 13rem;
  }

  .sm\:inset-56 {
    inset: 14rem;
  }

  .sm\:inset-60 {
    inset: 15rem;
  }

  .sm\:inset-64 {
    inset: 16rem;
  }

  .sm\:inset-72 {
    inset: 18rem;
  }

  .sm\:inset-80 {
    inset: 20rem;
  }

  .sm\:inset-96 {
    inset: 24rem;
  }

  .sm\:inset-auto {
    inset: auto;
  }

  .sm\:inset-px {
    inset: 1px;
  }

  .sm\:inset-0\.5 {
    inset: .125rem;
  }

  .sm\:inset-1\.5 {
    inset: .375rem;
  }

  .sm\:inset-2\.5 {
    inset: .625rem;
  }

  .sm\:inset-3\.5 {
    inset: .875rem;
  }

  .sm\:inset-1\/2 {
    inset: 50%;
  }

  .sm\:inset-1\/3 {
    inset: 33.3333%;
  }

  .sm\:inset-2\/3 {
    inset: 66.6667%;
  }

  .sm\:inset-1\/4 {
    inset: 25%;
  }

  .sm\:inset-2\/4 {
    inset: 50%;
  }

  .sm\:inset-3\/4 {
    inset: 75%;
  }

  .sm\:inset-1\/5 {
    inset: 20%;
  }

  .sm\:inset-2\/5 {
    inset: 40%;
  }

  .sm\:inset-3\/5 {
    inset: 60%;
  }

  .sm\:inset-4\/5 {
    inset: 80%;
  }

  .sm\:inset-1\/6 {
    inset: 16.6667%;
  }

  .sm\:inset-2\/6 {
    inset: 33.3333%;
  }

  .sm\:inset-3\/6 {
    inset: 50%;
  }

  .sm\:inset-4\/6 {
    inset: 66.6667%;
  }

  .sm\:inset-5\/6 {
    inset: 83.3333%;
  }

  .sm\:inset-1\/12 {
    inset: 8.33333%;
  }

  .sm\:inset-2\/12 {
    inset: 16.6667%;
  }

  .sm\:inset-3\/12 {
    inset: 25%;
  }

  .sm\:inset-4\/12 {
    inset: 33.3333%;
  }

  .sm\:inset-5\/12 {
    inset: 41.6667%;
  }

  .sm\:inset-6\/12 {
    inset: 50%;
  }

  .sm\:inset-7\/12 {
    inset: 58.3333%;
  }

  .sm\:inset-8\/12 {
    inset: 66.6667%;
  }

  .sm\:inset-9\/12 {
    inset: 75%;
  }

  .sm\:inset-10\/12 {
    inset: 83.3333%;
  }

  .sm\:inset-11\/12 {
    inset: 91.6667%;
  }

  .sm\:inset-full {
    inset: 100%;
  }

  .sm\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .sm\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .sm\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .sm\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .sm\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .sm\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .sm\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .sm\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .sm\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .sm\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .sm\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .sm\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .sm\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .sm\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .sm\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .sm\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .sm\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .sm\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .sm\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .sm\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .sm\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .sm\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .sm\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .sm\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .sm\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .sm\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .sm\:inset-y-13 {
    top: 3.25rem;
    bottom: 3.25rem;
  }

  .sm\:inset-x-13 {
    left: 3.25rem;
    right: 3.25rem;
  }

  .sm\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .sm\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .sm\:inset-y-15 {
    top: 3.75rem;
    bottom: 3.75rem;
  }

  .sm\:inset-x-15 {
    left: 3.75rem;
    right: 3.75rem;
  }

  .sm\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .sm\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .sm\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .sm\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .sm\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .sm\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .sm\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .sm\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .sm\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .sm\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .sm\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .sm\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .sm\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .sm\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .sm\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .sm\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .sm\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .sm\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .sm\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .sm\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .sm\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .sm\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .sm\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .sm\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .sm\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .sm\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .sm\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .sm\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .sm\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .sm\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .sm\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .sm\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .sm\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .sm\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .sm\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .sm\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .sm\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .sm\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .sm\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .sm\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .sm\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .sm\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .sm\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .sm\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .sm\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .sm\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .sm\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .sm\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .sm\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .sm\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .sm\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .sm\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .sm\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .sm\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .sm\:inset-y-1\/5 {
    top: 20%;
    bottom: 20%;
  }

  .sm\:inset-x-1\/5 {
    left: 20%;
    right: 20%;
  }

  .sm\:inset-y-2\/5 {
    top: 40%;
    bottom: 40%;
  }

  .sm\:inset-x-2\/5 {
    left: 40%;
    right: 40%;
  }

  .sm\:inset-y-3\/5 {
    top: 60%;
    bottom: 60%;
  }

  .sm\:inset-x-3\/5 {
    left: 60%;
    right: 60%;
  }

  .sm\:inset-y-4\/5 {
    top: 80%;
    bottom: 80%;
  }

  .sm\:inset-x-4\/5 {
    left: 80%;
    right: 80%;
  }

  .sm\:inset-y-1\/6 {
    top: 16.6667%;
    bottom: 16.6667%;
  }

  .sm\:inset-x-1\/6 {
    left: 16.6667%;
    right: 16.6667%;
  }

  .sm\:inset-y-2\/6 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .sm\:inset-x-2\/6 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .sm\:inset-y-3\/6 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:inset-x-3\/6 {
    left: 50%;
    right: 50%;
  }

  .sm\:inset-y-4\/6 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .sm\:inset-x-4\/6 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .sm\:inset-y-5\/6 {
    top: 83.3333%;
    bottom: 83.3333%;
  }

  .sm\:inset-x-5\/6 {
    left: 83.3333%;
    right: 83.3333%;
  }

  .sm\:inset-y-1\/12 {
    top: 8.33333%;
    bottom: 8.33333%;
  }

  .sm\:inset-x-1\/12 {
    left: 8.33333%;
    right: 8.33333%;
  }

  .sm\:inset-y-2\/12 {
    top: 16.6667%;
    bottom: 16.6667%;
  }

  .sm\:inset-x-2\/12 {
    left: 16.6667%;
    right: 16.6667%;
  }

  .sm\:inset-y-3\/12 {
    top: 25%;
    bottom: 25%;
  }

  .sm\:inset-x-3\/12 {
    left: 25%;
    right: 25%;
  }

  .sm\:inset-y-4\/12 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .sm\:inset-x-4\/12 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .sm\:inset-y-5\/12 {
    top: 41.6667%;
    bottom: 41.6667%;
  }

  .sm\:inset-x-5\/12 {
    left: 41.6667%;
    right: 41.6667%;
  }

  .sm\:inset-y-6\/12 {
    top: 50%;
    bottom: 50%;
  }

  .sm\:inset-x-6\/12 {
    left: 50%;
    right: 50%;
  }

  .sm\:inset-y-7\/12 {
    top: 58.3333%;
    bottom: 58.3333%;
  }

  .sm\:inset-x-7\/12 {
    left: 58.3333%;
    right: 58.3333%;
  }

  .sm\:inset-y-8\/12 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .sm\:inset-x-8\/12 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .sm\:inset-y-9\/12 {
    top: 75%;
    bottom: 75%;
  }

  .sm\:inset-x-9\/12 {
    left: 75%;
    right: 75%;
  }

  .sm\:inset-y-10\/12 {
    top: 83.3333%;
    bottom: 83.3333%;
  }

  .sm\:inset-x-10\/12 {
    left: 83.3333%;
    right: 83.3333%;
  }

  .sm\:inset-y-11\/12 {
    top: 91.6667%;
    bottom: 91.6667%;
  }

  .sm\:inset-x-11\/12 {
    left: 91.6667%;
    right: 91.6667%;
  }

  .sm\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .sm\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .sm\:top-0 {
    top: 0;
  }

  .sm\:right-0 {
    right: 0;
  }

  .sm\:bottom-0 {
    bottom: 0;
  }

  .sm\:left-0 {
    left: 0;
  }

  .sm\:top-1 {
    top: .25rem;
  }

  .sm\:right-1 {
    right: .25rem;
  }

  .sm\:bottom-1 {
    bottom: .25rem;
  }

  .sm\:left-1 {
    left: .25rem;
  }

  .sm\:top-2 {
    top: .5rem;
  }

  .sm\:right-2 {
    right: .5rem;
  }

  .sm\:bottom-2 {
    bottom: .5rem;
  }

  .sm\:left-2 {
    left: .5rem;
  }

  .sm\:top-3 {
    top: .75rem;
  }

  .sm\:right-3 {
    right: .75rem;
  }

  .sm\:bottom-3 {
    bottom: .75rem;
  }

  .sm\:left-3 {
    left: .75rem;
  }

  .sm\:top-4 {
    top: 1rem;
  }

  .sm\:right-4 {
    right: 1rem;
  }

  .sm\:bottom-4 {
    bottom: 1rem;
  }

  .sm\:left-4 {
    left: 1rem;
  }

  .sm\:top-5 {
    top: 1.25rem;
  }

  .sm\:right-5 {
    right: 1.25rem;
  }

  .sm\:bottom-5 {
    bottom: 1.25rem;
  }

  .sm\:left-5 {
    left: 1.25rem;
  }

  .sm\:top-6 {
    top: 1.5rem;
  }

  .sm\:right-6 {
    right: 1.5rem;
  }

  .sm\:bottom-6 {
    bottom: 1.5rem;
  }

  .sm\:left-6 {
    left: 1.5rem;
  }

  .sm\:top-7 {
    top: 1.75rem;
  }

  .sm\:right-7 {
    right: 1.75rem;
  }

  .sm\:bottom-7 {
    bottom: 1.75rem;
  }

  .sm\:left-7 {
    left: 1.75rem;
  }

  .sm\:top-8 {
    top: 2rem;
  }

  .sm\:right-8 {
    right: 2rem;
  }

  .sm\:bottom-8 {
    bottom: 2rem;
  }

  .sm\:left-8 {
    left: 2rem;
  }

  .sm\:top-9 {
    top: 2.25rem;
  }

  .sm\:right-9 {
    right: 2.25rem;
  }

  .sm\:bottom-9 {
    bottom: 2.25rem;
  }

  .sm\:left-9 {
    left: 2.25rem;
  }

  .sm\:top-10 {
    top: 2.5rem;
  }

  .sm\:right-10 {
    right: 2.5rem;
  }

  .sm\:bottom-10 {
    bottom: 2.5rem;
  }

  .sm\:left-10 {
    left: 2.5rem;
  }

  .sm\:top-11 {
    top: 2.75rem;
  }

  .sm\:right-11 {
    right: 2.75rem;
  }

  .sm\:bottom-11 {
    bottom: 2.75rem;
  }

  .sm\:left-11 {
    left: 2.75rem;
  }

  .sm\:top-12 {
    top: 3rem;
  }

  .sm\:right-12 {
    right: 3rem;
  }

  .sm\:bottom-12 {
    bottom: 3rem;
  }

  .sm\:left-12 {
    left: 3rem;
  }

  .sm\:top-13 {
    top: 3.25rem;
  }

  .sm\:right-13 {
    right: 3.25rem;
  }

  .sm\:bottom-13 {
    bottom: 3.25rem;
  }

  .sm\:left-13 {
    left: 3.25rem;
  }

  .sm\:top-14 {
    top: 3.5rem;
  }

  .sm\:right-14 {
    right: 3.5rem;
  }

  .sm\:bottom-14 {
    bottom: 3.5rem;
  }

  .sm\:left-14 {
    left: 3.5rem;
  }

  .sm\:top-15 {
    top: 3.75rem;
  }

  .sm\:right-15 {
    right: 3.75rem;
  }

  .sm\:bottom-15 {
    bottom: 3.75rem;
  }

  .sm\:left-15 {
    left: 3.75rem;
  }

  .sm\:top-16 {
    top: 4rem;
  }

  .sm\:right-16 {
    right: 4rem;
  }

  .sm\:bottom-16 {
    bottom: 4rem;
  }

  .sm\:left-16 {
    left: 4rem;
  }

  .sm\:top-20 {
    top: 5rem;
  }

  .sm\:right-20 {
    right: 5rem;
  }

  .sm\:bottom-20 {
    bottom: 5rem;
  }

  .sm\:left-20 {
    left: 5rem;
  }

  .sm\:top-24 {
    top: 6rem;
  }

  .sm\:right-24 {
    right: 6rem;
  }

  .sm\:bottom-24 {
    bottom: 6rem;
  }

  .sm\:left-24 {
    left: 6rem;
  }

  .sm\:top-28 {
    top: 7rem;
  }

  .sm\:right-28 {
    right: 7rem;
  }

  .sm\:bottom-28 {
    bottom: 7rem;
  }

  .sm\:left-28 {
    left: 7rem;
  }

  .sm\:top-32 {
    top: 8rem;
  }

  .sm\:right-32 {
    right: 8rem;
  }

  .sm\:bottom-32 {
    bottom: 8rem;
  }

  .sm\:left-32 {
    left: 8rem;
  }

  .sm\:top-36 {
    top: 9rem;
  }

  .sm\:right-36 {
    right: 9rem;
  }

  .sm\:bottom-36 {
    bottom: 9rem;
  }

  .sm\:left-36 {
    left: 9rem;
  }

  .sm\:top-40 {
    top: 10rem;
  }

  .sm\:right-40 {
    right: 10rem;
  }

  .sm\:bottom-40 {
    bottom: 10rem;
  }

  .sm\:left-40 {
    left: 10rem;
  }

  .sm\:top-44 {
    top: 11rem;
  }

  .sm\:right-44 {
    right: 11rem;
  }

  .sm\:bottom-44 {
    bottom: 11rem;
  }

  .sm\:left-44 {
    left: 11rem;
  }

  .sm\:top-48 {
    top: 12rem;
  }

  .sm\:right-48 {
    right: 12rem;
  }

  .sm\:bottom-48 {
    bottom: 12rem;
  }

  .sm\:left-48 {
    left: 12rem;
  }

  .sm\:top-52 {
    top: 13rem;
  }

  .sm\:right-52 {
    right: 13rem;
  }

  .sm\:bottom-52 {
    bottom: 13rem;
  }

  .sm\:left-52 {
    left: 13rem;
  }

  .sm\:top-56 {
    top: 14rem;
  }

  .sm\:right-56 {
    right: 14rem;
  }

  .sm\:bottom-56 {
    bottom: 14rem;
  }

  .sm\:left-56 {
    left: 14rem;
  }

  .sm\:top-60 {
    top: 15rem;
  }

  .sm\:right-60 {
    right: 15rem;
  }

  .sm\:bottom-60 {
    bottom: 15rem;
  }

  .sm\:left-60 {
    left: 15rem;
  }

  .sm\:top-64 {
    top: 16rem;
  }

  .sm\:right-64 {
    right: 16rem;
  }

  .sm\:bottom-64 {
    bottom: 16rem;
  }

  .sm\:left-64 {
    left: 16rem;
  }

  .sm\:top-72 {
    top: 18rem;
  }

  .sm\:right-72 {
    right: 18rem;
  }

  .sm\:bottom-72 {
    bottom: 18rem;
  }

  .sm\:left-72 {
    left: 18rem;
  }

  .sm\:top-80 {
    top: 20rem;
  }

  .sm\:right-80 {
    right: 20rem;
  }

  .sm\:bottom-80 {
    bottom: 20rem;
  }

  .sm\:left-80 {
    left: 20rem;
  }

  .sm\:top-96 {
    top: 24rem;
  }

  .sm\:right-96 {
    right: 24rem;
  }

  .sm\:bottom-96 {
    bottom: 24rem;
  }

  .sm\:left-96 {
    left: 24rem;
  }

  .sm\:top-auto {
    top: auto;
  }

  .sm\:right-auto {
    right: auto;
  }

  .sm\:bottom-auto {
    bottom: auto;
  }

  .sm\:left-auto {
    left: auto;
  }

  .sm\:top-px {
    top: 1px;
  }

  .sm\:right-px {
    right: 1px;
  }

  .sm\:bottom-px {
    bottom: 1px;
  }

  .sm\:left-px {
    left: 1px;
  }

  .sm\:top-0\.5 {
    top: .125rem;
  }

  .sm\:right-0\.5 {
    right: .125rem;
  }

  .sm\:bottom-0\.5 {
    bottom: .125rem;
  }

  .sm\:left-0\.5 {
    left: .125rem;
  }

  .sm\:top-1\.5 {
    top: .375rem;
  }

  .sm\:right-1\.5 {
    right: .375rem;
  }

  .sm\:bottom-1\.5 {
    bottom: .375rem;
  }

  .sm\:left-1\.5 {
    left: .375rem;
  }

  .sm\:top-2\.5 {
    top: .625rem;
  }

  .sm\:right-2\.5 {
    right: .625rem;
  }

  .sm\:bottom-2\.5 {
    bottom: .625rem;
  }

  .sm\:left-2\.5 {
    left: .625rem;
  }

  .sm\:top-3\.5 {
    top: .875rem;
  }

  .sm\:right-3\.5 {
    right: .875rem;
  }

  .sm\:bottom-3\.5 {
    bottom: .875rem;
  }

  .sm\:left-3\.5 {
    left: .875rem;
  }

  .sm\:top-1\/2 {
    top: 50%;
  }

  .sm\:right-1\/2 {
    right: 50%;
  }

  .sm\:bottom-1\/2 {
    bottom: 50%;
  }

  .sm\:left-1\/2 {
    left: 50%;
  }

  .sm\:top-1\/3 {
    top: 33.3333%;
  }

  .sm\:right-1\/3 {
    right: 33.3333%;
  }

  .sm\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .sm\:left-1\/3 {
    left: 33.3333%;
  }

  .sm\:top-2\/3 {
    top: 66.6667%;
  }

  .sm\:right-2\/3 {
    right: 66.6667%;
  }

  .sm\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .sm\:left-2\/3 {
    left: 66.6667%;
  }

  .sm\:top-1\/4 {
    top: 25%;
  }

  .sm\:right-1\/4 {
    right: 25%;
  }

  .sm\:bottom-1\/4 {
    bottom: 25%;
  }

  .sm\:left-1\/4 {
    left: 25%;
  }

  .sm\:top-2\/4 {
    top: 50%;
  }

  .sm\:right-2\/4 {
    right: 50%;
  }

  .sm\:bottom-2\/4 {
    bottom: 50%;
  }

  .sm\:left-2\/4 {
    left: 50%;
  }

  .sm\:top-3\/4 {
    top: 75%;
  }

  .sm\:right-3\/4 {
    right: 75%;
  }

  .sm\:bottom-3\/4 {
    bottom: 75%;
  }

  .sm\:left-3\/4 {
    left: 75%;
  }

  .sm\:top-1\/5 {
    top: 20%;
  }

  .sm\:right-1\/5 {
    right: 20%;
  }

  .sm\:bottom-1\/5 {
    bottom: 20%;
  }

  .sm\:left-1\/5 {
    left: 20%;
  }

  .sm\:top-2\/5 {
    top: 40%;
  }

  .sm\:right-2\/5 {
    right: 40%;
  }

  .sm\:bottom-2\/5 {
    bottom: 40%;
  }

  .sm\:left-2\/5 {
    left: 40%;
  }

  .sm\:top-3\/5 {
    top: 60%;
  }

  .sm\:right-3\/5 {
    right: 60%;
  }

  .sm\:bottom-3\/5 {
    bottom: 60%;
  }

  .sm\:left-3\/5 {
    left: 60%;
  }

  .sm\:top-4\/5 {
    top: 80%;
  }

  .sm\:right-4\/5 {
    right: 80%;
  }

  .sm\:bottom-4\/5 {
    bottom: 80%;
  }

  .sm\:left-4\/5 {
    left: 80%;
  }

  .sm\:top-1\/6 {
    top: 16.6667%;
  }

  .sm\:right-1\/6 {
    right: 16.6667%;
  }

  .sm\:bottom-1\/6 {
    bottom: 16.6667%;
  }

  .sm\:left-1\/6 {
    left: 16.6667%;
  }

  .sm\:top-2\/6 {
    top: 33.3333%;
  }

  .sm\:right-2\/6 {
    right: 33.3333%;
  }

  .sm\:bottom-2\/6 {
    bottom: 33.3333%;
  }

  .sm\:left-2\/6 {
    left: 33.3333%;
  }

  .sm\:top-3\/6 {
    top: 50%;
  }

  .sm\:right-3\/6 {
    right: 50%;
  }

  .sm\:bottom-3\/6 {
    bottom: 50%;
  }

  .sm\:left-3\/6 {
    left: 50%;
  }

  .sm\:top-4\/6 {
    top: 66.6667%;
  }

  .sm\:right-4\/6 {
    right: 66.6667%;
  }

  .sm\:bottom-4\/6 {
    bottom: 66.6667%;
  }

  .sm\:left-4\/6 {
    left: 66.6667%;
  }

  .sm\:top-5\/6 {
    top: 83.3333%;
  }

  .sm\:right-5\/6 {
    right: 83.3333%;
  }

  .sm\:bottom-5\/6 {
    bottom: 83.3333%;
  }

  .sm\:left-5\/6 {
    left: 83.3333%;
  }

  .sm\:top-1\/12 {
    top: 8.33333%;
  }

  .sm\:right-1\/12 {
    right: 8.33333%;
  }

  .sm\:bottom-1\/12 {
    bottom: 8.33333%;
  }

  .sm\:left-1\/12 {
    left: 8.33333%;
  }

  .sm\:top-2\/12 {
    top: 16.6667%;
  }

  .sm\:right-2\/12 {
    right: 16.6667%;
  }

  .sm\:bottom-2\/12 {
    bottom: 16.6667%;
  }

  .sm\:left-2\/12 {
    left: 16.6667%;
  }

  .sm\:top-3\/12 {
    top: 25%;
  }

  .sm\:right-3\/12 {
    right: 25%;
  }

  .sm\:bottom-3\/12 {
    bottom: 25%;
  }

  .sm\:left-3\/12 {
    left: 25%;
  }

  .sm\:top-4\/12 {
    top: 33.3333%;
  }

  .sm\:right-4\/12 {
    right: 33.3333%;
  }

  .sm\:bottom-4\/12 {
    bottom: 33.3333%;
  }

  .sm\:left-4\/12 {
    left: 33.3333%;
  }

  .sm\:top-5\/12 {
    top: 41.6667%;
  }

  .sm\:right-5\/12 {
    right: 41.6667%;
  }

  .sm\:bottom-5\/12 {
    bottom: 41.6667%;
  }

  .sm\:left-5\/12 {
    left: 41.6667%;
  }

  .sm\:top-6\/12 {
    top: 50%;
  }

  .sm\:right-6\/12 {
    right: 50%;
  }

  .sm\:bottom-6\/12 {
    bottom: 50%;
  }

  .sm\:left-6\/12 {
    left: 50%;
  }

  .sm\:top-7\/12 {
    top: 58.3333%;
  }

  .sm\:right-7\/12 {
    right: 58.3333%;
  }

  .sm\:bottom-7\/12 {
    bottom: 58.3333%;
  }

  .sm\:left-7\/12 {
    left: 58.3333%;
  }

  .sm\:top-8\/12 {
    top: 66.6667%;
  }

  .sm\:right-8\/12 {
    right: 66.6667%;
  }

  .sm\:bottom-8\/12 {
    bottom: 66.6667%;
  }

  .sm\:left-8\/12 {
    left: 66.6667%;
  }

  .sm\:top-9\/12 {
    top: 75%;
  }

  .sm\:right-9\/12 {
    right: 75%;
  }

  .sm\:bottom-9\/12 {
    bottom: 75%;
  }

  .sm\:left-9\/12 {
    left: 75%;
  }

  .sm\:top-10\/12 {
    top: 83.3333%;
  }

  .sm\:right-10\/12 {
    right: 83.3333%;
  }

  .sm\:bottom-10\/12 {
    bottom: 83.3333%;
  }

  .sm\:left-10\/12 {
    left: 83.3333%;
  }

  .sm\:top-11\/12 {
    top: 91.6667%;
  }

  .sm\:right-11\/12 {
    right: 91.6667%;
  }

  .sm\:bottom-11\/12 {
    bottom: 91.6667%;
  }

  .sm\:left-11\/12 {
    left: 91.6667%;
  }

  .sm\:top-full {
    top: 100%;
  }

  .sm\:right-full {
    right: 100%;
  }

  .sm\:bottom-full {
    bottom: 100%;
  }

  .sm\:left-full {
    left: 100%;
  }

  .sm\:resize-none {
    resize: none;
  }

  .sm\:resize-y {
    resize: vertical;
  }

  .sm\:resize-x {
    resize: horizontal;
  }

  .sm\:resize {
    resize: both;
  }

  .sm\:shadow-xs {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .sm\:shadow-sm {
    box-shadow: 0 1px 2px #0000000d;
  }

  .sm\:shadow {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .sm\:shadow-md {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .sm\:shadow-lg {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .sm\:shadow-xl {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .sm\:shadow-2xl {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .sm\:shadow-inner {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .sm\:shadow-outline {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .sm\:shadow-none {
    box-shadow: none;
  }

  .sm\:shadow-solid {
    box-shadow: 0 0 0 2px;
  }

  .sm\:shadow-outline-gray {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .sm\:shadow-outline-blue {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .sm\:shadow-outline-teal {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .sm\:shadow-outline-green {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .sm\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .sm\:shadow-outline-orange {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .sm\:shadow-outline-red {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .sm\:shadow-outline-pink {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .sm\:shadow-outline-purple {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .sm\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .group:focus .sm\:group-focus\:shadow-xs {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .group:focus .sm\:group-focus\:shadow-sm {
    box-shadow: 0 1px 2px #0000000d;
  }

  .group:focus .sm\:group-focus\:shadow {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .group:focus .sm\:group-focus\:shadow-md {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .group:focus .sm\:group-focus\:shadow-lg {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .group:focus .sm\:group-focus\:shadow-xl {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .group:focus .sm\:group-focus\:shadow-2xl {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .group:focus .sm\:group-focus\:shadow-inner {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .group:focus .sm\:group-focus\:shadow-outline {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .group:focus .sm\:group-focus\:shadow-none {
    box-shadow: none;
  }

  .group:focus .sm\:group-focus\:shadow-solid {
    box-shadow: 0 0 0 2px;
  }

  .group:focus .sm\:group-focus\:shadow-outline-gray {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .group:focus .sm\:group-focus\:shadow-outline-blue {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .group:focus .sm\:group-focus\:shadow-outline-teal {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .group:focus .sm\:group-focus\:shadow-outline-green {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .group:focus .sm\:group-focus\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .group:focus .sm\:group-focus\:shadow-outline-orange {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .group:focus .sm\:group-focus\:shadow-outline-red {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .group:focus .sm\:group-focus\:shadow-outline-pink {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .group:focus .sm\:group-focus\:shadow-outline-purple {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .group:focus .sm\:group-focus\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .sm\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .sm\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px #0000000d;
  }

  .sm\:hover\:shadow:hover {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .sm\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .sm\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .sm\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .sm\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .sm\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .sm\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .sm\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .sm\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px;
  }

  .sm\:hover\:shadow-outline-gray:hover {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .sm\:hover\:shadow-outline-blue:hover {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .sm\:hover\:shadow-outline-teal:hover {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .sm\:hover\:shadow-outline-green:hover {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .sm\:hover\:shadow-outline-yellow:hover {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .sm\:hover\:shadow-outline-orange:hover {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .sm\:hover\:shadow-outline-red:hover {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .sm\:hover\:shadow-outline-pink:hover {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .sm\:hover\:shadow-outline-purple:hover {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .sm\:hover\:shadow-outline-indigo:hover {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .sm\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .sm\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px #0000000d;
  }

  .sm\:focus\:shadow:focus {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .sm\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .sm\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .sm\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .sm\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .sm\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .sm\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .sm\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .sm\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px;
  }

  .sm\:focus\:shadow-outline-gray:focus {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .sm\:focus\:shadow-outline-blue:focus {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .sm\:focus\:shadow-outline-teal:focus {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .sm\:focus\:shadow-outline-green:focus {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .sm\:focus\:shadow-outline-yellow:focus {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .sm\:focus\:shadow-outline-orange:focus {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .sm\:focus\:shadow-outline-red:focus {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .sm\:focus\:shadow-outline-pink:focus {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .sm\:focus\:shadow-outline-purple:focus {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .sm\:focus\:shadow-outline-indigo:focus {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .sm\:fill-current {
    fill: currentColor;
  }

  .sm\:stroke-current {
    stroke: currentColor;
  }

  .sm\:stroke-0 {
    stroke-width: 0;
  }

  .sm\:stroke-1 {
    stroke-width: 1px;
  }

  .sm\:stroke-2 {
    stroke-width: 2px;
  }

  .sm\:table-auto {
    table-layout: auto;
  }

  .sm\:table-fixed {
    table-layout: fixed;
  }

  .sm\:text-left {
    text-align: left;
  }

  .sm\:text-center {
    text-align: center;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-justify {
    text-align: justify;
  }

  .sm\:text-transparent {
    color: #0000;
  }

  .sm\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .sm\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .sm\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .sm\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .sm\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .sm\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .sm\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .sm\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .sm\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .sm\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .sm\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .sm\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .sm\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .sm\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .sm\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .sm\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .sm\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .sm\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .sm\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .sm\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .sm\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .sm\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .sm\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .sm\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .sm\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .sm\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .sm\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .sm\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .sm\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .sm\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .sm\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .sm\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .sm\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .sm\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .sm\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .sm\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .sm\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .sm\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .sm\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .sm\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .sm\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .sm\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .sm\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .sm\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .sm\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .sm\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .sm\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .sm\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .sm\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .sm\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .sm\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .sm\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .sm\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .sm\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .sm\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .sm\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .sm\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .sm\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .sm\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .sm\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .sm\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .sm\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .sm\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .sm\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .sm\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .sm\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .sm\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .sm\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .sm\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .sm\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .sm\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .sm\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .sm\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .sm\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .sm\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .sm\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .sm\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .sm\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .sm\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .sm\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .sm\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .sm\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .sm\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .sm\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .sm\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .sm\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .sm\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .sm\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .sm\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .sm\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .sm\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .sm\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .sm\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .sm\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .sm\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .sm\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .sm\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .sm\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .sm\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .sm\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .sm\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .sm\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .sm\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .sm\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .sm\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .sm\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .sm\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .sm\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .sm\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .sm\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .sm\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .sm\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .sm\:group-hover\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .group:hover .sm\:group-hover\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-transparent {
    color: #0000;
  }

  .group:focus .sm\:group-focus\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .group:focus .sm\:group-focus\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .sm\:hover\:text-transparent:hover {
    color: #0000;
  }

  .sm\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .sm\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-50:hover {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .sm\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-50:hover {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-100:hover {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-200:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-300:hover {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-400:hover {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-500:hover {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-600:hover {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-700:hover {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-800:hover {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .sm\:hover\:text-cool-gray-900:hover {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .sm\:hover\:text-red-50:hover {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .sm\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .sm\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .sm\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .sm\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .sm\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .sm\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .sm\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .sm\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .sm\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-50:hover {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .sm\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-50:hover {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .sm\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .sm\:hover\:text-green-50:hover {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .sm\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .sm\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .sm\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .sm\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .sm\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .sm\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .sm\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .sm\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .sm\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-50:hover {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .sm\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-50:hover {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .sm\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-50:hover {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .sm\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-50:hover {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .sm\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-50:hover {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .sm\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .sm\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .sm\:focus-within\:text-white:focus-within {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .sm\:focus-within\:text-black:focus-within {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-50:focus-within {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-100:focus-within {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-200:focus-within {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-300:focus-within {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-400:focus-within {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-500:focus-within {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-600:focus-within {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-700:focus-within {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-800:focus-within {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .sm\:focus-within\:text-gray-900:focus-within {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-50:focus-within {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-100:focus-within {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-200:focus-within {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-300:focus-within {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-400:focus-within {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-500:focus-within {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-600:focus-within {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-700:focus-within {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-800:focus-within {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .sm\:focus-within\:text-cool-gray-900:focus-within {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-100:focus-within {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-200:focus-within {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-300:focus-within {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-400:focus-within {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-500:focus-within {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-600:focus-within {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-700:focus-within {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-800:focus-within {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .sm\:focus-within\:text-red-900:focus-within {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-50:focus-within {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-100:focus-within {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-200:focus-within {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-300:focus-within {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-400:focus-within {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-500:focus-within {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-600:focus-within {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-700:focus-within {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-800:focus-within {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .sm\:focus-within\:text-orange-900:focus-within {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-50:focus-within {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-100:focus-within {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-200:focus-within {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-300:focus-within {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-400:focus-within {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-500:focus-within {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-600:focus-within {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-700:focus-within {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-800:focus-within {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .sm\:focus-within\:text-yellow-900:focus-within {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-50:focus-within {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-100:focus-within {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-200:focus-within {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-300:focus-within {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-400:focus-within {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-500:focus-within {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-600:focus-within {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-700:focus-within {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-800:focus-within {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .sm\:focus-within\:text-green-900:focus-within {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-50:focus-within {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-100:focus-within {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-200:focus-within {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-300:focus-within {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-400:focus-within {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-500:focus-within {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-600:focus-within {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-700:focus-within {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-800:focus-within {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .sm\:focus-within\:text-teal-900:focus-within {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-50:focus-within {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-100:focus-within {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-200:focus-within {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-300:focus-within {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-400:focus-within {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-500:focus-within {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-600:focus-within {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-700:focus-within {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-800:focus-within {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .sm\:focus-within\:text-blue-900:focus-within {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-50:focus-within {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-100:focus-within {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-200:focus-within {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-300:focus-within {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-400:focus-within {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-500:focus-within {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-600:focus-within {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-700:focus-within {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-800:focus-within {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .sm\:focus-within\:text-indigo-900:focus-within {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-50:focus-within {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-100:focus-within {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-200:focus-within {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-300:focus-within {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-400:focus-within {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-500:focus-within {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-600:focus-within {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-700:focus-within {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-800:focus-within {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .sm\:focus-within\:text-purple-900:focus-within {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-100:focus-within {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-200:focus-within {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-300:focus-within {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-400:focus-within {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-500:focus-within {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-600:focus-within {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-700:focus-within {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-800:focus-within {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .sm\:focus-within\:text-pink-900:focus-within {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .sm\:focus\:text-transparent:focus {
    color: #0000;
  }

  .sm\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .sm\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-50:focus {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .sm\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-50:focus {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-100:focus {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-200:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-300:focus {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-400:focus {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-500:focus {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-600:focus {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-700:focus {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-800:focus {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .sm\:focus\:text-cool-gray-900:focus {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .sm\:focus\:text-red-50:focus {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .sm\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .sm\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .sm\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .sm\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .sm\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .sm\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .sm\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .sm\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .sm\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-50:focus {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .sm\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-50:focus {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .sm\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .sm\:focus\:text-green-50:focus {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .sm\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .sm\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .sm\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .sm\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .sm\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .sm\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .sm\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .sm\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .sm\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-50:focus {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .sm\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-50:focus {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .sm\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-50:focus {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .sm\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-50:focus {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .sm\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-50:focus {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .sm\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .sm\:active\:text-transparent:active {
    color: #0000;
  }

  .sm\:active\:text-white:active {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .sm\:active\:text-black:active {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .sm\:active\:text-gray-50:active {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .sm\:active\:text-gray-100:active {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .sm\:active\:text-gray-200:active {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .sm\:active\:text-gray-300:active {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .sm\:active\:text-gray-400:active {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .sm\:active\:text-gray-500:active {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .sm\:active\:text-gray-600:active {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .sm\:active\:text-gray-700:active {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .sm\:active\:text-gray-800:active {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .sm\:active\:text-gray-900:active {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-50:active {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-100:active {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-200:active {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-300:active {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-400:active {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-500:active {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-600:active {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-700:active {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-800:active {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .sm\:active\:text-cool-gray-900:active {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .sm\:active\:text-red-50:active {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .sm\:active\:text-red-100:active {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .sm\:active\:text-red-200:active {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .sm\:active\:text-red-300:active {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .sm\:active\:text-red-400:active {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .sm\:active\:text-red-500:active {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .sm\:active\:text-red-600:active {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .sm\:active\:text-red-700:active {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .sm\:active\:text-red-800:active {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .sm\:active\:text-red-900:active {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .sm\:active\:text-orange-50:active {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .sm\:active\:text-orange-100:active {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .sm\:active\:text-orange-200:active {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .sm\:active\:text-orange-300:active {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .sm\:active\:text-orange-400:active {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .sm\:active\:text-orange-500:active {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .sm\:active\:text-orange-600:active {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .sm\:active\:text-orange-700:active {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .sm\:active\:text-orange-800:active {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .sm\:active\:text-orange-900:active {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-50:active {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-100:active {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-200:active {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-300:active {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-400:active {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-500:active {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-600:active {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-700:active {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-800:active {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .sm\:active\:text-yellow-900:active {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .sm\:active\:text-green-50:active {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .sm\:active\:text-green-100:active {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .sm\:active\:text-green-200:active {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .sm\:active\:text-green-300:active {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .sm\:active\:text-green-400:active {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .sm\:active\:text-green-500:active {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .sm\:active\:text-green-600:active {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .sm\:active\:text-green-700:active {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .sm\:active\:text-green-800:active {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .sm\:active\:text-green-900:active {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .sm\:active\:text-teal-50:active {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .sm\:active\:text-teal-100:active {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .sm\:active\:text-teal-200:active {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .sm\:active\:text-teal-300:active {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .sm\:active\:text-teal-400:active {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .sm\:active\:text-teal-500:active {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .sm\:active\:text-teal-600:active {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .sm\:active\:text-teal-700:active {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .sm\:active\:text-teal-800:active {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .sm\:active\:text-teal-900:active {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .sm\:active\:text-blue-50:active {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .sm\:active\:text-blue-100:active {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .sm\:active\:text-blue-200:active {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .sm\:active\:text-blue-300:active {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .sm\:active\:text-blue-400:active {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .sm\:active\:text-blue-500:active {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .sm\:active\:text-blue-600:active {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .sm\:active\:text-blue-700:active {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .sm\:active\:text-blue-800:active {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .sm\:active\:text-blue-900:active {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-50:active {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-100:active {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-200:active {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-300:active {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-400:active {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-500:active {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-600:active {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-700:active {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-800:active {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .sm\:active\:text-indigo-900:active {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .sm\:active\:text-purple-50:active {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .sm\:active\:text-purple-100:active {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .sm\:active\:text-purple-200:active {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .sm\:active\:text-purple-300:active {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .sm\:active\:text-purple-400:active {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .sm\:active\:text-purple-500:active {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .sm\:active\:text-purple-600:active {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .sm\:active\:text-purple-700:active {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .sm\:active\:text-purple-800:active {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .sm\:active\:text-purple-900:active {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .sm\:active\:text-pink-50:active {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .sm\:active\:text-pink-100:active {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .sm\:active\:text-pink-200:active {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .sm\:active\:text-pink-300:active {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .sm\:active\:text-pink-400:active {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .sm\:active\:text-pink-500:active {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .sm\:active\:text-pink-600:active {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .sm\:active\:text-pink-700:active {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .sm\:active\:text-pink-800:active {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .sm\:active\:text-pink-900:active {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .sm\:text-opacity-0 {
    --text-opacity: 0;
  }

  .sm\:text-opacity-25 {
    --text-opacity: .25;
  }

  .sm\:text-opacity-50 {
    --text-opacity: .5;
  }

  .sm\:text-opacity-75 {
    --text-opacity: .75;
  }

  .sm\:text-opacity-100 {
    --text-opacity: 1;
  }

  .sm\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .sm\:hover\:text-opacity-25:hover {
    --text-opacity: .25;
  }

  .sm\:hover\:text-opacity-50:hover {
    --text-opacity: .5;
  }

  .sm\:hover\:text-opacity-75:hover {
    --text-opacity: .75;
  }

  .sm\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .sm\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .sm\:focus\:text-opacity-25:focus {
    --text-opacity: .25;
  }

  .sm\:focus\:text-opacity-50:focus {
    --text-opacity: .5;
  }

  .sm\:focus\:text-opacity-75:focus {
    --text-opacity: .75;
  }

  .sm\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .sm\:italic {
    font-style: italic;
  }

  .sm\:not-italic {
    font-style: normal;
  }

  .sm\:uppercase {
    text-transform: uppercase;
  }

  .sm\:lowercase {
    text-transform: lowercase;
  }

  .sm\:capitalize {
    text-transform: capitalize;
  }

  .sm\:normal-case {
    text-transform: none;
  }

  .sm\:underline {
    text-decoration: underline;
  }

  .sm\:line-through {
    text-decoration: line-through;
  }

  .sm\:no-underline {
    text-decoration: none;
  }

  .group:hover .sm\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .sm\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .sm\:group-hover\:no-underline {
    text-decoration: none;
  }

  .group:focus .sm\:group-focus\:underline {
    text-decoration: underline;
  }

  .group:focus .sm\:group-focus\:line-through {
    text-decoration: line-through;
  }

  .group:focus .sm\:group-focus\:no-underline {
    text-decoration: none;
  }

  .sm\:hover\:underline:hover {
    text-decoration: underline;
  }

  .sm\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .sm\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .sm\:focus\:underline:focus {
    text-decoration: underline;
  }

  .sm\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .sm\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .sm\:ordinal, .sm\:slashed-zero, .sm\:lining-nums, .sm\:oldstyle-nums, .sm\:proportional-nums, .sm\:tabular-nums, .sm\:diagonal-fractions, .sm\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty, );
    --font-variant-numeric-slashed-zero: var(--tailwind-empty, );
    --font-variant-numeric-figure: var(--tailwind-empty, );
    --font-variant-numeric-spacing: var(--tailwind-empty, );
    --font-variant-numeric-fraction: var(--tailwind-empty, );
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .sm\:normal-nums {
    font-variant-numeric: normal;
  }

  .sm\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .sm\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .sm\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .sm\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .sm\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .sm\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .sm\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .sm\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .sm\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .sm\:tracking-tight {
    letter-spacing: -.025em;
  }

  .sm\:tracking-normal {
    letter-spacing: 0;
  }

  .sm\:tracking-wide {
    letter-spacing: .025em;
  }

  .sm\:tracking-wider {
    letter-spacing: .05em;
  }

  .sm\:tracking-widest {
    letter-spacing: .1em;
  }

  .sm\:select-none {
    user-select: none;
  }

  .sm\:select-text {
    user-select: text;
  }

  .sm\:select-all {
    user-select: all;
  }

  .sm\:select-auto {
    user-select: auto;
  }

  .sm\:align-baseline {
    vertical-align: baseline;
  }

  .sm\:align-top {
    vertical-align: top;
  }

  .sm\:align-middle {
    vertical-align: middle;
  }

  .sm\:align-bottom {
    vertical-align: bottom;
  }

  .sm\:align-text-top {
    vertical-align: text-top;
  }

  .sm\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .sm\:visible {
    visibility: visible;
  }

  .sm\:invisible {
    visibility: hidden;
  }

  .sm\:whitespace-normal {
    white-space: normal;
  }

  .sm\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .sm\:whitespace-pre {
    white-space: pre;
  }

  .sm\:whitespace-pre-line {
    white-space: pre-line;
  }

  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .sm\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .sm\:break-all {
    word-break: break-all;
  }

  .sm\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .sm\:w-0 {
    width: 0;
  }

  .sm\:w-1 {
    width: .25rem;
  }

  .sm\:w-2 {
    width: .5rem;
  }

  .sm\:w-3 {
    width: .75rem;
  }

  .sm\:w-4 {
    width: 1rem;
  }

  .sm\:w-5 {
    width: 1.25rem;
  }

  .sm\:w-6 {
    width: 1.5rem;
  }

  .sm\:w-7 {
    width: 1.75rem;
  }

  .sm\:w-8 {
    width: 2rem;
  }

  .sm\:w-9 {
    width: 2.25rem;
  }

  .sm\:w-10 {
    width: 2.5rem;
  }

  .sm\:w-11 {
    width: 2.75rem;
  }

  .sm\:w-12 {
    width: 3rem;
  }

  .sm\:w-13 {
    width: 3.25rem;
  }

  .sm\:w-14 {
    width: 3.5rem;
  }

  .sm\:w-15 {
    width: 3.75rem;
  }

  .sm\:w-16 {
    width: 4rem;
  }

  .sm\:w-20 {
    width: 5rem;
  }

  .sm\:w-24 {
    width: 6rem;
  }

  .sm\:w-28 {
    width: 7rem;
  }

  .sm\:w-32 {
    width: 8rem;
  }

  .sm\:w-36 {
    width: 9rem;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-44 {
    width: 11rem;
  }

  .sm\:w-48 {
    width: 12rem;
  }

  .sm\:w-52 {
    width: 13rem;
  }

  .sm\:w-56 {
    width: 14rem;
  }

  .sm\:w-60 {
    width: 15rem;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-80 {
    width: 20rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:w-auto {
    width: auto;
  }

  .sm\:w-px {
    width: 1px;
  }

  .sm\:w-0\.5 {
    width: .125rem;
  }

  .sm\:w-1\.5 {
    width: .375rem;
  }

  .sm\:w-2\.5 {
    width: .625rem;
  }

  .sm\:w-3\.5 {
    width: .875rem;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-2\/3 {
    width: 66.6667%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }

  .sm\:w-2\/4 {
    width: 50%;
  }

  .sm\:w-3\/4 {
    width: 75%;
  }

  .sm\:w-1\/5 {
    width: 20%;
  }

  .sm\:w-2\/5 {
    width: 40%;
  }

  .sm\:w-3\/5 {
    width: 60%;
  }

  .sm\:w-4\/5 {
    width: 80%;
  }

  .sm\:w-1\/6 {
    width: 16.6667%;
  }

  .sm\:w-2\/6 {
    width: 33.3333%;
  }

  .sm\:w-3\/6 {
    width: 50%;
  }

  .sm\:w-4\/6 {
    width: 66.6667%;
  }

  .sm\:w-5\/6 {
    width: 83.3333%;
  }

  .sm\:w-1\/12 {
    width: 8.33333%;
  }

  .sm\:w-2\/12 {
    width: 16.6667%;
  }

  .sm\:w-3\/12 {
    width: 25%;
  }

  .sm\:w-4\/12 {
    width: 33.3333%;
  }

  .sm\:w-5\/12 {
    width: 41.6667%;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }

  .sm\:w-7\/12 {
    width: 58.3333%;
  }

  .sm\:w-8\/12 {
    width: 66.6667%;
  }

  .sm\:w-9\/12 {
    width: 75%;
  }

  .sm\:w-10\/12 {
    width: 83.3333%;
  }

  .sm\:w-11\/12 {
    width: 91.6667%;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:w-screen {
    width: 100vw;
  }

  .sm\:w-min-content {
    width: min-content;
  }

  .sm\:w-max-content {
    width: max-content;
  }

  .sm\:z-0 {
    z-index: 0;
  }

  .sm\:z-10 {
    z-index: 10;
  }

  .sm\:z-20 {
    z-index: 20;
  }

  .sm\:z-30 {
    z-index: 30;
  }

  .sm\:z-40 {
    z-index: 40;
  }

  .sm\:z-50 {
    z-index: 50;
  }

  .sm\:z-auto {
    z-index: auto;
  }

  .sm\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .sm\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .sm\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .sm\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .sm\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .sm\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .sm\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .sm\:focus\:z-0:focus {
    z-index: 0;
  }

  .sm\:focus\:z-10:focus {
    z-index: 10;
  }

  .sm\:focus\:z-20:focus {
    z-index: 20;
  }

  .sm\:focus\:z-30:focus {
    z-index: 30;
  }

  .sm\:focus\:z-40:focus {
    z-index: 40;
  }

  .sm\:focus\:z-50:focus {
    z-index: 50;
  }

  .sm\:focus\:z-auto:focus {
    z-index: auto;
  }

  .sm\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .sm\:gap-1 {
    grid-gap: .25rem;
    gap: .25rem;
  }

  .sm\:gap-2 {
    grid-gap: .5rem;
    gap: .5rem;
  }

  .sm\:gap-3 {
    grid-gap: .75rem;
    gap: .75rem;
  }

  .sm\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .sm\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .sm\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .sm\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }

  .sm\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .sm\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }

  .sm\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .sm\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }

  .sm\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .sm\:gap-13 {
    grid-gap: 3.25rem;
    gap: 3.25rem;
  }

  .sm\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }

  .sm\:gap-15 {
    grid-gap: 3.75rem;
    gap: 3.75rem;
  }

  .sm\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .sm\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .sm\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .sm\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }

  .sm\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .sm\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }

  .sm\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .sm\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }

  .sm\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .sm\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }

  .sm\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .sm\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }

  .sm\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .sm\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }

  .sm\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }

  .sm\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }

  .sm\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .sm\:gap-0\.5 {
    grid-gap: .125rem;
    gap: .125rem;
  }

  .sm\:gap-1\.5 {
    grid-gap: .375rem;
    gap: .375rem;
  }

  .sm\:gap-2\.5 {
    grid-gap: .625rem;
    gap: .625rem;
  }

  .sm\:gap-3\.5 {
    grid-gap: .875rem;
    gap: .875rem;
  }

  .sm\:gap-1\/2 {
    grid-gap: 50%;
    gap: 50%;
  }

  .sm\:gap-1\/3 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .sm\:gap-2\/3 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .sm\:gap-1\/4 {
    grid-gap: 25%;
    gap: 25%;
  }

  .sm\:gap-2\/4 {
    grid-gap: 50%;
    gap: 50%;
  }

  .sm\:gap-3\/4 {
    grid-gap: 75%;
    gap: 75%;
  }

  .sm\:gap-1\/5 {
    grid-gap: 20%;
    gap: 20%;
  }

  .sm\:gap-2\/5 {
    grid-gap: 40%;
    gap: 40%;
  }

  .sm\:gap-3\/5 {
    grid-gap: 60%;
    gap: 60%;
  }

  .sm\:gap-4\/5 {
    grid-gap: 80%;
    gap: 80%;
  }

  .sm\:gap-1\/6 {
    grid-gap: 16.6667%;
    gap: 16.6667%;
  }

  .sm\:gap-2\/6 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .sm\:gap-3\/6 {
    grid-gap: 50%;
    gap: 50%;
  }

  .sm\:gap-4\/6 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .sm\:gap-5\/6 {
    grid-gap: 83.3333%;
    gap: 83.3333%;
  }

  .sm\:gap-1\/12 {
    grid-gap: 8.33333%;
    gap: 8.33333%;
  }

  .sm\:gap-2\/12 {
    grid-gap: 16.6667%;
    gap: 16.6667%;
  }

  .sm\:gap-3\/12 {
    grid-gap: 25%;
    gap: 25%;
  }

  .sm\:gap-4\/12 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .sm\:gap-5\/12 {
    grid-gap: 41.6667%;
    gap: 41.6667%;
  }

  .sm\:gap-6\/12 {
    grid-gap: 50%;
    gap: 50%;
  }

  .sm\:gap-7\/12 {
    grid-gap: 58.3333%;
    gap: 58.3333%;
  }

  .sm\:gap-8\/12 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .sm\:gap-9\/12 {
    grid-gap: 75%;
    gap: 75%;
  }

  .sm\:gap-10\/12 {
    grid-gap: 83.3333%;
    gap: 83.3333%;
  }

  .sm\:gap-11\/12 {
    grid-gap: 91.6667%;
    gap: 91.6667%;
  }

  .sm\:gap-full {
    grid-gap: 100%;
    gap: 100%;
  }

  .sm\:col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0;
  }

  .sm\:col-gap-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem;
  }

  .sm\:col-gap-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem;
  }

  .sm\:col-gap-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem;
  }

  .sm\:col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }

  .sm\:col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .sm\:col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .sm\:col-gap-7 {
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }

  .sm\:col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }

  .sm\:col-gap-9 {
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }

  .sm\:col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .sm\:col-gap-11 {
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }

  .sm\:col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }

  .sm\:col-gap-13 {
    grid-column-gap: 3.25rem;
    column-gap: 3.25rem;
  }

  .sm\:col-gap-14 {
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }

  .sm\:col-gap-15 {
    grid-column-gap: 3.75rem;
    column-gap: 3.75rem;
  }

  .sm\:col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }

  .sm\:col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }

  .sm\:col-gap-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }

  .sm\:col-gap-28 {
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }

  .sm\:col-gap-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }

  .sm\:col-gap-36 {
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }

  .sm\:col-gap-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }

  .sm\:col-gap-44 {
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }

  .sm\:col-gap-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }

  .sm\:col-gap-52 {
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }

  .sm\:col-gap-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }

  .sm\:col-gap-60 {
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }

  .sm\:col-gap-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }

  .sm\:col-gap-72 {
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }

  .sm\:col-gap-80 {
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }

  .sm\:col-gap-96 {
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }

  .sm\:col-gap-px {
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  .sm\:col-gap-0\.5 {
    grid-column-gap: .125rem;
    column-gap: .125rem;
  }

  .sm\:col-gap-1\.5 {
    grid-column-gap: .375rem;
    column-gap: .375rem;
  }

  .sm\:col-gap-2\.5 {
    grid-column-gap: .625rem;
    column-gap: .625rem;
  }

  .sm\:col-gap-3\.5 {
    grid-column-gap: .875rem;
    column-gap: .875rem;
  }

  .sm\:col-gap-1\/2 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .sm\:col-gap-1\/3 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .sm\:col-gap-2\/3 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .sm\:col-gap-1\/4 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .sm\:col-gap-2\/4 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .sm\:col-gap-3\/4 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .sm\:col-gap-1\/5 {
    grid-column-gap: 20%;
    column-gap: 20%;
  }

  .sm\:col-gap-2\/5 {
    grid-column-gap: 40%;
    column-gap: 40%;
  }

  .sm\:col-gap-3\/5 {
    grid-column-gap: 60%;
    column-gap: 60%;
  }

  .sm\:col-gap-4\/5 {
    grid-column-gap: 80%;
    column-gap: 80%;
  }

  .sm\:col-gap-1\/6 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .sm\:col-gap-2\/6 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .sm\:col-gap-3\/6 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .sm\:col-gap-4\/6 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .sm\:col-gap-5\/6 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .sm\:col-gap-1\/12 {
    grid-column-gap: 8.33333%;
    column-gap: 8.33333%;
  }

  .sm\:col-gap-2\/12 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .sm\:col-gap-3\/12 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .sm\:col-gap-4\/12 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .sm\:col-gap-5\/12 {
    grid-column-gap: 41.6667%;
    column-gap: 41.6667%;
  }

  .sm\:col-gap-6\/12 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .sm\:col-gap-7\/12 {
    grid-column-gap: 58.3333%;
    column-gap: 58.3333%;
  }

  .sm\:col-gap-8\/12 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .sm\:col-gap-9\/12 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .sm\:col-gap-10\/12 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .sm\:col-gap-11\/12 {
    grid-column-gap: 91.6667%;
    column-gap: 91.6667%;
  }

  .sm\:col-gap-full {
    grid-column-gap: 100%;
    column-gap: 100%;
  }

  .sm\:gap-x-0 {
    grid-column-gap: 0;
    column-gap: 0;
  }

  .sm\:gap-x-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem;
  }

  .sm\:gap-x-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem;
  }

  .sm\:gap-x-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem;
  }

  .sm\:gap-x-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }

  .sm\:gap-x-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .sm\:gap-x-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .sm\:gap-x-7 {
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }

  .sm\:gap-x-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }

  .sm\:gap-x-9 {
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }

  .sm\:gap-x-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .sm\:gap-x-11 {
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }

  .sm\:gap-x-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }

  .sm\:gap-x-13 {
    grid-column-gap: 3.25rem;
    column-gap: 3.25rem;
  }

  .sm\:gap-x-14 {
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }

  .sm\:gap-x-15 {
    grid-column-gap: 3.75rem;
    column-gap: 3.75rem;
  }

  .sm\:gap-x-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }

  .sm\:gap-x-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }

  .sm\:gap-x-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }

  .sm\:gap-x-28 {
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }

  .sm\:gap-x-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }

  .sm\:gap-x-36 {
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }

  .sm\:gap-x-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }

  .sm\:gap-x-44 {
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }

  .sm\:gap-x-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }

  .sm\:gap-x-52 {
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }

  .sm\:gap-x-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }

  .sm\:gap-x-60 {
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }

  .sm\:gap-x-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }

  .sm\:gap-x-72 {
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }

  .sm\:gap-x-80 {
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }

  .sm\:gap-x-96 {
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }

  .sm\:gap-x-px {
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  .sm\:gap-x-0\.5 {
    grid-column-gap: .125rem;
    column-gap: .125rem;
  }

  .sm\:gap-x-1\.5 {
    grid-column-gap: .375rem;
    column-gap: .375rem;
  }

  .sm\:gap-x-2\.5 {
    grid-column-gap: .625rem;
    column-gap: .625rem;
  }

  .sm\:gap-x-3\.5 {
    grid-column-gap: .875rem;
    column-gap: .875rem;
  }

  .sm\:gap-x-1\/2 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .sm\:gap-x-1\/3 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .sm\:gap-x-2\/3 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .sm\:gap-x-1\/4 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .sm\:gap-x-2\/4 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .sm\:gap-x-3\/4 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .sm\:gap-x-1\/5 {
    grid-column-gap: 20%;
    column-gap: 20%;
  }

  .sm\:gap-x-2\/5 {
    grid-column-gap: 40%;
    column-gap: 40%;
  }

  .sm\:gap-x-3\/5 {
    grid-column-gap: 60%;
    column-gap: 60%;
  }

  .sm\:gap-x-4\/5 {
    grid-column-gap: 80%;
    column-gap: 80%;
  }

  .sm\:gap-x-1\/6 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .sm\:gap-x-2\/6 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .sm\:gap-x-3\/6 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .sm\:gap-x-4\/6 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .sm\:gap-x-5\/6 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .sm\:gap-x-1\/12 {
    grid-column-gap: 8.33333%;
    column-gap: 8.33333%;
  }

  .sm\:gap-x-2\/12 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .sm\:gap-x-3\/12 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .sm\:gap-x-4\/12 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .sm\:gap-x-5\/12 {
    grid-column-gap: 41.6667%;
    column-gap: 41.6667%;
  }

  .sm\:gap-x-6\/12 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .sm\:gap-x-7\/12 {
    grid-column-gap: 58.3333%;
    column-gap: 58.3333%;
  }

  .sm\:gap-x-8\/12 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .sm\:gap-x-9\/12 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .sm\:gap-x-10\/12 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .sm\:gap-x-11\/12 {
    grid-column-gap: 91.6667%;
    column-gap: 91.6667%;
  }

  .sm\:gap-x-full {
    grid-column-gap: 100%;
    column-gap: 100%;
  }

  .sm\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .sm\:row-gap-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem;
  }

  .sm\:row-gap-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }

  .sm\:row-gap-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem;
  }

  .sm\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .sm\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .sm\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .sm\:row-gap-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }

  .sm\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .sm\:row-gap-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }

  .sm\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .sm\:row-gap-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }

  .sm\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .sm\:row-gap-13 {
    grid-row-gap: 3.25rem;
    row-gap: 3.25rem;
  }

  .sm\:row-gap-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  .sm\:row-gap-15 {
    grid-row-gap: 3.75rem;
    row-gap: 3.75rem;
  }

  .sm\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .sm\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .sm\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .sm\:row-gap-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }

  .sm\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .sm\:row-gap-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }

  .sm\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .sm\:row-gap-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }

  .sm\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .sm\:row-gap-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }

  .sm\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .sm\:row-gap-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }

  .sm\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .sm\:row-gap-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }

  .sm\:row-gap-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }

  .sm\:row-gap-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }

  .sm\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .sm\:row-gap-0\.5 {
    grid-row-gap: .125rem;
    row-gap: .125rem;
  }

  .sm\:row-gap-1\.5 {
    grid-row-gap: .375rem;
    row-gap: .375rem;
  }

  .sm\:row-gap-2\.5 {
    grid-row-gap: .625rem;
    row-gap: .625rem;
  }

  .sm\:row-gap-3\.5 {
    grid-row-gap: .875rem;
    row-gap: .875rem;
  }

  .sm\:row-gap-1\/2 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .sm\:row-gap-1\/3 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .sm\:row-gap-2\/3 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .sm\:row-gap-1\/4 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .sm\:row-gap-2\/4 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .sm\:row-gap-3\/4 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .sm\:row-gap-1\/5 {
    grid-row-gap: 20%;
    row-gap: 20%;
  }

  .sm\:row-gap-2\/5 {
    grid-row-gap: 40%;
    row-gap: 40%;
  }

  .sm\:row-gap-3\/5 {
    grid-row-gap: 60%;
    row-gap: 60%;
  }

  .sm\:row-gap-4\/5 {
    grid-row-gap: 80%;
    row-gap: 80%;
  }

  .sm\:row-gap-1\/6 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .sm\:row-gap-2\/6 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .sm\:row-gap-3\/6 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .sm\:row-gap-4\/6 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .sm\:row-gap-5\/6 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .sm\:row-gap-1\/12 {
    grid-row-gap: 8.33333%;
    row-gap: 8.33333%;
  }

  .sm\:row-gap-2\/12 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .sm\:row-gap-3\/12 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .sm\:row-gap-4\/12 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .sm\:row-gap-5\/12 {
    grid-row-gap: 41.6667%;
    row-gap: 41.6667%;
  }

  .sm\:row-gap-6\/12 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .sm\:row-gap-7\/12 {
    grid-row-gap: 58.3333%;
    row-gap: 58.3333%;
  }

  .sm\:row-gap-8\/12 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .sm\:row-gap-9\/12 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .sm\:row-gap-10\/12 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .sm\:row-gap-11\/12 {
    grid-row-gap: 91.6667%;
    row-gap: 91.6667%;
  }

  .sm\:row-gap-full {
    grid-row-gap: 100%;
    row-gap: 100%;
  }

  .sm\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .sm\:gap-y-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem;
  }

  .sm\:gap-y-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }

  .sm\:gap-y-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem;
  }

  .sm\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .sm\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .sm\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .sm\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }

  .sm\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .sm\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }

  .sm\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .sm\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }

  .sm\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .sm\:gap-y-13 {
    grid-row-gap: 3.25rem;
    row-gap: 3.25rem;
  }

  .sm\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  .sm\:gap-y-15 {
    grid-row-gap: 3.75rem;
    row-gap: 3.75rem;
  }

  .sm\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .sm\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .sm\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .sm\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }

  .sm\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .sm\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }

  .sm\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .sm\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }

  .sm\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .sm\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }

  .sm\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .sm\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }

  .sm\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .sm\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }

  .sm\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }

  .sm\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }

  .sm\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .sm\:gap-y-0\.5 {
    grid-row-gap: .125rem;
    row-gap: .125rem;
  }

  .sm\:gap-y-1\.5 {
    grid-row-gap: .375rem;
    row-gap: .375rem;
  }

  .sm\:gap-y-2\.5 {
    grid-row-gap: .625rem;
    row-gap: .625rem;
  }

  .sm\:gap-y-3\.5 {
    grid-row-gap: .875rem;
    row-gap: .875rem;
  }

  .sm\:gap-y-1\/2 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .sm\:gap-y-1\/3 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .sm\:gap-y-2\/3 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .sm\:gap-y-1\/4 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .sm\:gap-y-2\/4 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .sm\:gap-y-3\/4 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .sm\:gap-y-1\/5 {
    grid-row-gap: 20%;
    row-gap: 20%;
  }

  .sm\:gap-y-2\/5 {
    grid-row-gap: 40%;
    row-gap: 40%;
  }

  .sm\:gap-y-3\/5 {
    grid-row-gap: 60%;
    row-gap: 60%;
  }

  .sm\:gap-y-4\/5 {
    grid-row-gap: 80%;
    row-gap: 80%;
  }

  .sm\:gap-y-1\/6 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .sm\:gap-y-2\/6 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .sm\:gap-y-3\/6 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .sm\:gap-y-4\/6 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .sm\:gap-y-5\/6 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .sm\:gap-y-1\/12 {
    grid-row-gap: 8.33333%;
    row-gap: 8.33333%;
  }

  .sm\:gap-y-2\/12 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .sm\:gap-y-3\/12 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .sm\:gap-y-4\/12 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .sm\:gap-y-5\/12 {
    grid-row-gap: 41.6667%;
    row-gap: 41.6667%;
  }

  .sm\:gap-y-6\/12 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .sm\:gap-y-7\/12 {
    grid-row-gap: 58.3333%;
    row-gap: 58.3333%;
  }

  .sm\:gap-y-8\/12 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .sm\:gap-y-9\/12 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .sm\:gap-y-10\/12 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .sm\:gap-y-11\/12 {
    grid-row-gap: 91.6667%;
    row-gap: 91.6667%;
  }

  .sm\:gap-y-full {
    grid-row-gap: 100%;
    row-gap: 100%;
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row;
  }

  .sm\:grid-flow-col {
    grid-auto-flow: column;
  }

  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .sm\:grid-cols-none {
    grid-template-columns: none;
  }

  .sm\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .sm\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .sm\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .sm\:col-auto {
    grid-column: auto;
  }

  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:col-start-1 {
    grid-column-start: 1;
  }

  .sm\:col-start-2 {
    grid-column-start: 2;
  }

  .sm\:col-start-3 {
    grid-column-start: 3;
  }

  .sm\:col-start-4 {
    grid-column-start: 4;
  }

  .sm\:col-start-5 {
    grid-column-start: 5;
  }

  .sm\:col-start-6 {
    grid-column-start: 6;
  }

  .sm\:col-start-7 {
    grid-column-start: 7;
  }

  .sm\:col-start-8 {
    grid-column-start: 8;
  }

  .sm\:col-start-9 {
    grid-column-start: 9;
  }

  .sm\:col-start-10 {
    grid-column-start: 10;
  }

  .sm\:col-start-11 {
    grid-column-start: 11;
  }

  .sm\:col-start-12 {
    grid-column-start: 12;
  }

  .sm\:col-start-13 {
    grid-column-start: 13;
  }

  .sm\:col-start-auto {
    grid-column-start: auto;
  }

  .sm\:col-end-1 {
    grid-column-end: 1;
  }

  .sm\:col-end-2 {
    grid-column-end: 2;
  }

  .sm\:col-end-3 {
    grid-column-end: 3;
  }

  .sm\:col-end-4 {
    grid-column-end: 4;
  }

  .sm\:col-end-5 {
    grid-column-end: 5;
  }

  .sm\:col-end-6 {
    grid-column-end: 6;
  }

  .sm\:col-end-7 {
    grid-column-end: 7;
  }

  .sm\:col-end-8 {
    grid-column-end: 8;
  }

  .sm\:col-end-9 {
    grid-column-end: 9;
  }

  .sm\:col-end-10 {
    grid-column-end: 10;
  }

  .sm\:col-end-11 {
    grid-column-end: 11;
  }

  .sm\:col-end-12 {
    grid-column-end: 12;
  }

  .sm\:col-end-13 {
    grid-column-end: 13;
  }

  .sm\:col-end-auto {
    grid-column-end: auto;
  }

  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .sm\:grid-rows-none {
    grid-template-rows: none;
  }

  .sm\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .sm\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .sm\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .sm\:row-auto {
    grid-row: auto;
  }

  .sm\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .sm\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .sm\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .sm\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .sm\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .sm\:row-span-full {
    grid-row: 1 / -1;
  }

  .sm\:row-start-1 {
    grid-row-start: 1;
  }

  .sm\:row-start-2 {
    grid-row-start: 2;
  }

  .sm\:row-start-3 {
    grid-row-start: 3;
  }

  .sm\:row-start-4 {
    grid-row-start: 4;
  }

  .sm\:row-start-5 {
    grid-row-start: 5;
  }

  .sm\:row-start-6 {
    grid-row-start: 6;
  }

  .sm\:row-start-7 {
    grid-row-start: 7;
  }

  .sm\:row-start-auto {
    grid-row-start: auto;
  }

  .sm\:row-end-1 {
    grid-row-end: 1;
  }

  .sm\:row-end-2 {
    grid-row-end: 2;
  }

  .sm\:row-end-3 {
    grid-row-end: 3;
  }

  .sm\:row-end-4 {
    grid-row-end: 4;
  }

  .sm\:row-end-5 {
    grid-row-end: 5;
  }

  .sm\:row-end-6 {
    grid-row-end: 6;
  }

  .sm\:row-end-7 {
    grid-row-end: 7;
  }

  .sm\:row-end-auto {
    grid-row-end: auto;
  }

  .sm\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x) ) translateY(var(--transform-translate-y) ) rotate(var(--transform-rotate) ) skewX(var(--transform-skew-x) ) skewY(var(--transform-skew-y) ) scaleX(var(--transform-scale-x) ) scaleY(var(--transform-scale-y) );
  }

  .sm\:transform-none {
    transform: none;
  }

  .sm\:origin-center {
    transform-origin: center;
  }

  .sm\:origin-top {
    transform-origin: top;
  }

  .sm\:origin-top-right {
    transform-origin: 100% 0;
  }

  .sm\:origin-right {
    transform-origin: 100%;
  }

  .sm\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .sm\:origin-bottom {
    transform-origin: bottom;
  }

  .sm\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .sm\:origin-left {
    transform-origin: 0;
  }

  .sm\:origin-top-left {
    transform-origin: 0 0;
  }

  .sm\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .sm\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .sm\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .sm\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .sm\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .sm\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .sm\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .sm\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .sm\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .sm\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .sm\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .sm\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .sm\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .sm\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .sm\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .sm\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .sm\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .sm\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .sm\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .sm\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .sm\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .sm\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .sm\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .sm\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .sm\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .sm\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .sm\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .sm\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .sm\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .sm\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .sm\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .sm\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .sm\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .sm\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .sm\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .sm\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .sm\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .sm\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .sm\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .sm\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .sm\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .sm\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .sm\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .sm\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .sm\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .sm\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .sm\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .sm\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .sm\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .sm\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .sm\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .sm\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .sm\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .sm\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .sm\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .sm\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .sm\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .sm\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .sm\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .sm\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .sm\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .sm\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .sm\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .sm\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .sm\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .sm\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .sm\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .sm\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .sm\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .sm\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .sm\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .sm\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .sm\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .sm\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .sm\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .sm\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .sm\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .sm\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .sm\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .sm\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .sm\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .sm\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .sm\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .sm\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .sm\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .sm\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .sm\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .sm\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .sm\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .sm\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .sm\:rotate-0 {
    --transform-rotate: 0;
  }

  .sm\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .sm\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .sm\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .sm\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .sm\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .sm\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .sm\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .sm\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .sm\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .sm\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .sm\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .sm\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .sm\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .sm\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .sm\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .sm\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .sm\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .sm\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .sm\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .sm\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .sm\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .sm\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .sm\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .sm\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .sm\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .sm\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .sm\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .sm\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .sm\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .sm\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .sm\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .sm\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .sm\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .sm\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .sm\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .sm\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .sm\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .sm\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .sm\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .sm\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .sm\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .sm\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .sm\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .sm\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .sm\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .sm\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .sm\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .sm\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .sm\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .sm\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .sm\:translate-x-0 {
    --transform-translate-x: 0;
  }

  .sm\:translate-x-1 {
    --transform-translate-x: .25rem;
  }

  .sm\:translate-x-2 {
    --transform-translate-x: .5rem;
  }

  .sm\:translate-x-3 {
    --transform-translate-x: .75rem;
  }

  .sm\:translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .sm\:translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .sm\:translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .sm\:translate-x-7 {
    --transform-translate-x: 1.75rem;
  }

  .sm\:translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .sm\:translate-x-9 {
    --transform-translate-x: 2.25rem;
  }

  .sm\:translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .sm\:translate-x-11 {
    --transform-translate-x: 2.75rem;
  }

  .sm\:translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .sm\:translate-x-13 {
    --transform-translate-x: 3.25rem;
  }

  .sm\:translate-x-14 {
    --transform-translate-x: 3.5rem;
  }

  .sm\:translate-x-15 {
    --transform-translate-x: 3.75rem;
  }

  .sm\:translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .sm\:translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .sm\:translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .sm\:translate-x-28 {
    --transform-translate-x: 7rem;
  }

  .sm\:translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .sm\:translate-x-36 {
    --transform-translate-x: 9rem;
  }

  .sm\:translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .sm\:translate-x-44 {
    --transform-translate-x: 11rem;
  }

  .sm\:translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .sm\:translate-x-52 {
    --transform-translate-x: 13rem;
  }

  .sm\:translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .sm\:translate-x-60 {
    --transform-translate-x: 15rem;
  }

  .sm\:translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .sm\:translate-x-72 {
    --transform-translate-x: 18rem;
  }

  .sm\:translate-x-80 {
    --transform-translate-x: 20rem;
  }

  .sm\:translate-x-96 {
    --transform-translate-x: 24rem;
  }

  .sm\:translate-x-px {
    --transform-translate-x: 1px;
  }

  .sm\:translate-x-0\.5 {
    --transform-translate-x: .125rem;
  }

  .sm\:translate-x-1\.5 {
    --transform-translate-x: .375rem;
  }

  .sm\:translate-x-2\.5 {
    --transform-translate-x: .625rem;
  }

  .sm\:translate-x-3\.5 {
    --transform-translate-x: .875rem;
  }

  .sm\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .sm\:translate-x-1\/3 {
    --transform-translate-x: 33.3333%;
  }

  .sm\:translate-x-2\/3 {
    --transform-translate-x: 66.6667%;
  }

  .sm\:translate-x-1\/4 {
    --transform-translate-x: 25%;
  }

  .sm\:translate-x-2\/4 {
    --transform-translate-x: 50%;
  }

  .sm\:translate-x-3\/4 {
    --transform-translate-x: 75%;
  }

  .sm\:translate-x-1\/5 {
    --transform-translate-x: 20%;
  }

  .sm\:translate-x-2\/5 {
    --transform-translate-x: 40%;
  }

  .sm\:translate-x-3\/5 {
    --transform-translate-x: 60%;
  }

  .sm\:translate-x-4\/5 {
    --transform-translate-x: 80%;
  }

  .sm\:translate-x-1\/6 {
    --transform-translate-x: 16.6667%;
  }

  .sm\:translate-x-2\/6 {
    --transform-translate-x: 33.3333%;
  }

  .sm\:translate-x-3\/6 {
    --transform-translate-x: 50%;
  }

  .sm\:translate-x-4\/6 {
    --transform-translate-x: 66.6667%;
  }

  .sm\:translate-x-5\/6 {
    --transform-translate-x: 83.3333%;
  }

  .sm\:translate-x-1\/12 {
    --transform-translate-x: 8.33333%;
  }

  .sm\:translate-x-2\/12 {
    --transform-translate-x: 16.6667%;
  }

  .sm\:translate-x-3\/12 {
    --transform-translate-x: 25%;
  }

  .sm\:translate-x-4\/12 {
    --transform-translate-x: 33.3333%;
  }

  .sm\:translate-x-5\/12 {
    --transform-translate-x: 41.6667%;
  }

  .sm\:translate-x-6\/12 {
    --transform-translate-x: 50%;
  }

  .sm\:translate-x-7\/12 {
    --transform-translate-x: 58.3333%;
  }

  .sm\:translate-x-8\/12 {
    --transform-translate-x: 66.6667%;
  }

  .sm\:translate-x-9\/12 {
    --transform-translate-x: 75%;
  }

  .sm\:translate-x-10\/12 {
    --transform-translate-x: 83.3333%;
  }

  .sm\:translate-x-11\/12 {
    --transform-translate-x: 91.6667%;
  }

  .sm\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .sm\:-translate-x-1 {
    --transform-translate-x: -.25rem;
  }

  .sm\:-translate-x-2 {
    --transform-translate-x: -.5rem;
  }

  .sm\:-translate-x-3 {
    --transform-translate-x: -.75rem;
  }

  .sm\:-translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .sm\:-translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .sm\:-translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .sm\:-translate-x-7 {
    --transform-translate-x: -1.75rem;
  }

  .sm\:-translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .sm\:-translate-x-9 {
    --transform-translate-x: -2.25rem;
  }

  .sm\:-translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .sm\:-translate-x-11 {
    --transform-translate-x: -2.75rem;
  }

  .sm\:-translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .sm\:-translate-x-13 {
    --transform-translate-x: -3.25rem;
  }

  .sm\:-translate-x-14 {
    --transform-translate-x: -3.5rem;
  }

  .sm\:-translate-x-15 {
    --transform-translate-x: -3.75rem;
  }

  .sm\:-translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .sm\:-translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .sm\:-translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .sm\:-translate-x-28 {
    --transform-translate-x: -7rem;
  }

  .sm\:-translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .sm\:-translate-x-36 {
    --transform-translate-x: -9rem;
  }

  .sm\:-translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .sm\:-translate-x-44 {
    --transform-translate-x: -11rem;
  }

  .sm\:-translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .sm\:-translate-x-52 {
    --transform-translate-x: -13rem;
  }

  .sm\:-translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .sm\:-translate-x-60 {
    --transform-translate-x: -15rem;
  }

  .sm\:-translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .sm\:-translate-x-72 {
    --transform-translate-x: -18rem;
  }

  .sm\:-translate-x-80 {
    --transform-translate-x: -20rem;
  }

  .sm\:-translate-x-96 {
    --transform-translate-x: -24rem;
  }

  .sm\:-translate-x-px {
    --transform-translate-x: -1px;
  }

  .sm\:-translate-x-0\.5 {
    --transform-translate-x: -.125rem;
  }

  .sm\:-translate-x-1\.5 {
    --transform-translate-x: -.375rem;
  }

  .sm\:-translate-x-2\.5 {
    --transform-translate-x: -.625rem;
  }

  .sm\:-translate-x-3\.5 {
    --transform-translate-x: -.875rem;
  }

  .sm\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .sm\:-translate-x-1\/3 {
    --transform-translate-x: -33.3333%;
  }

  .sm\:-translate-x-2\/3 {
    --transform-translate-x: -66.6667%;
  }

  .sm\:-translate-x-1\/4 {
    --transform-translate-x: -25%;
  }

  .sm\:-translate-x-2\/4 {
    --transform-translate-x: -50%;
  }

  .sm\:-translate-x-3\/4 {
    --transform-translate-x: -75%;
  }

  .sm\:-translate-x-1\/5 {
    --transform-translate-x: -20%;
  }

  .sm\:-translate-x-2\/5 {
    --transform-translate-x: -40%;
  }

  .sm\:-translate-x-3\/5 {
    --transform-translate-x: -60%;
  }

  .sm\:-translate-x-4\/5 {
    --transform-translate-x: -80%;
  }

  .sm\:-translate-x-1\/6 {
    --transform-translate-x: -16.6667%;
  }

  .sm\:-translate-x-2\/6 {
    --transform-translate-x: -33.3333%;
  }

  .sm\:-translate-x-3\/6 {
    --transform-translate-x: -50%;
  }

  .sm\:-translate-x-4\/6 {
    --transform-translate-x: -66.6667%;
  }

  .sm\:-translate-x-5\/6 {
    --transform-translate-x: -83.3333%;
  }

  .sm\:-translate-x-1\/12 {
    --transform-translate-x: -8.33333%;
  }

  .sm\:-translate-x-2\/12 {
    --transform-translate-x: -16.6667%;
  }

  .sm\:-translate-x-3\/12 {
    --transform-translate-x: -25%;
  }

  .sm\:-translate-x-4\/12 {
    --transform-translate-x: -33.3333%;
  }

  .sm\:-translate-x-5\/12 {
    --transform-translate-x: -41.6667%;
  }

  .sm\:-translate-x-6\/12 {
    --transform-translate-x: -50%;
  }

  .sm\:-translate-x-7\/12 {
    --transform-translate-x: -58.3333%;
  }

  .sm\:-translate-x-8\/12 {
    --transform-translate-x: -66.6667%;
  }

  .sm\:-translate-x-9\/12 {
    --transform-translate-x: -75%;
  }

  .sm\:-translate-x-10\/12 {
    --transform-translate-x: -83.3333%;
  }

  .sm\:-translate-x-11\/12 {
    --transform-translate-x: -91.6667%;
  }

  .sm\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .sm\:translate-y-0 {
    --transform-translate-y: 0;
  }

  .sm\:translate-y-1 {
    --transform-translate-y: .25rem;
  }

  .sm\:translate-y-2 {
    --transform-translate-y: .5rem;
  }

  .sm\:translate-y-3 {
    --transform-translate-y: .75rem;
  }

  .sm\:translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .sm\:translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .sm\:translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .sm\:translate-y-7 {
    --transform-translate-y: 1.75rem;
  }

  .sm\:translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .sm\:translate-y-9 {
    --transform-translate-y: 2.25rem;
  }

  .sm\:translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .sm\:translate-y-11 {
    --transform-translate-y: 2.75rem;
  }

  .sm\:translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .sm\:translate-y-13 {
    --transform-translate-y: 3.25rem;
  }

  .sm\:translate-y-14 {
    --transform-translate-y: 3.5rem;
  }

  .sm\:translate-y-15 {
    --transform-translate-y: 3.75rem;
  }

  .sm\:translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .sm\:translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .sm\:translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .sm\:translate-y-28 {
    --transform-translate-y: 7rem;
  }

  .sm\:translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .sm\:translate-y-36 {
    --transform-translate-y: 9rem;
  }

  .sm\:translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .sm\:translate-y-44 {
    --transform-translate-y: 11rem;
  }

  .sm\:translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .sm\:translate-y-52 {
    --transform-translate-y: 13rem;
  }

  .sm\:translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .sm\:translate-y-60 {
    --transform-translate-y: 15rem;
  }

  .sm\:translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .sm\:translate-y-72 {
    --transform-translate-y: 18rem;
  }

  .sm\:translate-y-80 {
    --transform-translate-y: 20rem;
  }

  .sm\:translate-y-96 {
    --transform-translate-y: 24rem;
  }

  .sm\:translate-y-px {
    --transform-translate-y: 1px;
  }

  .sm\:translate-y-0\.5 {
    --transform-translate-y: .125rem;
  }

  .sm\:translate-y-1\.5 {
    --transform-translate-y: .375rem;
  }

  .sm\:translate-y-2\.5 {
    --transform-translate-y: .625rem;
  }

  .sm\:translate-y-3\.5 {
    --transform-translate-y: .875rem;
  }

  .sm\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .sm\:translate-y-1\/3 {
    --transform-translate-y: 33.3333%;
  }

  .sm\:translate-y-2\/3 {
    --transform-translate-y: 66.6667%;
  }

  .sm\:translate-y-1\/4 {
    --transform-translate-y: 25%;
  }

  .sm\:translate-y-2\/4 {
    --transform-translate-y: 50%;
  }

  .sm\:translate-y-3\/4 {
    --transform-translate-y: 75%;
  }

  .sm\:translate-y-1\/5 {
    --transform-translate-y: 20%;
  }

  .sm\:translate-y-2\/5 {
    --transform-translate-y: 40%;
  }

  .sm\:translate-y-3\/5 {
    --transform-translate-y: 60%;
  }

  .sm\:translate-y-4\/5 {
    --transform-translate-y: 80%;
  }

  .sm\:translate-y-1\/6 {
    --transform-translate-y: 16.6667%;
  }

  .sm\:translate-y-2\/6 {
    --transform-translate-y: 33.3333%;
  }

  .sm\:translate-y-3\/6 {
    --transform-translate-y: 50%;
  }

  .sm\:translate-y-4\/6 {
    --transform-translate-y: 66.6667%;
  }

  .sm\:translate-y-5\/6 {
    --transform-translate-y: 83.3333%;
  }

  .sm\:translate-y-1\/12 {
    --transform-translate-y: 8.33333%;
  }

  .sm\:translate-y-2\/12 {
    --transform-translate-y: 16.6667%;
  }

  .sm\:translate-y-3\/12 {
    --transform-translate-y: 25%;
  }

  .sm\:translate-y-4\/12 {
    --transform-translate-y: 33.3333%;
  }

  .sm\:translate-y-5\/12 {
    --transform-translate-y: 41.6667%;
  }

  .sm\:translate-y-6\/12 {
    --transform-translate-y: 50%;
  }

  .sm\:translate-y-7\/12 {
    --transform-translate-y: 58.3333%;
  }

  .sm\:translate-y-8\/12 {
    --transform-translate-y: 66.6667%;
  }

  .sm\:translate-y-9\/12 {
    --transform-translate-y: 75%;
  }

  .sm\:translate-y-10\/12 {
    --transform-translate-y: 83.3333%;
  }

  .sm\:translate-y-11\/12 {
    --transform-translate-y: 91.6667%;
  }

  .sm\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .sm\:-translate-y-1 {
    --transform-translate-y: -.25rem;
  }

  .sm\:-translate-y-2 {
    --transform-translate-y: -.5rem;
  }

  .sm\:-translate-y-3 {
    --transform-translate-y: -.75rem;
  }

  .sm\:-translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .sm\:-translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .sm\:-translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .sm\:-translate-y-7 {
    --transform-translate-y: -1.75rem;
  }

  .sm\:-translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .sm\:-translate-y-9 {
    --transform-translate-y: -2.25rem;
  }

  .sm\:-translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .sm\:-translate-y-11 {
    --transform-translate-y: -2.75rem;
  }

  .sm\:-translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .sm\:-translate-y-13 {
    --transform-translate-y: -3.25rem;
  }

  .sm\:-translate-y-14 {
    --transform-translate-y: -3.5rem;
  }

  .sm\:-translate-y-15 {
    --transform-translate-y: -3.75rem;
  }

  .sm\:-translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .sm\:-translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .sm\:-translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .sm\:-translate-y-28 {
    --transform-translate-y: -7rem;
  }

  .sm\:-translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .sm\:-translate-y-36 {
    --transform-translate-y: -9rem;
  }

  .sm\:-translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .sm\:-translate-y-44 {
    --transform-translate-y: -11rem;
  }

  .sm\:-translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .sm\:-translate-y-52 {
    --transform-translate-y: -13rem;
  }

  .sm\:-translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .sm\:-translate-y-60 {
    --transform-translate-y: -15rem;
  }

  .sm\:-translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .sm\:-translate-y-72 {
    --transform-translate-y: -18rem;
  }

  .sm\:-translate-y-80 {
    --transform-translate-y: -20rem;
  }

  .sm\:-translate-y-96 {
    --transform-translate-y: -24rem;
  }

  .sm\:-translate-y-px {
    --transform-translate-y: -1px;
  }

  .sm\:-translate-y-0\.5 {
    --transform-translate-y: -.125rem;
  }

  .sm\:-translate-y-1\.5 {
    --transform-translate-y: -.375rem;
  }

  .sm\:-translate-y-2\.5 {
    --transform-translate-y: -.625rem;
  }

  .sm\:-translate-y-3\.5 {
    --transform-translate-y: -.875rem;
  }

  .sm\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .sm\:-translate-y-1\/3 {
    --transform-translate-y: -33.3333%;
  }

  .sm\:-translate-y-2\/3 {
    --transform-translate-y: -66.6667%;
  }

  .sm\:-translate-y-1\/4 {
    --transform-translate-y: -25%;
  }

  .sm\:-translate-y-2\/4 {
    --transform-translate-y: -50%;
  }

  .sm\:-translate-y-3\/4 {
    --transform-translate-y: -75%;
  }

  .sm\:-translate-y-1\/5 {
    --transform-translate-y: -20%;
  }

  .sm\:-translate-y-2\/5 {
    --transform-translate-y: -40%;
  }

  .sm\:-translate-y-3\/5 {
    --transform-translate-y: -60%;
  }

  .sm\:-translate-y-4\/5 {
    --transform-translate-y: -80%;
  }

  .sm\:-translate-y-1\/6 {
    --transform-translate-y: -16.6667%;
  }

  .sm\:-translate-y-2\/6 {
    --transform-translate-y: -33.3333%;
  }

  .sm\:-translate-y-3\/6 {
    --transform-translate-y: -50%;
  }

  .sm\:-translate-y-4\/6 {
    --transform-translate-y: -66.6667%;
  }

  .sm\:-translate-y-5\/6 {
    --transform-translate-y: -83.3333%;
  }

  .sm\:-translate-y-1\/12 {
    --transform-translate-y: -8.33333%;
  }

  .sm\:-translate-y-2\/12 {
    --transform-translate-y: -16.6667%;
  }

  .sm\:-translate-y-3\/12 {
    --transform-translate-y: -25%;
  }

  .sm\:-translate-y-4\/12 {
    --transform-translate-y: -33.3333%;
  }

  .sm\:-translate-y-5\/12 {
    --transform-translate-y: -41.6667%;
  }

  .sm\:-translate-y-6\/12 {
    --transform-translate-y: -50%;
  }

  .sm\:-translate-y-7\/12 {
    --transform-translate-y: -58.3333%;
  }

  .sm\:-translate-y-8\/12 {
    --transform-translate-y: -66.6667%;
  }

  .sm\:-translate-y-9\/12 {
    --transform-translate-y: -75%;
  }

  .sm\:-translate-y-10\/12 {
    --transform-translate-y: -83.3333%;
  }

  .sm\:-translate-y-11\/12 {
    --transform-translate-y: -91.6667%;
  }

  .sm\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .sm\:hover\:translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .sm\:hover\:translate-x-1:hover {
    --transform-translate-x: .25rem;
  }

  .sm\:hover\:translate-x-2:hover {
    --transform-translate-x: .5rem;
  }

  .sm\:hover\:translate-x-3:hover {
    --transform-translate-x: .75rem;
  }

  .sm\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .sm\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .sm\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .sm\:hover\:translate-x-7:hover {
    --transform-translate-x: 1.75rem;
  }

  .sm\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .sm\:hover\:translate-x-9:hover {
    --transform-translate-x: 2.25rem;
  }

  .sm\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .sm\:hover\:translate-x-11:hover {
    --transform-translate-x: 2.75rem;
  }

  .sm\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .sm\:hover\:translate-x-13:hover {
    --transform-translate-x: 3.25rem;
  }

  .sm\:hover\:translate-x-14:hover {
    --transform-translate-x: 3.5rem;
  }

  .sm\:hover\:translate-x-15:hover {
    --transform-translate-x: 3.75rem;
  }

  .sm\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .sm\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .sm\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .sm\:hover\:translate-x-28:hover {
    --transform-translate-x: 7rem;
  }

  .sm\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .sm\:hover\:translate-x-36:hover {
    --transform-translate-x: 9rem;
  }

  .sm\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .sm\:hover\:translate-x-44:hover {
    --transform-translate-x: 11rem;
  }

  .sm\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .sm\:hover\:translate-x-52:hover {
    --transform-translate-x: 13rem;
  }

  .sm\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .sm\:hover\:translate-x-60:hover {
    --transform-translate-x: 15rem;
  }

  .sm\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .sm\:hover\:translate-x-72:hover {
    --transform-translate-x: 18rem;
  }

  .sm\:hover\:translate-x-80:hover {
    --transform-translate-x: 20rem;
  }

  .sm\:hover\:translate-x-96:hover {
    --transform-translate-x: 24rem;
  }

  .sm\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .sm\:hover\:translate-x-0\.5:hover {
    --transform-translate-x: .125rem;
  }

  .sm\:hover\:translate-x-1\.5:hover {
    --transform-translate-x: .375rem;
  }

  .sm\:hover\:translate-x-2\.5:hover {
    --transform-translate-x: .625rem;
  }

  .sm\:hover\:translate-x-3\.5:hover {
    --transform-translate-x: .875rem;
  }

  .sm\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .sm\:hover\:translate-x-1\/3:hover {
    --transform-translate-x: 33.3333%;
  }

  .sm\:hover\:translate-x-2\/3:hover {
    --transform-translate-x: 66.6667%;
  }

  .sm\:hover\:translate-x-1\/4:hover {
    --transform-translate-x: 25%;
  }

  .sm\:hover\:translate-x-2\/4:hover {
    --transform-translate-x: 50%;
  }

  .sm\:hover\:translate-x-3\/4:hover {
    --transform-translate-x: 75%;
  }

  .sm\:hover\:translate-x-1\/5:hover {
    --transform-translate-x: 20%;
  }

  .sm\:hover\:translate-x-2\/5:hover {
    --transform-translate-x: 40%;
  }

  .sm\:hover\:translate-x-3\/5:hover {
    --transform-translate-x: 60%;
  }

  .sm\:hover\:translate-x-4\/5:hover {
    --transform-translate-x: 80%;
  }

  .sm\:hover\:translate-x-1\/6:hover {
    --transform-translate-x: 16.6667%;
  }

  .sm\:hover\:translate-x-2\/6:hover {
    --transform-translate-x: 33.3333%;
  }

  .sm\:hover\:translate-x-3\/6:hover {
    --transform-translate-x: 50%;
  }

  .sm\:hover\:translate-x-4\/6:hover {
    --transform-translate-x: 66.6667%;
  }

  .sm\:hover\:translate-x-5\/6:hover {
    --transform-translate-x: 83.3333%;
  }

  .sm\:hover\:translate-x-1\/12:hover {
    --transform-translate-x: 8.33333%;
  }

  .sm\:hover\:translate-x-2\/12:hover {
    --transform-translate-x: 16.6667%;
  }

  .sm\:hover\:translate-x-3\/12:hover {
    --transform-translate-x: 25%;
  }

  .sm\:hover\:translate-x-4\/12:hover {
    --transform-translate-x: 33.3333%;
  }

  .sm\:hover\:translate-x-5\/12:hover {
    --transform-translate-x: 41.6667%;
  }

  .sm\:hover\:translate-x-6\/12:hover {
    --transform-translate-x: 50%;
  }

  .sm\:hover\:translate-x-7\/12:hover {
    --transform-translate-x: 58.3333%;
  }

  .sm\:hover\:translate-x-8\/12:hover {
    --transform-translate-x: 66.6667%;
  }

  .sm\:hover\:translate-x-9\/12:hover {
    --transform-translate-x: 75%;
  }

  .sm\:hover\:translate-x-10\/12:hover {
    --transform-translate-x: 83.3333%;
  }

  .sm\:hover\:translate-x-11\/12:hover {
    --transform-translate-x: 91.6667%;
  }

  .sm\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .sm\:hover\:-translate-x-1:hover {
    --transform-translate-x: -.25rem;
  }

  .sm\:hover\:-translate-x-2:hover {
    --transform-translate-x: -.5rem;
  }

  .sm\:hover\:-translate-x-3:hover {
    --transform-translate-x: -.75rem;
  }

  .sm\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .sm\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .sm\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .sm\:hover\:-translate-x-7:hover {
    --transform-translate-x: -1.75rem;
  }

  .sm\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .sm\:hover\:-translate-x-9:hover {
    --transform-translate-x: -2.25rem;
  }

  .sm\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .sm\:hover\:-translate-x-11:hover {
    --transform-translate-x: -2.75rem;
  }

  .sm\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .sm\:hover\:-translate-x-13:hover {
    --transform-translate-x: -3.25rem;
  }

  .sm\:hover\:-translate-x-14:hover {
    --transform-translate-x: -3.5rem;
  }

  .sm\:hover\:-translate-x-15:hover {
    --transform-translate-x: -3.75rem;
  }

  .sm\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .sm\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .sm\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .sm\:hover\:-translate-x-28:hover {
    --transform-translate-x: -7rem;
  }

  .sm\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .sm\:hover\:-translate-x-36:hover {
    --transform-translate-x: -9rem;
  }

  .sm\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .sm\:hover\:-translate-x-44:hover {
    --transform-translate-x: -11rem;
  }

  .sm\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .sm\:hover\:-translate-x-52:hover {
    --transform-translate-x: -13rem;
  }

  .sm\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .sm\:hover\:-translate-x-60:hover {
    --transform-translate-x: -15rem;
  }

  .sm\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .sm\:hover\:-translate-x-72:hover {
    --transform-translate-x: -18rem;
  }

  .sm\:hover\:-translate-x-80:hover {
    --transform-translate-x: -20rem;
  }

  .sm\:hover\:-translate-x-96:hover {
    --transform-translate-x: -24rem;
  }

  .sm\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .sm\:hover\:-translate-x-0\.5:hover {
    --transform-translate-x: -.125rem;
  }

  .sm\:hover\:-translate-x-1\.5:hover {
    --transform-translate-x: -.375rem;
  }

  .sm\:hover\:-translate-x-2\.5:hover {
    --transform-translate-x: -.625rem;
  }

  .sm\:hover\:-translate-x-3\.5:hover {
    --transform-translate-x: -.875rem;
  }

  .sm\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .sm\:hover\:-translate-x-1\/3:hover {
    --transform-translate-x: -33.3333%;
  }

  .sm\:hover\:-translate-x-2\/3:hover {
    --transform-translate-x: -66.6667%;
  }

  .sm\:hover\:-translate-x-1\/4:hover {
    --transform-translate-x: -25%;
  }

  .sm\:hover\:-translate-x-2\/4:hover {
    --transform-translate-x: -50%;
  }

  .sm\:hover\:-translate-x-3\/4:hover {
    --transform-translate-x: -75%;
  }

  .sm\:hover\:-translate-x-1\/5:hover {
    --transform-translate-x: -20%;
  }

  .sm\:hover\:-translate-x-2\/5:hover {
    --transform-translate-x: -40%;
  }

  .sm\:hover\:-translate-x-3\/5:hover {
    --transform-translate-x: -60%;
  }

  .sm\:hover\:-translate-x-4\/5:hover {
    --transform-translate-x: -80%;
  }

  .sm\:hover\:-translate-x-1\/6:hover {
    --transform-translate-x: -16.6667%;
  }

  .sm\:hover\:-translate-x-2\/6:hover {
    --transform-translate-x: -33.3333%;
  }

  .sm\:hover\:-translate-x-3\/6:hover {
    --transform-translate-x: -50%;
  }

  .sm\:hover\:-translate-x-4\/6:hover {
    --transform-translate-x: -66.6667%;
  }

  .sm\:hover\:-translate-x-5\/6:hover {
    --transform-translate-x: -83.3333%;
  }

  .sm\:hover\:-translate-x-1\/12:hover {
    --transform-translate-x: -8.33333%;
  }

  .sm\:hover\:-translate-x-2\/12:hover {
    --transform-translate-x: -16.6667%;
  }

  .sm\:hover\:-translate-x-3\/12:hover {
    --transform-translate-x: -25%;
  }

  .sm\:hover\:-translate-x-4\/12:hover {
    --transform-translate-x: -33.3333%;
  }

  .sm\:hover\:-translate-x-5\/12:hover {
    --transform-translate-x: -41.6667%;
  }

  .sm\:hover\:-translate-x-6\/12:hover {
    --transform-translate-x: -50%;
  }

  .sm\:hover\:-translate-x-7\/12:hover {
    --transform-translate-x: -58.3333%;
  }

  .sm\:hover\:-translate-x-8\/12:hover {
    --transform-translate-x: -66.6667%;
  }

  .sm\:hover\:-translate-x-9\/12:hover {
    --transform-translate-x: -75%;
  }

  .sm\:hover\:-translate-x-10\/12:hover {
    --transform-translate-x: -83.3333%;
  }

  .sm\:hover\:-translate-x-11\/12:hover {
    --transform-translate-x: -91.6667%;
  }

  .sm\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .sm\:hover\:translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .sm\:hover\:translate-y-1:hover {
    --transform-translate-y: .25rem;
  }

  .sm\:hover\:translate-y-2:hover {
    --transform-translate-y: .5rem;
  }

  .sm\:hover\:translate-y-3:hover {
    --transform-translate-y: .75rem;
  }

  .sm\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .sm\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .sm\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .sm\:hover\:translate-y-7:hover {
    --transform-translate-y: 1.75rem;
  }

  .sm\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .sm\:hover\:translate-y-9:hover {
    --transform-translate-y: 2.25rem;
  }

  .sm\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .sm\:hover\:translate-y-11:hover {
    --transform-translate-y: 2.75rem;
  }

  .sm\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .sm\:hover\:translate-y-13:hover {
    --transform-translate-y: 3.25rem;
  }

  .sm\:hover\:translate-y-14:hover {
    --transform-translate-y: 3.5rem;
  }

  .sm\:hover\:translate-y-15:hover {
    --transform-translate-y: 3.75rem;
  }

  .sm\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .sm\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .sm\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .sm\:hover\:translate-y-28:hover {
    --transform-translate-y: 7rem;
  }

  .sm\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .sm\:hover\:translate-y-36:hover {
    --transform-translate-y: 9rem;
  }

  .sm\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .sm\:hover\:translate-y-44:hover {
    --transform-translate-y: 11rem;
  }

  .sm\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .sm\:hover\:translate-y-52:hover {
    --transform-translate-y: 13rem;
  }

  .sm\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .sm\:hover\:translate-y-60:hover {
    --transform-translate-y: 15rem;
  }

  .sm\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .sm\:hover\:translate-y-72:hover {
    --transform-translate-y: 18rem;
  }

  .sm\:hover\:translate-y-80:hover {
    --transform-translate-y: 20rem;
  }

  .sm\:hover\:translate-y-96:hover {
    --transform-translate-y: 24rem;
  }

  .sm\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .sm\:hover\:translate-y-0\.5:hover {
    --transform-translate-y: .125rem;
  }

  .sm\:hover\:translate-y-1\.5:hover {
    --transform-translate-y: .375rem;
  }

  .sm\:hover\:translate-y-2\.5:hover {
    --transform-translate-y: .625rem;
  }

  .sm\:hover\:translate-y-3\.5:hover {
    --transform-translate-y: .875rem;
  }

  .sm\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .sm\:hover\:translate-y-1\/3:hover {
    --transform-translate-y: 33.3333%;
  }

  .sm\:hover\:translate-y-2\/3:hover {
    --transform-translate-y: 66.6667%;
  }

  .sm\:hover\:translate-y-1\/4:hover {
    --transform-translate-y: 25%;
  }

  .sm\:hover\:translate-y-2\/4:hover {
    --transform-translate-y: 50%;
  }

  .sm\:hover\:translate-y-3\/4:hover {
    --transform-translate-y: 75%;
  }

  .sm\:hover\:translate-y-1\/5:hover {
    --transform-translate-y: 20%;
  }

  .sm\:hover\:translate-y-2\/5:hover {
    --transform-translate-y: 40%;
  }

  .sm\:hover\:translate-y-3\/5:hover {
    --transform-translate-y: 60%;
  }

  .sm\:hover\:translate-y-4\/5:hover {
    --transform-translate-y: 80%;
  }

  .sm\:hover\:translate-y-1\/6:hover {
    --transform-translate-y: 16.6667%;
  }

  .sm\:hover\:translate-y-2\/6:hover {
    --transform-translate-y: 33.3333%;
  }

  .sm\:hover\:translate-y-3\/6:hover {
    --transform-translate-y: 50%;
  }

  .sm\:hover\:translate-y-4\/6:hover {
    --transform-translate-y: 66.6667%;
  }

  .sm\:hover\:translate-y-5\/6:hover {
    --transform-translate-y: 83.3333%;
  }

  .sm\:hover\:translate-y-1\/12:hover {
    --transform-translate-y: 8.33333%;
  }

  .sm\:hover\:translate-y-2\/12:hover {
    --transform-translate-y: 16.6667%;
  }

  .sm\:hover\:translate-y-3\/12:hover {
    --transform-translate-y: 25%;
  }

  .sm\:hover\:translate-y-4\/12:hover {
    --transform-translate-y: 33.3333%;
  }

  .sm\:hover\:translate-y-5\/12:hover {
    --transform-translate-y: 41.6667%;
  }

  .sm\:hover\:translate-y-6\/12:hover {
    --transform-translate-y: 50%;
  }

  .sm\:hover\:translate-y-7\/12:hover {
    --transform-translate-y: 58.3333%;
  }

  .sm\:hover\:translate-y-8\/12:hover {
    --transform-translate-y: 66.6667%;
  }

  .sm\:hover\:translate-y-9\/12:hover {
    --transform-translate-y: 75%;
  }

  .sm\:hover\:translate-y-10\/12:hover {
    --transform-translate-y: 83.3333%;
  }

  .sm\:hover\:translate-y-11\/12:hover {
    --transform-translate-y: 91.6667%;
  }

  .sm\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .sm\:hover\:-translate-y-1:hover {
    --transform-translate-y: -.25rem;
  }

  .sm\:hover\:-translate-y-2:hover {
    --transform-translate-y: -.5rem;
  }

  .sm\:hover\:-translate-y-3:hover {
    --transform-translate-y: -.75rem;
  }

  .sm\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .sm\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .sm\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .sm\:hover\:-translate-y-7:hover {
    --transform-translate-y: -1.75rem;
  }

  .sm\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .sm\:hover\:-translate-y-9:hover {
    --transform-translate-y: -2.25rem;
  }

  .sm\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .sm\:hover\:-translate-y-11:hover {
    --transform-translate-y: -2.75rem;
  }

  .sm\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .sm\:hover\:-translate-y-13:hover {
    --transform-translate-y: -3.25rem;
  }

  .sm\:hover\:-translate-y-14:hover {
    --transform-translate-y: -3.5rem;
  }

  .sm\:hover\:-translate-y-15:hover {
    --transform-translate-y: -3.75rem;
  }

  .sm\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .sm\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .sm\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .sm\:hover\:-translate-y-28:hover {
    --transform-translate-y: -7rem;
  }

  .sm\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .sm\:hover\:-translate-y-36:hover {
    --transform-translate-y: -9rem;
  }

  .sm\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .sm\:hover\:-translate-y-44:hover {
    --transform-translate-y: -11rem;
  }

  .sm\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .sm\:hover\:-translate-y-52:hover {
    --transform-translate-y: -13rem;
  }

  .sm\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .sm\:hover\:-translate-y-60:hover {
    --transform-translate-y: -15rem;
  }

  .sm\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .sm\:hover\:-translate-y-72:hover {
    --transform-translate-y: -18rem;
  }

  .sm\:hover\:-translate-y-80:hover {
    --transform-translate-y: -20rem;
  }

  .sm\:hover\:-translate-y-96:hover {
    --transform-translate-y: -24rem;
  }

  .sm\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .sm\:hover\:-translate-y-0\.5:hover {
    --transform-translate-y: -.125rem;
  }

  .sm\:hover\:-translate-y-1\.5:hover {
    --transform-translate-y: -.375rem;
  }

  .sm\:hover\:-translate-y-2\.5:hover {
    --transform-translate-y: -.625rem;
  }

  .sm\:hover\:-translate-y-3\.5:hover {
    --transform-translate-y: -.875rem;
  }

  .sm\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .sm\:hover\:-translate-y-1\/3:hover {
    --transform-translate-y: -33.3333%;
  }

  .sm\:hover\:-translate-y-2\/3:hover {
    --transform-translate-y: -66.6667%;
  }

  .sm\:hover\:-translate-y-1\/4:hover {
    --transform-translate-y: -25%;
  }

  .sm\:hover\:-translate-y-2\/4:hover {
    --transform-translate-y: -50%;
  }

  .sm\:hover\:-translate-y-3\/4:hover {
    --transform-translate-y: -75%;
  }

  .sm\:hover\:-translate-y-1\/5:hover {
    --transform-translate-y: -20%;
  }

  .sm\:hover\:-translate-y-2\/5:hover {
    --transform-translate-y: -40%;
  }

  .sm\:hover\:-translate-y-3\/5:hover {
    --transform-translate-y: -60%;
  }

  .sm\:hover\:-translate-y-4\/5:hover {
    --transform-translate-y: -80%;
  }

  .sm\:hover\:-translate-y-1\/6:hover {
    --transform-translate-y: -16.6667%;
  }

  .sm\:hover\:-translate-y-2\/6:hover {
    --transform-translate-y: -33.3333%;
  }

  .sm\:hover\:-translate-y-3\/6:hover {
    --transform-translate-y: -50%;
  }

  .sm\:hover\:-translate-y-4\/6:hover {
    --transform-translate-y: -66.6667%;
  }

  .sm\:hover\:-translate-y-5\/6:hover {
    --transform-translate-y: -83.3333%;
  }

  .sm\:hover\:-translate-y-1\/12:hover {
    --transform-translate-y: -8.33333%;
  }

  .sm\:hover\:-translate-y-2\/12:hover {
    --transform-translate-y: -16.6667%;
  }

  .sm\:hover\:-translate-y-3\/12:hover {
    --transform-translate-y: -25%;
  }

  .sm\:hover\:-translate-y-4\/12:hover {
    --transform-translate-y: -33.3333%;
  }

  .sm\:hover\:-translate-y-5\/12:hover {
    --transform-translate-y: -41.6667%;
  }

  .sm\:hover\:-translate-y-6\/12:hover {
    --transform-translate-y: -50%;
  }

  .sm\:hover\:-translate-y-7\/12:hover {
    --transform-translate-y: -58.3333%;
  }

  .sm\:hover\:-translate-y-8\/12:hover {
    --transform-translate-y: -66.6667%;
  }

  .sm\:hover\:-translate-y-9\/12:hover {
    --transform-translate-y: -75%;
  }

  .sm\:hover\:-translate-y-10\/12:hover {
    --transform-translate-y: -83.3333%;
  }

  .sm\:hover\:-translate-y-11\/12:hover {
    --transform-translate-y: -91.6667%;
  }

  .sm\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .sm\:focus\:translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .sm\:focus\:translate-x-1:focus {
    --transform-translate-x: .25rem;
  }

  .sm\:focus\:translate-x-2:focus {
    --transform-translate-x: .5rem;
  }

  .sm\:focus\:translate-x-3:focus {
    --transform-translate-x: .75rem;
  }

  .sm\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .sm\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .sm\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .sm\:focus\:translate-x-7:focus {
    --transform-translate-x: 1.75rem;
  }

  .sm\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .sm\:focus\:translate-x-9:focus {
    --transform-translate-x: 2.25rem;
  }

  .sm\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .sm\:focus\:translate-x-11:focus {
    --transform-translate-x: 2.75rem;
  }

  .sm\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .sm\:focus\:translate-x-13:focus {
    --transform-translate-x: 3.25rem;
  }

  .sm\:focus\:translate-x-14:focus {
    --transform-translate-x: 3.5rem;
  }

  .sm\:focus\:translate-x-15:focus {
    --transform-translate-x: 3.75rem;
  }

  .sm\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .sm\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .sm\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .sm\:focus\:translate-x-28:focus {
    --transform-translate-x: 7rem;
  }

  .sm\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .sm\:focus\:translate-x-36:focus {
    --transform-translate-x: 9rem;
  }

  .sm\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .sm\:focus\:translate-x-44:focus {
    --transform-translate-x: 11rem;
  }

  .sm\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .sm\:focus\:translate-x-52:focus {
    --transform-translate-x: 13rem;
  }

  .sm\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .sm\:focus\:translate-x-60:focus {
    --transform-translate-x: 15rem;
  }

  .sm\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .sm\:focus\:translate-x-72:focus {
    --transform-translate-x: 18rem;
  }

  .sm\:focus\:translate-x-80:focus {
    --transform-translate-x: 20rem;
  }

  .sm\:focus\:translate-x-96:focus {
    --transform-translate-x: 24rem;
  }

  .sm\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .sm\:focus\:translate-x-0\.5:focus {
    --transform-translate-x: .125rem;
  }

  .sm\:focus\:translate-x-1\.5:focus {
    --transform-translate-x: .375rem;
  }

  .sm\:focus\:translate-x-2\.5:focus {
    --transform-translate-x: .625rem;
  }

  .sm\:focus\:translate-x-3\.5:focus {
    --transform-translate-x: .875rem;
  }

  .sm\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .sm\:focus\:translate-x-1\/3:focus {
    --transform-translate-x: 33.3333%;
  }

  .sm\:focus\:translate-x-2\/3:focus {
    --transform-translate-x: 66.6667%;
  }

  .sm\:focus\:translate-x-1\/4:focus {
    --transform-translate-x: 25%;
  }

  .sm\:focus\:translate-x-2\/4:focus {
    --transform-translate-x: 50%;
  }

  .sm\:focus\:translate-x-3\/4:focus {
    --transform-translate-x: 75%;
  }

  .sm\:focus\:translate-x-1\/5:focus {
    --transform-translate-x: 20%;
  }

  .sm\:focus\:translate-x-2\/5:focus {
    --transform-translate-x: 40%;
  }

  .sm\:focus\:translate-x-3\/5:focus {
    --transform-translate-x: 60%;
  }

  .sm\:focus\:translate-x-4\/5:focus {
    --transform-translate-x: 80%;
  }

  .sm\:focus\:translate-x-1\/6:focus {
    --transform-translate-x: 16.6667%;
  }

  .sm\:focus\:translate-x-2\/6:focus {
    --transform-translate-x: 33.3333%;
  }

  .sm\:focus\:translate-x-3\/6:focus {
    --transform-translate-x: 50%;
  }

  .sm\:focus\:translate-x-4\/6:focus {
    --transform-translate-x: 66.6667%;
  }

  .sm\:focus\:translate-x-5\/6:focus {
    --transform-translate-x: 83.3333%;
  }

  .sm\:focus\:translate-x-1\/12:focus {
    --transform-translate-x: 8.33333%;
  }

  .sm\:focus\:translate-x-2\/12:focus {
    --transform-translate-x: 16.6667%;
  }

  .sm\:focus\:translate-x-3\/12:focus {
    --transform-translate-x: 25%;
  }

  .sm\:focus\:translate-x-4\/12:focus {
    --transform-translate-x: 33.3333%;
  }

  .sm\:focus\:translate-x-5\/12:focus {
    --transform-translate-x: 41.6667%;
  }

  .sm\:focus\:translate-x-6\/12:focus {
    --transform-translate-x: 50%;
  }

  .sm\:focus\:translate-x-7\/12:focus {
    --transform-translate-x: 58.3333%;
  }

  .sm\:focus\:translate-x-8\/12:focus {
    --transform-translate-x: 66.6667%;
  }

  .sm\:focus\:translate-x-9\/12:focus {
    --transform-translate-x: 75%;
  }

  .sm\:focus\:translate-x-10\/12:focus {
    --transform-translate-x: 83.3333%;
  }

  .sm\:focus\:translate-x-11\/12:focus {
    --transform-translate-x: 91.6667%;
  }

  .sm\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .sm\:focus\:-translate-x-1:focus {
    --transform-translate-x: -.25rem;
  }

  .sm\:focus\:-translate-x-2:focus {
    --transform-translate-x: -.5rem;
  }

  .sm\:focus\:-translate-x-3:focus {
    --transform-translate-x: -.75rem;
  }

  .sm\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .sm\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .sm\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .sm\:focus\:-translate-x-7:focus {
    --transform-translate-x: -1.75rem;
  }

  .sm\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .sm\:focus\:-translate-x-9:focus {
    --transform-translate-x: -2.25rem;
  }

  .sm\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .sm\:focus\:-translate-x-11:focus {
    --transform-translate-x: -2.75rem;
  }

  .sm\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .sm\:focus\:-translate-x-13:focus {
    --transform-translate-x: -3.25rem;
  }

  .sm\:focus\:-translate-x-14:focus {
    --transform-translate-x: -3.5rem;
  }

  .sm\:focus\:-translate-x-15:focus {
    --transform-translate-x: -3.75rem;
  }

  .sm\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .sm\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .sm\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .sm\:focus\:-translate-x-28:focus {
    --transform-translate-x: -7rem;
  }

  .sm\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .sm\:focus\:-translate-x-36:focus {
    --transform-translate-x: -9rem;
  }

  .sm\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .sm\:focus\:-translate-x-44:focus {
    --transform-translate-x: -11rem;
  }

  .sm\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .sm\:focus\:-translate-x-52:focus {
    --transform-translate-x: -13rem;
  }

  .sm\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .sm\:focus\:-translate-x-60:focus {
    --transform-translate-x: -15rem;
  }

  .sm\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .sm\:focus\:-translate-x-72:focus {
    --transform-translate-x: -18rem;
  }

  .sm\:focus\:-translate-x-80:focus {
    --transform-translate-x: -20rem;
  }

  .sm\:focus\:-translate-x-96:focus {
    --transform-translate-x: -24rem;
  }

  .sm\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .sm\:focus\:-translate-x-0\.5:focus {
    --transform-translate-x: -.125rem;
  }

  .sm\:focus\:-translate-x-1\.5:focus {
    --transform-translate-x: -.375rem;
  }

  .sm\:focus\:-translate-x-2\.5:focus {
    --transform-translate-x: -.625rem;
  }

  .sm\:focus\:-translate-x-3\.5:focus {
    --transform-translate-x: -.875rem;
  }

  .sm\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .sm\:focus\:-translate-x-1\/3:focus {
    --transform-translate-x: -33.3333%;
  }

  .sm\:focus\:-translate-x-2\/3:focus {
    --transform-translate-x: -66.6667%;
  }

  .sm\:focus\:-translate-x-1\/4:focus {
    --transform-translate-x: -25%;
  }

  .sm\:focus\:-translate-x-2\/4:focus {
    --transform-translate-x: -50%;
  }

  .sm\:focus\:-translate-x-3\/4:focus {
    --transform-translate-x: -75%;
  }

  .sm\:focus\:-translate-x-1\/5:focus {
    --transform-translate-x: -20%;
  }

  .sm\:focus\:-translate-x-2\/5:focus {
    --transform-translate-x: -40%;
  }

  .sm\:focus\:-translate-x-3\/5:focus {
    --transform-translate-x: -60%;
  }

  .sm\:focus\:-translate-x-4\/5:focus {
    --transform-translate-x: -80%;
  }

  .sm\:focus\:-translate-x-1\/6:focus {
    --transform-translate-x: -16.6667%;
  }

  .sm\:focus\:-translate-x-2\/6:focus {
    --transform-translate-x: -33.3333%;
  }

  .sm\:focus\:-translate-x-3\/6:focus {
    --transform-translate-x: -50%;
  }

  .sm\:focus\:-translate-x-4\/6:focus {
    --transform-translate-x: -66.6667%;
  }

  .sm\:focus\:-translate-x-5\/6:focus {
    --transform-translate-x: -83.3333%;
  }

  .sm\:focus\:-translate-x-1\/12:focus {
    --transform-translate-x: -8.33333%;
  }

  .sm\:focus\:-translate-x-2\/12:focus {
    --transform-translate-x: -16.6667%;
  }

  .sm\:focus\:-translate-x-3\/12:focus {
    --transform-translate-x: -25%;
  }

  .sm\:focus\:-translate-x-4\/12:focus {
    --transform-translate-x: -33.3333%;
  }

  .sm\:focus\:-translate-x-5\/12:focus {
    --transform-translate-x: -41.6667%;
  }

  .sm\:focus\:-translate-x-6\/12:focus {
    --transform-translate-x: -50%;
  }

  .sm\:focus\:-translate-x-7\/12:focus {
    --transform-translate-x: -58.3333%;
  }

  .sm\:focus\:-translate-x-8\/12:focus {
    --transform-translate-x: -66.6667%;
  }

  .sm\:focus\:-translate-x-9\/12:focus {
    --transform-translate-x: -75%;
  }

  .sm\:focus\:-translate-x-10\/12:focus {
    --transform-translate-x: -83.3333%;
  }

  .sm\:focus\:-translate-x-11\/12:focus {
    --transform-translate-x: -91.6667%;
  }

  .sm\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .sm\:focus\:translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .sm\:focus\:translate-y-1:focus {
    --transform-translate-y: .25rem;
  }

  .sm\:focus\:translate-y-2:focus {
    --transform-translate-y: .5rem;
  }

  .sm\:focus\:translate-y-3:focus {
    --transform-translate-y: .75rem;
  }

  .sm\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .sm\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .sm\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .sm\:focus\:translate-y-7:focus {
    --transform-translate-y: 1.75rem;
  }

  .sm\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .sm\:focus\:translate-y-9:focus {
    --transform-translate-y: 2.25rem;
  }

  .sm\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .sm\:focus\:translate-y-11:focus {
    --transform-translate-y: 2.75rem;
  }

  .sm\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .sm\:focus\:translate-y-13:focus {
    --transform-translate-y: 3.25rem;
  }

  .sm\:focus\:translate-y-14:focus {
    --transform-translate-y: 3.5rem;
  }

  .sm\:focus\:translate-y-15:focus {
    --transform-translate-y: 3.75rem;
  }

  .sm\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .sm\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .sm\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .sm\:focus\:translate-y-28:focus {
    --transform-translate-y: 7rem;
  }

  .sm\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .sm\:focus\:translate-y-36:focus {
    --transform-translate-y: 9rem;
  }

  .sm\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .sm\:focus\:translate-y-44:focus {
    --transform-translate-y: 11rem;
  }

  .sm\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .sm\:focus\:translate-y-52:focus {
    --transform-translate-y: 13rem;
  }

  .sm\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .sm\:focus\:translate-y-60:focus {
    --transform-translate-y: 15rem;
  }

  .sm\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .sm\:focus\:translate-y-72:focus {
    --transform-translate-y: 18rem;
  }

  .sm\:focus\:translate-y-80:focus {
    --transform-translate-y: 20rem;
  }

  .sm\:focus\:translate-y-96:focus {
    --transform-translate-y: 24rem;
  }

  .sm\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .sm\:focus\:translate-y-0\.5:focus {
    --transform-translate-y: .125rem;
  }

  .sm\:focus\:translate-y-1\.5:focus {
    --transform-translate-y: .375rem;
  }

  .sm\:focus\:translate-y-2\.5:focus {
    --transform-translate-y: .625rem;
  }

  .sm\:focus\:translate-y-3\.5:focus {
    --transform-translate-y: .875rem;
  }

  .sm\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .sm\:focus\:translate-y-1\/3:focus {
    --transform-translate-y: 33.3333%;
  }

  .sm\:focus\:translate-y-2\/3:focus {
    --transform-translate-y: 66.6667%;
  }

  .sm\:focus\:translate-y-1\/4:focus {
    --transform-translate-y: 25%;
  }

  .sm\:focus\:translate-y-2\/4:focus {
    --transform-translate-y: 50%;
  }

  .sm\:focus\:translate-y-3\/4:focus {
    --transform-translate-y: 75%;
  }

  .sm\:focus\:translate-y-1\/5:focus {
    --transform-translate-y: 20%;
  }

  .sm\:focus\:translate-y-2\/5:focus {
    --transform-translate-y: 40%;
  }

  .sm\:focus\:translate-y-3\/5:focus {
    --transform-translate-y: 60%;
  }

  .sm\:focus\:translate-y-4\/5:focus {
    --transform-translate-y: 80%;
  }

  .sm\:focus\:translate-y-1\/6:focus {
    --transform-translate-y: 16.6667%;
  }

  .sm\:focus\:translate-y-2\/6:focus {
    --transform-translate-y: 33.3333%;
  }

  .sm\:focus\:translate-y-3\/6:focus {
    --transform-translate-y: 50%;
  }

  .sm\:focus\:translate-y-4\/6:focus {
    --transform-translate-y: 66.6667%;
  }

  .sm\:focus\:translate-y-5\/6:focus {
    --transform-translate-y: 83.3333%;
  }

  .sm\:focus\:translate-y-1\/12:focus {
    --transform-translate-y: 8.33333%;
  }

  .sm\:focus\:translate-y-2\/12:focus {
    --transform-translate-y: 16.6667%;
  }

  .sm\:focus\:translate-y-3\/12:focus {
    --transform-translate-y: 25%;
  }

  .sm\:focus\:translate-y-4\/12:focus {
    --transform-translate-y: 33.3333%;
  }

  .sm\:focus\:translate-y-5\/12:focus {
    --transform-translate-y: 41.6667%;
  }

  .sm\:focus\:translate-y-6\/12:focus {
    --transform-translate-y: 50%;
  }

  .sm\:focus\:translate-y-7\/12:focus {
    --transform-translate-y: 58.3333%;
  }

  .sm\:focus\:translate-y-8\/12:focus {
    --transform-translate-y: 66.6667%;
  }

  .sm\:focus\:translate-y-9\/12:focus {
    --transform-translate-y: 75%;
  }

  .sm\:focus\:translate-y-10\/12:focus {
    --transform-translate-y: 83.3333%;
  }

  .sm\:focus\:translate-y-11\/12:focus {
    --transform-translate-y: 91.6667%;
  }

  .sm\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .sm\:focus\:-translate-y-1:focus {
    --transform-translate-y: -.25rem;
  }

  .sm\:focus\:-translate-y-2:focus {
    --transform-translate-y: -.5rem;
  }

  .sm\:focus\:-translate-y-3:focus {
    --transform-translate-y: -.75rem;
  }

  .sm\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .sm\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .sm\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .sm\:focus\:-translate-y-7:focus {
    --transform-translate-y: -1.75rem;
  }

  .sm\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .sm\:focus\:-translate-y-9:focus {
    --transform-translate-y: -2.25rem;
  }

  .sm\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .sm\:focus\:-translate-y-11:focus {
    --transform-translate-y: -2.75rem;
  }

  .sm\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .sm\:focus\:-translate-y-13:focus {
    --transform-translate-y: -3.25rem;
  }

  .sm\:focus\:-translate-y-14:focus {
    --transform-translate-y: -3.5rem;
  }

  .sm\:focus\:-translate-y-15:focus {
    --transform-translate-y: -3.75rem;
  }

  .sm\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .sm\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .sm\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .sm\:focus\:-translate-y-28:focus {
    --transform-translate-y: -7rem;
  }

  .sm\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .sm\:focus\:-translate-y-36:focus {
    --transform-translate-y: -9rem;
  }

  .sm\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .sm\:focus\:-translate-y-44:focus {
    --transform-translate-y: -11rem;
  }

  .sm\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .sm\:focus\:-translate-y-52:focus {
    --transform-translate-y: -13rem;
  }

  .sm\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .sm\:focus\:-translate-y-60:focus {
    --transform-translate-y: -15rem;
  }

  .sm\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .sm\:focus\:-translate-y-72:focus {
    --transform-translate-y: -18rem;
  }

  .sm\:focus\:-translate-y-80:focus {
    --transform-translate-y: -20rem;
  }

  .sm\:focus\:-translate-y-96:focus {
    --transform-translate-y: -24rem;
  }

  .sm\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .sm\:focus\:-translate-y-0\.5:focus {
    --transform-translate-y: -.125rem;
  }

  .sm\:focus\:-translate-y-1\.5:focus {
    --transform-translate-y: -.375rem;
  }

  .sm\:focus\:-translate-y-2\.5:focus {
    --transform-translate-y: -.625rem;
  }

  .sm\:focus\:-translate-y-3\.5:focus {
    --transform-translate-y: -.875rem;
  }

  .sm\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .sm\:focus\:-translate-y-1\/3:focus {
    --transform-translate-y: -33.3333%;
  }

  .sm\:focus\:-translate-y-2\/3:focus {
    --transform-translate-y: -66.6667%;
  }

  .sm\:focus\:-translate-y-1\/4:focus {
    --transform-translate-y: -25%;
  }

  .sm\:focus\:-translate-y-2\/4:focus {
    --transform-translate-y: -50%;
  }

  .sm\:focus\:-translate-y-3\/4:focus {
    --transform-translate-y: -75%;
  }

  .sm\:focus\:-translate-y-1\/5:focus {
    --transform-translate-y: -20%;
  }

  .sm\:focus\:-translate-y-2\/5:focus {
    --transform-translate-y: -40%;
  }

  .sm\:focus\:-translate-y-3\/5:focus {
    --transform-translate-y: -60%;
  }

  .sm\:focus\:-translate-y-4\/5:focus {
    --transform-translate-y: -80%;
  }

  .sm\:focus\:-translate-y-1\/6:focus {
    --transform-translate-y: -16.6667%;
  }

  .sm\:focus\:-translate-y-2\/6:focus {
    --transform-translate-y: -33.3333%;
  }

  .sm\:focus\:-translate-y-3\/6:focus {
    --transform-translate-y: -50%;
  }

  .sm\:focus\:-translate-y-4\/6:focus {
    --transform-translate-y: -66.6667%;
  }

  .sm\:focus\:-translate-y-5\/6:focus {
    --transform-translate-y: -83.3333%;
  }

  .sm\:focus\:-translate-y-1\/12:focus {
    --transform-translate-y: -8.33333%;
  }

  .sm\:focus\:-translate-y-2\/12:focus {
    --transform-translate-y: -16.6667%;
  }

  .sm\:focus\:-translate-y-3\/12:focus {
    --transform-translate-y: -25%;
  }

  .sm\:focus\:-translate-y-4\/12:focus {
    --transform-translate-y: -33.3333%;
  }

  .sm\:focus\:-translate-y-5\/12:focus {
    --transform-translate-y: -41.6667%;
  }

  .sm\:focus\:-translate-y-6\/12:focus {
    --transform-translate-y: -50%;
  }

  .sm\:focus\:-translate-y-7\/12:focus {
    --transform-translate-y: -58.3333%;
  }

  .sm\:focus\:-translate-y-8\/12:focus {
    --transform-translate-y: -66.6667%;
  }

  .sm\:focus\:-translate-y-9\/12:focus {
    --transform-translate-y: -75%;
  }

  .sm\:focus\:-translate-y-10\/12:focus {
    --transform-translate-y: -83.3333%;
  }

  .sm\:focus\:-translate-y-11\/12:focus {
    --transform-translate-y: -91.6667%;
  }

  .sm\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .sm\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .sm\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .sm\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .sm\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .sm\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .sm\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .sm\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .sm\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .sm\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .sm\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .sm\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .sm\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .sm\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .sm\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .sm\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .sm\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .sm\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .sm\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .sm\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .sm\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .sm\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .sm\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .sm\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .sm\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .sm\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .sm\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .sm\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .sm\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .sm\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .sm\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .sm\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .sm\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .sm\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .sm\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .sm\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .sm\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .sm\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .sm\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .sm\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .sm\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .sm\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .sm\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .sm\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .sm\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .sm\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .sm\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .sm\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .sm\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .sm\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .sm\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .sm\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .sm\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .sm\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .sm\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .sm\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .sm\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .sm\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .sm\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .sm\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .sm\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .sm\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .sm\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .sm\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .sm\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .sm\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .sm\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .sm\:transition-none {
    transition-property: none;
  }

  .sm\:transition-all {
    transition-property: all;
  }

  .sm\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .sm\:transition-opacity {
    transition-property: opacity;
  }

  .sm\:transition-shadow {
    transition-property: box-shadow;
  }

  .sm\:transition-transform {
    transition-property: transform;
  }

  .sm\:ease-linear {
    transition-timing-function: linear;
  }

  .sm\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .sm\:duration-75 {
    transition-duration: 75ms;
  }

  .sm\:duration-100 {
    transition-duration: .1s;
  }

  .sm\:duration-150 {
    transition-duration: .15s;
  }

  .sm\:duration-200 {
    transition-duration: .2s;
  }

  .sm\:duration-300 {
    transition-duration: .3s;
  }

  .sm\:duration-500 {
    transition-duration: .5s;
  }

  .sm\:duration-700 {
    transition-duration: .7s;
  }

  .sm\:duration-1000 {
    transition-duration: 1s;
  }

  .sm\:delay-75 {
    transition-delay: 75ms;
  }

  .sm\:delay-100 {
    transition-delay: .1s;
  }

  .sm\:delay-150 {
    transition-delay: .15s;
  }

  .sm\:delay-200 {
    transition-delay: .2s;
  }

  .sm\:delay-300 {
    transition-delay: .3s;
  }

  .sm\:delay-500 {
    transition-delay: .5s;
  }

  .sm\:delay-700 {
    transition-delay: .7s;
  }

  .sm\:delay-1000 {
    transition-delay: 1s;
  }

  .sm\:animate-none {
    animation: none;
  }

  .sm\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .sm\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
  }

  .sm\:animate-pulse {
    animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
  }

  .sm\:animate-bounce {
    animation: bounce 1s infinite;
  }
}

@media (min-width: 768px) {
  .md\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }

  .md\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(0px * var(--space-y-reverse) );
  }

  .md\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse) );
    margin-left: calc(0px * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.25rem * var(--space-y-reverse) );
  }

  .md\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.25rem * var(--space-x-reverse) );
    margin-left: calc(.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.5rem * var(--space-y-reverse) );
  }

  .md\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.5rem * var(--space-x-reverse) );
    margin-left: calc(.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.75rem * var(--space-y-reverse) );
  }

  .md\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.75rem * var(--space-x-reverse) );
    margin-left: calc(.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1rem * var(--space-y-reverse) );
  }

  .md\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse) );
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.25rem * var(--space-y-reverse) );
  }

  .md\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse) );
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.5rem * var(--space-y-reverse) );
  }

  .md\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse) );
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.75rem * var(--space-y-reverse) );
  }

  .md\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--space-x-reverse) );
    margin-left: calc(1.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2rem * var(--space-y-reverse) );
  }

  .md\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse) );
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-9 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.25rem * var(--space-y-reverse) );
  }

  .md\:space-x-9 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--space-x-reverse) );
    margin-left: calc(2.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.5rem * var(--space-y-reverse) );
  }

  .md\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse) );
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-11 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.75rem * var(--space-y-reverse) );
  }

  .md\:space-x-11 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--space-x-reverse) );
    margin-left: calc(2.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3rem * var(--space-y-reverse) );
  }

  .md\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse) );
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-13 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.25rem * var(--space-y-reverse) );
  }

  .md\:space-x-13 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.25rem * var(--space-x-reverse) );
    margin-left: calc(3.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.5rem * var(--space-y-reverse) );
  }

  .md\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--space-x-reverse) );
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-15 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.75rem * var(--space-y-reverse) );
  }

  .md\:space-x-15 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.75rem * var(--space-x-reverse) );
    margin-left: calc(3.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(4rem * var(--space-y-reverse) );
  }

  .md\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse) );
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(5rem * var(--space-y-reverse) );
  }

  .md\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse) );
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(6rem * var(--space-y-reverse) );
  }

  .md\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse) );
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(7rem * var(--space-y-reverse) );
  }

  .md\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(7rem * var(--space-x-reverse) );
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(8rem * var(--space-y-reverse) );
  }

  .md\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse) );
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(9rem * var(--space-y-reverse) );
  }

  .md\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(9rem * var(--space-x-reverse) );
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(10rem * var(--space-y-reverse) );
  }

  .md\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse) );
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-44 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(11rem * var(--space-y-reverse) );
  }

  .md\:space-x-44 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(11rem * var(--space-x-reverse) );
    margin-left: calc(11rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(12rem * var(--space-y-reverse) );
  }

  .md\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse) );
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-52 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(13rem * var(--space-y-reverse) );
  }

  .md\:space-x-52 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(13rem * var(--space-x-reverse) );
    margin-left: calc(13rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(14rem * var(--space-y-reverse) );
  }

  .md\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse) );
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-60 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(15rem * var(--space-y-reverse) );
  }

  .md\:space-x-60 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15rem * var(--space-x-reverse) );
    margin-left: calc(15rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16rem * var(--space-y-reverse) );
  }

  .md\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse) );
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-72 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(18rem * var(--space-y-reverse) );
  }

  .md\:space-x-72 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(18rem * var(--space-x-reverse) );
    margin-left: calc(18rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-80 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(20rem * var(--space-y-reverse) );
  }

  .md\:space-x-80 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20rem * var(--space-x-reverse) );
    margin-left: calc(20rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-96 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(24rem * var(--space-y-reverse) );
  }

  .md\:space-x-96 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(24rem * var(--space-x-reverse) );
    margin-left: calc(24rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1px * var(--space-y-reverse) );
  }

  .md\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse) );
    margin-left: calc(1px * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-0\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.125rem * var(--space-y-reverse) );
  }

  .md\:space-x-0\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.125rem * var(--space-x-reverse) );
    margin-left: calc(.125rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-1\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.375rem * var(--space-y-reverse) );
  }

  .md\:space-x-1\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.375rem * var(--space-x-reverse) );
    margin-left: calc(.375rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-2\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.625rem * var(--space-y-reverse) );
  }

  .md\:space-x-2\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.625rem * var(--space-x-reverse) );
    margin-left: calc(.625rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-3\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.875rem * var(--space-y-reverse) );
  }

  .md\:space-x-3\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.875rem * var(--space-x-reverse) );
    margin-left: calc(.875rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-1\/2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .md\:space-x-1\/2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-1\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .md\:space-x-1\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-2\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .md\:space-x-2\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-1\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(25% * var(--space-y-reverse) );
  }

  .md\:space-x-1\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(25% * var(--space-x-reverse) );
    margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-2\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .md\:space-x-2\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-3\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(75% * var(--space-y-reverse) );
  }

  .md\:space-x-3\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75% * var(--space-x-reverse) );
    margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-1\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(20% * var(--space-y-reverse) );
  }

  .md\:space-x-1\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20% * var(--space-x-reverse) );
    margin-left: calc(20% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-2\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(40% * var(--space-y-reverse) );
  }

  .md\:space-x-2\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40% * var(--space-x-reverse) );
    margin-left: calc(40% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-3\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(60% * var(--space-y-reverse) );
  }

  .md\:space-x-3\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60% * var(--space-x-reverse) );
    margin-left: calc(60% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-4\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(80% * var(--space-y-reverse) );
  }

  .md\:space-x-4\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80% * var(--space-x-reverse) );
    margin-left: calc(80% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-1\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16.6667% * var(--space-y-reverse) );
  }

  .md\:space-x-1\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16.6667% * var(--space-x-reverse) );
    margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-2\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .md\:space-x-2\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-3\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .md\:space-x-3\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-4\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .md\:space-x-4\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-5\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(83.3333% * var(--space-y-reverse) );
  }

  .md\:space-x-5\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(83.3333% * var(--space-x-reverse) );
    margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-1\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8.33333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(8.33333% * var(--space-y-reverse) );
  }

  .md\:space-x-1\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8.33333% * var(--space-x-reverse) );
    margin-left: calc(8.33333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-2\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16.6667% * var(--space-y-reverse) );
  }

  .md\:space-x-2\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16.6667% * var(--space-x-reverse) );
    margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-3\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(25% * var(--space-y-reverse) );
  }

  .md\:space-x-3\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(25% * var(--space-x-reverse) );
    margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-4\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .md\:space-x-4\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-5\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(41.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(41.6667% * var(--space-y-reverse) );
  }

  .md\:space-x-5\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(41.6667% * var(--space-x-reverse) );
    margin-left: calc(41.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-6\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .md\:space-x-6\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-7\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(58.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(58.3333% * var(--space-y-reverse) );
  }

  .md\:space-x-7\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(58.3333% * var(--space-x-reverse) );
    margin-left: calc(58.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-8\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .md\:space-x-8\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-9\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(75% * var(--space-y-reverse) );
  }

  .md\:space-x-9\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75% * var(--space-x-reverse) );
    margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-10\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(83.3333% * var(--space-y-reverse) );
  }

  .md\:space-x-10\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(83.3333% * var(--space-x-reverse) );
    margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-11\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(91.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(91.6667% * var(--space-y-reverse) );
  }

  .md\:space-x-11\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(91.6667% * var(--space-x-reverse) );
    margin-left: calc(91.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-full > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(100% * var(--space-y-reverse) );
  }

  .md\:space-x-full > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100% * var(--space-x-reverse) );
    margin-left: calc(100% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.25rem * var(--space-y-reverse) );
  }

  .md\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--space-x-reverse) );
    margin-left: calc(-.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.5rem * var(--space-y-reverse) );
  }

  .md\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--space-x-reverse) );
    margin-left: calc(-.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.75rem * var(--space-y-reverse) );
  }

  .md\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--space-x-reverse) );
    margin-left: calc(-.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1rem * var(--space-y-reverse) );
  }

  .md\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse) );
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.25rem * var(--space-y-reverse) );
  }

  .md\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse) );
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.5rem * var(--space-y-reverse) );
  }

  .md\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse) );
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.75rem * var(--space-y-reverse) );
  }

  .md\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--space-x-reverse) );
    margin-left: calc(-1.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2rem * var(--space-y-reverse) );
  }

  .md\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse) );
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-9 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.25rem * var(--space-y-reverse) );
  }

  .md\:-space-x-9 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--space-x-reverse) );
    margin-left: calc(-2.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.5rem * var(--space-y-reverse) );
  }

  .md\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse) );
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-11 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.75rem * var(--space-y-reverse) );
  }

  .md\:-space-x-11 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--space-x-reverse) );
    margin-left: calc(-2.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3rem * var(--space-y-reverse) );
  }

  .md\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse) );
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-13 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.25rem * var(--space-y-reverse) );
  }

  .md\:-space-x-13 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.25rem * var(--space-x-reverse) );
    margin-left: calc(-3.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.5rem * var(--space-y-reverse) );
  }

  .md\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--space-x-reverse) );
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-15 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.75rem * var(--space-y-reverse) );
  }

  .md\:-space-x-15 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.75rem * var(--space-x-reverse) );
    margin-left: calc(-3.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-4rem * var(--space-y-reverse) );
  }

  .md\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse) );
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-5rem * var(--space-y-reverse) );
  }

  .md\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse) );
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-6rem * var(--space-y-reverse) );
  }

  .md\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse) );
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-7rem * var(--space-y-reverse) );
  }

  .md\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-7rem * var(--space-x-reverse) );
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-8rem * var(--space-y-reverse) );
  }

  .md\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse) );
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-9rem * var(--space-y-reverse) );
  }

  .md\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-9rem * var(--space-x-reverse) );
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-10rem * var(--space-y-reverse) );
  }

  .md\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse) );
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-44 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-11rem * var(--space-y-reverse) );
  }

  .md\:-space-x-44 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-11rem * var(--space-x-reverse) );
    margin-left: calc(-11rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-12rem * var(--space-y-reverse) );
  }

  .md\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse) );
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-52 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-13rem * var(--space-y-reverse) );
  }

  .md\:-space-x-52 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-13rem * var(--space-x-reverse) );
    margin-left: calc(-13rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-14rem * var(--space-y-reverse) );
  }

  .md\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse) );
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-60 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-15rem * var(--space-y-reverse) );
  }

  .md\:-space-x-60 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15rem * var(--space-x-reverse) );
    margin-left: calc(-15rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16rem * var(--space-y-reverse) );
  }

  .md\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse) );
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-72 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-18rem * var(--space-y-reverse) );
  }

  .md\:-space-x-72 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-18rem * var(--space-x-reverse) );
    margin-left: calc(-18rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-80 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-20rem * var(--space-y-reverse) );
  }

  .md\:-space-x-80 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20rem * var(--space-x-reverse) );
    margin-left: calc(-20rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-96 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-24rem * var(--space-y-reverse) );
  }

  .md\:-space-x-96 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-24rem * var(--space-x-reverse) );
    margin-left: calc(-24rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1px * var(--space-y-reverse) );
  }

  .md\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse) );
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-0\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.125rem * var(--space-y-reverse) );
  }

  .md\:-space-x-0\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--space-x-reverse) );
    margin-left: calc(-.125rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-1\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.375rem * var(--space-y-reverse) );
  }

  .md\:-space-x-1\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--space-x-reverse) );
    margin-left: calc(-.375rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-2\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.625rem * var(--space-y-reverse) );
  }

  .md\:-space-x-2\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--space-x-reverse) );
    margin-left: calc(-.625rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-3\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.875rem * var(--space-y-reverse) );
  }

  .md\:-space-x-3\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--space-x-reverse) );
    margin-left: calc(-.875rem * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-1\/2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .md\:-space-x-1\/2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-1\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .md\:-space-x-1\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-2\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .md\:-space-x-2\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-1\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-25% * var(--space-y-reverse) );
  }

  .md\:-space-x-1\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-25% * var(--space-x-reverse) );
    margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-2\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .md\:-space-x-2\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-3\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-75% * var(--space-y-reverse) );
  }

  .md\:-space-x-3\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75% * var(--space-x-reverse) );
    margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-1\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-20% * var(--space-y-reverse) );
  }

  .md\:-space-x-1\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20% * var(--space-x-reverse) );
    margin-left: calc(-20% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-2\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-40% * var(--space-y-reverse) );
  }

  .md\:-space-x-2\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40% * var(--space-x-reverse) );
    margin-left: calc(-40% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-3\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-60% * var(--space-y-reverse) );
  }

  .md\:-space-x-3\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60% * var(--space-x-reverse) );
    margin-left: calc(-60% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-4\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-80% * var(--space-y-reverse) );
  }

  .md\:-space-x-4\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80% * var(--space-x-reverse) );
    margin-left: calc(-80% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-1\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
  }

  .md\:-space-x-1\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16.6667% * var(--space-x-reverse) );
    margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-2\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .md\:-space-x-2\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-3\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .md\:-space-x-3\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-4\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .md\:-space-x-4\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-5\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
  }

  .md\:-space-x-5\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-83.3333% * var(--space-x-reverse) );
    margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-1\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8.33333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-8.33333% * var(--space-y-reverse) );
  }

  .md\:-space-x-1\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8.33333% * var(--space-x-reverse) );
    margin-left: calc(-8.33333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-2\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
  }

  .md\:-space-x-2\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16.6667% * var(--space-x-reverse) );
    margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-3\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-25% * var(--space-y-reverse) );
  }

  .md\:-space-x-3\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-25% * var(--space-x-reverse) );
    margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-4\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .md\:-space-x-4\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-5\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-41.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-41.6667% * var(--space-y-reverse) );
  }

  .md\:-space-x-5\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-41.6667% * var(--space-x-reverse) );
    margin-left: calc(-41.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-6\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .md\:-space-x-6\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-7\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-58.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-58.3333% * var(--space-y-reverse) );
  }

  .md\:-space-x-7\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-58.3333% * var(--space-x-reverse) );
    margin-left: calc(-58.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-8\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .md\:-space-x-8\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-9\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-75% * var(--space-y-reverse) );
  }

  .md\:-space-x-9\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75% * var(--space-x-reverse) );
    margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-10\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
  }

  .md\:-space-x-10\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-83.3333% * var(--space-x-reverse) );
    margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-11\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-91.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-91.6667% * var(--space-y-reverse) );
  }

  .md\:-space-x-11\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-91.6667% * var(--space-x-reverse) );
    margin-left: calc(-91.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:-space-y-full > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-100% * var(--space-y-reverse) );
  }

  .md\:-space-x-full > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100% * var(--space-x-reverse) );
    margin-left: calc(-100% * calc(1 - var(--space-x-reverse) ) );
  }

  .md\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .md\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .md\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(0px * var(--divide-y-reverse) );
  }

  .md\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse) );
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse) ) );
  }

  .md\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(2px * var(--divide-y-reverse) );
  }

  .md\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse) );
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse) ) );
  }

  .md\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(4px * var(--divide-y-reverse) );
  }

  .md\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse) );
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse) ) );
  }

  .md\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(8px * var(--divide-y-reverse) );
  }

  .md\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse) );
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse) ) );
  }

  .md\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(1px * var(--divide-y-reverse) );
  }

  .md\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse) );
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse) ) );
  }

  .md\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .md\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .md\:divide-transparent > :not(template) ~ :not(template) {
    border-color: #0000;
  }

  .md\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity) );
  }

  .md\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity) );
  }

  .md\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--divide-opacity) );
  }

  .md\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--divide-opacity) );
  }

  .md\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--divide-opacity) );
  }

  .md\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--divide-opacity) );
  }

  .md\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--divide-opacity) );
  }

  .md\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--divide-opacity) );
  }

  .md\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--divide-opacity) );
  }

  .md\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--divide-opacity) );
  }

  .md\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--divide-opacity) );
  }

  .md\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--divide-opacity) );
  }

  .md\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--divide-opacity) );
  }

  .md\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--divide-opacity) );
  }

  .md\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--divide-opacity) );
  }

  .md\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--divide-opacity) );
  }

  .md\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--divide-opacity) );
  }

  .md\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--divide-opacity) );
  }

  .md\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--divide-opacity) );
  }

  .md\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--divide-opacity) );
  }

  .md\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--divide-opacity) );
  }

  .md\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--divide-opacity) );
  }

  .md\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--divide-opacity) );
  }

  .md\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--divide-opacity) );
  }

  .md\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--divide-opacity) );
  }

  .md\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--divide-opacity) );
  }

  .md\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--divide-opacity) );
  }

  .md\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--divide-opacity) );
  }

  .md\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--divide-opacity) );
  }

  .md\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--divide-opacity) );
  }

  .md\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--divide-opacity) );
  }

  .md\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--divide-opacity) );
  }

  .md\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--divide-opacity) );
  }

  .md\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--divide-opacity) );
  }

  .md\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--divide-opacity) );
  }

  .md\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--divide-opacity) );
  }

  .md\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--divide-opacity) );
  }

  .md\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--divide-opacity) );
  }

  .md\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--divide-opacity) );
  }

  .md\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--divide-opacity) );
  }

  .md\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--divide-opacity) );
  }

  .md\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--divide-opacity) );
  }

  .md\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--divide-opacity) );
  }

  .md\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--divide-opacity) );
  }

  .md\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--divide-opacity) );
  }

  .md\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--divide-opacity) );
  }

  .md\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--divide-opacity) );
  }

  .md\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--divide-opacity) );
  }

  .md\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--divide-opacity) );
  }

  .md\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--divide-opacity) );
  }

  .md\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--divide-opacity) );
  }

  .md\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--divide-opacity) );
  }

  .md\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--divide-opacity) );
  }

  .md\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--divide-opacity) );
  }

  .md\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--divide-opacity) );
  }

  .md\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--divide-opacity) );
  }

  .md\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--divide-opacity) );
  }

  .md\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--divide-opacity) );
  }

  .md\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--divide-opacity) );
  }

  .md\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--divide-opacity) );
  }

  .md\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--divide-opacity) );
  }

  .md\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--divide-opacity) );
  }

  .md\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--divide-opacity) );
  }

  .md\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--divide-opacity) );
  }

  .md\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--divide-opacity) );
  }

  .md\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--divide-opacity) );
  }

  .md\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--divide-opacity) );
  }

  .md\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--divide-opacity) );
  }

  .md\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--divide-opacity) );
  }

  .md\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--divide-opacity) );
  }

  .md\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--divide-opacity) );
  }

  .md\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--divide-opacity) );
  }

  .md\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--divide-opacity) );
  }

  .md\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--divide-opacity) );
  }

  .md\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--divide-opacity) );
  }

  .md\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--divide-opacity) );
  }

  .md\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--divide-opacity) );
  }

  .md\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--divide-opacity) );
  }

  .md\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--divide-opacity) );
  }

  .md\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--divide-opacity) );
  }

  .md\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--divide-opacity) );
  }

  .md\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--divide-opacity) );
  }

  .md\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--divide-opacity) );
  }

  .md\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--divide-opacity) );
  }

  .md\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--divide-opacity) );
  }

  .md\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--divide-opacity) );
  }

  .md\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--divide-opacity) );
  }

  .md\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--divide-opacity) );
  }

  .md\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--divide-opacity) );
  }

  .md\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--divide-opacity) );
  }

  .md\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--divide-opacity) );
  }

  .md\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--divide-opacity) );
  }

  .md\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--divide-opacity) );
  }

  .md\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--divide-opacity) );
  }

  .md\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--divide-opacity) );
  }

  .md\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--divide-opacity) );
  }

  .md\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--divide-opacity) );
  }

  .md\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--divide-opacity) );
  }

  .md\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--divide-opacity) );
  }

  .md\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--divide-opacity) );
  }

  .md\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--divide-opacity) );
  }

  .md\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--divide-opacity) );
  }

  .md\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--divide-opacity) );
  }

  .md\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .md\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .md\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .md\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .md\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .md\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .md\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: .25;
  }

  .md\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: .5;
  }

  .md\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: .75;
  }

  .md\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .md\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .md\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .md\:appearance-none {
    appearance: none;
  }

  .md\:bg-fixed {
    background-attachment: fixed;
  }

  .md\:bg-local {
    background-attachment: local;
  }

  .md\:bg-scroll {
    background-attachment: scroll;
  }

  .md\:bg-clip-border {
    background-clip: border-box;
  }

  .md\:bg-clip-padding {
    background-clip: padding-box;
  }

  .md\:bg-clip-content {
    background-clip: content-box;
  }

  .md\:bg-clip-text {
    background-clip: text;
  }

  .md\:bg-transparent {
    background-color: #0000;
  }

  .md\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .md\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .md\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .md\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .md\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .md\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .md\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .md\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .md\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .md\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .md\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .md\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .md\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .md\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .md\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .md\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .md\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .md\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .md\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .md\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .md\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .md\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .md\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .md\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .md\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .md\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .md\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .md\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .md\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .md\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .md\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .md\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .md\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .md\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .md\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .md\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .md\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .md\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .md\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .md\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .md\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .md\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .md\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .md\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .md\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .md\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .md\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .md\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .md\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .md\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .md\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .md\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .md\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .md\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .md\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .md\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .md\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .md\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .md\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .md\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .md\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .md\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .md\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .md\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .md\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .md\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .md\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .md\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .md\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .md\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .md\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .md\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .md\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .md\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .md\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .md\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .md\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .md\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .md\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .md\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .md\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .md\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .md\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .md\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .md\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .md\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .md\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .md\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .md\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .md\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .md\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .md\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .md\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .md\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .md\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .md\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .md\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .md\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .md\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .md\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .md\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .md\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .md\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .md\:group-hover\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .group:hover .md\:group-hover\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-transparent {
    background-color: #0000;
  }

  .group:focus .md\:group-focus\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .group:focus .md\:group-focus\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .md\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .md\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .md\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-50:hover {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .md\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-50:hover {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-300:hover {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-400:hover {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-500:hover {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-600:hover {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-700:hover {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-800:hover {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .md\:hover\:bg-cool-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .md\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-50:hover {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .md\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-50:hover {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .md\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-50:hover {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .md\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-50:hover {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .md\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-50:hover {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .md\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-50:hover {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .md\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-50:hover {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .md\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .md\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .md\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .md\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .md\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-50:focus {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .md\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-50:focus {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-300:focus {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-400:focus {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-500:focus {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-600:focus {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-700:focus {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-800:focus {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .md\:focus\:bg-cool-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .md\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-50:focus {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .md\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-50:focus {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .md\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-50:focus {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .md\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-50:focus {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .md\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-50:focus {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .md\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-50:focus {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .md\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-50:focus {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .md\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .md\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .md\:active\:bg-transparent:active {
    background-color: #0000;
  }

  .md\:active\:bg-white:active {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .md\:active\:bg-black:active {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-50:active {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-100:active {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-200:active {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-300:active {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-400:active {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-500:active {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-600:active {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-700:active {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-800:active {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .md\:active\:bg-gray-900:active {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-50:active {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-100:active {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-200:active {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-300:active {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-400:active {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-500:active {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-600:active {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-700:active {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-800:active {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .md\:active\:bg-cool-gray-900:active {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .md\:active\:bg-red-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .md\:active\:bg-red-100:active {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .md\:active\:bg-red-200:active {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .md\:active\:bg-red-300:active {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .md\:active\:bg-red-400:active {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .md\:active\:bg-red-500:active {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .md\:active\:bg-red-600:active {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .md\:active\:bg-red-700:active {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .md\:active\:bg-red-800:active {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .md\:active\:bg-red-900:active {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-50:active {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-100:active {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-200:active {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-300:active {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-400:active {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-500:active {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-600:active {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-700:active {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-800:active {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .md\:active\:bg-orange-900:active {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-50:active {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-100:active {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-200:active {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-300:active {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-400:active {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-500:active {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-600:active {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-700:active {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-800:active {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .md\:active\:bg-yellow-900:active {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .md\:active\:bg-green-50:active {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .md\:active\:bg-green-100:active {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .md\:active\:bg-green-200:active {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .md\:active\:bg-green-300:active {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .md\:active\:bg-green-400:active {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .md\:active\:bg-green-500:active {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .md\:active\:bg-green-600:active {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .md\:active\:bg-green-700:active {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .md\:active\:bg-green-800:active {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .md\:active\:bg-green-900:active {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-50:active {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-100:active {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-200:active {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-300:active {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-400:active {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-500:active {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-600:active {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-700:active {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-800:active {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .md\:active\:bg-teal-900:active {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-50:active {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-100:active {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-200:active {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-300:active {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-400:active {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-500:active {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-600:active {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-700:active {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-800:active {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .md\:active\:bg-blue-900:active {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-50:active {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-100:active {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-200:active {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-300:active {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-400:active {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-500:active {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-600:active {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-700:active {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-800:active {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .md\:active\:bg-indigo-900:active {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-50:active {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-100:active {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-200:active {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-300:active {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-400:active {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-500:active {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-600:active {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-700:active {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-800:active {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .md\:active\:bg-purple-900:active {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-100:active {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-200:active {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-300:active {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-400:active {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-500:active {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-600:active {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-700:active {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-800:active {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .md\:active\:bg-pink-900:active {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .md\:bg-none {
    background-image: none;
  }

  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops) );
  }

  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops) );
  }

  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops) );
  }

  .md\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops) );
  }

  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops) );
  }

  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops) );
  }

  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops) );
  }

  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops) );
  }

  .md\:from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .md\:from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .md\:from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .md\:from-gray-50 {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .md\:from-gray-100 {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .md\:from-gray-200 {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .md\:from-gray-300 {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .md\:from-gray-400 {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .md\:from-gray-500 {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .md\:from-gray-600 {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .md\:from-gray-700 {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .md\:from-gray-800 {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .md\:from-gray-900 {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .md\:from-cool-gray-50 {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .md\:from-cool-gray-100 {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .md\:from-cool-gray-200 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .md\:from-cool-gray-300 {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .md\:from-cool-gray-400 {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .md\:from-cool-gray-500 {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .md\:from-cool-gray-600 {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .md\:from-cool-gray-700 {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .md\:from-cool-gray-800 {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .md\:from-cool-gray-900 {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .md\:from-red-50 {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .md\:from-red-100 {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .md\:from-red-200 {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .md\:from-red-300 {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .md\:from-red-400 {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .md\:from-red-500 {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .md\:from-red-600 {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .md\:from-red-700 {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .md\:from-red-800 {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .md\:from-red-900 {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .md\:from-orange-50 {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .md\:from-orange-100 {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .md\:from-orange-200 {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .md\:from-orange-300 {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .md\:from-orange-400 {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .md\:from-orange-500 {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .md\:from-orange-600 {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .md\:from-orange-700 {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .md\:from-orange-800 {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .md\:from-orange-900 {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .md\:from-yellow-50 {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .md\:from-yellow-100 {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .md\:from-yellow-200 {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .md\:from-yellow-300 {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .md\:from-yellow-400 {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .md\:from-yellow-500 {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .md\:from-yellow-600 {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .md\:from-yellow-700 {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .md\:from-yellow-800 {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .md\:from-yellow-900 {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .md\:from-green-50 {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .md\:from-green-100 {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .md\:from-green-200 {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .md\:from-green-300 {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .md\:from-green-400 {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .md\:from-green-500 {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .md\:from-green-600 {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .md\:from-green-700 {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .md\:from-green-800 {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .md\:from-green-900 {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .md\:from-teal-50 {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .md\:from-teal-100 {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .md\:from-teal-200 {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .md\:from-teal-300 {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .md\:from-teal-400 {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .md\:from-teal-500 {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .md\:from-teal-600 {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .md\:from-teal-700 {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .md\:from-teal-800 {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .md\:from-teal-900 {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .md\:from-blue-50 {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .md\:from-blue-100 {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .md\:from-blue-200 {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .md\:from-blue-300 {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .md\:from-blue-400 {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .md\:from-blue-500 {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .md\:from-blue-600 {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .md\:from-blue-700 {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .md\:from-blue-800 {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .md\:from-blue-900 {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .md\:from-indigo-50 {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .md\:from-indigo-100 {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .md\:from-indigo-200 {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .md\:from-indigo-300 {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .md\:from-indigo-400 {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .md\:from-indigo-500 {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .md\:from-indigo-600 {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .md\:from-indigo-700 {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .md\:from-indigo-800 {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .md\:from-indigo-900 {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .md\:from-purple-50 {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .md\:from-purple-100 {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .md\:from-purple-200 {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .md\:from-purple-300 {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .md\:from-purple-400 {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .md\:from-purple-500 {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .md\:from-purple-600 {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .md\:from-purple-700 {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .md\:from-purple-800 {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .md\:from-purple-900 {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .md\:from-pink-50 {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .md\:from-pink-100 {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .md\:from-pink-200 {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .md\:from-pink-300 {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .md\:from-pink-400 {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .md\:from-pink-500 {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .md\:from-pink-600 {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .md\:from-pink-700 {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .md\:from-pink-800 {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .md\:from-pink-900 {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .md\:via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .md\:via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .md\:via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .md\:via-gray-50 {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .md\:via-gray-100 {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .md\:via-gray-200 {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .md\:via-gray-300 {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .md\:via-gray-400 {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .md\:via-gray-500 {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .md\:via-gray-600 {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .md\:via-gray-700 {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .md\:via-gray-800 {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .md\:via-gray-900 {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .md\:via-cool-gray-50 {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .md\:via-cool-gray-100 {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .md\:via-cool-gray-200 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .md\:via-cool-gray-300 {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .md\:via-cool-gray-400 {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .md\:via-cool-gray-500 {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .md\:via-cool-gray-600 {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .md\:via-cool-gray-700 {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .md\:via-cool-gray-800 {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .md\:via-cool-gray-900 {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .md\:via-red-50 {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .md\:via-red-100 {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .md\:via-red-200 {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .md\:via-red-300 {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .md\:via-red-400 {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .md\:via-red-500 {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .md\:via-red-600 {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .md\:via-red-700 {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .md\:via-red-800 {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .md\:via-red-900 {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .md\:via-orange-50 {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .md\:via-orange-100 {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .md\:via-orange-200 {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .md\:via-orange-300 {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .md\:via-orange-400 {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .md\:via-orange-500 {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .md\:via-orange-600 {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .md\:via-orange-700 {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .md\:via-orange-800 {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .md\:via-orange-900 {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .md\:via-yellow-50 {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .md\:via-yellow-100 {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .md\:via-yellow-200 {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .md\:via-yellow-300 {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .md\:via-yellow-400 {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .md\:via-yellow-500 {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .md\:via-yellow-600 {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .md\:via-yellow-700 {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .md\:via-yellow-800 {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .md\:via-yellow-900 {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .md\:via-green-50 {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .md\:via-green-100 {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .md\:via-green-200 {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .md\:via-green-300 {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .md\:via-green-400 {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .md\:via-green-500 {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .md\:via-green-600 {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .md\:via-green-700 {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .md\:via-green-800 {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .md\:via-green-900 {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .md\:via-teal-50 {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .md\:via-teal-100 {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .md\:via-teal-200 {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .md\:via-teal-300 {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .md\:via-teal-400 {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .md\:via-teal-500 {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .md\:via-teal-600 {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .md\:via-teal-700 {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .md\:via-teal-800 {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .md\:via-teal-900 {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .md\:via-blue-50 {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .md\:via-blue-100 {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .md\:via-blue-200 {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .md\:via-blue-300 {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .md\:via-blue-400 {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .md\:via-blue-500 {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .md\:via-blue-600 {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .md\:via-blue-700 {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .md\:via-blue-800 {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .md\:via-blue-900 {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .md\:via-indigo-50 {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .md\:via-indigo-100 {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .md\:via-indigo-200 {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .md\:via-indigo-300 {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .md\:via-indigo-400 {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .md\:via-indigo-500 {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .md\:via-indigo-600 {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .md\:via-indigo-700 {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .md\:via-indigo-800 {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .md\:via-indigo-900 {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .md\:via-purple-50 {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .md\:via-purple-100 {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .md\:via-purple-200 {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .md\:via-purple-300 {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .md\:via-purple-400 {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .md\:via-purple-500 {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .md\:via-purple-600 {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .md\:via-purple-700 {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .md\:via-purple-800 {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .md\:via-purple-900 {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .md\:via-pink-50 {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .md\:via-pink-100 {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .md\:via-pink-200 {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .md\:via-pink-300 {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .md\:via-pink-400 {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .md\:via-pink-500 {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .md\:via-pink-600 {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .md\:via-pink-700 {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .md\:via-pink-800 {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .md\:via-pink-900 {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .md\:to-transparent {
    --gradient-to-color: transparent;
  }

  .md\:to-white {
    --gradient-to-color: #fff;
  }

  .md\:to-black {
    --gradient-to-color: #000;
  }

  .md\:to-gray-50 {
    --gradient-to-color: #f9fafb;
  }

  .md\:to-gray-100 {
    --gradient-to-color: #f4f5f7;
  }

  .md\:to-gray-200 {
    --gradient-to-color: #e5e7eb;
  }

  .md\:to-gray-300 {
    --gradient-to-color: #d2d6dc;
  }

  .md\:to-gray-400 {
    --gradient-to-color: #9fa6b2;
  }

  .md\:to-gray-500 {
    --gradient-to-color: #6b7280;
  }

  .md\:to-gray-600 {
    --gradient-to-color: #4b5563;
  }

  .md\:to-gray-700 {
    --gradient-to-color: #374151;
  }

  .md\:to-gray-800 {
    --gradient-to-color: #252f3f;
  }

  .md\:to-gray-900 {
    --gradient-to-color: #161e2e;
  }

  .md\:to-cool-gray-50 {
    --gradient-to-color: #fbfdfe;
  }

  .md\:to-cool-gray-100 {
    --gradient-to-color: #f1f5f9;
  }

  .md\:to-cool-gray-200 {
    --gradient-to-color: #e2e8f0;
  }

  .md\:to-cool-gray-300 {
    --gradient-to-color: #cfd8e3;
  }

  .md\:to-cool-gray-400 {
    --gradient-to-color: #97a6ba;
  }

  .md\:to-cool-gray-500 {
    --gradient-to-color: #64748b;
  }

  .md\:to-cool-gray-600 {
    --gradient-to-color: #475569;
  }

  .md\:to-cool-gray-700 {
    --gradient-to-color: #364152;
  }

  .md\:to-cool-gray-800 {
    --gradient-to-color: #27303f;
  }

  .md\:to-cool-gray-900 {
    --gradient-to-color: #1a202e;
  }

  .md\:to-red-50 {
    --gradient-to-color: #fdf2f2;
  }

  .md\:to-red-100 {
    --gradient-to-color: #fde8e8;
  }

  .md\:to-red-200 {
    --gradient-to-color: #fbd5d5;
  }

  .md\:to-red-300 {
    --gradient-to-color: #f8b4b4;
  }

  .md\:to-red-400 {
    --gradient-to-color: #f98080;
  }

  .md\:to-red-500 {
    --gradient-to-color: #f05252;
  }

  .md\:to-red-600 {
    --gradient-to-color: #e02424;
  }

  .md\:to-red-700 {
    --gradient-to-color: #c81e1e;
  }

  .md\:to-red-800 {
    --gradient-to-color: #9b1c1c;
  }

  .md\:to-red-900 {
    --gradient-to-color: #771d1d;
  }

  .md\:to-orange-50 {
    --gradient-to-color: #fff8f1;
  }

  .md\:to-orange-100 {
    --gradient-to-color: #feecdc;
  }

  .md\:to-orange-200 {
    --gradient-to-color: #fcd9bd;
  }

  .md\:to-orange-300 {
    --gradient-to-color: #fdba8c;
  }

  .md\:to-orange-400 {
    --gradient-to-color: #ff8a4c;
  }

  .md\:to-orange-500 {
    --gradient-to-color: #ff5a1f;
  }

  .md\:to-orange-600 {
    --gradient-to-color: #d03801;
  }

  .md\:to-orange-700 {
    --gradient-to-color: #b43403;
  }

  .md\:to-orange-800 {
    --gradient-to-color: #8a2c0d;
  }

  .md\:to-orange-900 {
    --gradient-to-color: #73230d;
  }

  .md\:to-yellow-50 {
    --gradient-to-color: #fdfdea;
  }

  .md\:to-yellow-100 {
    --gradient-to-color: #fdf6b2;
  }

  .md\:to-yellow-200 {
    --gradient-to-color: #fce96a;
  }

  .md\:to-yellow-300 {
    --gradient-to-color: #faca15;
  }

  .md\:to-yellow-400 {
    --gradient-to-color: #e3a008;
  }

  .md\:to-yellow-500 {
    --gradient-to-color: #c27803;
  }

  .md\:to-yellow-600 {
    --gradient-to-color: #9f580a;
  }

  .md\:to-yellow-700 {
    --gradient-to-color: #8e4b10;
  }

  .md\:to-yellow-800 {
    --gradient-to-color: #723b13;
  }

  .md\:to-yellow-900 {
    --gradient-to-color: #633112;
  }

  .md\:to-green-50 {
    --gradient-to-color: #f3faf7;
  }

  .md\:to-green-100 {
    --gradient-to-color: #def7ec;
  }

  .md\:to-green-200 {
    --gradient-to-color: #bcf0da;
  }

  .md\:to-green-300 {
    --gradient-to-color: #84e1bc;
  }

  .md\:to-green-400 {
    --gradient-to-color: #31c48d;
  }

  .md\:to-green-500 {
    --gradient-to-color: #0e9f6e;
  }

  .md\:to-green-600 {
    --gradient-to-color: #057a55;
  }

  .md\:to-green-700 {
    --gradient-to-color: #046c4e;
  }

  .md\:to-green-800 {
    --gradient-to-color: #03543f;
  }

  .md\:to-green-900 {
    --gradient-to-color: #014737;
  }

  .md\:to-teal-50 {
    --gradient-to-color: #edfafa;
  }

  .md\:to-teal-100 {
    --gradient-to-color: #d5f5f6;
  }

  .md\:to-teal-200 {
    --gradient-to-color: #afecef;
  }

  .md\:to-teal-300 {
    --gradient-to-color: #7edce2;
  }

  .md\:to-teal-400 {
    --gradient-to-color: #16bdca;
  }

  .md\:to-teal-500 {
    --gradient-to-color: #0694a2;
  }

  .md\:to-teal-600 {
    --gradient-to-color: #047481;
  }

  .md\:to-teal-700 {
    --gradient-to-color: #036672;
  }

  .md\:to-teal-800 {
    --gradient-to-color: #05505c;
  }

  .md\:to-teal-900 {
    --gradient-to-color: #014451;
  }

  .md\:to-blue-50 {
    --gradient-to-color: #ebf5ff;
  }

  .md\:to-blue-100 {
    --gradient-to-color: #e1effe;
  }

  .md\:to-blue-200 {
    --gradient-to-color: #c3ddfd;
  }

  .md\:to-blue-300 {
    --gradient-to-color: #a4cafe;
  }

  .md\:to-blue-400 {
    --gradient-to-color: #76a9fa;
  }

  .md\:to-blue-500 {
    --gradient-to-color: #3f83f8;
  }

  .md\:to-blue-600 {
    --gradient-to-color: #1c64f2;
  }

  .md\:to-blue-700 {
    --gradient-to-color: #1a56db;
  }

  .md\:to-blue-800 {
    --gradient-to-color: #1e429f;
  }

  .md\:to-blue-900 {
    --gradient-to-color: #233876;
  }

  .md\:to-indigo-50 {
    --gradient-to-color: #f0f5ff;
  }

  .md\:to-indigo-100 {
    --gradient-to-color: #e5edff;
  }

  .md\:to-indigo-200 {
    --gradient-to-color: #cddbfe;
  }

  .md\:to-indigo-300 {
    --gradient-to-color: #b4c6fc;
  }

  .md\:to-indigo-400 {
    --gradient-to-color: #8da2fb;
  }

  .md\:to-indigo-500 {
    --gradient-to-color: #6875f5;
  }

  .md\:to-indigo-600 {
    --gradient-to-color: #5850ec;
  }

  .md\:to-indigo-700 {
    --gradient-to-color: #5145cd;
  }

  .md\:to-indigo-800 {
    --gradient-to-color: #42389d;
  }

  .md\:to-indigo-900 {
    --gradient-to-color: #362f78;
  }

  .md\:to-purple-50 {
    --gradient-to-color: #f6f5ff;
  }

  .md\:to-purple-100 {
    --gradient-to-color: #edebfe;
  }

  .md\:to-purple-200 {
    --gradient-to-color: #dcd7fe;
  }

  .md\:to-purple-300 {
    --gradient-to-color: #cabffd;
  }

  .md\:to-purple-400 {
    --gradient-to-color: #ac94fa;
  }

  .md\:to-purple-500 {
    --gradient-to-color: #9061f9;
  }

  .md\:to-purple-600 {
    --gradient-to-color: #7e3af2;
  }

  .md\:to-purple-700 {
    --gradient-to-color: #6c2bd9;
  }

  .md\:to-purple-800 {
    --gradient-to-color: #5521b5;
  }

  .md\:to-purple-900 {
    --gradient-to-color: #4a1d96;
  }

  .md\:to-pink-50 {
    --gradient-to-color: #fdf2f8;
  }

  .md\:to-pink-100 {
    --gradient-to-color: #fce8f3;
  }

  .md\:to-pink-200 {
    --gradient-to-color: #fad1e8;
  }

  .md\:to-pink-300 {
    --gradient-to-color: #f8b4d9;
  }

  .md\:to-pink-400 {
    --gradient-to-color: #f17eb8;
  }

  .md\:to-pink-500 {
    --gradient-to-color: #e74694;
  }

  .md\:to-pink-600 {
    --gradient-to-color: #d61f69;
  }

  .md\:to-pink-700 {
    --gradient-to-color: #bf125d;
  }

  .md\:to-pink-800 {
    --gradient-to-color: #99154b;
  }

  .md\:to-pink-900 {
    --gradient-to-color: #751a3d;
  }

  .md\:hover\:from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .md\:hover\:from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .md\:hover\:from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .md\:hover\:from-gray-50:hover {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .md\:hover\:from-gray-100:hover {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .md\:hover\:from-gray-200:hover {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .md\:hover\:from-gray-300:hover {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .md\:hover\:from-gray-400:hover {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .md\:hover\:from-gray-500:hover {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .md\:hover\:from-gray-600:hover {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .md\:hover\:from-gray-700:hover {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .md\:hover\:from-gray-800:hover {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .md\:hover\:from-gray-900:hover {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .md\:hover\:from-cool-gray-50:hover {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .md\:hover\:from-cool-gray-100:hover {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .md\:hover\:from-cool-gray-200:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .md\:hover\:from-cool-gray-300:hover {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .md\:hover\:from-cool-gray-400:hover {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .md\:hover\:from-cool-gray-500:hover {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .md\:hover\:from-cool-gray-600:hover {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .md\:hover\:from-cool-gray-700:hover {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .md\:hover\:from-cool-gray-800:hover {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .md\:hover\:from-cool-gray-900:hover {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .md\:hover\:from-red-50:hover {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .md\:hover\:from-red-100:hover {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .md\:hover\:from-red-200:hover {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .md\:hover\:from-red-300:hover {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .md\:hover\:from-red-400:hover {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .md\:hover\:from-red-500:hover {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .md\:hover\:from-red-600:hover {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .md\:hover\:from-red-700:hover {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .md\:hover\:from-red-800:hover {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .md\:hover\:from-red-900:hover {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .md\:hover\:from-orange-50:hover {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .md\:hover\:from-orange-100:hover {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .md\:hover\:from-orange-200:hover {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .md\:hover\:from-orange-300:hover {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .md\:hover\:from-orange-400:hover {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .md\:hover\:from-orange-500:hover {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .md\:hover\:from-orange-600:hover {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .md\:hover\:from-orange-700:hover {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .md\:hover\:from-orange-800:hover {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .md\:hover\:from-orange-900:hover {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .md\:hover\:from-yellow-50:hover {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .md\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .md\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .md\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .md\:hover\:from-yellow-400:hover {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .md\:hover\:from-yellow-500:hover {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .md\:hover\:from-yellow-600:hover {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .md\:hover\:from-yellow-700:hover {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .md\:hover\:from-yellow-800:hover {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .md\:hover\:from-yellow-900:hover {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .md\:hover\:from-green-50:hover {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .md\:hover\:from-green-100:hover {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .md\:hover\:from-green-200:hover {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .md\:hover\:from-green-300:hover {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .md\:hover\:from-green-400:hover {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .md\:hover\:from-green-500:hover {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .md\:hover\:from-green-600:hover {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .md\:hover\:from-green-700:hover {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .md\:hover\:from-green-800:hover {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .md\:hover\:from-green-900:hover {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .md\:hover\:from-teal-50:hover {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .md\:hover\:from-teal-100:hover {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .md\:hover\:from-teal-200:hover {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .md\:hover\:from-teal-300:hover {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .md\:hover\:from-teal-400:hover {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .md\:hover\:from-teal-500:hover {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .md\:hover\:from-teal-600:hover {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .md\:hover\:from-teal-700:hover {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .md\:hover\:from-teal-800:hover {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .md\:hover\:from-teal-900:hover {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .md\:hover\:from-blue-50:hover {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .md\:hover\:from-blue-100:hover {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .md\:hover\:from-blue-200:hover {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .md\:hover\:from-blue-300:hover {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .md\:hover\:from-blue-400:hover {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .md\:hover\:from-blue-500:hover {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .md\:hover\:from-blue-600:hover {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .md\:hover\:from-blue-700:hover {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .md\:hover\:from-blue-800:hover {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .md\:hover\:from-blue-900:hover {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .md\:hover\:from-indigo-50:hover {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .md\:hover\:from-indigo-100:hover {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .md\:hover\:from-indigo-200:hover {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .md\:hover\:from-indigo-300:hover {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .md\:hover\:from-indigo-400:hover {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .md\:hover\:from-indigo-500:hover {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .md\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .md\:hover\:from-indigo-700:hover {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .md\:hover\:from-indigo-800:hover {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .md\:hover\:from-indigo-900:hover {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .md\:hover\:from-purple-50:hover {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .md\:hover\:from-purple-100:hover {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .md\:hover\:from-purple-200:hover {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .md\:hover\:from-purple-300:hover {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .md\:hover\:from-purple-400:hover {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .md\:hover\:from-purple-500:hover {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .md\:hover\:from-purple-600:hover {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .md\:hover\:from-purple-700:hover {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .md\:hover\:from-purple-800:hover {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .md\:hover\:from-purple-900:hover {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .md\:hover\:from-pink-50:hover {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .md\:hover\:from-pink-100:hover {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .md\:hover\:from-pink-200:hover {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .md\:hover\:from-pink-300:hover {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .md\:hover\:from-pink-400:hover {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .md\:hover\:from-pink-500:hover {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .md\:hover\:from-pink-600:hover {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .md\:hover\:from-pink-700:hover {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .md\:hover\:from-pink-800:hover {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .md\:hover\:from-pink-900:hover {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .md\:hover\:via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .md\:hover\:via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .md\:hover\:via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .md\:hover\:via-gray-50:hover {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .md\:hover\:via-gray-100:hover {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .md\:hover\:via-gray-200:hover {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .md\:hover\:via-gray-300:hover {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .md\:hover\:via-gray-400:hover {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .md\:hover\:via-gray-500:hover {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .md\:hover\:via-gray-600:hover {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .md\:hover\:via-gray-700:hover {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .md\:hover\:via-gray-800:hover {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .md\:hover\:via-gray-900:hover {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .md\:hover\:via-cool-gray-50:hover {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .md\:hover\:via-cool-gray-100:hover {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .md\:hover\:via-cool-gray-200:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .md\:hover\:via-cool-gray-300:hover {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .md\:hover\:via-cool-gray-400:hover {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .md\:hover\:via-cool-gray-500:hover {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .md\:hover\:via-cool-gray-600:hover {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .md\:hover\:via-cool-gray-700:hover {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .md\:hover\:via-cool-gray-800:hover {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .md\:hover\:via-cool-gray-900:hover {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .md\:hover\:via-red-50:hover {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .md\:hover\:via-red-100:hover {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .md\:hover\:via-red-200:hover {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .md\:hover\:via-red-300:hover {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .md\:hover\:via-red-400:hover {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .md\:hover\:via-red-500:hover {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .md\:hover\:via-red-600:hover {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .md\:hover\:via-red-700:hover {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .md\:hover\:via-red-800:hover {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .md\:hover\:via-red-900:hover {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .md\:hover\:via-orange-50:hover {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .md\:hover\:via-orange-100:hover {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .md\:hover\:via-orange-200:hover {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .md\:hover\:via-orange-300:hover {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .md\:hover\:via-orange-400:hover {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .md\:hover\:via-orange-500:hover {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .md\:hover\:via-orange-600:hover {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .md\:hover\:via-orange-700:hover {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .md\:hover\:via-orange-800:hover {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .md\:hover\:via-orange-900:hover {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .md\:hover\:via-yellow-50:hover {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .md\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .md\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .md\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .md\:hover\:via-yellow-400:hover {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .md\:hover\:via-yellow-500:hover {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .md\:hover\:via-yellow-600:hover {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .md\:hover\:via-yellow-700:hover {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .md\:hover\:via-yellow-800:hover {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .md\:hover\:via-yellow-900:hover {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .md\:hover\:via-green-50:hover {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .md\:hover\:via-green-100:hover {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .md\:hover\:via-green-200:hover {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .md\:hover\:via-green-300:hover {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .md\:hover\:via-green-400:hover {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .md\:hover\:via-green-500:hover {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .md\:hover\:via-green-600:hover {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .md\:hover\:via-green-700:hover {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .md\:hover\:via-green-800:hover {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .md\:hover\:via-green-900:hover {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .md\:hover\:via-teal-50:hover {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .md\:hover\:via-teal-100:hover {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .md\:hover\:via-teal-200:hover {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .md\:hover\:via-teal-300:hover {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .md\:hover\:via-teal-400:hover {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .md\:hover\:via-teal-500:hover {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .md\:hover\:via-teal-600:hover {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .md\:hover\:via-teal-700:hover {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .md\:hover\:via-teal-800:hover {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .md\:hover\:via-teal-900:hover {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .md\:hover\:via-blue-50:hover {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .md\:hover\:via-blue-100:hover {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .md\:hover\:via-blue-200:hover {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .md\:hover\:via-blue-300:hover {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .md\:hover\:via-blue-400:hover {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .md\:hover\:via-blue-500:hover {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .md\:hover\:via-blue-600:hover {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .md\:hover\:via-blue-700:hover {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .md\:hover\:via-blue-800:hover {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .md\:hover\:via-blue-900:hover {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .md\:hover\:via-indigo-50:hover {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .md\:hover\:via-indigo-100:hover {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .md\:hover\:via-indigo-200:hover {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .md\:hover\:via-indigo-300:hover {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .md\:hover\:via-indigo-400:hover {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .md\:hover\:via-indigo-500:hover {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .md\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .md\:hover\:via-indigo-700:hover {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .md\:hover\:via-indigo-800:hover {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .md\:hover\:via-indigo-900:hover {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .md\:hover\:via-purple-50:hover {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .md\:hover\:via-purple-100:hover {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .md\:hover\:via-purple-200:hover {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .md\:hover\:via-purple-300:hover {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .md\:hover\:via-purple-400:hover {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .md\:hover\:via-purple-500:hover {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .md\:hover\:via-purple-600:hover {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .md\:hover\:via-purple-700:hover {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .md\:hover\:via-purple-800:hover {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .md\:hover\:via-purple-900:hover {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .md\:hover\:via-pink-50:hover {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .md\:hover\:via-pink-100:hover {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .md\:hover\:via-pink-200:hover {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .md\:hover\:via-pink-300:hover {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .md\:hover\:via-pink-400:hover {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .md\:hover\:via-pink-500:hover {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .md\:hover\:via-pink-600:hover {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .md\:hover\:via-pink-700:hover {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .md\:hover\:via-pink-800:hover {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .md\:hover\:via-pink-900:hover {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .md\:hover\:to-transparent:hover {
    --gradient-to-color: transparent;
  }

  .md\:hover\:to-white:hover {
    --gradient-to-color: #fff;
  }

  .md\:hover\:to-black:hover {
    --gradient-to-color: #000;
  }

  .md\:hover\:to-gray-50:hover {
    --gradient-to-color: #f9fafb;
  }

  .md\:hover\:to-gray-100:hover {
    --gradient-to-color: #f4f5f7;
  }

  .md\:hover\:to-gray-200:hover {
    --gradient-to-color: #e5e7eb;
  }

  .md\:hover\:to-gray-300:hover {
    --gradient-to-color: #d2d6dc;
  }

  .md\:hover\:to-gray-400:hover {
    --gradient-to-color: #9fa6b2;
  }

  .md\:hover\:to-gray-500:hover {
    --gradient-to-color: #6b7280;
  }

  .md\:hover\:to-gray-600:hover {
    --gradient-to-color: #4b5563;
  }

  .md\:hover\:to-gray-700:hover {
    --gradient-to-color: #374151;
  }

  .md\:hover\:to-gray-800:hover {
    --gradient-to-color: #252f3f;
  }

  .md\:hover\:to-gray-900:hover {
    --gradient-to-color: #161e2e;
  }

  .md\:hover\:to-cool-gray-50:hover {
    --gradient-to-color: #fbfdfe;
  }

  .md\:hover\:to-cool-gray-100:hover {
    --gradient-to-color: #f1f5f9;
  }

  .md\:hover\:to-cool-gray-200:hover {
    --gradient-to-color: #e2e8f0;
  }

  .md\:hover\:to-cool-gray-300:hover {
    --gradient-to-color: #cfd8e3;
  }

  .md\:hover\:to-cool-gray-400:hover {
    --gradient-to-color: #97a6ba;
  }

  .md\:hover\:to-cool-gray-500:hover {
    --gradient-to-color: #64748b;
  }

  .md\:hover\:to-cool-gray-600:hover {
    --gradient-to-color: #475569;
  }

  .md\:hover\:to-cool-gray-700:hover {
    --gradient-to-color: #364152;
  }

  .md\:hover\:to-cool-gray-800:hover {
    --gradient-to-color: #27303f;
  }

  .md\:hover\:to-cool-gray-900:hover {
    --gradient-to-color: #1a202e;
  }

  .md\:hover\:to-red-50:hover {
    --gradient-to-color: #fdf2f2;
  }

  .md\:hover\:to-red-100:hover {
    --gradient-to-color: #fde8e8;
  }

  .md\:hover\:to-red-200:hover {
    --gradient-to-color: #fbd5d5;
  }

  .md\:hover\:to-red-300:hover {
    --gradient-to-color: #f8b4b4;
  }

  .md\:hover\:to-red-400:hover {
    --gradient-to-color: #f98080;
  }

  .md\:hover\:to-red-500:hover {
    --gradient-to-color: #f05252;
  }

  .md\:hover\:to-red-600:hover {
    --gradient-to-color: #e02424;
  }

  .md\:hover\:to-red-700:hover {
    --gradient-to-color: #c81e1e;
  }

  .md\:hover\:to-red-800:hover {
    --gradient-to-color: #9b1c1c;
  }

  .md\:hover\:to-red-900:hover {
    --gradient-to-color: #771d1d;
  }

  .md\:hover\:to-orange-50:hover {
    --gradient-to-color: #fff8f1;
  }

  .md\:hover\:to-orange-100:hover {
    --gradient-to-color: #feecdc;
  }

  .md\:hover\:to-orange-200:hover {
    --gradient-to-color: #fcd9bd;
  }

  .md\:hover\:to-orange-300:hover {
    --gradient-to-color: #fdba8c;
  }

  .md\:hover\:to-orange-400:hover {
    --gradient-to-color: #ff8a4c;
  }

  .md\:hover\:to-orange-500:hover {
    --gradient-to-color: #ff5a1f;
  }

  .md\:hover\:to-orange-600:hover {
    --gradient-to-color: #d03801;
  }

  .md\:hover\:to-orange-700:hover {
    --gradient-to-color: #b43403;
  }

  .md\:hover\:to-orange-800:hover {
    --gradient-to-color: #8a2c0d;
  }

  .md\:hover\:to-orange-900:hover {
    --gradient-to-color: #73230d;
  }

  .md\:hover\:to-yellow-50:hover {
    --gradient-to-color: #fdfdea;
  }

  .md\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fdf6b2;
  }

  .md\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fce96a;
  }

  .md\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faca15;
  }

  .md\:hover\:to-yellow-400:hover {
    --gradient-to-color: #e3a008;
  }

  .md\:hover\:to-yellow-500:hover {
    --gradient-to-color: #c27803;
  }

  .md\:hover\:to-yellow-600:hover {
    --gradient-to-color: #9f580a;
  }

  .md\:hover\:to-yellow-700:hover {
    --gradient-to-color: #8e4b10;
  }

  .md\:hover\:to-yellow-800:hover {
    --gradient-to-color: #723b13;
  }

  .md\:hover\:to-yellow-900:hover {
    --gradient-to-color: #633112;
  }

  .md\:hover\:to-green-50:hover {
    --gradient-to-color: #f3faf7;
  }

  .md\:hover\:to-green-100:hover {
    --gradient-to-color: #def7ec;
  }

  .md\:hover\:to-green-200:hover {
    --gradient-to-color: #bcf0da;
  }

  .md\:hover\:to-green-300:hover {
    --gradient-to-color: #84e1bc;
  }

  .md\:hover\:to-green-400:hover {
    --gradient-to-color: #31c48d;
  }

  .md\:hover\:to-green-500:hover {
    --gradient-to-color: #0e9f6e;
  }

  .md\:hover\:to-green-600:hover {
    --gradient-to-color: #057a55;
  }

  .md\:hover\:to-green-700:hover {
    --gradient-to-color: #046c4e;
  }

  .md\:hover\:to-green-800:hover {
    --gradient-to-color: #03543f;
  }

  .md\:hover\:to-green-900:hover {
    --gradient-to-color: #014737;
  }

  .md\:hover\:to-teal-50:hover {
    --gradient-to-color: #edfafa;
  }

  .md\:hover\:to-teal-100:hover {
    --gradient-to-color: #d5f5f6;
  }

  .md\:hover\:to-teal-200:hover {
    --gradient-to-color: #afecef;
  }

  .md\:hover\:to-teal-300:hover {
    --gradient-to-color: #7edce2;
  }

  .md\:hover\:to-teal-400:hover {
    --gradient-to-color: #16bdca;
  }

  .md\:hover\:to-teal-500:hover {
    --gradient-to-color: #0694a2;
  }

  .md\:hover\:to-teal-600:hover {
    --gradient-to-color: #047481;
  }

  .md\:hover\:to-teal-700:hover {
    --gradient-to-color: #036672;
  }

  .md\:hover\:to-teal-800:hover {
    --gradient-to-color: #05505c;
  }

  .md\:hover\:to-teal-900:hover {
    --gradient-to-color: #014451;
  }

  .md\:hover\:to-blue-50:hover {
    --gradient-to-color: #ebf5ff;
  }

  .md\:hover\:to-blue-100:hover {
    --gradient-to-color: #e1effe;
  }

  .md\:hover\:to-blue-200:hover {
    --gradient-to-color: #c3ddfd;
  }

  .md\:hover\:to-blue-300:hover {
    --gradient-to-color: #a4cafe;
  }

  .md\:hover\:to-blue-400:hover {
    --gradient-to-color: #76a9fa;
  }

  .md\:hover\:to-blue-500:hover {
    --gradient-to-color: #3f83f8;
  }

  .md\:hover\:to-blue-600:hover {
    --gradient-to-color: #1c64f2;
  }

  .md\:hover\:to-blue-700:hover {
    --gradient-to-color: #1a56db;
  }

  .md\:hover\:to-blue-800:hover {
    --gradient-to-color: #1e429f;
  }

  .md\:hover\:to-blue-900:hover {
    --gradient-to-color: #233876;
  }

  .md\:hover\:to-indigo-50:hover {
    --gradient-to-color: #f0f5ff;
  }

  .md\:hover\:to-indigo-100:hover {
    --gradient-to-color: #e5edff;
  }

  .md\:hover\:to-indigo-200:hover {
    --gradient-to-color: #cddbfe;
  }

  .md\:hover\:to-indigo-300:hover {
    --gradient-to-color: #b4c6fc;
  }

  .md\:hover\:to-indigo-400:hover {
    --gradient-to-color: #8da2fb;
  }

  .md\:hover\:to-indigo-500:hover {
    --gradient-to-color: #6875f5;
  }

  .md\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5850ec;
  }

  .md\:hover\:to-indigo-700:hover {
    --gradient-to-color: #5145cd;
  }

  .md\:hover\:to-indigo-800:hover {
    --gradient-to-color: #42389d;
  }

  .md\:hover\:to-indigo-900:hover {
    --gradient-to-color: #362f78;
  }

  .md\:hover\:to-purple-50:hover {
    --gradient-to-color: #f6f5ff;
  }

  .md\:hover\:to-purple-100:hover {
    --gradient-to-color: #edebfe;
  }

  .md\:hover\:to-purple-200:hover {
    --gradient-to-color: #dcd7fe;
  }

  .md\:hover\:to-purple-300:hover {
    --gradient-to-color: #cabffd;
  }

  .md\:hover\:to-purple-400:hover {
    --gradient-to-color: #ac94fa;
  }

  .md\:hover\:to-purple-500:hover {
    --gradient-to-color: #9061f9;
  }

  .md\:hover\:to-purple-600:hover {
    --gradient-to-color: #7e3af2;
  }

  .md\:hover\:to-purple-700:hover {
    --gradient-to-color: #6c2bd9;
  }

  .md\:hover\:to-purple-800:hover {
    --gradient-to-color: #5521b5;
  }

  .md\:hover\:to-purple-900:hover {
    --gradient-to-color: #4a1d96;
  }

  .md\:hover\:to-pink-50:hover {
    --gradient-to-color: #fdf2f8;
  }

  .md\:hover\:to-pink-100:hover {
    --gradient-to-color: #fce8f3;
  }

  .md\:hover\:to-pink-200:hover {
    --gradient-to-color: #fad1e8;
  }

  .md\:hover\:to-pink-300:hover {
    --gradient-to-color: #f8b4d9;
  }

  .md\:hover\:to-pink-400:hover {
    --gradient-to-color: #f17eb8;
  }

  .md\:hover\:to-pink-500:hover {
    --gradient-to-color: #e74694;
  }

  .md\:hover\:to-pink-600:hover {
    --gradient-to-color: #d61f69;
  }

  .md\:hover\:to-pink-700:hover {
    --gradient-to-color: #bf125d;
  }

  .md\:hover\:to-pink-800:hover {
    --gradient-to-color: #99154b;
  }

  .md\:hover\:to-pink-900:hover {
    --gradient-to-color: #751a3d;
  }

  .md\:focus\:from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .md\:focus\:from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .md\:focus\:from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .md\:focus\:from-gray-50:focus {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .md\:focus\:from-gray-100:focus {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .md\:focus\:from-gray-200:focus {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .md\:focus\:from-gray-300:focus {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .md\:focus\:from-gray-400:focus {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .md\:focus\:from-gray-500:focus {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .md\:focus\:from-gray-600:focus {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .md\:focus\:from-gray-700:focus {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .md\:focus\:from-gray-800:focus {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .md\:focus\:from-gray-900:focus {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .md\:focus\:from-cool-gray-50:focus {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .md\:focus\:from-cool-gray-100:focus {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .md\:focus\:from-cool-gray-200:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .md\:focus\:from-cool-gray-300:focus {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .md\:focus\:from-cool-gray-400:focus {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .md\:focus\:from-cool-gray-500:focus {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .md\:focus\:from-cool-gray-600:focus {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .md\:focus\:from-cool-gray-700:focus {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .md\:focus\:from-cool-gray-800:focus {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .md\:focus\:from-cool-gray-900:focus {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .md\:focus\:from-red-50:focus {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .md\:focus\:from-red-100:focus {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .md\:focus\:from-red-200:focus {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .md\:focus\:from-red-300:focus {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .md\:focus\:from-red-400:focus {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .md\:focus\:from-red-500:focus {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .md\:focus\:from-red-600:focus {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .md\:focus\:from-red-700:focus {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .md\:focus\:from-red-800:focus {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .md\:focus\:from-red-900:focus {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .md\:focus\:from-orange-50:focus {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .md\:focus\:from-orange-100:focus {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .md\:focus\:from-orange-200:focus {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .md\:focus\:from-orange-300:focus {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .md\:focus\:from-orange-400:focus {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .md\:focus\:from-orange-500:focus {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .md\:focus\:from-orange-600:focus {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .md\:focus\:from-orange-700:focus {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .md\:focus\:from-orange-800:focus {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .md\:focus\:from-orange-900:focus {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .md\:focus\:from-yellow-50:focus {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .md\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .md\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .md\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .md\:focus\:from-yellow-400:focus {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .md\:focus\:from-yellow-500:focus {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .md\:focus\:from-yellow-600:focus {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .md\:focus\:from-yellow-700:focus {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .md\:focus\:from-yellow-800:focus {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .md\:focus\:from-yellow-900:focus {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .md\:focus\:from-green-50:focus {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .md\:focus\:from-green-100:focus {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .md\:focus\:from-green-200:focus {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .md\:focus\:from-green-300:focus {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .md\:focus\:from-green-400:focus {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .md\:focus\:from-green-500:focus {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .md\:focus\:from-green-600:focus {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .md\:focus\:from-green-700:focus {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .md\:focus\:from-green-800:focus {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .md\:focus\:from-green-900:focus {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .md\:focus\:from-teal-50:focus {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .md\:focus\:from-teal-100:focus {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .md\:focus\:from-teal-200:focus {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .md\:focus\:from-teal-300:focus {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .md\:focus\:from-teal-400:focus {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .md\:focus\:from-teal-500:focus {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .md\:focus\:from-teal-600:focus {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .md\:focus\:from-teal-700:focus {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .md\:focus\:from-teal-800:focus {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .md\:focus\:from-teal-900:focus {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .md\:focus\:from-blue-50:focus {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .md\:focus\:from-blue-100:focus {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .md\:focus\:from-blue-200:focus {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .md\:focus\:from-blue-300:focus {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .md\:focus\:from-blue-400:focus {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .md\:focus\:from-blue-500:focus {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .md\:focus\:from-blue-600:focus {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .md\:focus\:from-blue-700:focus {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .md\:focus\:from-blue-800:focus {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .md\:focus\:from-blue-900:focus {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .md\:focus\:from-indigo-50:focus {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .md\:focus\:from-indigo-100:focus {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .md\:focus\:from-indigo-200:focus {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .md\:focus\:from-indigo-300:focus {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .md\:focus\:from-indigo-400:focus {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .md\:focus\:from-indigo-500:focus {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .md\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .md\:focus\:from-indigo-700:focus {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .md\:focus\:from-indigo-800:focus {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .md\:focus\:from-indigo-900:focus {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .md\:focus\:from-purple-50:focus {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .md\:focus\:from-purple-100:focus {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .md\:focus\:from-purple-200:focus {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .md\:focus\:from-purple-300:focus {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .md\:focus\:from-purple-400:focus {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .md\:focus\:from-purple-500:focus {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .md\:focus\:from-purple-600:focus {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .md\:focus\:from-purple-700:focus {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .md\:focus\:from-purple-800:focus {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .md\:focus\:from-purple-900:focus {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .md\:focus\:from-pink-50:focus {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .md\:focus\:from-pink-100:focus {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .md\:focus\:from-pink-200:focus {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .md\:focus\:from-pink-300:focus {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .md\:focus\:from-pink-400:focus {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .md\:focus\:from-pink-500:focus {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .md\:focus\:from-pink-600:focus {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .md\:focus\:from-pink-700:focus {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .md\:focus\:from-pink-800:focus {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .md\:focus\:from-pink-900:focus {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .md\:focus\:via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .md\:focus\:via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .md\:focus\:via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .md\:focus\:via-gray-50:focus {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .md\:focus\:via-gray-100:focus {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .md\:focus\:via-gray-200:focus {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .md\:focus\:via-gray-300:focus {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .md\:focus\:via-gray-400:focus {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .md\:focus\:via-gray-500:focus {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .md\:focus\:via-gray-600:focus {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .md\:focus\:via-gray-700:focus {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .md\:focus\:via-gray-800:focus {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .md\:focus\:via-gray-900:focus {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .md\:focus\:via-cool-gray-50:focus {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .md\:focus\:via-cool-gray-100:focus {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .md\:focus\:via-cool-gray-200:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .md\:focus\:via-cool-gray-300:focus {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .md\:focus\:via-cool-gray-400:focus {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .md\:focus\:via-cool-gray-500:focus {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .md\:focus\:via-cool-gray-600:focus {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .md\:focus\:via-cool-gray-700:focus {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .md\:focus\:via-cool-gray-800:focus {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .md\:focus\:via-cool-gray-900:focus {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .md\:focus\:via-red-50:focus {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .md\:focus\:via-red-100:focus {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .md\:focus\:via-red-200:focus {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .md\:focus\:via-red-300:focus {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .md\:focus\:via-red-400:focus {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .md\:focus\:via-red-500:focus {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .md\:focus\:via-red-600:focus {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .md\:focus\:via-red-700:focus {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .md\:focus\:via-red-800:focus {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .md\:focus\:via-red-900:focus {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .md\:focus\:via-orange-50:focus {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .md\:focus\:via-orange-100:focus {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .md\:focus\:via-orange-200:focus {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .md\:focus\:via-orange-300:focus {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .md\:focus\:via-orange-400:focus {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .md\:focus\:via-orange-500:focus {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .md\:focus\:via-orange-600:focus {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .md\:focus\:via-orange-700:focus {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .md\:focus\:via-orange-800:focus {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .md\:focus\:via-orange-900:focus {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .md\:focus\:via-yellow-50:focus {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .md\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .md\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .md\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .md\:focus\:via-yellow-400:focus {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .md\:focus\:via-yellow-500:focus {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .md\:focus\:via-yellow-600:focus {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .md\:focus\:via-yellow-700:focus {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .md\:focus\:via-yellow-800:focus {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .md\:focus\:via-yellow-900:focus {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .md\:focus\:via-green-50:focus {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .md\:focus\:via-green-100:focus {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .md\:focus\:via-green-200:focus {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .md\:focus\:via-green-300:focus {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .md\:focus\:via-green-400:focus {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .md\:focus\:via-green-500:focus {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .md\:focus\:via-green-600:focus {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .md\:focus\:via-green-700:focus {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .md\:focus\:via-green-800:focus {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .md\:focus\:via-green-900:focus {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .md\:focus\:via-teal-50:focus {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .md\:focus\:via-teal-100:focus {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .md\:focus\:via-teal-200:focus {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .md\:focus\:via-teal-300:focus {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .md\:focus\:via-teal-400:focus {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .md\:focus\:via-teal-500:focus {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .md\:focus\:via-teal-600:focus {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .md\:focus\:via-teal-700:focus {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .md\:focus\:via-teal-800:focus {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .md\:focus\:via-teal-900:focus {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .md\:focus\:via-blue-50:focus {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .md\:focus\:via-blue-100:focus {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .md\:focus\:via-blue-200:focus {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .md\:focus\:via-blue-300:focus {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .md\:focus\:via-blue-400:focus {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .md\:focus\:via-blue-500:focus {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .md\:focus\:via-blue-600:focus {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .md\:focus\:via-blue-700:focus {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .md\:focus\:via-blue-800:focus {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .md\:focus\:via-blue-900:focus {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .md\:focus\:via-indigo-50:focus {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .md\:focus\:via-indigo-100:focus {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .md\:focus\:via-indigo-200:focus {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .md\:focus\:via-indigo-300:focus {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .md\:focus\:via-indigo-400:focus {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .md\:focus\:via-indigo-500:focus {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .md\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .md\:focus\:via-indigo-700:focus {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .md\:focus\:via-indigo-800:focus {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .md\:focus\:via-indigo-900:focus {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .md\:focus\:via-purple-50:focus {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .md\:focus\:via-purple-100:focus {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .md\:focus\:via-purple-200:focus {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .md\:focus\:via-purple-300:focus {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .md\:focus\:via-purple-400:focus {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .md\:focus\:via-purple-500:focus {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .md\:focus\:via-purple-600:focus {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .md\:focus\:via-purple-700:focus {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .md\:focus\:via-purple-800:focus {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .md\:focus\:via-purple-900:focus {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .md\:focus\:via-pink-50:focus {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .md\:focus\:via-pink-100:focus {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .md\:focus\:via-pink-200:focus {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .md\:focus\:via-pink-300:focus {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .md\:focus\:via-pink-400:focus {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .md\:focus\:via-pink-500:focus {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .md\:focus\:via-pink-600:focus {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .md\:focus\:via-pink-700:focus {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .md\:focus\:via-pink-800:focus {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .md\:focus\:via-pink-900:focus {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .md\:focus\:to-transparent:focus {
    --gradient-to-color: transparent;
  }

  .md\:focus\:to-white:focus {
    --gradient-to-color: #fff;
  }

  .md\:focus\:to-black:focus {
    --gradient-to-color: #000;
  }

  .md\:focus\:to-gray-50:focus {
    --gradient-to-color: #f9fafb;
  }

  .md\:focus\:to-gray-100:focus {
    --gradient-to-color: #f4f5f7;
  }

  .md\:focus\:to-gray-200:focus {
    --gradient-to-color: #e5e7eb;
  }

  .md\:focus\:to-gray-300:focus {
    --gradient-to-color: #d2d6dc;
  }

  .md\:focus\:to-gray-400:focus {
    --gradient-to-color: #9fa6b2;
  }

  .md\:focus\:to-gray-500:focus {
    --gradient-to-color: #6b7280;
  }

  .md\:focus\:to-gray-600:focus {
    --gradient-to-color: #4b5563;
  }

  .md\:focus\:to-gray-700:focus {
    --gradient-to-color: #374151;
  }

  .md\:focus\:to-gray-800:focus {
    --gradient-to-color: #252f3f;
  }

  .md\:focus\:to-gray-900:focus {
    --gradient-to-color: #161e2e;
  }

  .md\:focus\:to-cool-gray-50:focus {
    --gradient-to-color: #fbfdfe;
  }

  .md\:focus\:to-cool-gray-100:focus {
    --gradient-to-color: #f1f5f9;
  }

  .md\:focus\:to-cool-gray-200:focus {
    --gradient-to-color: #e2e8f0;
  }

  .md\:focus\:to-cool-gray-300:focus {
    --gradient-to-color: #cfd8e3;
  }

  .md\:focus\:to-cool-gray-400:focus {
    --gradient-to-color: #97a6ba;
  }

  .md\:focus\:to-cool-gray-500:focus {
    --gradient-to-color: #64748b;
  }

  .md\:focus\:to-cool-gray-600:focus {
    --gradient-to-color: #475569;
  }

  .md\:focus\:to-cool-gray-700:focus {
    --gradient-to-color: #364152;
  }

  .md\:focus\:to-cool-gray-800:focus {
    --gradient-to-color: #27303f;
  }

  .md\:focus\:to-cool-gray-900:focus {
    --gradient-to-color: #1a202e;
  }

  .md\:focus\:to-red-50:focus {
    --gradient-to-color: #fdf2f2;
  }

  .md\:focus\:to-red-100:focus {
    --gradient-to-color: #fde8e8;
  }

  .md\:focus\:to-red-200:focus {
    --gradient-to-color: #fbd5d5;
  }

  .md\:focus\:to-red-300:focus {
    --gradient-to-color: #f8b4b4;
  }

  .md\:focus\:to-red-400:focus {
    --gradient-to-color: #f98080;
  }

  .md\:focus\:to-red-500:focus {
    --gradient-to-color: #f05252;
  }

  .md\:focus\:to-red-600:focus {
    --gradient-to-color: #e02424;
  }

  .md\:focus\:to-red-700:focus {
    --gradient-to-color: #c81e1e;
  }

  .md\:focus\:to-red-800:focus {
    --gradient-to-color: #9b1c1c;
  }

  .md\:focus\:to-red-900:focus {
    --gradient-to-color: #771d1d;
  }

  .md\:focus\:to-orange-50:focus {
    --gradient-to-color: #fff8f1;
  }

  .md\:focus\:to-orange-100:focus {
    --gradient-to-color: #feecdc;
  }

  .md\:focus\:to-orange-200:focus {
    --gradient-to-color: #fcd9bd;
  }

  .md\:focus\:to-orange-300:focus {
    --gradient-to-color: #fdba8c;
  }

  .md\:focus\:to-orange-400:focus {
    --gradient-to-color: #ff8a4c;
  }

  .md\:focus\:to-orange-500:focus {
    --gradient-to-color: #ff5a1f;
  }

  .md\:focus\:to-orange-600:focus {
    --gradient-to-color: #d03801;
  }

  .md\:focus\:to-orange-700:focus {
    --gradient-to-color: #b43403;
  }

  .md\:focus\:to-orange-800:focus {
    --gradient-to-color: #8a2c0d;
  }

  .md\:focus\:to-orange-900:focus {
    --gradient-to-color: #73230d;
  }

  .md\:focus\:to-yellow-50:focus {
    --gradient-to-color: #fdfdea;
  }

  .md\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fdf6b2;
  }

  .md\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fce96a;
  }

  .md\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faca15;
  }

  .md\:focus\:to-yellow-400:focus {
    --gradient-to-color: #e3a008;
  }

  .md\:focus\:to-yellow-500:focus {
    --gradient-to-color: #c27803;
  }

  .md\:focus\:to-yellow-600:focus {
    --gradient-to-color: #9f580a;
  }

  .md\:focus\:to-yellow-700:focus {
    --gradient-to-color: #8e4b10;
  }

  .md\:focus\:to-yellow-800:focus {
    --gradient-to-color: #723b13;
  }

  .md\:focus\:to-yellow-900:focus {
    --gradient-to-color: #633112;
  }

  .md\:focus\:to-green-50:focus {
    --gradient-to-color: #f3faf7;
  }

  .md\:focus\:to-green-100:focus {
    --gradient-to-color: #def7ec;
  }

  .md\:focus\:to-green-200:focus {
    --gradient-to-color: #bcf0da;
  }

  .md\:focus\:to-green-300:focus {
    --gradient-to-color: #84e1bc;
  }

  .md\:focus\:to-green-400:focus {
    --gradient-to-color: #31c48d;
  }

  .md\:focus\:to-green-500:focus {
    --gradient-to-color: #0e9f6e;
  }

  .md\:focus\:to-green-600:focus {
    --gradient-to-color: #057a55;
  }

  .md\:focus\:to-green-700:focus {
    --gradient-to-color: #046c4e;
  }

  .md\:focus\:to-green-800:focus {
    --gradient-to-color: #03543f;
  }

  .md\:focus\:to-green-900:focus {
    --gradient-to-color: #014737;
  }

  .md\:focus\:to-teal-50:focus {
    --gradient-to-color: #edfafa;
  }

  .md\:focus\:to-teal-100:focus {
    --gradient-to-color: #d5f5f6;
  }

  .md\:focus\:to-teal-200:focus {
    --gradient-to-color: #afecef;
  }

  .md\:focus\:to-teal-300:focus {
    --gradient-to-color: #7edce2;
  }

  .md\:focus\:to-teal-400:focus {
    --gradient-to-color: #16bdca;
  }

  .md\:focus\:to-teal-500:focus {
    --gradient-to-color: #0694a2;
  }

  .md\:focus\:to-teal-600:focus {
    --gradient-to-color: #047481;
  }

  .md\:focus\:to-teal-700:focus {
    --gradient-to-color: #036672;
  }

  .md\:focus\:to-teal-800:focus {
    --gradient-to-color: #05505c;
  }

  .md\:focus\:to-teal-900:focus {
    --gradient-to-color: #014451;
  }

  .md\:focus\:to-blue-50:focus {
    --gradient-to-color: #ebf5ff;
  }

  .md\:focus\:to-blue-100:focus {
    --gradient-to-color: #e1effe;
  }

  .md\:focus\:to-blue-200:focus {
    --gradient-to-color: #c3ddfd;
  }

  .md\:focus\:to-blue-300:focus {
    --gradient-to-color: #a4cafe;
  }

  .md\:focus\:to-blue-400:focus {
    --gradient-to-color: #76a9fa;
  }

  .md\:focus\:to-blue-500:focus {
    --gradient-to-color: #3f83f8;
  }

  .md\:focus\:to-blue-600:focus {
    --gradient-to-color: #1c64f2;
  }

  .md\:focus\:to-blue-700:focus {
    --gradient-to-color: #1a56db;
  }

  .md\:focus\:to-blue-800:focus {
    --gradient-to-color: #1e429f;
  }

  .md\:focus\:to-blue-900:focus {
    --gradient-to-color: #233876;
  }

  .md\:focus\:to-indigo-50:focus {
    --gradient-to-color: #f0f5ff;
  }

  .md\:focus\:to-indigo-100:focus {
    --gradient-to-color: #e5edff;
  }

  .md\:focus\:to-indigo-200:focus {
    --gradient-to-color: #cddbfe;
  }

  .md\:focus\:to-indigo-300:focus {
    --gradient-to-color: #b4c6fc;
  }

  .md\:focus\:to-indigo-400:focus {
    --gradient-to-color: #8da2fb;
  }

  .md\:focus\:to-indigo-500:focus {
    --gradient-to-color: #6875f5;
  }

  .md\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5850ec;
  }

  .md\:focus\:to-indigo-700:focus {
    --gradient-to-color: #5145cd;
  }

  .md\:focus\:to-indigo-800:focus {
    --gradient-to-color: #42389d;
  }

  .md\:focus\:to-indigo-900:focus {
    --gradient-to-color: #362f78;
  }

  .md\:focus\:to-purple-50:focus {
    --gradient-to-color: #f6f5ff;
  }

  .md\:focus\:to-purple-100:focus {
    --gradient-to-color: #edebfe;
  }

  .md\:focus\:to-purple-200:focus {
    --gradient-to-color: #dcd7fe;
  }

  .md\:focus\:to-purple-300:focus {
    --gradient-to-color: #cabffd;
  }

  .md\:focus\:to-purple-400:focus {
    --gradient-to-color: #ac94fa;
  }

  .md\:focus\:to-purple-500:focus {
    --gradient-to-color: #9061f9;
  }

  .md\:focus\:to-purple-600:focus {
    --gradient-to-color: #7e3af2;
  }

  .md\:focus\:to-purple-700:focus {
    --gradient-to-color: #6c2bd9;
  }

  .md\:focus\:to-purple-800:focus {
    --gradient-to-color: #5521b5;
  }

  .md\:focus\:to-purple-900:focus {
    --gradient-to-color: #4a1d96;
  }

  .md\:focus\:to-pink-50:focus {
    --gradient-to-color: #fdf2f8;
  }

  .md\:focus\:to-pink-100:focus {
    --gradient-to-color: #fce8f3;
  }

  .md\:focus\:to-pink-200:focus {
    --gradient-to-color: #fad1e8;
  }

  .md\:focus\:to-pink-300:focus {
    --gradient-to-color: #f8b4d9;
  }

  .md\:focus\:to-pink-400:focus {
    --gradient-to-color: #f17eb8;
  }

  .md\:focus\:to-pink-500:focus {
    --gradient-to-color: #e74694;
  }

  .md\:focus\:to-pink-600:focus {
    --gradient-to-color: #d61f69;
  }

  .md\:focus\:to-pink-700:focus {
    --gradient-to-color: #bf125d;
  }

  .md\:focus\:to-pink-800:focus {
    --gradient-to-color: #99154b;
  }

  .md\:focus\:to-pink-900:focus {
    --gradient-to-color: #751a3d;
  }

  .md\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .md\:bg-opacity-25 {
    --bg-opacity: .25;
  }

  .md\:bg-opacity-50 {
    --bg-opacity: .5;
  }

  .md\:bg-opacity-75 {
    --bg-opacity: .75;
  }

  .md\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .md\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .md\:hover\:bg-opacity-25:hover {
    --bg-opacity: .25;
  }

  .md\:hover\:bg-opacity-50:hover {
    --bg-opacity: .5;
  }

  .md\:hover\:bg-opacity-75:hover {
    --bg-opacity: .75;
  }

  .md\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .md\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .md\:focus\:bg-opacity-25:focus {
    --bg-opacity: .25;
  }

  .md\:focus\:bg-opacity-50:focus {
    --bg-opacity: .5;
  }

  .md\:focus\:bg-opacity-75:focus {
    --bg-opacity: .75;
  }

  .md\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .md\:bg-bottom {
    background-position: bottom;
  }

  .md\:bg-center {
    background-position: center;
  }

  .md\:bg-left {
    background-position: 0;
  }

  .md\:bg-left-bottom {
    background-position: 0 100%;
  }

  .md\:bg-left-top {
    background-position: 0 0;
  }

  .md\:bg-right {
    background-position: 100%;
  }

  .md\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .md\:bg-right-top {
    background-position: 100% 0;
  }

  .md\:bg-top {
    background-position: top;
  }

  .md\:bg-repeat {
    background-repeat: repeat;
  }

  .md\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .md\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .md\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .md\:bg-repeat-round {
    background-repeat: round;
  }

  .md\:bg-repeat-space {
    background-repeat: space;
  }

  .md\:bg-auto {
    background-size: auto;
  }

  .md\:bg-cover {
    background-size: cover;
  }

  .md\:bg-contain {
    background-size: contain;
  }

  .md\:border-collapse {
    border-collapse: collapse;
  }

  .md\:border-separate {
    border-collapse: separate;
  }

  .md\:border-transparent {
    border-color: #0000;
  }

  .md\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .md\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .md\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .md\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .md\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .md\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .md\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .md\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .md\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .md\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .md\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .md\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .md\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .md\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .md\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .md\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .md\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .md\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .md\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .md\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .md\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .md\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .md\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .md\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .md\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .md\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .md\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .md\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .md\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .md\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .md\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .md\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .md\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .md\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .md\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .md\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .md\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .md\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .md\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .md\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .md\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .md\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .md\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .md\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .md\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .md\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .md\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .md\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .md\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .md\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .md\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .md\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .md\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .md\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .md\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .md\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .md\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .md\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .md\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .md\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .md\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .md\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .md\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .md\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .md\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .md\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .md\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .md\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .md\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .md\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .md\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .md\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .md\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .md\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .md\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .md\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .md\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .md\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .md\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .md\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .md\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .md\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .md\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .md\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .md\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .md\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .md\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .md\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .md\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .md\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .md\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .md\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .md\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .md\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .md\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .md\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .md\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .md\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .md\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .md\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .md\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .md\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .md\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .md\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .md\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .md\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .md\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .md\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .md\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .md\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .md\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .md\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .md\:group-hover\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .group:hover .md\:group-hover\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-transparent {
    border-color: #0000;
  }

  .group:focus .md\:group-focus\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .group:focus .md\:group-focus\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .md\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .md\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .md\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .md\:hover\:border-gray-50:hover {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .md\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .md\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .md\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .md\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .md\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .md\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .md\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .md\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .md\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-50:hover {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-100:hover {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-200:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-300:hover {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-400:hover {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-500:hover {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-600:hover {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-700:hover {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-800:hover {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .md\:hover\:border-cool-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .md\:hover\:border-red-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .md\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .md\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .md\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .md\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .md\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .md\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .md\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .md\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .md\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .md\:hover\:border-orange-50:hover {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .md\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .md\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .md\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .md\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .md\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .md\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .md\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .md\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .md\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-50:hover {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .md\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .md\:hover\:border-green-50:hover {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .md\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .md\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .md\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .md\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .md\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .md\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .md\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .md\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .md\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .md\:hover\:border-teal-50:hover {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .md\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .md\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .md\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .md\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .md\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .md\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .md\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .md\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .md\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .md\:hover\:border-blue-50:hover {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .md\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .md\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .md\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .md\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .md\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .md\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .md\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .md\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .md\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-50:hover {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .md\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .md\:hover\:border-purple-50:hover {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .md\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .md\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .md\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .md\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .md\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .md\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .md\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .md\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .md\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .md\:hover\:border-pink-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .md\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .md\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .md\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .md\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .md\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .md\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .md\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .md\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .md\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .md\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .md\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .md\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .md\:focus\:border-gray-50:focus {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .md\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .md\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .md\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .md\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .md\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .md\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .md\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .md\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .md\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-50:focus {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-100:focus {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-200:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-300:focus {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-400:focus {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-500:focus {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-600:focus {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-700:focus {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-800:focus {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .md\:focus\:border-cool-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .md\:focus\:border-red-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .md\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .md\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .md\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .md\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .md\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .md\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .md\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .md\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .md\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .md\:focus\:border-orange-50:focus {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .md\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .md\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .md\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .md\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .md\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .md\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .md\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .md\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .md\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-50:focus {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .md\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .md\:focus\:border-green-50:focus {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .md\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .md\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .md\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .md\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .md\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .md\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .md\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .md\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .md\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .md\:focus\:border-teal-50:focus {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .md\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .md\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .md\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .md\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .md\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .md\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .md\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .md\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .md\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .md\:focus\:border-blue-50:focus {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .md\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .md\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .md\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .md\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .md\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .md\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .md\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .md\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .md\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-50:focus {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .md\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .md\:focus\:border-purple-50:focus {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .md\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .md\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .md\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .md\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .md\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .md\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .md\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .md\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .md\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .md\:focus\:border-pink-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .md\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .md\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .md\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .md\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .md\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .md\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .md\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .md\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .md\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .md\:border-opacity-0 {
    --border-opacity: 0;
  }

  .md\:border-opacity-25 {
    --border-opacity: .25;
  }

  .md\:border-opacity-50 {
    --border-opacity: .5;
  }

  .md\:border-opacity-75 {
    --border-opacity: .75;
  }

  .md\:border-opacity-100 {
    --border-opacity: 1;
  }

  .md\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .md\:hover\:border-opacity-25:hover {
    --border-opacity: .25;
  }

  .md\:hover\:border-opacity-50:hover {
    --border-opacity: .5;
  }

  .md\:hover\:border-opacity-75:hover {
    --border-opacity: .75;
  }

  .md\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .md\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .md\:focus\:border-opacity-25:focus {
    --border-opacity: .25;
  }

  .md\:focus\:border-opacity-50:focus {
    --border-opacity: .5;
  }

  .md\:focus\:border-opacity-75:focus {
    --border-opacity: .75;
  }

  .md\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .md\:rounded-none {
    border-radius: 0;
  }

  .md\:rounded-sm {
    border-radius: .125rem;
  }

  .md\:rounded {
    border-radius: .25rem;
  }

  .md\:rounded-md {
    border-radius: .375rem;
  }

  .md\:rounded-lg {
    border-radius: .5rem;
  }

  .md\:rounded-xl {
    border-radius: .75rem;
  }

  .md\:rounded-2xl {
    border-radius: 1rem;
  }

  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .md\:rounded-full {
    border-radius: 9999px;
  }

  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:rounded-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .md\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .md\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .md\:rounded-b-sm {
    border-bottom-left-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .md\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .md\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .md\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .md\:rounded-b {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .md\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .md\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .md\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .md\:rounded-b-md {
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .md\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .md\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .md\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .md\:rounded-b-lg {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .md\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .md\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .md\:rounded-b-xl {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .md\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-b-2xl {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-b-3xl {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-b-full {
    border-bottom-left-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .md\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .md\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .md\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .md\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .md\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .md\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .md\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .md\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .md\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .md\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .md\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .md\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .md\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .md\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .md\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .md\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .md\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .md\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .md\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .md\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .md\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .md\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .md\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .md\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .md\:border-solid {
    border-style: solid;
  }

  .md\:border-dashed {
    border-style: dashed;
  }

  .md\:border-dotted {
    border-style: dotted;
  }

  .md\:border-double {
    border-style: double;
  }

  .md\:border-none {
    border-style: none;
  }

  .md\:border-0 {
    border-width: 0;
  }

  .md\:border-2 {
    border-width: 2px;
  }

  .md\:border-4 {
    border-width: 4px;
  }

  .md\:border-8 {
    border-width: 8px;
  }

  .md\:border {
    border-width: 1px;
  }

  .md\:border-t-0 {
    border-top-width: 0;
  }

  .md\:border-r-0 {
    border-right-width: 0;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-l-0 {
    border-left-width: 0;
  }

  .md\:border-t-2 {
    border-top-width: 2px;
  }

  .md\:border-r-2 {
    border-right-width: 2px;
  }

  .md\:border-b-2 {
    border-bottom-width: 2px;
  }

  .md\:border-l-2 {
    border-left-width: 2px;
  }

  .md\:border-t-4 {
    border-top-width: 4px;
  }

  .md\:border-r-4 {
    border-right-width: 4px;
  }

  .md\:border-b-4 {
    border-bottom-width: 4px;
  }

  .md\:border-l-4 {
    border-left-width: 4px;
  }

  .md\:border-t-8 {
    border-top-width: 8px;
  }

  .md\:border-r-8 {
    border-right-width: 8px;
  }

  .md\:border-b-8 {
    border-bottom-width: 8px;
  }

  .md\:border-l-8 {
    border-left-width: 8px;
  }

  .md\:border-t {
    border-top-width: 1px;
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:border-b {
    border-bottom-width: 1px;
  }

  .md\:border-l {
    border-left-width: 1px;
  }

  .md\:box-border {
    box-sizing: border-box;
  }

  .md\:box-content {
    box-sizing: content-box;
  }

  .md\:cursor-auto {
    cursor: auto;
  }

  .md\:cursor-default {
    cursor: default;
  }

  .md\:cursor-pointer {
    cursor: pointer;
  }

  .md\:cursor-wait {
    cursor: wait;
  }

  .md\:cursor-text {
    cursor: text;
  }

  .md\:cursor-move {
    cursor: move;
  }

  .md\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .md\:block {
    display: block;
  }

  .md\:inline-block {
    display: inline-block;
  }

  .md\:inline {
    display: inline;
  }

  .md\:flex {
    display: flex;
  }

  .md\:inline-flex {
    display: inline-flex;
  }

  .md\:table {
    display: table;
  }

  .md\:table-caption {
    display: table-caption;
  }

  .md\:table-cell {
    display: table-cell;
  }

  .md\:table-column {
    display: table-column;
  }

  .md\:table-column-group {
    display: table-column-group;
  }

  .md\:table-footer-group {
    display: table-footer-group;
  }

  .md\:table-header-group {
    display: table-header-group;
  }

  .md\:table-row-group {
    display: table-row-group;
  }

  .md\:table-row {
    display: table-row;
  }

  .md\:flow-root {
    display: flow-root;
  }

  .md\:grid {
    display: grid;
  }

  .md\:inline-grid {
    display: inline-grid;
  }

  .md\:contents {
    display: contents;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:flex-col {
    flex-direction: column;
  }

  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md\:flex-wrap {
    flex-wrap: wrap;
  }

  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md\:flex-no-wrap {
    flex-wrap: nowrap;
  }

  .md\:place-items-auto {
    place-items: auto;
  }

  .md\:place-items-start {
    place-items: start;
  }

  .md\:place-items-end {
    place-items: end;
  }

  .md\:place-items-center {
    place-items: center;
  }

  .md\:place-items-stretch {
    place-items: stretch stretch;
  }

  .md\:place-content-center {
    place-content: center;
  }

  .md\:place-content-start {
    place-content: start;
  }

  .md\:place-content-end {
    place-content: end;
  }

  .md\:place-content-between {
    place-content: space-between;
  }

  .md\:place-content-around {
    place-content: space-around;
  }

  .md\:place-content-evenly {
    place-content: space-evenly;
  }

  .md\:place-content-stretch {
    place-content: stretch;
  }

  .md\:place-self-auto {
    place-self: auto;
  }

  .md\:place-self-start {
    place-self: start;
  }

  .md\:place-self-end {
    place-self: end;
  }

  .md\:place-self-center {
    place-self: center;
  }

  .md\:place-self-stretch {
    place-self: stretch stretch;
  }

  .md\:items-start {
    align-items: flex-start;
  }

  .md\:items-end {
    align-items: flex-end;
  }

  .md\:items-center {
    align-items: center;
  }

  .md\:items-baseline {
    align-items: baseline;
  }

  .md\:items-stretch {
    align-items: stretch;
  }

  .md\:content-center {
    align-content: center;
  }

  .md\:content-start {
    align-content: flex-start;
  }

  .md\:content-end {
    align-content: flex-end;
  }

  .md\:content-between {
    align-content: space-between;
  }

  .md\:content-around {
    align-content: space-around;
  }

  .md\:content-evenly {
    align-content: space-evenly;
  }

  .md\:self-auto {
    align-self: auto;
  }

  .md\:self-start {
    align-self: flex-start;
  }

  .md\:self-end {
    align-self: flex-end;
  }

  .md\:self-center {
    align-self: center;
  }

  .md\:self-stretch {
    align-self: stretch;
  }

  .md\:justify-items-auto {
    justify-items: auto;
  }

  .md\:justify-items-start {
    justify-items: start;
  }

  .md\:justify-items-end {
    justify-items: end;
  }

  .md\:justify-items-center {
    justify-items: center;
  }

  .md\:justify-items-stretch {
    justify-items: stretch;
  }

  .md\:justify-start {
    justify-content: flex-start;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:justify-center {
    justify-content: center;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:justify-around {
    justify-content: space-around;
  }

  .md\:justify-evenly {
    justify-content: space-evenly;
  }

  .md\:justify-self-auto {
    justify-self: auto;
  }

  .md\:justify-self-start {
    justify-self: start;
  }

  .md\:justify-self-end {
    justify-self: end;
  }

  .md\:justify-self-center {
    justify-self: center;
  }

  .md\:justify-self-stretch {
    justify-self: stretch;
  }

  .md\:flex-1 {
    flex: 1;
  }

  .md\:flex-auto {
    flex: auto;
  }

  .md\:flex-initial {
    flex: 0 auto;
  }

  .md\:flex-none {
    flex: none;
  }

  .md\:flex-grow-0 {
    flex-grow: 0;
  }

  .md\:flex-grow {
    flex-grow: 1;
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .md\:flex-shrink {
    flex-shrink: 1;
  }

  .md\:order-1 {
    order: 1;
  }

  .md\:order-2 {
    order: 2;
  }

  .md\:order-3 {
    order: 3;
  }

  .md\:order-4 {
    order: 4;
  }

  .md\:order-5 {
    order: 5;
  }

  .md\:order-6 {
    order: 6;
  }

  .md\:order-7 {
    order: 7;
  }

  .md\:order-8 {
    order: 8;
  }

  .md\:order-9 {
    order: 9;
  }

  .md\:order-10 {
    order: 10;
  }

  .md\:order-11 {
    order: 11;
  }

  .md\:order-12 {
    order: 12;
  }

  .md\:order-first {
    order: -9999;
  }

  .md\:order-last {
    order: 9999;
  }

  .md\:order-none {
    order: 0;
  }

  .md\:float-right {
    float: right;
  }

  .md\:float-left {
    float: left;
  }

  .md\:float-none {
    float: none;
  }

  .md\:clearfix:after {
    content: "";
    clear: both;
    display: table;
  }

  .md\:clear-left {
    clear: left;
  }

  .md\:clear-right {
    clear: right;
  }

  .md\:clear-both {
    clear: both;
  }

  .md\:clear-none {
    clear: none;
  }

  .md\:font-sans {
    font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .md\:font-serif {
    font-family: Georgia, Cambria, Times New Roman, Times, serif;
  }

  .md\:font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .md\:font-ibmmono {
    font-family: IBM Plex Mono;
  }

  .md\:font-hairline {
    font-weight: 100;
  }

  .md\:font-thin {
    font-weight: 200;
  }

  .md\:font-light {
    font-weight: 300;
  }

  .md\:font-normal {
    font-weight: 400;
  }

  .md\:font-medium {
    font-weight: 500;
  }

  .md\:font-semibold {
    font-weight: 600;
  }

  .md\:font-bold {
    font-weight: 700;
  }

  .md\:font-extrabold {
    font-weight: 800;
  }

  .md\:font-black {
    font-weight: 900;
  }

  .md\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .md\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .md\:hover\:font-light:hover {
    font-weight: 300;
  }

  .md\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .md\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .md\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .md\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .md\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .md\:hover\:font-black:hover {
    font-weight: 900;
  }

  .md\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .md\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .md\:focus\:font-light:focus {
    font-weight: 300;
  }

  .md\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .md\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .md\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .md\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .md\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .md\:focus\:font-black:focus {
    font-weight: 900;
  }

  .md\:h-0 {
    height: 0;
  }

  .md\:h-1 {
    height: .25rem;
  }

  .md\:h-2 {
    height: .5rem;
  }

  .md\:h-3 {
    height: .75rem;
  }

  .md\:h-4 {
    height: 1rem;
  }

  .md\:h-5 {
    height: 1.25rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:h-7 {
    height: 1.75rem;
  }

  .md\:h-8 {
    height: 2rem;
  }

  .md\:h-9 {
    height: 2.25rem;
  }

  .md\:h-10 {
    height: 2.5rem;
  }

  .md\:h-11 {
    height: 2.75rem;
  }

  .md\:h-12 {
    height: 3rem;
  }

  .md\:h-13 {
    height: 3.25rem;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:h-15 {
    height: 3.75rem;
  }

  .md\:h-16 {
    height: 4rem;
  }

  .md\:h-20 {
    height: 5rem;
  }

  .md\:h-24 {
    height: 6rem;
  }

  .md\:h-28 {
    height: 7rem;
  }

  .md\:h-32 {
    height: 8rem;
  }

  .md\:h-36 {
    height: 9rem;
  }

  .md\:h-40 {
    height: 10rem;
  }

  .md\:h-44 {
    height: 11rem;
  }

  .md\:h-48 {
    height: 12rem;
  }

  .md\:h-52 {
    height: 13rem;
  }

  .md\:h-56 {
    height: 14rem;
  }

  .md\:h-60 {
    height: 15rem;
  }

  .md\:h-64 {
    height: 16rem;
  }

  .md\:h-72 {
    height: 18rem;
  }

  .md\:h-80 {
    height: 20rem;
  }

  .md\:h-96 {
    height: 24rem;
  }

  .md\:h-auto {
    height: auto;
  }

  .md\:h-px {
    height: 1px;
  }

  .md\:h-0\.5 {
    height: .125rem;
  }

  .md\:h-1\.5 {
    height: .375rem;
  }

  .md\:h-2\.5 {
    height: .625rem;
  }

  .md\:h-3\.5 {
    height: .875rem;
  }

  .md\:h-1\/2 {
    height: 50%;
  }

  .md\:h-1\/3 {
    height: 33.3333%;
  }

  .md\:h-2\/3 {
    height: 66.6667%;
  }

  .md\:h-1\/4 {
    height: 25%;
  }

  .md\:h-2\/4 {
    height: 50%;
  }

  .md\:h-3\/4 {
    height: 75%;
  }

  .md\:h-1\/5 {
    height: 20%;
  }

  .md\:h-2\/5 {
    height: 40%;
  }

  .md\:h-3\/5 {
    height: 60%;
  }

  .md\:h-4\/5 {
    height: 80%;
  }

  .md\:h-1\/6 {
    height: 16.6667%;
  }

  .md\:h-2\/6 {
    height: 33.3333%;
  }

  .md\:h-3\/6 {
    height: 50%;
  }

  .md\:h-4\/6 {
    height: 66.6667%;
  }

  .md\:h-5\/6 {
    height: 83.3333%;
  }

  .md\:h-1\/12 {
    height: 8.33333%;
  }

  .md\:h-2\/12 {
    height: 16.6667%;
  }

  .md\:h-3\/12 {
    height: 25%;
  }

  .md\:h-4\/12 {
    height: 33.3333%;
  }

  .md\:h-5\/12 {
    height: 41.6667%;
  }

  .md\:h-6\/12 {
    height: 50%;
  }

  .md\:h-7\/12 {
    height: 58.3333%;
  }

  .md\:h-8\/12 {
    height: 66.6667%;
  }

  .md\:h-9\/12 {
    height: 75%;
  }

  .md\:h-10\/12 {
    height: 83.3333%;
  }

  .md\:h-11\/12 {
    height: 91.6667%;
  }

  .md\:h-full {
    height: 100%;
  }

  .md\:h-screen {
    height: 100vh;
  }

  .md\:text-xs {
    font-size: .75rem;
  }

  .md\:text-sm {
    font-size: .875rem;
  }

  .md\:text-base {
    font-size: 1rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
  }

  .md\:text-xl {
    font-size: 1.25rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
  }

  .md\:text-3xl {
    font-size: 1.875rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
  }

  .md\:text-6xl {
    font-size: 4rem;
  }

  .md\:leading-3 {
    line-height: .75rem;
  }

  .md\:leading-4 {
    line-height: 1rem;
  }

  .md\:leading-5 {
    line-height: 1.25rem;
  }

  .md\:leading-6 {
    line-height: 1.5rem;
  }

  .md\:leading-7 {
    line-height: 1.75rem;
  }

  .md\:leading-8 {
    line-height: 2rem;
  }

  .md\:leading-9 {
    line-height: 2.25rem;
  }

  .md\:leading-10 {
    line-height: 2.5rem;
  }

  .md\:leading-none {
    line-height: 1;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }

  .md\:leading-snug {
    line-height: 1.375;
  }

  .md\:leading-normal {
    line-height: 1.5;
  }

  .md\:leading-relaxed {
    line-height: 1.625;
  }

  .md\:leading-loose {
    line-height: 2;
  }

  .md\:list-inside {
    list-style-position: inside;
  }

  .md\:list-outside {
    list-style-position: outside;
  }

  .md\:list-none {
    list-style-type: none;
  }

  .md\:list-disc {
    list-style-type: disc;
  }

  .md\:list-decimal {
    list-style-type: decimal;
  }

  .md\:m-0 {
    margin: 0;
  }

  .md\:m-1 {
    margin: .25rem;
  }

  .md\:m-2 {
    margin: .5rem;
  }

  .md\:m-3 {
    margin: .75rem;
  }

  .md\:m-4 {
    margin: 1rem;
  }

  .md\:m-5 {
    margin: 1.25rem;
  }

  .md\:m-6 {
    margin: 1.5rem;
  }

  .md\:m-7 {
    margin: 1.75rem;
  }

  .md\:m-8 {
    margin: 2rem;
  }

  .md\:m-9 {
    margin: 2.25rem;
  }

  .md\:m-10 {
    margin: 2.5rem;
  }

  .md\:m-11 {
    margin: 2.75rem;
  }

  .md\:m-12 {
    margin: 3rem;
  }

  .md\:m-13 {
    margin: 3.25rem;
  }

  .md\:m-14 {
    margin: 3.5rem;
  }

  .md\:m-15 {
    margin: 3.75rem;
  }

  .md\:m-16 {
    margin: 4rem;
  }

  .md\:m-20 {
    margin: 5rem;
  }

  .md\:m-24 {
    margin: 6rem;
  }

  .md\:m-28 {
    margin: 7rem;
  }

  .md\:m-32 {
    margin: 8rem;
  }

  .md\:m-36 {
    margin: 9rem;
  }

  .md\:m-40 {
    margin: 10rem;
  }

  .md\:m-44 {
    margin: 11rem;
  }

  .md\:m-48 {
    margin: 12rem;
  }

  .md\:m-52 {
    margin: 13rem;
  }

  .md\:m-56 {
    margin: 14rem;
  }

  .md\:m-60 {
    margin: 15rem;
  }

  .md\:m-64 {
    margin: 16rem;
  }

  .md\:m-72 {
    margin: 18rem;
  }

  .md\:m-80 {
    margin: 20rem;
  }

  .md\:m-96 {
    margin: 24rem;
  }

  .md\:m-auto {
    margin: auto;
  }

  .md\:m-px {
    margin: 1px;
  }

  .md\:m-0\.5 {
    margin: .125rem;
  }

  .md\:m-1\.5 {
    margin: .375rem;
  }

  .md\:m-2\.5 {
    margin: .625rem;
  }

  .md\:m-3\.5 {
    margin: .875rem;
  }

  .md\:m-1\/2 {
    margin: 50%;
  }

  .md\:m-1\/3 {
    margin: 33.3333%;
  }

  .md\:m-2\/3 {
    margin: 66.6667%;
  }

  .md\:m-1\/4 {
    margin: 25%;
  }

  .md\:m-2\/4 {
    margin: 50%;
  }

  .md\:m-3\/4 {
    margin: 75%;
  }

  .md\:m-1\/5 {
    margin: 20%;
  }

  .md\:m-2\/5 {
    margin: 40%;
  }

  .md\:m-3\/5 {
    margin: 60%;
  }

  .md\:m-4\/5 {
    margin: 80%;
  }

  .md\:m-1\/6 {
    margin: 16.6667%;
  }

  .md\:m-2\/6 {
    margin: 33.3333%;
  }

  .md\:m-3\/6 {
    margin: 50%;
  }

  .md\:m-4\/6 {
    margin: 66.6667%;
  }

  .md\:m-5\/6 {
    margin: 83.3333%;
  }

  .md\:m-1\/12 {
    margin: 8.33333%;
  }

  .md\:m-2\/12 {
    margin: 16.6667%;
  }

  .md\:m-3\/12 {
    margin: 25%;
  }

  .md\:m-4\/12 {
    margin: 33.3333%;
  }

  .md\:m-5\/12 {
    margin: 41.6667%;
  }

  .md\:m-6\/12 {
    margin: 50%;
  }

  .md\:m-7\/12 {
    margin: 58.3333%;
  }

  .md\:m-8\/12 {
    margin: 66.6667%;
  }

  .md\:m-9\/12 {
    margin: 75%;
  }

  .md\:m-10\/12 {
    margin: 83.3333%;
  }

  .md\:m-11\/12 {
    margin: 91.6667%;
  }

  .md\:m-full {
    margin: 100%;
  }

  .md\:-m-1 {
    margin: -.25rem;
  }

  .md\:-m-2 {
    margin: -.5rem;
  }

  .md\:-m-3 {
    margin: -.75rem;
  }

  .md\:-m-4 {
    margin: -1rem;
  }

  .md\:-m-5 {
    margin: -1.25rem;
  }

  .md\:-m-6 {
    margin: -1.5rem;
  }

  .md\:-m-7 {
    margin: -1.75rem;
  }

  .md\:-m-8 {
    margin: -2rem;
  }

  .md\:-m-9 {
    margin: -2.25rem;
  }

  .md\:-m-10 {
    margin: -2.5rem;
  }

  .md\:-m-11 {
    margin: -2.75rem;
  }

  .md\:-m-12 {
    margin: -3rem;
  }

  .md\:-m-13 {
    margin: -3.25rem;
  }

  .md\:-m-14 {
    margin: -3.5rem;
  }

  .md\:-m-15 {
    margin: -3.75rem;
  }

  .md\:-m-16 {
    margin: -4rem;
  }

  .md\:-m-20 {
    margin: -5rem;
  }

  .md\:-m-24 {
    margin: -6rem;
  }

  .md\:-m-28 {
    margin: -7rem;
  }

  .md\:-m-32 {
    margin: -8rem;
  }

  .md\:-m-36 {
    margin: -9rem;
  }

  .md\:-m-40 {
    margin: -10rem;
  }

  .md\:-m-44 {
    margin: -11rem;
  }

  .md\:-m-48 {
    margin: -12rem;
  }

  .md\:-m-52 {
    margin: -13rem;
  }

  .md\:-m-56 {
    margin: -14rem;
  }

  .md\:-m-60 {
    margin: -15rem;
  }

  .md\:-m-64 {
    margin: -16rem;
  }

  .md\:-m-72 {
    margin: -18rem;
  }

  .md\:-m-80 {
    margin: -20rem;
  }

  .md\:-m-96 {
    margin: -24rem;
  }

  .md\:-m-px {
    margin: -1px;
  }

  .md\:-m-0\.5 {
    margin: -.125rem;
  }

  .md\:-m-1\.5 {
    margin: -.375rem;
  }

  .md\:-m-2\.5 {
    margin: -.625rem;
  }

  .md\:-m-3\.5 {
    margin: -.875rem;
  }

  .md\:-m-1\/2 {
    margin: -50%;
  }

  .md\:-m-1\/3 {
    margin: -33.3333%;
  }

  .md\:-m-2\/3 {
    margin: -66.6667%;
  }

  .md\:-m-1\/4 {
    margin: -25%;
  }

  .md\:-m-2\/4 {
    margin: -50%;
  }

  .md\:-m-3\/4 {
    margin: -75%;
  }

  .md\:-m-1\/5 {
    margin: -20%;
  }

  .md\:-m-2\/5 {
    margin: -40%;
  }

  .md\:-m-3\/5 {
    margin: -60%;
  }

  .md\:-m-4\/5 {
    margin: -80%;
  }

  .md\:-m-1\/6 {
    margin: -16.6667%;
  }

  .md\:-m-2\/6 {
    margin: -33.3333%;
  }

  .md\:-m-3\/6 {
    margin: -50%;
  }

  .md\:-m-4\/6 {
    margin: -66.6667%;
  }

  .md\:-m-5\/6 {
    margin: -83.3333%;
  }

  .md\:-m-1\/12 {
    margin: -8.33333%;
  }

  .md\:-m-2\/12 {
    margin: -16.6667%;
  }

  .md\:-m-3\/12 {
    margin: -25%;
  }

  .md\:-m-4\/12 {
    margin: -33.3333%;
  }

  .md\:-m-5\/12 {
    margin: -41.6667%;
  }

  .md\:-m-6\/12 {
    margin: -50%;
  }

  .md\:-m-7\/12 {
    margin: -58.3333%;
  }

  .md\:-m-8\/12 {
    margin: -66.6667%;
  }

  .md\:-m-9\/12 {
    margin: -75%;
  }

  .md\:-m-10\/12 {
    margin: -83.3333%;
  }

  .md\:-m-11\/12 {
    margin: -91.6667%;
  }

  .md\:-m-full {
    margin: -100%;
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .md\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .md\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .md\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .md\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .md\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .md\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .md\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .md\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .md\:my-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem;
  }

  .md\:mx-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem;
  }

  .md\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .md\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .md\:my-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .md\:mx-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .md\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .md\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .md\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .md\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .md\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .md\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .md\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .md\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .md\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .md\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .md\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .md\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .md\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .md\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .md\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .md\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .md\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .md\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .md\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .md\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .md\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .md\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .md\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .md\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .md\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .md\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .md\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .md\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .md\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .md\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .md\:my-1\/2 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .md\:mx-1\/2 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .md\:my-1\/3 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .md\:mx-1\/3 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .md\:my-2\/3 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .md\:mx-2\/3 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .md\:my-1\/4 {
    margin-top: 25%;
    margin-bottom: 25%;
  }

  .md\:mx-1\/4 {
    margin-left: 25%;
    margin-right: 25%;
  }

  .md\:my-2\/4 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .md\:mx-2\/4 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .md\:my-3\/4 {
    margin-top: 75%;
    margin-bottom: 75%;
  }

  .md\:mx-3\/4 {
    margin-left: 75%;
    margin-right: 75%;
  }

  .md\:my-1\/5 {
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .md\:mx-1\/5 {
    margin-left: 20%;
    margin-right: 20%;
  }

  .md\:my-2\/5 {
    margin-top: 40%;
    margin-bottom: 40%;
  }

  .md\:mx-2\/5 {
    margin-left: 40%;
    margin-right: 40%;
  }

  .md\:my-3\/5 {
    margin-top: 60%;
    margin-bottom: 60%;
  }

  .md\:mx-3\/5 {
    margin-left: 60%;
    margin-right: 60%;
  }

  .md\:my-4\/5 {
    margin-top: 80%;
    margin-bottom: 80%;
  }

  .md\:mx-4\/5 {
    margin-left: 80%;
    margin-right: 80%;
  }

  .md\:my-1\/6 {
    margin-top: 16.6667%;
    margin-bottom: 16.6667%;
  }

  .md\:mx-1\/6 {
    margin-left: 16.6667%;
    margin-right: 16.6667%;
  }

  .md\:my-2\/6 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .md\:mx-2\/6 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .md\:my-3\/6 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .md\:mx-3\/6 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .md\:my-4\/6 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .md\:mx-4\/6 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .md\:my-5\/6 {
    margin-top: 83.3333%;
    margin-bottom: 83.3333%;
  }

  .md\:mx-5\/6 {
    margin-left: 83.3333%;
    margin-right: 83.3333%;
  }

  .md\:my-1\/12 {
    margin-top: 8.33333%;
    margin-bottom: 8.33333%;
  }

  .md\:mx-1\/12 {
    margin-left: 8.33333%;
    margin-right: 8.33333%;
  }

  .md\:my-2\/12 {
    margin-top: 16.6667%;
    margin-bottom: 16.6667%;
  }

  .md\:mx-2\/12 {
    margin-left: 16.6667%;
    margin-right: 16.6667%;
  }

  .md\:my-3\/12 {
    margin-top: 25%;
    margin-bottom: 25%;
  }

  .md\:mx-3\/12 {
    margin-left: 25%;
    margin-right: 25%;
  }

  .md\:my-4\/12 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .md\:mx-4\/12 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .md\:my-5\/12 {
    margin-top: 41.6667%;
    margin-bottom: 41.6667%;
  }

  .md\:mx-5\/12 {
    margin-left: 41.6667%;
    margin-right: 41.6667%;
  }

  .md\:my-6\/12 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .md\:mx-6\/12 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .md\:my-7\/12 {
    margin-top: 58.3333%;
    margin-bottom: 58.3333%;
  }

  .md\:mx-7\/12 {
    margin-left: 58.3333%;
    margin-right: 58.3333%;
  }

  .md\:my-8\/12 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .md\:mx-8\/12 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .md\:my-9\/12 {
    margin-top: 75%;
    margin-bottom: 75%;
  }

  .md\:mx-9\/12 {
    margin-left: 75%;
    margin-right: 75%;
  }

  .md\:my-10\/12 {
    margin-top: 83.3333%;
    margin-bottom: 83.3333%;
  }

  .md\:mx-10\/12 {
    margin-left: 83.3333%;
    margin-right: 83.3333%;
  }

  .md\:my-11\/12 {
    margin-top: 91.6667%;
    margin-bottom: 91.6667%;
  }

  .md\:mx-11\/12 {
    margin-left: 91.6667%;
    margin-right: 91.6667%;
  }

  .md\:my-full {
    margin-top: 100%;
    margin-bottom: 100%;
  }

  .md\:mx-full {
    margin-left: 100%;
    margin-right: 100%;
  }

  .md\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .md\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .md\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .md\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .md\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .md\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .md\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .md\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .md\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .md\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .md\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .md\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .md\:-my-13 {
    margin-top: -3.25rem;
    margin-bottom: -3.25rem;
  }

  .md\:-mx-13 {
    margin-left: -3.25rem;
    margin-right: -3.25rem;
  }

  .md\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .md\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .md\:-my-15 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }

  .md\:-mx-15 {
    margin-left: -3.75rem;
    margin-right: -3.75rem;
  }

  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .md\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .md\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .md\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .md\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .md\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .md\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .md\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .md\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .md\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .md\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .md\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .md\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .md\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .md\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .md\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .md\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .md\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .md\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .md\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .md\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .md\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .md\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .md\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .md\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .md\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .md\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .md\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .md\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .md\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .md\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .md\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .md\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .md\:-my-1\/2 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .md\:-mx-1\/2 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .md\:-my-1\/3 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .md\:-mx-1\/3 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .md\:-my-2\/3 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .md\:-mx-2\/3 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .md\:-my-1\/4 {
    margin-top: -25%;
    margin-bottom: -25%;
  }

  .md\:-mx-1\/4 {
    margin-left: -25%;
    margin-right: -25%;
  }

  .md\:-my-2\/4 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .md\:-mx-2\/4 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .md\:-my-3\/4 {
    margin-top: -75%;
    margin-bottom: -75%;
  }

  .md\:-mx-3\/4 {
    margin-left: -75%;
    margin-right: -75%;
  }

  .md\:-my-1\/5 {
    margin-top: -20%;
    margin-bottom: -20%;
  }

  .md\:-mx-1\/5 {
    margin-left: -20%;
    margin-right: -20%;
  }

  .md\:-my-2\/5 {
    margin-top: -40%;
    margin-bottom: -40%;
  }

  .md\:-mx-2\/5 {
    margin-left: -40%;
    margin-right: -40%;
  }

  .md\:-my-3\/5 {
    margin-top: -60%;
    margin-bottom: -60%;
  }

  .md\:-mx-3\/5 {
    margin-left: -60%;
    margin-right: -60%;
  }

  .md\:-my-4\/5 {
    margin-top: -80%;
    margin-bottom: -80%;
  }

  .md\:-mx-4\/5 {
    margin-left: -80%;
    margin-right: -80%;
  }

  .md\:-my-1\/6 {
    margin-top: -16.6667%;
    margin-bottom: -16.6667%;
  }

  .md\:-mx-1\/6 {
    margin-left: -16.6667%;
    margin-right: -16.6667%;
  }

  .md\:-my-2\/6 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .md\:-mx-2\/6 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .md\:-my-3\/6 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .md\:-mx-3\/6 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .md\:-my-4\/6 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .md\:-mx-4\/6 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .md\:-my-5\/6 {
    margin-top: -83.3333%;
    margin-bottom: -83.3333%;
  }

  .md\:-mx-5\/6 {
    margin-left: -83.3333%;
    margin-right: -83.3333%;
  }

  .md\:-my-1\/12 {
    margin-top: -8.33333%;
    margin-bottom: -8.33333%;
  }

  .md\:-mx-1\/12 {
    margin-left: -8.33333%;
    margin-right: -8.33333%;
  }

  .md\:-my-2\/12 {
    margin-top: -16.6667%;
    margin-bottom: -16.6667%;
  }

  .md\:-mx-2\/12 {
    margin-left: -16.6667%;
    margin-right: -16.6667%;
  }

  .md\:-my-3\/12 {
    margin-top: -25%;
    margin-bottom: -25%;
  }

  .md\:-mx-3\/12 {
    margin-left: -25%;
    margin-right: -25%;
  }

  .md\:-my-4\/12 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .md\:-mx-4\/12 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .md\:-my-5\/12 {
    margin-top: -41.6667%;
    margin-bottom: -41.6667%;
  }

  .md\:-mx-5\/12 {
    margin-left: -41.6667%;
    margin-right: -41.6667%;
  }

  .md\:-my-6\/12 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .md\:-mx-6\/12 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .md\:-my-7\/12 {
    margin-top: -58.3333%;
    margin-bottom: -58.3333%;
  }

  .md\:-mx-7\/12 {
    margin-left: -58.3333%;
    margin-right: -58.3333%;
  }

  .md\:-my-8\/12 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .md\:-mx-8\/12 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .md\:-my-9\/12 {
    margin-top: -75%;
    margin-bottom: -75%;
  }

  .md\:-mx-9\/12 {
    margin-left: -75%;
    margin-right: -75%;
  }

  .md\:-my-10\/12 {
    margin-top: -83.3333%;
    margin-bottom: -83.3333%;
  }

  .md\:-mx-10\/12 {
    margin-left: -83.3333%;
    margin-right: -83.3333%;
  }

  .md\:-my-11\/12 {
    margin-top: -91.6667%;
    margin-bottom: -91.6667%;
  }

  .md\:-mx-11\/12 {
    margin-left: -91.6667%;
    margin-right: -91.6667%;
  }

  .md\:-my-full {
    margin-top: -100%;
    margin-bottom: -100%;
  }

  .md\:-mx-full {
    margin-left: -100%;
    margin-right: -100%;
  }

  .md\:mt-0 {
    margin-top: 0;
  }

  .md\:mr-0 {
    margin-right: 0;
  }

  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:ml-0 {
    margin-left: 0;
  }

  .md\:mt-1 {
    margin-top: .25rem;
  }

  .md\:mr-1 {
    margin-right: .25rem;
  }

  .md\:mb-1 {
    margin-bottom: .25rem;
  }

  .md\:ml-1 {
    margin-left: .25rem;
  }

  .md\:mt-2 {
    margin-top: .5rem;
  }

  .md\:mr-2 {
    margin-right: .5rem;
  }

  .md\:mb-2 {
    margin-bottom: .5rem;
  }

  .md\:ml-2 {
    margin-left: .5rem;
  }

  .md\:mt-3 {
    margin-top: .75rem;
  }

  .md\:mr-3 {
    margin-right: .75rem;
  }

  .md\:mb-3 {
    margin-bottom: .75rem;
  }

  .md\:ml-3 {
    margin-left: .75rem;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mr-4 {
    margin-right: 1rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:mt-5 {
    margin-top: 1.25rem;
  }

  .md\:mr-5 {
    margin-right: 1.25rem;
  }

  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:ml-5 {
    margin-left: 1.25rem;
  }

  .md\:mt-6 {
    margin-top: 1.5rem;
  }

  .md\:mr-6 {
    margin-right: 1.5rem;
  }

  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:ml-6 {
    margin-left: 1.5rem;
  }

  .md\:mt-7 {
    margin-top: 1.75rem;
  }

  .md\:mr-7 {
    margin-right: 1.75rem;
  }

  .md\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .md\:ml-7 {
    margin-left: 1.75rem;
  }

  .md\:mt-8 {
    margin-top: 2rem;
  }

  .md\:mr-8 {
    margin-right: 2rem;
  }

  .md\:mb-8 {
    margin-bottom: 2rem;
  }

  .md\:ml-8 {
    margin-left: 2rem;
  }

  .md\:mt-9 {
    margin-top: 2.25rem;
  }

  .md\:mr-9 {
    margin-right: 2.25rem;
  }

  .md\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .md\:ml-9 {
    margin-left: 2.25rem;
  }

  .md\:mt-10 {
    margin-top: 2.5rem;
  }

  .md\:mr-10 {
    margin-right: 2.5rem;
  }

  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:ml-10 {
    margin-left: 2.5rem;
  }

  .md\:mt-11 {
    margin-top: 2.75rem;
  }

  .md\:mr-11 {
    margin-right: 2.75rem;
  }

  .md\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .md\:ml-11 {
    margin-left: 2.75rem;
  }

  .md\:mt-12 {
    margin-top: 3rem;
  }

  .md\:mr-12 {
    margin-right: 3rem;
  }

  .md\:mb-12 {
    margin-bottom: 3rem;
  }

  .md\:ml-12 {
    margin-left: 3rem;
  }

  .md\:mt-13 {
    margin-top: 3.25rem;
  }

  .md\:mr-13 {
    margin-right: 3.25rem;
  }

  .md\:mb-13 {
    margin-bottom: 3.25rem;
  }

  .md\:ml-13 {
    margin-left: 3.25rem;
  }

  .md\:mt-14 {
    margin-top: 3.5rem;
  }

  .md\:mr-14 {
    margin-right: 3.5rem;
  }

  .md\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .md\:ml-14 {
    margin-left: 3.5rem;
  }

  .md\:mt-15 {
    margin-top: 3.75rem;
  }

  .md\:mr-15 {
    margin-right: 3.75rem;
  }

  .md\:mb-15 {
    margin-bottom: 3.75rem;
  }

  .md\:ml-15 {
    margin-left: 3.75rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mr-16 {
    margin-right: 4rem;
  }

  .md\:mb-16 {
    margin-bottom: 4rem;
  }

  .md\:ml-16 {
    margin-left: 4rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:mr-20 {
    margin-right: 5rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:ml-20 {
    margin-left: 5rem;
  }

  .md\:mt-24 {
    margin-top: 6rem;
  }

  .md\:mr-24 {
    margin-right: 6rem;
  }

  .md\:mb-24 {
    margin-bottom: 6rem;
  }

  .md\:ml-24 {
    margin-left: 6rem;
  }

  .md\:mt-28 {
    margin-top: 7rem;
  }

  .md\:mr-28 {
    margin-right: 7rem;
  }

  .md\:mb-28 {
    margin-bottom: 7rem;
  }

  .md\:ml-28 {
    margin-left: 7rem;
  }

  .md\:mt-32 {
    margin-top: 8rem;
  }

  .md\:mr-32 {
    margin-right: 8rem;
  }

  .md\:mb-32 {
    margin-bottom: 8rem;
  }

  .md\:ml-32 {
    margin-left: 8rem;
  }

  .md\:mt-36 {
    margin-top: 9rem;
  }

  .md\:mr-36 {
    margin-right: 9rem;
  }

  .md\:mb-36 {
    margin-bottom: 9rem;
  }

  .md\:ml-36 {
    margin-left: 9rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mr-40 {
    margin-right: 10rem;
  }

  .md\:mb-40 {
    margin-bottom: 10rem;
  }

  .md\:ml-40 {
    margin-left: 10rem;
  }

  .md\:mt-44 {
    margin-top: 11rem;
  }

  .md\:mr-44 {
    margin-right: 11rem;
  }

  .md\:mb-44 {
    margin-bottom: 11rem;
  }

  .md\:ml-44 {
    margin-left: 11rem;
  }

  .md\:mt-48 {
    margin-top: 12rem;
  }

  .md\:mr-48 {
    margin-right: 12rem;
  }

  .md\:mb-48 {
    margin-bottom: 12rem;
  }

  .md\:ml-48 {
    margin-left: 12rem;
  }

  .md\:mt-52 {
    margin-top: 13rem;
  }

  .md\:mr-52 {
    margin-right: 13rem;
  }

  .md\:mb-52 {
    margin-bottom: 13rem;
  }

  .md\:ml-52 {
    margin-left: 13rem;
  }

  .md\:mt-56 {
    margin-top: 14rem;
  }

  .md\:mr-56 {
    margin-right: 14rem;
  }

  .md\:mb-56 {
    margin-bottom: 14rem;
  }

  .md\:ml-56 {
    margin-left: 14rem;
  }

  .md\:mt-60 {
    margin-top: 15rem;
  }

  .md\:mr-60 {
    margin-right: 15rem;
  }

  .md\:mb-60 {
    margin-bottom: 15rem;
  }

  .md\:ml-60 {
    margin-left: 15rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:mr-64 {
    margin-right: 16rem;
  }

  .md\:mb-64 {
    margin-bottom: 16rem;
  }

  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:mt-72 {
    margin-top: 18rem;
  }

  .md\:mr-72 {
    margin-right: 18rem;
  }

  .md\:mb-72 {
    margin-bottom: 18rem;
  }

  .md\:ml-72 {
    margin-left: 18rem;
  }

  .md\:mt-80 {
    margin-top: 20rem;
  }

  .md\:mr-80 {
    margin-right: 20rem;
  }

  .md\:mb-80 {
    margin-bottom: 20rem;
  }

  .md\:ml-80 {
    margin-left: 20rem;
  }

  .md\:mt-96 {
    margin-top: 24rem;
  }

  .md\:mr-96 {
    margin-right: 24rem;
  }

  .md\:mb-96 {
    margin-bottom: 24rem;
  }

  .md\:ml-96 {
    margin-left: 24rem;
  }

  .md\:mt-auto {
    margin-top: auto;
  }

  .md\:mr-auto {
    margin-right: auto;
  }

  .md\:mb-auto {
    margin-bottom: auto;
  }

  .md\:ml-auto {
    margin-left: auto;
  }

  .md\:mt-px {
    margin-top: 1px;
  }

  .md\:mr-px {
    margin-right: 1px;
  }

  .md\:mb-px {
    margin-bottom: 1px;
  }

  .md\:ml-px {
    margin-left: 1px;
  }

  .md\:mt-0\.5 {
    margin-top: .125rem;
  }

  .md\:mr-0\.5 {
    margin-right: .125rem;
  }

  .md\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .md\:ml-0\.5 {
    margin-left: .125rem;
  }

  .md\:mt-1\.5 {
    margin-top: .375rem;
  }

  .md\:mr-1\.5 {
    margin-right: .375rem;
  }

  .md\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .md\:ml-1\.5 {
    margin-left: .375rem;
  }

  .md\:mt-2\.5 {
    margin-top: .625rem;
  }

  .md\:mr-2\.5 {
    margin-right: .625rem;
  }

  .md\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .md\:ml-2\.5 {
    margin-left: .625rem;
  }

  .md\:mt-3\.5 {
    margin-top: .875rem;
  }

  .md\:mr-3\.5 {
    margin-right: .875rem;
  }

  .md\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .md\:ml-3\.5 {
    margin-left: .875rem;
  }

  .md\:mt-1\/2 {
    margin-top: 50%;
  }

  .md\:mr-1\/2 {
    margin-right: 50%;
  }

  .md\:mb-1\/2 {
    margin-bottom: 50%;
  }

  .md\:ml-1\/2 {
    margin-left: 50%;
  }

  .md\:mt-1\/3 {
    margin-top: 33.3333%;
  }

  .md\:mr-1\/3 {
    margin-right: 33.3333%;
  }

  .md\:mb-1\/3 {
    margin-bottom: 33.3333%;
  }

  .md\:ml-1\/3 {
    margin-left: 33.3333%;
  }

  .md\:mt-2\/3 {
    margin-top: 66.6667%;
  }

  .md\:mr-2\/3 {
    margin-right: 66.6667%;
  }

  .md\:mb-2\/3 {
    margin-bottom: 66.6667%;
  }

  .md\:ml-2\/3 {
    margin-left: 66.6667%;
  }

  .md\:mt-1\/4 {
    margin-top: 25%;
  }

  .md\:mr-1\/4 {
    margin-right: 25%;
  }

  .md\:mb-1\/4 {
    margin-bottom: 25%;
  }

  .md\:ml-1\/4 {
    margin-left: 25%;
  }

  .md\:mt-2\/4 {
    margin-top: 50%;
  }

  .md\:mr-2\/4 {
    margin-right: 50%;
  }

  .md\:mb-2\/4 {
    margin-bottom: 50%;
  }

  .md\:ml-2\/4 {
    margin-left: 50%;
  }

  .md\:mt-3\/4 {
    margin-top: 75%;
  }

  .md\:mr-3\/4 {
    margin-right: 75%;
  }

  .md\:mb-3\/4 {
    margin-bottom: 75%;
  }

  .md\:ml-3\/4 {
    margin-left: 75%;
  }

  .md\:mt-1\/5 {
    margin-top: 20%;
  }

  .md\:mr-1\/5 {
    margin-right: 20%;
  }

  .md\:mb-1\/5 {
    margin-bottom: 20%;
  }

  .md\:ml-1\/5 {
    margin-left: 20%;
  }

  .md\:mt-2\/5 {
    margin-top: 40%;
  }

  .md\:mr-2\/5 {
    margin-right: 40%;
  }

  .md\:mb-2\/5 {
    margin-bottom: 40%;
  }

  .md\:ml-2\/5 {
    margin-left: 40%;
  }

  .md\:mt-3\/5 {
    margin-top: 60%;
  }

  .md\:mr-3\/5 {
    margin-right: 60%;
  }

  .md\:mb-3\/5 {
    margin-bottom: 60%;
  }

  .md\:ml-3\/5 {
    margin-left: 60%;
  }

  .md\:mt-4\/5 {
    margin-top: 80%;
  }

  .md\:mr-4\/5 {
    margin-right: 80%;
  }

  .md\:mb-4\/5 {
    margin-bottom: 80%;
  }

  .md\:ml-4\/5 {
    margin-left: 80%;
  }

  .md\:mt-1\/6 {
    margin-top: 16.6667%;
  }

  .md\:mr-1\/6 {
    margin-right: 16.6667%;
  }

  .md\:mb-1\/6 {
    margin-bottom: 16.6667%;
  }

  .md\:ml-1\/6 {
    margin-left: 16.6667%;
  }

  .md\:mt-2\/6 {
    margin-top: 33.3333%;
  }

  .md\:mr-2\/6 {
    margin-right: 33.3333%;
  }

  .md\:mb-2\/6 {
    margin-bottom: 33.3333%;
  }

  .md\:ml-2\/6 {
    margin-left: 33.3333%;
  }

  .md\:mt-3\/6 {
    margin-top: 50%;
  }

  .md\:mr-3\/6 {
    margin-right: 50%;
  }

  .md\:mb-3\/6 {
    margin-bottom: 50%;
  }

  .md\:ml-3\/6 {
    margin-left: 50%;
  }

  .md\:mt-4\/6 {
    margin-top: 66.6667%;
  }

  .md\:mr-4\/6 {
    margin-right: 66.6667%;
  }

  .md\:mb-4\/6 {
    margin-bottom: 66.6667%;
  }

  .md\:ml-4\/6 {
    margin-left: 66.6667%;
  }

  .md\:mt-5\/6 {
    margin-top: 83.3333%;
  }

  .md\:mr-5\/6 {
    margin-right: 83.3333%;
  }

  .md\:mb-5\/6 {
    margin-bottom: 83.3333%;
  }

  .md\:ml-5\/6 {
    margin-left: 83.3333%;
  }

  .md\:mt-1\/12 {
    margin-top: 8.33333%;
  }

  .md\:mr-1\/12 {
    margin-right: 8.33333%;
  }

  .md\:mb-1\/12 {
    margin-bottom: 8.33333%;
  }

  .md\:ml-1\/12 {
    margin-left: 8.33333%;
  }

  .md\:mt-2\/12 {
    margin-top: 16.6667%;
  }

  .md\:mr-2\/12 {
    margin-right: 16.6667%;
  }

  .md\:mb-2\/12 {
    margin-bottom: 16.6667%;
  }

  .md\:ml-2\/12 {
    margin-left: 16.6667%;
  }

  .md\:mt-3\/12 {
    margin-top: 25%;
  }

  .md\:mr-3\/12 {
    margin-right: 25%;
  }

  .md\:mb-3\/12 {
    margin-bottom: 25%;
  }

  .md\:ml-3\/12 {
    margin-left: 25%;
  }

  .md\:mt-4\/12 {
    margin-top: 33.3333%;
  }

  .md\:mr-4\/12 {
    margin-right: 33.3333%;
  }

  .md\:mb-4\/12 {
    margin-bottom: 33.3333%;
  }

  .md\:ml-4\/12 {
    margin-left: 33.3333%;
  }

  .md\:mt-5\/12 {
    margin-top: 41.6667%;
  }

  .md\:mr-5\/12 {
    margin-right: 41.6667%;
  }

  .md\:mb-5\/12 {
    margin-bottom: 41.6667%;
  }

  .md\:ml-5\/12 {
    margin-left: 41.6667%;
  }

  .md\:mt-6\/12 {
    margin-top: 50%;
  }

  .md\:mr-6\/12 {
    margin-right: 50%;
  }

  .md\:mb-6\/12 {
    margin-bottom: 50%;
  }

  .md\:ml-6\/12 {
    margin-left: 50%;
  }

  .md\:mt-7\/12 {
    margin-top: 58.3333%;
  }

  .md\:mr-7\/12 {
    margin-right: 58.3333%;
  }

  .md\:mb-7\/12 {
    margin-bottom: 58.3333%;
  }

  .md\:ml-7\/12 {
    margin-left: 58.3333%;
  }

  .md\:mt-8\/12 {
    margin-top: 66.6667%;
  }

  .md\:mr-8\/12 {
    margin-right: 66.6667%;
  }

  .md\:mb-8\/12 {
    margin-bottom: 66.6667%;
  }

  .md\:ml-8\/12 {
    margin-left: 66.6667%;
  }

  .md\:mt-9\/12 {
    margin-top: 75%;
  }

  .md\:mr-9\/12 {
    margin-right: 75%;
  }

  .md\:mb-9\/12 {
    margin-bottom: 75%;
  }

  .md\:ml-9\/12 {
    margin-left: 75%;
  }

  .md\:mt-10\/12 {
    margin-top: 83.3333%;
  }

  .md\:mr-10\/12 {
    margin-right: 83.3333%;
  }

  .md\:mb-10\/12 {
    margin-bottom: 83.3333%;
  }

  .md\:ml-10\/12 {
    margin-left: 83.3333%;
  }

  .md\:mt-11\/12 {
    margin-top: 91.6667%;
  }

  .md\:mr-11\/12 {
    margin-right: 91.6667%;
  }

  .md\:mb-11\/12 {
    margin-bottom: 91.6667%;
  }

  .md\:ml-11\/12 {
    margin-left: 91.6667%;
  }

  .md\:mt-full {
    margin-top: 100%;
  }

  .md\:mr-full {
    margin-right: 100%;
  }

  .md\:mb-full {
    margin-bottom: 100%;
  }

  .md\:ml-full {
    margin-left: 100%;
  }

  .md\:-mt-1 {
    margin-top: -.25rem;
  }

  .md\:-mr-1 {
    margin-right: -.25rem;
  }

  .md\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .md\:-ml-1 {
    margin-left: -.25rem;
  }

  .md\:-mt-2 {
    margin-top: -.5rem;
  }

  .md\:-mr-2 {
    margin-right: -.5rem;
  }

  .md\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .md\:-ml-2 {
    margin-left: -.5rem;
  }

  .md\:-mt-3 {
    margin-top: -.75rem;
  }

  .md\:-mr-3 {
    margin-right: -.75rem;
  }

  .md\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .md\:-ml-3 {
    margin-left: -.75rem;
  }

  .md\:-mt-4 {
    margin-top: -1rem;
  }

  .md\:-mr-4 {
    margin-right: -1rem;
  }

  .md\:-mb-4 {
    margin-bottom: -1rem;
  }

  .md\:-ml-4 {
    margin-left: -1rem;
  }

  .md\:-mt-5 {
    margin-top: -1.25rem;
  }

  .md\:-mr-5 {
    margin-right: -1.25rem;
  }

  .md\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .md\:-ml-5 {
    margin-left: -1.25rem;
  }

  .md\:-mt-6 {
    margin-top: -1.5rem;
  }

  .md\:-mr-6 {
    margin-right: -1.5rem;
  }

  .md\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .md\:-ml-6 {
    margin-left: -1.5rem;
  }

  .md\:-mt-7 {
    margin-top: -1.75rem;
  }

  .md\:-mr-7 {
    margin-right: -1.75rem;
  }

  .md\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .md\:-ml-7 {
    margin-left: -1.75rem;
  }

  .md\:-mt-8 {
    margin-top: -2rem;
  }

  .md\:-mr-8 {
    margin-right: -2rem;
  }

  .md\:-mb-8 {
    margin-bottom: -2rem;
  }

  .md\:-ml-8 {
    margin-left: -2rem;
  }

  .md\:-mt-9 {
    margin-top: -2.25rem;
  }

  .md\:-mr-9 {
    margin-right: -2.25rem;
  }

  .md\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .md\:-ml-9 {
    margin-left: -2.25rem;
  }

  .md\:-mt-10 {
    margin-top: -2.5rem;
  }

  .md\:-mr-10 {
    margin-right: -2.5rem;
  }

  .md\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .md\:-ml-10 {
    margin-left: -2.5rem;
  }

  .md\:-mt-11 {
    margin-top: -2.75rem;
  }

  .md\:-mr-11 {
    margin-right: -2.75rem;
  }

  .md\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .md\:-ml-11 {
    margin-left: -2.75rem;
  }

  .md\:-mt-12 {
    margin-top: -3rem;
  }

  .md\:-mr-12 {
    margin-right: -3rem;
  }

  .md\:-mb-12 {
    margin-bottom: -3rem;
  }

  .md\:-ml-12 {
    margin-left: -3rem;
  }

  .md\:-mt-13 {
    margin-top: -3.25rem;
  }

  .md\:-mr-13 {
    margin-right: -3.25rem;
  }

  .md\:-mb-13 {
    margin-bottom: -3.25rem;
  }

  .md\:-ml-13 {
    margin-left: -3.25rem;
  }

  .md\:-mt-14 {
    margin-top: -3.5rem;
  }

  .md\:-mr-14 {
    margin-right: -3.5rem;
  }

  .md\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .md\:-ml-14 {
    margin-left: -3.5rem;
  }

  .md\:-mt-15 {
    margin-top: -3.75rem;
  }

  .md\:-mr-15 {
    margin-right: -3.75rem;
  }

  .md\:-mb-15 {
    margin-bottom: -3.75rem;
  }

  .md\:-ml-15 {
    margin-left: -3.75rem;
  }

  .md\:-mt-16 {
    margin-top: -4rem;
  }

  .md\:-mr-16 {
    margin-right: -4rem;
  }

  .md\:-mb-16 {
    margin-bottom: -4rem;
  }

  .md\:-ml-16 {
    margin-left: -4rem;
  }

  .md\:-mt-20 {
    margin-top: -5rem;
  }

  .md\:-mr-20 {
    margin-right: -5rem;
  }

  .md\:-mb-20 {
    margin-bottom: -5rem;
  }

  .md\:-ml-20 {
    margin-left: -5rem;
  }

  .md\:-mt-24 {
    margin-top: -6rem;
  }

  .md\:-mr-24 {
    margin-right: -6rem;
  }

  .md\:-mb-24 {
    margin-bottom: -6rem;
  }

  .md\:-ml-24 {
    margin-left: -6rem;
  }

  .md\:-mt-28 {
    margin-top: -7rem;
  }

  .md\:-mr-28 {
    margin-right: -7rem;
  }

  .md\:-mb-28 {
    margin-bottom: -7rem;
  }

  .md\:-ml-28 {
    margin-left: -7rem;
  }

  .md\:-mt-32 {
    margin-top: -8rem;
  }

  .md\:-mr-32 {
    margin-right: -8rem;
  }

  .md\:-mb-32 {
    margin-bottom: -8rem;
  }

  .md\:-ml-32 {
    margin-left: -8rem;
  }

  .md\:-mt-36 {
    margin-top: -9rem;
  }

  .md\:-mr-36 {
    margin-right: -9rem;
  }

  .md\:-mb-36 {
    margin-bottom: -9rem;
  }

  .md\:-ml-36 {
    margin-left: -9rem;
  }

  .md\:-mt-40 {
    margin-top: -10rem;
  }

  .md\:-mr-40 {
    margin-right: -10rem;
  }

  .md\:-mb-40 {
    margin-bottom: -10rem;
  }

  .md\:-ml-40 {
    margin-left: -10rem;
  }

  .md\:-mt-44 {
    margin-top: -11rem;
  }

  .md\:-mr-44 {
    margin-right: -11rem;
  }

  .md\:-mb-44 {
    margin-bottom: -11rem;
  }

  .md\:-ml-44 {
    margin-left: -11rem;
  }

  .md\:-mt-48 {
    margin-top: -12rem;
  }

  .md\:-mr-48 {
    margin-right: -12rem;
  }

  .md\:-mb-48 {
    margin-bottom: -12rem;
  }

  .md\:-ml-48 {
    margin-left: -12rem;
  }

  .md\:-mt-52 {
    margin-top: -13rem;
  }

  .md\:-mr-52 {
    margin-right: -13rem;
  }

  .md\:-mb-52 {
    margin-bottom: -13rem;
  }

  .md\:-ml-52 {
    margin-left: -13rem;
  }

  .md\:-mt-56 {
    margin-top: -14rem;
  }

  .md\:-mr-56 {
    margin-right: -14rem;
  }

  .md\:-mb-56 {
    margin-bottom: -14rem;
  }

  .md\:-ml-56 {
    margin-left: -14rem;
  }

  .md\:-mt-60 {
    margin-top: -15rem;
  }

  .md\:-mr-60 {
    margin-right: -15rem;
  }

  .md\:-mb-60 {
    margin-bottom: -15rem;
  }

  .md\:-ml-60 {
    margin-left: -15rem;
  }

  .md\:-mt-64 {
    margin-top: -16rem;
  }

  .md\:-mr-64 {
    margin-right: -16rem;
  }

  .md\:-mb-64 {
    margin-bottom: -16rem;
  }

  .md\:-ml-64 {
    margin-left: -16rem;
  }

  .md\:-mt-72 {
    margin-top: -18rem;
  }

  .md\:-mr-72 {
    margin-right: -18rem;
  }

  .md\:-mb-72 {
    margin-bottom: -18rem;
  }

  .md\:-ml-72 {
    margin-left: -18rem;
  }

  .md\:-mt-80 {
    margin-top: -20rem;
  }

  .md\:-mr-80 {
    margin-right: -20rem;
  }

  .md\:-mb-80 {
    margin-bottom: -20rem;
  }

  .md\:-ml-80 {
    margin-left: -20rem;
  }

  .md\:-mt-96 {
    margin-top: -24rem;
  }

  .md\:-mr-96 {
    margin-right: -24rem;
  }

  .md\:-mb-96 {
    margin-bottom: -24rem;
  }

  .md\:-ml-96 {
    margin-left: -24rem;
  }

  .md\:-mt-px {
    margin-top: -1px;
  }

  .md\:-mr-px {
    margin-right: -1px;
  }

  .md\:-mb-px {
    margin-bottom: -1px;
  }

  .md\:-ml-px {
    margin-left: -1px;
  }

  .md\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .md\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .md\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .md\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .md\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .md\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .md\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .md\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .md\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .md\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .md\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .md\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .md\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .md\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .md\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .md\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .md\:-mt-1\/2 {
    margin-top: -50%;
  }

  .md\:-mr-1\/2 {
    margin-right: -50%;
  }

  .md\:-mb-1\/2 {
    margin-bottom: -50%;
  }

  .md\:-ml-1\/2 {
    margin-left: -50%;
  }

  .md\:-mt-1\/3 {
    margin-top: -33.3333%;
  }

  .md\:-mr-1\/3 {
    margin-right: -33.3333%;
  }

  .md\:-mb-1\/3 {
    margin-bottom: -33.3333%;
  }

  .md\:-ml-1\/3 {
    margin-left: -33.3333%;
  }

  .md\:-mt-2\/3 {
    margin-top: -66.6667%;
  }

  .md\:-mr-2\/3 {
    margin-right: -66.6667%;
  }

  .md\:-mb-2\/3 {
    margin-bottom: -66.6667%;
  }

  .md\:-ml-2\/3 {
    margin-left: -66.6667%;
  }

  .md\:-mt-1\/4 {
    margin-top: -25%;
  }

  .md\:-mr-1\/4 {
    margin-right: -25%;
  }

  .md\:-mb-1\/4 {
    margin-bottom: -25%;
  }

  .md\:-ml-1\/4 {
    margin-left: -25%;
  }

  .md\:-mt-2\/4 {
    margin-top: -50%;
  }

  .md\:-mr-2\/4 {
    margin-right: -50%;
  }

  .md\:-mb-2\/4 {
    margin-bottom: -50%;
  }

  .md\:-ml-2\/4 {
    margin-left: -50%;
  }

  .md\:-mt-3\/4 {
    margin-top: -75%;
  }

  .md\:-mr-3\/4 {
    margin-right: -75%;
  }

  .md\:-mb-3\/4 {
    margin-bottom: -75%;
  }

  .md\:-ml-3\/4 {
    margin-left: -75%;
  }

  .md\:-mt-1\/5 {
    margin-top: -20%;
  }

  .md\:-mr-1\/5 {
    margin-right: -20%;
  }

  .md\:-mb-1\/5 {
    margin-bottom: -20%;
  }

  .md\:-ml-1\/5 {
    margin-left: -20%;
  }

  .md\:-mt-2\/5 {
    margin-top: -40%;
  }

  .md\:-mr-2\/5 {
    margin-right: -40%;
  }

  .md\:-mb-2\/5 {
    margin-bottom: -40%;
  }

  .md\:-ml-2\/5 {
    margin-left: -40%;
  }

  .md\:-mt-3\/5 {
    margin-top: -60%;
  }

  .md\:-mr-3\/5 {
    margin-right: -60%;
  }

  .md\:-mb-3\/5 {
    margin-bottom: -60%;
  }

  .md\:-ml-3\/5 {
    margin-left: -60%;
  }

  .md\:-mt-4\/5 {
    margin-top: -80%;
  }

  .md\:-mr-4\/5 {
    margin-right: -80%;
  }

  .md\:-mb-4\/5 {
    margin-bottom: -80%;
  }

  .md\:-ml-4\/5 {
    margin-left: -80%;
  }

  .md\:-mt-1\/6 {
    margin-top: -16.6667%;
  }

  .md\:-mr-1\/6 {
    margin-right: -16.6667%;
  }

  .md\:-mb-1\/6 {
    margin-bottom: -16.6667%;
  }

  .md\:-ml-1\/6 {
    margin-left: -16.6667%;
  }

  .md\:-mt-2\/6 {
    margin-top: -33.3333%;
  }

  .md\:-mr-2\/6 {
    margin-right: -33.3333%;
  }

  .md\:-mb-2\/6 {
    margin-bottom: -33.3333%;
  }

  .md\:-ml-2\/6 {
    margin-left: -33.3333%;
  }

  .md\:-mt-3\/6 {
    margin-top: -50%;
  }

  .md\:-mr-3\/6 {
    margin-right: -50%;
  }

  .md\:-mb-3\/6 {
    margin-bottom: -50%;
  }

  .md\:-ml-3\/6 {
    margin-left: -50%;
  }

  .md\:-mt-4\/6 {
    margin-top: -66.6667%;
  }

  .md\:-mr-4\/6 {
    margin-right: -66.6667%;
  }

  .md\:-mb-4\/6 {
    margin-bottom: -66.6667%;
  }

  .md\:-ml-4\/6 {
    margin-left: -66.6667%;
  }

  .md\:-mt-5\/6 {
    margin-top: -83.3333%;
  }

  .md\:-mr-5\/6 {
    margin-right: -83.3333%;
  }

  .md\:-mb-5\/6 {
    margin-bottom: -83.3333%;
  }

  .md\:-ml-5\/6 {
    margin-left: -83.3333%;
  }

  .md\:-mt-1\/12 {
    margin-top: -8.33333%;
  }

  .md\:-mr-1\/12 {
    margin-right: -8.33333%;
  }

  .md\:-mb-1\/12 {
    margin-bottom: -8.33333%;
  }

  .md\:-ml-1\/12 {
    margin-left: -8.33333%;
  }

  .md\:-mt-2\/12 {
    margin-top: -16.6667%;
  }

  .md\:-mr-2\/12 {
    margin-right: -16.6667%;
  }

  .md\:-mb-2\/12 {
    margin-bottom: -16.6667%;
  }

  .md\:-ml-2\/12 {
    margin-left: -16.6667%;
  }

  .md\:-mt-3\/12 {
    margin-top: -25%;
  }

  .md\:-mr-3\/12 {
    margin-right: -25%;
  }

  .md\:-mb-3\/12 {
    margin-bottom: -25%;
  }

  .md\:-ml-3\/12 {
    margin-left: -25%;
  }

  .md\:-mt-4\/12 {
    margin-top: -33.3333%;
  }

  .md\:-mr-4\/12 {
    margin-right: -33.3333%;
  }

  .md\:-mb-4\/12 {
    margin-bottom: -33.3333%;
  }

  .md\:-ml-4\/12 {
    margin-left: -33.3333%;
  }

  .md\:-mt-5\/12 {
    margin-top: -41.6667%;
  }

  .md\:-mr-5\/12 {
    margin-right: -41.6667%;
  }

  .md\:-mb-5\/12 {
    margin-bottom: -41.6667%;
  }

  .md\:-ml-5\/12 {
    margin-left: -41.6667%;
  }

  .md\:-mt-6\/12 {
    margin-top: -50%;
  }

  .md\:-mr-6\/12 {
    margin-right: -50%;
  }

  .md\:-mb-6\/12 {
    margin-bottom: -50%;
  }

  .md\:-ml-6\/12 {
    margin-left: -50%;
  }

  .md\:-mt-7\/12 {
    margin-top: -58.3333%;
  }

  .md\:-mr-7\/12 {
    margin-right: -58.3333%;
  }

  .md\:-mb-7\/12 {
    margin-bottom: -58.3333%;
  }

  .md\:-ml-7\/12 {
    margin-left: -58.3333%;
  }

  .md\:-mt-8\/12 {
    margin-top: -66.6667%;
  }

  .md\:-mr-8\/12 {
    margin-right: -66.6667%;
  }

  .md\:-mb-8\/12 {
    margin-bottom: -66.6667%;
  }

  .md\:-ml-8\/12 {
    margin-left: -66.6667%;
  }

  .md\:-mt-9\/12 {
    margin-top: -75%;
  }

  .md\:-mr-9\/12 {
    margin-right: -75%;
  }

  .md\:-mb-9\/12 {
    margin-bottom: -75%;
  }

  .md\:-ml-9\/12 {
    margin-left: -75%;
  }

  .md\:-mt-10\/12 {
    margin-top: -83.3333%;
  }

  .md\:-mr-10\/12 {
    margin-right: -83.3333%;
  }

  .md\:-mb-10\/12 {
    margin-bottom: -83.3333%;
  }

  .md\:-ml-10\/12 {
    margin-left: -83.3333%;
  }

  .md\:-mt-11\/12 {
    margin-top: -91.6667%;
  }

  .md\:-mr-11\/12 {
    margin-right: -91.6667%;
  }

  .md\:-mb-11\/12 {
    margin-bottom: -91.6667%;
  }

  .md\:-ml-11\/12 {
    margin-left: -91.6667%;
  }

  .md\:-mt-full {
    margin-top: -100%;
  }

  .md\:-mr-full {
    margin-right: -100%;
  }

  .md\:-mb-full {
    margin-bottom: -100%;
  }

  .md\:-ml-full {
    margin-left: -100%;
  }

  .md\:max-h-0 {
    max-height: 0;
  }

  .md\:max-h-1 {
    max-height: .25rem;
  }

  .md\:max-h-2 {
    max-height: .5rem;
  }

  .md\:max-h-3 {
    max-height: .75rem;
  }

  .md\:max-h-4 {
    max-height: 1rem;
  }

  .md\:max-h-5 {
    max-height: 1.25rem;
  }

  .md\:max-h-6 {
    max-height: 1.5rem;
  }

  .md\:max-h-7 {
    max-height: 1.75rem;
  }

  .md\:max-h-8 {
    max-height: 2rem;
  }

  .md\:max-h-9 {
    max-height: 2.25rem;
  }

  .md\:max-h-10 {
    max-height: 2.5rem;
  }

  .md\:max-h-11 {
    max-height: 2.75rem;
  }

  .md\:max-h-12 {
    max-height: 3rem;
  }

  .md\:max-h-13 {
    max-height: 3.25rem;
  }

  .md\:max-h-14 {
    max-height: 3.5rem;
  }

  .md\:max-h-15 {
    max-height: 3.75rem;
  }

  .md\:max-h-16 {
    max-height: 4rem;
  }

  .md\:max-h-20 {
    max-height: 5rem;
  }

  .md\:max-h-24 {
    max-height: 6rem;
  }

  .md\:max-h-28 {
    max-height: 7rem;
  }

  .md\:max-h-32 {
    max-height: 8rem;
  }

  .md\:max-h-36 {
    max-height: 9rem;
  }

  .md\:max-h-40 {
    max-height: 10rem;
  }

  .md\:max-h-44 {
    max-height: 11rem;
  }

  .md\:max-h-48 {
    max-height: 12rem;
  }

  .md\:max-h-52 {
    max-height: 13rem;
  }

  .md\:max-h-56 {
    max-height: 14rem;
  }

  .md\:max-h-60 {
    max-height: 15rem;
  }

  .md\:max-h-64 {
    max-height: 16rem;
  }

  .md\:max-h-72 {
    max-height: 18rem;
  }

  .md\:max-h-80 {
    max-height: 20rem;
  }

  .md\:max-h-96 {
    max-height: 24rem;
  }

  .md\:max-h-screen {
    max-height: 100vh;
  }

  .md\:max-h-px {
    max-height: 1px;
  }

  .md\:max-h-0\.5 {
    max-height: .125rem;
  }

  .md\:max-h-1\.5 {
    max-height: .375rem;
  }

  .md\:max-h-2\.5 {
    max-height: .625rem;
  }

  .md\:max-h-3\.5 {
    max-height: .875rem;
  }

  .md\:max-h-1\/2 {
    max-height: 50%;
  }

  .md\:max-h-1\/3 {
    max-height: 33.3333%;
  }

  .md\:max-h-2\/3 {
    max-height: 66.6667%;
  }

  .md\:max-h-1\/4 {
    max-height: 25%;
  }

  .md\:max-h-2\/4 {
    max-height: 50%;
  }

  .md\:max-h-3\/4 {
    max-height: 75%;
  }

  .md\:max-h-1\/5 {
    max-height: 20%;
  }

  .md\:max-h-2\/5 {
    max-height: 40%;
  }

  .md\:max-h-3\/5 {
    max-height: 60%;
  }

  .md\:max-h-4\/5 {
    max-height: 80%;
  }

  .md\:max-h-1\/6 {
    max-height: 16.6667%;
  }

  .md\:max-h-2\/6 {
    max-height: 33.3333%;
  }

  .md\:max-h-3\/6 {
    max-height: 50%;
  }

  .md\:max-h-4\/6 {
    max-height: 66.6667%;
  }

  .md\:max-h-5\/6 {
    max-height: 83.3333%;
  }

  .md\:max-h-1\/12 {
    max-height: 8.33333%;
  }

  .md\:max-h-2\/12 {
    max-height: 16.6667%;
  }

  .md\:max-h-3\/12 {
    max-height: 25%;
  }

  .md\:max-h-4\/12 {
    max-height: 33.3333%;
  }

  .md\:max-h-5\/12 {
    max-height: 41.6667%;
  }

  .md\:max-h-6\/12 {
    max-height: 50%;
  }

  .md\:max-h-7\/12 {
    max-height: 58.3333%;
  }

  .md\:max-h-8\/12 {
    max-height: 66.6667%;
  }

  .md\:max-h-9\/12 {
    max-height: 75%;
  }

  .md\:max-h-10\/12 {
    max-height: 83.3333%;
  }

  .md\:max-h-11\/12 {
    max-height: 91.6667%;
  }

  .md\:max-h-full {
    max-height: 100%;
  }

  .md\:max-w-none {
    max-width: none;
  }

  .md\:max-w-xs {
    max-width: 20rem;
  }

  .md\:max-w-sm {
    max-width: 24rem;
  }

  .md\:max-w-md {
    max-width: 28rem;
  }

  .md\:max-w-lg {
    max-width: 32rem;
  }

  .md\:max-w-xl {
    max-width: 36rem;
  }

  .md\:max-w-2xl {
    max-width: 42rem;
  }

  .md\:max-w-3xl {
    max-width: 48rem;
  }

  .md\:max-w-4xl {
    max-width: 56rem;
  }

  .md\:max-w-5xl {
    max-width: 64rem;
  }

  .md\:max-w-6xl {
    max-width: 72rem;
  }

  .md\:max-w-7xl {
    max-width: 80rem;
  }

  .md\:max-w-full {
    max-width: 100%;
  }

  .md\:max-w-min-content {
    max-width: min-content;
  }

  .md\:max-w-max-content {
    max-width: max-content;
  }

  .md\:max-w-screen-sm {
    max-width: 640px;
  }

  .md\:max-w-screen-md {
    max-width: 768px;
  }

  .md\:max-w-screen-lg {
    max-width: 1024px;
  }

  .md\:max-w-screen-xl {
    max-width: 1280px;
  }

  .md\:min-h-0 {
    min-height: 0;
  }

  .md\:min-h-full {
    min-height: 100%;
  }

  .md\:min-h-screen {
    min-height: 100vh;
  }

  .md\:min-w-0 {
    min-width: 0;
  }

  .md\:min-w-full {
    min-width: 100%;
  }

  .md\:min-w-min-content {
    min-width: min-content;
  }

  .md\:min-w-max-content {
    min-width: max-content;
  }

  .md\:object-contain {
    object-fit: contain;
  }

  .md\:object-cover {
    object-fit: cover;
  }

  .md\:object-fill {
    object-fit: fill;
  }

  .md\:object-none {
    object-fit: none;
  }

  .md\:object-scale-down {
    object-fit: scale-down;
  }

  .md\:object-bottom {
    object-position: bottom;
  }

  .md\:object-center {
    object-position: center;
  }

  .md\:object-left {
    object-position: left;
  }

  .md\:object-left-bottom {
    object-position: left bottom;
  }

  .md\:object-left-top {
    object-position: left top;
  }

  .md\:object-right {
    object-position: right;
  }

  .md\:object-right-bottom {
    object-position: right bottom;
  }

  .md\:object-right-top {
    object-position: right top;
  }

  .md\:object-top {
    object-position: top;
  }

  .md\:opacity-0 {
    opacity: 0;
  }

  .md\:opacity-25 {
    opacity: .25;
  }

  .md\:opacity-50 {
    opacity: .5;
  }

  .md\:opacity-75 {
    opacity: .75;
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .md\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .md\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .md\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .md\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .md\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .md\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .md\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .md\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .md\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .md\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .md\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .md\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .md\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .md\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .md\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .md\:overflow-auto {
    overflow: auto;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:overflow-visible {
    overflow: visible;
  }

  .md\:overflow-scroll {
    overflow: scroll;
  }

  .md\:overflow-x-auto {
    overflow-x: auto;
  }

  .md\:overflow-y-auto {
    overflow-y: auto;
  }

  .md\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .md\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .md\:overflow-x-visible {
    overflow-x: visible;
  }

  .md\:overflow-y-visible {
    overflow-y: visible;
  }

  .md\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .md\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .md\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .md\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .md\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .md\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .md\:overscroll-none {
    overscroll-behavior: none;
  }

  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .md\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .md\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .md\:p-0 {
    padding: 0;
  }

  .md\:p-1 {
    padding: .25rem;
  }

  .md\:p-2 {
    padding: .5rem;
  }

  .md\:p-3 {
    padding: .75rem;
  }

  .md\:p-4 {
    padding: 1rem;
  }

  .md\:p-5 {
    padding: 1.25rem;
  }

  .md\:p-6 {
    padding: 1.5rem;
  }

  .md\:p-7 {
    padding: 1.75rem;
  }

  .md\:p-8 {
    padding: 2rem;
  }

  .md\:p-9 {
    padding: 2.25rem;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }

  .md\:p-11 {
    padding: 2.75rem;
  }

  .md\:p-12 {
    padding: 3rem;
  }

  .md\:p-13 {
    padding: 3.25rem;
  }

  .md\:p-14 {
    padding: 3.5rem;
  }

  .md\:p-15 {
    padding: 3.75rem;
  }

  .md\:p-16 {
    padding: 4rem;
  }

  .md\:p-20 {
    padding: 5rem;
  }

  .md\:p-24 {
    padding: 6rem;
  }

  .md\:p-28 {
    padding: 7rem;
  }

  .md\:p-32 {
    padding: 8rem;
  }

  .md\:p-36 {
    padding: 9rem;
  }

  .md\:p-40 {
    padding: 10rem;
  }

  .md\:p-44 {
    padding: 11rem;
  }

  .md\:p-48 {
    padding: 12rem;
  }

  .md\:p-52 {
    padding: 13rem;
  }

  .md\:p-56 {
    padding: 14rem;
  }

  .md\:p-60 {
    padding: 15rem;
  }

  .md\:p-64 {
    padding: 16rem;
  }

  .md\:p-72 {
    padding: 18rem;
  }

  .md\:p-80 {
    padding: 20rem;
  }

  .md\:p-96 {
    padding: 24rem;
  }

  .md\:p-px {
    padding: 1px;
  }

  .md\:p-0\.5 {
    padding: .125rem;
  }

  .md\:p-1\.5 {
    padding: .375rem;
  }

  .md\:p-2\.5 {
    padding: .625rem;
  }

  .md\:p-3\.5 {
    padding: .875rem;
  }

  .md\:p-1\/2 {
    padding: 50%;
  }

  .md\:p-1\/3 {
    padding: 33.3333%;
  }

  .md\:p-2\/3 {
    padding: 66.6667%;
  }

  .md\:p-1\/4 {
    padding: 25%;
  }

  .md\:p-2\/4 {
    padding: 50%;
  }

  .md\:p-3\/4 {
    padding: 75%;
  }

  .md\:p-1\/5 {
    padding: 20%;
  }

  .md\:p-2\/5 {
    padding: 40%;
  }

  .md\:p-3\/5 {
    padding: 60%;
  }

  .md\:p-4\/5 {
    padding: 80%;
  }

  .md\:p-1\/6 {
    padding: 16.6667%;
  }

  .md\:p-2\/6 {
    padding: 33.3333%;
  }

  .md\:p-3\/6 {
    padding: 50%;
  }

  .md\:p-4\/6 {
    padding: 66.6667%;
  }

  .md\:p-5\/6 {
    padding: 83.3333%;
  }

  .md\:p-1\/12 {
    padding: 8.33333%;
  }

  .md\:p-2\/12 {
    padding: 16.6667%;
  }

  .md\:p-3\/12 {
    padding: 25%;
  }

  .md\:p-4\/12 {
    padding: 33.3333%;
  }

  .md\:p-5\/12 {
    padding: 41.6667%;
  }

  .md\:p-6\/12 {
    padding: 50%;
  }

  .md\:p-7\/12 {
    padding: 58.3333%;
  }

  .md\:p-8\/12 {
    padding: 66.6667%;
  }

  .md\:p-9\/12 {
    padding: 75%;
  }

  .md\:p-10\/12 {
    padding: 83.3333%;
  }

  .md\:p-11\/12 {
    padding: 91.6667%;
  }

  .md\:p-full {
    padding: 100%;
  }

  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .md\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .md\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .md\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:py-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem;
  }

  .md\:px-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }

  .md\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .md\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .md\:py-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .md\:px-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .md\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .md\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .md\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .md\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .md\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .md\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .md\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .md\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .md\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .md\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .md\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .md\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .md\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .md\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .md\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .md\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .md\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .md\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .md\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .md\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .md\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .md\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .md\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .md\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .md\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .md\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .md\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .md\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .md\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .md\:py-1\/2 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .md\:px-1\/2 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .md\:py-1\/3 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .md\:px-1\/3 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .md\:py-2\/3 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .md\:px-2\/3 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .md\:py-1\/4 {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .md\:px-1\/4 {
    padding-left: 25%;
    padding-right: 25%;
  }

  .md\:py-2\/4 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .md\:px-2\/4 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .md\:py-3\/4 {
    padding-top: 75%;
    padding-bottom: 75%;
  }

  .md\:px-3\/4 {
    padding-left: 75%;
    padding-right: 75%;
  }

  .md\:py-1\/5 {
    padding-top: 20%;
    padding-bottom: 20%;
  }

  .md\:px-1\/5 {
    padding-left: 20%;
    padding-right: 20%;
  }

  .md\:py-2\/5 {
    padding-top: 40%;
    padding-bottom: 40%;
  }

  .md\:px-2\/5 {
    padding-left: 40%;
    padding-right: 40%;
  }

  .md\:py-3\/5 {
    padding-top: 60%;
    padding-bottom: 60%;
  }

  .md\:px-3\/5 {
    padding-left: 60%;
    padding-right: 60%;
  }

  .md\:py-4\/5 {
    padding-top: 80%;
    padding-bottom: 80%;
  }

  .md\:px-4\/5 {
    padding-left: 80%;
    padding-right: 80%;
  }

  .md\:py-1\/6 {
    padding-top: 16.6667%;
    padding-bottom: 16.6667%;
  }

  .md\:px-1\/6 {
    padding-left: 16.6667%;
    padding-right: 16.6667%;
  }

  .md\:py-2\/6 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .md\:px-2\/6 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .md\:py-3\/6 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .md\:px-3\/6 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .md\:py-4\/6 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .md\:px-4\/6 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .md\:py-5\/6 {
    padding-top: 83.3333%;
    padding-bottom: 83.3333%;
  }

  .md\:px-5\/6 {
    padding-left: 83.3333%;
    padding-right: 83.3333%;
  }

  .md\:py-1\/12 {
    padding-top: 8.33333%;
    padding-bottom: 8.33333%;
  }

  .md\:px-1\/12 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }

  .md\:py-2\/12 {
    padding-top: 16.6667%;
    padding-bottom: 16.6667%;
  }

  .md\:px-2\/12 {
    padding-left: 16.6667%;
    padding-right: 16.6667%;
  }

  .md\:py-3\/12 {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .md\:px-3\/12 {
    padding-left: 25%;
    padding-right: 25%;
  }

  .md\:py-4\/12 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .md\:px-4\/12 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .md\:py-5\/12 {
    padding-top: 41.6667%;
    padding-bottom: 41.6667%;
  }

  .md\:px-5\/12 {
    padding-left: 41.6667%;
    padding-right: 41.6667%;
  }

  .md\:py-6\/12 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .md\:px-6\/12 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .md\:py-7\/12 {
    padding-top: 58.3333%;
    padding-bottom: 58.3333%;
  }

  .md\:px-7\/12 {
    padding-left: 58.3333%;
    padding-right: 58.3333%;
  }

  .md\:py-8\/12 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .md\:px-8\/12 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .md\:py-9\/12 {
    padding-top: 75%;
    padding-bottom: 75%;
  }

  .md\:px-9\/12 {
    padding-left: 75%;
    padding-right: 75%;
  }

  .md\:py-10\/12 {
    padding-top: 83.3333%;
    padding-bottom: 83.3333%;
  }

  .md\:px-10\/12 {
    padding-left: 83.3333%;
    padding-right: 83.3333%;
  }

  .md\:py-11\/12 {
    padding-top: 91.6667%;
    padding-bottom: 91.6667%;
  }

  .md\:px-11\/12 {
    padding-left: 91.6667%;
    padding-right: 91.6667%;
  }

  .md\:py-full {
    padding-top: 100%;
    padding-bottom: 100%;
  }

  .md\:px-full {
    padding-left: 100%;
    padding-right: 100%;
  }

  .md\:pt-0 {
    padding-top: 0;
  }

  .md\:pr-0 {
    padding-right: 0;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  .md\:pl-0 {
    padding-left: 0;
  }

  .md\:pt-1 {
    padding-top: .25rem;
  }

  .md\:pr-1 {
    padding-right: .25rem;
  }

  .md\:pb-1 {
    padding-bottom: .25rem;
  }

  .md\:pl-1 {
    padding-left: .25rem;
  }

  .md\:pt-2 {
    padding-top: .5rem;
  }

  .md\:pr-2 {
    padding-right: .5rem;
  }

  .md\:pb-2 {
    padding-bottom: .5rem;
  }

  .md\:pl-2 {
    padding-left: .5rem;
  }

  .md\:pt-3 {
    padding-top: .75rem;
  }

  .md\:pr-3 {
    padding-right: .75rem;
  }

  .md\:pb-3 {
    padding-bottom: .75rem;
  }

  .md\:pl-3 {
    padding-left: .75rem;
  }

  .md\:pt-4 {
    padding-top: 1rem;
  }

  .md\:pr-4 {
    padding-right: 1rem;
  }

  .md\:pb-4 {
    padding-bottom: 1rem;
  }

  .md\:pl-4 {
    padding-left: 1rem;
  }

  .md\:pt-5 {
    padding-top: 1.25rem;
  }

  .md\:pr-5 {
    padding-right: 1.25rem;
  }

  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:pl-5 {
    padding-left: 1.25rem;
  }

  .md\:pt-6 {
    padding-top: 1.5rem;
  }

  .md\:pr-6 {
    padding-right: 1.5rem;
  }

  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:pl-6 {
    padding-left: 1.5rem;
  }

  .md\:pt-7 {
    padding-top: 1.75rem;
  }

  .md\:pr-7 {
    padding-right: 1.75rem;
  }

  .md\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .md\:pl-7 {
    padding-left: 1.75rem;
  }

  .md\:pt-8 {
    padding-top: 2rem;
  }

  .md\:pr-8 {
    padding-right: 2rem;
  }

  .md\:pb-8 {
    padding-bottom: 2rem;
  }

  .md\:pl-8 {
    padding-left: 2rem;
  }

  .md\:pt-9 {
    padding-top: 2.25rem;
  }

  .md\:pr-9 {
    padding-right: 2.25rem;
  }

  .md\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .md\:pl-9 {
    padding-left: 2.25rem;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:pr-10 {
    padding-right: 2.5rem;
  }

  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:pl-10 {
    padding-left: 2.5rem;
  }

  .md\:pt-11 {
    padding-top: 2.75rem;
  }

  .md\:pr-11 {
    padding-right: 2.75rem;
  }

  .md\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .md\:pl-11 {
    padding-left: 2.75rem;
  }

  .md\:pt-12 {
    padding-top: 3rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pb-12 {
    padding-bottom: 3rem;
  }

  .md\:pl-12 {
    padding-left: 3rem;
  }

  .md\:pt-13 {
    padding-top: 3.25rem;
  }

  .md\:pr-13 {
    padding-right: 3.25rem;
  }

  .md\:pb-13 {
    padding-bottom: 3.25rem;
  }

  .md\:pl-13 {
    padding-left: 3.25rem;
  }

  .md\:pt-14 {
    padding-top: 3.5rem;
  }

  .md\:pr-14 {
    padding-right: 3.5rem;
  }

  .md\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .md\:pl-14 {
    padding-left: 3.5rem;
  }

  .md\:pt-15 {
    padding-top: 3.75rem;
  }

  .md\:pr-15 {
    padding-right: 3.75rem;
  }

  .md\:pb-15 {
    padding-bottom: 3.75rem;
  }

  .md\:pl-15 {
    padding-left: 3.75rem;
  }

  .md\:pt-16 {
    padding-top: 4rem;
  }

  .md\:pr-16 {
    padding-right: 4rem;
  }

  .md\:pb-16 {
    padding-bottom: 4rem;
  }

  .md\:pl-16 {
    padding-left: 4rem;
  }

  .md\:pt-20 {
    padding-top: 5rem;
  }

  .md\:pr-20 {
    padding-right: 5rem;
  }

  .md\:pb-20 {
    padding-bottom: 5rem;
  }

  .md\:pl-20 {
    padding-left: 5rem;
  }

  .md\:pt-24 {
    padding-top: 6rem;
  }

  .md\:pr-24 {
    padding-right: 6rem;
  }

  .md\:pb-24 {
    padding-bottom: 6rem;
  }

  .md\:pl-24 {
    padding-left: 6rem;
  }

  .md\:pt-28 {
    padding-top: 7rem;
  }

  .md\:pr-28 {
    padding-right: 7rem;
  }

  .md\:pb-28 {
    padding-bottom: 7rem;
  }

  .md\:pl-28 {
    padding-left: 7rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:pr-32 {
    padding-right: 8rem;
  }

  .md\:pb-32 {
    padding-bottom: 8rem;
  }

  .md\:pl-32 {
    padding-left: 8rem;
  }

  .md\:pt-36 {
    padding-top: 9rem;
  }

  .md\:pr-36 {
    padding-right: 9rem;
  }

  .md\:pb-36 {
    padding-bottom: 9rem;
  }

  .md\:pl-36 {
    padding-left: 9rem;
  }

  .md\:pt-40 {
    padding-top: 10rem;
  }

  .md\:pr-40 {
    padding-right: 10rem;
  }

  .md\:pb-40 {
    padding-bottom: 10rem;
  }

  .md\:pl-40 {
    padding-left: 10rem;
  }

  .md\:pt-44 {
    padding-top: 11rem;
  }

  .md\:pr-44 {
    padding-right: 11rem;
  }

  .md\:pb-44 {
    padding-bottom: 11rem;
  }

  .md\:pl-44 {
    padding-left: 11rem;
  }

  .md\:pt-48 {
    padding-top: 12rem;
  }

  .md\:pr-48 {
    padding-right: 12rem;
  }

  .md\:pb-48 {
    padding-bottom: 12rem;
  }

  .md\:pl-48 {
    padding-left: 12rem;
  }

  .md\:pt-52 {
    padding-top: 13rem;
  }

  .md\:pr-52 {
    padding-right: 13rem;
  }

  .md\:pb-52 {
    padding-bottom: 13rem;
  }

  .md\:pl-52 {
    padding-left: 13rem;
  }

  .md\:pt-56 {
    padding-top: 14rem;
  }

  .md\:pr-56 {
    padding-right: 14rem;
  }

  .md\:pb-56 {
    padding-bottom: 14rem;
  }

  .md\:pl-56 {
    padding-left: 14rem;
  }

  .md\:pt-60 {
    padding-top: 15rem;
  }

  .md\:pr-60 {
    padding-right: 15rem;
  }

  .md\:pb-60 {
    padding-bottom: 15rem;
  }

  .md\:pl-60 {
    padding-left: 15rem;
  }

  .md\:pt-64 {
    padding-top: 16rem;
  }

  .md\:pr-64 {
    padding-right: 16rem;
  }

  .md\:pb-64 {
    padding-bottom: 16rem;
  }

  .md\:pl-64 {
    padding-left: 16rem;
  }

  .md\:pt-72 {
    padding-top: 18rem;
  }

  .md\:pr-72 {
    padding-right: 18rem;
  }

  .md\:pb-72 {
    padding-bottom: 18rem;
  }

  .md\:pl-72 {
    padding-left: 18rem;
  }

  .md\:pt-80 {
    padding-top: 20rem;
  }

  .md\:pr-80 {
    padding-right: 20rem;
  }

  .md\:pb-80 {
    padding-bottom: 20rem;
  }

  .md\:pl-80 {
    padding-left: 20rem;
  }

  .md\:pt-96 {
    padding-top: 24rem;
  }

  .md\:pr-96 {
    padding-right: 24rem;
  }

  .md\:pb-96 {
    padding-bottom: 24rem;
  }

  .md\:pl-96 {
    padding-left: 24rem;
  }

  .md\:pt-px {
    padding-top: 1px;
  }

  .md\:pr-px {
    padding-right: 1px;
  }

  .md\:pb-px {
    padding-bottom: 1px;
  }

  .md\:pl-px {
    padding-left: 1px;
  }

  .md\:pt-0\.5 {
    padding-top: .125rem;
  }

  .md\:pr-0\.5 {
    padding-right: .125rem;
  }

  .md\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .md\:pl-0\.5 {
    padding-left: .125rem;
  }

  .md\:pt-1\.5 {
    padding-top: .375rem;
  }

  .md\:pr-1\.5 {
    padding-right: .375rem;
  }

  .md\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .md\:pl-1\.5 {
    padding-left: .375rem;
  }

  .md\:pt-2\.5 {
    padding-top: .625rem;
  }

  .md\:pr-2\.5 {
    padding-right: .625rem;
  }

  .md\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .md\:pl-2\.5 {
    padding-left: .625rem;
  }

  .md\:pt-3\.5 {
    padding-top: .875rem;
  }

  .md\:pr-3\.5 {
    padding-right: .875rem;
  }

  .md\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .md\:pl-3\.5 {
    padding-left: .875rem;
  }

  .md\:pt-1\/2 {
    padding-top: 50%;
  }

  .md\:pr-1\/2 {
    padding-right: 50%;
  }

  .md\:pb-1\/2 {
    padding-bottom: 50%;
  }

  .md\:pl-1\/2 {
    padding-left: 50%;
  }

  .md\:pt-1\/3 {
    padding-top: 33.3333%;
  }

  .md\:pr-1\/3 {
    padding-right: 33.3333%;
  }

  .md\:pb-1\/3 {
    padding-bottom: 33.3333%;
  }

  .md\:pl-1\/3 {
    padding-left: 33.3333%;
  }

  .md\:pt-2\/3 {
    padding-top: 66.6667%;
  }

  .md\:pr-2\/3 {
    padding-right: 66.6667%;
  }

  .md\:pb-2\/3 {
    padding-bottom: 66.6667%;
  }

  .md\:pl-2\/3 {
    padding-left: 66.6667%;
  }

  .md\:pt-1\/4 {
    padding-top: 25%;
  }

  .md\:pr-1\/4 {
    padding-right: 25%;
  }

  .md\:pb-1\/4 {
    padding-bottom: 25%;
  }

  .md\:pl-1\/4 {
    padding-left: 25%;
  }

  .md\:pt-2\/4 {
    padding-top: 50%;
  }

  .md\:pr-2\/4 {
    padding-right: 50%;
  }

  .md\:pb-2\/4 {
    padding-bottom: 50%;
  }

  .md\:pl-2\/4 {
    padding-left: 50%;
  }

  .md\:pt-3\/4 {
    padding-top: 75%;
  }

  .md\:pr-3\/4 {
    padding-right: 75%;
  }

  .md\:pb-3\/4 {
    padding-bottom: 75%;
  }

  .md\:pl-3\/4 {
    padding-left: 75%;
  }

  .md\:pt-1\/5 {
    padding-top: 20%;
  }

  .md\:pr-1\/5 {
    padding-right: 20%;
  }

  .md\:pb-1\/5 {
    padding-bottom: 20%;
  }

  .md\:pl-1\/5 {
    padding-left: 20%;
  }

  .md\:pt-2\/5 {
    padding-top: 40%;
  }

  .md\:pr-2\/5 {
    padding-right: 40%;
  }

  .md\:pb-2\/5 {
    padding-bottom: 40%;
  }

  .md\:pl-2\/5 {
    padding-left: 40%;
  }

  .md\:pt-3\/5 {
    padding-top: 60%;
  }

  .md\:pr-3\/5 {
    padding-right: 60%;
  }

  .md\:pb-3\/5 {
    padding-bottom: 60%;
  }

  .md\:pl-3\/5 {
    padding-left: 60%;
  }

  .md\:pt-4\/5 {
    padding-top: 80%;
  }

  .md\:pr-4\/5 {
    padding-right: 80%;
  }

  .md\:pb-4\/5 {
    padding-bottom: 80%;
  }

  .md\:pl-4\/5 {
    padding-left: 80%;
  }

  .md\:pt-1\/6 {
    padding-top: 16.6667%;
  }

  .md\:pr-1\/6 {
    padding-right: 16.6667%;
  }

  .md\:pb-1\/6 {
    padding-bottom: 16.6667%;
  }

  .md\:pl-1\/6 {
    padding-left: 16.6667%;
  }

  .md\:pt-2\/6 {
    padding-top: 33.3333%;
  }

  .md\:pr-2\/6 {
    padding-right: 33.3333%;
  }

  .md\:pb-2\/6 {
    padding-bottom: 33.3333%;
  }

  .md\:pl-2\/6 {
    padding-left: 33.3333%;
  }

  .md\:pt-3\/6 {
    padding-top: 50%;
  }

  .md\:pr-3\/6 {
    padding-right: 50%;
  }

  .md\:pb-3\/6 {
    padding-bottom: 50%;
  }

  .md\:pl-3\/6 {
    padding-left: 50%;
  }

  .md\:pt-4\/6 {
    padding-top: 66.6667%;
  }

  .md\:pr-4\/6 {
    padding-right: 66.6667%;
  }

  .md\:pb-4\/6 {
    padding-bottom: 66.6667%;
  }

  .md\:pl-4\/6 {
    padding-left: 66.6667%;
  }

  .md\:pt-5\/6 {
    padding-top: 83.3333%;
  }

  .md\:pr-5\/6 {
    padding-right: 83.3333%;
  }

  .md\:pb-5\/6 {
    padding-bottom: 83.3333%;
  }

  .md\:pl-5\/6 {
    padding-left: 83.3333%;
  }

  .md\:pt-1\/12 {
    padding-top: 8.33333%;
  }

  .md\:pr-1\/12 {
    padding-right: 8.33333%;
  }

  .md\:pb-1\/12 {
    padding-bottom: 8.33333%;
  }

  .md\:pl-1\/12 {
    padding-left: 8.33333%;
  }

  .md\:pt-2\/12 {
    padding-top: 16.6667%;
  }

  .md\:pr-2\/12 {
    padding-right: 16.6667%;
  }

  .md\:pb-2\/12 {
    padding-bottom: 16.6667%;
  }

  .md\:pl-2\/12 {
    padding-left: 16.6667%;
  }

  .md\:pt-3\/12 {
    padding-top: 25%;
  }

  .md\:pr-3\/12 {
    padding-right: 25%;
  }

  .md\:pb-3\/12 {
    padding-bottom: 25%;
  }

  .md\:pl-3\/12 {
    padding-left: 25%;
  }

  .md\:pt-4\/12 {
    padding-top: 33.3333%;
  }

  .md\:pr-4\/12 {
    padding-right: 33.3333%;
  }

  .md\:pb-4\/12 {
    padding-bottom: 33.3333%;
  }

  .md\:pl-4\/12 {
    padding-left: 33.3333%;
  }

  .md\:pt-5\/12 {
    padding-top: 41.6667%;
  }

  .md\:pr-5\/12 {
    padding-right: 41.6667%;
  }

  .md\:pb-5\/12 {
    padding-bottom: 41.6667%;
  }

  .md\:pl-5\/12 {
    padding-left: 41.6667%;
  }

  .md\:pt-6\/12 {
    padding-top: 50%;
  }

  .md\:pr-6\/12 {
    padding-right: 50%;
  }

  .md\:pb-6\/12 {
    padding-bottom: 50%;
  }

  .md\:pl-6\/12 {
    padding-left: 50%;
  }

  .md\:pt-7\/12 {
    padding-top: 58.3333%;
  }

  .md\:pr-7\/12 {
    padding-right: 58.3333%;
  }

  .md\:pb-7\/12 {
    padding-bottom: 58.3333%;
  }

  .md\:pl-7\/12 {
    padding-left: 58.3333%;
  }

  .md\:pt-8\/12 {
    padding-top: 66.6667%;
  }

  .md\:pr-8\/12 {
    padding-right: 66.6667%;
  }

  .md\:pb-8\/12 {
    padding-bottom: 66.6667%;
  }

  .md\:pl-8\/12 {
    padding-left: 66.6667%;
  }

  .md\:pt-9\/12 {
    padding-top: 75%;
  }

  .md\:pr-9\/12 {
    padding-right: 75%;
  }

  .md\:pb-9\/12 {
    padding-bottom: 75%;
  }

  .md\:pl-9\/12 {
    padding-left: 75%;
  }

  .md\:pt-10\/12 {
    padding-top: 83.3333%;
  }

  .md\:pr-10\/12 {
    padding-right: 83.3333%;
  }

  .md\:pb-10\/12 {
    padding-bottom: 83.3333%;
  }

  .md\:pl-10\/12 {
    padding-left: 83.3333%;
  }

  .md\:pt-11\/12 {
    padding-top: 91.6667%;
  }

  .md\:pr-11\/12 {
    padding-right: 91.6667%;
  }

  .md\:pb-11\/12 {
    padding-bottom: 91.6667%;
  }

  .md\:pl-11\/12 {
    padding-left: 91.6667%;
  }

  .md\:pt-full {
    padding-top: 100%;
  }

  .md\:pr-full {
    padding-right: 100%;
  }

  .md\:pb-full {
    padding-bottom: 100%;
  }

  .md\:pl-full {
    padding-left: 100%;
  }

  .md\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .md\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity) );
  }

  .md\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--placeholder-opacity) );
  }

  .md\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity) );
  }

  .md\:placeholder-cool-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity) );
  }

  .md\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-50::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity) );
  }

  .md\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity) );
  }

  .md\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-50::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity) );
  }

  .md\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-50::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity) );
  }

  .md\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-50::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity) );
  }

  .md\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-50::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity) );
  }

  .md\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-50::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity) );
  }

  .md\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity) );
  }

  .md\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .md\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-cool-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity) );
  }

  .md\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity) );
  }

  .md\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .md\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: .25;
  }

  .md\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: .5;
  }

  .md\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: .75;
  }

  .md\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: .25;
  }

  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: .5;
  }

  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: .75;
  }

  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .md\:pointer-events-none {
    pointer-events: none;
  }

  .md\:pointer-events-auto {
    pointer-events: auto;
  }

  .md\:static {
    position: static;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:absolute {
    position: absolute;
  }

  .md\:relative {
    position: relative;
  }

  .md\:sticky {
    position: sticky;
  }

  .md\:inset-0 {
    inset: 0;
  }

  .md\:inset-1 {
    inset: .25rem;
  }

  .md\:inset-2 {
    inset: .5rem;
  }

  .md\:inset-3 {
    inset: .75rem;
  }

  .md\:inset-4 {
    inset: 1rem;
  }

  .md\:inset-5 {
    inset: 1.25rem;
  }

  .md\:inset-6 {
    inset: 1.5rem;
  }

  .md\:inset-7 {
    inset: 1.75rem;
  }

  .md\:inset-8 {
    inset: 2rem;
  }

  .md\:inset-9 {
    inset: 2.25rem;
  }

  .md\:inset-10 {
    inset: 2.5rem;
  }

  .md\:inset-11 {
    inset: 2.75rem;
  }

  .md\:inset-12 {
    inset: 3rem;
  }

  .md\:inset-13 {
    inset: 3.25rem;
  }

  .md\:inset-14 {
    inset: 3.5rem;
  }

  .md\:inset-15 {
    inset: 3.75rem;
  }

  .md\:inset-16 {
    inset: 4rem;
  }

  .md\:inset-20 {
    inset: 5rem;
  }

  .md\:inset-24 {
    inset: 6rem;
  }

  .md\:inset-28 {
    inset: 7rem;
  }

  .md\:inset-32 {
    inset: 8rem;
  }

  .md\:inset-36 {
    inset: 9rem;
  }

  .md\:inset-40 {
    inset: 10rem;
  }

  .md\:inset-44 {
    inset: 11rem;
  }

  .md\:inset-48 {
    inset: 12rem;
  }

  .md\:inset-52 {
    inset: 13rem;
  }

  .md\:inset-56 {
    inset: 14rem;
  }

  .md\:inset-60 {
    inset: 15rem;
  }

  .md\:inset-64 {
    inset: 16rem;
  }

  .md\:inset-72 {
    inset: 18rem;
  }

  .md\:inset-80 {
    inset: 20rem;
  }

  .md\:inset-96 {
    inset: 24rem;
  }

  .md\:inset-auto {
    inset: auto;
  }

  .md\:inset-px {
    inset: 1px;
  }

  .md\:inset-0\.5 {
    inset: .125rem;
  }

  .md\:inset-1\.5 {
    inset: .375rem;
  }

  .md\:inset-2\.5 {
    inset: .625rem;
  }

  .md\:inset-3\.5 {
    inset: .875rem;
  }

  .md\:inset-1\/2 {
    inset: 50%;
  }

  .md\:inset-1\/3 {
    inset: 33.3333%;
  }

  .md\:inset-2\/3 {
    inset: 66.6667%;
  }

  .md\:inset-1\/4 {
    inset: 25%;
  }

  .md\:inset-2\/4 {
    inset: 50%;
  }

  .md\:inset-3\/4 {
    inset: 75%;
  }

  .md\:inset-1\/5 {
    inset: 20%;
  }

  .md\:inset-2\/5 {
    inset: 40%;
  }

  .md\:inset-3\/5 {
    inset: 60%;
  }

  .md\:inset-4\/5 {
    inset: 80%;
  }

  .md\:inset-1\/6 {
    inset: 16.6667%;
  }

  .md\:inset-2\/6 {
    inset: 33.3333%;
  }

  .md\:inset-3\/6 {
    inset: 50%;
  }

  .md\:inset-4\/6 {
    inset: 66.6667%;
  }

  .md\:inset-5\/6 {
    inset: 83.3333%;
  }

  .md\:inset-1\/12 {
    inset: 8.33333%;
  }

  .md\:inset-2\/12 {
    inset: 16.6667%;
  }

  .md\:inset-3\/12 {
    inset: 25%;
  }

  .md\:inset-4\/12 {
    inset: 33.3333%;
  }

  .md\:inset-5\/12 {
    inset: 41.6667%;
  }

  .md\:inset-6\/12 {
    inset: 50%;
  }

  .md\:inset-7\/12 {
    inset: 58.3333%;
  }

  .md\:inset-8\/12 {
    inset: 66.6667%;
  }

  .md\:inset-9\/12 {
    inset: 75%;
  }

  .md\:inset-10\/12 {
    inset: 83.3333%;
  }

  .md\:inset-11\/12 {
    inset: 91.6667%;
  }

  .md\:inset-full {
    inset: 100%;
  }

  .md\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .md\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .md\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .md\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .md\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .md\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .md\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .md\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .md\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .md\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .md\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .md\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .md\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .md\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .md\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .md\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .md\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .md\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .md\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .md\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .md\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .md\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .md\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .md\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .md\:inset-y-13 {
    top: 3.25rem;
    bottom: 3.25rem;
  }

  .md\:inset-x-13 {
    left: 3.25rem;
    right: 3.25rem;
  }

  .md\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .md\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .md\:inset-y-15 {
    top: 3.75rem;
    bottom: 3.75rem;
  }

  .md\:inset-x-15 {
    left: 3.75rem;
    right: 3.75rem;
  }

  .md\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .md\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .md\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .md\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .md\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .md\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .md\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .md\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .md\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .md\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .md\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .md\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .md\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .md\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .md\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .md\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .md\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .md\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .md\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .md\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .md\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .md\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .md\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .md\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .md\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .md\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .md\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .md\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .md\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .md\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .md\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .md\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .md\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .md\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .md\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .md\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .md\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .md\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .md\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .md\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .md\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .md\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .md\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .md\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .md\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .md\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .md\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .md\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .md\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .md\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .md\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .md\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .md\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .md\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .md\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .md\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .md\:inset-y-1\/5 {
    top: 20%;
    bottom: 20%;
  }

  .md\:inset-x-1\/5 {
    left: 20%;
    right: 20%;
  }

  .md\:inset-y-2\/5 {
    top: 40%;
    bottom: 40%;
  }

  .md\:inset-x-2\/5 {
    left: 40%;
    right: 40%;
  }

  .md\:inset-y-3\/5 {
    top: 60%;
    bottom: 60%;
  }

  .md\:inset-x-3\/5 {
    left: 60%;
    right: 60%;
  }

  .md\:inset-y-4\/5 {
    top: 80%;
    bottom: 80%;
  }

  .md\:inset-x-4\/5 {
    left: 80%;
    right: 80%;
  }

  .md\:inset-y-1\/6 {
    top: 16.6667%;
    bottom: 16.6667%;
  }

  .md\:inset-x-1\/6 {
    left: 16.6667%;
    right: 16.6667%;
  }

  .md\:inset-y-2\/6 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .md\:inset-x-2\/6 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .md\:inset-y-3\/6 {
    top: 50%;
    bottom: 50%;
  }

  .md\:inset-x-3\/6 {
    left: 50%;
    right: 50%;
  }

  .md\:inset-y-4\/6 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .md\:inset-x-4\/6 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .md\:inset-y-5\/6 {
    top: 83.3333%;
    bottom: 83.3333%;
  }

  .md\:inset-x-5\/6 {
    left: 83.3333%;
    right: 83.3333%;
  }

  .md\:inset-y-1\/12 {
    top: 8.33333%;
    bottom: 8.33333%;
  }

  .md\:inset-x-1\/12 {
    left: 8.33333%;
    right: 8.33333%;
  }

  .md\:inset-y-2\/12 {
    top: 16.6667%;
    bottom: 16.6667%;
  }

  .md\:inset-x-2\/12 {
    left: 16.6667%;
    right: 16.6667%;
  }

  .md\:inset-y-3\/12 {
    top: 25%;
    bottom: 25%;
  }

  .md\:inset-x-3\/12 {
    left: 25%;
    right: 25%;
  }

  .md\:inset-y-4\/12 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .md\:inset-x-4\/12 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .md\:inset-y-5\/12 {
    top: 41.6667%;
    bottom: 41.6667%;
  }

  .md\:inset-x-5\/12 {
    left: 41.6667%;
    right: 41.6667%;
  }

  .md\:inset-y-6\/12 {
    top: 50%;
    bottom: 50%;
  }

  .md\:inset-x-6\/12 {
    left: 50%;
    right: 50%;
  }

  .md\:inset-y-7\/12 {
    top: 58.3333%;
    bottom: 58.3333%;
  }

  .md\:inset-x-7\/12 {
    left: 58.3333%;
    right: 58.3333%;
  }

  .md\:inset-y-8\/12 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .md\:inset-x-8\/12 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .md\:inset-y-9\/12 {
    top: 75%;
    bottom: 75%;
  }

  .md\:inset-x-9\/12 {
    left: 75%;
    right: 75%;
  }

  .md\:inset-y-10\/12 {
    top: 83.3333%;
    bottom: 83.3333%;
  }

  .md\:inset-x-10\/12 {
    left: 83.3333%;
    right: 83.3333%;
  }

  .md\:inset-y-11\/12 {
    top: 91.6667%;
    bottom: 91.6667%;
  }

  .md\:inset-x-11\/12 {
    left: 91.6667%;
    right: 91.6667%;
  }

  .md\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .md\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .md\:top-0 {
    top: 0;
  }

  .md\:right-0 {
    right: 0;
  }

  .md\:bottom-0 {
    bottom: 0;
  }

  .md\:left-0 {
    left: 0;
  }

  .md\:top-1 {
    top: .25rem;
  }

  .md\:right-1 {
    right: .25rem;
  }

  .md\:bottom-1 {
    bottom: .25rem;
  }

  .md\:left-1 {
    left: .25rem;
  }

  .md\:top-2 {
    top: .5rem;
  }

  .md\:right-2 {
    right: .5rem;
  }

  .md\:bottom-2 {
    bottom: .5rem;
  }

  .md\:left-2 {
    left: .5rem;
  }

  .md\:top-3 {
    top: .75rem;
  }

  .md\:right-3 {
    right: .75rem;
  }

  .md\:bottom-3 {
    bottom: .75rem;
  }

  .md\:left-3 {
    left: .75rem;
  }

  .md\:top-4 {
    top: 1rem;
  }

  .md\:right-4 {
    right: 1rem;
  }

  .md\:bottom-4 {
    bottom: 1rem;
  }

  .md\:left-4 {
    left: 1rem;
  }

  .md\:top-5 {
    top: 1.25rem;
  }

  .md\:right-5 {
    right: 1.25rem;
  }

  .md\:bottom-5 {
    bottom: 1.25rem;
  }

  .md\:left-5 {
    left: 1.25rem;
  }

  .md\:top-6 {
    top: 1.5rem;
  }

  .md\:right-6 {
    right: 1.5rem;
  }

  .md\:bottom-6 {
    bottom: 1.5rem;
  }

  .md\:left-6 {
    left: 1.5rem;
  }

  .md\:top-7 {
    top: 1.75rem;
  }

  .md\:right-7 {
    right: 1.75rem;
  }

  .md\:bottom-7 {
    bottom: 1.75rem;
  }

  .md\:left-7 {
    left: 1.75rem;
  }

  .md\:top-8 {
    top: 2rem;
  }

  .md\:right-8 {
    right: 2rem;
  }

  .md\:bottom-8 {
    bottom: 2rem;
  }

  .md\:left-8 {
    left: 2rem;
  }

  .md\:top-9 {
    top: 2.25rem;
  }

  .md\:right-9 {
    right: 2.25rem;
  }

  .md\:bottom-9 {
    bottom: 2.25rem;
  }

  .md\:left-9 {
    left: 2.25rem;
  }

  .md\:top-10 {
    top: 2.5rem;
  }

  .md\:right-10 {
    right: 2.5rem;
  }

  .md\:bottom-10 {
    bottom: 2.5rem;
  }

  .md\:left-10 {
    left: 2.5rem;
  }

  .md\:top-11 {
    top: 2.75rem;
  }

  .md\:right-11 {
    right: 2.75rem;
  }

  .md\:bottom-11 {
    bottom: 2.75rem;
  }

  .md\:left-11 {
    left: 2.75rem;
  }

  .md\:top-12 {
    top: 3rem;
  }

  .md\:right-12 {
    right: 3rem;
  }

  .md\:bottom-12 {
    bottom: 3rem;
  }

  .md\:left-12 {
    left: 3rem;
  }

  .md\:top-13 {
    top: 3.25rem;
  }

  .md\:right-13 {
    right: 3.25rem;
  }

  .md\:bottom-13 {
    bottom: 3.25rem;
  }

  .md\:left-13 {
    left: 3.25rem;
  }

  .md\:top-14 {
    top: 3.5rem;
  }

  .md\:right-14 {
    right: 3.5rem;
  }

  .md\:bottom-14 {
    bottom: 3.5rem;
  }

  .md\:left-14 {
    left: 3.5rem;
  }

  .md\:top-15 {
    top: 3.75rem;
  }

  .md\:right-15 {
    right: 3.75rem;
  }

  .md\:bottom-15 {
    bottom: 3.75rem;
  }

  .md\:left-15 {
    left: 3.75rem;
  }

  .md\:top-16 {
    top: 4rem;
  }

  .md\:right-16 {
    right: 4rem;
  }

  .md\:bottom-16 {
    bottom: 4rem;
  }

  .md\:left-16 {
    left: 4rem;
  }

  .md\:top-20 {
    top: 5rem;
  }

  .md\:right-20 {
    right: 5rem;
  }

  .md\:bottom-20 {
    bottom: 5rem;
  }

  .md\:left-20 {
    left: 5rem;
  }

  .md\:top-24 {
    top: 6rem;
  }

  .md\:right-24 {
    right: 6rem;
  }

  .md\:bottom-24 {
    bottom: 6rem;
  }

  .md\:left-24 {
    left: 6rem;
  }

  .md\:top-28 {
    top: 7rem;
  }

  .md\:right-28 {
    right: 7rem;
  }

  .md\:bottom-28 {
    bottom: 7rem;
  }

  .md\:left-28 {
    left: 7rem;
  }

  .md\:top-32 {
    top: 8rem;
  }

  .md\:right-32 {
    right: 8rem;
  }

  .md\:bottom-32 {
    bottom: 8rem;
  }

  .md\:left-32 {
    left: 8rem;
  }

  .md\:top-36 {
    top: 9rem;
  }

  .md\:right-36 {
    right: 9rem;
  }

  .md\:bottom-36 {
    bottom: 9rem;
  }

  .md\:left-36 {
    left: 9rem;
  }

  .md\:top-40 {
    top: 10rem;
  }

  .md\:right-40 {
    right: 10rem;
  }

  .md\:bottom-40 {
    bottom: 10rem;
  }

  .md\:left-40 {
    left: 10rem;
  }

  .md\:top-44 {
    top: 11rem;
  }

  .md\:right-44 {
    right: 11rem;
  }

  .md\:bottom-44 {
    bottom: 11rem;
  }

  .md\:left-44 {
    left: 11rem;
  }

  .md\:top-48 {
    top: 12rem;
  }

  .md\:right-48 {
    right: 12rem;
  }

  .md\:bottom-48 {
    bottom: 12rem;
  }

  .md\:left-48 {
    left: 12rem;
  }

  .md\:top-52 {
    top: 13rem;
  }

  .md\:right-52 {
    right: 13rem;
  }

  .md\:bottom-52 {
    bottom: 13rem;
  }

  .md\:left-52 {
    left: 13rem;
  }

  .md\:top-56 {
    top: 14rem;
  }

  .md\:right-56 {
    right: 14rem;
  }

  .md\:bottom-56 {
    bottom: 14rem;
  }

  .md\:left-56 {
    left: 14rem;
  }

  .md\:top-60 {
    top: 15rem;
  }

  .md\:right-60 {
    right: 15rem;
  }

  .md\:bottom-60 {
    bottom: 15rem;
  }

  .md\:left-60 {
    left: 15rem;
  }

  .md\:top-64 {
    top: 16rem;
  }

  .md\:right-64 {
    right: 16rem;
  }

  .md\:bottom-64 {
    bottom: 16rem;
  }

  .md\:left-64 {
    left: 16rem;
  }

  .md\:top-72 {
    top: 18rem;
  }

  .md\:right-72 {
    right: 18rem;
  }

  .md\:bottom-72 {
    bottom: 18rem;
  }

  .md\:left-72 {
    left: 18rem;
  }

  .md\:top-80 {
    top: 20rem;
  }

  .md\:right-80 {
    right: 20rem;
  }

  .md\:bottom-80 {
    bottom: 20rem;
  }

  .md\:left-80 {
    left: 20rem;
  }

  .md\:top-96 {
    top: 24rem;
  }

  .md\:right-96 {
    right: 24rem;
  }

  .md\:bottom-96 {
    bottom: 24rem;
  }

  .md\:left-96 {
    left: 24rem;
  }

  .md\:top-auto {
    top: auto;
  }

  .md\:right-auto {
    right: auto;
  }

  .md\:bottom-auto {
    bottom: auto;
  }

  .md\:left-auto {
    left: auto;
  }

  .md\:top-px {
    top: 1px;
  }

  .md\:right-px {
    right: 1px;
  }

  .md\:bottom-px {
    bottom: 1px;
  }

  .md\:left-px {
    left: 1px;
  }

  .md\:top-0\.5 {
    top: .125rem;
  }

  .md\:right-0\.5 {
    right: .125rem;
  }

  .md\:bottom-0\.5 {
    bottom: .125rem;
  }

  .md\:left-0\.5 {
    left: .125rem;
  }

  .md\:top-1\.5 {
    top: .375rem;
  }

  .md\:right-1\.5 {
    right: .375rem;
  }

  .md\:bottom-1\.5 {
    bottom: .375rem;
  }

  .md\:left-1\.5 {
    left: .375rem;
  }

  .md\:top-2\.5 {
    top: .625rem;
  }

  .md\:right-2\.5 {
    right: .625rem;
  }

  .md\:bottom-2\.5 {
    bottom: .625rem;
  }

  .md\:left-2\.5 {
    left: .625rem;
  }

  .md\:top-3\.5 {
    top: .875rem;
  }

  .md\:right-3\.5 {
    right: .875rem;
  }

  .md\:bottom-3\.5 {
    bottom: .875rem;
  }

  .md\:left-3\.5 {
    left: .875rem;
  }

  .md\:top-1\/2 {
    top: 50%;
  }

  .md\:right-1\/2 {
    right: 50%;
  }

  .md\:bottom-1\/2 {
    bottom: 50%;
  }

  .md\:left-1\/2 {
    left: 50%;
  }

  .md\:top-1\/3 {
    top: 33.3333%;
  }

  .md\:right-1\/3 {
    right: 33.3333%;
  }

  .md\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .md\:left-1\/3 {
    left: 33.3333%;
  }

  .md\:top-2\/3 {
    top: 66.6667%;
  }

  .md\:right-2\/3 {
    right: 66.6667%;
  }

  .md\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .md\:left-2\/3 {
    left: 66.6667%;
  }

  .md\:top-1\/4 {
    top: 25%;
  }

  .md\:right-1\/4 {
    right: 25%;
  }

  .md\:bottom-1\/4 {
    bottom: 25%;
  }

  .md\:left-1\/4 {
    left: 25%;
  }

  .md\:top-2\/4 {
    top: 50%;
  }

  .md\:right-2\/4 {
    right: 50%;
  }

  .md\:bottom-2\/4 {
    bottom: 50%;
  }

  .md\:left-2\/4 {
    left: 50%;
  }

  .md\:top-3\/4 {
    top: 75%;
  }

  .md\:right-3\/4 {
    right: 75%;
  }

  .md\:bottom-3\/4 {
    bottom: 75%;
  }

  .md\:left-3\/4 {
    left: 75%;
  }

  .md\:top-1\/5 {
    top: 20%;
  }

  .md\:right-1\/5 {
    right: 20%;
  }

  .md\:bottom-1\/5 {
    bottom: 20%;
  }

  .md\:left-1\/5 {
    left: 20%;
  }

  .md\:top-2\/5 {
    top: 40%;
  }

  .md\:right-2\/5 {
    right: 40%;
  }

  .md\:bottom-2\/5 {
    bottom: 40%;
  }

  .md\:left-2\/5 {
    left: 40%;
  }

  .md\:top-3\/5 {
    top: 60%;
  }

  .md\:right-3\/5 {
    right: 60%;
  }

  .md\:bottom-3\/5 {
    bottom: 60%;
  }

  .md\:left-3\/5 {
    left: 60%;
  }

  .md\:top-4\/5 {
    top: 80%;
  }

  .md\:right-4\/5 {
    right: 80%;
  }

  .md\:bottom-4\/5 {
    bottom: 80%;
  }

  .md\:left-4\/5 {
    left: 80%;
  }

  .md\:top-1\/6 {
    top: 16.6667%;
  }

  .md\:right-1\/6 {
    right: 16.6667%;
  }

  .md\:bottom-1\/6 {
    bottom: 16.6667%;
  }

  .md\:left-1\/6 {
    left: 16.6667%;
  }

  .md\:top-2\/6 {
    top: 33.3333%;
  }

  .md\:right-2\/6 {
    right: 33.3333%;
  }

  .md\:bottom-2\/6 {
    bottom: 33.3333%;
  }

  .md\:left-2\/6 {
    left: 33.3333%;
  }

  .md\:top-3\/6 {
    top: 50%;
  }

  .md\:right-3\/6 {
    right: 50%;
  }

  .md\:bottom-3\/6 {
    bottom: 50%;
  }

  .md\:left-3\/6 {
    left: 50%;
  }

  .md\:top-4\/6 {
    top: 66.6667%;
  }

  .md\:right-4\/6 {
    right: 66.6667%;
  }

  .md\:bottom-4\/6 {
    bottom: 66.6667%;
  }

  .md\:left-4\/6 {
    left: 66.6667%;
  }

  .md\:top-5\/6 {
    top: 83.3333%;
  }

  .md\:right-5\/6 {
    right: 83.3333%;
  }

  .md\:bottom-5\/6 {
    bottom: 83.3333%;
  }

  .md\:left-5\/6 {
    left: 83.3333%;
  }

  .md\:top-1\/12 {
    top: 8.33333%;
  }

  .md\:right-1\/12 {
    right: 8.33333%;
  }

  .md\:bottom-1\/12 {
    bottom: 8.33333%;
  }

  .md\:left-1\/12 {
    left: 8.33333%;
  }

  .md\:top-2\/12 {
    top: 16.6667%;
  }

  .md\:right-2\/12 {
    right: 16.6667%;
  }

  .md\:bottom-2\/12 {
    bottom: 16.6667%;
  }

  .md\:left-2\/12 {
    left: 16.6667%;
  }

  .md\:top-3\/12 {
    top: 25%;
  }

  .md\:right-3\/12 {
    right: 25%;
  }

  .md\:bottom-3\/12 {
    bottom: 25%;
  }

  .md\:left-3\/12 {
    left: 25%;
  }

  .md\:top-4\/12 {
    top: 33.3333%;
  }

  .md\:right-4\/12 {
    right: 33.3333%;
  }

  .md\:bottom-4\/12 {
    bottom: 33.3333%;
  }

  .md\:left-4\/12 {
    left: 33.3333%;
  }

  .md\:top-5\/12 {
    top: 41.6667%;
  }

  .md\:right-5\/12 {
    right: 41.6667%;
  }

  .md\:bottom-5\/12 {
    bottom: 41.6667%;
  }

  .md\:left-5\/12 {
    left: 41.6667%;
  }

  .md\:top-6\/12 {
    top: 50%;
  }

  .md\:right-6\/12 {
    right: 50%;
  }

  .md\:bottom-6\/12 {
    bottom: 50%;
  }

  .md\:left-6\/12 {
    left: 50%;
  }

  .md\:top-7\/12 {
    top: 58.3333%;
  }

  .md\:right-7\/12 {
    right: 58.3333%;
  }

  .md\:bottom-7\/12 {
    bottom: 58.3333%;
  }

  .md\:left-7\/12 {
    left: 58.3333%;
  }

  .md\:top-8\/12 {
    top: 66.6667%;
  }

  .md\:right-8\/12 {
    right: 66.6667%;
  }

  .md\:bottom-8\/12 {
    bottom: 66.6667%;
  }

  .md\:left-8\/12 {
    left: 66.6667%;
  }

  .md\:top-9\/12 {
    top: 75%;
  }

  .md\:right-9\/12 {
    right: 75%;
  }

  .md\:bottom-9\/12 {
    bottom: 75%;
  }

  .md\:left-9\/12 {
    left: 75%;
  }

  .md\:top-10\/12 {
    top: 83.3333%;
  }

  .md\:right-10\/12 {
    right: 83.3333%;
  }

  .md\:bottom-10\/12 {
    bottom: 83.3333%;
  }

  .md\:left-10\/12 {
    left: 83.3333%;
  }

  .md\:top-11\/12 {
    top: 91.6667%;
  }

  .md\:right-11\/12 {
    right: 91.6667%;
  }

  .md\:bottom-11\/12 {
    bottom: 91.6667%;
  }

  .md\:left-11\/12 {
    left: 91.6667%;
  }

  .md\:top-full {
    top: 100%;
  }

  .md\:right-full {
    right: 100%;
  }

  .md\:bottom-full {
    bottom: 100%;
  }

  .md\:left-full {
    left: 100%;
  }

  .md\:resize-none {
    resize: none;
  }

  .md\:resize-y {
    resize: vertical;
  }

  .md\:resize-x {
    resize: horizontal;
  }

  .md\:resize {
    resize: both;
  }

  .md\:shadow-xs {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .md\:shadow-sm {
    box-shadow: 0 1px 2px #0000000d;
  }

  .md\:shadow {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .md\:shadow-md {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .md\:shadow-lg {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .md\:shadow-xl {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .md\:shadow-2xl {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .md\:shadow-inner {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .md\:shadow-outline {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .md\:shadow-none {
    box-shadow: none;
  }

  .md\:shadow-solid {
    box-shadow: 0 0 0 2px;
  }

  .md\:shadow-outline-gray {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .md\:shadow-outline-blue {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .md\:shadow-outline-teal {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .md\:shadow-outline-green {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .md\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .md\:shadow-outline-orange {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .md\:shadow-outline-red {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .md\:shadow-outline-pink {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .md\:shadow-outline-purple {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .md\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .group:focus .md\:group-focus\:shadow-xs {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .group:focus .md\:group-focus\:shadow-sm {
    box-shadow: 0 1px 2px #0000000d;
  }

  .group:focus .md\:group-focus\:shadow {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .group:focus .md\:group-focus\:shadow-md {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .group:focus .md\:group-focus\:shadow-lg {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .group:focus .md\:group-focus\:shadow-xl {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .group:focus .md\:group-focus\:shadow-2xl {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .group:focus .md\:group-focus\:shadow-inner {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .group:focus .md\:group-focus\:shadow-outline {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .group:focus .md\:group-focus\:shadow-none {
    box-shadow: none;
  }

  .group:focus .md\:group-focus\:shadow-solid {
    box-shadow: 0 0 0 2px;
  }

  .group:focus .md\:group-focus\:shadow-outline-gray {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .group:focus .md\:group-focus\:shadow-outline-blue {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .group:focus .md\:group-focus\:shadow-outline-teal {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .group:focus .md\:group-focus\:shadow-outline-green {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .group:focus .md\:group-focus\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .group:focus .md\:group-focus\:shadow-outline-orange {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .group:focus .md\:group-focus\:shadow-outline-red {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .group:focus .md\:group-focus\:shadow-outline-pink {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .group:focus .md\:group-focus\:shadow-outline-purple {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .group:focus .md\:group-focus\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .md\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .md\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px #0000000d;
  }

  .md\:hover\:shadow:hover {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .md\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .md\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .md\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .md\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .md\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .md\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .md\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .md\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px;
  }

  .md\:hover\:shadow-outline-gray:hover {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .md\:hover\:shadow-outline-blue:hover {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .md\:hover\:shadow-outline-teal:hover {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .md\:hover\:shadow-outline-green:hover {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .md\:hover\:shadow-outline-yellow:hover {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .md\:hover\:shadow-outline-orange:hover {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .md\:hover\:shadow-outline-red:hover {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .md\:hover\:shadow-outline-pink:hover {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .md\:hover\:shadow-outline-purple:hover {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .md\:hover\:shadow-outline-indigo:hover {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .md\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .md\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px #0000000d;
  }

  .md\:focus\:shadow:focus {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .md\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .md\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .md\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .md\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .md\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .md\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .md\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .md\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px;
  }

  .md\:focus\:shadow-outline-gray:focus {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .md\:focus\:shadow-outline-blue:focus {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .md\:focus\:shadow-outline-teal:focus {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .md\:focus\:shadow-outline-green:focus {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .md\:focus\:shadow-outline-yellow:focus {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .md\:focus\:shadow-outline-orange:focus {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .md\:focus\:shadow-outline-red:focus {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .md\:focus\:shadow-outline-pink:focus {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .md\:focus\:shadow-outline-purple:focus {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .md\:focus\:shadow-outline-indigo:focus {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .md\:fill-current {
    fill: currentColor;
  }

  .md\:stroke-current {
    stroke: currentColor;
  }

  .md\:stroke-0 {
    stroke-width: 0;
  }

  .md\:stroke-1 {
    stroke-width: 1px;
  }

  .md\:stroke-2 {
    stroke-width: 2px;
  }

  .md\:table-auto {
    table-layout: auto;
  }

  .md\:table-fixed {
    table-layout: fixed;
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:text-center {
    text-align: center;
  }

  .md\:text-right {
    text-align: right;
  }

  .md\:text-justify {
    text-align: justify;
  }

  .md\:text-transparent {
    color: #0000;
  }

  .md\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .md\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .md\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .md\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .md\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .md\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .md\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .md\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .md\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .md\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .md\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .md\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .md\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .md\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .md\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .md\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .md\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .md\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .md\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .md\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .md\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .md\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .md\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .md\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .md\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .md\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .md\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .md\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .md\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .md\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .md\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .md\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .md\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .md\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .md\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .md\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .md\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .md\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .md\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .md\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .md\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .md\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .md\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .md\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .md\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .md\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .md\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .md\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .md\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .md\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .md\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .md\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .md\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .md\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .md\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .md\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .md\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .md\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .md\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .md\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .md\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .md\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .md\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .md\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .md\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .md\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .md\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .md\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .md\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .md\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .md\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .md\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .md\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .md\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .md\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .md\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .md\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .md\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .md\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .md\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .md\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .md\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .md\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .md\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .md\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .md\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .md\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .md\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .md\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .md\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .md\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .md\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .md\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .md\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .md\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .md\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .md\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .md\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .md\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .md\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .md\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .md\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .md\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .md\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .md\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .md\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .md\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .md\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .md\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .md\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .md\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .md\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .md\:group-hover\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .group:hover .md\:group-hover\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-transparent {
    color: #0000;
  }

  .group:focus .md\:group-focus\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .group:focus .md\:group-focus\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .md\:hover\:text-transparent:hover {
    color: #0000;
  }

  .md\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .md\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .md\:hover\:text-gray-50:hover {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .md\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .md\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .md\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .md\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .md\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .md\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .md\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .md\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .md\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-50:hover {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-100:hover {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-200:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-300:hover {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-400:hover {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-500:hover {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-600:hover {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-700:hover {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-800:hover {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .md\:hover\:text-cool-gray-900:hover {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .md\:hover\:text-red-50:hover {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .md\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .md\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .md\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .md\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .md\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .md\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .md\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .md\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .md\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .md\:hover\:text-orange-50:hover {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .md\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .md\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .md\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .md\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .md\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .md\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .md\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .md\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .md\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-50:hover {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .md\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .md\:hover\:text-green-50:hover {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .md\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .md\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .md\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .md\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .md\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .md\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .md\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .md\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .md\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .md\:hover\:text-teal-50:hover {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .md\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .md\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .md\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .md\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .md\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .md\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .md\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .md\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .md\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .md\:hover\:text-blue-50:hover {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .md\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .md\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .md\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .md\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .md\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .md\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .md\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .md\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .md\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-50:hover {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .md\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .md\:hover\:text-purple-50:hover {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .md\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .md\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .md\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .md\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .md\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .md\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .md\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .md\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .md\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .md\:hover\:text-pink-50:hover {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .md\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .md\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .md\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .md\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .md\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .md\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .md\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .md\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .md\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .md\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .md\:focus-within\:text-white:focus-within {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .md\:focus-within\:text-black:focus-within {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-50:focus-within {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-100:focus-within {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-200:focus-within {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-300:focus-within {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-400:focus-within {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-500:focus-within {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-600:focus-within {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-700:focus-within {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-800:focus-within {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .md\:focus-within\:text-gray-900:focus-within {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-50:focus-within {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-100:focus-within {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-200:focus-within {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-300:focus-within {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-400:focus-within {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-500:focus-within {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-600:focus-within {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-700:focus-within {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-800:focus-within {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .md\:focus-within\:text-cool-gray-900:focus-within {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-100:focus-within {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-200:focus-within {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-300:focus-within {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-400:focus-within {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-500:focus-within {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-600:focus-within {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-700:focus-within {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-800:focus-within {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .md\:focus-within\:text-red-900:focus-within {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-50:focus-within {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-100:focus-within {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-200:focus-within {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-300:focus-within {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-400:focus-within {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-500:focus-within {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-600:focus-within {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-700:focus-within {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-800:focus-within {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .md\:focus-within\:text-orange-900:focus-within {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-50:focus-within {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-100:focus-within {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-200:focus-within {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-300:focus-within {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-400:focus-within {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-500:focus-within {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-600:focus-within {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-700:focus-within {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-800:focus-within {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .md\:focus-within\:text-yellow-900:focus-within {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-50:focus-within {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-100:focus-within {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-200:focus-within {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-300:focus-within {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-400:focus-within {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-500:focus-within {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-600:focus-within {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-700:focus-within {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-800:focus-within {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .md\:focus-within\:text-green-900:focus-within {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-50:focus-within {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-100:focus-within {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-200:focus-within {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-300:focus-within {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-400:focus-within {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-500:focus-within {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-600:focus-within {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-700:focus-within {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-800:focus-within {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .md\:focus-within\:text-teal-900:focus-within {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-50:focus-within {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-100:focus-within {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-200:focus-within {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-300:focus-within {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-400:focus-within {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-500:focus-within {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-600:focus-within {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-700:focus-within {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-800:focus-within {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .md\:focus-within\:text-blue-900:focus-within {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-50:focus-within {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-100:focus-within {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-200:focus-within {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-300:focus-within {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-400:focus-within {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-500:focus-within {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-600:focus-within {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-700:focus-within {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-800:focus-within {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .md\:focus-within\:text-indigo-900:focus-within {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-50:focus-within {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-100:focus-within {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-200:focus-within {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-300:focus-within {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-400:focus-within {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-500:focus-within {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-600:focus-within {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-700:focus-within {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-800:focus-within {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .md\:focus-within\:text-purple-900:focus-within {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-100:focus-within {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-200:focus-within {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-300:focus-within {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-400:focus-within {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-500:focus-within {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-600:focus-within {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-700:focus-within {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-800:focus-within {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .md\:focus-within\:text-pink-900:focus-within {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .md\:focus\:text-transparent:focus {
    color: #0000;
  }

  .md\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .md\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .md\:focus\:text-gray-50:focus {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .md\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .md\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .md\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .md\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .md\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .md\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .md\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .md\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .md\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-50:focus {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-100:focus {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-200:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-300:focus {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-400:focus {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-500:focus {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-600:focus {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-700:focus {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-800:focus {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .md\:focus\:text-cool-gray-900:focus {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .md\:focus\:text-red-50:focus {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .md\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .md\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .md\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .md\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .md\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .md\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .md\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .md\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .md\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .md\:focus\:text-orange-50:focus {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .md\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .md\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .md\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .md\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .md\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .md\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .md\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .md\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .md\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-50:focus {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .md\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .md\:focus\:text-green-50:focus {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .md\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .md\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .md\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .md\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .md\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .md\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .md\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .md\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .md\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .md\:focus\:text-teal-50:focus {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .md\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .md\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .md\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .md\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .md\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .md\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .md\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .md\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .md\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .md\:focus\:text-blue-50:focus {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .md\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .md\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .md\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .md\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .md\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .md\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .md\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .md\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .md\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-50:focus {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .md\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .md\:focus\:text-purple-50:focus {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .md\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .md\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .md\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .md\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .md\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .md\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .md\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .md\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .md\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .md\:focus\:text-pink-50:focus {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .md\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .md\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .md\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .md\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .md\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .md\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .md\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .md\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .md\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .md\:active\:text-transparent:active {
    color: #0000;
  }

  .md\:active\:text-white:active {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .md\:active\:text-black:active {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .md\:active\:text-gray-50:active {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .md\:active\:text-gray-100:active {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .md\:active\:text-gray-200:active {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .md\:active\:text-gray-300:active {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .md\:active\:text-gray-400:active {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .md\:active\:text-gray-500:active {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .md\:active\:text-gray-600:active {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .md\:active\:text-gray-700:active {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .md\:active\:text-gray-800:active {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .md\:active\:text-gray-900:active {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-50:active {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-100:active {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-200:active {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-300:active {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-400:active {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-500:active {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-600:active {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-700:active {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-800:active {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .md\:active\:text-cool-gray-900:active {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .md\:active\:text-red-50:active {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .md\:active\:text-red-100:active {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .md\:active\:text-red-200:active {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .md\:active\:text-red-300:active {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .md\:active\:text-red-400:active {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .md\:active\:text-red-500:active {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .md\:active\:text-red-600:active {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .md\:active\:text-red-700:active {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .md\:active\:text-red-800:active {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .md\:active\:text-red-900:active {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .md\:active\:text-orange-50:active {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .md\:active\:text-orange-100:active {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .md\:active\:text-orange-200:active {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .md\:active\:text-orange-300:active {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .md\:active\:text-orange-400:active {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .md\:active\:text-orange-500:active {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .md\:active\:text-orange-600:active {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .md\:active\:text-orange-700:active {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .md\:active\:text-orange-800:active {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .md\:active\:text-orange-900:active {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .md\:active\:text-yellow-50:active {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .md\:active\:text-yellow-100:active {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .md\:active\:text-yellow-200:active {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .md\:active\:text-yellow-300:active {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .md\:active\:text-yellow-400:active {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .md\:active\:text-yellow-500:active {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .md\:active\:text-yellow-600:active {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .md\:active\:text-yellow-700:active {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .md\:active\:text-yellow-800:active {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .md\:active\:text-yellow-900:active {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .md\:active\:text-green-50:active {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .md\:active\:text-green-100:active {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .md\:active\:text-green-200:active {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .md\:active\:text-green-300:active {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .md\:active\:text-green-400:active {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .md\:active\:text-green-500:active {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .md\:active\:text-green-600:active {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .md\:active\:text-green-700:active {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .md\:active\:text-green-800:active {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .md\:active\:text-green-900:active {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .md\:active\:text-teal-50:active {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .md\:active\:text-teal-100:active {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .md\:active\:text-teal-200:active {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .md\:active\:text-teal-300:active {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .md\:active\:text-teal-400:active {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .md\:active\:text-teal-500:active {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .md\:active\:text-teal-600:active {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .md\:active\:text-teal-700:active {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .md\:active\:text-teal-800:active {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .md\:active\:text-teal-900:active {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .md\:active\:text-blue-50:active {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .md\:active\:text-blue-100:active {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .md\:active\:text-blue-200:active {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .md\:active\:text-blue-300:active {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .md\:active\:text-blue-400:active {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .md\:active\:text-blue-500:active {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .md\:active\:text-blue-600:active {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .md\:active\:text-blue-700:active {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .md\:active\:text-blue-800:active {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .md\:active\:text-blue-900:active {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .md\:active\:text-indigo-50:active {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .md\:active\:text-indigo-100:active {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .md\:active\:text-indigo-200:active {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .md\:active\:text-indigo-300:active {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .md\:active\:text-indigo-400:active {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .md\:active\:text-indigo-500:active {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .md\:active\:text-indigo-600:active {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .md\:active\:text-indigo-700:active {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .md\:active\:text-indigo-800:active {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .md\:active\:text-indigo-900:active {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .md\:active\:text-purple-50:active {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .md\:active\:text-purple-100:active {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .md\:active\:text-purple-200:active {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .md\:active\:text-purple-300:active {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .md\:active\:text-purple-400:active {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .md\:active\:text-purple-500:active {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .md\:active\:text-purple-600:active {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .md\:active\:text-purple-700:active {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .md\:active\:text-purple-800:active {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .md\:active\:text-purple-900:active {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .md\:active\:text-pink-50:active {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .md\:active\:text-pink-100:active {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .md\:active\:text-pink-200:active {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .md\:active\:text-pink-300:active {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .md\:active\:text-pink-400:active {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .md\:active\:text-pink-500:active {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .md\:active\:text-pink-600:active {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .md\:active\:text-pink-700:active {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .md\:active\:text-pink-800:active {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .md\:active\:text-pink-900:active {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .md\:text-opacity-0 {
    --text-opacity: 0;
  }

  .md\:text-opacity-25 {
    --text-opacity: .25;
  }

  .md\:text-opacity-50 {
    --text-opacity: .5;
  }

  .md\:text-opacity-75 {
    --text-opacity: .75;
  }

  .md\:text-opacity-100 {
    --text-opacity: 1;
  }

  .md\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .md\:hover\:text-opacity-25:hover {
    --text-opacity: .25;
  }

  .md\:hover\:text-opacity-50:hover {
    --text-opacity: .5;
  }

  .md\:hover\:text-opacity-75:hover {
    --text-opacity: .75;
  }

  .md\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .md\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .md\:focus\:text-opacity-25:focus {
    --text-opacity: .25;
  }

  .md\:focus\:text-opacity-50:focus {
    --text-opacity: .5;
  }

  .md\:focus\:text-opacity-75:focus {
    --text-opacity: .75;
  }

  .md\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .md\:italic {
    font-style: italic;
  }

  .md\:not-italic {
    font-style: normal;
  }

  .md\:uppercase {
    text-transform: uppercase;
  }

  .md\:lowercase {
    text-transform: lowercase;
  }

  .md\:capitalize {
    text-transform: capitalize;
  }

  .md\:normal-case {
    text-transform: none;
  }

  .md\:underline {
    text-decoration: underline;
  }

  .md\:line-through {
    text-decoration: line-through;
  }

  .md\:no-underline {
    text-decoration: none;
  }

  .group:hover .md\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .md\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .md\:group-hover\:no-underline {
    text-decoration: none;
  }

  .group:focus .md\:group-focus\:underline {
    text-decoration: underline;
  }

  .group:focus .md\:group-focus\:line-through {
    text-decoration: line-through;
  }

  .group:focus .md\:group-focus\:no-underline {
    text-decoration: none;
  }

  .md\:hover\:underline:hover {
    text-decoration: underline;
  }

  .md\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .md\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .md\:focus\:underline:focus {
    text-decoration: underline;
  }

  .md\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .md\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .md\:ordinal, .md\:slashed-zero, .md\:lining-nums, .md\:oldstyle-nums, .md\:proportional-nums, .md\:tabular-nums, .md\:diagonal-fractions, .md\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty, );
    --font-variant-numeric-slashed-zero: var(--tailwind-empty, );
    --font-variant-numeric-figure: var(--tailwind-empty, );
    --font-variant-numeric-spacing: var(--tailwind-empty, );
    --font-variant-numeric-fraction: var(--tailwind-empty, );
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .md\:normal-nums {
    font-variant-numeric: normal;
  }

  .md\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .md\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .md\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .md\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .md\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .md\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .md\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .md\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .md\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .md\:tracking-tight {
    letter-spacing: -.025em;
  }

  .md\:tracking-normal {
    letter-spacing: 0;
  }

  .md\:tracking-wide {
    letter-spacing: .025em;
  }

  .md\:tracking-wider {
    letter-spacing: .05em;
  }

  .md\:tracking-widest {
    letter-spacing: .1em;
  }

  .md\:select-none {
    user-select: none;
  }

  .md\:select-text {
    user-select: text;
  }

  .md\:select-all {
    user-select: all;
  }

  .md\:select-auto {
    user-select: auto;
  }

  .md\:align-baseline {
    vertical-align: baseline;
  }

  .md\:align-top {
    vertical-align: top;
  }

  .md\:align-middle {
    vertical-align: middle;
  }

  .md\:align-bottom {
    vertical-align: bottom;
  }

  .md\:align-text-top {
    vertical-align: text-top;
  }

  .md\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .md\:visible {
    visibility: visible;
  }

  .md\:invisible {
    visibility: hidden;
  }

  .md\:whitespace-normal {
    white-space: normal;
  }

  .md\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .md\:whitespace-pre {
    white-space: pre;
  }

  .md\:whitespace-pre-line {
    white-space: pre-line;
  }

  .md\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .md\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .md\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .md\:break-all {
    word-break: break-all;
  }

  .md\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .md\:w-0 {
    width: 0;
  }

  .md\:w-1 {
    width: .25rem;
  }

  .md\:w-2 {
    width: .5rem;
  }

  .md\:w-3 {
    width: .75rem;
  }

  .md\:w-4 {
    width: 1rem;
  }

  .md\:w-5 {
    width: 1.25rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-7 {
    width: 1.75rem;
  }

  .md\:w-8 {
    width: 2rem;
  }

  .md\:w-9 {
    width: 2.25rem;
  }

  .md\:w-10 {
    width: 2.5rem;
  }

  .md\:w-11 {
    width: 2.75rem;
  }

  .md\:w-12 {
    width: 3rem;
  }

  .md\:w-13 {
    width: 3.25rem;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:w-15 {
    width: 3.75rem;
  }

  .md\:w-16 {
    width: 4rem;
  }

  .md\:w-20 {
    width: 5rem;
  }

  .md\:w-24 {
    width: 6rem;
  }

  .md\:w-28 {
    width: 7rem;
  }

  .md\:w-32 {
    width: 8rem;
  }

  .md\:w-36 {
    width: 9rem;
  }

  .md\:w-40 {
    width: 10rem;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-52 {
    width: 13rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-72 {
    width: 18rem;
  }

  .md\:w-80 {
    width: 20rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:w-px {
    width: 1px;
  }

  .md\:w-0\.5 {
    width: .125rem;
  }

  .md\:w-1\.5 {
    width: .375rem;
  }

  .md\:w-2\.5 {
    width: .625rem;
  }

  .md\:w-3\.5 {
    width: .875rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-1\/3 {
    width: 33.3333%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:w-1\/4 {
    width: 25%;
  }

  .md\:w-2\/4 {
    width: 50%;
  }

  .md\:w-3\/4 {
    width: 75%;
  }

  .md\:w-1\/5 {
    width: 20%;
  }

  .md\:w-2\/5 {
    width: 40%;
  }

  .md\:w-3\/5 {
    width: 60%;
  }

  .md\:w-4\/5 {
    width: 80%;
  }

  .md\:w-1\/6 {
    width: 16.6667%;
  }

  .md\:w-2\/6 {
    width: 33.3333%;
  }

  .md\:w-3\/6 {
    width: 50%;
  }

  .md\:w-4\/6 {
    width: 66.6667%;
  }

  .md\:w-5\/6 {
    width: 83.3333%;
  }

  .md\:w-1\/12 {
    width: 8.33333%;
  }

  .md\:w-2\/12 {
    width: 16.6667%;
  }

  .md\:w-3\/12 {
    width: 25%;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-5\/12 {
    width: 41.6667%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-7\/12 {
    width: 58.3333%;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-9\/12 {
    width: 75%;
  }

  .md\:w-10\/12 {
    width: 83.3333%;
  }

  .md\:w-11\/12 {
    width: 91.6667%;
  }

  .md\:w-full {
    width: 100%;
  }

  .md\:w-screen {
    width: 100vw;
  }

  .md\:w-min-content {
    width: min-content;
  }

  .md\:w-max-content {
    width: max-content;
  }

  .md\:z-0 {
    z-index: 0;
  }

  .md\:z-10 {
    z-index: 10;
  }

  .md\:z-20 {
    z-index: 20;
  }

  .md\:z-30 {
    z-index: 30;
  }

  .md\:z-40 {
    z-index: 40;
  }

  .md\:z-50 {
    z-index: 50;
  }

  .md\:z-auto {
    z-index: auto;
  }

  .md\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .md\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .md\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .md\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .md\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .md\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .md\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .md\:focus\:z-0:focus {
    z-index: 0;
  }

  .md\:focus\:z-10:focus {
    z-index: 10;
  }

  .md\:focus\:z-20:focus {
    z-index: 20;
  }

  .md\:focus\:z-30:focus {
    z-index: 30;
  }

  .md\:focus\:z-40:focus {
    z-index: 40;
  }

  .md\:focus\:z-50:focus {
    z-index: 50;
  }

  .md\:focus\:z-auto:focus {
    z-index: auto;
  }

  .md\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .md\:gap-1 {
    grid-gap: .25rem;
    gap: .25rem;
  }

  .md\:gap-2 {
    grid-gap: .5rem;
    gap: .5rem;
  }

  .md\:gap-3 {
    grid-gap: .75rem;
    gap: .75rem;
  }

  .md\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .md\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .md\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .md\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }

  .md\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .md\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }

  .md\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .md\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }

  .md\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .md\:gap-13 {
    grid-gap: 3.25rem;
    gap: 3.25rem;
  }

  .md\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }

  .md\:gap-15 {
    grid-gap: 3.75rem;
    gap: 3.75rem;
  }

  .md\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .md\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .md\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .md\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }

  .md\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .md\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }

  .md\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .md\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }

  .md\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .md\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }

  .md\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .md\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }

  .md\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .md\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }

  .md\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }

  .md\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }

  .md\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .md\:gap-0\.5 {
    grid-gap: .125rem;
    gap: .125rem;
  }

  .md\:gap-1\.5 {
    grid-gap: .375rem;
    gap: .375rem;
  }

  .md\:gap-2\.5 {
    grid-gap: .625rem;
    gap: .625rem;
  }

  .md\:gap-3\.5 {
    grid-gap: .875rem;
    gap: .875rem;
  }

  .md\:gap-1\/2 {
    grid-gap: 50%;
    gap: 50%;
  }

  .md\:gap-1\/3 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .md\:gap-2\/3 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .md\:gap-1\/4 {
    grid-gap: 25%;
    gap: 25%;
  }

  .md\:gap-2\/4 {
    grid-gap: 50%;
    gap: 50%;
  }

  .md\:gap-3\/4 {
    grid-gap: 75%;
    gap: 75%;
  }

  .md\:gap-1\/5 {
    grid-gap: 20%;
    gap: 20%;
  }

  .md\:gap-2\/5 {
    grid-gap: 40%;
    gap: 40%;
  }

  .md\:gap-3\/5 {
    grid-gap: 60%;
    gap: 60%;
  }

  .md\:gap-4\/5 {
    grid-gap: 80%;
    gap: 80%;
  }

  .md\:gap-1\/6 {
    grid-gap: 16.6667%;
    gap: 16.6667%;
  }

  .md\:gap-2\/6 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .md\:gap-3\/6 {
    grid-gap: 50%;
    gap: 50%;
  }

  .md\:gap-4\/6 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .md\:gap-5\/6 {
    grid-gap: 83.3333%;
    gap: 83.3333%;
  }

  .md\:gap-1\/12 {
    grid-gap: 8.33333%;
    gap: 8.33333%;
  }

  .md\:gap-2\/12 {
    grid-gap: 16.6667%;
    gap: 16.6667%;
  }

  .md\:gap-3\/12 {
    grid-gap: 25%;
    gap: 25%;
  }

  .md\:gap-4\/12 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .md\:gap-5\/12 {
    grid-gap: 41.6667%;
    gap: 41.6667%;
  }

  .md\:gap-6\/12 {
    grid-gap: 50%;
    gap: 50%;
  }

  .md\:gap-7\/12 {
    grid-gap: 58.3333%;
    gap: 58.3333%;
  }

  .md\:gap-8\/12 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .md\:gap-9\/12 {
    grid-gap: 75%;
    gap: 75%;
  }

  .md\:gap-10\/12 {
    grid-gap: 83.3333%;
    gap: 83.3333%;
  }

  .md\:gap-11\/12 {
    grid-gap: 91.6667%;
    gap: 91.6667%;
  }

  .md\:gap-full {
    grid-gap: 100%;
    gap: 100%;
  }

  .md\:col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0;
  }

  .md\:col-gap-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem;
  }

  .md\:col-gap-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem;
  }

  .md\:col-gap-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem;
  }

  .md\:col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }

  .md\:col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .md\:col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .md\:col-gap-7 {
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }

  .md\:col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }

  .md\:col-gap-9 {
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }

  .md\:col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .md\:col-gap-11 {
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }

  .md\:col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }

  .md\:col-gap-13 {
    grid-column-gap: 3.25rem;
    column-gap: 3.25rem;
  }

  .md\:col-gap-14 {
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }

  .md\:col-gap-15 {
    grid-column-gap: 3.75rem;
    column-gap: 3.75rem;
  }

  .md\:col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }

  .md\:col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }

  .md\:col-gap-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }

  .md\:col-gap-28 {
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }

  .md\:col-gap-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }

  .md\:col-gap-36 {
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }

  .md\:col-gap-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }

  .md\:col-gap-44 {
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }

  .md\:col-gap-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }

  .md\:col-gap-52 {
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }

  .md\:col-gap-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }

  .md\:col-gap-60 {
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }

  .md\:col-gap-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }

  .md\:col-gap-72 {
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }

  .md\:col-gap-80 {
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }

  .md\:col-gap-96 {
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }

  .md\:col-gap-px {
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  .md\:col-gap-0\.5 {
    grid-column-gap: .125rem;
    column-gap: .125rem;
  }

  .md\:col-gap-1\.5 {
    grid-column-gap: .375rem;
    column-gap: .375rem;
  }

  .md\:col-gap-2\.5 {
    grid-column-gap: .625rem;
    column-gap: .625rem;
  }

  .md\:col-gap-3\.5 {
    grid-column-gap: .875rem;
    column-gap: .875rem;
  }

  .md\:col-gap-1\/2 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .md\:col-gap-1\/3 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .md\:col-gap-2\/3 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .md\:col-gap-1\/4 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .md\:col-gap-2\/4 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .md\:col-gap-3\/4 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .md\:col-gap-1\/5 {
    grid-column-gap: 20%;
    column-gap: 20%;
  }

  .md\:col-gap-2\/5 {
    grid-column-gap: 40%;
    column-gap: 40%;
  }

  .md\:col-gap-3\/5 {
    grid-column-gap: 60%;
    column-gap: 60%;
  }

  .md\:col-gap-4\/5 {
    grid-column-gap: 80%;
    column-gap: 80%;
  }

  .md\:col-gap-1\/6 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .md\:col-gap-2\/6 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .md\:col-gap-3\/6 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .md\:col-gap-4\/6 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .md\:col-gap-5\/6 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .md\:col-gap-1\/12 {
    grid-column-gap: 8.33333%;
    column-gap: 8.33333%;
  }

  .md\:col-gap-2\/12 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .md\:col-gap-3\/12 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .md\:col-gap-4\/12 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .md\:col-gap-5\/12 {
    grid-column-gap: 41.6667%;
    column-gap: 41.6667%;
  }

  .md\:col-gap-6\/12 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .md\:col-gap-7\/12 {
    grid-column-gap: 58.3333%;
    column-gap: 58.3333%;
  }

  .md\:col-gap-8\/12 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .md\:col-gap-9\/12 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .md\:col-gap-10\/12 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .md\:col-gap-11\/12 {
    grid-column-gap: 91.6667%;
    column-gap: 91.6667%;
  }

  .md\:col-gap-full {
    grid-column-gap: 100%;
    column-gap: 100%;
  }

  .md\:gap-x-0 {
    grid-column-gap: 0;
    column-gap: 0;
  }

  .md\:gap-x-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem;
  }

  .md\:gap-x-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem;
  }

  .md\:gap-x-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem;
  }

  .md\:gap-x-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }

  .md\:gap-x-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .md\:gap-x-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .md\:gap-x-7 {
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }

  .md\:gap-x-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }

  .md\:gap-x-9 {
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }

  .md\:gap-x-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .md\:gap-x-11 {
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }

  .md\:gap-x-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }

  .md\:gap-x-13 {
    grid-column-gap: 3.25rem;
    column-gap: 3.25rem;
  }

  .md\:gap-x-14 {
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }

  .md\:gap-x-15 {
    grid-column-gap: 3.75rem;
    column-gap: 3.75rem;
  }

  .md\:gap-x-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }

  .md\:gap-x-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }

  .md\:gap-x-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }

  .md\:gap-x-28 {
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }

  .md\:gap-x-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }

  .md\:gap-x-36 {
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }

  .md\:gap-x-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }

  .md\:gap-x-44 {
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }

  .md\:gap-x-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }

  .md\:gap-x-52 {
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }

  .md\:gap-x-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }

  .md\:gap-x-60 {
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }

  .md\:gap-x-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }

  .md\:gap-x-72 {
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }

  .md\:gap-x-80 {
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }

  .md\:gap-x-96 {
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }

  .md\:gap-x-px {
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  .md\:gap-x-0\.5 {
    grid-column-gap: .125rem;
    column-gap: .125rem;
  }

  .md\:gap-x-1\.5 {
    grid-column-gap: .375rem;
    column-gap: .375rem;
  }

  .md\:gap-x-2\.5 {
    grid-column-gap: .625rem;
    column-gap: .625rem;
  }

  .md\:gap-x-3\.5 {
    grid-column-gap: .875rem;
    column-gap: .875rem;
  }

  .md\:gap-x-1\/2 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .md\:gap-x-1\/3 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .md\:gap-x-2\/3 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .md\:gap-x-1\/4 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .md\:gap-x-2\/4 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .md\:gap-x-3\/4 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .md\:gap-x-1\/5 {
    grid-column-gap: 20%;
    column-gap: 20%;
  }

  .md\:gap-x-2\/5 {
    grid-column-gap: 40%;
    column-gap: 40%;
  }

  .md\:gap-x-3\/5 {
    grid-column-gap: 60%;
    column-gap: 60%;
  }

  .md\:gap-x-4\/5 {
    grid-column-gap: 80%;
    column-gap: 80%;
  }

  .md\:gap-x-1\/6 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .md\:gap-x-2\/6 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .md\:gap-x-3\/6 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .md\:gap-x-4\/6 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .md\:gap-x-5\/6 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .md\:gap-x-1\/12 {
    grid-column-gap: 8.33333%;
    column-gap: 8.33333%;
  }

  .md\:gap-x-2\/12 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .md\:gap-x-3\/12 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .md\:gap-x-4\/12 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .md\:gap-x-5\/12 {
    grid-column-gap: 41.6667%;
    column-gap: 41.6667%;
  }

  .md\:gap-x-6\/12 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .md\:gap-x-7\/12 {
    grid-column-gap: 58.3333%;
    column-gap: 58.3333%;
  }

  .md\:gap-x-8\/12 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .md\:gap-x-9\/12 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .md\:gap-x-10\/12 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .md\:gap-x-11\/12 {
    grid-column-gap: 91.6667%;
    column-gap: 91.6667%;
  }

  .md\:gap-x-full {
    grid-column-gap: 100%;
    column-gap: 100%;
  }

  .md\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .md\:row-gap-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem;
  }

  .md\:row-gap-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }

  .md\:row-gap-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem;
  }

  .md\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .md\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .md\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .md\:row-gap-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }

  .md\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .md\:row-gap-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }

  .md\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .md\:row-gap-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }

  .md\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .md\:row-gap-13 {
    grid-row-gap: 3.25rem;
    row-gap: 3.25rem;
  }

  .md\:row-gap-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  .md\:row-gap-15 {
    grid-row-gap: 3.75rem;
    row-gap: 3.75rem;
  }

  .md\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .md\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .md\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .md\:row-gap-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }

  .md\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .md\:row-gap-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }

  .md\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .md\:row-gap-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }

  .md\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .md\:row-gap-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }

  .md\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .md\:row-gap-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }

  .md\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .md\:row-gap-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }

  .md\:row-gap-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }

  .md\:row-gap-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }

  .md\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .md\:row-gap-0\.5 {
    grid-row-gap: .125rem;
    row-gap: .125rem;
  }

  .md\:row-gap-1\.5 {
    grid-row-gap: .375rem;
    row-gap: .375rem;
  }

  .md\:row-gap-2\.5 {
    grid-row-gap: .625rem;
    row-gap: .625rem;
  }

  .md\:row-gap-3\.5 {
    grid-row-gap: .875rem;
    row-gap: .875rem;
  }

  .md\:row-gap-1\/2 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .md\:row-gap-1\/3 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .md\:row-gap-2\/3 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .md\:row-gap-1\/4 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .md\:row-gap-2\/4 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .md\:row-gap-3\/4 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .md\:row-gap-1\/5 {
    grid-row-gap: 20%;
    row-gap: 20%;
  }

  .md\:row-gap-2\/5 {
    grid-row-gap: 40%;
    row-gap: 40%;
  }

  .md\:row-gap-3\/5 {
    grid-row-gap: 60%;
    row-gap: 60%;
  }

  .md\:row-gap-4\/5 {
    grid-row-gap: 80%;
    row-gap: 80%;
  }

  .md\:row-gap-1\/6 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .md\:row-gap-2\/6 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .md\:row-gap-3\/6 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .md\:row-gap-4\/6 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .md\:row-gap-5\/6 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .md\:row-gap-1\/12 {
    grid-row-gap: 8.33333%;
    row-gap: 8.33333%;
  }

  .md\:row-gap-2\/12 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .md\:row-gap-3\/12 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .md\:row-gap-4\/12 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .md\:row-gap-5\/12 {
    grid-row-gap: 41.6667%;
    row-gap: 41.6667%;
  }

  .md\:row-gap-6\/12 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .md\:row-gap-7\/12 {
    grid-row-gap: 58.3333%;
    row-gap: 58.3333%;
  }

  .md\:row-gap-8\/12 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .md\:row-gap-9\/12 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .md\:row-gap-10\/12 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .md\:row-gap-11\/12 {
    grid-row-gap: 91.6667%;
    row-gap: 91.6667%;
  }

  .md\:row-gap-full {
    grid-row-gap: 100%;
    row-gap: 100%;
  }

  .md\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .md\:gap-y-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem;
  }

  .md\:gap-y-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }

  .md\:gap-y-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem;
  }

  .md\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .md\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .md\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .md\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }

  .md\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .md\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }

  .md\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .md\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }

  .md\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .md\:gap-y-13 {
    grid-row-gap: 3.25rem;
    row-gap: 3.25rem;
  }

  .md\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  .md\:gap-y-15 {
    grid-row-gap: 3.75rem;
    row-gap: 3.75rem;
  }

  .md\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .md\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .md\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .md\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }

  .md\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .md\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }

  .md\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .md\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }

  .md\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .md\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }

  .md\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .md\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }

  .md\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .md\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }

  .md\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }

  .md\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }

  .md\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .md\:gap-y-0\.5 {
    grid-row-gap: .125rem;
    row-gap: .125rem;
  }

  .md\:gap-y-1\.5 {
    grid-row-gap: .375rem;
    row-gap: .375rem;
  }

  .md\:gap-y-2\.5 {
    grid-row-gap: .625rem;
    row-gap: .625rem;
  }

  .md\:gap-y-3\.5 {
    grid-row-gap: .875rem;
    row-gap: .875rem;
  }

  .md\:gap-y-1\/2 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .md\:gap-y-1\/3 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .md\:gap-y-2\/3 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .md\:gap-y-1\/4 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .md\:gap-y-2\/4 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .md\:gap-y-3\/4 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .md\:gap-y-1\/5 {
    grid-row-gap: 20%;
    row-gap: 20%;
  }

  .md\:gap-y-2\/5 {
    grid-row-gap: 40%;
    row-gap: 40%;
  }

  .md\:gap-y-3\/5 {
    grid-row-gap: 60%;
    row-gap: 60%;
  }

  .md\:gap-y-4\/5 {
    grid-row-gap: 80%;
    row-gap: 80%;
  }

  .md\:gap-y-1\/6 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .md\:gap-y-2\/6 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .md\:gap-y-3\/6 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .md\:gap-y-4\/6 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .md\:gap-y-5\/6 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .md\:gap-y-1\/12 {
    grid-row-gap: 8.33333%;
    row-gap: 8.33333%;
  }

  .md\:gap-y-2\/12 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .md\:gap-y-3\/12 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .md\:gap-y-4\/12 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .md\:gap-y-5\/12 {
    grid-row-gap: 41.6667%;
    row-gap: 41.6667%;
  }

  .md\:gap-y-6\/12 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .md\:gap-y-7\/12 {
    grid-row-gap: 58.3333%;
    row-gap: 58.3333%;
  }

  .md\:gap-y-8\/12 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .md\:gap-y-9\/12 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .md\:gap-y-10\/12 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .md\:gap-y-11\/12 {
    grid-row-gap: 91.6667%;
    row-gap: 91.6667%;
  }

  .md\:gap-y-full {
    grid-row-gap: 100%;
    row-gap: 100%;
  }

  .md\:grid-flow-row {
    grid-auto-flow: row;
  }

  .md\:grid-flow-col {
    grid-auto-flow: column;
  }

  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:grid-cols-none {
    grid-template-columns: none;
  }

  .md\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .md\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .md\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .md\:col-auto {
    grid-column: auto;
  }

  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:col-span-full {
    grid-column: 1 / -1;
  }

  .md\:col-start-1 {
    grid-column-start: 1;
  }

  .md\:col-start-2 {
    grid-column-start: 2;
  }

  .md\:col-start-3 {
    grid-column-start: 3;
  }

  .md\:col-start-4 {
    grid-column-start: 4;
  }

  .md\:col-start-5 {
    grid-column-start: 5;
  }

  .md\:col-start-6 {
    grid-column-start: 6;
  }

  .md\:col-start-7 {
    grid-column-start: 7;
  }

  .md\:col-start-8 {
    grid-column-start: 8;
  }

  .md\:col-start-9 {
    grid-column-start: 9;
  }

  .md\:col-start-10 {
    grid-column-start: 10;
  }

  .md\:col-start-11 {
    grid-column-start: 11;
  }

  .md\:col-start-12 {
    grid-column-start: 12;
  }

  .md\:col-start-13 {
    grid-column-start: 13;
  }

  .md\:col-start-auto {
    grid-column-start: auto;
  }

  .md\:col-end-1 {
    grid-column-end: 1;
  }

  .md\:col-end-2 {
    grid-column-end: 2;
  }

  .md\:col-end-3 {
    grid-column-end: 3;
  }

  .md\:col-end-4 {
    grid-column-end: 4;
  }

  .md\:col-end-5 {
    grid-column-end: 5;
  }

  .md\:col-end-6 {
    grid-column-end: 6;
  }

  .md\:col-end-7 {
    grid-column-end: 7;
  }

  .md\:col-end-8 {
    grid-column-end: 8;
  }

  .md\:col-end-9 {
    grid-column-end: 9;
  }

  .md\:col-end-10 {
    grid-column-end: 10;
  }

  .md\:col-end-11 {
    grid-column-end: 11;
  }

  .md\:col-end-12 {
    grid-column-end: 12;
  }

  .md\:col-end-13 {
    grid-column-end: 13;
  }

  .md\:col-end-auto {
    grid-column-end: auto;
  }

  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .md\:grid-rows-none {
    grid-template-rows: none;
  }

  .md\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .md\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .md\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .md\:row-auto {
    grid-row: auto;
  }

  .md\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .md\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .md\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .md\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .md\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .md\:row-span-full {
    grid-row: 1 / -1;
  }

  .md\:row-start-1 {
    grid-row-start: 1;
  }

  .md\:row-start-2 {
    grid-row-start: 2;
  }

  .md\:row-start-3 {
    grid-row-start: 3;
  }

  .md\:row-start-4 {
    grid-row-start: 4;
  }

  .md\:row-start-5 {
    grid-row-start: 5;
  }

  .md\:row-start-6 {
    grid-row-start: 6;
  }

  .md\:row-start-7 {
    grid-row-start: 7;
  }

  .md\:row-start-auto {
    grid-row-start: auto;
  }

  .md\:row-end-1 {
    grid-row-end: 1;
  }

  .md\:row-end-2 {
    grid-row-end: 2;
  }

  .md\:row-end-3 {
    grid-row-end: 3;
  }

  .md\:row-end-4 {
    grid-row-end: 4;
  }

  .md\:row-end-5 {
    grid-row-end: 5;
  }

  .md\:row-end-6 {
    grid-row-end: 6;
  }

  .md\:row-end-7 {
    grid-row-end: 7;
  }

  .md\:row-end-auto {
    grid-row-end: auto;
  }

  .md\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x) ) translateY(var(--transform-translate-y) ) rotate(var(--transform-rotate) ) skewX(var(--transform-skew-x) ) skewY(var(--transform-skew-y) ) scaleX(var(--transform-scale-x) ) scaleY(var(--transform-scale-y) );
  }

  .md\:transform-none {
    transform: none;
  }

  .md\:origin-center {
    transform-origin: center;
  }

  .md\:origin-top {
    transform-origin: top;
  }

  .md\:origin-top-right {
    transform-origin: 100% 0;
  }

  .md\:origin-right {
    transform-origin: 100%;
  }

  .md\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .md\:origin-bottom {
    transform-origin: bottom;
  }

  .md\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .md\:origin-left {
    transform-origin: 0;
  }

  .md\:origin-top-left {
    transform-origin: 0 0;
  }

  .md\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .md\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .md\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .md\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .md\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .md\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .md\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .md\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .md\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .md\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .md\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .md\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .md\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .md\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .md\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .md\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .md\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .md\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .md\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .md\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .md\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .md\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .md\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .md\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .md\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .md\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .md\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .md\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .md\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .md\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .md\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .md\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .md\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .md\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .md\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .md\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .md\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .md\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .md\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .md\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .md\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .md\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .md\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .md\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .md\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .md\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .md\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .md\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .md\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .md\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .md\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .md\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .md\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .md\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .md\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .md\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .md\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .md\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .md\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .md\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .md\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .md\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .md\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .md\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .md\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .md\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .md\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .md\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .md\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .md\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .md\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .md\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .md\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .md\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .md\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .md\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .md\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .md\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .md\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .md\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .md\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .md\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .md\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .md\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .md\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .md\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .md\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .md\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .md\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .md\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .md\:rotate-0 {
    --transform-rotate: 0;
  }

  .md\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .md\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .md\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .md\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .md\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .md\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .md\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .md\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .md\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .md\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .md\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .md\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .md\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .md\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .md\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .md\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .md\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .md\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .md\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .md\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .md\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .md\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .md\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .md\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .md\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .md\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .md\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .md\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .md\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .md\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .md\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .md\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .md\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .md\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .md\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .md\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .md\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .md\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .md\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .md\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .md\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .md\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .md\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .md\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .md\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .md\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .md\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .md\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .md\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .md\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .md\:translate-x-0 {
    --transform-translate-x: 0;
  }

  .md\:translate-x-1 {
    --transform-translate-x: .25rem;
  }

  .md\:translate-x-2 {
    --transform-translate-x: .5rem;
  }

  .md\:translate-x-3 {
    --transform-translate-x: .75rem;
  }

  .md\:translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .md\:translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .md\:translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .md\:translate-x-7 {
    --transform-translate-x: 1.75rem;
  }

  .md\:translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .md\:translate-x-9 {
    --transform-translate-x: 2.25rem;
  }

  .md\:translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .md\:translate-x-11 {
    --transform-translate-x: 2.75rem;
  }

  .md\:translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .md\:translate-x-13 {
    --transform-translate-x: 3.25rem;
  }

  .md\:translate-x-14 {
    --transform-translate-x: 3.5rem;
  }

  .md\:translate-x-15 {
    --transform-translate-x: 3.75rem;
  }

  .md\:translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .md\:translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .md\:translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .md\:translate-x-28 {
    --transform-translate-x: 7rem;
  }

  .md\:translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .md\:translate-x-36 {
    --transform-translate-x: 9rem;
  }

  .md\:translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .md\:translate-x-44 {
    --transform-translate-x: 11rem;
  }

  .md\:translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .md\:translate-x-52 {
    --transform-translate-x: 13rem;
  }

  .md\:translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .md\:translate-x-60 {
    --transform-translate-x: 15rem;
  }

  .md\:translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .md\:translate-x-72 {
    --transform-translate-x: 18rem;
  }

  .md\:translate-x-80 {
    --transform-translate-x: 20rem;
  }

  .md\:translate-x-96 {
    --transform-translate-x: 24rem;
  }

  .md\:translate-x-px {
    --transform-translate-x: 1px;
  }

  .md\:translate-x-0\.5 {
    --transform-translate-x: .125rem;
  }

  .md\:translate-x-1\.5 {
    --transform-translate-x: .375rem;
  }

  .md\:translate-x-2\.5 {
    --transform-translate-x: .625rem;
  }

  .md\:translate-x-3\.5 {
    --transform-translate-x: .875rem;
  }

  .md\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .md\:translate-x-1\/3 {
    --transform-translate-x: 33.3333%;
  }

  .md\:translate-x-2\/3 {
    --transform-translate-x: 66.6667%;
  }

  .md\:translate-x-1\/4 {
    --transform-translate-x: 25%;
  }

  .md\:translate-x-2\/4 {
    --transform-translate-x: 50%;
  }

  .md\:translate-x-3\/4 {
    --transform-translate-x: 75%;
  }

  .md\:translate-x-1\/5 {
    --transform-translate-x: 20%;
  }

  .md\:translate-x-2\/5 {
    --transform-translate-x: 40%;
  }

  .md\:translate-x-3\/5 {
    --transform-translate-x: 60%;
  }

  .md\:translate-x-4\/5 {
    --transform-translate-x: 80%;
  }

  .md\:translate-x-1\/6 {
    --transform-translate-x: 16.6667%;
  }

  .md\:translate-x-2\/6 {
    --transform-translate-x: 33.3333%;
  }

  .md\:translate-x-3\/6 {
    --transform-translate-x: 50%;
  }

  .md\:translate-x-4\/6 {
    --transform-translate-x: 66.6667%;
  }

  .md\:translate-x-5\/6 {
    --transform-translate-x: 83.3333%;
  }

  .md\:translate-x-1\/12 {
    --transform-translate-x: 8.33333%;
  }

  .md\:translate-x-2\/12 {
    --transform-translate-x: 16.6667%;
  }

  .md\:translate-x-3\/12 {
    --transform-translate-x: 25%;
  }

  .md\:translate-x-4\/12 {
    --transform-translate-x: 33.3333%;
  }

  .md\:translate-x-5\/12 {
    --transform-translate-x: 41.6667%;
  }

  .md\:translate-x-6\/12 {
    --transform-translate-x: 50%;
  }

  .md\:translate-x-7\/12 {
    --transform-translate-x: 58.3333%;
  }

  .md\:translate-x-8\/12 {
    --transform-translate-x: 66.6667%;
  }

  .md\:translate-x-9\/12 {
    --transform-translate-x: 75%;
  }

  .md\:translate-x-10\/12 {
    --transform-translate-x: 83.3333%;
  }

  .md\:translate-x-11\/12 {
    --transform-translate-x: 91.6667%;
  }

  .md\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .md\:-translate-x-1 {
    --transform-translate-x: -.25rem;
  }

  .md\:-translate-x-2 {
    --transform-translate-x: -.5rem;
  }

  .md\:-translate-x-3 {
    --transform-translate-x: -.75rem;
  }

  .md\:-translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .md\:-translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .md\:-translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .md\:-translate-x-7 {
    --transform-translate-x: -1.75rem;
  }

  .md\:-translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .md\:-translate-x-9 {
    --transform-translate-x: -2.25rem;
  }

  .md\:-translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .md\:-translate-x-11 {
    --transform-translate-x: -2.75rem;
  }

  .md\:-translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .md\:-translate-x-13 {
    --transform-translate-x: -3.25rem;
  }

  .md\:-translate-x-14 {
    --transform-translate-x: -3.5rem;
  }

  .md\:-translate-x-15 {
    --transform-translate-x: -3.75rem;
  }

  .md\:-translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .md\:-translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .md\:-translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .md\:-translate-x-28 {
    --transform-translate-x: -7rem;
  }

  .md\:-translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .md\:-translate-x-36 {
    --transform-translate-x: -9rem;
  }

  .md\:-translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .md\:-translate-x-44 {
    --transform-translate-x: -11rem;
  }

  .md\:-translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .md\:-translate-x-52 {
    --transform-translate-x: -13rem;
  }

  .md\:-translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .md\:-translate-x-60 {
    --transform-translate-x: -15rem;
  }

  .md\:-translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .md\:-translate-x-72 {
    --transform-translate-x: -18rem;
  }

  .md\:-translate-x-80 {
    --transform-translate-x: -20rem;
  }

  .md\:-translate-x-96 {
    --transform-translate-x: -24rem;
  }

  .md\:-translate-x-px {
    --transform-translate-x: -1px;
  }

  .md\:-translate-x-0\.5 {
    --transform-translate-x: -.125rem;
  }

  .md\:-translate-x-1\.5 {
    --transform-translate-x: -.375rem;
  }

  .md\:-translate-x-2\.5 {
    --transform-translate-x: -.625rem;
  }

  .md\:-translate-x-3\.5 {
    --transform-translate-x: -.875rem;
  }

  .md\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .md\:-translate-x-1\/3 {
    --transform-translate-x: -33.3333%;
  }

  .md\:-translate-x-2\/3 {
    --transform-translate-x: -66.6667%;
  }

  .md\:-translate-x-1\/4 {
    --transform-translate-x: -25%;
  }

  .md\:-translate-x-2\/4 {
    --transform-translate-x: -50%;
  }

  .md\:-translate-x-3\/4 {
    --transform-translate-x: -75%;
  }

  .md\:-translate-x-1\/5 {
    --transform-translate-x: -20%;
  }

  .md\:-translate-x-2\/5 {
    --transform-translate-x: -40%;
  }

  .md\:-translate-x-3\/5 {
    --transform-translate-x: -60%;
  }

  .md\:-translate-x-4\/5 {
    --transform-translate-x: -80%;
  }

  .md\:-translate-x-1\/6 {
    --transform-translate-x: -16.6667%;
  }

  .md\:-translate-x-2\/6 {
    --transform-translate-x: -33.3333%;
  }

  .md\:-translate-x-3\/6 {
    --transform-translate-x: -50%;
  }

  .md\:-translate-x-4\/6 {
    --transform-translate-x: -66.6667%;
  }

  .md\:-translate-x-5\/6 {
    --transform-translate-x: -83.3333%;
  }

  .md\:-translate-x-1\/12 {
    --transform-translate-x: -8.33333%;
  }

  .md\:-translate-x-2\/12 {
    --transform-translate-x: -16.6667%;
  }

  .md\:-translate-x-3\/12 {
    --transform-translate-x: -25%;
  }

  .md\:-translate-x-4\/12 {
    --transform-translate-x: -33.3333%;
  }

  .md\:-translate-x-5\/12 {
    --transform-translate-x: -41.6667%;
  }

  .md\:-translate-x-6\/12 {
    --transform-translate-x: -50%;
  }

  .md\:-translate-x-7\/12 {
    --transform-translate-x: -58.3333%;
  }

  .md\:-translate-x-8\/12 {
    --transform-translate-x: -66.6667%;
  }

  .md\:-translate-x-9\/12 {
    --transform-translate-x: -75%;
  }

  .md\:-translate-x-10\/12 {
    --transform-translate-x: -83.3333%;
  }

  .md\:-translate-x-11\/12 {
    --transform-translate-x: -91.6667%;
  }

  .md\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .md\:translate-y-0 {
    --transform-translate-y: 0;
  }

  .md\:translate-y-1 {
    --transform-translate-y: .25rem;
  }

  .md\:translate-y-2 {
    --transform-translate-y: .5rem;
  }

  .md\:translate-y-3 {
    --transform-translate-y: .75rem;
  }

  .md\:translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .md\:translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .md\:translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .md\:translate-y-7 {
    --transform-translate-y: 1.75rem;
  }

  .md\:translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .md\:translate-y-9 {
    --transform-translate-y: 2.25rem;
  }

  .md\:translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .md\:translate-y-11 {
    --transform-translate-y: 2.75rem;
  }

  .md\:translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .md\:translate-y-13 {
    --transform-translate-y: 3.25rem;
  }

  .md\:translate-y-14 {
    --transform-translate-y: 3.5rem;
  }

  .md\:translate-y-15 {
    --transform-translate-y: 3.75rem;
  }

  .md\:translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .md\:translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .md\:translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .md\:translate-y-28 {
    --transform-translate-y: 7rem;
  }

  .md\:translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .md\:translate-y-36 {
    --transform-translate-y: 9rem;
  }

  .md\:translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .md\:translate-y-44 {
    --transform-translate-y: 11rem;
  }

  .md\:translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .md\:translate-y-52 {
    --transform-translate-y: 13rem;
  }

  .md\:translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .md\:translate-y-60 {
    --transform-translate-y: 15rem;
  }

  .md\:translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .md\:translate-y-72 {
    --transform-translate-y: 18rem;
  }

  .md\:translate-y-80 {
    --transform-translate-y: 20rem;
  }

  .md\:translate-y-96 {
    --transform-translate-y: 24rem;
  }

  .md\:translate-y-px {
    --transform-translate-y: 1px;
  }

  .md\:translate-y-0\.5 {
    --transform-translate-y: .125rem;
  }

  .md\:translate-y-1\.5 {
    --transform-translate-y: .375rem;
  }

  .md\:translate-y-2\.5 {
    --transform-translate-y: .625rem;
  }

  .md\:translate-y-3\.5 {
    --transform-translate-y: .875rem;
  }

  .md\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .md\:translate-y-1\/3 {
    --transform-translate-y: 33.3333%;
  }

  .md\:translate-y-2\/3 {
    --transform-translate-y: 66.6667%;
  }

  .md\:translate-y-1\/4 {
    --transform-translate-y: 25%;
  }

  .md\:translate-y-2\/4 {
    --transform-translate-y: 50%;
  }

  .md\:translate-y-3\/4 {
    --transform-translate-y: 75%;
  }

  .md\:translate-y-1\/5 {
    --transform-translate-y: 20%;
  }

  .md\:translate-y-2\/5 {
    --transform-translate-y: 40%;
  }

  .md\:translate-y-3\/5 {
    --transform-translate-y: 60%;
  }

  .md\:translate-y-4\/5 {
    --transform-translate-y: 80%;
  }

  .md\:translate-y-1\/6 {
    --transform-translate-y: 16.6667%;
  }

  .md\:translate-y-2\/6 {
    --transform-translate-y: 33.3333%;
  }

  .md\:translate-y-3\/6 {
    --transform-translate-y: 50%;
  }

  .md\:translate-y-4\/6 {
    --transform-translate-y: 66.6667%;
  }

  .md\:translate-y-5\/6 {
    --transform-translate-y: 83.3333%;
  }

  .md\:translate-y-1\/12 {
    --transform-translate-y: 8.33333%;
  }

  .md\:translate-y-2\/12 {
    --transform-translate-y: 16.6667%;
  }

  .md\:translate-y-3\/12 {
    --transform-translate-y: 25%;
  }

  .md\:translate-y-4\/12 {
    --transform-translate-y: 33.3333%;
  }

  .md\:translate-y-5\/12 {
    --transform-translate-y: 41.6667%;
  }

  .md\:translate-y-6\/12 {
    --transform-translate-y: 50%;
  }

  .md\:translate-y-7\/12 {
    --transform-translate-y: 58.3333%;
  }

  .md\:translate-y-8\/12 {
    --transform-translate-y: 66.6667%;
  }

  .md\:translate-y-9\/12 {
    --transform-translate-y: 75%;
  }

  .md\:translate-y-10\/12 {
    --transform-translate-y: 83.3333%;
  }

  .md\:translate-y-11\/12 {
    --transform-translate-y: 91.6667%;
  }

  .md\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .md\:-translate-y-1 {
    --transform-translate-y: -.25rem;
  }

  .md\:-translate-y-2 {
    --transform-translate-y: -.5rem;
  }

  .md\:-translate-y-3 {
    --transform-translate-y: -.75rem;
  }

  .md\:-translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .md\:-translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .md\:-translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .md\:-translate-y-7 {
    --transform-translate-y: -1.75rem;
  }

  .md\:-translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .md\:-translate-y-9 {
    --transform-translate-y: -2.25rem;
  }

  .md\:-translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .md\:-translate-y-11 {
    --transform-translate-y: -2.75rem;
  }

  .md\:-translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .md\:-translate-y-13 {
    --transform-translate-y: -3.25rem;
  }

  .md\:-translate-y-14 {
    --transform-translate-y: -3.5rem;
  }

  .md\:-translate-y-15 {
    --transform-translate-y: -3.75rem;
  }

  .md\:-translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .md\:-translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .md\:-translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .md\:-translate-y-28 {
    --transform-translate-y: -7rem;
  }

  .md\:-translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .md\:-translate-y-36 {
    --transform-translate-y: -9rem;
  }

  .md\:-translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .md\:-translate-y-44 {
    --transform-translate-y: -11rem;
  }

  .md\:-translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .md\:-translate-y-52 {
    --transform-translate-y: -13rem;
  }

  .md\:-translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .md\:-translate-y-60 {
    --transform-translate-y: -15rem;
  }

  .md\:-translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .md\:-translate-y-72 {
    --transform-translate-y: -18rem;
  }

  .md\:-translate-y-80 {
    --transform-translate-y: -20rem;
  }

  .md\:-translate-y-96 {
    --transform-translate-y: -24rem;
  }

  .md\:-translate-y-px {
    --transform-translate-y: -1px;
  }

  .md\:-translate-y-0\.5 {
    --transform-translate-y: -.125rem;
  }

  .md\:-translate-y-1\.5 {
    --transform-translate-y: -.375rem;
  }

  .md\:-translate-y-2\.5 {
    --transform-translate-y: -.625rem;
  }

  .md\:-translate-y-3\.5 {
    --transform-translate-y: -.875rem;
  }

  .md\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .md\:-translate-y-1\/3 {
    --transform-translate-y: -33.3333%;
  }

  .md\:-translate-y-2\/3 {
    --transform-translate-y: -66.6667%;
  }

  .md\:-translate-y-1\/4 {
    --transform-translate-y: -25%;
  }

  .md\:-translate-y-2\/4 {
    --transform-translate-y: -50%;
  }

  .md\:-translate-y-3\/4 {
    --transform-translate-y: -75%;
  }

  .md\:-translate-y-1\/5 {
    --transform-translate-y: -20%;
  }

  .md\:-translate-y-2\/5 {
    --transform-translate-y: -40%;
  }

  .md\:-translate-y-3\/5 {
    --transform-translate-y: -60%;
  }

  .md\:-translate-y-4\/5 {
    --transform-translate-y: -80%;
  }

  .md\:-translate-y-1\/6 {
    --transform-translate-y: -16.6667%;
  }

  .md\:-translate-y-2\/6 {
    --transform-translate-y: -33.3333%;
  }

  .md\:-translate-y-3\/6 {
    --transform-translate-y: -50%;
  }

  .md\:-translate-y-4\/6 {
    --transform-translate-y: -66.6667%;
  }

  .md\:-translate-y-5\/6 {
    --transform-translate-y: -83.3333%;
  }

  .md\:-translate-y-1\/12 {
    --transform-translate-y: -8.33333%;
  }

  .md\:-translate-y-2\/12 {
    --transform-translate-y: -16.6667%;
  }

  .md\:-translate-y-3\/12 {
    --transform-translate-y: -25%;
  }

  .md\:-translate-y-4\/12 {
    --transform-translate-y: -33.3333%;
  }

  .md\:-translate-y-5\/12 {
    --transform-translate-y: -41.6667%;
  }

  .md\:-translate-y-6\/12 {
    --transform-translate-y: -50%;
  }

  .md\:-translate-y-7\/12 {
    --transform-translate-y: -58.3333%;
  }

  .md\:-translate-y-8\/12 {
    --transform-translate-y: -66.6667%;
  }

  .md\:-translate-y-9\/12 {
    --transform-translate-y: -75%;
  }

  .md\:-translate-y-10\/12 {
    --transform-translate-y: -83.3333%;
  }

  .md\:-translate-y-11\/12 {
    --transform-translate-y: -91.6667%;
  }

  .md\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .md\:hover\:translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .md\:hover\:translate-x-1:hover {
    --transform-translate-x: .25rem;
  }

  .md\:hover\:translate-x-2:hover {
    --transform-translate-x: .5rem;
  }

  .md\:hover\:translate-x-3:hover {
    --transform-translate-x: .75rem;
  }

  .md\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .md\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .md\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .md\:hover\:translate-x-7:hover {
    --transform-translate-x: 1.75rem;
  }

  .md\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .md\:hover\:translate-x-9:hover {
    --transform-translate-x: 2.25rem;
  }

  .md\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .md\:hover\:translate-x-11:hover {
    --transform-translate-x: 2.75rem;
  }

  .md\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .md\:hover\:translate-x-13:hover {
    --transform-translate-x: 3.25rem;
  }

  .md\:hover\:translate-x-14:hover {
    --transform-translate-x: 3.5rem;
  }

  .md\:hover\:translate-x-15:hover {
    --transform-translate-x: 3.75rem;
  }

  .md\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .md\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .md\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .md\:hover\:translate-x-28:hover {
    --transform-translate-x: 7rem;
  }

  .md\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .md\:hover\:translate-x-36:hover {
    --transform-translate-x: 9rem;
  }

  .md\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .md\:hover\:translate-x-44:hover {
    --transform-translate-x: 11rem;
  }

  .md\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .md\:hover\:translate-x-52:hover {
    --transform-translate-x: 13rem;
  }

  .md\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .md\:hover\:translate-x-60:hover {
    --transform-translate-x: 15rem;
  }

  .md\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .md\:hover\:translate-x-72:hover {
    --transform-translate-x: 18rem;
  }

  .md\:hover\:translate-x-80:hover {
    --transform-translate-x: 20rem;
  }

  .md\:hover\:translate-x-96:hover {
    --transform-translate-x: 24rem;
  }

  .md\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .md\:hover\:translate-x-0\.5:hover {
    --transform-translate-x: .125rem;
  }

  .md\:hover\:translate-x-1\.5:hover {
    --transform-translate-x: .375rem;
  }

  .md\:hover\:translate-x-2\.5:hover {
    --transform-translate-x: .625rem;
  }

  .md\:hover\:translate-x-3\.5:hover {
    --transform-translate-x: .875rem;
  }

  .md\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .md\:hover\:translate-x-1\/3:hover {
    --transform-translate-x: 33.3333%;
  }

  .md\:hover\:translate-x-2\/3:hover {
    --transform-translate-x: 66.6667%;
  }

  .md\:hover\:translate-x-1\/4:hover {
    --transform-translate-x: 25%;
  }

  .md\:hover\:translate-x-2\/4:hover {
    --transform-translate-x: 50%;
  }

  .md\:hover\:translate-x-3\/4:hover {
    --transform-translate-x: 75%;
  }

  .md\:hover\:translate-x-1\/5:hover {
    --transform-translate-x: 20%;
  }

  .md\:hover\:translate-x-2\/5:hover {
    --transform-translate-x: 40%;
  }

  .md\:hover\:translate-x-3\/5:hover {
    --transform-translate-x: 60%;
  }

  .md\:hover\:translate-x-4\/5:hover {
    --transform-translate-x: 80%;
  }

  .md\:hover\:translate-x-1\/6:hover {
    --transform-translate-x: 16.6667%;
  }

  .md\:hover\:translate-x-2\/6:hover {
    --transform-translate-x: 33.3333%;
  }

  .md\:hover\:translate-x-3\/6:hover {
    --transform-translate-x: 50%;
  }

  .md\:hover\:translate-x-4\/6:hover {
    --transform-translate-x: 66.6667%;
  }

  .md\:hover\:translate-x-5\/6:hover {
    --transform-translate-x: 83.3333%;
  }

  .md\:hover\:translate-x-1\/12:hover {
    --transform-translate-x: 8.33333%;
  }

  .md\:hover\:translate-x-2\/12:hover {
    --transform-translate-x: 16.6667%;
  }

  .md\:hover\:translate-x-3\/12:hover {
    --transform-translate-x: 25%;
  }

  .md\:hover\:translate-x-4\/12:hover {
    --transform-translate-x: 33.3333%;
  }

  .md\:hover\:translate-x-5\/12:hover {
    --transform-translate-x: 41.6667%;
  }

  .md\:hover\:translate-x-6\/12:hover {
    --transform-translate-x: 50%;
  }

  .md\:hover\:translate-x-7\/12:hover {
    --transform-translate-x: 58.3333%;
  }

  .md\:hover\:translate-x-8\/12:hover {
    --transform-translate-x: 66.6667%;
  }

  .md\:hover\:translate-x-9\/12:hover {
    --transform-translate-x: 75%;
  }

  .md\:hover\:translate-x-10\/12:hover {
    --transform-translate-x: 83.3333%;
  }

  .md\:hover\:translate-x-11\/12:hover {
    --transform-translate-x: 91.6667%;
  }

  .md\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .md\:hover\:-translate-x-1:hover {
    --transform-translate-x: -.25rem;
  }

  .md\:hover\:-translate-x-2:hover {
    --transform-translate-x: -.5rem;
  }

  .md\:hover\:-translate-x-3:hover {
    --transform-translate-x: -.75rem;
  }

  .md\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .md\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .md\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .md\:hover\:-translate-x-7:hover {
    --transform-translate-x: -1.75rem;
  }

  .md\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .md\:hover\:-translate-x-9:hover {
    --transform-translate-x: -2.25rem;
  }

  .md\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .md\:hover\:-translate-x-11:hover {
    --transform-translate-x: -2.75rem;
  }

  .md\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .md\:hover\:-translate-x-13:hover {
    --transform-translate-x: -3.25rem;
  }

  .md\:hover\:-translate-x-14:hover {
    --transform-translate-x: -3.5rem;
  }

  .md\:hover\:-translate-x-15:hover {
    --transform-translate-x: -3.75rem;
  }

  .md\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .md\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .md\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .md\:hover\:-translate-x-28:hover {
    --transform-translate-x: -7rem;
  }

  .md\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .md\:hover\:-translate-x-36:hover {
    --transform-translate-x: -9rem;
  }

  .md\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .md\:hover\:-translate-x-44:hover {
    --transform-translate-x: -11rem;
  }

  .md\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .md\:hover\:-translate-x-52:hover {
    --transform-translate-x: -13rem;
  }

  .md\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .md\:hover\:-translate-x-60:hover {
    --transform-translate-x: -15rem;
  }

  .md\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .md\:hover\:-translate-x-72:hover {
    --transform-translate-x: -18rem;
  }

  .md\:hover\:-translate-x-80:hover {
    --transform-translate-x: -20rem;
  }

  .md\:hover\:-translate-x-96:hover {
    --transform-translate-x: -24rem;
  }

  .md\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .md\:hover\:-translate-x-0\.5:hover {
    --transform-translate-x: -.125rem;
  }

  .md\:hover\:-translate-x-1\.5:hover {
    --transform-translate-x: -.375rem;
  }

  .md\:hover\:-translate-x-2\.5:hover {
    --transform-translate-x: -.625rem;
  }

  .md\:hover\:-translate-x-3\.5:hover {
    --transform-translate-x: -.875rem;
  }

  .md\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .md\:hover\:-translate-x-1\/3:hover {
    --transform-translate-x: -33.3333%;
  }

  .md\:hover\:-translate-x-2\/3:hover {
    --transform-translate-x: -66.6667%;
  }

  .md\:hover\:-translate-x-1\/4:hover {
    --transform-translate-x: -25%;
  }

  .md\:hover\:-translate-x-2\/4:hover {
    --transform-translate-x: -50%;
  }

  .md\:hover\:-translate-x-3\/4:hover {
    --transform-translate-x: -75%;
  }

  .md\:hover\:-translate-x-1\/5:hover {
    --transform-translate-x: -20%;
  }

  .md\:hover\:-translate-x-2\/5:hover {
    --transform-translate-x: -40%;
  }

  .md\:hover\:-translate-x-3\/5:hover {
    --transform-translate-x: -60%;
  }

  .md\:hover\:-translate-x-4\/5:hover {
    --transform-translate-x: -80%;
  }

  .md\:hover\:-translate-x-1\/6:hover {
    --transform-translate-x: -16.6667%;
  }

  .md\:hover\:-translate-x-2\/6:hover {
    --transform-translate-x: -33.3333%;
  }

  .md\:hover\:-translate-x-3\/6:hover {
    --transform-translate-x: -50%;
  }

  .md\:hover\:-translate-x-4\/6:hover {
    --transform-translate-x: -66.6667%;
  }

  .md\:hover\:-translate-x-5\/6:hover {
    --transform-translate-x: -83.3333%;
  }

  .md\:hover\:-translate-x-1\/12:hover {
    --transform-translate-x: -8.33333%;
  }

  .md\:hover\:-translate-x-2\/12:hover {
    --transform-translate-x: -16.6667%;
  }

  .md\:hover\:-translate-x-3\/12:hover {
    --transform-translate-x: -25%;
  }

  .md\:hover\:-translate-x-4\/12:hover {
    --transform-translate-x: -33.3333%;
  }

  .md\:hover\:-translate-x-5\/12:hover {
    --transform-translate-x: -41.6667%;
  }

  .md\:hover\:-translate-x-6\/12:hover {
    --transform-translate-x: -50%;
  }

  .md\:hover\:-translate-x-7\/12:hover {
    --transform-translate-x: -58.3333%;
  }

  .md\:hover\:-translate-x-8\/12:hover {
    --transform-translate-x: -66.6667%;
  }

  .md\:hover\:-translate-x-9\/12:hover {
    --transform-translate-x: -75%;
  }

  .md\:hover\:-translate-x-10\/12:hover {
    --transform-translate-x: -83.3333%;
  }

  .md\:hover\:-translate-x-11\/12:hover {
    --transform-translate-x: -91.6667%;
  }

  .md\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .md\:hover\:translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .md\:hover\:translate-y-1:hover {
    --transform-translate-y: .25rem;
  }

  .md\:hover\:translate-y-2:hover {
    --transform-translate-y: .5rem;
  }

  .md\:hover\:translate-y-3:hover {
    --transform-translate-y: .75rem;
  }

  .md\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .md\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .md\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .md\:hover\:translate-y-7:hover {
    --transform-translate-y: 1.75rem;
  }

  .md\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .md\:hover\:translate-y-9:hover {
    --transform-translate-y: 2.25rem;
  }

  .md\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .md\:hover\:translate-y-11:hover {
    --transform-translate-y: 2.75rem;
  }

  .md\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .md\:hover\:translate-y-13:hover {
    --transform-translate-y: 3.25rem;
  }

  .md\:hover\:translate-y-14:hover {
    --transform-translate-y: 3.5rem;
  }

  .md\:hover\:translate-y-15:hover {
    --transform-translate-y: 3.75rem;
  }

  .md\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .md\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .md\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .md\:hover\:translate-y-28:hover {
    --transform-translate-y: 7rem;
  }

  .md\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .md\:hover\:translate-y-36:hover {
    --transform-translate-y: 9rem;
  }

  .md\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .md\:hover\:translate-y-44:hover {
    --transform-translate-y: 11rem;
  }

  .md\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .md\:hover\:translate-y-52:hover {
    --transform-translate-y: 13rem;
  }

  .md\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .md\:hover\:translate-y-60:hover {
    --transform-translate-y: 15rem;
  }

  .md\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .md\:hover\:translate-y-72:hover {
    --transform-translate-y: 18rem;
  }

  .md\:hover\:translate-y-80:hover {
    --transform-translate-y: 20rem;
  }

  .md\:hover\:translate-y-96:hover {
    --transform-translate-y: 24rem;
  }

  .md\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .md\:hover\:translate-y-0\.5:hover {
    --transform-translate-y: .125rem;
  }

  .md\:hover\:translate-y-1\.5:hover {
    --transform-translate-y: .375rem;
  }

  .md\:hover\:translate-y-2\.5:hover {
    --transform-translate-y: .625rem;
  }

  .md\:hover\:translate-y-3\.5:hover {
    --transform-translate-y: .875rem;
  }

  .md\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .md\:hover\:translate-y-1\/3:hover {
    --transform-translate-y: 33.3333%;
  }

  .md\:hover\:translate-y-2\/3:hover {
    --transform-translate-y: 66.6667%;
  }

  .md\:hover\:translate-y-1\/4:hover {
    --transform-translate-y: 25%;
  }

  .md\:hover\:translate-y-2\/4:hover {
    --transform-translate-y: 50%;
  }

  .md\:hover\:translate-y-3\/4:hover {
    --transform-translate-y: 75%;
  }

  .md\:hover\:translate-y-1\/5:hover {
    --transform-translate-y: 20%;
  }

  .md\:hover\:translate-y-2\/5:hover {
    --transform-translate-y: 40%;
  }

  .md\:hover\:translate-y-3\/5:hover {
    --transform-translate-y: 60%;
  }

  .md\:hover\:translate-y-4\/5:hover {
    --transform-translate-y: 80%;
  }

  .md\:hover\:translate-y-1\/6:hover {
    --transform-translate-y: 16.6667%;
  }

  .md\:hover\:translate-y-2\/6:hover {
    --transform-translate-y: 33.3333%;
  }

  .md\:hover\:translate-y-3\/6:hover {
    --transform-translate-y: 50%;
  }

  .md\:hover\:translate-y-4\/6:hover {
    --transform-translate-y: 66.6667%;
  }

  .md\:hover\:translate-y-5\/6:hover {
    --transform-translate-y: 83.3333%;
  }

  .md\:hover\:translate-y-1\/12:hover {
    --transform-translate-y: 8.33333%;
  }

  .md\:hover\:translate-y-2\/12:hover {
    --transform-translate-y: 16.6667%;
  }

  .md\:hover\:translate-y-3\/12:hover {
    --transform-translate-y: 25%;
  }

  .md\:hover\:translate-y-4\/12:hover {
    --transform-translate-y: 33.3333%;
  }

  .md\:hover\:translate-y-5\/12:hover {
    --transform-translate-y: 41.6667%;
  }

  .md\:hover\:translate-y-6\/12:hover {
    --transform-translate-y: 50%;
  }

  .md\:hover\:translate-y-7\/12:hover {
    --transform-translate-y: 58.3333%;
  }

  .md\:hover\:translate-y-8\/12:hover {
    --transform-translate-y: 66.6667%;
  }

  .md\:hover\:translate-y-9\/12:hover {
    --transform-translate-y: 75%;
  }

  .md\:hover\:translate-y-10\/12:hover {
    --transform-translate-y: 83.3333%;
  }

  .md\:hover\:translate-y-11\/12:hover {
    --transform-translate-y: 91.6667%;
  }

  .md\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .md\:hover\:-translate-y-1:hover {
    --transform-translate-y: -.25rem;
  }

  .md\:hover\:-translate-y-2:hover {
    --transform-translate-y: -.5rem;
  }

  .md\:hover\:-translate-y-3:hover {
    --transform-translate-y: -.75rem;
  }

  .md\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .md\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .md\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .md\:hover\:-translate-y-7:hover {
    --transform-translate-y: -1.75rem;
  }

  .md\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .md\:hover\:-translate-y-9:hover {
    --transform-translate-y: -2.25rem;
  }

  .md\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .md\:hover\:-translate-y-11:hover {
    --transform-translate-y: -2.75rem;
  }

  .md\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .md\:hover\:-translate-y-13:hover {
    --transform-translate-y: -3.25rem;
  }

  .md\:hover\:-translate-y-14:hover {
    --transform-translate-y: -3.5rem;
  }

  .md\:hover\:-translate-y-15:hover {
    --transform-translate-y: -3.75rem;
  }

  .md\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .md\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .md\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .md\:hover\:-translate-y-28:hover {
    --transform-translate-y: -7rem;
  }

  .md\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .md\:hover\:-translate-y-36:hover {
    --transform-translate-y: -9rem;
  }

  .md\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .md\:hover\:-translate-y-44:hover {
    --transform-translate-y: -11rem;
  }

  .md\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .md\:hover\:-translate-y-52:hover {
    --transform-translate-y: -13rem;
  }

  .md\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .md\:hover\:-translate-y-60:hover {
    --transform-translate-y: -15rem;
  }

  .md\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .md\:hover\:-translate-y-72:hover {
    --transform-translate-y: -18rem;
  }

  .md\:hover\:-translate-y-80:hover {
    --transform-translate-y: -20rem;
  }

  .md\:hover\:-translate-y-96:hover {
    --transform-translate-y: -24rem;
  }

  .md\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .md\:hover\:-translate-y-0\.5:hover {
    --transform-translate-y: -.125rem;
  }

  .md\:hover\:-translate-y-1\.5:hover {
    --transform-translate-y: -.375rem;
  }

  .md\:hover\:-translate-y-2\.5:hover {
    --transform-translate-y: -.625rem;
  }

  .md\:hover\:-translate-y-3\.5:hover {
    --transform-translate-y: -.875rem;
  }

  .md\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .md\:hover\:-translate-y-1\/3:hover {
    --transform-translate-y: -33.3333%;
  }

  .md\:hover\:-translate-y-2\/3:hover {
    --transform-translate-y: -66.6667%;
  }

  .md\:hover\:-translate-y-1\/4:hover {
    --transform-translate-y: -25%;
  }

  .md\:hover\:-translate-y-2\/4:hover {
    --transform-translate-y: -50%;
  }

  .md\:hover\:-translate-y-3\/4:hover {
    --transform-translate-y: -75%;
  }

  .md\:hover\:-translate-y-1\/5:hover {
    --transform-translate-y: -20%;
  }

  .md\:hover\:-translate-y-2\/5:hover {
    --transform-translate-y: -40%;
  }

  .md\:hover\:-translate-y-3\/5:hover {
    --transform-translate-y: -60%;
  }

  .md\:hover\:-translate-y-4\/5:hover {
    --transform-translate-y: -80%;
  }

  .md\:hover\:-translate-y-1\/6:hover {
    --transform-translate-y: -16.6667%;
  }

  .md\:hover\:-translate-y-2\/6:hover {
    --transform-translate-y: -33.3333%;
  }

  .md\:hover\:-translate-y-3\/6:hover {
    --transform-translate-y: -50%;
  }

  .md\:hover\:-translate-y-4\/6:hover {
    --transform-translate-y: -66.6667%;
  }

  .md\:hover\:-translate-y-5\/6:hover {
    --transform-translate-y: -83.3333%;
  }

  .md\:hover\:-translate-y-1\/12:hover {
    --transform-translate-y: -8.33333%;
  }

  .md\:hover\:-translate-y-2\/12:hover {
    --transform-translate-y: -16.6667%;
  }

  .md\:hover\:-translate-y-3\/12:hover {
    --transform-translate-y: -25%;
  }

  .md\:hover\:-translate-y-4\/12:hover {
    --transform-translate-y: -33.3333%;
  }

  .md\:hover\:-translate-y-5\/12:hover {
    --transform-translate-y: -41.6667%;
  }

  .md\:hover\:-translate-y-6\/12:hover {
    --transform-translate-y: -50%;
  }

  .md\:hover\:-translate-y-7\/12:hover {
    --transform-translate-y: -58.3333%;
  }

  .md\:hover\:-translate-y-8\/12:hover {
    --transform-translate-y: -66.6667%;
  }

  .md\:hover\:-translate-y-9\/12:hover {
    --transform-translate-y: -75%;
  }

  .md\:hover\:-translate-y-10\/12:hover {
    --transform-translate-y: -83.3333%;
  }

  .md\:hover\:-translate-y-11\/12:hover {
    --transform-translate-y: -91.6667%;
  }

  .md\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .md\:focus\:translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .md\:focus\:translate-x-1:focus {
    --transform-translate-x: .25rem;
  }

  .md\:focus\:translate-x-2:focus {
    --transform-translate-x: .5rem;
  }

  .md\:focus\:translate-x-3:focus {
    --transform-translate-x: .75rem;
  }

  .md\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .md\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .md\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .md\:focus\:translate-x-7:focus {
    --transform-translate-x: 1.75rem;
  }

  .md\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .md\:focus\:translate-x-9:focus {
    --transform-translate-x: 2.25rem;
  }

  .md\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .md\:focus\:translate-x-11:focus {
    --transform-translate-x: 2.75rem;
  }

  .md\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .md\:focus\:translate-x-13:focus {
    --transform-translate-x: 3.25rem;
  }

  .md\:focus\:translate-x-14:focus {
    --transform-translate-x: 3.5rem;
  }

  .md\:focus\:translate-x-15:focus {
    --transform-translate-x: 3.75rem;
  }

  .md\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .md\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .md\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .md\:focus\:translate-x-28:focus {
    --transform-translate-x: 7rem;
  }

  .md\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .md\:focus\:translate-x-36:focus {
    --transform-translate-x: 9rem;
  }

  .md\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .md\:focus\:translate-x-44:focus {
    --transform-translate-x: 11rem;
  }

  .md\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .md\:focus\:translate-x-52:focus {
    --transform-translate-x: 13rem;
  }

  .md\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .md\:focus\:translate-x-60:focus {
    --transform-translate-x: 15rem;
  }

  .md\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .md\:focus\:translate-x-72:focus {
    --transform-translate-x: 18rem;
  }

  .md\:focus\:translate-x-80:focus {
    --transform-translate-x: 20rem;
  }

  .md\:focus\:translate-x-96:focus {
    --transform-translate-x: 24rem;
  }

  .md\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .md\:focus\:translate-x-0\.5:focus {
    --transform-translate-x: .125rem;
  }

  .md\:focus\:translate-x-1\.5:focus {
    --transform-translate-x: .375rem;
  }

  .md\:focus\:translate-x-2\.5:focus {
    --transform-translate-x: .625rem;
  }

  .md\:focus\:translate-x-3\.5:focus {
    --transform-translate-x: .875rem;
  }

  .md\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .md\:focus\:translate-x-1\/3:focus {
    --transform-translate-x: 33.3333%;
  }

  .md\:focus\:translate-x-2\/3:focus {
    --transform-translate-x: 66.6667%;
  }

  .md\:focus\:translate-x-1\/4:focus {
    --transform-translate-x: 25%;
  }

  .md\:focus\:translate-x-2\/4:focus {
    --transform-translate-x: 50%;
  }

  .md\:focus\:translate-x-3\/4:focus {
    --transform-translate-x: 75%;
  }

  .md\:focus\:translate-x-1\/5:focus {
    --transform-translate-x: 20%;
  }

  .md\:focus\:translate-x-2\/5:focus {
    --transform-translate-x: 40%;
  }

  .md\:focus\:translate-x-3\/5:focus {
    --transform-translate-x: 60%;
  }

  .md\:focus\:translate-x-4\/5:focus {
    --transform-translate-x: 80%;
  }

  .md\:focus\:translate-x-1\/6:focus {
    --transform-translate-x: 16.6667%;
  }

  .md\:focus\:translate-x-2\/6:focus {
    --transform-translate-x: 33.3333%;
  }

  .md\:focus\:translate-x-3\/6:focus {
    --transform-translate-x: 50%;
  }

  .md\:focus\:translate-x-4\/6:focus {
    --transform-translate-x: 66.6667%;
  }

  .md\:focus\:translate-x-5\/6:focus {
    --transform-translate-x: 83.3333%;
  }

  .md\:focus\:translate-x-1\/12:focus {
    --transform-translate-x: 8.33333%;
  }

  .md\:focus\:translate-x-2\/12:focus {
    --transform-translate-x: 16.6667%;
  }

  .md\:focus\:translate-x-3\/12:focus {
    --transform-translate-x: 25%;
  }

  .md\:focus\:translate-x-4\/12:focus {
    --transform-translate-x: 33.3333%;
  }

  .md\:focus\:translate-x-5\/12:focus {
    --transform-translate-x: 41.6667%;
  }

  .md\:focus\:translate-x-6\/12:focus {
    --transform-translate-x: 50%;
  }

  .md\:focus\:translate-x-7\/12:focus {
    --transform-translate-x: 58.3333%;
  }

  .md\:focus\:translate-x-8\/12:focus {
    --transform-translate-x: 66.6667%;
  }

  .md\:focus\:translate-x-9\/12:focus {
    --transform-translate-x: 75%;
  }

  .md\:focus\:translate-x-10\/12:focus {
    --transform-translate-x: 83.3333%;
  }

  .md\:focus\:translate-x-11\/12:focus {
    --transform-translate-x: 91.6667%;
  }

  .md\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .md\:focus\:-translate-x-1:focus {
    --transform-translate-x: -.25rem;
  }

  .md\:focus\:-translate-x-2:focus {
    --transform-translate-x: -.5rem;
  }

  .md\:focus\:-translate-x-3:focus {
    --transform-translate-x: -.75rem;
  }

  .md\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .md\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .md\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .md\:focus\:-translate-x-7:focus {
    --transform-translate-x: -1.75rem;
  }

  .md\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .md\:focus\:-translate-x-9:focus {
    --transform-translate-x: -2.25rem;
  }

  .md\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .md\:focus\:-translate-x-11:focus {
    --transform-translate-x: -2.75rem;
  }

  .md\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .md\:focus\:-translate-x-13:focus {
    --transform-translate-x: -3.25rem;
  }

  .md\:focus\:-translate-x-14:focus {
    --transform-translate-x: -3.5rem;
  }

  .md\:focus\:-translate-x-15:focus {
    --transform-translate-x: -3.75rem;
  }

  .md\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .md\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .md\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .md\:focus\:-translate-x-28:focus {
    --transform-translate-x: -7rem;
  }

  .md\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .md\:focus\:-translate-x-36:focus {
    --transform-translate-x: -9rem;
  }

  .md\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .md\:focus\:-translate-x-44:focus {
    --transform-translate-x: -11rem;
  }

  .md\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .md\:focus\:-translate-x-52:focus {
    --transform-translate-x: -13rem;
  }

  .md\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .md\:focus\:-translate-x-60:focus {
    --transform-translate-x: -15rem;
  }

  .md\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .md\:focus\:-translate-x-72:focus {
    --transform-translate-x: -18rem;
  }

  .md\:focus\:-translate-x-80:focus {
    --transform-translate-x: -20rem;
  }

  .md\:focus\:-translate-x-96:focus {
    --transform-translate-x: -24rem;
  }

  .md\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .md\:focus\:-translate-x-0\.5:focus {
    --transform-translate-x: -.125rem;
  }

  .md\:focus\:-translate-x-1\.5:focus {
    --transform-translate-x: -.375rem;
  }

  .md\:focus\:-translate-x-2\.5:focus {
    --transform-translate-x: -.625rem;
  }

  .md\:focus\:-translate-x-3\.5:focus {
    --transform-translate-x: -.875rem;
  }

  .md\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .md\:focus\:-translate-x-1\/3:focus {
    --transform-translate-x: -33.3333%;
  }

  .md\:focus\:-translate-x-2\/3:focus {
    --transform-translate-x: -66.6667%;
  }

  .md\:focus\:-translate-x-1\/4:focus {
    --transform-translate-x: -25%;
  }

  .md\:focus\:-translate-x-2\/4:focus {
    --transform-translate-x: -50%;
  }

  .md\:focus\:-translate-x-3\/4:focus {
    --transform-translate-x: -75%;
  }

  .md\:focus\:-translate-x-1\/5:focus {
    --transform-translate-x: -20%;
  }

  .md\:focus\:-translate-x-2\/5:focus {
    --transform-translate-x: -40%;
  }

  .md\:focus\:-translate-x-3\/5:focus {
    --transform-translate-x: -60%;
  }

  .md\:focus\:-translate-x-4\/5:focus {
    --transform-translate-x: -80%;
  }

  .md\:focus\:-translate-x-1\/6:focus {
    --transform-translate-x: -16.6667%;
  }

  .md\:focus\:-translate-x-2\/6:focus {
    --transform-translate-x: -33.3333%;
  }

  .md\:focus\:-translate-x-3\/6:focus {
    --transform-translate-x: -50%;
  }

  .md\:focus\:-translate-x-4\/6:focus {
    --transform-translate-x: -66.6667%;
  }

  .md\:focus\:-translate-x-5\/6:focus {
    --transform-translate-x: -83.3333%;
  }

  .md\:focus\:-translate-x-1\/12:focus {
    --transform-translate-x: -8.33333%;
  }

  .md\:focus\:-translate-x-2\/12:focus {
    --transform-translate-x: -16.6667%;
  }

  .md\:focus\:-translate-x-3\/12:focus {
    --transform-translate-x: -25%;
  }

  .md\:focus\:-translate-x-4\/12:focus {
    --transform-translate-x: -33.3333%;
  }

  .md\:focus\:-translate-x-5\/12:focus {
    --transform-translate-x: -41.6667%;
  }

  .md\:focus\:-translate-x-6\/12:focus {
    --transform-translate-x: -50%;
  }

  .md\:focus\:-translate-x-7\/12:focus {
    --transform-translate-x: -58.3333%;
  }

  .md\:focus\:-translate-x-8\/12:focus {
    --transform-translate-x: -66.6667%;
  }

  .md\:focus\:-translate-x-9\/12:focus {
    --transform-translate-x: -75%;
  }

  .md\:focus\:-translate-x-10\/12:focus {
    --transform-translate-x: -83.3333%;
  }

  .md\:focus\:-translate-x-11\/12:focus {
    --transform-translate-x: -91.6667%;
  }

  .md\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .md\:focus\:translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .md\:focus\:translate-y-1:focus {
    --transform-translate-y: .25rem;
  }

  .md\:focus\:translate-y-2:focus {
    --transform-translate-y: .5rem;
  }

  .md\:focus\:translate-y-3:focus {
    --transform-translate-y: .75rem;
  }

  .md\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .md\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .md\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .md\:focus\:translate-y-7:focus {
    --transform-translate-y: 1.75rem;
  }

  .md\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .md\:focus\:translate-y-9:focus {
    --transform-translate-y: 2.25rem;
  }

  .md\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .md\:focus\:translate-y-11:focus {
    --transform-translate-y: 2.75rem;
  }

  .md\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .md\:focus\:translate-y-13:focus {
    --transform-translate-y: 3.25rem;
  }

  .md\:focus\:translate-y-14:focus {
    --transform-translate-y: 3.5rem;
  }

  .md\:focus\:translate-y-15:focus {
    --transform-translate-y: 3.75rem;
  }

  .md\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .md\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .md\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .md\:focus\:translate-y-28:focus {
    --transform-translate-y: 7rem;
  }

  .md\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .md\:focus\:translate-y-36:focus {
    --transform-translate-y: 9rem;
  }

  .md\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .md\:focus\:translate-y-44:focus {
    --transform-translate-y: 11rem;
  }

  .md\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .md\:focus\:translate-y-52:focus {
    --transform-translate-y: 13rem;
  }

  .md\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .md\:focus\:translate-y-60:focus {
    --transform-translate-y: 15rem;
  }

  .md\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .md\:focus\:translate-y-72:focus {
    --transform-translate-y: 18rem;
  }

  .md\:focus\:translate-y-80:focus {
    --transform-translate-y: 20rem;
  }

  .md\:focus\:translate-y-96:focus {
    --transform-translate-y: 24rem;
  }

  .md\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .md\:focus\:translate-y-0\.5:focus {
    --transform-translate-y: .125rem;
  }

  .md\:focus\:translate-y-1\.5:focus {
    --transform-translate-y: .375rem;
  }

  .md\:focus\:translate-y-2\.5:focus {
    --transform-translate-y: .625rem;
  }

  .md\:focus\:translate-y-3\.5:focus {
    --transform-translate-y: .875rem;
  }

  .md\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .md\:focus\:translate-y-1\/3:focus {
    --transform-translate-y: 33.3333%;
  }

  .md\:focus\:translate-y-2\/3:focus {
    --transform-translate-y: 66.6667%;
  }

  .md\:focus\:translate-y-1\/4:focus {
    --transform-translate-y: 25%;
  }

  .md\:focus\:translate-y-2\/4:focus {
    --transform-translate-y: 50%;
  }

  .md\:focus\:translate-y-3\/4:focus {
    --transform-translate-y: 75%;
  }

  .md\:focus\:translate-y-1\/5:focus {
    --transform-translate-y: 20%;
  }

  .md\:focus\:translate-y-2\/5:focus {
    --transform-translate-y: 40%;
  }

  .md\:focus\:translate-y-3\/5:focus {
    --transform-translate-y: 60%;
  }

  .md\:focus\:translate-y-4\/5:focus {
    --transform-translate-y: 80%;
  }

  .md\:focus\:translate-y-1\/6:focus {
    --transform-translate-y: 16.6667%;
  }

  .md\:focus\:translate-y-2\/6:focus {
    --transform-translate-y: 33.3333%;
  }

  .md\:focus\:translate-y-3\/6:focus {
    --transform-translate-y: 50%;
  }

  .md\:focus\:translate-y-4\/6:focus {
    --transform-translate-y: 66.6667%;
  }

  .md\:focus\:translate-y-5\/6:focus {
    --transform-translate-y: 83.3333%;
  }

  .md\:focus\:translate-y-1\/12:focus {
    --transform-translate-y: 8.33333%;
  }

  .md\:focus\:translate-y-2\/12:focus {
    --transform-translate-y: 16.6667%;
  }

  .md\:focus\:translate-y-3\/12:focus {
    --transform-translate-y: 25%;
  }

  .md\:focus\:translate-y-4\/12:focus {
    --transform-translate-y: 33.3333%;
  }

  .md\:focus\:translate-y-5\/12:focus {
    --transform-translate-y: 41.6667%;
  }

  .md\:focus\:translate-y-6\/12:focus {
    --transform-translate-y: 50%;
  }

  .md\:focus\:translate-y-7\/12:focus {
    --transform-translate-y: 58.3333%;
  }

  .md\:focus\:translate-y-8\/12:focus {
    --transform-translate-y: 66.6667%;
  }

  .md\:focus\:translate-y-9\/12:focus {
    --transform-translate-y: 75%;
  }

  .md\:focus\:translate-y-10\/12:focus {
    --transform-translate-y: 83.3333%;
  }

  .md\:focus\:translate-y-11\/12:focus {
    --transform-translate-y: 91.6667%;
  }

  .md\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .md\:focus\:-translate-y-1:focus {
    --transform-translate-y: -.25rem;
  }

  .md\:focus\:-translate-y-2:focus {
    --transform-translate-y: -.5rem;
  }

  .md\:focus\:-translate-y-3:focus {
    --transform-translate-y: -.75rem;
  }

  .md\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .md\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .md\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .md\:focus\:-translate-y-7:focus {
    --transform-translate-y: -1.75rem;
  }

  .md\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .md\:focus\:-translate-y-9:focus {
    --transform-translate-y: -2.25rem;
  }

  .md\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .md\:focus\:-translate-y-11:focus {
    --transform-translate-y: -2.75rem;
  }

  .md\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .md\:focus\:-translate-y-13:focus {
    --transform-translate-y: -3.25rem;
  }

  .md\:focus\:-translate-y-14:focus {
    --transform-translate-y: -3.5rem;
  }

  .md\:focus\:-translate-y-15:focus {
    --transform-translate-y: -3.75rem;
  }

  .md\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .md\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .md\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .md\:focus\:-translate-y-28:focus {
    --transform-translate-y: -7rem;
  }

  .md\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .md\:focus\:-translate-y-36:focus {
    --transform-translate-y: -9rem;
  }

  .md\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .md\:focus\:-translate-y-44:focus {
    --transform-translate-y: -11rem;
  }

  .md\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .md\:focus\:-translate-y-52:focus {
    --transform-translate-y: -13rem;
  }

  .md\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .md\:focus\:-translate-y-60:focus {
    --transform-translate-y: -15rem;
  }

  .md\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .md\:focus\:-translate-y-72:focus {
    --transform-translate-y: -18rem;
  }

  .md\:focus\:-translate-y-80:focus {
    --transform-translate-y: -20rem;
  }

  .md\:focus\:-translate-y-96:focus {
    --transform-translate-y: -24rem;
  }

  .md\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .md\:focus\:-translate-y-0\.5:focus {
    --transform-translate-y: -.125rem;
  }

  .md\:focus\:-translate-y-1\.5:focus {
    --transform-translate-y: -.375rem;
  }

  .md\:focus\:-translate-y-2\.5:focus {
    --transform-translate-y: -.625rem;
  }

  .md\:focus\:-translate-y-3\.5:focus {
    --transform-translate-y: -.875rem;
  }

  .md\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .md\:focus\:-translate-y-1\/3:focus {
    --transform-translate-y: -33.3333%;
  }

  .md\:focus\:-translate-y-2\/3:focus {
    --transform-translate-y: -66.6667%;
  }

  .md\:focus\:-translate-y-1\/4:focus {
    --transform-translate-y: -25%;
  }

  .md\:focus\:-translate-y-2\/4:focus {
    --transform-translate-y: -50%;
  }

  .md\:focus\:-translate-y-3\/4:focus {
    --transform-translate-y: -75%;
  }

  .md\:focus\:-translate-y-1\/5:focus {
    --transform-translate-y: -20%;
  }

  .md\:focus\:-translate-y-2\/5:focus {
    --transform-translate-y: -40%;
  }

  .md\:focus\:-translate-y-3\/5:focus {
    --transform-translate-y: -60%;
  }

  .md\:focus\:-translate-y-4\/5:focus {
    --transform-translate-y: -80%;
  }

  .md\:focus\:-translate-y-1\/6:focus {
    --transform-translate-y: -16.6667%;
  }

  .md\:focus\:-translate-y-2\/6:focus {
    --transform-translate-y: -33.3333%;
  }

  .md\:focus\:-translate-y-3\/6:focus {
    --transform-translate-y: -50%;
  }

  .md\:focus\:-translate-y-4\/6:focus {
    --transform-translate-y: -66.6667%;
  }

  .md\:focus\:-translate-y-5\/6:focus {
    --transform-translate-y: -83.3333%;
  }

  .md\:focus\:-translate-y-1\/12:focus {
    --transform-translate-y: -8.33333%;
  }

  .md\:focus\:-translate-y-2\/12:focus {
    --transform-translate-y: -16.6667%;
  }

  .md\:focus\:-translate-y-3\/12:focus {
    --transform-translate-y: -25%;
  }

  .md\:focus\:-translate-y-4\/12:focus {
    --transform-translate-y: -33.3333%;
  }

  .md\:focus\:-translate-y-5\/12:focus {
    --transform-translate-y: -41.6667%;
  }

  .md\:focus\:-translate-y-6\/12:focus {
    --transform-translate-y: -50%;
  }

  .md\:focus\:-translate-y-7\/12:focus {
    --transform-translate-y: -58.3333%;
  }

  .md\:focus\:-translate-y-8\/12:focus {
    --transform-translate-y: -66.6667%;
  }

  .md\:focus\:-translate-y-9\/12:focus {
    --transform-translate-y: -75%;
  }

  .md\:focus\:-translate-y-10\/12:focus {
    --transform-translate-y: -83.3333%;
  }

  .md\:focus\:-translate-y-11\/12:focus {
    --transform-translate-y: -91.6667%;
  }

  .md\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .md\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .md\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .md\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .md\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .md\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .md\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .md\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .md\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .md\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .md\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .md\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .md\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .md\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .md\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .md\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .md\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .md\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .md\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .md\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .md\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .md\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .md\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .md\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .md\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .md\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .md\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .md\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .md\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .md\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .md\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .md\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .md\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .md\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .md\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .md\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .md\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .md\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .md\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .md\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .md\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .md\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .md\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .md\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .md\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .md\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .md\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .md\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .md\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .md\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .md\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .md\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .md\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .md\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .md\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .md\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .md\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .md\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .md\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .md\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .md\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .md\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .md\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .md\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .md\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .md\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .md\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .md\:transition-none {
    transition-property: none;
  }

  .md\:transition-all {
    transition-property: all;
  }

  .md\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .md\:transition-opacity {
    transition-property: opacity;
  }

  .md\:transition-shadow {
    transition-property: box-shadow;
  }

  .md\:transition-transform {
    transition-property: transform;
  }

  .md\:ease-linear {
    transition-timing-function: linear;
  }

  .md\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .md\:duration-75 {
    transition-duration: 75ms;
  }

  .md\:duration-100 {
    transition-duration: .1s;
  }

  .md\:duration-150 {
    transition-duration: .15s;
  }

  .md\:duration-200 {
    transition-duration: .2s;
  }

  .md\:duration-300 {
    transition-duration: .3s;
  }

  .md\:duration-500 {
    transition-duration: .5s;
  }

  .md\:duration-700 {
    transition-duration: .7s;
  }

  .md\:duration-1000 {
    transition-duration: 1s;
  }

  .md\:delay-75 {
    transition-delay: 75ms;
  }

  .md\:delay-100 {
    transition-delay: .1s;
  }

  .md\:delay-150 {
    transition-delay: .15s;
  }

  .md\:delay-200 {
    transition-delay: .2s;
  }

  .md\:delay-300 {
    transition-delay: .3s;
  }

  .md\:delay-500 {
    transition-delay: .5s;
  }

  .md\:delay-700 {
    transition-delay: .7s;
  }

  .md\:delay-1000 {
    transition-delay: 1s;
  }

  .md\:animate-none {
    animation: none;
  }

  .md\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .md\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
  }

  .md\:animate-pulse {
    animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
  }

  .md\:animate-bounce {
    animation: bounce 1s infinite;
  }
}

@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }

  .lg\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(0px * var(--space-y-reverse) );
  }

  .lg\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse) );
    margin-left: calc(0px * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.25rem * var(--space-y-reverse) );
  }

  .lg\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.25rem * var(--space-x-reverse) );
    margin-left: calc(.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.5rem * var(--space-y-reverse) );
  }

  .lg\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.5rem * var(--space-x-reverse) );
    margin-left: calc(.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.75rem * var(--space-y-reverse) );
  }

  .lg\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.75rem * var(--space-x-reverse) );
    margin-left: calc(.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1rem * var(--space-y-reverse) );
  }

  .lg\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse) );
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.25rem * var(--space-y-reverse) );
  }

  .lg\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse) );
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.5rem * var(--space-y-reverse) );
  }

  .lg\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse) );
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.75rem * var(--space-y-reverse) );
  }

  .lg\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--space-x-reverse) );
    margin-left: calc(1.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2rem * var(--space-y-reverse) );
  }

  .lg\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse) );
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-9 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.25rem * var(--space-y-reverse) );
  }

  .lg\:space-x-9 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--space-x-reverse) );
    margin-left: calc(2.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.5rem * var(--space-y-reverse) );
  }

  .lg\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse) );
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-11 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.75rem * var(--space-y-reverse) );
  }

  .lg\:space-x-11 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--space-x-reverse) );
    margin-left: calc(2.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3rem * var(--space-y-reverse) );
  }

  .lg\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse) );
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-13 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.25rem * var(--space-y-reverse) );
  }

  .lg\:space-x-13 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.25rem * var(--space-x-reverse) );
    margin-left: calc(3.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.5rem * var(--space-y-reverse) );
  }

  .lg\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--space-x-reverse) );
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-15 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.75rem * var(--space-y-reverse) );
  }

  .lg\:space-x-15 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.75rem * var(--space-x-reverse) );
    margin-left: calc(3.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(4rem * var(--space-y-reverse) );
  }

  .lg\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse) );
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(5rem * var(--space-y-reverse) );
  }

  .lg\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse) );
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(6rem * var(--space-y-reverse) );
  }

  .lg\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse) );
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(7rem * var(--space-y-reverse) );
  }

  .lg\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(7rem * var(--space-x-reverse) );
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(8rem * var(--space-y-reverse) );
  }

  .lg\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse) );
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(9rem * var(--space-y-reverse) );
  }

  .lg\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(9rem * var(--space-x-reverse) );
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(10rem * var(--space-y-reverse) );
  }

  .lg\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse) );
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-44 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(11rem * var(--space-y-reverse) );
  }

  .lg\:space-x-44 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(11rem * var(--space-x-reverse) );
    margin-left: calc(11rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(12rem * var(--space-y-reverse) );
  }

  .lg\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse) );
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-52 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(13rem * var(--space-y-reverse) );
  }

  .lg\:space-x-52 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(13rem * var(--space-x-reverse) );
    margin-left: calc(13rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(14rem * var(--space-y-reverse) );
  }

  .lg\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse) );
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-60 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(15rem * var(--space-y-reverse) );
  }

  .lg\:space-x-60 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15rem * var(--space-x-reverse) );
    margin-left: calc(15rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16rem * var(--space-y-reverse) );
  }

  .lg\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse) );
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-72 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(18rem * var(--space-y-reverse) );
  }

  .lg\:space-x-72 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(18rem * var(--space-x-reverse) );
    margin-left: calc(18rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-80 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(20rem * var(--space-y-reverse) );
  }

  .lg\:space-x-80 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20rem * var(--space-x-reverse) );
    margin-left: calc(20rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-96 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(24rem * var(--space-y-reverse) );
  }

  .lg\:space-x-96 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(24rem * var(--space-x-reverse) );
    margin-left: calc(24rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1px * var(--space-y-reverse) );
  }

  .lg\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse) );
    margin-left: calc(1px * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-0\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.125rem * var(--space-y-reverse) );
  }

  .lg\:space-x-0\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.125rem * var(--space-x-reverse) );
    margin-left: calc(.125rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-1\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.375rem * var(--space-y-reverse) );
  }

  .lg\:space-x-1\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.375rem * var(--space-x-reverse) );
    margin-left: calc(.375rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-2\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.625rem * var(--space-y-reverse) );
  }

  .lg\:space-x-2\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.625rem * var(--space-x-reverse) );
    margin-left: calc(.625rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-3\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.875rem * var(--space-y-reverse) );
  }

  .lg\:space-x-3\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.875rem * var(--space-x-reverse) );
    margin-left: calc(.875rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-1\/2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .lg\:space-x-1\/2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-1\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .lg\:space-x-1\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-2\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .lg\:space-x-2\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-1\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(25% * var(--space-y-reverse) );
  }

  .lg\:space-x-1\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(25% * var(--space-x-reverse) );
    margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-2\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .lg\:space-x-2\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-3\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(75% * var(--space-y-reverse) );
  }

  .lg\:space-x-3\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75% * var(--space-x-reverse) );
    margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-1\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(20% * var(--space-y-reverse) );
  }

  .lg\:space-x-1\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20% * var(--space-x-reverse) );
    margin-left: calc(20% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-2\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(40% * var(--space-y-reverse) );
  }

  .lg\:space-x-2\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40% * var(--space-x-reverse) );
    margin-left: calc(40% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-3\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(60% * var(--space-y-reverse) );
  }

  .lg\:space-x-3\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60% * var(--space-x-reverse) );
    margin-left: calc(60% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-4\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(80% * var(--space-y-reverse) );
  }

  .lg\:space-x-4\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80% * var(--space-x-reverse) );
    margin-left: calc(80% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-1\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16.6667% * var(--space-y-reverse) );
  }

  .lg\:space-x-1\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16.6667% * var(--space-x-reverse) );
    margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-2\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .lg\:space-x-2\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-3\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .lg\:space-x-3\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-4\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .lg\:space-x-4\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-5\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(83.3333% * var(--space-y-reverse) );
  }

  .lg\:space-x-5\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(83.3333% * var(--space-x-reverse) );
    margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-1\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8.33333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(8.33333% * var(--space-y-reverse) );
  }

  .lg\:space-x-1\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8.33333% * var(--space-x-reverse) );
    margin-left: calc(8.33333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-2\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16.6667% * var(--space-y-reverse) );
  }

  .lg\:space-x-2\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16.6667% * var(--space-x-reverse) );
    margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-3\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(25% * var(--space-y-reverse) );
  }

  .lg\:space-x-3\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(25% * var(--space-x-reverse) );
    margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-4\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .lg\:space-x-4\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-5\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(41.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(41.6667% * var(--space-y-reverse) );
  }

  .lg\:space-x-5\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(41.6667% * var(--space-x-reverse) );
    margin-left: calc(41.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-6\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .lg\:space-x-6\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-7\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(58.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(58.3333% * var(--space-y-reverse) );
  }

  .lg\:space-x-7\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(58.3333% * var(--space-x-reverse) );
    margin-left: calc(58.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-8\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .lg\:space-x-8\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-9\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(75% * var(--space-y-reverse) );
  }

  .lg\:space-x-9\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75% * var(--space-x-reverse) );
    margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-10\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(83.3333% * var(--space-y-reverse) );
  }

  .lg\:space-x-10\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(83.3333% * var(--space-x-reverse) );
    margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-11\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(91.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(91.6667% * var(--space-y-reverse) );
  }

  .lg\:space-x-11\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(91.6667% * var(--space-x-reverse) );
    margin-left: calc(91.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-full > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(100% * var(--space-y-reverse) );
  }

  .lg\:space-x-full > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100% * var(--space-x-reverse) );
    margin-left: calc(100% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.25rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--space-x-reverse) );
    margin-left: calc(-.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.5rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--space-x-reverse) );
    margin-left: calc(-.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.75rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--space-x-reverse) );
    margin-left: calc(-.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse) );
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.25rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse) );
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.5rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse) );
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.75rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--space-x-reverse) );
    margin-left: calc(-1.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse) );
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-9 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.25rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-9 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--space-x-reverse) );
    margin-left: calc(-2.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.5rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse) );
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-11 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.75rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-11 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--space-x-reverse) );
    margin-left: calc(-2.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse) );
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-13 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.25rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-13 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.25rem * var(--space-x-reverse) );
    margin-left: calc(-3.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.5rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--space-x-reverse) );
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-15 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.75rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-15 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.75rem * var(--space-x-reverse) );
    margin-left: calc(-3.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-4rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse) );
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-5rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse) );
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-6rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse) );
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-7rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-7rem * var(--space-x-reverse) );
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-8rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse) );
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-9rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-9rem * var(--space-x-reverse) );
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-10rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse) );
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-44 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-11rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-44 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-11rem * var(--space-x-reverse) );
    margin-left: calc(-11rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-12rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse) );
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-52 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-13rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-52 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-13rem * var(--space-x-reverse) );
    margin-left: calc(-13rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-14rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse) );
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-60 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-15rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-60 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15rem * var(--space-x-reverse) );
    margin-left: calc(-15rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse) );
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-72 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-18rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-72 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-18rem * var(--space-x-reverse) );
    margin-left: calc(-18rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-80 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-20rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-80 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20rem * var(--space-x-reverse) );
    margin-left: calc(-20rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-96 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-24rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-96 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-24rem * var(--space-x-reverse) );
    margin-left: calc(-24rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1px * var(--space-y-reverse) );
  }

  .lg\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse) );
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-0\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.125rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-0\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--space-x-reverse) );
    margin-left: calc(-.125rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-1\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.375rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-1\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--space-x-reverse) );
    margin-left: calc(-.375rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-2\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.625rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-2\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--space-x-reverse) );
    margin-left: calc(-.625rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-3\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.875rem * var(--space-y-reverse) );
  }

  .lg\:-space-x-3\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--space-x-reverse) );
    margin-left: calc(-.875rem * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-1\/2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .lg\:-space-x-1\/2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-1\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .lg\:-space-x-1\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-2\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .lg\:-space-x-2\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-1\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-25% * var(--space-y-reverse) );
  }

  .lg\:-space-x-1\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-25% * var(--space-x-reverse) );
    margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-2\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .lg\:-space-x-2\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-3\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-75% * var(--space-y-reverse) );
  }

  .lg\:-space-x-3\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75% * var(--space-x-reverse) );
    margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-1\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-20% * var(--space-y-reverse) );
  }

  .lg\:-space-x-1\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20% * var(--space-x-reverse) );
    margin-left: calc(-20% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-2\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-40% * var(--space-y-reverse) );
  }

  .lg\:-space-x-2\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40% * var(--space-x-reverse) );
    margin-left: calc(-40% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-3\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-60% * var(--space-y-reverse) );
  }

  .lg\:-space-x-3\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60% * var(--space-x-reverse) );
    margin-left: calc(-60% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-4\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-80% * var(--space-y-reverse) );
  }

  .lg\:-space-x-4\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80% * var(--space-x-reverse) );
    margin-left: calc(-80% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-1\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
  }

  .lg\:-space-x-1\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16.6667% * var(--space-x-reverse) );
    margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-2\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .lg\:-space-x-2\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-3\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .lg\:-space-x-3\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-4\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .lg\:-space-x-4\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-5\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
  }

  .lg\:-space-x-5\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-83.3333% * var(--space-x-reverse) );
    margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-1\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8.33333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-8.33333% * var(--space-y-reverse) );
  }

  .lg\:-space-x-1\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8.33333% * var(--space-x-reverse) );
    margin-left: calc(-8.33333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-2\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
  }

  .lg\:-space-x-2\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16.6667% * var(--space-x-reverse) );
    margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-3\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-25% * var(--space-y-reverse) );
  }

  .lg\:-space-x-3\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-25% * var(--space-x-reverse) );
    margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-4\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .lg\:-space-x-4\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-5\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-41.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-41.6667% * var(--space-y-reverse) );
  }

  .lg\:-space-x-5\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-41.6667% * var(--space-x-reverse) );
    margin-left: calc(-41.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-6\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .lg\:-space-x-6\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-7\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-58.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-58.3333% * var(--space-y-reverse) );
  }

  .lg\:-space-x-7\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-58.3333% * var(--space-x-reverse) );
    margin-left: calc(-58.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-8\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .lg\:-space-x-8\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-9\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-75% * var(--space-y-reverse) );
  }

  .lg\:-space-x-9\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75% * var(--space-x-reverse) );
    margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-10\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
  }

  .lg\:-space-x-10\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-83.3333% * var(--space-x-reverse) );
    margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-11\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-91.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-91.6667% * var(--space-y-reverse) );
  }

  .lg\:-space-x-11\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-91.6667% * var(--space-x-reverse) );
    margin-left: calc(-91.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:-space-y-full > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-100% * var(--space-y-reverse) );
  }

  .lg\:-space-x-full > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100% * var(--space-x-reverse) );
    margin-left: calc(-100% * calc(1 - var(--space-x-reverse) ) );
  }

  .lg\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .lg\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .lg\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(0px * var(--divide-y-reverse) );
  }

  .lg\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse) );
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse) ) );
  }

  .lg\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(2px * var(--divide-y-reverse) );
  }

  .lg\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse) );
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse) ) );
  }

  .lg\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(4px * var(--divide-y-reverse) );
  }

  .lg\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse) );
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse) ) );
  }

  .lg\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(8px * var(--divide-y-reverse) );
  }

  .lg\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse) );
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse) ) );
  }

  .lg\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(1px * var(--divide-y-reverse) );
  }

  .lg\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse) );
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse) ) );
  }

  .lg\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .lg\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .lg\:divide-transparent > :not(template) ~ :not(template) {
    border-color: #0000;
  }

  .lg\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity) );
  }

  .lg\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity) );
  }

  .lg\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--divide-opacity) );
  }

  .lg\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--divide-opacity) );
  }

  .lg\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--divide-opacity) );
  }

  .lg\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--divide-opacity) );
  }

  .lg\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--divide-opacity) );
  }

  .lg\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--divide-opacity) );
  }

  .lg\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--divide-opacity) );
  }

  .lg\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--divide-opacity) );
  }

  .lg\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--divide-opacity) );
  }

  .lg\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--divide-opacity) );
  }

  .lg\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--divide-opacity) );
  }

  .lg\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--divide-opacity) );
  }

  .lg\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--divide-opacity) );
  }

  .lg\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--divide-opacity) );
  }

  .lg\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--divide-opacity) );
  }

  .lg\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--divide-opacity) );
  }

  .lg\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--divide-opacity) );
  }

  .lg\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--divide-opacity) );
  }

  .lg\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--divide-opacity) );
  }

  .lg\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--divide-opacity) );
  }

  .lg\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--divide-opacity) );
  }

  .lg\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--divide-opacity) );
  }

  .lg\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--divide-opacity) );
  }

  .lg\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--divide-opacity) );
  }

  .lg\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--divide-opacity) );
  }

  .lg\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--divide-opacity) );
  }

  .lg\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--divide-opacity) );
  }

  .lg\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--divide-opacity) );
  }

  .lg\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--divide-opacity) );
  }

  .lg\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--divide-opacity) );
  }

  .lg\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--divide-opacity) );
  }

  .lg\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--divide-opacity) );
  }

  .lg\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--divide-opacity) );
  }

  .lg\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--divide-opacity) );
  }

  .lg\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--divide-opacity) );
  }

  .lg\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--divide-opacity) );
  }

  .lg\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--divide-opacity) );
  }

  .lg\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--divide-opacity) );
  }

  .lg\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--divide-opacity) );
  }

  .lg\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--divide-opacity) );
  }

  .lg\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--divide-opacity) );
  }

  .lg\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--divide-opacity) );
  }

  .lg\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--divide-opacity) );
  }

  .lg\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--divide-opacity) );
  }

  .lg\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--divide-opacity) );
  }

  .lg\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--divide-opacity) );
  }

  .lg\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--divide-opacity) );
  }

  .lg\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--divide-opacity) );
  }

  .lg\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--divide-opacity) );
  }

  .lg\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--divide-opacity) );
  }

  .lg\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--divide-opacity) );
  }

  .lg\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--divide-opacity) );
  }

  .lg\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--divide-opacity) );
  }

  .lg\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--divide-opacity) );
  }

  .lg\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--divide-opacity) );
  }

  .lg\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--divide-opacity) );
  }

  .lg\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--divide-opacity) );
  }

  .lg\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--divide-opacity) );
  }

  .lg\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--divide-opacity) );
  }

  .lg\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--divide-opacity) );
  }

  .lg\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--divide-opacity) );
  }

  .lg\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--divide-opacity) );
  }

  .lg\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--divide-opacity) );
  }

  .lg\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--divide-opacity) );
  }

  .lg\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--divide-opacity) );
  }

  .lg\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--divide-opacity) );
  }

  .lg\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--divide-opacity) );
  }

  .lg\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--divide-opacity) );
  }

  .lg\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--divide-opacity) );
  }

  .lg\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--divide-opacity) );
  }

  .lg\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--divide-opacity) );
  }

  .lg\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--divide-opacity) );
  }

  .lg\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--divide-opacity) );
  }

  .lg\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--divide-opacity) );
  }

  .lg\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--divide-opacity) );
  }

  .lg\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--divide-opacity) );
  }

  .lg\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--divide-opacity) );
  }

  .lg\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--divide-opacity) );
  }

  .lg\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--divide-opacity) );
  }

  .lg\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--divide-opacity) );
  }

  .lg\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--divide-opacity) );
  }

  .lg\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--divide-opacity) );
  }

  .lg\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--divide-opacity) );
  }

  .lg\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--divide-opacity) );
  }

  .lg\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--divide-opacity) );
  }

  .lg\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--divide-opacity) );
  }

  .lg\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--divide-opacity) );
  }

  .lg\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--divide-opacity) );
  }

  .lg\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--divide-opacity) );
  }

  .lg\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--divide-opacity) );
  }

  .lg\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--divide-opacity) );
  }

  .lg\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--divide-opacity) );
  }

  .lg\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--divide-opacity) );
  }

  .lg\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--divide-opacity) );
  }

  .lg\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--divide-opacity) );
  }

  .lg\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--divide-opacity) );
  }

  .lg\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--divide-opacity) );
  }

  .lg\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--divide-opacity) );
  }

  .lg\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--divide-opacity) );
  }

  .lg\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--divide-opacity) );
  }

  .lg\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--divide-opacity) );
  }

  .lg\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .lg\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .lg\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .lg\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .lg\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .lg\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .lg\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: .25;
  }

  .lg\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: .5;
  }

  .lg\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: .75;
  }

  .lg\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .lg\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .lg\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .lg\:appearance-none {
    appearance: none;
  }

  .lg\:bg-fixed {
    background-attachment: fixed;
  }

  .lg\:bg-local {
    background-attachment: local;
  }

  .lg\:bg-scroll {
    background-attachment: scroll;
  }

  .lg\:bg-clip-border {
    background-clip: border-box;
  }

  .lg\:bg-clip-padding {
    background-clip: padding-box;
  }

  .lg\:bg-clip-content {
    background-clip: content-box;
  }

  .lg\:bg-clip-text {
    background-clip: text;
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .lg\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .lg\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .lg\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .lg\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .lg\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .lg\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .lg\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .lg\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .lg\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .lg\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .lg\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .lg\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .lg\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .lg\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .lg\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .lg\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .lg\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .lg\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .lg\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .lg\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .lg\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .lg\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .lg\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .lg\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .lg\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .lg\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .lg\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .lg\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .lg\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .lg\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .lg\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .lg\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .lg\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .lg\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .lg\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .lg\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .lg\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .lg\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .lg\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .lg\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .lg\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .lg\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .lg\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .lg\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .lg\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .lg\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .lg\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .lg\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .lg\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .lg\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .lg\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .lg\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .lg\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .lg\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .lg\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .lg\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .lg\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .lg\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .lg\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .lg\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .lg\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .lg\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .lg\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .lg\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .lg\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .lg\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .lg\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .lg\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .lg\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .lg\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .lg\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .lg\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .lg\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .lg\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .lg\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .lg\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .lg\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .lg\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .lg\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .lg\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .lg\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .lg\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .lg\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .lg\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .lg\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .lg\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .lg\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .lg\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .lg\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .lg\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .lg\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .lg\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .lg\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .lg\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .lg\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .lg\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .lg\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .lg\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .lg\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .lg\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .lg\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .lg\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .lg\:group-hover\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .group:hover .lg\:group-hover\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-transparent {
    background-color: #0000;
  }

  .group:focus .lg\:group-focus\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .group:focus .lg\:group-focus\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .lg\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .lg\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .lg\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-50:hover {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .lg\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-50:hover {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-300:hover {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-400:hover {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-500:hover {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-600:hover {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-700:hover {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-800:hover {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .lg\:hover\:bg-cool-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .lg\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-50:hover {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .lg\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-50:hover {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .lg\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-50:hover {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .lg\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-50:hover {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .lg\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-50:hover {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .lg\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-50:hover {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .lg\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-50:hover {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .lg\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .lg\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .lg\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .lg\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .lg\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-50:focus {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .lg\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-50:focus {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-300:focus {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-400:focus {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-500:focus {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-600:focus {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-700:focus {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-800:focus {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .lg\:focus\:bg-cool-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .lg\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-50:focus {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .lg\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-50:focus {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .lg\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-50:focus {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .lg\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-50:focus {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .lg\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-50:focus {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .lg\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-50:focus {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .lg\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-50:focus {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .lg\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .lg\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .lg\:active\:bg-transparent:active {
    background-color: #0000;
  }

  .lg\:active\:bg-white:active {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .lg\:active\:bg-black:active {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-50:active {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-100:active {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-200:active {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-300:active {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-400:active {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-500:active {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-600:active {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-700:active {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-800:active {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .lg\:active\:bg-gray-900:active {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-50:active {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-100:active {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-200:active {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-300:active {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-400:active {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-500:active {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-600:active {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-700:active {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-800:active {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .lg\:active\:bg-cool-gray-900:active {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-100:active {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-200:active {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-300:active {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-400:active {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-500:active {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-600:active {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-700:active {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-800:active {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .lg\:active\:bg-red-900:active {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-50:active {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-100:active {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-200:active {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-300:active {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-400:active {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-500:active {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-600:active {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-700:active {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-800:active {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .lg\:active\:bg-orange-900:active {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-50:active {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-100:active {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-200:active {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-300:active {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-400:active {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-500:active {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-600:active {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-700:active {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-800:active {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .lg\:active\:bg-yellow-900:active {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-50:active {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-100:active {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-200:active {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-300:active {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-400:active {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-500:active {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-600:active {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-700:active {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-800:active {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .lg\:active\:bg-green-900:active {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-50:active {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-100:active {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-200:active {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-300:active {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-400:active {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-500:active {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-600:active {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-700:active {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-800:active {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .lg\:active\:bg-teal-900:active {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-50:active {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-100:active {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-200:active {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-300:active {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-400:active {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-500:active {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-600:active {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-700:active {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-800:active {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .lg\:active\:bg-blue-900:active {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-50:active {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-100:active {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-200:active {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-300:active {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-400:active {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-500:active {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-600:active {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-700:active {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-800:active {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .lg\:active\:bg-indigo-900:active {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-50:active {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-100:active {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-200:active {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-300:active {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-400:active {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-500:active {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-600:active {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-700:active {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-800:active {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .lg\:active\:bg-purple-900:active {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-100:active {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-200:active {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-300:active {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-400:active {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-500:active {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-600:active {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-700:active {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-800:active {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .lg\:active\:bg-pink-900:active {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .lg\:bg-none {
    background-image: none;
  }

  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops) );
  }

  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops) );
  }

  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops) );
  }

  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops) );
  }

  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops) );
  }

  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops) );
  }

  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops) );
  }

  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops) );
  }

  .lg\:from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .lg\:from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .lg\:from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .lg\:from-gray-50 {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .lg\:from-gray-100 {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .lg\:from-gray-200 {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .lg\:from-gray-300 {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .lg\:from-gray-400 {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .lg\:from-gray-500 {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .lg\:from-gray-600 {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .lg\:from-gray-700 {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .lg\:from-gray-800 {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .lg\:from-gray-900 {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .lg\:from-cool-gray-50 {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .lg\:from-cool-gray-100 {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .lg\:from-cool-gray-200 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .lg\:from-cool-gray-300 {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .lg\:from-cool-gray-400 {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .lg\:from-cool-gray-500 {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .lg\:from-cool-gray-600 {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .lg\:from-cool-gray-700 {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .lg\:from-cool-gray-800 {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .lg\:from-cool-gray-900 {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .lg\:from-red-50 {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .lg\:from-red-100 {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .lg\:from-red-200 {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .lg\:from-red-300 {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .lg\:from-red-400 {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .lg\:from-red-500 {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .lg\:from-red-600 {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .lg\:from-red-700 {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .lg\:from-red-800 {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .lg\:from-red-900 {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .lg\:from-orange-50 {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .lg\:from-orange-100 {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .lg\:from-orange-200 {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .lg\:from-orange-300 {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .lg\:from-orange-400 {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .lg\:from-orange-500 {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .lg\:from-orange-600 {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .lg\:from-orange-700 {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .lg\:from-orange-800 {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .lg\:from-orange-900 {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .lg\:from-yellow-50 {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .lg\:from-yellow-100 {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .lg\:from-yellow-200 {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .lg\:from-yellow-300 {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .lg\:from-yellow-400 {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .lg\:from-yellow-500 {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .lg\:from-yellow-600 {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .lg\:from-yellow-700 {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .lg\:from-yellow-800 {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .lg\:from-yellow-900 {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .lg\:from-green-50 {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .lg\:from-green-100 {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .lg\:from-green-200 {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .lg\:from-green-300 {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .lg\:from-green-400 {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .lg\:from-green-500 {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .lg\:from-green-600 {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .lg\:from-green-700 {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .lg\:from-green-800 {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .lg\:from-green-900 {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .lg\:from-teal-50 {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .lg\:from-teal-100 {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .lg\:from-teal-200 {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .lg\:from-teal-300 {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .lg\:from-teal-400 {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .lg\:from-teal-500 {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .lg\:from-teal-600 {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .lg\:from-teal-700 {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .lg\:from-teal-800 {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .lg\:from-teal-900 {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .lg\:from-blue-50 {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .lg\:from-blue-100 {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .lg\:from-blue-200 {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .lg\:from-blue-300 {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .lg\:from-blue-400 {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .lg\:from-blue-500 {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .lg\:from-blue-600 {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .lg\:from-blue-700 {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .lg\:from-blue-800 {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .lg\:from-blue-900 {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .lg\:from-indigo-50 {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .lg\:from-indigo-100 {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .lg\:from-indigo-200 {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .lg\:from-indigo-300 {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .lg\:from-indigo-400 {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .lg\:from-indigo-500 {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .lg\:from-indigo-600 {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .lg\:from-indigo-700 {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .lg\:from-indigo-800 {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .lg\:from-indigo-900 {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .lg\:from-purple-50 {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .lg\:from-purple-100 {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .lg\:from-purple-200 {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .lg\:from-purple-300 {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .lg\:from-purple-400 {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .lg\:from-purple-500 {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .lg\:from-purple-600 {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .lg\:from-purple-700 {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .lg\:from-purple-800 {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .lg\:from-purple-900 {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .lg\:from-pink-50 {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .lg\:from-pink-100 {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .lg\:from-pink-200 {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .lg\:from-pink-300 {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .lg\:from-pink-400 {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .lg\:from-pink-500 {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .lg\:from-pink-600 {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .lg\:from-pink-700 {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .lg\:from-pink-800 {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .lg\:from-pink-900 {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .lg\:via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .lg\:via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .lg\:via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .lg\:via-gray-50 {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .lg\:via-gray-100 {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .lg\:via-gray-200 {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .lg\:via-gray-300 {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .lg\:via-gray-400 {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .lg\:via-gray-500 {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .lg\:via-gray-600 {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .lg\:via-gray-700 {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .lg\:via-gray-800 {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .lg\:via-gray-900 {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .lg\:via-cool-gray-50 {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .lg\:via-cool-gray-100 {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .lg\:via-cool-gray-200 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .lg\:via-cool-gray-300 {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .lg\:via-cool-gray-400 {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .lg\:via-cool-gray-500 {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .lg\:via-cool-gray-600 {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .lg\:via-cool-gray-700 {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .lg\:via-cool-gray-800 {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .lg\:via-cool-gray-900 {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .lg\:via-red-50 {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .lg\:via-red-100 {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .lg\:via-red-200 {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .lg\:via-red-300 {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .lg\:via-red-400 {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .lg\:via-red-500 {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .lg\:via-red-600 {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .lg\:via-red-700 {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .lg\:via-red-800 {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .lg\:via-red-900 {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .lg\:via-orange-50 {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .lg\:via-orange-100 {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .lg\:via-orange-200 {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .lg\:via-orange-300 {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .lg\:via-orange-400 {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .lg\:via-orange-500 {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .lg\:via-orange-600 {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .lg\:via-orange-700 {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .lg\:via-orange-800 {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .lg\:via-orange-900 {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .lg\:via-yellow-50 {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .lg\:via-yellow-100 {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .lg\:via-yellow-200 {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .lg\:via-yellow-300 {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .lg\:via-yellow-400 {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .lg\:via-yellow-500 {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .lg\:via-yellow-600 {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .lg\:via-yellow-700 {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .lg\:via-yellow-800 {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .lg\:via-yellow-900 {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .lg\:via-green-50 {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .lg\:via-green-100 {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .lg\:via-green-200 {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .lg\:via-green-300 {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .lg\:via-green-400 {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .lg\:via-green-500 {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .lg\:via-green-600 {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .lg\:via-green-700 {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .lg\:via-green-800 {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .lg\:via-green-900 {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .lg\:via-teal-50 {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .lg\:via-teal-100 {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .lg\:via-teal-200 {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .lg\:via-teal-300 {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .lg\:via-teal-400 {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .lg\:via-teal-500 {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .lg\:via-teal-600 {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .lg\:via-teal-700 {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .lg\:via-teal-800 {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .lg\:via-teal-900 {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .lg\:via-blue-50 {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .lg\:via-blue-100 {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .lg\:via-blue-200 {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .lg\:via-blue-300 {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .lg\:via-blue-400 {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .lg\:via-blue-500 {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .lg\:via-blue-600 {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .lg\:via-blue-700 {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .lg\:via-blue-800 {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .lg\:via-blue-900 {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .lg\:via-indigo-50 {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .lg\:via-indigo-100 {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .lg\:via-indigo-200 {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .lg\:via-indigo-300 {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .lg\:via-indigo-400 {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .lg\:via-indigo-500 {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .lg\:via-indigo-600 {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .lg\:via-indigo-700 {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .lg\:via-indigo-800 {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .lg\:via-indigo-900 {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .lg\:via-purple-50 {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .lg\:via-purple-100 {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .lg\:via-purple-200 {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .lg\:via-purple-300 {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .lg\:via-purple-400 {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .lg\:via-purple-500 {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .lg\:via-purple-600 {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .lg\:via-purple-700 {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .lg\:via-purple-800 {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .lg\:via-purple-900 {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .lg\:via-pink-50 {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .lg\:via-pink-100 {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .lg\:via-pink-200 {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .lg\:via-pink-300 {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .lg\:via-pink-400 {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .lg\:via-pink-500 {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .lg\:via-pink-600 {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .lg\:via-pink-700 {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .lg\:via-pink-800 {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .lg\:via-pink-900 {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .lg\:to-transparent {
    --gradient-to-color: transparent;
  }

  .lg\:to-white {
    --gradient-to-color: #fff;
  }

  .lg\:to-black {
    --gradient-to-color: #000;
  }

  .lg\:to-gray-50 {
    --gradient-to-color: #f9fafb;
  }

  .lg\:to-gray-100 {
    --gradient-to-color: #f4f5f7;
  }

  .lg\:to-gray-200 {
    --gradient-to-color: #e5e7eb;
  }

  .lg\:to-gray-300 {
    --gradient-to-color: #d2d6dc;
  }

  .lg\:to-gray-400 {
    --gradient-to-color: #9fa6b2;
  }

  .lg\:to-gray-500 {
    --gradient-to-color: #6b7280;
  }

  .lg\:to-gray-600 {
    --gradient-to-color: #4b5563;
  }

  .lg\:to-gray-700 {
    --gradient-to-color: #374151;
  }

  .lg\:to-gray-800 {
    --gradient-to-color: #252f3f;
  }

  .lg\:to-gray-900 {
    --gradient-to-color: #161e2e;
  }

  .lg\:to-cool-gray-50 {
    --gradient-to-color: #fbfdfe;
  }

  .lg\:to-cool-gray-100 {
    --gradient-to-color: #f1f5f9;
  }

  .lg\:to-cool-gray-200 {
    --gradient-to-color: #e2e8f0;
  }

  .lg\:to-cool-gray-300 {
    --gradient-to-color: #cfd8e3;
  }

  .lg\:to-cool-gray-400 {
    --gradient-to-color: #97a6ba;
  }

  .lg\:to-cool-gray-500 {
    --gradient-to-color: #64748b;
  }

  .lg\:to-cool-gray-600 {
    --gradient-to-color: #475569;
  }

  .lg\:to-cool-gray-700 {
    --gradient-to-color: #364152;
  }

  .lg\:to-cool-gray-800 {
    --gradient-to-color: #27303f;
  }

  .lg\:to-cool-gray-900 {
    --gradient-to-color: #1a202e;
  }

  .lg\:to-red-50 {
    --gradient-to-color: #fdf2f2;
  }

  .lg\:to-red-100 {
    --gradient-to-color: #fde8e8;
  }

  .lg\:to-red-200 {
    --gradient-to-color: #fbd5d5;
  }

  .lg\:to-red-300 {
    --gradient-to-color: #f8b4b4;
  }

  .lg\:to-red-400 {
    --gradient-to-color: #f98080;
  }

  .lg\:to-red-500 {
    --gradient-to-color: #f05252;
  }

  .lg\:to-red-600 {
    --gradient-to-color: #e02424;
  }

  .lg\:to-red-700 {
    --gradient-to-color: #c81e1e;
  }

  .lg\:to-red-800 {
    --gradient-to-color: #9b1c1c;
  }

  .lg\:to-red-900 {
    --gradient-to-color: #771d1d;
  }

  .lg\:to-orange-50 {
    --gradient-to-color: #fff8f1;
  }

  .lg\:to-orange-100 {
    --gradient-to-color: #feecdc;
  }

  .lg\:to-orange-200 {
    --gradient-to-color: #fcd9bd;
  }

  .lg\:to-orange-300 {
    --gradient-to-color: #fdba8c;
  }

  .lg\:to-orange-400 {
    --gradient-to-color: #ff8a4c;
  }

  .lg\:to-orange-500 {
    --gradient-to-color: #ff5a1f;
  }

  .lg\:to-orange-600 {
    --gradient-to-color: #d03801;
  }

  .lg\:to-orange-700 {
    --gradient-to-color: #b43403;
  }

  .lg\:to-orange-800 {
    --gradient-to-color: #8a2c0d;
  }

  .lg\:to-orange-900 {
    --gradient-to-color: #73230d;
  }

  .lg\:to-yellow-50 {
    --gradient-to-color: #fdfdea;
  }

  .lg\:to-yellow-100 {
    --gradient-to-color: #fdf6b2;
  }

  .lg\:to-yellow-200 {
    --gradient-to-color: #fce96a;
  }

  .lg\:to-yellow-300 {
    --gradient-to-color: #faca15;
  }

  .lg\:to-yellow-400 {
    --gradient-to-color: #e3a008;
  }

  .lg\:to-yellow-500 {
    --gradient-to-color: #c27803;
  }

  .lg\:to-yellow-600 {
    --gradient-to-color: #9f580a;
  }

  .lg\:to-yellow-700 {
    --gradient-to-color: #8e4b10;
  }

  .lg\:to-yellow-800 {
    --gradient-to-color: #723b13;
  }

  .lg\:to-yellow-900 {
    --gradient-to-color: #633112;
  }

  .lg\:to-green-50 {
    --gradient-to-color: #f3faf7;
  }

  .lg\:to-green-100 {
    --gradient-to-color: #def7ec;
  }

  .lg\:to-green-200 {
    --gradient-to-color: #bcf0da;
  }

  .lg\:to-green-300 {
    --gradient-to-color: #84e1bc;
  }

  .lg\:to-green-400 {
    --gradient-to-color: #31c48d;
  }

  .lg\:to-green-500 {
    --gradient-to-color: #0e9f6e;
  }

  .lg\:to-green-600 {
    --gradient-to-color: #057a55;
  }

  .lg\:to-green-700 {
    --gradient-to-color: #046c4e;
  }

  .lg\:to-green-800 {
    --gradient-to-color: #03543f;
  }

  .lg\:to-green-900 {
    --gradient-to-color: #014737;
  }

  .lg\:to-teal-50 {
    --gradient-to-color: #edfafa;
  }

  .lg\:to-teal-100 {
    --gradient-to-color: #d5f5f6;
  }

  .lg\:to-teal-200 {
    --gradient-to-color: #afecef;
  }

  .lg\:to-teal-300 {
    --gradient-to-color: #7edce2;
  }

  .lg\:to-teal-400 {
    --gradient-to-color: #16bdca;
  }

  .lg\:to-teal-500 {
    --gradient-to-color: #0694a2;
  }

  .lg\:to-teal-600 {
    --gradient-to-color: #047481;
  }

  .lg\:to-teal-700 {
    --gradient-to-color: #036672;
  }

  .lg\:to-teal-800 {
    --gradient-to-color: #05505c;
  }

  .lg\:to-teal-900 {
    --gradient-to-color: #014451;
  }

  .lg\:to-blue-50 {
    --gradient-to-color: #ebf5ff;
  }

  .lg\:to-blue-100 {
    --gradient-to-color: #e1effe;
  }

  .lg\:to-blue-200 {
    --gradient-to-color: #c3ddfd;
  }

  .lg\:to-blue-300 {
    --gradient-to-color: #a4cafe;
  }

  .lg\:to-blue-400 {
    --gradient-to-color: #76a9fa;
  }

  .lg\:to-blue-500 {
    --gradient-to-color: #3f83f8;
  }

  .lg\:to-blue-600 {
    --gradient-to-color: #1c64f2;
  }

  .lg\:to-blue-700 {
    --gradient-to-color: #1a56db;
  }

  .lg\:to-blue-800 {
    --gradient-to-color: #1e429f;
  }

  .lg\:to-blue-900 {
    --gradient-to-color: #233876;
  }

  .lg\:to-indigo-50 {
    --gradient-to-color: #f0f5ff;
  }

  .lg\:to-indigo-100 {
    --gradient-to-color: #e5edff;
  }

  .lg\:to-indigo-200 {
    --gradient-to-color: #cddbfe;
  }

  .lg\:to-indigo-300 {
    --gradient-to-color: #b4c6fc;
  }

  .lg\:to-indigo-400 {
    --gradient-to-color: #8da2fb;
  }

  .lg\:to-indigo-500 {
    --gradient-to-color: #6875f5;
  }

  .lg\:to-indigo-600 {
    --gradient-to-color: #5850ec;
  }

  .lg\:to-indigo-700 {
    --gradient-to-color: #5145cd;
  }

  .lg\:to-indigo-800 {
    --gradient-to-color: #42389d;
  }

  .lg\:to-indigo-900 {
    --gradient-to-color: #362f78;
  }

  .lg\:to-purple-50 {
    --gradient-to-color: #f6f5ff;
  }

  .lg\:to-purple-100 {
    --gradient-to-color: #edebfe;
  }

  .lg\:to-purple-200 {
    --gradient-to-color: #dcd7fe;
  }

  .lg\:to-purple-300 {
    --gradient-to-color: #cabffd;
  }

  .lg\:to-purple-400 {
    --gradient-to-color: #ac94fa;
  }

  .lg\:to-purple-500 {
    --gradient-to-color: #9061f9;
  }

  .lg\:to-purple-600 {
    --gradient-to-color: #7e3af2;
  }

  .lg\:to-purple-700 {
    --gradient-to-color: #6c2bd9;
  }

  .lg\:to-purple-800 {
    --gradient-to-color: #5521b5;
  }

  .lg\:to-purple-900 {
    --gradient-to-color: #4a1d96;
  }

  .lg\:to-pink-50 {
    --gradient-to-color: #fdf2f8;
  }

  .lg\:to-pink-100 {
    --gradient-to-color: #fce8f3;
  }

  .lg\:to-pink-200 {
    --gradient-to-color: #fad1e8;
  }

  .lg\:to-pink-300 {
    --gradient-to-color: #f8b4d9;
  }

  .lg\:to-pink-400 {
    --gradient-to-color: #f17eb8;
  }

  .lg\:to-pink-500 {
    --gradient-to-color: #e74694;
  }

  .lg\:to-pink-600 {
    --gradient-to-color: #d61f69;
  }

  .lg\:to-pink-700 {
    --gradient-to-color: #bf125d;
  }

  .lg\:to-pink-800 {
    --gradient-to-color: #99154b;
  }

  .lg\:to-pink-900 {
    --gradient-to-color: #751a3d;
  }

  .lg\:hover\:from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .lg\:hover\:from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .lg\:hover\:from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .lg\:hover\:from-gray-50:hover {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .lg\:hover\:from-gray-100:hover {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .lg\:hover\:from-gray-200:hover {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .lg\:hover\:from-gray-300:hover {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .lg\:hover\:from-gray-400:hover {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .lg\:hover\:from-gray-500:hover {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .lg\:hover\:from-gray-600:hover {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .lg\:hover\:from-gray-700:hover {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .lg\:hover\:from-gray-800:hover {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .lg\:hover\:from-gray-900:hover {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .lg\:hover\:from-cool-gray-50:hover {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .lg\:hover\:from-cool-gray-100:hover {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .lg\:hover\:from-cool-gray-200:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .lg\:hover\:from-cool-gray-300:hover {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .lg\:hover\:from-cool-gray-400:hover {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .lg\:hover\:from-cool-gray-500:hover {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .lg\:hover\:from-cool-gray-600:hover {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .lg\:hover\:from-cool-gray-700:hover {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .lg\:hover\:from-cool-gray-800:hover {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .lg\:hover\:from-cool-gray-900:hover {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .lg\:hover\:from-red-50:hover {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .lg\:hover\:from-red-100:hover {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .lg\:hover\:from-red-200:hover {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .lg\:hover\:from-red-300:hover {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .lg\:hover\:from-red-400:hover {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .lg\:hover\:from-red-500:hover {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .lg\:hover\:from-red-600:hover {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .lg\:hover\:from-red-700:hover {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .lg\:hover\:from-red-800:hover {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .lg\:hover\:from-red-900:hover {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .lg\:hover\:from-orange-50:hover {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .lg\:hover\:from-orange-100:hover {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .lg\:hover\:from-orange-200:hover {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .lg\:hover\:from-orange-300:hover {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .lg\:hover\:from-orange-400:hover {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .lg\:hover\:from-orange-500:hover {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .lg\:hover\:from-orange-600:hover {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .lg\:hover\:from-orange-700:hover {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .lg\:hover\:from-orange-800:hover {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .lg\:hover\:from-orange-900:hover {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .lg\:hover\:from-yellow-50:hover {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .lg\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .lg\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .lg\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .lg\:hover\:from-yellow-400:hover {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .lg\:hover\:from-yellow-500:hover {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .lg\:hover\:from-yellow-600:hover {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .lg\:hover\:from-yellow-700:hover {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .lg\:hover\:from-yellow-800:hover {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .lg\:hover\:from-yellow-900:hover {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .lg\:hover\:from-green-50:hover {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .lg\:hover\:from-green-100:hover {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .lg\:hover\:from-green-200:hover {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .lg\:hover\:from-green-300:hover {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .lg\:hover\:from-green-400:hover {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .lg\:hover\:from-green-500:hover {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .lg\:hover\:from-green-600:hover {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .lg\:hover\:from-green-700:hover {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .lg\:hover\:from-green-800:hover {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .lg\:hover\:from-green-900:hover {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .lg\:hover\:from-teal-50:hover {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .lg\:hover\:from-teal-100:hover {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .lg\:hover\:from-teal-200:hover {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .lg\:hover\:from-teal-300:hover {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .lg\:hover\:from-teal-400:hover {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .lg\:hover\:from-teal-500:hover {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .lg\:hover\:from-teal-600:hover {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .lg\:hover\:from-teal-700:hover {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .lg\:hover\:from-teal-800:hover {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .lg\:hover\:from-teal-900:hover {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .lg\:hover\:from-blue-50:hover {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .lg\:hover\:from-blue-100:hover {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .lg\:hover\:from-blue-200:hover {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .lg\:hover\:from-blue-300:hover {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .lg\:hover\:from-blue-400:hover {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .lg\:hover\:from-blue-500:hover {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .lg\:hover\:from-blue-600:hover {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .lg\:hover\:from-blue-700:hover {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .lg\:hover\:from-blue-800:hover {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .lg\:hover\:from-blue-900:hover {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .lg\:hover\:from-indigo-50:hover {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .lg\:hover\:from-indigo-100:hover {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .lg\:hover\:from-indigo-200:hover {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .lg\:hover\:from-indigo-300:hover {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .lg\:hover\:from-indigo-400:hover {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .lg\:hover\:from-indigo-500:hover {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .lg\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .lg\:hover\:from-indigo-700:hover {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .lg\:hover\:from-indigo-800:hover {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .lg\:hover\:from-indigo-900:hover {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .lg\:hover\:from-purple-50:hover {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .lg\:hover\:from-purple-100:hover {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .lg\:hover\:from-purple-200:hover {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .lg\:hover\:from-purple-300:hover {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .lg\:hover\:from-purple-400:hover {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .lg\:hover\:from-purple-500:hover {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .lg\:hover\:from-purple-600:hover {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .lg\:hover\:from-purple-700:hover {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .lg\:hover\:from-purple-800:hover {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .lg\:hover\:from-purple-900:hover {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .lg\:hover\:from-pink-50:hover {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .lg\:hover\:from-pink-100:hover {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .lg\:hover\:from-pink-200:hover {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .lg\:hover\:from-pink-300:hover {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .lg\:hover\:from-pink-400:hover {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .lg\:hover\:from-pink-500:hover {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .lg\:hover\:from-pink-600:hover {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .lg\:hover\:from-pink-700:hover {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .lg\:hover\:from-pink-800:hover {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .lg\:hover\:from-pink-900:hover {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .lg\:hover\:via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .lg\:hover\:via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .lg\:hover\:via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .lg\:hover\:via-gray-50:hover {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .lg\:hover\:via-gray-100:hover {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .lg\:hover\:via-gray-200:hover {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .lg\:hover\:via-gray-300:hover {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .lg\:hover\:via-gray-400:hover {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .lg\:hover\:via-gray-500:hover {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .lg\:hover\:via-gray-600:hover {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .lg\:hover\:via-gray-700:hover {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .lg\:hover\:via-gray-800:hover {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .lg\:hover\:via-gray-900:hover {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .lg\:hover\:via-cool-gray-50:hover {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .lg\:hover\:via-cool-gray-100:hover {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .lg\:hover\:via-cool-gray-200:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .lg\:hover\:via-cool-gray-300:hover {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .lg\:hover\:via-cool-gray-400:hover {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .lg\:hover\:via-cool-gray-500:hover {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .lg\:hover\:via-cool-gray-600:hover {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .lg\:hover\:via-cool-gray-700:hover {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .lg\:hover\:via-cool-gray-800:hover {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .lg\:hover\:via-cool-gray-900:hover {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .lg\:hover\:via-red-50:hover {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .lg\:hover\:via-red-100:hover {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .lg\:hover\:via-red-200:hover {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .lg\:hover\:via-red-300:hover {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .lg\:hover\:via-red-400:hover {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .lg\:hover\:via-red-500:hover {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .lg\:hover\:via-red-600:hover {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .lg\:hover\:via-red-700:hover {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .lg\:hover\:via-red-800:hover {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .lg\:hover\:via-red-900:hover {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .lg\:hover\:via-orange-50:hover {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .lg\:hover\:via-orange-100:hover {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .lg\:hover\:via-orange-200:hover {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .lg\:hover\:via-orange-300:hover {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .lg\:hover\:via-orange-400:hover {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .lg\:hover\:via-orange-500:hover {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .lg\:hover\:via-orange-600:hover {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .lg\:hover\:via-orange-700:hover {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .lg\:hover\:via-orange-800:hover {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .lg\:hover\:via-orange-900:hover {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .lg\:hover\:via-yellow-50:hover {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .lg\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .lg\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .lg\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .lg\:hover\:via-yellow-400:hover {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .lg\:hover\:via-yellow-500:hover {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .lg\:hover\:via-yellow-600:hover {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .lg\:hover\:via-yellow-700:hover {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .lg\:hover\:via-yellow-800:hover {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .lg\:hover\:via-yellow-900:hover {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .lg\:hover\:via-green-50:hover {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .lg\:hover\:via-green-100:hover {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .lg\:hover\:via-green-200:hover {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .lg\:hover\:via-green-300:hover {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .lg\:hover\:via-green-400:hover {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .lg\:hover\:via-green-500:hover {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .lg\:hover\:via-green-600:hover {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .lg\:hover\:via-green-700:hover {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .lg\:hover\:via-green-800:hover {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .lg\:hover\:via-green-900:hover {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .lg\:hover\:via-teal-50:hover {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .lg\:hover\:via-teal-100:hover {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .lg\:hover\:via-teal-200:hover {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .lg\:hover\:via-teal-300:hover {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .lg\:hover\:via-teal-400:hover {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .lg\:hover\:via-teal-500:hover {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .lg\:hover\:via-teal-600:hover {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .lg\:hover\:via-teal-700:hover {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .lg\:hover\:via-teal-800:hover {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .lg\:hover\:via-teal-900:hover {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .lg\:hover\:via-blue-50:hover {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .lg\:hover\:via-blue-100:hover {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .lg\:hover\:via-blue-200:hover {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .lg\:hover\:via-blue-300:hover {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .lg\:hover\:via-blue-400:hover {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .lg\:hover\:via-blue-500:hover {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .lg\:hover\:via-blue-600:hover {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .lg\:hover\:via-blue-700:hover {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .lg\:hover\:via-blue-800:hover {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .lg\:hover\:via-blue-900:hover {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .lg\:hover\:via-indigo-50:hover {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .lg\:hover\:via-indigo-100:hover {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .lg\:hover\:via-indigo-200:hover {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .lg\:hover\:via-indigo-300:hover {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .lg\:hover\:via-indigo-400:hover {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .lg\:hover\:via-indigo-500:hover {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .lg\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .lg\:hover\:via-indigo-700:hover {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .lg\:hover\:via-indigo-800:hover {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .lg\:hover\:via-indigo-900:hover {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .lg\:hover\:via-purple-50:hover {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .lg\:hover\:via-purple-100:hover {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .lg\:hover\:via-purple-200:hover {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .lg\:hover\:via-purple-300:hover {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .lg\:hover\:via-purple-400:hover {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .lg\:hover\:via-purple-500:hover {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .lg\:hover\:via-purple-600:hover {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .lg\:hover\:via-purple-700:hover {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .lg\:hover\:via-purple-800:hover {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .lg\:hover\:via-purple-900:hover {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .lg\:hover\:via-pink-50:hover {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .lg\:hover\:via-pink-100:hover {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .lg\:hover\:via-pink-200:hover {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .lg\:hover\:via-pink-300:hover {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .lg\:hover\:via-pink-400:hover {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .lg\:hover\:via-pink-500:hover {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .lg\:hover\:via-pink-600:hover {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .lg\:hover\:via-pink-700:hover {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .lg\:hover\:via-pink-800:hover {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .lg\:hover\:via-pink-900:hover {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .lg\:hover\:to-transparent:hover {
    --gradient-to-color: transparent;
  }

  .lg\:hover\:to-white:hover {
    --gradient-to-color: #fff;
  }

  .lg\:hover\:to-black:hover {
    --gradient-to-color: #000;
  }

  .lg\:hover\:to-gray-50:hover {
    --gradient-to-color: #f9fafb;
  }

  .lg\:hover\:to-gray-100:hover {
    --gradient-to-color: #f4f5f7;
  }

  .lg\:hover\:to-gray-200:hover {
    --gradient-to-color: #e5e7eb;
  }

  .lg\:hover\:to-gray-300:hover {
    --gradient-to-color: #d2d6dc;
  }

  .lg\:hover\:to-gray-400:hover {
    --gradient-to-color: #9fa6b2;
  }

  .lg\:hover\:to-gray-500:hover {
    --gradient-to-color: #6b7280;
  }

  .lg\:hover\:to-gray-600:hover {
    --gradient-to-color: #4b5563;
  }

  .lg\:hover\:to-gray-700:hover {
    --gradient-to-color: #374151;
  }

  .lg\:hover\:to-gray-800:hover {
    --gradient-to-color: #252f3f;
  }

  .lg\:hover\:to-gray-900:hover {
    --gradient-to-color: #161e2e;
  }

  .lg\:hover\:to-cool-gray-50:hover {
    --gradient-to-color: #fbfdfe;
  }

  .lg\:hover\:to-cool-gray-100:hover {
    --gradient-to-color: #f1f5f9;
  }

  .lg\:hover\:to-cool-gray-200:hover {
    --gradient-to-color: #e2e8f0;
  }

  .lg\:hover\:to-cool-gray-300:hover {
    --gradient-to-color: #cfd8e3;
  }

  .lg\:hover\:to-cool-gray-400:hover {
    --gradient-to-color: #97a6ba;
  }

  .lg\:hover\:to-cool-gray-500:hover {
    --gradient-to-color: #64748b;
  }

  .lg\:hover\:to-cool-gray-600:hover {
    --gradient-to-color: #475569;
  }

  .lg\:hover\:to-cool-gray-700:hover {
    --gradient-to-color: #364152;
  }

  .lg\:hover\:to-cool-gray-800:hover {
    --gradient-to-color: #27303f;
  }

  .lg\:hover\:to-cool-gray-900:hover {
    --gradient-to-color: #1a202e;
  }

  .lg\:hover\:to-red-50:hover {
    --gradient-to-color: #fdf2f2;
  }

  .lg\:hover\:to-red-100:hover {
    --gradient-to-color: #fde8e8;
  }

  .lg\:hover\:to-red-200:hover {
    --gradient-to-color: #fbd5d5;
  }

  .lg\:hover\:to-red-300:hover {
    --gradient-to-color: #f8b4b4;
  }

  .lg\:hover\:to-red-400:hover {
    --gradient-to-color: #f98080;
  }

  .lg\:hover\:to-red-500:hover {
    --gradient-to-color: #f05252;
  }

  .lg\:hover\:to-red-600:hover {
    --gradient-to-color: #e02424;
  }

  .lg\:hover\:to-red-700:hover {
    --gradient-to-color: #c81e1e;
  }

  .lg\:hover\:to-red-800:hover {
    --gradient-to-color: #9b1c1c;
  }

  .lg\:hover\:to-red-900:hover {
    --gradient-to-color: #771d1d;
  }

  .lg\:hover\:to-orange-50:hover {
    --gradient-to-color: #fff8f1;
  }

  .lg\:hover\:to-orange-100:hover {
    --gradient-to-color: #feecdc;
  }

  .lg\:hover\:to-orange-200:hover {
    --gradient-to-color: #fcd9bd;
  }

  .lg\:hover\:to-orange-300:hover {
    --gradient-to-color: #fdba8c;
  }

  .lg\:hover\:to-orange-400:hover {
    --gradient-to-color: #ff8a4c;
  }

  .lg\:hover\:to-orange-500:hover {
    --gradient-to-color: #ff5a1f;
  }

  .lg\:hover\:to-orange-600:hover {
    --gradient-to-color: #d03801;
  }

  .lg\:hover\:to-orange-700:hover {
    --gradient-to-color: #b43403;
  }

  .lg\:hover\:to-orange-800:hover {
    --gradient-to-color: #8a2c0d;
  }

  .lg\:hover\:to-orange-900:hover {
    --gradient-to-color: #73230d;
  }

  .lg\:hover\:to-yellow-50:hover {
    --gradient-to-color: #fdfdea;
  }

  .lg\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fdf6b2;
  }

  .lg\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fce96a;
  }

  .lg\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faca15;
  }

  .lg\:hover\:to-yellow-400:hover {
    --gradient-to-color: #e3a008;
  }

  .lg\:hover\:to-yellow-500:hover {
    --gradient-to-color: #c27803;
  }

  .lg\:hover\:to-yellow-600:hover {
    --gradient-to-color: #9f580a;
  }

  .lg\:hover\:to-yellow-700:hover {
    --gradient-to-color: #8e4b10;
  }

  .lg\:hover\:to-yellow-800:hover {
    --gradient-to-color: #723b13;
  }

  .lg\:hover\:to-yellow-900:hover {
    --gradient-to-color: #633112;
  }

  .lg\:hover\:to-green-50:hover {
    --gradient-to-color: #f3faf7;
  }

  .lg\:hover\:to-green-100:hover {
    --gradient-to-color: #def7ec;
  }

  .lg\:hover\:to-green-200:hover {
    --gradient-to-color: #bcf0da;
  }

  .lg\:hover\:to-green-300:hover {
    --gradient-to-color: #84e1bc;
  }

  .lg\:hover\:to-green-400:hover {
    --gradient-to-color: #31c48d;
  }

  .lg\:hover\:to-green-500:hover {
    --gradient-to-color: #0e9f6e;
  }

  .lg\:hover\:to-green-600:hover {
    --gradient-to-color: #057a55;
  }

  .lg\:hover\:to-green-700:hover {
    --gradient-to-color: #046c4e;
  }

  .lg\:hover\:to-green-800:hover {
    --gradient-to-color: #03543f;
  }

  .lg\:hover\:to-green-900:hover {
    --gradient-to-color: #014737;
  }

  .lg\:hover\:to-teal-50:hover {
    --gradient-to-color: #edfafa;
  }

  .lg\:hover\:to-teal-100:hover {
    --gradient-to-color: #d5f5f6;
  }

  .lg\:hover\:to-teal-200:hover {
    --gradient-to-color: #afecef;
  }

  .lg\:hover\:to-teal-300:hover {
    --gradient-to-color: #7edce2;
  }

  .lg\:hover\:to-teal-400:hover {
    --gradient-to-color: #16bdca;
  }

  .lg\:hover\:to-teal-500:hover {
    --gradient-to-color: #0694a2;
  }

  .lg\:hover\:to-teal-600:hover {
    --gradient-to-color: #047481;
  }

  .lg\:hover\:to-teal-700:hover {
    --gradient-to-color: #036672;
  }

  .lg\:hover\:to-teal-800:hover {
    --gradient-to-color: #05505c;
  }

  .lg\:hover\:to-teal-900:hover {
    --gradient-to-color: #014451;
  }

  .lg\:hover\:to-blue-50:hover {
    --gradient-to-color: #ebf5ff;
  }

  .lg\:hover\:to-blue-100:hover {
    --gradient-to-color: #e1effe;
  }

  .lg\:hover\:to-blue-200:hover {
    --gradient-to-color: #c3ddfd;
  }

  .lg\:hover\:to-blue-300:hover {
    --gradient-to-color: #a4cafe;
  }

  .lg\:hover\:to-blue-400:hover {
    --gradient-to-color: #76a9fa;
  }

  .lg\:hover\:to-blue-500:hover {
    --gradient-to-color: #3f83f8;
  }

  .lg\:hover\:to-blue-600:hover {
    --gradient-to-color: #1c64f2;
  }

  .lg\:hover\:to-blue-700:hover {
    --gradient-to-color: #1a56db;
  }

  .lg\:hover\:to-blue-800:hover {
    --gradient-to-color: #1e429f;
  }

  .lg\:hover\:to-blue-900:hover {
    --gradient-to-color: #233876;
  }

  .lg\:hover\:to-indigo-50:hover {
    --gradient-to-color: #f0f5ff;
  }

  .lg\:hover\:to-indigo-100:hover {
    --gradient-to-color: #e5edff;
  }

  .lg\:hover\:to-indigo-200:hover {
    --gradient-to-color: #cddbfe;
  }

  .lg\:hover\:to-indigo-300:hover {
    --gradient-to-color: #b4c6fc;
  }

  .lg\:hover\:to-indigo-400:hover {
    --gradient-to-color: #8da2fb;
  }

  .lg\:hover\:to-indigo-500:hover {
    --gradient-to-color: #6875f5;
  }

  .lg\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5850ec;
  }

  .lg\:hover\:to-indigo-700:hover {
    --gradient-to-color: #5145cd;
  }

  .lg\:hover\:to-indigo-800:hover {
    --gradient-to-color: #42389d;
  }

  .lg\:hover\:to-indigo-900:hover {
    --gradient-to-color: #362f78;
  }

  .lg\:hover\:to-purple-50:hover {
    --gradient-to-color: #f6f5ff;
  }

  .lg\:hover\:to-purple-100:hover {
    --gradient-to-color: #edebfe;
  }

  .lg\:hover\:to-purple-200:hover {
    --gradient-to-color: #dcd7fe;
  }

  .lg\:hover\:to-purple-300:hover {
    --gradient-to-color: #cabffd;
  }

  .lg\:hover\:to-purple-400:hover {
    --gradient-to-color: #ac94fa;
  }

  .lg\:hover\:to-purple-500:hover {
    --gradient-to-color: #9061f9;
  }

  .lg\:hover\:to-purple-600:hover {
    --gradient-to-color: #7e3af2;
  }

  .lg\:hover\:to-purple-700:hover {
    --gradient-to-color: #6c2bd9;
  }

  .lg\:hover\:to-purple-800:hover {
    --gradient-to-color: #5521b5;
  }

  .lg\:hover\:to-purple-900:hover {
    --gradient-to-color: #4a1d96;
  }

  .lg\:hover\:to-pink-50:hover {
    --gradient-to-color: #fdf2f8;
  }

  .lg\:hover\:to-pink-100:hover {
    --gradient-to-color: #fce8f3;
  }

  .lg\:hover\:to-pink-200:hover {
    --gradient-to-color: #fad1e8;
  }

  .lg\:hover\:to-pink-300:hover {
    --gradient-to-color: #f8b4d9;
  }

  .lg\:hover\:to-pink-400:hover {
    --gradient-to-color: #f17eb8;
  }

  .lg\:hover\:to-pink-500:hover {
    --gradient-to-color: #e74694;
  }

  .lg\:hover\:to-pink-600:hover {
    --gradient-to-color: #d61f69;
  }

  .lg\:hover\:to-pink-700:hover {
    --gradient-to-color: #bf125d;
  }

  .lg\:hover\:to-pink-800:hover {
    --gradient-to-color: #99154b;
  }

  .lg\:hover\:to-pink-900:hover {
    --gradient-to-color: #751a3d;
  }

  .lg\:focus\:from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .lg\:focus\:from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .lg\:focus\:from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .lg\:focus\:from-gray-50:focus {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .lg\:focus\:from-gray-100:focus {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .lg\:focus\:from-gray-200:focus {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .lg\:focus\:from-gray-300:focus {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .lg\:focus\:from-gray-400:focus {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .lg\:focus\:from-gray-500:focus {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .lg\:focus\:from-gray-600:focus {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .lg\:focus\:from-gray-700:focus {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .lg\:focus\:from-gray-800:focus {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .lg\:focus\:from-gray-900:focus {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .lg\:focus\:from-cool-gray-50:focus {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .lg\:focus\:from-cool-gray-100:focus {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .lg\:focus\:from-cool-gray-200:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .lg\:focus\:from-cool-gray-300:focus {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .lg\:focus\:from-cool-gray-400:focus {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .lg\:focus\:from-cool-gray-500:focus {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .lg\:focus\:from-cool-gray-600:focus {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .lg\:focus\:from-cool-gray-700:focus {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .lg\:focus\:from-cool-gray-800:focus {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .lg\:focus\:from-cool-gray-900:focus {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .lg\:focus\:from-red-50:focus {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .lg\:focus\:from-red-100:focus {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .lg\:focus\:from-red-200:focus {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .lg\:focus\:from-red-300:focus {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .lg\:focus\:from-red-400:focus {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .lg\:focus\:from-red-500:focus {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .lg\:focus\:from-red-600:focus {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .lg\:focus\:from-red-700:focus {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .lg\:focus\:from-red-800:focus {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .lg\:focus\:from-red-900:focus {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .lg\:focus\:from-orange-50:focus {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .lg\:focus\:from-orange-100:focus {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .lg\:focus\:from-orange-200:focus {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .lg\:focus\:from-orange-300:focus {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .lg\:focus\:from-orange-400:focus {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .lg\:focus\:from-orange-500:focus {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .lg\:focus\:from-orange-600:focus {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .lg\:focus\:from-orange-700:focus {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .lg\:focus\:from-orange-800:focus {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .lg\:focus\:from-orange-900:focus {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .lg\:focus\:from-yellow-50:focus {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .lg\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .lg\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .lg\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .lg\:focus\:from-yellow-400:focus {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .lg\:focus\:from-yellow-500:focus {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .lg\:focus\:from-yellow-600:focus {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .lg\:focus\:from-yellow-700:focus {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .lg\:focus\:from-yellow-800:focus {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .lg\:focus\:from-yellow-900:focus {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .lg\:focus\:from-green-50:focus {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .lg\:focus\:from-green-100:focus {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .lg\:focus\:from-green-200:focus {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .lg\:focus\:from-green-300:focus {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .lg\:focus\:from-green-400:focus {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .lg\:focus\:from-green-500:focus {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .lg\:focus\:from-green-600:focus {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .lg\:focus\:from-green-700:focus {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .lg\:focus\:from-green-800:focus {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .lg\:focus\:from-green-900:focus {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .lg\:focus\:from-teal-50:focus {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .lg\:focus\:from-teal-100:focus {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .lg\:focus\:from-teal-200:focus {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .lg\:focus\:from-teal-300:focus {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .lg\:focus\:from-teal-400:focus {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .lg\:focus\:from-teal-500:focus {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .lg\:focus\:from-teal-600:focus {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .lg\:focus\:from-teal-700:focus {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .lg\:focus\:from-teal-800:focus {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .lg\:focus\:from-teal-900:focus {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .lg\:focus\:from-blue-50:focus {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .lg\:focus\:from-blue-100:focus {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .lg\:focus\:from-blue-200:focus {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .lg\:focus\:from-blue-300:focus {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .lg\:focus\:from-blue-400:focus {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .lg\:focus\:from-blue-500:focus {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .lg\:focus\:from-blue-600:focus {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .lg\:focus\:from-blue-700:focus {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .lg\:focus\:from-blue-800:focus {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .lg\:focus\:from-blue-900:focus {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .lg\:focus\:from-indigo-50:focus {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .lg\:focus\:from-indigo-100:focus {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .lg\:focus\:from-indigo-200:focus {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .lg\:focus\:from-indigo-300:focus {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .lg\:focus\:from-indigo-400:focus {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .lg\:focus\:from-indigo-500:focus {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .lg\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .lg\:focus\:from-indigo-700:focus {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .lg\:focus\:from-indigo-800:focus {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .lg\:focus\:from-indigo-900:focus {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .lg\:focus\:from-purple-50:focus {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .lg\:focus\:from-purple-100:focus {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .lg\:focus\:from-purple-200:focus {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .lg\:focus\:from-purple-300:focus {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .lg\:focus\:from-purple-400:focus {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .lg\:focus\:from-purple-500:focus {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .lg\:focus\:from-purple-600:focus {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .lg\:focus\:from-purple-700:focus {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .lg\:focus\:from-purple-800:focus {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .lg\:focus\:from-purple-900:focus {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .lg\:focus\:from-pink-50:focus {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .lg\:focus\:from-pink-100:focus {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .lg\:focus\:from-pink-200:focus {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .lg\:focus\:from-pink-300:focus {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .lg\:focus\:from-pink-400:focus {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .lg\:focus\:from-pink-500:focus {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .lg\:focus\:from-pink-600:focus {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .lg\:focus\:from-pink-700:focus {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .lg\:focus\:from-pink-800:focus {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .lg\:focus\:from-pink-900:focus {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .lg\:focus\:via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .lg\:focus\:via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .lg\:focus\:via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .lg\:focus\:via-gray-50:focus {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .lg\:focus\:via-gray-100:focus {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .lg\:focus\:via-gray-200:focus {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .lg\:focus\:via-gray-300:focus {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .lg\:focus\:via-gray-400:focus {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .lg\:focus\:via-gray-500:focus {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .lg\:focus\:via-gray-600:focus {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .lg\:focus\:via-gray-700:focus {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .lg\:focus\:via-gray-800:focus {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .lg\:focus\:via-gray-900:focus {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .lg\:focus\:via-cool-gray-50:focus {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .lg\:focus\:via-cool-gray-100:focus {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .lg\:focus\:via-cool-gray-200:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .lg\:focus\:via-cool-gray-300:focus {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .lg\:focus\:via-cool-gray-400:focus {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .lg\:focus\:via-cool-gray-500:focus {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .lg\:focus\:via-cool-gray-600:focus {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .lg\:focus\:via-cool-gray-700:focus {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .lg\:focus\:via-cool-gray-800:focus {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .lg\:focus\:via-cool-gray-900:focus {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .lg\:focus\:via-red-50:focus {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .lg\:focus\:via-red-100:focus {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .lg\:focus\:via-red-200:focus {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .lg\:focus\:via-red-300:focus {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .lg\:focus\:via-red-400:focus {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .lg\:focus\:via-red-500:focus {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .lg\:focus\:via-red-600:focus {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .lg\:focus\:via-red-700:focus {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .lg\:focus\:via-red-800:focus {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .lg\:focus\:via-red-900:focus {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .lg\:focus\:via-orange-50:focus {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .lg\:focus\:via-orange-100:focus {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .lg\:focus\:via-orange-200:focus {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .lg\:focus\:via-orange-300:focus {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .lg\:focus\:via-orange-400:focus {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .lg\:focus\:via-orange-500:focus {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .lg\:focus\:via-orange-600:focus {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .lg\:focus\:via-orange-700:focus {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .lg\:focus\:via-orange-800:focus {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .lg\:focus\:via-orange-900:focus {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .lg\:focus\:via-yellow-50:focus {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .lg\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .lg\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .lg\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .lg\:focus\:via-yellow-400:focus {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .lg\:focus\:via-yellow-500:focus {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .lg\:focus\:via-yellow-600:focus {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .lg\:focus\:via-yellow-700:focus {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .lg\:focus\:via-yellow-800:focus {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .lg\:focus\:via-yellow-900:focus {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .lg\:focus\:via-green-50:focus {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .lg\:focus\:via-green-100:focus {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .lg\:focus\:via-green-200:focus {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .lg\:focus\:via-green-300:focus {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .lg\:focus\:via-green-400:focus {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .lg\:focus\:via-green-500:focus {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .lg\:focus\:via-green-600:focus {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .lg\:focus\:via-green-700:focus {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .lg\:focus\:via-green-800:focus {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .lg\:focus\:via-green-900:focus {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .lg\:focus\:via-teal-50:focus {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .lg\:focus\:via-teal-100:focus {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .lg\:focus\:via-teal-200:focus {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .lg\:focus\:via-teal-300:focus {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .lg\:focus\:via-teal-400:focus {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .lg\:focus\:via-teal-500:focus {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .lg\:focus\:via-teal-600:focus {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .lg\:focus\:via-teal-700:focus {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .lg\:focus\:via-teal-800:focus {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .lg\:focus\:via-teal-900:focus {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .lg\:focus\:via-blue-50:focus {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .lg\:focus\:via-blue-100:focus {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .lg\:focus\:via-blue-200:focus {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .lg\:focus\:via-blue-300:focus {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .lg\:focus\:via-blue-400:focus {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .lg\:focus\:via-blue-500:focus {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .lg\:focus\:via-blue-600:focus {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .lg\:focus\:via-blue-700:focus {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .lg\:focus\:via-blue-800:focus {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .lg\:focus\:via-blue-900:focus {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .lg\:focus\:via-indigo-50:focus {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .lg\:focus\:via-indigo-100:focus {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .lg\:focus\:via-indigo-200:focus {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .lg\:focus\:via-indigo-300:focus {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .lg\:focus\:via-indigo-400:focus {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .lg\:focus\:via-indigo-500:focus {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .lg\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .lg\:focus\:via-indigo-700:focus {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .lg\:focus\:via-indigo-800:focus {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .lg\:focus\:via-indigo-900:focus {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .lg\:focus\:via-purple-50:focus {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .lg\:focus\:via-purple-100:focus {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .lg\:focus\:via-purple-200:focus {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .lg\:focus\:via-purple-300:focus {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .lg\:focus\:via-purple-400:focus {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .lg\:focus\:via-purple-500:focus {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .lg\:focus\:via-purple-600:focus {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .lg\:focus\:via-purple-700:focus {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .lg\:focus\:via-purple-800:focus {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .lg\:focus\:via-purple-900:focus {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .lg\:focus\:via-pink-50:focus {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .lg\:focus\:via-pink-100:focus {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .lg\:focus\:via-pink-200:focus {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .lg\:focus\:via-pink-300:focus {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .lg\:focus\:via-pink-400:focus {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .lg\:focus\:via-pink-500:focus {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .lg\:focus\:via-pink-600:focus {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .lg\:focus\:via-pink-700:focus {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .lg\:focus\:via-pink-800:focus {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .lg\:focus\:via-pink-900:focus {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .lg\:focus\:to-transparent:focus {
    --gradient-to-color: transparent;
  }

  .lg\:focus\:to-white:focus {
    --gradient-to-color: #fff;
  }

  .lg\:focus\:to-black:focus {
    --gradient-to-color: #000;
  }

  .lg\:focus\:to-gray-50:focus {
    --gradient-to-color: #f9fafb;
  }

  .lg\:focus\:to-gray-100:focus {
    --gradient-to-color: #f4f5f7;
  }

  .lg\:focus\:to-gray-200:focus {
    --gradient-to-color: #e5e7eb;
  }

  .lg\:focus\:to-gray-300:focus {
    --gradient-to-color: #d2d6dc;
  }

  .lg\:focus\:to-gray-400:focus {
    --gradient-to-color: #9fa6b2;
  }

  .lg\:focus\:to-gray-500:focus {
    --gradient-to-color: #6b7280;
  }

  .lg\:focus\:to-gray-600:focus {
    --gradient-to-color: #4b5563;
  }

  .lg\:focus\:to-gray-700:focus {
    --gradient-to-color: #374151;
  }

  .lg\:focus\:to-gray-800:focus {
    --gradient-to-color: #252f3f;
  }

  .lg\:focus\:to-gray-900:focus {
    --gradient-to-color: #161e2e;
  }

  .lg\:focus\:to-cool-gray-50:focus {
    --gradient-to-color: #fbfdfe;
  }

  .lg\:focus\:to-cool-gray-100:focus {
    --gradient-to-color: #f1f5f9;
  }

  .lg\:focus\:to-cool-gray-200:focus {
    --gradient-to-color: #e2e8f0;
  }

  .lg\:focus\:to-cool-gray-300:focus {
    --gradient-to-color: #cfd8e3;
  }

  .lg\:focus\:to-cool-gray-400:focus {
    --gradient-to-color: #97a6ba;
  }

  .lg\:focus\:to-cool-gray-500:focus {
    --gradient-to-color: #64748b;
  }

  .lg\:focus\:to-cool-gray-600:focus {
    --gradient-to-color: #475569;
  }

  .lg\:focus\:to-cool-gray-700:focus {
    --gradient-to-color: #364152;
  }

  .lg\:focus\:to-cool-gray-800:focus {
    --gradient-to-color: #27303f;
  }

  .lg\:focus\:to-cool-gray-900:focus {
    --gradient-to-color: #1a202e;
  }

  .lg\:focus\:to-red-50:focus {
    --gradient-to-color: #fdf2f2;
  }

  .lg\:focus\:to-red-100:focus {
    --gradient-to-color: #fde8e8;
  }

  .lg\:focus\:to-red-200:focus {
    --gradient-to-color: #fbd5d5;
  }

  .lg\:focus\:to-red-300:focus {
    --gradient-to-color: #f8b4b4;
  }

  .lg\:focus\:to-red-400:focus {
    --gradient-to-color: #f98080;
  }

  .lg\:focus\:to-red-500:focus {
    --gradient-to-color: #f05252;
  }

  .lg\:focus\:to-red-600:focus {
    --gradient-to-color: #e02424;
  }

  .lg\:focus\:to-red-700:focus {
    --gradient-to-color: #c81e1e;
  }

  .lg\:focus\:to-red-800:focus {
    --gradient-to-color: #9b1c1c;
  }

  .lg\:focus\:to-red-900:focus {
    --gradient-to-color: #771d1d;
  }

  .lg\:focus\:to-orange-50:focus {
    --gradient-to-color: #fff8f1;
  }

  .lg\:focus\:to-orange-100:focus {
    --gradient-to-color: #feecdc;
  }

  .lg\:focus\:to-orange-200:focus {
    --gradient-to-color: #fcd9bd;
  }

  .lg\:focus\:to-orange-300:focus {
    --gradient-to-color: #fdba8c;
  }

  .lg\:focus\:to-orange-400:focus {
    --gradient-to-color: #ff8a4c;
  }

  .lg\:focus\:to-orange-500:focus {
    --gradient-to-color: #ff5a1f;
  }

  .lg\:focus\:to-orange-600:focus {
    --gradient-to-color: #d03801;
  }

  .lg\:focus\:to-orange-700:focus {
    --gradient-to-color: #b43403;
  }

  .lg\:focus\:to-orange-800:focus {
    --gradient-to-color: #8a2c0d;
  }

  .lg\:focus\:to-orange-900:focus {
    --gradient-to-color: #73230d;
  }

  .lg\:focus\:to-yellow-50:focus {
    --gradient-to-color: #fdfdea;
  }

  .lg\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fdf6b2;
  }

  .lg\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fce96a;
  }

  .lg\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faca15;
  }

  .lg\:focus\:to-yellow-400:focus {
    --gradient-to-color: #e3a008;
  }

  .lg\:focus\:to-yellow-500:focus {
    --gradient-to-color: #c27803;
  }

  .lg\:focus\:to-yellow-600:focus {
    --gradient-to-color: #9f580a;
  }

  .lg\:focus\:to-yellow-700:focus {
    --gradient-to-color: #8e4b10;
  }

  .lg\:focus\:to-yellow-800:focus {
    --gradient-to-color: #723b13;
  }

  .lg\:focus\:to-yellow-900:focus {
    --gradient-to-color: #633112;
  }

  .lg\:focus\:to-green-50:focus {
    --gradient-to-color: #f3faf7;
  }

  .lg\:focus\:to-green-100:focus {
    --gradient-to-color: #def7ec;
  }

  .lg\:focus\:to-green-200:focus {
    --gradient-to-color: #bcf0da;
  }

  .lg\:focus\:to-green-300:focus {
    --gradient-to-color: #84e1bc;
  }

  .lg\:focus\:to-green-400:focus {
    --gradient-to-color: #31c48d;
  }

  .lg\:focus\:to-green-500:focus {
    --gradient-to-color: #0e9f6e;
  }

  .lg\:focus\:to-green-600:focus {
    --gradient-to-color: #057a55;
  }

  .lg\:focus\:to-green-700:focus {
    --gradient-to-color: #046c4e;
  }

  .lg\:focus\:to-green-800:focus {
    --gradient-to-color: #03543f;
  }

  .lg\:focus\:to-green-900:focus {
    --gradient-to-color: #014737;
  }

  .lg\:focus\:to-teal-50:focus {
    --gradient-to-color: #edfafa;
  }

  .lg\:focus\:to-teal-100:focus {
    --gradient-to-color: #d5f5f6;
  }

  .lg\:focus\:to-teal-200:focus {
    --gradient-to-color: #afecef;
  }

  .lg\:focus\:to-teal-300:focus {
    --gradient-to-color: #7edce2;
  }

  .lg\:focus\:to-teal-400:focus {
    --gradient-to-color: #16bdca;
  }

  .lg\:focus\:to-teal-500:focus {
    --gradient-to-color: #0694a2;
  }

  .lg\:focus\:to-teal-600:focus {
    --gradient-to-color: #047481;
  }

  .lg\:focus\:to-teal-700:focus {
    --gradient-to-color: #036672;
  }

  .lg\:focus\:to-teal-800:focus {
    --gradient-to-color: #05505c;
  }

  .lg\:focus\:to-teal-900:focus {
    --gradient-to-color: #014451;
  }

  .lg\:focus\:to-blue-50:focus {
    --gradient-to-color: #ebf5ff;
  }

  .lg\:focus\:to-blue-100:focus {
    --gradient-to-color: #e1effe;
  }

  .lg\:focus\:to-blue-200:focus {
    --gradient-to-color: #c3ddfd;
  }

  .lg\:focus\:to-blue-300:focus {
    --gradient-to-color: #a4cafe;
  }

  .lg\:focus\:to-blue-400:focus {
    --gradient-to-color: #76a9fa;
  }

  .lg\:focus\:to-blue-500:focus {
    --gradient-to-color: #3f83f8;
  }

  .lg\:focus\:to-blue-600:focus {
    --gradient-to-color: #1c64f2;
  }

  .lg\:focus\:to-blue-700:focus {
    --gradient-to-color: #1a56db;
  }

  .lg\:focus\:to-blue-800:focus {
    --gradient-to-color: #1e429f;
  }

  .lg\:focus\:to-blue-900:focus {
    --gradient-to-color: #233876;
  }

  .lg\:focus\:to-indigo-50:focus {
    --gradient-to-color: #f0f5ff;
  }

  .lg\:focus\:to-indigo-100:focus {
    --gradient-to-color: #e5edff;
  }

  .lg\:focus\:to-indigo-200:focus {
    --gradient-to-color: #cddbfe;
  }

  .lg\:focus\:to-indigo-300:focus {
    --gradient-to-color: #b4c6fc;
  }

  .lg\:focus\:to-indigo-400:focus {
    --gradient-to-color: #8da2fb;
  }

  .lg\:focus\:to-indigo-500:focus {
    --gradient-to-color: #6875f5;
  }

  .lg\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5850ec;
  }

  .lg\:focus\:to-indigo-700:focus {
    --gradient-to-color: #5145cd;
  }

  .lg\:focus\:to-indigo-800:focus {
    --gradient-to-color: #42389d;
  }

  .lg\:focus\:to-indigo-900:focus {
    --gradient-to-color: #362f78;
  }

  .lg\:focus\:to-purple-50:focus {
    --gradient-to-color: #f6f5ff;
  }

  .lg\:focus\:to-purple-100:focus {
    --gradient-to-color: #edebfe;
  }

  .lg\:focus\:to-purple-200:focus {
    --gradient-to-color: #dcd7fe;
  }

  .lg\:focus\:to-purple-300:focus {
    --gradient-to-color: #cabffd;
  }

  .lg\:focus\:to-purple-400:focus {
    --gradient-to-color: #ac94fa;
  }

  .lg\:focus\:to-purple-500:focus {
    --gradient-to-color: #9061f9;
  }

  .lg\:focus\:to-purple-600:focus {
    --gradient-to-color: #7e3af2;
  }

  .lg\:focus\:to-purple-700:focus {
    --gradient-to-color: #6c2bd9;
  }

  .lg\:focus\:to-purple-800:focus {
    --gradient-to-color: #5521b5;
  }

  .lg\:focus\:to-purple-900:focus {
    --gradient-to-color: #4a1d96;
  }

  .lg\:focus\:to-pink-50:focus {
    --gradient-to-color: #fdf2f8;
  }

  .lg\:focus\:to-pink-100:focus {
    --gradient-to-color: #fce8f3;
  }

  .lg\:focus\:to-pink-200:focus {
    --gradient-to-color: #fad1e8;
  }

  .lg\:focus\:to-pink-300:focus {
    --gradient-to-color: #f8b4d9;
  }

  .lg\:focus\:to-pink-400:focus {
    --gradient-to-color: #f17eb8;
  }

  .lg\:focus\:to-pink-500:focus {
    --gradient-to-color: #e74694;
  }

  .lg\:focus\:to-pink-600:focus {
    --gradient-to-color: #d61f69;
  }

  .lg\:focus\:to-pink-700:focus {
    --gradient-to-color: #bf125d;
  }

  .lg\:focus\:to-pink-800:focus {
    --gradient-to-color: #99154b;
  }

  .lg\:focus\:to-pink-900:focus {
    --gradient-to-color: #751a3d;
  }

  .lg\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .lg\:bg-opacity-25 {
    --bg-opacity: .25;
  }

  .lg\:bg-opacity-50 {
    --bg-opacity: .5;
  }

  .lg\:bg-opacity-75 {
    --bg-opacity: .75;
  }

  .lg\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .lg\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .lg\:hover\:bg-opacity-25:hover {
    --bg-opacity: .25;
  }

  .lg\:hover\:bg-opacity-50:hover {
    --bg-opacity: .5;
  }

  .lg\:hover\:bg-opacity-75:hover {
    --bg-opacity: .75;
  }

  .lg\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .lg\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .lg\:focus\:bg-opacity-25:focus {
    --bg-opacity: .25;
  }

  .lg\:focus\:bg-opacity-50:focus {
    --bg-opacity: .5;
  }

  .lg\:focus\:bg-opacity-75:focus {
    --bg-opacity: .75;
  }

  .lg\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .lg\:bg-bottom {
    background-position: bottom;
  }

  .lg\:bg-center {
    background-position: center;
  }

  .lg\:bg-left {
    background-position: 0;
  }

  .lg\:bg-left-bottom {
    background-position: 0 100%;
  }

  .lg\:bg-left-top {
    background-position: 0 0;
  }

  .lg\:bg-right {
    background-position: 100%;
  }

  .lg\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .lg\:bg-right-top {
    background-position: 100% 0;
  }

  .lg\:bg-top {
    background-position: top;
  }

  .lg\:bg-repeat {
    background-repeat: repeat;
  }

  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .lg\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .lg\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .lg\:bg-repeat-round {
    background-repeat: round;
  }

  .lg\:bg-repeat-space {
    background-repeat: space;
  }

  .lg\:bg-auto {
    background-size: auto;
  }

  .lg\:bg-cover {
    background-size: cover;
  }

  .lg\:bg-contain {
    background-size: contain;
  }

  .lg\:border-collapse {
    border-collapse: collapse;
  }

  .lg\:border-separate {
    border-collapse: separate;
  }

  .lg\:border-transparent {
    border-color: #0000;
  }

  .lg\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .lg\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .lg\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .lg\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .lg\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .lg\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .lg\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .lg\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .lg\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .lg\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .lg\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .lg\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .lg\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .lg\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .lg\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .lg\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .lg\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .lg\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .lg\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .lg\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .lg\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .lg\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .lg\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .lg\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .lg\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .lg\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .lg\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .lg\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .lg\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .lg\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .lg\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .lg\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .lg\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .lg\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .lg\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .lg\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .lg\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .lg\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .lg\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .lg\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .lg\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .lg\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .lg\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .lg\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .lg\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .lg\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .lg\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .lg\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .lg\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .lg\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .lg\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .lg\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .lg\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .lg\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .lg\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .lg\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .lg\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .lg\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .lg\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .lg\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .lg\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .lg\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .lg\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .lg\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .lg\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .lg\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .lg\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .lg\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .lg\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .lg\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .lg\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .lg\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .lg\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .lg\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .lg\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .lg\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .lg\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .lg\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .lg\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .lg\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .lg\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .lg\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .lg\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .lg\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .lg\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .lg\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .lg\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .lg\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .lg\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .lg\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .lg\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .lg\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .lg\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .lg\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .lg\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .lg\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .lg\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .lg\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .lg\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .lg\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .lg\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .lg\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .lg\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .lg\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .lg\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .lg\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .lg\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .lg\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .lg\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .lg\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .lg\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .lg\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .lg\:group-hover\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .group:hover .lg\:group-hover\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-transparent {
    border-color: #0000;
  }

  .group:focus .lg\:group-focus\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .group:focus .lg\:group-focus\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .lg\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .lg\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .lg\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-50:hover {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .lg\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-50:hover {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-100:hover {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-200:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-300:hover {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-400:hover {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-500:hover {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-600:hover {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-700:hover {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-800:hover {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .lg\:hover\:border-cool-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .lg\:hover\:border-red-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .lg\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .lg\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .lg\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .lg\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .lg\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .lg\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .lg\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .lg\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .lg\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-50:hover {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .lg\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-50:hover {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .lg\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .lg\:hover\:border-green-50:hover {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .lg\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .lg\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .lg\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .lg\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .lg\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .lg\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .lg\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .lg\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .lg\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-50:hover {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .lg\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-50:hover {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .lg\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-50:hover {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .lg\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-50:hover {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .lg\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .lg\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .lg\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .lg\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .lg\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-50:focus {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .lg\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-50:focus {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-100:focus {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-200:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-300:focus {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-400:focus {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-500:focus {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-600:focus {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-700:focus {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-800:focus {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .lg\:focus\:border-cool-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .lg\:focus\:border-red-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .lg\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .lg\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .lg\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .lg\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .lg\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .lg\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .lg\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .lg\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .lg\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-50:focus {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .lg\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-50:focus {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .lg\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .lg\:focus\:border-green-50:focus {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .lg\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .lg\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .lg\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .lg\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .lg\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .lg\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .lg\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .lg\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .lg\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-50:focus {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .lg\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-50:focus {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .lg\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-50:focus {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .lg\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-50:focus {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .lg\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .lg\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .lg\:border-opacity-0 {
    --border-opacity: 0;
  }

  .lg\:border-opacity-25 {
    --border-opacity: .25;
  }

  .lg\:border-opacity-50 {
    --border-opacity: .5;
  }

  .lg\:border-opacity-75 {
    --border-opacity: .75;
  }

  .lg\:border-opacity-100 {
    --border-opacity: 1;
  }

  .lg\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .lg\:hover\:border-opacity-25:hover {
    --border-opacity: .25;
  }

  .lg\:hover\:border-opacity-50:hover {
    --border-opacity: .5;
  }

  .lg\:hover\:border-opacity-75:hover {
    --border-opacity: .75;
  }

  .lg\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .lg\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .lg\:focus\:border-opacity-25:focus {
    --border-opacity: .25;
  }

  .lg\:focus\:border-opacity-50:focus {
    --border-opacity: .5;
  }

  .lg\:focus\:border-opacity-75:focus {
    --border-opacity: .75;
  }

  .lg\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .lg\:rounded-none {
    border-radius: 0;
  }

  .lg\:rounded-sm {
    border-radius: .125rem;
  }

  .lg\:rounded {
    border-radius: .25rem;
  }

  .lg\:rounded-md {
    border-radius: .375rem;
  }

  .lg\:rounded-lg {
    border-radius: .5rem;
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:rounded-2xl {
    border-radius: 1rem;
  }

  .lg\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .lg\:rounded-full {
    border-radius: 9999px;
  }

  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .lg\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .lg\:rounded-b-sm {
    border-bottom-left-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .lg\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .lg\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .lg\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .lg\:rounded-b {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .lg\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .lg\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .lg\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .lg\:rounded-b-md {
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .lg\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .lg\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .lg\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .lg\:rounded-b-lg {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .lg\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .lg\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .lg\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .lg\:rounded-b-xl {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .lg\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-b-2xl {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-b-3xl {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-b-full {
    border-bottom-left-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .lg\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .lg\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .lg\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .lg\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .lg\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .lg\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .lg\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .lg\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .lg\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .lg\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .lg\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .lg\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .lg\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .lg\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .lg\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .lg\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .lg\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .lg\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .lg\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .lg\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .lg\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .lg\:border-solid {
    border-style: solid;
  }

  .lg\:border-dashed {
    border-style: dashed;
  }

  .lg\:border-dotted {
    border-style: dotted;
  }

  .lg\:border-double {
    border-style: double;
  }

  .lg\:border-none {
    border-style: none;
  }

  .lg\:border-0 {
    border-width: 0;
  }

  .lg\:border-2 {
    border-width: 2px;
  }

  .lg\:border-4 {
    border-width: 4px;
  }

  .lg\:border-8 {
    border-width: 8px;
  }

  .lg\:border {
    border-width: 1px;
  }

  .lg\:border-t-0 {
    border-top-width: 0;
  }

  .lg\:border-r-0 {
    border-right-width: 0;
  }

  .lg\:border-b-0 {
    border-bottom-width: 0;
  }

  .lg\:border-l-0 {
    border-left-width: 0;
  }

  .lg\:border-t-2 {
    border-top-width: 2px;
  }

  .lg\:border-r-2 {
    border-right-width: 2px;
  }

  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }

  .lg\:border-l-2 {
    border-left-width: 2px;
  }

  .lg\:border-t-4 {
    border-top-width: 4px;
  }

  .lg\:border-r-4 {
    border-right-width: 4px;
  }

  .lg\:border-b-4 {
    border-bottom-width: 4px;
  }

  .lg\:border-l-4 {
    border-left-width: 4px;
  }

  .lg\:border-t-8 {
    border-top-width: 8px;
  }

  .lg\:border-r-8 {
    border-right-width: 8px;
  }

  .lg\:border-b-8 {
    border-bottom-width: 8px;
  }

  .lg\:border-l-8 {
    border-left-width: 8px;
  }

  .lg\:border-t {
    border-top-width: 1px;
  }

  .lg\:border-r {
    border-right-width: 1px;
  }

  .lg\:border-b {
    border-bottom-width: 1px;
  }

  .lg\:border-l {
    border-left-width: 1px;
  }

  .lg\:box-border {
    box-sizing: border-box;
  }

  .lg\:box-content {
    box-sizing: content-box;
  }

  .lg\:cursor-auto {
    cursor: auto;
  }

  .lg\:cursor-default {
    cursor: default;
  }

  .lg\:cursor-pointer {
    cursor: pointer;
  }

  .lg\:cursor-wait {
    cursor: wait;
  }

  .lg\:cursor-text {
    cursor: text;
  }

  .lg\:cursor-move {
    cursor: move;
  }

  .lg\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:table {
    display: table;
  }

  .lg\:table-caption {
    display: table-caption;
  }

  .lg\:table-cell {
    display: table-cell;
  }

  .lg\:table-column {
    display: table-column;
  }

  .lg\:table-column-group {
    display: table-column-group;
  }

  .lg\:table-footer-group {
    display: table-footer-group;
  }

  .lg\:table-header-group {
    display: table-header-group;
  }

  .lg\:table-row-group {
    display: table-row-group;
  }

  .lg\:table-row {
    display: table-row;
  }

  .lg\:flow-root {
    display: flow-root;
  }

  .lg\:grid {
    display: grid;
  }

  .lg\:inline-grid {
    display: inline-grid;
  }

  .lg\:contents {
    display: contents;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg\:flex-col {
    flex-direction: column;
  }

  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg\:flex-wrap {
    flex-wrap: wrap;
  }

  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg\:flex-no-wrap {
    flex-wrap: nowrap;
  }

  .lg\:place-items-auto {
    place-items: auto;
  }

  .lg\:place-items-start {
    place-items: start;
  }

  .lg\:place-items-end {
    place-items: end;
  }

  .lg\:place-items-center {
    place-items: center;
  }

  .lg\:place-items-stretch {
    place-items: stretch stretch;
  }

  .lg\:place-content-center {
    place-content: center;
  }

  .lg\:place-content-start {
    place-content: start;
  }

  .lg\:place-content-end {
    place-content: end;
  }

  .lg\:place-content-between {
    place-content: space-between;
  }

  .lg\:place-content-around {
    place-content: space-around;
  }

  .lg\:place-content-evenly {
    place-content: space-evenly;
  }

  .lg\:place-content-stretch {
    place-content: stretch;
  }

  .lg\:place-self-auto {
    place-self: auto;
  }

  .lg\:place-self-start {
    place-self: start;
  }

  .lg\:place-self-end {
    place-self: end;
  }

  .lg\:place-self-center {
    place-self: center;
  }

  .lg\:place-self-stretch {
    place-self: stretch stretch;
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-end {
    align-items: flex-end;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:items-baseline {
    align-items: baseline;
  }

  .lg\:items-stretch {
    align-items: stretch;
  }

  .lg\:content-center {
    align-content: center;
  }

  .lg\:content-start {
    align-content: flex-start;
  }

  .lg\:content-end {
    align-content: flex-end;
  }

  .lg\:content-between {
    align-content: space-between;
  }

  .lg\:content-around {
    align-content: space-around;
  }

  .lg\:content-evenly {
    align-content: space-evenly;
  }

  .lg\:self-auto {
    align-self: auto;
  }

  .lg\:self-start {
    align-self: flex-start;
  }

  .lg\:self-end {
    align-self: flex-end;
  }

  .lg\:self-center {
    align-self: center;
  }

  .lg\:self-stretch {
    align-self: stretch;
  }

  .lg\:justify-items-auto {
    justify-items: auto;
  }

  .lg\:justify-items-start {
    justify-items: start;
  }

  .lg\:justify-items-end {
    justify-items: end;
  }

  .lg\:justify-items-center {
    justify-items: center;
  }

  .lg\:justify-items-stretch {
    justify-items: stretch;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-end {
    justify-content: flex-end;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:justify-evenly {
    justify-content: space-evenly;
  }

  .lg\:justify-self-auto {
    justify-self: auto;
  }

  .lg\:justify-self-start {
    justify-self: start;
  }

  .lg\:justify-self-end {
    justify-self: end;
  }

  .lg\:justify-self-center {
    justify-self: center;
  }

  .lg\:justify-self-stretch {
    justify-self: stretch;
  }

  .lg\:flex-1 {
    flex: 1;
  }

  .lg\:flex-auto {
    flex: auto;
  }

  .lg\:flex-initial {
    flex: 0 auto;
  }

  .lg\:flex-none {
    flex: none;
  }

  .lg\:flex-grow-0 {
    flex-grow: 0;
  }

  .lg\:flex-grow {
    flex-grow: 1;
  }

  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .lg\:flex-shrink {
    flex-shrink: 1;
  }

  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:order-3 {
    order: 3;
  }

  .lg\:order-4 {
    order: 4;
  }

  .lg\:order-5 {
    order: 5;
  }

  .lg\:order-6 {
    order: 6;
  }

  .lg\:order-7 {
    order: 7;
  }

  .lg\:order-8 {
    order: 8;
  }

  .lg\:order-9 {
    order: 9;
  }

  .lg\:order-10 {
    order: 10;
  }

  .lg\:order-11 {
    order: 11;
  }

  .lg\:order-12 {
    order: 12;
  }

  .lg\:order-first {
    order: -9999;
  }

  .lg\:order-last {
    order: 9999;
  }

  .lg\:order-none {
    order: 0;
  }

  .lg\:float-right {
    float: right;
  }

  .lg\:float-left {
    float: left;
  }

  .lg\:float-none {
    float: none;
  }

  .lg\:clearfix:after {
    content: "";
    clear: both;
    display: table;
  }

  .lg\:clear-left {
    clear: left;
  }

  .lg\:clear-right {
    clear: right;
  }

  .lg\:clear-both {
    clear: both;
  }

  .lg\:clear-none {
    clear: none;
  }

  .lg\:font-sans {
    font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .lg\:font-serif {
    font-family: Georgia, Cambria, Times New Roman, Times, serif;
  }

  .lg\:font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .lg\:font-ibmmono {
    font-family: IBM Plex Mono;
  }

  .lg\:font-hairline {
    font-weight: 100;
  }

  .lg\:font-thin {
    font-weight: 200;
  }

  .lg\:font-light {
    font-weight: 300;
  }

  .lg\:font-normal {
    font-weight: 400;
  }

  .lg\:font-medium {
    font-weight: 500;
  }

  .lg\:font-semibold {
    font-weight: 600;
  }

  .lg\:font-bold {
    font-weight: 700;
  }

  .lg\:font-extrabold {
    font-weight: 800;
  }

  .lg\:font-black {
    font-weight: 900;
  }

  .lg\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .lg\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .lg\:hover\:font-light:hover {
    font-weight: 300;
  }

  .lg\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .lg\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .lg\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .lg\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .lg\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .lg\:hover\:font-black:hover {
    font-weight: 900;
  }

  .lg\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .lg\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .lg\:focus\:font-light:focus {
    font-weight: 300;
  }

  .lg\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .lg\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .lg\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .lg\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .lg\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .lg\:focus\:font-black:focus {
    font-weight: 900;
  }

  .lg\:h-0 {
    height: 0;
  }

  .lg\:h-1 {
    height: .25rem;
  }

  .lg\:h-2 {
    height: .5rem;
  }

  .lg\:h-3 {
    height: .75rem;
  }

  .lg\:h-4 {
    height: 1rem;
  }

  .lg\:h-5 {
    height: 1.25rem;
  }

  .lg\:h-6 {
    height: 1.5rem;
  }

  .lg\:h-7 {
    height: 1.75rem;
  }

  .lg\:h-8 {
    height: 2rem;
  }

  .lg\:h-9 {
    height: 2.25rem;
  }

  .lg\:h-10 {
    height: 2.5rem;
  }

  .lg\:h-11 {
    height: 2.75rem;
  }

  .lg\:h-12 {
    height: 3rem;
  }

  .lg\:h-13 {
    height: 3.25rem;
  }

  .lg\:h-14 {
    height: 3.5rem;
  }

  .lg\:h-15 {
    height: 3.75rem;
  }

  .lg\:h-16 {
    height: 4rem;
  }

  .lg\:h-20 {
    height: 5rem;
  }

  .lg\:h-24 {
    height: 6rem;
  }

  .lg\:h-28 {
    height: 7rem;
  }

  .lg\:h-32 {
    height: 8rem;
  }

  .lg\:h-36 {
    height: 9rem;
  }

  .lg\:h-40 {
    height: 10rem;
  }

  .lg\:h-44 {
    height: 11rem;
  }

  .lg\:h-48 {
    height: 12rem;
  }

  .lg\:h-52 {
    height: 13rem;
  }

  .lg\:h-56 {
    height: 14rem;
  }

  .lg\:h-60 {
    height: 15rem;
  }

  .lg\:h-64 {
    height: 16rem;
  }

  .lg\:h-72 {
    height: 18rem;
  }

  .lg\:h-80 {
    height: 20rem;
  }

  .lg\:h-96 {
    height: 24rem;
  }

  .lg\:h-auto {
    height: auto;
  }

  .lg\:h-px {
    height: 1px;
  }

  .lg\:h-0\.5 {
    height: .125rem;
  }

  .lg\:h-1\.5 {
    height: .375rem;
  }

  .lg\:h-2\.5 {
    height: .625rem;
  }

  .lg\:h-3\.5 {
    height: .875rem;
  }

  .lg\:h-1\/2 {
    height: 50%;
  }

  .lg\:h-1\/3 {
    height: 33.3333%;
  }

  .lg\:h-2\/3 {
    height: 66.6667%;
  }

  .lg\:h-1\/4 {
    height: 25%;
  }

  .lg\:h-2\/4 {
    height: 50%;
  }

  .lg\:h-3\/4 {
    height: 75%;
  }

  .lg\:h-1\/5 {
    height: 20%;
  }

  .lg\:h-2\/5 {
    height: 40%;
  }

  .lg\:h-3\/5 {
    height: 60%;
  }

  .lg\:h-4\/5 {
    height: 80%;
  }

  .lg\:h-1\/6 {
    height: 16.6667%;
  }

  .lg\:h-2\/6 {
    height: 33.3333%;
  }

  .lg\:h-3\/6 {
    height: 50%;
  }

  .lg\:h-4\/6 {
    height: 66.6667%;
  }

  .lg\:h-5\/6 {
    height: 83.3333%;
  }

  .lg\:h-1\/12 {
    height: 8.33333%;
  }

  .lg\:h-2\/12 {
    height: 16.6667%;
  }

  .lg\:h-3\/12 {
    height: 25%;
  }

  .lg\:h-4\/12 {
    height: 33.3333%;
  }

  .lg\:h-5\/12 {
    height: 41.6667%;
  }

  .lg\:h-6\/12 {
    height: 50%;
  }

  .lg\:h-7\/12 {
    height: 58.3333%;
  }

  .lg\:h-8\/12 {
    height: 66.6667%;
  }

  .lg\:h-9\/12 {
    height: 75%;
  }

  .lg\:h-10\/12 {
    height: 83.3333%;
  }

  .lg\:h-11\/12 {
    height: 91.6667%;
  }

  .lg\:h-full {
    height: 100%;
  }

  .lg\:h-screen {
    height: 100vh;
  }

  .lg\:text-xs {
    font-size: .75rem;
  }

  .lg\:text-sm {
    font-size: .875rem;
  }

  .lg\:text-base {
    font-size: 1rem;
  }

  .lg\:text-lg {
    font-size: 1.125rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
  }

  .lg\:text-3xl {
    font-size: 1.875rem;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
  }

  .lg\:text-5xl {
    font-size: 3rem;
  }

  .lg\:text-6xl {
    font-size: 4rem;
  }

  .lg\:leading-3 {
    line-height: .75rem;
  }

  .lg\:leading-4 {
    line-height: 1rem;
  }

  .lg\:leading-5 {
    line-height: 1.25rem;
  }

  .lg\:leading-6 {
    line-height: 1.5rem;
  }

  .lg\:leading-7 {
    line-height: 1.75rem;
  }

  .lg\:leading-8 {
    line-height: 2rem;
  }

  .lg\:leading-9 {
    line-height: 2.25rem;
  }

  .lg\:leading-10 {
    line-height: 2.5rem;
  }

  .lg\:leading-none {
    line-height: 1;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:leading-snug {
    line-height: 1.375;
  }

  .lg\:leading-normal {
    line-height: 1.5;
  }

  .lg\:leading-relaxed {
    line-height: 1.625;
  }

  .lg\:leading-loose {
    line-height: 2;
  }

  .lg\:list-inside {
    list-style-position: inside;
  }

  .lg\:list-outside {
    list-style-position: outside;
  }

  .lg\:list-none {
    list-style-type: none;
  }

  .lg\:list-disc {
    list-style-type: disc;
  }

  .lg\:list-decimal {
    list-style-type: decimal;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:m-1 {
    margin: .25rem;
  }

  .lg\:m-2 {
    margin: .5rem;
  }

  .lg\:m-3 {
    margin: .75rem;
  }

  .lg\:m-4 {
    margin: 1rem;
  }

  .lg\:m-5 {
    margin: 1.25rem;
  }

  .lg\:m-6 {
    margin: 1.5rem;
  }

  .lg\:m-7 {
    margin: 1.75rem;
  }

  .lg\:m-8 {
    margin: 2rem;
  }

  .lg\:m-9 {
    margin: 2.25rem;
  }

  .lg\:m-10 {
    margin: 2.5rem;
  }

  .lg\:m-11 {
    margin: 2.75rem;
  }

  .lg\:m-12 {
    margin: 3rem;
  }

  .lg\:m-13 {
    margin: 3.25rem;
  }

  .lg\:m-14 {
    margin: 3.5rem;
  }

  .lg\:m-15 {
    margin: 3.75rem;
  }

  .lg\:m-16 {
    margin: 4rem;
  }

  .lg\:m-20 {
    margin: 5rem;
  }

  .lg\:m-24 {
    margin: 6rem;
  }

  .lg\:m-28 {
    margin: 7rem;
  }

  .lg\:m-32 {
    margin: 8rem;
  }

  .lg\:m-36 {
    margin: 9rem;
  }

  .lg\:m-40 {
    margin: 10rem;
  }

  .lg\:m-44 {
    margin: 11rem;
  }

  .lg\:m-48 {
    margin: 12rem;
  }

  .lg\:m-52 {
    margin: 13rem;
  }

  .lg\:m-56 {
    margin: 14rem;
  }

  .lg\:m-60 {
    margin: 15rem;
  }

  .lg\:m-64 {
    margin: 16rem;
  }

  .lg\:m-72 {
    margin: 18rem;
  }

  .lg\:m-80 {
    margin: 20rem;
  }

  .lg\:m-96 {
    margin: 24rem;
  }

  .lg\:m-auto {
    margin: auto;
  }

  .lg\:m-px {
    margin: 1px;
  }

  .lg\:m-0\.5 {
    margin: .125rem;
  }

  .lg\:m-1\.5 {
    margin: .375rem;
  }

  .lg\:m-2\.5 {
    margin: .625rem;
  }

  .lg\:m-3\.5 {
    margin: .875rem;
  }

  .lg\:m-1\/2 {
    margin: 50%;
  }

  .lg\:m-1\/3 {
    margin: 33.3333%;
  }

  .lg\:m-2\/3 {
    margin: 66.6667%;
  }

  .lg\:m-1\/4 {
    margin: 25%;
  }

  .lg\:m-2\/4 {
    margin: 50%;
  }

  .lg\:m-3\/4 {
    margin: 75%;
  }

  .lg\:m-1\/5 {
    margin: 20%;
  }

  .lg\:m-2\/5 {
    margin: 40%;
  }

  .lg\:m-3\/5 {
    margin: 60%;
  }

  .lg\:m-4\/5 {
    margin: 80%;
  }

  .lg\:m-1\/6 {
    margin: 16.6667%;
  }

  .lg\:m-2\/6 {
    margin: 33.3333%;
  }

  .lg\:m-3\/6 {
    margin: 50%;
  }

  .lg\:m-4\/6 {
    margin: 66.6667%;
  }

  .lg\:m-5\/6 {
    margin: 83.3333%;
  }

  .lg\:m-1\/12 {
    margin: 8.33333%;
  }

  .lg\:m-2\/12 {
    margin: 16.6667%;
  }

  .lg\:m-3\/12 {
    margin: 25%;
  }

  .lg\:m-4\/12 {
    margin: 33.3333%;
  }

  .lg\:m-5\/12 {
    margin: 41.6667%;
  }

  .lg\:m-6\/12 {
    margin: 50%;
  }

  .lg\:m-7\/12 {
    margin: 58.3333%;
  }

  .lg\:m-8\/12 {
    margin: 66.6667%;
  }

  .lg\:m-9\/12 {
    margin: 75%;
  }

  .lg\:m-10\/12 {
    margin: 83.3333%;
  }

  .lg\:m-11\/12 {
    margin: 91.6667%;
  }

  .lg\:m-full {
    margin: 100%;
  }

  .lg\:-m-1 {
    margin: -.25rem;
  }

  .lg\:-m-2 {
    margin: -.5rem;
  }

  .lg\:-m-3 {
    margin: -.75rem;
  }

  .lg\:-m-4 {
    margin: -1rem;
  }

  .lg\:-m-5 {
    margin: -1.25rem;
  }

  .lg\:-m-6 {
    margin: -1.5rem;
  }

  .lg\:-m-7 {
    margin: -1.75rem;
  }

  .lg\:-m-8 {
    margin: -2rem;
  }

  .lg\:-m-9 {
    margin: -2.25rem;
  }

  .lg\:-m-10 {
    margin: -2.5rem;
  }

  .lg\:-m-11 {
    margin: -2.75rem;
  }

  .lg\:-m-12 {
    margin: -3rem;
  }

  .lg\:-m-13 {
    margin: -3.25rem;
  }

  .lg\:-m-14 {
    margin: -3.5rem;
  }

  .lg\:-m-15 {
    margin: -3.75rem;
  }

  .lg\:-m-16 {
    margin: -4rem;
  }

  .lg\:-m-20 {
    margin: -5rem;
  }

  .lg\:-m-24 {
    margin: -6rem;
  }

  .lg\:-m-28 {
    margin: -7rem;
  }

  .lg\:-m-32 {
    margin: -8rem;
  }

  .lg\:-m-36 {
    margin: -9rem;
  }

  .lg\:-m-40 {
    margin: -10rem;
  }

  .lg\:-m-44 {
    margin: -11rem;
  }

  .lg\:-m-48 {
    margin: -12rem;
  }

  .lg\:-m-52 {
    margin: -13rem;
  }

  .lg\:-m-56 {
    margin: -14rem;
  }

  .lg\:-m-60 {
    margin: -15rem;
  }

  .lg\:-m-64 {
    margin: -16rem;
  }

  .lg\:-m-72 {
    margin: -18rem;
  }

  .lg\:-m-80 {
    margin: -20rem;
  }

  .lg\:-m-96 {
    margin: -24rem;
  }

  .lg\:-m-px {
    margin: -1px;
  }

  .lg\:-m-0\.5 {
    margin: -.125rem;
  }

  .lg\:-m-1\.5 {
    margin: -.375rem;
  }

  .lg\:-m-2\.5 {
    margin: -.625rem;
  }

  .lg\:-m-3\.5 {
    margin: -.875rem;
  }

  .lg\:-m-1\/2 {
    margin: -50%;
  }

  .lg\:-m-1\/3 {
    margin: -33.3333%;
  }

  .lg\:-m-2\/3 {
    margin: -66.6667%;
  }

  .lg\:-m-1\/4 {
    margin: -25%;
  }

  .lg\:-m-2\/4 {
    margin: -50%;
  }

  .lg\:-m-3\/4 {
    margin: -75%;
  }

  .lg\:-m-1\/5 {
    margin: -20%;
  }

  .lg\:-m-2\/5 {
    margin: -40%;
  }

  .lg\:-m-3\/5 {
    margin: -60%;
  }

  .lg\:-m-4\/5 {
    margin: -80%;
  }

  .lg\:-m-1\/6 {
    margin: -16.6667%;
  }

  .lg\:-m-2\/6 {
    margin: -33.3333%;
  }

  .lg\:-m-3\/6 {
    margin: -50%;
  }

  .lg\:-m-4\/6 {
    margin: -66.6667%;
  }

  .lg\:-m-5\/6 {
    margin: -83.3333%;
  }

  .lg\:-m-1\/12 {
    margin: -8.33333%;
  }

  .lg\:-m-2\/12 {
    margin: -16.6667%;
  }

  .lg\:-m-3\/12 {
    margin: -25%;
  }

  .lg\:-m-4\/12 {
    margin: -33.3333%;
  }

  .lg\:-m-5\/12 {
    margin: -41.6667%;
  }

  .lg\:-m-6\/12 {
    margin: -50%;
  }

  .lg\:-m-7\/12 {
    margin: -58.3333%;
  }

  .lg\:-m-8\/12 {
    margin: -66.6667%;
  }

  .lg\:-m-9\/12 {
    margin: -75%;
  }

  .lg\:-m-10\/12 {
    margin: -83.3333%;
  }

  .lg\:-m-11\/12 {
    margin: -91.6667%;
  }

  .lg\:-m-full {
    margin: -100%;
  }

  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .lg\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .lg\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .lg\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .lg\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .lg\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .lg\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .lg\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .lg\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .lg\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:my-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem;
  }

  .lg\:mx-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem;
  }

  .lg\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .lg\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .lg\:my-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .lg\:mx-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .lg\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .lg\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .lg\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .lg\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .lg\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .lg\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .lg\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .lg\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .lg\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .lg\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .lg\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .lg\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .lg\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .lg\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .lg\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .lg\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .lg\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .lg\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .lg\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .lg\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .lg\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .lg\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .lg\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .lg\:my-1\/2 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .lg\:mx-1\/2 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .lg\:my-1\/3 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .lg\:mx-1\/3 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .lg\:my-2\/3 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .lg\:mx-2\/3 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .lg\:my-1\/4 {
    margin-top: 25%;
    margin-bottom: 25%;
  }

  .lg\:mx-1\/4 {
    margin-left: 25%;
    margin-right: 25%;
  }

  .lg\:my-2\/4 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .lg\:mx-2\/4 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .lg\:my-3\/4 {
    margin-top: 75%;
    margin-bottom: 75%;
  }

  .lg\:mx-3\/4 {
    margin-left: 75%;
    margin-right: 75%;
  }

  .lg\:my-1\/5 {
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .lg\:mx-1\/5 {
    margin-left: 20%;
    margin-right: 20%;
  }

  .lg\:my-2\/5 {
    margin-top: 40%;
    margin-bottom: 40%;
  }

  .lg\:mx-2\/5 {
    margin-left: 40%;
    margin-right: 40%;
  }

  .lg\:my-3\/5 {
    margin-top: 60%;
    margin-bottom: 60%;
  }

  .lg\:mx-3\/5 {
    margin-left: 60%;
    margin-right: 60%;
  }

  .lg\:my-4\/5 {
    margin-top: 80%;
    margin-bottom: 80%;
  }

  .lg\:mx-4\/5 {
    margin-left: 80%;
    margin-right: 80%;
  }

  .lg\:my-1\/6 {
    margin-top: 16.6667%;
    margin-bottom: 16.6667%;
  }

  .lg\:mx-1\/6 {
    margin-left: 16.6667%;
    margin-right: 16.6667%;
  }

  .lg\:my-2\/6 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .lg\:mx-2\/6 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .lg\:my-3\/6 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .lg\:mx-3\/6 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .lg\:my-4\/6 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .lg\:mx-4\/6 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .lg\:my-5\/6 {
    margin-top: 83.3333%;
    margin-bottom: 83.3333%;
  }

  .lg\:mx-5\/6 {
    margin-left: 83.3333%;
    margin-right: 83.3333%;
  }

  .lg\:my-1\/12 {
    margin-top: 8.33333%;
    margin-bottom: 8.33333%;
  }

  .lg\:mx-1\/12 {
    margin-left: 8.33333%;
    margin-right: 8.33333%;
  }

  .lg\:my-2\/12 {
    margin-top: 16.6667%;
    margin-bottom: 16.6667%;
  }

  .lg\:mx-2\/12 {
    margin-left: 16.6667%;
    margin-right: 16.6667%;
  }

  .lg\:my-3\/12 {
    margin-top: 25%;
    margin-bottom: 25%;
  }

  .lg\:mx-3\/12 {
    margin-left: 25%;
    margin-right: 25%;
  }

  .lg\:my-4\/12 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .lg\:mx-4\/12 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .lg\:my-5\/12 {
    margin-top: 41.6667%;
    margin-bottom: 41.6667%;
  }

  .lg\:mx-5\/12 {
    margin-left: 41.6667%;
    margin-right: 41.6667%;
  }

  .lg\:my-6\/12 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .lg\:mx-6\/12 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .lg\:my-7\/12 {
    margin-top: 58.3333%;
    margin-bottom: 58.3333%;
  }

  .lg\:mx-7\/12 {
    margin-left: 58.3333%;
    margin-right: 58.3333%;
  }

  .lg\:my-8\/12 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .lg\:mx-8\/12 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .lg\:my-9\/12 {
    margin-top: 75%;
    margin-bottom: 75%;
  }

  .lg\:mx-9\/12 {
    margin-left: 75%;
    margin-right: 75%;
  }

  .lg\:my-10\/12 {
    margin-top: 83.3333%;
    margin-bottom: 83.3333%;
  }

  .lg\:mx-10\/12 {
    margin-left: 83.3333%;
    margin-right: 83.3333%;
  }

  .lg\:my-11\/12 {
    margin-top: 91.6667%;
    margin-bottom: 91.6667%;
  }

  .lg\:mx-11\/12 {
    margin-left: 91.6667%;
    margin-right: 91.6667%;
  }

  .lg\:my-full {
    margin-top: 100%;
    margin-bottom: 100%;
  }

  .lg\:mx-full {
    margin-left: 100%;
    margin-right: 100%;
  }

  .lg\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .lg\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .lg\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .lg\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .lg\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .lg\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .lg\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .lg\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .lg\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .lg\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .lg\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .lg\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .lg\:-my-13 {
    margin-top: -3.25rem;
    margin-bottom: -3.25rem;
  }

  .lg\:-mx-13 {
    margin-left: -3.25rem;
    margin-right: -3.25rem;
  }

  .lg\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .lg\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .lg\:-my-15 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }

  .lg\:-mx-15 {
    margin-left: -3.75rem;
    margin-right: -3.75rem;
  }

  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .lg\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .lg\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .lg\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .lg\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .lg\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .lg\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .lg\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .lg\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .lg\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .lg\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .lg\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .lg\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .lg\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .lg\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .lg\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .lg\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .lg\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .lg\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .lg\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .lg\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .lg\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .lg\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .lg\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .lg\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .lg\:-my-1\/2 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .lg\:-mx-1\/2 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .lg\:-my-1\/3 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .lg\:-mx-1\/3 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .lg\:-my-2\/3 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .lg\:-mx-2\/3 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .lg\:-my-1\/4 {
    margin-top: -25%;
    margin-bottom: -25%;
  }

  .lg\:-mx-1\/4 {
    margin-left: -25%;
    margin-right: -25%;
  }

  .lg\:-my-2\/4 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .lg\:-mx-2\/4 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .lg\:-my-3\/4 {
    margin-top: -75%;
    margin-bottom: -75%;
  }

  .lg\:-mx-3\/4 {
    margin-left: -75%;
    margin-right: -75%;
  }

  .lg\:-my-1\/5 {
    margin-top: -20%;
    margin-bottom: -20%;
  }

  .lg\:-mx-1\/5 {
    margin-left: -20%;
    margin-right: -20%;
  }

  .lg\:-my-2\/5 {
    margin-top: -40%;
    margin-bottom: -40%;
  }

  .lg\:-mx-2\/5 {
    margin-left: -40%;
    margin-right: -40%;
  }

  .lg\:-my-3\/5 {
    margin-top: -60%;
    margin-bottom: -60%;
  }

  .lg\:-mx-3\/5 {
    margin-left: -60%;
    margin-right: -60%;
  }

  .lg\:-my-4\/5 {
    margin-top: -80%;
    margin-bottom: -80%;
  }

  .lg\:-mx-4\/5 {
    margin-left: -80%;
    margin-right: -80%;
  }

  .lg\:-my-1\/6 {
    margin-top: -16.6667%;
    margin-bottom: -16.6667%;
  }

  .lg\:-mx-1\/6 {
    margin-left: -16.6667%;
    margin-right: -16.6667%;
  }

  .lg\:-my-2\/6 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .lg\:-mx-2\/6 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .lg\:-my-3\/6 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .lg\:-mx-3\/6 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .lg\:-my-4\/6 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .lg\:-mx-4\/6 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .lg\:-my-5\/6 {
    margin-top: -83.3333%;
    margin-bottom: -83.3333%;
  }

  .lg\:-mx-5\/6 {
    margin-left: -83.3333%;
    margin-right: -83.3333%;
  }

  .lg\:-my-1\/12 {
    margin-top: -8.33333%;
    margin-bottom: -8.33333%;
  }

  .lg\:-mx-1\/12 {
    margin-left: -8.33333%;
    margin-right: -8.33333%;
  }

  .lg\:-my-2\/12 {
    margin-top: -16.6667%;
    margin-bottom: -16.6667%;
  }

  .lg\:-mx-2\/12 {
    margin-left: -16.6667%;
    margin-right: -16.6667%;
  }

  .lg\:-my-3\/12 {
    margin-top: -25%;
    margin-bottom: -25%;
  }

  .lg\:-mx-3\/12 {
    margin-left: -25%;
    margin-right: -25%;
  }

  .lg\:-my-4\/12 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .lg\:-mx-4\/12 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .lg\:-my-5\/12 {
    margin-top: -41.6667%;
    margin-bottom: -41.6667%;
  }

  .lg\:-mx-5\/12 {
    margin-left: -41.6667%;
    margin-right: -41.6667%;
  }

  .lg\:-my-6\/12 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .lg\:-mx-6\/12 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .lg\:-my-7\/12 {
    margin-top: -58.3333%;
    margin-bottom: -58.3333%;
  }

  .lg\:-mx-7\/12 {
    margin-left: -58.3333%;
    margin-right: -58.3333%;
  }

  .lg\:-my-8\/12 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .lg\:-mx-8\/12 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .lg\:-my-9\/12 {
    margin-top: -75%;
    margin-bottom: -75%;
  }

  .lg\:-mx-9\/12 {
    margin-left: -75%;
    margin-right: -75%;
  }

  .lg\:-my-10\/12 {
    margin-top: -83.3333%;
    margin-bottom: -83.3333%;
  }

  .lg\:-mx-10\/12 {
    margin-left: -83.3333%;
    margin-right: -83.3333%;
  }

  .lg\:-my-11\/12 {
    margin-top: -91.6667%;
    margin-bottom: -91.6667%;
  }

  .lg\:-mx-11\/12 {
    margin-left: -91.6667%;
    margin-right: -91.6667%;
  }

  .lg\:-my-full {
    margin-top: -100%;
    margin-bottom: -100%;
  }

  .lg\:-mx-full {
    margin-left: -100%;
    margin-right: -100%;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mt-1 {
    margin-top: .25rem;
  }

  .lg\:mr-1 {
    margin-right: .25rem;
  }

  .lg\:mb-1 {
    margin-bottom: .25rem;
  }

  .lg\:ml-1 {
    margin-left: .25rem;
  }

  .lg\:mt-2 {
    margin-top: .5rem;
  }

  .lg\:mr-2 {
    margin-right: .5rem;
  }

  .lg\:mb-2 {
    margin-bottom: .5rem;
  }

  .lg\:ml-2 {
    margin-left: .5rem;
  }

  .lg\:mt-3 {
    margin-top: .75rem;
  }

  .lg\:mr-3 {
    margin-right: .75rem;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:ml-3 {
    margin-left: .75rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:ml-4 {
    margin-left: 1rem;
  }

  .lg\:mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:mt-7 {
    margin-top: 1.75rem;
  }

  .lg\:mr-7 {
    margin-right: 1.75rem;
  }

  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .lg\:ml-7 {
    margin-left: 1.75rem;
  }

  .lg\:mt-8 {
    margin-top: 2rem;
  }

  .lg\:mr-8 {
    margin-right: 2rem;
  }

  .lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mt-9 {
    margin-top: 2.25rem;
  }

  .lg\:mr-9 {
    margin-right: 2.25rem;
  }

  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .lg\:ml-9 {
    margin-left: 2.25rem;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:mt-11 {
    margin-top: 2.75rem;
  }

  .lg\:mr-11 {
    margin-right: 2.75rem;
  }

  .lg\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .lg\:ml-11 {
    margin-left: 2.75rem;
  }

  .lg\:mt-12 {
    margin-top: 3rem;
  }

  .lg\:mr-12 {
    margin-right: 3rem;
  }

  .lg\:mb-12 {
    margin-bottom: 3rem;
  }

  .lg\:ml-12 {
    margin-left: 3rem;
  }

  .lg\:mt-13 {
    margin-top: 3.25rem;
  }

  .lg\:mr-13 {
    margin-right: 3.25rem;
  }

  .lg\:mb-13 {
    margin-bottom: 3.25rem;
  }

  .lg\:ml-13 {
    margin-left: 3.25rem;
  }

  .lg\:mt-14 {
    margin-top: 3.5rem;
  }

  .lg\:mr-14 {
    margin-right: 3.5rem;
  }

  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .lg\:ml-14 {
    margin-left: 3.5rem;
  }

  .lg\:mt-15 {
    margin-top: 3.75rem;
  }

  .lg\:mr-15 {
    margin-right: 3.75rem;
  }

  .lg\:mb-15 {
    margin-bottom: 3.75rem;
  }

  .lg\:ml-15 {
    margin-left: 3.75rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:mr-16 {
    margin-right: 4rem;
  }

  .lg\:mb-16 {
    margin-bottom: 4rem;
  }

  .lg\:ml-16 {
    margin-left: 4rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mr-20 {
    margin-right: 5rem;
  }

  .lg\:mb-20 {
    margin-bottom: 5rem;
  }

  .lg\:ml-20 {
    margin-left: 5rem;
  }

  .lg\:mt-24 {
    margin-top: 6rem;
  }

  .lg\:mr-24 {
    margin-right: 6rem;
  }

  .lg\:mb-24 {
    margin-bottom: 6rem;
  }

  .lg\:ml-24 {
    margin-left: 6rem;
  }

  .lg\:mt-28 {
    margin-top: 7rem;
  }

  .lg\:mr-28 {
    margin-right: 7rem;
  }

  .lg\:mb-28 {
    margin-bottom: 7rem;
  }

  .lg\:ml-28 {
    margin-left: 7rem;
  }

  .lg\:mt-32 {
    margin-top: 8rem;
  }

  .lg\:mr-32 {
    margin-right: 8rem;
  }

  .lg\:mb-32 {
    margin-bottom: 8rem;
  }

  .lg\:ml-32 {
    margin-left: 8rem;
  }

  .lg\:mt-36 {
    margin-top: 9rem;
  }

  .lg\:mr-36 {
    margin-right: 9rem;
  }

  .lg\:mb-36 {
    margin-bottom: 9rem;
  }

  .lg\:ml-36 {
    margin-left: 9rem;
  }

  .lg\:mt-40 {
    margin-top: 10rem;
  }

  .lg\:mr-40 {
    margin-right: 10rem;
  }

  .lg\:mb-40 {
    margin-bottom: 10rem;
  }

  .lg\:ml-40 {
    margin-left: 10rem;
  }

  .lg\:mt-44 {
    margin-top: 11rem;
  }

  .lg\:mr-44 {
    margin-right: 11rem;
  }

  .lg\:mb-44 {
    margin-bottom: 11rem;
  }

  .lg\:ml-44 {
    margin-left: 11rem;
  }

  .lg\:mt-48 {
    margin-top: 12rem;
  }

  .lg\:mr-48 {
    margin-right: 12rem;
  }

  .lg\:mb-48 {
    margin-bottom: 12rem;
  }

  .lg\:ml-48 {
    margin-left: 12rem;
  }

  .lg\:mt-52 {
    margin-top: 13rem;
  }

  .lg\:mr-52 {
    margin-right: 13rem;
  }

  .lg\:mb-52 {
    margin-bottom: 13rem;
  }

  .lg\:ml-52 {
    margin-left: 13rem;
  }

  .lg\:mt-56 {
    margin-top: 14rem;
  }

  .lg\:mr-56 {
    margin-right: 14rem;
  }

  .lg\:mb-56 {
    margin-bottom: 14rem;
  }

  .lg\:ml-56 {
    margin-left: 14rem;
  }

  .lg\:mt-60 {
    margin-top: 15rem;
  }

  .lg\:mr-60 {
    margin-right: 15rem;
  }

  .lg\:mb-60 {
    margin-bottom: 15rem;
  }

  .lg\:ml-60 {
    margin-left: 15rem;
  }

  .lg\:mt-64 {
    margin-top: 16rem;
  }

  .lg\:mr-64 {
    margin-right: 16rem;
  }

  .lg\:mb-64 {
    margin-bottom: 16rem;
  }

  .lg\:ml-64 {
    margin-left: 16rem;
  }

  .lg\:mt-72 {
    margin-top: 18rem;
  }

  .lg\:mr-72 {
    margin-right: 18rem;
  }

  .lg\:mb-72 {
    margin-bottom: 18rem;
  }

  .lg\:ml-72 {
    margin-left: 18rem;
  }

  .lg\:mt-80 {
    margin-top: 20rem;
  }

  .lg\:mr-80 {
    margin-right: 20rem;
  }

  .lg\:mb-80 {
    margin-bottom: 20rem;
  }

  .lg\:ml-80 {
    margin-left: 20rem;
  }

  .lg\:mt-96 {
    margin-top: 24rem;
  }

  .lg\:mr-96 {
    margin-right: 24rem;
  }

  .lg\:mb-96 {
    margin-bottom: 24rem;
  }

  .lg\:ml-96 {
    margin-left: 24rem;
  }

  .lg\:mt-auto {
    margin-top: auto;
  }

  .lg\:mr-auto {
    margin-right: auto;
  }

  .lg\:mb-auto {
    margin-bottom: auto;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:mt-px {
    margin-top: 1px;
  }

  .lg\:mr-px {
    margin-right: 1px;
  }

  .lg\:mb-px {
    margin-bottom: 1px;
  }

  .lg\:ml-px {
    margin-left: 1px;
  }

  .lg\:mt-0\.5 {
    margin-top: .125rem;
  }

  .lg\:mr-0\.5 {
    margin-right: .125rem;
  }

  .lg\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .lg\:ml-0\.5 {
    margin-left: .125rem;
  }

  .lg\:mt-1\.5 {
    margin-top: .375rem;
  }

  .lg\:mr-1\.5 {
    margin-right: .375rem;
  }

  .lg\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .lg\:ml-1\.5 {
    margin-left: .375rem;
  }

  .lg\:mt-2\.5 {
    margin-top: .625rem;
  }

  .lg\:mr-2\.5 {
    margin-right: .625rem;
  }

  .lg\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .lg\:ml-2\.5 {
    margin-left: .625rem;
  }

  .lg\:mt-3\.5 {
    margin-top: .875rem;
  }

  .lg\:mr-3\.5 {
    margin-right: .875rem;
  }

  .lg\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .lg\:ml-3\.5 {
    margin-left: .875rem;
  }

  .lg\:mt-1\/2 {
    margin-top: 50%;
  }

  .lg\:mr-1\/2 {
    margin-right: 50%;
  }

  .lg\:mb-1\/2 {
    margin-bottom: 50%;
  }

  .lg\:ml-1\/2 {
    margin-left: 50%;
  }

  .lg\:mt-1\/3 {
    margin-top: 33.3333%;
  }

  .lg\:mr-1\/3 {
    margin-right: 33.3333%;
  }

  .lg\:mb-1\/3 {
    margin-bottom: 33.3333%;
  }

  .lg\:ml-1\/3 {
    margin-left: 33.3333%;
  }

  .lg\:mt-2\/3 {
    margin-top: 66.6667%;
  }

  .lg\:mr-2\/3 {
    margin-right: 66.6667%;
  }

  .lg\:mb-2\/3 {
    margin-bottom: 66.6667%;
  }

  .lg\:ml-2\/3 {
    margin-left: 66.6667%;
  }

  .lg\:mt-1\/4 {
    margin-top: 25%;
  }

  .lg\:mr-1\/4 {
    margin-right: 25%;
  }

  .lg\:mb-1\/4 {
    margin-bottom: 25%;
  }

  .lg\:ml-1\/4 {
    margin-left: 25%;
  }

  .lg\:mt-2\/4 {
    margin-top: 50%;
  }

  .lg\:mr-2\/4 {
    margin-right: 50%;
  }

  .lg\:mb-2\/4 {
    margin-bottom: 50%;
  }

  .lg\:ml-2\/4 {
    margin-left: 50%;
  }

  .lg\:mt-3\/4 {
    margin-top: 75%;
  }

  .lg\:mr-3\/4 {
    margin-right: 75%;
  }

  .lg\:mb-3\/4 {
    margin-bottom: 75%;
  }

  .lg\:ml-3\/4 {
    margin-left: 75%;
  }

  .lg\:mt-1\/5 {
    margin-top: 20%;
  }

  .lg\:mr-1\/5 {
    margin-right: 20%;
  }

  .lg\:mb-1\/5 {
    margin-bottom: 20%;
  }

  .lg\:ml-1\/5 {
    margin-left: 20%;
  }

  .lg\:mt-2\/5 {
    margin-top: 40%;
  }

  .lg\:mr-2\/5 {
    margin-right: 40%;
  }

  .lg\:mb-2\/5 {
    margin-bottom: 40%;
  }

  .lg\:ml-2\/5 {
    margin-left: 40%;
  }

  .lg\:mt-3\/5 {
    margin-top: 60%;
  }

  .lg\:mr-3\/5 {
    margin-right: 60%;
  }

  .lg\:mb-3\/5 {
    margin-bottom: 60%;
  }

  .lg\:ml-3\/5 {
    margin-left: 60%;
  }

  .lg\:mt-4\/5 {
    margin-top: 80%;
  }

  .lg\:mr-4\/5 {
    margin-right: 80%;
  }

  .lg\:mb-4\/5 {
    margin-bottom: 80%;
  }

  .lg\:ml-4\/5 {
    margin-left: 80%;
  }

  .lg\:mt-1\/6 {
    margin-top: 16.6667%;
  }

  .lg\:mr-1\/6 {
    margin-right: 16.6667%;
  }

  .lg\:mb-1\/6 {
    margin-bottom: 16.6667%;
  }

  .lg\:ml-1\/6 {
    margin-left: 16.6667%;
  }

  .lg\:mt-2\/6 {
    margin-top: 33.3333%;
  }

  .lg\:mr-2\/6 {
    margin-right: 33.3333%;
  }

  .lg\:mb-2\/6 {
    margin-bottom: 33.3333%;
  }

  .lg\:ml-2\/6 {
    margin-left: 33.3333%;
  }

  .lg\:mt-3\/6 {
    margin-top: 50%;
  }

  .lg\:mr-3\/6 {
    margin-right: 50%;
  }

  .lg\:mb-3\/6 {
    margin-bottom: 50%;
  }

  .lg\:ml-3\/6 {
    margin-left: 50%;
  }

  .lg\:mt-4\/6 {
    margin-top: 66.6667%;
  }

  .lg\:mr-4\/6 {
    margin-right: 66.6667%;
  }

  .lg\:mb-4\/6 {
    margin-bottom: 66.6667%;
  }

  .lg\:ml-4\/6 {
    margin-left: 66.6667%;
  }

  .lg\:mt-5\/6 {
    margin-top: 83.3333%;
  }

  .lg\:mr-5\/6 {
    margin-right: 83.3333%;
  }

  .lg\:mb-5\/6 {
    margin-bottom: 83.3333%;
  }

  .lg\:ml-5\/6 {
    margin-left: 83.3333%;
  }

  .lg\:mt-1\/12 {
    margin-top: 8.33333%;
  }

  .lg\:mr-1\/12 {
    margin-right: 8.33333%;
  }

  .lg\:mb-1\/12 {
    margin-bottom: 8.33333%;
  }

  .lg\:ml-1\/12 {
    margin-left: 8.33333%;
  }

  .lg\:mt-2\/12 {
    margin-top: 16.6667%;
  }

  .lg\:mr-2\/12 {
    margin-right: 16.6667%;
  }

  .lg\:mb-2\/12 {
    margin-bottom: 16.6667%;
  }

  .lg\:ml-2\/12 {
    margin-left: 16.6667%;
  }

  .lg\:mt-3\/12 {
    margin-top: 25%;
  }

  .lg\:mr-3\/12 {
    margin-right: 25%;
  }

  .lg\:mb-3\/12 {
    margin-bottom: 25%;
  }

  .lg\:ml-3\/12 {
    margin-left: 25%;
  }

  .lg\:mt-4\/12 {
    margin-top: 33.3333%;
  }

  .lg\:mr-4\/12 {
    margin-right: 33.3333%;
  }

  .lg\:mb-4\/12 {
    margin-bottom: 33.3333%;
  }

  .lg\:ml-4\/12 {
    margin-left: 33.3333%;
  }

  .lg\:mt-5\/12 {
    margin-top: 41.6667%;
  }

  .lg\:mr-5\/12 {
    margin-right: 41.6667%;
  }

  .lg\:mb-5\/12 {
    margin-bottom: 41.6667%;
  }

  .lg\:ml-5\/12 {
    margin-left: 41.6667%;
  }

  .lg\:mt-6\/12 {
    margin-top: 50%;
  }

  .lg\:mr-6\/12 {
    margin-right: 50%;
  }

  .lg\:mb-6\/12 {
    margin-bottom: 50%;
  }

  .lg\:ml-6\/12 {
    margin-left: 50%;
  }

  .lg\:mt-7\/12 {
    margin-top: 58.3333%;
  }

  .lg\:mr-7\/12 {
    margin-right: 58.3333%;
  }

  .lg\:mb-7\/12 {
    margin-bottom: 58.3333%;
  }

  .lg\:ml-7\/12 {
    margin-left: 58.3333%;
  }

  .lg\:mt-8\/12 {
    margin-top: 66.6667%;
  }

  .lg\:mr-8\/12 {
    margin-right: 66.6667%;
  }

  .lg\:mb-8\/12 {
    margin-bottom: 66.6667%;
  }

  .lg\:ml-8\/12 {
    margin-left: 66.6667%;
  }

  .lg\:mt-9\/12 {
    margin-top: 75%;
  }

  .lg\:mr-9\/12 {
    margin-right: 75%;
  }

  .lg\:mb-9\/12 {
    margin-bottom: 75%;
  }

  .lg\:ml-9\/12 {
    margin-left: 75%;
  }

  .lg\:mt-10\/12 {
    margin-top: 83.3333%;
  }

  .lg\:mr-10\/12 {
    margin-right: 83.3333%;
  }

  .lg\:mb-10\/12 {
    margin-bottom: 83.3333%;
  }

  .lg\:ml-10\/12 {
    margin-left: 83.3333%;
  }

  .lg\:mt-11\/12 {
    margin-top: 91.6667%;
  }

  .lg\:mr-11\/12 {
    margin-right: 91.6667%;
  }

  .lg\:mb-11\/12 {
    margin-bottom: 91.6667%;
  }

  .lg\:ml-11\/12 {
    margin-left: 91.6667%;
  }

  .lg\:mt-full {
    margin-top: 100%;
  }

  .lg\:mr-full {
    margin-right: 100%;
  }

  .lg\:mb-full {
    margin-bottom: 100%;
  }

  .lg\:ml-full {
    margin-left: 100%;
  }

  .lg\:-mt-1 {
    margin-top: -.25rem;
  }

  .lg\:-mr-1 {
    margin-right: -.25rem;
  }

  .lg\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .lg\:-ml-1 {
    margin-left: -.25rem;
  }

  .lg\:-mt-2 {
    margin-top: -.5rem;
  }

  .lg\:-mr-2 {
    margin-right: -.5rem;
  }

  .lg\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .lg\:-ml-2 {
    margin-left: -.5rem;
  }

  .lg\:-mt-3 {
    margin-top: -.75rem;
  }

  .lg\:-mr-3 {
    margin-right: -.75rem;
  }

  .lg\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .lg\:-ml-3 {
    margin-left: -.75rem;
  }

  .lg\:-mt-4 {
    margin-top: -1rem;
  }

  .lg\:-mr-4 {
    margin-right: -1rem;
  }

  .lg\:-mb-4 {
    margin-bottom: -1rem;
  }

  .lg\:-ml-4 {
    margin-left: -1rem;
  }

  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }

  .lg\:-mr-5 {
    margin-right: -1.25rem;
  }

  .lg\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:-mt-6 {
    margin-top: -1.5rem;
  }

  .lg\:-mr-6 {
    margin-right: -1.5rem;
  }

  .lg\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .lg\:-ml-6 {
    margin-left: -1.5rem;
  }

  .lg\:-mt-7 {
    margin-top: -1.75rem;
  }

  .lg\:-mr-7 {
    margin-right: -1.75rem;
  }

  .lg\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .lg\:-ml-7 {
    margin-left: -1.75rem;
  }

  .lg\:-mt-8 {
    margin-top: -2rem;
  }

  .lg\:-mr-8 {
    margin-right: -2rem;
  }

  .lg\:-mb-8 {
    margin-bottom: -2rem;
  }

  .lg\:-ml-8 {
    margin-left: -2rem;
  }

  .lg\:-mt-9 {
    margin-top: -2.25rem;
  }

  .lg\:-mr-9 {
    margin-right: -2.25rem;
  }

  .lg\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .lg\:-ml-9 {
    margin-left: -2.25rem;
  }

  .lg\:-mt-10 {
    margin-top: -2.5rem;
  }

  .lg\:-mr-10 {
    margin-right: -2.5rem;
  }

  .lg\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:-mt-11 {
    margin-top: -2.75rem;
  }

  .lg\:-mr-11 {
    margin-right: -2.75rem;
  }

  .lg\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .lg\:-ml-11 {
    margin-left: -2.75rem;
  }

  .lg\:-mt-12 {
    margin-top: -3rem;
  }

  .lg\:-mr-12 {
    margin-right: -3rem;
  }

  .lg\:-mb-12 {
    margin-bottom: -3rem;
  }

  .lg\:-ml-12 {
    margin-left: -3rem;
  }

  .lg\:-mt-13 {
    margin-top: -3.25rem;
  }

  .lg\:-mr-13 {
    margin-right: -3.25rem;
  }

  .lg\:-mb-13 {
    margin-bottom: -3.25rem;
  }

  .lg\:-ml-13 {
    margin-left: -3.25rem;
  }

  .lg\:-mt-14 {
    margin-top: -3.5rem;
  }

  .lg\:-mr-14 {
    margin-right: -3.5rem;
  }

  .lg\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .lg\:-ml-14 {
    margin-left: -3.5rem;
  }

  .lg\:-mt-15 {
    margin-top: -3.75rem;
  }

  .lg\:-mr-15 {
    margin-right: -3.75rem;
  }

  .lg\:-mb-15 {
    margin-bottom: -3.75rem;
  }

  .lg\:-ml-15 {
    margin-left: -3.75rem;
  }

  .lg\:-mt-16 {
    margin-top: -4rem;
  }

  .lg\:-mr-16 {
    margin-right: -4rem;
  }

  .lg\:-mb-16 {
    margin-bottom: -4rem;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-mt-20 {
    margin-top: -5rem;
  }

  .lg\:-mr-20 {
    margin-right: -5rem;
  }

  .lg\:-mb-20 {
    margin-bottom: -5rem;
  }

  .lg\:-ml-20 {
    margin-left: -5rem;
  }

  .lg\:-mt-24 {
    margin-top: -6rem;
  }

  .lg\:-mr-24 {
    margin-right: -6rem;
  }

  .lg\:-mb-24 {
    margin-bottom: -6rem;
  }

  .lg\:-ml-24 {
    margin-left: -6rem;
  }

  .lg\:-mt-28 {
    margin-top: -7rem;
  }

  .lg\:-mr-28 {
    margin-right: -7rem;
  }

  .lg\:-mb-28 {
    margin-bottom: -7rem;
  }

  .lg\:-ml-28 {
    margin-left: -7rem;
  }

  .lg\:-mt-32 {
    margin-top: -8rem;
  }

  .lg\:-mr-32 {
    margin-right: -8rem;
  }

  .lg\:-mb-32 {
    margin-bottom: -8rem;
  }

  .lg\:-ml-32 {
    margin-left: -8rem;
  }

  .lg\:-mt-36 {
    margin-top: -9rem;
  }

  .lg\:-mr-36 {
    margin-right: -9rem;
  }

  .lg\:-mb-36 {
    margin-bottom: -9rem;
  }

  .lg\:-ml-36 {
    margin-left: -9rem;
  }

  .lg\:-mt-40 {
    margin-top: -10rem;
  }

  .lg\:-mr-40 {
    margin-right: -10rem;
  }

  .lg\:-mb-40 {
    margin-bottom: -10rem;
  }

  .lg\:-ml-40 {
    margin-left: -10rem;
  }

  .lg\:-mt-44 {
    margin-top: -11rem;
  }

  .lg\:-mr-44 {
    margin-right: -11rem;
  }

  .lg\:-mb-44 {
    margin-bottom: -11rem;
  }

  .lg\:-ml-44 {
    margin-left: -11rem;
  }

  .lg\:-mt-48 {
    margin-top: -12rem;
  }

  .lg\:-mr-48 {
    margin-right: -12rem;
  }

  .lg\:-mb-48 {
    margin-bottom: -12rem;
  }

  .lg\:-ml-48 {
    margin-left: -12rem;
  }

  .lg\:-mt-52 {
    margin-top: -13rem;
  }

  .lg\:-mr-52 {
    margin-right: -13rem;
  }

  .lg\:-mb-52 {
    margin-bottom: -13rem;
  }

  .lg\:-ml-52 {
    margin-left: -13rem;
  }

  .lg\:-mt-56 {
    margin-top: -14rem;
  }

  .lg\:-mr-56 {
    margin-right: -14rem;
  }

  .lg\:-mb-56 {
    margin-bottom: -14rem;
  }

  .lg\:-ml-56 {
    margin-left: -14rem;
  }

  .lg\:-mt-60 {
    margin-top: -15rem;
  }

  .lg\:-mr-60 {
    margin-right: -15rem;
  }

  .lg\:-mb-60 {
    margin-bottom: -15rem;
  }

  .lg\:-ml-60 {
    margin-left: -15rem;
  }

  .lg\:-mt-64 {
    margin-top: -16rem;
  }

  .lg\:-mr-64 {
    margin-right: -16rem;
  }

  .lg\:-mb-64 {
    margin-bottom: -16rem;
  }

  .lg\:-ml-64 {
    margin-left: -16rem;
  }

  .lg\:-mt-72 {
    margin-top: -18rem;
  }

  .lg\:-mr-72 {
    margin-right: -18rem;
  }

  .lg\:-mb-72 {
    margin-bottom: -18rem;
  }

  .lg\:-ml-72 {
    margin-left: -18rem;
  }

  .lg\:-mt-80 {
    margin-top: -20rem;
  }

  .lg\:-mr-80 {
    margin-right: -20rem;
  }

  .lg\:-mb-80 {
    margin-bottom: -20rem;
  }

  .lg\:-ml-80 {
    margin-left: -20rem;
  }

  .lg\:-mt-96 {
    margin-top: -24rem;
  }

  .lg\:-mr-96 {
    margin-right: -24rem;
  }

  .lg\:-mb-96 {
    margin-bottom: -24rem;
  }

  .lg\:-ml-96 {
    margin-left: -24rem;
  }

  .lg\:-mt-px {
    margin-top: -1px;
  }

  .lg\:-mr-px {
    margin-right: -1px;
  }

  .lg\:-mb-px {
    margin-bottom: -1px;
  }

  .lg\:-ml-px {
    margin-left: -1px;
  }

  .lg\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .lg\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .lg\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .lg\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .lg\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .lg\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .lg\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .lg\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .lg\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .lg\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .lg\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .lg\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .lg\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .lg\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .lg\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .lg\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .lg\:-mt-1\/2 {
    margin-top: -50%;
  }

  .lg\:-mr-1\/2 {
    margin-right: -50%;
  }

  .lg\:-mb-1\/2 {
    margin-bottom: -50%;
  }

  .lg\:-ml-1\/2 {
    margin-left: -50%;
  }

  .lg\:-mt-1\/3 {
    margin-top: -33.3333%;
  }

  .lg\:-mr-1\/3 {
    margin-right: -33.3333%;
  }

  .lg\:-mb-1\/3 {
    margin-bottom: -33.3333%;
  }

  .lg\:-ml-1\/3 {
    margin-left: -33.3333%;
  }

  .lg\:-mt-2\/3 {
    margin-top: -66.6667%;
  }

  .lg\:-mr-2\/3 {
    margin-right: -66.6667%;
  }

  .lg\:-mb-2\/3 {
    margin-bottom: -66.6667%;
  }

  .lg\:-ml-2\/3 {
    margin-left: -66.6667%;
  }

  .lg\:-mt-1\/4 {
    margin-top: -25%;
  }

  .lg\:-mr-1\/4 {
    margin-right: -25%;
  }

  .lg\:-mb-1\/4 {
    margin-bottom: -25%;
  }

  .lg\:-ml-1\/4 {
    margin-left: -25%;
  }

  .lg\:-mt-2\/4 {
    margin-top: -50%;
  }

  .lg\:-mr-2\/4 {
    margin-right: -50%;
  }

  .lg\:-mb-2\/4 {
    margin-bottom: -50%;
  }

  .lg\:-ml-2\/4 {
    margin-left: -50%;
  }

  .lg\:-mt-3\/4 {
    margin-top: -75%;
  }

  .lg\:-mr-3\/4 {
    margin-right: -75%;
  }

  .lg\:-mb-3\/4 {
    margin-bottom: -75%;
  }

  .lg\:-ml-3\/4 {
    margin-left: -75%;
  }

  .lg\:-mt-1\/5 {
    margin-top: -20%;
  }

  .lg\:-mr-1\/5 {
    margin-right: -20%;
  }

  .lg\:-mb-1\/5 {
    margin-bottom: -20%;
  }

  .lg\:-ml-1\/5 {
    margin-left: -20%;
  }

  .lg\:-mt-2\/5 {
    margin-top: -40%;
  }

  .lg\:-mr-2\/5 {
    margin-right: -40%;
  }

  .lg\:-mb-2\/5 {
    margin-bottom: -40%;
  }

  .lg\:-ml-2\/5 {
    margin-left: -40%;
  }

  .lg\:-mt-3\/5 {
    margin-top: -60%;
  }

  .lg\:-mr-3\/5 {
    margin-right: -60%;
  }

  .lg\:-mb-3\/5 {
    margin-bottom: -60%;
  }

  .lg\:-ml-3\/5 {
    margin-left: -60%;
  }

  .lg\:-mt-4\/5 {
    margin-top: -80%;
  }

  .lg\:-mr-4\/5 {
    margin-right: -80%;
  }

  .lg\:-mb-4\/5 {
    margin-bottom: -80%;
  }

  .lg\:-ml-4\/5 {
    margin-left: -80%;
  }

  .lg\:-mt-1\/6 {
    margin-top: -16.6667%;
  }

  .lg\:-mr-1\/6 {
    margin-right: -16.6667%;
  }

  .lg\:-mb-1\/6 {
    margin-bottom: -16.6667%;
  }

  .lg\:-ml-1\/6 {
    margin-left: -16.6667%;
  }

  .lg\:-mt-2\/6 {
    margin-top: -33.3333%;
  }

  .lg\:-mr-2\/6 {
    margin-right: -33.3333%;
  }

  .lg\:-mb-2\/6 {
    margin-bottom: -33.3333%;
  }

  .lg\:-ml-2\/6 {
    margin-left: -33.3333%;
  }

  .lg\:-mt-3\/6 {
    margin-top: -50%;
  }

  .lg\:-mr-3\/6 {
    margin-right: -50%;
  }

  .lg\:-mb-3\/6 {
    margin-bottom: -50%;
  }

  .lg\:-ml-3\/6 {
    margin-left: -50%;
  }

  .lg\:-mt-4\/6 {
    margin-top: -66.6667%;
  }

  .lg\:-mr-4\/6 {
    margin-right: -66.6667%;
  }

  .lg\:-mb-4\/6 {
    margin-bottom: -66.6667%;
  }

  .lg\:-ml-4\/6 {
    margin-left: -66.6667%;
  }

  .lg\:-mt-5\/6 {
    margin-top: -83.3333%;
  }

  .lg\:-mr-5\/6 {
    margin-right: -83.3333%;
  }

  .lg\:-mb-5\/6 {
    margin-bottom: -83.3333%;
  }

  .lg\:-ml-5\/6 {
    margin-left: -83.3333%;
  }

  .lg\:-mt-1\/12 {
    margin-top: -8.33333%;
  }

  .lg\:-mr-1\/12 {
    margin-right: -8.33333%;
  }

  .lg\:-mb-1\/12 {
    margin-bottom: -8.33333%;
  }

  .lg\:-ml-1\/12 {
    margin-left: -8.33333%;
  }

  .lg\:-mt-2\/12 {
    margin-top: -16.6667%;
  }

  .lg\:-mr-2\/12 {
    margin-right: -16.6667%;
  }

  .lg\:-mb-2\/12 {
    margin-bottom: -16.6667%;
  }

  .lg\:-ml-2\/12 {
    margin-left: -16.6667%;
  }

  .lg\:-mt-3\/12 {
    margin-top: -25%;
  }

  .lg\:-mr-3\/12 {
    margin-right: -25%;
  }

  .lg\:-mb-3\/12 {
    margin-bottom: -25%;
  }

  .lg\:-ml-3\/12 {
    margin-left: -25%;
  }

  .lg\:-mt-4\/12 {
    margin-top: -33.3333%;
  }

  .lg\:-mr-4\/12 {
    margin-right: -33.3333%;
  }

  .lg\:-mb-4\/12 {
    margin-bottom: -33.3333%;
  }

  .lg\:-ml-4\/12 {
    margin-left: -33.3333%;
  }

  .lg\:-mt-5\/12 {
    margin-top: -41.6667%;
  }

  .lg\:-mr-5\/12 {
    margin-right: -41.6667%;
  }

  .lg\:-mb-5\/12 {
    margin-bottom: -41.6667%;
  }

  .lg\:-ml-5\/12 {
    margin-left: -41.6667%;
  }

  .lg\:-mt-6\/12 {
    margin-top: -50%;
  }

  .lg\:-mr-6\/12 {
    margin-right: -50%;
  }

  .lg\:-mb-6\/12 {
    margin-bottom: -50%;
  }

  .lg\:-ml-6\/12 {
    margin-left: -50%;
  }

  .lg\:-mt-7\/12 {
    margin-top: -58.3333%;
  }

  .lg\:-mr-7\/12 {
    margin-right: -58.3333%;
  }

  .lg\:-mb-7\/12 {
    margin-bottom: -58.3333%;
  }

  .lg\:-ml-7\/12 {
    margin-left: -58.3333%;
  }

  .lg\:-mt-8\/12 {
    margin-top: -66.6667%;
  }

  .lg\:-mr-8\/12 {
    margin-right: -66.6667%;
  }

  .lg\:-mb-8\/12 {
    margin-bottom: -66.6667%;
  }

  .lg\:-ml-8\/12 {
    margin-left: -66.6667%;
  }

  .lg\:-mt-9\/12 {
    margin-top: -75%;
  }

  .lg\:-mr-9\/12 {
    margin-right: -75%;
  }

  .lg\:-mb-9\/12 {
    margin-bottom: -75%;
  }

  .lg\:-ml-9\/12 {
    margin-left: -75%;
  }

  .lg\:-mt-10\/12 {
    margin-top: -83.3333%;
  }

  .lg\:-mr-10\/12 {
    margin-right: -83.3333%;
  }

  .lg\:-mb-10\/12 {
    margin-bottom: -83.3333%;
  }

  .lg\:-ml-10\/12 {
    margin-left: -83.3333%;
  }

  .lg\:-mt-11\/12 {
    margin-top: -91.6667%;
  }

  .lg\:-mr-11\/12 {
    margin-right: -91.6667%;
  }

  .lg\:-mb-11\/12 {
    margin-bottom: -91.6667%;
  }

  .lg\:-ml-11\/12 {
    margin-left: -91.6667%;
  }

  .lg\:-mt-full {
    margin-top: -100%;
  }

  .lg\:-mr-full {
    margin-right: -100%;
  }

  .lg\:-mb-full {
    margin-bottom: -100%;
  }

  .lg\:-ml-full {
    margin-left: -100%;
  }

  .lg\:max-h-0 {
    max-height: 0;
  }

  .lg\:max-h-1 {
    max-height: .25rem;
  }

  .lg\:max-h-2 {
    max-height: .5rem;
  }

  .lg\:max-h-3 {
    max-height: .75rem;
  }

  .lg\:max-h-4 {
    max-height: 1rem;
  }

  .lg\:max-h-5 {
    max-height: 1.25rem;
  }

  .lg\:max-h-6 {
    max-height: 1.5rem;
  }

  .lg\:max-h-7 {
    max-height: 1.75rem;
  }

  .lg\:max-h-8 {
    max-height: 2rem;
  }

  .lg\:max-h-9 {
    max-height: 2.25rem;
  }

  .lg\:max-h-10 {
    max-height: 2.5rem;
  }

  .lg\:max-h-11 {
    max-height: 2.75rem;
  }

  .lg\:max-h-12 {
    max-height: 3rem;
  }

  .lg\:max-h-13 {
    max-height: 3.25rem;
  }

  .lg\:max-h-14 {
    max-height: 3.5rem;
  }

  .lg\:max-h-15 {
    max-height: 3.75rem;
  }

  .lg\:max-h-16 {
    max-height: 4rem;
  }

  .lg\:max-h-20 {
    max-height: 5rem;
  }

  .lg\:max-h-24 {
    max-height: 6rem;
  }

  .lg\:max-h-28 {
    max-height: 7rem;
  }

  .lg\:max-h-32 {
    max-height: 8rem;
  }

  .lg\:max-h-36 {
    max-height: 9rem;
  }

  .lg\:max-h-40 {
    max-height: 10rem;
  }

  .lg\:max-h-44 {
    max-height: 11rem;
  }

  .lg\:max-h-48 {
    max-height: 12rem;
  }

  .lg\:max-h-52 {
    max-height: 13rem;
  }

  .lg\:max-h-56 {
    max-height: 14rem;
  }

  .lg\:max-h-60 {
    max-height: 15rem;
  }

  .lg\:max-h-64 {
    max-height: 16rem;
  }

  .lg\:max-h-72 {
    max-height: 18rem;
  }

  .lg\:max-h-80 {
    max-height: 20rem;
  }

  .lg\:max-h-96 {
    max-height: 24rem;
  }

  .lg\:max-h-screen {
    max-height: 100vh;
  }

  .lg\:max-h-px {
    max-height: 1px;
  }

  .lg\:max-h-0\.5 {
    max-height: .125rem;
  }

  .lg\:max-h-1\.5 {
    max-height: .375rem;
  }

  .lg\:max-h-2\.5 {
    max-height: .625rem;
  }

  .lg\:max-h-3\.5 {
    max-height: .875rem;
  }

  .lg\:max-h-1\/2 {
    max-height: 50%;
  }

  .lg\:max-h-1\/3 {
    max-height: 33.3333%;
  }

  .lg\:max-h-2\/3 {
    max-height: 66.6667%;
  }

  .lg\:max-h-1\/4 {
    max-height: 25%;
  }

  .lg\:max-h-2\/4 {
    max-height: 50%;
  }

  .lg\:max-h-3\/4 {
    max-height: 75%;
  }

  .lg\:max-h-1\/5 {
    max-height: 20%;
  }

  .lg\:max-h-2\/5 {
    max-height: 40%;
  }

  .lg\:max-h-3\/5 {
    max-height: 60%;
  }

  .lg\:max-h-4\/5 {
    max-height: 80%;
  }

  .lg\:max-h-1\/6 {
    max-height: 16.6667%;
  }

  .lg\:max-h-2\/6 {
    max-height: 33.3333%;
  }

  .lg\:max-h-3\/6 {
    max-height: 50%;
  }

  .lg\:max-h-4\/6 {
    max-height: 66.6667%;
  }

  .lg\:max-h-5\/6 {
    max-height: 83.3333%;
  }

  .lg\:max-h-1\/12 {
    max-height: 8.33333%;
  }

  .lg\:max-h-2\/12 {
    max-height: 16.6667%;
  }

  .lg\:max-h-3\/12 {
    max-height: 25%;
  }

  .lg\:max-h-4\/12 {
    max-height: 33.3333%;
  }

  .lg\:max-h-5\/12 {
    max-height: 41.6667%;
  }

  .lg\:max-h-6\/12 {
    max-height: 50%;
  }

  .lg\:max-h-7\/12 {
    max-height: 58.3333%;
  }

  .lg\:max-h-8\/12 {
    max-height: 66.6667%;
  }

  .lg\:max-h-9\/12 {
    max-height: 75%;
  }

  .lg\:max-h-10\/12 {
    max-height: 83.3333%;
  }

  .lg\:max-h-11\/12 {
    max-height: 91.6667%;
  }

  .lg\:max-h-full {
    max-height: 100%;
  }

  .lg\:max-w-none {
    max-width: none;
  }

  .lg\:max-w-xs {
    max-width: 20rem;
  }

  .lg\:max-w-sm {
    max-width: 24rem;
  }

  .lg\:max-w-md {
    max-width: 28rem;
  }

  .lg\:max-w-lg {
    max-width: 32rem;
  }

  .lg\:max-w-xl {
    max-width: 36rem;
  }

  .lg\:max-w-2xl {
    max-width: 42rem;
  }

  .lg\:max-w-3xl {
    max-width: 48rem;
  }

  .lg\:max-w-4xl {
    max-width: 56rem;
  }

  .lg\:max-w-5xl {
    max-width: 64rem;
  }

  .lg\:max-w-6xl {
    max-width: 72rem;
  }

  .lg\:max-w-7xl {
    max-width: 80rem;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-min-content {
    max-width: min-content;
  }

  .lg\:max-w-max-content {
    max-width: max-content;
  }

  .lg\:max-w-screen-sm {
    max-width: 640px;
  }

  .lg\:max-w-screen-md {
    max-width: 768px;
  }

  .lg\:max-w-screen-lg {
    max-width: 1024px;
  }

  .lg\:max-w-screen-xl {
    max-width: 1280px;
  }

  .lg\:min-h-0 {
    min-height: 0;
  }

  .lg\:min-h-full {
    min-height: 100%;
  }

  .lg\:min-h-screen {
    min-height: 100vh;
  }

  .lg\:min-w-0 {
    min-width: 0;
  }

  .lg\:min-w-full {
    min-width: 100%;
  }

  .lg\:min-w-min-content {
    min-width: min-content;
  }

  .lg\:min-w-max-content {
    min-width: max-content;
  }

  .lg\:object-contain {
    object-fit: contain;
  }

  .lg\:object-cover {
    object-fit: cover;
  }

  .lg\:object-fill {
    object-fit: fill;
  }

  .lg\:object-none {
    object-fit: none;
  }

  .lg\:object-scale-down {
    object-fit: scale-down;
  }

  .lg\:object-bottom {
    object-position: bottom;
  }

  .lg\:object-center {
    object-position: center;
  }

  .lg\:object-left {
    object-position: left;
  }

  .lg\:object-left-bottom {
    object-position: left bottom;
  }

  .lg\:object-left-top {
    object-position: left top;
  }

  .lg\:object-right {
    object-position: right;
  }

  .lg\:object-right-bottom {
    object-position: right bottom;
  }

  .lg\:object-right-top {
    object-position: right top;
  }

  .lg\:object-top {
    object-position: top;
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:opacity-25 {
    opacity: .25;
  }

  .lg\:opacity-50 {
    opacity: .5;
  }

  .lg\:opacity-75 {
    opacity: .75;
  }

  .lg\:opacity-100 {
    opacity: 1;
  }

  .lg\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .lg\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .lg\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .lg\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .lg\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .lg\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .lg\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .lg\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .lg\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .lg\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .lg\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .lg\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .lg\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .lg\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .lg\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .lg\:overflow-auto {
    overflow: auto;
  }

  .lg\:overflow-hidden {
    overflow: hidden;
  }

  .lg\:overflow-visible {
    overflow: visible;
  }

  .lg\:overflow-scroll {
    overflow: scroll;
  }

  .lg\:overflow-x-auto {
    overflow-x: auto;
  }

  .lg\:overflow-y-auto {
    overflow-y: auto;
  }

  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:overflow-y-visible {
    overflow-y: visible;
  }

  .lg\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .lg\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .lg\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .lg\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .lg\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .lg\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .lg\:overscroll-none {
    overscroll-behavior: none;
  }

  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .lg\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .lg\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-1 {
    padding: .25rem;
  }

  .lg\:p-2 {
    padding: .5rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:p-6 {
    padding: 1.5rem;
  }

  .lg\:p-7 {
    padding: 1.75rem;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-9 {
    padding: 2.25rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-11 {
    padding: 2.75rem;
  }

  .lg\:p-12 {
    padding: 3rem;
  }

  .lg\:p-13 {
    padding: 3.25rem;
  }

  .lg\:p-14 {
    padding: 3.5rem;
  }

  .lg\:p-15 {
    padding: 3.75rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:p-24 {
    padding: 6rem;
  }

  .lg\:p-28 {
    padding: 7rem;
  }

  .lg\:p-32 {
    padding: 8rem;
  }

  .lg\:p-36 {
    padding: 9rem;
  }

  .lg\:p-40 {
    padding: 10rem;
  }

  .lg\:p-44 {
    padding: 11rem;
  }

  .lg\:p-48 {
    padding: 12rem;
  }

  .lg\:p-52 {
    padding: 13rem;
  }

  .lg\:p-56 {
    padding: 14rem;
  }

  .lg\:p-60 {
    padding: 15rem;
  }

  .lg\:p-64 {
    padding: 16rem;
  }

  .lg\:p-72 {
    padding: 18rem;
  }

  .lg\:p-80 {
    padding: 20rem;
  }

  .lg\:p-96 {
    padding: 24rem;
  }

  .lg\:p-px {
    padding: 1px;
  }

  .lg\:p-0\.5 {
    padding: .125rem;
  }

  .lg\:p-1\.5 {
    padding: .375rem;
  }

  .lg\:p-2\.5 {
    padding: .625rem;
  }

  .lg\:p-3\.5 {
    padding: .875rem;
  }

  .lg\:p-1\/2 {
    padding: 50%;
  }

  .lg\:p-1\/3 {
    padding: 33.3333%;
  }

  .lg\:p-2\/3 {
    padding: 66.6667%;
  }

  .lg\:p-1\/4 {
    padding: 25%;
  }

  .lg\:p-2\/4 {
    padding: 50%;
  }

  .lg\:p-3\/4 {
    padding: 75%;
  }

  .lg\:p-1\/5 {
    padding: 20%;
  }

  .lg\:p-2\/5 {
    padding: 40%;
  }

  .lg\:p-3\/5 {
    padding: 60%;
  }

  .lg\:p-4\/5 {
    padding: 80%;
  }

  .lg\:p-1\/6 {
    padding: 16.6667%;
  }

  .lg\:p-2\/6 {
    padding: 33.3333%;
  }

  .lg\:p-3\/6 {
    padding: 50%;
  }

  .lg\:p-4\/6 {
    padding: 66.6667%;
  }

  .lg\:p-5\/6 {
    padding: 83.3333%;
  }

  .lg\:p-1\/12 {
    padding: 8.33333%;
  }

  .lg\:p-2\/12 {
    padding: 16.6667%;
  }

  .lg\:p-3\/12 {
    padding: 25%;
  }

  .lg\:p-4\/12 {
    padding: 33.3333%;
  }

  .lg\:p-5\/12 {
    padding: 41.6667%;
  }

  .lg\:p-6\/12 {
    padding: 50%;
  }

  .lg\:p-7\/12 {
    padding: 58.3333%;
  }

  .lg\:p-8\/12 {
    padding: 66.6667%;
  }

  .lg\:p-9\/12 {
    padding: 75%;
  }

  .lg\:p-10\/12 {
    padding: 83.3333%;
  }

  .lg\:p-11\/12 {
    padding: 91.6667%;
  }

  .lg\:p-full {
    padding: 100%;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .lg\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .lg\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .lg\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .lg\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .lg\:py-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem;
  }

  .lg\:px-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }

  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .lg\:py-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .lg\:px-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .lg\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .lg\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .lg\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .lg\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .lg\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .lg\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .lg\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .lg\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .lg\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .lg\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .lg\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .lg\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .lg\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .lg\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .lg\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .lg\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .lg\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .lg\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .lg\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .lg\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .lg\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .lg\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .lg\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .lg\:py-1\/2 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .lg\:px-1\/2 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .lg\:py-1\/3 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .lg\:px-1\/3 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .lg\:py-2\/3 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .lg\:px-2\/3 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .lg\:py-1\/4 {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .lg\:px-1\/4 {
    padding-left: 25%;
    padding-right: 25%;
  }

  .lg\:py-2\/4 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .lg\:px-2\/4 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .lg\:py-3\/4 {
    padding-top: 75%;
    padding-bottom: 75%;
  }

  .lg\:px-3\/4 {
    padding-left: 75%;
    padding-right: 75%;
  }

  .lg\:py-1\/5 {
    padding-top: 20%;
    padding-bottom: 20%;
  }

  .lg\:px-1\/5 {
    padding-left: 20%;
    padding-right: 20%;
  }

  .lg\:py-2\/5 {
    padding-top: 40%;
    padding-bottom: 40%;
  }

  .lg\:px-2\/5 {
    padding-left: 40%;
    padding-right: 40%;
  }

  .lg\:py-3\/5 {
    padding-top: 60%;
    padding-bottom: 60%;
  }

  .lg\:px-3\/5 {
    padding-left: 60%;
    padding-right: 60%;
  }

  .lg\:py-4\/5 {
    padding-top: 80%;
    padding-bottom: 80%;
  }

  .lg\:px-4\/5 {
    padding-left: 80%;
    padding-right: 80%;
  }

  .lg\:py-1\/6 {
    padding-top: 16.6667%;
    padding-bottom: 16.6667%;
  }

  .lg\:px-1\/6 {
    padding-left: 16.6667%;
    padding-right: 16.6667%;
  }

  .lg\:py-2\/6 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .lg\:px-2\/6 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .lg\:py-3\/6 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .lg\:px-3\/6 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .lg\:py-4\/6 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .lg\:px-4\/6 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .lg\:py-5\/6 {
    padding-top: 83.3333%;
    padding-bottom: 83.3333%;
  }

  .lg\:px-5\/6 {
    padding-left: 83.3333%;
    padding-right: 83.3333%;
  }

  .lg\:py-1\/12 {
    padding-top: 8.33333%;
    padding-bottom: 8.33333%;
  }

  .lg\:px-1\/12 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }

  .lg\:py-2\/12 {
    padding-top: 16.6667%;
    padding-bottom: 16.6667%;
  }

  .lg\:px-2\/12 {
    padding-left: 16.6667%;
    padding-right: 16.6667%;
  }

  .lg\:py-3\/12 {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .lg\:px-3\/12 {
    padding-left: 25%;
    padding-right: 25%;
  }

  .lg\:py-4\/12 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .lg\:px-4\/12 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .lg\:py-5\/12 {
    padding-top: 41.6667%;
    padding-bottom: 41.6667%;
  }

  .lg\:px-5\/12 {
    padding-left: 41.6667%;
    padding-right: 41.6667%;
  }

  .lg\:py-6\/12 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .lg\:px-6\/12 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .lg\:py-7\/12 {
    padding-top: 58.3333%;
    padding-bottom: 58.3333%;
  }

  .lg\:px-7\/12 {
    padding-left: 58.3333%;
    padding-right: 58.3333%;
  }

  .lg\:py-8\/12 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .lg\:px-8\/12 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .lg\:py-9\/12 {
    padding-top: 75%;
    padding-bottom: 75%;
  }

  .lg\:px-9\/12 {
    padding-left: 75%;
    padding-right: 75%;
  }

  .lg\:py-10\/12 {
    padding-top: 83.3333%;
    padding-bottom: 83.3333%;
  }

  .lg\:px-10\/12 {
    padding-left: 83.3333%;
    padding-right: 83.3333%;
  }

  .lg\:py-11\/12 {
    padding-top: 91.6667%;
    padding-bottom: 91.6667%;
  }

  .lg\:px-11\/12 {
    padding-left: 91.6667%;
    padding-right: 91.6667%;
  }

  .lg\:py-full {
    padding-top: 100%;
    padding-bottom: 100%;
  }

  .lg\:px-full {
    padding-left: 100%;
    padding-right: 100%;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pt-1 {
    padding-top: .25rem;
  }

  .lg\:pr-1 {
    padding-right: .25rem;
  }

  .lg\:pb-1 {
    padding-bottom: .25rem;
  }

  .lg\:pl-1 {
    padding-left: .25rem;
  }

  .lg\:pt-2 {
    padding-top: .5rem;
  }

  .lg\:pr-2 {
    padding-right: .5rem;
  }

  .lg\:pb-2 {
    padding-bottom: .5rem;
  }

  .lg\:pl-2 {
    padding-left: .5rem;
  }

  .lg\:pt-3 {
    padding-top: .75rem;
  }

  .lg\:pr-3 {
    padding-right: .75rem;
  }

  .lg\:pb-3 {
    padding-bottom: .75rem;
  }

  .lg\:pl-3 {
    padding-left: .75rem;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:pl-4 {
    padding-left: 1rem;
  }

  .lg\:pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:pt-7 {
    padding-top: 1.75rem;
  }

  .lg\:pr-7 {
    padding-right: 1.75rem;
  }

  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .lg\:pl-7 {
    padding-left: 1.75rem;
  }

  .lg\:pt-8 {
    padding-top: 2rem;
  }

  .lg\:pr-8 {
    padding-right: 2rem;
  }

  .lg\:pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:pl-8 {
    padding-left: 2rem;
  }

  .lg\:pt-9 {
    padding-top: 2.25rem;
  }

  .lg\:pr-9 {
    padding-right: 2.25rem;
  }

  .lg\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .lg\:pl-9 {
    padding-left: 2.25rem;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:pt-11 {
    padding-top: 2.75rem;
  }

  .lg\:pr-11 {
    padding-right: 2.75rem;
  }

  .lg\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .lg\:pl-11 {
    padding-left: 2.75rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pr-12 {
    padding-right: 3rem;
  }

  .lg\:pb-12 {
    padding-bottom: 3rem;
  }

  .lg\:pl-12 {
    padding-left: 3rem;
  }

  .lg\:pt-13 {
    padding-top: 3.25rem;
  }

  .lg\:pr-13 {
    padding-right: 3.25rem;
  }

  .lg\:pb-13 {
    padding-bottom: 3.25rem;
  }

  .lg\:pl-13 {
    padding-left: 3.25rem;
  }

  .lg\:pt-14 {
    padding-top: 3.5rem;
  }

  .lg\:pr-14 {
    padding-right: 3.5rem;
  }

  .lg\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .lg\:pl-14 {
    padding-left: 3.5rem;
  }

  .lg\:pt-15 {
    padding-top: 3.75rem;
  }

  .lg\:pr-15 {
    padding-right: 3.75rem;
  }

  .lg\:pb-15 {
    padding-bottom: 3.75rem;
  }

  .lg\:pl-15 {
    padding-left: 3.75rem;
  }

  .lg\:pt-16 {
    padding-top: 4rem;
  }

  .lg\:pr-16 {
    padding-right: 4rem;
  }

  .lg\:pb-16 {
    padding-bottom: 4rem;
  }

  .lg\:pl-16 {
    padding-left: 4rem;
  }

  .lg\:pt-20 {
    padding-top: 5rem;
  }

  .lg\:pr-20 {
    padding-right: 5rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:pl-20 {
    padding-left: 5rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pr-24 {
    padding-right: 6rem;
  }

  .lg\:pb-24 {
    padding-bottom: 6rem;
  }

  .lg\:pl-24 {
    padding-left: 6rem;
  }

  .lg\:pt-28 {
    padding-top: 7rem;
  }

  .lg\:pr-28 {
    padding-right: 7rem;
  }

  .lg\:pb-28 {
    padding-bottom: 7rem;
  }

  .lg\:pl-28 {
    padding-left: 7rem;
  }

  .lg\:pt-32 {
    padding-top: 8rem;
  }

  .lg\:pr-32 {
    padding-right: 8rem;
  }

  .lg\:pb-32 {
    padding-bottom: 8rem;
  }

  .lg\:pl-32 {
    padding-left: 8rem;
  }

  .lg\:pt-36 {
    padding-top: 9rem;
  }

  .lg\:pr-36 {
    padding-right: 9rem;
  }

  .lg\:pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:pl-36 {
    padding-left: 9rem;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pr-40 {
    padding-right: 10rem;
  }

  .lg\:pb-40 {
    padding-bottom: 10rem;
  }

  .lg\:pl-40 {
    padding-left: 10rem;
  }

  .lg\:pt-44 {
    padding-top: 11rem;
  }

  .lg\:pr-44 {
    padding-right: 11rem;
  }

  .lg\:pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:pl-44 {
    padding-left: 11rem;
  }

  .lg\:pt-48 {
    padding-top: 12rem;
  }

  .lg\:pr-48 {
    padding-right: 12rem;
  }

  .lg\:pb-48 {
    padding-bottom: 12rem;
  }

  .lg\:pl-48 {
    padding-left: 12rem;
  }

  .lg\:pt-52 {
    padding-top: 13rem;
  }

  .lg\:pr-52 {
    padding-right: 13rem;
  }

  .lg\:pb-52 {
    padding-bottom: 13rem;
  }

  .lg\:pl-52 {
    padding-left: 13rem;
  }

  .lg\:pt-56 {
    padding-top: 14rem;
  }

  .lg\:pr-56 {
    padding-right: 14rem;
  }

  .lg\:pb-56 {
    padding-bottom: 14rem;
  }

  .lg\:pl-56 {
    padding-left: 14rem;
  }

  .lg\:pt-60 {
    padding-top: 15rem;
  }

  .lg\:pr-60 {
    padding-right: 15rem;
  }

  .lg\:pb-60 {
    padding-bottom: 15rem;
  }

  .lg\:pl-60 {
    padding-left: 15rem;
  }

  .lg\:pt-64 {
    padding-top: 16rem;
  }

  .lg\:pr-64 {
    padding-right: 16rem;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:pl-64 {
    padding-left: 16rem;
  }

  .lg\:pt-72 {
    padding-top: 18rem;
  }

  .lg\:pr-72 {
    padding-right: 18rem;
  }

  .lg\:pb-72 {
    padding-bottom: 18rem;
  }

  .lg\:pl-72 {
    padding-left: 18rem;
  }

  .lg\:pt-80 {
    padding-top: 20rem;
  }

  .lg\:pr-80 {
    padding-right: 20rem;
  }

  .lg\:pb-80 {
    padding-bottom: 20rem;
  }

  .lg\:pl-80 {
    padding-left: 20rem;
  }

  .lg\:pt-96 {
    padding-top: 24rem;
  }

  .lg\:pr-96 {
    padding-right: 24rem;
  }

  .lg\:pb-96 {
    padding-bottom: 24rem;
  }

  .lg\:pl-96 {
    padding-left: 24rem;
  }

  .lg\:pt-px {
    padding-top: 1px;
  }

  .lg\:pr-px {
    padding-right: 1px;
  }

  .lg\:pb-px {
    padding-bottom: 1px;
  }

  .lg\:pl-px {
    padding-left: 1px;
  }

  .lg\:pt-0\.5 {
    padding-top: .125rem;
  }

  .lg\:pr-0\.5 {
    padding-right: .125rem;
  }

  .lg\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .lg\:pl-0\.5 {
    padding-left: .125rem;
  }

  .lg\:pt-1\.5 {
    padding-top: .375rem;
  }

  .lg\:pr-1\.5 {
    padding-right: .375rem;
  }

  .lg\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .lg\:pl-1\.5 {
    padding-left: .375rem;
  }

  .lg\:pt-2\.5 {
    padding-top: .625rem;
  }

  .lg\:pr-2\.5 {
    padding-right: .625rem;
  }

  .lg\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .lg\:pl-2\.5 {
    padding-left: .625rem;
  }

  .lg\:pt-3\.5 {
    padding-top: .875rem;
  }

  .lg\:pr-3\.5 {
    padding-right: .875rem;
  }

  .lg\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .lg\:pl-3\.5 {
    padding-left: .875rem;
  }

  .lg\:pt-1\/2 {
    padding-top: 50%;
  }

  .lg\:pr-1\/2 {
    padding-right: 50%;
  }

  .lg\:pb-1\/2 {
    padding-bottom: 50%;
  }

  .lg\:pl-1\/2 {
    padding-left: 50%;
  }

  .lg\:pt-1\/3 {
    padding-top: 33.3333%;
  }

  .lg\:pr-1\/3 {
    padding-right: 33.3333%;
  }

  .lg\:pb-1\/3 {
    padding-bottom: 33.3333%;
  }

  .lg\:pl-1\/3 {
    padding-left: 33.3333%;
  }

  .lg\:pt-2\/3 {
    padding-top: 66.6667%;
  }

  .lg\:pr-2\/3 {
    padding-right: 66.6667%;
  }

  .lg\:pb-2\/3 {
    padding-bottom: 66.6667%;
  }

  .lg\:pl-2\/3 {
    padding-left: 66.6667%;
  }

  .lg\:pt-1\/4 {
    padding-top: 25%;
  }

  .lg\:pr-1\/4 {
    padding-right: 25%;
  }

  .lg\:pb-1\/4 {
    padding-bottom: 25%;
  }

  .lg\:pl-1\/4 {
    padding-left: 25%;
  }

  .lg\:pt-2\/4 {
    padding-top: 50%;
  }

  .lg\:pr-2\/4 {
    padding-right: 50%;
  }

  .lg\:pb-2\/4 {
    padding-bottom: 50%;
  }

  .lg\:pl-2\/4 {
    padding-left: 50%;
  }

  .lg\:pt-3\/4 {
    padding-top: 75%;
  }

  .lg\:pr-3\/4 {
    padding-right: 75%;
  }

  .lg\:pb-3\/4 {
    padding-bottom: 75%;
  }

  .lg\:pl-3\/4 {
    padding-left: 75%;
  }

  .lg\:pt-1\/5 {
    padding-top: 20%;
  }

  .lg\:pr-1\/5 {
    padding-right: 20%;
  }

  .lg\:pb-1\/5 {
    padding-bottom: 20%;
  }

  .lg\:pl-1\/5 {
    padding-left: 20%;
  }

  .lg\:pt-2\/5 {
    padding-top: 40%;
  }

  .lg\:pr-2\/5 {
    padding-right: 40%;
  }

  .lg\:pb-2\/5 {
    padding-bottom: 40%;
  }

  .lg\:pl-2\/5 {
    padding-left: 40%;
  }

  .lg\:pt-3\/5 {
    padding-top: 60%;
  }

  .lg\:pr-3\/5 {
    padding-right: 60%;
  }

  .lg\:pb-3\/5 {
    padding-bottom: 60%;
  }

  .lg\:pl-3\/5 {
    padding-left: 60%;
  }

  .lg\:pt-4\/5 {
    padding-top: 80%;
  }

  .lg\:pr-4\/5 {
    padding-right: 80%;
  }

  .lg\:pb-4\/5 {
    padding-bottom: 80%;
  }

  .lg\:pl-4\/5 {
    padding-left: 80%;
  }

  .lg\:pt-1\/6 {
    padding-top: 16.6667%;
  }

  .lg\:pr-1\/6 {
    padding-right: 16.6667%;
  }

  .lg\:pb-1\/6 {
    padding-bottom: 16.6667%;
  }

  .lg\:pl-1\/6 {
    padding-left: 16.6667%;
  }

  .lg\:pt-2\/6 {
    padding-top: 33.3333%;
  }

  .lg\:pr-2\/6 {
    padding-right: 33.3333%;
  }

  .lg\:pb-2\/6 {
    padding-bottom: 33.3333%;
  }

  .lg\:pl-2\/6 {
    padding-left: 33.3333%;
  }

  .lg\:pt-3\/6 {
    padding-top: 50%;
  }

  .lg\:pr-3\/6 {
    padding-right: 50%;
  }

  .lg\:pb-3\/6 {
    padding-bottom: 50%;
  }

  .lg\:pl-3\/6 {
    padding-left: 50%;
  }

  .lg\:pt-4\/6 {
    padding-top: 66.6667%;
  }

  .lg\:pr-4\/6 {
    padding-right: 66.6667%;
  }

  .lg\:pb-4\/6 {
    padding-bottom: 66.6667%;
  }

  .lg\:pl-4\/6 {
    padding-left: 66.6667%;
  }

  .lg\:pt-5\/6 {
    padding-top: 83.3333%;
  }

  .lg\:pr-5\/6 {
    padding-right: 83.3333%;
  }

  .lg\:pb-5\/6 {
    padding-bottom: 83.3333%;
  }

  .lg\:pl-5\/6 {
    padding-left: 83.3333%;
  }

  .lg\:pt-1\/12 {
    padding-top: 8.33333%;
  }

  .lg\:pr-1\/12 {
    padding-right: 8.33333%;
  }

  .lg\:pb-1\/12 {
    padding-bottom: 8.33333%;
  }

  .lg\:pl-1\/12 {
    padding-left: 8.33333%;
  }

  .lg\:pt-2\/12 {
    padding-top: 16.6667%;
  }

  .lg\:pr-2\/12 {
    padding-right: 16.6667%;
  }

  .lg\:pb-2\/12 {
    padding-bottom: 16.6667%;
  }

  .lg\:pl-2\/12 {
    padding-left: 16.6667%;
  }

  .lg\:pt-3\/12 {
    padding-top: 25%;
  }

  .lg\:pr-3\/12 {
    padding-right: 25%;
  }

  .lg\:pb-3\/12 {
    padding-bottom: 25%;
  }

  .lg\:pl-3\/12 {
    padding-left: 25%;
  }

  .lg\:pt-4\/12 {
    padding-top: 33.3333%;
  }

  .lg\:pr-4\/12 {
    padding-right: 33.3333%;
  }

  .lg\:pb-4\/12 {
    padding-bottom: 33.3333%;
  }

  .lg\:pl-4\/12 {
    padding-left: 33.3333%;
  }

  .lg\:pt-5\/12 {
    padding-top: 41.6667%;
  }

  .lg\:pr-5\/12 {
    padding-right: 41.6667%;
  }

  .lg\:pb-5\/12 {
    padding-bottom: 41.6667%;
  }

  .lg\:pl-5\/12 {
    padding-left: 41.6667%;
  }

  .lg\:pt-6\/12 {
    padding-top: 50%;
  }

  .lg\:pr-6\/12 {
    padding-right: 50%;
  }

  .lg\:pb-6\/12 {
    padding-bottom: 50%;
  }

  .lg\:pl-6\/12 {
    padding-left: 50%;
  }

  .lg\:pt-7\/12 {
    padding-top: 58.3333%;
  }

  .lg\:pr-7\/12 {
    padding-right: 58.3333%;
  }

  .lg\:pb-7\/12 {
    padding-bottom: 58.3333%;
  }

  .lg\:pl-7\/12 {
    padding-left: 58.3333%;
  }

  .lg\:pt-8\/12 {
    padding-top: 66.6667%;
  }

  .lg\:pr-8\/12 {
    padding-right: 66.6667%;
  }

  .lg\:pb-8\/12 {
    padding-bottom: 66.6667%;
  }

  .lg\:pl-8\/12 {
    padding-left: 66.6667%;
  }

  .lg\:pt-9\/12 {
    padding-top: 75%;
  }

  .lg\:pr-9\/12 {
    padding-right: 75%;
  }

  .lg\:pb-9\/12 {
    padding-bottom: 75%;
  }

  .lg\:pl-9\/12 {
    padding-left: 75%;
  }

  .lg\:pt-10\/12 {
    padding-top: 83.3333%;
  }

  .lg\:pr-10\/12 {
    padding-right: 83.3333%;
  }

  .lg\:pb-10\/12 {
    padding-bottom: 83.3333%;
  }

  .lg\:pl-10\/12 {
    padding-left: 83.3333%;
  }

  .lg\:pt-11\/12 {
    padding-top: 91.6667%;
  }

  .lg\:pr-11\/12 {
    padding-right: 91.6667%;
  }

  .lg\:pb-11\/12 {
    padding-bottom: 91.6667%;
  }

  .lg\:pl-11\/12 {
    padding-left: 91.6667%;
  }

  .lg\:pt-full {
    padding-top: 100%;
  }

  .lg\:pr-full {
    padding-right: 100%;
  }

  .lg\:pb-full {
    padding-bottom: 100%;
  }

  .lg\:pl-full {
    padding-left: 100%;
  }

  .lg\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .lg\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity) );
  }

  .lg\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--placeholder-opacity) );
  }

  .lg\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity) );
  }

  .lg\:placeholder-cool-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity) );
  }

  .lg\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-50::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity) );
  }

  .lg\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity) );
  }

  .lg\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-50::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity) );
  }

  .lg\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-50::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity) );
  }

  .lg\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-50::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity) );
  }

  .lg\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-50::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity) );
  }

  .lg\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-50::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity) );
  }

  .lg\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity) );
  }

  .lg\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .lg\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-cool-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity) );
  }

  .lg\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity) );
  }

  .lg\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: .25;
  }

  .lg\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: .5;
  }

  .lg\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: .75;
  }

  .lg\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: .25;
  }

  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: .5;
  }

  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: .75;
  }

  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .lg\:pointer-events-none {
    pointer-events: none;
  }

  .lg\:pointer-events-auto {
    pointer-events: auto;
  }

  .lg\:static {
    position: static;
  }

  .lg\:fixed {
    position: fixed;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:relative {
    position: relative;
  }

  .lg\:sticky {
    position: sticky;
  }

  .lg\:inset-0 {
    inset: 0;
  }

  .lg\:inset-1 {
    inset: .25rem;
  }

  .lg\:inset-2 {
    inset: .5rem;
  }

  .lg\:inset-3 {
    inset: .75rem;
  }

  .lg\:inset-4 {
    inset: 1rem;
  }

  .lg\:inset-5 {
    inset: 1.25rem;
  }

  .lg\:inset-6 {
    inset: 1.5rem;
  }

  .lg\:inset-7 {
    inset: 1.75rem;
  }

  .lg\:inset-8 {
    inset: 2rem;
  }

  .lg\:inset-9 {
    inset: 2.25rem;
  }

  .lg\:inset-10 {
    inset: 2.5rem;
  }

  .lg\:inset-11 {
    inset: 2.75rem;
  }

  .lg\:inset-12 {
    inset: 3rem;
  }

  .lg\:inset-13 {
    inset: 3.25rem;
  }

  .lg\:inset-14 {
    inset: 3.5rem;
  }

  .lg\:inset-15 {
    inset: 3.75rem;
  }

  .lg\:inset-16 {
    inset: 4rem;
  }

  .lg\:inset-20 {
    inset: 5rem;
  }

  .lg\:inset-24 {
    inset: 6rem;
  }

  .lg\:inset-28 {
    inset: 7rem;
  }

  .lg\:inset-32 {
    inset: 8rem;
  }

  .lg\:inset-36 {
    inset: 9rem;
  }

  .lg\:inset-40 {
    inset: 10rem;
  }

  .lg\:inset-44 {
    inset: 11rem;
  }

  .lg\:inset-48 {
    inset: 12rem;
  }

  .lg\:inset-52 {
    inset: 13rem;
  }

  .lg\:inset-56 {
    inset: 14rem;
  }

  .lg\:inset-60 {
    inset: 15rem;
  }

  .lg\:inset-64 {
    inset: 16rem;
  }

  .lg\:inset-72 {
    inset: 18rem;
  }

  .lg\:inset-80 {
    inset: 20rem;
  }

  .lg\:inset-96 {
    inset: 24rem;
  }

  .lg\:inset-auto {
    inset: auto;
  }

  .lg\:inset-px {
    inset: 1px;
  }

  .lg\:inset-0\.5 {
    inset: .125rem;
  }

  .lg\:inset-1\.5 {
    inset: .375rem;
  }

  .lg\:inset-2\.5 {
    inset: .625rem;
  }

  .lg\:inset-3\.5 {
    inset: .875rem;
  }

  .lg\:inset-1\/2 {
    inset: 50%;
  }

  .lg\:inset-1\/3 {
    inset: 33.3333%;
  }

  .lg\:inset-2\/3 {
    inset: 66.6667%;
  }

  .lg\:inset-1\/4 {
    inset: 25%;
  }

  .lg\:inset-2\/4 {
    inset: 50%;
  }

  .lg\:inset-3\/4 {
    inset: 75%;
  }

  .lg\:inset-1\/5 {
    inset: 20%;
  }

  .lg\:inset-2\/5 {
    inset: 40%;
  }

  .lg\:inset-3\/5 {
    inset: 60%;
  }

  .lg\:inset-4\/5 {
    inset: 80%;
  }

  .lg\:inset-1\/6 {
    inset: 16.6667%;
  }

  .lg\:inset-2\/6 {
    inset: 33.3333%;
  }

  .lg\:inset-3\/6 {
    inset: 50%;
  }

  .lg\:inset-4\/6 {
    inset: 66.6667%;
  }

  .lg\:inset-5\/6 {
    inset: 83.3333%;
  }

  .lg\:inset-1\/12 {
    inset: 8.33333%;
  }

  .lg\:inset-2\/12 {
    inset: 16.6667%;
  }

  .lg\:inset-3\/12 {
    inset: 25%;
  }

  .lg\:inset-4\/12 {
    inset: 33.3333%;
  }

  .lg\:inset-5\/12 {
    inset: 41.6667%;
  }

  .lg\:inset-6\/12 {
    inset: 50%;
  }

  .lg\:inset-7\/12 {
    inset: 58.3333%;
  }

  .lg\:inset-8\/12 {
    inset: 66.6667%;
  }

  .lg\:inset-9\/12 {
    inset: 75%;
  }

  .lg\:inset-10\/12 {
    inset: 83.3333%;
  }

  .lg\:inset-11\/12 {
    inset: 91.6667%;
  }

  .lg\:inset-full {
    inset: 100%;
  }

  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .lg\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .lg\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .lg\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .lg\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .lg\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .lg\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .lg\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .lg\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .lg\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .lg\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .lg\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .lg\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .lg\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .lg\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .lg\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .lg\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .lg\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .lg\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .lg\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .lg\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .lg\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .lg\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .lg\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .lg\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .lg\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .lg\:inset-y-13 {
    top: 3.25rem;
    bottom: 3.25rem;
  }

  .lg\:inset-x-13 {
    left: 3.25rem;
    right: 3.25rem;
  }

  .lg\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .lg\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .lg\:inset-y-15 {
    top: 3.75rem;
    bottom: 3.75rem;
  }

  .lg\:inset-x-15 {
    left: 3.75rem;
    right: 3.75rem;
  }

  .lg\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .lg\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .lg\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .lg\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .lg\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .lg\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .lg\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .lg\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .lg\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .lg\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .lg\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .lg\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .lg\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .lg\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .lg\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .lg\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .lg\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .lg\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .lg\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .lg\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .lg\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .lg\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .lg\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .lg\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .lg\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .lg\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .lg\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .lg\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .lg\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .lg\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .lg\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .lg\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .lg\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .lg\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .lg\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .lg\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .lg\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .lg\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .lg\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .lg\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .lg\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .lg\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .lg\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .lg\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .lg\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .lg\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .lg\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .lg\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .lg\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .lg\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .lg\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .lg\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .lg\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .lg\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .lg\:inset-y-1\/5 {
    top: 20%;
    bottom: 20%;
  }

  .lg\:inset-x-1\/5 {
    left: 20%;
    right: 20%;
  }

  .lg\:inset-y-2\/5 {
    top: 40%;
    bottom: 40%;
  }

  .lg\:inset-x-2\/5 {
    left: 40%;
    right: 40%;
  }

  .lg\:inset-y-3\/5 {
    top: 60%;
    bottom: 60%;
  }

  .lg\:inset-x-3\/5 {
    left: 60%;
    right: 60%;
  }

  .lg\:inset-y-4\/5 {
    top: 80%;
    bottom: 80%;
  }

  .lg\:inset-x-4\/5 {
    left: 80%;
    right: 80%;
  }

  .lg\:inset-y-1\/6 {
    top: 16.6667%;
    bottom: 16.6667%;
  }

  .lg\:inset-x-1\/6 {
    left: 16.6667%;
    right: 16.6667%;
  }

  .lg\:inset-y-2\/6 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .lg\:inset-x-2\/6 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .lg\:inset-y-3\/6 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:inset-x-3\/6 {
    left: 50%;
    right: 50%;
  }

  .lg\:inset-y-4\/6 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .lg\:inset-x-4\/6 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .lg\:inset-y-5\/6 {
    top: 83.3333%;
    bottom: 83.3333%;
  }

  .lg\:inset-x-5\/6 {
    left: 83.3333%;
    right: 83.3333%;
  }

  .lg\:inset-y-1\/12 {
    top: 8.33333%;
    bottom: 8.33333%;
  }

  .lg\:inset-x-1\/12 {
    left: 8.33333%;
    right: 8.33333%;
  }

  .lg\:inset-y-2\/12 {
    top: 16.6667%;
    bottom: 16.6667%;
  }

  .lg\:inset-x-2\/12 {
    left: 16.6667%;
    right: 16.6667%;
  }

  .lg\:inset-y-3\/12 {
    top: 25%;
    bottom: 25%;
  }

  .lg\:inset-x-3\/12 {
    left: 25%;
    right: 25%;
  }

  .lg\:inset-y-4\/12 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .lg\:inset-x-4\/12 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .lg\:inset-y-5\/12 {
    top: 41.6667%;
    bottom: 41.6667%;
  }

  .lg\:inset-x-5\/12 {
    left: 41.6667%;
    right: 41.6667%;
  }

  .lg\:inset-y-6\/12 {
    top: 50%;
    bottom: 50%;
  }

  .lg\:inset-x-6\/12 {
    left: 50%;
    right: 50%;
  }

  .lg\:inset-y-7\/12 {
    top: 58.3333%;
    bottom: 58.3333%;
  }

  .lg\:inset-x-7\/12 {
    left: 58.3333%;
    right: 58.3333%;
  }

  .lg\:inset-y-8\/12 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .lg\:inset-x-8\/12 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .lg\:inset-y-9\/12 {
    top: 75%;
    bottom: 75%;
  }

  .lg\:inset-x-9\/12 {
    left: 75%;
    right: 75%;
  }

  .lg\:inset-y-10\/12 {
    top: 83.3333%;
    bottom: 83.3333%;
  }

  .lg\:inset-x-10\/12 {
    left: 83.3333%;
    right: 83.3333%;
  }

  .lg\:inset-y-11\/12 {
    top: 91.6667%;
    bottom: 91.6667%;
  }

  .lg\:inset-x-11\/12 {
    left: 91.6667%;
    right: 91.6667%;
  }

  .lg\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .lg\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .lg\:top-0 {
    top: 0;
  }

  .lg\:right-0 {
    right: 0;
  }

  .lg\:bottom-0 {
    bottom: 0;
  }

  .lg\:left-0 {
    left: 0;
  }

  .lg\:top-1 {
    top: .25rem;
  }

  .lg\:right-1 {
    right: .25rem;
  }

  .lg\:bottom-1 {
    bottom: .25rem;
  }

  .lg\:left-1 {
    left: .25rem;
  }

  .lg\:top-2 {
    top: .5rem;
  }

  .lg\:right-2 {
    right: .5rem;
  }

  .lg\:bottom-2 {
    bottom: .5rem;
  }

  .lg\:left-2 {
    left: .5rem;
  }

  .lg\:top-3 {
    top: .75rem;
  }

  .lg\:right-3 {
    right: .75rem;
  }

  .lg\:bottom-3 {
    bottom: .75rem;
  }

  .lg\:left-3 {
    left: .75rem;
  }

  .lg\:top-4 {
    top: 1rem;
  }

  .lg\:right-4 {
    right: 1rem;
  }

  .lg\:bottom-4 {
    bottom: 1rem;
  }

  .lg\:left-4 {
    left: 1rem;
  }

  .lg\:top-5 {
    top: 1.25rem;
  }

  .lg\:right-5 {
    right: 1.25rem;
  }

  .lg\:bottom-5 {
    bottom: 1.25rem;
  }

  .lg\:left-5 {
    left: 1.25rem;
  }

  .lg\:top-6 {
    top: 1.5rem;
  }

  .lg\:right-6 {
    right: 1.5rem;
  }

  .lg\:bottom-6 {
    bottom: 1.5rem;
  }

  .lg\:left-6 {
    left: 1.5rem;
  }

  .lg\:top-7 {
    top: 1.75rem;
  }

  .lg\:right-7 {
    right: 1.75rem;
  }

  .lg\:bottom-7 {
    bottom: 1.75rem;
  }

  .lg\:left-7 {
    left: 1.75rem;
  }

  .lg\:top-8 {
    top: 2rem;
  }

  .lg\:right-8 {
    right: 2rem;
  }

  .lg\:bottom-8 {
    bottom: 2rem;
  }

  .lg\:left-8 {
    left: 2rem;
  }

  .lg\:top-9 {
    top: 2.25rem;
  }

  .lg\:right-9 {
    right: 2.25rem;
  }

  .lg\:bottom-9 {
    bottom: 2.25rem;
  }

  .lg\:left-9 {
    left: 2.25rem;
  }

  .lg\:top-10 {
    top: 2.5rem;
  }

  .lg\:right-10 {
    right: 2.5rem;
  }

  .lg\:bottom-10 {
    bottom: 2.5rem;
  }

  .lg\:left-10 {
    left: 2.5rem;
  }

  .lg\:top-11 {
    top: 2.75rem;
  }

  .lg\:right-11 {
    right: 2.75rem;
  }

  .lg\:bottom-11 {
    bottom: 2.75rem;
  }

  .lg\:left-11 {
    left: 2.75rem;
  }

  .lg\:top-12 {
    top: 3rem;
  }

  .lg\:right-12 {
    right: 3rem;
  }

  .lg\:bottom-12 {
    bottom: 3rem;
  }

  .lg\:left-12 {
    left: 3rem;
  }

  .lg\:top-13 {
    top: 3.25rem;
  }

  .lg\:right-13 {
    right: 3.25rem;
  }

  .lg\:bottom-13 {
    bottom: 3.25rem;
  }

  .lg\:left-13 {
    left: 3.25rem;
  }

  .lg\:top-14 {
    top: 3.5rem;
  }

  .lg\:right-14 {
    right: 3.5rem;
  }

  .lg\:bottom-14 {
    bottom: 3.5rem;
  }

  .lg\:left-14 {
    left: 3.5rem;
  }

  .lg\:top-15 {
    top: 3.75rem;
  }

  .lg\:right-15 {
    right: 3.75rem;
  }

  .lg\:bottom-15 {
    bottom: 3.75rem;
  }

  .lg\:left-15 {
    left: 3.75rem;
  }

  .lg\:top-16 {
    top: 4rem;
  }

  .lg\:right-16 {
    right: 4rem;
  }

  .lg\:bottom-16 {
    bottom: 4rem;
  }

  .lg\:left-16 {
    left: 4rem;
  }

  .lg\:top-20 {
    top: 5rem;
  }

  .lg\:right-20 {
    right: 5rem;
  }

  .lg\:bottom-20 {
    bottom: 5rem;
  }

  .lg\:left-20 {
    left: 5rem;
  }

  .lg\:top-24 {
    top: 6rem;
  }

  .lg\:right-24 {
    right: 6rem;
  }

  .lg\:bottom-24 {
    bottom: 6rem;
  }

  .lg\:left-24 {
    left: 6rem;
  }

  .lg\:top-28 {
    top: 7rem;
  }

  .lg\:right-28 {
    right: 7rem;
  }

  .lg\:bottom-28 {
    bottom: 7rem;
  }

  .lg\:left-28 {
    left: 7rem;
  }

  .lg\:top-32 {
    top: 8rem;
  }

  .lg\:right-32 {
    right: 8rem;
  }

  .lg\:bottom-32 {
    bottom: 8rem;
  }

  .lg\:left-32 {
    left: 8rem;
  }

  .lg\:top-36 {
    top: 9rem;
  }

  .lg\:right-36 {
    right: 9rem;
  }

  .lg\:bottom-36 {
    bottom: 9rem;
  }

  .lg\:left-36 {
    left: 9rem;
  }

  .lg\:top-40 {
    top: 10rem;
  }

  .lg\:right-40 {
    right: 10rem;
  }

  .lg\:bottom-40 {
    bottom: 10rem;
  }

  .lg\:left-40 {
    left: 10rem;
  }

  .lg\:top-44 {
    top: 11rem;
  }

  .lg\:right-44 {
    right: 11rem;
  }

  .lg\:bottom-44 {
    bottom: 11rem;
  }

  .lg\:left-44 {
    left: 11rem;
  }

  .lg\:top-48 {
    top: 12rem;
  }

  .lg\:right-48 {
    right: 12rem;
  }

  .lg\:bottom-48 {
    bottom: 12rem;
  }

  .lg\:left-48 {
    left: 12rem;
  }

  .lg\:top-52 {
    top: 13rem;
  }

  .lg\:right-52 {
    right: 13rem;
  }

  .lg\:bottom-52 {
    bottom: 13rem;
  }

  .lg\:left-52 {
    left: 13rem;
  }

  .lg\:top-56 {
    top: 14rem;
  }

  .lg\:right-56 {
    right: 14rem;
  }

  .lg\:bottom-56 {
    bottom: 14rem;
  }

  .lg\:left-56 {
    left: 14rem;
  }

  .lg\:top-60 {
    top: 15rem;
  }

  .lg\:right-60 {
    right: 15rem;
  }

  .lg\:bottom-60 {
    bottom: 15rem;
  }

  .lg\:left-60 {
    left: 15rem;
  }

  .lg\:top-64 {
    top: 16rem;
  }

  .lg\:right-64 {
    right: 16rem;
  }

  .lg\:bottom-64 {
    bottom: 16rem;
  }

  .lg\:left-64 {
    left: 16rem;
  }

  .lg\:top-72 {
    top: 18rem;
  }

  .lg\:right-72 {
    right: 18rem;
  }

  .lg\:bottom-72 {
    bottom: 18rem;
  }

  .lg\:left-72 {
    left: 18rem;
  }

  .lg\:top-80 {
    top: 20rem;
  }

  .lg\:right-80 {
    right: 20rem;
  }

  .lg\:bottom-80 {
    bottom: 20rem;
  }

  .lg\:left-80 {
    left: 20rem;
  }

  .lg\:top-96 {
    top: 24rem;
  }

  .lg\:right-96 {
    right: 24rem;
  }

  .lg\:bottom-96 {
    bottom: 24rem;
  }

  .lg\:left-96 {
    left: 24rem;
  }

  .lg\:top-auto {
    top: auto;
  }

  .lg\:right-auto {
    right: auto;
  }

  .lg\:bottom-auto {
    bottom: auto;
  }

  .lg\:left-auto {
    left: auto;
  }

  .lg\:top-px {
    top: 1px;
  }

  .lg\:right-px {
    right: 1px;
  }

  .lg\:bottom-px {
    bottom: 1px;
  }

  .lg\:left-px {
    left: 1px;
  }

  .lg\:top-0\.5 {
    top: .125rem;
  }

  .lg\:right-0\.5 {
    right: .125rem;
  }

  .lg\:bottom-0\.5 {
    bottom: .125rem;
  }

  .lg\:left-0\.5 {
    left: .125rem;
  }

  .lg\:top-1\.5 {
    top: .375rem;
  }

  .lg\:right-1\.5 {
    right: .375rem;
  }

  .lg\:bottom-1\.5 {
    bottom: .375rem;
  }

  .lg\:left-1\.5 {
    left: .375rem;
  }

  .lg\:top-2\.5 {
    top: .625rem;
  }

  .lg\:right-2\.5 {
    right: .625rem;
  }

  .lg\:bottom-2\.5 {
    bottom: .625rem;
  }

  .lg\:left-2\.5 {
    left: .625rem;
  }

  .lg\:top-3\.5 {
    top: .875rem;
  }

  .lg\:right-3\.5 {
    right: .875rem;
  }

  .lg\:bottom-3\.5 {
    bottom: .875rem;
  }

  .lg\:left-3\.5 {
    left: .875rem;
  }

  .lg\:top-1\/2 {
    top: 50%;
  }

  .lg\:right-1\/2 {
    right: 50%;
  }

  .lg\:bottom-1\/2 {
    bottom: 50%;
  }

  .lg\:left-1\/2 {
    left: 50%;
  }

  .lg\:top-1\/3 {
    top: 33.3333%;
  }

  .lg\:right-1\/3 {
    right: 33.3333%;
  }

  .lg\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .lg\:left-1\/3 {
    left: 33.3333%;
  }

  .lg\:top-2\/3 {
    top: 66.6667%;
  }

  .lg\:right-2\/3 {
    right: 66.6667%;
  }

  .lg\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .lg\:left-2\/3 {
    left: 66.6667%;
  }

  .lg\:top-1\/4 {
    top: 25%;
  }

  .lg\:right-1\/4 {
    right: 25%;
  }

  .lg\:bottom-1\/4 {
    bottom: 25%;
  }

  .lg\:left-1\/4 {
    left: 25%;
  }

  .lg\:top-2\/4 {
    top: 50%;
  }

  .lg\:right-2\/4 {
    right: 50%;
  }

  .lg\:bottom-2\/4 {
    bottom: 50%;
  }

  .lg\:left-2\/4 {
    left: 50%;
  }

  .lg\:top-3\/4 {
    top: 75%;
  }

  .lg\:right-3\/4 {
    right: 75%;
  }

  .lg\:bottom-3\/4 {
    bottom: 75%;
  }

  .lg\:left-3\/4 {
    left: 75%;
  }

  .lg\:top-1\/5 {
    top: 20%;
  }

  .lg\:right-1\/5 {
    right: 20%;
  }

  .lg\:bottom-1\/5 {
    bottom: 20%;
  }

  .lg\:left-1\/5 {
    left: 20%;
  }

  .lg\:top-2\/5 {
    top: 40%;
  }

  .lg\:right-2\/5 {
    right: 40%;
  }

  .lg\:bottom-2\/5 {
    bottom: 40%;
  }

  .lg\:left-2\/5 {
    left: 40%;
  }

  .lg\:top-3\/5 {
    top: 60%;
  }

  .lg\:right-3\/5 {
    right: 60%;
  }

  .lg\:bottom-3\/5 {
    bottom: 60%;
  }

  .lg\:left-3\/5 {
    left: 60%;
  }

  .lg\:top-4\/5 {
    top: 80%;
  }

  .lg\:right-4\/5 {
    right: 80%;
  }

  .lg\:bottom-4\/5 {
    bottom: 80%;
  }

  .lg\:left-4\/5 {
    left: 80%;
  }

  .lg\:top-1\/6 {
    top: 16.6667%;
  }

  .lg\:right-1\/6 {
    right: 16.6667%;
  }

  .lg\:bottom-1\/6 {
    bottom: 16.6667%;
  }

  .lg\:left-1\/6 {
    left: 16.6667%;
  }

  .lg\:top-2\/6 {
    top: 33.3333%;
  }

  .lg\:right-2\/6 {
    right: 33.3333%;
  }

  .lg\:bottom-2\/6 {
    bottom: 33.3333%;
  }

  .lg\:left-2\/6 {
    left: 33.3333%;
  }

  .lg\:top-3\/6 {
    top: 50%;
  }

  .lg\:right-3\/6 {
    right: 50%;
  }

  .lg\:bottom-3\/6 {
    bottom: 50%;
  }

  .lg\:left-3\/6 {
    left: 50%;
  }

  .lg\:top-4\/6 {
    top: 66.6667%;
  }

  .lg\:right-4\/6 {
    right: 66.6667%;
  }

  .lg\:bottom-4\/6 {
    bottom: 66.6667%;
  }

  .lg\:left-4\/6 {
    left: 66.6667%;
  }

  .lg\:top-5\/6 {
    top: 83.3333%;
  }

  .lg\:right-5\/6 {
    right: 83.3333%;
  }

  .lg\:bottom-5\/6 {
    bottom: 83.3333%;
  }

  .lg\:left-5\/6 {
    left: 83.3333%;
  }

  .lg\:top-1\/12 {
    top: 8.33333%;
  }

  .lg\:right-1\/12 {
    right: 8.33333%;
  }

  .lg\:bottom-1\/12 {
    bottom: 8.33333%;
  }

  .lg\:left-1\/12 {
    left: 8.33333%;
  }

  .lg\:top-2\/12 {
    top: 16.6667%;
  }

  .lg\:right-2\/12 {
    right: 16.6667%;
  }

  .lg\:bottom-2\/12 {
    bottom: 16.6667%;
  }

  .lg\:left-2\/12 {
    left: 16.6667%;
  }

  .lg\:top-3\/12 {
    top: 25%;
  }

  .lg\:right-3\/12 {
    right: 25%;
  }

  .lg\:bottom-3\/12 {
    bottom: 25%;
  }

  .lg\:left-3\/12 {
    left: 25%;
  }

  .lg\:top-4\/12 {
    top: 33.3333%;
  }

  .lg\:right-4\/12 {
    right: 33.3333%;
  }

  .lg\:bottom-4\/12 {
    bottom: 33.3333%;
  }

  .lg\:left-4\/12 {
    left: 33.3333%;
  }

  .lg\:top-5\/12 {
    top: 41.6667%;
  }

  .lg\:right-5\/12 {
    right: 41.6667%;
  }

  .lg\:bottom-5\/12 {
    bottom: 41.6667%;
  }

  .lg\:left-5\/12 {
    left: 41.6667%;
  }

  .lg\:top-6\/12 {
    top: 50%;
  }

  .lg\:right-6\/12 {
    right: 50%;
  }

  .lg\:bottom-6\/12 {
    bottom: 50%;
  }

  .lg\:left-6\/12 {
    left: 50%;
  }

  .lg\:top-7\/12 {
    top: 58.3333%;
  }

  .lg\:right-7\/12 {
    right: 58.3333%;
  }

  .lg\:bottom-7\/12 {
    bottom: 58.3333%;
  }

  .lg\:left-7\/12 {
    left: 58.3333%;
  }

  .lg\:top-8\/12 {
    top: 66.6667%;
  }

  .lg\:right-8\/12 {
    right: 66.6667%;
  }

  .lg\:bottom-8\/12 {
    bottom: 66.6667%;
  }

  .lg\:left-8\/12 {
    left: 66.6667%;
  }

  .lg\:top-9\/12 {
    top: 75%;
  }

  .lg\:right-9\/12 {
    right: 75%;
  }

  .lg\:bottom-9\/12 {
    bottom: 75%;
  }

  .lg\:left-9\/12 {
    left: 75%;
  }

  .lg\:top-10\/12 {
    top: 83.3333%;
  }

  .lg\:right-10\/12 {
    right: 83.3333%;
  }

  .lg\:bottom-10\/12 {
    bottom: 83.3333%;
  }

  .lg\:left-10\/12 {
    left: 83.3333%;
  }

  .lg\:top-11\/12 {
    top: 91.6667%;
  }

  .lg\:right-11\/12 {
    right: 91.6667%;
  }

  .lg\:bottom-11\/12 {
    bottom: 91.6667%;
  }

  .lg\:left-11\/12 {
    left: 91.6667%;
  }

  .lg\:top-full {
    top: 100%;
  }

  .lg\:right-full {
    right: 100%;
  }

  .lg\:bottom-full {
    bottom: 100%;
  }

  .lg\:left-full {
    left: 100%;
  }

  .lg\:resize-none {
    resize: none;
  }

  .lg\:resize-y {
    resize: vertical;
  }

  .lg\:resize-x {
    resize: horizontal;
  }

  .lg\:resize {
    resize: both;
  }

  .lg\:shadow-xs {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .lg\:shadow-sm {
    box-shadow: 0 1px 2px #0000000d;
  }

  .lg\:shadow {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .lg\:shadow-md {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .lg\:shadow-lg {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .lg\:shadow-xl {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .lg\:shadow-2xl {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .lg\:shadow-inner {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .lg\:shadow-outline {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .lg\:shadow-none {
    box-shadow: none;
  }

  .lg\:shadow-solid {
    box-shadow: 0 0 0 2px;
  }

  .lg\:shadow-outline-gray {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .lg\:shadow-outline-blue {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .lg\:shadow-outline-teal {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .lg\:shadow-outline-green {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .lg\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .lg\:shadow-outline-orange {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .lg\:shadow-outline-red {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .lg\:shadow-outline-pink {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .lg\:shadow-outline-purple {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .lg\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .group:focus .lg\:group-focus\:shadow-xs {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .group:focus .lg\:group-focus\:shadow-sm {
    box-shadow: 0 1px 2px #0000000d;
  }

  .group:focus .lg\:group-focus\:shadow {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .group:focus .lg\:group-focus\:shadow-md {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .group:focus .lg\:group-focus\:shadow-lg {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .group:focus .lg\:group-focus\:shadow-xl {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .group:focus .lg\:group-focus\:shadow-2xl {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .group:focus .lg\:group-focus\:shadow-inner {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .group:focus .lg\:group-focus\:shadow-outline {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .group:focus .lg\:group-focus\:shadow-none {
    box-shadow: none;
  }

  .group:focus .lg\:group-focus\:shadow-solid {
    box-shadow: 0 0 0 2px;
  }

  .group:focus .lg\:group-focus\:shadow-outline-gray {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .group:focus .lg\:group-focus\:shadow-outline-blue {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .group:focus .lg\:group-focus\:shadow-outline-teal {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .group:focus .lg\:group-focus\:shadow-outline-green {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .group:focus .lg\:group-focus\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .group:focus .lg\:group-focus\:shadow-outline-orange {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .group:focus .lg\:group-focus\:shadow-outline-red {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .group:focus .lg\:group-focus\:shadow-outline-pink {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .group:focus .lg\:group-focus\:shadow-outline-purple {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .group:focus .lg\:group-focus\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .lg\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .lg\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px #0000000d;
  }

  .lg\:hover\:shadow:hover {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .lg\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .lg\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .lg\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .lg\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .lg\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .lg\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .lg\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .lg\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px;
  }

  .lg\:hover\:shadow-outline-gray:hover {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .lg\:hover\:shadow-outline-blue:hover {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .lg\:hover\:shadow-outline-teal:hover {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .lg\:hover\:shadow-outline-green:hover {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .lg\:hover\:shadow-outline-yellow:hover {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .lg\:hover\:shadow-outline-orange:hover {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .lg\:hover\:shadow-outline-red:hover {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .lg\:hover\:shadow-outline-pink:hover {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .lg\:hover\:shadow-outline-purple:hover {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .lg\:hover\:shadow-outline-indigo:hover {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .lg\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .lg\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px #0000000d;
  }

  .lg\:focus\:shadow:focus {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .lg\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .lg\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .lg\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .lg\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .lg\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .lg\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .lg\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .lg\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px;
  }

  .lg\:focus\:shadow-outline-gray:focus {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .lg\:focus\:shadow-outline-blue:focus {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .lg\:focus\:shadow-outline-teal:focus {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .lg\:focus\:shadow-outline-green:focus {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .lg\:focus\:shadow-outline-yellow:focus {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .lg\:focus\:shadow-outline-orange:focus {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .lg\:focus\:shadow-outline-red:focus {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .lg\:focus\:shadow-outline-pink:focus {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .lg\:focus\:shadow-outline-purple:focus {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .lg\:focus\:shadow-outline-indigo:focus {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .lg\:fill-current {
    fill: currentColor;
  }

  .lg\:stroke-current {
    stroke: currentColor;
  }

  .lg\:stroke-0 {
    stroke-width: 0;
  }

  .lg\:stroke-1 {
    stroke-width: 1px;
  }

  .lg\:stroke-2 {
    stroke-width: 2px;
  }

  .lg\:table-auto {
    table-layout: auto;
  }

  .lg\:table-fixed {
    table-layout: fixed;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-center {
    text-align: center;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-justify {
    text-align: justify;
  }

  .lg\:text-transparent {
    color: #0000;
  }

  .lg\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .lg\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .lg\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .lg\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .lg\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .lg\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .lg\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .lg\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .lg\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .lg\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .lg\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .lg\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .lg\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .lg\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .lg\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .lg\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .lg\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .lg\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .lg\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .lg\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .lg\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .lg\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .lg\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .lg\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .lg\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .lg\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .lg\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .lg\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .lg\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .lg\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .lg\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .lg\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .lg\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .lg\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .lg\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .lg\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .lg\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .lg\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .lg\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .lg\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .lg\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .lg\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .lg\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .lg\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .lg\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .lg\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .lg\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .lg\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .lg\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .lg\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .lg\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .lg\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .lg\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .lg\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .lg\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .lg\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .lg\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .lg\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .lg\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .lg\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .lg\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .lg\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .lg\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .lg\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .lg\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .lg\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .lg\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .lg\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .lg\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .lg\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .lg\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .lg\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .lg\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .lg\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .lg\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .lg\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .lg\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .lg\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .lg\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .lg\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .lg\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .lg\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .lg\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .lg\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .lg\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .lg\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .lg\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .lg\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .lg\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .lg\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .lg\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .lg\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .lg\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .lg\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .lg\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .lg\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .lg\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .lg\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .lg\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .lg\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .lg\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .lg\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .lg\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .lg\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .lg\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .lg\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .lg\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .lg\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .lg\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .lg\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .lg\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .lg\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .lg\:group-hover\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .group:hover .lg\:group-hover\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-transparent {
    color: #0000;
  }

  .group:focus .lg\:group-focus\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .group:focus .lg\:group-focus\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .lg\:hover\:text-transparent:hover {
    color: #0000;
  }

  .lg\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .lg\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-50:hover {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .lg\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-50:hover {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-100:hover {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-200:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-300:hover {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-400:hover {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-500:hover {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-600:hover {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-700:hover {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-800:hover {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .lg\:hover\:text-cool-gray-900:hover {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .lg\:hover\:text-red-50:hover {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .lg\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .lg\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .lg\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .lg\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .lg\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .lg\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .lg\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .lg\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .lg\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-50:hover {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .lg\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-50:hover {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .lg\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .lg\:hover\:text-green-50:hover {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .lg\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .lg\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .lg\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .lg\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .lg\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .lg\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .lg\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .lg\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .lg\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-50:hover {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .lg\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-50:hover {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .lg\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-50:hover {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .lg\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-50:hover {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .lg\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-50:hover {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .lg\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .lg\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .lg\:focus-within\:text-white:focus-within {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .lg\:focus-within\:text-black:focus-within {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-50:focus-within {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-100:focus-within {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-200:focus-within {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-300:focus-within {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-400:focus-within {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-500:focus-within {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-600:focus-within {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-700:focus-within {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-800:focus-within {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .lg\:focus-within\:text-gray-900:focus-within {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-50:focus-within {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-100:focus-within {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-200:focus-within {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-300:focus-within {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-400:focus-within {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-500:focus-within {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-600:focus-within {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-700:focus-within {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-800:focus-within {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .lg\:focus-within\:text-cool-gray-900:focus-within {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-100:focus-within {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-200:focus-within {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-300:focus-within {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-400:focus-within {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-500:focus-within {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-600:focus-within {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-700:focus-within {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-800:focus-within {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .lg\:focus-within\:text-red-900:focus-within {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-50:focus-within {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-100:focus-within {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-200:focus-within {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-300:focus-within {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-400:focus-within {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-500:focus-within {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-600:focus-within {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-700:focus-within {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-800:focus-within {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .lg\:focus-within\:text-orange-900:focus-within {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-50:focus-within {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-100:focus-within {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-200:focus-within {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-300:focus-within {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-400:focus-within {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-500:focus-within {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-600:focus-within {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-700:focus-within {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-800:focus-within {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .lg\:focus-within\:text-yellow-900:focus-within {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-50:focus-within {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-100:focus-within {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-200:focus-within {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-300:focus-within {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-400:focus-within {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-500:focus-within {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-600:focus-within {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-700:focus-within {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-800:focus-within {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .lg\:focus-within\:text-green-900:focus-within {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-50:focus-within {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-100:focus-within {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-200:focus-within {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-300:focus-within {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-400:focus-within {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-500:focus-within {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-600:focus-within {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-700:focus-within {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-800:focus-within {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .lg\:focus-within\:text-teal-900:focus-within {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-50:focus-within {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-100:focus-within {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-200:focus-within {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-300:focus-within {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-400:focus-within {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-500:focus-within {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-600:focus-within {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-700:focus-within {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-800:focus-within {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .lg\:focus-within\:text-blue-900:focus-within {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-50:focus-within {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-100:focus-within {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-200:focus-within {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-300:focus-within {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-400:focus-within {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-500:focus-within {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-600:focus-within {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-700:focus-within {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-800:focus-within {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .lg\:focus-within\:text-indigo-900:focus-within {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-50:focus-within {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-100:focus-within {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-200:focus-within {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-300:focus-within {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-400:focus-within {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-500:focus-within {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-600:focus-within {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-700:focus-within {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-800:focus-within {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .lg\:focus-within\:text-purple-900:focus-within {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-100:focus-within {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-200:focus-within {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-300:focus-within {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-400:focus-within {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-500:focus-within {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-600:focus-within {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-700:focus-within {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-800:focus-within {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .lg\:focus-within\:text-pink-900:focus-within {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .lg\:focus\:text-transparent:focus {
    color: #0000;
  }

  .lg\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .lg\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-50:focus {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .lg\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-50:focus {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-100:focus {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-200:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-300:focus {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-400:focus {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-500:focus {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-600:focus {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-700:focus {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-800:focus {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .lg\:focus\:text-cool-gray-900:focus {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .lg\:focus\:text-red-50:focus {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .lg\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .lg\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .lg\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .lg\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .lg\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .lg\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .lg\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .lg\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .lg\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-50:focus {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .lg\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-50:focus {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .lg\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .lg\:focus\:text-green-50:focus {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .lg\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .lg\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .lg\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .lg\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .lg\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .lg\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .lg\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .lg\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .lg\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-50:focus {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .lg\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-50:focus {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .lg\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-50:focus {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .lg\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-50:focus {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .lg\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-50:focus {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .lg\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .lg\:active\:text-transparent:active {
    color: #0000;
  }

  .lg\:active\:text-white:active {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .lg\:active\:text-black:active {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .lg\:active\:text-gray-50:active {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .lg\:active\:text-gray-100:active {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .lg\:active\:text-gray-200:active {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .lg\:active\:text-gray-300:active {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .lg\:active\:text-gray-400:active {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .lg\:active\:text-gray-500:active {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .lg\:active\:text-gray-600:active {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .lg\:active\:text-gray-700:active {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .lg\:active\:text-gray-800:active {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .lg\:active\:text-gray-900:active {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-50:active {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-100:active {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-200:active {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-300:active {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-400:active {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-500:active {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-600:active {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-700:active {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-800:active {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .lg\:active\:text-cool-gray-900:active {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .lg\:active\:text-red-50:active {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .lg\:active\:text-red-100:active {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .lg\:active\:text-red-200:active {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .lg\:active\:text-red-300:active {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .lg\:active\:text-red-400:active {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .lg\:active\:text-red-500:active {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .lg\:active\:text-red-600:active {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .lg\:active\:text-red-700:active {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .lg\:active\:text-red-800:active {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .lg\:active\:text-red-900:active {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .lg\:active\:text-orange-50:active {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .lg\:active\:text-orange-100:active {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .lg\:active\:text-orange-200:active {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .lg\:active\:text-orange-300:active {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .lg\:active\:text-orange-400:active {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .lg\:active\:text-orange-500:active {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .lg\:active\:text-orange-600:active {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .lg\:active\:text-orange-700:active {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .lg\:active\:text-orange-800:active {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .lg\:active\:text-orange-900:active {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-50:active {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-100:active {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-200:active {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-300:active {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-400:active {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-500:active {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-600:active {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-700:active {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-800:active {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .lg\:active\:text-yellow-900:active {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .lg\:active\:text-green-50:active {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .lg\:active\:text-green-100:active {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .lg\:active\:text-green-200:active {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .lg\:active\:text-green-300:active {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .lg\:active\:text-green-400:active {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .lg\:active\:text-green-500:active {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .lg\:active\:text-green-600:active {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .lg\:active\:text-green-700:active {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .lg\:active\:text-green-800:active {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .lg\:active\:text-green-900:active {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .lg\:active\:text-teal-50:active {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .lg\:active\:text-teal-100:active {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .lg\:active\:text-teal-200:active {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .lg\:active\:text-teal-300:active {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .lg\:active\:text-teal-400:active {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .lg\:active\:text-teal-500:active {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .lg\:active\:text-teal-600:active {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .lg\:active\:text-teal-700:active {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .lg\:active\:text-teal-800:active {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .lg\:active\:text-teal-900:active {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .lg\:active\:text-blue-50:active {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .lg\:active\:text-blue-100:active {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .lg\:active\:text-blue-200:active {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .lg\:active\:text-blue-300:active {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .lg\:active\:text-blue-400:active {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .lg\:active\:text-blue-500:active {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .lg\:active\:text-blue-600:active {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .lg\:active\:text-blue-700:active {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .lg\:active\:text-blue-800:active {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .lg\:active\:text-blue-900:active {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-50:active {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-100:active {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-200:active {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-300:active {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-400:active {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-500:active {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-600:active {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-700:active {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-800:active {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .lg\:active\:text-indigo-900:active {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .lg\:active\:text-purple-50:active {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .lg\:active\:text-purple-100:active {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .lg\:active\:text-purple-200:active {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .lg\:active\:text-purple-300:active {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .lg\:active\:text-purple-400:active {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .lg\:active\:text-purple-500:active {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .lg\:active\:text-purple-600:active {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .lg\:active\:text-purple-700:active {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .lg\:active\:text-purple-800:active {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .lg\:active\:text-purple-900:active {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .lg\:active\:text-pink-50:active {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .lg\:active\:text-pink-100:active {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .lg\:active\:text-pink-200:active {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .lg\:active\:text-pink-300:active {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .lg\:active\:text-pink-400:active {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .lg\:active\:text-pink-500:active {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .lg\:active\:text-pink-600:active {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .lg\:active\:text-pink-700:active {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .lg\:active\:text-pink-800:active {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .lg\:active\:text-pink-900:active {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .lg\:text-opacity-0 {
    --text-opacity: 0;
  }

  .lg\:text-opacity-25 {
    --text-opacity: .25;
  }

  .lg\:text-opacity-50 {
    --text-opacity: .5;
  }

  .lg\:text-opacity-75 {
    --text-opacity: .75;
  }

  .lg\:text-opacity-100 {
    --text-opacity: 1;
  }

  .lg\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .lg\:hover\:text-opacity-25:hover {
    --text-opacity: .25;
  }

  .lg\:hover\:text-opacity-50:hover {
    --text-opacity: .5;
  }

  .lg\:hover\:text-opacity-75:hover {
    --text-opacity: .75;
  }

  .lg\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .lg\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .lg\:focus\:text-opacity-25:focus {
    --text-opacity: .25;
  }

  .lg\:focus\:text-opacity-50:focus {
    --text-opacity: .5;
  }

  .lg\:focus\:text-opacity-75:focus {
    --text-opacity: .75;
  }

  .lg\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .lg\:italic {
    font-style: italic;
  }

  .lg\:not-italic {
    font-style: normal;
  }

  .lg\:uppercase {
    text-transform: uppercase;
  }

  .lg\:lowercase {
    text-transform: lowercase;
  }

  .lg\:capitalize {
    text-transform: capitalize;
  }

  .lg\:normal-case {
    text-transform: none;
  }

  .lg\:underline {
    text-decoration: underline;
  }

  .lg\:line-through {
    text-decoration: line-through;
  }

  .lg\:no-underline {
    text-decoration: none;
  }

  .group:hover .lg\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .lg\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .lg\:group-hover\:no-underline {
    text-decoration: none;
  }

  .group:focus .lg\:group-focus\:underline {
    text-decoration: underline;
  }

  .group:focus .lg\:group-focus\:line-through {
    text-decoration: line-through;
  }

  .group:focus .lg\:group-focus\:no-underline {
    text-decoration: none;
  }

  .lg\:hover\:underline:hover {
    text-decoration: underline;
  }

  .lg\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .lg\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .lg\:focus\:underline:focus {
    text-decoration: underline;
  }

  .lg\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .lg\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .lg\:ordinal, .lg\:slashed-zero, .lg\:lining-nums, .lg\:oldstyle-nums, .lg\:proportional-nums, .lg\:tabular-nums, .lg\:diagonal-fractions, .lg\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty, );
    --font-variant-numeric-slashed-zero: var(--tailwind-empty, );
    --font-variant-numeric-figure: var(--tailwind-empty, );
    --font-variant-numeric-spacing: var(--tailwind-empty, );
    --font-variant-numeric-fraction: var(--tailwind-empty, );
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .lg\:normal-nums {
    font-variant-numeric: normal;
  }

  .lg\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .lg\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .lg\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .lg\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .lg\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .lg\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .lg\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .lg\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .lg\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .lg\:tracking-tight {
    letter-spacing: -.025em;
  }

  .lg\:tracking-normal {
    letter-spacing: 0;
  }

  .lg\:tracking-wide {
    letter-spacing: .025em;
  }

  .lg\:tracking-wider {
    letter-spacing: .05em;
  }

  .lg\:tracking-widest {
    letter-spacing: .1em;
  }

  .lg\:select-none {
    user-select: none;
  }

  .lg\:select-text {
    user-select: text;
  }

  .lg\:select-all {
    user-select: all;
  }

  .lg\:select-auto {
    user-select: auto;
  }

  .lg\:align-baseline {
    vertical-align: baseline;
  }

  .lg\:align-top {
    vertical-align: top;
  }

  .lg\:align-middle {
    vertical-align: middle;
  }

  .lg\:align-bottom {
    vertical-align: bottom;
  }

  .lg\:align-text-top {
    vertical-align: text-top;
  }

  .lg\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .lg\:visible {
    visibility: visible;
  }

  .lg\:invisible {
    visibility: hidden;
  }

  .lg\:whitespace-normal {
    white-space: normal;
  }

  .lg\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .lg\:whitespace-pre {
    white-space: pre;
  }

  .lg\:whitespace-pre-line {
    white-space: pre-line;
  }

  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .lg\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .lg\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .lg\:break-all {
    word-break: break-all;
  }

  .lg\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .lg\:w-0 {
    width: 0;
  }

  .lg\:w-1 {
    width: .25rem;
  }

  .lg\:w-2 {
    width: .5rem;
  }

  .lg\:w-3 {
    width: .75rem;
  }

  .lg\:w-4 {
    width: 1rem;
  }

  .lg\:w-5 {
    width: 1.25rem;
  }

  .lg\:w-6 {
    width: 1.5rem;
  }

  .lg\:w-7 {
    width: 1.75rem;
  }

  .lg\:w-8 {
    width: 2rem;
  }

  .lg\:w-9 {
    width: 2.25rem;
  }

  .lg\:w-10 {
    width: 2.5rem;
  }

  .lg\:w-11 {
    width: 2.75rem;
  }

  .lg\:w-12 {
    width: 3rem;
  }

  .lg\:w-13 {
    width: 3.25rem;
  }

  .lg\:w-14 {
    width: 3.5rem;
  }

  .lg\:w-15 {
    width: 3.75rem;
  }

  .lg\:w-16 {
    width: 4rem;
  }

  .lg\:w-20 {
    width: 5rem;
  }

  .lg\:w-24 {
    width: 6rem;
  }

  .lg\:w-28 {
    width: 7rem;
  }

  .lg\:w-32 {
    width: 8rem;
  }

  .lg\:w-36 {
    width: 9rem;
  }

  .lg\:w-40 {
    width: 10rem;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:w-48 {
    width: 12rem;
  }

  .lg\:w-52 {
    width: 13rem;
  }

  .lg\:w-56 {
    width: 14rem;
  }

  .lg\:w-60 {
    width: 15rem;
  }

  .lg\:w-64 {
    width: 16rem;
  }

  .lg\:w-72 {
    width: 18rem;
  }

  .lg\:w-80 {
    width: 20rem;
  }

  .lg\:w-96 {
    width: 24rem;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-px {
    width: 1px;
  }

  .lg\:w-0\.5 {
    width: .125rem;
  }

  .lg\:w-1\.5 {
    width: .375rem;
  }

  .lg\:w-2\.5 {
    width: .625rem;
  }

  .lg\:w-3\.5 {
    width: .875rem;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-1\/4 {
    width: 25%;
  }

  .lg\:w-2\/4 {
    width: 50%;
  }

  .lg\:w-3\/4 {
    width: 75%;
  }

  .lg\:w-1\/5 {
    width: 20%;
  }

  .lg\:w-2\/5 {
    width: 40%;
  }

  .lg\:w-3\/5 {
    width: 60%;
  }

  .lg\:w-4\/5 {
    width: 80%;
  }

  .lg\:w-1\/6 {
    width: 16.6667%;
  }

  .lg\:w-2\/6 {
    width: 33.3333%;
  }

  .lg\:w-3\/6 {
    width: 50%;
  }

  .lg\:w-4\/6 {
    width: 66.6667%;
  }

  .lg\:w-5\/6 {
    width: 83.3333%;
  }

  .lg\:w-1\/12 {
    width: 8.33333%;
  }

  .lg\:w-2\/12 {
    width: 16.6667%;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }

  .lg\:w-10\/12 {
    width: 83.3333%;
  }

  .lg\:w-11\/12 {
    width: 91.6667%;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-screen {
    width: 100vw;
  }

  .lg\:w-min-content {
    width: min-content;
  }

  .lg\:w-max-content {
    width: max-content;
  }

  .lg\:z-0 {
    z-index: 0;
  }

  .lg\:z-10 {
    z-index: 10;
  }

  .lg\:z-20 {
    z-index: 20;
  }

  .lg\:z-30 {
    z-index: 30;
  }

  .lg\:z-40 {
    z-index: 40;
  }

  .lg\:z-50 {
    z-index: 50;
  }

  .lg\:z-auto {
    z-index: auto;
  }

  .lg\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .lg\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .lg\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .lg\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .lg\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .lg\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .lg\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .lg\:focus\:z-0:focus {
    z-index: 0;
  }

  .lg\:focus\:z-10:focus {
    z-index: 10;
  }

  .lg\:focus\:z-20:focus {
    z-index: 20;
  }

  .lg\:focus\:z-30:focus {
    z-index: 30;
  }

  .lg\:focus\:z-40:focus {
    z-index: 40;
  }

  .lg\:focus\:z-50:focus {
    z-index: 50;
  }

  .lg\:focus\:z-auto:focus {
    z-index: auto;
  }

  .lg\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .lg\:gap-1 {
    grid-gap: .25rem;
    gap: .25rem;
  }

  .lg\:gap-2 {
    grid-gap: .5rem;
    gap: .5rem;
  }

  .lg\:gap-3 {
    grid-gap: .75rem;
    gap: .75rem;
  }

  .lg\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .lg\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .lg\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .lg\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }

  .lg\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .lg\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }

  .lg\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .lg\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }

  .lg\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .lg\:gap-13 {
    grid-gap: 3.25rem;
    gap: 3.25rem;
  }

  .lg\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }

  .lg\:gap-15 {
    grid-gap: 3.75rem;
    gap: 3.75rem;
  }

  .lg\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .lg\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .lg\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .lg\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }

  .lg\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .lg\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }

  .lg\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .lg\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }

  .lg\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .lg\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }

  .lg\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .lg\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }

  .lg\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .lg\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }

  .lg\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }

  .lg\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }

  .lg\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .lg\:gap-0\.5 {
    grid-gap: .125rem;
    gap: .125rem;
  }

  .lg\:gap-1\.5 {
    grid-gap: .375rem;
    gap: .375rem;
  }

  .lg\:gap-2\.5 {
    grid-gap: .625rem;
    gap: .625rem;
  }

  .lg\:gap-3\.5 {
    grid-gap: .875rem;
    gap: .875rem;
  }

  .lg\:gap-1\/2 {
    grid-gap: 50%;
    gap: 50%;
  }

  .lg\:gap-1\/3 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .lg\:gap-2\/3 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .lg\:gap-1\/4 {
    grid-gap: 25%;
    gap: 25%;
  }

  .lg\:gap-2\/4 {
    grid-gap: 50%;
    gap: 50%;
  }

  .lg\:gap-3\/4 {
    grid-gap: 75%;
    gap: 75%;
  }

  .lg\:gap-1\/5 {
    grid-gap: 20%;
    gap: 20%;
  }

  .lg\:gap-2\/5 {
    grid-gap: 40%;
    gap: 40%;
  }

  .lg\:gap-3\/5 {
    grid-gap: 60%;
    gap: 60%;
  }

  .lg\:gap-4\/5 {
    grid-gap: 80%;
    gap: 80%;
  }

  .lg\:gap-1\/6 {
    grid-gap: 16.6667%;
    gap: 16.6667%;
  }

  .lg\:gap-2\/6 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .lg\:gap-3\/6 {
    grid-gap: 50%;
    gap: 50%;
  }

  .lg\:gap-4\/6 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .lg\:gap-5\/6 {
    grid-gap: 83.3333%;
    gap: 83.3333%;
  }

  .lg\:gap-1\/12 {
    grid-gap: 8.33333%;
    gap: 8.33333%;
  }

  .lg\:gap-2\/12 {
    grid-gap: 16.6667%;
    gap: 16.6667%;
  }

  .lg\:gap-3\/12 {
    grid-gap: 25%;
    gap: 25%;
  }

  .lg\:gap-4\/12 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .lg\:gap-5\/12 {
    grid-gap: 41.6667%;
    gap: 41.6667%;
  }

  .lg\:gap-6\/12 {
    grid-gap: 50%;
    gap: 50%;
  }

  .lg\:gap-7\/12 {
    grid-gap: 58.3333%;
    gap: 58.3333%;
  }

  .lg\:gap-8\/12 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .lg\:gap-9\/12 {
    grid-gap: 75%;
    gap: 75%;
  }

  .lg\:gap-10\/12 {
    grid-gap: 83.3333%;
    gap: 83.3333%;
  }

  .lg\:gap-11\/12 {
    grid-gap: 91.6667%;
    gap: 91.6667%;
  }

  .lg\:gap-full {
    grid-gap: 100%;
    gap: 100%;
  }

  .lg\:col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0;
  }

  .lg\:col-gap-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem;
  }

  .lg\:col-gap-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem;
  }

  .lg\:col-gap-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem;
  }

  .lg\:col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }

  .lg\:col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .lg\:col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .lg\:col-gap-7 {
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }

  .lg\:col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }

  .lg\:col-gap-9 {
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }

  .lg\:col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .lg\:col-gap-11 {
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }

  .lg\:col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }

  .lg\:col-gap-13 {
    grid-column-gap: 3.25rem;
    column-gap: 3.25rem;
  }

  .lg\:col-gap-14 {
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }

  .lg\:col-gap-15 {
    grid-column-gap: 3.75rem;
    column-gap: 3.75rem;
  }

  .lg\:col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }

  .lg\:col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }

  .lg\:col-gap-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }

  .lg\:col-gap-28 {
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }

  .lg\:col-gap-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }

  .lg\:col-gap-36 {
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }

  .lg\:col-gap-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }

  .lg\:col-gap-44 {
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }

  .lg\:col-gap-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }

  .lg\:col-gap-52 {
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }

  .lg\:col-gap-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }

  .lg\:col-gap-60 {
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }

  .lg\:col-gap-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }

  .lg\:col-gap-72 {
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }

  .lg\:col-gap-80 {
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }

  .lg\:col-gap-96 {
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }

  .lg\:col-gap-px {
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  .lg\:col-gap-0\.5 {
    grid-column-gap: .125rem;
    column-gap: .125rem;
  }

  .lg\:col-gap-1\.5 {
    grid-column-gap: .375rem;
    column-gap: .375rem;
  }

  .lg\:col-gap-2\.5 {
    grid-column-gap: .625rem;
    column-gap: .625rem;
  }

  .lg\:col-gap-3\.5 {
    grid-column-gap: .875rem;
    column-gap: .875rem;
  }

  .lg\:col-gap-1\/2 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .lg\:col-gap-1\/3 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .lg\:col-gap-2\/3 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .lg\:col-gap-1\/4 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .lg\:col-gap-2\/4 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .lg\:col-gap-3\/4 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .lg\:col-gap-1\/5 {
    grid-column-gap: 20%;
    column-gap: 20%;
  }

  .lg\:col-gap-2\/5 {
    grid-column-gap: 40%;
    column-gap: 40%;
  }

  .lg\:col-gap-3\/5 {
    grid-column-gap: 60%;
    column-gap: 60%;
  }

  .lg\:col-gap-4\/5 {
    grid-column-gap: 80%;
    column-gap: 80%;
  }

  .lg\:col-gap-1\/6 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .lg\:col-gap-2\/6 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .lg\:col-gap-3\/6 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .lg\:col-gap-4\/6 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .lg\:col-gap-5\/6 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .lg\:col-gap-1\/12 {
    grid-column-gap: 8.33333%;
    column-gap: 8.33333%;
  }

  .lg\:col-gap-2\/12 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .lg\:col-gap-3\/12 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .lg\:col-gap-4\/12 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .lg\:col-gap-5\/12 {
    grid-column-gap: 41.6667%;
    column-gap: 41.6667%;
  }

  .lg\:col-gap-6\/12 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .lg\:col-gap-7\/12 {
    grid-column-gap: 58.3333%;
    column-gap: 58.3333%;
  }

  .lg\:col-gap-8\/12 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .lg\:col-gap-9\/12 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .lg\:col-gap-10\/12 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .lg\:col-gap-11\/12 {
    grid-column-gap: 91.6667%;
    column-gap: 91.6667%;
  }

  .lg\:col-gap-full {
    grid-column-gap: 100%;
    column-gap: 100%;
  }

  .lg\:gap-x-0 {
    grid-column-gap: 0;
    column-gap: 0;
  }

  .lg\:gap-x-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem;
  }

  .lg\:gap-x-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem;
  }

  .lg\:gap-x-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem;
  }

  .lg\:gap-x-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }

  .lg\:gap-x-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .lg\:gap-x-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .lg\:gap-x-7 {
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }

  .lg\:gap-x-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }

  .lg\:gap-x-9 {
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }

  .lg\:gap-x-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .lg\:gap-x-11 {
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }

  .lg\:gap-x-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }

  .lg\:gap-x-13 {
    grid-column-gap: 3.25rem;
    column-gap: 3.25rem;
  }

  .lg\:gap-x-14 {
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }

  .lg\:gap-x-15 {
    grid-column-gap: 3.75rem;
    column-gap: 3.75rem;
  }

  .lg\:gap-x-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }

  .lg\:gap-x-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }

  .lg\:gap-x-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }

  .lg\:gap-x-28 {
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }

  .lg\:gap-x-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }

  .lg\:gap-x-36 {
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }

  .lg\:gap-x-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }

  .lg\:gap-x-44 {
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }

  .lg\:gap-x-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }

  .lg\:gap-x-52 {
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }

  .lg\:gap-x-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }

  .lg\:gap-x-60 {
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }

  .lg\:gap-x-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }

  .lg\:gap-x-72 {
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }

  .lg\:gap-x-80 {
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }

  .lg\:gap-x-96 {
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }

  .lg\:gap-x-px {
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  .lg\:gap-x-0\.5 {
    grid-column-gap: .125rem;
    column-gap: .125rem;
  }

  .lg\:gap-x-1\.5 {
    grid-column-gap: .375rem;
    column-gap: .375rem;
  }

  .lg\:gap-x-2\.5 {
    grid-column-gap: .625rem;
    column-gap: .625rem;
  }

  .lg\:gap-x-3\.5 {
    grid-column-gap: .875rem;
    column-gap: .875rem;
  }

  .lg\:gap-x-1\/2 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .lg\:gap-x-1\/3 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .lg\:gap-x-2\/3 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .lg\:gap-x-1\/4 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .lg\:gap-x-2\/4 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .lg\:gap-x-3\/4 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .lg\:gap-x-1\/5 {
    grid-column-gap: 20%;
    column-gap: 20%;
  }

  .lg\:gap-x-2\/5 {
    grid-column-gap: 40%;
    column-gap: 40%;
  }

  .lg\:gap-x-3\/5 {
    grid-column-gap: 60%;
    column-gap: 60%;
  }

  .lg\:gap-x-4\/5 {
    grid-column-gap: 80%;
    column-gap: 80%;
  }

  .lg\:gap-x-1\/6 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .lg\:gap-x-2\/6 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .lg\:gap-x-3\/6 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .lg\:gap-x-4\/6 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .lg\:gap-x-5\/6 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .lg\:gap-x-1\/12 {
    grid-column-gap: 8.33333%;
    column-gap: 8.33333%;
  }

  .lg\:gap-x-2\/12 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .lg\:gap-x-3\/12 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .lg\:gap-x-4\/12 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .lg\:gap-x-5\/12 {
    grid-column-gap: 41.6667%;
    column-gap: 41.6667%;
  }

  .lg\:gap-x-6\/12 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .lg\:gap-x-7\/12 {
    grid-column-gap: 58.3333%;
    column-gap: 58.3333%;
  }

  .lg\:gap-x-8\/12 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .lg\:gap-x-9\/12 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .lg\:gap-x-10\/12 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .lg\:gap-x-11\/12 {
    grid-column-gap: 91.6667%;
    column-gap: 91.6667%;
  }

  .lg\:gap-x-full {
    grid-column-gap: 100%;
    column-gap: 100%;
  }

  .lg\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .lg\:row-gap-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem;
  }

  .lg\:row-gap-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }

  .lg\:row-gap-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem;
  }

  .lg\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .lg\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .lg\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .lg\:row-gap-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }

  .lg\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .lg\:row-gap-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }

  .lg\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .lg\:row-gap-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }

  .lg\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .lg\:row-gap-13 {
    grid-row-gap: 3.25rem;
    row-gap: 3.25rem;
  }

  .lg\:row-gap-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  .lg\:row-gap-15 {
    grid-row-gap: 3.75rem;
    row-gap: 3.75rem;
  }

  .lg\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .lg\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .lg\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .lg\:row-gap-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }

  .lg\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .lg\:row-gap-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }

  .lg\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .lg\:row-gap-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }

  .lg\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .lg\:row-gap-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }

  .lg\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .lg\:row-gap-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }

  .lg\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .lg\:row-gap-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }

  .lg\:row-gap-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }

  .lg\:row-gap-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }

  .lg\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .lg\:row-gap-0\.5 {
    grid-row-gap: .125rem;
    row-gap: .125rem;
  }

  .lg\:row-gap-1\.5 {
    grid-row-gap: .375rem;
    row-gap: .375rem;
  }

  .lg\:row-gap-2\.5 {
    grid-row-gap: .625rem;
    row-gap: .625rem;
  }

  .lg\:row-gap-3\.5 {
    grid-row-gap: .875rem;
    row-gap: .875rem;
  }

  .lg\:row-gap-1\/2 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .lg\:row-gap-1\/3 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .lg\:row-gap-2\/3 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .lg\:row-gap-1\/4 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .lg\:row-gap-2\/4 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .lg\:row-gap-3\/4 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .lg\:row-gap-1\/5 {
    grid-row-gap: 20%;
    row-gap: 20%;
  }

  .lg\:row-gap-2\/5 {
    grid-row-gap: 40%;
    row-gap: 40%;
  }

  .lg\:row-gap-3\/5 {
    grid-row-gap: 60%;
    row-gap: 60%;
  }

  .lg\:row-gap-4\/5 {
    grid-row-gap: 80%;
    row-gap: 80%;
  }

  .lg\:row-gap-1\/6 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .lg\:row-gap-2\/6 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .lg\:row-gap-3\/6 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .lg\:row-gap-4\/6 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .lg\:row-gap-5\/6 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .lg\:row-gap-1\/12 {
    grid-row-gap: 8.33333%;
    row-gap: 8.33333%;
  }

  .lg\:row-gap-2\/12 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .lg\:row-gap-3\/12 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .lg\:row-gap-4\/12 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .lg\:row-gap-5\/12 {
    grid-row-gap: 41.6667%;
    row-gap: 41.6667%;
  }

  .lg\:row-gap-6\/12 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .lg\:row-gap-7\/12 {
    grid-row-gap: 58.3333%;
    row-gap: 58.3333%;
  }

  .lg\:row-gap-8\/12 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .lg\:row-gap-9\/12 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .lg\:row-gap-10\/12 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .lg\:row-gap-11\/12 {
    grid-row-gap: 91.6667%;
    row-gap: 91.6667%;
  }

  .lg\:row-gap-full {
    grid-row-gap: 100%;
    row-gap: 100%;
  }

  .lg\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .lg\:gap-y-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem;
  }

  .lg\:gap-y-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }

  .lg\:gap-y-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem;
  }

  .lg\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .lg\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .lg\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .lg\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }

  .lg\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .lg\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }

  .lg\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .lg\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }

  .lg\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .lg\:gap-y-13 {
    grid-row-gap: 3.25rem;
    row-gap: 3.25rem;
  }

  .lg\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  .lg\:gap-y-15 {
    grid-row-gap: 3.75rem;
    row-gap: 3.75rem;
  }

  .lg\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .lg\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .lg\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .lg\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }

  .lg\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .lg\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }

  .lg\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .lg\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }

  .lg\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .lg\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }

  .lg\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .lg\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }

  .lg\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .lg\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }

  .lg\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }

  .lg\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }

  .lg\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .lg\:gap-y-0\.5 {
    grid-row-gap: .125rem;
    row-gap: .125rem;
  }

  .lg\:gap-y-1\.5 {
    grid-row-gap: .375rem;
    row-gap: .375rem;
  }

  .lg\:gap-y-2\.5 {
    grid-row-gap: .625rem;
    row-gap: .625rem;
  }

  .lg\:gap-y-3\.5 {
    grid-row-gap: .875rem;
    row-gap: .875rem;
  }

  .lg\:gap-y-1\/2 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .lg\:gap-y-1\/3 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .lg\:gap-y-2\/3 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .lg\:gap-y-1\/4 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .lg\:gap-y-2\/4 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .lg\:gap-y-3\/4 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .lg\:gap-y-1\/5 {
    grid-row-gap: 20%;
    row-gap: 20%;
  }

  .lg\:gap-y-2\/5 {
    grid-row-gap: 40%;
    row-gap: 40%;
  }

  .lg\:gap-y-3\/5 {
    grid-row-gap: 60%;
    row-gap: 60%;
  }

  .lg\:gap-y-4\/5 {
    grid-row-gap: 80%;
    row-gap: 80%;
  }

  .lg\:gap-y-1\/6 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .lg\:gap-y-2\/6 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .lg\:gap-y-3\/6 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .lg\:gap-y-4\/6 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .lg\:gap-y-5\/6 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .lg\:gap-y-1\/12 {
    grid-row-gap: 8.33333%;
    row-gap: 8.33333%;
  }

  .lg\:gap-y-2\/12 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .lg\:gap-y-3\/12 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .lg\:gap-y-4\/12 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .lg\:gap-y-5\/12 {
    grid-row-gap: 41.6667%;
    row-gap: 41.6667%;
  }

  .lg\:gap-y-6\/12 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .lg\:gap-y-7\/12 {
    grid-row-gap: 58.3333%;
    row-gap: 58.3333%;
  }

  .lg\:gap-y-8\/12 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .lg\:gap-y-9\/12 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .lg\:gap-y-10\/12 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .lg\:gap-y-11\/12 {
    grid-row-gap: 91.6667%;
    row-gap: 91.6667%;
  }

  .lg\:gap-y-full {
    grid-row-gap: 100%;
    row-gap: 100%;
  }

  .lg\:grid-flow-row {
    grid-auto-flow: row;
  }

  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }

  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .lg\:grid-cols-none {
    grid-template-columns: none;
  }

  .lg\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .lg\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .lg\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .lg\:col-auto {
    grid-column: auto;
  }

  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:col-start-1 {
    grid-column-start: 1;
  }

  .lg\:col-start-2 {
    grid-column-start: 2;
  }

  .lg\:col-start-3 {
    grid-column-start: 3;
  }

  .lg\:col-start-4 {
    grid-column-start: 4;
  }

  .lg\:col-start-5 {
    grid-column-start: 5;
  }

  .lg\:col-start-6 {
    grid-column-start: 6;
  }

  .lg\:col-start-7 {
    grid-column-start: 7;
  }

  .lg\:col-start-8 {
    grid-column-start: 8;
  }

  .lg\:col-start-9 {
    grid-column-start: 9;
  }

  .lg\:col-start-10 {
    grid-column-start: 10;
  }

  .lg\:col-start-11 {
    grid-column-start: 11;
  }

  .lg\:col-start-12 {
    grid-column-start: 12;
  }

  .lg\:col-start-13 {
    grid-column-start: 13;
  }

  .lg\:col-start-auto {
    grid-column-start: auto;
  }

  .lg\:col-end-1 {
    grid-column-end: 1;
  }

  .lg\:col-end-2 {
    grid-column-end: 2;
  }

  .lg\:col-end-3 {
    grid-column-end: 3;
  }

  .lg\:col-end-4 {
    grid-column-end: 4;
  }

  .lg\:col-end-5 {
    grid-column-end: 5;
  }

  .lg\:col-end-6 {
    grid-column-end: 6;
  }

  .lg\:col-end-7 {
    grid-column-end: 7;
  }

  .lg\:col-end-8 {
    grid-column-end: 8;
  }

  .lg\:col-end-9 {
    grid-column-end: 9;
  }

  .lg\:col-end-10 {
    grid-column-end: 10;
  }

  .lg\:col-end-11 {
    grid-column-end: 11;
  }

  .lg\:col-end-12 {
    grid-column-end: 12;
  }

  .lg\:col-end-13 {
    grid-column-end: 13;
  }

  .lg\:col-end-auto {
    grid-column-end: auto;
  }

  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .lg\:grid-rows-none {
    grid-template-rows: none;
  }

  .lg\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .lg\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .lg\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .lg\:row-auto {
    grid-row: auto;
  }

  .lg\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .lg\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .lg\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .lg\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .lg\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .lg\:row-span-full {
    grid-row: 1 / -1;
  }

  .lg\:row-start-1 {
    grid-row-start: 1;
  }

  .lg\:row-start-2 {
    grid-row-start: 2;
  }

  .lg\:row-start-3 {
    grid-row-start: 3;
  }

  .lg\:row-start-4 {
    grid-row-start: 4;
  }

  .lg\:row-start-5 {
    grid-row-start: 5;
  }

  .lg\:row-start-6 {
    grid-row-start: 6;
  }

  .lg\:row-start-7 {
    grid-row-start: 7;
  }

  .lg\:row-start-auto {
    grid-row-start: auto;
  }

  .lg\:row-end-1 {
    grid-row-end: 1;
  }

  .lg\:row-end-2 {
    grid-row-end: 2;
  }

  .lg\:row-end-3 {
    grid-row-end: 3;
  }

  .lg\:row-end-4 {
    grid-row-end: 4;
  }

  .lg\:row-end-5 {
    grid-row-end: 5;
  }

  .lg\:row-end-6 {
    grid-row-end: 6;
  }

  .lg\:row-end-7 {
    grid-row-end: 7;
  }

  .lg\:row-end-auto {
    grid-row-end: auto;
  }

  .lg\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x) ) translateY(var(--transform-translate-y) ) rotate(var(--transform-rotate) ) skewX(var(--transform-skew-x) ) skewY(var(--transform-skew-y) ) scaleX(var(--transform-scale-x) ) scaleY(var(--transform-scale-y) );
  }

  .lg\:transform-none {
    transform: none;
  }

  .lg\:origin-center {
    transform-origin: center;
  }

  .lg\:origin-top {
    transform-origin: top;
  }

  .lg\:origin-top-right {
    transform-origin: 100% 0;
  }

  .lg\:origin-right {
    transform-origin: 100%;
  }

  .lg\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .lg\:origin-bottom {
    transform-origin: bottom;
  }

  .lg\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .lg\:origin-left {
    transform-origin: 0;
  }

  .lg\:origin-top-left {
    transform-origin: 0 0;
  }

  .lg\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .lg\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .lg\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .lg\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .lg\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .lg\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .lg\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .lg\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .lg\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .lg\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .lg\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .lg\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .lg\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .lg\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .lg\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .lg\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .lg\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .lg\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .lg\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .lg\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .lg\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .lg\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .lg\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .lg\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .lg\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .lg\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .lg\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .lg\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .lg\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .lg\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .lg\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .lg\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .lg\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .lg\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .lg\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .lg\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .lg\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .lg\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .lg\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .lg\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .lg\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .lg\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .lg\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .lg\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .lg\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .lg\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .lg\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .lg\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .lg\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .lg\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .lg\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .lg\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .lg\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .lg\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .lg\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .lg\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .lg\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .lg\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .lg\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .lg\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .lg\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .lg\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .lg\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .lg\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .lg\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .lg\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .lg\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .lg\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .lg\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .lg\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .lg\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .lg\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .lg\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .lg\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .lg\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .lg\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .lg\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .lg\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .lg\:rotate-0 {
    --transform-rotate: 0;
  }

  .lg\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .lg\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .lg\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .lg\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .lg\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .lg\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .lg\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .lg\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .lg\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .lg\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .lg\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .lg\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .lg\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .lg\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .lg\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .lg\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .lg\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .lg\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .lg\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .lg\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .lg\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .lg\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .lg\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .lg\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .lg\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .lg\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .lg\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .lg\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .lg\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .lg\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .lg\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .lg\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .lg\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .lg\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .lg\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .lg\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .lg\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .lg\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .lg\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .lg\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .lg\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .lg\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .lg\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .lg\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .lg\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .lg\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .lg\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .lg\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .lg\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .lg\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .lg\:translate-x-0 {
    --transform-translate-x: 0;
  }

  .lg\:translate-x-1 {
    --transform-translate-x: .25rem;
  }

  .lg\:translate-x-2 {
    --transform-translate-x: .5rem;
  }

  .lg\:translate-x-3 {
    --transform-translate-x: .75rem;
  }

  .lg\:translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .lg\:translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .lg\:translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .lg\:translate-x-7 {
    --transform-translate-x: 1.75rem;
  }

  .lg\:translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .lg\:translate-x-9 {
    --transform-translate-x: 2.25rem;
  }

  .lg\:translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .lg\:translate-x-11 {
    --transform-translate-x: 2.75rem;
  }

  .lg\:translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .lg\:translate-x-13 {
    --transform-translate-x: 3.25rem;
  }

  .lg\:translate-x-14 {
    --transform-translate-x: 3.5rem;
  }

  .lg\:translate-x-15 {
    --transform-translate-x: 3.75rem;
  }

  .lg\:translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .lg\:translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .lg\:translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .lg\:translate-x-28 {
    --transform-translate-x: 7rem;
  }

  .lg\:translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .lg\:translate-x-36 {
    --transform-translate-x: 9rem;
  }

  .lg\:translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .lg\:translate-x-44 {
    --transform-translate-x: 11rem;
  }

  .lg\:translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .lg\:translate-x-52 {
    --transform-translate-x: 13rem;
  }

  .lg\:translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .lg\:translate-x-60 {
    --transform-translate-x: 15rem;
  }

  .lg\:translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .lg\:translate-x-72 {
    --transform-translate-x: 18rem;
  }

  .lg\:translate-x-80 {
    --transform-translate-x: 20rem;
  }

  .lg\:translate-x-96 {
    --transform-translate-x: 24rem;
  }

  .lg\:translate-x-px {
    --transform-translate-x: 1px;
  }

  .lg\:translate-x-0\.5 {
    --transform-translate-x: .125rem;
  }

  .lg\:translate-x-1\.5 {
    --transform-translate-x: .375rem;
  }

  .lg\:translate-x-2\.5 {
    --transform-translate-x: .625rem;
  }

  .lg\:translate-x-3\.5 {
    --transform-translate-x: .875rem;
  }

  .lg\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .lg\:translate-x-1\/3 {
    --transform-translate-x: 33.3333%;
  }

  .lg\:translate-x-2\/3 {
    --transform-translate-x: 66.6667%;
  }

  .lg\:translate-x-1\/4 {
    --transform-translate-x: 25%;
  }

  .lg\:translate-x-2\/4 {
    --transform-translate-x: 50%;
  }

  .lg\:translate-x-3\/4 {
    --transform-translate-x: 75%;
  }

  .lg\:translate-x-1\/5 {
    --transform-translate-x: 20%;
  }

  .lg\:translate-x-2\/5 {
    --transform-translate-x: 40%;
  }

  .lg\:translate-x-3\/5 {
    --transform-translate-x: 60%;
  }

  .lg\:translate-x-4\/5 {
    --transform-translate-x: 80%;
  }

  .lg\:translate-x-1\/6 {
    --transform-translate-x: 16.6667%;
  }

  .lg\:translate-x-2\/6 {
    --transform-translate-x: 33.3333%;
  }

  .lg\:translate-x-3\/6 {
    --transform-translate-x: 50%;
  }

  .lg\:translate-x-4\/6 {
    --transform-translate-x: 66.6667%;
  }

  .lg\:translate-x-5\/6 {
    --transform-translate-x: 83.3333%;
  }

  .lg\:translate-x-1\/12 {
    --transform-translate-x: 8.33333%;
  }

  .lg\:translate-x-2\/12 {
    --transform-translate-x: 16.6667%;
  }

  .lg\:translate-x-3\/12 {
    --transform-translate-x: 25%;
  }

  .lg\:translate-x-4\/12 {
    --transform-translate-x: 33.3333%;
  }

  .lg\:translate-x-5\/12 {
    --transform-translate-x: 41.6667%;
  }

  .lg\:translate-x-6\/12 {
    --transform-translate-x: 50%;
  }

  .lg\:translate-x-7\/12 {
    --transform-translate-x: 58.3333%;
  }

  .lg\:translate-x-8\/12 {
    --transform-translate-x: 66.6667%;
  }

  .lg\:translate-x-9\/12 {
    --transform-translate-x: 75%;
  }

  .lg\:translate-x-10\/12 {
    --transform-translate-x: 83.3333%;
  }

  .lg\:translate-x-11\/12 {
    --transform-translate-x: 91.6667%;
  }

  .lg\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .lg\:-translate-x-1 {
    --transform-translate-x: -.25rem;
  }

  .lg\:-translate-x-2 {
    --transform-translate-x: -.5rem;
  }

  .lg\:-translate-x-3 {
    --transform-translate-x: -.75rem;
  }

  .lg\:-translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .lg\:-translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .lg\:-translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .lg\:-translate-x-7 {
    --transform-translate-x: -1.75rem;
  }

  .lg\:-translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .lg\:-translate-x-9 {
    --transform-translate-x: -2.25rem;
  }

  .lg\:-translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .lg\:-translate-x-11 {
    --transform-translate-x: -2.75rem;
  }

  .lg\:-translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .lg\:-translate-x-13 {
    --transform-translate-x: -3.25rem;
  }

  .lg\:-translate-x-14 {
    --transform-translate-x: -3.5rem;
  }

  .lg\:-translate-x-15 {
    --transform-translate-x: -3.75rem;
  }

  .lg\:-translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .lg\:-translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .lg\:-translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .lg\:-translate-x-28 {
    --transform-translate-x: -7rem;
  }

  .lg\:-translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .lg\:-translate-x-36 {
    --transform-translate-x: -9rem;
  }

  .lg\:-translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .lg\:-translate-x-44 {
    --transform-translate-x: -11rem;
  }

  .lg\:-translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .lg\:-translate-x-52 {
    --transform-translate-x: -13rem;
  }

  .lg\:-translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .lg\:-translate-x-60 {
    --transform-translate-x: -15rem;
  }

  .lg\:-translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .lg\:-translate-x-72 {
    --transform-translate-x: -18rem;
  }

  .lg\:-translate-x-80 {
    --transform-translate-x: -20rem;
  }

  .lg\:-translate-x-96 {
    --transform-translate-x: -24rem;
  }

  .lg\:-translate-x-px {
    --transform-translate-x: -1px;
  }

  .lg\:-translate-x-0\.5 {
    --transform-translate-x: -.125rem;
  }

  .lg\:-translate-x-1\.5 {
    --transform-translate-x: -.375rem;
  }

  .lg\:-translate-x-2\.5 {
    --transform-translate-x: -.625rem;
  }

  .lg\:-translate-x-3\.5 {
    --transform-translate-x: -.875rem;
  }

  .lg\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .lg\:-translate-x-1\/3 {
    --transform-translate-x: -33.3333%;
  }

  .lg\:-translate-x-2\/3 {
    --transform-translate-x: -66.6667%;
  }

  .lg\:-translate-x-1\/4 {
    --transform-translate-x: -25%;
  }

  .lg\:-translate-x-2\/4 {
    --transform-translate-x: -50%;
  }

  .lg\:-translate-x-3\/4 {
    --transform-translate-x: -75%;
  }

  .lg\:-translate-x-1\/5 {
    --transform-translate-x: -20%;
  }

  .lg\:-translate-x-2\/5 {
    --transform-translate-x: -40%;
  }

  .lg\:-translate-x-3\/5 {
    --transform-translate-x: -60%;
  }

  .lg\:-translate-x-4\/5 {
    --transform-translate-x: -80%;
  }

  .lg\:-translate-x-1\/6 {
    --transform-translate-x: -16.6667%;
  }

  .lg\:-translate-x-2\/6 {
    --transform-translate-x: -33.3333%;
  }

  .lg\:-translate-x-3\/6 {
    --transform-translate-x: -50%;
  }

  .lg\:-translate-x-4\/6 {
    --transform-translate-x: -66.6667%;
  }

  .lg\:-translate-x-5\/6 {
    --transform-translate-x: -83.3333%;
  }

  .lg\:-translate-x-1\/12 {
    --transform-translate-x: -8.33333%;
  }

  .lg\:-translate-x-2\/12 {
    --transform-translate-x: -16.6667%;
  }

  .lg\:-translate-x-3\/12 {
    --transform-translate-x: -25%;
  }

  .lg\:-translate-x-4\/12 {
    --transform-translate-x: -33.3333%;
  }

  .lg\:-translate-x-5\/12 {
    --transform-translate-x: -41.6667%;
  }

  .lg\:-translate-x-6\/12 {
    --transform-translate-x: -50%;
  }

  .lg\:-translate-x-7\/12 {
    --transform-translate-x: -58.3333%;
  }

  .lg\:-translate-x-8\/12 {
    --transform-translate-x: -66.6667%;
  }

  .lg\:-translate-x-9\/12 {
    --transform-translate-x: -75%;
  }

  .lg\:-translate-x-10\/12 {
    --transform-translate-x: -83.3333%;
  }

  .lg\:-translate-x-11\/12 {
    --transform-translate-x: -91.6667%;
  }

  .lg\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .lg\:translate-y-0 {
    --transform-translate-y: 0;
  }

  .lg\:translate-y-1 {
    --transform-translate-y: .25rem;
  }

  .lg\:translate-y-2 {
    --transform-translate-y: .5rem;
  }

  .lg\:translate-y-3 {
    --transform-translate-y: .75rem;
  }

  .lg\:translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .lg\:translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .lg\:translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .lg\:translate-y-7 {
    --transform-translate-y: 1.75rem;
  }

  .lg\:translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .lg\:translate-y-9 {
    --transform-translate-y: 2.25rem;
  }

  .lg\:translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .lg\:translate-y-11 {
    --transform-translate-y: 2.75rem;
  }

  .lg\:translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .lg\:translate-y-13 {
    --transform-translate-y: 3.25rem;
  }

  .lg\:translate-y-14 {
    --transform-translate-y: 3.5rem;
  }

  .lg\:translate-y-15 {
    --transform-translate-y: 3.75rem;
  }

  .lg\:translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .lg\:translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .lg\:translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .lg\:translate-y-28 {
    --transform-translate-y: 7rem;
  }

  .lg\:translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .lg\:translate-y-36 {
    --transform-translate-y: 9rem;
  }

  .lg\:translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .lg\:translate-y-44 {
    --transform-translate-y: 11rem;
  }

  .lg\:translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .lg\:translate-y-52 {
    --transform-translate-y: 13rem;
  }

  .lg\:translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .lg\:translate-y-60 {
    --transform-translate-y: 15rem;
  }

  .lg\:translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .lg\:translate-y-72 {
    --transform-translate-y: 18rem;
  }

  .lg\:translate-y-80 {
    --transform-translate-y: 20rem;
  }

  .lg\:translate-y-96 {
    --transform-translate-y: 24rem;
  }

  .lg\:translate-y-px {
    --transform-translate-y: 1px;
  }

  .lg\:translate-y-0\.5 {
    --transform-translate-y: .125rem;
  }

  .lg\:translate-y-1\.5 {
    --transform-translate-y: .375rem;
  }

  .lg\:translate-y-2\.5 {
    --transform-translate-y: .625rem;
  }

  .lg\:translate-y-3\.5 {
    --transform-translate-y: .875rem;
  }

  .lg\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .lg\:translate-y-1\/3 {
    --transform-translate-y: 33.3333%;
  }

  .lg\:translate-y-2\/3 {
    --transform-translate-y: 66.6667%;
  }

  .lg\:translate-y-1\/4 {
    --transform-translate-y: 25%;
  }

  .lg\:translate-y-2\/4 {
    --transform-translate-y: 50%;
  }

  .lg\:translate-y-3\/4 {
    --transform-translate-y: 75%;
  }

  .lg\:translate-y-1\/5 {
    --transform-translate-y: 20%;
  }

  .lg\:translate-y-2\/5 {
    --transform-translate-y: 40%;
  }

  .lg\:translate-y-3\/5 {
    --transform-translate-y: 60%;
  }

  .lg\:translate-y-4\/5 {
    --transform-translate-y: 80%;
  }

  .lg\:translate-y-1\/6 {
    --transform-translate-y: 16.6667%;
  }

  .lg\:translate-y-2\/6 {
    --transform-translate-y: 33.3333%;
  }

  .lg\:translate-y-3\/6 {
    --transform-translate-y: 50%;
  }

  .lg\:translate-y-4\/6 {
    --transform-translate-y: 66.6667%;
  }

  .lg\:translate-y-5\/6 {
    --transform-translate-y: 83.3333%;
  }

  .lg\:translate-y-1\/12 {
    --transform-translate-y: 8.33333%;
  }

  .lg\:translate-y-2\/12 {
    --transform-translate-y: 16.6667%;
  }

  .lg\:translate-y-3\/12 {
    --transform-translate-y: 25%;
  }

  .lg\:translate-y-4\/12 {
    --transform-translate-y: 33.3333%;
  }

  .lg\:translate-y-5\/12 {
    --transform-translate-y: 41.6667%;
  }

  .lg\:translate-y-6\/12 {
    --transform-translate-y: 50%;
  }

  .lg\:translate-y-7\/12 {
    --transform-translate-y: 58.3333%;
  }

  .lg\:translate-y-8\/12 {
    --transform-translate-y: 66.6667%;
  }

  .lg\:translate-y-9\/12 {
    --transform-translate-y: 75%;
  }

  .lg\:translate-y-10\/12 {
    --transform-translate-y: 83.3333%;
  }

  .lg\:translate-y-11\/12 {
    --transform-translate-y: 91.6667%;
  }

  .lg\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .lg\:-translate-y-1 {
    --transform-translate-y: -.25rem;
  }

  .lg\:-translate-y-2 {
    --transform-translate-y: -.5rem;
  }

  .lg\:-translate-y-3 {
    --transform-translate-y: -.75rem;
  }

  .lg\:-translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .lg\:-translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .lg\:-translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .lg\:-translate-y-7 {
    --transform-translate-y: -1.75rem;
  }

  .lg\:-translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .lg\:-translate-y-9 {
    --transform-translate-y: -2.25rem;
  }

  .lg\:-translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .lg\:-translate-y-11 {
    --transform-translate-y: -2.75rem;
  }

  .lg\:-translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .lg\:-translate-y-13 {
    --transform-translate-y: -3.25rem;
  }

  .lg\:-translate-y-14 {
    --transform-translate-y: -3.5rem;
  }

  .lg\:-translate-y-15 {
    --transform-translate-y: -3.75rem;
  }

  .lg\:-translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .lg\:-translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .lg\:-translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .lg\:-translate-y-28 {
    --transform-translate-y: -7rem;
  }

  .lg\:-translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .lg\:-translate-y-36 {
    --transform-translate-y: -9rem;
  }

  .lg\:-translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .lg\:-translate-y-44 {
    --transform-translate-y: -11rem;
  }

  .lg\:-translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .lg\:-translate-y-52 {
    --transform-translate-y: -13rem;
  }

  .lg\:-translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .lg\:-translate-y-60 {
    --transform-translate-y: -15rem;
  }

  .lg\:-translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .lg\:-translate-y-72 {
    --transform-translate-y: -18rem;
  }

  .lg\:-translate-y-80 {
    --transform-translate-y: -20rem;
  }

  .lg\:-translate-y-96 {
    --transform-translate-y: -24rem;
  }

  .lg\:-translate-y-px {
    --transform-translate-y: -1px;
  }

  .lg\:-translate-y-0\.5 {
    --transform-translate-y: -.125rem;
  }

  .lg\:-translate-y-1\.5 {
    --transform-translate-y: -.375rem;
  }

  .lg\:-translate-y-2\.5 {
    --transform-translate-y: -.625rem;
  }

  .lg\:-translate-y-3\.5 {
    --transform-translate-y: -.875rem;
  }

  .lg\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .lg\:-translate-y-1\/3 {
    --transform-translate-y: -33.3333%;
  }

  .lg\:-translate-y-2\/3 {
    --transform-translate-y: -66.6667%;
  }

  .lg\:-translate-y-1\/4 {
    --transform-translate-y: -25%;
  }

  .lg\:-translate-y-2\/4 {
    --transform-translate-y: -50%;
  }

  .lg\:-translate-y-3\/4 {
    --transform-translate-y: -75%;
  }

  .lg\:-translate-y-1\/5 {
    --transform-translate-y: -20%;
  }

  .lg\:-translate-y-2\/5 {
    --transform-translate-y: -40%;
  }

  .lg\:-translate-y-3\/5 {
    --transform-translate-y: -60%;
  }

  .lg\:-translate-y-4\/5 {
    --transform-translate-y: -80%;
  }

  .lg\:-translate-y-1\/6 {
    --transform-translate-y: -16.6667%;
  }

  .lg\:-translate-y-2\/6 {
    --transform-translate-y: -33.3333%;
  }

  .lg\:-translate-y-3\/6 {
    --transform-translate-y: -50%;
  }

  .lg\:-translate-y-4\/6 {
    --transform-translate-y: -66.6667%;
  }

  .lg\:-translate-y-5\/6 {
    --transform-translate-y: -83.3333%;
  }

  .lg\:-translate-y-1\/12 {
    --transform-translate-y: -8.33333%;
  }

  .lg\:-translate-y-2\/12 {
    --transform-translate-y: -16.6667%;
  }

  .lg\:-translate-y-3\/12 {
    --transform-translate-y: -25%;
  }

  .lg\:-translate-y-4\/12 {
    --transform-translate-y: -33.3333%;
  }

  .lg\:-translate-y-5\/12 {
    --transform-translate-y: -41.6667%;
  }

  .lg\:-translate-y-6\/12 {
    --transform-translate-y: -50%;
  }

  .lg\:-translate-y-7\/12 {
    --transform-translate-y: -58.3333%;
  }

  .lg\:-translate-y-8\/12 {
    --transform-translate-y: -66.6667%;
  }

  .lg\:-translate-y-9\/12 {
    --transform-translate-y: -75%;
  }

  .lg\:-translate-y-10\/12 {
    --transform-translate-y: -83.3333%;
  }

  .lg\:-translate-y-11\/12 {
    --transform-translate-y: -91.6667%;
  }

  .lg\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .lg\:hover\:translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .lg\:hover\:translate-x-1:hover {
    --transform-translate-x: .25rem;
  }

  .lg\:hover\:translate-x-2:hover {
    --transform-translate-x: .5rem;
  }

  .lg\:hover\:translate-x-3:hover {
    --transform-translate-x: .75rem;
  }

  .lg\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .lg\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .lg\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .lg\:hover\:translate-x-7:hover {
    --transform-translate-x: 1.75rem;
  }

  .lg\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .lg\:hover\:translate-x-9:hover {
    --transform-translate-x: 2.25rem;
  }

  .lg\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .lg\:hover\:translate-x-11:hover {
    --transform-translate-x: 2.75rem;
  }

  .lg\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .lg\:hover\:translate-x-13:hover {
    --transform-translate-x: 3.25rem;
  }

  .lg\:hover\:translate-x-14:hover {
    --transform-translate-x: 3.5rem;
  }

  .lg\:hover\:translate-x-15:hover {
    --transform-translate-x: 3.75rem;
  }

  .lg\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .lg\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .lg\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .lg\:hover\:translate-x-28:hover {
    --transform-translate-x: 7rem;
  }

  .lg\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .lg\:hover\:translate-x-36:hover {
    --transform-translate-x: 9rem;
  }

  .lg\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .lg\:hover\:translate-x-44:hover {
    --transform-translate-x: 11rem;
  }

  .lg\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .lg\:hover\:translate-x-52:hover {
    --transform-translate-x: 13rem;
  }

  .lg\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .lg\:hover\:translate-x-60:hover {
    --transform-translate-x: 15rem;
  }

  .lg\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .lg\:hover\:translate-x-72:hover {
    --transform-translate-x: 18rem;
  }

  .lg\:hover\:translate-x-80:hover {
    --transform-translate-x: 20rem;
  }

  .lg\:hover\:translate-x-96:hover {
    --transform-translate-x: 24rem;
  }

  .lg\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .lg\:hover\:translate-x-0\.5:hover {
    --transform-translate-x: .125rem;
  }

  .lg\:hover\:translate-x-1\.5:hover {
    --transform-translate-x: .375rem;
  }

  .lg\:hover\:translate-x-2\.5:hover {
    --transform-translate-x: .625rem;
  }

  .lg\:hover\:translate-x-3\.5:hover {
    --transform-translate-x: .875rem;
  }

  .lg\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .lg\:hover\:translate-x-1\/3:hover {
    --transform-translate-x: 33.3333%;
  }

  .lg\:hover\:translate-x-2\/3:hover {
    --transform-translate-x: 66.6667%;
  }

  .lg\:hover\:translate-x-1\/4:hover {
    --transform-translate-x: 25%;
  }

  .lg\:hover\:translate-x-2\/4:hover {
    --transform-translate-x: 50%;
  }

  .lg\:hover\:translate-x-3\/4:hover {
    --transform-translate-x: 75%;
  }

  .lg\:hover\:translate-x-1\/5:hover {
    --transform-translate-x: 20%;
  }

  .lg\:hover\:translate-x-2\/5:hover {
    --transform-translate-x: 40%;
  }

  .lg\:hover\:translate-x-3\/5:hover {
    --transform-translate-x: 60%;
  }

  .lg\:hover\:translate-x-4\/5:hover {
    --transform-translate-x: 80%;
  }

  .lg\:hover\:translate-x-1\/6:hover {
    --transform-translate-x: 16.6667%;
  }

  .lg\:hover\:translate-x-2\/6:hover {
    --transform-translate-x: 33.3333%;
  }

  .lg\:hover\:translate-x-3\/6:hover {
    --transform-translate-x: 50%;
  }

  .lg\:hover\:translate-x-4\/6:hover {
    --transform-translate-x: 66.6667%;
  }

  .lg\:hover\:translate-x-5\/6:hover {
    --transform-translate-x: 83.3333%;
  }

  .lg\:hover\:translate-x-1\/12:hover {
    --transform-translate-x: 8.33333%;
  }

  .lg\:hover\:translate-x-2\/12:hover {
    --transform-translate-x: 16.6667%;
  }

  .lg\:hover\:translate-x-3\/12:hover {
    --transform-translate-x: 25%;
  }

  .lg\:hover\:translate-x-4\/12:hover {
    --transform-translate-x: 33.3333%;
  }

  .lg\:hover\:translate-x-5\/12:hover {
    --transform-translate-x: 41.6667%;
  }

  .lg\:hover\:translate-x-6\/12:hover {
    --transform-translate-x: 50%;
  }

  .lg\:hover\:translate-x-7\/12:hover {
    --transform-translate-x: 58.3333%;
  }

  .lg\:hover\:translate-x-8\/12:hover {
    --transform-translate-x: 66.6667%;
  }

  .lg\:hover\:translate-x-9\/12:hover {
    --transform-translate-x: 75%;
  }

  .lg\:hover\:translate-x-10\/12:hover {
    --transform-translate-x: 83.3333%;
  }

  .lg\:hover\:translate-x-11\/12:hover {
    --transform-translate-x: 91.6667%;
  }

  .lg\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .lg\:hover\:-translate-x-1:hover {
    --transform-translate-x: -.25rem;
  }

  .lg\:hover\:-translate-x-2:hover {
    --transform-translate-x: -.5rem;
  }

  .lg\:hover\:-translate-x-3:hover {
    --transform-translate-x: -.75rem;
  }

  .lg\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .lg\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .lg\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .lg\:hover\:-translate-x-7:hover {
    --transform-translate-x: -1.75rem;
  }

  .lg\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .lg\:hover\:-translate-x-9:hover {
    --transform-translate-x: -2.25rem;
  }

  .lg\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .lg\:hover\:-translate-x-11:hover {
    --transform-translate-x: -2.75rem;
  }

  .lg\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .lg\:hover\:-translate-x-13:hover {
    --transform-translate-x: -3.25rem;
  }

  .lg\:hover\:-translate-x-14:hover {
    --transform-translate-x: -3.5rem;
  }

  .lg\:hover\:-translate-x-15:hover {
    --transform-translate-x: -3.75rem;
  }

  .lg\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .lg\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .lg\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .lg\:hover\:-translate-x-28:hover {
    --transform-translate-x: -7rem;
  }

  .lg\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .lg\:hover\:-translate-x-36:hover {
    --transform-translate-x: -9rem;
  }

  .lg\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .lg\:hover\:-translate-x-44:hover {
    --transform-translate-x: -11rem;
  }

  .lg\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .lg\:hover\:-translate-x-52:hover {
    --transform-translate-x: -13rem;
  }

  .lg\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .lg\:hover\:-translate-x-60:hover {
    --transform-translate-x: -15rem;
  }

  .lg\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .lg\:hover\:-translate-x-72:hover {
    --transform-translate-x: -18rem;
  }

  .lg\:hover\:-translate-x-80:hover {
    --transform-translate-x: -20rem;
  }

  .lg\:hover\:-translate-x-96:hover {
    --transform-translate-x: -24rem;
  }

  .lg\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .lg\:hover\:-translate-x-0\.5:hover {
    --transform-translate-x: -.125rem;
  }

  .lg\:hover\:-translate-x-1\.5:hover {
    --transform-translate-x: -.375rem;
  }

  .lg\:hover\:-translate-x-2\.5:hover {
    --transform-translate-x: -.625rem;
  }

  .lg\:hover\:-translate-x-3\.5:hover {
    --transform-translate-x: -.875rem;
  }

  .lg\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .lg\:hover\:-translate-x-1\/3:hover {
    --transform-translate-x: -33.3333%;
  }

  .lg\:hover\:-translate-x-2\/3:hover {
    --transform-translate-x: -66.6667%;
  }

  .lg\:hover\:-translate-x-1\/4:hover {
    --transform-translate-x: -25%;
  }

  .lg\:hover\:-translate-x-2\/4:hover {
    --transform-translate-x: -50%;
  }

  .lg\:hover\:-translate-x-3\/4:hover {
    --transform-translate-x: -75%;
  }

  .lg\:hover\:-translate-x-1\/5:hover {
    --transform-translate-x: -20%;
  }

  .lg\:hover\:-translate-x-2\/5:hover {
    --transform-translate-x: -40%;
  }

  .lg\:hover\:-translate-x-3\/5:hover {
    --transform-translate-x: -60%;
  }

  .lg\:hover\:-translate-x-4\/5:hover {
    --transform-translate-x: -80%;
  }

  .lg\:hover\:-translate-x-1\/6:hover {
    --transform-translate-x: -16.6667%;
  }

  .lg\:hover\:-translate-x-2\/6:hover {
    --transform-translate-x: -33.3333%;
  }

  .lg\:hover\:-translate-x-3\/6:hover {
    --transform-translate-x: -50%;
  }

  .lg\:hover\:-translate-x-4\/6:hover {
    --transform-translate-x: -66.6667%;
  }

  .lg\:hover\:-translate-x-5\/6:hover {
    --transform-translate-x: -83.3333%;
  }

  .lg\:hover\:-translate-x-1\/12:hover {
    --transform-translate-x: -8.33333%;
  }

  .lg\:hover\:-translate-x-2\/12:hover {
    --transform-translate-x: -16.6667%;
  }

  .lg\:hover\:-translate-x-3\/12:hover {
    --transform-translate-x: -25%;
  }

  .lg\:hover\:-translate-x-4\/12:hover {
    --transform-translate-x: -33.3333%;
  }

  .lg\:hover\:-translate-x-5\/12:hover {
    --transform-translate-x: -41.6667%;
  }

  .lg\:hover\:-translate-x-6\/12:hover {
    --transform-translate-x: -50%;
  }

  .lg\:hover\:-translate-x-7\/12:hover {
    --transform-translate-x: -58.3333%;
  }

  .lg\:hover\:-translate-x-8\/12:hover {
    --transform-translate-x: -66.6667%;
  }

  .lg\:hover\:-translate-x-9\/12:hover {
    --transform-translate-x: -75%;
  }

  .lg\:hover\:-translate-x-10\/12:hover {
    --transform-translate-x: -83.3333%;
  }

  .lg\:hover\:-translate-x-11\/12:hover {
    --transform-translate-x: -91.6667%;
  }

  .lg\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .lg\:hover\:translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .lg\:hover\:translate-y-1:hover {
    --transform-translate-y: .25rem;
  }

  .lg\:hover\:translate-y-2:hover {
    --transform-translate-y: .5rem;
  }

  .lg\:hover\:translate-y-3:hover {
    --transform-translate-y: .75rem;
  }

  .lg\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .lg\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .lg\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .lg\:hover\:translate-y-7:hover {
    --transform-translate-y: 1.75rem;
  }

  .lg\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .lg\:hover\:translate-y-9:hover {
    --transform-translate-y: 2.25rem;
  }

  .lg\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .lg\:hover\:translate-y-11:hover {
    --transform-translate-y: 2.75rem;
  }

  .lg\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .lg\:hover\:translate-y-13:hover {
    --transform-translate-y: 3.25rem;
  }

  .lg\:hover\:translate-y-14:hover {
    --transform-translate-y: 3.5rem;
  }

  .lg\:hover\:translate-y-15:hover {
    --transform-translate-y: 3.75rem;
  }

  .lg\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .lg\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .lg\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .lg\:hover\:translate-y-28:hover {
    --transform-translate-y: 7rem;
  }

  .lg\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .lg\:hover\:translate-y-36:hover {
    --transform-translate-y: 9rem;
  }

  .lg\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .lg\:hover\:translate-y-44:hover {
    --transform-translate-y: 11rem;
  }

  .lg\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .lg\:hover\:translate-y-52:hover {
    --transform-translate-y: 13rem;
  }

  .lg\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .lg\:hover\:translate-y-60:hover {
    --transform-translate-y: 15rem;
  }

  .lg\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .lg\:hover\:translate-y-72:hover {
    --transform-translate-y: 18rem;
  }

  .lg\:hover\:translate-y-80:hover {
    --transform-translate-y: 20rem;
  }

  .lg\:hover\:translate-y-96:hover {
    --transform-translate-y: 24rem;
  }

  .lg\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .lg\:hover\:translate-y-0\.5:hover {
    --transform-translate-y: .125rem;
  }

  .lg\:hover\:translate-y-1\.5:hover {
    --transform-translate-y: .375rem;
  }

  .lg\:hover\:translate-y-2\.5:hover {
    --transform-translate-y: .625rem;
  }

  .lg\:hover\:translate-y-3\.5:hover {
    --transform-translate-y: .875rem;
  }

  .lg\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .lg\:hover\:translate-y-1\/3:hover {
    --transform-translate-y: 33.3333%;
  }

  .lg\:hover\:translate-y-2\/3:hover {
    --transform-translate-y: 66.6667%;
  }

  .lg\:hover\:translate-y-1\/4:hover {
    --transform-translate-y: 25%;
  }

  .lg\:hover\:translate-y-2\/4:hover {
    --transform-translate-y: 50%;
  }

  .lg\:hover\:translate-y-3\/4:hover {
    --transform-translate-y: 75%;
  }

  .lg\:hover\:translate-y-1\/5:hover {
    --transform-translate-y: 20%;
  }

  .lg\:hover\:translate-y-2\/5:hover {
    --transform-translate-y: 40%;
  }

  .lg\:hover\:translate-y-3\/5:hover {
    --transform-translate-y: 60%;
  }

  .lg\:hover\:translate-y-4\/5:hover {
    --transform-translate-y: 80%;
  }

  .lg\:hover\:translate-y-1\/6:hover {
    --transform-translate-y: 16.6667%;
  }

  .lg\:hover\:translate-y-2\/6:hover {
    --transform-translate-y: 33.3333%;
  }

  .lg\:hover\:translate-y-3\/6:hover {
    --transform-translate-y: 50%;
  }

  .lg\:hover\:translate-y-4\/6:hover {
    --transform-translate-y: 66.6667%;
  }

  .lg\:hover\:translate-y-5\/6:hover {
    --transform-translate-y: 83.3333%;
  }

  .lg\:hover\:translate-y-1\/12:hover {
    --transform-translate-y: 8.33333%;
  }

  .lg\:hover\:translate-y-2\/12:hover {
    --transform-translate-y: 16.6667%;
  }

  .lg\:hover\:translate-y-3\/12:hover {
    --transform-translate-y: 25%;
  }

  .lg\:hover\:translate-y-4\/12:hover {
    --transform-translate-y: 33.3333%;
  }

  .lg\:hover\:translate-y-5\/12:hover {
    --transform-translate-y: 41.6667%;
  }

  .lg\:hover\:translate-y-6\/12:hover {
    --transform-translate-y: 50%;
  }

  .lg\:hover\:translate-y-7\/12:hover {
    --transform-translate-y: 58.3333%;
  }

  .lg\:hover\:translate-y-8\/12:hover {
    --transform-translate-y: 66.6667%;
  }

  .lg\:hover\:translate-y-9\/12:hover {
    --transform-translate-y: 75%;
  }

  .lg\:hover\:translate-y-10\/12:hover {
    --transform-translate-y: 83.3333%;
  }

  .lg\:hover\:translate-y-11\/12:hover {
    --transform-translate-y: 91.6667%;
  }

  .lg\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .lg\:hover\:-translate-y-1:hover {
    --transform-translate-y: -.25rem;
  }

  .lg\:hover\:-translate-y-2:hover {
    --transform-translate-y: -.5rem;
  }

  .lg\:hover\:-translate-y-3:hover {
    --transform-translate-y: -.75rem;
  }

  .lg\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .lg\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .lg\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .lg\:hover\:-translate-y-7:hover {
    --transform-translate-y: -1.75rem;
  }

  .lg\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .lg\:hover\:-translate-y-9:hover {
    --transform-translate-y: -2.25rem;
  }

  .lg\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .lg\:hover\:-translate-y-11:hover {
    --transform-translate-y: -2.75rem;
  }

  .lg\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .lg\:hover\:-translate-y-13:hover {
    --transform-translate-y: -3.25rem;
  }

  .lg\:hover\:-translate-y-14:hover {
    --transform-translate-y: -3.5rem;
  }

  .lg\:hover\:-translate-y-15:hover {
    --transform-translate-y: -3.75rem;
  }

  .lg\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .lg\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .lg\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .lg\:hover\:-translate-y-28:hover {
    --transform-translate-y: -7rem;
  }

  .lg\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .lg\:hover\:-translate-y-36:hover {
    --transform-translate-y: -9rem;
  }

  .lg\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .lg\:hover\:-translate-y-44:hover {
    --transform-translate-y: -11rem;
  }

  .lg\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .lg\:hover\:-translate-y-52:hover {
    --transform-translate-y: -13rem;
  }

  .lg\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .lg\:hover\:-translate-y-60:hover {
    --transform-translate-y: -15rem;
  }

  .lg\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .lg\:hover\:-translate-y-72:hover {
    --transform-translate-y: -18rem;
  }

  .lg\:hover\:-translate-y-80:hover {
    --transform-translate-y: -20rem;
  }

  .lg\:hover\:-translate-y-96:hover {
    --transform-translate-y: -24rem;
  }

  .lg\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .lg\:hover\:-translate-y-0\.5:hover {
    --transform-translate-y: -.125rem;
  }

  .lg\:hover\:-translate-y-1\.5:hover {
    --transform-translate-y: -.375rem;
  }

  .lg\:hover\:-translate-y-2\.5:hover {
    --transform-translate-y: -.625rem;
  }

  .lg\:hover\:-translate-y-3\.5:hover {
    --transform-translate-y: -.875rem;
  }

  .lg\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .lg\:hover\:-translate-y-1\/3:hover {
    --transform-translate-y: -33.3333%;
  }

  .lg\:hover\:-translate-y-2\/3:hover {
    --transform-translate-y: -66.6667%;
  }

  .lg\:hover\:-translate-y-1\/4:hover {
    --transform-translate-y: -25%;
  }

  .lg\:hover\:-translate-y-2\/4:hover {
    --transform-translate-y: -50%;
  }

  .lg\:hover\:-translate-y-3\/4:hover {
    --transform-translate-y: -75%;
  }

  .lg\:hover\:-translate-y-1\/5:hover {
    --transform-translate-y: -20%;
  }

  .lg\:hover\:-translate-y-2\/5:hover {
    --transform-translate-y: -40%;
  }

  .lg\:hover\:-translate-y-3\/5:hover {
    --transform-translate-y: -60%;
  }

  .lg\:hover\:-translate-y-4\/5:hover {
    --transform-translate-y: -80%;
  }

  .lg\:hover\:-translate-y-1\/6:hover {
    --transform-translate-y: -16.6667%;
  }

  .lg\:hover\:-translate-y-2\/6:hover {
    --transform-translate-y: -33.3333%;
  }

  .lg\:hover\:-translate-y-3\/6:hover {
    --transform-translate-y: -50%;
  }

  .lg\:hover\:-translate-y-4\/6:hover {
    --transform-translate-y: -66.6667%;
  }

  .lg\:hover\:-translate-y-5\/6:hover {
    --transform-translate-y: -83.3333%;
  }

  .lg\:hover\:-translate-y-1\/12:hover {
    --transform-translate-y: -8.33333%;
  }

  .lg\:hover\:-translate-y-2\/12:hover {
    --transform-translate-y: -16.6667%;
  }

  .lg\:hover\:-translate-y-3\/12:hover {
    --transform-translate-y: -25%;
  }

  .lg\:hover\:-translate-y-4\/12:hover {
    --transform-translate-y: -33.3333%;
  }

  .lg\:hover\:-translate-y-5\/12:hover {
    --transform-translate-y: -41.6667%;
  }

  .lg\:hover\:-translate-y-6\/12:hover {
    --transform-translate-y: -50%;
  }

  .lg\:hover\:-translate-y-7\/12:hover {
    --transform-translate-y: -58.3333%;
  }

  .lg\:hover\:-translate-y-8\/12:hover {
    --transform-translate-y: -66.6667%;
  }

  .lg\:hover\:-translate-y-9\/12:hover {
    --transform-translate-y: -75%;
  }

  .lg\:hover\:-translate-y-10\/12:hover {
    --transform-translate-y: -83.3333%;
  }

  .lg\:hover\:-translate-y-11\/12:hover {
    --transform-translate-y: -91.6667%;
  }

  .lg\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .lg\:focus\:translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .lg\:focus\:translate-x-1:focus {
    --transform-translate-x: .25rem;
  }

  .lg\:focus\:translate-x-2:focus {
    --transform-translate-x: .5rem;
  }

  .lg\:focus\:translate-x-3:focus {
    --transform-translate-x: .75rem;
  }

  .lg\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .lg\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .lg\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .lg\:focus\:translate-x-7:focus {
    --transform-translate-x: 1.75rem;
  }

  .lg\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .lg\:focus\:translate-x-9:focus {
    --transform-translate-x: 2.25rem;
  }

  .lg\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .lg\:focus\:translate-x-11:focus {
    --transform-translate-x: 2.75rem;
  }

  .lg\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .lg\:focus\:translate-x-13:focus {
    --transform-translate-x: 3.25rem;
  }

  .lg\:focus\:translate-x-14:focus {
    --transform-translate-x: 3.5rem;
  }

  .lg\:focus\:translate-x-15:focus {
    --transform-translate-x: 3.75rem;
  }

  .lg\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .lg\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .lg\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .lg\:focus\:translate-x-28:focus {
    --transform-translate-x: 7rem;
  }

  .lg\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .lg\:focus\:translate-x-36:focus {
    --transform-translate-x: 9rem;
  }

  .lg\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .lg\:focus\:translate-x-44:focus {
    --transform-translate-x: 11rem;
  }

  .lg\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .lg\:focus\:translate-x-52:focus {
    --transform-translate-x: 13rem;
  }

  .lg\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .lg\:focus\:translate-x-60:focus {
    --transform-translate-x: 15rem;
  }

  .lg\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .lg\:focus\:translate-x-72:focus {
    --transform-translate-x: 18rem;
  }

  .lg\:focus\:translate-x-80:focus {
    --transform-translate-x: 20rem;
  }

  .lg\:focus\:translate-x-96:focus {
    --transform-translate-x: 24rem;
  }

  .lg\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .lg\:focus\:translate-x-0\.5:focus {
    --transform-translate-x: .125rem;
  }

  .lg\:focus\:translate-x-1\.5:focus {
    --transform-translate-x: .375rem;
  }

  .lg\:focus\:translate-x-2\.5:focus {
    --transform-translate-x: .625rem;
  }

  .lg\:focus\:translate-x-3\.5:focus {
    --transform-translate-x: .875rem;
  }

  .lg\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .lg\:focus\:translate-x-1\/3:focus {
    --transform-translate-x: 33.3333%;
  }

  .lg\:focus\:translate-x-2\/3:focus {
    --transform-translate-x: 66.6667%;
  }

  .lg\:focus\:translate-x-1\/4:focus {
    --transform-translate-x: 25%;
  }

  .lg\:focus\:translate-x-2\/4:focus {
    --transform-translate-x: 50%;
  }

  .lg\:focus\:translate-x-3\/4:focus {
    --transform-translate-x: 75%;
  }

  .lg\:focus\:translate-x-1\/5:focus {
    --transform-translate-x: 20%;
  }

  .lg\:focus\:translate-x-2\/5:focus {
    --transform-translate-x: 40%;
  }

  .lg\:focus\:translate-x-3\/5:focus {
    --transform-translate-x: 60%;
  }

  .lg\:focus\:translate-x-4\/5:focus {
    --transform-translate-x: 80%;
  }

  .lg\:focus\:translate-x-1\/6:focus {
    --transform-translate-x: 16.6667%;
  }

  .lg\:focus\:translate-x-2\/6:focus {
    --transform-translate-x: 33.3333%;
  }

  .lg\:focus\:translate-x-3\/6:focus {
    --transform-translate-x: 50%;
  }

  .lg\:focus\:translate-x-4\/6:focus {
    --transform-translate-x: 66.6667%;
  }

  .lg\:focus\:translate-x-5\/6:focus {
    --transform-translate-x: 83.3333%;
  }

  .lg\:focus\:translate-x-1\/12:focus {
    --transform-translate-x: 8.33333%;
  }

  .lg\:focus\:translate-x-2\/12:focus {
    --transform-translate-x: 16.6667%;
  }

  .lg\:focus\:translate-x-3\/12:focus {
    --transform-translate-x: 25%;
  }

  .lg\:focus\:translate-x-4\/12:focus {
    --transform-translate-x: 33.3333%;
  }

  .lg\:focus\:translate-x-5\/12:focus {
    --transform-translate-x: 41.6667%;
  }

  .lg\:focus\:translate-x-6\/12:focus {
    --transform-translate-x: 50%;
  }

  .lg\:focus\:translate-x-7\/12:focus {
    --transform-translate-x: 58.3333%;
  }

  .lg\:focus\:translate-x-8\/12:focus {
    --transform-translate-x: 66.6667%;
  }

  .lg\:focus\:translate-x-9\/12:focus {
    --transform-translate-x: 75%;
  }

  .lg\:focus\:translate-x-10\/12:focus {
    --transform-translate-x: 83.3333%;
  }

  .lg\:focus\:translate-x-11\/12:focus {
    --transform-translate-x: 91.6667%;
  }

  .lg\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .lg\:focus\:-translate-x-1:focus {
    --transform-translate-x: -.25rem;
  }

  .lg\:focus\:-translate-x-2:focus {
    --transform-translate-x: -.5rem;
  }

  .lg\:focus\:-translate-x-3:focus {
    --transform-translate-x: -.75rem;
  }

  .lg\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .lg\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .lg\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .lg\:focus\:-translate-x-7:focus {
    --transform-translate-x: -1.75rem;
  }

  .lg\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .lg\:focus\:-translate-x-9:focus {
    --transform-translate-x: -2.25rem;
  }

  .lg\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .lg\:focus\:-translate-x-11:focus {
    --transform-translate-x: -2.75rem;
  }

  .lg\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .lg\:focus\:-translate-x-13:focus {
    --transform-translate-x: -3.25rem;
  }

  .lg\:focus\:-translate-x-14:focus {
    --transform-translate-x: -3.5rem;
  }

  .lg\:focus\:-translate-x-15:focus {
    --transform-translate-x: -3.75rem;
  }

  .lg\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .lg\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .lg\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .lg\:focus\:-translate-x-28:focus {
    --transform-translate-x: -7rem;
  }

  .lg\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .lg\:focus\:-translate-x-36:focus {
    --transform-translate-x: -9rem;
  }

  .lg\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .lg\:focus\:-translate-x-44:focus {
    --transform-translate-x: -11rem;
  }

  .lg\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .lg\:focus\:-translate-x-52:focus {
    --transform-translate-x: -13rem;
  }

  .lg\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .lg\:focus\:-translate-x-60:focus {
    --transform-translate-x: -15rem;
  }

  .lg\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .lg\:focus\:-translate-x-72:focus {
    --transform-translate-x: -18rem;
  }

  .lg\:focus\:-translate-x-80:focus {
    --transform-translate-x: -20rem;
  }

  .lg\:focus\:-translate-x-96:focus {
    --transform-translate-x: -24rem;
  }

  .lg\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .lg\:focus\:-translate-x-0\.5:focus {
    --transform-translate-x: -.125rem;
  }

  .lg\:focus\:-translate-x-1\.5:focus {
    --transform-translate-x: -.375rem;
  }

  .lg\:focus\:-translate-x-2\.5:focus {
    --transform-translate-x: -.625rem;
  }

  .lg\:focus\:-translate-x-3\.5:focus {
    --transform-translate-x: -.875rem;
  }

  .lg\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .lg\:focus\:-translate-x-1\/3:focus {
    --transform-translate-x: -33.3333%;
  }

  .lg\:focus\:-translate-x-2\/3:focus {
    --transform-translate-x: -66.6667%;
  }

  .lg\:focus\:-translate-x-1\/4:focus {
    --transform-translate-x: -25%;
  }

  .lg\:focus\:-translate-x-2\/4:focus {
    --transform-translate-x: -50%;
  }

  .lg\:focus\:-translate-x-3\/4:focus {
    --transform-translate-x: -75%;
  }

  .lg\:focus\:-translate-x-1\/5:focus {
    --transform-translate-x: -20%;
  }

  .lg\:focus\:-translate-x-2\/5:focus {
    --transform-translate-x: -40%;
  }

  .lg\:focus\:-translate-x-3\/5:focus {
    --transform-translate-x: -60%;
  }

  .lg\:focus\:-translate-x-4\/5:focus {
    --transform-translate-x: -80%;
  }

  .lg\:focus\:-translate-x-1\/6:focus {
    --transform-translate-x: -16.6667%;
  }

  .lg\:focus\:-translate-x-2\/6:focus {
    --transform-translate-x: -33.3333%;
  }

  .lg\:focus\:-translate-x-3\/6:focus {
    --transform-translate-x: -50%;
  }

  .lg\:focus\:-translate-x-4\/6:focus {
    --transform-translate-x: -66.6667%;
  }

  .lg\:focus\:-translate-x-5\/6:focus {
    --transform-translate-x: -83.3333%;
  }

  .lg\:focus\:-translate-x-1\/12:focus {
    --transform-translate-x: -8.33333%;
  }

  .lg\:focus\:-translate-x-2\/12:focus {
    --transform-translate-x: -16.6667%;
  }

  .lg\:focus\:-translate-x-3\/12:focus {
    --transform-translate-x: -25%;
  }

  .lg\:focus\:-translate-x-4\/12:focus {
    --transform-translate-x: -33.3333%;
  }

  .lg\:focus\:-translate-x-5\/12:focus {
    --transform-translate-x: -41.6667%;
  }

  .lg\:focus\:-translate-x-6\/12:focus {
    --transform-translate-x: -50%;
  }

  .lg\:focus\:-translate-x-7\/12:focus {
    --transform-translate-x: -58.3333%;
  }

  .lg\:focus\:-translate-x-8\/12:focus {
    --transform-translate-x: -66.6667%;
  }

  .lg\:focus\:-translate-x-9\/12:focus {
    --transform-translate-x: -75%;
  }

  .lg\:focus\:-translate-x-10\/12:focus {
    --transform-translate-x: -83.3333%;
  }

  .lg\:focus\:-translate-x-11\/12:focus {
    --transform-translate-x: -91.6667%;
  }

  .lg\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .lg\:focus\:translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .lg\:focus\:translate-y-1:focus {
    --transform-translate-y: .25rem;
  }

  .lg\:focus\:translate-y-2:focus {
    --transform-translate-y: .5rem;
  }

  .lg\:focus\:translate-y-3:focus {
    --transform-translate-y: .75rem;
  }

  .lg\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .lg\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .lg\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .lg\:focus\:translate-y-7:focus {
    --transform-translate-y: 1.75rem;
  }

  .lg\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .lg\:focus\:translate-y-9:focus {
    --transform-translate-y: 2.25rem;
  }

  .lg\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .lg\:focus\:translate-y-11:focus {
    --transform-translate-y: 2.75rem;
  }

  .lg\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .lg\:focus\:translate-y-13:focus {
    --transform-translate-y: 3.25rem;
  }

  .lg\:focus\:translate-y-14:focus {
    --transform-translate-y: 3.5rem;
  }

  .lg\:focus\:translate-y-15:focus {
    --transform-translate-y: 3.75rem;
  }

  .lg\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .lg\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .lg\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .lg\:focus\:translate-y-28:focus {
    --transform-translate-y: 7rem;
  }

  .lg\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .lg\:focus\:translate-y-36:focus {
    --transform-translate-y: 9rem;
  }

  .lg\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .lg\:focus\:translate-y-44:focus {
    --transform-translate-y: 11rem;
  }

  .lg\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .lg\:focus\:translate-y-52:focus {
    --transform-translate-y: 13rem;
  }

  .lg\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .lg\:focus\:translate-y-60:focus {
    --transform-translate-y: 15rem;
  }

  .lg\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .lg\:focus\:translate-y-72:focus {
    --transform-translate-y: 18rem;
  }

  .lg\:focus\:translate-y-80:focus {
    --transform-translate-y: 20rem;
  }

  .lg\:focus\:translate-y-96:focus {
    --transform-translate-y: 24rem;
  }

  .lg\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .lg\:focus\:translate-y-0\.5:focus {
    --transform-translate-y: .125rem;
  }

  .lg\:focus\:translate-y-1\.5:focus {
    --transform-translate-y: .375rem;
  }

  .lg\:focus\:translate-y-2\.5:focus {
    --transform-translate-y: .625rem;
  }

  .lg\:focus\:translate-y-3\.5:focus {
    --transform-translate-y: .875rem;
  }

  .lg\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .lg\:focus\:translate-y-1\/3:focus {
    --transform-translate-y: 33.3333%;
  }

  .lg\:focus\:translate-y-2\/3:focus {
    --transform-translate-y: 66.6667%;
  }

  .lg\:focus\:translate-y-1\/4:focus {
    --transform-translate-y: 25%;
  }

  .lg\:focus\:translate-y-2\/4:focus {
    --transform-translate-y: 50%;
  }

  .lg\:focus\:translate-y-3\/4:focus {
    --transform-translate-y: 75%;
  }

  .lg\:focus\:translate-y-1\/5:focus {
    --transform-translate-y: 20%;
  }

  .lg\:focus\:translate-y-2\/5:focus {
    --transform-translate-y: 40%;
  }

  .lg\:focus\:translate-y-3\/5:focus {
    --transform-translate-y: 60%;
  }

  .lg\:focus\:translate-y-4\/5:focus {
    --transform-translate-y: 80%;
  }

  .lg\:focus\:translate-y-1\/6:focus {
    --transform-translate-y: 16.6667%;
  }

  .lg\:focus\:translate-y-2\/6:focus {
    --transform-translate-y: 33.3333%;
  }

  .lg\:focus\:translate-y-3\/6:focus {
    --transform-translate-y: 50%;
  }

  .lg\:focus\:translate-y-4\/6:focus {
    --transform-translate-y: 66.6667%;
  }

  .lg\:focus\:translate-y-5\/6:focus {
    --transform-translate-y: 83.3333%;
  }

  .lg\:focus\:translate-y-1\/12:focus {
    --transform-translate-y: 8.33333%;
  }

  .lg\:focus\:translate-y-2\/12:focus {
    --transform-translate-y: 16.6667%;
  }

  .lg\:focus\:translate-y-3\/12:focus {
    --transform-translate-y: 25%;
  }

  .lg\:focus\:translate-y-4\/12:focus {
    --transform-translate-y: 33.3333%;
  }

  .lg\:focus\:translate-y-5\/12:focus {
    --transform-translate-y: 41.6667%;
  }

  .lg\:focus\:translate-y-6\/12:focus {
    --transform-translate-y: 50%;
  }

  .lg\:focus\:translate-y-7\/12:focus {
    --transform-translate-y: 58.3333%;
  }

  .lg\:focus\:translate-y-8\/12:focus {
    --transform-translate-y: 66.6667%;
  }

  .lg\:focus\:translate-y-9\/12:focus {
    --transform-translate-y: 75%;
  }

  .lg\:focus\:translate-y-10\/12:focus {
    --transform-translate-y: 83.3333%;
  }

  .lg\:focus\:translate-y-11\/12:focus {
    --transform-translate-y: 91.6667%;
  }

  .lg\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .lg\:focus\:-translate-y-1:focus {
    --transform-translate-y: -.25rem;
  }

  .lg\:focus\:-translate-y-2:focus {
    --transform-translate-y: -.5rem;
  }

  .lg\:focus\:-translate-y-3:focus {
    --transform-translate-y: -.75rem;
  }

  .lg\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .lg\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .lg\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .lg\:focus\:-translate-y-7:focus {
    --transform-translate-y: -1.75rem;
  }

  .lg\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .lg\:focus\:-translate-y-9:focus {
    --transform-translate-y: -2.25rem;
  }

  .lg\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .lg\:focus\:-translate-y-11:focus {
    --transform-translate-y: -2.75rem;
  }

  .lg\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .lg\:focus\:-translate-y-13:focus {
    --transform-translate-y: -3.25rem;
  }

  .lg\:focus\:-translate-y-14:focus {
    --transform-translate-y: -3.5rem;
  }

  .lg\:focus\:-translate-y-15:focus {
    --transform-translate-y: -3.75rem;
  }

  .lg\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .lg\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .lg\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .lg\:focus\:-translate-y-28:focus {
    --transform-translate-y: -7rem;
  }

  .lg\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .lg\:focus\:-translate-y-36:focus {
    --transform-translate-y: -9rem;
  }

  .lg\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .lg\:focus\:-translate-y-44:focus {
    --transform-translate-y: -11rem;
  }

  .lg\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .lg\:focus\:-translate-y-52:focus {
    --transform-translate-y: -13rem;
  }

  .lg\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .lg\:focus\:-translate-y-60:focus {
    --transform-translate-y: -15rem;
  }

  .lg\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .lg\:focus\:-translate-y-72:focus {
    --transform-translate-y: -18rem;
  }

  .lg\:focus\:-translate-y-80:focus {
    --transform-translate-y: -20rem;
  }

  .lg\:focus\:-translate-y-96:focus {
    --transform-translate-y: -24rem;
  }

  .lg\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .lg\:focus\:-translate-y-0\.5:focus {
    --transform-translate-y: -.125rem;
  }

  .lg\:focus\:-translate-y-1\.5:focus {
    --transform-translate-y: -.375rem;
  }

  .lg\:focus\:-translate-y-2\.5:focus {
    --transform-translate-y: -.625rem;
  }

  .lg\:focus\:-translate-y-3\.5:focus {
    --transform-translate-y: -.875rem;
  }

  .lg\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .lg\:focus\:-translate-y-1\/3:focus {
    --transform-translate-y: -33.3333%;
  }

  .lg\:focus\:-translate-y-2\/3:focus {
    --transform-translate-y: -66.6667%;
  }

  .lg\:focus\:-translate-y-1\/4:focus {
    --transform-translate-y: -25%;
  }

  .lg\:focus\:-translate-y-2\/4:focus {
    --transform-translate-y: -50%;
  }

  .lg\:focus\:-translate-y-3\/4:focus {
    --transform-translate-y: -75%;
  }

  .lg\:focus\:-translate-y-1\/5:focus {
    --transform-translate-y: -20%;
  }

  .lg\:focus\:-translate-y-2\/5:focus {
    --transform-translate-y: -40%;
  }

  .lg\:focus\:-translate-y-3\/5:focus {
    --transform-translate-y: -60%;
  }

  .lg\:focus\:-translate-y-4\/5:focus {
    --transform-translate-y: -80%;
  }

  .lg\:focus\:-translate-y-1\/6:focus {
    --transform-translate-y: -16.6667%;
  }

  .lg\:focus\:-translate-y-2\/6:focus {
    --transform-translate-y: -33.3333%;
  }

  .lg\:focus\:-translate-y-3\/6:focus {
    --transform-translate-y: -50%;
  }

  .lg\:focus\:-translate-y-4\/6:focus {
    --transform-translate-y: -66.6667%;
  }

  .lg\:focus\:-translate-y-5\/6:focus {
    --transform-translate-y: -83.3333%;
  }

  .lg\:focus\:-translate-y-1\/12:focus {
    --transform-translate-y: -8.33333%;
  }

  .lg\:focus\:-translate-y-2\/12:focus {
    --transform-translate-y: -16.6667%;
  }

  .lg\:focus\:-translate-y-3\/12:focus {
    --transform-translate-y: -25%;
  }

  .lg\:focus\:-translate-y-4\/12:focus {
    --transform-translate-y: -33.3333%;
  }

  .lg\:focus\:-translate-y-5\/12:focus {
    --transform-translate-y: -41.6667%;
  }

  .lg\:focus\:-translate-y-6\/12:focus {
    --transform-translate-y: -50%;
  }

  .lg\:focus\:-translate-y-7\/12:focus {
    --transform-translate-y: -58.3333%;
  }

  .lg\:focus\:-translate-y-8\/12:focus {
    --transform-translate-y: -66.6667%;
  }

  .lg\:focus\:-translate-y-9\/12:focus {
    --transform-translate-y: -75%;
  }

  .lg\:focus\:-translate-y-10\/12:focus {
    --transform-translate-y: -83.3333%;
  }

  .lg\:focus\:-translate-y-11\/12:focus {
    --transform-translate-y: -91.6667%;
  }

  .lg\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .lg\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .lg\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .lg\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .lg\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .lg\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .lg\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .lg\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .lg\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .lg\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .lg\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .lg\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .lg\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .lg\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .lg\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .lg\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .lg\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .lg\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .lg\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .lg\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .lg\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .lg\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .lg\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .lg\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .lg\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .lg\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .lg\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .lg\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .lg\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .lg\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .lg\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .lg\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .lg\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .lg\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .lg\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .lg\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .lg\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .lg\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .lg\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .lg\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .lg\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .lg\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .lg\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .lg\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .lg\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .lg\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .lg\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .lg\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .lg\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .lg\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .lg\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .lg\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .lg\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .lg\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .lg\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .lg\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .lg\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .lg\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .lg\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .lg\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .lg\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .lg\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .lg\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .lg\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .lg\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .lg\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .lg\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .lg\:transition-none {
    transition-property: none;
  }

  .lg\:transition-all {
    transition-property: all;
  }

  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .lg\:transition-opacity {
    transition-property: opacity;
  }

  .lg\:transition-shadow {
    transition-property: box-shadow;
  }

  .lg\:transition-transform {
    transition-property: transform;
  }

  .lg\:ease-linear {
    transition-timing-function: linear;
  }

  .lg\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .lg\:duration-75 {
    transition-duration: 75ms;
  }

  .lg\:duration-100 {
    transition-duration: .1s;
  }

  .lg\:duration-150 {
    transition-duration: .15s;
  }

  .lg\:duration-200 {
    transition-duration: .2s;
  }

  .lg\:duration-300 {
    transition-duration: .3s;
  }

  .lg\:duration-500 {
    transition-duration: .5s;
  }

  .lg\:duration-700 {
    transition-duration: .7s;
  }

  .lg\:duration-1000 {
    transition-duration: 1s;
  }

  .lg\:delay-75 {
    transition-delay: 75ms;
  }

  .lg\:delay-100 {
    transition-delay: .1s;
  }

  .lg\:delay-150 {
    transition-delay: .15s;
  }

  .lg\:delay-200 {
    transition-delay: .2s;
  }

  .lg\:delay-300 {
    transition-delay: .3s;
  }

  .lg\:delay-500 {
    transition-delay: .5s;
  }

  .lg\:delay-700 {
    transition-delay: .7s;
  }

  .lg\:delay-1000 {
    transition-delay: 1s;
  }

  .lg\:animate-none {
    animation: none;
  }

  .lg\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .lg\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
  }

  .lg\:animate-pulse {
    animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
  }

  .lg\:animate-bounce {
    animation: bounce 1s infinite;
  }
}

@media (min-width: 1280px) {
  .xl\:container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }

  .xl\:space-y-0 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(0px * var(--space-y-reverse) );
  }

  .xl\:space-x-0 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(0px * var(--space-x-reverse) );
    margin-left: calc(0px * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.25rem * var(--space-y-reverse) );
  }

  .xl\:space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.25rem * var(--space-x-reverse) );
    margin-left: calc(.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.5rem * var(--space-y-reverse) );
  }

  .xl\:space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.5rem * var(--space-x-reverse) );
    margin-left: calc(.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.75rem * var(--space-y-reverse) );
  }

  .xl\:space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.75rem * var(--space-x-reverse) );
    margin-left: calc(.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1rem * var(--space-y-reverse) );
  }

  .xl\:space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1rem * var(--space-x-reverse) );
    margin-left: calc(1rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.25rem * var(--space-y-reverse) );
  }

  .xl\:space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--space-x-reverse) );
    margin-left: calc(1.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.5rem * var(--space-y-reverse) );
  }

  .xl\:space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--space-x-reverse) );
    margin-left: calc(1.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1.75rem * var(--space-y-reverse) );
  }

  .xl\:space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--space-x-reverse) );
    margin-left: calc(1.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2rem * var(--space-y-reverse) );
  }

  .xl\:space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2rem * var(--space-x-reverse) );
    margin-left: calc(2rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-9 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.25rem * var(--space-y-reverse) );
  }

  .xl\:space-x-9 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--space-x-reverse) );
    margin-left: calc(2.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.5rem * var(--space-y-reverse) );
  }

  .xl\:space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--space-x-reverse) );
    margin-left: calc(2.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-11 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(2.75rem * var(--space-y-reverse) );
  }

  .xl\:space-x-11 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--space-x-reverse) );
    margin-left: calc(2.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3rem * var(--space-y-reverse) );
  }

  .xl\:space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3rem * var(--space-x-reverse) );
    margin-left: calc(3rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-13 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.25rem * var(--space-y-reverse) );
  }

  .xl\:space-x-13 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.25rem * var(--space-x-reverse) );
    margin-left: calc(3.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.5rem * var(--space-y-reverse) );
  }

  .xl\:space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--space-x-reverse) );
    margin-left: calc(3.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-15 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(3.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(3.75rem * var(--space-y-reverse) );
  }

  .xl\:space-x-15 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(3.75rem * var(--space-x-reverse) );
    margin-left: calc(3.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(4rem * var(--space-y-reverse) );
  }

  .xl\:space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(4rem * var(--space-x-reverse) );
    margin-left: calc(4rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(5rem * var(--space-y-reverse) );
  }

  .xl\:space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(5rem * var(--space-x-reverse) );
    margin-left: calc(5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(6rem * var(--space-y-reverse) );
  }

  .xl\:space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(6rem * var(--space-x-reverse) );
    margin-left: calc(6rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(7rem * var(--space-y-reverse) );
  }

  .xl\:space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(7rem * var(--space-x-reverse) );
    margin-left: calc(7rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(8rem * var(--space-y-reverse) );
  }

  .xl\:space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8rem * var(--space-x-reverse) );
    margin-left: calc(8rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(9rem * var(--space-y-reverse) );
  }

  .xl\:space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(9rem * var(--space-x-reverse) );
    margin-left: calc(9rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(10rem * var(--space-y-reverse) );
  }

  .xl\:space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(10rem * var(--space-x-reverse) );
    margin-left: calc(10rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-44 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(11rem * var(--space-y-reverse) );
  }

  .xl\:space-x-44 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(11rem * var(--space-x-reverse) );
    margin-left: calc(11rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(12rem * var(--space-y-reverse) );
  }

  .xl\:space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(12rem * var(--space-x-reverse) );
    margin-left: calc(12rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-52 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(13rem * var(--space-y-reverse) );
  }

  .xl\:space-x-52 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(13rem * var(--space-x-reverse) );
    margin-left: calc(13rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(14rem * var(--space-y-reverse) );
  }

  .xl\:space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(14rem * var(--space-x-reverse) );
    margin-left: calc(14rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-60 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(15rem * var(--space-y-reverse) );
  }

  .xl\:space-x-60 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(15rem * var(--space-x-reverse) );
    margin-left: calc(15rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16rem * var(--space-y-reverse) );
  }

  .xl\:space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16rem * var(--space-x-reverse) );
    margin-left: calc(16rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-72 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(18rem * var(--space-y-reverse) );
  }

  .xl\:space-x-72 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(18rem * var(--space-x-reverse) );
    margin-left: calc(18rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-80 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(20rem * var(--space-y-reverse) );
  }

  .xl\:space-x-80 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20rem * var(--space-x-reverse) );
    margin-left: calc(20rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-96 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(24rem * var(--space-y-reverse) );
  }

  .xl\:space-x-96 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(24rem * var(--space-x-reverse) );
    margin-left: calc(24rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(1px * var(--space-y-reverse) );
  }

  .xl\:space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(1px * var(--space-x-reverse) );
    margin-left: calc(1px * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-0\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.125rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.125rem * var(--space-y-reverse) );
  }

  .xl\:space-x-0\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.125rem * var(--space-x-reverse) );
    margin-left: calc(.125rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-1\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.375rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.375rem * var(--space-y-reverse) );
  }

  .xl\:space-x-1\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.375rem * var(--space-x-reverse) );
    margin-left: calc(.375rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-2\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.625rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.625rem * var(--space-y-reverse) );
  }

  .xl\:space-x-2\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.625rem * var(--space-x-reverse) );
    margin-left: calc(.625rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-3\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(.875rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(.875rem * var(--space-y-reverse) );
  }

  .xl\:space-x-3\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(.875rem * var(--space-x-reverse) );
    margin-left: calc(.875rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-1\/2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .xl\:space-x-1\/2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-1\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .xl\:space-x-1\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-2\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .xl\:space-x-2\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-1\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(25% * var(--space-y-reverse) );
  }

  .xl\:space-x-1\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(25% * var(--space-x-reverse) );
    margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-2\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .xl\:space-x-2\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-3\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(75% * var(--space-y-reverse) );
  }

  .xl\:space-x-3\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75% * var(--space-x-reverse) );
    margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-1\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(20% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(20% * var(--space-y-reverse) );
  }

  .xl\:space-x-1\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(20% * var(--space-x-reverse) );
    margin-left: calc(20% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-2\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(40% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(40% * var(--space-y-reverse) );
  }

  .xl\:space-x-2\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(40% * var(--space-x-reverse) );
    margin-left: calc(40% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-3\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(60% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(60% * var(--space-y-reverse) );
  }

  .xl\:space-x-3\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(60% * var(--space-x-reverse) );
    margin-left: calc(60% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-4\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(80% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(80% * var(--space-y-reverse) );
  }

  .xl\:space-x-4\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(80% * var(--space-x-reverse) );
    margin-left: calc(80% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-1\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16.6667% * var(--space-y-reverse) );
  }

  .xl\:space-x-1\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16.6667% * var(--space-x-reverse) );
    margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-2\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .xl\:space-x-2\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-3\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .xl\:space-x-3\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-4\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .xl\:space-x-4\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-5\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(83.3333% * var(--space-y-reverse) );
  }

  .xl\:space-x-5\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(83.3333% * var(--space-x-reverse) );
    margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-1\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(8.33333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(8.33333% * var(--space-y-reverse) );
  }

  .xl\:space-x-1\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(8.33333% * var(--space-x-reverse) );
    margin-left: calc(8.33333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-2\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(16.6667% * var(--space-y-reverse) );
  }

  .xl\:space-x-2\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(16.6667% * var(--space-x-reverse) );
    margin-left: calc(16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-3\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(25% * var(--space-y-reverse) );
  }

  .xl\:space-x-3\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(25% * var(--space-x-reverse) );
    margin-left: calc(25% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-4\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(33.3333% * var(--space-y-reverse) );
  }

  .xl\:space-x-4\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(33.3333% * var(--space-x-reverse) );
    margin-left: calc(33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-5\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(41.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(41.6667% * var(--space-y-reverse) );
  }

  .xl\:space-x-5\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(41.6667% * var(--space-x-reverse) );
    margin-left: calc(41.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-6\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(50% * var(--space-y-reverse) );
  }

  .xl\:space-x-6\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(50% * var(--space-x-reverse) );
    margin-left: calc(50% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-7\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(58.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(58.3333% * var(--space-y-reverse) );
  }

  .xl\:space-x-7\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(58.3333% * var(--space-x-reverse) );
    margin-left: calc(58.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-8\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(66.6667% * var(--space-y-reverse) );
  }

  .xl\:space-x-8\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(66.6667% * var(--space-x-reverse) );
    margin-left: calc(66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-9\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(75% * var(--space-y-reverse) );
  }

  .xl\:space-x-9\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(75% * var(--space-x-reverse) );
    margin-left: calc(75% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-10\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(83.3333% * var(--space-y-reverse) );
  }

  .xl\:space-x-10\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(83.3333% * var(--space-x-reverse) );
    margin-left: calc(83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-11\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(91.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(91.6667% * var(--space-y-reverse) );
  }

  .xl\:space-x-11\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(91.6667% * var(--space-x-reverse) );
    margin-left: calc(91.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-full > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(100% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(100% * var(--space-y-reverse) );
  }

  .xl\:space-x-full > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(100% * var(--space-x-reverse) );
    margin-left: calc(100% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-1 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.25rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-1 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.25rem * var(--space-x-reverse) );
    margin-left: calc(-.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.5rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.5rem * var(--space-x-reverse) );
    margin-left: calc(-.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.75rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.75rem * var(--space-x-reverse) );
    margin-left: calc(-.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1rem * var(--space-x-reverse) );
    margin-left: calc(-1rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.25rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--space-x-reverse) );
    margin-left: calc(-1.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.5rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--space-x-reverse) );
    margin-left: calc(-1.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-7 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1.75rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-7 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--space-x-reverse) );
    margin-left: calc(-1.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-8 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-8 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2rem * var(--space-x-reverse) );
    margin-left: calc(-2rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-9 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.25rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-9 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--space-x-reverse) );
    margin-left: calc(-2.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-10 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.5rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-10 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--space-x-reverse) );
    margin-left: calc(-2.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-11 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-2.75rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-11 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--space-x-reverse) );
    margin-left: calc(-2.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3rem * var(--space-x-reverse) );
    margin-left: calc(-3rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-13 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.25rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.25rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-13 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.25rem * var(--space-x-reverse) );
    margin-left: calc(-3.25rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-14 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.5rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-14 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--space-x-reverse) );
    margin-left: calc(-3.5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-15 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-3.75rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-3.75rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-15 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-3.75rem * var(--space-x-reverse) );
    margin-left: calc(-3.75rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-16 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-4rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-16 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-4rem * var(--space-x-reverse) );
    margin-left: calc(-4rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-20 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-5rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-20 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-5rem * var(--space-x-reverse) );
    margin-left: calc(-5rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-24 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-6rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-24 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-6rem * var(--space-x-reverse) );
    margin-left: calc(-6rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-28 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-7rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-28 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-7rem * var(--space-x-reverse) );
    margin-left: calc(-7rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-32 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-8rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-32 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8rem * var(--space-x-reverse) );
    margin-left: calc(-8rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-36 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-9rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-36 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-9rem * var(--space-x-reverse) );
    margin-left: calc(-9rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-40 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-10rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-40 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-10rem * var(--space-x-reverse) );
    margin-left: calc(-10rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-44 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-11rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-44 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-11rem * var(--space-x-reverse) );
    margin-left: calc(-11rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-48 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-12rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-48 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-12rem * var(--space-x-reverse) );
    margin-left: calc(-12rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-52 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-13rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-52 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-13rem * var(--space-x-reverse) );
    margin-left: calc(-13rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-56 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-14rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-56 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-14rem * var(--space-x-reverse) );
    margin-left: calc(-14rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-60 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-15rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-60 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-15rem * var(--space-x-reverse) );
    margin-left: calc(-15rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-64 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-64 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16rem * var(--space-x-reverse) );
    margin-left: calc(-16rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-72 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-18rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-72 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-18rem * var(--space-x-reverse) );
    margin-left: calc(-18rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-80 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-20rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-80 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20rem * var(--space-x-reverse) );
    margin-left: calc(-20rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-96 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-24rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-96 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-24rem * var(--space-x-reverse) );
    margin-left: calc(-24rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-px > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-1px * var(--space-y-reverse) );
  }

  .xl\:-space-x-px > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-1px * var(--space-x-reverse) );
    margin-left: calc(-1px * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-0\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.125rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.125rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-0\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.125rem * var(--space-x-reverse) );
    margin-left: calc(-.125rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-1\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.375rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.375rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-1\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.375rem * var(--space-x-reverse) );
    margin-left: calc(-.375rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-2\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.625rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.625rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-2\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.625rem * var(--space-x-reverse) );
    margin-left: calc(-.625rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-3\.5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-.875rem * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-.875rem * var(--space-y-reverse) );
  }

  .xl\:-space-x-3\.5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-.875rem * var(--space-x-reverse) );
    margin-left: calc(-.875rem * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-1\/2 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .xl\:-space-x-1\/2 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-1\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .xl\:-space-x-1\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-2\/3 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .xl\:-space-x-2\/3 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-1\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-25% * var(--space-y-reverse) );
  }

  .xl\:-space-x-1\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-25% * var(--space-x-reverse) );
    margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-2\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .xl\:-space-x-2\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-3\/4 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-75% * var(--space-y-reverse) );
  }

  .xl\:-space-x-3\/4 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75% * var(--space-x-reverse) );
    margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-1\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-20% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-20% * var(--space-y-reverse) );
  }

  .xl\:-space-x-1\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-20% * var(--space-x-reverse) );
    margin-left: calc(-20% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-2\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-40% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-40% * var(--space-y-reverse) );
  }

  .xl\:-space-x-2\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-40% * var(--space-x-reverse) );
    margin-left: calc(-40% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-3\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-60% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-60% * var(--space-y-reverse) );
  }

  .xl\:-space-x-3\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-60% * var(--space-x-reverse) );
    margin-left: calc(-60% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-4\/5 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-80% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-80% * var(--space-y-reverse) );
  }

  .xl\:-space-x-4\/5 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-80% * var(--space-x-reverse) );
    margin-left: calc(-80% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-1\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
  }

  .xl\:-space-x-1\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16.6667% * var(--space-x-reverse) );
    margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-2\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .xl\:-space-x-2\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-3\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .xl\:-space-x-3\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-4\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .xl\:-space-x-4\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-5\/6 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
  }

  .xl\:-space-x-5\/6 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-83.3333% * var(--space-x-reverse) );
    margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-1\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-8.33333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-8.33333% * var(--space-y-reverse) );
  }

  .xl\:-space-x-1\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-8.33333% * var(--space-x-reverse) );
    margin-left: calc(-8.33333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-2\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-16.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-16.6667% * var(--space-y-reverse) );
  }

  .xl\:-space-x-2\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-16.6667% * var(--space-x-reverse) );
    margin-left: calc(-16.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-3\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-25% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-25% * var(--space-y-reverse) );
  }

  .xl\:-space-x-3\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-25% * var(--space-x-reverse) );
    margin-left: calc(-25% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-4\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-33.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-33.3333% * var(--space-y-reverse) );
  }

  .xl\:-space-x-4\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-33.3333% * var(--space-x-reverse) );
    margin-left: calc(-33.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-5\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-41.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-41.6667% * var(--space-y-reverse) );
  }

  .xl\:-space-x-5\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-41.6667% * var(--space-x-reverse) );
    margin-left: calc(-41.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-6\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-50% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-50% * var(--space-y-reverse) );
  }

  .xl\:-space-x-6\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-50% * var(--space-x-reverse) );
    margin-left: calc(-50% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-7\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-58.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-58.3333% * var(--space-y-reverse) );
  }

  .xl\:-space-x-7\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-58.3333% * var(--space-x-reverse) );
    margin-left: calc(-58.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-8\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-66.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-66.6667% * var(--space-y-reverse) );
  }

  .xl\:-space-x-8\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-66.6667% * var(--space-x-reverse) );
    margin-left: calc(-66.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-9\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-75% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-75% * var(--space-y-reverse) );
  }

  .xl\:-space-x-9\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-75% * var(--space-x-reverse) );
    margin-left: calc(-75% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-10\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-83.3333% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-83.3333% * var(--space-y-reverse) );
  }

  .xl\:-space-x-10\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-83.3333% * var(--space-x-reverse) );
    margin-left: calc(-83.3333% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-11\/12 > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-91.6667% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-91.6667% * var(--space-y-reverse) );
  }

  .xl\:-space-x-11\/12 > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-91.6667% * var(--space-x-reverse) );
    margin-left: calc(-91.6667% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:-space-y-full > :not(template) ~ :not(template) {
    --space-y-reverse: 0;
    margin-top: calc(-100% * calc(1 - var(--space-y-reverse) ) );
    margin-bottom: calc(-100% * var(--space-y-reverse) );
  }

  .xl\:-space-x-full > :not(template) ~ :not(template) {
    --space-x-reverse: 0;
    margin-right: calc(-100% * var(--space-x-reverse) );
    margin-left: calc(-100% * calc(1 - var(--space-x-reverse) ) );
  }

  .xl\:space-y-reverse > :not(template) ~ :not(template) {
    --space-y-reverse: 1;
  }

  .xl\:space-x-reverse > :not(template) ~ :not(template) {
    --space-x-reverse: 1;
  }

  .xl\:divide-y-0 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(0px * var(--divide-y-reverse) );
  }

  .xl\:divide-x-0 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(0px * var(--divide-x-reverse) );
    border-left-width: calc(0px * calc(1 - var(--divide-x-reverse) ) );
  }

  .xl\:divide-y-2 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(2px * var(--divide-y-reverse) );
  }

  .xl\:divide-x-2 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(2px * var(--divide-x-reverse) );
    border-left-width: calc(2px * calc(1 - var(--divide-x-reverse) ) );
  }

  .xl\:divide-y-4 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(4px * var(--divide-y-reverse) );
  }

  .xl\:divide-x-4 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(4px * var(--divide-x-reverse) );
    border-left-width: calc(4px * calc(1 - var(--divide-x-reverse) ) );
  }

  .xl\:divide-y-8 > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(8px * var(--divide-y-reverse) );
  }

  .xl\:divide-x-8 > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(8px * var(--divide-x-reverse) );
    border-left-width: calc(8px * calc(1 - var(--divide-x-reverse) ) );
  }

  .xl\:divide-y > :not(template) ~ :not(template) {
    --divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--divide-y-reverse) ) );
    border-bottom-width: calc(1px * var(--divide-y-reverse) );
  }

  .xl\:divide-x > :not(template) ~ :not(template) {
    --divide-x-reverse: 0;
    border-right-width: calc(1px * var(--divide-x-reverse) );
    border-left-width: calc(1px * calc(1 - var(--divide-x-reverse) ) );
  }

  .xl\:divide-y-reverse > :not(template) ~ :not(template) {
    --divide-y-reverse: 1;
  }

  .xl\:divide-x-reverse > :not(template) ~ :not(template) {
    --divide-x-reverse: 1;
  }

  .xl\:divide-transparent > :not(template) ~ :not(template) {
    border-color: #0000;
  }

  .xl\:divide-white > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--divide-opacity) );
  }

  .xl\:divide-black > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--divide-opacity) );
  }

  .xl\:divide-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--divide-opacity) );
  }

  .xl\:divide-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--divide-opacity) );
  }

  .xl\:divide-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--divide-opacity) );
  }

  .xl\:divide-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--divide-opacity) );
  }

  .xl\:divide-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--divide-opacity) );
  }

  .xl\:divide-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--divide-opacity) );
  }

  .xl\:divide-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--divide-opacity) );
  }

  .xl\:divide-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--divide-opacity) );
  }

  .xl\:divide-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--divide-opacity) );
  }

  .xl\:divide-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--divide-opacity) );
  }

  .xl\:divide-cool-gray-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--divide-opacity) );
  }

  .xl\:divide-red-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--divide-opacity) );
  }

  .xl\:divide-red-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--divide-opacity) );
  }

  .xl\:divide-red-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--divide-opacity) );
  }

  .xl\:divide-red-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--divide-opacity) );
  }

  .xl\:divide-red-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--divide-opacity) );
  }

  .xl\:divide-red-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--divide-opacity) );
  }

  .xl\:divide-red-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--divide-opacity) );
  }

  .xl\:divide-red-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--divide-opacity) );
  }

  .xl\:divide-red-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--divide-opacity) );
  }

  .xl\:divide-red-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--divide-opacity) );
  }

  .xl\:divide-orange-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--divide-opacity) );
  }

  .xl\:divide-orange-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--divide-opacity) );
  }

  .xl\:divide-orange-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--divide-opacity) );
  }

  .xl\:divide-orange-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--divide-opacity) );
  }

  .xl\:divide-orange-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--divide-opacity) );
  }

  .xl\:divide-orange-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--divide-opacity) );
  }

  .xl\:divide-orange-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--divide-opacity) );
  }

  .xl\:divide-orange-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--divide-opacity) );
  }

  .xl\:divide-orange-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--divide-opacity) );
  }

  .xl\:divide-orange-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--divide-opacity) );
  }

  .xl\:divide-yellow-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--divide-opacity) );
  }

  .xl\:divide-yellow-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--divide-opacity) );
  }

  .xl\:divide-yellow-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--divide-opacity) );
  }

  .xl\:divide-yellow-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--divide-opacity) );
  }

  .xl\:divide-yellow-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--divide-opacity) );
  }

  .xl\:divide-yellow-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--divide-opacity) );
  }

  .xl\:divide-yellow-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--divide-opacity) );
  }

  .xl\:divide-yellow-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--divide-opacity) );
  }

  .xl\:divide-yellow-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--divide-opacity) );
  }

  .xl\:divide-yellow-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--divide-opacity) );
  }

  .xl\:divide-green-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--divide-opacity) );
  }

  .xl\:divide-green-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--divide-opacity) );
  }

  .xl\:divide-green-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--divide-opacity) );
  }

  .xl\:divide-green-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--divide-opacity) );
  }

  .xl\:divide-green-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--divide-opacity) );
  }

  .xl\:divide-green-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--divide-opacity) );
  }

  .xl\:divide-green-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--divide-opacity) );
  }

  .xl\:divide-green-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--divide-opacity) );
  }

  .xl\:divide-green-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--divide-opacity) );
  }

  .xl\:divide-green-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--divide-opacity) );
  }

  .xl\:divide-teal-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--divide-opacity) );
  }

  .xl\:divide-teal-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--divide-opacity) );
  }

  .xl\:divide-teal-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--divide-opacity) );
  }

  .xl\:divide-teal-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--divide-opacity) );
  }

  .xl\:divide-teal-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--divide-opacity) );
  }

  .xl\:divide-teal-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--divide-opacity) );
  }

  .xl\:divide-teal-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--divide-opacity) );
  }

  .xl\:divide-teal-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--divide-opacity) );
  }

  .xl\:divide-teal-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--divide-opacity) );
  }

  .xl\:divide-teal-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--divide-opacity) );
  }

  .xl\:divide-blue-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--divide-opacity) );
  }

  .xl\:divide-blue-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--divide-opacity) );
  }

  .xl\:divide-blue-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--divide-opacity) );
  }

  .xl\:divide-blue-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--divide-opacity) );
  }

  .xl\:divide-blue-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--divide-opacity) );
  }

  .xl\:divide-blue-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--divide-opacity) );
  }

  .xl\:divide-blue-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--divide-opacity) );
  }

  .xl\:divide-blue-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--divide-opacity) );
  }

  .xl\:divide-blue-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--divide-opacity) );
  }

  .xl\:divide-blue-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--divide-opacity) );
  }

  .xl\:divide-indigo-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--divide-opacity) );
  }

  .xl\:divide-indigo-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--divide-opacity) );
  }

  .xl\:divide-indigo-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--divide-opacity) );
  }

  .xl\:divide-indigo-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--divide-opacity) );
  }

  .xl\:divide-indigo-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--divide-opacity) );
  }

  .xl\:divide-indigo-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--divide-opacity) );
  }

  .xl\:divide-indigo-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--divide-opacity) );
  }

  .xl\:divide-indigo-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--divide-opacity) );
  }

  .xl\:divide-indigo-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--divide-opacity) );
  }

  .xl\:divide-indigo-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--divide-opacity) );
  }

  .xl\:divide-purple-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--divide-opacity) );
  }

  .xl\:divide-purple-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--divide-opacity) );
  }

  .xl\:divide-purple-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--divide-opacity) );
  }

  .xl\:divide-purple-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--divide-opacity) );
  }

  .xl\:divide-purple-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--divide-opacity) );
  }

  .xl\:divide-purple-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--divide-opacity) );
  }

  .xl\:divide-purple-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--divide-opacity) );
  }

  .xl\:divide-purple-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--divide-opacity) );
  }

  .xl\:divide-purple-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--divide-opacity) );
  }

  .xl\:divide-purple-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--divide-opacity) );
  }

  .xl\:divide-pink-50 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--divide-opacity) );
  }

  .xl\:divide-pink-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--divide-opacity) );
  }

  .xl\:divide-pink-200 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--divide-opacity) );
  }

  .xl\:divide-pink-300 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--divide-opacity) );
  }

  .xl\:divide-pink-400 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--divide-opacity) );
  }

  .xl\:divide-pink-500 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--divide-opacity) );
  }

  .xl\:divide-pink-600 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--divide-opacity) );
  }

  .xl\:divide-pink-700 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--divide-opacity) );
  }

  .xl\:divide-pink-800 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--divide-opacity) );
  }

  .xl\:divide-pink-900 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--divide-opacity) );
  }

  .xl\:divide-solid > :not(template) ~ :not(template) {
    border-style: solid;
  }

  .xl\:divide-dashed > :not(template) ~ :not(template) {
    border-style: dashed;
  }

  .xl\:divide-dotted > :not(template) ~ :not(template) {
    border-style: dotted;
  }

  .xl\:divide-double > :not(template) ~ :not(template) {
    border-style: double;
  }

  .xl\:divide-none > :not(template) ~ :not(template) {
    border-style: none;
  }

  .xl\:divide-opacity-0 > :not(template) ~ :not(template) {
    --divide-opacity: 0;
  }

  .xl\:divide-opacity-25 > :not(template) ~ :not(template) {
    --divide-opacity: .25;
  }

  .xl\:divide-opacity-50 > :not(template) ~ :not(template) {
    --divide-opacity: .5;
  }

  .xl\:divide-opacity-75 > :not(template) ~ :not(template) {
    --divide-opacity: .75;
  }

  .xl\:divide-opacity-100 > :not(template) ~ :not(template) {
    --divide-opacity: 1;
  }

  .xl\:sr-only {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xl\:not-sr-only {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xl\:focus\:sr-only:focus {
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
  }

  .xl\:focus\:not-sr-only:focus {
    width: auto;
    height: auto;
    clip: auto;
    white-space: normal;
    margin: 0;
    padding: 0;
    position: static;
    overflow: visible;
  }

  .xl\:appearance-none {
    appearance: none;
  }

  .xl\:bg-fixed {
    background-attachment: fixed;
  }

  .xl\:bg-local {
    background-attachment: local;
  }

  .xl\:bg-scroll {
    background-attachment: scroll;
  }

  .xl\:bg-clip-border {
    background-clip: border-box;
  }

  .xl\:bg-clip-padding {
    background-clip: padding-box;
  }

  .xl\:bg-clip-content {
    background-clip: content-box;
  }

  .xl\:bg-clip-text {
    background-clip: text;
  }

  .xl\:bg-transparent {
    background-color: #0000;
  }

  .xl\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .xl\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .xl\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .xl\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .xl\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .xl\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .xl\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .xl\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .xl\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .xl\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .xl\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .xl\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .xl\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .xl\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .xl\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .xl\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .xl\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .xl\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .xl\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .xl\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .xl\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .xl\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .xl\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .xl\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .xl\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .xl\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .xl\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .xl\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .xl\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .xl\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .xl\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .xl\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .xl\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .xl\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .xl\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .xl\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .xl\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .xl\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .xl\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .xl\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .xl\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .xl\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .xl\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .xl\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .xl\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .xl\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .xl\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .xl\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .xl\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .xl\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .xl\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .xl\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .xl\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .xl\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .xl\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .xl\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .xl\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .xl\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .xl\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .xl\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .xl\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .xl\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .xl\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .xl\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .xl\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .xl\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .xl\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .xl\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .xl\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .xl\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .xl\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .xl\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .xl\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .xl\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .xl\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .xl\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .xl\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .xl\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .xl\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .xl\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .xl\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .xl\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .xl\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .xl\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .xl\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .xl\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .xl\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .xl\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .xl\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .xl\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .xl\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .xl\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .xl\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .xl\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .xl\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .xl\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .xl\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .xl\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .xl\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .xl\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .xl\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .xl\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .xl\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-transparent {
    background-color: #0000;
  }

  .group:hover .xl\:group-hover\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .group:hover .xl\:group-hover\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-transparent {
    background-color: #0000;
  }

  .group:focus .xl\:group-focus\:bg-white {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-black {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-50 {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-100 {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-200 {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-300 {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-400 {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-500 {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-600 {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-700 {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-800 {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-gray-900 {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-50 {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-100 {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-200 {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-300 {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-400 {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-500 {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-600 {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-700 {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-800 {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-cool-gray-900 {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-50 {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-100 {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-200 {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-300 {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-400 {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-500 {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-600 {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-700 {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-800 {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-red-900 {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-50 {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-100 {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-200 {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-300 {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-400 {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-500 {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-600 {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-700 {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-800 {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-orange-900 {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-50 {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-100 {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-200 {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-300 {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-400 {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-500 {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-600 {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-700 {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-800 {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-yellow-900 {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-50 {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-100 {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-200 {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-300 {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-400 {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-500 {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-600 {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-700 {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-800 {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-green-900 {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-50 {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-100 {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-200 {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-300 {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-400 {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-500 {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-600 {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-700 {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-800 {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-teal-900 {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-50 {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-100 {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-200 {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-300 {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-400 {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-500 {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-600 {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-700 {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-800 {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-blue-900 {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-50 {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-100 {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-200 {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-300 {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-400 {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-500 {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-600 {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-700 {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-800 {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-indigo-900 {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-50 {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-100 {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-200 {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-300 {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-400 {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-500 {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-600 {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-700 {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-800 {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-purple-900 {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-50 {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-100 {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-200 {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-300 {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-400 {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-500 {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-600 {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-700 {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-800 {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .group:focus .xl\:group-focus\:bg-pink-900 {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .xl\:hover\:bg-transparent:hover {
    background-color: #0000;
  }

  .xl\:hover\:bg-white:hover {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .xl\:hover\:bg-black:hover {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-50:hover {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-300:hover {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-400:hover {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-500:hover {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-600:hover {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-700:hover {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-800:hover {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .xl\:hover\:bg-gray-900:hover {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-50:hover {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-100:hover {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-200:hover {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-300:hover {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-400:hover {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-500:hover {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-600:hover {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-700:hover {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-800:hover {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .xl\:hover\:bg-cool-gray-900:hover {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-100:hover {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-200:hover {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-400:hover {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-500:hover {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-600:hover {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-700:hover {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-800:hover {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .xl\:hover\:bg-red-900:hover {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-50:hover {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-100:hover {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-200:hover {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-300:hover {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-400:hover {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-500:hover {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-600:hover {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-700:hover {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-800:hover {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .xl\:hover\:bg-orange-900:hover {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-50:hover {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-100:hover {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-200:hover {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-300:hover {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-400:hover {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-500:hover {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-600:hover {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-700:hover {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-800:hover {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .xl\:hover\:bg-yellow-900:hover {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-50:hover {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-100:hover {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-200:hover {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-300:hover {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-400:hover {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-500:hover {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-600:hover {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-700:hover {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-800:hover {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .xl\:hover\:bg-green-900:hover {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-50:hover {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-100:hover {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-200:hover {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-300:hover {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-400:hover {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-500:hover {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-600:hover {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-700:hover {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-800:hover {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .xl\:hover\:bg-teal-900:hover {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-50:hover {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-100:hover {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-200:hover {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-300:hover {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-400:hover {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-500:hover {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-600:hover {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-700:hover {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-800:hover {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .xl\:hover\:bg-blue-900:hover {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-50:hover {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-100:hover {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-200:hover {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-300:hover {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-400:hover {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-500:hover {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-600:hover {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-700:hover {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-800:hover {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .xl\:hover\:bg-indigo-900:hover {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-50:hover {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-100:hover {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-200:hover {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-300:hover {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-400:hover {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-500:hover {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-600:hover {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-700:hover {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-800:hover {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .xl\:hover\:bg-purple-900:hover {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-50:hover {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-100:hover {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-200:hover {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-300:hover {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-400:hover {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-500:hover {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-600:hover {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-700:hover {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-800:hover {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .xl\:hover\:bg-pink-900:hover {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .xl\:focus\:bg-transparent:focus {
    background-color: #0000;
  }

  .xl\:focus\:bg-white:focus {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .xl\:focus\:bg-black:focus {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-50:focus {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-300:focus {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-400:focus {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-500:focus {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-600:focus {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-700:focus {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-800:focus {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .xl\:focus\:bg-gray-900:focus {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-50:focus {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-100:focus {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-200:focus {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-300:focus {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-400:focus {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-500:focus {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-600:focus {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-700:focus {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-800:focus {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .xl\:focus\:bg-cool-gray-900:focus {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-100:focus {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-200:focus {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-400:focus {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-500:focus {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-600:focus {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-700:focus {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-800:focus {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .xl\:focus\:bg-red-900:focus {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-50:focus {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-100:focus {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-200:focus {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-300:focus {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-400:focus {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-500:focus {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-600:focus {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-700:focus {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-800:focus {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .xl\:focus\:bg-orange-900:focus {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-50:focus {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-100:focus {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-200:focus {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-300:focus {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-400:focus {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-500:focus {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-600:focus {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-700:focus {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-800:focus {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .xl\:focus\:bg-yellow-900:focus {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-50:focus {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-100:focus {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-200:focus {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-300:focus {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-400:focus {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-500:focus {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-600:focus {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-700:focus {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-800:focus {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .xl\:focus\:bg-green-900:focus {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-50:focus {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-100:focus {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-200:focus {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-300:focus {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-400:focus {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-500:focus {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-600:focus {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-700:focus {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-800:focus {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .xl\:focus\:bg-teal-900:focus {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-50:focus {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-100:focus {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-200:focus {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-300:focus {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-400:focus {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-500:focus {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-600:focus {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-700:focus {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-800:focus {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .xl\:focus\:bg-blue-900:focus {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-50:focus {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-100:focus {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-200:focus {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-300:focus {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-400:focus {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-500:focus {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-600:focus {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-700:focus {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-800:focus {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .xl\:focus\:bg-indigo-900:focus {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-50:focus {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-100:focus {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-200:focus {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-300:focus {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-400:focus {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-500:focus {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-600:focus {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-700:focus {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-800:focus {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .xl\:focus\:bg-purple-900:focus {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-50:focus {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-100:focus {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-200:focus {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-300:focus {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-400:focus {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-500:focus {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-600:focus {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-700:focus {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-800:focus {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .xl\:focus\:bg-pink-900:focus {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .xl\:active\:bg-transparent:active {
    background-color: #0000;
  }

  .xl\:active\:bg-white:active {
    --bg-opacity: 1;
    background-color: #fff;
    background-color: rgba(255, 255, 255, var(--bg-opacity) );
  }

  .xl\:active\:bg-black:active {
    --bg-opacity: 1;
    background-color: #000;
    background-color: rgba(0, 0, 0, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-50:active {
    --bg-opacity: 1;
    background-color: #f9fafb;
    background-color: rgba(249, 250, 251, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-100:active {
    --bg-opacity: 1;
    background-color: #f4f5f7;
    background-color: rgba(244, 245, 247, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-200:active {
    --bg-opacity: 1;
    background-color: #e5e7eb;
    background-color: rgba(229, 231, 235, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-300:active {
    --bg-opacity: 1;
    background-color: #d2d6dc;
    background-color: rgba(210, 214, 220, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-400:active {
    --bg-opacity: 1;
    background-color: #9fa6b2;
    background-color: rgba(159, 166, 178, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-500:active {
    --bg-opacity: 1;
    background-color: #6b7280;
    background-color: rgba(107, 114, 128, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-600:active {
    --bg-opacity: 1;
    background-color: #4b5563;
    background-color: rgba(75, 85, 99, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-700:active {
    --bg-opacity: 1;
    background-color: #374151;
    background-color: rgba(55, 65, 81, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-800:active {
    --bg-opacity: 1;
    background-color: #252f3f;
    background-color: rgba(37, 47, 63, var(--bg-opacity) );
  }

  .xl\:active\:bg-gray-900:active {
    --bg-opacity: 1;
    background-color: #161e2e;
    background-color: rgba(22, 30, 46, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-50:active {
    --bg-opacity: 1;
    background-color: #fbfdfe;
    background-color: rgba(251, 253, 254, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-100:active {
    --bg-opacity: 1;
    background-color: #f1f5f9;
    background-color: rgba(241, 245, 249, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-200:active {
    --bg-opacity: 1;
    background-color: #e2e8f0;
    background-color: rgba(226, 232, 240, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-300:active {
    --bg-opacity: 1;
    background-color: #cfd8e3;
    background-color: rgba(207, 216, 227, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-400:active {
    --bg-opacity: 1;
    background-color: #97a6ba;
    background-color: rgba(151, 166, 186, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-500:active {
    --bg-opacity: 1;
    background-color: #64748b;
    background-color: rgba(100, 116, 139, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-600:active {
    --bg-opacity: 1;
    background-color: #475569;
    background-color: rgba(71, 85, 105, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-700:active {
    --bg-opacity: 1;
    background-color: #364152;
    background-color: rgba(54, 65, 82, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-800:active {
    --bg-opacity: 1;
    background-color: #27303f;
    background-color: rgba(39, 48, 63, var(--bg-opacity) );
  }

  .xl\:active\:bg-cool-gray-900:active {
    --bg-opacity: 1;
    background-color: #1a202e;
    background-color: rgba(26, 32, 46, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f2;
    background-color: rgba(253, 242, 242, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-100:active {
    --bg-opacity: 1;
    background-color: #fde8e8;
    background-color: rgba(253, 232, 232, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-200:active {
    --bg-opacity: 1;
    background-color: #fbd5d5;
    background-color: rgba(251, 213, 213, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-300:active {
    --bg-opacity: 1;
    background-color: #f8b4b4;
    background-color: rgba(248, 180, 180, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-400:active {
    --bg-opacity: 1;
    background-color: #f98080;
    background-color: rgba(249, 128, 128, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-500:active {
    --bg-opacity: 1;
    background-color: #f05252;
    background-color: rgba(240, 82, 82, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-600:active {
    --bg-opacity: 1;
    background-color: #e02424;
    background-color: rgba(224, 36, 36, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-700:active {
    --bg-opacity: 1;
    background-color: #c81e1e;
    background-color: rgba(200, 30, 30, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-800:active {
    --bg-opacity: 1;
    background-color: #9b1c1c;
    background-color: rgba(155, 28, 28, var(--bg-opacity) );
  }

  .xl\:active\:bg-red-900:active {
    --bg-opacity: 1;
    background-color: #771d1d;
    background-color: rgba(119, 29, 29, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-50:active {
    --bg-opacity: 1;
    background-color: #fff8f1;
    background-color: rgba(255, 248, 241, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-100:active {
    --bg-opacity: 1;
    background-color: #feecdc;
    background-color: rgba(254, 236, 220, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-200:active {
    --bg-opacity: 1;
    background-color: #fcd9bd;
    background-color: rgba(252, 217, 189, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-300:active {
    --bg-opacity: 1;
    background-color: #fdba8c;
    background-color: rgba(253, 186, 140, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-400:active {
    --bg-opacity: 1;
    background-color: #ff8a4c;
    background-color: rgba(255, 138, 76, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-500:active {
    --bg-opacity: 1;
    background-color: #ff5a1f;
    background-color: rgba(255, 90, 31, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-600:active {
    --bg-opacity: 1;
    background-color: #d03801;
    background-color: rgba(208, 56, 1, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-700:active {
    --bg-opacity: 1;
    background-color: #b43403;
    background-color: rgba(180, 52, 3, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-800:active {
    --bg-opacity: 1;
    background-color: #8a2c0d;
    background-color: rgba(138, 44, 13, var(--bg-opacity) );
  }

  .xl\:active\:bg-orange-900:active {
    --bg-opacity: 1;
    background-color: #73230d;
    background-color: rgba(115, 35, 13, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-50:active {
    --bg-opacity: 1;
    background-color: #fdfdea;
    background-color: rgba(253, 253, 234, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-100:active {
    --bg-opacity: 1;
    background-color: #fdf6b2;
    background-color: rgba(253, 246, 178, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-200:active {
    --bg-opacity: 1;
    background-color: #fce96a;
    background-color: rgba(252, 233, 106, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-300:active {
    --bg-opacity: 1;
    background-color: #faca15;
    background-color: rgba(250, 202, 21, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-400:active {
    --bg-opacity: 1;
    background-color: #e3a008;
    background-color: rgba(227, 160, 8, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-500:active {
    --bg-opacity: 1;
    background-color: #c27803;
    background-color: rgba(194, 120, 3, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-600:active {
    --bg-opacity: 1;
    background-color: #9f580a;
    background-color: rgba(159, 88, 10, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-700:active {
    --bg-opacity: 1;
    background-color: #8e4b10;
    background-color: rgba(142, 75, 16, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-800:active {
    --bg-opacity: 1;
    background-color: #723b13;
    background-color: rgba(114, 59, 19, var(--bg-opacity) );
  }

  .xl\:active\:bg-yellow-900:active {
    --bg-opacity: 1;
    background-color: #633112;
    background-color: rgba(99, 49, 18, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-50:active {
    --bg-opacity: 1;
    background-color: #f3faf7;
    background-color: rgba(243, 250, 247, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-100:active {
    --bg-opacity: 1;
    background-color: #def7ec;
    background-color: rgba(222, 247, 236, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-200:active {
    --bg-opacity: 1;
    background-color: #bcf0da;
    background-color: rgba(188, 240, 218, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-300:active {
    --bg-opacity: 1;
    background-color: #84e1bc;
    background-color: rgba(132, 225, 188, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-400:active {
    --bg-opacity: 1;
    background-color: #31c48d;
    background-color: rgba(49, 196, 141, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-500:active {
    --bg-opacity: 1;
    background-color: #0e9f6e;
    background-color: rgba(14, 159, 110, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-600:active {
    --bg-opacity: 1;
    background-color: #057a55;
    background-color: rgba(5, 122, 85, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-700:active {
    --bg-opacity: 1;
    background-color: #046c4e;
    background-color: rgba(4, 108, 78, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-800:active {
    --bg-opacity: 1;
    background-color: #03543f;
    background-color: rgba(3, 84, 63, var(--bg-opacity) );
  }

  .xl\:active\:bg-green-900:active {
    --bg-opacity: 1;
    background-color: #014737;
    background-color: rgba(1, 71, 55, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-50:active {
    --bg-opacity: 1;
    background-color: #edfafa;
    background-color: rgba(237, 250, 250, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-100:active {
    --bg-opacity: 1;
    background-color: #d5f5f6;
    background-color: rgba(213, 245, 246, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-200:active {
    --bg-opacity: 1;
    background-color: #afecef;
    background-color: rgba(175, 236, 239, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-300:active {
    --bg-opacity: 1;
    background-color: #7edce2;
    background-color: rgba(126, 220, 226, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-400:active {
    --bg-opacity: 1;
    background-color: #16bdca;
    background-color: rgba(22, 189, 202, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-500:active {
    --bg-opacity: 1;
    background-color: #0694a2;
    background-color: rgba(6, 148, 162, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-600:active {
    --bg-opacity: 1;
    background-color: #047481;
    background-color: rgba(4, 116, 129, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-700:active {
    --bg-opacity: 1;
    background-color: #036672;
    background-color: rgba(3, 102, 114, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-800:active {
    --bg-opacity: 1;
    background-color: #05505c;
    background-color: rgba(5, 80, 92, var(--bg-opacity) );
  }

  .xl\:active\:bg-teal-900:active {
    --bg-opacity: 1;
    background-color: #014451;
    background-color: rgba(1, 68, 81, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-50:active {
    --bg-opacity: 1;
    background-color: #ebf5ff;
    background-color: rgba(235, 245, 255, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-100:active {
    --bg-opacity: 1;
    background-color: #e1effe;
    background-color: rgba(225, 239, 254, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-200:active {
    --bg-opacity: 1;
    background-color: #c3ddfd;
    background-color: rgba(195, 221, 253, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-300:active {
    --bg-opacity: 1;
    background-color: #a4cafe;
    background-color: rgba(164, 202, 254, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-400:active {
    --bg-opacity: 1;
    background-color: #76a9fa;
    background-color: rgba(118, 169, 250, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-500:active {
    --bg-opacity: 1;
    background-color: #3f83f8;
    background-color: rgba(63, 131, 248, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-600:active {
    --bg-opacity: 1;
    background-color: #1c64f2;
    background-color: rgba(28, 100, 242, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-700:active {
    --bg-opacity: 1;
    background-color: #1a56db;
    background-color: rgba(26, 86, 219, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-800:active {
    --bg-opacity: 1;
    background-color: #1e429f;
    background-color: rgba(30, 66, 159, var(--bg-opacity) );
  }

  .xl\:active\:bg-blue-900:active {
    --bg-opacity: 1;
    background-color: #233876;
    background-color: rgba(35, 56, 118, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-50:active {
    --bg-opacity: 1;
    background-color: #f0f5ff;
    background-color: rgba(240, 245, 255, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-100:active {
    --bg-opacity: 1;
    background-color: #e5edff;
    background-color: rgba(229, 237, 255, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-200:active {
    --bg-opacity: 1;
    background-color: #cddbfe;
    background-color: rgba(205, 219, 254, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-300:active {
    --bg-opacity: 1;
    background-color: #b4c6fc;
    background-color: rgba(180, 198, 252, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-400:active {
    --bg-opacity: 1;
    background-color: #8da2fb;
    background-color: rgba(141, 162, 251, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-500:active {
    --bg-opacity: 1;
    background-color: #6875f5;
    background-color: rgba(104, 117, 245, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-600:active {
    --bg-opacity: 1;
    background-color: #5850ec;
    background-color: rgba(88, 80, 236, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-700:active {
    --bg-opacity: 1;
    background-color: #5145cd;
    background-color: rgba(81, 69, 205, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-800:active {
    --bg-opacity: 1;
    background-color: #42389d;
    background-color: rgba(66, 56, 157, var(--bg-opacity) );
  }

  .xl\:active\:bg-indigo-900:active {
    --bg-opacity: 1;
    background-color: #362f78;
    background-color: rgba(54, 47, 120, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-50:active {
    --bg-opacity: 1;
    background-color: #f6f5ff;
    background-color: rgba(246, 245, 255, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-100:active {
    --bg-opacity: 1;
    background-color: #edebfe;
    background-color: rgba(237, 235, 254, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-200:active {
    --bg-opacity: 1;
    background-color: #dcd7fe;
    background-color: rgba(220, 215, 254, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-300:active {
    --bg-opacity: 1;
    background-color: #cabffd;
    background-color: rgba(202, 191, 253, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-400:active {
    --bg-opacity: 1;
    background-color: #ac94fa;
    background-color: rgba(172, 148, 250, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-500:active {
    --bg-opacity: 1;
    background-color: #9061f9;
    background-color: rgba(144, 97, 249, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-600:active {
    --bg-opacity: 1;
    background-color: #7e3af2;
    background-color: rgba(126, 58, 242, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-700:active {
    --bg-opacity: 1;
    background-color: #6c2bd9;
    background-color: rgba(108, 43, 217, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-800:active {
    --bg-opacity: 1;
    background-color: #5521b5;
    background-color: rgba(85, 33, 181, var(--bg-opacity) );
  }

  .xl\:active\:bg-purple-900:active {
    --bg-opacity: 1;
    background-color: #4a1d96;
    background-color: rgba(74, 29, 150, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-50:active {
    --bg-opacity: 1;
    background-color: #fdf2f8;
    background-color: rgba(253, 242, 248, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-100:active {
    --bg-opacity: 1;
    background-color: #fce8f3;
    background-color: rgba(252, 232, 243, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-200:active {
    --bg-opacity: 1;
    background-color: #fad1e8;
    background-color: rgba(250, 209, 232, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-300:active {
    --bg-opacity: 1;
    background-color: #f8b4d9;
    background-color: rgba(248, 180, 217, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-400:active {
    --bg-opacity: 1;
    background-color: #f17eb8;
    background-color: rgba(241, 126, 184, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-500:active {
    --bg-opacity: 1;
    background-color: #e74694;
    background-color: rgba(231, 70, 148, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-600:active {
    --bg-opacity: 1;
    background-color: #d61f69;
    background-color: rgba(214, 31, 105, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-700:active {
    --bg-opacity: 1;
    background-color: #bf125d;
    background-color: rgba(191, 18, 93, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-800:active {
    --bg-opacity: 1;
    background-color: #99154b;
    background-color: rgba(153, 21, 75, var(--bg-opacity) );
  }

  .xl\:active\:bg-pink-900:active {
    --bg-opacity: 1;
    background-color: #751a3d;
    background-color: rgba(117, 26, 61, var(--bg-opacity) );
  }

  .xl\:bg-none {
    background-image: none;
  }

  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--gradient-color-stops) );
  }

  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--gradient-color-stops) );
  }

  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--gradient-color-stops) );
  }

  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(to bottom right, var(--gradient-color-stops) );
  }

  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--gradient-color-stops) );
  }

  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--gradient-color-stops) );
  }

  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--gradient-color-stops) );
  }

  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--gradient-color-stops) );
  }

  .xl\:from-transparent {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .xl\:from-white {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .xl\:from-black {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .xl\:from-gray-50 {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .xl\:from-gray-100 {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .xl\:from-gray-200 {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .xl\:from-gray-300 {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .xl\:from-gray-400 {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .xl\:from-gray-500 {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .xl\:from-gray-600 {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .xl\:from-gray-700 {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .xl\:from-gray-800 {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .xl\:from-gray-900 {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .xl\:from-cool-gray-50 {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .xl\:from-cool-gray-100 {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .xl\:from-cool-gray-200 {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .xl\:from-cool-gray-300 {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .xl\:from-cool-gray-400 {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .xl\:from-cool-gray-500 {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .xl\:from-cool-gray-600 {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .xl\:from-cool-gray-700 {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .xl\:from-cool-gray-800 {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .xl\:from-cool-gray-900 {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .xl\:from-red-50 {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .xl\:from-red-100 {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .xl\:from-red-200 {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .xl\:from-red-300 {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .xl\:from-red-400 {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .xl\:from-red-500 {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .xl\:from-red-600 {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .xl\:from-red-700 {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .xl\:from-red-800 {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .xl\:from-red-900 {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .xl\:from-orange-50 {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .xl\:from-orange-100 {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .xl\:from-orange-200 {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .xl\:from-orange-300 {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .xl\:from-orange-400 {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .xl\:from-orange-500 {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .xl\:from-orange-600 {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .xl\:from-orange-700 {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .xl\:from-orange-800 {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .xl\:from-orange-900 {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .xl\:from-yellow-50 {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .xl\:from-yellow-100 {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .xl\:from-yellow-200 {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .xl\:from-yellow-300 {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .xl\:from-yellow-400 {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .xl\:from-yellow-500 {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .xl\:from-yellow-600 {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .xl\:from-yellow-700 {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .xl\:from-yellow-800 {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .xl\:from-yellow-900 {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .xl\:from-green-50 {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .xl\:from-green-100 {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .xl\:from-green-200 {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .xl\:from-green-300 {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .xl\:from-green-400 {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .xl\:from-green-500 {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .xl\:from-green-600 {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .xl\:from-green-700 {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .xl\:from-green-800 {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .xl\:from-green-900 {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .xl\:from-teal-50 {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .xl\:from-teal-100 {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .xl\:from-teal-200 {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .xl\:from-teal-300 {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .xl\:from-teal-400 {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .xl\:from-teal-500 {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .xl\:from-teal-600 {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .xl\:from-teal-700 {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .xl\:from-teal-800 {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .xl\:from-teal-900 {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .xl\:from-blue-50 {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .xl\:from-blue-100 {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .xl\:from-blue-200 {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .xl\:from-blue-300 {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .xl\:from-blue-400 {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .xl\:from-blue-500 {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .xl\:from-blue-600 {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .xl\:from-blue-700 {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .xl\:from-blue-800 {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .xl\:from-blue-900 {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .xl\:from-indigo-50 {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .xl\:from-indigo-100 {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .xl\:from-indigo-200 {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .xl\:from-indigo-300 {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .xl\:from-indigo-400 {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .xl\:from-indigo-500 {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .xl\:from-indigo-600 {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .xl\:from-indigo-700 {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .xl\:from-indigo-800 {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .xl\:from-indigo-900 {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .xl\:from-purple-50 {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .xl\:from-purple-100 {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .xl\:from-purple-200 {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .xl\:from-purple-300 {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .xl\:from-purple-400 {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .xl\:from-purple-500 {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .xl\:from-purple-600 {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .xl\:from-purple-700 {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .xl\:from-purple-800 {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .xl\:from-purple-900 {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .xl\:from-pink-50 {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .xl\:from-pink-100 {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .xl\:from-pink-200 {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .xl\:from-pink-300 {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .xl\:from-pink-400 {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .xl\:from-pink-500 {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .xl\:from-pink-600 {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .xl\:from-pink-700 {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .xl\:from-pink-800 {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .xl\:from-pink-900 {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .xl\:via-transparent {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .xl\:via-white {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .xl\:via-black {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .xl\:via-gray-50 {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .xl\:via-gray-100 {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .xl\:via-gray-200 {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .xl\:via-gray-300 {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .xl\:via-gray-400 {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .xl\:via-gray-500 {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .xl\:via-gray-600 {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .xl\:via-gray-700 {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .xl\:via-gray-800 {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .xl\:via-gray-900 {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .xl\:via-cool-gray-50 {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .xl\:via-cool-gray-100 {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .xl\:via-cool-gray-200 {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .xl\:via-cool-gray-300 {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .xl\:via-cool-gray-400 {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .xl\:via-cool-gray-500 {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .xl\:via-cool-gray-600 {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .xl\:via-cool-gray-700 {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .xl\:via-cool-gray-800 {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .xl\:via-cool-gray-900 {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .xl\:via-red-50 {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .xl\:via-red-100 {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .xl\:via-red-200 {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .xl\:via-red-300 {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .xl\:via-red-400 {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .xl\:via-red-500 {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .xl\:via-red-600 {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .xl\:via-red-700 {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .xl\:via-red-800 {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .xl\:via-red-900 {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .xl\:via-orange-50 {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .xl\:via-orange-100 {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .xl\:via-orange-200 {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .xl\:via-orange-300 {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .xl\:via-orange-400 {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .xl\:via-orange-500 {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .xl\:via-orange-600 {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .xl\:via-orange-700 {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .xl\:via-orange-800 {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .xl\:via-orange-900 {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .xl\:via-yellow-50 {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .xl\:via-yellow-100 {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .xl\:via-yellow-200 {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .xl\:via-yellow-300 {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .xl\:via-yellow-400 {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .xl\:via-yellow-500 {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .xl\:via-yellow-600 {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .xl\:via-yellow-700 {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .xl\:via-yellow-800 {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .xl\:via-yellow-900 {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .xl\:via-green-50 {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .xl\:via-green-100 {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .xl\:via-green-200 {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .xl\:via-green-300 {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .xl\:via-green-400 {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .xl\:via-green-500 {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .xl\:via-green-600 {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .xl\:via-green-700 {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .xl\:via-green-800 {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .xl\:via-green-900 {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .xl\:via-teal-50 {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .xl\:via-teal-100 {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .xl\:via-teal-200 {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .xl\:via-teal-300 {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .xl\:via-teal-400 {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .xl\:via-teal-500 {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .xl\:via-teal-600 {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .xl\:via-teal-700 {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .xl\:via-teal-800 {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .xl\:via-teal-900 {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .xl\:via-blue-50 {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .xl\:via-blue-100 {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .xl\:via-blue-200 {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .xl\:via-blue-300 {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .xl\:via-blue-400 {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .xl\:via-blue-500 {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .xl\:via-blue-600 {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .xl\:via-blue-700 {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .xl\:via-blue-800 {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .xl\:via-blue-900 {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .xl\:via-indigo-50 {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .xl\:via-indigo-100 {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .xl\:via-indigo-200 {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .xl\:via-indigo-300 {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .xl\:via-indigo-400 {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .xl\:via-indigo-500 {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .xl\:via-indigo-600 {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .xl\:via-indigo-700 {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .xl\:via-indigo-800 {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .xl\:via-indigo-900 {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .xl\:via-purple-50 {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .xl\:via-purple-100 {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .xl\:via-purple-200 {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .xl\:via-purple-300 {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .xl\:via-purple-400 {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .xl\:via-purple-500 {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .xl\:via-purple-600 {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .xl\:via-purple-700 {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .xl\:via-purple-800 {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .xl\:via-purple-900 {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .xl\:via-pink-50 {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .xl\:via-pink-100 {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .xl\:via-pink-200 {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .xl\:via-pink-300 {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .xl\:via-pink-400 {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .xl\:via-pink-500 {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .xl\:via-pink-600 {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .xl\:via-pink-700 {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .xl\:via-pink-800 {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .xl\:via-pink-900 {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .xl\:to-transparent {
    --gradient-to-color: transparent;
  }

  .xl\:to-white {
    --gradient-to-color: #fff;
  }

  .xl\:to-black {
    --gradient-to-color: #000;
  }

  .xl\:to-gray-50 {
    --gradient-to-color: #f9fafb;
  }

  .xl\:to-gray-100 {
    --gradient-to-color: #f4f5f7;
  }

  .xl\:to-gray-200 {
    --gradient-to-color: #e5e7eb;
  }

  .xl\:to-gray-300 {
    --gradient-to-color: #d2d6dc;
  }

  .xl\:to-gray-400 {
    --gradient-to-color: #9fa6b2;
  }

  .xl\:to-gray-500 {
    --gradient-to-color: #6b7280;
  }

  .xl\:to-gray-600 {
    --gradient-to-color: #4b5563;
  }

  .xl\:to-gray-700 {
    --gradient-to-color: #374151;
  }

  .xl\:to-gray-800 {
    --gradient-to-color: #252f3f;
  }

  .xl\:to-gray-900 {
    --gradient-to-color: #161e2e;
  }

  .xl\:to-cool-gray-50 {
    --gradient-to-color: #fbfdfe;
  }

  .xl\:to-cool-gray-100 {
    --gradient-to-color: #f1f5f9;
  }

  .xl\:to-cool-gray-200 {
    --gradient-to-color: #e2e8f0;
  }

  .xl\:to-cool-gray-300 {
    --gradient-to-color: #cfd8e3;
  }

  .xl\:to-cool-gray-400 {
    --gradient-to-color: #97a6ba;
  }

  .xl\:to-cool-gray-500 {
    --gradient-to-color: #64748b;
  }

  .xl\:to-cool-gray-600 {
    --gradient-to-color: #475569;
  }

  .xl\:to-cool-gray-700 {
    --gradient-to-color: #364152;
  }

  .xl\:to-cool-gray-800 {
    --gradient-to-color: #27303f;
  }

  .xl\:to-cool-gray-900 {
    --gradient-to-color: #1a202e;
  }

  .xl\:to-red-50 {
    --gradient-to-color: #fdf2f2;
  }

  .xl\:to-red-100 {
    --gradient-to-color: #fde8e8;
  }

  .xl\:to-red-200 {
    --gradient-to-color: #fbd5d5;
  }

  .xl\:to-red-300 {
    --gradient-to-color: #f8b4b4;
  }

  .xl\:to-red-400 {
    --gradient-to-color: #f98080;
  }

  .xl\:to-red-500 {
    --gradient-to-color: #f05252;
  }

  .xl\:to-red-600 {
    --gradient-to-color: #e02424;
  }

  .xl\:to-red-700 {
    --gradient-to-color: #c81e1e;
  }

  .xl\:to-red-800 {
    --gradient-to-color: #9b1c1c;
  }

  .xl\:to-red-900 {
    --gradient-to-color: #771d1d;
  }

  .xl\:to-orange-50 {
    --gradient-to-color: #fff8f1;
  }

  .xl\:to-orange-100 {
    --gradient-to-color: #feecdc;
  }

  .xl\:to-orange-200 {
    --gradient-to-color: #fcd9bd;
  }

  .xl\:to-orange-300 {
    --gradient-to-color: #fdba8c;
  }

  .xl\:to-orange-400 {
    --gradient-to-color: #ff8a4c;
  }

  .xl\:to-orange-500 {
    --gradient-to-color: #ff5a1f;
  }

  .xl\:to-orange-600 {
    --gradient-to-color: #d03801;
  }

  .xl\:to-orange-700 {
    --gradient-to-color: #b43403;
  }

  .xl\:to-orange-800 {
    --gradient-to-color: #8a2c0d;
  }

  .xl\:to-orange-900 {
    --gradient-to-color: #73230d;
  }

  .xl\:to-yellow-50 {
    --gradient-to-color: #fdfdea;
  }

  .xl\:to-yellow-100 {
    --gradient-to-color: #fdf6b2;
  }

  .xl\:to-yellow-200 {
    --gradient-to-color: #fce96a;
  }

  .xl\:to-yellow-300 {
    --gradient-to-color: #faca15;
  }

  .xl\:to-yellow-400 {
    --gradient-to-color: #e3a008;
  }

  .xl\:to-yellow-500 {
    --gradient-to-color: #c27803;
  }

  .xl\:to-yellow-600 {
    --gradient-to-color: #9f580a;
  }

  .xl\:to-yellow-700 {
    --gradient-to-color: #8e4b10;
  }

  .xl\:to-yellow-800 {
    --gradient-to-color: #723b13;
  }

  .xl\:to-yellow-900 {
    --gradient-to-color: #633112;
  }

  .xl\:to-green-50 {
    --gradient-to-color: #f3faf7;
  }

  .xl\:to-green-100 {
    --gradient-to-color: #def7ec;
  }

  .xl\:to-green-200 {
    --gradient-to-color: #bcf0da;
  }

  .xl\:to-green-300 {
    --gradient-to-color: #84e1bc;
  }

  .xl\:to-green-400 {
    --gradient-to-color: #31c48d;
  }

  .xl\:to-green-500 {
    --gradient-to-color: #0e9f6e;
  }

  .xl\:to-green-600 {
    --gradient-to-color: #057a55;
  }

  .xl\:to-green-700 {
    --gradient-to-color: #046c4e;
  }

  .xl\:to-green-800 {
    --gradient-to-color: #03543f;
  }

  .xl\:to-green-900 {
    --gradient-to-color: #014737;
  }

  .xl\:to-teal-50 {
    --gradient-to-color: #edfafa;
  }

  .xl\:to-teal-100 {
    --gradient-to-color: #d5f5f6;
  }

  .xl\:to-teal-200 {
    --gradient-to-color: #afecef;
  }

  .xl\:to-teal-300 {
    --gradient-to-color: #7edce2;
  }

  .xl\:to-teal-400 {
    --gradient-to-color: #16bdca;
  }

  .xl\:to-teal-500 {
    --gradient-to-color: #0694a2;
  }

  .xl\:to-teal-600 {
    --gradient-to-color: #047481;
  }

  .xl\:to-teal-700 {
    --gradient-to-color: #036672;
  }

  .xl\:to-teal-800 {
    --gradient-to-color: #05505c;
  }

  .xl\:to-teal-900 {
    --gradient-to-color: #014451;
  }

  .xl\:to-blue-50 {
    --gradient-to-color: #ebf5ff;
  }

  .xl\:to-blue-100 {
    --gradient-to-color: #e1effe;
  }

  .xl\:to-blue-200 {
    --gradient-to-color: #c3ddfd;
  }

  .xl\:to-blue-300 {
    --gradient-to-color: #a4cafe;
  }

  .xl\:to-blue-400 {
    --gradient-to-color: #76a9fa;
  }

  .xl\:to-blue-500 {
    --gradient-to-color: #3f83f8;
  }

  .xl\:to-blue-600 {
    --gradient-to-color: #1c64f2;
  }

  .xl\:to-blue-700 {
    --gradient-to-color: #1a56db;
  }

  .xl\:to-blue-800 {
    --gradient-to-color: #1e429f;
  }

  .xl\:to-blue-900 {
    --gradient-to-color: #233876;
  }

  .xl\:to-indigo-50 {
    --gradient-to-color: #f0f5ff;
  }

  .xl\:to-indigo-100 {
    --gradient-to-color: #e5edff;
  }

  .xl\:to-indigo-200 {
    --gradient-to-color: #cddbfe;
  }

  .xl\:to-indigo-300 {
    --gradient-to-color: #b4c6fc;
  }

  .xl\:to-indigo-400 {
    --gradient-to-color: #8da2fb;
  }

  .xl\:to-indigo-500 {
    --gradient-to-color: #6875f5;
  }

  .xl\:to-indigo-600 {
    --gradient-to-color: #5850ec;
  }

  .xl\:to-indigo-700 {
    --gradient-to-color: #5145cd;
  }

  .xl\:to-indigo-800 {
    --gradient-to-color: #42389d;
  }

  .xl\:to-indigo-900 {
    --gradient-to-color: #362f78;
  }

  .xl\:to-purple-50 {
    --gradient-to-color: #f6f5ff;
  }

  .xl\:to-purple-100 {
    --gradient-to-color: #edebfe;
  }

  .xl\:to-purple-200 {
    --gradient-to-color: #dcd7fe;
  }

  .xl\:to-purple-300 {
    --gradient-to-color: #cabffd;
  }

  .xl\:to-purple-400 {
    --gradient-to-color: #ac94fa;
  }

  .xl\:to-purple-500 {
    --gradient-to-color: #9061f9;
  }

  .xl\:to-purple-600 {
    --gradient-to-color: #7e3af2;
  }

  .xl\:to-purple-700 {
    --gradient-to-color: #6c2bd9;
  }

  .xl\:to-purple-800 {
    --gradient-to-color: #5521b5;
  }

  .xl\:to-purple-900 {
    --gradient-to-color: #4a1d96;
  }

  .xl\:to-pink-50 {
    --gradient-to-color: #fdf2f8;
  }

  .xl\:to-pink-100 {
    --gradient-to-color: #fce8f3;
  }

  .xl\:to-pink-200 {
    --gradient-to-color: #fad1e8;
  }

  .xl\:to-pink-300 {
    --gradient-to-color: #f8b4d9;
  }

  .xl\:to-pink-400 {
    --gradient-to-color: #f17eb8;
  }

  .xl\:to-pink-500 {
    --gradient-to-color: #e74694;
  }

  .xl\:to-pink-600 {
    --gradient-to-color: #d61f69;
  }

  .xl\:to-pink-700 {
    --gradient-to-color: #bf125d;
  }

  .xl\:to-pink-800 {
    --gradient-to-color: #99154b;
  }

  .xl\:to-pink-900 {
    --gradient-to-color: #751a3d;
  }

  .xl\:hover\:from-transparent:hover {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .xl\:hover\:from-white:hover {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .xl\:hover\:from-black:hover {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .xl\:hover\:from-gray-50:hover {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .xl\:hover\:from-gray-100:hover {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .xl\:hover\:from-gray-200:hover {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .xl\:hover\:from-gray-300:hover {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .xl\:hover\:from-gray-400:hover {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .xl\:hover\:from-gray-500:hover {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .xl\:hover\:from-gray-600:hover {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .xl\:hover\:from-gray-700:hover {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .xl\:hover\:from-gray-800:hover {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .xl\:hover\:from-gray-900:hover {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .xl\:hover\:from-cool-gray-50:hover {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .xl\:hover\:from-cool-gray-100:hover {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .xl\:hover\:from-cool-gray-200:hover {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .xl\:hover\:from-cool-gray-300:hover {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .xl\:hover\:from-cool-gray-400:hover {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .xl\:hover\:from-cool-gray-500:hover {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .xl\:hover\:from-cool-gray-600:hover {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .xl\:hover\:from-cool-gray-700:hover {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .xl\:hover\:from-cool-gray-800:hover {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .xl\:hover\:from-cool-gray-900:hover {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .xl\:hover\:from-red-50:hover {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .xl\:hover\:from-red-100:hover {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .xl\:hover\:from-red-200:hover {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .xl\:hover\:from-red-300:hover {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .xl\:hover\:from-red-400:hover {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .xl\:hover\:from-red-500:hover {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .xl\:hover\:from-red-600:hover {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .xl\:hover\:from-red-700:hover {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .xl\:hover\:from-red-800:hover {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .xl\:hover\:from-red-900:hover {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .xl\:hover\:from-orange-50:hover {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .xl\:hover\:from-orange-100:hover {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .xl\:hover\:from-orange-200:hover {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .xl\:hover\:from-orange-300:hover {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .xl\:hover\:from-orange-400:hover {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .xl\:hover\:from-orange-500:hover {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .xl\:hover\:from-orange-600:hover {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .xl\:hover\:from-orange-700:hover {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .xl\:hover\:from-orange-800:hover {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .xl\:hover\:from-orange-900:hover {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .xl\:hover\:from-yellow-50:hover {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .xl\:hover\:from-yellow-100:hover {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .xl\:hover\:from-yellow-200:hover {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .xl\:hover\:from-yellow-300:hover {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .xl\:hover\:from-yellow-400:hover {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .xl\:hover\:from-yellow-500:hover {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .xl\:hover\:from-yellow-600:hover {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .xl\:hover\:from-yellow-700:hover {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .xl\:hover\:from-yellow-800:hover {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .xl\:hover\:from-yellow-900:hover {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .xl\:hover\:from-green-50:hover {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .xl\:hover\:from-green-100:hover {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .xl\:hover\:from-green-200:hover {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .xl\:hover\:from-green-300:hover {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .xl\:hover\:from-green-400:hover {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .xl\:hover\:from-green-500:hover {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .xl\:hover\:from-green-600:hover {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .xl\:hover\:from-green-700:hover {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .xl\:hover\:from-green-800:hover {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .xl\:hover\:from-green-900:hover {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .xl\:hover\:from-teal-50:hover {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .xl\:hover\:from-teal-100:hover {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .xl\:hover\:from-teal-200:hover {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .xl\:hover\:from-teal-300:hover {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .xl\:hover\:from-teal-400:hover {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .xl\:hover\:from-teal-500:hover {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .xl\:hover\:from-teal-600:hover {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .xl\:hover\:from-teal-700:hover {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .xl\:hover\:from-teal-800:hover {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .xl\:hover\:from-teal-900:hover {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .xl\:hover\:from-blue-50:hover {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .xl\:hover\:from-blue-100:hover {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .xl\:hover\:from-blue-200:hover {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .xl\:hover\:from-blue-300:hover {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .xl\:hover\:from-blue-400:hover {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .xl\:hover\:from-blue-500:hover {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .xl\:hover\:from-blue-600:hover {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .xl\:hover\:from-blue-700:hover {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .xl\:hover\:from-blue-800:hover {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .xl\:hover\:from-blue-900:hover {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .xl\:hover\:from-indigo-50:hover {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .xl\:hover\:from-indigo-100:hover {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .xl\:hover\:from-indigo-200:hover {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .xl\:hover\:from-indigo-300:hover {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .xl\:hover\:from-indigo-400:hover {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .xl\:hover\:from-indigo-500:hover {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .xl\:hover\:from-indigo-600:hover {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .xl\:hover\:from-indigo-700:hover {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .xl\:hover\:from-indigo-800:hover {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .xl\:hover\:from-indigo-900:hover {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .xl\:hover\:from-purple-50:hover {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .xl\:hover\:from-purple-100:hover {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .xl\:hover\:from-purple-200:hover {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .xl\:hover\:from-purple-300:hover {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .xl\:hover\:from-purple-400:hover {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .xl\:hover\:from-purple-500:hover {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .xl\:hover\:from-purple-600:hover {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .xl\:hover\:from-purple-700:hover {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .xl\:hover\:from-purple-800:hover {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .xl\:hover\:from-purple-900:hover {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .xl\:hover\:from-pink-50:hover {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .xl\:hover\:from-pink-100:hover {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .xl\:hover\:from-pink-200:hover {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .xl\:hover\:from-pink-300:hover {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .xl\:hover\:from-pink-400:hover {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .xl\:hover\:from-pink-500:hover {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .xl\:hover\:from-pink-600:hover {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .xl\:hover\:from-pink-700:hover {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .xl\:hover\:from-pink-800:hover {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .xl\:hover\:from-pink-900:hover {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .xl\:hover\:via-transparent:hover {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .xl\:hover\:via-white:hover {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .xl\:hover\:via-black:hover {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .xl\:hover\:via-gray-50:hover {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .xl\:hover\:via-gray-100:hover {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .xl\:hover\:via-gray-200:hover {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .xl\:hover\:via-gray-300:hover {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .xl\:hover\:via-gray-400:hover {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .xl\:hover\:via-gray-500:hover {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .xl\:hover\:via-gray-600:hover {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .xl\:hover\:via-gray-700:hover {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .xl\:hover\:via-gray-800:hover {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .xl\:hover\:via-gray-900:hover {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .xl\:hover\:via-cool-gray-50:hover {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .xl\:hover\:via-cool-gray-100:hover {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .xl\:hover\:via-cool-gray-200:hover {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .xl\:hover\:via-cool-gray-300:hover {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .xl\:hover\:via-cool-gray-400:hover {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .xl\:hover\:via-cool-gray-500:hover {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .xl\:hover\:via-cool-gray-600:hover {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .xl\:hover\:via-cool-gray-700:hover {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .xl\:hover\:via-cool-gray-800:hover {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .xl\:hover\:via-cool-gray-900:hover {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .xl\:hover\:via-red-50:hover {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .xl\:hover\:via-red-100:hover {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .xl\:hover\:via-red-200:hover {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .xl\:hover\:via-red-300:hover {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .xl\:hover\:via-red-400:hover {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .xl\:hover\:via-red-500:hover {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .xl\:hover\:via-red-600:hover {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .xl\:hover\:via-red-700:hover {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .xl\:hover\:via-red-800:hover {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .xl\:hover\:via-red-900:hover {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .xl\:hover\:via-orange-50:hover {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .xl\:hover\:via-orange-100:hover {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .xl\:hover\:via-orange-200:hover {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .xl\:hover\:via-orange-300:hover {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .xl\:hover\:via-orange-400:hover {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .xl\:hover\:via-orange-500:hover {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .xl\:hover\:via-orange-600:hover {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .xl\:hover\:via-orange-700:hover {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .xl\:hover\:via-orange-800:hover {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .xl\:hover\:via-orange-900:hover {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .xl\:hover\:via-yellow-50:hover {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .xl\:hover\:via-yellow-100:hover {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .xl\:hover\:via-yellow-200:hover {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .xl\:hover\:via-yellow-300:hover {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .xl\:hover\:via-yellow-400:hover {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .xl\:hover\:via-yellow-500:hover {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .xl\:hover\:via-yellow-600:hover {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .xl\:hover\:via-yellow-700:hover {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .xl\:hover\:via-yellow-800:hover {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .xl\:hover\:via-yellow-900:hover {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .xl\:hover\:via-green-50:hover {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .xl\:hover\:via-green-100:hover {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .xl\:hover\:via-green-200:hover {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .xl\:hover\:via-green-300:hover {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .xl\:hover\:via-green-400:hover {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .xl\:hover\:via-green-500:hover {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .xl\:hover\:via-green-600:hover {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .xl\:hover\:via-green-700:hover {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .xl\:hover\:via-green-800:hover {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .xl\:hover\:via-green-900:hover {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .xl\:hover\:via-teal-50:hover {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .xl\:hover\:via-teal-100:hover {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .xl\:hover\:via-teal-200:hover {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .xl\:hover\:via-teal-300:hover {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .xl\:hover\:via-teal-400:hover {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .xl\:hover\:via-teal-500:hover {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .xl\:hover\:via-teal-600:hover {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .xl\:hover\:via-teal-700:hover {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .xl\:hover\:via-teal-800:hover {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .xl\:hover\:via-teal-900:hover {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .xl\:hover\:via-blue-50:hover {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .xl\:hover\:via-blue-100:hover {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .xl\:hover\:via-blue-200:hover {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .xl\:hover\:via-blue-300:hover {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .xl\:hover\:via-blue-400:hover {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .xl\:hover\:via-blue-500:hover {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .xl\:hover\:via-blue-600:hover {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .xl\:hover\:via-blue-700:hover {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .xl\:hover\:via-blue-800:hover {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .xl\:hover\:via-blue-900:hover {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .xl\:hover\:via-indigo-50:hover {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .xl\:hover\:via-indigo-100:hover {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .xl\:hover\:via-indigo-200:hover {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .xl\:hover\:via-indigo-300:hover {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .xl\:hover\:via-indigo-400:hover {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .xl\:hover\:via-indigo-500:hover {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .xl\:hover\:via-indigo-600:hover {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .xl\:hover\:via-indigo-700:hover {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .xl\:hover\:via-indigo-800:hover {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .xl\:hover\:via-indigo-900:hover {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .xl\:hover\:via-purple-50:hover {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .xl\:hover\:via-purple-100:hover {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .xl\:hover\:via-purple-200:hover {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .xl\:hover\:via-purple-300:hover {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .xl\:hover\:via-purple-400:hover {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .xl\:hover\:via-purple-500:hover {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .xl\:hover\:via-purple-600:hover {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .xl\:hover\:via-purple-700:hover {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .xl\:hover\:via-purple-800:hover {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .xl\:hover\:via-purple-900:hover {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .xl\:hover\:via-pink-50:hover {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .xl\:hover\:via-pink-100:hover {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .xl\:hover\:via-pink-200:hover {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .xl\:hover\:via-pink-300:hover {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .xl\:hover\:via-pink-400:hover {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .xl\:hover\:via-pink-500:hover {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .xl\:hover\:via-pink-600:hover {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .xl\:hover\:via-pink-700:hover {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .xl\:hover\:via-pink-800:hover {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .xl\:hover\:via-pink-900:hover {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .xl\:hover\:to-transparent:hover {
    --gradient-to-color: transparent;
  }

  .xl\:hover\:to-white:hover {
    --gradient-to-color: #fff;
  }

  .xl\:hover\:to-black:hover {
    --gradient-to-color: #000;
  }

  .xl\:hover\:to-gray-50:hover {
    --gradient-to-color: #f9fafb;
  }

  .xl\:hover\:to-gray-100:hover {
    --gradient-to-color: #f4f5f7;
  }

  .xl\:hover\:to-gray-200:hover {
    --gradient-to-color: #e5e7eb;
  }

  .xl\:hover\:to-gray-300:hover {
    --gradient-to-color: #d2d6dc;
  }

  .xl\:hover\:to-gray-400:hover {
    --gradient-to-color: #9fa6b2;
  }

  .xl\:hover\:to-gray-500:hover {
    --gradient-to-color: #6b7280;
  }

  .xl\:hover\:to-gray-600:hover {
    --gradient-to-color: #4b5563;
  }

  .xl\:hover\:to-gray-700:hover {
    --gradient-to-color: #374151;
  }

  .xl\:hover\:to-gray-800:hover {
    --gradient-to-color: #252f3f;
  }

  .xl\:hover\:to-gray-900:hover {
    --gradient-to-color: #161e2e;
  }

  .xl\:hover\:to-cool-gray-50:hover {
    --gradient-to-color: #fbfdfe;
  }

  .xl\:hover\:to-cool-gray-100:hover {
    --gradient-to-color: #f1f5f9;
  }

  .xl\:hover\:to-cool-gray-200:hover {
    --gradient-to-color: #e2e8f0;
  }

  .xl\:hover\:to-cool-gray-300:hover {
    --gradient-to-color: #cfd8e3;
  }

  .xl\:hover\:to-cool-gray-400:hover {
    --gradient-to-color: #97a6ba;
  }

  .xl\:hover\:to-cool-gray-500:hover {
    --gradient-to-color: #64748b;
  }

  .xl\:hover\:to-cool-gray-600:hover {
    --gradient-to-color: #475569;
  }

  .xl\:hover\:to-cool-gray-700:hover {
    --gradient-to-color: #364152;
  }

  .xl\:hover\:to-cool-gray-800:hover {
    --gradient-to-color: #27303f;
  }

  .xl\:hover\:to-cool-gray-900:hover {
    --gradient-to-color: #1a202e;
  }

  .xl\:hover\:to-red-50:hover {
    --gradient-to-color: #fdf2f2;
  }

  .xl\:hover\:to-red-100:hover {
    --gradient-to-color: #fde8e8;
  }

  .xl\:hover\:to-red-200:hover {
    --gradient-to-color: #fbd5d5;
  }

  .xl\:hover\:to-red-300:hover {
    --gradient-to-color: #f8b4b4;
  }

  .xl\:hover\:to-red-400:hover {
    --gradient-to-color: #f98080;
  }

  .xl\:hover\:to-red-500:hover {
    --gradient-to-color: #f05252;
  }

  .xl\:hover\:to-red-600:hover {
    --gradient-to-color: #e02424;
  }

  .xl\:hover\:to-red-700:hover {
    --gradient-to-color: #c81e1e;
  }

  .xl\:hover\:to-red-800:hover {
    --gradient-to-color: #9b1c1c;
  }

  .xl\:hover\:to-red-900:hover {
    --gradient-to-color: #771d1d;
  }

  .xl\:hover\:to-orange-50:hover {
    --gradient-to-color: #fff8f1;
  }

  .xl\:hover\:to-orange-100:hover {
    --gradient-to-color: #feecdc;
  }

  .xl\:hover\:to-orange-200:hover {
    --gradient-to-color: #fcd9bd;
  }

  .xl\:hover\:to-orange-300:hover {
    --gradient-to-color: #fdba8c;
  }

  .xl\:hover\:to-orange-400:hover {
    --gradient-to-color: #ff8a4c;
  }

  .xl\:hover\:to-orange-500:hover {
    --gradient-to-color: #ff5a1f;
  }

  .xl\:hover\:to-orange-600:hover {
    --gradient-to-color: #d03801;
  }

  .xl\:hover\:to-orange-700:hover {
    --gradient-to-color: #b43403;
  }

  .xl\:hover\:to-orange-800:hover {
    --gradient-to-color: #8a2c0d;
  }

  .xl\:hover\:to-orange-900:hover {
    --gradient-to-color: #73230d;
  }

  .xl\:hover\:to-yellow-50:hover {
    --gradient-to-color: #fdfdea;
  }

  .xl\:hover\:to-yellow-100:hover {
    --gradient-to-color: #fdf6b2;
  }

  .xl\:hover\:to-yellow-200:hover {
    --gradient-to-color: #fce96a;
  }

  .xl\:hover\:to-yellow-300:hover {
    --gradient-to-color: #faca15;
  }

  .xl\:hover\:to-yellow-400:hover {
    --gradient-to-color: #e3a008;
  }

  .xl\:hover\:to-yellow-500:hover {
    --gradient-to-color: #c27803;
  }

  .xl\:hover\:to-yellow-600:hover {
    --gradient-to-color: #9f580a;
  }

  .xl\:hover\:to-yellow-700:hover {
    --gradient-to-color: #8e4b10;
  }

  .xl\:hover\:to-yellow-800:hover {
    --gradient-to-color: #723b13;
  }

  .xl\:hover\:to-yellow-900:hover {
    --gradient-to-color: #633112;
  }

  .xl\:hover\:to-green-50:hover {
    --gradient-to-color: #f3faf7;
  }

  .xl\:hover\:to-green-100:hover {
    --gradient-to-color: #def7ec;
  }

  .xl\:hover\:to-green-200:hover {
    --gradient-to-color: #bcf0da;
  }

  .xl\:hover\:to-green-300:hover {
    --gradient-to-color: #84e1bc;
  }

  .xl\:hover\:to-green-400:hover {
    --gradient-to-color: #31c48d;
  }

  .xl\:hover\:to-green-500:hover {
    --gradient-to-color: #0e9f6e;
  }

  .xl\:hover\:to-green-600:hover {
    --gradient-to-color: #057a55;
  }

  .xl\:hover\:to-green-700:hover {
    --gradient-to-color: #046c4e;
  }

  .xl\:hover\:to-green-800:hover {
    --gradient-to-color: #03543f;
  }

  .xl\:hover\:to-green-900:hover {
    --gradient-to-color: #014737;
  }

  .xl\:hover\:to-teal-50:hover {
    --gradient-to-color: #edfafa;
  }

  .xl\:hover\:to-teal-100:hover {
    --gradient-to-color: #d5f5f6;
  }

  .xl\:hover\:to-teal-200:hover {
    --gradient-to-color: #afecef;
  }

  .xl\:hover\:to-teal-300:hover {
    --gradient-to-color: #7edce2;
  }

  .xl\:hover\:to-teal-400:hover {
    --gradient-to-color: #16bdca;
  }

  .xl\:hover\:to-teal-500:hover {
    --gradient-to-color: #0694a2;
  }

  .xl\:hover\:to-teal-600:hover {
    --gradient-to-color: #047481;
  }

  .xl\:hover\:to-teal-700:hover {
    --gradient-to-color: #036672;
  }

  .xl\:hover\:to-teal-800:hover {
    --gradient-to-color: #05505c;
  }

  .xl\:hover\:to-teal-900:hover {
    --gradient-to-color: #014451;
  }

  .xl\:hover\:to-blue-50:hover {
    --gradient-to-color: #ebf5ff;
  }

  .xl\:hover\:to-blue-100:hover {
    --gradient-to-color: #e1effe;
  }

  .xl\:hover\:to-blue-200:hover {
    --gradient-to-color: #c3ddfd;
  }

  .xl\:hover\:to-blue-300:hover {
    --gradient-to-color: #a4cafe;
  }

  .xl\:hover\:to-blue-400:hover {
    --gradient-to-color: #76a9fa;
  }

  .xl\:hover\:to-blue-500:hover {
    --gradient-to-color: #3f83f8;
  }

  .xl\:hover\:to-blue-600:hover {
    --gradient-to-color: #1c64f2;
  }

  .xl\:hover\:to-blue-700:hover {
    --gradient-to-color: #1a56db;
  }

  .xl\:hover\:to-blue-800:hover {
    --gradient-to-color: #1e429f;
  }

  .xl\:hover\:to-blue-900:hover {
    --gradient-to-color: #233876;
  }

  .xl\:hover\:to-indigo-50:hover {
    --gradient-to-color: #f0f5ff;
  }

  .xl\:hover\:to-indigo-100:hover {
    --gradient-to-color: #e5edff;
  }

  .xl\:hover\:to-indigo-200:hover {
    --gradient-to-color: #cddbfe;
  }

  .xl\:hover\:to-indigo-300:hover {
    --gradient-to-color: #b4c6fc;
  }

  .xl\:hover\:to-indigo-400:hover {
    --gradient-to-color: #8da2fb;
  }

  .xl\:hover\:to-indigo-500:hover {
    --gradient-to-color: #6875f5;
  }

  .xl\:hover\:to-indigo-600:hover {
    --gradient-to-color: #5850ec;
  }

  .xl\:hover\:to-indigo-700:hover {
    --gradient-to-color: #5145cd;
  }

  .xl\:hover\:to-indigo-800:hover {
    --gradient-to-color: #42389d;
  }

  .xl\:hover\:to-indigo-900:hover {
    --gradient-to-color: #362f78;
  }

  .xl\:hover\:to-purple-50:hover {
    --gradient-to-color: #f6f5ff;
  }

  .xl\:hover\:to-purple-100:hover {
    --gradient-to-color: #edebfe;
  }

  .xl\:hover\:to-purple-200:hover {
    --gradient-to-color: #dcd7fe;
  }

  .xl\:hover\:to-purple-300:hover {
    --gradient-to-color: #cabffd;
  }

  .xl\:hover\:to-purple-400:hover {
    --gradient-to-color: #ac94fa;
  }

  .xl\:hover\:to-purple-500:hover {
    --gradient-to-color: #9061f9;
  }

  .xl\:hover\:to-purple-600:hover {
    --gradient-to-color: #7e3af2;
  }

  .xl\:hover\:to-purple-700:hover {
    --gradient-to-color: #6c2bd9;
  }

  .xl\:hover\:to-purple-800:hover {
    --gradient-to-color: #5521b5;
  }

  .xl\:hover\:to-purple-900:hover {
    --gradient-to-color: #4a1d96;
  }

  .xl\:hover\:to-pink-50:hover {
    --gradient-to-color: #fdf2f8;
  }

  .xl\:hover\:to-pink-100:hover {
    --gradient-to-color: #fce8f3;
  }

  .xl\:hover\:to-pink-200:hover {
    --gradient-to-color: #fad1e8;
  }

  .xl\:hover\:to-pink-300:hover {
    --gradient-to-color: #f8b4d9;
  }

  .xl\:hover\:to-pink-400:hover {
    --gradient-to-color: #f17eb8;
  }

  .xl\:hover\:to-pink-500:hover {
    --gradient-to-color: #e74694;
  }

  .xl\:hover\:to-pink-600:hover {
    --gradient-to-color: #d61f69;
  }

  .xl\:hover\:to-pink-700:hover {
    --gradient-to-color: #bf125d;
  }

  .xl\:hover\:to-pink-800:hover {
    --gradient-to-color: #99154b;
  }

  .xl\:hover\:to-pink-900:hover {
    --gradient-to-color: #751a3d;
  }

  .xl\:focus\:from-transparent:focus {
    --gradient-from-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .xl\:focus\:from-white:focus {
    --gradient-from-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff0);
  }

  .xl\:focus\:from-black:focus {
    --gradient-from-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0000);
  }

  .xl\:focus\:from-gray-50:focus {
    --gradient-from-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9fafb00);
  }

  .xl\:focus\:from-gray-100:focus {
    --gradient-from-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f4f5f700);
  }

  .xl\:focus\:from-gray-200:focus {
    --gradient-from-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5e7eb00);
  }

  .xl\:focus\:from-gray-300:focus {
    --gradient-from-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d2d6dc00);
  }

  .xl\:focus\:from-gray-400:focus {
    --gradient-from-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9fa6b200);
  }

  .xl\:focus\:from-gray-500:focus {
    --gradient-from-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6b728000);
  }

  .xl\:focus\:from-gray-600:focus {
    --gradient-from-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4b556300);
  }

  .xl\:focus\:from-gray-700:focus {
    --gradient-from-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #37415100);
  }

  .xl\:focus\:from-gray-800:focus {
    --gradient-from-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #252f3f00);
  }

  .xl\:focus\:from-gray-900:focus {
    --gradient-from-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #161e2e00);
  }

  .xl\:focus\:from-cool-gray-50:focus {
    --gradient-from-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbfdfe00);
  }

  .xl\:focus\:from-cool-gray-100:focus {
    --gradient-from-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f1f5f900);
  }

  .xl\:focus\:from-cool-gray-200:focus {
    --gradient-from-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e2e8f000);
  }

  .xl\:focus\:from-cool-gray-300:focus {
    --gradient-from-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cfd8e300);
  }

  .xl\:focus\:from-cool-gray-400:focus {
    --gradient-from-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #97a6ba00);
  }

  .xl\:focus\:from-cool-gray-500:focus {
    --gradient-from-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #64748b00);
  }

  .xl\:focus\:from-cool-gray-600:focus {
    --gradient-from-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #47556900);
  }

  .xl\:focus\:from-cool-gray-700:focus {
    --gradient-from-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #36415200);
  }

  .xl\:focus\:from-cool-gray-800:focus {
    --gradient-from-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #27303f00);
  }

  .xl\:focus\:from-cool-gray-900:focus {
    --gradient-from-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a202e00);
  }

  .xl\:focus\:from-red-50:focus {
    --gradient-from-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f200);
  }

  .xl\:focus\:from-red-100:focus {
    --gradient-from-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fde8e800);
  }

  .xl\:focus\:from-red-200:focus {
    --gradient-from-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fbd5d500);
  }

  .xl\:focus\:from-red-300:focus {
    --gradient-from-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4b400);
  }

  .xl\:focus\:from-red-400:focus {
    --gradient-from-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f9808000);
  }

  .xl\:focus\:from-red-500:focus {
    --gradient-from-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0525200);
  }

  .xl\:focus\:from-red-600:focus {
    --gradient-from-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e0242400);
  }

  .xl\:focus\:from-red-700:focus {
    --gradient-from-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c81e1e00);
  }

  .xl\:focus\:from-red-800:focus {
    --gradient-from-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9b1c1c00);
  }

  .xl\:focus\:from-red-900:focus {
    --gradient-from-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #771d1d00);
  }

  .xl\:focus\:from-orange-50:focus {
    --gradient-from-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fff8f100);
  }

  .xl\:focus\:from-orange-100:focus {
    --gradient-from-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #feecdc00);
  }

  .xl\:focus\:from-orange-200:focus {
    --gradient-from-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fcd9bd00);
  }

  .xl\:focus\:from-orange-300:focus {
    --gradient-from-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdba8c00);
  }

  .xl\:focus\:from-orange-400:focus {
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff8a4c00);
  }

  .xl\:focus\:from-orange-500:focus {
    --gradient-from-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ff5a1f00);
  }

  .xl\:focus\:from-orange-600:focus {
    --gradient-from-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d0380100);
  }

  .xl\:focus\:from-orange-700:focus {
    --gradient-from-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4340300);
  }

  .xl\:focus\:from-orange-800:focus {
    --gradient-from-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8a2c0d00);
  }

  .xl\:focus\:from-orange-900:focus {
    --gradient-from-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #73230d00);
  }

  .xl\:focus\:from-yellow-50:focus {
    --gradient-from-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdfdea00);
  }

  .xl\:focus\:from-yellow-100:focus {
    --gradient-from-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf6b200);
  }

  .xl\:focus\:from-yellow-200:focus {
    --gradient-from-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce96a00);
  }

  .xl\:focus\:from-yellow-300:focus {
    --gradient-from-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #faca1500);
  }

  .xl\:focus\:from-yellow-400:focus {
    --gradient-from-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e3a00800);
  }

  .xl\:focus\:from-yellow-500:focus {
    --gradient-from-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c2780300);
  }

  .xl\:focus\:from-yellow-600:focus {
    --gradient-from-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9f580a00);
  }

  .xl\:focus\:from-yellow-700:focus {
    --gradient-from-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8e4b1000);
  }

  .xl\:focus\:from-yellow-800:focus {
    --gradient-from-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #723b1300);
  }

  .xl\:focus\:from-yellow-900:focus {
    --gradient-from-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #63311200);
  }

  .xl\:focus\:from-green-50:focus {
    --gradient-from-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f3faf700);
  }

  .xl\:focus\:from-green-100:focus {
    --gradient-from-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #def7ec00);
  }

  .xl\:focus\:from-green-200:focus {
    --gradient-from-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bcf0da00);
  }

  .xl\:focus\:from-green-300:focus {
    --gradient-from-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #84e1bc00);
  }

  .xl\:focus\:from-green-400:focus {
    --gradient-from-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #31c48d00);
  }

  .xl\:focus\:from-green-500:focus {
    --gradient-from-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0e9f6e00);
  }

  .xl\:focus\:from-green-600:focus {
    --gradient-from-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #057a5500);
  }

  .xl\:focus\:from-green-700:focus {
    --gradient-from-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #046c4e00);
  }

  .xl\:focus\:from-green-800:focus {
    --gradient-from-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03543f00);
  }

  .xl\:focus\:from-green-900:focus {
    --gradient-from-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01473700);
  }

  .xl\:focus\:from-teal-50:focus {
    --gradient-from-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edfafa00);
  }

  .xl\:focus\:from-teal-100:focus {
    --gradient-from-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d5f5f600);
  }

  .xl\:focus\:from-teal-200:focus {
    --gradient-from-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #afecef00);
  }

  .xl\:focus\:from-teal-300:focus {
    --gradient-from-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7edce200);
  }

  .xl\:focus\:from-teal-400:focus {
    --gradient-from-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #16bdca00);
  }

  .xl\:focus\:from-teal-500:focus {
    --gradient-from-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #0694a200);
  }

  .xl\:focus\:from-teal-600:focus {
    --gradient-from-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #04748100);
  }

  .xl\:focus\:from-teal-700:focus {
    --gradient-from-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #03667200);
  }

  .xl\:focus\:from-teal-800:focus {
    --gradient-from-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #05505c00);
  }

  .xl\:focus\:from-teal-900:focus {
    --gradient-from-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #01445100);
  }

  .xl\:focus\:from-blue-50:focus {
    --gradient-from-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ebf5ff00);
  }

  .xl\:focus\:from-blue-100:focus {
    --gradient-from-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e1effe00);
  }

  .xl\:focus\:from-blue-200:focus {
    --gradient-from-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #c3ddfd00);
  }

  .xl\:focus\:from-blue-300:focus {
    --gradient-from-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #a4cafe00);
  }

  .xl\:focus\:from-blue-400:focus {
    --gradient-from-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #76a9fa00);
  }

  .xl\:focus\:from-blue-500:focus {
    --gradient-from-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #3f83f800);
  }

  .xl\:focus\:from-blue-600:focus {
    --gradient-from-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1c64f200);
  }

  .xl\:focus\:from-blue-700:focus {
    --gradient-from-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1a56db00);
  }

  .xl\:focus\:from-blue-800:focus {
    --gradient-from-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #1e429f00);
  }

  .xl\:focus\:from-blue-900:focus {
    --gradient-from-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #23387600);
  }

  .xl\:focus\:from-indigo-50:focus {
    --gradient-from-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f0f5ff00);
  }

  .xl\:focus\:from-indigo-100:focus {
    --gradient-from-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e5edff00);
  }

  .xl\:focus\:from-indigo-200:focus {
    --gradient-from-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cddbfe00);
  }

  .xl\:focus\:from-indigo-300:focus {
    --gradient-from-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #b4c6fc00);
  }

  .xl\:focus\:from-indigo-400:focus {
    --gradient-from-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #8da2fb00);
  }

  .xl\:focus\:from-indigo-500:focus {
    --gradient-from-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6875f500);
  }

  .xl\:focus\:from-indigo-600:focus {
    --gradient-from-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5850ec00);
  }

  .xl\:focus\:from-indigo-700:focus {
    --gradient-from-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5145cd00);
  }

  .xl\:focus\:from-indigo-800:focus {
    --gradient-from-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #42389d00);
  }

  .xl\:focus\:from-indigo-900:focus {
    --gradient-from-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #362f7800);
  }

  .xl\:focus\:from-purple-50:focus {
    --gradient-from-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f6f5ff00);
  }

  .xl\:focus\:from-purple-100:focus {
    --gradient-from-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #edebfe00);
  }

  .xl\:focus\:from-purple-200:focus {
    --gradient-from-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #dcd7fe00);
  }

  .xl\:focus\:from-purple-300:focus {
    --gradient-from-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #cabffd00);
  }

  .xl\:focus\:from-purple-400:focus {
    --gradient-from-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #ac94fa00);
  }

  .xl\:focus\:from-purple-500:focus {
    --gradient-from-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #9061f900);
  }

  .xl\:focus\:from-purple-600:focus {
    --gradient-from-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #7e3af200);
  }

  .xl\:focus\:from-purple-700:focus {
    --gradient-from-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #6c2bd900);
  }

  .xl\:focus\:from-purple-800:focus {
    --gradient-from-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #5521b500);
  }

  .xl\:focus\:from-purple-900:focus {
    --gradient-from-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #4a1d9600);
  }

  .xl\:focus\:from-pink-50:focus {
    --gradient-from-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fdf2f800);
  }

  .xl\:focus\:from-pink-100:focus {
    --gradient-from-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fce8f300);
  }

  .xl\:focus\:from-pink-200:focus {
    --gradient-from-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #fad1e800);
  }

  .xl\:focus\:from-pink-300:focus {
    --gradient-from-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f8b4d900);
  }

  .xl\:focus\:from-pink-400:focus {
    --gradient-from-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #f17eb800);
  }

  .xl\:focus\:from-pink-500:focus {
    --gradient-from-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #e7469400);
  }

  .xl\:focus\:from-pink-600:focus {
    --gradient-from-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #d61f6900);
  }

  .xl\:focus\:from-pink-700:focus {
    --gradient-from-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #bf125d00);
  }

  .xl\:focus\:from-pink-800:focus {
    --gradient-from-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #99154b00);
  }

  .xl\:focus\:from-pink-900:focus {
    --gradient-from-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-to-color, #751a3d00);
  }

  .xl\:focus\:via-transparent:focus {
    --gradient-via-color: transparent;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .xl\:focus\:via-white:focus {
    --gradient-via-color: #fff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff0);
  }

  .xl\:focus\:via-black:focus {
    --gradient-via-color: #000;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0000);
  }

  .xl\:focus\:via-gray-50:focus {
    --gradient-via-color: #f9fafb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9fafb00);
  }

  .xl\:focus\:via-gray-100:focus {
    --gradient-via-color: #f4f5f7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f4f5f700);
  }

  .xl\:focus\:via-gray-200:focus {
    --gradient-via-color: #e5e7eb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5e7eb00);
  }

  .xl\:focus\:via-gray-300:focus {
    --gradient-via-color: #d2d6dc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d2d6dc00);
  }

  .xl\:focus\:via-gray-400:focus {
    --gradient-via-color: #9fa6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9fa6b200);
  }

  .xl\:focus\:via-gray-500:focus {
    --gradient-via-color: #6b7280;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6b728000);
  }

  .xl\:focus\:via-gray-600:focus {
    --gradient-via-color: #4b5563;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4b556300);
  }

  .xl\:focus\:via-gray-700:focus {
    --gradient-via-color: #374151;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #37415100);
  }

  .xl\:focus\:via-gray-800:focus {
    --gradient-via-color: #252f3f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #252f3f00);
  }

  .xl\:focus\:via-gray-900:focus {
    --gradient-via-color: #161e2e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #161e2e00);
  }

  .xl\:focus\:via-cool-gray-50:focus {
    --gradient-via-color: #fbfdfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbfdfe00);
  }

  .xl\:focus\:via-cool-gray-100:focus {
    --gradient-via-color: #f1f5f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f1f5f900);
  }

  .xl\:focus\:via-cool-gray-200:focus {
    --gradient-via-color: #e2e8f0;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e2e8f000);
  }

  .xl\:focus\:via-cool-gray-300:focus {
    --gradient-via-color: #cfd8e3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cfd8e300);
  }

  .xl\:focus\:via-cool-gray-400:focus {
    --gradient-via-color: #97a6ba;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #97a6ba00);
  }

  .xl\:focus\:via-cool-gray-500:focus {
    --gradient-via-color: #64748b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #64748b00);
  }

  .xl\:focus\:via-cool-gray-600:focus {
    --gradient-via-color: #475569;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #47556900);
  }

  .xl\:focus\:via-cool-gray-700:focus {
    --gradient-via-color: #364152;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #36415200);
  }

  .xl\:focus\:via-cool-gray-800:focus {
    --gradient-via-color: #27303f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #27303f00);
  }

  .xl\:focus\:via-cool-gray-900:focus {
    --gradient-via-color: #1a202e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a202e00);
  }

  .xl\:focus\:via-red-50:focus {
    --gradient-via-color: #fdf2f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f200);
  }

  .xl\:focus\:via-red-100:focus {
    --gradient-via-color: #fde8e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fde8e800);
  }

  .xl\:focus\:via-red-200:focus {
    --gradient-via-color: #fbd5d5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fbd5d500);
  }

  .xl\:focus\:via-red-300:focus {
    --gradient-via-color: #f8b4b4;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4b400);
  }

  .xl\:focus\:via-red-400:focus {
    --gradient-via-color: #f98080;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f9808000);
  }

  .xl\:focus\:via-red-500:focus {
    --gradient-via-color: #f05252;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0525200);
  }

  .xl\:focus\:via-red-600:focus {
    --gradient-via-color: #e02424;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e0242400);
  }

  .xl\:focus\:via-red-700:focus {
    --gradient-via-color: #c81e1e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c81e1e00);
  }

  .xl\:focus\:via-red-800:focus {
    --gradient-via-color: #9b1c1c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9b1c1c00);
  }

  .xl\:focus\:via-red-900:focus {
    --gradient-via-color: #771d1d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #771d1d00);
  }

  .xl\:focus\:via-orange-50:focus {
    --gradient-via-color: #fff8f1;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fff8f100);
  }

  .xl\:focus\:via-orange-100:focus {
    --gradient-via-color: #feecdc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #feecdc00);
  }

  .xl\:focus\:via-orange-200:focus {
    --gradient-via-color: #fcd9bd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fcd9bd00);
  }

  .xl\:focus\:via-orange-300:focus {
    --gradient-via-color: #fdba8c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdba8c00);
  }

  .xl\:focus\:via-orange-400:focus {
    --gradient-via-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff8a4c00);
  }

  .xl\:focus\:via-orange-500:focus {
    --gradient-via-color: #ff5a1f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ff5a1f00);
  }

  .xl\:focus\:via-orange-600:focus {
    --gradient-via-color: #d03801;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d0380100);
  }

  .xl\:focus\:via-orange-700:focus {
    --gradient-via-color: #b43403;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4340300);
  }

  .xl\:focus\:via-orange-800:focus {
    --gradient-via-color: #8a2c0d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8a2c0d00);
  }

  .xl\:focus\:via-orange-900:focus {
    --gradient-via-color: #73230d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #73230d00);
  }

  .xl\:focus\:via-yellow-50:focus {
    --gradient-via-color: #fdfdea;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdfdea00);
  }

  .xl\:focus\:via-yellow-100:focus {
    --gradient-via-color: #fdf6b2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf6b200);
  }

  .xl\:focus\:via-yellow-200:focus {
    --gradient-via-color: #fce96a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce96a00);
  }

  .xl\:focus\:via-yellow-300:focus {
    --gradient-via-color: #faca15;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #faca1500);
  }

  .xl\:focus\:via-yellow-400:focus {
    --gradient-via-color: #e3a008;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e3a00800);
  }

  .xl\:focus\:via-yellow-500:focus {
    --gradient-via-color: #c27803;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c2780300);
  }

  .xl\:focus\:via-yellow-600:focus {
    --gradient-via-color: #9f580a;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9f580a00);
  }

  .xl\:focus\:via-yellow-700:focus {
    --gradient-via-color: #8e4b10;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8e4b1000);
  }

  .xl\:focus\:via-yellow-800:focus {
    --gradient-via-color: #723b13;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #723b1300);
  }

  .xl\:focus\:via-yellow-900:focus {
    --gradient-via-color: #633112;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #63311200);
  }

  .xl\:focus\:via-green-50:focus {
    --gradient-via-color: #f3faf7;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f3faf700);
  }

  .xl\:focus\:via-green-100:focus {
    --gradient-via-color: #def7ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #def7ec00);
  }

  .xl\:focus\:via-green-200:focus {
    --gradient-via-color: #bcf0da;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bcf0da00);
  }

  .xl\:focus\:via-green-300:focus {
    --gradient-via-color: #84e1bc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #84e1bc00);
  }

  .xl\:focus\:via-green-400:focus {
    --gradient-via-color: #31c48d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #31c48d00);
  }

  .xl\:focus\:via-green-500:focus {
    --gradient-via-color: #0e9f6e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0e9f6e00);
  }

  .xl\:focus\:via-green-600:focus {
    --gradient-via-color: #057a55;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #057a5500);
  }

  .xl\:focus\:via-green-700:focus {
    --gradient-via-color: #046c4e;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #046c4e00);
  }

  .xl\:focus\:via-green-800:focus {
    --gradient-via-color: #03543f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03543f00);
  }

  .xl\:focus\:via-green-900:focus {
    --gradient-via-color: #014737;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01473700);
  }

  .xl\:focus\:via-teal-50:focus {
    --gradient-via-color: #edfafa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edfafa00);
  }

  .xl\:focus\:via-teal-100:focus {
    --gradient-via-color: #d5f5f6;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d5f5f600);
  }

  .xl\:focus\:via-teal-200:focus {
    --gradient-via-color: #afecef;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #afecef00);
  }

  .xl\:focus\:via-teal-300:focus {
    --gradient-via-color: #7edce2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7edce200);
  }

  .xl\:focus\:via-teal-400:focus {
    --gradient-via-color: #16bdca;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #16bdca00);
  }

  .xl\:focus\:via-teal-500:focus {
    --gradient-via-color: #0694a2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #0694a200);
  }

  .xl\:focus\:via-teal-600:focus {
    --gradient-via-color: #047481;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #04748100);
  }

  .xl\:focus\:via-teal-700:focus {
    --gradient-via-color: #036672;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #03667200);
  }

  .xl\:focus\:via-teal-800:focus {
    --gradient-via-color: #05505c;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #05505c00);
  }

  .xl\:focus\:via-teal-900:focus {
    --gradient-via-color: #014451;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #01445100);
  }

  .xl\:focus\:via-blue-50:focus {
    --gradient-via-color: #ebf5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ebf5ff00);
  }

  .xl\:focus\:via-blue-100:focus {
    --gradient-via-color: #e1effe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e1effe00);
  }

  .xl\:focus\:via-blue-200:focus {
    --gradient-via-color: #c3ddfd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #c3ddfd00);
  }

  .xl\:focus\:via-blue-300:focus {
    --gradient-via-color: #a4cafe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #a4cafe00);
  }

  .xl\:focus\:via-blue-400:focus {
    --gradient-via-color: #76a9fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #76a9fa00);
  }

  .xl\:focus\:via-blue-500:focus {
    --gradient-via-color: #3f83f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #3f83f800);
  }

  .xl\:focus\:via-blue-600:focus {
    --gradient-via-color: #1c64f2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1c64f200);
  }

  .xl\:focus\:via-blue-700:focus {
    --gradient-via-color: #1a56db;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1a56db00);
  }

  .xl\:focus\:via-blue-800:focus {
    --gradient-via-color: #1e429f;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #1e429f00);
  }

  .xl\:focus\:via-blue-900:focus {
    --gradient-via-color: #233876;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #23387600);
  }

  .xl\:focus\:via-indigo-50:focus {
    --gradient-via-color: #f0f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f0f5ff00);
  }

  .xl\:focus\:via-indigo-100:focus {
    --gradient-via-color: #e5edff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e5edff00);
  }

  .xl\:focus\:via-indigo-200:focus {
    --gradient-via-color: #cddbfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cddbfe00);
  }

  .xl\:focus\:via-indigo-300:focus {
    --gradient-via-color: #b4c6fc;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #b4c6fc00);
  }

  .xl\:focus\:via-indigo-400:focus {
    --gradient-via-color: #8da2fb;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #8da2fb00);
  }

  .xl\:focus\:via-indigo-500:focus {
    --gradient-via-color: #6875f5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6875f500);
  }

  .xl\:focus\:via-indigo-600:focus {
    --gradient-via-color: #5850ec;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5850ec00);
  }

  .xl\:focus\:via-indigo-700:focus {
    --gradient-via-color: #5145cd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5145cd00);
  }

  .xl\:focus\:via-indigo-800:focus {
    --gradient-via-color: #42389d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #42389d00);
  }

  .xl\:focus\:via-indigo-900:focus {
    --gradient-via-color: #362f78;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #362f7800);
  }

  .xl\:focus\:via-purple-50:focus {
    --gradient-via-color: #f6f5ff;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f6f5ff00);
  }

  .xl\:focus\:via-purple-100:focus {
    --gradient-via-color: #edebfe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #edebfe00);
  }

  .xl\:focus\:via-purple-200:focus {
    --gradient-via-color: #dcd7fe;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #dcd7fe00);
  }

  .xl\:focus\:via-purple-300:focus {
    --gradient-via-color: #cabffd;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #cabffd00);
  }

  .xl\:focus\:via-purple-400:focus {
    --gradient-via-color: #ac94fa;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #ac94fa00);
  }

  .xl\:focus\:via-purple-500:focus {
    --gradient-via-color: #9061f9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #9061f900);
  }

  .xl\:focus\:via-purple-600:focus {
    --gradient-via-color: #7e3af2;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #7e3af200);
  }

  .xl\:focus\:via-purple-700:focus {
    --gradient-via-color: #6c2bd9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #6c2bd900);
  }

  .xl\:focus\:via-purple-800:focus {
    --gradient-via-color: #5521b5;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #5521b500);
  }

  .xl\:focus\:via-purple-900:focus {
    --gradient-via-color: #4a1d96;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #4a1d9600);
  }

  .xl\:focus\:via-pink-50:focus {
    --gradient-via-color: #fdf2f8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fdf2f800);
  }

  .xl\:focus\:via-pink-100:focus {
    --gradient-via-color: #fce8f3;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fce8f300);
  }

  .xl\:focus\:via-pink-200:focus {
    --gradient-via-color: #fad1e8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #fad1e800);
  }

  .xl\:focus\:via-pink-300:focus {
    --gradient-via-color: #f8b4d9;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f8b4d900);
  }

  .xl\:focus\:via-pink-400:focus {
    --gradient-via-color: #f17eb8;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #f17eb800);
  }

  .xl\:focus\:via-pink-500:focus {
    --gradient-via-color: #e74694;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #e7469400);
  }

  .xl\:focus\:via-pink-600:focus {
    --gradient-via-color: #d61f69;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #d61f6900);
  }

  .xl\:focus\:via-pink-700:focus {
    --gradient-via-color: #bf125d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #bf125d00);
  }

  .xl\:focus\:via-pink-800:focus {
    --gradient-via-color: #99154b;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #99154b00);
  }

  .xl\:focus\:via-pink-900:focus {
    --gradient-via-color: #751a3d;
    --gradient-color-stops: var(--gradient-from-color), var(--gradient-via-color), var(--gradient-to-color, #751a3d00);
  }

  .xl\:focus\:to-transparent:focus {
    --gradient-to-color: transparent;
  }

  .xl\:focus\:to-white:focus {
    --gradient-to-color: #fff;
  }

  .xl\:focus\:to-black:focus {
    --gradient-to-color: #000;
  }

  .xl\:focus\:to-gray-50:focus {
    --gradient-to-color: #f9fafb;
  }

  .xl\:focus\:to-gray-100:focus {
    --gradient-to-color: #f4f5f7;
  }

  .xl\:focus\:to-gray-200:focus {
    --gradient-to-color: #e5e7eb;
  }

  .xl\:focus\:to-gray-300:focus {
    --gradient-to-color: #d2d6dc;
  }

  .xl\:focus\:to-gray-400:focus {
    --gradient-to-color: #9fa6b2;
  }

  .xl\:focus\:to-gray-500:focus {
    --gradient-to-color: #6b7280;
  }

  .xl\:focus\:to-gray-600:focus {
    --gradient-to-color: #4b5563;
  }

  .xl\:focus\:to-gray-700:focus {
    --gradient-to-color: #374151;
  }

  .xl\:focus\:to-gray-800:focus {
    --gradient-to-color: #252f3f;
  }

  .xl\:focus\:to-gray-900:focus {
    --gradient-to-color: #161e2e;
  }

  .xl\:focus\:to-cool-gray-50:focus {
    --gradient-to-color: #fbfdfe;
  }

  .xl\:focus\:to-cool-gray-100:focus {
    --gradient-to-color: #f1f5f9;
  }

  .xl\:focus\:to-cool-gray-200:focus {
    --gradient-to-color: #e2e8f0;
  }

  .xl\:focus\:to-cool-gray-300:focus {
    --gradient-to-color: #cfd8e3;
  }

  .xl\:focus\:to-cool-gray-400:focus {
    --gradient-to-color: #97a6ba;
  }

  .xl\:focus\:to-cool-gray-500:focus {
    --gradient-to-color: #64748b;
  }

  .xl\:focus\:to-cool-gray-600:focus {
    --gradient-to-color: #475569;
  }

  .xl\:focus\:to-cool-gray-700:focus {
    --gradient-to-color: #364152;
  }

  .xl\:focus\:to-cool-gray-800:focus {
    --gradient-to-color: #27303f;
  }

  .xl\:focus\:to-cool-gray-900:focus {
    --gradient-to-color: #1a202e;
  }

  .xl\:focus\:to-red-50:focus {
    --gradient-to-color: #fdf2f2;
  }

  .xl\:focus\:to-red-100:focus {
    --gradient-to-color: #fde8e8;
  }

  .xl\:focus\:to-red-200:focus {
    --gradient-to-color: #fbd5d5;
  }

  .xl\:focus\:to-red-300:focus {
    --gradient-to-color: #f8b4b4;
  }

  .xl\:focus\:to-red-400:focus {
    --gradient-to-color: #f98080;
  }

  .xl\:focus\:to-red-500:focus {
    --gradient-to-color: #f05252;
  }

  .xl\:focus\:to-red-600:focus {
    --gradient-to-color: #e02424;
  }

  .xl\:focus\:to-red-700:focus {
    --gradient-to-color: #c81e1e;
  }

  .xl\:focus\:to-red-800:focus {
    --gradient-to-color: #9b1c1c;
  }

  .xl\:focus\:to-red-900:focus {
    --gradient-to-color: #771d1d;
  }

  .xl\:focus\:to-orange-50:focus {
    --gradient-to-color: #fff8f1;
  }

  .xl\:focus\:to-orange-100:focus {
    --gradient-to-color: #feecdc;
  }

  .xl\:focus\:to-orange-200:focus {
    --gradient-to-color: #fcd9bd;
  }

  .xl\:focus\:to-orange-300:focus {
    --gradient-to-color: #fdba8c;
  }

  .xl\:focus\:to-orange-400:focus {
    --gradient-to-color: #ff8a4c;
  }

  .xl\:focus\:to-orange-500:focus {
    --gradient-to-color: #ff5a1f;
  }

  .xl\:focus\:to-orange-600:focus {
    --gradient-to-color: #d03801;
  }

  .xl\:focus\:to-orange-700:focus {
    --gradient-to-color: #b43403;
  }

  .xl\:focus\:to-orange-800:focus {
    --gradient-to-color: #8a2c0d;
  }

  .xl\:focus\:to-orange-900:focus {
    --gradient-to-color: #73230d;
  }

  .xl\:focus\:to-yellow-50:focus {
    --gradient-to-color: #fdfdea;
  }

  .xl\:focus\:to-yellow-100:focus {
    --gradient-to-color: #fdf6b2;
  }

  .xl\:focus\:to-yellow-200:focus {
    --gradient-to-color: #fce96a;
  }

  .xl\:focus\:to-yellow-300:focus {
    --gradient-to-color: #faca15;
  }

  .xl\:focus\:to-yellow-400:focus {
    --gradient-to-color: #e3a008;
  }

  .xl\:focus\:to-yellow-500:focus {
    --gradient-to-color: #c27803;
  }

  .xl\:focus\:to-yellow-600:focus {
    --gradient-to-color: #9f580a;
  }

  .xl\:focus\:to-yellow-700:focus {
    --gradient-to-color: #8e4b10;
  }

  .xl\:focus\:to-yellow-800:focus {
    --gradient-to-color: #723b13;
  }

  .xl\:focus\:to-yellow-900:focus {
    --gradient-to-color: #633112;
  }

  .xl\:focus\:to-green-50:focus {
    --gradient-to-color: #f3faf7;
  }

  .xl\:focus\:to-green-100:focus {
    --gradient-to-color: #def7ec;
  }

  .xl\:focus\:to-green-200:focus {
    --gradient-to-color: #bcf0da;
  }

  .xl\:focus\:to-green-300:focus {
    --gradient-to-color: #84e1bc;
  }

  .xl\:focus\:to-green-400:focus {
    --gradient-to-color: #31c48d;
  }

  .xl\:focus\:to-green-500:focus {
    --gradient-to-color: #0e9f6e;
  }

  .xl\:focus\:to-green-600:focus {
    --gradient-to-color: #057a55;
  }

  .xl\:focus\:to-green-700:focus {
    --gradient-to-color: #046c4e;
  }

  .xl\:focus\:to-green-800:focus {
    --gradient-to-color: #03543f;
  }

  .xl\:focus\:to-green-900:focus {
    --gradient-to-color: #014737;
  }

  .xl\:focus\:to-teal-50:focus {
    --gradient-to-color: #edfafa;
  }

  .xl\:focus\:to-teal-100:focus {
    --gradient-to-color: #d5f5f6;
  }

  .xl\:focus\:to-teal-200:focus {
    --gradient-to-color: #afecef;
  }

  .xl\:focus\:to-teal-300:focus {
    --gradient-to-color: #7edce2;
  }

  .xl\:focus\:to-teal-400:focus {
    --gradient-to-color: #16bdca;
  }

  .xl\:focus\:to-teal-500:focus {
    --gradient-to-color: #0694a2;
  }

  .xl\:focus\:to-teal-600:focus {
    --gradient-to-color: #047481;
  }

  .xl\:focus\:to-teal-700:focus {
    --gradient-to-color: #036672;
  }

  .xl\:focus\:to-teal-800:focus {
    --gradient-to-color: #05505c;
  }

  .xl\:focus\:to-teal-900:focus {
    --gradient-to-color: #014451;
  }

  .xl\:focus\:to-blue-50:focus {
    --gradient-to-color: #ebf5ff;
  }

  .xl\:focus\:to-blue-100:focus {
    --gradient-to-color: #e1effe;
  }

  .xl\:focus\:to-blue-200:focus {
    --gradient-to-color: #c3ddfd;
  }

  .xl\:focus\:to-blue-300:focus {
    --gradient-to-color: #a4cafe;
  }

  .xl\:focus\:to-blue-400:focus {
    --gradient-to-color: #76a9fa;
  }

  .xl\:focus\:to-blue-500:focus {
    --gradient-to-color: #3f83f8;
  }

  .xl\:focus\:to-blue-600:focus {
    --gradient-to-color: #1c64f2;
  }

  .xl\:focus\:to-blue-700:focus {
    --gradient-to-color: #1a56db;
  }

  .xl\:focus\:to-blue-800:focus {
    --gradient-to-color: #1e429f;
  }

  .xl\:focus\:to-blue-900:focus {
    --gradient-to-color: #233876;
  }

  .xl\:focus\:to-indigo-50:focus {
    --gradient-to-color: #f0f5ff;
  }

  .xl\:focus\:to-indigo-100:focus {
    --gradient-to-color: #e5edff;
  }

  .xl\:focus\:to-indigo-200:focus {
    --gradient-to-color: #cddbfe;
  }

  .xl\:focus\:to-indigo-300:focus {
    --gradient-to-color: #b4c6fc;
  }

  .xl\:focus\:to-indigo-400:focus {
    --gradient-to-color: #8da2fb;
  }

  .xl\:focus\:to-indigo-500:focus {
    --gradient-to-color: #6875f5;
  }

  .xl\:focus\:to-indigo-600:focus {
    --gradient-to-color: #5850ec;
  }

  .xl\:focus\:to-indigo-700:focus {
    --gradient-to-color: #5145cd;
  }

  .xl\:focus\:to-indigo-800:focus {
    --gradient-to-color: #42389d;
  }

  .xl\:focus\:to-indigo-900:focus {
    --gradient-to-color: #362f78;
  }

  .xl\:focus\:to-purple-50:focus {
    --gradient-to-color: #f6f5ff;
  }

  .xl\:focus\:to-purple-100:focus {
    --gradient-to-color: #edebfe;
  }

  .xl\:focus\:to-purple-200:focus {
    --gradient-to-color: #dcd7fe;
  }

  .xl\:focus\:to-purple-300:focus {
    --gradient-to-color: #cabffd;
  }

  .xl\:focus\:to-purple-400:focus {
    --gradient-to-color: #ac94fa;
  }

  .xl\:focus\:to-purple-500:focus {
    --gradient-to-color: #9061f9;
  }

  .xl\:focus\:to-purple-600:focus {
    --gradient-to-color: #7e3af2;
  }

  .xl\:focus\:to-purple-700:focus {
    --gradient-to-color: #6c2bd9;
  }

  .xl\:focus\:to-purple-800:focus {
    --gradient-to-color: #5521b5;
  }

  .xl\:focus\:to-purple-900:focus {
    --gradient-to-color: #4a1d96;
  }

  .xl\:focus\:to-pink-50:focus {
    --gradient-to-color: #fdf2f8;
  }

  .xl\:focus\:to-pink-100:focus {
    --gradient-to-color: #fce8f3;
  }

  .xl\:focus\:to-pink-200:focus {
    --gradient-to-color: #fad1e8;
  }

  .xl\:focus\:to-pink-300:focus {
    --gradient-to-color: #f8b4d9;
  }

  .xl\:focus\:to-pink-400:focus {
    --gradient-to-color: #f17eb8;
  }

  .xl\:focus\:to-pink-500:focus {
    --gradient-to-color: #e74694;
  }

  .xl\:focus\:to-pink-600:focus {
    --gradient-to-color: #d61f69;
  }

  .xl\:focus\:to-pink-700:focus {
    --gradient-to-color: #bf125d;
  }

  .xl\:focus\:to-pink-800:focus {
    --gradient-to-color: #99154b;
  }

  .xl\:focus\:to-pink-900:focus {
    --gradient-to-color: #751a3d;
  }

  .xl\:bg-opacity-0 {
    --bg-opacity: 0;
  }

  .xl\:bg-opacity-25 {
    --bg-opacity: .25;
  }

  .xl\:bg-opacity-50 {
    --bg-opacity: .5;
  }

  .xl\:bg-opacity-75 {
    --bg-opacity: .75;
  }

  .xl\:bg-opacity-100 {
    --bg-opacity: 1;
  }

  .xl\:hover\:bg-opacity-0:hover {
    --bg-opacity: 0;
  }

  .xl\:hover\:bg-opacity-25:hover {
    --bg-opacity: .25;
  }

  .xl\:hover\:bg-opacity-50:hover {
    --bg-opacity: .5;
  }

  .xl\:hover\:bg-opacity-75:hover {
    --bg-opacity: .75;
  }

  .xl\:hover\:bg-opacity-100:hover {
    --bg-opacity: 1;
  }

  .xl\:focus\:bg-opacity-0:focus {
    --bg-opacity: 0;
  }

  .xl\:focus\:bg-opacity-25:focus {
    --bg-opacity: .25;
  }

  .xl\:focus\:bg-opacity-50:focus {
    --bg-opacity: .5;
  }

  .xl\:focus\:bg-opacity-75:focus {
    --bg-opacity: .75;
  }

  .xl\:focus\:bg-opacity-100:focus {
    --bg-opacity: 1;
  }

  .xl\:bg-bottom {
    background-position: bottom;
  }

  .xl\:bg-center {
    background-position: center;
  }

  .xl\:bg-left {
    background-position: 0;
  }

  .xl\:bg-left-bottom {
    background-position: 0 100%;
  }

  .xl\:bg-left-top {
    background-position: 0 0;
  }

  .xl\:bg-right {
    background-position: 100%;
  }

  .xl\:bg-right-bottom {
    background-position: 100% 100%;
  }

  .xl\:bg-right-top {
    background-position: 100% 0;
  }

  .xl\:bg-top {
    background-position: top;
  }

  .xl\:bg-repeat {
    background-repeat: repeat;
  }

  .xl\:bg-no-repeat {
    background-repeat: no-repeat;
  }

  .xl\:bg-repeat-x {
    background-repeat: repeat-x;
  }

  .xl\:bg-repeat-y {
    background-repeat: repeat-y;
  }

  .xl\:bg-repeat-round {
    background-repeat: round;
  }

  .xl\:bg-repeat-space {
    background-repeat: space;
  }

  .xl\:bg-auto {
    background-size: auto;
  }

  .xl\:bg-cover {
    background-size: cover;
  }

  .xl\:bg-contain {
    background-size: contain;
  }

  .xl\:border-collapse {
    border-collapse: collapse;
  }

  .xl\:border-separate {
    border-collapse: separate;
  }

  .xl\:border-transparent {
    border-color: #0000;
  }

  .xl\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .xl\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .xl\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .xl\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .xl\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .xl\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .xl\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .xl\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .xl\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .xl\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .xl\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .xl\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .xl\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .xl\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .xl\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .xl\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .xl\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .xl\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .xl\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .xl\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .xl\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .xl\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .xl\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .xl\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .xl\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .xl\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .xl\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .xl\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .xl\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .xl\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .xl\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .xl\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .xl\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .xl\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .xl\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .xl\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .xl\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .xl\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .xl\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .xl\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .xl\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .xl\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .xl\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .xl\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .xl\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .xl\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .xl\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .xl\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .xl\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .xl\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .xl\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .xl\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .xl\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .xl\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .xl\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .xl\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .xl\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .xl\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .xl\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .xl\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .xl\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .xl\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .xl\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .xl\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .xl\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .xl\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .xl\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .xl\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .xl\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .xl\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .xl\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .xl\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .xl\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .xl\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .xl\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .xl\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .xl\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .xl\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .xl\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .xl\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .xl\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .xl\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .xl\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .xl\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .xl\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .xl\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .xl\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .xl\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .xl\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .xl\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .xl\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .xl\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .xl\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .xl\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .xl\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .xl\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .xl\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .xl\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .xl\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .xl\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .xl\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .xl\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .xl\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .xl\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .xl\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .xl\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .xl\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .xl\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .xl\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .xl\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .xl\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .xl\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-transparent {
    border-color: #0000;
  }

  .group:hover .xl\:group-hover\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .group:hover .xl\:group-hover\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-transparent {
    border-color: #0000;
  }

  .group:focus .xl\:group-focus\:border-white {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-black {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-50 {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-100 {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-200 {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-300 {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-400 {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-500 {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-600 {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-700 {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-800 {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-gray-900 {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-50 {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-100 {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-200 {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-300 {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-400 {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-500 {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-600 {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-700 {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-800 {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-cool-gray-900 {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-50 {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-100 {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-200 {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-300 {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-400 {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-500 {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-600 {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-700 {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-800 {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-red-900 {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-50 {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-100 {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-200 {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-300 {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-400 {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-500 {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-600 {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-700 {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-800 {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-orange-900 {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-50 {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-100 {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-200 {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-300 {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-400 {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-500 {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-600 {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-700 {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-800 {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-yellow-900 {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-50 {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-100 {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-200 {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-300 {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-400 {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-500 {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-600 {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-700 {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-800 {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-green-900 {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-50 {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-100 {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-200 {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-300 {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-400 {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-500 {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-600 {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-700 {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-800 {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-teal-900 {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-50 {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-100 {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-200 {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-300 {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-400 {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-500 {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-600 {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-700 {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-800 {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-blue-900 {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-50 {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-100 {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-200 {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-300 {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-400 {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-500 {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-600 {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-700 {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-800 {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-indigo-900 {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-50 {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-100 {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-200 {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-300 {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-400 {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-500 {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-600 {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-700 {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-800 {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-purple-900 {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-50 {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-100 {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-200 {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-300 {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-400 {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-500 {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-600 {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-700 {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-800 {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .group:focus .xl\:group-focus\:border-pink-900 {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .xl\:hover\:border-transparent:hover {
    border-color: #0000;
  }

  .xl\:hover\:border-white:hover {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .xl\:hover\:border-black:hover {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-50:hover {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-100:hover {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-200:hover {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-300:hover {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-400:hover {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-500:hover {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-600:hover {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-700:hover {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-800:hover {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .xl\:hover\:border-gray-900:hover {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-50:hover {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-100:hover {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-200:hover {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-300:hover {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-400:hover {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-500:hover {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-600:hover {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-700:hover {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-800:hover {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .xl\:hover\:border-cool-gray-900:hover {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .xl\:hover\:border-red-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .xl\:hover\:border-red-100:hover {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .xl\:hover\:border-red-200:hover {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .xl\:hover\:border-red-300:hover {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .xl\:hover\:border-red-400:hover {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .xl\:hover\:border-red-500:hover {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .xl\:hover\:border-red-600:hover {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .xl\:hover\:border-red-700:hover {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .xl\:hover\:border-red-800:hover {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .xl\:hover\:border-red-900:hover {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-50:hover {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-100:hover {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-200:hover {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-300:hover {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-400:hover {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-500:hover {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-600:hover {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-700:hover {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-800:hover {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .xl\:hover\:border-orange-900:hover {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-50:hover {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-100:hover {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-200:hover {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-300:hover {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-400:hover {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-500:hover {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-600:hover {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-700:hover {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-800:hover {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .xl\:hover\:border-yellow-900:hover {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .xl\:hover\:border-green-50:hover {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .xl\:hover\:border-green-100:hover {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .xl\:hover\:border-green-200:hover {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .xl\:hover\:border-green-300:hover {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .xl\:hover\:border-green-400:hover {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .xl\:hover\:border-green-500:hover {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .xl\:hover\:border-green-600:hover {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .xl\:hover\:border-green-700:hover {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .xl\:hover\:border-green-800:hover {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .xl\:hover\:border-green-900:hover {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-50:hover {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-100:hover {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-200:hover {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-300:hover {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-400:hover {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-500:hover {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-600:hover {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-700:hover {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-800:hover {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .xl\:hover\:border-teal-900:hover {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-50:hover {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-100:hover {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-200:hover {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-300:hover {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-400:hover {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-500:hover {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-600:hover {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-700:hover {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-800:hover {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .xl\:hover\:border-blue-900:hover {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-50:hover {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-100:hover {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-200:hover {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-300:hover {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-400:hover {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-500:hover {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-600:hover {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-700:hover {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-800:hover {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .xl\:hover\:border-indigo-900:hover {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-50:hover {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-100:hover {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-200:hover {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-300:hover {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-400:hover {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-500:hover {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-600:hover {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-700:hover {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-800:hover {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .xl\:hover\:border-purple-900:hover {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-50:hover {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-100:hover {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-200:hover {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-300:hover {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-400:hover {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-500:hover {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-600:hover {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-700:hover {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-800:hover {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .xl\:hover\:border-pink-900:hover {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .xl\:focus\:border-transparent:focus {
    border-color: #0000;
  }

  .xl\:focus\:border-white:focus {
    --border-opacity: 1;
    border-color: #fff;
    border-color: rgba(255, 255, 255, var(--border-opacity) );
  }

  .xl\:focus\:border-black:focus {
    --border-opacity: 1;
    border-color: #000;
    border-color: rgba(0, 0, 0, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-50:focus {
    --border-opacity: 1;
    border-color: #f9fafb;
    border-color: rgba(249, 250, 251, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-100:focus {
    --border-opacity: 1;
    border-color: #f4f5f7;
    border-color: rgba(244, 245, 247, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-200:focus {
    --border-opacity: 1;
    border-color: #e5e7eb;
    border-color: rgba(229, 231, 235, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-300:focus {
    --border-opacity: 1;
    border-color: #d2d6dc;
    border-color: rgba(210, 214, 220, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-400:focus {
    --border-opacity: 1;
    border-color: #9fa6b2;
    border-color: rgba(159, 166, 178, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-500:focus {
    --border-opacity: 1;
    border-color: #6b7280;
    border-color: rgba(107, 114, 128, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-600:focus {
    --border-opacity: 1;
    border-color: #4b5563;
    border-color: rgba(75, 85, 99, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-700:focus {
    --border-opacity: 1;
    border-color: #374151;
    border-color: rgba(55, 65, 81, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-800:focus {
    --border-opacity: 1;
    border-color: #252f3f;
    border-color: rgba(37, 47, 63, var(--border-opacity) );
  }

  .xl\:focus\:border-gray-900:focus {
    --border-opacity: 1;
    border-color: #161e2e;
    border-color: rgba(22, 30, 46, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-50:focus {
    --border-opacity: 1;
    border-color: #fbfdfe;
    border-color: rgba(251, 253, 254, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-100:focus {
    --border-opacity: 1;
    border-color: #f1f5f9;
    border-color: rgba(241, 245, 249, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-200:focus {
    --border-opacity: 1;
    border-color: #e2e8f0;
    border-color: rgba(226, 232, 240, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-300:focus {
    --border-opacity: 1;
    border-color: #cfd8e3;
    border-color: rgba(207, 216, 227, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-400:focus {
    --border-opacity: 1;
    border-color: #97a6ba;
    border-color: rgba(151, 166, 186, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-500:focus {
    --border-opacity: 1;
    border-color: #64748b;
    border-color: rgba(100, 116, 139, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-600:focus {
    --border-opacity: 1;
    border-color: #475569;
    border-color: rgba(71, 85, 105, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-700:focus {
    --border-opacity: 1;
    border-color: #364152;
    border-color: rgba(54, 65, 82, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-800:focus {
    --border-opacity: 1;
    border-color: #27303f;
    border-color: rgba(39, 48, 63, var(--border-opacity) );
  }

  .xl\:focus\:border-cool-gray-900:focus {
    --border-opacity: 1;
    border-color: #1a202e;
    border-color: rgba(26, 32, 46, var(--border-opacity) );
  }

  .xl\:focus\:border-red-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f2;
    border-color: rgba(253, 242, 242, var(--border-opacity) );
  }

  .xl\:focus\:border-red-100:focus {
    --border-opacity: 1;
    border-color: #fde8e8;
    border-color: rgba(253, 232, 232, var(--border-opacity) );
  }

  .xl\:focus\:border-red-200:focus {
    --border-opacity: 1;
    border-color: #fbd5d5;
    border-color: rgba(251, 213, 213, var(--border-opacity) );
  }

  .xl\:focus\:border-red-300:focus {
    --border-opacity: 1;
    border-color: #f8b4b4;
    border-color: rgba(248, 180, 180, var(--border-opacity) );
  }

  .xl\:focus\:border-red-400:focus {
    --border-opacity: 1;
    border-color: #f98080;
    border-color: rgba(249, 128, 128, var(--border-opacity) );
  }

  .xl\:focus\:border-red-500:focus {
    --border-opacity: 1;
    border-color: #f05252;
    border-color: rgba(240, 82, 82, var(--border-opacity) );
  }

  .xl\:focus\:border-red-600:focus {
    --border-opacity: 1;
    border-color: #e02424;
    border-color: rgba(224, 36, 36, var(--border-opacity) );
  }

  .xl\:focus\:border-red-700:focus {
    --border-opacity: 1;
    border-color: #c81e1e;
    border-color: rgba(200, 30, 30, var(--border-opacity) );
  }

  .xl\:focus\:border-red-800:focus {
    --border-opacity: 1;
    border-color: #9b1c1c;
    border-color: rgba(155, 28, 28, var(--border-opacity) );
  }

  .xl\:focus\:border-red-900:focus {
    --border-opacity: 1;
    border-color: #771d1d;
    border-color: rgba(119, 29, 29, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-50:focus {
    --border-opacity: 1;
    border-color: #fff8f1;
    border-color: rgba(255, 248, 241, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-100:focus {
    --border-opacity: 1;
    border-color: #feecdc;
    border-color: rgba(254, 236, 220, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-200:focus {
    --border-opacity: 1;
    border-color: #fcd9bd;
    border-color: rgba(252, 217, 189, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-300:focus {
    --border-opacity: 1;
    border-color: #fdba8c;
    border-color: rgba(253, 186, 140, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-400:focus {
    --border-opacity: 1;
    border-color: #ff8a4c;
    border-color: rgba(255, 138, 76, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-500:focus {
    --border-opacity: 1;
    border-color: #ff5a1f;
    border-color: rgba(255, 90, 31, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-600:focus {
    --border-opacity: 1;
    border-color: #d03801;
    border-color: rgba(208, 56, 1, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-700:focus {
    --border-opacity: 1;
    border-color: #b43403;
    border-color: rgba(180, 52, 3, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-800:focus {
    --border-opacity: 1;
    border-color: #8a2c0d;
    border-color: rgba(138, 44, 13, var(--border-opacity) );
  }

  .xl\:focus\:border-orange-900:focus {
    --border-opacity: 1;
    border-color: #73230d;
    border-color: rgba(115, 35, 13, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-50:focus {
    --border-opacity: 1;
    border-color: #fdfdea;
    border-color: rgba(253, 253, 234, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-100:focus {
    --border-opacity: 1;
    border-color: #fdf6b2;
    border-color: rgba(253, 246, 178, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-200:focus {
    --border-opacity: 1;
    border-color: #fce96a;
    border-color: rgba(252, 233, 106, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-300:focus {
    --border-opacity: 1;
    border-color: #faca15;
    border-color: rgba(250, 202, 21, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-400:focus {
    --border-opacity: 1;
    border-color: #e3a008;
    border-color: rgba(227, 160, 8, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-500:focus {
    --border-opacity: 1;
    border-color: #c27803;
    border-color: rgba(194, 120, 3, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-600:focus {
    --border-opacity: 1;
    border-color: #9f580a;
    border-color: rgba(159, 88, 10, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-700:focus {
    --border-opacity: 1;
    border-color: #8e4b10;
    border-color: rgba(142, 75, 16, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-800:focus {
    --border-opacity: 1;
    border-color: #723b13;
    border-color: rgba(114, 59, 19, var(--border-opacity) );
  }

  .xl\:focus\:border-yellow-900:focus {
    --border-opacity: 1;
    border-color: #633112;
    border-color: rgba(99, 49, 18, var(--border-opacity) );
  }

  .xl\:focus\:border-green-50:focus {
    --border-opacity: 1;
    border-color: #f3faf7;
    border-color: rgba(243, 250, 247, var(--border-opacity) );
  }

  .xl\:focus\:border-green-100:focus {
    --border-opacity: 1;
    border-color: #def7ec;
    border-color: rgba(222, 247, 236, var(--border-opacity) );
  }

  .xl\:focus\:border-green-200:focus {
    --border-opacity: 1;
    border-color: #bcf0da;
    border-color: rgba(188, 240, 218, var(--border-opacity) );
  }

  .xl\:focus\:border-green-300:focus {
    --border-opacity: 1;
    border-color: #84e1bc;
    border-color: rgba(132, 225, 188, var(--border-opacity) );
  }

  .xl\:focus\:border-green-400:focus {
    --border-opacity: 1;
    border-color: #31c48d;
    border-color: rgba(49, 196, 141, var(--border-opacity) );
  }

  .xl\:focus\:border-green-500:focus {
    --border-opacity: 1;
    border-color: #0e9f6e;
    border-color: rgba(14, 159, 110, var(--border-opacity) );
  }

  .xl\:focus\:border-green-600:focus {
    --border-opacity: 1;
    border-color: #057a55;
    border-color: rgba(5, 122, 85, var(--border-opacity) );
  }

  .xl\:focus\:border-green-700:focus {
    --border-opacity: 1;
    border-color: #046c4e;
    border-color: rgba(4, 108, 78, var(--border-opacity) );
  }

  .xl\:focus\:border-green-800:focus {
    --border-opacity: 1;
    border-color: #03543f;
    border-color: rgba(3, 84, 63, var(--border-opacity) );
  }

  .xl\:focus\:border-green-900:focus {
    --border-opacity: 1;
    border-color: #014737;
    border-color: rgba(1, 71, 55, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-50:focus {
    --border-opacity: 1;
    border-color: #edfafa;
    border-color: rgba(237, 250, 250, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-100:focus {
    --border-opacity: 1;
    border-color: #d5f5f6;
    border-color: rgba(213, 245, 246, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-200:focus {
    --border-opacity: 1;
    border-color: #afecef;
    border-color: rgba(175, 236, 239, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-300:focus {
    --border-opacity: 1;
    border-color: #7edce2;
    border-color: rgba(126, 220, 226, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-400:focus {
    --border-opacity: 1;
    border-color: #16bdca;
    border-color: rgba(22, 189, 202, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-500:focus {
    --border-opacity: 1;
    border-color: #0694a2;
    border-color: rgba(6, 148, 162, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-600:focus {
    --border-opacity: 1;
    border-color: #047481;
    border-color: rgba(4, 116, 129, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-700:focus {
    --border-opacity: 1;
    border-color: #036672;
    border-color: rgba(3, 102, 114, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-800:focus {
    --border-opacity: 1;
    border-color: #05505c;
    border-color: rgba(5, 80, 92, var(--border-opacity) );
  }

  .xl\:focus\:border-teal-900:focus {
    --border-opacity: 1;
    border-color: #014451;
    border-color: rgba(1, 68, 81, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-50:focus {
    --border-opacity: 1;
    border-color: #ebf5ff;
    border-color: rgba(235, 245, 255, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-100:focus {
    --border-opacity: 1;
    border-color: #e1effe;
    border-color: rgba(225, 239, 254, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-200:focus {
    --border-opacity: 1;
    border-color: #c3ddfd;
    border-color: rgba(195, 221, 253, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-300:focus {
    --border-opacity: 1;
    border-color: #a4cafe;
    border-color: rgba(164, 202, 254, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-400:focus {
    --border-opacity: 1;
    border-color: #76a9fa;
    border-color: rgba(118, 169, 250, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-500:focus {
    --border-opacity: 1;
    border-color: #3f83f8;
    border-color: rgba(63, 131, 248, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-600:focus {
    --border-opacity: 1;
    border-color: #1c64f2;
    border-color: rgba(28, 100, 242, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-700:focus {
    --border-opacity: 1;
    border-color: #1a56db;
    border-color: rgba(26, 86, 219, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-800:focus {
    --border-opacity: 1;
    border-color: #1e429f;
    border-color: rgba(30, 66, 159, var(--border-opacity) );
  }

  .xl\:focus\:border-blue-900:focus {
    --border-opacity: 1;
    border-color: #233876;
    border-color: rgba(35, 56, 118, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-50:focus {
    --border-opacity: 1;
    border-color: #f0f5ff;
    border-color: rgba(240, 245, 255, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-100:focus {
    --border-opacity: 1;
    border-color: #e5edff;
    border-color: rgba(229, 237, 255, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-200:focus {
    --border-opacity: 1;
    border-color: #cddbfe;
    border-color: rgba(205, 219, 254, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-300:focus {
    --border-opacity: 1;
    border-color: #b4c6fc;
    border-color: rgba(180, 198, 252, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-400:focus {
    --border-opacity: 1;
    border-color: #8da2fb;
    border-color: rgba(141, 162, 251, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-500:focus {
    --border-opacity: 1;
    border-color: #6875f5;
    border-color: rgba(104, 117, 245, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-600:focus {
    --border-opacity: 1;
    border-color: #5850ec;
    border-color: rgba(88, 80, 236, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-700:focus {
    --border-opacity: 1;
    border-color: #5145cd;
    border-color: rgba(81, 69, 205, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-800:focus {
    --border-opacity: 1;
    border-color: #42389d;
    border-color: rgba(66, 56, 157, var(--border-opacity) );
  }

  .xl\:focus\:border-indigo-900:focus {
    --border-opacity: 1;
    border-color: #362f78;
    border-color: rgba(54, 47, 120, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-50:focus {
    --border-opacity: 1;
    border-color: #f6f5ff;
    border-color: rgba(246, 245, 255, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-100:focus {
    --border-opacity: 1;
    border-color: #edebfe;
    border-color: rgba(237, 235, 254, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-200:focus {
    --border-opacity: 1;
    border-color: #dcd7fe;
    border-color: rgba(220, 215, 254, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-300:focus {
    --border-opacity: 1;
    border-color: #cabffd;
    border-color: rgba(202, 191, 253, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-400:focus {
    --border-opacity: 1;
    border-color: #ac94fa;
    border-color: rgba(172, 148, 250, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-500:focus {
    --border-opacity: 1;
    border-color: #9061f9;
    border-color: rgba(144, 97, 249, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-600:focus {
    --border-opacity: 1;
    border-color: #7e3af2;
    border-color: rgba(126, 58, 242, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-700:focus {
    --border-opacity: 1;
    border-color: #6c2bd9;
    border-color: rgba(108, 43, 217, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-800:focus {
    --border-opacity: 1;
    border-color: #5521b5;
    border-color: rgba(85, 33, 181, var(--border-opacity) );
  }

  .xl\:focus\:border-purple-900:focus {
    --border-opacity: 1;
    border-color: #4a1d96;
    border-color: rgba(74, 29, 150, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-50:focus {
    --border-opacity: 1;
    border-color: #fdf2f8;
    border-color: rgba(253, 242, 248, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-100:focus {
    --border-opacity: 1;
    border-color: #fce8f3;
    border-color: rgba(252, 232, 243, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-200:focus {
    --border-opacity: 1;
    border-color: #fad1e8;
    border-color: rgba(250, 209, 232, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-300:focus {
    --border-opacity: 1;
    border-color: #f8b4d9;
    border-color: rgba(248, 180, 217, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-400:focus {
    --border-opacity: 1;
    border-color: #f17eb8;
    border-color: rgba(241, 126, 184, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-500:focus {
    --border-opacity: 1;
    border-color: #e74694;
    border-color: rgba(231, 70, 148, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-600:focus {
    --border-opacity: 1;
    border-color: #d61f69;
    border-color: rgba(214, 31, 105, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-700:focus {
    --border-opacity: 1;
    border-color: #bf125d;
    border-color: rgba(191, 18, 93, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-800:focus {
    --border-opacity: 1;
    border-color: #99154b;
    border-color: rgba(153, 21, 75, var(--border-opacity) );
  }

  .xl\:focus\:border-pink-900:focus {
    --border-opacity: 1;
    border-color: #751a3d;
    border-color: rgba(117, 26, 61, var(--border-opacity) );
  }

  .xl\:border-opacity-0 {
    --border-opacity: 0;
  }

  .xl\:border-opacity-25 {
    --border-opacity: .25;
  }

  .xl\:border-opacity-50 {
    --border-opacity: .5;
  }

  .xl\:border-opacity-75 {
    --border-opacity: .75;
  }

  .xl\:border-opacity-100 {
    --border-opacity: 1;
  }

  .xl\:hover\:border-opacity-0:hover {
    --border-opacity: 0;
  }

  .xl\:hover\:border-opacity-25:hover {
    --border-opacity: .25;
  }

  .xl\:hover\:border-opacity-50:hover {
    --border-opacity: .5;
  }

  .xl\:hover\:border-opacity-75:hover {
    --border-opacity: .75;
  }

  .xl\:hover\:border-opacity-100:hover {
    --border-opacity: 1;
  }

  .xl\:focus\:border-opacity-0:focus {
    --border-opacity: 0;
  }

  .xl\:focus\:border-opacity-25:focus {
    --border-opacity: .25;
  }

  .xl\:focus\:border-opacity-50:focus {
    --border-opacity: .5;
  }

  .xl\:focus\:border-opacity-75:focus {
    --border-opacity: .75;
  }

  .xl\:focus\:border-opacity-100:focus {
    --border-opacity: 1;
  }

  .xl\:rounded-none {
    border-radius: 0;
  }

  .xl\:rounded-sm {
    border-radius: .125rem;
  }

  .xl\:rounded {
    border-radius: .25rem;
  }

  .xl\:rounded-md {
    border-radius: .375rem;
  }

  .xl\:rounded-lg {
    border-radius: .5rem;
  }

  .xl\:rounded-xl {
    border-radius: .75rem;
  }

  .xl\:rounded-2xl {
    border-radius: 1rem;
  }

  .xl\:rounded-3xl {
    border-radius: 1.5rem;
  }

  .xl\:rounded-full {
    border-radius: 9999px;
  }

  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-b-none {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-t-sm {
    border-top-left-radius: .125rem;
    border-top-right-radius: .125rem;
  }

  .xl\:rounded-r-sm {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .xl\:rounded-b-sm {
    border-bottom-left-radius: .125rem;
    border-bottom-right-radius: .125rem;
  }

  .xl\:rounded-l-sm {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem;
  }

  .xl\:rounded-t {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
  }

  .xl\:rounded-r {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .xl\:rounded-b {
    border-bottom-left-radius: .25rem;
    border-bottom-right-radius: .25rem;
  }

  .xl\:rounded-l {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
  }

  .xl\:rounded-t-md {
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
  }

  .xl\:rounded-r-md {
    border-top-right-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .xl\:rounded-b-md {
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
  }

  .xl\:rounded-l-md {
    border-top-left-radius: .375rem;
    border-bottom-left-radius: .375rem;
  }

  .xl\:rounded-t-lg {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
  }

  .xl\:rounded-r-lg {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .xl\:rounded-b-lg {
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .xl\:rounded-l-lg {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .xl\:rounded-t-xl {
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
  }

  .xl\:rounded-r-xl {
    border-top-right-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .xl\:rounded-b-xl {
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
  }

  .xl\:rounded-l-xl {
    border-top-left-radius: .75rem;
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-b-2xl {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }

  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-b-3xl {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }

  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-b-full {
    border-bottom-left-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }

  .xl\:rounded-tl-none {
    border-top-left-radius: 0;
  }

  .xl\:rounded-tr-none {
    border-top-right-radius: 0;
  }

  .xl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }

  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }

  .xl\:rounded-tl-sm {
    border-top-left-radius: .125rem;
  }

  .xl\:rounded-tr-sm {
    border-top-right-radius: .125rem;
  }

  .xl\:rounded-br-sm {
    border-bottom-right-radius: .125rem;
  }

  .xl\:rounded-bl-sm {
    border-bottom-left-radius: .125rem;
  }

  .xl\:rounded-tl {
    border-top-left-radius: .25rem;
  }

  .xl\:rounded-tr {
    border-top-right-radius: .25rem;
  }

  .xl\:rounded-br {
    border-bottom-right-radius: .25rem;
  }

  .xl\:rounded-bl {
    border-bottom-left-radius: .25rem;
  }

  .xl\:rounded-tl-md {
    border-top-left-radius: .375rem;
  }

  .xl\:rounded-tr-md {
    border-top-right-radius: .375rem;
  }

  .xl\:rounded-br-md {
    border-bottom-right-radius: .375rem;
  }

  .xl\:rounded-bl-md {
    border-bottom-left-radius: .375rem;
  }

  .xl\:rounded-tl-lg {
    border-top-left-radius: .5rem;
  }

  .xl\:rounded-tr-lg {
    border-top-right-radius: .5rem;
  }

  .xl\:rounded-br-lg {
    border-bottom-right-radius: .5rem;
  }

  .xl\:rounded-bl-lg {
    border-bottom-left-radius: .5rem;
  }

  .xl\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .xl\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .xl\:rounded-br-xl {
    border-bottom-right-radius: .75rem;
  }

  .xl\:rounded-bl-xl {
    border-bottom-left-radius: .75rem;
  }

  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }

  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }

  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }

  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }

  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }

  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }

  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }

  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }

  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }

  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }

  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }

  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }

  .xl\:border-solid {
    border-style: solid;
  }

  .xl\:border-dashed {
    border-style: dashed;
  }

  .xl\:border-dotted {
    border-style: dotted;
  }

  .xl\:border-double {
    border-style: double;
  }

  .xl\:border-none {
    border-style: none;
  }

  .xl\:border-0 {
    border-width: 0;
  }

  .xl\:border-2 {
    border-width: 2px;
  }

  .xl\:border-4 {
    border-width: 4px;
  }

  .xl\:border-8 {
    border-width: 8px;
  }

  .xl\:border {
    border-width: 1px;
  }

  .xl\:border-t-0 {
    border-top-width: 0;
  }

  .xl\:border-r-0 {
    border-right-width: 0;
  }

  .xl\:border-b-0 {
    border-bottom-width: 0;
  }

  .xl\:border-l-0 {
    border-left-width: 0;
  }

  .xl\:border-t-2 {
    border-top-width: 2px;
  }

  .xl\:border-r-2 {
    border-right-width: 2px;
  }

  .xl\:border-b-2 {
    border-bottom-width: 2px;
  }

  .xl\:border-l-2 {
    border-left-width: 2px;
  }

  .xl\:border-t-4 {
    border-top-width: 4px;
  }

  .xl\:border-r-4 {
    border-right-width: 4px;
  }

  .xl\:border-b-4 {
    border-bottom-width: 4px;
  }

  .xl\:border-l-4 {
    border-left-width: 4px;
  }

  .xl\:border-t-8 {
    border-top-width: 8px;
  }

  .xl\:border-r-8 {
    border-right-width: 8px;
  }

  .xl\:border-b-8 {
    border-bottom-width: 8px;
  }

  .xl\:border-l-8 {
    border-left-width: 8px;
  }

  .xl\:border-t {
    border-top-width: 1px;
  }

  .xl\:border-r {
    border-right-width: 1px;
  }

  .xl\:border-b {
    border-bottom-width: 1px;
  }

  .xl\:border-l {
    border-left-width: 1px;
  }

  .xl\:box-border {
    box-sizing: border-box;
  }

  .xl\:box-content {
    box-sizing: content-box;
  }

  .xl\:cursor-auto {
    cursor: auto;
  }

  .xl\:cursor-default {
    cursor: default;
  }

  .xl\:cursor-pointer {
    cursor: pointer;
  }

  .xl\:cursor-wait {
    cursor: wait;
  }

  .xl\:cursor-text {
    cursor: text;
  }

  .xl\:cursor-move {
    cursor: move;
  }

  .xl\:cursor-not-allowed {
    cursor: not-allowed;
  }

  .xl\:block {
    display: block;
  }

  .xl\:inline-block {
    display: inline-block;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:inline-flex {
    display: inline-flex;
  }

  .xl\:table {
    display: table;
  }

  .xl\:table-caption {
    display: table-caption;
  }

  .xl\:table-cell {
    display: table-cell;
  }

  .xl\:table-column {
    display: table-column;
  }

  .xl\:table-column-group {
    display: table-column-group;
  }

  .xl\:table-footer-group {
    display: table-footer-group;
  }

  .xl\:table-header-group {
    display: table-header-group;
  }

  .xl\:table-row-group {
    display: table-row-group;
  }

  .xl\:table-row {
    display: table-row;
  }

  .xl\:flow-root {
    display: flow-root;
  }

  .xl\:grid {
    display: grid;
  }

  .xl\:inline-grid {
    display: inline-grid;
  }

  .xl\:contents {
    display: contents;
  }

  .xl\:hidden {
    display: none;
  }

  .xl\:flex-row {
    flex-direction: row;
  }

  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xl\:flex-col {
    flex-direction: column;
  }

  .xl\:flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xl\:flex-wrap {
    flex-wrap: wrap;
  }

  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xl\:flex-no-wrap {
    flex-wrap: nowrap;
  }

  .xl\:place-items-auto {
    place-items: auto;
  }

  .xl\:place-items-start {
    place-items: start;
  }

  .xl\:place-items-end {
    place-items: end;
  }

  .xl\:place-items-center {
    place-items: center;
  }

  .xl\:place-items-stretch {
    place-items: stretch stretch;
  }

  .xl\:place-content-center {
    place-content: center;
  }

  .xl\:place-content-start {
    place-content: start;
  }

  .xl\:place-content-end {
    place-content: end;
  }

  .xl\:place-content-between {
    place-content: space-between;
  }

  .xl\:place-content-around {
    place-content: space-around;
  }

  .xl\:place-content-evenly {
    place-content: space-evenly;
  }

  .xl\:place-content-stretch {
    place-content: stretch;
  }

  .xl\:place-self-auto {
    place-self: auto;
  }

  .xl\:place-self-start {
    place-self: start;
  }

  .xl\:place-self-end {
    place-self: end;
  }

  .xl\:place-self-center {
    place-self: center;
  }

  .xl\:place-self-stretch {
    place-self: stretch stretch;
  }

  .xl\:items-start {
    align-items: flex-start;
  }

  .xl\:items-end {
    align-items: flex-end;
  }

  .xl\:items-center {
    align-items: center;
  }

  .xl\:items-baseline {
    align-items: baseline;
  }

  .xl\:items-stretch {
    align-items: stretch;
  }

  .xl\:content-center {
    align-content: center;
  }

  .xl\:content-start {
    align-content: flex-start;
  }

  .xl\:content-end {
    align-content: flex-end;
  }

  .xl\:content-between {
    align-content: space-between;
  }

  .xl\:content-around {
    align-content: space-around;
  }

  .xl\:content-evenly {
    align-content: space-evenly;
  }

  .xl\:self-auto {
    align-self: auto;
  }

  .xl\:self-start {
    align-self: flex-start;
  }

  .xl\:self-end {
    align-self: flex-end;
  }

  .xl\:self-center {
    align-self: center;
  }

  .xl\:self-stretch {
    align-self: stretch;
  }

  .xl\:justify-items-auto {
    justify-items: auto;
  }

  .xl\:justify-items-start {
    justify-items: start;
  }

  .xl\:justify-items-end {
    justify-items: end;
  }

  .xl\:justify-items-center {
    justify-items: center;
  }

  .xl\:justify-items-stretch {
    justify-items: stretch;
  }

  .xl\:justify-start {
    justify-content: flex-start;
  }

  .xl\:justify-end {
    justify-content: flex-end;
  }

  .xl\:justify-center {
    justify-content: center;
  }

  .xl\:justify-between {
    justify-content: space-between;
  }

  .xl\:justify-around {
    justify-content: space-around;
  }

  .xl\:justify-evenly {
    justify-content: space-evenly;
  }

  .xl\:justify-self-auto {
    justify-self: auto;
  }

  .xl\:justify-self-start {
    justify-self: start;
  }

  .xl\:justify-self-end {
    justify-self: end;
  }

  .xl\:justify-self-center {
    justify-self: center;
  }

  .xl\:justify-self-stretch {
    justify-self: stretch;
  }

  .xl\:flex-1 {
    flex: 1;
  }

  .xl\:flex-auto {
    flex: auto;
  }

  .xl\:flex-initial {
    flex: 0 auto;
  }

  .xl\:flex-none {
    flex: none;
  }

  .xl\:flex-grow-0 {
    flex-grow: 0;
  }

  .xl\:flex-grow {
    flex-grow: 1;
  }

  .xl\:flex-shrink-0 {
    flex-shrink: 0;
  }

  .xl\:flex-shrink {
    flex-shrink: 1;
  }

  .xl\:order-1 {
    order: 1;
  }

  .xl\:order-2 {
    order: 2;
  }

  .xl\:order-3 {
    order: 3;
  }

  .xl\:order-4 {
    order: 4;
  }

  .xl\:order-5 {
    order: 5;
  }

  .xl\:order-6 {
    order: 6;
  }

  .xl\:order-7 {
    order: 7;
  }

  .xl\:order-8 {
    order: 8;
  }

  .xl\:order-9 {
    order: 9;
  }

  .xl\:order-10 {
    order: 10;
  }

  .xl\:order-11 {
    order: 11;
  }

  .xl\:order-12 {
    order: 12;
  }

  .xl\:order-first {
    order: -9999;
  }

  .xl\:order-last {
    order: 9999;
  }

  .xl\:order-none {
    order: 0;
  }

  .xl\:float-right {
    float: right;
  }

  .xl\:float-left {
    float: left;
  }

  .xl\:float-none {
    float: none;
  }

  .xl\:clearfix:after {
    content: "";
    clear: both;
    display: table;
  }

  .xl\:clear-left {
    clear: left;
  }

  .xl\:clear-right {
    clear: right;
  }

  .xl\:clear-both {
    clear: both;
  }

  .xl\:clear-none {
    clear: none;
  }

  .xl\:font-sans {
    font-family: Inter var, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  }

  .xl\:font-serif {
    font-family: Georgia, Cambria, Times New Roman, Times, serif;
  }

  .xl\:font-mono {
    font-family: Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  }

  .xl\:font-ibmmono {
    font-family: IBM Plex Mono;
  }

  .xl\:font-hairline {
    font-weight: 100;
  }

  .xl\:font-thin {
    font-weight: 200;
  }

  .xl\:font-light {
    font-weight: 300;
  }

  .xl\:font-normal {
    font-weight: 400;
  }

  .xl\:font-medium {
    font-weight: 500;
  }

  .xl\:font-semibold {
    font-weight: 600;
  }

  .xl\:font-bold {
    font-weight: 700;
  }

  .xl\:font-extrabold {
    font-weight: 800;
  }

  .xl\:font-black {
    font-weight: 900;
  }

  .xl\:hover\:font-hairline:hover {
    font-weight: 100;
  }

  .xl\:hover\:font-thin:hover {
    font-weight: 200;
  }

  .xl\:hover\:font-light:hover {
    font-weight: 300;
  }

  .xl\:hover\:font-normal:hover {
    font-weight: 400;
  }

  .xl\:hover\:font-medium:hover {
    font-weight: 500;
  }

  .xl\:hover\:font-semibold:hover {
    font-weight: 600;
  }

  .xl\:hover\:font-bold:hover {
    font-weight: 700;
  }

  .xl\:hover\:font-extrabold:hover {
    font-weight: 800;
  }

  .xl\:hover\:font-black:hover {
    font-weight: 900;
  }

  .xl\:focus\:font-hairline:focus {
    font-weight: 100;
  }

  .xl\:focus\:font-thin:focus {
    font-weight: 200;
  }

  .xl\:focus\:font-light:focus {
    font-weight: 300;
  }

  .xl\:focus\:font-normal:focus {
    font-weight: 400;
  }

  .xl\:focus\:font-medium:focus {
    font-weight: 500;
  }

  .xl\:focus\:font-semibold:focus {
    font-weight: 600;
  }

  .xl\:focus\:font-bold:focus {
    font-weight: 700;
  }

  .xl\:focus\:font-extrabold:focus {
    font-weight: 800;
  }

  .xl\:focus\:font-black:focus {
    font-weight: 900;
  }

  .xl\:h-0 {
    height: 0;
  }

  .xl\:h-1 {
    height: .25rem;
  }

  .xl\:h-2 {
    height: .5rem;
  }

  .xl\:h-3 {
    height: .75rem;
  }

  .xl\:h-4 {
    height: 1rem;
  }

  .xl\:h-5 {
    height: 1.25rem;
  }

  .xl\:h-6 {
    height: 1.5rem;
  }

  .xl\:h-7 {
    height: 1.75rem;
  }

  .xl\:h-8 {
    height: 2rem;
  }

  .xl\:h-9 {
    height: 2.25rem;
  }

  .xl\:h-10 {
    height: 2.5rem;
  }

  .xl\:h-11 {
    height: 2.75rem;
  }

  .xl\:h-12 {
    height: 3rem;
  }

  .xl\:h-13 {
    height: 3.25rem;
  }

  .xl\:h-14 {
    height: 3.5rem;
  }

  .xl\:h-15 {
    height: 3.75rem;
  }

  .xl\:h-16 {
    height: 4rem;
  }

  .xl\:h-20 {
    height: 5rem;
  }

  .xl\:h-24 {
    height: 6rem;
  }

  .xl\:h-28 {
    height: 7rem;
  }

  .xl\:h-32 {
    height: 8rem;
  }

  .xl\:h-36 {
    height: 9rem;
  }

  .xl\:h-40 {
    height: 10rem;
  }

  .xl\:h-44 {
    height: 11rem;
  }

  .xl\:h-48 {
    height: 12rem;
  }

  .xl\:h-52 {
    height: 13rem;
  }

  .xl\:h-56 {
    height: 14rem;
  }

  .xl\:h-60 {
    height: 15rem;
  }

  .xl\:h-64 {
    height: 16rem;
  }

  .xl\:h-72 {
    height: 18rem;
  }

  .xl\:h-80 {
    height: 20rem;
  }

  .xl\:h-96 {
    height: 24rem;
  }

  .xl\:h-auto {
    height: auto;
  }

  .xl\:h-px {
    height: 1px;
  }

  .xl\:h-0\.5 {
    height: .125rem;
  }

  .xl\:h-1\.5 {
    height: .375rem;
  }

  .xl\:h-2\.5 {
    height: .625rem;
  }

  .xl\:h-3\.5 {
    height: .875rem;
  }

  .xl\:h-1\/2 {
    height: 50%;
  }

  .xl\:h-1\/3 {
    height: 33.3333%;
  }

  .xl\:h-2\/3 {
    height: 66.6667%;
  }

  .xl\:h-1\/4 {
    height: 25%;
  }

  .xl\:h-2\/4 {
    height: 50%;
  }

  .xl\:h-3\/4 {
    height: 75%;
  }

  .xl\:h-1\/5 {
    height: 20%;
  }

  .xl\:h-2\/5 {
    height: 40%;
  }

  .xl\:h-3\/5 {
    height: 60%;
  }

  .xl\:h-4\/5 {
    height: 80%;
  }

  .xl\:h-1\/6 {
    height: 16.6667%;
  }

  .xl\:h-2\/6 {
    height: 33.3333%;
  }

  .xl\:h-3\/6 {
    height: 50%;
  }

  .xl\:h-4\/6 {
    height: 66.6667%;
  }

  .xl\:h-5\/6 {
    height: 83.3333%;
  }

  .xl\:h-1\/12 {
    height: 8.33333%;
  }

  .xl\:h-2\/12 {
    height: 16.6667%;
  }

  .xl\:h-3\/12 {
    height: 25%;
  }

  .xl\:h-4\/12 {
    height: 33.3333%;
  }

  .xl\:h-5\/12 {
    height: 41.6667%;
  }

  .xl\:h-6\/12 {
    height: 50%;
  }

  .xl\:h-7\/12 {
    height: 58.3333%;
  }

  .xl\:h-8\/12 {
    height: 66.6667%;
  }

  .xl\:h-9\/12 {
    height: 75%;
  }

  .xl\:h-10\/12 {
    height: 83.3333%;
  }

  .xl\:h-11\/12 {
    height: 91.6667%;
  }

  .xl\:h-full {
    height: 100%;
  }

  .xl\:h-screen {
    height: 100vh;
  }

  .xl\:text-xs {
    font-size: .75rem;
  }

  .xl\:text-sm {
    font-size: .875rem;
  }

  .xl\:text-base {
    font-size: 1rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
  }

  .xl\:text-xl {
    font-size: 1.25rem;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
  }

  .xl\:text-4xl {
    font-size: 2.25rem;
  }

  .xl\:text-5xl {
    font-size: 3rem;
  }

  .xl\:text-6xl {
    font-size: 4rem;
  }

  .xl\:leading-3 {
    line-height: .75rem;
  }

  .xl\:leading-4 {
    line-height: 1rem;
  }

  .xl\:leading-5 {
    line-height: 1.25rem;
  }

  .xl\:leading-6 {
    line-height: 1.5rem;
  }

  .xl\:leading-7 {
    line-height: 1.75rem;
  }

  .xl\:leading-8 {
    line-height: 2rem;
  }

  .xl\:leading-9 {
    line-height: 2.25rem;
  }

  .xl\:leading-10 {
    line-height: 2.5rem;
  }

  .xl\:leading-none {
    line-height: 1;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }

  .xl\:leading-snug {
    line-height: 1.375;
  }

  .xl\:leading-normal {
    line-height: 1.5;
  }

  .xl\:leading-relaxed {
    line-height: 1.625;
  }

  .xl\:leading-loose {
    line-height: 2;
  }

  .xl\:list-inside {
    list-style-position: inside;
  }

  .xl\:list-outside {
    list-style-position: outside;
  }

  .xl\:list-none {
    list-style-type: none;
  }

  .xl\:list-disc {
    list-style-type: disc;
  }

  .xl\:list-decimal {
    list-style-type: decimal;
  }

  .xl\:m-0 {
    margin: 0;
  }

  .xl\:m-1 {
    margin: .25rem;
  }

  .xl\:m-2 {
    margin: .5rem;
  }

  .xl\:m-3 {
    margin: .75rem;
  }

  .xl\:m-4 {
    margin: 1rem;
  }

  .xl\:m-5 {
    margin: 1.25rem;
  }

  .xl\:m-6 {
    margin: 1.5rem;
  }

  .xl\:m-7 {
    margin: 1.75rem;
  }

  .xl\:m-8 {
    margin: 2rem;
  }

  .xl\:m-9 {
    margin: 2.25rem;
  }

  .xl\:m-10 {
    margin: 2.5rem;
  }

  .xl\:m-11 {
    margin: 2.75rem;
  }

  .xl\:m-12 {
    margin: 3rem;
  }

  .xl\:m-13 {
    margin: 3.25rem;
  }

  .xl\:m-14 {
    margin: 3.5rem;
  }

  .xl\:m-15 {
    margin: 3.75rem;
  }

  .xl\:m-16 {
    margin: 4rem;
  }

  .xl\:m-20 {
    margin: 5rem;
  }

  .xl\:m-24 {
    margin: 6rem;
  }

  .xl\:m-28 {
    margin: 7rem;
  }

  .xl\:m-32 {
    margin: 8rem;
  }

  .xl\:m-36 {
    margin: 9rem;
  }

  .xl\:m-40 {
    margin: 10rem;
  }

  .xl\:m-44 {
    margin: 11rem;
  }

  .xl\:m-48 {
    margin: 12rem;
  }

  .xl\:m-52 {
    margin: 13rem;
  }

  .xl\:m-56 {
    margin: 14rem;
  }

  .xl\:m-60 {
    margin: 15rem;
  }

  .xl\:m-64 {
    margin: 16rem;
  }

  .xl\:m-72 {
    margin: 18rem;
  }

  .xl\:m-80 {
    margin: 20rem;
  }

  .xl\:m-96 {
    margin: 24rem;
  }

  .xl\:m-auto {
    margin: auto;
  }

  .xl\:m-px {
    margin: 1px;
  }

  .xl\:m-0\.5 {
    margin: .125rem;
  }

  .xl\:m-1\.5 {
    margin: .375rem;
  }

  .xl\:m-2\.5 {
    margin: .625rem;
  }

  .xl\:m-3\.5 {
    margin: .875rem;
  }

  .xl\:m-1\/2 {
    margin: 50%;
  }

  .xl\:m-1\/3 {
    margin: 33.3333%;
  }

  .xl\:m-2\/3 {
    margin: 66.6667%;
  }

  .xl\:m-1\/4 {
    margin: 25%;
  }

  .xl\:m-2\/4 {
    margin: 50%;
  }

  .xl\:m-3\/4 {
    margin: 75%;
  }

  .xl\:m-1\/5 {
    margin: 20%;
  }

  .xl\:m-2\/5 {
    margin: 40%;
  }

  .xl\:m-3\/5 {
    margin: 60%;
  }

  .xl\:m-4\/5 {
    margin: 80%;
  }

  .xl\:m-1\/6 {
    margin: 16.6667%;
  }

  .xl\:m-2\/6 {
    margin: 33.3333%;
  }

  .xl\:m-3\/6 {
    margin: 50%;
  }

  .xl\:m-4\/6 {
    margin: 66.6667%;
  }

  .xl\:m-5\/6 {
    margin: 83.3333%;
  }

  .xl\:m-1\/12 {
    margin: 8.33333%;
  }

  .xl\:m-2\/12 {
    margin: 16.6667%;
  }

  .xl\:m-3\/12 {
    margin: 25%;
  }

  .xl\:m-4\/12 {
    margin: 33.3333%;
  }

  .xl\:m-5\/12 {
    margin: 41.6667%;
  }

  .xl\:m-6\/12 {
    margin: 50%;
  }

  .xl\:m-7\/12 {
    margin: 58.3333%;
  }

  .xl\:m-8\/12 {
    margin: 66.6667%;
  }

  .xl\:m-9\/12 {
    margin: 75%;
  }

  .xl\:m-10\/12 {
    margin: 83.3333%;
  }

  .xl\:m-11\/12 {
    margin: 91.6667%;
  }

  .xl\:m-full {
    margin: 100%;
  }

  .xl\:-m-1 {
    margin: -.25rem;
  }

  .xl\:-m-2 {
    margin: -.5rem;
  }

  .xl\:-m-3 {
    margin: -.75rem;
  }

  .xl\:-m-4 {
    margin: -1rem;
  }

  .xl\:-m-5 {
    margin: -1.25rem;
  }

  .xl\:-m-6 {
    margin: -1.5rem;
  }

  .xl\:-m-7 {
    margin: -1.75rem;
  }

  .xl\:-m-8 {
    margin: -2rem;
  }

  .xl\:-m-9 {
    margin: -2.25rem;
  }

  .xl\:-m-10 {
    margin: -2.5rem;
  }

  .xl\:-m-11 {
    margin: -2.75rem;
  }

  .xl\:-m-12 {
    margin: -3rem;
  }

  .xl\:-m-13 {
    margin: -3.25rem;
  }

  .xl\:-m-14 {
    margin: -3.5rem;
  }

  .xl\:-m-15 {
    margin: -3.75rem;
  }

  .xl\:-m-16 {
    margin: -4rem;
  }

  .xl\:-m-20 {
    margin: -5rem;
  }

  .xl\:-m-24 {
    margin: -6rem;
  }

  .xl\:-m-28 {
    margin: -7rem;
  }

  .xl\:-m-32 {
    margin: -8rem;
  }

  .xl\:-m-36 {
    margin: -9rem;
  }

  .xl\:-m-40 {
    margin: -10rem;
  }

  .xl\:-m-44 {
    margin: -11rem;
  }

  .xl\:-m-48 {
    margin: -12rem;
  }

  .xl\:-m-52 {
    margin: -13rem;
  }

  .xl\:-m-56 {
    margin: -14rem;
  }

  .xl\:-m-60 {
    margin: -15rem;
  }

  .xl\:-m-64 {
    margin: -16rem;
  }

  .xl\:-m-72 {
    margin: -18rem;
  }

  .xl\:-m-80 {
    margin: -20rem;
  }

  .xl\:-m-96 {
    margin: -24rem;
  }

  .xl\:-m-px {
    margin: -1px;
  }

  .xl\:-m-0\.5 {
    margin: -.125rem;
  }

  .xl\:-m-1\.5 {
    margin: -.375rem;
  }

  .xl\:-m-2\.5 {
    margin: -.625rem;
  }

  .xl\:-m-3\.5 {
    margin: -.875rem;
  }

  .xl\:-m-1\/2 {
    margin: -50%;
  }

  .xl\:-m-1\/3 {
    margin: -33.3333%;
  }

  .xl\:-m-2\/3 {
    margin: -66.6667%;
  }

  .xl\:-m-1\/4 {
    margin: -25%;
  }

  .xl\:-m-2\/4 {
    margin: -50%;
  }

  .xl\:-m-3\/4 {
    margin: -75%;
  }

  .xl\:-m-1\/5 {
    margin: -20%;
  }

  .xl\:-m-2\/5 {
    margin: -40%;
  }

  .xl\:-m-3\/5 {
    margin: -60%;
  }

  .xl\:-m-4\/5 {
    margin: -80%;
  }

  .xl\:-m-1\/6 {
    margin: -16.6667%;
  }

  .xl\:-m-2\/6 {
    margin: -33.3333%;
  }

  .xl\:-m-3\/6 {
    margin: -50%;
  }

  .xl\:-m-4\/6 {
    margin: -66.6667%;
  }

  .xl\:-m-5\/6 {
    margin: -83.3333%;
  }

  .xl\:-m-1\/12 {
    margin: -8.33333%;
  }

  .xl\:-m-2\/12 {
    margin: -16.6667%;
  }

  .xl\:-m-3\/12 {
    margin: -25%;
  }

  .xl\:-m-4\/12 {
    margin: -33.3333%;
  }

  .xl\:-m-5\/12 {
    margin: -41.6667%;
  }

  .xl\:-m-6\/12 {
    margin: -50%;
  }

  .xl\:-m-7\/12 {
    margin: -58.3333%;
  }

  .xl\:-m-8\/12 {
    margin: -66.6667%;
  }

  .xl\:-m-9\/12 {
    margin: -75%;
  }

  .xl\:-m-10\/12 {
    margin: -83.3333%;
  }

  .xl\:-m-11\/12 {
    margin: -91.6667%;
  }

  .xl\:-m-full {
    margin: -100%;
  }

  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .xl\:my-1 {
    margin-top: .25rem;
    margin-bottom: .25rem;
  }

  .xl\:mx-1 {
    margin-left: .25rem;
    margin-right: .25rem;
  }

  .xl\:my-2 {
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .xl\:mx-2 {
    margin-left: .5rem;
    margin-right: .5rem;
  }

  .xl\:my-3 {
    margin-top: .75rem;
    margin-bottom: .75rem;
  }

  .xl\:mx-3 {
    margin-left: .75rem;
    margin-right: .75rem;
  }

  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }

  .xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }

  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }

  .xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }

  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }

  .xl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }

  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }

  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .xl\:my-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem;
  }

  .xl\:mx-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem;
  }

  .xl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }

  .xl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }

  .xl\:my-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem;
  }

  .xl\:mx-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }

  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }

  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }

  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }

  .xl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }

  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }

  .xl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }

  .xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }

  .xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .xl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }

  .xl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }

  .xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }

  .xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }

  .xl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }

  .xl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }

  .xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }

  .xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }

  .xl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }

  .xl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }

  .xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }

  .xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }

  .xl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }

  .xl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }

  .xl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }

  .xl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }

  .xl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }

  .xl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }

  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }

  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }

  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }

  .xl\:my-0\.5 {
    margin-top: .125rem;
    margin-bottom: .125rem;
  }

  .xl\:mx-0\.5 {
    margin-left: .125rem;
    margin-right: .125rem;
  }

  .xl\:my-1\.5 {
    margin-top: .375rem;
    margin-bottom: .375rem;
  }

  .xl\:mx-1\.5 {
    margin-left: .375rem;
    margin-right: .375rem;
  }

  .xl\:my-2\.5 {
    margin-top: .625rem;
    margin-bottom: .625rem;
  }

  .xl\:mx-2\.5 {
    margin-left: .625rem;
    margin-right: .625rem;
  }

  .xl\:my-3\.5 {
    margin-top: .875rem;
    margin-bottom: .875rem;
  }

  .xl\:mx-3\.5 {
    margin-left: .875rem;
    margin-right: .875rem;
  }

  .xl\:my-1\/2 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .xl\:mx-1\/2 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .xl\:my-1\/3 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .xl\:mx-1\/3 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .xl\:my-2\/3 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .xl\:mx-2\/3 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .xl\:my-1\/4 {
    margin-top: 25%;
    margin-bottom: 25%;
  }

  .xl\:mx-1\/4 {
    margin-left: 25%;
    margin-right: 25%;
  }

  .xl\:my-2\/4 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .xl\:mx-2\/4 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .xl\:my-3\/4 {
    margin-top: 75%;
    margin-bottom: 75%;
  }

  .xl\:mx-3\/4 {
    margin-left: 75%;
    margin-right: 75%;
  }

  .xl\:my-1\/5 {
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .xl\:mx-1\/5 {
    margin-left: 20%;
    margin-right: 20%;
  }

  .xl\:my-2\/5 {
    margin-top: 40%;
    margin-bottom: 40%;
  }

  .xl\:mx-2\/5 {
    margin-left: 40%;
    margin-right: 40%;
  }

  .xl\:my-3\/5 {
    margin-top: 60%;
    margin-bottom: 60%;
  }

  .xl\:mx-3\/5 {
    margin-left: 60%;
    margin-right: 60%;
  }

  .xl\:my-4\/5 {
    margin-top: 80%;
    margin-bottom: 80%;
  }

  .xl\:mx-4\/5 {
    margin-left: 80%;
    margin-right: 80%;
  }

  .xl\:my-1\/6 {
    margin-top: 16.6667%;
    margin-bottom: 16.6667%;
  }

  .xl\:mx-1\/6 {
    margin-left: 16.6667%;
    margin-right: 16.6667%;
  }

  .xl\:my-2\/6 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .xl\:mx-2\/6 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .xl\:my-3\/6 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .xl\:mx-3\/6 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .xl\:my-4\/6 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .xl\:mx-4\/6 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .xl\:my-5\/6 {
    margin-top: 83.3333%;
    margin-bottom: 83.3333%;
  }

  .xl\:mx-5\/6 {
    margin-left: 83.3333%;
    margin-right: 83.3333%;
  }

  .xl\:my-1\/12 {
    margin-top: 8.33333%;
    margin-bottom: 8.33333%;
  }

  .xl\:mx-1\/12 {
    margin-left: 8.33333%;
    margin-right: 8.33333%;
  }

  .xl\:my-2\/12 {
    margin-top: 16.6667%;
    margin-bottom: 16.6667%;
  }

  .xl\:mx-2\/12 {
    margin-left: 16.6667%;
    margin-right: 16.6667%;
  }

  .xl\:my-3\/12 {
    margin-top: 25%;
    margin-bottom: 25%;
  }

  .xl\:mx-3\/12 {
    margin-left: 25%;
    margin-right: 25%;
  }

  .xl\:my-4\/12 {
    margin-top: 33.3333%;
    margin-bottom: 33.3333%;
  }

  .xl\:mx-4\/12 {
    margin-left: 33.3333%;
    margin-right: 33.3333%;
  }

  .xl\:my-5\/12 {
    margin-top: 41.6667%;
    margin-bottom: 41.6667%;
  }

  .xl\:mx-5\/12 {
    margin-left: 41.6667%;
    margin-right: 41.6667%;
  }

  .xl\:my-6\/12 {
    margin-top: 50%;
    margin-bottom: 50%;
  }

  .xl\:mx-6\/12 {
    margin-left: 50%;
    margin-right: 50%;
  }

  .xl\:my-7\/12 {
    margin-top: 58.3333%;
    margin-bottom: 58.3333%;
  }

  .xl\:mx-7\/12 {
    margin-left: 58.3333%;
    margin-right: 58.3333%;
  }

  .xl\:my-8\/12 {
    margin-top: 66.6667%;
    margin-bottom: 66.6667%;
  }

  .xl\:mx-8\/12 {
    margin-left: 66.6667%;
    margin-right: 66.6667%;
  }

  .xl\:my-9\/12 {
    margin-top: 75%;
    margin-bottom: 75%;
  }

  .xl\:mx-9\/12 {
    margin-left: 75%;
    margin-right: 75%;
  }

  .xl\:my-10\/12 {
    margin-top: 83.3333%;
    margin-bottom: 83.3333%;
  }

  .xl\:mx-10\/12 {
    margin-left: 83.3333%;
    margin-right: 83.3333%;
  }

  .xl\:my-11\/12 {
    margin-top: 91.6667%;
    margin-bottom: 91.6667%;
  }

  .xl\:mx-11\/12 {
    margin-left: 91.6667%;
    margin-right: 91.6667%;
  }

  .xl\:my-full {
    margin-top: 100%;
    margin-bottom: 100%;
  }

  .xl\:mx-full {
    margin-left: 100%;
    margin-right: 100%;
  }

  .xl\:-my-1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem;
  }

  .xl\:-mx-1 {
    margin-left: -.25rem;
    margin-right: -.25rem;
  }

  .xl\:-my-2 {
    margin-top: -.5rem;
    margin-bottom: -.5rem;
  }

  .xl\:-mx-2 {
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .xl\:-my-3 {
    margin-top: -.75rem;
    margin-bottom: -.75rem;
  }

  .xl\:-mx-3 {
    margin-left: -.75rem;
    margin-right: -.75rem;
  }

  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }

  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }

  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }

  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }

  .xl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }

  .xl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }

  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }

  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .xl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }

  .xl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }

  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }

  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }

  .xl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }

  .xl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }

  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }

  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .xl\:-my-13 {
    margin-top: -3.25rem;
    margin-bottom: -3.25rem;
  }

  .xl\:-mx-13 {
    margin-left: -3.25rem;
    margin-right: -3.25rem;
  }

  .xl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }

  .xl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }

  .xl\:-my-15 {
    margin-top: -3.75rem;
    margin-bottom: -3.75rem;
  }

  .xl\:-mx-15 {
    margin-left: -3.75rem;
    margin-right: -3.75rem;
  }

  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }

  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }

  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }

  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }

  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }

  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }

  .xl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }

  .xl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }

  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }

  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }

  .xl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }

  .xl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }

  .xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }

  .xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }

  .xl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }

  .xl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }

  .xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }

  .xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }

  .xl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }

  .xl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }

  .xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }

  .xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }

  .xl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }

  .xl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }

  .xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }

  .xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }

  .xl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }

  .xl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }

  .xl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }

  .xl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }

  .xl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }

  .xl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }

  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }

  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }

  .xl\:-my-0\.5 {
    margin-top: -.125rem;
    margin-bottom: -.125rem;
  }

  .xl\:-mx-0\.5 {
    margin-left: -.125rem;
    margin-right: -.125rem;
  }

  .xl\:-my-1\.5 {
    margin-top: -.375rem;
    margin-bottom: -.375rem;
  }

  .xl\:-mx-1\.5 {
    margin-left: -.375rem;
    margin-right: -.375rem;
  }

  .xl\:-my-2\.5 {
    margin-top: -.625rem;
    margin-bottom: -.625rem;
  }

  .xl\:-mx-2\.5 {
    margin-left: -.625rem;
    margin-right: -.625rem;
  }

  .xl\:-my-3\.5 {
    margin-top: -.875rem;
    margin-bottom: -.875rem;
  }

  .xl\:-mx-3\.5 {
    margin-left: -.875rem;
    margin-right: -.875rem;
  }

  .xl\:-my-1\/2 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .xl\:-mx-1\/2 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .xl\:-my-1\/3 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .xl\:-mx-1\/3 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .xl\:-my-2\/3 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .xl\:-mx-2\/3 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .xl\:-my-1\/4 {
    margin-top: -25%;
    margin-bottom: -25%;
  }

  .xl\:-mx-1\/4 {
    margin-left: -25%;
    margin-right: -25%;
  }

  .xl\:-my-2\/4 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .xl\:-mx-2\/4 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .xl\:-my-3\/4 {
    margin-top: -75%;
    margin-bottom: -75%;
  }

  .xl\:-mx-3\/4 {
    margin-left: -75%;
    margin-right: -75%;
  }

  .xl\:-my-1\/5 {
    margin-top: -20%;
    margin-bottom: -20%;
  }

  .xl\:-mx-1\/5 {
    margin-left: -20%;
    margin-right: -20%;
  }

  .xl\:-my-2\/5 {
    margin-top: -40%;
    margin-bottom: -40%;
  }

  .xl\:-mx-2\/5 {
    margin-left: -40%;
    margin-right: -40%;
  }

  .xl\:-my-3\/5 {
    margin-top: -60%;
    margin-bottom: -60%;
  }

  .xl\:-mx-3\/5 {
    margin-left: -60%;
    margin-right: -60%;
  }

  .xl\:-my-4\/5 {
    margin-top: -80%;
    margin-bottom: -80%;
  }

  .xl\:-mx-4\/5 {
    margin-left: -80%;
    margin-right: -80%;
  }

  .xl\:-my-1\/6 {
    margin-top: -16.6667%;
    margin-bottom: -16.6667%;
  }

  .xl\:-mx-1\/6 {
    margin-left: -16.6667%;
    margin-right: -16.6667%;
  }

  .xl\:-my-2\/6 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .xl\:-mx-2\/6 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .xl\:-my-3\/6 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .xl\:-mx-3\/6 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .xl\:-my-4\/6 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .xl\:-mx-4\/6 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .xl\:-my-5\/6 {
    margin-top: -83.3333%;
    margin-bottom: -83.3333%;
  }

  .xl\:-mx-5\/6 {
    margin-left: -83.3333%;
    margin-right: -83.3333%;
  }

  .xl\:-my-1\/12 {
    margin-top: -8.33333%;
    margin-bottom: -8.33333%;
  }

  .xl\:-mx-1\/12 {
    margin-left: -8.33333%;
    margin-right: -8.33333%;
  }

  .xl\:-my-2\/12 {
    margin-top: -16.6667%;
    margin-bottom: -16.6667%;
  }

  .xl\:-mx-2\/12 {
    margin-left: -16.6667%;
    margin-right: -16.6667%;
  }

  .xl\:-my-3\/12 {
    margin-top: -25%;
    margin-bottom: -25%;
  }

  .xl\:-mx-3\/12 {
    margin-left: -25%;
    margin-right: -25%;
  }

  .xl\:-my-4\/12 {
    margin-top: -33.3333%;
    margin-bottom: -33.3333%;
  }

  .xl\:-mx-4\/12 {
    margin-left: -33.3333%;
    margin-right: -33.3333%;
  }

  .xl\:-my-5\/12 {
    margin-top: -41.6667%;
    margin-bottom: -41.6667%;
  }

  .xl\:-mx-5\/12 {
    margin-left: -41.6667%;
    margin-right: -41.6667%;
  }

  .xl\:-my-6\/12 {
    margin-top: -50%;
    margin-bottom: -50%;
  }

  .xl\:-mx-6\/12 {
    margin-left: -50%;
    margin-right: -50%;
  }

  .xl\:-my-7\/12 {
    margin-top: -58.3333%;
    margin-bottom: -58.3333%;
  }

  .xl\:-mx-7\/12 {
    margin-left: -58.3333%;
    margin-right: -58.3333%;
  }

  .xl\:-my-8\/12 {
    margin-top: -66.6667%;
    margin-bottom: -66.6667%;
  }

  .xl\:-mx-8\/12 {
    margin-left: -66.6667%;
    margin-right: -66.6667%;
  }

  .xl\:-my-9\/12 {
    margin-top: -75%;
    margin-bottom: -75%;
  }

  .xl\:-mx-9\/12 {
    margin-left: -75%;
    margin-right: -75%;
  }

  .xl\:-my-10\/12 {
    margin-top: -83.3333%;
    margin-bottom: -83.3333%;
  }

  .xl\:-mx-10\/12 {
    margin-left: -83.3333%;
    margin-right: -83.3333%;
  }

  .xl\:-my-11\/12 {
    margin-top: -91.6667%;
    margin-bottom: -91.6667%;
  }

  .xl\:-mx-11\/12 {
    margin-left: -91.6667%;
    margin-right: -91.6667%;
  }

  .xl\:-my-full {
    margin-top: -100%;
    margin-bottom: -100%;
  }

  .xl\:-mx-full {
    margin-left: -100%;
    margin-right: -100%;
  }

  .xl\:mt-0 {
    margin-top: 0;
  }

  .xl\:mr-0 {
    margin-right: 0;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:ml-0 {
    margin-left: 0;
  }

  .xl\:mt-1 {
    margin-top: .25rem;
  }

  .xl\:mr-1 {
    margin-right: .25rem;
  }

  .xl\:mb-1 {
    margin-bottom: .25rem;
  }

  .xl\:ml-1 {
    margin-left: .25rem;
  }

  .xl\:mt-2 {
    margin-top: .5rem;
  }

  .xl\:mr-2 {
    margin-right: .5rem;
  }

  .xl\:mb-2 {
    margin-bottom: .5rem;
  }

  .xl\:ml-2 {
    margin-left: .5rem;
  }

  .xl\:mt-3 {
    margin-top: .75rem;
  }

  .xl\:mr-3 {
    margin-right: .75rem;
  }

  .xl\:mb-3 {
    margin-bottom: .75rem;
  }

  .xl\:ml-3 {
    margin-left: .75rem;
  }

  .xl\:mt-4 {
    margin-top: 1rem;
  }

  .xl\:mr-4 {
    margin-right: 1rem;
  }

  .xl\:mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:ml-4 {
    margin-left: 1rem;
  }

  .xl\:mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .xl\:ml-5 {
    margin-left: 1.25rem;
  }

  .xl\:mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:mr-6 {
    margin-right: 1.5rem;
  }

  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:mt-7 {
    margin-top: 1.75rem;
  }

  .xl\:mr-7 {
    margin-right: 1.75rem;
  }

  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }

  .xl\:ml-7 {
    margin-left: 1.75rem;
  }

  .xl\:mt-8 {
    margin-top: 2rem;
  }

  .xl\:mr-8 {
    margin-right: 2rem;
  }

  .xl\:mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:ml-8 {
    margin-left: 2rem;
  }

  .xl\:mt-9 {
    margin-top: 2.25rem;
  }

  .xl\:mr-9 {
    margin-right: 2.25rem;
  }

  .xl\:mb-9 {
    margin-bottom: 2.25rem;
  }

  .xl\:ml-9 {
    margin-left: 2.25rem;
  }

  .xl\:mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:mr-10 {
    margin-right: 2.5rem;
  }

  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:mt-11 {
    margin-top: 2.75rem;
  }

  .xl\:mr-11 {
    margin-right: 2.75rem;
  }

  .xl\:mb-11 {
    margin-bottom: 2.75rem;
  }

  .xl\:ml-11 {
    margin-left: 2.75rem;
  }

  .xl\:mt-12 {
    margin-top: 3rem;
  }

  .xl\:mr-12 {
    margin-right: 3rem;
  }

  .xl\:mb-12 {
    margin-bottom: 3rem;
  }

  .xl\:ml-12 {
    margin-left: 3rem;
  }

  .xl\:mt-13 {
    margin-top: 3.25rem;
  }

  .xl\:mr-13 {
    margin-right: 3.25rem;
  }

  .xl\:mb-13 {
    margin-bottom: 3.25rem;
  }

  .xl\:ml-13 {
    margin-left: 3.25rem;
  }

  .xl\:mt-14 {
    margin-top: 3.5rem;
  }

  .xl\:mr-14 {
    margin-right: 3.5rem;
  }

  .xl\:mb-14 {
    margin-bottom: 3.5rem;
  }

  .xl\:ml-14 {
    margin-left: 3.5rem;
  }

  .xl\:mt-15 {
    margin-top: 3.75rem;
  }

  .xl\:mr-15 {
    margin-right: 3.75rem;
  }

  .xl\:mb-15 {
    margin-bottom: 3.75rem;
  }

  .xl\:ml-15 {
    margin-left: 3.75rem;
  }

  .xl\:mt-16 {
    margin-top: 4rem;
  }

  .xl\:mr-16 {
    margin-right: 4rem;
  }

  .xl\:mb-16 {
    margin-bottom: 4rem;
  }

  .xl\:ml-16 {
    margin-left: 4rem;
  }

  .xl\:mt-20 {
    margin-top: 5rem;
  }

  .xl\:mr-20 {
    margin-right: 5rem;
  }

  .xl\:mb-20 {
    margin-bottom: 5rem;
  }

  .xl\:ml-20 {
    margin-left: 5rem;
  }

  .xl\:mt-24 {
    margin-top: 6rem;
  }

  .xl\:mr-24 {
    margin-right: 6rem;
  }

  .xl\:mb-24 {
    margin-bottom: 6rem;
  }

  .xl\:ml-24 {
    margin-left: 6rem;
  }

  .xl\:mt-28 {
    margin-top: 7rem;
  }

  .xl\:mr-28 {
    margin-right: 7rem;
  }

  .xl\:mb-28 {
    margin-bottom: 7rem;
  }

  .xl\:ml-28 {
    margin-left: 7rem;
  }

  .xl\:mt-32 {
    margin-top: 8rem;
  }

  .xl\:mr-32 {
    margin-right: 8rem;
  }

  .xl\:mb-32 {
    margin-bottom: 8rem;
  }

  .xl\:ml-32 {
    margin-left: 8rem;
  }

  .xl\:mt-36 {
    margin-top: 9rem;
  }

  .xl\:mr-36 {
    margin-right: 9rem;
  }

  .xl\:mb-36 {
    margin-bottom: 9rem;
  }

  .xl\:ml-36 {
    margin-left: 9rem;
  }

  .xl\:mt-40 {
    margin-top: 10rem;
  }

  .xl\:mr-40 {
    margin-right: 10rem;
  }

  .xl\:mb-40 {
    margin-bottom: 10rem;
  }

  .xl\:ml-40 {
    margin-left: 10rem;
  }

  .xl\:mt-44 {
    margin-top: 11rem;
  }

  .xl\:mr-44 {
    margin-right: 11rem;
  }

  .xl\:mb-44 {
    margin-bottom: 11rem;
  }

  .xl\:ml-44 {
    margin-left: 11rem;
  }

  .xl\:mt-48 {
    margin-top: 12rem;
  }

  .xl\:mr-48 {
    margin-right: 12rem;
  }

  .xl\:mb-48 {
    margin-bottom: 12rem;
  }

  .xl\:ml-48 {
    margin-left: 12rem;
  }

  .xl\:mt-52 {
    margin-top: 13rem;
  }

  .xl\:mr-52 {
    margin-right: 13rem;
  }

  .xl\:mb-52 {
    margin-bottom: 13rem;
  }

  .xl\:ml-52 {
    margin-left: 13rem;
  }

  .xl\:mt-56 {
    margin-top: 14rem;
  }

  .xl\:mr-56 {
    margin-right: 14rem;
  }

  .xl\:mb-56 {
    margin-bottom: 14rem;
  }

  .xl\:ml-56 {
    margin-left: 14rem;
  }

  .xl\:mt-60 {
    margin-top: 15rem;
  }

  .xl\:mr-60 {
    margin-right: 15rem;
  }

  .xl\:mb-60 {
    margin-bottom: 15rem;
  }

  .xl\:ml-60 {
    margin-left: 15rem;
  }

  .xl\:mt-64 {
    margin-top: 16rem;
  }

  .xl\:mr-64 {
    margin-right: 16rem;
  }

  .xl\:mb-64 {
    margin-bottom: 16rem;
  }

  .xl\:ml-64 {
    margin-left: 16rem;
  }

  .xl\:mt-72 {
    margin-top: 18rem;
  }

  .xl\:mr-72 {
    margin-right: 18rem;
  }

  .xl\:mb-72 {
    margin-bottom: 18rem;
  }

  .xl\:ml-72 {
    margin-left: 18rem;
  }

  .xl\:mt-80 {
    margin-top: 20rem;
  }

  .xl\:mr-80 {
    margin-right: 20rem;
  }

  .xl\:mb-80 {
    margin-bottom: 20rem;
  }

  .xl\:ml-80 {
    margin-left: 20rem;
  }

  .xl\:mt-96 {
    margin-top: 24rem;
  }

  .xl\:mr-96 {
    margin-right: 24rem;
  }

  .xl\:mb-96 {
    margin-bottom: 24rem;
  }

  .xl\:ml-96 {
    margin-left: 24rem;
  }

  .xl\:mt-auto {
    margin-top: auto;
  }

  .xl\:mr-auto {
    margin-right: auto;
  }

  .xl\:mb-auto {
    margin-bottom: auto;
  }

  .xl\:ml-auto {
    margin-left: auto;
  }

  .xl\:mt-px {
    margin-top: 1px;
  }

  .xl\:mr-px {
    margin-right: 1px;
  }

  .xl\:mb-px {
    margin-bottom: 1px;
  }

  .xl\:ml-px {
    margin-left: 1px;
  }

  .xl\:mt-0\.5 {
    margin-top: .125rem;
  }

  .xl\:mr-0\.5 {
    margin-right: .125rem;
  }

  .xl\:mb-0\.5 {
    margin-bottom: .125rem;
  }

  .xl\:ml-0\.5 {
    margin-left: .125rem;
  }

  .xl\:mt-1\.5 {
    margin-top: .375rem;
  }

  .xl\:mr-1\.5 {
    margin-right: .375rem;
  }

  .xl\:mb-1\.5 {
    margin-bottom: .375rem;
  }

  .xl\:ml-1\.5 {
    margin-left: .375rem;
  }

  .xl\:mt-2\.5 {
    margin-top: .625rem;
  }

  .xl\:mr-2\.5 {
    margin-right: .625rem;
  }

  .xl\:mb-2\.5 {
    margin-bottom: .625rem;
  }

  .xl\:ml-2\.5 {
    margin-left: .625rem;
  }

  .xl\:mt-3\.5 {
    margin-top: .875rem;
  }

  .xl\:mr-3\.5 {
    margin-right: .875rem;
  }

  .xl\:mb-3\.5 {
    margin-bottom: .875rem;
  }

  .xl\:ml-3\.5 {
    margin-left: .875rem;
  }

  .xl\:mt-1\/2 {
    margin-top: 50%;
  }

  .xl\:mr-1\/2 {
    margin-right: 50%;
  }

  .xl\:mb-1\/2 {
    margin-bottom: 50%;
  }

  .xl\:ml-1\/2 {
    margin-left: 50%;
  }

  .xl\:mt-1\/3 {
    margin-top: 33.3333%;
  }

  .xl\:mr-1\/3 {
    margin-right: 33.3333%;
  }

  .xl\:mb-1\/3 {
    margin-bottom: 33.3333%;
  }

  .xl\:ml-1\/3 {
    margin-left: 33.3333%;
  }

  .xl\:mt-2\/3 {
    margin-top: 66.6667%;
  }

  .xl\:mr-2\/3 {
    margin-right: 66.6667%;
  }

  .xl\:mb-2\/3 {
    margin-bottom: 66.6667%;
  }

  .xl\:ml-2\/3 {
    margin-left: 66.6667%;
  }

  .xl\:mt-1\/4 {
    margin-top: 25%;
  }

  .xl\:mr-1\/4 {
    margin-right: 25%;
  }

  .xl\:mb-1\/4 {
    margin-bottom: 25%;
  }

  .xl\:ml-1\/4 {
    margin-left: 25%;
  }

  .xl\:mt-2\/4 {
    margin-top: 50%;
  }

  .xl\:mr-2\/4 {
    margin-right: 50%;
  }

  .xl\:mb-2\/4 {
    margin-bottom: 50%;
  }

  .xl\:ml-2\/4 {
    margin-left: 50%;
  }

  .xl\:mt-3\/4 {
    margin-top: 75%;
  }

  .xl\:mr-3\/4 {
    margin-right: 75%;
  }

  .xl\:mb-3\/4 {
    margin-bottom: 75%;
  }

  .xl\:ml-3\/4 {
    margin-left: 75%;
  }

  .xl\:mt-1\/5 {
    margin-top: 20%;
  }

  .xl\:mr-1\/5 {
    margin-right: 20%;
  }

  .xl\:mb-1\/5 {
    margin-bottom: 20%;
  }

  .xl\:ml-1\/5 {
    margin-left: 20%;
  }

  .xl\:mt-2\/5 {
    margin-top: 40%;
  }

  .xl\:mr-2\/5 {
    margin-right: 40%;
  }

  .xl\:mb-2\/5 {
    margin-bottom: 40%;
  }

  .xl\:ml-2\/5 {
    margin-left: 40%;
  }

  .xl\:mt-3\/5 {
    margin-top: 60%;
  }

  .xl\:mr-3\/5 {
    margin-right: 60%;
  }

  .xl\:mb-3\/5 {
    margin-bottom: 60%;
  }

  .xl\:ml-3\/5 {
    margin-left: 60%;
  }

  .xl\:mt-4\/5 {
    margin-top: 80%;
  }

  .xl\:mr-4\/5 {
    margin-right: 80%;
  }

  .xl\:mb-4\/5 {
    margin-bottom: 80%;
  }

  .xl\:ml-4\/5 {
    margin-left: 80%;
  }

  .xl\:mt-1\/6 {
    margin-top: 16.6667%;
  }

  .xl\:mr-1\/6 {
    margin-right: 16.6667%;
  }

  .xl\:mb-1\/6 {
    margin-bottom: 16.6667%;
  }

  .xl\:ml-1\/6 {
    margin-left: 16.6667%;
  }

  .xl\:mt-2\/6 {
    margin-top: 33.3333%;
  }

  .xl\:mr-2\/6 {
    margin-right: 33.3333%;
  }

  .xl\:mb-2\/6 {
    margin-bottom: 33.3333%;
  }

  .xl\:ml-2\/6 {
    margin-left: 33.3333%;
  }

  .xl\:mt-3\/6 {
    margin-top: 50%;
  }

  .xl\:mr-3\/6 {
    margin-right: 50%;
  }

  .xl\:mb-3\/6 {
    margin-bottom: 50%;
  }

  .xl\:ml-3\/6 {
    margin-left: 50%;
  }

  .xl\:mt-4\/6 {
    margin-top: 66.6667%;
  }

  .xl\:mr-4\/6 {
    margin-right: 66.6667%;
  }

  .xl\:mb-4\/6 {
    margin-bottom: 66.6667%;
  }

  .xl\:ml-4\/6 {
    margin-left: 66.6667%;
  }

  .xl\:mt-5\/6 {
    margin-top: 83.3333%;
  }

  .xl\:mr-5\/6 {
    margin-right: 83.3333%;
  }

  .xl\:mb-5\/6 {
    margin-bottom: 83.3333%;
  }

  .xl\:ml-5\/6 {
    margin-left: 83.3333%;
  }

  .xl\:mt-1\/12 {
    margin-top: 8.33333%;
  }

  .xl\:mr-1\/12 {
    margin-right: 8.33333%;
  }

  .xl\:mb-1\/12 {
    margin-bottom: 8.33333%;
  }

  .xl\:ml-1\/12 {
    margin-left: 8.33333%;
  }

  .xl\:mt-2\/12 {
    margin-top: 16.6667%;
  }

  .xl\:mr-2\/12 {
    margin-right: 16.6667%;
  }

  .xl\:mb-2\/12 {
    margin-bottom: 16.6667%;
  }

  .xl\:ml-2\/12 {
    margin-left: 16.6667%;
  }

  .xl\:mt-3\/12 {
    margin-top: 25%;
  }

  .xl\:mr-3\/12 {
    margin-right: 25%;
  }

  .xl\:mb-3\/12 {
    margin-bottom: 25%;
  }

  .xl\:ml-3\/12 {
    margin-left: 25%;
  }

  .xl\:mt-4\/12 {
    margin-top: 33.3333%;
  }

  .xl\:mr-4\/12 {
    margin-right: 33.3333%;
  }

  .xl\:mb-4\/12 {
    margin-bottom: 33.3333%;
  }

  .xl\:ml-4\/12 {
    margin-left: 33.3333%;
  }

  .xl\:mt-5\/12 {
    margin-top: 41.6667%;
  }

  .xl\:mr-5\/12 {
    margin-right: 41.6667%;
  }

  .xl\:mb-5\/12 {
    margin-bottom: 41.6667%;
  }

  .xl\:ml-5\/12 {
    margin-left: 41.6667%;
  }

  .xl\:mt-6\/12 {
    margin-top: 50%;
  }

  .xl\:mr-6\/12 {
    margin-right: 50%;
  }

  .xl\:mb-6\/12 {
    margin-bottom: 50%;
  }

  .xl\:ml-6\/12 {
    margin-left: 50%;
  }

  .xl\:mt-7\/12 {
    margin-top: 58.3333%;
  }

  .xl\:mr-7\/12 {
    margin-right: 58.3333%;
  }

  .xl\:mb-7\/12 {
    margin-bottom: 58.3333%;
  }

  .xl\:ml-7\/12 {
    margin-left: 58.3333%;
  }

  .xl\:mt-8\/12 {
    margin-top: 66.6667%;
  }

  .xl\:mr-8\/12 {
    margin-right: 66.6667%;
  }

  .xl\:mb-8\/12 {
    margin-bottom: 66.6667%;
  }

  .xl\:ml-8\/12 {
    margin-left: 66.6667%;
  }

  .xl\:mt-9\/12 {
    margin-top: 75%;
  }

  .xl\:mr-9\/12 {
    margin-right: 75%;
  }

  .xl\:mb-9\/12 {
    margin-bottom: 75%;
  }

  .xl\:ml-9\/12 {
    margin-left: 75%;
  }

  .xl\:mt-10\/12 {
    margin-top: 83.3333%;
  }

  .xl\:mr-10\/12 {
    margin-right: 83.3333%;
  }

  .xl\:mb-10\/12 {
    margin-bottom: 83.3333%;
  }

  .xl\:ml-10\/12 {
    margin-left: 83.3333%;
  }

  .xl\:mt-11\/12 {
    margin-top: 91.6667%;
  }

  .xl\:mr-11\/12 {
    margin-right: 91.6667%;
  }

  .xl\:mb-11\/12 {
    margin-bottom: 91.6667%;
  }

  .xl\:ml-11\/12 {
    margin-left: 91.6667%;
  }

  .xl\:mt-full {
    margin-top: 100%;
  }

  .xl\:mr-full {
    margin-right: 100%;
  }

  .xl\:mb-full {
    margin-bottom: 100%;
  }

  .xl\:ml-full {
    margin-left: 100%;
  }

  .xl\:-mt-1 {
    margin-top: -.25rem;
  }

  .xl\:-mr-1 {
    margin-right: -.25rem;
  }

  .xl\:-mb-1 {
    margin-bottom: -.25rem;
  }

  .xl\:-ml-1 {
    margin-left: -.25rem;
  }

  .xl\:-mt-2 {
    margin-top: -.5rem;
  }

  .xl\:-mr-2 {
    margin-right: -.5rem;
  }

  .xl\:-mb-2 {
    margin-bottom: -.5rem;
  }

  .xl\:-ml-2 {
    margin-left: -.5rem;
  }

  .xl\:-mt-3 {
    margin-top: -.75rem;
  }

  .xl\:-mr-3 {
    margin-right: -.75rem;
  }

  .xl\:-mb-3 {
    margin-bottom: -.75rem;
  }

  .xl\:-ml-3 {
    margin-left: -.75rem;
  }

  .xl\:-mt-4 {
    margin-top: -1rem;
  }

  .xl\:-mr-4 {
    margin-right: -1rem;
  }

  .xl\:-mb-4 {
    margin-bottom: -1rem;
  }

  .xl\:-ml-4 {
    margin-left: -1rem;
  }

  .xl\:-mt-5 {
    margin-top: -1.25rem;
  }

  .xl\:-mr-5 {
    margin-right: -1.25rem;
  }

  .xl\:-mb-5 {
    margin-bottom: -1.25rem;
  }

  .xl\:-ml-5 {
    margin-left: -1.25rem;
  }

  .xl\:-mt-6 {
    margin-top: -1.5rem;
  }

  .xl\:-mr-6 {
    margin-right: -1.5rem;
  }

  .xl\:-mb-6 {
    margin-bottom: -1.5rem;
  }

  .xl\:-ml-6 {
    margin-left: -1.5rem;
  }

  .xl\:-mt-7 {
    margin-top: -1.75rem;
  }

  .xl\:-mr-7 {
    margin-right: -1.75rem;
  }

  .xl\:-mb-7 {
    margin-bottom: -1.75rem;
  }

  .xl\:-ml-7 {
    margin-left: -1.75rem;
  }

  .xl\:-mt-8 {
    margin-top: -2rem;
  }

  .xl\:-mr-8 {
    margin-right: -2rem;
  }

  .xl\:-mb-8 {
    margin-bottom: -2rem;
  }

  .xl\:-ml-8 {
    margin-left: -2rem;
  }

  .xl\:-mt-9 {
    margin-top: -2.25rem;
  }

  .xl\:-mr-9 {
    margin-right: -2.25rem;
  }

  .xl\:-mb-9 {
    margin-bottom: -2.25rem;
  }

  .xl\:-ml-9 {
    margin-left: -2.25rem;
  }

  .xl\:-mt-10 {
    margin-top: -2.5rem;
  }

  .xl\:-mr-10 {
    margin-right: -2.5rem;
  }

  .xl\:-mb-10 {
    margin-bottom: -2.5rem;
  }

  .xl\:-ml-10 {
    margin-left: -2.5rem;
  }

  .xl\:-mt-11 {
    margin-top: -2.75rem;
  }

  .xl\:-mr-11 {
    margin-right: -2.75rem;
  }

  .xl\:-mb-11 {
    margin-bottom: -2.75rem;
  }

  .xl\:-ml-11 {
    margin-left: -2.75rem;
  }

  .xl\:-mt-12 {
    margin-top: -3rem;
  }

  .xl\:-mr-12 {
    margin-right: -3rem;
  }

  .xl\:-mb-12 {
    margin-bottom: -3rem;
  }

  .xl\:-ml-12 {
    margin-left: -3rem;
  }

  .xl\:-mt-13 {
    margin-top: -3.25rem;
  }

  .xl\:-mr-13 {
    margin-right: -3.25rem;
  }

  .xl\:-mb-13 {
    margin-bottom: -3.25rem;
  }

  .xl\:-ml-13 {
    margin-left: -3.25rem;
  }

  .xl\:-mt-14 {
    margin-top: -3.5rem;
  }

  .xl\:-mr-14 {
    margin-right: -3.5rem;
  }

  .xl\:-mb-14 {
    margin-bottom: -3.5rem;
  }

  .xl\:-ml-14 {
    margin-left: -3.5rem;
  }

  .xl\:-mt-15 {
    margin-top: -3.75rem;
  }

  .xl\:-mr-15 {
    margin-right: -3.75rem;
  }

  .xl\:-mb-15 {
    margin-bottom: -3.75rem;
  }

  .xl\:-ml-15 {
    margin-left: -3.75rem;
  }

  .xl\:-mt-16 {
    margin-top: -4rem;
  }

  .xl\:-mr-16 {
    margin-right: -4rem;
  }

  .xl\:-mb-16 {
    margin-bottom: -4rem;
  }

  .xl\:-ml-16 {
    margin-left: -4rem;
  }

  .xl\:-mt-20 {
    margin-top: -5rem;
  }

  .xl\:-mr-20 {
    margin-right: -5rem;
  }

  .xl\:-mb-20 {
    margin-bottom: -5rem;
  }

  .xl\:-ml-20 {
    margin-left: -5rem;
  }

  .xl\:-mt-24 {
    margin-top: -6rem;
  }

  .xl\:-mr-24 {
    margin-right: -6rem;
  }

  .xl\:-mb-24 {
    margin-bottom: -6rem;
  }

  .xl\:-ml-24 {
    margin-left: -6rem;
  }

  .xl\:-mt-28 {
    margin-top: -7rem;
  }

  .xl\:-mr-28 {
    margin-right: -7rem;
  }

  .xl\:-mb-28 {
    margin-bottom: -7rem;
  }

  .xl\:-ml-28 {
    margin-left: -7rem;
  }

  .xl\:-mt-32 {
    margin-top: -8rem;
  }

  .xl\:-mr-32 {
    margin-right: -8rem;
  }

  .xl\:-mb-32 {
    margin-bottom: -8rem;
  }

  .xl\:-ml-32 {
    margin-left: -8rem;
  }

  .xl\:-mt-36 {
    margin-top: -9rem;
  }

  .xl\:-mr-36 {
    margin-right: -9rem;
  }

  .xl\:-mb-36 {
    margin-bottom: -9rem;
  }

  .xl\:-ml-36 {
    margin-left: -9rem;
  }

  .xl\:-mt-40 {
    margin-top: -10rem;
  }

  .xl\:-mr-40 {
    margin-right: -10rem;
  }

  .xl\:-mb-40 {
    margin-bottom: -10rem;
  }

  .xl\:-ml-40 {
    margin-left: -10rem;
  }

  .xl\:-mt-44 {
    margin-top: -11rem;
  }

  .xl\:-mr-44 {
    margin-right: -11rem;
  }

  .xl\:-mb-44 {
    margin-bottom: -11rem;
  }

  .xl\:-ml-44 {
    margin-left: -11rem;
  }

  .xl\:-mt-48 {
    margin-top: -12rem;
  }

  .xl\:-mr-48 {
    margin-right: -12rem;
  }

  .xl\:-mb-48 {
    margin-bottom: -12rem;
  }

  .xl\:-ml-48 {
    margin-left: -12rem;
  }

  .xl\:-mt-52 {
    margin-top: -13rem;
  }

  .xl\:-mr-52 {
    margin-right: -13rem;
  }

  .xl\:-mb-52 {
    margin-bottom: -13rem;
  }

  .xl\:-ml-52 {
    margin-left: -13rem;
  }

  .xl\:-mt-56 {
    margin-top: -14rem;
  }

  .xl\:-mr-56 {
    margin-right: -14rem;
  }

  .xl\:-mb-56 {
    margin-bottom: -14rem;
  }

  .xl\:-ml-56 {
    margin-left: -14rem;
  }

  .xl\:-mt-60 {
    margin-top: -15rem;
  }

  .xl\:-mr-60 {
    margin-right: -15rem;
  }

  .xl\:-mb-60 {
    margin-bottom: -15rem;
  }

  .xl\:-ml-60 {
    margin-left: -15rem;
  }

  .xl\:-mt-64 {
    margin-top: -16rem;
  }

  .xl\:-mr-64 {
    margin-right: -16rem;
  }

  .xl\:-mb-64 {
    margin-bottom: -16rem;
  }

  .xl\:-ml-64 {
    margin-left: -16rem;
  }

  .xl\:-mt-72 {
    margin-top: -18rem;
  }

  .xl\:-mr-72 {
    margin-right: -18rem;
  }

  .xl\:-mb-72 {
    margin-bottom: -18rem;
  }

  .xl\:-ml-72 {
    margin-left: -18rem;
  }

  .xl\:-mt-80 {
    margin-top: -20rem;
  }

  .xl\:-mr-80 {
    margin-right: -20rem;
  }

  .xl\:-mb-80 {
    margin-bottom: -20rem;
  }

  .xl\:-ml-80 {
    margin-left: -20rem;
  }

  .xl\:-mt-96 {
    margin-top: -24rem;
  }

  .xl\:-mr-96 {
    margin-right: -24rem;
  }

  .xl\:-mb-96 {
    margin-bottom: -24rem;
  }

  .xl\:-ml-96 {
    margin-left: -24rem;
  }

  .xl\:-mt-px {
    margin-top: -1px;
  }

  .xl\:-mr-px {
    margin-right: -1px;
  }

  .xl\:-mb-px {
    margin-bottom: -1px;
  }

  .xl\:-ml-px {
    margin-left: -1px;
  }

  .xl\:-mt-0\.5 {
    margin-top: -.125rem;
  }

  .xl\:-mr-0\.5 {
    margin-right: -.125rem;
  }

  .xl\:-mb-0\.5 {
    margin-bottom: -.125rem;
  }

  .xl\:-ml-0\.5 {
    margin-left: -.125rem;
  }

  .xl\:-mt-1\.5 {
    margin-top: -.375rem;
  }

  .xl\:-mr-1\.5 {
    margin-right: -.375rem;
  }

  .xl\:-mb-1\.5 {
    margin-bottom: -.375rem;
  }

  .xl\:-ml-1\.5 {
    margin-left: -.375rem;
  }

  .xl\:-mt-2\.5 {
    margin-top: -.625rem;
  }

  .xl\:-mr-2\.5 {
    margin-right: -.625rem;
  }

  .xl\:-mb-2\.5 {
    margin-bottom: -.625rem;
  }

  .xl\:-ml-2\.5 {
    margin-left: -.625rem;
  }

  .xl\:-mt-3\.5 {
    margin-top: -.875rem;
  }

  .xl\:-mr-3\.5 {
    margin-right: -.875rem;
  }

  .xl\:-mb-3\.5 {
    margin-bottom: -.875rem;
  }

  .xl\:-ml-3\.5 {
    margin-left: -.875rem;
  }

  .xl\:-mt-1\/2 {
    margin-top: -50%;
  }

  .xl\:-mr-1\/2 {
    margin-right: -50%;
  }

  .xl\:-mb-1\/2 {
    margin-bottom: -50%;
  }

  .xl\:-ml-1\/2 {
    margin-left: -50%;
  }

  .xl\:-mt-1\/3 {
    margin-top: -33.3333%;
  }

  .xl\:-mr-1\/3 {
    margin-right: -33.3333%;
  }

  .xl\:-mb-1\/3 {
    margin-bottom: -33.3333%;
  }

  .xl\:-ml-1\/3 {
    margin-left: -33.3333%;
  }

  .xl\:-mt-2\/3 {
    margin-top: -66.6667%;
  }

  .xl\:-mr-2\/3 {
    margin-right: -66.6667%;
  }

  .xl\:-mb-2\/3 {
    margin-bottom: -66.6667%;
  }

  .xl\:-ml-2\/3 {
    margin-left: -66.6667%;
  }

  .xl\:-mt-1\/4 {
    margin-top: -25%;
  }

  .xl\:-mr-1\/4 {
    margin-right: -25%;
  }

  .xl\:-mb-1\/4 {
    margin-bottom: -25%;
  }

  .xl\:-ml-1\/4 {
    margin-left: -25%;
  }

  .xl\:-mt-2\/4 {
    margin-top: -50%;
  }

  .xl\:-mr-2\/4 {
    margin-right: -50%;
  }

  .xl\:-mb-2\/4 {
    margin-bottom: -50%;
  }

  .xl\:-ml-2\/4 {
    margin-left: -50%;
  }

  .xl\:-mt-3\/4 {
    margin-top: -75%;
  }

  .xl\:-mr-3\/4 {
    margin-right: -75%;
  }

  .xl\:-mb-3\/4 {
    margin-bottom: -75%;
  }

  .xl\:-ml-3\/4 {
    margin-left: -75%;
  }

  .xl\:-mt-1\/5 {
    margin-top: -20%;
  }

  .xl\:-mr-1\/5 {
    margin-right: -20%;
  }

  .xl\:-mb-1\/5 {
    margin-bottom: -20%;
  }

  .xl\:-ml-1\/5 {
    margin-left: -20%;
  }

  .xl\:-mt-2\/5 {
    margin-top: -40%;
  }

  .xl\:-mr-2\/5 {
    margin-right: -40%;
  }

  .xl\:-mb-2\/5 {
    margin-bottom: -40%;
  }

  .xl\:-ml-2\/5 {
    margin-left: -40%;
  }

  .xl\:-mt-3\/5 {
    margin-top: -60%;
  }

  .xl\:-mr-3\/5 {
    margin-right: -60%;
  }

  .xl\:-mb-3\/5 {
    margin-bottom: -60%;
  }

  .xl\:-ml-3\/5 {
    margin-left: -60%;
  }

  .xl\:-mt-4\/5 {
    margin-top: -80%;
  }

  .xl\:-mr-4\/5 {
    margin-right: -80%;
  }

  .xl\:-mb-4\/5 {
    margin-bottom: -80%;
  }

  .xl\:-ml-4\/5 {
    margin-left: -80%;
  }

  .xl\:-mt-1\/6 {
    margin-top: -16.6667%;
  }

  .xl\:-mr-1\/6 {
    margin-right: -16.6667%;
  }

  .xl\:-mb-1\/6 {
    margin-bottom: -16.6667%;
  }

  .xl\:-ml-1\/6 {
    margin-left: -16.6667%;
  }

  .xl\:-mt-2\/6 {
    margin-top: -33.3333%;
  }

  .xl\:-mr-2\/6 {
    margin-right: -33.3333%;
  }

  .xl\:-mb-2\/6 {
    margin-bottom: -33.3333%;
  }

  .xl\:-ml-2\/6 {
    margin-left: -33.3333%;
  }

  .xl\:-mt-3\/6 {
    margin-top: -50%;
  }

  .xl\:-mr-3\/6 {
    margin-right: -50%;
  }

  .xl\:-mb-3\/6 {
    margin-bottom: -50%;
  }

  .xl\:-ml-3\/6 {
    margin-left: -50%;
  }

  .xl\:-mt-4\/6 {
    margin-top: -66.6667%;
  }

  .xl\:-mr-4\/6 {
    margin-right: -66.6667%;
  }

  .xl\:-mb-4\/6 {
    margin-bottom: -66.6667%;
  }

  .xl\:-ml-4\/6 {
    margin-left: -66.6667%;
  }

  .xl\:-mt-5\/6 {
    margin-top: -83.3333%;
  }

  .xl\:-mr-5\/6 {
    margin-right: -83.3333%;
  }

  .xl\:-mb-5\/6 {
    margin-bottom: -83.3333%;
  }

  .xl\:-ml-5\/6 {
    margin-left: -83.3333%;
  }

  .xl\:-mt-1\/12 {
    margin-top: -8.33333%;
  }

  .xl\:-mr-1\/12 {
    margin-right: -8.33333%;
  }

  .xl\:-mb-1\/12 {
    margin-bottom: -8.33333%;
  }

  .xl\:-ml-1\/12 {
    margin-left: -8.33333%;
  }

  .xl\:-mt-2\/12 {
    margin-top: -16.6667%;
  }

  .xl\:-mr-2\/12 {
    margin-right: -16.6667%;
  }

  .xl\:-mb-2\/12 {
    margin-bottom: -16.6667%;
  }

  .xl\:-ml-2\/12 {
    margin-left: -16.6667%;
  }

  .xl\:-mt-3\/12 {
    margin-top: -25%;
  }

  .xl\:-mr-3\/12 {
    margin-right: -25%;
  }

  .xl\:-mb-3\/12 {
    margin-bottom: -25%;
  }

  .xl\:-ml-3\/12 {
    margin-left: -25%;
  }

  .xl\:-mt-4\/12 {
    margin-top: -33.3333%;
  }

  .xl\:-mr-4\/12 {
    margin-right: -33.3333%;
  }

  .xl\:-mb-4\/12 {
    margin-bottom: -33.3333%;
  }

  .xl\:-ml-4\/12 {
    margin-left: -33.3333%;
  }

  .xl\:-mt-5\/12 {
    margin-top: -41.6667%;
  }

  .xl\:-mr-5\/12 {
    margin-right: -41.6667%;
  }

  .xl\:-mb-5\/12 {
    margin-bottom: -41.6667%;
  }

  .xl\:-ml-5\/12 {
    margin-left: -41.6667%;
  }

  .xl\:-mt-6\/12 {
    margin-top: -50%;
  }

  .xl\:-mr-6\/12 {
    margin-right: -50%;
  }

  .xl\:-mb-6\/12 {
    margin-bottom: -50%;
  }

  .xl\:-ml-6\/12 {
    margin-left: -50%;
  }

  .xl\:-mt-7\/12 {
    margin-top: -58.3333%;
  }

  .xl\:-mr-7\/12 {
    margin-right: -58.3333%;
  }

  .xl\:-mb-7\/12 {
    margin-bottom: -58.3333%;
  }

  .xl\:-ml-7\/12 {
    margin-left: -58.3333%;
  }

  .xl\:-mt-8\/12 {
    margin-top: -66.6667%;
  }

  .xl\:-mr-8\/12 {
    margin-right: -66.6667%;
  }

  .xl\:-mb-8\/12 {
    margin-bottom: -66.6667%;
  }

  .xl\:-ml-8\/12 {
    margin-left: -66.6667%;
  }

  .xl\:-mt-9\/12 {
    margin-top: -75%;
  }

  .xl\:-mr-9\/12 {
    margin-right: -75%;
  }

  .xl\:-mb-9\/12 {
    margin-bottom: -75%;
  }

  .xl\:-ml-9\/12 {
    margin-left: -75%;
  }

  .xl\:-mt-10\/12 {
    margin-top: -83.3333%;
  }

  .xl\:-mr-10\/12 {
    margin-right: -83.3333%;
  }

  .xl\:-mb-10\/12 {
    margin-bottom: -83.3333%;
  }

  .xl\:-ml-10\/12 {
    margin-left: -83.3333%;
  }

  .xl\:-mt-11\/12 {
    margin-top: -91.6667%;
  }

  .xl\:-mr-11\/12 {
    margin-right: -91.6667%;
  }

  .xl\:-mb-11\/12 {
    margin-bottom: -91.6667%;
  }

  .xl\:-ml-11\/12 {
    margin-left: -91.6667%;
  }

  .xl\:-mt-full {
    margin-top: -100%;
  }

  .xl\:-mr-full {
    margin-right: -100%;
  }

  .xl\:-mb-full {
    margin-bottom: -100%;
  }

  .xl\:-ml-full {
    margin-left: -100%;
  }

  .xl\:max-h-0 {
    max-height: 0;
  }

  .xl\:max-h-1 {
    max-height: .25rem;
  }

  .xl\:max-h-2 {
    max-height: .5rem;
  }

  .xl\:max-h-3 {
    max-height: .75rem;
  }

  .xl\:max-h-4 {
    max-height: 1rem;
  }

  .xl\:max-h-5 {
    max-height: 1.25rem;
  }

  .xl\:max-h-6 {
    max-height: 1.5rem;
  }

  .xl\:max-h-7 {
    max-height: 1.75rem;
  }

  .xl\:max-h-8 {
    max-height: 2rem;
  }

  .xl\:max-h-9 {
    max-height: 2.25rem;
  }

  .xl\:max-h-10 {
    max-height: 2.5rem;
  }

  .xl\:max-h-11 {
    max-height: 2.75rem;
  }

  .xl\:max-h-12 {
    max-height: 3rem;
  }

  .xl\:max-h-13 {
    max-height: 3.25rem;
  }

  .xl\:max-h-14 {
    max-height: 3.5rem;
  }

  .xl\:max-h-15 {
    max-height: 3.75rem;
  }

  .xl\:max-h-16 {
    max-height: 4rem;
  }

  .xl\:max-h-20 {
    max-height: 5rem;
  }

  .xl\:max-h-24 {
    max-height: 6rem;
  }

  .xl\:max-h-28 {
    max-height: 7rem;
  }

  .xl\:max-h-32 {
    max-height: 8rem;
  }

  .xl\:max-h-36 {
    max-height: 9rem;
  }

  .xl\:max-h-40 {
    max-height: 10rem;
  }

  .xl\:max-h-44 {
    max-height: 11rem;
  }

  .xl\:max-h-48 {
    max-height: 12rem;
  }

  .xl\:max-h-52 {
    max-height: 13rem;
  }

  .xl\:max-h-56 {
    max-height: 14rem;
  }

  .xl\:max-h-60 {
    max-height: 15rem;
  }

  .xl\:max-h-64 {
    max-height: 16rem;
  }

  .xl\:max-h-72 {
    max-height: 18rem;
  }

  .xl\:max-h-80 {
    max-height: 20rem;
  }

  .xl\:max-h-96 {
    max-height: 24rem;
  }

  .xl\:max-h-screen {
    max-height: 100vh;
  }

  .xl\:max-h-px {
    max-height: 1px;
  }

  .xl\:max-h-0\.5 {
    max-height: .125rem;
  }

  .xl\:max-h-1\.5 {
    max-height: .375rem;
  }

  .xl\:max-h-2\.5 {
    max-height: .625rem;
  }

  .xl\:max-h-3\.5 {
    max-height: .875rem;
  }

  .xl\:max-h-1\/2 {
    max-height: 50%;
  }

  .xl\:max-h-1\/3 {
    max-height: 33.3333%;
  }

  .xl\:max-h-2\/3 {
    max-height: 66.6667%;
  }

  .xl\:max-h-1\/4 {
    max-height: 25%;
  }

  .xl\:max-h-2\/4 {
    max-height: 50%;
  }

  .xl\:max-h-3\/4 {
    max-height: 75%;
  }

  .xl\:max-h-1\/5 {
    max-height: 20%;
  }

  .xl\:max-h-2\/5 {
    max-height: 40%;
  }

  .xl\:max-h-3\/5 {
    max-height: 60%;
  }

  .xl\:max-h-4\/5 {
    max-height: 80%;
  }

  .xl\:max-h-1\/6 {
    max-height: 16.6667%;
  }

  .xl\:max-h-2\/6 {
    max-height: 33.3333%;
  }

  .xl\:max-h-3\/6 {
    max-height: 50%;
  }

  .xl\:max-h-4\/6 {
    max-height: 66.6667%;
  }

  .xl\:max-h-5\/6 {
    max-height: 83.3333%;
  }

  .xl\:max-h-1\/12 {
    max-height: 8.33333%;
  }

  .xl\:max-h-2\/12 {
    max-height: 16.6667%;
  }

  .xl\:max-h-3\/12 {
    max-height: 25%;
  }

  .xl\:max-h-4\/12 {
    max-height: 33.3333%;
  }

  .xl\:max-h-5\/12 {
    max-height: 41.6667%;
  }

  .xl\:max-h-6\/12 {
    max-height: 50%;
  }

  .xl\:max-h-7\/12 {
    max-height: 58.3333%;
  }

  .xl\:max-h-8\/12 {
    max-height: 66.6667%;
  }

  .xl\:max-h-9\/12 {
    max-height: 75%;
  }

  .xl\:max-h-10\/12 {
    max-height: 83.3333%;
  }

  .xl\:max-h-11\/12 {
    max-height: 91.6667%;
  }

  .xl\:max-h-full {
    max-height: 100%;
  }

  .xl\:max-w-none {
    max-width: none;
  }

  .xl\:max-w-xs {
    max-width: 20rem;
  }

  .xl\:max-w-sm {
    max-width: 24rem;
  }

  .xl\:max-w-md {
    max-width: 28rem;
  }

  .xl\:max-w-lg {
    max-width: 32rem;
  }

  .xl\:max-w-xl {
    max-width: 36rem;
  }

  .xl\:max-w-2xl {
    max-width: 42rem;
  }

  .xl\:max-w-3xl {
    max-width: 48rem;
  }

  .xl\:max-w-4xl {
    max-width: 56rem;
  }

  .xl\:max-w-5xl {
    max-width: 64rem;
  }

  .xl\:max-w-6xl {
    max-width: 72rem;
  }

  .xl\:max-w-7xl {
    max-width: 80rem;
  }

  .xl\:max-w-full {
    max-width: 100%;
  }

  .xl\:max-w-min-content {
    max-width: min-content;
  }

  .xl\:max-w-max-content {
    max-width: max-content;
  }

  .xl\:max-w-screen-sm {
    max-width: 640px;
  }

  .xl\:max-w-screen-md {
    max-width: 768px;
  }

  .xl\:max-w-screen-lg {
    max-width: 1024px;
  }

  .xl\:max-w-screen-xl {
    max-width: 1280px;
  }

  .xl\:min-h-0 {
    min-height: 0;
  }

  .xl\:min-h-full {
    min-height: 100%;
  }

  .xl\:min-h-screen {
    min-height: 100vh;
  }

  .xl\:min-w-0 {
    min-width: 0;
  }

  .xl\:min-w-full {
    min-width: 100%;
  }

  .xl\:min-w-min-content {
    min-width: min-content;
  }

  .xl\:min-w-max-content {
    min-width: max-content;
  }

  .xl\:object-contain {
    object-fit: contain;
  }

  .xl\:object-cover {
    object-fit: cover;
  }

  .xl\:object-fill {
    object-fit: fill;
  }

  .xl\:object-none {
    object-fit: none;
  }

  .xl\:object-scale-down {
    object-fit: scale-down;
  }

  .xl\:object-bottom {
    object-position: bottom;
  }

  .xl\:object-center {
    object-position: center;
  }

  .xl\:object-left {
    object-position: left;
  }

  .xl\:object-left-bottom {
    object-position: left bottom;
  }

  .xl\:object-left-top {
    object-position: left top;
  }

  .xl\:object-right {
    object-position: right;
  }

  .xl\:object-right-bottom {
    object-position: right bottom;
  }

  .xl\:object-right-top {
    object-position: right top;
  }

  .xl\:object-top {
    object-position: top;
  }

  .xl\:opacity-0 {
    opacity: 0;
  }

  .xl\:opacity-25 {
    opacity: .25;
  }

  .xl\:opacity-50 {
    opacity: .5;
  }

  .xl\:opacity-75 {
    opacity: .75;
  }

  .xl\:opacity-100 {
    opacity: 1;
  }

  .xl\:hover\:opacity-0:hover {
    opacity: 0;
  }

  .xl\:hover\:opacity-25:hover {
    opacity: .25;
  }

  .xl\:hover\:opacity-50:hover {
    opacity: .5;
  }

  .xl\:hover\:opacity-75:hover {
    opacity: .75;
  }

  .xl\:hover\:opacity-100:hover {
    opacity: 1;
  }

  .xl\:focus\:opacity-0:focus {
    opacity: 0;
  }

  .xl\:focus\:opacity-25:focus {
    opacity: .25;
  }

  .xl\:focus\:opacity-50:focus {
    opacity: .5;
  }

  .xl\:focus\:opacity-75:focus {
    opacity: .75;
  }

  .xl\:focus\:opacity-100:focus {
    opacity: 1;
  }

  .xl\:outline-none {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xl\:outline-white {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .xl\:outline-black {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .xl\:focus\:outline-none:focus {
    outline-offset: 2px;
    outline: 2px solid #0000;
  }

  .xl\:focus\:outline-white:focus {
    outline-offset: 2px;
    outline: 2px dotted #fff;
  }

  .xl\:focus\:outline-black:focus {
    outline-offset: 2px;
    outline: 2px dotted #000;
  }

  .xl\:overflow-auto {
    overflow: auto;
  }

  .xl\:overflow-hidden {
    overflow: hidden;
  }

  .xl\:overflow-visible {
    overflow: visible;
  }

  .xl\:overflow-scroll {
    overflow: scroll;
  }

  .xl\:overflow-x-auto {
    overflow-x: auto;
  }

  .xl\:overflow-y-auto {
    overflow-y: auto;
  }

  .xl\:overflow-x-hidden {
    overflow-x: hidden;
  }

  .xl\:overflow-y-hidden {
    overflow-y: hidden;
  }

  .xl\:overflow-x-visible {
    overflow-x: visible;
  }

  .xl\:overflow-y-visible {
    overflow-y: visible;
  }

  .xl\:overflow-x-scroll {
    overflow-x: scroll;
  }

  .xl\:overflow-y-scroll {
    overflow-y: scroll;
  }

  .xl\:scrolling-touch {
    -webkit-overflow-scrolling: touch;
  }

  .xl\:scrolling-auto {
    -webkit-overflow-scrolling: auto;
  }

  .xl\:overscroll-auto {
    overscroll-behavior: auto;
  }

  .xl\:overscroll-contain {
    overscroll-behavior: contain;
  }

  .xl\:overscroll-none {
    overscroll-behavior: none;
  }

  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }

  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }

  .xl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }

  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }

  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }

  .xl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }

  .xl\:p-0 {
    padding: 0;
  }

  .xl\:p-1 {
    padding: .25rem;
  }

  .xl\:p-2 {
    padding: .5rem;
  }

  .xl\:p-3 {
    padding: .75rem;
  }

  .xl\:p-4 {
    padding: 1rem;
  }

  .xl\:p-5 {
    padding: 1.25rem;
  }

  .xl\:p-6 {
    padding: 1.5rem;
  }

  .xl\:p-7 {
    padding: 1.75rem;
  }

  .xl\:p-8 {
    padding: 2rem;
  }

  .xl\:p-9 {
    padding: 2.25rem;
  }

  .xl\:p-10 {
    padding: 2.5rem;
  }

  .xl\:p-11 {
    padding: 2.75rem;
  }

  .xl\:p-12 {
    padding: 3rem;
  }

  .xl\:p-13 {
    padding: 3.25rem;
  }

  .xl\:p-14 {
    padding: 3.5rem;
  }

  .xl\:p-15 {
    padding: 3.75rem;
  }

  .xl\:p-16 {
    padding: 4rem;
  }

  .xl\:p-20 {
    padding: 5rem;
  }

  .xl\:p-24 {
    padding: 6rem;
  }

  .xl\:p-28 {
    padding: 7rem;
  }

  .xl\:p-32 {
    padding: 8rem;
  }

  .xl\:p-36 {
    padding: 9rem;
  }

  .xl\:p-40 {
    padding: 10rem;
  }

  .xl\:p-44 {
    padding: 11rem;
  }

  .xl\:p-48 {
    padding: 12rem;
  }

  .xl\:p-52 {
    padding: 13rem;
  }

  .xl\:p-56 {
    padding: 14rem;
  }

  .xl\:p-60 {
    padding: 15rem;
  }

  .xl\:p-64 {
    padding: 16rem;
  }

  .xl\:p-72 {
    padding: 18rem;
  }

  .xl\:p-80 {
    padding: 20rem;
  }

  .xl\:p-96 {
    padding: 24rem;
  }

  .xl\:p-px {
    padding: 1px;
  }

  .xl\:p-0\.5 {
    padding: .125rem;
  }

  .xl\:p-1\.5 {
    padding: .375rem;
  }

  .xl\:p-2\.5 {
    padding: .625rem;
  }

  .xl\:p-3\.5 {
    padding: .875rem;
  }

  .xl\:p-1\/2 {
    padding: 50%;
  }

  .xl\:p-1\/3 {
    padding: 33.3333%;
  }

  .xl\:p-2\/3 {
    padding: 66.6667%;
  }

  .xl\:p-1\/4 {
    padding: 25%;
  }

  .xl\:p-2\/4 {
    padding: 50%;
  }

  .xl\:p-3\/4 {
    padding: 75%;
  }

  .xl\:p-1\/5 {
    padding: 20%;
  }

  .xl\:p-2\/5 {
    padding: 40%;
  }

  .xl\:p-3\/5 {
    padding: 60%;
  }

  .xl\:p-4\/5 {
    padding: 80%;
  }

  .xl\:p-1\/6 {
    padding: 16.6667%;
  }

  .xl\:p-2\/6 {
    padding: 33.3333%;
  }

  .xl\:p-3\/6 {
    padding: 50%;
  }

  .xl\:p-4\/6 {
    padding: 66.6667%;
  }

  .xl\:p-5\/6 {
    padding: 83.3333%;
  }

  .xl\:p-1\/12 {
    padding: 8.33333%;
  }

  .xl\:p-2\/12 {
    padding: 16.6667%;
  }

  .xl\:p-3\/12 {
    padding: 25%;
  }

  .xl\:p-4\/12 {
    padding: 33.3333%;
  }

  .xl\:p-5\/12 {
    padding: 41.6667%;
  }

  .xl\:p-6\/12 {
    padding: 50%;
  }

  .xl\:p-7\/12 {
    padding: 58.3333%;
  }

  .xl\:p-8\/12 {
    padding: 66.6667%;
  }

  .xl\:p-9\/12 {
    padding: 75%;
  }

  .xl\:p-10\/12 {
    padding: 83.3333%;
  }

  .xl\:p-11\/12 {
    padding: 91.6667%;
  }

  .xl\:p-full {
    padding: 100%;
  }

  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .xl\:py-1 {
    padding-top: .25rem;
    padding-bottom: .25rem;
  }

  .xl\:px-1 {
    padding-left: .25rem;
    padding-right: .25rem;
  }

  .xl\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .xl\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .xl\:py-3 {
    padding-top: .75rem;
    padding-bottom: .75rem;
  }

  .xl\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }

  .xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }

  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }

  .xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }

  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .xl\:py-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem;
  }

  .xl\:px-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }

  .xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }

  .xl\:py-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }

  .xl\:px-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }

  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }

  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }

  .xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }

  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }

  .xl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }

  .xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }

  .xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }

  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }

  .xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }

  .xl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }

  .xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }

  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }

  .xl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }

  .xl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }

  .xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }

  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }

  .xl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }

  .xl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }

  .xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }

  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .xl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }

  .xl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }

  .xl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }

  .xl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }

  .xl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }

  .xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }

  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }

  .xl\:py-0\.5 {
    padding-top: .125rem;
    padding-bottom: .125rem;
  }

  .xl\:px-0\.5 {
    padding-left: .125rem;
    padding-right: .125rem;
  }

  .xl\:py-1\.5 {
    padding-top: .375rem;
    padding-bottom: .375rem;
  }

  .xl\:px-1\.5 {
    padding-left: .375rem;
    padding-right: .375rem;
  }

  .xl\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }

  .xl\:px-2\.5 {
    padding-left: .625rem;
    padding-right: .625rem;
  }

  .xl\:py-3\.5 {
    padding-top: .875rem;
    padding-bottom: .875rem;
  }

  .xl\:px-3\.5 {
    padding-left: .875rem;
    padding-right: .875rem;
  }

  .xl\:py-1\/2 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .xl\:px-1\/2 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .xl\:py-1\/3 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .xl\:px-1\/3 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .xl\:py-2\/3 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .xl\:px-2\/3 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .xl\:py-1\/4 {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .xl\:px-1\/4 {
    padding-left: 25%;
    padding-right: 25%;
  }

  .xl\:py-2\/4 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .xl\:px-2\/4 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .xl\:py-3\/4 {
    padding-top: 75%;
    padding-bottom: 75%;
  }

  .xl\:px-3\/4 {
    padding-left: 75%;
    padding-right: 75%;
  }

  .xl\:py-1\/5 {
    padding-top: 20%;
    padding-bottom: 20%;
  }

  .xl\:px-1\/5 {
    padding-left: 20%;
    padding-right: 20%;
  }

  .xl\:py-2\/5 {
    padding-top: 40%;
    padding-bottom: 40%;
  }

  .xl\:px-2\/5 {
    padding-left: 40%;
    padding-right: 40%;
  }

  .xl\:py-3\/5 {
    padding-top: 60%;
    padding-bottom: 60%;
  }

  .xl\:px-3\/5 {
    padding-left: 60%;
    padding-right: 60%;
  }

  .xl\:py-4\/5 {
    padding-top: 80%;
    padding-bottom: 80%;
  }

  .xl\:px-4\/5 {
    padding-left: 80%;
    padding-right: 80%;
  }

  .xl\:py-1\/6 {
    padding-top: 16.6667%;
    padding-bottom: 16.6667%;
  }

  .xl\:px-1\/6 {
    padding-left: 16.6667%;
    padding-right: 16.6667%;
  }

  .xl\:py-2\/6 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .xl\:px-2\/6 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .xl\:py-3\/6 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .xl\:px-3\/6 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .xl\:py-4\/6 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .xl\:px-4\/6 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .xl\:py-5\/6 {
    padding-top: 83.3333%;
    padding-bottom: 83.3333%;
  }

  .xl\:px-5\/6 {
    padding-left: 83.3333%;
    padding-right: 83.3333%;
  }

  .xl\:py-1\/12 {
    padding-top: 8.33333%;
    padding-bottom: 8.33333%;
  }

  .xl\:px-1\/12 {
    padding-left: 8.33333%;
    padding-right: 8.33333%;
  }

  .xl\:py-2\/12 {
    padding-top: 16.6667%;
    padding-bottom: 16.6667%;
  }

  .xl\:px-2\/12 {
    padding-left: 16.6667%;
    padding-right: 16.6667%;
  }

  .xl\:py-3\/12 {
    padding-top: 25%;
    padding-bottom: 25%;
  }

  .xl\:px-3\/12 {
    padding-left: 25%;
    padding-right: 25%;
  }

  .xl\:py-4\/12 {
    padding-top: 33.3333%;
    padding-bottom: 33.3333%;
  }

  .xl\:px-4\/12 {
    padding-left: 33.3333%;
    padding-right: 33.3333%;
  }

  .xl\:py-5\/12 {
    padding-top: 41.6667%;
    padding-bottom: 41.6667%;
  }

  .xl\:px-5\/12 {
    padding-left: 41.6667%;
    padding-right: 41.6667%;
  }

  .xl\:py-6\/12 {
    padding-top: 50%;
    padding-bottom: 50%;
  }

  .xl\:px-6\/12 {
    padding-left: 50%;
    padding-right: 50%;
  }

  .xl\:py-7\/12 {
    padding-top: 58.3333%;
    padding-bottom: 58.3333%;
  }

  .xl\:px-7\/12 {
    padding-left: 58.3333%;
    padding-right: 58.3333%;
  }

  .xl\:py-8\/12 {
    padding-top: 66.6667%;
    padding-bottom: 66.6667%;
  }

  .xl\:px-8\/12 {
    padding-left: 66.6667%;
    padding-right: 66.6667%;
  }

  .xl\:py-9\/12 {
    padding-top: 75%;
    padding-bottom: 75%;
  }

  .xl\:px-9\/12 {
    padding-left: 75%;
    padding-right: 75%;
  }

  .xl\:py-10\/12 {
    padding-top: 83.3333%;
    padding-bottom: 83.3333%;
  }

  .xl\:px-10\/12 {
    padding-left: 83.3333%;
    padding-right: 83.3333%;
  }

  .xl\:py-11\/12 {
    padding-top: 91.6667%;
    padding-bottom: 91.6667%;
  }

  .xl\:px-11\/12 {
    padding-left: 91.6667%;
    padding-right: 91.6667%;
  }

  .xl\:py-full {
    padding-top: 100%;
    padding-bottom: 100%;
  }

  .xl\:px-full {
    padding-left: 100%;
    padding-right: 100%;
  }

  .xl\:pt-0 {
    padding-top: 0;
  }

  .xl\:pr-0 {
    padding-right: 0;
  }

  .xl\:pb-0 {
    padding-bottom: 0;
  }

  .xl\:pl-0 {
    padding-left: 0;
  }

  .xl\:pt-1 {
    padding-top: .25rem;
  }

  .xl\:pr-1 {
    padding-right: .25rem;
  }

  .xl\:pb-1 {
    padding-bottom: .25rem;
  }

  .xl\:pl-1 {
    padding-left: .25rem;
  }

  .xl\:pt-2 {
    padding-top: .5rem;
  }

  .xl\:pr-2 {
    padding-right: .5rem;
  }

  .xl\:pb-2 {
    padding-bottom: .5rem;
  }

  .xl\:pl-2 {
    padding-left: .5rem;
  }

  .xl\:pt-3 {
    padding-top: .75rem;
  }

  .xl\:pr-3 {
    padding-right: .75rem;
  }

  .xl\:pb-3 {
    padding-bottom: .75rem;
  }

  .xl\:pl-3 {
    padding-left: .75rem;
  }

  .xl\:pt-4 {
    padding-top: 1rem;
  }

  .xl\:pr-4 {
    padding-right: 1rem;
  }

  .xl\:pb-4 {
    padding-bottom: 1rem;
  }

  .xl\:pl-4 {
    padding-left: 1rem;
  }

  .xl\:pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:pl-5 {
    padding-left: 1.25rem;
  }

  .xl\:pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:pt-7 {
    padding-top: 1.75rem;
  }

  .xl\:pr-7 {
    padding-right: 1.75rem;
  }

  .xl\:pb-7 {
    padding-bottom: 1.75rem;
  }

  .xl\:pl-7 {
    padding-left: 1.75rem;
  }

  .xl\:pt-8 {
    padding-top: 2rem;
  }

  .xl\:pr-8 {
    padding-right: 2rem;
  }

  .xl\:pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:pl-8 {
    padding-left: 2rem;
  }

  .xl\:pt-9 {
    padding-top: 2.25rem;
  }

  .xl\:pr-9 {
    padding-right: 2.25rem;
  }

  .xl\:pb-9 {
    padding-bottom: 2.25rem;
  }

  .xl\:pl-9 {
    padding-left: 2.25rem;
  }

  .xl\:pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:pr-10 {
    padding-right: 2.5rem;
  }

  .xl\:pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:pt-11 {
    padding-top: 2.75rem;
  }

  .xl\:pr-11 {
    padding-right: 2.75rem;
  }

  .xl\:pb-11 {
    padding-bottom: 2.75rem;
  }

  .xl\:pl-11 {
    padding-left: 2.75rem;
  }

  .xl\:pt-12 {
    padding-top: 3rem;
  }

  .xl\:pr-12 {
    padding-right: 3rem;
  }

  .xl\:pb-12 {
    padding-bottom: 3rem;
  }

  .xl\:pl-12 {
    padding-left: 3rem;
  }

  .xl\:pt-13 {
    padding-top: 3.25rem;
  }

  .xl\:pr-13 {
    padding-right: 3.25rem;
  }

  .xl\:pb-13 {
    padding-bottom: 3.25rem;
  }

  .xl\:pl-13 {
    padding-left: 3.25rem;
  }

  .xl\:pt-14 {
    padding-top: 3.5rem;
  }

  .xl\:pr-14 {
    padding-right: 3.5rem;
  }

  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }

  .xl\:pl-14 {
    padding-left: 3.5rem;
  }

  .xl\:pt-15 {
    padding-top: 3.75rem;
  }

  .xl\:pr-15 {
    padding-right: 3.75rem;
  }

  .xl\:pb-15 {
    padding-bottom: 3.75rem;
  }

  .xl\:pl-15 {
    padding-left: 3.75rem;
  }

  .xl\:pt-16 {
    padding-top: 4rem;
  }

  .xl\:pr-16 {
    padding-right: 4rem;
  }

  .xl\:pb-16 {
    padding-bottom: 4rem;
  }

  .xl\:pl-16 {
    padding-left: 4rem;
  }

  .xl\:pt-20 {
    padding-top: 5rem;
  }

  .xl\:pr-20 {
    padding-right: 5rem;
  }

  .xl\:pb-20 {
    padding-bottom: 5rem;
  }

  .xl\:pl-20 {
    padding-left: 5rem;
  }

  .xl\:pt-24 {
    padding-top: 6rem;
  }

  .xl\:pr-24 {
    padding-right: 6rem;
  }

  .xl\:pb-24 {
    padding-bottom: 6rem;
  }

  .xl\:pl-24 {
    padding-left: 6rem;
  }

  .xl\:pt-28 {
    padding-top: 7rem;
  }

  .xl\:pr-28 {
    padding-right: 7rem;
  }

  .xl\:pb-28 {
    padding-bottom: 7rem;
  }

  .xl\:pl-28 {
    padding-left: 7rem;
  }

  .xl\:pt-32 {
    padding-top: 8rem;
  }

  .xl\:pr-32 {
    padding-right: 8rem;
  }

  .xl\:pb-32 {
    padding-bottom: 8rem;
  }

  .xl\:pl-32 {
    padding-left: 8rem;
  }

  .xl\:pt-36 {
    padding-top: 9rem;
  }

  .xl\:pr-36 {
    padding-right: 9rem;
  }

  .xl\:pb-36 {
    padding-bottom: 9rem;
  }

  .xl\:pl-36 {
    padding-left: 9rem;
  }

  .xl\:pt-40 {
    padding-top: 10rem;
  }

  .xl\:pr-40 {
    padding-right: 10rem;
  }

  .xl\:pb-40 {
    padding-bottom: 10rem;
  }

  .xl\:pl-40 {
    padding-left: 10rem;
  }

  .xl\:pt-44 {
    padding-top: 11rem;
  }

  .xl\:pr-44 {
    padding-right: 11rem;
  }

  .xl\:pb-44 {
    padding-bottom: 11rem;
  }

  .xl\:pl-44 {
    padding-left: 11rem;
  }

  .xl\:pt-48 {
    padding-top: 12rem;
  }

  .xl\:pr-48 {
    padding-right: 12rem;
  }

  .xl\:pb-48 {
    padding-bottom: 12rem;
  }

  .xl\:pl-48 {
    padding-left: 12rem;
  }

  .xl\:pt-52 {
    padding-top: 13rem;
  }

  .xl\:pr-52 {
    padding-right: 13rem;
  }

  .xl\:pb-52 {
    padding-bottom: 13rem;
  }

  .xl\:pl-52 {
    padding-left: 13rem;
  }

  .xl\:pt-56 {
    padding-top: 14rem;
  }

  .xl\:pr-56 {
    padding-right: 14rem;
  }

  .xl\:pb-56 {
    padding-bottom: 14rem;
  }

  .xl\:pl-56 {
    padding-left: 14rem;
  }

  .xl\:pt-60 {
    padding-top: 15rem;
  }

  .xl\:pr-60 {
    padding-right: 15rem;
  }

  .xl\:pb-60 {
    padding-bottom: 15rem;
  }

  .xl\:pl-60 {
    padding-left: 15rem;
  }

  .xl\:pt-64 {
    padding-top: 16rem;
  }

  .xl\:pr-64 {
    padding-right: 16rem;
  }

  .xl\:pb-64 {
    padding-bottom: 16rem;
  }

  .xl\:pl-64 {
    padding-left: 16rem;
  }

  .xl\:pt-72 {
    padding-top: 18rem;
  }

  .xl\:pr-72 {
    padding-right: 18rem;
  }

  .xl\:pb-72 {
    padding-bottom: 18rem;
  }

  .xl\:pl-72 {
    padding-left: 18rem;
  }

  .xl\:pt-80 {
    padding-top: 20rem;
  }

  .xl\:pr-80 {
    padding-right: 20rem;
  }

  .xl\:pb-80 {
    padding-bottom: 20rem;
  }

  .xl\:pl-80 {
    padding-left: 20rem;
  }

  .xl\:pt-96 {
    padding-top: 24rem;
  }

  .xl\:pr-96 {
    padding-right: 24rem;
  }

  .xl\:pb-96 {
    padding-bottom: 24rem;
  }

  .xl\:pl-96 {
    padding-left: 24rem;
  }

  .xl\:pt-px {
    padding-top: 1px;
  }

  .xl\:pr-px {
    padding-right: 1px;
  }

  .xl\:pb-px {
    padding-bottom: 1px;
  }

  .xl\:pl-px {
    padding-left: 1px;
  }

  .xl\:pt-0\.5 {
    padding-top: .125rem;
  }

  .xl\:pr-0\.5 {
    padding-right: .125rem;
  }

  .xl\:pb-0\.5 {
    padding-bottom: .125rem;
  }

  .xl\:pl-0\.5 {
    padding-left: .125rem;
  }

  .xl\:pt-1\.5 {
    padding-top: .375rem;
  }

  .xl\:pr-1\.5 {
    padding-right: .375rem;
  }

  .xl\:pb-1\.5 {
    padding-bottom: .375rem;
  }

  .xl\:pl-1\.5 {
    padding-left: .375rem;
  }

  .xl\:pt-2\.5 {
    padding-top: .625rem;
  }

  .xl\:pr-2\.5 {
    padding-right: .625rem;
  }

  .xl\:pb-2\.5 {
    padding-bottom: .625rem;
  }

  .xl\:pl-2\.5 {
    padding-left: .625rem;
  }

  .xl\:pt-3\.5 {
    padding-top: .875rem;
  }

  .xl\:pr-3\.5 {
    padding-right: .875rem;
  }

  .xl\:pb-3\.5 {
    padding-bottom: .875rem;
  }

  .xl\:pl-3\.5 {
    padding-left: .875rem;
  }

  .xl\:pt-1\/2 {
    padding-top: 50%;
  }

  .xl\:pr-1\/2 {
    padding-right: 50%;
  }

  .xl\:pb-1\/2 {
    padding-bottom: 50%;
  }

  .xl\:pl-1\/2 {
    padding-left: 50%;
  }

  .xl\:pt-1\/3 {
    padding-top: 33.3333%;
  }

  .xl\:pr-1\/3 {
    padding-right: 33.3333%;
  }

  .xl\:pb-1\/3 {
    padding-bottom: 33.3333%;
  }

  .xl\:pl-1\/3 {
    padding-left: 33.3333%;
  }

  .xl\:pt-2\/3 {
    padding-top: 66.6667%;
  }

  .xl\:pr-2\/3 {
    padding-right: 66.6667%;
  }

  .xl\:pb-2\/3 {
    padding-bottom: 66.6667%;
  }

  .xl\:pl-2\/3 {
    padding-left: 66.6667%;
  }

  .xl\:pt-1\/4 {
    padding-top: 25%;
  }

  .xl\:pr-1\/4 {
    padding-right: 25%;
  }

  .xl\:pb-1\/4 {
    padding-bottom: 25%;
  }

  .xl\:pl-1\/4 {
    padding-left: 25%;
  }

  .xl\:pt-2\/4 {
    padding-top: 50%;
  }

  .xl\:pr-2\/4 {
    padding-right: 50%;
  }

  .xl\:pb-2\/4 {
    padding-bottom: 50%;
  }

  .xl\:pl-2\/4 {
    padding-left: 50%;
  }

  .xl\:pt-3\/4 {
    padding-top: 75%;
  }

  .xl\:pr-3\/4 {
    padding-right: 75%;
  }

  .xl\:pb-3\/4 {
    padding-bottom: 75%;
  }

  .xl\:pl-3\/4 {
    padding-left: 75%;
  }

  .xl\:pt-1\/5 {
    padding-top: 20%;
  }

  .xl\:pr-1\/5 {
    padding-right: 20%;
  }

  .xl\:pb-1\/5 {
    padding-bottom: 20%;
  }

  .xl\:pl-1\/5 {
    padding-left: 20%;
  }

  .xl\:pt-2\/5 {
    padding-top: 40%;
  }

  .xl\:pr-2\/5 {
    padding-right: 40%;
  }

  .xl\:pb-2\/5 {
    padding-bottom: 40%;
  }

  .xl\:pl-2\/5 {
    padding-left: 40%;
  }

  .xl\:pt-3\/5 {
    padding-top: 60%;
  }

  .xl\:pr-3\/5 {
    padding-right: 60%;
  }

  .xl\:pb-3\/5 {
    padding-bottom: 60%;
  }

  .xl\:pl-3\/5 {
    padding-left: 60%;
  }

  .xl\:pt-4\/5 {
    padding-top: 80%;
  }

  .xl\:pr-4\/5 {
    padding-right: 80%;
  }

  .xl\:pb-4\/5 {
    padding-bottom: 80%;
  }

  .xl\:pl-4\/5 {
    padding-left: 80%;
  }

  .xl\:pt-1\/6 {
    padding-top: 16.6667%;
  }

  .xl\:pr-1\/6 {
    padding-right: 16.6667%;
  }

  .xl\:pb-1\/6 {
    padding-bottom: 16.6667%;
  }

  .xl\:pl-1\/6 {
    padding-left: 16.6667%;
  }

  .xl\:pt-2\/6 {
    padding-top: 33.3333%;
  }

  .xl\:pr-2\/6 {
    padding-right: 33.3333%;
  }

  .xl\:pb-2\/6 {
    padding-bottom: 33.3333%;
  }

  .xl\:pl-2\/6 {
    padding-left: 33.3333%;
  }

  .xl\:pt-3\/6 {
    padding-top: 50%;
  }

  .xl\:pr-3\/6 {
    padding-right: 50%;
  }

  .xl\:pb-3\/6 {
    padding-bottom: 50%;
  }

  .xl\:pl-3\/6 {
    padding-left: 50%;
  }

  .xl\:pt-4\/6 {
    padding-top: 66.6667%;
  }

  .xl\:pr-4\/6 {
    padding-right: 66.6667%;
  }

  .xl\:pb-4\/6 {
    padding-bottom: 66.6667%;
  }

  .xl\:pl-4\/6 {
    padding-left: 66.6667%;
  }

  .xl\:pt-5\/6 {
    padding-top: 83.3333%;
  }

  .xl\:pr-5\/6 {
    padding-right: 83.3333%;
  }

  .xl\:pb-5\/6 {
    padding-bottom: 83.3333%;
  }

  .xl\:pl-5\/6 {
    padding-left: 83.3333%;
  }

  .xl\:pt-1\/12 {
    padding-top: 8.33333%;
  }

  .xl\:pr-1\/12 {
    padding-right: 8.33333%;
  }

  .xl\:pb-1\/12 {
    padding-bottom: 8.33333%;
  }

  .xl\:pl-1\/12 {
    padding-left: 8.33333%;
  }

  .xl\:pt-2\/12 {
    padding-top: 16.6667%;
  }

  .xl\:pr-2\/12 {
    padding-right: 16.6667%;
  }

  .xl\:pb-2\/12 {
    padding-bottom: 16.6667%;
  }

  .xl\:pl-2\/12 {
    padding-left: 16.6667%;
  }

  .xl\:pt-3\/12 {
    padding-top: 25%;
  }

  .xl\:pr-3\/12 {
    padding-right: 25%;
  }

  .xl\:pb-3\/12 {
    padding-bottom: 25%;
  }

  .xl\:pl-3\/12 {
    padding-left: 25%;
  }

  .xl\:pt-4\/12 {
    padding-top: 33.3333%;
  }

  .xl\:pr-4\/12 {
    padding-right: 33.3333%;
  }

  .xl\:pb-4\/12 {
    padding-bottom: 33.3333%;
  }

  .xl\:pl-4\/12 {
    padding-left: 33.3333%;
  }

  .xl\:pt-5\/12 {
    padding-top: 41.6667%;
  }

  .xl\:pr-5\/12 {
    padding-right: 41.6667%;
  }

  .xl\:pb-5\/12 {
    padding-bottom: 41.6667%;
  }

  .xl\:pl-5\/12 {
    padding-left: 41.6667%;
  }

  .xl\:pt-6\/12 {
    padding-top: 50%;
  }

  .xl\:pr-6\/12 {
    padding-right: 50%;
  }

  .xl\:pb-6\/12 {
    padding-bottom: 50%;
  }

  .xl\:pl-6\/12 {
    padding-left: 50%;
  }

  .xl\:pt-7\/12 {
    padding-top: 58.3333%;
  }

  .xl\:pr-7\/12 {
    padding-right: 58.3333%;
  }

  .xl\:pb-7\/12 {
    padding-bottom: 58.3333%;
  }

  .xl\:pl-7\/12 {
    padding-left: 58.3333%;
  }

  .xl\:pt-8\/12 {
    padding-top: 66.6667%;
  }

  .xl\:pr-8\/12 {
    padding-right: 66.6667%;
  }

  .xl\:pb-8\/12 {
    padding-bottom: 66.6667%;
  }

  .xl\:pl-8\/12 {
    padding-left: 66.6667%;
  }

  .xl\:pt-9\/12 {
    padding-top: 75%;
  }

  .xl\:pr-9\/12 {
    padding-right: 75%;
  }

  .xl\:pb-9\/12 {
    padding-bottom: 75%;
  }

  .xl\:pl-9\/12 {
    padding-left: 75%;
  }

  .xl\:pt-10\/12 {
    padding-top: 83.3333%;
  }

  .xl\:pr-10\/12 {
    padding-right: 83.3333%;
  }

  .xl\:pb-10\/12 {
    padding-bottom: 83.3333%;
  }

  .xl\:pl-10\/12 {
    padding-left: 83.3333%;
  }

  .xl\:pt-11\/12 {
    padding-top: 91.6667%;
  }

  .xl\:pr-11\/12 {
    padding-right: 91.6667%;
  }

  .xl\:pb-11\/12 {
    padding-bottom: 91.6667%;
  }

  .xl\:pl-11\/12 {
    padding-left: 91.6667%;
  }

  .xl\:pt-full {
    padding-top: 100%;
  }

  .xl\:pr-full {
    padding-right: 100%;
  }

  .xl\:pb-full {
    padding-bottom: 100%;
  }

  .xl\:pl-full {
    padding-left: 100%;
  }

  .xl\:placeholder-transparent::placeholder {
    color: #0000;
  }

  .xl\:placeholder-white::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity) );
  }

  .xl\:placeholder-black::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--placeholder-opacity) );
  }

  .xl\:placeholder-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-50::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-100::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-200::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-300::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-400::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-500::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-600::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-700::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-800::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity) );
  }

  .xl\:placeholder-cool-gray-900::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-100::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-200::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-400::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-500::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-600::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-700::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-800::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity) );
  }

  .xl\:placeholder-red-900::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-50::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-100::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-200::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-300::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-400::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-500::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-600::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-700::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-800::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity) );
  }

  .xl\:placeholder-orange-900::placeholder {
    --placeholder-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-100::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-200::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-300::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-400::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-500::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-600::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-700::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-800::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity) );
  }

  .xl\:placeholder-yellow-900::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-50::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-100::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-200::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-300::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-400::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-500::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-600::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-700::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-800::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity) );
  }

  .xl\:placeholder-green-900::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-50::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-100::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-200::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-300::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-400::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-500::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-600::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-700::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-800::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity) );
  }

  .xl\:placeholder-teal-900::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-50::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-100::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-200::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-300::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-400::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-500::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-600::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-700::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-800::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity) );
  }

  .xl\:placeholder-blue-900::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-50::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-100::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-200::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-300::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-400::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-500::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-600::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-700::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-800::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity) );
  }

  .xl\:placeholder-indigo-900::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-50::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-100::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-200::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-300::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-400::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-500::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-600::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-700::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-800::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity) );
  }

  .xl\:placeholder-purple-900::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-50::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-100::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-200::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-300::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-400::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-500::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-600::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-700::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-800::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity) );
  }

  .xl\:placeholder-pink-900::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: #0000;
  }

  .xl\:focus\:placeholder-white:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-black:focus::placeholder {
    --placeholder-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-cool-gray-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-red-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-orange-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-green-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-teal-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-blue-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-purple-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-50:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-100:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-200:focus::placeholder {
    --placeholder-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-300:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-400:focus::placeholder {
    --placeholder-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-500:focus::placeholder {
    --placeholder-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-600:focus::placeholder {
    --placeholder-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-700:focus::placeholder {
    --placeholder-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-800:focus::placeholder {
    --placeholder-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--placeholder-opacity) );
  }

  .xl\:focus\:placeholder-pink-900:focus::placeholder {
    --placeholder-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--placeholder-opacity) );
  }

  .xl\:placeholder-opacity-0::placeholder {
    --placeholder-opacity: 0;
  }

  .xl\:placeholder-opacity-25::placeholder {
    --placeholder-opacity: .25;
  }

  .xl\:placeholder-opacity-50::placeholder {
    --placeholder-opacity: .5;
  }

  .xl\:placeholder-opacity-75::placeholder {
    --placeholder-opacity: .75;
  }

  .xl\:placeholder-opacity-100::placeholder {
    --placeholder-opacity: 1;
  }

  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --placeholder-opacity: 0;
  }

  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --placeholder-opacity: .25;
  }

  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --placeholder-opacity: .5;
  }

  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --placeholder-opacity: .75;
  }

  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --placeholder-opacity: 1;
  }

  .xl\:pointer-events-none {
    pointer-events: none;
  }

  .xl\:pointer-events-auto {
    pointer-events: auto;
  }

  .xl\:static {
    position: static;
  }

  .xl\:fixed {
    position: fixed;
  }

  .xl\:absolute {
    position: absolute;
  }

  .xl\:relative {
    position: relative;
  }

  .xl\:sticky {
    position: sticky;
  }

  .xl\:inset-0 {
    inset: 0;
  }

  .xl\:inset-1 {
    inset: .25rem;
  }

  .xl\:inset-2 {
    inset: .5rem;
  }

  .xl\:inset-3 {
    inset: .75rem;
  }

  .xl\:inset-4 {
    inset: 1rem;
  }

  .xl\:inset-5 {
    inset: 1.25rem;
  }

  .xl\:inset-6 {
    inset: 1.5rem;
  }

  .xl\:inset-7 {
    inset: 1.75rem;
  }

  .xl\:inset-8 {
    inset: 2rem;
  }

  .xl\:inset-9 {
    inset: 2.25rem;
  }

  .xl\:inset-10 {
    inset: 2.5rem;
  }

  .xl\:inset-11 {
    inset: 2.75rem;
  }

  .xl\:inset-12 {
    inset: 3rem;
  }

  .xl\:inset-13 {
    inset: 3.25rem;
  }

  .xl\:inset-14 {
    inset: 3.5rem;
  }

  .xl\:inset-15 {
    inset: 3.75rem;
  }

  .xl\:inset-16 {
    inset: 4rem;
  }

  .xl\:inset-20 {
    inset: 5rem;
  }

  .xl\:inset-24 {
    inset: 6rem;
  }

  .xl\:inset-28 {
    inset: 7rem;
  }

  .xl\:inset-32 {
    inset: 8rem;
  }

  .xl\:inset-36 {
    inset: 9rem;
  }

  .xl\:inset-40 {
    inset: 10rem;
  }

  .xl\:inset-44 {
    inset: 11rem;
  }

  .xl\:inset-48 {
    inset: 12rem;
  }

  .xl\:inset-52 {
    inset: 13rem;
  }

  .xl\:inset-56 {
    inset: 14rem;
  }

  .xl\:inset-60 {
    inset: 15rem;
  }

  .xl\:inset-64 {
    inset: 16rem;
  }

  .xl\:inset-72 {
    inset: 18rem;
  }

  .xl\:inset-80 {
    inset: 20rem;
  }

  .xl\:inset-96 {
    inset: 24rem;
  }

  .xl\:inset-auto {
    inset: auto;
  }

  .xl\:inset-px {
    inset: 1px;
  }

  .xl\:inset-0\.5 {
    inset: .125rem;
  }

  .xl\:inset-1\.5 {
    inset: .375rem;
  }

  .xl\:inset-2\.5 {
    inset: .625rem;
  }

  .xl\:inset-3\.5 {
    inset: .875rem;
  }

  .xl\:inset-1\/2 {
    inset: 50%;
  }

  .xl\:inset-1\/3 {
    inset: 33.3333%;
  }

  .xl\:inset-2\/3 {
    inset: 66.6667%;
  }

  .xl\:inset-1\/4 {
    inset: 25%;
  }

  .xl\:inset-2\/4 {
    inset: 50%;
  }

  .xl\:inset-3\/4 {
    inset: 75%;
  }

  .xl\:inset-1\/5 {
    inset: 20%;
  }

  .xl\:inset-2\/5 {
    inset: 40%;
  }

  .xl\:inset-3\/5 {
    inset: 60%;
  }

  .xl\:inset-4\/5 {
    inset: 80%;
  }

  .xl\:inset-1\/6 {
    inset: 16.6667%;
  }

  .xl\:inset-2\/6 {
    inset: 33.3333%;
  }

  .xl\:inset-3\/6 {
    inset: 50%;
  }

  .xl\:inset-4\/6 {
    inset: 66.6667%;
  }

  .xl\:inset-5\/6 {
    inset: 83.3333%;
  }

  .xl\:inset-1\/12 {
    inset: 8.33333%;
  }

  .xl\:inset-2\/12 {
    inset: 16.6667%;
  }

  .xl\:inset-3\/12 {
    inset: 25%;
  }

  .xl\:inset-4\/12 {
    inset: 33.3333%;
  }

  .xl\:inset-5\/12 {
    inset: 41.6667%;
  }

  .xl\:inset-6\/12 {
    inset: 50%;
  }

  .xl\:inset-7\/12 {
    inset: 58.3333%;
  }

  .xl\:inset-8\/12 {
    inset: 66.6667%;
  }

  .xl\:inset-9\/12 {
    inset: 75%;
  }

  .xl\:inset-10\/12 {
    inset: 83.3333%;
  }

  .xl\:inset-11\/12 {
    inset: 91.6667%;
  }

  .xl\:inset-full {
    inset: 100%;
  }

  .xl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }

  .xl\:inset-x-0 {
    left: 0;
    right: 0;
  }

  .xl\:inset-y-1 {
    top: .25rem;
    bottom: .25rem;
  }

  .xl\:inset-x-1 {
    left: .25rem;
    right: .25rem;
  }

  .xl\:inset-y-2 {
    top: .5rem;
    bottom: .5rem;
  }

  .xl\:inset-x-2 {
    left: .5rem;
    right: .5rem;
  }

  .xl\:inset-y-3 {
    top: .75rem;
    bottom: .75rem;
  }

  .xl\:inset-x-3 {
    left: .75rem;
    right: .75rem;
  }

  .xl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }

  .xl\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }

  .xl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }

  .xl\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }

  .xl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }

  .xl\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }

  .xl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }

  .xl\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }

  .xl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }

  .xl\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }

  .xl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }

  .xl\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }

  .xl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }

  .xl\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }

  .xl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }

  .xl\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }

  .xl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }

  .xl\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }

  .xl\:inset-y-13 {
    top: 3.25rem;
    bottom: 3.25rem;
  }

  .xl\:inset-x-13 {
    left: 3.25rem;
    right: 3.25rem;
  }

  .xl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }

  .xl\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }

  .xl\:inset-y-15 {
    top: 3.75rem;
    bottom: 3.75rem;
  }

  .xl\:inset-x-15 {
    left: 3.75rem;
    right: 3.75rem;
  }

  .xl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }

  .xl\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }

  .xl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }

  .xl\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }

  .xl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }

  .xl\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }

  .xl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }

  .xl\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }

  .xl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }

  .xl\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }

  .xl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }

  .xl\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }

  .xl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }

  .xl\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }

  .xl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }

  .xl\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }

  .xl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }

  .xl\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }

  .xl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }

  .xl\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }

  .xl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }

  .xl\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }

  .xl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }

  .xl\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }

  .xl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }

  .xl\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }

  .xl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }

  .xl\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }

  .xl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }

  .xl\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }

  .xl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }

  .xl\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }

  .xl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }

  .xl\:inset-x-auto {
    left: auto;
    right: auto;
  }

  .xl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }

  .xl\:inset-x-px {
    left: 1px;
    right: 1px;
  }

  .xl\:inset-y-0\.5 {
    top: .125rem;
    bottom: .125rem;
  }

  .xl\:inset-x-0\.5 {
    left: .125rem;
    right: .125rem;
  }

  .xl\:inset-y-1\.5 {
    top: .375rem;
    bottom: .375rem;
  }

  .xl\:inset-x-1\.5 {
    left: .375rem;
    right: .375rem;
  }

  .xl\:inset-y-2\.5 {
    top: .625rem;
    bottom: .625rem;
  }

  .xl\:inset-x-2\.5 {
    left: .625rem;
    right: .625rem;
  }

  .xl\:inset-y-3\.5 {
    top: .875rem;
    bottom: .875rem;
  }

  .xl\:inset-x-3\.5 {
    left: .875rem;
    right: .875rem;
  }

  .xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }

  .xl\:inset-y-1\/3 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .xl\:inset-x-1\/3 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .xl\:inset-y-2\/3 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .xl\:inset-x-2\/3 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }

  .xl\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }

  .xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }

  .xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }

  .xl\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }

  .xl\:inset-y-1\/5 {
    top: 20%;
    bottom: 20%;
  }

  .xl\:inset-x-1\/5 {
    left: 20%;
    right: 20%;
  }

  .xl\:inset-y-2\/5 {
    top: 40%;
    bottom: 40%;
  }

  .xl\:inset-x-2\/5 {
    left: 40%;
    right: 40%;
  }

  .xl\:inset-y-3\/5 {
    top: 60%;
    bottom: 60%;
  }

  .xl\:inset-x-3\/5 {
    left: 60%;
    right: 60%;
  }

  .xl\:inset-y-4\/5 {
    top: 80%;
    bottom: 80%;
  }

  .xl\:inset-x-4\/5 {
    left: 80%;
    right: 80%;
  }

  .xl\:inset-y-1\/6 {
    top: 16.6667%;
    bottom: 16.6667%;
  }

  .xl\:inset-x-1\/6 {
    left: 16.6667%;
    right: 16.6667%;
  }

  .xl\:inset-y-2\/6 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .xl\:inset-x-2\/6 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .xl\:inset-y-3\/6 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:inset-x-3\/6 {
    left: 50%;
    right: 50%;
  }

  .xl\:inset-y-4\/6 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .xl\:inset-x-4\/6 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .xl\:inset-y-5\/6 {
    top: 83.3333%;
    bottom: 83.3333%;
  }

  .xl\:inset-x-5\/6 {
    left: 83.3333%;
    right: 83.3333%;
  }

  .xl\:inset-y-1\/12 {
    top: 8.33333%;
    bottom: 8.33333%;
  }

  .xl\:inset-x-1\/12 {
    left: 8.33333%;
    right: 8.33333%;
  }

  .xl\:inset-y-2\/12 {
    top: 16.6667%;
    bottom: 16.6667%;
  }

  .xl\:inset-x-2\/12 {
    left: 16.6667%;
    right: 16.6667%;
  }

  .xl\:inset-y-3\/12 {
    top: 25%;
    bottom: 25%;
  }

  .xl\:inset-x-3\/12 {
    left: 25%;
    right: 25%;
  }

  .xl\:inset-y-4\/12 {
    top: 33.3333%;
    bottom: 33.3333%;
  }

  .xl\:inset-x-4\/12 {
    left: 33.3333%;
    right: 33.3333%;
  }

  .xl\:inset-y-5\/12 {
    top: 41.6667%;
    bottom: 41.6667%;
  }

  .xl\:inset-x-5\/12 {
    left: 41.6667%;
    right: 41.6667%;
  }

  .xl\:inset-y-6\/12 {
    top: 50%;
    bottom: 50%;
  }

  .xl\:inset-x-6\/12 {
    left: 50%;
    right: 50%;
  }

  .xl\:inset-y-7\/12 {
    top: 58.3333%;
    bottom: 58.3333%;
  }

  .xl\:inset-x-7\/12 {
    left: 58.3333%;
    right: 58.3333%;
  }

  .xl\:inset-y-8\/12 {
    top: 66.6667%;
    bottom: 66.6667%;
  }

  .xl\:inset-x-8\/12 {
    left: 66.6667%;
    right: 66.6667%;
  }

  .xl\:inset-y-9\/12 {
    top: 75%;
    bottom: 75%;
  }

  .xl\:inset-x-9\/12 {
    left: 75%;
    right: 75%;
  }

  .xl\:inset-y-10\/12 {
    top: 83.3333%;
    bottom: 83.3333%;
  }

  .xl\:inset-x-10\/12 {
    left: 83.3333%;
    right: 83.3333%;
  }

  .xl\:inset-y-11\/12 {
    top: 91.6667%;
    bottom: 91.6667%;
  }

  .xl\:inset-x-11\/12 {
    left: 91.6667%;
    right: 91.6667%;
  }

  .xl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }

  .xl\:inset-x-full {
    left: 100%;
    right: 100%;
  }

  .xl\:top-0 {
    top: 0;
  }

  .xl\:right-0 {
    right: 0;
  }

  .xl\:bottom-0 {
    bottom: 0;
  }

  .xl\:left-0 {
    left: 0;
  }

  .xl\:top-1 {
    top: .25rem;
  }

  .xl\:right-1 {
    right: .25rem;
  }

  .xl\:bottom-1 {
    bottom: .25rem;
  }

  .xl\:left-1 {
    left: .25rem;
  }

  .xl\:top-2 {
    top: .5rem;
  }

  .xl\:right-2 {
    right: .5rem;
  }

  .xl\:bottom-2 {
    bottom: .5rem;
  }

  .xl\:left-2 {
    left: .5rem;
  }

  .xl\:top-3 {
    top: .75rem;
  }

  .xl\:right-3 {
    right: .75rem;
  }

  .xl\:bottom-3 {
    bottom: .75rem;
  }

  .xl\:left-3 {
    left: .75rem;
  }

  .xl\:top-4 {
    top: 1rem;
  }

  .xl\:right-4 {
    right: 1rem;
  }

  .xl\:bottom-4 {
    bottom: 1rem;
  }

  .xl\:left-4 {
    left: 1rem;
  }

  .xl\:top-5 {
    top: 1.25rem;
  }

  .xl\:right-5 {
    right: 1.25rem;
  }

  .xl\:bottom-5 {
    bottom: 1.25rem;
  }

  .xl\:left-5 {
    left: 1.25rem;
  }

  .xl\:top-6 {
    top: 1.5rem;
  }

  .xl\:right-6 {
    right: 1.5rem;
  }

  .xl\:bottom-6 {
    bottom: 1.5rem;
  }

  .xl\:left-6 {
    left: 1.5rem;
  }

  .xl\:top-7 {
    top: 1.75rem;
  }

  .xl\:right-7 {
    right: 1.75rem;
  }

  .xl\:bottom-7 {
    bottom: 1.75rem;
  }

  .xl\:left-7 {
    left: 1.75rem;
  }

  .xl\:top-8 {
    top: 2rem;
  }

  .xl\:right-8 {
    right: 2rem;
  }

  .xl\:bottom-8 {
    bottom: 2rem;
  }

  .xl\:left-8 {
    left: 2rem;
  }

  .xl\:top-9 {
    top: 2.25rem;
  }

  .xl\:right-9 {
    right: 2.25rem;
  }

  .xl\:bottom-9 {
    bottom: 2.25rem;
  }

  .xl\:left-9 {
    left: 2.25rem;
  }

  .xl\:top-10 {
    top: 2.5rem;
  }

  .xl\:right-10 {
    right: 2.5rem;
  }

  .xl\:bottom-10 {
    bottom: 2.5rem;
  }

  .xl\:left-10 {
    left: 2.5rem;
  }

  .xl\:top-11 {
    top: 2.75rem;
  }

  .xl\:right-11 {
    right: 2.75rem;
  }

  .xl\:bottom-11 {
    bottom: 2.75rem;
  }

  .xl\:left-11 {
    left: 2.75rem;
  }

  .xl\:top-12 {
    top: 3rem;
  }

  .xl\:right-12 {
    right: 3rem;
  }

  .xl\:bottom-12 {
    bottom: 3rem;
  }

  .xl\:left-12 {
    left: 3rem;
  }

  .xl\:top-13 {
    top: 3.25rem;
  }

  .xl\:right-13 {
    right: 3.25rem;
  }

  .xl\:bottom-13 {
    bottom: 3.25rem;
  }

  .xl\:left-13 {
    left: 3.25rem;
  }

  .xl\:top-14 {
    top: 3.5rem;
  }

  .xl\:right-14 {
    right: 3.5rem;
  }

  .xl\:bottom-14 {
    bottom: 3.5rem;
  }

  .xl\:left-14 {
    left: 3.5rem;
  }

  .xl\:top-15 {
    top: 3.75rem;
  }

  .xl\:right-15 {
    right: 3.75rem;
  }

  .xl\:bottom-15 {
    bottom: 3.75rem;
  }

  .xl\:left-15 {
    left: 3.75rem;
  }

  .xl\:top-16 {
    top: 4rem;
  }

  .xl\:right-16 {
    right: 4rem;
  }

  .xl\:bottom-16 {
    bottom: 4rem;
  }

  .xl\:left-16 {
    left: 4rem;
  }

  .xl\:top-20 {
    top: 5rem;
  }

  .xl\:right-20 {
    right: 5rem;
  }

  .xl\:bottom-20 {
    bottom: 5rem;
  }

  .xl\:left-20 {
    left: 5rem;
  }

  .xl\:top-24 {
    top: 6rem;
  }

  .xl\:right-24 {
    right: 6rem;
  }

  .xl\:bottom-24 {
    bottom: 6rem;
  }

  .xl\:left-24 {
    left: 6rem;
  }

  .xl\:top-28 {
    top: 7rem;
  }

  .xl\:right-28 {
    right: 7rem;
  }

  .xl\:bottom-28 {
    bottom: 7rem;
  }

  .xl\:left-28 {
    left: 7rem;
  }

  .xl\:top-32 {
    top: 8rem;
  }

  .xl\:right-32 {
    right: 8rem;
  }

  .xl\:bottom-32 {
    bottom: 8rem;
  }

  .xl\:left-32 {
    left: 8rem;
  }

  .xl\:top-36 {
    top: 9rem;
  }

  .xl\:right-36 {
    right: 9rem;
  }

  .xl\:bottom-36 {
    bottom: 9rem;
  }

  .xl\:left-36 {
    left: 9rem;
  }

  .xl\:top-40 {
    top: 10rem;
  }

  .xl\:right-40 {
    right: 10rem;
  }

  .xl\:bottom-40 {
    bottom: 10rem;
  }

  .xl\:left-40 {
    left: 10rem;
  }

  .xl\:top-44 {
    top: 11rem;
  }

  .xl\:right-44 {
    right: 11rem;
  }

  .xl\:bottom-44 {
    bottom: 11rem;
  }

  .xl\:left-44 {
    left: 11rem;
  }

  .xl\:top-48 {
    top: 12rem;
  }

  .xl\:right-48 {
    right: 12rem;
  }

  .xl\:bottom-48 {
    bottom: 12rem;
  }

  .xl\:left-48 {
    left: 12rem;
  }

  .xl\:top-52 {
    top: 13rem;
  }

  .xl\:right-52 {
    right: 13rem;
  }

  .xl\:bottom-52 {
    bottom: 13rem;
  }

  .xl\:left-52 {
    left: 13rem;
  }

  .xl\:top-56 {
    top: 14rem;
  }

  .xl\:right-56 {
    right: 14rem;
  }

  .xl\:bottom-56 {
    bottom: 14rem;
  }

  .xl\:left-56 {
    left: 14rem;
  }

  .xl\:top-60 {
    top: 15rem;
  }

  .xl\:right-60 {
    right: 15rem;
  }

  .xl\:bottom-60 {
    bottom: 15rem;
  }

  .xl\:left-60 {
    left: 15rem;
  }

  .xl\:top-64 {
    top: 16rem;
  }

  .xl\:right-64 {
    right: 16rem;
  }

  .xl\:bottom-64 {
    bottom: 16rem;
  }

  .xl\:left-64 {
    left: 16rem;
  }

  .xl\:top-72 {
    top: 18rem;
  }

  .xl\:right-72 {
    right: 18rem;
  }

  .xl\:bottom-72 {
    bottom: 18rem;
  }

  .xl\:left-72 {
    left: 18rem;
  }

  .xl\:top-80 {
    top: 20rem;
  }

  .xl\:right-80 {
    right: 20rem;
  }

  .xl\:bottom-80 {
    bottom: 20rem;
  }

  .xl\:left-80 {
    left: 20rem;
  }

  .xl\:top-96 {
    top: 24rem;
  }

  .xl\:right-96 {
    right: 24rem;
  }

  .xl\:bottom-96 {
    bottom: 24rem;
  }

  .xl\:left-96 {
    left: 24rem;
  }

  .xl\:top-auto {
    top: auto;
  }

  .xl\:right-auto {
    right: auto;
  }

  .xl\:bottom-auto {
    bottom: auto;
  }

  .xl\:left-auto {
    left: auto;
  }

  .xl\:top-px {
    top: 1px;
  }

  .xl\:right-px {
    right: 1px;
  }

  .xl\:bottom-px {
    bottom: 1px;
  }

  .xl\:left-px {
    left: 1px;
  }

  .xl\:top-0\.5 {
    top: .125rem;
  }

  .xl\:right-0\.5 {
    right: .125rem;
  }

  .xl\:bottom-0\.5 {
    bottom: .125rem;
  }

  .xl\:left-0\.5 {
    left: .125rem;
  }

  .xl\:top-1\.5 {
    top: .375rem;
  }

  .xl\:right-1\.5 {
    right: .375rem;
  }

  .xl\:bottom-1\.5 {
    bottom: .375rem;
  }

  .xl\:left-1\.5 {
    left: .375rem;
  }

  .xl\:top-2\.5 {
    top: .625rem;
  }

  .xl\:right-2\.5 {
    right: .625rem;
  }

  .xl\:bottom-2\.5 {
    bottom: .625rem;
  }

  .xl\:left-2\.5 {
    left: .625rem;
  }

  .xl\:top-3\.5 {
    top: .875rem;
  }

  .xl\:right-3\.5 {
    right: .875rem;
  }

  .xl\:bottom-3\.5 {
    bottom: .875rem;
  }

  .xl\:left-3\.5 {
    left: .875rem;
  }

  .xl\:top-1\/2 {
    top: 50%;
  }

  .xl\:right-1\/2 {
    right: 50%;
  }

  .xl\:bottom-1\/2 {
    bottom: 50%;
  }

  .xl\:left-1\/2 {
    left: 50%;
  }

  .xl\:top-1\/3 {
    top: 33.3333%;
  }

  .xl\:right-1\/3 {
    right: 33.3333%;
  }

  .xl\:bottom-1\/3 {
    bottom: 33.3333%;
  }

  .xl\:left-1\/3 {
    left: 33.3333%;
  }

  .xl\:top-2\/3 {
    top: 66.6667%;
  }

  .xl\:right-2\/3 {
    right: 66.6667%;
  }

  .xl\:bottom-2\/3 {
    bottom: 66.6667%;
  }

  .xl\:left-2\/3 {
    left: 66.6667%;
  }

  .xl\:top-1\/4 {
    top: 25%;
  }

  .xl\:right-1\/4 {
    right: 25%;
  }

  .xl\:bottom-1\/4 {
    bottom: 25%;
  }

  .xl\:left-1\/4 {
    left: 25%;
  }

  .xl\:top-2\/4 {
    top: 50%;
  }

  .xl\:right-2\/4 {
    right: 50%;
  }

  .xl\:bottom-2\/4 {
    bottom: 50%;
  }

  .xl\:left-2\/4 {
    left: 50%;
  }

  .xl\:top-3\/4 {
    top: 75%;
  }

  .xl\:right-3\/4 {
    right: 75%;
  }

  .xl\:bottom-3\/4 {
    bottom: 75%;
  }

  .xl\:left-3\/4 {
    left: 75%;
  }

  .xl\:top-1\/5 {
    top: 20%;
  }

  .xl\:right-1\/5 {
    right: 20%;
  }

  .xl\:bottom-1\/5 {
    bottom: 20%;
  }

  .xl\:left-1\/5 {
    left: 20%;
  }

  .xl\:top-2\/5 {
    top: 40%;
  }

  .xl\:right-2\/5 {
    right: 40%;
  }

  .xl\:bottom-2\/5 {
    bottom: 40%;
  }

  .xl\:left-2\/5 {
    left: 40%;
  }

  .xl\:top-3\/5 {
    top: 60%;
  }

  .xl\:right-3\/5 {
    right: 60%;
  }

  .xl\:bottom-3\/5 {
    bottom: 60%;
  }

  .xl\:left-3\/5 {
    left: 60%;
  }

  .xl\:top-4\/5 {
    top: 80%;
  }

  .xl\:right-4\/5 {
    right: 80%;
  }

  .xl\:bottom-4\/5 {
    bottom: 80%;
  }

  .xl\:left-4\/5 {
    left: 80%;
  }

  .xl\:top-1\/6 {
    top: 16.6667%;
  }

  .xl\:right-1\/6 {
    right: 16.6667%;
  }

  .xl\:bottom-1\/6 {
    bottom: 16.6667%;
  }

  .xl\:left-1\/6 {
    left: 16.6667%;
  }

  .xl\:top-2\/6 {
    top: 33.3333%;
  }

  .xl\:right-2\/6 {
    right: 33.3333%;
  }

  .xl\:bottom-2\/6 {
    bottom: 33.3333%;
  }

  .xl\:left-2\/6 {
    left: 33.3333%;
  }

  .xl\:top-3\/6 {
    top: 50%;
  }

  .xl\:right-3\/6 {
    right: 50%;
  }

  .xl\:bottom-3\/6 {
    bottom: 50%;
  }

  .xl\:left-3\/6 {
    left: 50%;
  }

  .xl\:top-4\/6 {
    top: 66.6667%;
  }

  .xl\:right-4\/6 {
    right: 66.6667%;
  }

  .xl\:bottom-4\/6 {
    bottom: 66.6667%;
  }

  .xl\:left-4\/6 {
    left: 66.6667%;
  }

  .xl\:top-5\/6 {
    top: 83.3333%;
  }

  .xl\:right-5\/6 {
    right: 83.3333%;
  }

  .xl\:bottom-5\/6 {
    bottom: 83.3333%;
  }

  .xl\:left-5\/6 {
    left: 83.3333%;
  }

  .xl\:top-1\/12 {
    top: 8.33333%;
  }

  .xl\:right-1\/12 {
    right: 8.33333%;
  }

  .xl\:bottom-1\/12 {
    bottom: 8.33333%;
  }

  .xl\:left-1\/12 {
    left: 8.33333%;
  }

  .xl\:top-2\/12 {
    top: 16.6667%;
  }

  .xl\:right-2\/12 {
    right: 16.6667%;
  }

  .xl\:bottom-2\/12 {
    bottom: 16.6667%;
  }

  .xl\:left-2\/12 {
    left: 16.6667%;
  }

  .xl\:top-3\/12 {
    top: 25%;
  }

  .xl\:right-3\/12 {
    right: 25%;
  }

  .xl\:bottom-3\/12 {
    bottom: 25%;
  }

  .xl\:left-3\/12 {
    left: 25%;
  }

  .xl\:top-4\/12 {
    top: 33.3333%;
  }

  .xl\:right-4\/12 {
    right: 33.3333%;
  }

  .xl\:bottom-4\/12 {
    bottom: 33.3333%;
  }

  .xl\:left-4\/12 {
    left: 33.3333%;
  }

  .xl\:top-5\/12 {
    top: 41.6667%;
  }

  .xl\:right-5\/12 {
    right: 41.6667%;
  }

  .xl\:bottom-5\/12 {
    bottom: 41.6667%;
  }

  .xl\:left-5\/12 {
    left: 41.6667%;
  }

  .xl\:top-6\/12 {
    top: 50%;
  }

  .xl\:right-6\/12 {
    right: 50%;
  }

  .xl\:bottom-6\/12 {
    bottom: 50%;
  }

  .xl\:left-6\/12 {
    left: 50%;
  }

  .xl\:top-7\/12 {
    top: 58.3333%;
  }

  .xl\:right-7\/12 {
    right: 58.3333%;
  }

  .xl\:bottom-7\/12 {
    bottom: 58.3333%;
  }

  .xl\:left-7\/12 {
    left: 58.3333%;
  }

  .xl\:top-8\/12 {
    top: 66.6667%;
  }

  .xl\:right-8\/12 {
    right: 66.6667%;
  }

  .xl\:bottom-8\/12 {
    bottom: 66.6667%;
  }

  .xl\:left-8\/12 {
    left: 66.6667%;
  }

  .xl\:top-9\/12 {
    top: 75%;
  }

  .xl\:right-9\/12 {
    right: 75%;
  }

  .xl\:bottom-9\/12 {
    bottom: 75%;
  }

  .xl\:left-9\/12 {
    left: 75%;
  }

  .xl\:top-10\/12 {
    top: 83.3333%;
  }

  .xl\:right-10\/12 {
    right: 83.3333%;
  }

  .xl\:bottom-10\/12 {
    bottom: 83.3333%;
  }

  .xl\:left-10\/12 {
    left: 83.3333%;
  }

  .xl\:top-11\/12 {
    top: 91.6667%;
  }

  .xl\:right-11\/12 {
    right: 91.6667%;
  }

  .xl\:bottom-11\/12 {
    bottom: 91.6667%;
  }

  .xl\:left-11\/12 {
    left: 91.6667%;
  }

  .xl\:top-full {
    top: 100%;
  }

  .xl\:right-full {
    right: 100%;
  }

  .xl\:bottom-full {
    bottom: 100%;
  }

  .xl\:left-full {
    left: 100%;
  }

  .xl\:resize-none {
    resize: none;
  }

  .xl\:resize-y {
    resize: vertical;
  }

  .xl\:resize-x {
    resize: horizontal;
  }

  .xl\:resize {
    resize: both;
  }

  .xl\:shadow-xs {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .xl\:shadow-sm {
    box-shadow: 0 1px 2px #0000000d;
  }

  .xl\:shadow {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .xl\:shadow-md {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .xl\:shadow-lg {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .xl\:shadow-xl {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .xl\:shadow-2xl {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .xl\:shadow-inner {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .xl\:shadow-outline {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .xl\:shadow-none {
    box-shadow: none;
  }

  .xl\:shadow-solid {
    box-shadow: 0 0 0 2px;
  }

  .xl\:shadow-outline-gray {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .xl\:shadow-outline-blue {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .xl\:shadow-outline-teal {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .xl\:shadow-outline-green {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .xl\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .xl\:shadow-outline-orange {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .xl\:shadow-outline-red {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .xl\:shadow-outline-pink {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .xl\:shadow-outline-purple {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .xl\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .group:focus .xl\:group-focus\:shadow-xs {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .group:focus .xl\:group-focus\:shadow-sm {
    box-shadow: 0 1px 2px #0000000d;
  }

  .group:focus .xl\:group-focus\:shadow {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .group:focus .xl\:group-focus\:shadow-md {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .group:focus .xl\:group-focus\:shadow-lg {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .group:focus .xl\:group-focus\:shadow-xl {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .group:focus .xl\:group-focus\:shadow-2xl {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .group:focus .xl\:group-focus\:shadow-inner {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .group:focus .xl\:group-focus\:shadow-outline {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .group:focus .xl\:group-focus\:shadow-none {
    box-shadow: none;
  }

  .group:focus .xl\:group-focus\:shadow-solid {
    box-shadow: 0 0 0 2px;
  }

  .group:focus .xl\:group-focus\:shadow-outline-gray {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .group:focus .xl\:group-focus\:shadow-outline-blue {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .group:focus .xl\:group-focus\:shadow-outline-teal {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .group:focus .xl\:group-focus\:shadow-outline-green {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .group:focus .xl\:group-focus\:shadow-outline-yellow {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .group:focus .xl\:group-focus\:shadow-outline-orange {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .group:focus .xl\:group-focus\:shadow-outline-red {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .group:focus .xl\:group-focus\:shadow-outline-pink {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .group:focus .xl\:group-focus\:shadow-outline-purple {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .group:focus .xl\:group-focus\:shadow-outline-indigo {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .xl\:hover\:shadow-xs:hover {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .xl\:hover\:shadow-sm:hover {
    box-shadow: 0 1px 2px #0000000d;
  }

  .xl\:hover\:shadow:hover {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .xl\:hover\:shadow-md:hover {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .xl\:hover\:shadow-lg:hover {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .xl\:hover\:shadow-xl:hover {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .xl\:hover\:shadow-2xl:hover {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .xl\:hover\:shadow-inner:hover {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .xl\:hover\:shadow-outline:hover {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .xl\:hover\:shadow-none:hover {
    box-shadow: none;
  }

  .xl\:hover\:shadow-solid:hover {
    box-shadow: 0 0 0 2px;
  }

  .xl\:hover\:shadow-outline-gray:hover {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .xl\:hover\:shadow-outline-blue:hover {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .xl\:hover\:shadow-outline-teal:hover {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .xl\:hover\:shadow-outline-green:hover {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .xl\:hover\:shadow-outline-yellow:hover {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .xl\:hover\:shadow-outline-orange:hover {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .xl\:hover\:shadow-outline-red:hover {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .xl\:hover\:shadow-outline-pink:hover {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .xl\:hover\:shadow-outline-purple:hover {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .xl\:hover\:shadow-outline-indigo:hover {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .xl\:focus\:shadow-xs:focus {
    box-shadow: 0 0 0 1px #0000000d;
  }

  .xl\:focus\:shadow-sm:focus {
    box-shadow: 0 1px 2px #0000000d;
  }

  .xl\:focus\:shadow:focus {
    box-shadow: 0 1px 3px #0000001a, 0 1px 2px #0000000f;
  }

  .xl\:focus\:shadow-md:focus {
    box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -1px #0000000f;
  }

  .xl\:focus\:shadow-lg:focus {
    box-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
  }

  .xl\:focus\:shadow-xl:focus {
    box-shadow: 0 20px 25px -5px #0000001a, 0 10px 10px -5px #0000000a;
  }

  .xl\:focus\:shadow-2xl:focus {
    box-shadow: 0 25px 50px -12px #00000040;
  }

  .xl\:focus\:shadow-inner:focus {
    box-shadow: inset 0 2px 4px #0000000f;
  }

  .xl\:focus\:shadow-outline:focus {
    box-shadow: 0 0 0 3px #76a9fa73;
  }

  .xl\:focus\:shadow-none:focus {
    box-shadow: none;
  }

  .xl\:focus\:shadow-solid:focus {
    box-shadow: 0 0 0 2px;
  }

  .xl\:focus\:shadow-outline-gray:focus {
    box-shadow: 0 0 0 3px #9fa6b273;
  }

  .xl\:focus\:shadow-outline-blue:focus {
    box-shadow: 0 0 0 3px #a4cafe73;
  }

  .xl\:focus\:shadow-outline-teal:focus {
    box-shadow: 0 0 0 3px #7edce273;
  }

  .xl\:focus\:shadow-outline-green:focus {
    box-shadow: 0 0 0 3px #84e1bc73;
  }

  .xl\:focus\:shadow-outline-yellow:focus {
    box-shadow: 0 0 0 3px #faca1573;
  }

  .xl\:focus\:shadow-outline-orange:focus {
    box-shadow: 0 0 0 3px #fdba8c73;
  }

  .xl\:focus\:shadow-outline-red:focus {
    box-shadow: 0 0 0 3px #f8b4b473;
  }

  .xl\:focus\:shadow-outline-pink:focus {
    box-shadow: 0 0 0 3px #f8b4d973;
  }

  .xl\:focus\:shadow-outline-purple:focus {
    box-shadow: 0 0 0 3px #cabffd73;
  }

  .xl\:focus\:shadow-outline-indigo:focus {
    box-shadow: 0 0 0 3px #b4c6fc73;
  }

  .xl\:fill-current {
    fill: currentColor;
  }

  .xl\:stroke-current {
    stroke: currentColor;
  }

  .xl\:stroke-0 {
    stroke-width: 0;
  }

  .xl\:stroke-1 {
    stroke-width: 1px;
  }

  .xl\:stroke-2 {
    stroke-width: 2px;
  }

  .xl\:table-auto {
    table-layout: auto;
  }

  .xl\:table-fixed {
    table-layout: fixed;
  }

  .xl\:text-left {
    text-align: left;
  }

  .xl\:text-center {
    text-align: center;
  }

  .xl\:text-right {
    text-align: right;
  }

  .xl\:text-justify {
    text-align: justify;
  }

  .xl\:text-transparent {
    color: #0000;
  }

  .xl\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .xl\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .xl\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .xl\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .xl\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .xl\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .xl\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .xl\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .xl\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .xl\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .xl\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .xl\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .xl\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .xl\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .xl\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .xl\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .xl\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .xl\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .xl\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .xl\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .xl\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .xl\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .xl\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .xl\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .xl\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .xl\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .xl\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .xl\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .xl\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .xl\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .xl\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .xl\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .xl\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .xl\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .xl\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .xl\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .xl\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .xl\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .xl\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .xl\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .xl\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .xl\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .xl\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .xl\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .xl\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .xl\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .xl\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .xl\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .xl\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .xl\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .xl\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .xl\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .xl\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .xl\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .xl\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .xl\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .xl\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .xl\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .xl\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .xl\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .xl\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .xl\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .xl\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .xl\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .xl\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .xl\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .xl\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .xl\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .xl\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .xl\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .xl\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .xl\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .xl\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .xl\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .xl\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .xl\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .xl\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .xl\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .xl\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .xl\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .xl\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .xl\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .xl\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .xl\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .xl\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .xl\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .xl\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .xl\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .xl\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .xl\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .xl\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .xl\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .xl\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .xl\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .xl\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .xl\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .xl\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .xl\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .xl\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .xl\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .xl\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .xl\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .xl\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .xl\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .xl\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .xl\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .xl\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .xl\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .xl\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .xl\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .xl\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .xl\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-transparent {
    color: #0000;
  }

  .group:hover .xl\:group-hover\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .group:hover .xl\:group-hover\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-transparent {
    color: #0000;
  }

  .group:focus .xl\:group-focus\:text-white {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-black {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-50 {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-100 {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-200 {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-300 {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-400 {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-500 {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-600 {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-700 {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-800 {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-gray-900 {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-50 {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-100 {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-200 {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-300 {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-400 {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-500 {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-600 {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-700 {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-800 {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-cool-gray-900 {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-50 {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-100 {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-200 {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-300 {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-400 {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-500 {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-600 {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-700 {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-800 {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-red-900 {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-50 {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-100 {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-200 {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-300 {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-400 {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-500 {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-600 {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-700 {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-800 {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-orange-900 {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-50 {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-100 {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-200 {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-300 {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-400 {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-500 {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-600 {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-700 {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-800 {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-yellow-900 {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-50 {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-100 {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-200 {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-300 {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-400 {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-500 {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-600 {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-700 {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-800 {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-green-900 {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-50 {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-100 {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-200 {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-300 {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-400 {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-500 {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-600 {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-700 {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-800 {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-teal-900 {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-50 {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-100 {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-200 {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-300 {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-400 {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-500 {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-600 {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-700 {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-800 {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-blue-900 {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-50 {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-100 {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-200 {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-300 {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-400 {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-500 {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-600 {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-700 {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-800 {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-indigo-900 {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-50 {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-100 {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-200 {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-300 {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-400 {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-500 {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-600 {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-700 {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-800 {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-purple-900 {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-50 {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-100 {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-200 {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-300 {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-400 {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-500 {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-600 {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-700 {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-800 {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .group:focus .xl\:group-focus\:text-pink-900 {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .xl\:hover\:text-transparent:hover {
    color: #0000;
  }

  .xl\:hover\:text-white:hover {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .xl\:hover\:text-black:hover {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-50:hover {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-100:hover {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-200:hover {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-300:hover {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-400:hover {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-500:hover {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-600:hover {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-700:hover {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-800:hover {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .xl\:hover\:text-gray-900:hover {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-50:hover {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-100:hover {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-200:hover {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-300:hover {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-400:hover {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-500:hover {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-600:hover {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-700:hover {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-800:hover {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .xl\:hover\:text-cool-gray-900:hover {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .xl\:hover\:text-red-50:hover {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .xl\:hover\:text-red-100:hover {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .xl\:hover\:text-red-200:hover {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .xl\:hover\:text-red-300:hover {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .xl\:hover\:text-red-400:hover {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .xl\:hover\:text-red-500:hover {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .xl\:hover\:text-red-600:hover {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .xl\:hover\:text-red-700:hover {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .xl\:hover\:text-red-800:hover {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .xl\:hover\:text-red-900:hover {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-50:hover {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-100:hover {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-200:hover {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-300:hover {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-400:hover {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-500:hover {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-600:hover {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-700:hover {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-800:hover {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .xl\:hover\:text-orange-900:hover {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-50:hover {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-100:hover {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-200:hover {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-300:hover {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-400:hover {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-500:hover {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-600:hover {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-700:hover {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-800:hover {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .xl\:hover\:text-yellow-900:hover {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .xl\:hover\:text-green-50:hover {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .xl\:hover\:text-green-100:hover {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .xl\:hover\:text-green-200:hover {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .xl\:hover\:text-green-300:hover {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .xl\:hover\:text-green-400:hover {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .xl\:hover\:text-green-500:hover {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .xl\:hover\:text-green-600:hover {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .xl\:hover\:text-green-700:hover {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .xl\:hover\:text-green-800:hover {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .xl\:hover\:text-green-900:hover {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-50:hover {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-100:hover {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-200:hover {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-300:hover {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-400:hover {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-500:hover {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-600:hover {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-700:hover {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-800:hover {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .xl\:hover\:text-teal-900:hover {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-50:hover {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-100:hover {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-200:hover {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-300:hover {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-400:hover {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-500:hover {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-600:hover {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-700:hover {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-800:hover {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .xl\:hover\:text-blue-900:hover {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-50:hover {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-100:hover {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-200:hover {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-300:hover {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-400:hover {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-500:hover {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-600:hover {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-700:hover {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-800:hover {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .xl\:hover\:text-indigo-900:hover {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-50:hover {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-100:hover {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-200:hover {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-300:hover {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-400:hover {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-500:hover {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-600:hover {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-700:hover {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-800:hover {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .xl\:hover\:text-purple-900:hover {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-50:hover {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-100:hover {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-200:hover {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-300:hover {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-400:hover {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-500:hover {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-600:hover {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-700:hover {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-800:hover {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .xl\:hover\:text-pink-900:hover {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .xl\:focus-within\:text-transparent:focus-within {
    color: #0000;
  }

  .xl\:focus-within\:text-white:focus-within {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .xl\:focus-within\:text-black:focus-within {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-50:focus-within {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-100:focus-within {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-200:focus-within {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-300:focus-within {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-400:focus-within {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-500:focus-within {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-600:focus-within {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-700:focus-within {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-800:focus-within {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .xl\:focus-within\:text-gray-900:focus-within {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-50:focus-within {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-100:focus-within {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-200:focus-within {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-300:focus-within {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-400:focus-within {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-500:focus-within {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-600:focus-within {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-700:focus-within {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-800:focus-within {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .xl\:focus-within\:text-cool-gray-900:focus-within {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-100:focus-within {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-200:focus-within {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-300:focus-within {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-400:focus-within {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-500:focus-within {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-600:focus-within {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-700:focus-within {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-800:focus-within {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .xl\:focus-within\:text-red-900:focus-within {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-50:focus-within {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-100:focus-within {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-200:focus-within {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-300:focus-within {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-400:focus-within {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-500:focus-within {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-600:focus-within {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-700:focus-within {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-800:focus-within {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .xl\:focus-within\:text-orange-900:focus-within {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-50:focus-within {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-100:focus-within {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-200:focus-within {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-300:focus-within {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-400:focus-within {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-500:focus-within {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-600:focus-within {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-700:focus-within {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-800:focus-within {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .xl\:focus-within\:text-yellow-900:focus-within {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-50:focus-within {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-100:focus-within {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-200:focus-within {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-300:focus-within {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-400:focus-within {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-500:focus-within {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-600:focus-within {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-700:focus-within {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-800:focus-within {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .xl\:focus-within\:text-green-900:focus-within {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-50:focus-within {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-100:focus-within {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-200:focus-within {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-300:focus-within {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-400:focus-within {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-500:focus-within {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-600:focus-within {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-700:focus-within {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-800:focus-within {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .xl\:focus-within\:text-teal-900:focus-within {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-50:focus-within {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-100:focus-within {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-200:focus-within {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-300:focus-within {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-400:focus-within {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-500:focus-within {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-600:focus-within {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-700:focus-within {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-800:focus-within {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .xl\:focus-within\:text-blue-900:focus-within {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-50:focus-within {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-100:focus-within {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-200:focus-within {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-300:focus-within {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-400:focus-within {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-500:focus-within {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-600:focus-within {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-700:focus-within {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-800:focus-within {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .xl\:focus-within\:text-indigo-900:focus-within {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-50:focus-within {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-100:focus-within {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-200:focus-within {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-300:focus-within {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-400:focus-within {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-500:focus-within {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-600:focus-within {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-700:focus-within {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-800:focus-within {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .xl\:focus-within\:text-purple-900:focus-within {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-50:focus-within {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-100:focus-within {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-200:focus-within {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-300:focus-within {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-400:focus-within {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-500:focus-within {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-600:focus-within {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-700:focus-within {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-800:focus-within {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .xl\:focus-within\:text-pink-900:focus-within {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .xl\:focus\:text-transparent:focus {
    color: #0000;
  }

  .xl\:focus\:text-white:focus {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .xl\:focus\:text-black:focus {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-50:focus {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-100:focus {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-200:focus {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-300:focus {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-400:focus {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-500:focus {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-600:focus {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-700:focus {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-800:focus {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .xl\:focus\:text-gray-900:focus {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-50:focus {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-100:focus {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-200:focus {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-300:focus {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-400:focus {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-500:focus {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-600:focus {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-700:focus {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-800:focus {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .xl\:focus\:text-cool-gray-900:focus {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .xl\:focus\:text-red-50:focus {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .xl\:focus\:text-red-100:focus {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .xl\:focus\:text-red-200:focus {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .xl\:focus\:text-red-300:focus {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .xl\:focus\:text-red-400:focus {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .xl\:focus\:text-red-500:focus {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .xl\:focus\:text-red-600:focus {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .xl\:focus\:text-red-700:focus {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .xl\:focus\:text-red-800:focus {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .xl\:focus\:text-red-900:focus {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-50:focus {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-100:focus {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-200:focus {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-300:focus {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-400:focus {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-500:focus {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-600:focus {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-700:focus {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-800:focus {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .xl\:focus\:text-orange-900:focus {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-50:focus {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-100:focus {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-200:focus {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-300:focus {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-400:focus {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-500:focus {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-600:focus {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-700:focus {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-800:focus {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .xl\:focus\:text-yellow-900:focus {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .xl\:focus\:text-green-50:focus {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .xl\:focus\:text-green-100:focus {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .xl\:focus\:text-green-200:focus {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .xl\:focus\:text-green-300:focus {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .xl\:focus\:text-green-400:focus {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .xl\:focus\:text-green-500:focus {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .xl\:focus\:text-green-600:focus {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .xl\:focus\:text-green-700:focus {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .xl\:focus\:text-green-800:focus {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .xl\:focus\:text-green-900:focus {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-50:focus {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-100:focus {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-200:focus {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-300:focus {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-400:focus {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-500:focus {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-600:focus {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-700:focus {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-800:focus {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .xl\:focus\:text-teal-900:focus {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-50:focus {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-100:focus {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-200:focus {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-300:focus {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-400:focus {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-500:focus {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-600:focus {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-700:focus {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-800:focus {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .xl\:focus\:text-blue-900:focus {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-50:focus {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-100:focus {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-200:focus {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-300:focus {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-400:focus {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-500:focus {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-600:focus {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-700:focus {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-800:focus {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .xl\:focus\:text-indigo-900:focus {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-50:focus {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-100:focus {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-200:focus {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-300:focus {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-400:focus {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-500:focus {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-600:focus {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-700:focus {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-800:focus {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .xl\:focus\:text-purple-900:focus {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-50:focus {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-100:focus {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-200:focus {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-300:focus {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-400:focus {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-500:focus {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-600:focus {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-700:focus {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-800:focus {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .xl\:focus\:text-pink-900:focus {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .xl\:active\:text-transparent:active {
    color: #0000;
  }

  .xl\:active\:text-white:active {
    --text-opacity: 1;
    color: #fff;
    color: rgba(255, 255, 255, var(--text-opacity) );
  }

  .xl\:active\:text-black:active {
    --text-opacity: 1;
    color: #000;
    color: rgba(0, 0, 0, var(--text-opacity) );
  }

  .xl\:active\:text-gray-50:active {
    --text-opacity: 1;
    color: #f9fafb;
    color: rgba(249, 250, 251, var(--text-opacity) );
  }

  .xl\:active\:text-gray-100:active {
    --text-opacity: 1;
    color: #f4f5f7;
    color: rgba(244, 245, 247, var(--text-opacity) );
  }

  .xl\:active\:text-gray-200:active {
    --text-opacity: 1;
    color: #e5e7eb;
    color: rgba(229, 231, 235, var(--text-opacity) );
  }

  .xl\:active\:text-gray-300:active {
    --text-opacity: 1;
    color: #d2d6dc;
    color: rgba(210, 214, 220, var(--text-opacity) );
  }

  .xl\:active\:text-gray-400:active {
    --text-opacity: 1;
    color: #9fa6b2;
    color: rgba(159, 166, 178, var(--text-opacity) );
  }

  .xl\:active\:text-gray-500:active {
    --text-opacity: 1;
    color: #6b7280;
    color: rgba(107, 114, 128, var(--text-opacity) );
  }

  .xl\:active\:text-gray-600:active {
    --text-opacity: 1;
    color: #4b5563;
    color: rgba(75, 85, 99, var(--text-opacity) );
  }

  .xl\:active\:text-gray-700:active {
    --text-opacity: 1;
    color: #374151;
    color: rgba(55, 65, 81, var(--text-opacity) );
  }

  .xl\:active\:text-gray-800:active {
    --text-opacity: 1;
    color: #252f3f;
    color: rgba(37, 47, 63, var(--text-opacity) );
  }

  .xl\:active\:text-gray-900:active {
    --text-opacity: 1;
    color: #161e2e;
    color: rgba(22, 30, 46, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-50:active {
    --text-opacity: 1;
    color: #fbfdfe;
    color: rgba(251, 253, 254, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-100:active {
    --text-opacity: 1;
    color: #f1f5f9;
    color: rgba(241, 245, 249, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-200:active {
    --text-opacity: 1;
    color: #e2e8f0;
    color: rgba(226, 232, 240, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-300:active {
    --text-opacity: 1;
    color: #cfd8e3;
    color: rgba(207, 216, 227, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-400:active {
    --text-opacity: 1;
    color: #97a6ba;
    color: rgba(151, 166, 186, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-500:active {
    --text-opacity: 1;
    color: #64748b;
    color: rgba(100, 116, 139, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-600:active {
    --text-opacity: 1;
    color: #475569;
    color: rgba(71, 85, 105, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-700:active {
    --text-opacity: 1;
    color: #364152;
    color: rgba(54, 65, 82, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-800:active {
    --text-opacity: 1;
    color: #27303f;
    color: rgba(39, 48, 63, var(--text-opacity) );
  }

  .xl\:active\:text-cool-gray-900:active {
    --text-opacity: 1;
    color: #1a202e;
    color: rgba(26, 32, 46, var(--text-opacity) );
  }

  .xl\:active\:text-red-50:active {
    --text-opacity: 1;
    color: #fdf2f2;
    color: rgba(253, 242, 242, var(--text-opacity) );
  }

  .xl\:active\:text-red-100:active {
    --text-opacity: 1;
    color: #fde8e8;
    color: rgba(253, 232, 232, var(--text-opacity) );
  }

  .xl\:active\:text-red-200:active {
    --text-opacity: 1;
    color: #fbd5d5;
    color: rgba(251, 213, 213, var(--text-opacity) );
  }

  .xl\:active\:text-red-300:active {
    --text-opacity: 1;
    color: #f8b4b4;
    color: rgba(248, 180, 180, var(--text-opacity) );
  }

  .xl\:active\:text-red-400:active {
    --text-opacity: 1;
    color: #f98080;
    color: rgba(249, 128, 128, var(--text-opacity) );
  }

  .xl\:active\:text-red-500:active {
    --text-opacity: 1;
    color: #f05252;
    color: rgba(240, 82, 82, var(--text-opacity) );
  }

  .xl\:active\:text-red-600:active {
    --text-opacity: 1;
    color: #e02424;
    color: rgba(224, 36, 36, var(--text-opacity) );
  }

  .xl\:active\:text-red-700:active {
    --text-opacity: 1;
    color: #c81e1e;
    color: rgba(200, 30, 30, var(--text-opacity) );
  }

  .xl\:active\:text-red-800:active {
    --text-opacity: 1;
    color: #9b1c1c;
    color: rgba(155, 28, 28, var(--text-opacity) );
  }

  .xl\:active\:text-red-900:active {
    --text-opacity: 1;
    color: #771d1d;
    color: rgba(119, 29, 29, var(--text-opacity) );
  }

  .xl\:active\:text-orange-50:active {
    --text-opacity: 1;
    color: #fff8f1;
    color: rgba(255, 248, 241, var(--text-opacity) );
  }

  .xl\:active\:text-orange-100:active {
    --text-opacity: 1;
    color: #feecdc;
    color: rgba(254, 236, 220, var(--text-opacity) );
  }

  .xl\:active\:text-orange-200:active {
    --text-opacity: 1;
    color: #fcd9bd;
    color: rgba(252, 217, 189, var(--text-opacity) );
  }

  .xl\:active\:text-orange-300:active {
    --text-opacity: 1;
    color: #fdba8c;
    color: rgba(253, 186, 140, var(--text-opacity) );
  }

  .xl\:active\:text-orange-400:active {
    --text-opacity: 1;
    color: #ff8a4c;
    color: rgba(255, 138, 76, var(--text-opacity) );
  }

  .xl\:active\:text-orange-500:active {
    --text-opacity: 1;
    color: #ff5a1f;
    color: rgba(255, 90, 31, var(--text-opacity) );
  }

  .xl\:active\:text-orange-600:active {
    --text-opacity: 1;
    color: #d03801;
    color: rgba(208, 56, 1, var(--text-opacity) );
  }

  .xl\:active\:text-orange-700:active {
    --text-opacity: 1;
    color: #b43403;
    color: rgba(180, 52, 3, var(--text-opacity) );
  }

  .xl\:active\:text-orange-800:active {
    --text-opacity: 1;
    color: #8a2c0d;
    color: rgba(138, 44, 13, var(--text-opacity) );
  }

  .xl\:active\:text-orange-900:active {
    --text-opacity: 1;
    color: #73230d;
    color: rgba(115, 35, 13, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-50:active {
    --text-opacity: 1;
    color: #fdfdea;
    color: rgba(253, 253, 234, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-100:active {
    --text-opacity: 1;
    color: #fdf6b2;
    color: rgba(253, 246, 178, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-200:active {
    --text-opacity: 1;
    color: #fce96a;
    color: rgba(252, 233, 106, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-300:active {
    --text-opacity: 1;
    color: #faca15;
    color: rgba(250, 202, 21, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-400:active {
    --text-opacity: 1;
    color: #e3a008;
    color: rgba(227, 160, 8, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-500:active {
    --text-opacity: 1;
    color: #c27803;
    color: rgba(194, 120, 3, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-600:active {
    --text-opacity: 1;
    color: #9f580a;
    color: rgba(159, 88, 10, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-700:active {
    --text-opacity: 1;
    color: #8e4b10;
    color: rgba(142, 75, 16, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-800:active {
    --text-opacity: 1;
    color: #723b13;
    color: rgba(114, 59, 19, var(--text-opacity) );
  }

  .xl\:active\:text-yellow-900:active {
    --text-opacity: 1;
    color: #633112;
    color: rgba(99, 49, 18, var(--text-opacity) );
  }

  .xl\:active\:text-green-50:active {
    --text-opacity: 1;
    color: #f3faf7;
    color: rgba(243, 250, 247, var(--text-opacity) );
  }

  .xl\:active\:text-green-100:active {
    --text-opacity: 1;
    color: #def7ec;
    color: rgba(222, 247, 236, var(--text-opacity) );
  }

  .xl\:active\:text-green-200:active {
    --text-opacity: 1;
    color: #bcf0da;
    color: rgba(188, 240, 218, var(--text-opacity) );
  }

  .xl\:active\:text-green-300:active {
    --text-opacity: 1;
    color: #84e1bc;
    color: rgba(132, 225, 188, var(--text-opacity) );
  }

  .xl\:active\:text-green-400:active {
    --text-opacity: 1;
    color: #31c48d;
    color: rgba(49, 196, 141, var(--text-opacity) );
  }

  .xl\:active\:text-green-500:active {
    --text-opacity: 1;
    color: #0e9f6e;
    color: rgba(14, 159, 110, var(--text-opacity) );
  }

  .xl\:active\:text-green-600:active {
    --text-opacity: 1;
    color: #057a55;
    color: rgba(5, 122, 85, var(--text-opacity) );
  }

  .xl\:active\:text-green-700:active {
    --text-opacity: 1;
    color: #046c4e;
    color: rgba(4, 108, 78, var(--text-opacity) );
  }

  .xl\:active\:text-green-800:active {
    --text-opacity: 1;
    color: #03543f;
    color: rgba(3, 84, 63, var(--text-opacity) );
  }

  .xl\:active\:text-green-900:active {
    --text-opacity: 1;
    color: #014737;
    color: rgba(1, 71, 55, var(--text-opacity) );
  }

  .xl\:active\:text-teal-50:active {
    --text-opacity: 1;
    color: #edfafa;
    color: rgba(237, 250, 250, var(--text-opacity) );
  }

  .xl\:active\:text-teal-100:active {
    --text-opacity: 1;
    color: #d5f5f6;
    color: rgba(213, 245, 246, var(--text-opacity) );
  }

  .xl\:active\:text-teal-200:active {
    --text-opacity: 1;
    color: #afecef;
    color: rgba(175, 236, 239, var(--text-opacity) );
  }

  .xl\:active\:text-teal-300:active {
    --text-opacity: 1;
    color: #7edce2;
    color: rgba(126, 220, 226, var(--text-opacity) );
  }

  .xl\:active\:text-teal-400:active {
    --text-opacity: 1;
    color: #16bdca;
    color: rgba(22, 189, 202, var(--text-opacity) );
  }

  .xl\:active\:text-teal-500:active {
    --text-opacity: 1;
    color: #0694a2;
    color: rgba(6, 148, 162, var(--text-opacity) );
  }

  .xl\:active\:text-teal-600:active {
    --text-opacity: 1;
    color: #047481;
    color: rgba(4, 116, 129, var(--text-opacity) );
  }

  .xl\:active\:text-teal-700:active {
    --text-opacity: 1;
    color: #036672;
    color: rgba(3, 102, 114, var(--text-opacity) );
  }

  .xl\:active\:text-teal-800:active {
    --text-opacity: 1;
    color: #05505c;
    color: rgba(5, 80, 92, var(--text-opacity) );
  }

  .xl\:active\:text-teal-900:active {
    --text-opacity: 1;
    color: #014451;
    color: rgba(1, 68, 81, var(--text-opacity) );
  }

  .xl\:active\:text-blue-50:active {
    --text-opacity: 1;
    color: #ebf5ff;
    color: rgba(235, 245, 255, var(--text-opacity) );
  }

  .xl\:active\:text-blue-100:active {
    --text-opacity: 1;
    color: #e1effe;
    color: rgba(225, 239, 254, var(--text-opacity) );
  }

  .xl\:active\:text-blue-200:active {
    --text-opacity: 1;
    color: #c3ddfd;
    color: rgba(195, 221, 253, var(--text-opacity) );
  }

  .xl\:active\:text-blue-300:active {
    --text-opacity: 1;
    color: #a4cafe;
    color: rgba(164, 202, 254, var(--text-opacity) );
  }

  .xl\:active\:text-blue-400:active {
    --text-opacity: 1;
    color: #76a9fa;
    color: rgba(118, 169, 250, var(--text-opacity) );
  }

  .xl\:active\:text-blue-500:active {
    --text-opacity: 1;
    color: #3f83f8;
    color: rgba(63, 131, 248, var(--text-opacity) );
  }

  .xl\:active\:text-blue-600:active {
    --text-opacity: 1;
    color: #1c64f2;
    color: rgba(28, 100, 242, var(--text-opacity) );
  }

  .xl\:active\:text-blue-700:active {
    --text-opacity: 1;
    color: #1a56db;
    color: rgba(26, 86, 219, var(--text-opacity) );
  }

  .xl\:active\:text-blue-800:active {
    --text-opacity: 1;
    color: #1e429f;
    color: rgba(30, 66, 159, var(--text-opacity) );
  }

  .xl\:active\:text-blue-900:active {
    --text-opacity: 1;
    color: #233876;
    color: rgba(35, 56, 118, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-50:active {
    --text-opacity: 1;
    color: #f0f5ff;
    color: rgba(240, 245, 255, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-100:active {
    --text-opacity: 1;
    color: #e5edff;
    color: rgba(229, 237, 255, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-200:active {
    --text-opacity: 1;
    color: #cddbfe;
    color: rgba(205, 219, 254, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-300:active {
    --text-opacity: 1;
    color: #b4c6fc;
    color: rgba(180, 198, 252, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-400:active {
    --text-opacity: 1;
    color: #8da2fb;
    color: rgba(141, 162, 251, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-500:active {
    --text-opacity: 1;
    color: #6875f5;
    color: rgba(104, 117, 245, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-600:active {
    --text-opacity: 1;
    color: #5850ec;
    color: rgba(88, 80, 236, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-700:active {
    --text-opacity: 1;
    color: #5145cd;
    color: rgba(81, 69, 205, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-800:active {
    --text-opacity: 1;
    color: #42389d;
    color: rgba(66, 56, 157, var(--text-opacity) );
  }

  .xl\:active\:text-indigo-900:active {
    --text-opacity: 1;
    color: #362f78;
    color: rgba(54, 47, 120, var(--text-opacity) );
  }

  .xl\:active\:text-purple-50:active {
    --text-opacity: 1;
    color: #f6f5ff;
    color: rgba(246, 245, 255, var(--text-opacity) );
  }

  .xl\:active\:text-purple-100:active {
    --text-opacity: 1;
    color: #edebfe;
    color: rgba(237, 235, 254, var(--text-opacity) );
  }

  .xl\:active\:text-purple-200:active {
    --text-opacity: 1;
    color: #dcd7fe;
    color: rgba(220, 215, 254, var(--text-opacity) );
  }

  .xl\:active\:text-purple-300:active {
    --text-opacity: 1;
    color: #cabffd;
    color: rgba(202, 191, 253, var(--text-opacity) );
  }

  .xl\:active\:text-purple-400:active {
    --text-opacity: 1;
    color: #ac94fa;
    color: rgba(172, 148, 250, var(--text-opacity) );
  }

  .xl\:active\:text-purple-500:active {
    --text-opacity: 1;
    color: #9061f9;
    color: rgba(144, 97, 249, var(--text-opacity) );
  }

  .xl\:active\:text-purple-600:active {
    --text-opacity: 1;
    color: #7e3af2;
    color: rgba(126, 58, 242, var(--text-opacity) );
  }

  .xl\:active\:text-purple-700:active {
    --text-opacity: 1;
    color: #6c2bd9;
    color: rgba(108, 43, 217, var(--text-opacity) );
  }

  .xl\:active\:text-purple-800:active {
    --text-opacity: 1;
    color: #5521b5;
    color: rgba(85, 33, 181, var(--text-opacity) );
  }

  .xl\:active\:text-purple-900:active {
    --text-opacity: 1;
    color: #4a1d96;
    color: rgba(74, 29, 150, var(--text-opacity) );
  }

  .xl\:active\:text-pink-50:active {
    --text-opacity: 1;
    color: #fdf2f8;
    color: rgba(253, 242, 248, var(--text-opacity) );
  }

  .xl\:active\:text-pink-100:active {
    --text-opacity: 1;
    color: #fce8f3;
    color: rgba(252, 232, 243, var(--text-opacity) );
  }

  .xl\:active\:text-pink-200:active {
    --text-opacity: 1;
    color: #fad1e8;
    color: rgba(250, 209, 232, var(--text-opacity) );
  }

  .xl\:active\:text-pink-300:active {
    --text-opacity: 1;
    color: #f8b4d9;
    color: rgba(248, 180, 217, var(--text-opacity) );
  }

  .xl\:active\:text-pink-400:active {
    --text-opacity: 1;
    color: #f17eb8;
    color: rgba(241, 126, 184, var(--text-opacity) );
  }

  .xl\:active\:text-pink-500:active {
    --text-opacity: 1;
    color: #e74694;
    color: rgba(231, 70, 148, var(--text-opacity) );
  }

  .xl\:active\:text-pink-600:active {
    --text-opacity: 1;
    color: #d61f69;
    color: rgba(214, 31, 105, var(--text-opacity) );
  }

  .xl\:active\:text-pink-700:active {
    --text-opacity: 1;
    color: #bf125d;
    color: rgba(191, 18, 93, var(--text-opacity) );
  }

  .xl\:active\:text-pink-800:active {
    --text-opacity: 1;
    color: #99154b;
    color: rgba(153, 21, 75, var(--text-opacity) );
  }

  .xl\:active\:text-pink-900:active {
    --text-opacity: 1;
    color: #751a3d;
    color: rgba(117, 26, 61, var(--text-opacity) );
  }

  .xl\:text-opacity-0 {
    --text-opacity: 0;
  }

  .xl\:text-opacity-25 {
    --text-opacity: .25;
  }

  .xl\:text-opacity-50 {
    --text-opacity: .5;
  }

  .xl\:text-opacity-75 {
    --text-opacity: .75;
  }

  .xl\:text-opacity-100 {
    --text-opacity: 1;
  }

  .xl\:hover\:text-opacity-0:hover {
    --text-opacity: 0;
  }

  .xl\:hover\:text-opacity-25:hover {
    --text-opacity: .25;
  }

  .xl\:hover\:text-opacity-50:hover {
    --text-opacity: .5;
  }

  .xl\:hover\:text-opacity-75:hover {
    --text-opacity: .75;
  }

  .xl\:hover\:text-opacity-100:hover {
    --text-opacity: 1;
  }

  .xl\:focus\:text-opacity-0:focus {
    --text-opacity: 0;
  }

  .xl\:focus\:text-opacity-25:focus {
    --text-opacity: .25;
  }

  .xl\:focus\:text-opacity-50:focus {
    --text-opacity: .5;
  }

  .xl\:focus\:text-opacity-75:focus {
    --text-opacity: .75;
  }

  .xl\:focus\:text-opacity-100:focus {
    --text-opacity: 1;
  }

  .xl\:italic {
    font-style: italic;
  }

  .xl\:not-italic {
    font-style: normal;
  }

  .xl\:uppercase {
    text-transform: uppercase;
  }

  .xl\:lowercase {
    text-transform: lowercase;
  }

  .xl\:capitalize {
    text-transform: capitalize;
  }

  .xl\:normal-case {
    text-transform: none;
  }

  .xl\:underline {
    text-decoration: underline;
  }

  .xl\:line-through {
    text-decoration: line-through;
  }

  .xl\:no-underline {
    text-decoration: none;
  }

  .group:hover .xl\:group-hover\:underline {
    text-decoration: underline;
  }

  .group:hover .xl\:group-hover\:line-through {
    text-decoration: line-through;
  }

  .group:hover .xl\:group-hover\:no-underline {
    text-decoration: none;
  }

  .group:focus .xl\:group-focus\:underline {
    text-decoration: underline;
  }

  .group:focus .xl\:group-focus\:line-through {
    text-decoration: line-through;
  }

  .group:focus .xl\:group-focus\:no-underline {
    text-decoration: none;
  }

  .xl\:hover\:underline:hover {
    text-decoration: underline;
  }

  .xl\:hover\:line-through:hover {
    text-decoration: line-through;
  }

  .xl\:hover\:no-underline:hover {
    text-decoration: none;
  }

  .xl\:focus\:underline:focus {
    text-decoration: underline;
  }

  .xl\:focus\:line-through:focus {
    text-decoration: line-through;
  }

  .xl\:focus\:no-underline:focus {
    text-decoration: none;
  }

  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }

  .xl\:ordinal, .xl\:slashed-zero, .xl\:lining-nums, .xl\:oldstyle-nums, .xl\:proportional-nums, .xl\:tabular-nums, .xl\:diagonal-fractions, .xl\:stacked-fractions {
    --font-variant-numeric-ordinal: var(--tailwind-empty, );
    --font-variant-numeric-slashed-zero: var(--tailwind-empty, );
    --font-variant-numeric-figure: var(--tailwind-empty, );
    --font-variant-numeric-spacing: var(--tailwind-empty, );
    --font-variant-numeric-fraction: var(--tailwind-empty, );
    font-variant-numeric: var(--font-variant-numeric-ordinal) var(--font-variant-numeric-slashed-zero) var(--font-variant-numeric-figure) var(--font-variant-numeric-spacing) var(--font-variant-numeric-fraction);
  }

  .xl\:normal-nums {
    font-variant-numeric: normal;
  }

  .xl\:ordinal {
    --font-variant-numeric-ordinal: ordinal;
  }

  .xl\:slashed-zero {
    --font-variant-numeric-slashed-zero: slashed-zero;
  }

  .xl\:lining-nums {
    --font-variant-numeric-figure: lining-nums;
  }

  .xl\:oldstyle-nums {
    --font-variant-numeric-figure: oldstyle-nums;
  }

  .xl\:proportional-nums {
    --font-variant-numeric-spacing: proportional-nums;
  }

  .xl\:tabular-nums {
    --font-variant-numeric-spacing: tabular-nums;
  }

  .xl\:diagonal-fractions {
    --font-variant-numeric-fraction: diagonal-fractions;
  }

  .xl\:stacked-fractions {
    --font-variant-numeric-fraction: stacked-fractions;
  }

  .xl\:tracking-tighter {
    letter-spacing: -.05em;
  }

  .xl\:tracking-tight {
    letter-spacing: -.025em;
  }

  .xl\:tracking-normal {
    letter-spacing: 0;
  }

  .xl\:tracking-wide {
    letter-spacing: .025em;
  }

  .xl\:tracking-wider {
    letter-spacing: .05em;
  }

  .xl\:tracking-widest {
    letter-spacing: .1em;
  }

  .xl\:select-none {
    user-select: none;
  }

  .xl\:select-text {
    user-select: text;
  }

  .xl\:select-all {
    user-select: all;
  }

  .xl\:select-auto {
    user-select: auto;
  }

  .xl\:align-baseline {
    vertical-align: baseline;
  }

  .xl\:align-top {
    vertical-align: top;
  }

  .xl\:align-middle {
    vertical-align: middle;
  }

  .xl\:align-bottom {
    vertical-align: bottom;
  }

  .xl\:align-text-top {
    vertical-align: text-top;
  }

  .xl\:align-text-bottom {
    vertical-align: text-bottom;
  }

  .xl\:visible {
    visibility: visible;
  }

  .xl\:invisible {
    visibility: hidden;
  }

  .xl\:whitespace-normal {
    white-space: normal;
  }

  .xl\:whitespace-no-wrap {
    white-space: nowrap;
  }

  .xl\:whitespace-pre {
    white-space: pre;
  }

  .xl\:whitespace-pre-line {
    white-space: pre-line;
  }

  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }

  .xl\:break-normal {
    word-wrap: normal;
    overflow-wrap: normal;
    word-break: normal;
  }

  .xl\:break-words {
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .xl\:break-all {
    word-break: break-all;
  }

  .xl\:truncate {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .xl\:w-0 {
    width: 0;
  }

  .xl\:w-1 {
    width: .25rem;
  }

  .xl\:w-2 {
    width: .5rem;
  }

  .xl\:w-3 {
    width: .75rem;
  }

  .xl\:w-4 {
    width: 1rem;
  }

  .xl\:w-5 {
    width: 1.25rem;
  }

  .xl\:w-6 {
    width: 1.5rem;
  }

  .xl\:w-7 {
    width: 1.75rem;
  }

  .xl\:w-8 {
    width: 2rem;
  }

  .xl\:w-9 {
    width: 2.25rem;
  }

  .xl\:w-10 {
    width: 2.5rem;
  }

  .xl\:w-11 {
    width: 2.75rem;
  }

  .xl\:w-12 {
    width: 3rem;
  }

  .xl\:w-13 {
    width: 3.25rem;
  }

  .xl\:w-14 {
    width: 3.5rem;
  }

  .xl\:w-15 {
    width: 3.75rem;
  }

  .xl\:w-16 {
    width: 4rem;
  }

  .xl\:w-20 {
    width: 5rem;
  }

  .xl\:w-24 {
    width: 6rem;
  }

  .xl\:w-28 {
    width: 7rem;
  }

  .xl\:w-32 {
    width: 8rem;
  }

  .xl\:w-36 {
    width: 9rem;
  }

  .xl\:w-40 {
    width: 10rem;
  }

  .xl\:w-44 {
    width: 11rem;
  }

  .xl\:w-48 {
    width: 12rem;
  }

  .xl\:w-52 {
    width: 13rem;
  }

  .xl\:w-56 {
    width: 14rem;
  }

  .xl\:w-60 {
    width: 15rem;
  }

  .xl\:w-64 {
    width: 16rem;
  }

  .xl\:w-72 {
    width: 18rem;
  }

  .xl\:w-80 {
    width: 20rem;
  }

  .xl\:w-96 {
    width: 24rem;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:w-px {
    width: 1px;
  }

  .xl\:w-0\.5 {
    width: .125rem;
  }

  .xl\:w-1\.5 {
    width: .375rem;
  }

  .xl\:w-2\.5 {
    width: .625rem;
  }

  .xl\:w-3\.5 {
    width: .875rem;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:w-2\/3 {
    width: 66.6667%;
  }

  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:w-2\/4 {
    width: 50%;
  }

  .xl\:w-3\/4 {
    width: 75%;
  }

  .xl\:w-1\/5 {
    width: 20%;
  }

  .xl\:w-2\/5 {
    width: 40%;
  }

  .xl\:w-3\/5 {
    width: 60%;
  }

  .xl\:w-4\/5 {
    width: 80%;
  }

  .xl\:w-1\/6 {
    width: 16.6667%;
  }

  .xl\:w-2\/6 {
    width: 33.3333%;
  }

  .xl\:w-3\/6 {
    width: 50%;
  }

  .xl\:w-4\/6 {
    width: 66.6667%;
  }

  .xl\:w-5\/6 {
    width: 83.3333%;
  }

  .xl\:w-1\/12 {
    width: 8.33333%;
  }

  .xl\:w-2\/12 {
    width: 16.6667%;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-4\/12 {
    width: 33.3333%;
  }

  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-7\/12 {
    width: 58.3333%;
  }

  .xl\:w-8\/12 {
    width: 66.6667%;
  }

  .xl\:w-9\/12 {
    width: 75%;
  }

  .xl\:w-10\/12 {
    width: 83.3333%;
  }

  .xl\:w-11\/12 {
    width: 91.6667%;
  }

  .xl\:w-full {
    width: 100%;
  }

  .xl\:w-screen {
    width: 100vw;
  }

  .xl\:w-min-content {
    width: min-content;
  }

  .xl\:w-max-content {
    width: max-content;
  }

  .xl\:z-0 {
    z-index: 0;
  }

  .xl\:z-10 {
    z-index: 10;
  }

  .xl\:z-20 {
    z-index: 20;
  }

  .xl\:z-30 {
    z-index: 30;
  }

  .xl\:z-40 {
    z-index: 40;
  }

  .xl\:z-50 {
    z-index: 50;
  }

  .xl\:z-auto {
    z-index: auto;
  }

  .xl\:focus-within\:z-0:focus-within {
    z-index: 0;
  }

  .xl\:focus-within\:z-10:focus-within {
    z-index: 10;
  }

  .xl\:focus-within\:z-20:focus-within {
    z-index: 20;
  }

  .xl\:focus-within\:z-30:focus-within {
    z-index: 30;
  }

  .xl\:focus-within\:z-40:focus-within {
    z-index: 40;
  }

  .xl\:focus-within\:z-50:focus-within {
    z-index: 50;
  }

  .xl\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }

  .xl\:focus\:z-0:focus {
    z-index: 0;
  }

  .xl\:focus\:z-10:focus {
    z-index: 10;
  }

  .xl\:focus\:z-20:focus {
    z-index: 20;
  }

  .xl\:focus\:z-30:focus {
    z-index: 30;
  }

  .xl\:focus\:z-40:focus {
    z-index: 40;
  }

  .xl\:focus\:z-50:focus {
    z-index: 50;
  }

  .xl\:focus\:z-auto:focus {
    z-index: auto;
  }

  .xl\:gap-0 {
    grid-gap: 0;
    gap: 0;
  }

  .xl\:gap-1 {
    grid-gap: .25rem;
    gap: .25rem;
  }

  .xl\:gap-2 {
    grid-gap: .5rem;
    gap: .5rem;
  }

  .xl\:gap-3 {
    grid-gap: .75rem;
    gap: .75rem;
  }

  .xl\:gap-4 {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .xl\:gap-5 {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }

  .xl\:gap-6 {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .xl\:gap-7 {
    grid-gap: 1.75rem;
    gap: 1.75rem;
  }

  .xl\:gap-8 {
    grid-gap: 2rem;
    gap: 2rem;
  }

  .xl\:gap-9 {
    grid-gap: 2.25rem;
    gap: 2.25rem;
  }

  .xl\:gap-10 {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }

  .xl\:gap-11 {
    grid-gap: 2.75rem;
    gap: 2.75rem;
  }

  .xl\:gap-12 {
    grid-gap: 3rem;
    gap: 3rem;
  }

  .xl\:gap-13 {
    grid-gap: 3.25rem;
    gap: 3.25rem;
  }

  .xl\:gap-14 {
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }

  .xl\:gap-15 {
    grid-gap: 3.75rem;
    gap: 3.75rem;
  }

  .xl\:gap-16 {
    grid-gap: 4rem;
    gap: 4rem;
  }

  .xl\:gap-20 {
    grid-gap: 5rem;
    gap: 5rem;
  }

  .xl\:gap-24 {
    grid-gap: 6rem;
    gap: 6rem;
  }

  .xl\:gap-28 {
    grid-gap: 7rem;
    gap: 7rem;
  }

  .xl\:gap-32 {
    grid-gap: 8rem;
    gap: 8rem;
  }

  .xl\:gap-36 {
    grid-gap: 9rem;
    gap: 9rem;
  }

  .xl\:gap-40 {
    grid-gap: 10rem;
    gap: 10rem;
  }

  .xl\:gap-44 {
    grid-gap: 11rem;
    gap: 11rem;
  }

  .xl\:gap-48 {
    grid-gap: 12rem;
    gap: 12rem;
  }

  .xl\:gap-52 {
    grid-gap: 13rem;
    gap: 13rem;
  }

  .xl\:gap-56 {
    grid-gap: 14rem;
    gap: 14rem;
  }

  .xl\:gap-60 {
    grid-gap: 15rem;
    gap: 15rem;
  }

  .xl\:gap-64 {
    grid-gap: 16rem;
    gap: 16rem;
  }

  .xl\:gap-72 {
    grid-gap: 18rem;
    gap: 18rem;
  }

  .xl\:gap-80 {
    grid-gap: 20rem;
    gap: 20rem;
  }

  .xl\:gap-96 {
    grid-gap: 24rem;
    gap: 24rem;
  }

  .xl\:gap-px {
    grid-gap: 1px;
    gap: 1px;
  }

  .xl\:gap-0\.5 {
    grid-gap: .125rem;
    gap: .125rem;
  }

  .xl\:gap-1\.5 {
    grid-gap: .375rem;
    gap: .375rem;
  }

  .xl\:gap-2\.5 {
    grid-gap: .625rem;
    gap: .625rem;
  }

  .xl\:gap-3\.5 {
    grid-gap: .875rem;
    gap: .875rem;
  }

  .xl\:gap-1\/2 {
    grid-gap: 50%;
    gap: 50%;
  }

  .xl\:gap-1\/3 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .xl\:gap-2\/3 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .xl\:gap-1\/4 {
    grid-gap: 25%;
    gap: 25%;
  }

  .xl\:gap-2\/4 {
    grid-gap: 50%;
    gap: 50%;
  }

  .xl\:gap-3\/4 {
    grid-gap: 75%;
    gap: 75%;
  }

  .xl\:gap-1\/5 {
    grid-gap: 20%;
    gap: 20%;
  }

  .xl\:gap-2\/5 {
    grid-gap: 40%;
    gap: 40%;
  }

  .xl\:gap-3\/5 {
    grid-gap: 60%;
    gap: 60%;
  }

  .xl\:gap-4\/5 {
    grid-gap: 80%;
    gap: 80%;
  }

  .xl\:gap-1\/6 {
    grid-gap: 16.6667%;
    gap: 16.6667%;
  }

  .xl\:gap-2\/6 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .xl\:gap-3\/6 {
    grid-gap: 50%;
    gap: 50%;
  }

  .xl\:gap-4\/6 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .xl\:gap-5\/6 {
    grid-gap: 83.3333%;
    gap: 83.3333%;
  }

  .xl\:gap-1\/12 {
    grid-gap: 8.33333%;
    gap: 8.33333%;
  }

  .xl\:gap-2\/12 {
    grid-gap: 16.6667%;
    gap: 16.6667%;
  }

  .xl\:gap-3\/12 {
    grid-gap: 25%;
    gap: 25%;
  }

  .xl\:gap-4\/12 {
    grid-gap: 33.3333%;
    gap: 33.3333%;
  }

  .xl\:gap-5\/12 {
    grid-gap: 41.6667%;
    gap: 41.6667%;
  }

  .xl\:gap-6\/12 {
    grid-gap: 50%;
    gap: 50%;
  }

  .xl\:gap-7\/12 {
    grid-gap: 58.3333%;
    gap: 58.3333%;
  }

  .xl\:gap-8\/12 {
    grid-gap: 66.6667%;
    gap: 66.6667%;
  }

  .xl\:gap-9\/12 {
    grid-gap: 75%;
    gap: 75%;
  }

  .xl\:gap-10\/12 {
    grid-gap: 83.3333%;
    gap: 83.3333%;
  }

  .xl\:gap-11\/12 {
    grid-gap: 91.6667%;
    gap: 91.6667%;
  }

  .xl\:gap-full {
    grid-gap: 100%;
    gap: 100%;
  }

  .xl\:col-gap-0 {
    grid-column-gap: 0;
    column-gap: 0;
  }

  .xl\:col-gap-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem;
  }

  .xl\:col-gap-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem;
  }

  .xl\:col-gap-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem;
  }

  .xl\:col-gap-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }

  .xl\:col-gap-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .xl\:col-gap-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .xl\:col-gap-7 {
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }

  .xl\:col-gap-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }

  .xl\:col-gap-9 {
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }

  .xl\:col-gap-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .xl\:col-gap-11 {
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }

  .xl\:col-gap-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }

  .xl\:col-gap-13 {
    grid-column-gap: 3.25rem;
    column-gap: 3.25rem;
  }

  .xl\:col-gap-14 {
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }

  .xl\:col-gap-15 {
    grid-column-gap: 3.75rem;
    column-gap: 3.75rem;
  }

  .xl\:col-gap-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }

  .xl\:col-gap-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }

  .xl\:col-gap-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }

  .xl\:col-gap-28 {
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }

  .xl\:col-gap-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }

  .xl\:col-gap-36 {
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }

  .xl\:col-gap-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }

  .xl\:col-gap-44 {
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }

  .xl\:col-gap-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }

  .xl\:col-gap-52 {
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }

  .xl\:col-gap-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }

  .xl\:col-gap-60 {
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }

  .xl\:col-gap-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }

  .xl\:col-gap-72 {
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }

  .xl\:col-gap-80 {
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }

  .xl\:col-gap-96 {
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }

  .xl\:col-gap-px {
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  .xl\:col-gap-0\.5 {
    grid-column-gap: .125rem;
    column-gap: .125rem;
  }

  .xl\:col-gap-1\.5 {
    grid-column-gap: .375rem;
    column-gap: .375rem;
  }

  .xl\:col-gap-2\.5 {
    grid-column-gap: .625rem;
    column-gap: .625rem;
  }

  .xl\:col-gap-3\.5 {
    grid-column-gap: .875rem;
    column-gap: .875rem;
  }

  .xl\:col-gap-1\/2 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .xl\:col-gap-1\/3 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .xl\:col-gap-2\/3 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .xl\:col-gap-1\/4 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .xl\:col-gap-2\/4 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .xl\:col-gap-3\/4 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .xl\:col-gap-1\/5 {
    grid-column-gap: 20%;
    column-gap: 20%;
  }

  .xl\:col-gap-2\/5 {
    grid-column-gap: 40%;
    column-gap: 40%;
  }

  .xl\:col-gap-3\/5 {
    grid-column-gap: 60%;
    column-gap: 60%;
  }

  .xl\:col-gap-4\/5 {
    grid-column-gap: 80%;
    column-gap: 80%;
  }

  .xl\:col-gap-1\/6 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .xl\:col-gap-2\/6 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .xl\:col-gap-3\/6 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .xl\:col-gap-4\/6 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .xl\:col-gap-5\/6 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .xl\:col-gap-1\/12 {
    grid-column-gap: 8.33333%;
    column-gap: 8.33333%;
  }

  .xl\:col-gap-2\/12 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .xl\:col-gap-3\/12 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .xl\:col-gap-4\/12 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .xl\:col-gap-5\/12 {
    grid-column-gap: 41.6667%;
    column-gap: 41.6667%;
  }

  .xl\:col-gap-6\/12 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .xl\:col-gap-7\/12 {
    grid-column-gap: 58.3333%;
    column-gap: 58.3333%;
  }

  .xl\:col-gap-8\/12 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .xl\:col-gap-9\/12 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .xl\:col-gap-10\/12 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .xl\:col-gap-11\/12 {
    grid-column-gap: 91.6667%;
    column-gap: 91.6667%;
  }

  .xl\:col-gap-full {
    grid-column-gap: 100%;
    column-gap: 100%;
  }

  .xl\:gap-x-0 {
    grid-column-gap: 0;
    column-gap: 0;
  }

  .xl\:gap-x-1 {
    grid-column-gap: .25rem;
    column-gap: .25rem;
  }

  .xl\:gap-x-2 {
    grid-column-gap: .5rem;
    column-gap: .5rem;
  }

  .xl\:gap-x-3 {
    grid-column-gap: .75rem;
    column-gap: .75rem;
  }

  .xl\:gap-x-4 {
    grid-column-gap: 1rem;
    column-gap: 1rem;
  }

  .xl\:gap-x-5 {
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
  }

  .xl\:gap-x-6 {
    grid-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }

  .xl\:gap-x-7 {
    grid-column-gap: 1.75rem;
    column-gap: 1.75rem;
  }

  .xl\:gap-x-8 {
    grid-column-gap: 2rem;
    column-gap: 2rem;
  }

  .xl\:gap-x-9 {
    grid-column-gap: 2.25rem;
    column-gap: 2.25rem;
  }

  .xl\:gap-x-10 {
    grid-column-gap: 2.5rem;
    column-gap: 2.5rem;
  }

  .xl\:gap-x-11 {
    grid-column-gap: 2.75rem;
    column-gap: 2.75rem;
  }

  .xl\:gap-x-12 {
    grid-column-gap: 3rem;
    column-gap: 3rem;
  }

  .xl\:gap-x-13 {
    grid-column-gap: 3.25rem;
    column-gap: 3.25rem;
  }

  .xl\:gap-x-14 {
    grid-column-gap: 3.5rem;
    column-gap: 3.5rem;
  }

  .xl\:gap-x-15 {
    grid-column-gap: 3.75rem;
    column-gap: 3.75rem;
  }

  .xl\:gap-x-16 {
    grid-column-gap: 4rem;
    column-gap: 4rem;
  }

  .xl\:gap-x-20 {
    grid-column-gap: 5rem;
    column-gap: 5rem;
  }

  .xl\:gap-x-24 {
    grid-column-gap: 6rem;
    column-gap: 6rem;
  }

  .xl\:gap-x-28 {
    grid-column-gap: 7rem;
    column-gap: 7rem;
  }

  .xl\:gap-x-32 {
    grid-column-gap: 8rem;
    column-gap: 8rem;
  }

  .xl\:gap-x-36 {
    grid-column-gap: 9rem;
    column-gap: 9rem;
  }

  .xl\:gap-x-40 {
    grid-column-gap: 10rem;
    column-gap: 10rem;
  }

  .xl\:gap-x-44 {
    grid-column-gap: 11rem;
    column-gap: 11rem;
  }

  .xl\:gap-x-48 {
    grid-column-gap: 12rem;
    column-gap: 12rem;
  }

  .xl\:gap-x-52 {
    grid-column-gap: 13rem;
    column-gap: 13rem;
  }

  .xl\:gap-x-56 {
    grid-column-gap: 14rem;
    column-gap: 14rem;
  }

  .xl\:gap-x-60 {
    grid-column-gap: 15rem;
    column-gap: 15rem;
  }

  .xl\:gap-x-64 {
    grid-column-gap: 16rem;
    column-gap: 16rem;
  }

  .xl\:gap-x-72 {
    grid-column-gap: 18rem;
    column-gap: 18rem;
  }

  .xl\:gap-x-80 {
    grid-column-gap: 20rem;
    column-gap: 20rem;
  }

  .xl\:gap-x-96 {
    grid-column-gap: 24rem;
    column-gap: 24rem;
  }

  .xl\:gap-x-px {
    grid-column-gap: 1px;
    column-gap: 1px;
  }

  .xl\:gap-x-0\.5 {
    grid-column-gap: .125rem;
    column-gap: .125rem;
  }

  .xl\:gap-x-1\.5 {
    grid-column-gap: .375rem;
    column-gap: .375rem;
  }

  .xl\:gap-x-2\.5 {
    grid-column-gap: .625rem;
    column-gap: .625rem;
  }

  .xl\:gap-x-3\.5 {
    grid-column-gap: .875rem;
    column-gap: .875rem;
  }

  .xl\:gap-x-1\/2 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .xl\:gap-x-1\/3 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .xl\:gap-x-2\/3 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .xl\:gap-x-1\/4 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .xl\:gap-x-2\/4 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .xl\:gap-x-3\/4 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .xl\:gap-x-1\/5 {
    grid-column-gap: 20%;
    column-gap: 20%;
  }

  .xl\:gap-x-2\/5 {
    grid-column-gap: 40%;
    column-gap: 40%;
  }

  .xl\:gap-x-3\/5 {
    grid-column-gap: 60%;
    column-gap: 60%;
  }

  .xl\:gap-x-4\/5 {
    grid-column-gap: 80%;
    column-gap: 80%;
  }

  .xl\:gap-x-1\/6 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .xl\:gap-x-2\/6 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .xl\:gap-x-3\/6 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .xl\:gap-x-4\/6 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .xl\:gap-x-5\/6 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .xl\:gap-x-1\/12 {
    grid-column-gap: 8.33333%;
    column-gap: 8.33333%;
  }

  .xl\:gap-x-2\/12 {
    grid-column-gap: 16.6667%;
    column-gap: 16.6667%;
  }

  .xl\:gap-x-3\/12 {
    grid-column-gap: 25%;
    column-gap: 25%;
  }

  .xl\:gap-x-4\/12 {
    grid-column-gap: 33.3333%;
    column-gap: 33.3333%;
  }

  .xl\:gap-x-5\/12 {
    grid-column-gap: 41.6667%;
    column-gap: 41.6667%;
  }

  .xl\:gap-x-6\/12 {
    grid-column-gap: 50%;
    column-gap: 50%;
  }

  .xl\:gap-x-7\/12 {
    grid-column-gap: 58.3333%;
    column-gap: 58.3333%;
  }

  .xl\:gap-x-8\/12 {
    grid-column-gap: 66.6667%;
    column-gap: 66.6667%;
  }

  .xl\:gap-x-9\/12 {
    grid-column-gap: 75%;
    column-gap: 75%;
  }

  .xl\:gap-x-10\/12 {
    grid-column-gap: 83.3333%;
    column-gap: 83.3333%;
  }

  .xl\:gap-x-11\/12 {
    grid-column-gap: 91.6667%;
    column-gap: 91.6667%;
  }

  .xl\:gap-x-full {
    grid-column-gap: 100%;
    column-gap: 100%;
  }

  .xl\:row-gap-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .xl\:row-gap-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem;
  }

  .xl\:row-gap-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }

  .xl\:row-gap-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem;
  }

  .xl\:row-gap-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .xl\:row-gap-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .xl\:row-gap-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .xl\:row-gap-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }

  .xl\:row-gap-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .xl\:row-gap-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }

  .xl\:row-gap-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .xl\:row-gap-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }

  .xl\:row-gap-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .xl\:row-gap-13 {
    grid-row-gap: 3.25rem;
    row-gap: 3.25rem;
  }

  .xl\:row-gap-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  .xl\:row-gap-15 {
    grid-row-gap: 3.75rem;
    row-gap: 3.75rem;
  }

  .xl\:row-gap-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .xl\:row-gap-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .xl\:row-gap-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .xl\:row-gap-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }

  .xl\:row-gap-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .xl\:row-gap-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }

  .xl\:row-gap-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .xl\:row-gap-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }

  .xl\:row-gap-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .xl\:row-gap-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }

  .xl\:row-gap-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .xl\:row-gap-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }

  .xl\:row-gap-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .xl\:row-gap-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }

  .xl\:row-gap-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }

  .xl\:row-gap-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }

  .xl\:row-gap-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .xl\:row-gap-0\.5 {
    grid-row-gap: .125rem;
    row-gap: .125rem;
  }

  .xl\:row-gap-1\.5 {
    grid-row-gap: .375rem;
    row-gap: .375rem;
  }

  .xl\:row-gap-2\.5 {
    grid-row-gap: .625rem;
    row-gap: .625rem;
  }

  .xl\:row-gap-3\.5 {
    grid-row-gap: .875rem;
    row-gap: .875rem;
  }

  .xl\:row-gap-1\/2 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .xl\:row-gap-1\/3 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .xl\:row-gap-2\/3 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .xl\:row-gap-1\/4 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .xl\:row-gap-2\/4 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .xl\:row-gap-3\/4 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .xl\:row-gap-1\/5 {
    grid-row-gap: 20%;
    row-gap: 20%;
  }

  .xl\:row-gap-2\/5 {
    grid-row-gap: 40%;
    row-gap: 40%;
  }

  .xl\:row-gap-3\/5 {
    grid-row-gap: 60%;
    row-gap: 60%;
  }

  .xl\:row-gap-4\/5 {
    grid-row-gap: 80%;
    row-gap: 80%;
  }

  .xl\:row-gap-1\/6 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .xl\:row-gap-2\/6 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .xl\:row-gap-3\/6 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .xl\:row-gap-4\/6 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .xl\:row-gap-5\/6 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .xl\:row-gap-1\/12 {
    grid-row-gap: 8.33333%;
    row-gap: 8.33333%;
  }

  .xl\:row-gap-2\/12 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .xl\:row-gap-3\/12 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .xl\:row-gap-4\/12 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .xl\:row-gap-5\/12 {
    grid-row-gap: 41.6667%;
    row-gap: 41.6667%;
  }

  .xl\:row-gap-6\/12 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .xl\:row-gap-7\/12 {
    grid-row-gap: 58.3333%;
    row-gap: 58.3333%;
  }

  .xl\:row-gap-8\/12 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .xl\:row-gap-9\/12 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .xl\:row-gap-10\/12 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .xl\:row-gap-11\/12 {
    grid-row-gap: 91.6667%;
    row-gap: 91.6667%;
  }

  .xl\:row-gap-full {
    grid-row-gap: 100%;
    row-gap: 100%;
  }

  .xl\:gap-y-0 {
    grid-row-gap: 0;
    row-gap: 0;
  }

  .xl\:gap-y-1 {
    grid-row-gap: .25rem;
    row-gap: .25rem;
  }

  .xl\:gap-y-2 {
    grid-row-gap: .5rem;
    row-gap: .5rem;
  }

  .xl\:gap-y-3 {
    grid-row-gap: .75rem;
    row-gap: .75rem;
  }

  .xl\:gap-y-4 {
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .xl\:gap-y-5 {
    grid-row-gap: 1.25rem;
    row-gap: 1.25rem;
  }

  .xl\:gap-y-6 {
    grid-row-gap: 1.5rem;
    row-gap: 1.5rem;
  }

  .xl\:gap-y-7 {
    grid-row-gap: 1.75rem;
    row-gap: 1.75rem;
  }

  .xl\:gap-y-8 {
    grid-row-gap: 2rem;
    row-gap: 2rem;
  }

  .xl\:gap-y-9 {
    grid-row-gap: 2.25rem;
    row-gap: 2.25rem;
  }

  .xl\:gap-y-10 {
    grid-row-gap: 2.5rem;
    row-gap: 2.5rem;
  }

  .xl\:gap-y-11 {
    grid-row-gap: 2.75rem;
    row-gap: 2.75rem;
  }

  .xl\:gap-y-12 {
    grid-row-gap: 3rem;
    row-gap: 3rem;
  }

  .xl\:gap-y-13 {
    grid-row-gap: 3.25rem;
    row-gap: 3.25rem;
  }

  .xl\:gap-y-14 {
    grid-row-gap: 3.5rem;
    row-gap: 3.5rem;
  }

  .xl\:gap-y-15 {
    grid-row-gap: 3.75rem;
    row-gap: 3.75rem;
  }

  .xl\:gap-y-16 {
    grid-row-gap: 4rem;
    row-gap: 4rem;
  }

  .xl\:gap-y-20 {
    grid-row-gap: 5rem;
    row-gap: 5rem;
  }

  .xl\:gap-y-24 {
    grid-row-gap: 6rem;
    row-gap: 6rem;
  }

  .xl\:gap-y-28 {
    grid-row-gap: 7rem;
    row-gap: 7rem;
  }

  .xl\:gap-y-32 {
    grid-row-gap: 8rem;
    row-gap: 8rem;
  }

  .xl\:gap-y-36 {
    grid-row-gap: 9rem;
    row-gap: 9rem;
  }

  .xl\:gap-y-40 {
    grid-row-gap: 10rem;
    row-gap: 10rem;
  }

  .xl\:gap-y-44 {
    grid-row-gap: 11rem;
    row-gap: 11rem;
  }

  .xl\:gap-y-48 {
    grid-row-gap: 12rem;
    row-gap: 12rem;
  }

  .xl\:gap-y-52 {
    grid-row-gap: 13rem;
    row-gap: 13rem;
  }

  .xl\:gap-y-56 {
    grid-row-gap: 14rem;
    row-gap: 14rem;
  }

  .xl\:gap-y-60 {
    grid-row-gap: 15rem;
    row-gap: 15rem;
  }

  .xl\:gap-y-64 {
    grid-row-gap: 16rem;
    row-gap: 16rem;
  }

  .xl\:gap-y-72 {
    grid-row-gap: 18rem;
    row-gap: 18rem;
  }

  .xl\:gap-y-80 {
    grid-row-gap: 20rem;
    row-gap: 20rem;
  }

  .xl\:gap-y-96 {
    grid-row-gap: 24rem;
    row-gap: 24rem;
  }

  .xl\:gap-y-px {
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .xl\:gap-y-0\.5 {
    grid-row-gap: .125rem;
    row-gap: .125rem;
  }

  .xl\:gap-y-1\.5 {
    grid-row-gap: .375rem;
    row-gap: .375rem;
  }

  .xl\:gap-y-2\.5 {
    grid-row-gap: .625rem;
    row-gap: .625rem;
  }

  .xl\:gap-y-3\.5 {
    grid-row-gap: .875rem;
    row-gap: .875rem;
  }

  .xl\:gap-y-1\/2 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .xl\:gap-y-1\/3 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .xl\:gap-y-2\/3 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .xl\:gap-y-1\/4 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .xl\:gap-y-2\/4 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .xl\:gap-y-3\/4 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .xl\:gap-y-1\/5 {
    grid-row-gap: 20%;
    row-gap: 20%;
  }

  .xl\:gap-y-2\/5 {
    grid-row-gap: 40%;
    row-gap: 40%;
  }

  .xl\:gap-y-3\/5 {
    grid-row-gap: 60%;
    row-gap: 60%;
  }

  .xl\:gap-y-4\/5 {
    grid-row-gap: 80%;
    row-gap: 80%;
  }

  .xl\:gap-y-1\/6 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .xl\:gap-y-2\/6 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .xl\:gap-y-3\/6 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .xl\:gap-y-4\/6 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .xl\:gap-y-5\/6 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .xl\:gap-y-1\/12 {
    grid-row-gap: 8.33333%;
    row-gap: 8.33333%;
  }

  .xl\:gap-y-2\/12 {
    grid-row-gap: 16.6667%;
    row-gap: 16.6667%;
  }

  .xl\:gap-y-3\/12 {
    grid-row-gap: 25%;
    row-gap: 25%;
  }

  .xl\:gap-y-4\/12 {
    grid-row-gap: 33.3333%;
    row-gap: 33.3333%;
  }

  .xl\:gap-y-5\/12 {
    grid-row-gap: 41.6667%;
    row-gap: 41.6667%;
  }

  .xl\:gap-y-6\/12 {
    grid-row-gap: 50%;
    row-gap: 50%;
  }

  .xl\:gap-y-7\/12 {
    grid-row-gap: 58.3333%;
    row-gap: 58.3333%;
  }

  .xl\:gap-y-8\/12 {
    grid-row-gap: 66.6667%;
    row-gap: 66.6667%;
  }

  .xl\:gap-y-9\/12 {
    grid-row-gap: 75%;
    row-gap: 75%;
  }

  .xl\:gap-y-10\/12 {
    grid-row-gap: 83.3333%;
    row-gap: 83.3333%;
  }

  .xl\:gap-y-11\/12 {
    grid-row-gap: 91.6667%;
    row-gap: 91.6667%;
  }

  .xl\:gap-y-full {
    grid-row-gap: 100%;
    row-gap: 100%;
  }

  .xl\:grid-flow-row {
    grid-auto-flow: row;
  }

  .xl\:grid-flow-col {
    grid-auto-flow: column;
  }

  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }

  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }

  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }

  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }

  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }

  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .xl\:grid-cols-none {
    grid-template-columns: none;
  }

  .xl\:auto-cols-auto {
    grid-auto-columns: auto;
  }

  .xl\:auto-cols-min {
    grid-auto-columns: min-content;
  }

  .xl\:auto-cols-max {
    grid-auto-columns: max-content;
  }

  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }

  .xl\:col-auto {
    grid-column: auto;
  }

  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:col-start-1 {
    grid-column-start: 1;
  }

  .xl\:col-start-2 {
    grid-column-start: 2;
  }

  .xl\:col-start-3 {
    grid-column-start: 3;
  }

  .xl\:col-start-4 {
    grid-column-start: 4;
  }

  .xl\:col-start-5 {
    grid-column-start: 5;
  }

  .xl\:col-start-6 {
    grid-column-start: 6;
  }

  .xl\:col-start-7 {
    grid-column-start: 7;
  }

  .xl\:col-start-8 {
    grid-column-start: 8;
  }

  .xl\:col-start-9 {
    grid-column-start: 9;
  }

  .xl\:col-start-10 {
    grid-column-start: 10;
  }

  .xl\:col-start-11 {
    grid-column-start: 11;
  }

  .xl\:col-start-12 {
    grid-column-start: 12;
  }

  .xl\:col-start-13 {
    grid-column-start: 13;
  }

  .xl\:col-start-auto {
    grid-column-start: auto;
  }

  .xl\:col-end-1 {
    grid-column-end: 1;
  }

  .xl\:col-end-2 {
    grid-column-end: 2;
  }

  .xl\:col-end-3 {
    grid-column-end: 3;
  }

  .xl\:col-end-4 {
    grid-column-end: 4;
  }

  .xl\:col-end-5 {
    grid-column-end: 5;
  }

  .xl\:col-end-6 {
    grid-column-end: 6;
  }

  .xl\:col-end-7 {
    grid-column-end: 7;
  }

  .xl\:col-end-8 {
    grid-column-end: 8;
  }

  .xl\:col-end-9 {
    grid-column-end: 9;
  }

  .xl\:col-end-10 {
    grid-column-end: 10;
  }

  .xl\:col-end-11 {
    grid-column-end: 11;
  }

  .xl\:col-end-12 {
    grid-column-end: 12;
  }

  .xl\:col-end-13 {
    grid-column-end: 13;
  }

  .xl\:col-end-auto {
    grid-column-end: auto;
  }

  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }

  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }

  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }

  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }

  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }

  .xl\:grid-rows-none {
    grid-template-rows: none;
  }

  .xl\:auto-rows-auto {
    grid-auto-rows: auto;
  }

  .xl\:auto-rows-min {
    grid-auto-rows: min-content;
  }

  .xl\:auto-rows-max {
    grid-auto-rows: max-content;
  }

  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }

  .xl\:row-auto {
    grid-row: auto;
  }

  .xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }

  .xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }

  .xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }

  .xl\:row-span-4 {
    grid-row: span 4 / span 4;
  }

  .xl\:row-span-5 {
    grid-row: span 5 / span 5;
  }

  .xl\:row-span-6 {
    grid-row: span 6 / span 6;
  }

  .xl\:row-span-full {
    grid-row: 1 / -1;
  }

  .xl\:row-start-1 {
    grid-row-start: 1;
  }

  .xl\:row-start-2 {
    grid-row-start: 2;
  }

  .xl\:row-start-3 {
    grid-row-start: 3;
  }

  .xl\:row-start-4 {
    grid-row-start: 4;
  }

  .xl\:row-start-5 {
    grid-row-start: 5;
  }

  .xl\:row-start-6 {
    grid-row-start: 6;
  }

  .xl\:row-start-7 {
    grid-row-start: 7;
  }

  .xl\:row-start-auto {
    grid-row-start: auto;
  }

  .xl\:row-end-1 {
    grid-row-end: 1;
  }

  .xl\:row-end-2 {
    grid-row-end: 2;
  }

  .xl\:row-end-3 {
    grid-row-end: 3;
  }

  .xl\:row-end-4 {
    grid-row-end: 4;
  }

  .xl\:row-end-5 {
    grid-row-end: 5;
  }

  .xl\:row-end-6 {
    grid-row-end: 6;
  }

  .xl\:row-end-7 {
    grid-row-end: 7;
  }

  .xl\:row-end-auto {
    grid-row-end: auto;
  }

  .xl\:transform {
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x) ) translateY(var(--transform-translate-y) ) rotate(var(--transform-rotate) ) skewX(var(--transform-skew-x) ) skewY(var(--transform-skew-y) ) scaleX(var(--transform-scale-x) ) scaleY(var(--transform-scale-y) );
  }

  .xl\:transform-none {
    transform: none;
  }

  .xl\:origin-center {
    transform-origin: center;
  }

  .xl\:origin-top {
    transform-origin: top;
  }

  .xl\:origin-top-right {
    transform-origin: 100% 0;
  }

  .xl\:origin-right {
    transform-origin: 100%;
  }

  .xl\:origin-bottom-right {
    transform-origin: 100% 100%;
  }

  .xl\:origin-bottom {
    transform-origin: bottom;
  }

  .xl\:origin-bottom-left {
    transform-origin: 0 100%;
  }

  .xl\:origin-left {
    transform-origin: 0;
  }

  .xl\:origin-top-left {
    transform-origin: 0 0;
  }

  .xl\:scale-0 {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .xl\:scale-50 {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .xl\:scale-75 {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .xl\:scale-90 {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .xl\:scale-95 {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .xl\:scale-100 {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .xl\:scale-105 {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .xl\:scale-110 {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .xl\:scale-125 {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .xl\:scale-150 {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .xl\:scale-x-0 {
    --transform-scale-x: 0;
  }

  .xl\:scale-x-50 {
    --transform-scale-x: .5;
  }

  .xl\:scale-x-75 {
    --transform-scale-x: .75;
  }

  .xl\:scale-x-90 {
    --transform-scale-x: .9;
  }

  .xl\:scale-x-95 {
    --transform-scale-x: .95;
  }

  .xl\:scale-x-100 {
    --transform-scale-x: 1;
  }

  .xl\:scale-x-105 {
    --transform-scale-x: 1.05;
  }

  .xl\:scale-x-110 {
    --transform-scale-x: 1.1;
  }

  .xl\:scale-x-125 {
    --transform-scale-x: 1.25;
  }

  .xl\:scale-x-150 {
    --transform-scale-x: 1.5;
  }

  .xl\:scale-y-0 {
    --transform-scale-y: 0;
  }

  .xl\:scale-y-50 {
    --transform-scale-y: .5;
  }

  .xl\:scale-y-75 {
    --transform-scale-y: .75;
  }

  .xl\:scale-y-90 {
    --transform-scale-y: .9;
  }

  .xl\:scale-y-95 {
    --transform-scale-y: .95;
  }

  .xl\:scale-y-100 {
    --transform-scale-y: 1;
  }

  .xl\:scale-y-105 {
    --transform-scale-y: 1.05;
  }

  .xl\:scale-y-110 {
    --transform-scale-y: 1.1;
  }

  .xl\:scale-y-125 {
    --transform-scale-y: 1.25;
  }

  .xl\:scale-y-150 {
    --transform-scale-y: 1.5;
  }

  .xl\:hover\:scale-0:hover {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .xl\:hover\:scale-50:hover {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .xl\:hover\:scale-75:hover {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .xl\:hover\:scale-90:hover {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .xl\:hover\:scale-95:hover {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .xl\:hover\:scale-100:hover {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .xl\:hover\:scale-105:hover {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .xl\:hover\:scale-110:hover {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .xl\:hover\:scale-125:hover {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .xl\:hover\:scale-150:hover {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .xl\:hover\:scale-x-0:hover {
    --transform-scale-x: 0;
  }

  .xl\:hover\:scale-x-50:hover {
    --transform-scale-x: .5;
  }

  .xl\:hover\:scale-x-75:hover {
    --transform-scale-x: .75;
  }

  .xl\:hover\:scale-x-90:hover {
    --transform-scale-x: .9;
  }

  .xl\:hover\:scale-x-95:hover {
    --transform-scale-x: .95;
  }

  .xl\:hover\:scale-x-100:hover {
    --transform-scale-x: 1;
  }

  .xl\:hover\:scale-x-105:hover {
    --transform-scale-x: 1.05;
  }

  .xl\:hover\:scale-x-110:hover {
    --transform-scale-x: 1.1;
  }

  .xl\:hover\:scale-x-125:hover {
    --transform-scale-x: 1.25;
  }

  .xl\:hover\:scale-x-150:hover {
    --transform-scale-x: 1.5;
  }

  .xl\:hover\:scale-y-0:hover {
    --transform-scale-y: 0;
  }

  .xl\:hover\:scale-y-50:hover {
    --transform-scale-y: .5;
  }

  .xl\:hover\:scale-y-75:hover {
    --transform-scale-y: .75;
  }

  .xl\:hover\:scale-y-90:hover {
    --transform-scale-y: .9;
  }

  .xl\:hover\:scale-y-95:hover {
    --transform-scale-y: .95;
  }

  .xl\:hover\:scale-y-100:hover {
    --transform-scale-y: 1;
  }

  .xl\:hover\:scale-y-105:hover {
    --transform-scale-y: 1.05;
  }

  .xl\:hover\:scale-y-110:hover {
    --transform-scale-y: 1.1;
  }

  .xl\:hover\:scale-y-125:hover {
    --transform-scale-y: 1.25;
  }

  .xl\:hover\:scale-y-150:hover {
    --transform-scale-y: 1.5;
  }

  .xl\:focus\:scale-0:focus {
    --transform-scale-x: 0;
    --transform-scale-y: 0;
  }

  .xl\:focus\:scale-50:focus {
    --transform-scale-x: .5;
    --transform-scale-y: .5;
  }

  .xl\:focus\:scale-75:focus {
    --transform-scale-x: .75;
    --transform-scale-y: .75;
  }

  .xl\:focus\:scale-90:focus {
    --transform-scale-x: .9;
    --transform-scale-y: .9;
  }

  .xl\:focus\:scale-95:focus {
    --transform-scale-x: .95;
    --transform-scale-y: .95;
  }

  .xl\:focus\:scale-100:focus {
    --transform-scale-x: 1;
    --transform-scale-y: 1;
  }

  .xl\:focus\:scale-105:focus {
    --transform-scale-x: 1.05;
    --transform-scale-y: 1.05;
  }

  .xl\:focus\:scale-110:focus {
    --transform-scale-x: 1.1;
    --transform-scale-y: 1.1;
  }

  .xl\:focus\:scale-125:focus {
    --transform-scale-x: 1.25;
    --transform-scale-y: 1.25;
  }

  .xl\:focus\:scale-150:focus {
    --transform-scale-x: 1.5;
    --transform-scale-y: 1.5;
  }

  .xl\:focus\:scale-x-0:focus {
    --transform-scale-x: 0;
  }

  .xl\:focus\:scale-x-50:focus {
    --transform-scale-x: .5;
  }

  .xl\:focus\:scale-x-75:focus {
    --transform-scale-x: .75;
  }

  .xl\:focus\:scale-x-90:focus {
    --transform-scale-x: .9;
  }

  .xl\:focus\:scale-x-95:focus {
    --transform-scale-x: .95;
  }

  .xl\:focus\:scale-x-100:focus {
    --transform-scale-x: 1;
  }

  .xl\:focus\:scale-x-105:focus {
    --transform-scale-x: 1.05;
  }

  .xl\:focus\:scale-x-110:focus {
    --transform-scale-x: 1.1;
  }

  .xl\:focus\:scale-x-125:focus {
    --transform-scale-x: 1.25;
  }

  .xl\:focus\:scale-x-150:focus {
    --transform-scale-x: 1.5;
  }

  .xl\:focus\:scale-y-0:focus {
    --transform-scale-y: 0;
  }

  .xl\:focus\:scale-y-50:focus {
    --transform-scale-y: .5;
  }

  .xl\:focus\:scale-y-75:focus {
    --transform-scale-y: .75;
  }

  .xl\:focus\:scale-y-90:focus {
    --transform-scale-y: .9;
  }

  .xl\:focus\:scale-y-95:focus {
    --transform-scale-y: .95;
  }

  .xl\:focus\:scale-y-100:focus {
    --transform-scale-y: 1;
  }

  .xl\:focus\:scale-y-105:focus {
    --transform-scale-y: 1.05;
  }

  .xl\:focus\:scale-y-110:focus {
    --transform-scale-y: 1.1;
  }

  .xl\:focus\:scale-y-125:focus {
    --transform-scale-y: 1.25;
  }

  .xl\:focus\:scale-y-150:focus {
    --transform-scale-y: 1.5;
  }

  .xl\:rotate-0 {
    --transform-rotate: 0;
  }

  .xl\:rotate-1 {
    --transform-rotate: 1deg;
  }

  .xl\:rotate-2 {
    --transform-rotate: 2deg;
  }

  .xl\:rotate-3 {
    --transform-rotate: 3deg;
  }

  .xl\:rotate-6 {
    --transform-rotate: 6deg;
  }

  .xl\:rotate-12 {
    --transform-rotate: 12deg;
  }

  .xl\:rotate-45 {
    --transform-rotate: 45deg;
  }

  .xl\:rotate-90 {
    --transform-rotate: 90deg;
  }

  .xl\:rotate-180 {
    --transform-rotate: 180deg;
  }

  .xl\:-rotate-180 {
    --transform-rotate: -180deg;
  }

  .xl\:-rotate-90 {
    --transform-rotate: -90deg;
  }

  .xl\:-rotate-45 {
    --transform-rotate: -45deg;
  }

  .xl\:-rotate-12 {
    --transform-rotate: -12deg;
  }

  .xl\:-rotate-6 {
    --transform-rotate: -6deg;
  }

  .xl\:-rotate-3 {
    --transform-rotate: -3deg;
  }

  .xl\:-rotate-2 {
    --transform-rotate: -2deg;
  }

  .xl\:-rotate-1 {
    --transform-rotate: -1deg;
  }

  .xl\:hover\:rotate-0:hover {
    --transform-rotate: 0;
  }

  .xl\:hover\:rotate-1:hover {
    --transform-rotate: 1deg;
  }

  .xl\:hover\:rotate-2:hover {
    --transform-rotate: 2deg;
  }

  .xl\:hover\:rotate-3:hover {
    --transform-rotate: 3deg;
  }

  .xl\:hover\:rotate-6:hover {
    --transform-rotate: 6deg;
  }

  .xl\:hover\:rotate-12:hover {
    --transform-rotate: 12deg;
  }

  .xl\:hover\:rotate-45:hover {
    --transform-rotate: 45deg;
  }

  .xl\:hover\:rotate-90:hover {
    --transform-rotate: 90deg;
  }

  .xl\:hover\:rotate-180:hover {
    --transform-rotate: 180deg;
  }

  .xl\:hover\:-rotate-180:hover {
    --transform-rotate: -180deg;
  }

  .xl\:hover\:-rotate-90:hover {
    --transform-rotate: -90deg;
  }

  .xl\:hover\:-rotate-45:hover {
    --transform-rotate: -45deg;
  }

  .xl\:hover\:-rotate-12:hover {
    --transform-rotate: -12deg;
  }

  .xl\:hover\:-rotate-6:hover {
    --transform-rotate: -6deg;
  }

  .xl\:hover\:-rotate-3:hover {
    --transform-rotate: -3deg;
  }

  .xl\:hover\:-rotate-2:hover {
    --transform-rotate: -2deg;
  }

  .xl\:hover\:-rotate-1:hover {
    --transform-rotate: -1deg;
  }

  .xl\:focus\:rotate-0:focus {
    --transform-rotate: 0;
  }

  .xl\:focus\:rotate-1:focus {
    --transform-rotate: 1deg;
  }

  .xl\:focus\:rotate-2:focus {
    --transform-rotate: 2deg;
  }

  .xl\:focus\:rotate-3:focus {
    --transform-rotate: 3deg;
  }

  .xl\:focus\:rotate-6:focus {
    --transform-rotate: 6deg;
  }

  .xl\:focus\:rotate-12:focus {
    --transform-rotate: 12deg;
  }

  .xl\:focus\:rotate-45:focus {
    --transform-rotate: 45deg;
  }

  .xl\:focus\:rotate-90:focus {
    --transform-rotate: 90deg;
  }

  .xl\:focus\:rotate-180:focus {
    --transform-rotate: 180deg;
  }

  .xl\:focus\:-rotate-180:focus {
    --transform-rotate: -180deg;
  }

  .xl\:focus\:-rotate-90:focus {
    --transform-rotate: -90deg;
  }

  .xl\:focus\:-rotate-45:focus {
    --transform-rotate: -45deg;
  }

  .xl\:focus\:-rotate-12:focus {
    --transform-rotate: -12deg;
  }

  .xl\:focus\:-rotate-6:focus {
    --transform-rotate: -6deg;
  }

  .xl\:focus\:-rotate-3:focus {
    --transform-rotate: -3deg;
  }

  .xl\:focus\:-rotate-2:focus {
    --transform-rotate: -2deg;
  }

  .xl\:focus\:-rotate-1:focus {
    --transform-rotate: -1deg;
  }

  .xl\:translate-x-0 {
    --transform-translate-x: 0;
  }

  .xl\:translate-x-1 {
    --transform-translate-x: .25rem;
  }

  .xl\:translate-x-2 {
    --transform-translate-x: .5rem;
  }

  .xl\:translate-x-3 {
    --transform-translate-x: .75rem;
  }

  .xl\:translate-x-4 {
    --transform-translate-x: 1rem;
  }

  .xl\:translate-x-5 {
    --transform-translate-x: 1.25rem;
  }

  .xl\:translate-x-6 {
    --transform-translate-x: 1.5rem;
  }

  .xl\:translate-x-7 {
    --transform-translate-x: 1.75rem;
  }

  .xl\:translate-x-8 {
    --transform-translate-x: 2rem;
  }

  .xl\:translate-x-9 {
    --transform-translate-x: 2.25rem;
  }

  .xl\:translate-x-10 {
    --transform-translate-x: 2.5rem;
  }

  .xl\:translate-x-11 {
    --transform-translate-x: 2.75rem;
  }

  .xl\:translate-x-12 {
    --transform-translate-x: 3rem;
  }

  .xl\:translate-x-13 {
    --transform-translate-x: 3.25rem;
  }

  .xl\:translate-x-14 {
    --transform-translate-x: 3.5rem;
  }

  .xl\:translate-x-15 {
    --transform-translate-x: 3.75rem;
  }

  .xl\:translate-x-16 {
    --transform-translate-x: 4rem;
  }

  .xl\:translate-x-20 {
    --transform-translate-x: 5rem;
  }

  .xl\:translate-x-24 {
    --transform-translate-x: 6rem;
  }

  .xl\:translate-x-28 {
    --transform-translate-x: 7rem;
  }

  .xl\:translate-x-32 {
    --transform-translate-x: 8rem;
  }

  .xl\:translate-x-36 {
    --transform-translate-x: 9rem;
  }

  .xl\:translate-x-40 {
    --transform-translate-x: 10rem;
  }

  .xl\:translate-x-44 {
    --transform-translate-x: 11rem;
  }

  .xl\:translate-x-48 {
    --transform-translate-x: 12rem;
  }

  .xl\:translate-x-52 {
    --transform-translate-x: 13rem;
  }

  .xl\:translate-x-56 {
    --transform-translate-x: 14rem;
  }

  .xl\:translate-x-60 {
    --transform-translate-x: 15rem;
  }

  .xl\:translate-x-64 {
    --transform-translate-x: 16rem;
  }

  .xl\:translate-x-72 {
    --transform-translate-x: 18rem;
  }

  .xl\:translate-x-80 {
    --transform-translate-x: 20rem;
  }

  .xl\:translate-x-96 {
    --transform-translate-x: 24rem;
  }

  .xl\:translate-x-px {
    --transform-translate-x: 1px;
  }

  .xl\:translate-x-0\.5 {
    --transform-translate-x: .125rem;
  }

  .xl\:translate-x-1\.5 {
    --transform-translate-x: .375rem;
  }

  .xl\:translate-x-2\.5 {
    --transform-translate-x: .625rem;
  }

  .xl\:translate-x-3\.5 {
    --transform-translate-x: .875rem;
  }

  .xl\:translate-x-1\/2 {
    --transform-translate-x: 50%;
  }

  .xl\:translate-x-1\/3 {
    --transform-translate-x: 33.3333%;
  }

  .xl\:translate-x-2\/3 {
    --transform-translate-x: 66.6667%;
  }

  .xl\:translate-x-1\/4 {
    --transform-translate-x: 25%;
  }

  .xl\:translate-x-2\/4 {
    --transform-translate-x: 50%;
  }

  .xl\:translate-x-3\/4 {
    --transform-translate-x: 75%;
  }

  .xl\:translate-x-1\/5 {
    --transform-translate-x: 20%;
  }

  .xl\:translate-x-2\/5 {
    --transform-translate-x: 40%;
  }

  .xl\:translate-x-3\/5 {
    --transform-translate-x: 60%;
  }

  .xl\:translate-x-4\/5 {
    --transform-translate-x: 80%;
  }

  .xl\:translate-x-1\/6 {
    --transform-translate-x: 16.6667%;
  }

  .xl\:translate-x-2\/6 {
    --transform-translate-x: 33.3333%;
  }

  .xl\:translate-x-3\/6 {
    --transform-translate-x: 50%;
  }

  .xl\:translate-x-4\/6 {
    --transform-translate-x: 66.6667%;
  }

  .xl\:translate-x-5\/6 {
    --transform-translate-x: 83.3333%;
  }

  .xl\:translate-x-1\/12 {
    --transform-translate-x: 8.33333%;
  }

  .xl\:translate-x-2\/12 {
    --transform-translate-x: 16.6667%;
  }

  .xl\:translate-x-3\/12 {
    --transform-translate-x: 25%;
  }

  .xl\:translate-x-4\/12 {
    --transform-translate-x: 33.3333%;
  }

  .xl\:translate-x-5\/12 {
    --transform-translate-x: 41.6667%;
  }

  .xl\:translate-x-6\/12 {
    --transform-translate-x: 50%;
  }

  .xl\:translate-x-7\/12 {
    --transform-translate-x: 58.3333%;
  }

  .xl\:translate-x-8\/12 {
    --transform-translate-x: 66.6667%;
  }

  .xl\:translate-x-9\/12 {
    --transform-translate-x: 75%;
  }

  .xl\:translate-x-10\/12 {
    --transform-translate-x: 83.3333%;
  }

  .xl\:translate-x-11\/12 {
    --transform-translate-x: 91.6667%;
  }

  .xl\:translate-x-full {
    --transform-translate-x: 100%;
  }

  .xl\:-translate-x-1 {
    --transform-translate-x: -.25rem;
  }

  .xl\:-translate-x-2 {
    --transform-translate-x: -.5rem;
  }

  .xl\:-translate-x-3 {
    --transform-translate-x: -.75rem;
  }

  .xl\:-translate-x-4 {
    --transform-translate-x: -1rem;
  }

  .xl\:-translate-x-5 {
    --transform-translate-x: -1.25rem;
  }

  .xl\:-translate-x-6 {
    --transform-translate-x: -1.5rem;
  }

  .xl\:-translate-x-7 {
    --transform-translate-x: -1.75rem;
  }

  .xl\:-translate-x-8 {
    --transform-translate-x: -2rem;
  }

  .xl\:-translate-x-9 {
    --transform-translate-x: -2.25rem;
  }

  .xl\:-translate-x-10 {
    --transform-translate-x: -2.5rem;
  }

  .xl\:-translate-x-11 {
    --transform-translate-x: -2.75rem;
  }

  .xl\:-translate-x-12 {
    --transform-translate-x: -3rem;
  }

  .xl\:-translate-x-13 {
    --transform-translate-x: -3.25rem;
  }

  .xl\:-translate-x-14 {
    --transform-translate-x: -3.5rem;
  }

  .xl\:-translate-x-15 {
    --transform-translate-x: -3.75rem;
  }

  .xl\:-translate-x-16 {
    --transform-translate-x: -4rem;
  }

  .xl\:-translate-x-20 {
    --transform-translate-x: -5rem;
  }

  .xl\:-translate-x-24 {
    --transform-translate-x: -6rem;
  }

  .xl\:-translate-x-28 {
    --transform-translate-x: -7rem;
  }

  .xl\:-translate-x-32 {
    --transform-translate-x: -8rem;
  }

  .xl\:-translate-x-36 {
    --transform-translate-x: -9rem;
  }

  .xl\:-translate-x-40 {
    --transform-translate-x: -10rem;
  }

  .xl\:-translate-x-44 {
    --transform-translate-x: -11rem;
  }

  .xl\:-translate-x-48 {
    --transform-translate-x: -12rem;
  }

  .xl\:-translate-x-52 {
    --transform-translate-x: -13rem;
  }

  .xl\:-translate-x-56 {
    --transform-translate-x: -14rem;
  }

  .xl\:-translate-x-60 {
    --transform-translate-x: -15rem;
  }

  .xl\:-translate-x-64 {
    --transform-translate-x: -16rem;
  }

  .xl\:-translate-x-72 {
    --transform-translate-x: -18rem;
  }

  .xl\:-translate-x-80 {
    --transform-translate-x: -20rem;
  }

  .xl\:-translate-x-96 {
    --transform-translate-x: -24rem;
  }

  .xl\:-translate-x-px {
    --transform-translate-x: -1px;
  }

  .xl\:-translate-x-0\.5 {
    --transform-translate-x: -.125rem;
  }

  .xl\:-translate-x-1\.5 {
    --transform-translate-x: -.375rem;
  }

  .xl\:-translate-x-2\.5 {
    --transform-translate-x: -.625rem;
  }

  .xl\:-translate-x-3\.5 {
    --transform-translate-x: -.875rem;
  }

  .xl\:-translate-x-1\/2 {
    --transform-translate-x: -50%;
  }

  .xl\:-translate-x-1\/3 {
    --transform-translate-x: -33.3333%;
  }

  .xl\:-translate-x-2\/3 {
    --transform-translate-x: -66.6667%;
  }

  .xl\:-translate-x-1\/4 {
    --transform-translate-x: -25%;
  }

  .xl\:-translate-x-2\/4 {
    --transform-translate-x: -50%;
  }

  .xl\:-translate-x-3\/4 {
    --transform-translate-x: -75%;
  }

  .xl\:-translate-x-1\/5 {
    --transform-translate-x: -20%;
  }

  .xl\:-translate-x-2\/5 {
    --transform-translate-x: -40%;
  }

  .xl\:-translate-x-3\/5 {
    --transform-translate-x: -60%;
  }

  .xl\:-translate-x-4\/5 {
    --transform-translate-x: -80%;
  }

  .xl\:-translate-x-1\/6 {
    --transform-translate-x: -16.6667%;
  }

  .xl\:-translate-x-2\/6 {
    --transform-translate-x: -33.3333%;
  }

  .xl\:-translate-x-3\/6 {
    --transform-translate-x: -50%;
  }

  .xl\:-translate-x-4\/6 {
    --transform-translate-x: -66.6667%;
  }

  .xl\:-translate-x-5\/6 {
    --transform-translate-x: -83.3333%;
  }

  .xl\:-translate-x-1\/12 {
    --transform-translate-x: -8.33333%;
  }

  .xl\:-translate-x-2\/12 {
    --transform-translate-x: -16.6667%;
  }

  .xl\:-translate-x-3\/12 {
    --transform-translate-x: -25%;
  }

  .xl\:-translate-x-4\/12 {
    --transform-translate-x: -33.3333%;
  }

  .xl\:-translate-x-5\/12 {
    --transform-translate-x: -41.6667%;
  }

  .xl\:-translate-x-6\/12 {
    --transform-translate-x: -50%;
  }

  .xl\:-translate-x-7\/12 {
    --transform-translate-x: -58.3333%;
  }

  .xl\:-translate-x-8\/12 {
    --transform-translate-x: -66.6667%;
  }

  .xl\:-translate-x-9\/12 {
    --transform-translate-x: -75%;
  }

  .xl\:-translate-x-10\/12 {
    --transform-translate-x: -83.3333%;
  }

  .xl\:-translate-x-11\/12 {
    --transform-translate-x: -91.6667%;
  }

  .xl\:-translate-x-full {
    --transform-translate-x: -100%;
  }

  .xl\:translate-y-0 {
    --transform-translate-y: 0;
  }

  .xl\:translate-y-1 {
    --transform-translate-y: .25rem;
  }

  .xl\:translate-y-2 {
    --transform-translate-y: .5rem;
  }

  .xl\:translate-y-3 {
    --transform-translate-y: .75rem;
  }

  .xl\:translate-y-4 {
    --transform-translate-y: 1rem;
  }

  .xl\:translate-y-5 {
    --transform-translate-y: 1.25rem;
  }

  .xl\:translate-y-6 {
    --transform-translate-y: 1.5rem;
  }

  .xl\:translate-y-7 {
    --transform-translate-y: 1.75rem;
  }

  .xl\:translate-y-8 {
    --transform-translate-y: 2rem;
  }

  .xl\:translate-y-9 {
    --transform-translate-y: 2.25rem;
  }

  .xl\:translate-y-10 {
    --transform-translate-y: 2.5rem;
  }

  .xl\:translate-y-11 {
    --transform-translate-y: 2.75rem;
  }

  .xl\:translate-y-12 {
    --transform-translate-y: 3rem;
  }

  .xl\:translate-y-13 {
    --transform-translate-y: 3.25rem;
  }

  .xl\:translate-y-14 {
    --transform-translate-y: 3.5rem;
  }

  .xl\:translate-y-15 {
    --transform-translate-y: 3.75rem;
  }

  .xl\:translate-y-16 {
    --transform-translate-y: 4rem;
  }

  .xl\:translate-y-20 {
    --transform-translate-y: 5rem;
  }

  .xl\:translate-y-24 {
    --transform-translate-y: 6rem;
  }

  .xl\:translate-y-28 {
    --transform-translate-y: 7rem;
  }

  .xl\:translate-y-32 {
    --transform-translate-y: 8rem;
  }

  .xl\:translate-y-36 {
    --transform-translate-y: 9rem;
  }

  .xl\:translate-y-40 {
    --transform-translate-y: 10rem;
  }

  .xl\:translate-y-44 {
    --transform-translate-y: 11rem;
  }

  .xl\:translate-y-48 {
    --transform-translate-y: 12rem;
  }

  .xl\:translate-y-52 {
    --transform-translate-y: 13rem;
  }

  .xl\:translate-y-56 {
    --transform-translate-y: 14rem;
  }

  .xl\:translate-y-60 {
    --transform-translate-y: 15rem;
  }

  .xl\:translate-y-64 {
    --transform-translate-y: 16rem;
  }

  .xl\:translate-y-72 {
    --transform-translate-y: 18rem;
  }

  .xl\:translate-y-80 {
    --transform-translate-y: 20rem;
  }

  .xl\:translate-y-96 {
    --transform-translate-y: 24rem;
  }

  .xl\:translate-y-px {
    --transform-translate-y: 1px;
  }

  .xl\:translate-y-0\.5 {
    --transform-translate-y: .125rem;
  }

  .xl\:translate-y-1\.5 {
    --transform-translate-y: .375rem;
  }

  .xl\:translate-y-2\.5 {
    --transform-translate-y: .625rem;
  }

  .xl\:translate-y-3\.5 {
    --transform-translate-y: .875rem;
  }

  .xl\:translate-y-1\/2 {
    --transform-translate-y: 50%;
  }

  .xl\:translate-y-1\/3 {
    --transform-translate-y: 33.3333%;
  }

  .xl\:translate-y-2\/3 {
    --transform-translate-y: 66.6667%;
  }

  .xl\:translate-y-1\/4 {
    --transform-translate-y: 25%;
  }

  .xl\:translate-y-2\/4 {
    --transform-translate-y: 50%;
  }

  .xl\:translate-y-3\/4 {
    --transform-translate-y: 75%;
  }

  .xl\:translate-y-1\/5 {
    --transform-translate-y: 20%;
  }

  .xl\:translate-y-2\/5 {
    --transform-translate-y: 40%;
  }

  .xl\:translate-y-3\/5 {
    --transform-translate-y: 60%;
  }

  .xl\:translate-y-4\/5 {
    --transform-translate-y: 80%;
  }

  .xl\:translate-y-1\/6 {
    --transform-translate-y: 16.6667%;
  }

  .xl\:translate-y-2\/6 {
    --transform-translate-y: 33.3333%;
  }

  .xl\:translate-y-3\/6 {
    --transform-translate-y: 50%;
  }

  .xl\:translate-y-4\/6 {
    --transform-translate-y: 66.6667%;
  }

  .xl\:translate-y-5\/6 {
    --transform-translate-y: 83.3333%;
  }

  .xl\:translate-y-1\/12 {
    --transform-translate-y: 8.33333%;
  }

  .xl\:translate-y-2\/12 {
    --transform-translate-y: 16.6667%;
  }

  .xl\:translate-y-3\/12 {
    --transform-translate-y: 25%;
  }

  .xl\:translate-y-4\/12 {
    --transform-translate-y: 33.3333%;
  }

  .xl\:translate-y-5\/12 {
    --transform-translate-y: 41.6667%;
  }

  .xl\:translate-y-6\/12 {
    --transform-translate-y: 50%;
  }

  .xl\:translate-y-7\/12 {
    --transform-translate-y: 58.3333%;
  }

  .xl\:translate-y-8\/12 {
    --transform-translate-y: 66.6667%;
  }

  .xl\:translate-y-9\/12 {
    --transform-translate-y: 75%;
  }

  .xl\:translate-y-10\/12 {
    --transform-translate-y: 83.3333%;
  }

  .xl\:translate-y-11\/12 {
    --transform-translate-y: 91.6667%;
  }

  .xl\:translate-y-full {
    --transform-translate-y: 100%;
  }

  .xl\:-translate-y-1 {
    --transform-translate-y: -.25rem;
  }

  .xl\:-translate-y-2 {
    --transform-translate-y: -.5rem;
  }

  .xl\:-translate-y-3 {
    --transform-translate-y: -.75rem;
  }

  .xl\:-translate-y-4 {
    --transform-translate-y: -1rem;
  }

  .xl\:-translate-y-5 {
    --transform-translate-y: -1.25rem;
  }

  .xl\:-translate-y-6 {
    --transform-translate-y: -1.5rem;
  }

  .xl\:-translate-y-7 {
    --transform-translate-y: -1.75rem;
  }

  .xl\:-translate-y-8 {
    --transform-translate-y: -2rem;
  }

  .xl\:-translate-y-9 {
    --transform-translate-y: -2.25rem;
  }

  .xl\:-translate-y-10 {
    --transform-translate-y: -2.5rem;
  }

  .xl\:-translate-y-11 {
    --transform-translate-y: -2.75rem;
  }

  .xl\:-translate-y-12 {
    --transform-translate-y: -3rem;
  }

  .xl\:-translate-y-13 {
    --transform-translate-y: -3.25rem;
  }

  .xl\:-translate-y-14 {
    --transform-translate-y: -3.5rem;
  }

  .xl\:-translate-y-15 {
    --transform-translate-y: -3.75rem;
  }

  .xl\:-translate-y-16 {
    --transform-translate-y: -4rem;
  }

  .xl\:-translate-y-20 {
    --transform-translate-y: -5rem;
  }

  .xl\:-translate-y-24 {
    --transform-translate-y: -6rem;
  }

  .xl\:-translate-y-28 {
    --transform-translate-y: -7rem;
  }

  .xl\:-translate-y-32 {
    --transform-translate-y: -8rem;
  }

  .xl\:-translate-y-36 {
    --transform-translate-y: -9rem;
  }

  .xl\:-translate-y-40 {
    --transform-translate-y: -10rem;
  }

  .xl\:-translate-y-44 {
    --transform-translate-y: -11rem;
  }

  .xl\:-translate-y-48 {
    --transform-translate-y: -12rem;
  }

  .xl\:-translate-y-52 {
    --transform-translate-y: -13rem;
  }

  .xl\:-translate-y-56 {
    --transform-translate-y: -14rem;
  }

  .xl\:-translate-y-60 {
    --transform-translate-y: -15rem;
  }

  .xl\:-translate-y-64 {
    --transform-translate-y: -16rem;
  }

  .xl\:-translate-y-72 {
    --transform-translate-y: -18rem;
  }

  .xl\:-translate-y-80 {
    --transform-translate-y: -20rem;
  }

  .xl\:-translate-y-96 {
    --transform-translate-y: -24rem;
  }

  .xl\:-translate-y-px {
    --transform-translate-y: -1px;
  }

  .xl\:-translate-y-0\.5 {
    --transform-translate-y: -.125rem;
  }

  .xl\:-translate-y-1\.5 {
    --transform-translate-y: -.375rem;
  }

  .xl\:-translate-y-2\.5 {
    --transform-translate-y: -.625rem;
  }

  .xl\:-translate-y-3\.5 {
    --transform-translate-y: -.875rem;
  }

  .xl\:-translate-y-1\/2 {
    --transform-translate-y: -50%;
  }

  .xl\:-translate-y-1\/3 {
    --transform-translate-y: -33.3333%;
  }

  .xl\:-translate-y-2\/3 {
    --transform-translate-y: -66.6667%;
  }

  .xl\:-translate-y-1\/4 {
    --transform-translate-y: -25%;
  }

  .xl\:-translate-y-2\/4 {
    --transform-translate-y: -50%;
  }

  .xl\:-translate-y-3\/4 {
    --transform-translate-y: -75%;
  }

  .xl\:-translate-y-1\/5 {
    --transform-translate-y: -20%;
  }

  .xl\:-translate-y-2\/5 {
    --transform-translate-y: -40%;
  }

  .xl\:-translate-y-3\/5 {
    --transform-translate-y: -60%;
  }

  .xl\:-translate-y-4\/5 {
    --transform-translate-y: -80%;
  }

  .xl\:-translate-y-1\/6 {
    --transform-translate-y: -16.6667%;
  }

  .xl\:-translate-y-2\/6 {
    --transform-translate-y: -33.3333%;
  }

  .xl\:-translate-y-3\/6 {
    --transform-translate-y: -50%;
  }

  .xl\:-translate-y-4\/6 {
    --transform-translate-y: -66.6667%;
  }

  .xl\:-translate-y-5\/6 {
    --transform-translate-y: -83.3333%;
  }

  .xl\:-translate-y-1\/12 {
    --transform-translate-y: -8.33333%;
  }

  .xl\:-translate-y-2\/12 {
    --transform-translate-y: -16.6667%;
  }

  .xl\:-translate-y-3\/12 {
    --transform-translate-y: -25%;
  }

  .xl\:-translate-y-4\/12 {
    --transform-translate-y: -33.3333%;
  }

  .xl\:-translate-y-5\/12 {
    --transform-translate-y: -41.6667%;
  }

  .xl\:-translate-y-6\/12 {
    --transform-translate-y: -50%;
  }

  .xl\:-translate-y-7\/12 {
    --transform-translate-y: -58.3333%;
  }

  .xl\:-translate-y-8\/12 {
    --transform-translate-y: -66.6667%;
  }

  .xl\:-translate-y-9\/12 {
    --transform-translate-y: -75%;
  }

  .xl\:-translate-y-10\/12 {
    --transform-translate-y: -83.3333%;
  }

  .xl\:-translate-y-11\/12 {
    --transform-translate-y: -91.6667%;
  }

  .xl\:-translate-y-full {
    --transform-translate-y: -100%;
  }

  .xl\:hover\:translate-x-0:hover {
    --transform-translate-x: 0;
  }

  .xl\:hover\:translate-x-1:hover {
    --transform-translate-x: .25rem;
  }

  .xl\:hover\:translate-x-2:hover {
    --transform-translate-x: .5rem;
  }

  .xl\:hover\:translate-x-3:hover {
    --transform-translate-x: .75rem;
  }

  .xl\:hover\:translate-x-4:hover {
    --transform-translate-x: 1rem;
  }

  .xl\:hover\:translate-x-5:hover {
    --transform-translate-x: 1.25rem;
  }

  .xl\:hover\:translate-x-6:hover {
    --transform-translate-x: 1.5rem;
  }

  .xl\:hover\:translate-x-7:hover {
    --transform-translate-x: 1.75rem;
  }

  .xl\:hover\:translate-x-8:hover {
    --transform-translate-x: 2rem;
  }

  .xl\:hover\:translate-x-9:hover {
    --transform-translate-x: 2.25rem;
  }

  .xl\:hover\:translate-x-10:hover {
    --transform-translate-x: 2.5rem;
  }

  .xl\:hover\:translate-x-11:hover {
    --transform-translate-x: 2.75rem;
  }

  .xl\:hover\:translate-x-12:hover {
    --transform-translate-x: 3rem;
  }

  .xl\:hover\:translate-x-13:hover {
    --transform-translate-x: 3.25rem;
  }

  .xl\:hover\:translate-x-14:hover {
    --transform-translate-x: 3.5rem;
  }

  .xl\:hover\:translate-x-15:hover {
    --transform-translate-x: 3.75rem;
  }

  .xl\:hover\:translate-x-16:hover {
    --transform-translate-x: 4rem;
  }

  .xl\:hover\:translate-x-20:hover {
    --transform-translate-x: 5rem;
  }

  .xl\:hover\:translate-x-24:hover {
    --transform-translate-x: 6rem;
  }

  .xl\:hover\:translate-x-28:hover {
    --transform-translate-x: 7rem;
  }

  .xl\:hover\:translate-x-32:hover {
    --transform-translate-x: 8rem;
  }

  .xl\:hover\:translate-x-36:hover {
    --transform-translate-x: 9rem;
  }

  .xl\:hover\:translate-x-40:hover {
    --transform-translate-x: 10rem;
  }

  .xl\:hover\:translate-x-44:hover {
    --transform-translate-x: 11rem;
  }

  .xl\:hover\:translate-x-48:hover {
    --transform-translate-x: 12rem;
  }

  .xl\:hover\:translate-x-52:hover {
    --transform-translate-x: 13rem;
  }

  .xl\:hover\:translate-x-56:hover {
    --transform-translate-x: 14rem;
  }

  .xl\:hover\:translate-x-60:hover {
    --transform-translate-x: 15rem;
  }

  .xl\:hover\:translate-x-64:hover {
    --transform-translate-x: 16rem;
  }

  .xl\:hover\:translate-x-72:hover {
    --transform-translate-x: 18rem;
  }

  .xl\:hover\:translate-x-80:hover {
    --transform-translate-x: 20rem;
  }

  .xl\:hover\:translate-x-96:hover {
    --transform-translate-x: 24rem;
  }

  .xl\:hover\:translate-x-px:hover {
    --transform-translate-x: 1px;
  }

  .xl\:hover\:translate-x-0\.5:hover {
    --transform-translate-x: .125rem;
  }

  .xl\:hover\:translate-x-1\.5:hover {
    --transform-translate-x: .375rem;
  }

  .xl\:hover\:translate-x-2\.5:hover {
    --transform-translate-x: .625rem;
  }

  .xl\:hover\:translate-x-3\.5:hover {
    --transform-translate-x: .875rem;
  }

  .xl\:hover\:translate-x-1\/2:hover {
    --transform-translate-x: 50%;
  }

  .xl\:hover\:translate-x-1\/3:hover {
    --transform-translate-x: 33.3333%;
  }

  .xl\:hover\:translate-x-2\/3:hover {
    --transform-translate-x: 66.6667%;
  }

  .xl\:hover\:translate-x-1\/4:hover {
    --transform-translate-x: 25%;
  }

  .xl\:hover\:translate-x-2\/4:hover {
    --transform-translate-x: 50%;
  }

  .xl\:hover\:translate-x-3\/4:hover {
    --transform-translate-x: 75%;
  }

  .xl\:hover\:translate-x-1\/5:hover {
    --transform-translate-x: 20%;
  }

  .xl\:hover\:translate-x-2\/5:hover {
    --transform-translate-x: 40%;
  }

  .xl\:hover\:translate-x-3\/5:hover {
    --transform-translate-x: 60%;
  }

  .xl\:hover\:translate-x-4\/5:hover {
    --transform-translate-x: 80%;
  }

  .xl\:hover\:translate-x-1\/6:hover {
    --transform-translate-x: 16.6667%;
  }

  .xl\:hover\:translate-x-2\/6:hover {
    --transform-translate-x: 33.3333%;
  }

  .xl\:hover\:translate-x-3\/6:hover {
    --transform-translate-x: 50%;
  }

  .xl\:hover\:translate-x-4\/6:hover {
    --transform-translate-x: 66.6667%;
  }

  .xl\:hover\:translate-x-5\/6:hover {
    --transform-translate-x: 83.3333%;
  }

  .xl\:hover\:translate-x-1\/12:hover {
    --transform-translate-x: 8.33333%;
  }

  .xl\:hover\:translate-x-2\/12:hover {
    --transform-translate-x: 16.6667%;
  }

  .xl\:hover\:translate-x-3\/12:hover {
    --transform-translate-x: 25%;
  }

  .xl\:hover\:translate-x-4\/12:hover {
    --transform-translate-x: 33.3333%;
  }

  .xl\:hover\:translate-x-5\/12:hover {
    --transform-translate-x: 41.6667%;
  }

  .xl\:hover\:translate-x-6\/12:hover {
    --transform-translate-x: 50%;
  }

  .xl\:hover\:translate-x-7\/12:hover {
    --transform-translate-x: 58.3333%;
  }

  .xl\:hover\:translate-x-8\/12:hover {
    --transform-translate-x: 66.6667%;
  }

  .xl\:hover\:translate-x-9\/12:hover {
    --transform-translate-x: 75%;
  }

  .xl\:hover\:translate-x-10\/12:hover {
    --transform-translate-x: 83.3333%;
  }

  .xl\:hover\:translate-x-11\/12:hover {
    --transform-translate-x: 91.6667%;
  }

  .xl\:hover\:translate-x-full:hover {
    --transform-translate-x: 100%;
  }

  .xl\:hover\:-translate-x-1:hover {
    --transform-translate-x: -.25rem;
  }

  .xl\:hover\:-translate-x-2:hover {
    --transform-translate-x: -.5rem;
  }

  .xl\:hover\:-translate-x-3:hover {
    --transform-translate-x: -.75rem;
  }

  .xl\:hover\:-translate-x-4:hover {
    --transform-translate-x: -1rem;
  }

  .xl\:hover\:-translate-x-5:hover {
    --transform-translate-x: -1.25rem;
  }

  .xl\:hover\:-translate-x-6:hover {
    --transform-translate-x: -1.5rem;
  }

  .xl\:hover\:-translate-x-7:hover {
    --transform-translate-x: -1.75rem;
  }

  .xl\:hover\:-translate-x-8:hover {
    --transform-translate-x: -2rem;
  }

  .xl\:hover\:-translate-x-9:hover {
    --transform-translate-x: -2.25rem;
  }

  .xl\:hover\:-translate-x-10:hover {
    --transform-translate-x: -2.5rem;
  }

  .xl\:hover\:-translate-x-11:hover {
    --transform-translate-x: -2.75rem;
  }

  .xl\:hover\:-translate-x-12:hover {
    --transform-translate-x: -3rem;
  }

  .xl\:hover\:-translate-x-13:hover {
    --transform-translate-x: -3.25rem;
  }

  .xl\:hover\:-translate-x-14:hover {
    --transform-translate-x: -3.5rem;
  }

  .xl\:hover\:-translate-x-15:hover {
    --transform-translate-x: -3.75rem;
  }

  .xl\:hover\:-translate-x-16:hover {
    --transform-translate-x: -4rem;
  }

  .xl\:hover\:-translate-x-20:hover {
    --transform-translate-x: -5rem;
  }

  .xl\:hover\:-translate-x-24:hover {
    --transform-translate-x: -6rem;
  }

  .xl\:hover\:-translate-x-28:hover {
    --transform-translate-x: -7rem;
  }

  .xl\:hover\:-translate-x-32:hover {
    --transform-translate-x: -8rem;
  }

  .xl\:hover\:-translate-x-36:hover {
    --transform-translate-x: -9rem;
  }

  .xl\:hover\:-translate-x-40:hover {
    --transform-translate-x: -10rem;
  }

  .xl\:hover\:-translate-x-44:hover {
    --transform-translate-x: -11rem;
  }

  .xl\:hover\:-translate-x-48:hover {
    --transform-translate-x: -12rem;
  }

  .xl\:hover\:-translate-x-52:hover {
    --transform-translate-x: -13rem;
  }

  .xl\:hover\:-translate-x-56:hover {
    --transform-translate-x: -14rem;
  }

  .xl\:hover\:-translate-x-60:hover {
    --transform-translate-x: -15rem;
  }

  .xl\:hover\:-translate-x-64:hover {
    --transform-translate-x: -16rem;
  }

  .xl\:hover\:-translate-x-72:hover {
    --transform-translate-x: -18rem;
  }

  .xl\:hover\:-translate-x-80:hover {
    --transform-translate-x: -20rem;
  }

  .xl\:hover\:-translate-x-96:hover {
    --transform-translate-x: -24rem;
  }

  .xl\:hover\:-translate-x-px:hover {
    --transform-translate-x: -1px;
  }

  .xl\:hover\:-translate-x-0\.5:hover {
    --transform-translate-x: -.125rem;
  }

  .xl\:hover\:-translate-x-1\.5:hover {
    --transform-translate-x: -.375rem;
  }

  .xl\:hover\:-translate-x-2\.5:hover {
    --transform-translate-x: -.625rem;
  }

  .xl\:hover\:-translate-x-3\.5:hover {
    --transform-translate-x: -.875rem;
  }

  .xl\:hover\:-translate-x-1\/2:hover {
    --transform-translate-x: -50%;
  }

  .xl\:hover\:-translate-x-1\/3:hover {
    --transform-translate-x: -33.3333%;
  }

  .xl\:hover\:-translate-x-2\/3:hover {
    --transform-translate-x: -66.6667%;
  }

  .xl\:hover\:-translate-x-1\/4:hover {
    --transform-translate-x: -25%;
  }

  .xl\:hover\:-translate-x-2\/4:hover {
    --transform-translate-x: -50%;
  }

  .xl\:hover\:-translate-x-3\/4:hover {
    --transform-translate-x: -75%;
  }

  .xl\:hover\:-translate-x-1\/5:hover {
    --transform-translate-x: -20%;
  }

  .xl\:hover\:-translate-x-2\/5:hover {
    --transform-translate-x: -40%;
  }

  .xl\:hover\:-translate-x-3\/5:hover {
    --transform-translate-x: -60%;
  }

  .xl\:hover\:-translate-x-4\/5:hover {
    --transform-translate-x: -80%;
  }

  .xl\:hover\:-translate-x-1\/6:hover {
    --transform-translate-x: -16.6667%;
  }

  .xl\:hover\:-translate-x-2\/6:hover {
    --transform-translate-x: -33.3333%;
  }

  .xl\:hover\:-translate-x-3\/6:hover {
    --transform-translate-x: -50%;
  }

  .xl\:hover\:-translate-x-4\/6:hover {
    --transform-translate-x: -66.6667%;
  }

  .xl\:hover\:-translate-x-5\/6:hover {
    --transform-translate-x: -83.3333%;
  }

  .xl\:hover\:-translate-x-1\/12:hover {
    --transform-translate-x: -8.33333%;
  }

  .xl\:hover\:-translate-x-2\/12:hover {
    --transform-translate-x: -16.6667%;
  }

  .xl\:hover\:-translate-x-3\/12:hover {
    --transform-translate-x: -25%;
  }

  .xl\:hover\:-translate-x-4\/12:hover {
    --transform-translate-x: -33.3333%;
  }

  .xl\:hover\:-translate-x-5\/12:hover {
    --transform-translate-x: -41.6667%;
  }

  .xl\:hover\:-translate-x-6\/12:hover {
    --transform-translate-x: -50%;
  }

  .xl\:hover\:-translate-x-7\/12:hover {
    --transform-translate-x: -58.3333%;
  }

  .xl\:hover\:-translate-x-8\/12:hover {
    --transform-translate-x: -66.6667%;
  }

  .xl\:hover\:-translate-x-9\/12:hover {
    --transform-translate-x: -75%;
  }

  .xl\:hover\:-translate-x-10\/12:hover {
    --transform-translate-x: -83.3333%;
  }

  .xl\:hover\:-translate-x-11\/12:hover {
    --transform-translate-x: -91.6667%;
  }

  .xl\:hover\:-translate-x-full:hover {
    --transform-translate-x: -100%;
  }

  .xl\:hover\:translate-y-0:hover {
    --transform-translate-y: 0;
  }

  .xl\:hover\:translate-y-1:hover {
    --transform-translate-y: .25rem;
  }

  .xl\:hover\:translate-y-2:hover {
    --transform-translate-y: .5rem;
  }

  .xl\:hover\:translate-y-3:hover {
    --transform-translate-y: .75rem;
  }

  .xl\:hover\:translate-y-4:hover {
    --transform-translate-y: 1rem;
  }

  .xl\:hover\:translate-y-5:hover {
    --transform-translate-y: 1.25rem;
  }

  .xl\:hover\:translate-y-6:hover {
    --transform-translate-y: 1.5rem;
  }

  .xl\:hover\:translate-y-7:hover {
    --transform-translate-y: 1.75rem;
  }

  .xl\:hover\:translate-y-8:hover {
    --transform-translate-y: 2rem;
  }

  .xl\:hover\:translate-y-9:hover {
    --transform-translate-y: 2.25rem;
  }

  .xl\:hover\:translate-y-10:hover {
    --transform-translate-y: 2.5rem;
  }

  .xl\:hover\:translate-y-11:hover {
    --transform-translate-y: 2.75rem;
  }

  .xl\:hover\:translate-y-12:hover {
    --transform-translate-y: 3rem;
  }

  .xl\:hover\:translate-y-13:hover {
    --transform-translate-y: 3.25rem;
  }

  .xl\:hover\:translate-y-14:hover {
    --transform-translate-y: 3.5rem;
  }

  .xl\:hover\:translate-y-15:hover {
    --transform-translate-y: 3.75rem;
  }

  .xl\:hover\:translate-y-16:hover {
    --transform-translate-y: 4rem;
  }

  .xl\:hover\:translate-y-20:hover {
    --transform-translate-y: 5rem;
  }

  .xl\:hover\:translate-y-24:hover {
    --transform-translate-y: 6rem;
  }

  .xl\:hover\:translate-y-28:hover {
    --transform-translate-y: 7rem;
  }

  .xl\:hover\:translate-y-32:hover {
    --transform-translate-y: 8rem;
  }

  .xl\:hover\:translate-y-36:hover {
    --transform-translate-y: 9rem;
  }

  .xl\:hover\:translate-y-40:hover {
    --transform-translate-y: 10rem;
  }

  .xl\:hover\:translate-y-44:hover {
    --transform-translate-y: 11rem;
  }

  .xl\:hover\:translate-y-48:hover {
    --transform-translate-y: 12rem;
  }

  .xl\:hover\:translate-y-52:hover {
    --transform-translate-y: 13rem;
  }

  .xl\:hover\:translate-y-56:hover {
    --transform-translate-y: 14rem;
  }

  .xl\:hover\:translate-y-60:hover {
    --transform-translate-y: 15rem;
  }

  .xl\:hover\:translate-y-64:hover {
    --transform-translate-y: 16rem;
  }

  .xl\:hover\:translate-y-72:hover {
    --transform-translate-y: 18rem;
  }

  .xl\:hover\:translate-y-80:hover {
    --transform-translate-y: 20rem;
  }

  .xl\:hover\:translate-y-96:hover {
    --transform-translate-y: 24rem;
  }

  .xl\:hover\:translate-y-px:hover {
    --transform-translate-y: 1px;
  }

  .xl\:hover\:translate-y-0\.5:hover {
    --transform-translate-y: .125rem;
  }

  .xl\:hover\:translate-y-1\.5:hover {
    --transform-translate-y: .375rem;
  }

  .xl\:hover\:translate-y-2\.5:hover {
    --transform-translate-y: .625rem;
  }

  .xl\:hover\:translate-y-3\.5:hover {
    --transform-translate-y: .875rem;
  }

  .xl\:hover\:translate-y-1\/2:hover {
    --transform-translate-y: 50%;
  }

  .xl\:hover\:translate-y-1\/3:hover {
    --transform-translate-y: 33.3333%;
  }

  .xl\:hover\:translate-y-2\/3:hover {
    --transform-translate-y: 66.6667%;
  }

  .xl\:hover\:translate-y-1\/4:hover {
    --transform-translate-y: 25%;
  }

  .xl\:hover\:translate-y-2\/4:hover {
    --transform-translate-y: 50%;
  }

  .xl\:hover\:translate-y-3\/4:hover {
    --transform-translate-y: 75%;
  }

  .xl\:hover\:translate-y-1\/5:hover {
    --transform-translate-y: 20%;
  }

  .xl\:hover\:translate-y-2\/5:hover {
    --transform-translate-y: 40%;
  }

  .xl\:hover\:translate-y-3\/5:hover {
    --transform-translate-y: 60%;
  }

  .xl\:hover\:translate-y-4\/5:hover {
    --transform-translate-y: 80%;
  }

  .xl\:hover\:translate-y-1\/6:hover {
    --transform-translate-y: 16.6667%;
  }

  .xl\:hover\:translate-y-2\/6:hover {
    --transform-translate-y: 33.3333%;
  }

  .xl\:hover\:translate-y-3\/6:hover {
    --transform-translate-y: 50%;
  }

  .xl\:hover\:translate-y-4\/6:hover {
    --transform-translate-y: 66.6667%;
  }

  .xl\:hover\:translate-y-5\/6:hover {
    --transform-translate-y: 83.3333%;
  }

  .xl\:hover\:translate-y-1\/12:hover {
    --transform-translate-y: 8.33333%;
  }

  .xl\:hover\:translate-y-2\/12:hover {
    --transform-translate-y: 16.6667%;
  }

  .xl\:hover\:translate-y-3\/12:hover {
    --transform-translate-y: 25%;
  }

  .xl\:hover\:translate-y-4\/12:hover {
    --transform-translate-y: 33.3333%;
  }

  .xl\:hover\:translate-y-5\/12:hover {
    --transform-translate-y: 41.6667%;
  }

  .xl\:hover\:translate-y-6\/12:hover {
    --transform-translate-y: 50%;
  }

  .xl\:hover\:translate-y-7\/12:hover {
    --transform-translate-y: 58.3333%;
  }

  .xl\:hover\:translate-y-8\/12:hover {
    --transform-translate-y: 66.6667%;
  }

  .xl\:hover\:translate-y-9\/12:hover {
    --transform-translate-y: 75%;
  }

  .xl\:hover\:translate-y-10\/12:hover {
    --transform-translate-y: 83.3333%;
  }

  .xl\:hover\:translate-y-11\/12:hover {
    --transform-translate-y: 91.6667%;
  }

  .xl\:hover\:translate-y-full:hover {
    --transform-translate-y: 100%;
  }

  .xl\:hover\:-translate-y-1:hover {
    --transform-translate-y: -.25rem;
  }

  .xl\:hover\:-translate-y-2:hover {
    --transform-translate-y: -.5rem;
  }

  .xl\:hover\:-translate-y-3:hover {
    --transform-translate-y: -.75rem;
  }

  .xl\:hover\:-translate-y-4:hover {
    --transform-translate-y: -1rem;
  }

  .xl\:hover\:-translate-y-5:hover {
    --transform-translate-y: -1.25rem;
  }

  .xl\:hover\:-translate-y-6:hover {
    --transform-translate-y: -1.5rem;
  }

  .xl\:hover\:-translate-y-7:hover {
    --transform-translate-y: -1.75rem;
  }

  .xl\:hover\:-translate-y-8:hover {
    --transform-translate-y: -2rem;
  }

  .xl\:hover\:-translate-y-9:hover {
    --transform-translate-y: -2.25rem;
  }

  .xl\:hover\:-translate-y-10:hover {
    --transform-translate-y: -2.5rem;
  }

  .xl\:hover\:-translate-y-11:hover {
    --transform-translate-y: -2.75rem;
  }

  .xl\:hover\:-translate-y-12:hover {
    --transform-translate-y: -3rem;
  }

  .xl\:hover\:-translate-y-13:hover {
    --transform-translate-y: -3.25rem;
  }

  .xl\:hover\:-translate-y-14:hover {
    --transform-translate-y: -3.5rem;
  }

  .xl\:hover\:-translate-y-15:hover {
    --transform-translate-y: -3.75rem;
  }

  .xl\:hover\:-translate-y-16:hover {
    --transform-translate-y: -4rem;
  }

  .xl\:hover\:-translate-y-20:hover {
    --transform-translate-y: -5rem;
  }

  .xl\:hover\:-translate-y-24:hover {
    --transform-translate-y: -6rem;
  }

  .xl\:hover\:-translate-y-28:hover {
    --transform-translate-y: -7rem;
  }

  .xl\:hover\:-translate-y-32:hover {
    --transform-translate-y: -8rem;
  }

  .xl\:hover\:-translate-y-36:hover {
    --transform-translate-y: -9rem;
  }

  .xl\:hover\:-translate-y-40:hover {
    --transform-translate-y: -10rem;
  }

  .xl\:hover\:-translate-y-44:hover {
    --transform-translate-y: -11rem;
  }

  .xl\:hover\:-translate-y-48:hover {
    --transform-translate-y: -12rem;
  }

  .xl\:hover\:-translate-y-52:hover {
    --transform-translate-y: -13rem;
  }

  .xl\:hover\:-translate-y-56:hover {
    --transform-translate-y: -14rem;
  }

  .xl\:hover\:-translate-y-60:hover {
    --transform-translate-y: -15rem;
  }

  .xl\:hover\:-translate-y-64:hover {
    --transform-translate-y: -16rem;
  }

  .xl\:hover\:-translate-y-72:hover {
    --transform-translate-y: -18rem;
  }

  .xl\:hover\:-translate-y-80:hover {
    --transform-translate-y: -20rem;
  }

  .xl\:hover\:-translate-y-96:hover {
    --transform-translate-y: -24rem;
  }

  .xl\:hover\:-translate-y-px:hover {
    --transform-translate-y: -1px;
  }

  .xl\:hover\:-translate-y-0\.5:hover {
    --transform-translate-y: -.125rem;
  }

  .xl\:hover\:-translate-y-1\.5:hover {
    --transform-translate-y: -.375rem;
  }

  .xl\:hover\:-translate-y-2\.5:hover {
    --transform-translate-y: -.625rem;
  }

  .xl\:hover\:-translate-y-3\.5:hover {
    --transform-translate-y: -.875rem;
  }

  .xl\:hover\:-translate-y-1\/2:hover {
    --transform-translate-y: -50%;
  }

  .xl\:hover\:-translate-y-1\/3:hover {
    --transform-translate-y: -33.3333%;
  }

  .xl\:hover\:-translate-y-2\/3:hover {
    --transform-translate-y: -66.6667%;
  }

  .xl\:hover\:-translate-y-1\/4:hover {
    --transform-translate-y: -25%;
  }

  .xl\:hover\:-translate-y-2\/4:hover {
    --transform-translate-y: -50%;
  }

  .xl\:hover\:-translate-y-3\/4:hover {
    --transform-translate-y: -75%;
  }

  .xl\:hover\:-translate-y-1\/5:hover {
    --transform-translate-y: -20%;
  }

  .xl\:hover\:-translate-y-2\/5:hover {
    --transform-translate-y: -40%;
  }

  .xl\:hover\:-translate-y-3\/5:hover {
    --transform-translate-y: -60%;
  }

  .xl\:hover\:-translate-y-4\/5:hover {
    --transform-translate-y: -80%;
  }

  .xl\:hover\:-translate-y-1\/6:hover {
    --transform-translate-y: -16.6667%;
  }

  .xl\:hover\:-translate-y-2\/6:hover {
    --transform-translate-y: -33.3333%;
  }

  .xl\:hover\:-translate-y-3\/6:hover {
    --transform-translate-y: -50%;
  }

  .xl\:hover\:-translate-y-4\/6:hover {
    --transform-translate-y: -66.6667%;
  }

  .xl\:hover\:-translate-y-5\/6:hover {
    --transform-translate-y: -83.3333%;
  }

  .xl\:hover\:-translate-y-1\/12:hover {
    --transform-translate-y: -8.33333%;
  }

  .xl\:hover\:-translate-y-2\/12:hover {
    --transform-translate-y: -16.6667%;
  }

  .xl\:hover\:-translate-y-3\/12:hover {
    --transform-translate-y: -25%;
  }

  .xl\:hover\:-translate-y-4\/12:hover {
    --transform-translate-y: -33.3333%;
  }

  .xl\:hover\:-translate-y-5\/12:hover {
    --transform-translate-y: -41.6667%;
  }

  .xl\:hover\:-translate-y-6\/12:hover {
    --transform-translate-y: -50%;
  }

  .xl\:hover\:-translate-y-7\/12:hover {
    --transform-translate-y: -58.3333%;
  }

  .xl\:hover\:-translate-y-8\/12:hover {
    --transform-translate-y: -66.6667%;
  }

  .xl\:hover\:-translate-y-9\/12:hover {
    --transform-translate-y: -75%;
  }

  .xl\:hover\:-translate-y-10\/12:hover {
    --transform-translate-y: -83.3333%;
  }

  .xl\:hover\:-translate-y-11\/12:hover {
    --transform-translate-y: -91.6667%;
  }

  .xl\:hover\:-translate-y-full:hover {
    --transform-translate-y: -100%;
  }

  .xl\:focus\:translate-x-0:focus {
    --transform-translate-x: 0;
  }

  .xl\:focus\:translate-x-1:focus {
    --transform-translate-x: .25rem;
  }

  .xl\:focus\:translate-x-2:focus {
    --transform-translate-x: .5rem;
  }

  .xl\:focus\:translate-x-3:focus {
    --transform-translate-x: .75rem;
  }

  .xl\:focus\:translate-x-4:focus {
    --transform-translate-x: 1rem;
  }

  .xl\:focus\:translate-x-5:focus {
    --transform-translate-x: 1.25rem;
  }

  .xl\:focus\:translate-x-6:focus {
    --transform-translate-x: 1.5rem;
  }

  .xl\:focus\:translate-x-7:focus {
    --transform-translate-x: 1.75rem;
  }

  .xl\:focus\:translate-x-8:focus {
    --transform-translate-x: 2rem;
  }

  .xl\:focus\:translate-x-9:focus {
    --transform-translate-x: 2.25rem;
  }

  .xl\:focus\:translate-x-10:focus {
    --transform-translate-x: 2.5rem;
  }

  .xl\:focus\:translate-x-11:focus {
    --transform-translate-x: 2.75rem;
  }

  .xl\:focus\:translate-x-12:focus {
    --transform-translate-x: 3rem;
  }

  .xl\:focus\:translate-x-13:focus {
    --transform-translate-x: 3.25rem;
  }

  .xl\:focus\:translate-x-14:focus {
    --transform-translate-x: 3.5rem;
  }

  .xl\:focus\:translate-x-15:focus {
    --transform-translate-x: 3.75rem;
  }

  .xl\:focus\:translate-x-16:focus {
    --transform-translate-x: 4rem;
  }

  .xl\:focus\:translate-x-20:focus {
    --transform-translate-x: 5rem;
  }

  .xl\:focus\:translate-x-24:focus {
    --transform-translate-x: 6rem;
  }

  .xl\:focus\:translate-x-28:focus {
    --transform-translate-x: 7rem;
  }

  .xl\:focus\:translate-x-32:focus {
    --transform-translate-x: 8rem;
  }

  .xl\:focus\:translate-x-36:focus {
    --transform-translate-x: 9rem;
  }

  .xl\:focus\:translate-x-40:focus {
    --transform-translate-x: 10rem;
  }

  .xl\:focus\:translate-x-44:focus {
    --transform-translate-x: 11rem;
  }

  .xl\:focus\:translate-x-48:focus {
    --transform-translate-x: 12rem;
  }

  .xl\:focus\:translate-x-52:focus {
    --transform-translate-x: 13rem;
  }

  .xl\:focus\:translate-x-56:focus {
    --transform-translate-x: 14rem;
  }

  .xl\:focus\:translate-x-60:focus {
    --transform-translate-x: 15rem;
  }

  .xl\:focus\:translate-x-64:focus {
    --transform-translate-x: 16rem;
  }

  .xl\:focus\:translate-x-72:focus {
    --transform-translate-x: 18rem;
  }

  .xl\:focus\:translate-x-80:focus {
    --transform-translate-x: 20rem;
  }

  .xl\:focus\:translate-x-96:focus {
    --transform-translate-x: 24rem;
  }

  .xl\:focus\:translate-x-px:focus {
    --transform-translate-x: 1px;
  }

  .xl\:focus\:translate-x-0\.5:focus {
    --transform-translate-x: .125rem;
  }

  .xl\:focus\:translate-x-1\.5:focus {
    --transform-translate-x: .375rem;
  }

  .xl\:focus\:translate-x-2\.5:focus {
    --transform-translate-x: .625rem;
  }

  .xl\:focus\:translate-x-3\.5:focus {
    --transform-translate-x: .875rem;
  }

  .xl\:focus\:translate-x-1\/2:focus {
    --transform-translate-x: 50%;
  }

  .xl\:focus\:translate-x-1\/3:focus {
    --transform-translate-x: 33.3333%;
  }

  .xl\:focus\:translate-x-2\/3:focus {
    --transform-translate-x: 66.6667%;
  }

  .xl\:focus\:translate-x-1\/4:focus {
    --transform-translate-x: 25%;
  }

  .xl\:focus\:translate-x-2\/4:focus {
    --transform-translate-x: 50%;
  }

  .xl\:focus\:translate-x-3\/4:focus {
    --transform-translate-x: 75%;
  }

  .xl\:focus\:translate-x-1\/5:focus {
    --transform-translate-x: 20%;
  }

  .xl\:focus\:translate-x-2\/5:focus {
    --transform-translate-x: 40%;
  }

  .xl\:focus\:translate-x-3\/5:focus {
    --transform-translate-x: 60%;
  }

  .xl\:focus\:translate-x-4\/5:focus {
    --transform-translate-x: 80%;
  }

  .xl\:focus\:translate-x-1\/6:focus {
    --transform-translate-x: 16.6667%;
  }

  .xl\:focus\:translate-x-2\/6:focus {
    --transform-translate-x: 33.3333%;
  }

  .xl\:focus\:translate-x-3\/6:focus {
    --transform-translate-x: 50%;
  }

  .xl\:focus\:translate-x-4\/6:focus {
    --transform-translate-x: 66.6667%;
  }

  .xl\:focus\:translate-x-5\/6:focus {
    --transform-translate-x: 83.3333%;
  }

  .xl\:focus\:translate-x-1\/12:focus {
    --transform-translate-x: 8.33333%;
  }

  .xl\:focus\:translate-x-2\/12:focus {
    --transform-translate-x: 16.6667%;
  }

  .xl\:focus\:translate-x-3\/12:focus {
    --transform-translate-x: 25%;
  }

  .xl\:focus\:translate-x-4\/12:focus {
    --transform-translate-x: 33.3333%;
  }

  .xl\:focus\:translate-x-5\/12:focus {
    --transform-translate-x: 41.6667%;
  }

  .xl\:focus\:translate-x-6\/12:focus {
    --transform-translate-x: 50%;
  }

  .xl\:focus\:translate-x-7\/12:focus {
    --transform-translate-x: 58.3333%;
  }

  .xl\:focus\:translate-x-8\/12:focus {
    --transform-translate-x: 66.6667%;
  }

  .xl\:focus\:translate-x-9\/12:focus {
    --transform-translate-x: 75%;
  }

  .xl\:focus\:translate-x-10\/12:focus {
    --transform-translate-x: 83.3333%;
  }

  .xl\:focus\:translate-x-11\/12:focus {
    --transform-translate-x: 91.6667%;
  }

  .xl\:focus\:translate-x-full:focus {
    --transform-translate-x: 100%;
  }

  .xl\:focus\:-translate-x-1:focus {
    --transform-translate-x: -.25rem;
  }

  .xl\:focus\:-translate-x-2:focus {
    --transform-translate-x: -.5rem;
  }

  .xl\:focus\:-translate-x-3:focus {
    --transform-translate-x: -.75rem;
  }

  .xl\:focus\:-translate-x-4:focus {
    --transform-translate-x: -1rem;
  }

  .xl\:focus\:-translate-x-5:focus {
    --transform-translate-x: -1.25rem;
  }

  .xl\:focus\:-translate-x-6:focus {
    --transform-translate-x: -1.5rem;
  }

  .xl\:focus\:-translate-x-7:focus {
    --transform-translate-x: -1.75rem;
  }

  .xl\:focus\:-translate-x-8:focus {
    --transform-translate-x: -2rem;
  }

  .xl\:focus\:-translate-x-9:focus {
    --transform-translate-x: -2.25rem;
  }

  .xl\:focus\:-translate-x-10:focus {
    --transform-translate-x: -2.5rem;
  }

  .xl\:focus\:-translate-x-11:focus {
    --transform-translate-x: -2.75rem;
  }

  .xl\:focus\:-translate-x-12:focus {
    --transform-translate-x: -3rem;
  }

  .xl\:focus\:-translate-x-13:focus {
    --transform-translate-x: -3.25rem;
  }

  .xl\:focus\:-translate-x-14:focus {
    --transform-translate-x: -3.5rem;
  }

  .xl\:focus\:-translate-x-15:focus {
    --transform-translate-x: -3.75rem;
  }

  .xl\:focus\:-translate-x-16:focus {
    --transform-translate-x: -4rem;
  }

  .xl\:focus\:-translate-x-20:focus {
    --transform-translate-x: -5rem;
  }

  .xl\:focus\:-translate-x-24:focus {
    --transform-translate-x: -6rem;
  }

  .xl\:focus\:-translate-x-28:focus {
    --transform-translate-x: -7rem;
  }

  .xl\:focus\:-translate-x-32:focus {
    --transform-translate-x: -8rem;
  }

  .xl\:focus\:-translate-x-36:focus {
    --transform-translate-x: -9rem;
  }

  .xl\:focus\:-translate-x-40:focus {
    --transform-translate-x: -10rem;
  }

  .xl\:focus\:-translate-x-44:focus {
    --transform-translate-x: -11rem;
  }

  .xl\:focus\:-translate-x-48:focus {
    --transform-translate-x: -12rem;
  }

  .xl\:focus\:-translate-x-52:focus {
    --transform-translate-x: -13rem;
  }

  .xl\:focus\:-translate-x-56:focus {
    --transform-translate-x: -14rem;
  }

  .xl\:focus\:-translate-x-60:focus {
    --transform-translate-x: -15rem;
  }

  .xl\:focus\:-translate-x-64:focus {
    --transform-translate-x: -16rem;
  }

  .xl\:focus\:-translate-x-72:focus {
    --transform-translate-x: -18rem;
  }

  .xl\:focus\:-translate-x-80:focus {
    --transform-translate-x: -20rem;
  }

  .xl\:focus\:-translate-x-96:focus {
    --transform-translate-x: -24rem;
  }

  .xl\:focus\:-translate-x-px:focus {
    --transform-translate-x: -1px;
  }

  .xl\:focus\:-translate-x-0\.5:focus {
    --transform-translate-x: -.125rem;
  }

  .xl\:focus\:-translate-x-1\.5:focus {
    --transform-translate-x: -.375rem;
  }

  .xl\:focus\:-translate-x-2\.5:focus {
    --transform-translate-x: -.625rem;
  }

  .xl\:focus\:-translate-x-3\.5:focus {
    --transform-translate-x: -.875rem;
  }

  .xl\:focus\:-translate-x-1\/2:focus {
    --transform-translate-x: -50%;
  }

  .xl\:focus\:-translate-x-1\/3:focus {
    --transform-translate-x: -33.3333%;
  }

  .xl\:focus\:-translate-x-2\/3:focus {
    --transform-translate-x: -66.6667%;
  }

  .xl\:focus\:-translate-x-1\/4:focus {
    --transform-translate-x: -25%;
  }

  .xl\:focus\:-translate-x-2\/4:focus {
    --transform-translate-x: -50%;
  }

  .xl\:focus\:-translate-x-3\/4:focus {
    --transform-translate-x: -75%;
  }

  .xl\:focus\:-translate-x-1\/5:focus {
    --transform-translate-x: -20%;
  }

  .xl\:focus\:-translate-x-2\/5:focus {
    --transform-translate-x: -40%;
  }

  .xl\:focus\:-translate-x-3\/5:focus {
    --transform-translate-x: -60%;
  }

  .xl\:focus\:-translate-x-4\/5:focus {
    --transform-translate-x: -80%;
  }

  .xl\:focus\:-translate-x-1\/6:focus {
    --transform-translate-x: -16.6667%;
  }

  .xl\:focus\:-translate-x-2\/6:focus {
    --transform-translate-x: -33.3333%;
  }

  .xl\:focus\:-translate-x-3\/6:focus {
    --transform-translate-x: -50%;
  }

  .xl\:focus\:-translate-x-4\/6:focus {
    --transform-translate-x: -66.6667%;
  }

  .xl\:focus\:-translate-x-5\/6:focus {
    --transform-translate-x: -83.3333%;
  }

  .xl\:focus\:-translate-x-1\/12:focus {
    --transform-translate-x: -8.33333%;
  }

  .xl\:focus\:-translate-x-2\/12:focus {
    --transform-translate-x: -16.6667%;
  }

  .xl\:focus\:-translate-x-3\/12:focus {
    --transform-translate-x: -25%;
  }

  .xl\:focus\:-translate-x-4\/12:focus {
    --transform-translate-x: -33.3333%;
  }

  .xl\:focus\:-translate-x-5\/12:focus {
    --transform-translate-x: -41.6667%;
  }

  .xl\:focus\:-translate-x-6\/12:focus {
    --transform-translate-x: -50%;
  }

  .xl\:focus\:-translate-x-7\/12:focus {
    --transform-translate-x: -58.3333%;
  }

  .xl\:focus\:-translate-x-8\/12:focus {
    --transform-translate-x: -66.6667%;
  }

  .xl\:focus\:-translate-x-9\/12:focus {
    --transform-translate-x: -75%;
  }

  .xl\:focus\:-translate-x-10\/12:focus {
    --transform-translate-x: -83.3333%;
  }

  .xl\:focus\:-translate-x-11\/12:focus {
    --transform-translate-x: -91.6667%;
  }

  .xl\:focus\:-translate-x-full:focus {
    --transform-translate-x: -100%;
  }

  .xl\:focus\:translate-y-0:focus {
    --transform-translate-y: 0;
  }

  .xl\:focus\:translate-y-1:focus {
    --transform-translate-y: .25rem;
  }

  .xl\:focus\:translate-y-2:focus {
    --transform-translate-y: .5rem;
  }

  .xl\:focus\:translate-y-3:focus {
    --transform-translate-y: .75rem;
  }

  .xl\:focus\:translate-y-4:focus {
    --transform-translate-y: 1rem;
  }

  .xl\:focus\:translate-y-5:focus {
    --transform-translate-y: 1.25rem;
  }

  .xl\:focus\:translate-y-6:focus {
    --transform-translate-y: 1.5rem;
  }

  .xl\:focus\:translate-y-7:focus {
    --transform-translate-y: 1.75rem;
  }

  .xl\:focus\:translate-y-8:focus {
    --transform-translate-y: 2rem;
  }

  .xl\:focus\:translate-y-9:focus {
    --transform-translate-y: 2.25rem;
  }

  .xl\:focus\:translate-y-10:focus {
    --transform-translate-y: 2.5rem;
  }

  .xl\:focus\:translate-y-11:focus {
    --transform-translate-y: 2.75rem;
  }

  .xl\:focus\:translate-y-12:focus {
    --transform-translate-y: 3rem;
  }

  .xl\:focus\:translate-y-13:focus {
    --transform-translate-y: 3.25rem;
  }

  .xl\:focus\:translate-y-14:focus {
    --transform-translate-y: 3.5rem;
  }

  .xl\:focus\:translate-y-15:focus {
    --transform-translate-y: 3.75rem;
  }

  .xl\:focus\:translate-y-16:focus {
    --transform-translate-y: 4rem;
  }

  .xl\:focus\:translate-y-20:focus {
    --transform-translate-y: 5rem;
  }

  .xl\:focus\:translate-y-24:focus {
    --transform-translate-y: 6rem;
  }

  .xl\:focus\:translate-y-28:focus {
    --transform-translate-y: 7rem;
  }

  .xl\:focus\:translate-y-32:focus {
    --transform-translate-y: 8rem;
  }

  .xl\:focus\:translate-y-36:focus {
    --transform-translate-y: 9rem;
  }

  .xl\:focus\:translate-y-40:focus {
    --transform-translate-y: 10rem;
  }

  .xl\:focus\:translate-y-44:focus {
    --transform-translate-y: 11rem;
  }

  .xl\:focus\:translate-y-48:focus {
    --transform-translate-y: 12rem;
  }

  .xl\:focus\:translate-y-52:focus {
    --transform-translate-y: 13rem;
  }

  .xl\:focus\:translate-y-56:focus {
    --transform-translate-y: 14rem;
  }

  .xl\:focus\:translate-y-60:focus {
    --transform-translate-y: 15rem;
  }

  .xl\:focus\:translate-y-64:focus {
    --transform-translate-y: 16rem;
  }

  .xl\:focus\:translate-y-72:focus {
    --transform-translate-y: 18rem;
  }

  .xl\:focus\:translate-y-80:focus {
    --transform-translate-y: 20rem;
  }

  .xl\:focus\:translate-y-96:focus {
    --transform-translate-y: 24rem;
  }

  .xl\:focus\:translate-y-px:focus {
    --transform-translate-y: 1px;
  }

  .xl\:focus\:translate-y-0\.5:focus {
    --transform-translate-y: .125rem;
  }

  .xl\:focus\:translate-y-1\.5:focus {
    --transform-translate-y: .375rem;
  }

  .xl\:focus\:translate-y-2\.5:focus {
    --transform-translate-y: .625rem;
  }

  .xl\:focus\:translate-y-3\.5:focus {
    --transform-translate-y: .875rem;
  }

  .xl\:focus\:translate-y-1\/2:focus {
    --transform-translate-y: 50%;
  }

  .xl\:focus\:translate-y-1\/3:focus {
    --transform-translate-y: 33.3333%;
  }

  .xl\:focus\:translate-y-2\/3:focus {
    --transform-translate-y: 66.6667%;
  }

  .xl\:focus\:translate-y-1\/4:focus {
    --transform-translate-y: 25%;
  }

  .xl\:focus\:translate-y-2\/4:focus {
    --transform-translate-y: 50%;
  }

  .xl\:focus\:translate-y-3\/4:focus {
    --transform-translate-y: 75%;
  }

  .xl\:focus\:translate-y-1\/5:focus {
    --transform-translate-y: 20%;
  }

  .xl\:focus\:translate-y-2\/5:focus {
    --transform-translate-y: 40%;
  }

  .xl\:focus\:translate-y-3\/5:focus {
    --transform-translate-y: 60%;
  }

  .xl\:focus\:translate-y-4\/5:focus {
    --transform-translate-y: 80%;
  }

  .xl\:focus\:translate-y-1\/6:focus {
    --transform-translate-y: 16.6667%;
  }

  .xl\:focus\:translate-y-2\/6:focus {
    --transform-translate-y: 33.3333%;
  }

  .xl\:focus\:translate-y-3\/6:focus {
    --transform-translate-y: 50%;
  }

  .xl\:focus\:translate-y-4\/6:focus {
    --transform-translate-y: 66.6667%;
  }

  .xl\:focus\:translate-y-5\/6:focus {
    --transform-translate-y: 83.3333%;
  }

  .xl\:focus\:translate-y-1\/12:focus {
    --transform-translate-y: 8.33333%;
  }

  .xl\:focus\:translate-y-2\/12:focus {
    --transform-translate-y: 16.6667%;
  }

  .xl\:focus\:translate-y-3\/12:focus {
    --transform-translate-y: 25%;
  }

  .xl\:focus\:translate-y-4\/12:focus {
    --transform-translate-y: 33.3333%;
  }

  .xl\:focus\:translate-y-5\/12:focus {
    --transform-translate-y: 41.6667%;
  }

  .xl\:focus\:translate-y-6\/12:focus {
    --transform-translate-y: 50%;
  }

  .xl\:focus\:translate-y-7\/12:focus {
    --transform-translate-y: 58.3333%;
  }

  .xl\:focus\:translate-y-8\/12:focus {
    --transform-translate-y: 66.6667%;
  }

  .xl\:focus\:translate-y-9\/12:focus {
    --transform-translate-y: 75%;
  }

  .xl\:focus\:translate-y-10\/12:focus {
    --transform-translate-y: 83.3333%;
  }

  .xl\:focus\:translate-y-11\/12:focus {
    --transform-translate-y: 91.6667%;
  }

  .xl\:focus\:translate-y-full:focus {
    --transform-translate-y: 100%;
  }

  .xl\:focus\:-translate-y-1:focus {
    --transform-translate-y: -.25rem;
  }

  .xl\:focus\:-translate-y-2:focus {
    --transform-translate-y: -.5rem;
  }

  .xl\:focus\:-translate-y-3:focus {
    --transform-translate-y: -.75rem;
  }

  .xl\:focus\:-translate-y-4:focus {
    --transform-translate-y: -1rem;
  }

  .xl\:focus\:-translate-y-5:focus {
    --transform-translate-y: -1.25rem;
  }

  .xl\:focus\:-translate-y-6:focus {
    --transform-translate-y: -1.5rem;
  }

  .xl\:focus\:-translate-y-7:focus {
    --transform-translate-y: -1.75rem;
  }

  .xl\:focus\:-translate-y-8:focus {
    --transform-translate-y: -2rem;
  }

  .xl\:focus\:-translate-y-9:focus {
    --transform-translate-y: -2.25rem;
  }

  .xl\:focus\:-translate-y-10:focus {
    --transform-translate-y: -2.5rem;
  }

  .xl\:focus\:-translate-y-11:focus {
    --transform-translate-y: -2.75rem;
  }

  .xl\:focus\:-translate-y-12:focus {
    --transform-translate-y: -3rem;
  }

  .xl\:focus\:-translate-y-13:focus {
    --transform-translate-y: -3.25rem;
  }

  .xl\:focus\:-translate-y-14:focus {
    --transform-translate-y: -3.5rem;
  }

  .xl\:focus\:-translate-y-15:focus {
    --transform-translate-y: -3.75rem;
  }

  .xl\:focus\:-translate-y-16:focus {
    --transform-translate-y: -4rem;
  }

  .xl\:focus\:-translate-y-20:focus {
    --transform-translate-y: -5rem;
  }

  .xl\:focus\:-translate-y-24:focus {
    --transform-translate-y: -6rem;
  }

  .xl\:focus\:-translate-y-28:focus {
    --transform-translate-y: -7rem;
  }

  .xl\:focus\:-translate-y-32:focus {
    --transform-translate-y: -8rem;
  }

  .xl\:focus\:-translate-y-36:focus {
    --transform-translate-y: -9rem;
  }

  .xl\:focus\:-translate-y-40:focus {
    --transform-translate-y: -10rem;
  }

  .xl\:focus\:-translate-y-44:focus {
    --transform-translate-y: -11rem;
  }

  .xl\:focus\:-translate-y-48:focus {
    --transform-translate-y: -12rem;
  }

  .xl\:focus\:-translate-y-52:focus {
    --transform-translate-y: -13rem;
  }

  .xl\:focus\:-translate-y-56:focus {
    --transform-translate-y: -14rem;
  }

  .xl\:focus\:-translate-y-60:focus {
    --transform-translate-y: -15rem;
  }

  .xl\:focus\:-translate-y-64:focus {
    --transform-translate-y: -16rem;
  }

  .xl\:focus\:-translate-y-72:focus {
    --transform-translate-y: -18rem;
  }

  .xl\:focus\:-translate-y-80:focus {
    --transform-translate-y: -20rem;
  }

  .xl\:focus\:-translate-y-96:focus {
    --transform-translate-y: -24rem;
  }

  .xl\:focus\:-translate-y-px:focus {
    --transform-translate-y: -1px;
  }

  .xl\:focus\:-translate-y-0\.5:focus {
    --transform-translate-y: -.125rem;
  }

  .xl\:focus\:-translate-y-1\.5:focus {
    --transform-translate-y: -.375rem;
  }

  .xl\:focus\:-translate-y-2\.5:focus {
    --transform-translate-y: -.625rem;
  }

  .xl\:focus\:-translate-y-3\.5:focus {
    --transform-translate-y: -.875rem;
  }

  .xl\:focus\:-translate-y-1\/2:focus {
    --transform-translate-y: -50%;
  }

  .xl\:focus\:-translate-y-1\/3:focus {
    --transform-translate-y: -33.3333%;
  }

  .xl\:focus\:-translate-y-2\/3:focus {
    --transform-translate-y: -66.6667%;
  }

  .xl\:focus\:-translate-y-1\/4:focus {
    --transform-translate-y: -25%;
  }

  .xl\:focus\:-translate-y-2\/4:focus {
    --transform-translate-y: -50%;
  }

  .xl\:focus\:-translate-y-3\/4:focus {
    --transform-translate-y: -75%;
  }

  .xl\:focus\:-translate-y-1\/5:focus {
    --transform-translate-y: -20%;
  }

  .xl\:focus\:-translate-y-2\/5:focus {
    --transform-translate-y: -40%;
  }

  .xl\:focus\:-translate-y-3\/5:focus {
    --transform-translate-y: -60%;
  }

  .xl\:focus\:-translate-y-4\/5:focus {
    --transform-translate-y: -80%;
  }

  .xl\:focus\:-translate-y-1\/6:focus {
    --transform-translate-y: -16.6667%;
  }

  .xl\:focus\:-translate-y-2\/6:focus {
    --transform-translate-y: -33.3333%;
  }

  .xl\:focus\:-translate-y-3\/6:focus {
    --transform-translate-y: -50%;
  }

  .xl\:focus\:-translate-y-4\/6:focus {
    --transform-translate-y: -66.6667%;
  }

  .xl\:focus\:-translate-y-5\/6:focus {
    --transform-translate-y: -83.3333%;
  }

  .xl\:focus\:-translate-y-1\/12:focus {
    --transform-translate-y: -8.33333%;
  }

  .xl\:focus\:-translate-y-2\/12:focus {
    --transform-translate-y: -16.6667%;
  }

  .xl\:focus\:-translate-y-3\/12:focus {
    --transform-translate-y: -25%;
  }

  .xl\:focus\:-translate-y-4\/12:focus {
    --transform-translate-y: -33.3333%;
  }

  .xl\:focus\:-translate-y-5\/12:focus {
    --transform-translate-y: -41.6667%;
  }

  .xl\:focus\:-translate-y-6\/12:focus {
    --transform-translate-y: -50%;
  }

  .xl\:focus\:-translate-y-7\/12:focus {
    --transform-translate-y: -58.3333%;
  }

  .xl\:focus\:-translate-y-8\/12:focus {
    --transform-translate-y: -66.6667%;
  }

  .xl\:focus\:-translate-y-9\/12:focus {
    --transform-translate-y: -75%;
  }

  .xl\:focus\:-translate-y-10\/12:focus {
    --transform-translate-y: -83.3333%;
  }

  .xl\:focus\:-translate-y-11\/12:focus {
    --transform-translate-y: -91.6667%;
  }

  .xl\:focus\:-translate-y-full:focus {
    --transform-translate-y: -100%;
  }

  .xl\:skew-x-0 {
    --transform-skew-x: 0;
  }

  .xl\:skew-x-1 {
    --transform-skew-x: 1deg;
  }

  .xl\:skew-x-2 {
    --transform-skew-x: 2deg;
  }

  .xl\:skew-x-3 {
    --transform-skew-x: 3deg;
  }

  .xl\:skew-x-6 {
    --transform-skew-x: 6deg;
  }

  .xl\:skew-x-12 {
    --transform-skew-x: 12deg;
  }

  .xl\:-skew-x-12 {
    --transform-skew-x: -12deg;
  }

  .xl\:-skew-x-6 {
    --transform-skew-x: -6deg;
  }

  .xl\:-skew-x-3 {
    --transform-skew-x: -3deg;
  }

  .xl\:-skew-x-2 {
    --transform-skew-x: -2deg;
  }

  .xl\:-skew-x-1 {
    --transform-skew-x: -1deg;
  }

  .xl\:skew-y-0 {
    --transform-skew-y: 0;
  }

  .xl\:skew-y-1 {
    --transform-skew-y: 1deg;
  }

  .xl\:skew-y-2 {
    --transform-skew-y: 2deg;
  }

  .xl\:skew-y-3 {
    --transform-skew-y: 3deg;
  }

  .xl\:skew-y-6 {
    --transform-skew-y: 6deg;
  }

  .xl\:skew-y-12 {
    --transform-skew-y: 12deg;
  }

  .xl\:-skew-y-12 {
    --transform-skew-y: -12deg;
  }

  .xl\:-skew-y-6 {
    --transform-skew-y: -6deg;
  }

  .xl\:-skew-y-3 {
    --transform-skew-y: -3deg;
  }

  .xl\:-skew-y-2 {
    --transform-skew-y: -2deg;
  }

  .xl\:-skew-y-1 {
    --transform-skew-y: -1deg;
  }

  .xl\:hover\:skew-x-0:hover {
    --transform-skew-x: 0;
  }

  .xl\:hover\:skew-x-1:hover {
    --transform-skew-x: 1deg;
  }

  .xl\:hover\:skew-x-2:hover {
    --transform-skew-x: 2deg;
  }

  .xl\:hover\:skew-x-3:hover {
    --transform-skew-x: 3deg;
  }

  .xl\:hover\:skew-x-6:hover {
    --transform-skew-x: 6deg;
  }

  .xl\:hover\:skew-x-12:hover {
    --transform-skew-x: 12deg;
  }

  .xl\:hover\:-skew-x-12:hover {
    --transform-skew-x: -12deg;
  }

  .xl\:hover\:-skew-x-6:hover {
    --transform-skew-x: -6deg;
  }

  .xl\:hover\:-skew-x-3:hover {
    --transform-skew-x: -3deg;
  }

  .xl\:hover\:-skew-x-2:hover {
    --transform-skew-x: -2deg;
  }

  .xl\:hover\:-skew-x-1:hover {
    --transform-skew-x: -1deg;
  }

  .xl\:hover\:skew-y-0:hover {
    --transform-skew-y: 0;
  }

  .xl\:hover\:skew-y-1:hover {
    --transform-skew-y: 1deg;
  }

  .xl\:hover\:skew-y-2:hover {
    --transform-skew-y: 2deg;
  }

  .xl\:hover\:skew-y-3:hover {
    --transform-skew-y: 3deg;
  }

  .xl\:hover\:skew-y-6:hover {
    --transform-skew-y: 6deg;
  }

  .xl\:hover\:skew-y-12:hover {
    --transform-skew-y: 12deg;
  }

  .xl\:hover\:-skew-y-12:hover {
    --transform-skew-y: -12deg;
  }

  .xl\:hover\:-skew-y-6:hover {
    --transform-skew-y: -6deg;
  }

  .xl\:hover\:-skew-y-3:hover {
    --transform-skew-y: -3deg;
  }

  .xl\:hover\:-skew-y-2:hover {
    --transform-skew-y: -2deg;
  }

  .xl\:hover\:-skew-y-1:hover {
    --transform-skew-y: -1deg;
  }

  .xl\:focus\:skew-x-0:focus {
    --transform-skew-x: 0;
  }

  .xl\:focus\:skew-x-1:focus {
    --transform-skew-x: 1deg;
  }

  .xl\:focus\:skew-x-2:focus {
    --transform-skew-x: 2deg;
  }

  .xl\:focus\:skew-x-3:focus {
    --transform-skew-x: 3deg;
  }

  .xl\:focus\:skew-x-6:focus {
    --transform-skew-x: 6deg;
  }

  .xl\:focus\:skew-x-12:focus {
    --transform-skew-x: 12deg;
  }

  .xl\:focus\:-skew-x-12:focus {
    --transform-skew-x: -12deg;
  }

  .xl\:focus\:-skew-x-6:focus {
    --transform-skew-x: -6deg;
  }

  .xl\:focus\:-skew-x-3:focus {
    --transform-skew-x: -3deg;
  }

  .xl\:focus\:-skew-x-2:focus {
    --transform-skew-x: -2deg;
  }

  .xl\:focus\:-skew-x-1:focus {
    --transform-skew-x: -1deg;
  }

  .xl\:focus\:skew-y-0:focus {
    --transform-skew-y: 0;
  }

  .xl\:focus\:skew-y-1:focus {
    --transform-skew-y: 1deg;
  }

  .xl\:focus\:skew-y-2:focus {
    --transform-skew-y: 2deg;
  }

  .xl\:focus\:skew-y-3:focus {
    --transform-skew-y: 3deg;
  }

  .xl\:focus\:skew-y-6:focus {
    --transform-skew-y: 6deg;
  }

  .xl\:focus\:skew-y-12:focus {
    --transform-skew-y: 12deg;
  }

  .xl\:focus\:-skew-y-12:focus {
    --transform-skew-y: -12deg;
  }

  .xl\:focus\:-skew-y-6:focus {
    --transform-skew-y: -6deg;
  }

  .xl\:focus\:-skew-y-3:focus {
    --transform-skew-y: -3deg;
  }

  .xl\:focus\:-skew-y-2:focus {
    --transform-skew-y: -2deg;
  }

  .xl\:focus\:-skew-y-1:focus {
    --transform-skew-y: -1deg;
  }

  .xl\:transition-none {
    transition-property: none;
  }

  .xl\:transition-all {
    transition-property: all;
  }

  .xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  }

  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
  }

  .xl\:transition-opacity {
    transition-property: opacity;
  }

  .xl\:transition-shadow {
    transition-property: box-shadow;
  }

  .xl\:transition-transform {
    transition-property: transform;
  }

  .xl\:ease-linear {
    transition-timing-function: linear;
  }

  .xl\:ease-in {
    transition-timing-function: cubic-bezier(.4, 0, 1, 1);
  }

  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
  }

  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  }

  .xl\:duration-75 {
    transition-duration: 75ms;
  }

  .xl\:duration-100 {
    transition-duration: .1s;
  }

  .xl\:duration-150 {
    transition-duration: .15s;
  }

  .xl\:duration-200 {
    transition-duration: .2s;
  }

  .xl\:duration-300 {
    transition-duration: .3s;
  }

  .xl\:duration-500 {
    transition-duration: .5s;
  }

  .xl\:duration-700 {
    transition-duration: .7s;
  }

  .xl\:duration-1000 {
    transition-duration: 1s;
  }

  .xl\:delay-75 {
    transition-delay: 75ms;
  }

  .xl\:delay-100 {
    transition-delay: .1s;
  }

  .xl\:delay-150 {
    transition-delay: .15s;
  }

  .xl\:delay-200 {
    transition-delay: .2s;
  }

  .xl\:delay-300 {
    transition-delay: .3s;
  }

  .xl\:delay-500 {
    transition-delay: .5s;
  }

  .xl\:delay-700 {
    transition-delay: .7s;
  }

  .xl\:delay-1000 {
    transition-delay: 1s;
  }

  .xl\:animate-none {
    animation: none;
  }

  .xl\:animate-spin {
    animation: spin 1s linear infinite;
  }

  .xl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, .2, 1) infinite;
  }

  .xl\:animate-pulse {
    animation: pulse 2s cubic-bezier(.4, 0, .6, 1) infinite;
  }

  .xl\:animate-bounce {
    animation: bounce 1s infinite;
  }
}

/*# sourceMappingURL=index.fb9b7867.css.map */
